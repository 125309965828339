/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name StringGroupCreateController
 */
angular.module('aviApp').controller('StringGroupCreateController', [
'$scope', '$window', 'Schema', 'AviAlertService', 'StringGroup', 'AviConfirmService',
function($scope, $window, Schema, AviAlertService, StringGroup, AviConfirmService) {
    const gridConfig = {
        fields: [{
            title: 'String',
            name: 'key',
        }],
        rowId: StringGroup.generateStringObjId,
        searchFields: ['key'],
        multipleactions: [{
            title: 'Remove',
            do: rows => {
                $scope.editable.removeStrObjs(rows);
            },
        }],
    };

    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Schema = Schema;

    $scope.stringGrid = angular.copy(gridConfig);
    $scope.mapGrid = angular.copy(gridConfig);

    $scope.mapGrid.fields[0].title = 'Key';

    $scope.mapGrid.fields.push({
        title: 'Value',
        name: 'value',
    });

    $scope.mapGrid.searchFields.push('value');

    $scope.newString = {};

    $scope.init = () => {
        $scope.mapView = $scope.editable.getConfig().type;
    };

    /**
     * File upload event handler. Adds strings to the list.
     * @public
     */
    $scope.importFile = function() {
        let duplicateVals = false;

        const { type } = $scope.editable.getConfig();

        $scope.newString.file.split('\n').forEach(str => {
            let key = str.trim(),
                value,
                firstCommaPos;

            if (!str) {
                return;
            }

            if (type !== 'SG_TYPE_STRING') {
                firstCommaPos = key.indexOf(',');

                if (firstCommaPos !== -1) {
                    value = key.slice(firstCommaPos + 1).trim();
                    key = key.slice(0, firstCommaPos).trim();
                }
            }

            const strObj = { key, value };
            // Force adds strObj to list
            const strObjIsUnique = $scope.editable.addStringObj(strObj, true);

            if (!strObjIsUnique) {
                duplicateVals = true;
            }
        });

        if (duplicateVals) {
            AviAlertService.throw(
                'One or more strings being imported already existed.' +
                'Those have been over-written by the newly provided instances of the strings.',
            );
        }
    };

    /**
     * Adds kv object to group.
     * @param {Object} kvObj - kv trying to be added
     * @public
     */
    $scope.addString = function(kvObj) {
        kvObj = kvObj || $scope.newString;

        const strObjAdded = $scope.editable.addStringObj(kvObj);

        if (strObjAdded === false) {
            AviConfirmService.confirm(`String already exists.
            Do you want to replace the existing one anyway?`)
                .then(() => {
                    $scope.editable.addStringObj(kvObj, true);
                    $scope.newString = {};
                });
        } else {
            $scope.newString = {};
        }
    };

    /**
     * Since setting map property is async (because of confirmation dialog) we have to
     * introduce a separate `ui` value just for checkbox and update actual one when user has
     * confirmed or cancelled this change.
     */
    $scope.onViewTypeChange = function() {
        const config = $scope.editable.getConfig();

        //mapView already has a new, 'changed' value
        if (!config.kv.length ||
            $window.confirm('Current values will be discarded. Are you sure you want to continue?')
        ) {
            config.type = $scope.mapView;
            $scope.newString.value = undefined;
            $scope.editable.discardAllStrings();
        } else {
            //restore old value
            $scope.mapView = config.type;
        }
    };
}]);
