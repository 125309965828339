/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name infraCloudState
 * @description  Manages cloud state within Infrastructure pages.
 */
const infraCloudStateService = (Base, Cloud, VRFContextCollection) => {
    class InfraCloudState extends Base {
        constructor() {
            super();

            this.cloudState = {
                cloud: new Cloud(),
                vrfContextCollection: new VRFContextCollection({
                    overLimitCoeff: 0,
                    sortBy: 'name',
                }),
            };
        }

        /**
         * Returns the cloudState object.
         * @return {Object}
         */
        getCloudState() {
            return this.cloudState;
        }

        /**
         * Returns the stored cloud object.
         * @return {Cloud}
         */
        getCloud() {
            return this.cloudState.cloud;
        }

        /**
         * Returns the url of the cloud object.
         * @return {string}
         */
        getCloudRef() {
            const { cloud } = this.cloudState;

            return cloud && cloud.getRef();
        }

        /**
         * Returns currently selected cloud id.
         * @returns {string}
         */
        getCloudId() {
            const { cloud } = this.cloudState;

            return cloud.id;
        }

        /**
         * Returns the VRF Context collection.
         * @return {VRFContextCollection}
         */
        getVrfContextCollection() {
            return this.cloudState.vrfContextCollection;
        }

        /**
         * Sets the cloud on the cloudState object and triggers the 'cloudChange' event.
         * @param {Cloud} cloud
         */
        setCloud(cloud) {
            this.cloudState.cloud = cloud;
            this.trigger('cloudChange');
            this.reloadVRFContextCollection_();
        }

        /**
         * Reloads VRF context on cloud change.
         * So that vrfContext will never be outdated.
         * @protected
         */
        reloadVRFContextCollection_() {
            const cloudRef = this.getCloudRef();

            this.cloudState.vrfContextCollection.reset();

            this.loadVrfContextCollection(cloudRef)
                .then(response => {
                    this.setVRFContextCollection(response);
                });
        }

        /**
         * Checks if Custom VRF Context exists.
         * @return {boolean} True if custom VRF contexts exist, false otherwise.
         */
        hasCustomVRFContext() {
            return this.cloudState.vrfContextCollection.hasCustomVRFContext();
        }

        /**
         * Sets the VRF Context collection on the cloudState object and triggers the
         * 'vrfContextCollectionChange' event.
         * @param {VRFContextCollection} vrfContextCollection
         */
        setVRFContextCollection(vrfContextCollection) {
            this.cloudState.vrfContextCollection = vrfContextCollection;
            this.trigger('vrfContextCollectionChange');
        }

        /**
         * Returns true if the VRF Context tab should be shown. Dependent on the cloud type.
         * @return {boolean}
         */
        showVrfContextTab() {
            const cloud = this.getCloud();

            return cloud.allowCustomVRFContext();
        }

        /**
         * Returns true if the Gateway Monitor tab should be shown. Dependent on the number of
         * VRF Contexts within a cloud and the cloud type.
         * @return {boolean}
         */
        showGatewayMonitorTab() {
            const { vrfContextCollection, cloud } = this.getCloudState();

            return vrfContextCollection.getNumberOfItems() > 0 ||
                !vrfContextCollection.hasCustomVRFContext() ||
                cloud.getVtype() in InfraCloudState.showGatewayMonitor;
        }

        /**
         * Loads the VRF Context collection given a cloud uuid.
         * @param {string} cloudRef - Cloud ref.
         * @return {ng.$q.promise} to be resolved with collection.
         */
        loadVrfContextCollection(cloudRef) {
            const vrfContextCollection = new VRFContextCollection({
                params: {
                    'cloud_ref.uuid': cloudRef.slug(),
                },
                defaults: {
                    cloud_ref: cloudRef,
                },
                overLimitCoeff: 0,
                sortBy: 'name',
            });

            this.busy = true;

            return vrfContextCollection.load()
                .then(() => vrfContextCollection)
                .finally(() => this.busy = false);
        }
    }

    InfraCloudState.showGatewayMonitor = {
        CLOUD_VCENTER: true,
        CLOUD_NONE: true,
        CLOUD_LINUXSERVER: true,
    };

    return new InfraCloudState();
};

infraCloudStateService.$inject = [
    'Base',
    'Cloud',
    'VRFContextCollection',
];

angular.module('aviApp').factory('infraCloudState', infraCloudStateService);
