/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('SideMenuController', [
'$scope', '$timeout', '$rootScope', 'myAccount',
function($scope, $timeout, $rootScope, myAccount) {
    $scope.myAccount = myAccount;

    $scope.displayValues = function() {
        const toShow = myAccount.uiProperty.valuesToDisplay;

        myAccount.saveUIProperty();
        $rootScope.$broadcast(`display-${toShow}`);
    };

    $scope.setColumn = function(side) {
        if (side === 'left') {
            myAccount.uiProperty.leftMenu = true;
            myAccount.saveUIProperty();
        } else if (side === 'right') {
            myAccount.uiProperty.leftMenu = false;
            myAccount.saveUIProperty();
        }
    };

    $scope.toggleRepaint = function() {
        // using timeout so that this resolves after things have new sizes
        myAccount.saveUIProperty();
        $timeout(function() {
            $rootScope.$broadcast('repaint');
        }, 250);
    };
},
]);
