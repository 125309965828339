/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name persistenceProfileListPage
 * @desription
 *
 *     Regular list view component for Persistent Profiles.
 */
class PersistenceProfileListPageController {
    constructor(CRUDGridConfig, PersistenceProfileCollection) {
        this.CRUDGridConfig_ = CRUDGridConfig;
        this.PersistenceProfileCollection_ = PersistenceProfileCollection;
    }

    $onInit() {
        const gridConfig = new this.CRUDGridConfig_();

        gridConfig.collection = new this.PersistenceProfileCollection_();

        gridConfig.fields = [
                {
                    name: 'data.config.name',
                    title: 'Name',
                    sortBy: 'name',
                }, {
                    name: 'type',
                    title: 'Type',
                    template: '<span class="sel-type">' +
                    '{{ row.getType() | enum: "PERSISTENCE_TYPE_" }}</span>',
                },
        ];

        gridConfig.singleactions = [{
            title: 'Edit',
            class: 'icon-pencil-4',
            hidden: row => !row.isAllowed(),
            do(row) {
                row.isEditable() ? row.edit() : this.config.collection.clone(row);
            },
        }];

        this.gridConfig = gridConfig;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

PersistenceProfileListPageController.$inject = [
        'CRUDGridConfig',
        'PersistenceProfileCollection',
        'PersistenceProfile',
];

angular.module('aviApp').component('persistenceProfileListPage', {
    controller: PersistenceProfileListPageController,
    template:
            `<div class="panel templatesList">
                <collection-grid
                    config="$ctrl.gridConfig"
                    help-popover-anchor="persistencetable"
                    help-popover-position="lm"></collection-grid>
                <div class="help-popover-storage"
                    ng-include="::'src/views/components/help/1.2/properlist-quick-help.html'"></div>
            </div>`,
});
