/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-psm-match-element-config-form.less';

/**
 * @alias waf/wafPsmMatchElementConfigForm
 * @private
 */
class WafPsmMatchElementConfigFormComponent {
    constructor(dropDownUtils) {
        this.wafVariableOptions = dropDownUtils.getEnumDropdownOptions('WafVariable');
    }
}

WafPsmMatchElementConfigFormComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name wafPsmMatchElementConfigForm
 * @module waf/wafPsmMatchElementConfigForm
 * @desc Component for editing a PSM rule match element.
 * @param {WafPSMMatchElement} matchElement
 * @param {Function} onClose - Called when the user clicks the close button.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPsmMatchElementConfigForm', {
    bindings: {
        matchElement: '<',
        onClose: '&',
        preventEdit: '<',
    },
    controller: WafPsmMatchElementConfigFormComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-psm-match-element-config/waf-psm-match-element-config-form/' +
        'waf-psm-match-element-config-form.component.html',
});
