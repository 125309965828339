/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name pathMatch
 * @description
 *     Component for displaying the path match
 * @param {Object} match - Match object.
 * @param {Function=} onDelete - Method to delete the match.
 * @param {boolean=} allowDelete - Showing remove button or not.
 * @param {boolean=} allowCustomString - Allow custom string in input field or not, true by default.
 */
class PathMatchController {
    constructor(Schema) {
        this.Schema = Schema;
        this.allowDelete = true;
        this.allowCustomString = true;
    }

    /**
     * Returns true for non RegExp match criteria.
     */
    allowCustom() {
        const { match, allowCustomString } = this;

        if (!match || !allowCustomString) {
            return false;
        }

        const { match_criteria: criteria } = match;

        return criteria !== 'REGEX_DOES_NOT_MATCH' && criteria !== 'REGEX_MATCH';
    }
}

PathMatchController.$inject = [
    'Schema',
];

/**
 * @ngdoc component
 * @name pathMatch
 * @description
 *     Component for displaying the path match under tabs for VS policies.
 * @param {Object} match - PathMatch object.
 * @param {boolean=} allowDelete - Showing delete button or not.
 * @param {boolean=} allowCustomString - Allow custom string or not, true by default.
 * @param {Function=} onDelete - method to delete the match.
 */
angular.module('aviApp').component('pathMatch', {
    bindings: {
        match: '<',
        allowDelete: '<?',
        allowCustomString: '<?',
        onDelete: '&?',
    },
    controller: PathMatchController,
    templateUrl: 'src/components/applications/virtualservice/policy/path-match.component.html',
});
