/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name ProtocolParser
 * @description ProtocolParser item.
 */
const protocolParserFactory = Item => {
    class ProtocolParser extends Item {}

    Object.assign(ProtocolParser.prototype, {
        objectName: 'protocolparser',
        windowElement: 'protocol-parser-modal',
    });

    return ProtocolParser;
};

protocolParserFactory.$inject = [
    'Item',
];

angular.module('aviApp').factory('ProtocolParser', protocolParserFactory);
