/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../../less/pages/infrastructure/serviceengine-config.less';

angular.module('aviApp').controller('ServiceEngineConfigurationController', [
'$scope', 'SEGroupCollection', 'Cloud', '$templateCache', 'AviModal', 'Vlan',
'VRFContextCollection', 'getSubnetString', 'ServiceEngine',
(
    $scope,
    SEGroupCollection,
    Cloud,
    $templateCache,
    AviModal,
    Vlan,
    VRFContextCollection,
    getSubnetString,
    ServiceEngine,
) => {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.seGroupCollection = new SEGroupCollection({
        isStatic: true,
        showTenantName: true,
    });

    $scope.cloud = null;

    const vrfCollection = new VRFContextCollection();

    $scope.init = function() {
        const config = $scope.editable.getConfig();
        const cloudId = config.cloud_ref.slug();

        $scope.managementNetworkRows = [];

        if ('mgmt_vnic' in config) {
            $scope.managementNetworkRows.push(config.mgmt_vnic);
        }

        $scope.dataNetworkRows = config.data_vnics;

        $scope.vnicRows = $scope.managementNetworkRows.concat(config.data_vnics || []);
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);

        $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': cloudId });

        const cloud = new Cloud({ id: cloudId });

        $scope.cloud = cloud;
        $scope.dataNetworks.cloud = cloud;
        $scope.networksGridConfig.cloud = cloud;
        cloud.load();// don't need to wait since it's only used in a template

        vrfCollection.setParams({ 'cloud_ref.uuid': cloudId });
    };

    const vrfColumn = {
        name: 'vrf_ref',
        title: 'VRF',
        template:
            `<collection-dropdown
                class="full-width"
                options="config.vrfCollection"
                search="true"
                allow-create="false"
                allow-edit="false"
                ng-model="row.vrf_ref">
            </collection-dropdown>`,
    };

    $scope.managementNetwork = {
        vrfCollection,
        fields: [{
            name: 'adapter',
            title: 'Interface',
            transform(row) {
                return row.adapter === 'Unknown' ? row.if_name : row.adapter;
            },
        }, {
            name: 'network_name',
            title: 'Network Name',
        }, {
            name: 'mac_address',
            title: 'Mac Address',
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: '{{ row.dhcp_enabled  | booleanLabel: "enabled" }}',
        }, {
            title: 'IP Address',
            name: 'ip_address',
            template:
                `<span
                    class="sel-ip-addr"
                    ng-repeat="nw in row.vnic_networks track by $index">
                    {{$index > 0 ? ', ' : ''}} {{ nw.ip | subnetString }}
                 </span>`,
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        layout: {
            hideDisplaying: true,
            hideSearch: true,
        },
        renderAll: true,
    };

    $scope.dataNetworks = {
        vrfCollection,
        fields: [{
            name: 'adapter',
            title: 'Interface',
            sortBy: 'adapter',
            transform(row) {
                return row.adapter === 'Unknown' ? row.if_name : row.adapter;
            },
        }, {
            name: 'network_name',
            title: 'Network Name',
            sortBy: 'network_name',
            transform(row) {
                return row.network_name ? row.network_name : row.linux_name;
            },
        }, {
            name: 'mac_address',
            title: 'Mac Address',
            sortBy: 'mac_address',
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: `
                <div class="pt10 pb10">
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox class="sel-dhcp" ng-model="row.dhcp_enabled"></checkbox>
                            <span>IPv4 DHCP</span>
                        </label>
                    </div>
                    <div class="control-group">
                        <label class="flex-checkbox">
                            <checkbox ng-model="row.ip6_autocfg_enabled"></checkbox>
                            <span>IPv6 DHCP</span>
                        </label>
                    </div>
                </div>`,
        }, {
            name: 'ip_address',
            title: 'IP Address',
            template: require('./se-edit-data-networks-grid-ip.partial.html'),
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        searchFields: [
            'adapter',
            'network_name',
            'mac_address',
            'linux_name',
            'if_name',
        ],
        defaultSorting: 'adapter',
        renderAll: true, //since we don't know row height in advance
    };

    $scope.networksGridConfig = {
        vrfCollection,
        fields: [{
            name: 'if_name',
            title: 'Interface',
            sortBy: 'if_name',
        }, {
            name: 'mac_address',
            title: 'Mac Address',
            sortBy: 'mac_address',
        }, {
            name: 'dhcp_enabled',
            title: 'DHCP',
            template: '{{ row.dhcp_enabled | booleanLabel: "enabled" }}',
        }, {
            name: 'ip_address',
            title: 'IP Address',
            template: require('./se-edit-data-networks-grid-ip.partial.html'),
        }, vrfColumn],
        rowId: ServiceEngine.getVnicId,
        searchFields: [
            'adapter',
            'network_name',
            'mac_address',
        ],
        renderAll: true,
    };

    $scope.vlanInterfaceGridConfig = {
        fields: [{
            name: 'if_name',
            title: 'Name',
        }, {
            name: 'parent',
            title: 'Parent Interface',
            transform(row) {
                const index = row.if_name.lastIndexOf(`.${row.vlan_id}`);

                return index > -1 ? row.if_name.slice(0, index) : row.if_name;
            },
        }, {
            name: 'vlan_id',
            title: 'VLAN',
        }, {
            name: 'ip_address',
            title: 'IP Address',
            transform: parseVnicIps,
        }, {
            name: 'vrf_ref',
            title: 'VRF',
            template: '{{row.vrf_ref | name}}',
        }],
        singleactions: [{
            title: 'Edit',
            class: 'icon-pencil-4',
            do: editVlan,
        }, {
            title: 'Delete',
            class: 'icon-trash',
            do: removeVlan,
        }],
        layout: {
            hideDisplaying: true,
        },
        rowId: ServiceEngine.getVnicId,
        searchFields: [
            'vlan_id',
            'if_name',
        ],
        renderAll: true,
    };

    /**
     * Parses vnic_networks of VLAN interfaces for IP addresses to display in the grid.
     * @param  {Object} row - VLAN object.
     * @return {string} Parsed string of IP addresses.
     */
    function parseVnicIps(row) {
        if (Array.isArray(row.vnic_networks)) {
            const ret = [];

            row.vnic_networks.forEach(network => {
                if (network && network.ip) {
                    ret.push(getSubnetString(network.ip));
                }
            });

            return ret.join(', ');
        }

        return '';
    }

    /**
     * Goes through VNIC networks to get a list of all VLAN interfaces.
     * @param  {Object[]} networks - VNIC network object.
     * @return {Object[]} Array of VLAN interface objects.
     */
    function getVlanInterfaceRows(networks) {
        const vlanList = [];

        networks.forEach(net => {
            if (Array.isArray(net.vlan_interfaces)) {
                vlanList.push(...net.vlan_interfaces);
            }
        });

        return vlanList;
    }

    /**
     * Opens Create VLAN modal.
     */
    $scope.createVlan = function() {
        AviModal.open('infra-se-edit-vlan-create', {
            vlan: new Vlan($scope.vnicRows, null, $scope.cloud),
            closeVlan,
        });
    };

    /**
     * Opens Create VLAN modal for editing.
     */
    function editVlan(vlanRow) {
        AviModal.open('infra-se-edit-vlan-create', {
            vlan: new Vlan($scope.vnicRows, vlanRow, $scope.cloud),
            closeVlan,
        });
    }

    /**
     * Removes VLAN from VNIC network.
     * @param  {Object} vlanRow - VLAN object.
     */
    function removeVlan(vlanRow) {
        const vlan = new Vlan($scope.vnicRows, vlanRow, $scope.cloud);

        vlan.removeVlan();
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);
    }

    /**
     * Closes VLAN modal and resets vlanInterfaceRows to update changes.
     */
    function closeVlan() {
        AviModal.destroy('infra-se-edit-vlan-create');
        $scope.vlanInterfaceRows = getVlanInterfaceRows($scope.vnicRows);
    }

    $scope.$on('$destroy', () => {
        $scope.seGroupCollection.destroy();
        $scope.cloud.destroy();
        vrfCollection.destroy();
    });
}]);
