/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
/**
 * @alias module:services/errorPageService
 * @private
 */
class ErrorPageService {
    constructor(Base, AsyncFactory) {
        this.base_ = new Base();
        this.AsyncFactory_ = AsyncFactory;

        this.clusterStateAsyncFactory_ = null;
    }

    /**
     * Returns true if we are currently polling for the cluster state.
     * @returns {boolean}
     */
    get pollingActive() {
        return this.clusterStateAsyncFactory_ && this.clusterStateAsyncFactory_.isActive();
    }

    /**
     * Returns true if the upgrade process is active.
     * @returns {boolean}
     */
    get upgradeActive() {
        // TODO: call upgradeCardProgress.getControllerNode()
        return false;
    }

    /**
     * Begins polling for the cluster state.
     * @param {Function} callback - Function to be called on every successful request for the
     *     cluster state. Callback is called with the cluster status object.
     */
    startPollingClusterState(callback) {
        if (this.pollingActive) {
            this.stopPollingClusterState();
        }

        this.clusterStateAsyncFactory_ = new this.AsyncFactory_(this.getClusterState_(callback), {
            maxSkipped: 1,
            callback: () => this.base_.cancelRequests(),
        });

        this.clusterStateAsyncFactory_.start(9999);
    }

    /**
     * Stops polling for the cluster state.
     */
    stopPollingClusterState() {
        if (this.clusterStateAsyncFactory_) {
            this.clusterStateAsyncFactory_.stop();
        }

        this.base_.cancelRequests();
    }

    /**
     * Request for the cluster state.
     * @param {Function} callback - Function to be called on every successful request for the
     *     cluster state. Callback is called with the cluster status object.
     * @returns {Function}
     */
    getClusterState_(callback) {
        return () => {
            return this.base_.request('get', '/api/cluster/runtime')
                .then(({ data = {} }) => {
                    const { cluster_state: clusterState } = data;

                    callback(clusterState);
                });
        };
    }

    /**
     * Cancels requests for the cluster state as well as the upgrade status.
     */
    cancelRequests() {
        this.stopPollingClusterState();
    }
}

ErrorPageService.$inject = [
    'Base',
    'AsyncFactory',
];

/**
 * @ngdoc service
 * @name ErrorPageService
 * @author alextsg
 * @module services/errorPageService
 * @desc Service for the errorPage component. Used to make requests to the controller for its
 *     status.
 */
angular.module('aviApp').service('errorPageService', ErrorPageService);
