/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBCollection
 * @author Alex Malitsky
 * @description
 *
 *     Collection which is supposed to have at most one item. Used to load it or create a new
 *     one using default configuration.
 *
 */
angular.module('aviApp').factory('GSLBCollection', ['Collection', 'GSLB',
function(Collection, GSLB) {
    /**
     * @constructor
     * @extends Collection
     */
    class GSLBCollection extends Collection {}

    angular.extend(GSLBCollection.prototype, {
        objectName_: 'gslb',
        itemClass_: GSLB,
        defaultViewportSize_: 1,
        serverDefaultsOverride_: {
            name: 'Default',
            dns_configs: [{ domain_name: '' }],
        },
    });

    return GSLBCollection;
}]);
