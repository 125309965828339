/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} ControllerFaultCollection
 * @description  Collection of Controller Faults.
 */
angular.module('aviApp').factory('ControllerFaultCollection', [
'Collection', 'ControllerFault', (Collection, ControllerFault) => {
    class ControllerFaultCollection extends Collection {
        /**
         * Returns a list of all fault descriptions.
         * @return {string[]} - Array of fault descriptions.
         */
        getFaultDescriptions() {
            return this.items.reduce((acc, item) => {
                item.getConfig().faults.forEach(fault => acc.push(fault.description));

                return acc;
            }, []);
        }
    }

    angular.extend(ControllerFaultCollection.prototype, {
        objectName_: 'controller-inventory',
        itemClass_: ControllerFault,
        allDataSources_: {
            list: {
                source: 'ControllerFaultCollDataSource',
                transformer: 'ControllerFaultDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return ControllerFaultCollection;
}]);
