/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  MetricsDimensionAsnameDataTransformer
 * @description
 *
 *    Merges two lists into one, flattens data out and adds config.id.
 */
angular.module('security.vs.vantage.avi').factory('MetricsDimensionAsnameDataTransformer', [
'RevisedDataTransformer',
function(RevisedDataTransformer) {
    return class MetricsDimensionAsnameDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            const
                { data } = resp,
                results = [];

            if ('ipdata' in data) {
                _.forEach(data['ipdata'], ({ asdata }, ip) => {
                    results.push({
                        config: {
                            id: ip,
                        },
                        asname: asdata.asname,
                    });
                });
            }

            if ('asdata' in data) {
                _.forEach(data['asdata'], (name, asn) => {
                    results.push({
                        config: {
                            id: asn,
                        },
                        asname: name,
                    });
                });
            }

            data.results = results;
            data.count = results.length;

            return resp;
        }
    };
}]);
