/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/** @alias trafficCloneProfileModal */
class TrafficCloneProfileModalController {
    constructor(Schema, Regex, Cloud) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.Cloud = Cloud;
    }

    $onInit() {
        this.cloudIsSet = !!(this.editable.id || this.editable.getCloudRef());
    }

    /**
     * Returns true if the network should be shown as a dropdown, in cases of write access
     * clouds like vCenter, OpenStack, and AWS. Otherwise, returns false to show an input for
     * the subnet.
     * @return {boolean}
     */
    showNetworkDropdown() {
        if (this.cloudType) {
            return this.Cloud.hasSubnetList(this.cloudType);
        }

        return this.editable.allowNetworkConfiguration();
    }

    /**
     * Called by cloudSetter component. Sets the "cloud_ref" property in this.editable.
     * @param {string} cloudRef - Cloud item url.
     * @param {Cloud.data.config} cloudConfig - Cloud config.
     * @public
     */
    setCloud(cloudRef, cloudConfig) {
        this.editable.setCloudRef(cloudRef, cloudConfig);
        this.cloudIsSet = true;
    }
}

TrafficCloneProfileModalController.$inject = [
    'Schema',
    'Regex',
    'Cloud',
];

/**
 * @ngdoc component
 * @name  trafficCloneProfileModal
 * @description
 *     Component for Traffic Clone Profile modal.
 * @param {TrafficCloneProfile} editable
 * @param {Function} closeModal - Closes modal.
 * @param {string=} cloudType - Cloud type this traffic profile belongs to. Optional.
 */
angular.module('aviApp').component('trafficCloneProfileModal', {
    controller: TrafficCloneProfileModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
        cloudType: '<?',
    },
    templateUrl: 'src/components/templates/profiles/traffic-clone-profile/' +
        'traffic-clone-profile-modal/traffic-clone-profile-modal.html',
});
