/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  AviConfirmService
 * @description
 *     Used to create a confirmation window as an alternative to $window.confirm. Returns a promise
 *     that is resolved if the user confirms or rejected if the user cancels.
 */
class Service {
    constructor(AviModal, $q) {
        this._AviModal = AviModal;
        this._$q = $q;
    }

    /**
     * Opens the confirm modal for the user to Confirm or Cancel based on the message parameter.
     * @param  {string} message - Message to be displayed for confirmation.
     * @return {ng.$q.promise}
     */
    confirm(message) {
        const deferred = this._$q.defer();

        this._AviModal.open('avi-confirm', {
            message,
            onConfirm: () => deferred.resolve(true),
            onCancel: () => deferred.reject(false),
        }, 'avi-confirm');

        return deferred.promise;
    }

    /**
     * Compiles a component prompt to the user, used in cases where a user needs to proceed by
     * filling out a small form. Returns a promise so that an action can be chained with the
     * data.
     * @param {string} component - Name of the component to compile.
     * @param {Object=} data - Optional data to be used in bindings.
     * @return {ng.$q.promise}
     */
    prompt(component, data) {
        const deferred = this._$q.defer();
        const bindings = {
            onSubmit: ['data', data => deferred.resolve(data)],
            onCancel: () => deferred.reject(false),
        };

        if (angular.isObject(data)) {
            angular.extend(bindings, data);
        }

        this._AviModal.open(component, bindings, 'avi-prompt');

        return deferred.promise;
    }
}

Service.$inject = [
    'AviModal',
    '$q',
];

angular.module('aviApp').service('AviConfirmService', Service);
