/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('IpAddrGroupController', [
'$scope', 'CRUDGridConfig', 'IpAddrGroupCollection',
function($scope, CRUDGridConfig, IpAddrGroupCollection) {
    const gridConfig = {
        collection: new IpAddrGroupCollection(),
    };

    gridConfig.fields = [
        {
            name: 'name',
            title: 'Name',
            template: '<span class="sel-ipaddr-grp-name">{{row.getName()}}</span>',
            sortBy: 'name',
        }, {
            name: 'num',
            title: 'IP Addresses or Ranges',
            template: '<span class="sel-ipaddr-grp-num">' +
                '{{ row.getConfig().apic_epg_name ? "EPG" :' +
                'row.getConfig().country_codes ? "Country Code" :' +
                '(row.getConfig().addrs.length + row.getConfig().ranges.length + ' +
                'row.getConfig().prefixes.length) }}</span>',
        }, {
            name: 'country_code',
            title: 'Country Codes or EPG',
            template: '<span>' +
                '{{ row.getConfig().country_codes ? row.getConfig().country_codes.join(", ") :' +
                'row.getConfig().apic_epg_name ? row.getConfig().apic_epg_name : "" }}</span>',
        },
    ];

    gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);
