/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
import './waf-policy-psm-group.less';

/**
 * @ngdoc component
 * @name WafPolicyPsmGroupComponent
 * @module waf/WafPolicyPsmGroupComponent
 * @desc Component for displaying a WAF Policy PSM Group.
 * @param group - WafPolicyPsmGroup
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-group',
    templateUrl: './waf-policy-psm-group.component.html',
})
export class WafPolicyPsmGroupComponent {
    @Input() group: WafPolicyPsmGroup;

    @Output() onEdit = new EventEmitter();
    @Output() onDelete = new EventEmitter();

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    getEnableState(): number {
        return this.group.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable(): void {
        this.group.enable = !this.group.enable;
    }

    /**
     * Click handler for deleting a whitelist rule.
     */
    handleDelete($event: MouseEvent): void {
        $event.stopPropagation();
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a whitelist rule.
     */
    handleEdit($event: MouseEvent): void {
        $event.stopPropagation();
        this.onEdit.emit();
    }
}
