/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  gslbPoolMemberDomainName
 * @param {GslbPoolMemberConfig.fqdn} fqdn - FQDN to be resolved.
 * @param {GslbPoolMemberConfig.ip} ipAddress
 * @requires FQDNDNSResolveControllerService
 * @author Alex Malitsky
 * @description
 *
 *     Resolves FQDN to ip address and shows appropriate input elements.
 */
angular.module('aviApp').component('gslbPoolMemberDomainName', {
    bindings: {
        fqdn: '=',
        ipAddress: '=',
    },
    controller: ['$scope', '$attrs', 'FQDNDNSResolveControllerService',
        function($scope, $attrs, FQDNDNSResolveControllerService) {
            return new FQDNDNSResolveControllerService($scope, $attrs);
        }],
    templateUrl: 'src/components/gslb/forms/gslb-pool-member-domain-name/' +
            'gslb-pool-member-domain-name.html',
});
