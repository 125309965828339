/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class EventChartGrid {
    constructor(eventChartService, moment, alertGridConfig, eventGridConfig,
                    Timeframe, EventCollection, AlertCollection, AnomalyCollection,
                    eventsContext, getGridMetricFieldConfig) {
        this.eventChartService = eventChartService;
        this.moment = moment;
        this.alertGridConfig = alertGridConfig;
        this.eventGridConfig = eventGridConfig;
        this.Timeframe = Timeframe;
        this.EventCollection = EventCollection;
        this.AlertCollection = AlertCollection;
        this.AnomalyCollection = AnomalyCollection;
        this.eventsContext = eventsContext;
        this.getGridMetricFieldConfig = getGridMetricFieldConfig;

        this.item = null;
        this.eventChangeHandler = this.eventChangeHandler.bind(this);
        this.eventChartService.on('update', this.eventChangeHandler);
    }

    $onInit() {
        if (this.item) {
            const itemId = this.item.poolId || this.item.id;

            this.configEvents = new this.EventCollection({
                params: {
                    filter: [
                            `co(all,"${itemId}")`,
                            'ne(internal,EVENT_INTERNAL)',
                            `co(event_pages,${this.eventsContext()})`,
                            'eq(module,CONFIG)',
                    ],
                },
                sortBy: '-report_timestamp',
            });

            this.systemEvents = new this.EventCollection({
                params: {
                    filter: [
                            `co(all,"${itemId}")`,
                            'ne(internal,EVENT_INTERNAL)',
                            `co(event_pages,${this.eventsContext()})`,
                            'ne(module,CONFIG)'],
                },
                sortBy: '-report_timestamp',
            });

            this.alerts = new this.AlertCollection({
                params: {
                    'related_refs.contains': itemId,
                    'event_pages.contains': this.eventsContext(),
                },
                sortBy: '-timestamp',
            });

            this.anomalies = new this.AnomalyCollection({
                itemId,
                itemPoolId: this.item.poolId,
                itemObjectName: this.item.objectName,
            });

            this.anomaliesGridConfig = {
                fields: [{
                    name: 'timestamp',
                    title: 'Timestamp',
                    template: '{{::row.data.timestamp | prettyTimeShort}}',
                }, {
                    name: 'title',
                    title: 'Type',
                    template: '{{::row.data.title}}',
                }, {
                    name: 'entity_name',
                    title: 'Entity',
                    template: '{{::row.data.entity_name }}',
                }, {
                    name: 'obj_id_type',
                    title: 'Entity Type',
                    template: '{{::row.data.obj_id_type | objectTypeFilter }}',
                }, this.getGridMetricFieldConfig({
                    require: 'anomaly_metric',
                    title: 'Metric',
                }), {
                    name: 'metric_value',
                    title: 'Metric Value',
                    template: '{{::row.data.value }}',
                }, {
                    name: 'predict_interval',
                    title: 'Prediction Interval',
                    template:
                        '{{::row.data.prediction_interval_low + " &mdash; " + ' +
                        'row.data.prediction_interval_high}}',
                }],
                collection: this.anomalies,
                layout: {
                    hideDisplaying: true,
                    hideSearch: true,
                    hideEditColumns: true,
                },
            };

            this.systemGridConfig = this.eventGridConfig(this.systemEvents);
            this.configGridConfig = this.eventGridConfig(this.configEvents);
            this.alertsGridConfig = this.alertGridConfig(this.alerts);
        }
    }

    $onDestroy() {
        this.eventChartService.off('update', this.eventChangeHandler);
    }

    /**
     * Returns grid collection based on event type.
     * @param {string} type - "anomalies", "system", "alerts", "config".
     * @returns {Collection}
     */
    getCollection(type) {
        switch (type) {
            case 'anomalies':
                return this.anomalies;
            case 'system':
                return this.systemEvents;
            case 'alerts':
                return this.alerts;
            case 'config':
                return this.configEvents;
        }
    }

    /**
     * Handles user event selection on event chart.
     * @param {string} type - "anomalies", "system", "alerts", "config".
     * @param {number} timestamp - Timestamp where event occurred.
     */
    eventChangeHandler(type, timestamp) {
        const collection = this.getCollection(type);

        collection.emptyData();

        const start = this.moment(timestamp);
        const stop = this.moment(timestamp);
        const selectedTimeframe = this.Timeframe.selected();
        const { step } = selectedTimeframe;

        if (type === 'anomalies') {
            const delta = step * 2;

            collection.loadRange(start.subtract(delta, 's'), stop.add(delta, 's'));
        } else {
            collection.loadRange(start.subtract(step, 's'), stop.add(step, 's'));
        }
    }
}

EventChartGrid.$inject = [
    'eventChartService',
    'moment',
    'alertGridConfig',
    'eventGridConfig',
    'Timeframe',
    'EventCollection',
    'AlertCollection',
    'AnomalyCollection',
    'eventsContext',
    'getGridMetricFieldConfig',
];

/**
 * @ngdoc component
 * @name eventChartGrid
 * @description
 *      Grid used to display events from eventChart component.
 */
angular.module('aviApp').component('eventChartGrid', {
    bindings: {
        item: '<',
    },
    controller: EventChartGrid,
    templateUrl: 'src/components/common/charts/event-chart/event-chart-grid.html',
});
