/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name serviceEngineGroupList
 * @description Component for the Service Engine Group page.
 */
class ServiceEngineGroupListController {
    constructor(CRUDGridConfig, $templateCache, SEGroupCollection, infraCloudState) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._$templateCache = $templateCache;
        this._SEGroupCollection = SEGroupCollection;
        this._infraCloudState = infraCloudState;
    }

    $onInit() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._SEGroupCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
            defaults: {
                cloud_ref: cloudRef,
            },
        });

        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'data.inventory.serviceengines.length',
            title: '# Service Engines',
            visibility: 'm',
        }, {
            name: 'data.config.max_se',
            title: 'Maximum Number of Service Engines',
            visibility: 'm',
        }, {
            name: 'data.inventory.virtualservices.length',
            title: '# Virtual Services',
            visibility: 'm',
        }, {
            name: 'data.config.max_vs_per_se',
            title: 'Maximum Number of Virtual Services per Service Engine',
        }, {
            name: 'ha_mode',
            title: 'HA Mode',
            template: '<span>{{row.data.config.ha_mode | seGroupHaMode}}</span>',
            visibility: 'm',
        }];

        this.gridConfig.singleactions = [{
            title: 'edit',
            class: 'icon-pencil-4',
            do(row) {
                row.edit();
            },
            hidden(row) {
                return !row.isEditable();
            },
        }];

        this.gridConfig.expandedContainerTemplate = require(
            './service-engine-group-list-expander.partial.html',
        );

        this.gridConfig.expanderDisabled = row => {
            const { inventory } = row.data;

            return _.isEmpty(inventory) ||
                    !inventory.serviceengines.length && !inventory.virtualservices.length;
        };

        this.handleCloudChange = this.handleCloudChange.bind(this);
        this._infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
        this._infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }

    /**
     * Handler for the 'cloudChange' event. Sets the SE Group collection based on the cloud and
     * loads it.
     */
    handleCloudChange() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();
        const { collection } = this.gridConfig;

        collection.setParams({
            'cloud_ref.uuid': slug,
        });

        collection.setDefaultItemConfigProps({
            cloud_ref: slug,
        });

        collection.load();
    }
}

ServiceEngineGroupListController.$inject = [
    'CRUDGridConfig',
    '$templateCache',
    'SEGroupCollection',
    'infraCloudState',
];

angular.module('aviApp').component('serviceEngineGroupList', {
    controller: ServiceEngineGroupListController,
    template:
        `<div class="infrastructure-detail">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-segroup-list segroup">
            </collection-grid>
        </div>`,
});
