/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name SystemConfigurationController
 * @description
 *
 *     Controller for a few tabs within Administration > Settings.
 */
angular.module('aviApp').controller('SystemConfigurationController', [
'AviModal', 'loadedSystemConfigService',
function(AviModal, systemConfig) {
    this.systemConfig = systemConfig;//preloaded by router

    //opens different modals to change system settings
    this.editSmtpConfig = () => {
        systemConfig.edit('adm-sysconf-edit-smtp');
    };

    this.editSystemAccessSettings = () => {
        systemConfig.edit('adm-sysconf-edit-access');
    };

    /**
     * Opens modal to validate email settings.
     */
    this.openValidateEmailModal = () => {
        AviModal.open('adm-email-validate', {
            type: 'smtp',
        });
    };

    /**
     * Returns a string of IP addresses, ranges, prefixes, and groups.
     * @param  {Object} obj - Object containing IP addrs, ranges, prefixes, and group_refs lists.
     * @return {string}
     * @public
     */
    this.getStringOfIps = function(obj) {
        if (!obj) {
            return 'Any';
        }

        let output = [];

        if (obj.addrs && obj.addrs.length) {
            output = output.concat(_.pluck(obj.addrs, 'addr'));
        }

        if (obj.ranges && obj.ranges.length) {
            output = output.concat(obj.ranges.map(function(range) {
                return `${range.begin.addr}-${range.end.addr}`;
            }));
        }

        if (obj.prefixes && obj.prefixes.length) {
            output = output.concat(obj.prefixes.map(function(prefix) {
                return `${prefix.ip_addr.addr}/${prefix.mask}`;
            }));
        }

        if (obj.group_refs && obj.group_refs.length) {
            output = output.concat(obj.group_refs.map(function(group) {
                return group.name();
            }));
        }

        return output.join(', ');
    };

    /**
     * Returns mail server and port string or N/A.
     * @returns {string}
     * @public
     */
    this.getSMTPServerCredsLabel = function() {
        let emailConfig,
            config;

        if ((config = systemConfig.getConfig()) &&
            (emailConfig = config['email_configuration']) &&
            emailConfig['mail_server_name']) {
            return `${emailConfig['mail_server_name']}:${emailConfig['mail_server_port']}`;
        } else {
            return 'N/A';
        }
    };
}]);
