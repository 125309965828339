/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @typedef {Object} TechSupportConfig
 * @description properties used by tech support collection's items
 * extends {@link TechSupportSharedStatus}
 * @property {string} created - when tech support tar ball was created
 * @property {string} duration
 * @property {string[]} errors
 * @property {string} resultSnippet - 'Completed successfully' or 'Completed with errors'
 * @property {string} resultStatus - 'success' or 'warning'
 * @property {FormattedSize} size
 */

const PERMISSION_TECHSUPPORT = 'PERMISSION_TECHSUPPORT';

function techSupportFactory(FileService, Auth) {
    /**
     * @alias module:services/TechSupport
     * @extends FileService
     * @private
     */
    class TechSupport extends FileService {
        /**
         * @override
         */
        isAllowed() {
            return Auth.isAllowed(PERMISSION_TECHSUPPORT, 'w');
        }
    }

    return TechSupport;
}

techSupportFactory.$inject = ['FileService', 'Auth'];

/**
 * @ngdoc service
 * @name TechSupport
 * @module services/TechSupport
 * @author Akul Aggarwal
 * @description
 *
 *     Extension of generic @FileService @Item for @TechSupportCollection.
 *
 */
angular.module('aviApp').factory('TechSupport', techSupportFactory);
