/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name hsInsights
 * @author Alex Malitsky
 * @param {VirtualService=} resolveVS
 * @param {Pool=} resolvePool
 * @param {ServiceEngine=} resolveSE
 * @description
 *
 *     Top most level component for new HealthScore insights page. Usually contains three
 *     smaller components - HS tree, list of metrics and chart underneath. Sometimes we also
 *     show an extra block of information which is hidden from the viewport unless user
 *     scrolls down the page.
 *
 *     Used by VS, Pool and SE details pages. Loaded Item is provided by ui-router.
 *
 **/
import './hs-insights.less';

class HsInsightsController {
    constructor($scope, HSGraph, ChartSync, HSGraphMetrics) {
        this.itemFields_ = [
                'config',
                'health',
                'alert',
                'config_events',
                'system_events',
                'alerts',
                'faults',
        ];

        this.scope_ = $scope;
        this.HSGraph_ = HSGraph;
        this.HSGraphMetricsUpdateEventName_ = HSGraphMetrics.GROUP_UPDATE_EVENT;
        /** @type {?Metric} */
        this.healthMetric = null;
        /** @type {?Metric[]} */
        this.sideMetrics = null;
        /** @type {?Metric[]} */
        this.healthStackMetrics = null;
        /** @type {string} */
        this.bottomChartType = 'stack';
        /** @type {number} */
        this.bottomChartAvg = 0;

        // TODO: Dynamically generate title based on node selected? Related AV-18461
        this.sideChartsTitle = 'Top Factors Affecting Health Score';
        this.bottomChartTitle = 'Health Score';

        this.chartSync = new ChartSync();

        this.healthMetricUpdate = this.healthMetricUpdate.bind(this);
    }

    $onInit() {
        this.item = this.resolvePool || this.resolveVS || this.resolveSE;

        this.graph = new this.HSGraph_({
            rootItem: this.item,
            loadOnCreate: true,
        });

        const { graph } = this;

        graph.on('activeNodeChanged', nodeId => {
            this.bottomChartType = !nodeId ? 'stack' : 'line';
        });

        graph.metrics.on(this.HSGraphMetricsUpdateEventName_, (updateType, metricsList) => {
            switch (updateType) {
                case 'child':
                    this.sideMetrics = metricsList.concat();
                    break;
                case 'bottom':
                    [this.healthMetric] = metricsList;
                    this.healthMetricUpdate(null, this.healthMetric);
                    this.healthMetric.on('seriesUpdate', this.healthMetricUpdate);
                    this.healthMetric.on('seriesListUpdate', this.healthMetricUpdate);
                    break;
                case 'graph':
                    this.healthStackMetrics = metricsList.slice(1);
                    break;
            }
        });
    }

    healthMetricUpdate(updated, metric) {
        const [series] = metric.getSeries();

        if (series) {
            this.bottomChartAvg = series.getValue('avg') || 0;
        }
    }

    $onDestroy() {
        const { item, itemFields_, graph, healthMetric } = this;

        item.async.stop(true);
        item.removeLoad(itemFields_);

        graph.destroy();

        this.chartSync.destroy();

        if (healthMetric) {
            healthMetric.unbind('seriesUpdate', this.healthMetricUpdate);
            healthMetric.unbind('seriesListUpdate', this.healthMetricUpdate);
        }
    }
}

HsInsightsController.$inject = [
        '$scope',
        'HSGraph',
        'ChartSync',
        'HSGraphMetrics',
];

angular.module('aviApp').component('hsInsights', {
    bindings: {
        resolveVS: '<',
        resolvePool: '<',
        resolveSE: '<',
    },
    controller: HsInsightsController,
    templateUrl: 'src/components/applications/hs-insights/hs-insights.html',
});
