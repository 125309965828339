/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name aviKnob
 * @description Dial chart by jQuery 'jquery-knob' plugin.
 * @see {@link https://github.com/aterrien/jQuery-Knob}
 */
angular.module('aviApp').directive('aviKnob', function() {
    return {
        restrict: 'A',
        scope: {
            data: '=',
            width: '=',
            height: '=',
            min: '=',
            max: '=',
            color: '@',
        },
        link(scope, element, attrs) {
            element.css('pointer-events', 'none');
            scope.$watch('data', function() {
                const knob = $('<input>')
                    .val(scope.data || 0)
                    .data('min', scope.min || 0)
                    .data('max', scope.max || 100)
                    .data('width', scope.width || 100)
                    .data('height', scope.height || 100)
                    .knob({
                        readOnly: !_.isUndefined(attrs.readonly),
                        fgColor: scope.color,
                    });

                element.empty().append(knob);

                if (!_.isUndefined(scope.data)) {
                    element.find('input').val(`${scope.data}%`);
                }
            });
        },
    };
});
