/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name snmpV3User
 * @param {Object} config - Config object where SnmpV3UserParams user is configured.
 * @description
 *      Configures SnmpV3UserParams object inside "config" object "user" property.
 */
angular.module('aviApp').component('snmpV3User', {
    bindings: {
        config: '<',
    },
    controller: ['Schema', function SnmpV3UserController(Schema) {
        this.Schema = Schema;
    }],
    templateUrl: 'src/components/administration/snmp-v3-user/snmp-v3-user.html',
});
