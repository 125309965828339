/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './update-card.component.less';

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @mixes module:avi/upgrade.updateCardBindings
 * @description Presently used just to add class.
 */
class UpdateCardController {
    constructor($element) {
        this.$element_ = $element;
    }

    /** @override */
    $onInit() {
        this.$element_.addClass('update-card');
    }
}

UpdateCardController.$inject = [
    '$element',
];

const componentTag = 'update-card';
const templateUrl = 'src/components/pages/administration/controller/system-update/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @mixin updateCardBindings
 * @memberOf module:avi/upgrade
 * @property {string} headerText - title to display in header of card
 */

/**
 * @mixin updateCardTransclusions
 * @memberOf module:avi/upgrade
 * @property {HTMLElement} headerIcon - icon placed in top left of card
 * @property {HTMLElement=} headerButton - button placed in top right of card
 * @property {HTMLElement} Content - component with the primary content of the card
 */

/**
 * @name updateCardComponent
 * @memberOf module:avi/upgrade
 * @author Akul Aggarwal
 * @property {module:avi/upgrade.updateCardBindings} bindings
 * @property {module:avi/upgrade.UpdateCardController} controller
 * @property {module:avi/upgrade.updateCardTransclusions} transclude
 * @description Wrapper component (with basic controller) for upgrade2.0 page sectionals at top.
 */
angular.module('avi/upgrade').component('updateCard', {
    bindings: {
        headerText: '<',
    },
    controller: UpdateCardController,
    transclude: {
        headerIcon: 'updateCard.headerIcon',
        headerButton: '?updateCard.headerButton',
        content: 'updateCard.content',
    },
    templateUrl,
});
