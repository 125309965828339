/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
//TODO reload it at least after cloud creation/deletion since list of default objects changes
function defaultValuesFactory(
    $q,
    Base,
    propLookup,
) {
    /**
     * @alias module:services/defaultValues.DefaultValues
     * @extends module:avi/dataModel.Base
     */
    class DefaultValues extends Base {
        /**
         * @constructor
         * @param {Object=} args
         */
        constructor(args = {}) {
            super(args);

            /**
             * Hash of default object configurations by object type.
             * @type {{string: Object}}
             * @protected
             */
            this.defaults_ = {};

            /**
             * Hash of 'system' object refs by object type. Important details:
             *
             * Object types belonging to cloud context have name duplicates (combination per
             * every cloud), examples are vrfcontext and serviceengineggroup.
             *
             * Doesn't contain tenant's 'system' objects, such as "cloned-written" profiles.
             * Only top level 'system' objects are present.
             *
             * @type {{string: string[]}}
             * @protected
             */
            this.systemObjects_ = {};

            /**
             * Flag to figure whether defaults have been loaded.
             * @type {boolean}
             * @protected
             */
            this.isLoaded_ = false;

            /**
             * Promise set on default-values load event. Set to null once loaded.
             * @type {ng.$q.promise|null}
             * @protected
             */
            this.loadPromise_ = null;
        }

        /**
         * Fetches default object/item settings and system objects list from the server.
         * If already loaded will resolve the promise right away.
         * @param {boolean=} force - If passed will reload defaults.
         * @returns {ng.$q.promise} - To be resolved with 'true'.
         */
        load(force = false) {
            if (this.loadPromise_) {
                return this.loadPromise_;
            }

            if (!force && this.isLoaded_) {
                return $q.when(true);
            }

            const promise =
                this.request('get', '/api/default-values?include_name')
                    .then(({ data }) => {
                        this.transformAfterLoad_(data);

                        return true;
                    });

            this.loadPromise_ = promise;

            promise
                .catch(() => this.removeData())
                .finally(() => this.loadPromise_ = null);

            return promise;
        }

        /**
         * Parses the initial-data API response and stashes it into right places.
         * @param {Object} data
         * @protected
         */
        transformAfterLoad_(data) {
            const { cloud: cloudDefaultConfig } = data;

            delete cloudDefaultConfig['cloudstack_configuration'];
            delete cloudDefaultConfig['mesos_configuration'];

            this.defaults_ = data;
            this.systemObjects_ = data['default_refs'];

            delete data['default_refs'];
            delete data['default'];//same as above, but names only

            this.isLoaded_ = true;
        }

        /**
         * Removes data from the service.
         */
        removeData() {
            this.cancelRequests();
            this.defaults_ = {};
            this.systemObjects_ = {};
            this.isLoaded_ = false;
            this.loadPromise_ = null;
        }

        /**
         * Method to get a system object ref by object type and name.
         * Throws an error if full duplicates are found. See {@link this.systemObjects_} for
         * details.
         * @param {Item.objectName} type
         * @param {string} name
         * @returns {Item.id} - empty string if not found
         */
        //TODO introduce the black list of types with possible name duplicates: vrfcontext and
        // serviceenginegroup for sure
        getSystemObjectRefByName(type, name) {
            const { systemObjects_: hash } = this;

            if (name && type in hash) {
                const
                    list = _.filter(hash[type], ref => ref.name() === name),
                    { length } = list;

                if (length === 1) {
                    return list[0];
                }

                if (length > 1) {
                    throw new Error(
                        `"${type}" with name "${name}" has duplicates in system objects list`,
                    );
                }
            }

            return '';
        }

        /**
         * Checks whether passed object type and id combination is representing a
         * 'system' object. Doesn't work for system objects 'nested' under the tenant, see
         * {@link this.systemObjects_} for details.
         * @param {Item.objectName} type
         * @param {Item.id} id
         * @returns {boolean}
         */
        isSystemObject(type, id) {
            const { systemObjects_: hash } = this;

            if (id && type in hash) {
                return _.some(hash[type], ref => ref.slug() === id);
            }

            return false;
        }

        /**
         * Returns default object configuration by object type.
         * @param {Item.objectName} type
         * @returns {Object|null}
         */
        getDefaultItemConfigByType(type) {
            type = type && type.toLowerCase();

            if (type && type in this.defaults_) {
                return angular.copy(this.defaults_[type]);
            }

            return null;
        }

        /**
         * Returns default configuration by property path starting with object type.
         * @param {string} path - Whole property path, ex: 'cloud.aws_config.encryption'.
         * @returns {*} - undefined if not found.
         */
        getDefaultItemConfig(path) {
            const config = propLookup(path, this.defaults_);

            return angular.copy(config);
        }
    }

    return new DefaultValues();
}

defaultValuesFactory.$inject = [
    '$q',
    'Base',
    'propLookup',
];

/**
 * @ngdoc service
 * @name defaultValues
 * @module services/defaultValues
 * @desc
 *
 *     Instance loads default objects settings (property values) as well as list of 'system'
 *     objects and provides some convenience methods to work with em.
 *     Generally loaded only once as ui-router dependency in non-blocking fashion.
 *
 */
angular.module('core.vantage.avi').factory('defaultValues', defaultValuesFactory);
