/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class VirtualserviceVipNetworkController {
    constructor(
        Schema,
        schemaService,
        SubnetListNetworkCollection,
        SubnetListNetwork,
        getSubnetString,
        dropDownUtils,
    ) {
        this.Schema = Schema;
        this._SubnetListNetworkCollection = SubnetListNetworkCollection;
        this._SubnetListNetwork = SubnetListNetwork;
        this.getSubnetString = getSubnetString;
        this.dropDownUtils_ = dropDownUtils;
        this.schemaService_ = schemaService;

        /**
         * Options for IPv4 dropdown.
         * @type {string[]}
         */
        this.subnetOptions = [];

        /**
         * Options for IPv6 dropdown.
         * @type {string[]}
         */
        this.subnet6Options = [];

        /**
         * Options for Floating subnet IPV4 dropdown.
         * @type {string[]}
         */
        this.floatingSubnetOptions = [];

        /**
         * Options for Floating subnet IPV6 dropdown.
         * @type {string[]}
         */
        this.floatingSubnet6Options = [];

        /**
         * Map string IP Prefix to associated VIMgrIPSubnetRuntime instance.
         * @type {Object<string, VIMgrIPSubnetRuntime>}
         */
        this.subnetHash = {};

        this.subnets = [];
    }

    $onInit() {
        this.busy = false;

        const vsVipConfig = this.vsVip.getConfig();

        this.networkConfig = this.getNetworkConfig();

        /** Options for IP Address version dropdown.
         * @type {EnumValue[]}
         */
        this.IpAddressVersions = this.schemaService_
            .getEnumValues('IpAddressVersions');

        if (this.cloud.getVtype() === 'CLOUD_GCP') {
            this.IpAddressVersions =
                this.IpAddressVersions.filter(ipAddrObj => ipAddrObj.value === 'V4_ONLY');
        }

        const networkCollectionParams = {
            auto_allocate_only: true,
            cloud_uuid: this.cloud.id,
            fip_capable: this.vip.floatingIpIsEnabled() || undefined,
        };

        const { vrf_context_ref: vrfContextRef } = vsVipConfig;

        if (vrfContextRef) {
            networkCollectionParams.vrf_context_uuid = vrfContextRef.slug();
        }

        if (this.networkConfig) {
            const vipConfig = this.vip.getConfig();

            networkCollectionParams.preferred_nw_type = vipConfig.auto_allocate_ip_type;

            const { network_ref: netRef } = this.networkConfig;

            //loading network name and list of subnets
            if (netRef) {
                const networkSlug = netRef.slug();
                const { id: cloudId } = this.cloud;

                const network = new this._SubnetListNetwork({
                    id: networkSlug,
                    cloudId,
                    fip_capable: this.vip.floatingIpIsEnabled() || undefined,
                });

                this.busy = true;

                network.load()
                    .then(() => {
                        this.parseSubnets_(network.getSubnets());
                        this.networkConfig['network_ref'] = network.getRef();
                    })
                    .finally(() => {
                        this.busy = false;
                        network.destroy();
                    });
            }
        }

        this.subnetListNetworkCollection = new this._SubnetListNetworkCollection({
            params: networkCollectionParams,
        });
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.floatingIpEnabled) {
            const { currentValue: floatingIpEnabled } = changes.floatingIpEnabled;

            this.subnetListNetworkCollection.setParams({
                fip_capable: floatingIpEnabled || undefined,
            });
        }
    }

    /**
     * Returns usable network config object.
     * @return {Object} - VIP config object.
     */
    getNetworkConfig() {
        const { vip, cloud } = this;
        const vipConfig = vip.getConfig();

        if (cloud.hasIpamProviderProfile()) {
            if (!angular.isObject(vipConfig.ipam_network_subnet)) {
                vipConfig.ipam_network_subnet = {};
            }

            return vipConfig.ipam_network_subnet;
        }

        return vipConfig;
    }

    /**
     * Checks if selected Auto Allocation IP Type matches specified IP Type.
     * @param {string} type - V4 or V6.
     * @return {boolean}
     */
    isIPTypeSelected(type) {
        if (angular.isString(type)) {
            const { auto_allocate_ip_type: ipType } = this.vip.getConfig();

            if (angular.isString(ipType)) {
                return ipType.toLowerCase().indexOf(type.toLowerCase()) > -1;
            }
        }

        return false;
    }

    /**
     * Handles "Auto Allocation IP Type" dropdown selection change.
     * Clears the existing subnet dropdown options and reloads them based on the
     * selected Auto AllocateIP type.
     */
    ipTypeChange() {
        const { auto_allocate_ip_type: autoAllocateIpType } = this.vip.getConfig();

        this.subnetListNetworkCollection.setParams({
            preferred_nw_type: autoAllocateIpType,
        });
        this.handleSubnetChange();
        this.subnet6Options = [];
        this.subnetOptions = [];
        this.floatingSubnetOptions = [];
        this.floatingSubnet6Options = [];
        this.networkConfig = this.getNetworkConfig();

        switch (autoAllocateIpType) {
            case 'V4_V6':
                this.subnetOptions = this.getSubnetOptions();
                this.subnet6Options = this.getSubnetOptions('V6');
                break;

            case 'V4_ONLY':
                this.subnetOptions = this.getSubnetOptions();
                break;

            case 'V6_ONLY':
                this.subnet6Options = this.getSubnetOptions('V6');
                break;
        }
    }

    /**
     * Handles "Network for VIP Address Allocation" dropdown change.
     * @param  {SubnetListNetwork} network - Network object. Undefined when selected item
     *     got removed.
     */
    handleNetworkChange(network) {
        this.vip.setIpAddr();
        this.vip.setIpAddr(undefined, 'ip6_address');

        this.networkConfig.subnet6 = undefined;
        this.networkConfig.subnet = undefined;
        this.networkConfig.floating_subnet_uuid = undefined;

        this.parseSubnets_(network.getSubnets());
    }

    /**
     * Parses subnets for dropdown selectors.
     * It also parses the floating subnets for dropdowns if networkConfig exists.
     * @param {Subnet[]|undefined} subnets
     * @protected
     */
    parseSubnets_(subnets = []) {
        this.subnetHash = {};
        this.subnets = subnets;

        this.subnets.forEach(subnet => {
            const subnetString = this.getSubnetString(subnet.prefix);

            this.subnetHash[subnetString] = subnet;
        });

        this.subnetOptions = this.getSubnetOptions();
        this.subnet6Options = this.getSubnetOptions('V6');

        if (this.networkConfig) {
            this.floatingSubnetOptions = this.getFloatingSubnetOptions();
            this.floatingSubnet6Options = this.getFloatingSubnetOptions('V6');
        }
    }

    /**
     * Handles Subnet dropdown change event.
     * @param {Object} selected - Dropdown item.
     */
    handleSubnetChange(selected) {
        if (angular.isObject(selected)) {
            const subnet = this.subnetHash[selected.value];
            const { prefix, uuid } = subnet;

            this.vip.setVipSubnet(prefix, uuid, this.cloud.hasIpamProviderProfile());
        } else {
            this.vip.clearVipSubnet();
            this.networkConfig.floating_subnet_uuid = undefined;
        }

        if (this.floatingIpEnabled) {
            this.floatingSubnetOptions = this.getFloatingSubnetOptions();
            this.floatingSubnet6Options = this.getFloatingSubnetOptions('V6');
        }
    }

    /**
     * Returns the list of floating ip_subnets for a subnet.
     * @param {IpAddrPrefix} subnet - Subnet IP Prefix.
     * @return {FloatingIpSubnet[]} - floatingip_subnet objects.
     */
    getFloatingSubnets(subnet) {
        if (subnet) {
            const subnetString = this.getSubnetString(subnet);

            if (subnetString in this.subnetHash) {
                return this.subnetHash[subnetString].floatingip_subnets;
            }
        }
    }

    /**
     * Returns a string representation of a subnet name and prefix.
     * @param {IpAddrPrefix} subnet - Subnet object with name and prefix.
     * @return {string}
     */
    getSubnetName(subnet) {
        const { name, prefix } = subnet;

        return `${name ? `${name} - ` : ''}${this.getSubnetString(prefix)}`;
    }

    /**
     * Returns dropdown options for subnet selector.
     * @param {string} [type='V4'] - IpAddrType enum value.
     * @return {DropDownOption[]} - Dropdown directive option item data objects.
     */
    getSubnetOptions(type = 'V4') {
        const ret = [];

        if (Array.isArray(this.subnets)) {
            this.subnets.forEach(subnet => {
                const { prefix } = subnet;

                if (prefix.ip_addr.type === type) {
                    const label = this.getSubnetName(subnet);

                    ret.push(this.dropDownUtils_.createOption(
                        this.getSubnetString(prefix),
                        label,
                    ));
                }
            });
        }

        return ret;
    }

    /**
     * Returns dropdown options for floating subnet selector.
     * @param {string} [type='V4'] - IpAddrType enum value.
     * @return {DropDownOption[]} - Dropdown directive option item data objects.
     */
    getFloatingSubnetOptions(type = 'V4') {
        let floatingSubnetOptions = [];
        const { networkConfig } = this;

        let subnetList;

        if (type === 'V4') {
            subnetList = networkConfig.subnet;
        } else {
            subnetList = networkConfig.subnet6;
        }

        const floatingSubnets = this.getFloatingSubnets(subnetList);

        if (floatingSubnets) {
            floatingSubnetOptions = floatingSubnets.map(subnet => {
                const { uuid } = subnet;
                const label = this.getSubnetName(subnet);

                return this.dropDownUtils_.createOption(
                    uuid,
                    label,
                );
            });
        }

        return floatingSubnetOptions;
    }

    /** @override */
    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
    }
}

VirtualserviceVipNetworkController.$inject = [
    'Schema',
    'schemaService',
    'SubnetListNetworkCollection',
    'SubnetListNetwork',
    'getSubnetString',
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name virtualserviceVipNetwork
 * @author alextsg, chitra
 * @description Component to handle network selection for VSes in optional-auto-allocate clouds.
 * @param {VipConfig} vip
 * @param {Cloud} cloud
 * @param {boolean} floatingIpEnabled - Boolean value for the Assign Floating IP for
 *     External Client Access checkbox
 */
angular.module('aviApp').component('virtualserviceVipNetwork', {
    controller: VirtualserviceVipNetworkController,
    bindings: {
        vip: '<',
        vsVip: '<',
        cloud: '<',
        floatingIpEnabled: '<',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-network/virtualservice-vip-network.html',
});
