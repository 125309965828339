/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name dnsPolicyGridActionColumn
 * @description Component for displaying the action configuration in a grid field.
 * @param {DnsRuleConfig} rule
 */
class DnsPolicyGridActionColumnController {
    /**
     * Returns true is the log is enabled, otherwise returns false.
     * @return {boolean}
     */
    isLogEnabled() {
        return this.rule.getConfig().log;
    }

    /**
     * Returns all actions in the rule.
     * @return {ActionConfigItem[]}
     */
    getActions() {
        return this.rule.getActions();
    }
}

angular.module('aviApp').component('dnsPolicyGridActionColumn', {
    bindings: {
        rule: '<',
    },
    controller: DnsPolicyGridActionColumnController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-grid-action-column/dns-policy-grid-action-column.html',
});
