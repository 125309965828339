/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './newline-converted-textarea.component.less';

const componentName = 'newline-converted-textarea';

class NewlineConvertedTextarea {
    constructor(Regex) {
        this.Regex = Regex;

        /**
         * Toggles between wordrwap and non-wordrap version in read-only view.
         * @type {boolean}
         */
        this.showPureModel = true;
    }

    $onInit() {
        this.ngModelCtrl.$parsers.push(this.modelUpdate_.bind(this));
        this.ngModelCtrl.$render = this.updateReadOnly_.bind(this);
    }

    /**
     * Updates true model value to be saved.
     * @param {string} viewVal - Value held/shown in read/write window.
     * @returns {string} - Model value to be saved.
     * @protected
     */
    modelUpdate_(viewVal) {
        const
            pattern = new RegExp(this.Regex.carriageReturnLineFeedWEscape, 'g'),
            replaceVal = '\r\n';

        return viewVal.replace(pattern, replaceVal);
    }

    /**
     * Updates value to be shown on right read-only window.
     * Same as actual model value to be shown, but allows toggle between wordwrap:on/off.
     * @protected
     */
    updateReadOnly_() {
        let result = '';
        const { $modelValue: modelVal } = this.ngModelCtrl;

        if (this.showPureModel) {
            result = modelVal;
        } else {
            const
                pattern = /\r\n/g,
                replaceVal = '\r\n<br/>';

            result = modelVal.replace(pattern, replaceVal);
        }

        const
            { Regex: regex } = this,
            patternCr = new RegExp(regex.carriageReturn, 'g'),
            patternLf = new RegExp(regex.lineFeed, 'g'),
            replaceValCr = '<span class="newline-converted-textarea__emphasized">\\r</span>',
            replaceValLf = '<span class="newline-converted-textarea__emphasized">\\n</span>';

        if (result) {
            result = result.replace(patternCr, replaceValCr);
            result = result.replace(patternLf, replaceValLf);
        }

        this.readOnlyOutput = result || '';
    }

    /**
     * Toggles wordwrap:on/off.
     */
    toggleReadOnlyView() {
        this.showPureModel = !this.showPureModel;
        this.ngModelCtrl.$render();
    }

    /**
     * Triggers changes when user changes value in left read/write window.
     */
    onViewValChange() {
        this.ngModelCtrl.$setViewValue(this.ngModelCtrl.$viewValue);
        this.ngModelCtrl.$render();
    }
}

NewlineConvertedTextarea.$inject = [
    'Regex',
];

/**
 * @ngdoc component
 * @name NewlineConvertedTextarea
 * @description
 *     Allows user to enter text free-form into a box, and see real time in
 *     adjacent box the computed value which will be sent through API.
 *     Primary use is to deal with a browser's or OS's manipulation
 *     of stored strings.
 *
 *     Note: Has been tested and seen to work on Chrome and Firefox, on both
 *     Windows and OSX.
 *     Has been tested and seen to work on IE-Edge on Windows.
 */
angular.module('aviApp').component('newlineConvertedTextarea', {
    require: {
        ngModelCtrl: 'ngModel',
    },
    // used for inserting <label> component
    transclude: true,
    controller: NewlineConvertedTextarea,
    templateUrl: `src/components/forms/inputs/${componentName}/${componentName}.component.html`,
});
