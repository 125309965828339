/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name modalWizardTab
 * @description
 *     Component for a tab in the modalContainer component.
 * @param {string} tabTitle - Name of the tab.
 * @param {boolean} hideTab - True if the tab should be hidden.
 * @param {Object} helpPopver - Contains 'anchor' and 'position' properties to be passed to the
 *     subtab-nav component.
 */
class ModalWizardTabController {
    $onInit() {
        this.modalContainerCtrl.addModalWizardTab(this);
    }
}

angular.module('aviApp').component('modalWizardTab', {
    controller: ModalWizardTabController,
    transclude: true,
    require: {
        modalContainerCtrl: '^modalContainer',
    },
    bindings: {
        tabTitle: '@',
        hideTab: '<',
        helpPopover: '<',
    },
    template:
            '<div ng-if="$ctrl.modalContainerCtrl.isSelected($ctrl)" ng-transclude></div>',
});
