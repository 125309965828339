/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const pingAccessAgentProfileCollectionFactory = (Collection, PingAccessAgentProfile) => {
    class PingAccessAgentProfileCollection extends Collection {
    }

    angular.extend(PingAccessAgentProfileCollection.prototype, {
        objectName_: 'pingaccessagent',
        windowElement_: 'ping-access-agent-modal',
        itemClass_: PingAccessAgentProfile,
    });

    return PingAccessAgentProfileCollection;
};

pingAccessAgentProfileCollectionFactory.$inject = [
    'Collection',
    'PingAccessAgentProfile',
];

/**
 * @ngdoc factory
 * @name  PingAccessProfileCollection
 * @description  Collection of {PingAccessAgent} items.
 */
angular.module('aviApp')
    .factory('PingAccessAgentProfileCollection', pingAccessAgentProfileCollectionFactory);
