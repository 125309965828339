/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function fileServiceFactory(Item) {
    /**
     * @alias module:services/FileService
     * @extends Item
     * @private
     */
    class FileService extends Item {
        /**
         * @override
         */
        getIdFromData_(data) {
            const { config } = data;

            if (!config || !config.url) {
                console.error('missing config or url');

                return '';
            }

            const
                { url } = config,
                apiIndex = url.indexOf('?');

            return url.slice(apiIndex);
        }
    }

    FileService.prototype.objectName = 'fileservice';

    return FileService;
}

fileServiceFactory.$inject = ['Item'];

/**
 * @ngdoc service
 * @name FileService
 * @module services/FileService
 * @author Akul Aggarwal
 * @description
 *
 *     Generic File Service item to generate reports etc.
 *
 */
angular.module('aviApp').factory('FileService', fileServiceFactory);
