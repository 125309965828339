/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('TenantInventoryDataTransformer', ['ListDataTransformer',
function(ListDataTransformer) {
    function TenantInventoryDataTransformer(args) {
        TenantInventoryDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(TenantInventoryDataTransformer, ListDataTransformer);

    TenantInventoryDataTransformer.prototype.processResponse = function(resp, request) {
        const inventoryFields = ['users', 'non-admin', 'tenant-admin'];

        resp = this.responseListOffsetControl_(resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results)) {
            const results = resp.data.results.map(function(tenant) {
                return {
                    config: _.omit(tenant, inventoryFields),
                    inventory: _.pick(tenant, inventoryFields),
                };
            });

            resp.data.results = results;
        }

        return resp;
    };

    return TenantInventoryDataTransformer;
}]);
