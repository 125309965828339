/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './match-config.less';

/**
 * @alias module:match/matchConfig
 * @private
 */
class MatchConfigComponent {
    constructor(dropDownUtils) {
        this.dropDownUtils_ = dropDownUtils;

        /**
         * Property used as the ngModel for the match selection dropdown.
         * @type {Object}
         */
        this.selectedMatch = null;

        /**
         * Array of dropdown options for matches.
         * @type {Object[]}
         */
        this.matches = [];

        /**
         * Array of dropdown value strings to be filtered from the dropdown options for matches.
         * @type {string[]}
         */
        this.hiddenMatches = [];
    }

    $onInit() {
        this.matches = this.getMatchTypeOptions_();
        this.configuredMatches = this.getConfiguredMatches_();
        this.hiddenMatches = this.getHiddenMatches_();
    }

    /**
     * Returns a list of match dropdown options.
     * @protected
     * @returns {DropDownOption[]}
     */
    getMatchTypeOptions_() {
        const supportedMatches = this.supportedMatches || this.match.fields;
        const matchesWithProps = this.match.mapMatchFieldProps(supportedMatches);

        return this.dropDownUtils_.createOptionsFromEnumProps(matchesWithProps);
    }

    /**
     * Returns a list of configured matches.
     * @returns {DropDownOption[]}
     */
    getConfiguredMatches_() {
        return this.matches.filter(({ value }) => this.match.hasMatchByField(value));
    }

    /**
     * Returns a list of match dropdown options to hide. Matches that have already been selected
     * should be hidden.
     * @returns {string[]}
     */
    getHiddenMatches_() {
        return _.pluck(this.configuredMatches, 'value');
    }

    /**
     * Handler when the user has selected a dropdown option. Adds the match object to the config and
     * adds it to the list of hidden matches.
     * @param {DropDownOption} selectedMatch - Selected match dropdown option.
     */
    handleSelectMatch(selectedMatch) {
        const { value: matchFieldName } = selectedMatch;

        this.match.addMatch(matchFieldName);
        this.configuredMatches = this.getConfiguredMatches_();
        this.hiddenMatches = this.getHiddenMatches_();
        this.selectedMatch = null;
    }

    /**
     * Handler when the user wants to remove a match.
     * @param {string} matchFieldName - Field name of the match to remove.
     */
    handleCloseMatch(matchFieldName) {
        this.match.removeMatch(matchFieldName);
        this.configuredMatches = this.getConfiguredMatches_();
        this.hiddenMatches = this.getHiddenMatches_();
    }

    /**
     * Returns true to hide the match dropdown.
     * @returns {boolean}
     */
    hideMatchDropdown() {
        return this.hiddenMatches.length === this.matches.length;
    }
}

MatchConfigComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  matchConfig
 * @module match/matchConfig
 * @description Component for configuring a Match Extendable Config Item.
 * @param {MatchExtendableConfigItem} match
 * @param {string[]} [supportedMatches=] - List of field names as supported matches. Optional, if
 *     the message containing this MatchExtendableConfigItem does not support all available matches.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('matchConfig', {
    controller: MatchConfigComponent,
    bindings: {
        match: '<',
        supportedMatches: '<?',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/match-config.component.html',
});
