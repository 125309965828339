/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/application/vs-app-map.less';

angular.module('aviApp').controller('VsAppMapController', ['$scope', 'myAccount',
'MicroServiceGraphFactory', 'UnitLabels', 'ServerCollection', 'AppMapVSCollection',
function($scope, myAccount, MicroServiceGraphFactory, UnitLabels, ServerCollection,
         AppMapVSCollection) {
    const virtualService = $scope.VirtualService;

    this.ui = {
        selectedMetricUnitLabel: '', //metric unit label near dropdown
    };

    $scope.myAccount = myAccount;

    /**
     * ngClick handler to show the modal window for 'secure app'. Need to throttle it to avoid
     * double clicks and pass two metric parameters from the current page.
     * @type {Function}
     */
    this.secureApp = _.throttle(function() {
        virtualService.edit('app-vs-secure-app', {
            graphMetric: `source_insights.${
                myAccount.uiProperty.vsAppMap.graphMetric}`,
            mValuesAggregation: myAccount.uiProperty.valuesToDisplay,
        });
    }, 1999, { trailing: false });

    /**
     * Opens/closes corresponding divs in a sidebar and saves its displaying setting.
     * @param {string} propertyName - showPoolServers|showClientApps|showServerApps
     * @public
     */
    this.toggleUIProperty = function(propertyName) {
        const appMapUIProperty = myAccount.uiProperty.vsAppMap;

        if (appMapUIProperty && 'sidebar' in appMapUIProperty) {
            appMapUIProperty.sidebar[propertyName] = !appMapUIProperty.sidebar[propertyName];
            myAccount.saveUIProperty();
        }
    };

    /**
     * Metrics dictionary.
     * @type {Object[]} - Array of available metrics to be present on the
     *    edges of the graph.
     * @property {string} label
     * @property {string} value
     */
    this.graphMetrics = [{
        label: 'Throughput',
        value: 'avg_bandwidth',
        unitLabel: 'KILO_BITS_PER_SECOND',
    }, {
        label: 'Complete Requests',
        value: 'avg_complete_responses',
        unitLabel: 'PER_SECOND',
    }, {
        label: 'Total Requests',
        value: 'avg_total_requests',
        unitLabel: 'PER_SECOND',
    }, {
        label: 'Errors',
        value: 'avg_error_responses',
        unitLabel: 'PER_SECOND',
    }, {
        label: 'Latency',
        value: 'avg_client_end2end_latency',
        unitLabel: 'MILLISECONDS',
    }, {
        label: 'New Connections',
        value: 'avg_complete_conns',
        unitLabel: 'PER_SECOND',
    }, {
        label: 'Open Connections',
        value: 'max_open_conns',
        unitLabel: 'METRIC_COUNT',
    }];

    /**
     * Hash of metric unit labels. Metric is a key and value has a unit label text.
     * @type {{string:string}}
     * @inner
     */
    const metricUnitsHash = {};

    this.graphMetrics.forEach(metric => metricUnitsHash[metric.value] = metric.unitLabel);

    /**
     * Updates a unit label (this.ui.selectedMetricUnitLabel) for currently selected graph's
     * metric.
     * @inner
     */
    const updateSelectedMetricUnitLabel = metricName => {
        let res = '';

        if (metricName && metricName in metricUnitsHash &&
            metricUnitsHash[metricName] in UnitLabels) {
            res = UnitLabels[metricUnitsHash[metricName]].units;
        }

        this.ui.selectedMetricUnitLabel = res;
    };

    this.appMap = new MicroServiceGraphFactory({
        params: { microservice_levels: 1 },
    });

    /* Two sidebar collections */
    this.clientsCollection = new AppMapVSCollection({
        msGraph: this.appMap,
        nodeType: 'client',
    });

    this.serversCollection = new AppMapVSCollection({
        msGraph: this.appMap,
        nodeType: 'server',
    });

    /**
     * VS update event handler. We need to redraw the chart once VS has been updated or
     * Secure App has been switched on or off. Also we want to reload VS to get policy instance
     * updated.
     * @inner
     */
    //FIXME remove VS reload once policies get updates after save
    const onVSUpdate = () => {
        this.appMap.restart();
        virtualService.load();
    };

    /**
     * Launches the graph fetching and updates. Called on initial load and after selection of a
     * different metric by the {@link Dropdown}.
     */
    this.runAppMap = function() {
        const params = {//need to rewrite the ones we could have before
            dimension_aggregation: undefined,
            limit: undefined,
        };

        this.appMap.nodeGroups = true;
        this.appMap.stop(true);

        if (myAccount.uiProperty.valuesToDisplay === 'current') {
            params.limit = 1;
        } else {
            params.dimension_aggregation = myAccount.uiProperty.valuesToDisplay;
        }

        params.metric_id = `source_insights.${myAccount.uiProperty.vsAppMap.graphMetric}`;

        [this.clientsCollection, this.serversCollection].forEach(collection => {
            collection.reset();
            collection.load(params.metric_id);
        });

        this.appMap.start(virtualService.id, params);
    };

    const checkUIProperty = () => {
        const defaultSidebarSettings = {
            showPoolServers: true,
            showClientApps: true,
            showServerApps: true,
        };

        return myAccount.checkUIProperties('valuesToDisplay', 'vsAppMap').then(
            values => {
                if (!values[0]) {
                    myAccount.uiProperty.valuesToDisplay = 'avg';
                    myAccount.saveUIProperty();
                }

                if (!values[1]) {
                    myAccount.uiProperty.vsAppMap = {
                        graphMetric: this.graphMetrics[0].value,
                        sidebar: defaultSidebarSettings,
                    };

                    myAccount.saveUIProperty();
                } else {
                    const { graphMetric } = myAccount.uiProperty.vsAppMap;

                    if (angular.isUndefined(graphMetric) ||
                        !_.findWhere(this.graphMetrics, { value: graphMetric })) {
                        myAccount.uiProperty.vsAppMap.graphMetric = this.graphMetrics[0].value;
                        myAccount.saveUIProperty();
                    }

                    if (!('sidebar' in myAccount.uiProperty.vsAppMap)) {
                        myAccount.uiProperty.vsAppMap.sidebar = defaultSidebarSettings;
                        myAccount.saveUIProperty();
                    }
                }

                updateSelectedMetricUnitLabel(myAccount.uiProperty.vsAppMap.graphMetric);
            },
        );
    };

    virtualService.load(['config'])
        .then(() => {
            virtualService.addLoad(['health', 'alert']);

            this.VSServersCollection = new ServerCollection({
                params: {
                    referred_by: `virtualservice:${virtualService.id}`,
                },
                dataFields: ['config', 'health', 'runtime'],
                loadOnCreate: true,
            });

            checkUIProperty().then(run);
        });

    //need to have UI property before running app
    const run = () => {
        this.runAppMap();

        $scope.$watchGroup([
            'myAccount.uiProperty.vsAppMap.graphMetric',
            'myAccount.uiProperty.valuesToDisplay',
        ], (newVals, oldVals) => {
            if (oldVals[0] !== newVals[0] ||
                oldVals[1] !== newVals[1]) {
                this.runAppMap();
                myAccount.saveUIProperty();
                updateSelectedMetricUnitLabel(myAccount.uiProperty.vsAppMap.graphMetric);
            }
        });

        $scope.VirtualService.on('itemConfigUpdate', onVSUpdate);
    };

    $scope.$on('$destroy', () => {
        virtualService.async.stop(true);
        virtualService.removeLoad('health', 'alert');
        virtualService.unbind('itemConfigUpdate', onVSUpdate);

        [this.VSServersCollection, this.clientsCollection, this.serversCollection]
            .forEach(collection => collection && collection.destroy());

        this.appMap.destroy();
    });
}]);
