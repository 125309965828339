/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO Switch welcome to use router instead of custom show/hide classes (goTo).
//Currently templates render before they are used and/or data is ready
angular.module('aviApp').controller('WelcomeController', [
'$scope', '$timeout', '$state', '$q', 'Schema', 'Regex', 'Auth',
'CloudCollection', 'stackHyperVisorTypesFactory', 'TenantSettingsService', '$http',
'Cloud', 'cloudService', 'CloudConnectorUserCollection', 'appDefaultState',
'systemConfigService', 'defaultValues', 'dropDownUtils',
function($scope, $timeout, $state, $q, Schema, Regex, Auth, CloudCollection,
         stackHyperVisorTypesFactory, TenantSettingsService, $http, Cloud,
          cloudService, CloudConnectorUserCollection, appDefaultState,
         systemConfigService, defaultValues, dropDownUtils) {
    if (Auth.isAdminUserSetupRequired()) {
        sessionStorage.clear();
        localStorage.clear();
    }

    $scope.systemConfig = systemConfigService;

    const { systemConfig } = $scope;

    // Just reference, used in the template
    $scope.Regex = Regex;
    $scope.Schema = Schema;
    $scope.Auth = Auth;
    $scope.cloudService = cloudService;

    // Account screen defaults commented out ones are
    $scope.account = {
        username: 'admin',
    };

    $scope.saveEmailConfig = () => {
        $scope.busy = true;

        systemConfig.save()
            .then(() => {
                $scope.busy = false;

                if ($scope.cloud.data.config['vtype'] !== 'CLOUD_NONE') {
                    $scope.complete();
                } else {
                    $scope.goTo('cloud-type', 'smtp-config');
                }
            })
            .catch(responseErrorHandler);
    };

    $scope.forms = {};
    //className of currently selected Step of the welcome wizard, updated by goTo & backTo
    $scope.currentStep = undefined;
    //className of previously selected Step of the welcome wizard, updated by goTo & backTo.
    //This is needed because when moving from the tenancy screen(last screen in wizard) back
    //to a cloud screen, need to know what cloud had been selected.

    $scope.useMultiTenantConfig = false;

    /**
     * Takes care of model updates on step transitions. Called by goTo & backTo.
     * @param {string} to - ClassName of the step (div.panel.xxx) moving to.
     * @param {string=} from - ClassName of the step moving from.
     */
    function wizardGoTo(to, from) {
        const firstPagesOfType = ['vmware', 'openstack', 'aws-login', 'linux-server'];

        if (to === 'cloud-type') {
            $scope.cloud.data.config.vtype = 'CLOUD_NONE';
            $scope.cloud.removeCloudConfig();
        }

        if (from === 'cloud-type' && firstPagesOfType.indexOf(to) !== -1) {
            $scope.cloud.onCloudTypeChange();
        }

        if (from === 'cloud-type' && to === 'aws-login') {
            $scope.cloud.getCloudConfig().region = undefined;
        }

        if (from === 'aws-settings' && to === 'aws-login') {
            $scope.cloud.data.config.aws_configuration.vpc = undefined;
            $scope.cloud.data.config.aws_configuration.vpc_id = undefined;

            $scope.cloud.data.config.aws_configuration.zones = [{}];
            $scope.aws.updateSelectedAvailZones();
            $scope.cloud.az_nw_map = {};
        }
    }

    /**
     * Handles rejected promises from Http services.
     * @param {Object} response Http response object.
     */
    const responseErrorHandler = function(response) {
        $scope.busy = false;
        $scope.error = response.error || response.data.error;

        return $q.reject($scope.error);
    };

    /**
     * Updates default administrator account with passed details.
     * @param {string} username
     * @param {string} password
     * @param {string=} email
     * @returns {angular.$q.promise}
     * @inner
     */
    const updateAdministratorAccount = function({ username, password, email }) {
        const defaultPassword = '58NFaGDJm(PJH0G';
        let promise;

        $scope.error = null;

        if (username === password) {
            promise = $q.reject(
                $scope.error = { error: 'Password should not be the same as username' },
            );
        } else {
            promise = Auth.login({
                username: 'admin',
                password: defaultPassword,
            }).then(() => {
                const payload = {
                    username,
                    password,
                    email,
                    old_password: defaultPassword,
                };

                return $q.all([
                    $http.put('/api/useraccount', payload)
                        .then(() => { Auth.getInitData(); }),
                    systemConfig.load(),
                    defaultValues.load(),
                ]);
            });
        }

        return promise;
    };

    /**
     * Navigates to the panel (screen) with fadeInLeft animation.
     * If there is no current panel, navigate to the first panel with fadeIn animation.
     * @param {string} panelClassName - CSS class name of the panel (div.panel.xxx) to
     * navigate to.
     * @param {string=} fromClassName - ClassName of the step moving from.
     */
    $scope.goTo = function(panelClassName, fromClassName) {
        $scope.useMultiTenantConfig = $scope.checkKeystone();
        $scope.error = null;

        if ($scope.cloud) {
            $scope.cloud.errors = null;
        }
        // Using animate.css to slide the panels
        // If any of the container is active

        wizardGoTo(panelClassName, fromClassName);

        $('.carousel .panel')
            .removeClass('fadeInLeft fadeOutLeft fadeInRight fadeOutRight');

        const current = $('.carousel .panel.current');

        $(`.carousel .panel.${panelClassName}`).show().addClass('fadeInRight current');

        if (current.length) {
            current.removeClass('current').addClass('fadeOutLeft');
            current.hide();
            $scope.currentStep = panelClassName;
            $scope.previousStep = fromClassName;
        }

        $timeout(() => $(`.carousel .panel.${panelClassName} input:first`).trigger('focus'));
    };

    /**
     * Navigates to the panel (screen) with the fadeInRight animation.
     * Used to come back to "previous" screen.
     * @param {string} panelClassName - CSS className of the panel  (div.panel.xxx) to
     * navigate to.
     * @param {string} fromClassName - CSS className of the panel to navigate from.
     */
    $scope.backTo = function(panelClassName, fromClassName) {
        const current = $('.carousel .panel.current');

        panelClassName = panelClassName || $scope.previousStep;

        $scope.error = null;

        if ($scope.cloud) {
            $scope.cloud.errors = null;
        }

        wizardGoTo(panelClassName, fromClassName);

        $('.carousel .panel')
            .removeClass('fadeInLeft fadeOutLeft fadeInRight fadeOutRight');
        current.removeClass('current').addClass('fadeOutRight');
        $(`.carousel .panel.${panelClassName}`).show().addClass('fadeInLeft current');

        $scope.currentStep = panelClassName;
        $scope.previousStep = fromClassName;

        if (fromClassName == 'tenant-settings' && !$scope.checkKeystone()) {
            $scope.useMultiTenantConfig = false;
        }

        current.hide();
    };

    /**
     * Checks for keystone flag in openstack cloud configurations. If use_keystone_auth is
     * true, skip the step in tenancy that asks for multiple tenants.
     * @return {boolean} Return true if use_keystone_auth flag is true in openstack cloud.
     */
    $scope.checkKeystone = function() {
        return !!($scope.cloud && $scope.cloud.data.config.openstack_configuration &&
            $scope.cloud.data.config.openstack_configuration.use_keystone_auth);
    };

    $scope.getCloudTypeName = Cloud.getCloudTypeName;

    /*------------ Controller settings -------------*/

    /**
     * Used to match password and password confirmation on the account screen.
     * @returns {boolean} - True if password and it's confirmation match.
     */
    $scope.passwordsMatch = function() {
        return $scope.account.password === $scope.account.confirm_password;
    };

    /**
     * Used to highlight passwords if they don't match.
     */
    $scope.checkPasswords = function() {
        if ($scope.passwordsMatch()) {
            $('.panel.account input[type=password]').removeClass('ng-invalid');
        } else {
            $('.panel.account input[type=password]').addClass('ng-invalid');
        }
    };

    const clouds = new CloudCollection({
        isStatic: true,
        limit: 1,
    });

    /**
     * Saves administrator account (first screen).
     * @public
     */
    $scope.saveAdministratorAccount = function() {
        $scope.error = null;

        if (!$scope.passwordsMatch()) {
            $scope.error = 'Passwords don\'t match';
        } else {
            $scope.busy = true;

            updateAdministratorAccount($scope.account).then(() => {
                // controller set up done thru CLI, skip the welcome wizard
                if ($scope.Auth.isWelcomeWorkflowCompleted()) {
                    $state.go(appDefaultState);
                } else {
                    $scope.goToControllerSettings();
                }
            }, responseErrorHandler);
        }
    };

    /**
     * Navigates to the controller-settings panel (screen).
     * @public
     */
    $scope.goToControllerSettings = function() {
        TenantSettingsService.parseMessages();

        // Get default cloud as we need to decide to skip settings
        clouds.load().then(() => {
            $scope.busy = false;
            [$scope.cloud] = clouds.items;
            $scope.goTo('controller-settings');
        });
    };

    $scope.saveTenantSettings = function() {
        $scope.busy = true;
        $scope.error = null;

        TenantSettingsService.saveConfig()
            .then(() => {
                $scope.busy = false;

                if ($scope.cloud.data.config['vtype'] === 'CLOUD_OPENSTACK' &&
                    !$scope.seProviderContextValue()) {
                    $scope.goTo('openstack-roles', 'tenant-settings');
                } else {
                    $scope.complete();
                }
            })
            .catch(responseErrorHandler);
    };

    /**
     * Check SE Provider context from Tenant settings. This is needed in the case of
     * Openstack, where after tenant settings the user has to configure the SE ownership
     * of the cloud.
     * @return {boolean}
     */
    $scope.seProviderContextValue = function() {
        return TenantSettingsService.seProviderContext === 'true';
    };

    /**
     * Saves controller's networks settings.
     */
    $scope.saveSystemSettings = function() {
        $scope.busy = true;

        systemConfig.save()
            .then(() => {
                $scope.busy = false;
                $scope.goTo('smtp-config', 'controller-settings');
            })
            .catch(responseErrorHandler);
    };

    /*------------------------- vmWare vCenter -------------------------------*/

    $scope.vcenter = {
        thirdPartyIntegration: 'none',
        ipSubnet: '',
        /**
         * Login to vCenter.
         */
        vcenterLogin() {
            const { cloud } = $scope;

            cloud.vCenterLogin(true)
                .then(() => {
                    if (this.thirdPartyIntegration === 'nsx') {
                        $scope.goTo('vmware-nsx', 'vmware');
                    } else {
                        $scope.goTo('datacenter', 'vmware');
                    }

                    cloud.getvCenterDC().then($scope.vcenter.setDatacenterOptions);
                });
        },
        nsxLogin() {
            $scope.cloud.nsxLogin()
                .then(() => $scope.goTo('datacenter', 'vmware-nsx'));
        },

        /**
         * Generates Management Network dropdown options.
         */
        setManagementNetworkOptions() {
            const { networks } = $scope.cloud;

            $scope.managementNetworkOptions = networks.map(({ uuid, name }) => {
                const value = `${uuid}#${name}`;

                return dropDownUtils.createOption(
                    value,
                    name,
                );
            });
        },

        /**
         * Management network field change callback.
         * Creates selected management network object and triggers selected network load.
         */
        onManagementNetworkChange() {
            const { networks } = $scope.cloud;
            const { management_network: managementNetwork } = $scope.cloud.getCloudConfig();

            const selectedManagementNetwork = _.findWhere(networks, {
                uuid: managementNetwork.slug(),
            });

            selectedManagementNetwork.url = managementNetwork;
            $scope.cloud.setNetwork(selectedManagementNetwork);
        },

        /**
         * Generates Datacenter dropdown options.
         */
        setDatacenterOptions() {
            const { datacenters } = $scope.cloud;

            $scope.datacentersOptions = datacenters
                .map(({ name }) => dropDownUtils.createOption(name, name));
        },

        saveDataCenter() {
            $scope.cloud.save(false).then(function() {
                if ($scope.cloud.data.config.vcenter_configuration.privilege === 'READ_ACCESS') {
                    $scope.goTo('tenant-settings', 'datacenter');
                } else {
                    $scope.goTo('vmware-network', 'datacenter');
                    $scope.vcenter.loadingNetworks = true;

                    // Load management networks
                    $scope.cloud.getvCenterNetworks()
                        .then(() => {
                            $scope.vcenter.setManagementNetworkOptions();
                        })
                        .finally(() => {
                            $scope.vcenter.loadingNetworks = false;
                        });

                    $http.post('/api/vimgrvcenterruntime/controller/ipsubnet')
                        .then(function({ data }) {
                            const [ipSubnetInfo] =
                                data.resource.vi_controller_ip_subnet_info;
                            const { ip_subnet: ipSubnetAddr } = ipSubnetInfo;

                            $scope.vcenter.ipSubnet = `${ipSubnetAddr.ip_addr.addr}/${
                                ipSubnetAddr.mask}`;
                            $scope.cloud.getVRFContext();
                        });
                }
            });
        },
        saveNetwork() {
            if ($scope.cloud.data.config.vcenter_configuration.management_network) {
                $scope.cloud.saveVRFContext();
                $scope.cloud.saveNetworkInfo().then(function() {
                    $scope.goTo('tenant-settings', 'vmware-network');
                });
            }
        },
        handleThirdPartyIntegrationChange() {
            $scope.cloud.handleThirdPartyIntegrationChange(this.thirdPartyIntegration);
        },
    };

    /*------------------------- OpenStack -------------------------------*/
    /**
     * Flag needed for KeystoneRoleMappingController to turn off role creation in the
     * Welcome page.
     * @type {boolean}
     */
    $scope.isRoleCreatable = false;
    $scope.openstack = {
        /**
         * Checking openstack credentials and doing the stuff for it.
         * @returns {promise}
         */
        openStackLogin() {
            return $scope.cloud.openStackLogin().then(
                function() {
                    $scope.$broadcast('openStackLogin', $scope.cloud);
                    $scope.goTo('openstack-network', 'openstack');
                },
            );
        },
        /**
         * Preloading tenant networks when tenant is selected.
         */
        onTenantChange() {
            const adminObj = $scope.cloud.getTenantID();

            if (adminObj) {
                $scope.cloud.onTenantChange(adminObj);
            }
        },
        saveOpenStackConfiguration(previousState) {
            previousState = previousState || 'openstack-settings';
            $scope.$broadcast('openStackBeforeSave');
            $scope.cloud.save().then(function() {
                if (previousState === 'openstack-se_ownership' ||
                    $scope.cloud.data.config.openstack_configuration.use_keystone_auth) {
                    $scope.complete();
                } else {
                    $scope.goTo('tenant-settings', previousState);
                }
            });
        },
        checkAndSaveNuage() {
            return $scope.cloud.checkNuageCreds().then(
                function() {
                    $scope.goTo('openstack-roles', 'openstack-nuage');
                },
            );
        },
        hyperVisorTypes: stackHyperVisorTypesFactory,
        /**
         * Returns true to show Allow Self-signed Certificates checkbox.
         * @return {boolean}
         */
        showAllowSelfSignedCertCheckbox: () => {
            if (angular.isUndefined($scope.cloud)) {
                return false;
            }

            const config = $scope.cloud.data.config.openstack_configuration;

            if (angular.isUndefined(config)) {
                return false;
            }

            return !angular.isUndefined(config.auth_url) && config.auth_url.beginsWith('https://');
        },
    };
    /*---- AWS ----*/
    $scope.aws = {
        awsLogin() {
            $scope.cloud.awsLogin().then(function() {
                $scope.cloud.getAwsRegions();
                $scope.aws.updateSelectedAvailZones();
                $scope.goTo('aws-settings', 'aws-login');
            });
        },
        saveAwsConfiguration() {
            $scope.cloud.saveAwsConfiguration()
                .then(function() {
                    $scope.goTo('tenant-settings', 'aws-settings');
                });
        },
        /**
         * Used to hide availabilty zones which have been already selected in other
         * dropdowns.
         */
        updateSelectedAvailZones() {
            $scope.awsSelectedZones = [];
            _.each($scope.cloud.data.config.aws_configuration.zones, function(zone) {
                if (zone.availability_zone) {
                    $scope.awsSelectedZones.push(zone.availability_zone);
                }
            });
        },
        /**
         * Called by minus button to remove availability zone from layout.
         * Available only when we have at least two zones.
         */
        removeAvailZone(index) {
            $scope.cloud.data.config.aws_configuration.zones.splice(index, 1);
            $scope.aws.updateSelectedAvailZones();
        },
        /**
         * Called by plus button to add availability zone to layout. Up to four.
         */
        addAvailZone() {
            $scope.cloud.addAwsAvailabilityZone();
        },
    };

    // CLOUD_LINUXSERVER
    $scope.linuxServer = {
        sshUsers: new CloudConnectorUserCollection(),

        switchToHosts() {
            $scope.goTo('linux-server-hosts', 'cloud-type');
        },

        complete() {
            $scope.cloud.save().then(function() {
                $scope.goTo('tenant-settings', 'linux-server-hosts');
            });
        },
    };

    // copy-pasta from dns-ntp-modal.component.js
    /**
     * Adds empty object to ntp_authentication_keys array.
     */
    $scope.addNTPAuthenticationKey = function() {
        const { ntp_authentication_keys: authKeys } = systemConfig.getNTPConfig();

        authKeys.push({});
    };

    /**
     * Splices authentication key object from ntp_authentication_keys array.
     * @param  {number} index - Index to remove.
     */
    $scope.removeNTPAuthenticationKey = function(index) {
        const { ntp_authentication_keys: authKeys } = systemConfig.getNTPConfig();

        authKeys.splice(index, 1);
    };

    /**
     * Adds empty object to ntp_servers array.
     */
    $scope.addNTPServer = function() {
        const { ntp_servers: ntpServers } = systemConfig.getNTPConfig();

        ntpServers.push({});
    };

    /**
     * Splices server object from ntp_servers array.
     * @param  {number} index - Index to remove.
     */
    $scope.removeNTPServer = function(index) {
        const { ntp_servers: ntpServers } = systemConfig.getNTPConfig();

        ntpServers.splice(index, 1);
    };

    /*---- no cloud ----*/
    $scope.saveNoCloud = function() {
        $scope.cloud.setCloudNone()
            .then(() => $scope.goTo('tenant-settings', 'cloud-type'));
    };

    /**
     * Navigates to complete screen and then to the system.
     */
    $scope.complete = function() {
        $scope.goTo('complete');

        systemConfig.setWelcomeWorkflowComplete()
            .then(() => {
                $scope.Auth.getInitData()
                    .then(() => {
                        $scope.busy = false;
                        $scope.goTo('complete');
                        setTimeout(() => $state.go(appDefaultState), 2000);
                    });
            })
            .catch(responseErrorHandler);
    };

    //initialization
    if (!$scope.Auth.isAdminUserSetupRequired() && !$scope.Auth.isWelcomeWorkflowCompleted()) {
        defaultValues.load()
            .then($scope.goToControllerSettings)
            .catch(responseErrorHandler);
    } else {
        $scope.goTo('controller-account');
    }

    $scope.$on('$destroy', function() {
        clouds.destroy();
    });
}]);
