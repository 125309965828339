/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './error-page-profile.less';

/**
 * @ngdoc component
 * @name errorPageProfile
 * @description Error Page Profile list page.
 */
class ErrorPageProfileController {
    /**
     * Returns all status codes from specified page.
     * @param {ErrorPage} page
     * @return {string[]}
     */
    static getStatusCodesFromPage(page) {
        const { match } = page;
        let ret = [];
        const { ranges, status_codes: statusCodes } = match;

        if (Array.isArray(ranges)) {
            ret = ranges.map(r => `${r.begin}-${r.end}`);
        }

        if (Array.isArray(statusCodes)) {
            ret = ret.concat(statusCodes);
        }

        return ret;
    }

    constructor(ErrorPageProfileCollection, CRUDGridConfig) {
        this.ErrorPageProfileCollection = ErrorPageProfileCollection;
        this.CRUDGridConfig = CRUDGridConfig;
    }

    $onInit() {
        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.ErrorPageProfileCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }, {
            name: 'data.config.error_pages',
            title: 'Error Pages',
            transform: row => {
                const { error_pages: pages } = row.getConfig();
                const statusCodes = pages.map(page => {
                    return ErrorPageProfileController.getStatusCodesFromPage(page).join(', ');
                });

                return `
                        <div>Total Pages: ${pages.length}</div>
                        <div class="error-page-profile-list__status-codes">
                            Status Codes: ${statusCodes.join(', ')}
                        </div>`;
            },
        }];
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ErrorPageProfileController.$inject = [
        'ErrorPageProfileCollection',
        'CRUDGridConfig',
];

angular.module('aviApp').component('errorPageProfileList', {
    controller: ErrorPageProfileController,
    template:
            `<div class="panel templatesList error-page-profile-list">
                <collection-grid config="$ctrl.gridConfig" />
            </div>`,
});
