/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('TenantListController', [
'$scope', 'CRUDGridConfig', 'TenantCollection', 'getGridMetricFieldConfig',
function($scope, CRUDGridConfig, TenantCollection, getGridMetricFieldConfig) {
    const
        seriesNamePrefix = 'tenant_stats.',
        gridMetricFields = [
            'avg_num_backend_servers',
            'avg_num_se_cores',
            'max_total_se_throughput',
            'sum_total_vs_client_bytes',
            'max_num_ses',
            'avg_num_ses',
            'max_num_sockets',
            'avg_num_sockets',
        ];

    $scope.gridConfig = new CRUDGridConfig();

    $scope.gridConfig.collection = new TenantCollection();

    $scope.gridConfig.layout.includeTimeframeSelector = true;
    $scope.gridConfig.layout.includeMetricsValueSelector = true;

    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
        visibility: 'm',
    }, {
        name: 'data.inventory.users',
        title: 'Num Users',
        visibility: 'm',
    }, {
        name: 'tenant-admin-users',
        title: 'Admin Users',
        visibility: 'm',
        transform(row) {
            return row.data.inventory && row.data.inventory['tenant-admin'] &&
                    row.data.inventory['tenant-admin'].join(', ') || '';
        },
    }, {
        name: 'other-users',
        title: 'Other Users',
        visibility: 'm',
        transform(row) {
            if (row.data.inventory && row.data.inventory['non-admin']) {
                let nonAdmin = row.data.inventory['non-admin'];

                nonAdmin = _.uniq(nonAdmin);

                return nonAdmin.join(', ');
            }

            return '';
        },
    },
    ];

    const { fields } = $scope.gridConfig;

    //let's add metric series fields
    Array.prototype.push.apply(fields,
        gridMetricFields.map(seriesName =>
            getGridMetricFieldConfig(seriesNamePrefix + seriesName)));

    $scope.$on('$destroy', function() {
        $scope.gridConfig.collection.destroy();
    });
}]);
