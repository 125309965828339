/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './user-modal-password-settings.component.less';

const componentName = 'user-modal-password-settings';

class UserModalPasswordSettingsController {
    constructor(
        Auth,
        secretStubStr,
    ) {
        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.auth_ = Auth;

        /**
         * @protected
         */
        this.secretStubStr_ = secretStubStr;

        /**
         * Set to true when user types in Password field.
         * If its true, Password confirmation is required.
         * @type {boolean}
         */
        this.confirmationRequired = false;

        /**
         * Set to false when password and confirm passwords dont match.
         * Used to highlight error in above case.
         * @type {boolean}
         */
        this.isPasswordsMatch = true;
    }

    /**
     * Fires when there is a change in password input field.
     */
    onPasswordChange() {
        if (!this.user.getConfig()) {
            return;
        }

        this.confirmationRequired = !!this.user.getConfig().password;

        this.checkPasswords();
    }

    /**
     * Returns true if password and confirm_password are same.
     * @returns {boolean}
     */
    passwordsMatch() {
        if (!this.user.getConfig()) {
            return false;
        }

        const {
            password = '',
            confirm_password: confirmPassword = '',
        } = this.user.getConfig();

        if (!password) {
            return true;
        }

        return password === confirmPassword;
    }

    /**
     * Used to highlight passwords if they don't match and update parent
     * controller.
     */
    checkPasswords() {
        this.isPasswordsMatch = !this.confirmationRequired || this.passwordsMatch();

        this.onPasswordEdit({
            invalidPassword: !this.isPasswordsMatch,
        });
    }

    /**
     *  Generates random string to be used as a password.
     *  No need to confirm password for this case.
     */
    generatePassword() {
        this.confirmationRequired = false;

        this.user.generatePassword();
    }

    /**
     * Checks to see if current logged-in username matches the username of user being edited.
     * @return {boolean} True if usernames match, false otherwise.
     */
    activeUserSettings() {
        return this.user.id &&
            this.user.getConfig().username === this.auth_.getUsername();
    }

    /**
     * Returns true if the password is equal to the secretStubStr.
     * @return {boolean}
     */
    passwordIsSecretStub() {
        return this.user.getConfig().password === this.secretStubStr_;
    }

    /**
     * Returns Password field's class.
     * @return {string}
     */
    getPasswordFieldClass() {
        return this.confirmationRequired || this.passwordIsSecretStub() ?
            'grid-container__cell--w--full' : 'grid-container__cell--w--11';
    }
}

UserModalPasswordSettingsController.$inject = [
    'Auth',
    'secretStubStr',
];

/**
 * @ngdoc component
 * @name  userModalPasswordSettings
 * @param {User} user - User item config
 * @description
 *      Password component with new password generate and confirmation support
 *      For Administration/Accounts/User/Create-Edit modal.
 * @author Aravindh Nagarajan
 */
angular.module('aviApp').component('userModalPasswordSettings', {
    /**
     * @mixin userModalPasswordSettingsBindings
     * @memberof module:avi/accounts
     * @property {module:avi/accounts.User} user User item config
     * @property {Function} onPasswordEdit Function to update parent controller
     *      about password change
     */
    bindings: {
        user: '<',
        onPasswordEdit: '&',
    },
    controller: UserModalPasswordSettingsController,
    templateUrl: 'src/components/modals/administration/accounts/user/' +
        `${componentName}/${componentName}.component.html`,
});
