/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Module containing all files owning Upgrade 2.0 related functionalities.
 * @module avi/upgrade
 */

/**
 * Param hash as the request body to make upgrade API calls. Maps to UpgradeParams in protobuf.
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name UpgradeApiPayload
 * @property {string=} image_uuid - Image uuid for identifying base image.
 * @property {string=} controller_patch_uuid - Image uuid for identifying Controller patch.
 * @property {string=} se_patch_uuid - Image uuid for identifying Service Engine patch.
 * @property {boolean=} [system=false] - Apply upgrade operations such as Upgrade/Patch to
 *     Controller and ALL SE groups.
 * @property {SeGroupOptions=} se_group_options - Identify SE group options that need to be applied
 *     during the upgrade operations.
 * @property {Array.<string>=} se_group_uuids - Identify the list of SE groups for which the upgrade
 *     operations are applicable.  This field is ignored if the 'system' is enabled.
 * @property {boolean=}  [skip_warnings=false] - Flag to skip warnings returned by must-checks.
 */

/**
 * Param hash as the request body to make rollback API calls. Maps to RollbackParams in protobuf.
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name RollbackApiPayload
 * @property {string} rollback_type - Enum identifying to rollback the current applied patch or
 *     rollback to previous major version.
 * @property {boolean=} [system=false] - Apply rollback operation to Controller and ALL SE groups.
 * @property {Array.<string>=} se_group_uuids - Identify the list of SE groups for which the
 *     rollback operation is applicable.  This field is ignored if the 'system' is enabled.
 * @property {boolean=}  [skip_warnings=false] - Flag to skip warnings returned by must-checks.
 */

/**
 * Version number split and presented as Object with props.
 * @typedef {Object}
 * @name ImageVersionHash
 * @memberOf module:avi/upgrade
 * @property {string} version
 * @property {string} build
 * @property {string} date
 */

/**
 * Hash containing configuration of both system upgrade and rollback.
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name SystemUpgradeConfig
 * @property {string} upgradeType - Type of upgrade operation(upgrade/rollback).
 * @property {string} targetVersion - Target version to upgrade/rollback to.
 * @property {string=} systemImageId - System image uuid.
 * @property {string=} controllerPatchImageId - Controller patch image uuid.
 * @property {string=} sePatchImageId - SE patch image uuid.
 * @property {string=} rollbackType - Enum identifying to rollback the current applied patch or
 *     rollback to previous major version.
 */

/**
 * Event which fires upon successful upgrade trigger from UI.
 * @event module:avi/upgrade.UpgradeService#UPGRADE_TRIGGER_EVENT
 * @see module:avi/upgrade.UpgradeService#UPGRADE_TRIGGER_EVENT
 */

import { upgradeNodeTypes } from './upgrade-node-list-services/upgrade-node-list.service';

const { NODE_CONTROLLER_CLUSTER } = upgradeNodeTypes;

const UPGRADE_TYPE_SYSTEM_UPGRADE = 'system_upgrade';
const UPGRADE_TYPE_SYSTEM_ROLLBACK = 'system_rollback';

const ROLLBACK_TYPE_IMAGE = 'ROLLBACK_TYPE_IMAGE';
const ROLLBACK_TYPE_PATCH = 'ROLLBACK_TYPE_PATCH';

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @description
 *     Stateless service tackling tasks for Upgrade2.0.
 * @author Zhiqian Liu
 */
class UpgradeService {
    /**
     * Event name for when upgrade is successfully triggered from UI.
     * @type {string}
     */
    static UPGRADE_TRIGGER_EVENT = 'UPGRADE_TRIGGER_EVENT';

    // eslint-disable-next-line require-jsdoc
    constructor($http) {
        this.$http_ = $http;

        /**
         * API to make POST call for upgrade.
         * @type {string}
         * @protected
         */
        this.upgradeApi_ = '/api/upgrade';

        /**
         * API to make POST call for upgrade preview info.
         * @type {string}
         * @protected
         */
        this.upgradePreviewApi_ = `${this.upgradeApi_}/preview`;

        /**
         * API to make POST call for rollback.
         * @type {string}
         * @protected
         */
        this.rollbackApi_ = '/api/rollback';

        /**
         * API to make POST call for rollback preview info.
         * @type {string}
         * @protected
         */
        this.rollbackPreviewApi_ = `${this.rollbackApi_}/preview`;

        /**
         * API to make GET call fetching upgrade status.
         * @type {string}
         * @protected
         */
        this.upgradeStatusApi_ = '/api/upgradestatusinfo';
    }

    /**
     * Take SystemUpgradeConfig and generate default upgrade/rollback request payload.
     * @param {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of
     *     both system upgrade and rollback.
     * @return {module:avi/upgrade.UpgradeApiPayload | module:avi/upgrade.RollbackApiPayload}
     * @protected
     */
    static getBasicUpgradeRequestBody_(upgradeConfig) {
        const { upgradeType } = upgradeConfig;

        let mainConfig;

        switch (upgradeType) {
            case UPGRADE_TYPE_SYSTEM_UPGRADE: {
                const {
                    systemImageId,
                    controllerPatchImageId,
                    sePatchImageId,
                } = upgradeConfig;

                mainConfig = {
                    image_uuid: systemImageId,
                    controller_patch_uuid: controllerPatchImageId,
                    se_patch_uuid: sePatchImageId,
                };

                break;
            }

            case UPGRADE_TYPE_SYSTEM_ROLLBACK: {
                const { rollbackType } = upgradeConfig;

                mainConfig = { rollback_type: rollbackType };

                break;
            }
        }

        const optionalOpsConfig = {
            system: false,
            skip_warnings: false,
        };

        return { ...mainConfig, ...optionalOpsConfig };
    }

    /**
     * Splits input version string and returns useful object.
     * @param {string} fullVersion - version number i.e. "18.2.6-5000-20190830.160756"
     * @return {module:avi/upgrade.ImageVersionHash}
     */
    //TODO: Add unit tests
    static getVersionAsHash(fullVersion) {
        if (!fullVersion) {
            throw new Error(`input version str is not as expected: ${fullVersion}`);
        }

        const [version, build, date] = fullVersion.split('-');

        return {
            version,
            build,
            date,
        };
    }

    /**
     * Check is SE Group upgrade/rollback feasible or not.
     * @param {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of
     *     both system upgrade and rollback.
     * @return {boolean}
     */
    static isSegUpgradeAvailable(upgradeConfig) {
        const { upgradeType } = upgradeConfig;

        switch (upgradeType) {
            case UPGRADE_TYPE_SYSTEM_UPGRADE: {
                const { systemImageId, sePatchImageId } = upgradeConfig;

                return !!(systemImageId || sePatchImageId);
            }

            case UPGRADE_TYPE_SYSTEM_ROLLBACK:
                return true;
        }
    }

    /**
     * Start upgrade process w/o ALL service engine groups. Independent SE group upgrade is not of
     * option by using this method.
     * @param {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of
     *     both system upgrade and rollback.
     * @param {boolean} skipWarnings - True to skip warnings returned by must-checks and proceed
     *     with upgrade. False to get warnings from must-checks without upgrade being triggered.
     * @param {boolean=} system - If true ALL service engine groups will be upgraded.
     *     If false SEG upgrade will be ignored.
     * @param {string=} [actionOnSegFailure='SUSPEND_UPGRADE_OPS_ON_ERROR'] - Action to be taken
     *     when SEG hits error.
     * @return {ng.$q.promise} - Fail anyway with list of prompts when skip_warnings is set to true.
     *     Resolves only when skip_warnings is set to false and the upgrade call passes through.
     */
    startSystemUpgrade(
        upgradeConfig,
        skipWarnings,
        system,
        actionOnSegFailure = 'SUSPEND_UPGRADE_OPS_ON_ERROR',
    ) {
        /**
         * @type {module:avi/upgrade.UpgradeApiPayload}
         */
        const requestBody = UpgradeService.getBasicUpgradeRequestBody_(upgradeConfig);

        requestBody.skip_warnings = skipWarnings;

        // set se group based operations when SEGs are possible to be upgraded
        if (system &&
            UpgradeService.isSegUpgradeAvailable(upgradeConfig)
        ) {
            requestBody.se_group_options = {
                action_on_error: actionOnSegFailure,
            };

            // override default system flag
            requestBody.system = true;
        }

        return this.$http_.post(this.upgradeApi_, requestBody);
    }

    /**
     * Start rollback process w/o ALL service engine groups. Independent SE group rollback is not of
     * option by using this method.
     * @param {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of
     *     both system upgrade and rollback.
     * @param {boolean} skipWarnings - True to skip warnings returned by must-checks and proceed
     *     with upgrade. False to get warnings from must-checks without rollback being triggered.
     * @param {boolean=} system - If true ALL service engine groups will be rollbacked.
     *     If false SEG upgrade will be ignored.
     * @return {ng.$q.promise} - Fail anyway with list of prompts when skip_warnings is set to true.
     *     Resolves only when skip_warnings is set to false and the rollback call passes through.
     */
    startSystemRollback(upgradeConfig, skipWarnings, system) {
        /**
         * @type {modul:avi/upgrade.RollbackApiPayload}
         */
        const requestBody = UpgradeService.getBasicUpgradeRequestBody_(upgradeConfig);

        requestBody.skip_warnings = skipWarnings;

        if (system) {
            requestBody.system = true;
        }

        return this.$http_.post(this.rollbackApi_, requestBody);
    }

    /**
     * Make API call to get prompts before upgrade/rollback happens; particularly the list of
     * checks.
     * @param {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of
     *     both system upgrade and rollback.
     * @return {ng.$q.promise}
     */
    getPreUpgradePrompts(upgradeConfig) {
        const { upgradeType } = upgradeConfig;

        /**
         * @type {module:avi/upgrade.UpgradeApiPayload | module:avi/upgrade.RollbackApiPayload}
         */
        const requestBody = UpgradeService.getBasicUpgradeRequestBody_(upgradeConfig);

        let previewApi;

        switch (upgradeType) {
            case UPGRADE_TYPE_SYSTEM_UPGRADE:
                previewApi = this.upgradePreviewApi_;
                break;

            case UPGRADE_TYPE_SYSTEM_ROLLBACK:
                previewApi = this.rollbackPreviewApi_;
                break;
        }

        return this.$http_.post(previewApi, requestBody);
    }

    /**
     * Make API call to get upgrade status info, controller only.
     * @return {ng.$q.promise}
     */
    getControllerUpgradeStatus() {
        return this.$http_.get(`${this.upgradeStatusApi_}?node_type=${NODE_CONTROLLER_CLUSTER}`);
    }
}

UpgradeService.$inject = [
    '$http',
];

angular.module('avi/upgrade').service('upgradeService', UpgradeService);

const upgradeTypeHash = {
    UPGRADE_TYPE_SYSTEM_UPGRADE,
    UPGRADE_TYPE_SYSTEM_ROLLBACK,
};

const rollbackTypeHash = {
    ROLLBACK_TYPE_IMAGE,
    ROLLBACK_TYPE_PATCH,
};

export { UpgradeService, upgradeTypeHash, rollbackTypeHash };
