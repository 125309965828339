/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO isBusy as event
function updatableBaseFactory(
    $injector,
    $q,
    Base,
    Timeframe,
    aviInherit,
    DataSource,
) {
    /**
     * @class UpdatableBase
     * @param {Object=} args
     * @constructor
     * @extends module:avi/dataModel.Base
     * @memberOf module:avi/dataModel
     * @author Alex Malitsky
     * @description
     *
     *     Base extended by support of loading data through DataSources, DataTransformers and
     *     DataTransports.
     */
    function UpdatableBase(args = {}) {
        UpdatableBase.superconstructor.call(this, args);

        /**
         * Flag to switch on repeated updates of all dataSources. Used only by
         * {@link Collection#subscribe} on a new {@link DataSource} instantiation. Not
         * supposed to be updated on the fly - won't affect updates in any sense.
         * @type {boolean}
         * @protected
         */
        this.isStatic_ = angular.isUndefined(args.isStatic) ? this.isStatic_ : !!args.isStatic;

        /**
         * Flag to load all active DataSources once item has been instantiated. Should be done
         * by descendant.
         * @type {boolean}
         * @protected
         */
        this.loadOnCreate_ = !angular.isUndefined(args.loadOnCreate) ? !!args.loadOnCreate :
            false;

        /**
         * Hash of all available data sources for this instance. Ids must be unique within
         * instance and do not intersect with data field names.
         * @type {{string: DataSourceConfig}}
         * @protected
         */
        this.allDataSources_ = angular.isObject(args.allDataSources) ?
            angular.copy(args.allDataSources) : angular.copy(this.allDataSources_);

        /**
         * Default {@link DataSourceConfig} id or a list of them. Will be activated on
         * instantiation. These ids should be present in {@link UpdatableBase#allDataSources_}.
         * @type {string|string[]}
         * @protected
         */
        this.defaultDataSources_ = angular.copy(args.defaultDataSources ||
            this.defaultDataSources_);

        /**
         * Hash of active {@link DataSource} instances. Keys are taken from
         * {@link UpdatableBase#allDataSources_}. Maintained by {@link UpdatableBase#subscribe}
         * and {@link UpdatableBase#unSubscribe} methods.
         * @type {{string: DataSource}}
         * @memberOf UpdatableBase
         * @protected
         */
        this.dataSources_ = {};

        /**
         * Hash where keys are fieldNames and values are {@link DataSource} ids within this
         * instance which provide data for the corresponding field. Populated by
         * {@link UpdatableBase#populateAllDataFieldsHash_} called by constructor. This hash has all
         * available dataFields and dataSources, not only active ones as this.
         * @type {{string: string}}
         * @protected
         */
        this.allDataFields_ = {};

        /**
         * List of default dataFields names for the Collection.
         * @type {string|string[]}
         * @protected
         */
        this.defaultDataFields_ = angular.copy(args.defaultDataFields || this.defaultDataFields_);

        /**
         * Hash where keys are fieldNames and values are {@link DataSource} ids
         * within this Collection class which provide data for the corresponding field.
         * Maintained by {@link Collection#addDataFieldsOfSource_} and
         * {@link Collection#removeDataFieldsOfSource_} methods. Only `activated`
         * {@link DataSource | data sources} are represented here.
         * @type {{string: string}}
         * @protected
         */
        this.dataFields_ = {};

        this.populateAllDataFieldsHash_();

        if (angular.isArray(args.dataSources) && args.dataSources.length) {
            this.subscribe(args.dataSources, true);
        } else if (this.defaultDataSources_) {
            if (angular.isString(this.defaultDataSources_)) {
                this.defaultDataSources_ = [this.defaultDataSources_];
            }

            if (angular.isArray(this.defaultDataSources_)) {
                this.defaultDataSources_.forEach(sourceName => {
                    if (sourceName in this.allDataSources_) {
                        this.subscribe({
                            id: sourceName,
                            preserved: true,
                        }, true);
                    } else {
                        console.error(`There is no defaultDataSource "${sourceName}" in the list
                            of available dataSources %O`, this.allDataSources_);
                    }
                });
            }
        }

        if (angular.isArray(args.dataFields) && args.dataFields.length) {
            this.subscribe(args.dataFields, true);
        } else if (this.defaultDataFields_) {
            if (angular.isString(this.defaultDataFields_)) {
                this.defaultDataFields_ = [this.defaultDataFields_];
            }

            if (angular.isArray(this.defaultDataFields_)) {
                this.defaultDataFields_.forEach(fieldName => {
                    if (fieldName in this.allDataFields_) {
                        this.subscribe({
                            id: fieldName,
                            preserved: true,
                            subscriber: 'constructor',
                        }, true);
                    } else {
                        console.error(`There is no defaultDataField "%s" in the list of available
                            dataFields %O`, fieldName, this.allDataFields_);
                    }
                });
            }
        }

        if (args.params) {
            this.setParams({ ...args.params });
        }
    }

    aviInherit(UpdatableBase, Base);

    /**
     * Method populates {@link UpdatableBase.allDataFields_} hash. Used by constructor only.
     * @protected
     */
    UpdatableBase.prototype.populateAllDataFieldsHash_ = function() {
        this.allDataFields_ = {};

        _.each(this.allDataSources_, (source, sourceId) => {
            if (angular.isArray(source.fields)) {
                source.fields.forEach(fieldName => {
                    if (!(fieldName in this.allDataFields_)) {
                        this.allDataFields_[fieldName] = sourceId;
                    } else {
                        console.warn(`Instance is trying to add dataField "%s" as a duplicate to
                            the allDataFields_ hash %O. DataSource "%s" already owns it`,
                        fieldName, this.allDataFields_, this.allDataFields_[fieldName]);
                    }
                });
            } else {
                console.warn(`Got DataSource "${sourceId}" wo defined dataFields`);
            }
        });
    };

    /**
     * Gets a DataSource instance based on the field name it provides or by DataSource.id.
     * @param {string} name - Name(id) of dataSource or dataField.
     * @returns {DataSource|undefined}
     */
    UpdatableBase.prototype.getDataSourceByFieldName = function(name) {
        return name in this.allDataSources_ && name in this.dataSources_ &&
            this.dataSources_[name] ||
            name in this.dataFields_ && this.dataFields_[name] in this.dataSources_ &&
            this.dataSources_[this.dataFields_[name]] || undefined;
    };

    /**
     * Activates data sources and subscribes to their fields. List of {@link
     * DataSourceConfig} ids or field names can be passed. Instead of strings objects
     * can be passed as arguments which will be used as constructor or subscribe argument for
     * data source instantiation or fields subscription.
     * @param fields {DataSourceConfig[]|DataSourceConfig|DataSourceFieldConfig|
     *     DataSourceFieldConfig[]|string|string[]} - Name or config object for data source
     *     or field name. Array of those can be passed.
     * @param {boolean=} dontLoad - Set to true to avoid immediate loading of new DataSources or
     *     DataFields. Remember that each DataSource config can redefine it's `dontLoad` flag
     *     for that particular DS.
     * @returns {ng.$q.promise}
     */
    //TODO order fields taking in account dependsOn property since it is causing ds.load()
    UpdatableBase.prototype.subscribe = function(fields, dontLoad) {
        const fieldsToBeAdded = {};//sourceName: array of fields
        const updateInterval = this.isStatic_ ? 0 : Timeframe.selected().interval;

        let promise = $q.when(true);

        dontLoad = !angular.isUndefined(dontLoad) ? Boolean(dontLoad) : false;

        if (this.isDestroyed()) {
            return $q.reject('Can\'t subscribe on destroyed instance');
        }

        if (!angular.isArray(fields) &&
            (angular.isObject(fields) || angular.isString(fields))) {
            fields = [fields];
        }

        if (angular.isArray(fields)) {
            //field can be name of source or actual source.field
            fields.forEach(field => {
                const
                    id = field.id || field,
                    isFieldName = !(id in this.allDataSources_) && id in this.allDataFields_,
                    sourceId = isFieldName ? this.allDataFields_[id] : id;

                let
                    newSourceSpec,
                    DataSourceClass,
                    newSourceConfig;

                if (sourceId in this.allDataSources_) {
                    if (!(sourceId in this.dataSources_)) {
                        newSourceSpec = this.allDataSources_[sourceId];
                        newSourceConfig = {
                            id: sourceId,
                            owner: this,
                            updateInterval,
                            isStatic: this.isStatic_,
                            transport: newSourceSpec.transport,
                            transformer: newSourceSpec.transformer,
                            dependsOn: newSourceSpec.dependsOn,
                            loadOnCreate: !dontLoad,
                        };

                        if (isFieldName) {
                            newSourceConfig.fields = [];
                            newSourceConfig.fields.push(field);
                        } else if (angular.isObject(field)) {
                            newSourceConfig = angular.extend({}, newSourceConfig, field);
                        }

                        DataSourceClass = $injector.get(newSourceSpec.source);

                        this.dataSources_[sourceId] = new DataSourceClass(newSourceConfig);

                        this.addDataFieldsOfSource_(sourceId);
                    } else if (isFieldName) {
                        if (!(sourceId in fieldsToBeAdded)) {
                            fieldsToBeAdded[sourceId] = [];
                        }

                        fieldsToBeAdded[sourceId].push(field);
                    }
                    //TODO add ability to update DataSource config through subscribe?
                } else {
                    console.warn('DataField or dataSource "%s" was not found in a hash %O',
                        id, this.allDataSources_);
                }
            });

            _.each(fieldsToBeAdded, (fields, sourceId) => {
                this.dataSources_[sourceId].subscribe(fields, dontLoad);
            });
        } else {
            promise = $q.reject('no fields to add');
        }

        return promise;
    };

    /**
     * Updates {@link UpdatableBase#dataFields_} hash with the {@link DataSource} fields.
     * @param {string} sourceId
     * @protected
     */
    UpdatableBase.prototype.addDataFieldsOfSource_ = function(sourceId) {
        if (sourceId in this.allDataSources_ &&
            angular.isArray(this.allDataSources_[sourceId].fields)) {
            this.allDataSources_[sourceId].fields.forEach(fieldName => {
                if (!(fieldName in this.dataFields_)) {
                    this.dataFields_[fieldName] = sourceId;
                } else {
                    console.error(`UpdatableBase is trying to add dataField "%s" as a duplicate to
                        the dataFields_ hash %O. DataSource "%s" already owns it.`,
                    fieldName, this.dataFields_, this.dataFields_[fieldName]);
                }
            });
        }
    };

    /**
     * Removes fields provided by DataSource of passed id from {@link UpdatableBase#dataFields_}
     * hash.
     * @param {string} sourceId
     * @protected
     */
    UpdatableBase.prototype.removeDataFieldsOfSource_ = function(sourceId) {
        const keysToRemove = [];

        if (sourceId in this.allDataSources_) {
            _.each(this.dataFields_, (source, fieldName) => {
                if (sourceId === source) {
                    keysToRemove.push(fieldName);
                }
            });
        }

        keysToRemove.forEach(key => delete this.dataFields_[key]);
    };

    /**
     * Removes fields from {@link DataSource} or data source itself from the list of
     * active data sources. Also destroys data source if it becomes `inactive` after field
     * removal.
     * @param {Object|string|Object[]|string[]} fields - FieldName or data source id can be
     *     passed as strings or objects with id properties.
     * @returns {ng.$q.promise}
     */
    //TODO support subscriber's name only
    //TODO can get some fields and corresponding dataSource at the same time. Who wins?
    UpdatableBase.prototype.unSubscribe = function(fields) {
        const fieldsToBeRemoved = {};//sourceName: array of fields

        if (!angular.isArray(fields) &&
            (angular.isObject(fields) && 'id' in fields || angular.isString(fields))) {
            fields = [fields];
        }

        if (angular.isArray(fields)) {
            fields.forEach(field => {
                const
                    id = field.id || field,
                    isFieldName = !(id in this.allDataSources_) && id in this.allDataFields_,
                    sourceId = isFieldName ? this.allDataFields_[id] : id;

                if (sourceId in this.dataSources_) {
                    if (isFieldName) {
                        if (!(sourceId in fieldsToBeRemoved)) {
                            fieldsToBeRemoved[sourceId] = [];
                        }

                        fieldsToBeRemoved[sourceId].push(field);
                    } else {
                        const dataSource = this.dataSources_[sourceId];

                        if (dataSource.isPreserved) {
                            dataSource.reset();
                        } else {
                            dataSource.destroy();
                            this.removeDataFieldsOfSource_(sourceId);
                            delete this.dataSources_[sourceId];
                        }
                    }
                }
            });
        }

        _.each(fieldsToBeRemoved, (fields, sourceId) => {
            const source = this.dataSources_[sourceId];

            if (source) { //since it could be deleted few iterations ago
                source.unsubscribe(fields);

                if (source.isInactive()) {
                    if (source.isPreserved) {
                        source.reset();
                    } else {
                        source.destroy();
                        this.removeDataFieldsOfSource_(sourceId);
                        delete this.dataSources_[sourceId];
                    }
                }
            }
        });

        return $q.when(true);
    };

    /**
     * Calls load on every `active` data source.
     * @param {string|string[]|Object|Object[]} fields - List or just one data
     *     source id or data field name. For details look into {@link Collection#subscribe} method
     *     arguments.
     * @param {boolean=} emptyData - When true Collection will flush all existent data before
     *     making load.
     * @fires UpdatableBase#"collectionLoad collectionBeforeLoad"
     * @fires UpdatableBase#"collectionLoad collectionLoadSuccess"
     * @fires UpdatableBase#"collectionLoad collectionLoadFail"
     * @returns {angular.$q.promise}
     */
    UpdatableBase.prototype.load = function(fields, emptyData) {
        if (!angular.isUndefined(fields)) {
            this.subscribe(fields, true);
        }

        if (emptyData) {
            this.emptyData();
        }

        //TODO trigger events on DataSource, let the user decide how to react

        /**
         * Triggers event before proceeding with load
         * @event UpdatableBase#"collectionLoad collectionBeforeLoad"
         **/
        this.trigger('collectionLoad collectionBeforeLoad');

        return this.groupDataSourceMethodCall_('load')
            .then(() => {
                // this might happen when load was skipped by isActive
                //FIXME triggered twice: here and by ListCollDataSource
                if (!this.isDestroyed()) {
                    /**
                     * Triggers on successful load
                     * @event UpdatableBase#"collectionLoad collectionLoadSuccess"
                     **/
                    this.trigger('collectionLoad collectionLoadSuccess');
                }
            }, e => {
                if (!this.isDestroyed()) {
                    /**
                     * Triggers on load failure
                     * @event UpdatableBase#"collectionLoad collectionLoadFail"
                     **/
                    this.trigger('collectionLoad collectionLoadFail');
                }
            });
    };

    /**
     * @fires UpdatableBase#"dataFlush"
     **/
    UpdatableBase.prototype.emptyData = function() {
        if (!this.isDestroyed_) {
            /**
             * On data flush.
             * @event UpdatableBase#"dataFlush"
             */
            this.trigger('dataFlush');
        }
    };

    /**
     * Cancels running request and stops polling for all DataSources.
     * @returns {ng.$q.promise}
     */
    UpdatableBase.prototype.stopUpdates = function() {
        return this.groupDataSourceMethodCall_('stopUpdates');
    };

    /**
     * Returns a loading state of UpdatableBase.
     * @returns {boolean}
     */
    UpdatableBase.prototype.isBusy = function(...args) {
        return this.getDataSourceByFieldName('config').isBusy(...args);
    };

    /**
     * Passes params to be set on active data sources.
     * @param params {{string: *}} - Key&value pairs.
     */
    UpdatableBase.prototype.setParams = function(params) {
        if (angular.isObject(params)) {
            this.groupDataSourceMethodCall_('setParams', params);
        }
    };

    /**
     * Sets the static flag value.
     * @param {boolean} state
     */
    UpdatableBase.prototype.setStaticFlag = function(state) {
        this.isStatic_ = state;
    };

    /**
     * Gets `params` from the data source providing 'config' data field. If paramName is
     * provided will return value of that particular parameter, otherwise all param key&value pairs
     * will be returned.
     * @param {string=} paramName
     * @returns {*}
     */
    //TODO support getting params from multiple DataSources
    UpdatableBase.prototype.getParams = function(paramName) {
        return this.getDataSourceByFieldName('config').getParams(paramName);
    };

    /**
     * Calls a data source method passing arguments and wraps it into a promise.
     * @param {DataSource} source
     * @param {string} methodName
     * @param {*=} args
     * @returns {ng.$q.promise}
     * @protected
     */
    UpdatableBase.prototype.singleDataSourceMethodCall_ = function(source, methodName, args) {
        let promise;

        if (source && source instanceof DataSource && angular.isFunction(source[methodName])) {
            promise = source[methodName](...args);
        } else {
            const errMsg = `Can't call method "${methodName}" on source "${source && source.id}"`;

            promise = $q.reject(errMsg);

            if (!this.isDestroyed_) { //undefined when Collection got destroyed
                console.warn(errMsg, this.owner_);
            }
        }

        return promise;
    };

    /**
     * Ordered group call for all sources used by Collection. Very basic dependency support -
     * only one for each data source and no multi-level dependencies. Dependants will
     * be called once `parent` data source has been loaded.
     * @param {string} methodName - Name of DataSource method to be called.
     * @returns {ng.$q.promise}
     * @protected
     */
    //TODO check map - seems to be too nested
    UpdatableBase.prototype.groupDataSourceMethodCall_ = function(methodName) {
        const
            args = Array.prototype.slice.call(arguments, 1),
            promises = [],
            sampleDataSource = _.sample(this.dataSources_),
            dependantsHash = {}, //childId: true
            queue = {};//ancestorDepName: [descendantIds]

        if (methodName && angular.isString(methodName) &&
            sampleDataSource && methodName in sampleDataSource) {
            _.each(this.dataSources_, (source, sourceId) => {
                const ancestorDataSource = source['dependsOn'] &&
                        this.getDataSourceByFieldName(source['dependsOn']);

                if (ancestorDataSource) {
                    const ancestorId = ancestorDataSource.id;

                    if (!(ancestorId in queue)) {
                        queue[ancestorId] = [];
                    }

                    dependantsHash[sourceId] = true;

                    queue[ancestorId].push(sourceId);
                } else if (source['dependsOn']) {
                    console.warn(
                        `DependsOn dataField "${source['dependsOn']}" is not found`,
                        this,
                    );
                }
            });

            _.each(queue, (descendants, ancestorId) => {
                const ancestorPromise = this.singleDataSourceMethodCall_(
                    this.dataSources_[ancestorId], methodName, args,
                );

                const descendantPromises = _.map(descendants,
                    descendant => () => this.singleDataSourceMethodCall_(
                        this.dataSources_[descendant], methodName, args,
                    ));

                promises.push(descendantPromises.reduce($q.when, ancestorPromise));
            });

            //ones which don't have dependants and do not depend on anything else
            _.each(this.dataSources_, (source, sourceId) => {
                if (!(sourceId in dependantsHash) && !(sourceId in queue)) {
                    promises.push(this.singleDataSourceMethodCall_(source, methodName, args));
                }
            });
        } else {
            let errMsg;

            if (!sampleDataSource) {
                errMsg = 'No dataSource is registered on the instance';
            } else {
                errMsg = `DataSource "${sampleDataSource.id}" doesn't have ` +
                    `method "${methodName}" to be called`;
            }

            console.error(errMsg);

            return $q.reject(errMsg);
        }

        return $q.all(promises);
    };

    /**
     * Removes all Items from Collection and all non-required data sources.
     **/
    //TODO reset params through data source
    UpdatableBase.prototype.reset = function() {
        const dataSourcesToRemove = [];

        _.each(this.dataSources_, (source, key) => {
            this.unSubscribe(key);

            if (!source.isPreserved) {
                dataSourcesToRemove.push(key);
            }
        });

        dataSourcesToRemove.forEach(dataSourceId => delete this.dataSources_[dataSourceId]);
    };

    /** @override */
    UpdatableBase.prototype.destroy = function() {
        const gotDestroyed = UpdatableBase.superclass.destroy.call(this);

        if (gotDestroyed) {
            //removes even required ones
            _.each(this.dataSources_, (source, key) => {
                this.unSubscribe(key);
                source.destroy(true);
                this.removeDataFieldsOfSource_(key);
            });
            this.dataSources_ = {};
        }

        return gotDestroyed;
    };

    return UpdatableBase;
}

updatableBaseFactory.$inject = [
    '$injector',
    '$q',
    'Base',
    'Timeframe',
    'aviInherit',
    'DataSource',
];

angular.module('core.vantage.avi')
    .factory('UpdatableBase', updatableBaseFactory);
