/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  ProtocolParserCollection
 * @description  Collection of {ProtocolParser} items.
 */
const protocolParserCollectionFactory = (Collection, ProtocolParser) => {
    class ProtocolParserCollection extends Collection {}

    Object.assign(ProtocolParserCollection.prototype, {
        objectName_: 'protocolparser',
        itemClass_: ProtocolParser,
        windowElement_: 'protocol-parser-modal',
    });

    return ProtocolParserCollection;
};

protocolParserCollectionFactory.$inject = [
    'Collection',
    'ProtocolParser',
];

angular.module('aviApp').factory('ProtocolParserCollection', protocolParserCollectionFactory);
