/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('ModalAlertController', ['$scope', 'AviAlertService',
function($scope, AviAlertService) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.messages = AviAlertService.messages;

    setTimeout(function() {
        const scrollableListElement = $($scope.container).find('.error-list ul.scrollable');
        const scrollableListHtmlElement = scrollableListElement.get(0);

        if (scrollableListElement && scrollableListHtmlElement) {
            scrollableListElement.scrollTop(scrollableListHtmlElement.scrollHeight);
        }
    }, 0);

    $scope.stringifyMessage = function(message) {
        let res = message.text;

        if (message.count > 1) {
            res = `(${message.count}) ${res}`;
        }

        return res;
    };

    $scope.dismiss = function() {
        AviAlertService.clear();
        $scope.closeModal();
    };
}]);
