/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const READ_ACCESS = 'READ_ACCESS';

/**
 * @typedef {Object} statePermissionTree
 * @property {string} MAIN_MENU
 * @property {string} USER_MENU
 * @property {string} HELP
 * @property {string} NAVBAR
 * @property {string[]} APPLICATION
 * @property {string[]} OPERATIONS
 * @property {Array.<string, Object>} TEMPLATE
 * @property {Array.<string, Object>} INFRASTRUCTURE
 * @property {Array.<string, Object>} ADMINISTRATION
 */

/**
 * @alias module:services/statePermissionTreeService
 * @private
*/
class statePermissionTreeService {
    constructor(appStateTree) {
        this.appStates_ = appStateTree;
        /**
         * Map of custom permissions, that are used in UI, that depend on
         * server-defined permissions.
         *
         * Mapping: <CUSTOM_PERMISSION>: [<LIST_OF_SERVER_PERMISSIONS>]
         * @type {statePermissionTree|null}
         * @protected
         */
        this.statePermissionTree_ = null;
    }

    /**
     * Returns statePermissionTree.
     * Generates the same if its not generated already.
     * @returns {statePermissionTree} this.statePermissionTree_
     * @public
     */
    getStatePermissionTree() {
        if (!this.statePermissionTree_) {
            this.generatePermissionTree_();
        }

        return this.statePermissionTree_;
    }

    /**
     * Generates statePermissionTree from appStates.
     * @protected
     */
    generatePermissionTree_() {
        const {
            appStates_: appStates,
        } = this;

        const statePermissionTree = {};

        _.each(appStates, state => {
            const rootPermission = state.data && state.data.permission;

            const parentChildPermissionMap =
                this.getChildPermissions_(state.children, rootPermission, true);

            Object.assign(statePermissionTree, parentChildPermissionMap);
        });

        this.addUIOnlyPermissions_(statePermissionTree);

        this.statePermissionTree_ = statePermissionTree;
    }

    /**
     * Adds a permission to the list.
     * Only if the permission is not present already.
     * @param {Array.<string,Object>} permissionList
     * @param {string} permission
     * @protected
     */
    addPermission_(permissionList, permission) {
        if (permission && !_.includes(permissionList, permission)) {
            permissionList.push(permission);
        }
    }

    /**
     * Recursively collects permissions from all child states.
     * @param {Array} states
     * @param {string} parentPermission
     * @param {boolean} isRoot true, if we have to find children of this state
     * @return {Object|string[]|string}
     * @protected
     */
    getChildPermissions_(states = [], parentPermission, isRoot) {
        const permissionMap = {};
        const permissionList = [];
        const childPermissionsMap = {};

        _.each(states, state => {
            const currentStatePermission = state.data && state.data.permission;

            if (isRoot) {
                // No need to look for children of this states
                // as the resulting tree is only two level deep.
                const childPermissions =
                    this.getChildPermissions_(state.children, currentStatePermission, false);

                if (Array.isArray(childPermissions)) {
                    permissionList.push(...childPermissions);
                } else if (_.isObject(childPermissions)) {
                    Object.assign(childPermissionsMap, childPermissions);
                } else if (childPermissions) {
                    this.addPermission_(permissionList, childPermissions);
                }
            } else if (currentStatePermission) {
                this.addPermission_(permissionList, currentStatePermission);
            }
        });

        if (isRoot && !_.isEmpty(childPermissionsMap)) {
            permissionList.push(childPermissionsMap);
        }

        if (parentPermission && !_.isEmpty(permissionList)) {
            permissionMap[parentPermission] = _.uniq(permissionList);

            return permissionMap;
        } else if (!_.isEmpty(permissionList)) {
            return _.uniq(permissionList);
        } else if (parentPermission) {
            return parentPermission;
        }
    }

    /**
     * UI only permissions (for Main menu, Help..) will not be present in appState.
     * This method adds them to generated statePermissionTree Object.
     * @param {Object<string, Array>} statePermissionTree
     * @protected
     */
    addUIOnlyPermissions_(statePermissionTree) {
        const otherPermissions = {
            MAIN_MENU: READ_ACCESS,
            USER_MENU: READ_ACCESS,
            HELP: READ_ACCESS,
            NAVBAR: READ_ACCESS,
        };

        Object.assign(statePermissionTree, otherPermissions);
    }
}

statePermissionTreeService.$inject = [
    'appStateTree',
];

/**
 * @ngdoc service
 * @name statePermissionTreeService
 * @module services/statePermissionTreeService
 * @description
 *      Service used to generate and get statePermissionTree.
 * @author Aravindh Nagarajan
 */
angular.module('aviApp')
    .service('statePermissionTreeService', statePermissionTreeService);
