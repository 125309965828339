/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @fileoverview It contain constants related to Avi Cloud connection and registration status.
 */

const AVICLOUD_CONNECTED = 'AVICLOUD_CONNECTED';
const AVICLOUD_DISCONNECTED = 'AVICLOUD_DISCONNECTED';
const AVICLOUD_REGISTERED = 'AVICLOUD_REGISTERED';
const AVICLOUD_DEREGISTERED = 'AVICLOUD_DEREGISTERED';

export const portalStatus = {
    AVICLOUD_CONNECTED,
    AVICLOUD_DISCONNECTED,
    AVICLOUD_REGISTERED,
    AVICLOUD_DEREGISTERED,
};

export const defaultPortalUrl = 'aviportal.avinetworks.com';

/**
 * Duration between refresh status api call and configuration update.
 * @type {number}
 */
export const configUpdateDuration = 5000;
