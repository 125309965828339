/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('sideColumnCollapseButton', [
'$rootScope', 'myAccount',
function($rootScope, myAccount) {
    function sideColumnCollapseButtonLink(scope, element) {
        element
            .addClass('button-side-column')
            .on('click', () => myAccount.toggleSidebar());

        scope.$watch(
            () => myAccount.uiProperty.sideRailOpen,
            newValue => element.toggleClass('collapsed', !newValue),
        );
    }

    return {
        scope: false,
        link: sideColumnCollapseButtonLink,
        template: '<i class="icon icon-cancel-1"></i><i class="icon icon-chevron-left"></i>',
    };
}]);
