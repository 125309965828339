/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/profile/autoscale.less';

/**
 * @ngdoc controller
 * @description
 *     Pool AutoScale modal popup controller.
 */
angular.module('aviApp').controller('PoolAutoScalePolicyController', PoolAutoScalePolicyController);
PoolAutoScalePolicyController.$inject = ['$scope', 'Regex', 'AlertConfigCollection', 'Schema'];

/**
 * Pool AutoScale controller constructor.
 * @param {angular.$scope} $scope
 * @param {Regex} Regex - Regex service.
 * @param {AlertConfigCollection} AlertConfigCollection
 * @constructor
 */
function PoolAutoScalePolicyController($scope, Regex, AlertConfigCollection, Schema) {
    $scope.$parent.modalScope = $scope;

    this.$scope = $scope;
    this.Regex = Regex;
    this.Schema = Schema;
    this.alertConfigCollection = new AlertConfigCollection({ sortBy: 'name' });
}
