/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const controllerSiteCollectionFactory = (Collection, ControllerSite) => {
    class ControllerSiteCollection extends Collection {}

    Object.assign(ControllerSiteCollection.prototype, {
        objectName_: 'controllersite',
        itemClass_: ControllerSite,
    });

    return ControllerSiteCollection;
};

controllerSiteCollectionFactory.$inject = [
    'Collection',
    'ControllerSite',
];

/**
 * @ngdoc factory
 * @name  ControllerSiteCollection
 * @description  Collection of {ControllerSite} items.
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory('ControllerSiteCollection', controllerSiteCollectionFactory);
