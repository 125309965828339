/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name CloudListController
 */
angular.module('aviApp').controller('CloudListController', [
'$scope',
'CRUDGridConfig',
'CloudCollection',
'$templateCache',
'$rootScope',
'Schema',
'$http',
'Base',
'AviModal',
'CloudType',
'cloudService',
'myAccount',
'messageService',
'$compile',
'Auth',
'$timeout',
'AviAlertService',
'systemInfoService',
'$window',
function(
    $scope,
    CRUDGridConfig,
    CloudCollection,
    $templateCache,
    $rootScope,
    Schema,
    $http,
    Base,
    AviModal,
    CloudType,
    cloudService,
    myAccount,
    messageService,
    $compile,
    Auth,
    $timeout,
    AviAlertService,
    systemInfo,
    $window,
) {
    $scope.gridConfig = new CRUDGridConfig();

    $scope.ui = {};
    $scope.ui.gridBusyState = {
        isBusy: false,
        message: undefined,
    };

    $scope.Schema = Schema;
    $scope.myAccount = myAccount;
    $scope.messageService = messageService;

    const base = new Base();

    const linuxServerHostsGridConfig = {
        singleactions: null,
        multipleactions: null,
        rowId: row => row.host,
        fields: [{
            name: 'host',
            title: 'Host',
        }, {
            name: 'host_state',
            title: 'Host State',
            template:
                `<div ng-if="row.host_state">
                    <strong>Status:</strong> {{ row.host_state | enum: "CC_HOST_" }}
                </div>
                <div ng-if="row.host_reason">
                    <strong>Reason:</strong> {{ row.host_reason }}
                </div>`,
        }, {
            name: 'se_state',
            title: 'Service Engine State',
            template:
                `<div ng-if="row.se_state">
                    <strong>Status:</strong> {{ row.se_state | enum: "CC_HOST_" }}
                </div>
                <div ng-if="row.host_reason">
                    <strong>Reason:</strong> {{ row.se_reason[0] }}
                </div>`,
        }],
        layout: {
            hideEditColumns: true,
            hideSearch: true,
        },
    };

    /**
     * Data for the cloud token message popup.
     * @type {{string: string}}
     */
    $scope.cloudTokenMessageData = {
        token: '',
        cloudName: '',
        tenantName: '',
    };

    if (Auth.isAllowed('PERMISSION_SE_TOKEN')) {
        $http.get('/api/cluster/').then(({ data }) => {
            $scope.cloudTokenMessageData.clusterUuid = data.uuid;
        });
    }

    /** @type {string} */
    const noAccessCloudTokenTemplate = require('./cloud-token-popup.partial.html');

    const noAccessCloudTokenCompiled = $compile(noAccessCloudTokenTemplate)($scope);

    /** @type {Element} */
    const messagesElement = document.querySelector('.messages');

    if (messagesElement) {
        messagesElement.appendChild(noAccessCloudTokenCompiled[0]);
    }

    /**
     * Handles 'collectionLoad' event from CloudCollection.
     */
    const checkCollectionStateHandler = function() {
        if (cloudCollection.getNumberOfItems()) {
            cloudService.displayCloudDataStatus(cloudCollection.items);
        }
    };

    /**
     * Handles Input element click event to select all text inside the element.
     * @param {MouseEvent} event
     */
    $scope.focusInput = function(event) {
        const { target } = event;

        if (target instanceof HTMLInputElement) {
            target.trigger('focus');
            target.select();
        }
    };

    const cloudCollection = new CloudCollection();

    cloudCollection.bind('collectionLoadSuccess', checkCollectionStateHandler);

    $scope.gridConfig.collection = cloudCollection;
    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'type',
        title: 'Type',
        template: '<span class="sel-vtype">{{ row.getVtype() | enumText: "CloudType" }}</span>',
    }, {
        name: 'state',
        require: 'status',
        title: 'Status',
        template: '<cloud-status-icon cloud="row"></cloud-status-icon>',
    }];

    // since getLinuxHostsStatus is async can be the case when callback gets executed after
    // previous $timeout has been cancelled
    let expanderId = 0,
        linuxHostsPollPromise;

    const getLinuxHostsStatus = function(gridScope, cloudId) {
        const currentExpanderId = expanderId;

        gridScope.linuxServerHostsGridConfig = linuxServerHostsGridConfig;

        return $http.get(`/api/cloud/${cloudId}/hosts`)
            .then(function(response) {
                if (expanderId === currentExpanderId) {
                    linuxServerHostsGridConfig.rows = response.data.hosts;
                    linuxHostsPollPromise = $timeout(
                        getLinuxHostsStatus, 1000, true, gridScope, cloudId,
                    );
                }
            });
    };

    $scope.gridConfig.executeBeforeContainerExpand = function(row) {
        if (row.data.config.vtype === CloudType[CloudType.CLOUD_LINUXSERVER]) {
            getLinuxHostsStatus(this, row.data.config.uuid);
        }
    };

    $scope.gridConfig.executeOnExpandDestroy = function() {
        expanderId++;
        $timeout.cancel(linuxHostsPollPromise);
    };

    $scope.gridConfig.singleactions.push({
        title: 'Convert to No-Access',
        class: 'icon-block sel-block',
        dontCloseExpander: true,
        do(cloud) {
            if ($window.confirm('WARNING: This action will destroy all SEs created by ' +
                    'this controller within this cloud. Convert to No-Access?')) {
                cloud.setCloudNone().catch(response => {
                    $window.alert(response.data.error);
                    cloud.load();
                });
            }
        },
        hidden(row) {
            return CloudType[row.data.config.vtype] === CloudType.CLOUD_NONE;
        },
    }, {
        title: 'Download SE Image',
        dontCloseExpander: true,
        bypassPermissionsCheck: true,
        template: require('./se-image-picker.partial.html'),
        hidden: cloud => {
            const vtype = cloud.getVtype();

            return !Auth.isAllowed('PERMISSION_CLOUD', 'w') ||
                !(CloudType[vtype] === CloudType.CLOUD_NONE ||
                CloudType[vtype] === CloudType.CLOUD_VCENTER &&
                cloud.getCloudConfig().privilege === 'READ_ACCESS');
        },
    }, {
        title: 'Install LBaaS Plugin',
        dontCloseExpander: true,
        class: 'icon-plug-1',
        do(row) {
            AviModal.open('adm-sysconf-os-install-plugin', {
                cloudId: row.id,
            });
        },
        hidden(row) {
            return CloudType[row.data.config.vtype] !== CloudType.CLOUD_OPENSTACK;
        },
    }, {
        title: 'Generate Token',
        dontCloseExpander: true,
        class: 'icon-key-inv',
        bypassPermissionsCheck: true,
        do(row) {
            $http.get(`/api/securetoken-generate?cloud_uuid=${row.id}`).then(function(response) {
                const msgData = $scope.cloudTokenMessageData;

                msgData.token = response.data.auth_token;
                msgData.cloudName = row.getName();
                msgData.tenantName = Auth.context.tenant_ref;
                messageService.show('noAccessCloudTokenMessage');
            });
        },
        hidden(row) {
            return !Auth.isAllowed('PERMISSION_SE_TOKEN') ||
                !(CloudType[row.data.config.vtype] === CloudType.CLOUD_NONE ||
                CloudType[row.data.config.vtype] === CloudType.CLOUD_VCENTER &&
                    row.data.config.vcenter_configuration.privilege === 'READ_ACCESS');
        },
    }, {
        class: 'icon-refresh',
        title: 'Rediscover',
        hidden: row => CloudType[row.getVtype()] !== CloudType.CLOUD_VCENTER,
        do: row => row.vCenterRediscover(),
    });

    $scope.gridConfig.expandedContainerTemplate = require(
        './cloud-list-grid-expander.partial.html',
    );

    $scope.gridConfig.expanderDisabled = function(row) {
        return row.getConfig().vtype === CloudType[CloudType.CLOUD_AZURE];
    };

    /**
     * Allows user to download SE image by making POST request followed by assigning a URL to the
     * window. Can cancel previous requests if clicked multiple times.
     * @param  {Cloud}  row - Cloud object.
     * @param  {String} format - format of image, either 'ova' or 'qcow2'.
     */
    const downloadSEImage = (function() {
        let requestCancelled = false;

        function cancelDownloadSEImage() {
            requestCancelled = true;
            base.cancelRequests('downloadSEImage');
        }

        return function(row, format) {
            if (isGridBusy()) {
                cancelDownloadSEImage();
            }

            const { config } = row.data,
                api = '/api/fileservice/seova',
                payload = {
                    file_format: format,
                };

            if (config.name !== 'Default-Cloud') {
                payload.cloud_uuid = config.uuid;
            }

            setGridBusyState(true, 'Creating SE Image for Download.');

            base.request('POST', api, payload, null, 'downloadSEImage')
                .then(function(rsp) {
                    setGridBusyState(false);

                    const getAPI = `${api}?${_.reduce(payload, function(acc, value, key) {
                        return `${acc + key}=${value}&`;
                    }, '')}`;

                    $window.location.assign(getAPI);
                }).catch(function(rsp) {
                    if (rsp.data && rsp.data.error) {
                        AviAlertService.throw(rsp.data.error);
                    }
                }).finally(function() {
                    if (!requestCancelled) {
                        setGridBusyState(false);
                    } else {
                        requestCancelled = false;
                    }
                });
        };
    })();

    /**
     * Sets gridBusyState for CollectionGrid.
     * @param  {Boolean} busy - True to set to busy, false otherwise.
     * @param  {String}  message - Message to be displayed next to spinner.
     */
    function setGridBusyState(busy, message) {
        const { ui } = $scope;

        ui.gridBusyState.isBusy = busy;
        ui.gridBusyState.message = message;
    }

    /**
     * Returns true if CollectionGrid is busy.
     * @return {Boolean}
     */
    function isGridBusy() {
        return $scope.ui.gridBusyState.isBusy;
    }

    $scope.gridConfig.downloadSEImage = downloadSEImage;

    $scope.$on('$destroy', () => {
        cloudCollection.destroy();
        base.destroy();
        messageService.remove('noAccessCloudTokenMessage');
    });
}]);
