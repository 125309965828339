/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './update-card-progress.component.less';
import { UpgradeService } from 'services/upgrade/upgrade.service';

/**
 * @constructor
 * @memberOf module:avi/upgrade
 */
class UpdateCardProgressController {
    constructor($element, $scope, UpgradeNodeList) {
        this.$element = $element;
        this.$scope_ = $scope;
        this.upgradeNodeList_ = new UpgradeNodeList();
        this.upgradeNodeList_.load();
    }

    /**
     * @listens module:avi/upgrade.UpgradeService#UPGRADE_TRIGGER_EVENT
     * @override
     */
    $onInit() {
        this.$element.addClass('update-card-progress');
        this.$scope_.$on(UpgradeService.UPGRADE_TRIGGER_EVENT, this.handleUpgradeTrigger);
    }

    /**
     * Updates tile model upon upgrade trigger.
     */
    handleUpgradeTrigger = () => {
        this.upgradeNodeList_.load();
    };

    /**
     * Fetches percentage controller update completed.
     * @return {number}
     * TODO: Enable once API supports
     */
    /*
    getControllerProgressPercentage() {
        return this.upgradeNodeList_.getControllerProgressPercentage();
    }
    */

    /**
     * Informs whether controller is currently in-progress state.
     * @return {boolean}
     */
    controllerUpdateIsInProgress() {
        return !!this.upgradeNodeList_.getControllerNode();
    }

    /**
     * Fetches number of seGroups currently in-progress state.
     * @return {number}
     */
    getSeGroupCount() {
        return this.upgradeNodeList_.getSeGroupCount();
    }

    /**
     * Fetches version which controller is attempting to chagne to.
     * @return {string}
     */
    //TODO: Enable once API supports
    /*
    getNextControllerVersion() {
        const controllerNode = this.upgradeNodeList_.getControllerNode();

        return controllerNode.nextVersionOrWhatever;
    }
     */

    /**
     * Informs whether at least one seGroup are in-progress state.
     * @return {boolean}
     */
    seGroupsAreInProgress() {
        return !!this.getSeGroupCount();
    }

    /**
     * Informs whether something (at least one node of type: controller or seGroup) is in progress.
     * @return {boolean}
     */
    nothingIsInProgress() {
        return !this.seGroupsAreInProgress() && !this.controllerUpdateIsInProgress();
    }

    /** @override */
    $onDestroy() {
        this.upgradeNodeList_.destroy();
    }
}

UpdateCardProgressController.$inject = [
    '$element',
    '$scope',
    'UpgradeNodeList',
];

const componentTag = 'update-card-progress';
const templateUrl =
    'src/components/pages/administration/controller/system-update/update-card/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @name updateCardProgressComponent
 * @ngdoc component
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.UpdateCardProgressController} controller
 * @author Akul Aggarwal
 * @description Progress card used in update page, showing SEs and Controllers in-progress status.
 */
angular.module('avi/upgrade').component('updateCardProgress', {
    controller: UpdateCardProgressController,
    templateUrl,
});
