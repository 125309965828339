/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name LogRecord
 * @description
 *
 *     Log record Item.
 */
angular.module('aviApp').factory('LogRecord', ['Item',
function(Item) {
    class LogRecord extends Item {
        /** override */
        getIdFromData_({ config: record }) {
            return [
                record['service_engine'],
                record['vcpu_id'],
                LogRecord.getLogType_(record),
                record['log_id'],
                record['report_timestamp'],
            ].join('/');
        }

        /**
         * Returns the log record type.
         * @param {Object} record
         * @returns {string} - 'adf'/'udf'/'ndf'
         * @static
         * @inner
         */
        static getLogType_(record) {
            return 'adf' in record && 'adf' || 'udf' in record && 'udf' || 'ndf';
        }
    }

    LogRecord.prototype.objectName = 'analytics/logs';

    return LogRecord;
}]);
