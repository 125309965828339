/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { isEmpty } from 'underscore';
import './ipam-dns-profiles-modal.less';

const IPAMDNS_TYPE_INTERNAL_DNS = 'IPAMDNS_TYPE_INTERNAL_DNS';
const IPAMDNS_TYPE_AWS = 'IPAMDNS_TYPE_AWS';
const IPAMDNS_TYPE_AWS_DNS = 'IPAMDNS_TYPE_AWS_DNS';
const IPAMDNS_TYPE_INFOBLOX = 'IPAMDNS_TYPE_INFOBLOX';
const IPAMDNS_TYPE_INFOBLOX_DNS = 'IPAMDNS_TYPE_INFOBLOX_DNS';
const IPAMDNS_TYPE_CUSTOM_DNS = 'IPAMDNS_TYPE_CUSTOM_DNS';
const IPAMDNS_TYPE_AZURE = 'IPAMDNS_TYPE_AZURE';
const IPAMDNS_TYPE_AZURE_DNS = 'IPAMDNS_TYPE_AZURE_DNS';

class IpamDnsProfilesModalController {
    constructor(
        Schema,
        Auth,
        Regex,
        SubnetListNetworkCollection,
        CustomIpamDnsCollection,
        CloudCollection,
        dropDownUtils,
    ) {
        this.Schema = Schema;
        this.Auth = Auth;
        this.Regex = Regex;

        this.cloudCollection = new CloudCollection();
        this.customIpamDnsCollection = new CustomIpamDnsCollection();
        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
        this.cloudRef = '';

        this.dropDownUtils_ = dropDownUtils;
    }

    /** @override */
    $onInit() {
        this.step = 0;

        /**
         * Indicate whether credentials of a certain profile are being edited or not. Can be set to
         * true only when the profile supports credential verification.
         * @type {boolean}
         */
        this.editingCredentials = false;

        // IPAMDNS_TYPE_AWS, IPAMDNS_TYPE_AWS_DNS properties.
        this.useProxy = false;
        this.useIamAssumeRole = false;
        this.iamAssumeRoles = [];
        this.awsAvailabilityZones = [];
        this.awsAvailabilityZonesHash = {};
        // IPAMDNS_TYPE_AZURE properties.
        this.azureCredentialsType = 'userpass';
        this.azureVirtualNetworks = [];
        this.azureResourceGroups = [];
        this.azureNetworks = [];
        this.azureDomains = [];

        /**
         * Used for usable subnets in Infoblox IPAM Profile.
         * @type {AvailableInfobloxSubnets}
         */
        this.availableInfobloxSubnets = {
            v4: [],
            v6: [],
        };

        /**
         * Used for usable domain dropdown in infoblox dns ipam profile.
         * @type {DropDownOption[]}
         */
        this.infobloxDnsProfileDomainList = [];

        const { valuesList } = this.Schema.enums.IpamDnsType;
        const profileType = this.getProfileType();

        /**
         * Filters the available options in the Type dropdown based on the profile type.
         * For IPAM type, shows only IPAM profile options and for DNS type, the list would contain
         * only the DNS profile type options.
         */
        if (profileType.includes('_DNS')) {
            this.types = valuesList.filter(({ value }) => value.includes('_DNS'));
            this.editable.addNsRecord();
        } else {
            this.types = valuesList.filter(({ value }) => !value.includes('_DNS'));
        }

        if (this.editable.id) {
            const config = this.editable.getConfig();

            switch (profileType) {
                case IPAMDNS_TYPE_AWS:
                case IPAMDNS_TYPE_AWS_DNS: {
                    const { aws_profile: awsProfile } = config;

                    if (!angular.isUndefined(awsProfile.getConfig().iam_assume_role)) {
                        this.useIamAssumeRole = true;
                    }

                    awsProfile.getRegions()
                        .then(regions => this.awsRegions = regions);

                    break;
                }

                case IPAMDNS_TYPE_AZURE:
                case IPAMDNS_TYPE_AZURE_DNS:
                    if (isEmpty(config.azure_profile.azure_userpass)) {
                        this.azureCredentialsType = 'serviceprincipal';
                    }

                    break;

                case IPAMDNS_TYPE_INFOBLOX:
                    this.editable.getInfobloxProfileNetworkList()
                        .then(this.setAvailableInfobloxSubnets_);
                    break;

                case IPAMDNS_TYPE_INFOBLOX_DNS:
                    this.editable.getInfobloxDnsProfileDomainList()
                        .then(domains => {
                            this.infobloxDnsProfileDomainList = this.getDomainOptions(domains);
                        });
                    break;

                case IPAMDNS_TYPE_CUSTOM_DNS:
                    this.editable.getDomainList()
                        .then(domains => {
                            if (Array.isArray(domains)) {
                                this.domainList = domains.map(domain => {
                                    return {
                                        value: domain,
                                    };
                                });
                            }
                        });
                    break;
            }

            if (!isEmpty(config.proxy_configuration)) {
                this.useProxy = true;
            }
        } else {
            switch (profileType) {
                case IPAMDNS_TYPE_INFOBLOX:
                case IPAMDNS_TYPE_INFOBLOX_DNS:
                case IPAMDNS_TYPE_AZURE_DNS:
                    this.editingCredentials = true;
                    break;
            }
        }
    }

    /** @override */
    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
        this.customIpamDnsCollection.destroy();
    }

    /**
     * Sets Cloud UUID for Network list collection.
     * @param {Cloud} cloud - Cloud instance.
     */
    setCloud(cloud) {
        this.subnetListNetworkCollection.setParams({
            cloud_uuid: cloud.id,
        });
    }

    /**
     * Determine is the controller built on AWS or not.
     * @return {boolean}
     */
    isAWSController() {
        return this.Auth.isAWSCloud();
    }

    /**
     * Called when Next is clicked, applies for AWS and Infoblox types where there are multiple
     * pages.
     * @param  {number} step - Step number
     */
    goToStep(step) {
        const {
            type,
            aws_profile: awsProfile,
            azure_profile: azureProfile,
        } = this.editable.getConfig();

        switch (type) {
            case IPAMDNS_TYPE_AWS:
            case IPAMDNS_TYPE_AWS_DNS:
                if (step === 1) {
                    if (awsProfile && awsProfile.getConfig().vpc_id) {
                        if (type === IPAMDNS_TYPE_AWS_DNS) {
                            this.editable.getAwsLists('domains')
                                .then(([vpcs, domains]) => {
                                    this.awsVpcList = vpcs;
                                    this.awsDomains = domains.map(domain => {
                                        return {
                                            value: domain,
                                        };
                                    });
                                    this.step = step;
                                });
                        } else {
                            this.editable.getAwsLists('zones')
                                .then(([vpcs, zones]) => {
                                    this.awsVpcList = vpcs;
                                    this.setAvailabilityZones(zones);
                                    this.step = step;
                                });
                        }
                    } else {
                        /**
                         * Only get VPCs list if user is creating a new AWS profile and doesn't
                         * have a vpc_id already set, since the lists of networks and domains
                         * change when the VPC selected changes.
                         */
                        this.editable.getAwsLists()
                            .then(([vpcs]) => {
                                this.awsVpcList = vpcs;
                                this.step = step;
                            });
                    }
                } else {
                    this.step = step;
                }

                break;
            case IPAMDNS_TYPE_AZURE:
                if (step === 1) {
                    // virtual_network_ids is needed to get usable networks. If it exists, we
                    // can get the network list. Otherwise, just get the virtual networks and
                    // resource groups lists.
                    if (azureProfile && !isEmpty(azureProfile.virtual_network_ids)) {
                        if (type === IPAMDNS_TYPE_AZURE) {
                            this.editable.getAzureLists('networks')
                                .then(([virtualNetworks, resourceGroups, networks]) => {
                                    this.azureVirtualNetworks = virtualNetworks;
                                    this.azureResourceGroups = resourceGroups;
                                    this.azureNetworks = networks;
                                    this.step = step;
                                });
                        } else if (type === IPAMDNS_TYPE_AZURE_DNS) {
                            this.editable.getAzureLists('domains')
                                .then(([virtualNetworks, resourceGroups, domains]) => {
                                    this.azureVirtualNetworks = virtualNetworks;
                                    this.azureResourceGroups = resourceGroups;
                                    this.azureDomains = domains;
                                    this.step = step;
                                });
                        }
                    } else {
                        this.editable.getAzureLists()
                            .then(([virtualNetworks, resourceGroups]) => {
                                this.azureVirtualNetworks = virtualNetworks;
                                this.azureResourceGroups = resourceGroups;
                                this.step = step;
                            });
                    }
                } else {
                    this.step = step;
                }

                break;
            default:
                this.step = step;
        }
    }

    /**
     * Called when user changes type of IPAM Profile.
     */
    onTypeChange() {
        this.editingCredentials = false;
        this.goToStep(0);

        const { editable } = this;
        const config = editable.getConfig();
        const { type } = config;

        editable.onTypeChange();

        switch (type) {
            case IPAMDNS_TYPE_AWS:
            case IPAMDNS_TYPE_AWS_DNS: {
                const { aws_profile: awsProfile } = config;

                if (angular.isUndefined(this.awsRegions)) {
                    awsProfile.getRegions()
                        .then(regions => this.awsRegions = regions);
                }

                break;
            }

            case IPAMDNS_TYPE_INTERNAL_DNS:
                this.editable.addNsRecord();
                break;

            case IPAMDNS_TYPE_INFOBLOX:
            case IPAMDNS_TYPE_INFOBLOX_DNS:
            case IPAMDNS_TYPE_AZURE_DNS:
                this.editingCredentials = true;
                break;
        }

        if (type !== 'IPAMDNS_TYPE_INTERNAL') {
            config.allocate_ip_in_vrf = false;
        }
    }

    /**
     * Get ipam profile type.
     * @return {string}
     */
    getProfileType() {
        return this.editable.getType();
    }

    /**
     * Indicates if "Next" button should be displayed for some config types.
     * @returns {boolean}
     */
    hasNext() {
        const { type } = this.editable.getConfig();

        return type.indexOf(IPAMDNS_TYPE_AWS) > -1 || this.editable.isType(IPAMDNS_TYPE_AZURE);
    }

    /**
     * Called on change when user selects between IAM Roles and Access Keys radio buttons.
     * Resets AWS properties.
     */
    onIAMChange() {
        this.editable.getAwsProfile().clearAuthenticationFields();
    }

    /**
     * Called on change when user selects between credentials used for Azure configuration.
     */
    handleAzureCredentialsTypeChange() {
        this.editable.clearAzureCredentials();
    }

    /**
     * Handler for VPC change. Clears existing usable networks and usable domains. Gets the list
     * of domains or networks, depending on the AWS profile type.
     */
    handleVpcChange() {
        const { aws_profile: awsProfile } = this.editable.getConfig();

        awsProfile.clearUsableNetworks();
        awsProfile.clearUsableDomains();

        const config = this.editable.getConfig();

        if (config.type === IPAMDNS_TYPE_AWS_DNS) {
            this.editable.getAwsDomains()
                .then(domains => this.awsDomains = domains);
        } else if (config.type === IPAMDNS_TYPE_AWS) {
            this.editable.getAwsAvailabilityZones()
                .then(zones => this.setAvailabilityZones(zones));
        }
    }

    /**
     * Clears configured usable networks and domains. Called when Virtual Network is cleared or
     * changed.
     */
    clearAzureUsableNetworksAndDomains() {
        this.editable.clearAzureUsableNetworksAndDomains();
    }

    /**
     * Handler for Azure Virtual Network change. Clears existing usable networks since the list
     * of networks is dependent on the Virtual Network. Gets the list of networks.
     */
    handleVirtualNetworksChange() {
        this.clearAzureUsableNetworksAndDomains();

        const { type } = this.editable.getConfig();

        switch (type) {
            case IPAMDNS_TYPE_AZURE:
                this.editable.getAzureNetworks()
                    .then(networks => this.azureNetworks = networks);

                break;
            case IPAMDNS_TYPE_AZURE_DNS:
                this.editable.getDomainList()
                    .then(domains => this.azureDomains = domains)
                    .catch(error => {
                        console.error(`${error}: Azure domains fetch failed.`);
                    });
        }
    }

    /**
     * Handler function for ng-change event on "Use Cross-Account AssumeRole" checkbox. Makes
     * request to get a list of IAM Assume Roles. If an error occurs, unchecks the checkbox.
     */
    handleUseIamAssumeRoleChange() {
        const { aws_profile: awsProfile } = this.editable.getConfig();

        if (this.useIamAssumeRole) {
            this.editable.getAwsIamAssumeRoles()
                .then(iamAssumeRoles => this.iamAssumeRoles = iamAssumeRoles)
                .catch(() => {
                    this.iamAssumeRoles = [];
                    this.useIamAssumeRole = false;
                    awsProfile.removeIamAssumeRole();
                });
        } else {
            awsProfile.removeIamAssumeRole();
        }
    }

    /**
     * Sets this.awsAvailabilityZones and this.awsAvailabilityZonesHash to be used to select
     * usable networks.
     * @param {Object[]} zones - List of availability zones.
     */
    setAvailabilityZones(zones = []) {
        this.awsAvailabilityZones = zones;
        this.awsAvailabilityZonesHash = {};
        zones.forEach(({ availability_zone, subnets }) => {
            this.awsAvailabilityZonesHash[availability_zone] = subnets;
        });
    }

    /**
     * Returns dropdown options for usable domain in infoblox dns profile configuration section.
     * @param {string[]} domains - Domain list.
     * @return {DropDownOption[]} - Dropdown option objects.
     */
    getDomainOptions(domains) {
        let options = [];

        if (Array.isArray(domains)) {
            options = domains.map(domain => this.dropDownUtils_.createOption(domain));
        }

        return options;
    }

    /**
     * Returns true if there is domain optionn list and it's not empty.
     * @return {boolean}
     */
    hasInfobloxDnsProfileDomainList() {
        return this.infobloxDnsProfileDomainList.length > 0;
    }

    /**
     * Update infoblox profile network/domain list by profile type.
     * @param {string} type - IPAMDNS_TYPE_INFOBLOX / IPAMDNS_TYPE_INFOBLOX_DNS
     * @protected
     */
    updateInfobloxProfileSelectableList_(type) {
        switch (type) {
            case IPAMDNS_TYPE_INFOBLOX:
                this.updateInfobloxDnsProfileSubnetList_();
                break;

            case IPAMDNS_TYPE_INFOBLOX_DNS:
                this.updateInfobloxDnsProfileDomainList_();
                break;
        }
    }

    /**
     * Update inboblox dns domain list from raw data.
     * Update related states.
     * @protected
     */
    updateInfobloxDnsProfileDomainList_() {
        this.editable.clearInfobloxDnsProfileUsableDomaintList();
        this.infobloxDnsProfileDomainList = [];
        this.editable.getInfobloxDnsProfileDomainList(false)
            .then(domains => this.infobloxDnsProfileDomainList = this.getDomainOptions(domains));
    }

    /**
     * Update inboblox subnet list from raw data.
     * Update related states.
     * @protected
     */
    updateInfobloxDnsProfileSubnetList_() {
        this.editable.clearInfobloxProfileUsableSubnetList();
        this.infobloxProfileNetworkList = [];
        this.editable.getInfobloxProfileNetworkList(false).then(this.setAvailableInfobloxSubnets_);
    }

    /**
     * Decide behaviors when profile credential editing starts.
     */
    onProfileCredentialEditStart() {
        this.editable.clearProfilePassword();
    }

    /**
     * Decide behaviors when profile credential editing completes.
     */
    onProfileCredentialEditComplete() {
        const type = this.getProfileType();

        switch (type) {
            case IPAMDNS_TYPE_INFOBLOX:
            case IPAMDNS_TYPE_INFOBLOX_DNS:
                this.updateInfobloxProfileSelectableList_(this.getProfileType());
                break;
        }
    }

    /**
     * Verify profile credentials.
     * @param {string} type - Profile type.
     * @return {ng.$q.promise}
     */
    verifyProfileCredentials(type) {
        return this.editable.verifyProfileCredentials(type);
    }

    /**
     * Disable usable domain dropdown if there's no dropdown options or usable domain model data.
     * @return {boolean}
     */
    disableUsableDomainDropdown() {
        const domainList = this.editable.getInfobloxDnsProfileUsableDomainList();

        return !this.hasInfobloxDnsProfileDomainList() && isEmpty(domainList);
    }

    /**
     * Sets this.availableInfobloxSubnets to the retrieved subnets.
     * @param {InfobloxSubnet} subnetsHash - Hash containing v4 and v6 subnets.
     */
    setAvailableInfobloxSubnets_ = (subnetsHash = {}) => {
        this.availableInfobloxSubnets = subnetsHash;
    }

    /**
     * Removes a subnet object from the list of configured subnets.
     * @param {InfobloxSubnet} subnet - Subnet object containing a v4 and/or a v6 subnet.
     */
    removeUsableSubnetByRow(subnet) {
        const { infobloxConfiguredUsableSubnets } = this.editable;
        const indexToRemove = infobloxConfiguredUsableSubnets.indexOf(subnet);

        this.editable.removeInfobloxUsableSubnet(indexToRemove);
    }
}

IpamDnsProfilesModalController.$inject = [
    'Schema',
    'Auth',
    'Regex',
    'SubnetListNetworkCollection',
    'CustomIpamDnsCollection',
    'CloudCollection',
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  ipamDnsProfilesModal
 * @author alextg, Chitra
 * @description  Component for IPAM DNS Profile modal.
 *      It filters and shows the profile type list based on the pre-selected type.
 * @param  {IPAMProfile} editable - IPAMProfile object.
 */
angular.module('aviApp').component('ipamDnsProfilesModal', {
    bindings: {
        editable: '<',
    },
    controller: IpamDnsProfilesModalController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles/' +
        'ipam-dns-profiles-modal/ipam-dns-profiles-modal.html',
});
