/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function revisedDataTransformerFactory(
    Base,
    aviInherit,
) {
    /**
     * @class RevisedDataTransformer
     * @memberOf module:avi/dataModel
     * @constructor
     * @param {Object} args
     * @extends module:avi/dataModel.Base
     * @author Alex Malitsky
     * @desc
     *
     *     Solely data transformation layer above DataTransport. Provides just two methods - one to
     *     transform request parameters and another one to transform backend response. Should not
     *     use reference to owner_ since everything needed for processing must be passed as request
     *     parameters.
     *
     */
    function RevisedDataTransformer(args) {
        RevisedDataTransformer.superconstructor.call(this, args);

        this.id = args.id;
        // USE OF THIS PROPERTY IS A WORKAROUND IN 96% AND STRONGLY DISCOURAGED,
        // pass all needed for processing data through request params and use DataSource for it
        this.owner_ = args.owner;
    }

    aviInherit(RevisedDataTransformer, Base);

    /**
     * Makes some preparations on request parameters before passing them to DataTransport.
     * @param {*=} params
     * @returns {*}
     * @abstract
     */
    RevisedDataTransformer.prototype.getRequestConfig = function(params) {
        return params;
    };

    /**
     * Makes some basic transformation over the backend response before passing it to the
     * DataSource.
     * @param {*} resp - Backend response pre-proccessed by DataTransport.
     * @param {*} request - Request object prepared by DataTransformer#getRequestConfig for the
     *     API call we've got response for.
     * @returns {*}
     * @abstract
     */
    RevisedDataTransformer.prototype.processResponse = function(resp, request) {
        return resp;
    };

    return RevisedDataTransformer;
}

revisedDataTransformerFactory.$inject = [
    'Base',
    'aviInherit',
];

angular.module('aviApp')
    .factory('RevisedDataTransformer', revisedDataTransformerFactory);
