/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridName
 * @param {MetricsDimension} row - MetricsDimension item.
 * @param {function} onSeriesSelect - Function called when name of the filter is clicked.
 * @param {function} disableSelect - Determines if the name of the filter can be clicked.
 * @param {boolean} showTooltip - Determines if the tooltip should be shown, true when the filter is
 *     an attack.
 * @description
 *     Displays the name of the MetricsDimension item, along with a tooltip if applicable. Calls the
 *     onSeriesSelect function when clicked.
 */
class VsSecurityDdosTopListGridCellNameController {
    constructor($templateCache, Schema) {
        this.attackDescriptions_ = Schema.enums.AttackType.values;
        this.attackDescriptionTooltipTemplate = require(
            './vs-security-ddos-top-list-grid-cells-tooltip.partial.html',
        );
    }

    $onInit() {
        this.disabled = false;

        if (this.showTooltip) {
            this.description = this.attackDescriptions_[this.row.getName()]
                .options.e_description.value;
        }
    }

    /**
     * Selects filter when the name is clicked.
     */
    selectSeries() {
        this.onSeriesSelect({
            filter: this.row.getName(),
        });
    }

    /**
     * Returns true if clicking name is disabled.
     * @return {boolean}
     */
    isDisabled() {
        return this.disableSelect({
            filter: this.row.getName(),
        });
    }
}

VsSecurityDdosTopListGridCellNameController.$inject = [
    '$templateCache',
    'Schema',
];

angular.module('security.vs.vantage.avi')
    .component('vsSecurityDdosTopListGridCellName', {
        bindings: {
            row: '<',
            onSeriesSelect: '&',
            disableSelect: '&',
            showTooltip: '<',
        },
        controller: VsSecurityDdosTopListGridCellNameController,
        templateUrl: 'src/components/applications/virtualservice/vs-security-tab/' +
                'vs-security-ddos/vs-security-ddos-top-lists/' +
                'vs-security-ddos-top-list-grid-cells/' +
                'vs-security-ddos-top-list-grid-cell-name.html',
    });
