/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  subtabTab
 * @param {string} title - Title to be used in the list of tabs.
 * @param {boolean} hideTab - Hides the tab if evaluated to true.
 * @description
 *     Child component to be used along with subtabNav. Contains the actual content of the tab.
 * @example
 *     <subtab-nav>
 *         <subtab title="Tab 1">
 *             <div>Tab 1 content</div>
 *         </subtab>
 *         <subtab
 *             title="Tab 2"
 *             hide-tab="$ctrl.isHidden()">
 *             <div>Tab 2 content</div>
 *         </subtab>
 *     </subtab-nav>
 *
 * @typedef {Object} SubtabTab
 * @property {string} title - Title to be used for tab selection.
 * @property {boolean} hideTab - When evaluated to true, hide the tab.
 * @property {Object} helpPopover - Contains 'anchor' and 'position' properties to be passed to the
 *     subtab-nav component.
 */
class SubtabTabController {
    $onInit() {
        this.subtabNavController.addTab(this);
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.hideTab && changes.hideTab.currentValue) {
            this.subtabNavController.resetSelectedTab(this);
        }
    }
}

angular.module('aviApp').component('subtabTab', {
    transclude: true,
    require: {
        subtabNavController: '^subtabNav',
    },
    bindings: {
        tabTitle: '@',
        hideTab: '<',
        helpPopover: '<',
    },
    controller: SubtabTabController,
    template:
            '<div ng-if="$ctrl.subtabNavController.selectedTab === $ctrl" ng-transclude></div>',
});
