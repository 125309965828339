/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import './dns-zones.component.less';

const componentName = 'dns-zones';

/**
 * @typedef DnsZone
 * @memberOf module:avi/profiles/application
 * @property {string} domain_name
 * @property {string|undefined} name_server
 * @property {string|undefined} admin_email
 */

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @mixes module:avi/profiles/application.dnsZonesListComponentBindings
 */
class DnsZonesController {
    constructor(Regex) {
        this.Regex = Regex;
    }

    /**
     * Inserts a plain zone object to zones list.
     */
    addDnsZone() {
        this.dnsZonesList.push({});
    }

    /**
     * Removes a zone from dnsZonesList
     * @param {number} index Index of the zone to be deleted.
     */
    removeDnsZone(index) {
        this.dnsZonesList.splice(index, 1);
    }

    /**
     * Returns true when no. of zones has reached its max limit
     * @return {boolean}
     */
    isMaxLimitReached() {
        return this.dnsZonesList.length > 100;
    }
}

DnsZonesController.$inject = [
    'Regex',
];

/**
 * @name dnsZonesComponent
 * @memberOf module:avi/profiles/application
 * @property {module:avi/profiles/application.dnsZonesListComponentBindings} bindings
 * @property {module:avi/profiles/application.DnsZonesController} controller
 * @description
 *      DNS Zone configuration component.
 *      Used in DNS Application profile.
 * @author Aravindh Nagarajan
 */
angular.module('avi/profiles/application').component('dnsZones', {
    /**
     * @mixin dnsZonesListComponentBindings
     * @memberOf module:avi/profiles/application
     * @property {module:avi/profiles/application.DnsZone[]} dnsZonesList
     */
    bindings: {
        dnsZonesList: '<',
    },
    controller: DnsZonesController,
    templateUrl:
        'src/components/modals/templates/profiles/application-profile/' +
        `${componentName}/${componentName}.component.html`,
});
