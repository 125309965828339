/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBSiteCollection
 * @author Alex Malitsky
 * @description
 *
 *     Collection of GSLB sites coming from GSLB inventory. Since GslbSites are
 *     contained in GSLB configuration all edit operations are executed by GSLBSiteCollection.gslb.
 *
 *     There are two types of Gslb Sites - regular ones and "non avi" (third-party). Different
 *     collections and Gslb methods should be used for each of these types.
 *
 */
angular.module('GSLB.vantage.avi').factory('GSLBSiteCollection', ['Collection', 'GSLBSite',
function(Collection, GSLBSite) {
    /**
     * @constructor
     * @extends Collection
     */
    class GSLBSiteCollection extends Collection {
        constructor(args) {
            super(args);

            this.reload_ = this.reload_.bind(this);
            this.destroy = this.destroy.bind(this);

            this.gslbReloadEvents_ = ['itemLoadSuccess', 'itemSaveSuccess'];

            if (args.gslb) {
                /** @type {GSLB} */
                this.gslb = args.gslb;

                const { gslb } = args;

                this.gslbReloadEvents_.forEach(eventType => gslb.bind(eventType, this.reload_));
                gslb.one('beforeDestroy', this.destroy);
            } else {
                console.error(
                    'GSLB instance is needed for GSLBSiteCollection to operate properly.',
                );
            }

            if (args.nonAvi) {
                /**
                 * Set to true for collection of "non avi" Gslb sites.
                 * @type {boolean|undefined}
                 * @private
                 */
                this.nonAvi_ = true;
            }
        }

        /**
         * Wrapper for Collection.load to be called on certain GSLBSiteCollection.GSLB events.
         * @returns {angular.$q.promise}
         * @protected
         */
        reload_() {
            return this.load();
        }

        /**
         * @returns {GSLB.id}
         * @public
         */
        getGSLBid() {
            return this.gslb.id;
        }

        /** @override */
        isCreatable() {
            return this.gslb.isEditable();
        }

        /**
         * Drops passed GslbSites through GSLB Item.
         * @param {GslbSiteConfig.cluster_uuid|GslbSiteConfig.cluster_uuid[]} siteIds
         * @returns {angular.$q.promise}
         * @public
         */
        dropSites(siteIds) {
            const
                { gslb, nonAvi_ } = this,
                methodName = nonAvi_ ? 'dropNonAviSites' : 'dropSites';

            return gslb[methodName](siteIds);
        }

        /**
         * Enables passed GslbSites through GSLB Item.
         * @param {GslbSiteConfig.cluster_uuid|GslbSiteConfig.cluster_uuid[]} siteIds
         * @returns {angular.$q.promise}
         * @public
         */
        enableSites(siteIds) {
            const
                { gslb, nonAvi_ } = this,
                methodName = nonAvi_ ? 'toggleNonAviSiteEnabledFlag' : 'toggleSiteEnabledFlag';

            return gslb[methodName](siteIds, true);
        }

        /**
         * Disables passed GslbSites through GSLB Item.
         * @param {GslbSiteConfig.cluster_uuid|GslbSiteConfig.cluster_uuid[]} siteIds
         * @returns {angular.$q.promise}
         * @public
         */
        disableSites(siteIds) {
            const
                { gslb, nonAvi_ } = this,
                methodName = nonAvi_ ? 'toggleNonAviSiteEnabledFlag' : 'toggleSiteEnabledFlag';

            return gslb[methodName](siteIds, false);
        }

        /** @override */
        destroy() {
            const gotDestroyed = super.destroy();

            if (gotDestroyed) {
                const { gslb } = this;

                this.gslbReloadEvents_.forEach(eventType => {
                    gslb.unbind(eventType, this.reload_);
                });

                gslb.unbind('beforeDestroy', this.destroy);
                this.gslb = undefined;
            }

            return gotDestroyed;
        }
    }

    angular.extend(GSLBSiteCollection.prototype, {
        objectName_: 'gslb-inventory',
        itemClass_: GSLBSite,
        defaultDataFields_: ['config', 'runtime'],
        allDataSources_: {
            list: {
                source: 'GSLBSiteInventoryCollDataSource',
                transformer: 'GSLBSiteInventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config', 'runtime'],
            },
        },
        isStatic_: false,
    });

    return GSLBSiteCollection;
}]);
