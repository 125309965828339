/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function vmNicIPFactory(
    Item,
) {
    class VMNicIP extends Item {
        /** @override */
        getIdFromData_(data) {
            if (!data || !data.config) {
                return '';
            }

            const { config } = data;

            return [
                config.uuid,
                config.ip && config.ip.addr,
            ].join('-');
        }
    }

    /**
     * The actual endpoint is /api/cloud/cloudId/serversbynetwork.
     * It will be overwritten in its respective DataTransformer.
     * @type {string}
     */
    VMNicIP.prototype.objectName = 'serversbynetwork';

    return VMNicIP;
}

vmNicIPFactory.$inject = [
    'Item',
];

/**
 * @ngdoc service
 * @name VMNicIP
 * @author Alex Malitsky, chitra
 * @description
 *     Item for VMs IP address with network info.
 */
angular.module('aviApp')
    .factory('VMNicIP', vmNicIPFactory);
