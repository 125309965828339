/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name  DnsServiceController
 * @description
 *     Controller for Administration -> Settings -> DNS Service page.
 *     Works with a local copy of SystemConfig.data.dns_virtualservice_refs array which is
 *     updated on successful save event.
 */
//TODO preserve empty placeholders added by user on save events
class DnsServiceController {
    constructor(
            defaultValues,
            systemConfig,
            Schema,
            VirtualServiceCollection,
            AviConfirmService,
            VirtualService,
    ) {
        this.systemConfig_ = systemConfig;
        this.AviConfirmService_ = AviConfirmService;
        this.Schema = Schema;

        this.virtualServiceCollection = new VirtualServiceCollection({
            isStatic: true,
            params: {
                'application_profile_ref.type': 'APPLICATION_PROFILE_TYPE_DNS',
            },
        });

        defaultValues.load().then(
            () => {
                this.virtualServiceCollection.setNewItemDefaults({
                    application_profile_ref:
                            defaultValues.getSystemObjectRefByName(
                                'applicationprofile', 'System-DNS',
                            ),
                    network_profile_ref:
                            defaultValues.getSystemObjectRefByName(
                                'networkprofile', 'System-UDP-Per-Pkt',
                            ),
                    services:
                            [VirtualService.getDefaultServiceConfig({ port: 53 })],
                });
            },
        );

        this.updateDNSVSrefs_();
    }

    /**
     * Updates the local copy of dns_virtualservice_refs array.
     * @private
     */
    updateDNSVSrefs_() {
        this.dnsVSRefs = this.systemConfig_.data.config['dns_virtualservice_refs'].concat();
        this.updateFilters();
    }

    /**
     * Drops VS ref from the list.
     * @param {string} ref - VS.ref
     * @private
     */
    deleteDnsRefs_(ref) {
        if (ref) {
            this.systemConfig_.patch({
                delete: {
                    dns_virtualservice_refs: [ref],
                } });
        }
    }

    /**
     * Handles removing {VirtualService} from the dns_virtualservice_refs list when trash icon
     * is clicked and removes that ref from the list.
     * @param {string} ref - VS.ref
     * @param {number} index - Ref index in DNSVSrefs array.
     * @return
     */
    handleRemove(ref, index) {
        if (!ref) {
            this.dnsVSRefs.splice(index, 1);
            this.updateFilters();
        } else {
            this.AviConfirmService_
                .confirm('Are you sure you want to discard?')
                .then(() => {
                    this.dnsVSRefs.splice(index, 1);
                    this.updateFilters();
                    this.deleteDnsRefs_(ref);
                });
        }
    }

    /**
     * Updates filter params for collection API.
     */
    updateFilters() {
        this.virtualServiceCollection.setParams({
            'uuid.in': this.dnsVSRefs.map(val => val && val.slug()).join(),
            exclude: 'uuid.in',
        });
    }

    /**
     * Saves configuration settings. Called when VS got changed.
     * @public
     **/
    save() {
        this.updateFilters();
        this.systemConfig_.patch({
            replace: {
                dns_virtualservice_refs: this.dnsVSRefs.concat(),
            } });
    }

    /**
     * Disables inputs and buttons when updated Items is being saved.
     * @returns {boolean}
     */
    isBusy() {
        return this.systemConfig_.busy;
    }

    $onDestroy() {
        this.virtualServiceCollection.destroy();
    }
}

DnsServiceController.$inject = [
        'defaultValues',
        'loadedSystemConfigService',
        'Schema',
        'VirtualServiceCollection',
        'AviConfirmService',
        'VirtualService',
];

angular.module('aviApp').controller('DnsServiceController', DnsServiceController);
