/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './ordered-grid-controls.less';

/**
 * @ngdoc component
 * @name orderedGridControls
 * @description
 *     Component managing the controls section of the orderedGrid, including the create button and
 *     search button.
 * @param {Function} onCreate - Called when the Create button is clicked.
 */
class OrderedGridControlsController {
    $onInit() {
        /**
         * ngModel property for the Search input.
         */
        this.searchTerm = '';
    }

    /**
     * Handler for ngChange event on the search input. Calls this.onSearch with the searchTerm.
     */
    handleSearch() {
        const searchTerm = this.searchTerm || '';

        this.onSearch({ searchTerm });
    }
}

angular.module('aviApp').component('orderedGridControls', {
    controller: OrderedGridControlsController,
    bindings: {
        create: '<',
        onSearch: '&',
        hideSearch: '<',
    },
    templateUrl: 'src/components/common/ordered-grid/ordered-grid-controls/' +
            'ordered-grid-controls.html',
});
