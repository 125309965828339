/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const componentName = 'license-key-modal';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing:licenseKeyModalBindings
 * @see {@link module:avi/licensing.licenseKeyModalComponent licenseKeyModalComponent}
 */
class LicenseKeyModalController {
    constructor(Regex, licensingService) {
        /**
         * Regex for license key field.
         * @type {RegExp}
         */
        this.licenseKeyRegex = Regex.licenseSerialKey;

        /**
         * @type {module:avi/licensing.licensingService}
         * @protected
         */
        this.licensingService_ = licensingService;

        /**
         * License serial key model.
         * @type {string}
         */
        this.licenseKey = '';

        /**
         * True when license key is being uploaded.
         * @typedef {boolean}
         * @protected
         */
        this.busy_ = false;
    }

    /**
     * Getter for busy flag.
     * @returns {boolean}
     */
    get busy() {
        return this.busy_;
    }

    /**
     * Setter for busy flag.
     * @param {boolean} isBusy
     */
    set busy(isBusy) {
        this.busy_ = !!isBusy;
    }

    /**
     * Uploads Serial key to server.
     */
    applyKey() {
        this.busy = true;
        this.errors = null;

        this.licensingService_.applyLicenseKey(this.licenseKey)
            .then(() => {
                this.onKeyApplySuccess();
                this.closeModal();
            })
            .catch(({ data }) => {
                this.errors = data.result;
            })
            .finally(() => {
                this.busy = false;
            });
    }
}

LicenseKeyModalController.$inject = [
    'Regex',
    'licensingService',
];

/**
 * @name licenseKeyModalComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing:licenseKeyModalBindings} bindings
 * @property {module:avi/licensing:LicenseKeyModalController} controller
 * @desc Modal Component to apply license key.
 * @author Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licenseKeyModal', {
    /**
     * @mixin licenseKeyModalBindings
     * @memberOf module:avi/licensing
     * @property {Function} onKeyApplySuccess Success handler for License Key/File upload.
     * @property {Function} closeModal Method fired upon user selecting to close modal
     */
    bindings: {
        onKeyApplySuccess: '&',
        closeModal: '&',
    },
    controller: LicenseKeyModalController,
    templateUrl:
        'src/components/pages/administration/settings/licensing-page/license-upload-panel/' +
        `${componentName}/${componentName}.component.html`,
});
