/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @name appStateConstants
 * @type {Object<string, string>}
 * @desc Enums for ui-router states commonly used in redirection by appStateHandler.
 */
export const appStates = {
    DEFAULT_STATE: 'authenticated.application.dashboard',
    LOGIN_STATE: 'login',
    LOGGED_OUT_STATE: 'logged-out',
    WELCOME_STATE: 'welcome',
    PASSWORD_CHANGE_STATE: 'passwordchange',
    PLEASE_RELOAD_STATE: 'please-reload',
    CONTROLLER_DOWN_STATE: '503',
    UPGRADE_STATE: 'authenticated.administration.controller-detail.system-update',
    PRE_WELCOME_AWS_STATE: 'pre-welcome-aws',
};

angular.module('avi/app')
    .constant('appStateConstants', appStates);
