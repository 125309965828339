/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './server-analytics.less';

/**
 * @ngdoc controller
 * @name ServerAnalyticsController
 * @description
 *
 *     We fetch metrics for Server, for Pool it belongs to and corresponding VM host (when
 *     applicable).
 */
angular.module('aviApp').controller('ServerAnalyticsController', [
'$scope', '$state', '$stateParams', '$templateCache', '$q', 'ChartConfig',
'Cloud', 'Timeframe', 'myAccount', 'Server', 'resolvePoolServer',
function($scope, $state, $stateParams, $templateCache, $q, ChartConfig, Cloud,
         Timeframe, myAccount, Server, server) {
    $scope.myAccount = myAccount;
    $scope.Timeframe = Timeframe;
    $scope.server = server;
    $scope.$state = $state;
    $scope.$stateParams = $stateParams;

    const vmStats = {
        cpu: 'vm_stats.avg_cpu_usage',
        mem: 'vm_stats.avg_mem_usage',
        disk: 'vm_stats.avg_virtual_disk_throughput_usage',
    };

    const { Pool: pool, VirtualService: vs } = $scope;

    $scope.hmGridConfig = {
        fields: [{
            title: 'Health Monitor',
            name: 'data.config.name',
        }, {
            title: 'Type',
            name: 'data.config.type',
        }, {
            title: 'Status',
            name: 'status',
            template: require('./server-analytics-health-monitors-field-status.partial.html'),
        }, {
            title: 'Current Response Time',
            name: 'resp_time',
            template: `{{ row.getConfig().resptime >= 0 ?
                row.getConfig().resptime + 'ms' : 'N/A' }}`,
        }, {
            title: 'Average Response Time',
            name: 'avgRespTime',
            template: `{{ row.getConfig().avgRespTime >= 0 ?
                row.getConfig().avgRespTime + 'ms' : 'N/A' }}`,
        }, {
            title: 'Success',
            name: 'success',
            template: '{{::(row.getConfig().success || row.getConfig().success === 0) ' +
                '&& (row.getConfig().success + "%") || "N/A"}}',
        }],
        layout: {
            hideDisplaying: true,
            hideSearch: true,
            hideEditColumns: true,
            hideLoadingSpinner: true,
            hideTableNavigation: true,
        },
        expandedContainerTemplate: require(
            './server-analytics-health-monitors-expander.partial.html',
        ),
        expanderDisabled(row) {
            const { respCode, respStr, reason, sslErrorCode } = row.getConfig();

            return !(respCode || respStr || reason || sslErrorCode);
        },
    };

    const { hmGridConfig } = $scope;

    /**
     * Gets server id from Server's config data and default Pool's port.
     * @type {Function}
     * @public
     */
    $scope.getServerUuid = Server.getServerUuid;

    /**
     * Pool servers dropdown value.
     * @type {Server.id}
     * @public
     */
    $scope.selectedPoolServerId = server.id;

    const
        mFilterList = [], //metrics to be filtered out
        serverMetricsList = [
            'health',
            'alert',
            'config_events',
            'system_events',
            'alerts',
            'health_monitors',
        ];

    let metrics = [
        {
            name: vmStats.cpu, // all vm_stats of this server
            series: vmStats.cpu,
        }, {
            name: vmStats.mem,
            series: vmStats.mem,
        }, {
            name: vmStats.disk,
            series: vmStats.disk,
        }, {
            name: 'end_to_end',
        }, {
            name: 'l4_server.avg_bandwidth',
            series: 'l4_server.avg_bandwidth',
        }, {
            name: 'l4_server.max_open_conns',
            series: 'l4_server.max_open_conns',
        }, {
            name: 'l4_server.avg_complete_conns',
            series: 'l4_server.avg_complete_conns',
            errorsTotal: 'l4_server.avg_errored_connections',
            errorsSeries: [
                'l4_server.avg_lossy_connections',
            ],
        }, {
            name: 'l7_server.avg_complete_responses',
            series: 'l7_server.avg_complete_responses',
            errorsTotal: 'l7_server.pct_response_errors',
            errorsSeries: [
                'l7_server.avg_resp_4xx_errors',
                'l7_server.avg_resp_5xx_errors',
            ],
        }, {
            name: 'l4_server.avg_est_capacity',
            series: 'l4_server.avg_est_capacity',
            errorsSeries: ['l4_server.avg_available_capacity'],
        },
    ];

    metrics.forEach(metric => metric.subscriber = 'serverAnalyticsController');

    $scope.chartsConfig = new ChartConfig([{ id: '', series: [] }]);

    //setting grid for health score monitors
    const setHealthMonGridCollection = () => {
        hmGridConfig.collection = server.healthMonitors;

        if (vs.getRuntimeData()['one_plus_one_ha']) {
            server.healthMonitors.setParams({
                all_se: undefined,
                primary_only: true,
            });
        }
    };

    $q.all([
        vs.addLoad(['health']), //need VS runtime
        server.addLoad(serverMetricsList), //need healthMonitors
    ]).then(
        setHealthMonGridCollection,
    );

    //metrics charts of Server and pile/art charts from Pool data
    const
        cloudType = vs.getCloudType(),
        vsAppType = vs.appType();

    const vmStatsIsAvail = () => {
        return (cloudType === 'CLOUD_VCENTER' || Cloud.isContainerCloud(cloudType)) &&
            Server.getServerVMId(server.getConfig());
    };

    $scope.vmStatsIsAvail = vmStatsIsAvail;

    //need to pass VS layer into end_to_end config
    _.findWhere(metrics, { name: 'end_to_end' }).vsAppType = vsAppType;

    if (!vs.isHTTP()) {
        mFilterList.push('l7_server.avg_complete_responses');
    }

    pool.addLoad(['health', 'alert']);

    if (!vmStatsIsAvail()) {
        mFilterList.push(
            vmStats.cpu,
            vmStats.mem,
            vmStats.disk,
        );
    } else {
        // of pool, not shown in chart, hence can come through one metric with two series
        pool.collMetricsSubscribe([{
            name: 'vm_stats',
            series: [
                vmStats.cpu,
                vmStats.mem,
            ],
            subscriber: 'serverAnalyticsController',
            stackedSeries: false,
        }])
            .then(() => pool.startCollMetricsAsync());
    }

    if (!pool.getConfig()['capacity_estimation']) {
        mFilterList.push('l4_server.avg_est_capacity');
    }

    if (mFilterList.length) {
        metrics = _.reject(metrics, ({ name }) => _.contains(mFilterList, name));
    }

    const startMetricsUpdates = () => {
        $scope.chartsConfig = new ChartConfig(
            server.createChartsConfig(),
        );

        $scope.chartsConfig.setActiveCard($stateParams.metric);

        // hostname stat should not have sidebar tiles, therefore we want to add these once
        // chart config is ready
        const promise = vmStatsIsAvail() ?
            // of vhost
            server.collMetricsSubscribe([{
                name: 'vhost.vm_stats',
                series: [
                    vmStats.cpu,
                    vmStats.mem,
                ],
                subscriber: 'serverAnalyticsController',
                stackedSeries: false,
            }]) : $q.when(true);

        promise.then(() => server.startCollMetricsAsync());
    };

    server.collMetricsSubscribe(metrics)
        .then(startMetricsUpdates);

    //designated UI features
    $scope.switchToOtherServer = ({ value: serverId }) => {
        if (serverId !== server.id) {
            $state.go('.', { serverId });//reload is slower
        }
    };

    $scope.$on('$destroy', () => {
        server.destroy();

        pool.collMetricsUnSubscribe([
            vmStats.cpu,
            vmStats.mem,
        ]);

        pool.async.stop(true);
    });
}]);
