/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Inject } from '@angular/core';
import './waf-policy-psm-group-expander-content.less';

type ActionValueLabelHash = {
    [key in WafAction]: string;
}

/**
 * @ngdoc component
 * @name WafPolicyPsmGroupExpanderContentComponent
 * @module waf/WafPolicyPsmGroupExpanderContentComponent
 * @desc Component for displaying WAF PSM group expander content.
 * @param group - WafPolicyPsmGroup
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-group-expander-content',
    templateUrl: './waf-policy-psm-group-expander-content.component.html',
})
export class WafPolicyPsmGroupExpanderContentComponent {
    @Input() group: WafPolicyPsmGroup;

    private actionValueLabelHash_: ActionValueLabelHash;

    constructor(@Inject('schemaService') private schemaService: any) {
        this.actionValueLabelHash_ = this.schemaService.getEnumValueLabelsHash('WafAction');
    }

    /**
     * Returns text to display based on an action enum.
     * @param {string} value - Action enum from WafPolicyWhitelistAction.
     * @returns {string} Text to display from the enum.
     */
    getActionLabel(value: WafAction): string {
        return this.actionValueLabelHash_[value];
    }
}
