/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('SyslogController', [
'$scope', 'Collection', 'Regex', 'CRUDGridConfig', 'AviModal',
function($scope, Collection, Regex, CRUDGridConfig, AviModal) {
    $scope.Regex = Regex;

    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new Collection({
        objectName: 'alertsyslogconfig',
        windowElement: 'adm-syslog-create',
    });

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        },
        {
            name: 'data.config.syslog_server',
            title: 'Syslog Server',
            transform(row) {
                let ret = '';
                const cfg = row.data.config;

                if (cfg.syslog_servers) {
                    cfg.syslog_servers.forEach(function(server, index) {
                        if (index > 0) {
                            ret += ', ';
                        }

                        ret += `${server.syslog_server}:${server.syslog_server_port}`;
                    });
                } else {
                    ret = 'No Syslog Servers';
                }

                return ret;
            },
        },
    ];

    $scope.gridConfig.singleactions.unshift({
        title: 'Validate Settings',
        class: 'icon-lostlog',
        do(row) {
            AviModal.open('adm-email-validate', {
                uuid: row.data.config.uuid,
                type: 'syslog',
            });
        },
    });

    $scope.addSyslogServer = function() {
        const cfg = $scope.editable.data.config;

        cfg.syslog_servers = cfg.syslog_servers || [];
        cfg.syslog_servers.push({});
    };

    $scope.removeSyslogServer = function(index) {
        $scope.editable.data.config.syslog_servers.splice(index, 1);
    };
}]);
