/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/* wrapper for log popover values to move part of clickable filters logic out of templates */
angular.module('aviApp').directive('logCalloutValue', function() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            onClick: '&',
        },
        template: `
            <span title="{{data.display || data.value || data}}">
                <span ng-if="!data.param">
                    {{data.display || data.value || data}}
                </span>
                <span ng-if="data.param" 
                      log-filter-click 
                      display-value="{{data.display}}" 
                      value="data.value" 
                      min-value="{{data.value.start}}"
                      max-value="{{data.value.end}}" 
                      key="{{data.param}}" 
                      on-update="onClick({str:str})" 
                      contains="{{data.contains}}">
                </span>
            </span>`,
    };
});
