/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/components/file.less';

/**
 * @ngdoc directive
 * @restrict E
 * @name file
 * @param {string=} label - Value for file name.
 * @deprecated
 * @see fileUpload
 */
angular.module('aviApp').directive('file', [
'$timeout',
function($timeout) {
    /** @alias file */
    function fileLink(scope, elm) {
        elm.on('change', event => {
            $timeout(() => {
                [scope.file] = event.target.files;
                scope.label = scope.file.name;

                if (angular.isFunction(scope.onChange)) {
                    scope.onChange({ file: scope.file });
                }
            });
        });
    }

    return {
        scope: {
            label: '=?',
            file: '=',
            isRequired: '<?',
            onChange: '&?',
            buttonText: '@?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/file.html',
        link: fileLink,
    };
}]);
