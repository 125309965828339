/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc Controller
 * @name  CreateVlanInterfaceController
 * @description
 * Controller to create/edit VLAN interfaces by manually adding/modifying VLAN object to the list
 * of network interfaces.
 */
angular.module('aviApp').controller('CreateVlanInterfaceController', [
'$scope', 'Schema', 'Regex', function($scope, Schema, Regex) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    const vm = this;

    // Array of parent interface names as strings used for dropdown.
    vm.parentInterfaces = null;
    vm.errors = '';
    vm.Schema = Schema;
    vm.Regex = Regex;

    $scope.init = function() {
        vm.config = angular.copy($scope.vlan.config);
        vm.parentInterfaces = $scope.vlan.getParentInterfaceListNames();
        vm.parentInterface = $scope.vlan.parentInterface;
        vm.VIPVnicNetworks = $scope.vlan.VIPVnicNetworks;
    };

    /**
     * VLAN name is composed of <parent-interface>.<vlan-id>. Triggers on ng-change.
     */
    vm.setInterfaceName = function() {
        vm.config.if_name = `${_.isUndefined(vm.parentInterface) ? '' : vm.parentInterface}.${
            _.isUndefined(vm.config.vlan_id) ? '' : vm.config.vlan_id}`;
    };

    /**
     * Saves VLAN object.
     */
    vm.save = function() {
        vm.errors = '';

        if ($scope.vlan.vlanAlreadyExists(vm.parentInterface, vm.config.if_name)) {
            vm.errors = 'A VLAN with this name already exists.';

            return;
        }

        if ($scope.vlan.mode === 'create') {
            $scope.vlan.addNewVlan(vm.config, vm.parentInterface);
        } else {
            $scope.vlan.editVlan(vm.config, vm.parentInterface);
        }

        $scope.closeVlan();
    };

    /**
     * Deletes static network interface by index.
     * @param {number} index
     */
    vm.removeIpPrefix = function(index) {
        const { config } = vm;
        const { vnic_networks: vnicNetworks } = config;

        if (Array.isArray(vnicNetworks)) {
            vnicNetworks.splice(index, 1);
        }
    };

    /**
     * Adds empty static network interface.
     */
    vm.addIpPrefix = function() {
        const { config } = vm;
        const { vnic_networks: vnicNetworks } = config;

        if (Array.isArray(vnicNetworks)) {
            vnicNetworks.push({
                mode: 'STATIC',
                ip: {
                    ip_addr: {
                        addr: '0.0.0.0',
                        type: 'V4',
                    },
                    mask: 0,
                },
            });
        }
    };
}]);
