/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPositiveSecurityModelConfigItemFactory = (MessageItem, WafPolicyPsmGroup) => {
    /**
     * @alias module:config-item/WafPositiveSecurityModelConfigItem
     * @private
     */
    class WafPositiveSecurityModelConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPositiveSecurityModel',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                group_refs_data: [],
            };
        }

        /**
         * @override
         */
        modifyConfigDataAfterLoad_() {
            const { group_refs_data: groupRefsData = [] } = this.config;

            this.config.group_refs_data = groupRefsData.map(groupConfig => {
                return groupConfig instanceof WafPolicyPsmGroup ?
                    groupConfig :
                    new WafPolicyPsmGroup({
                        loadOnEdit: false,
                        data: {
                            config: groupConfig,
                        },
                    });
            });
        }

        /**
         * @override
         */
        modifyConfigDataBeforeSave_() {
            const { group_refs_data: groupRefsData = [] } = this.config;

            this.config.group_refs_data = groupRefsData.map(groupItem => groupItem.dataToSave());
            delete this.config.group_refs;
        }

        /**
         * @override
         */
        canFlatten_() {
            const { group_refs: groupRefs, group_refs_data: groupRefsData } = this.config;

            return !_.isEmpty(groupRefs) || !_.isEmpty(groupRefsData);
        }

        /**
         * Returns true if configured groups exist.
         * @returns {boolean}
         */
        hasGroups() {
            return this.config.group_refs_data.length > 0;
        }

        /**
         * Adds a new PSM Group.
         * @param {WafPolicyPsmGroup} newGroup
         */
        addGroup(newGroup) {
            const { group_refs_data: groups = [] } = this.config;

            groups.push(newGroup);
        }

        /**
         * Replaces an existing group using an index.
         * @param {WafPolicyPsmGroup} updatedGroup
         * @param {number} index - Index of the groups to replace.
         */
        editGroup(updatedGroup, index) {
            this.config.group_refs_data[index] = updatedGroup;
        }

        /**
         * Deletes a group from the list of configured groups.
         * @param {WafPolicyPsmGroup} group
         */
        deleteGroup(group) {
            const { group_refs_data: configuredGroups } = this.config;
            const index = _.indexOf(configuredGroups, group);

            if (index > -1) {
                configuredGroups.splice(index, 1);
            }
        }

        /**
         * Moves group to a new index. All groups in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the group.
         * @param {number} newIndex - Index of the new position.
         */
        moveGroup(oldIndex, newIndex) {
            let newIndexCounter = newIndex;
            /**
             * newIndex moves towards the direction of oldIndex
             */
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndexCounter) {
                this.swapGroup_(oldIndex, newIndexCounter);
                newIndexCounter += increment;
            }
        }

        /**
         * Given two indices of groups, swaps positions in the config.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapGroup_(oldIndex, newIndex) {
            const { config } = this;
            const { group_refs_data: groupRefsData = [] } = config;

            const oldGroup = groupRefsData[oldIndex];
            const newGroup = groupRefsData[newIndex];

            groupRefsData[oldIndex] = newGroup;
            groupRefsData[newIndex] = oldGroup;
        }
    }

    return WafPositiveSecurityModelConfigItem;
};

wafPositiveSecurityModelConfigItemFactory.$inject = [
    'MessageItem',
    'WafPolicyPsmGroup',
];

/**
 * @ngdoc factory
 * @name  WafPositiveSecurityModelConfigItem
 * @description  WafPositiveSecurityModel ConfigItem class.
 * @module config-item/WafPositiveSecurityModelConfigItem
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPositiveSecurityModelConfigItem', wafPositiveSecurityModelConfigItemFactory);
