/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name StringGroup
 */
angular.module('aviApp').factory('StringGroup', [
'Item',
function(Item) {
    class StringGroup extends Item {
        constructor(args) {
            super(args);
            /**
             * Hash map initialization for strObjs
             * @type {Object}
             * @protected
             */
            this.stringGroupHashes_ = {};
        }

        /**
         * @override
         */
        beforeEdit() {
            this.stringGroupHash_();
        }

        /**
         * Generates hash map for quick look up of kv.
         * @param {Object[]} stringsList - keys or key-val pairs belonging to string-group
         * @private
         */
        stringGroupHash_() {
            const { kv: stringsList } = this.getConfig();

            this.stringGroupHashes_ = {}; //need to re-init for removeStrObjs call
            stringsList.forEach((strObj, i) => this.addStringGroupToHash_(strObj, i));
        }

        /**
         * Adds strObj to list.
         * @param {Object} strObj - kv to be added to map
         * @param {boolean=} forceAdd - overwrite existing key
         * @returns {boolean|undefined} - T/F: unique/is-duplicate, undef if bad formatting of input
         * @public
         */
        addStringObj(strObj, forceAdd) {
            const inputFormattedCorrectly = typeof strObj === 'object' &&
                typeof strObj.key === 'string' &&
                !!strObj.key.trim();

            const alreadyExists = this.hasStringObj(strObj);

            strObj = this.sanitizeStrObj_(strObj);

            if (inputFormattedCorrectly) {
                if (!alreadyExists) {
                    this.getConfig().kv.push(strObj);
                    this.addStringGroupToHash_(strObj);

                    return true;
                } else if (forceAdd) {
                    const kvId = StringGroup.generateStringObjId(strObj);
                    const index = this.stringGroupHashes_[kvId];

                    this.getConfig().kv[index] = strObj;
                }
            }

            if (alreadyExists) return false;
        }

        /**
         * Preps strObj for addition into list.
         * @param {Object} strObj - original strObj provided
         * @returns {{key:string, value:*}} - formatted obj with both 'key' and 'value' keys
         * @private
         */
        sanitizeStrObj_(strObj) {
            strObj.key = strObj.key.trim();

            if (this.getConfig().type !== 'SG_TYPE_STRING' && !strObj.value) {
                strObj.value = '';
            }

            return strObj;
        }

        /**
         * Adds strObj to map.
         * @param {Object} strObj - kv to be added to map
         * @param {number=} i -  index of kv in stringGroup list
         * @private
         */
        addStringGroupToHash_(strObj, i) {
            const kvId = StringGroup.generateStringObjId(strObj);
            const index = _.isNaN(+i) ? this.getConfig().kv.length - 1 : i;

            this.stringGroupHashes_[kvId] = index;
        }

        /**
         * @param {Object} strObj - kv object to check presence of row
         * @returns {boolean} - whether map has specific kv
         * @public
         */
        hasStringObj(strObj) {
            const kvId = StringGroup.generateStringObjId(strObj);

            return kvId in this.stringGroupHashes_;
        }

        /**
         * Removes user selected rows from hash table and list.
         * @param {Object[]} strObjs
         * @public
         */
        removeStrObjs(strObjs) {
            const { kv: stringsList } = this.getConfig();

            const indexesToRemove = strObjs.map(strObj => {
                const kvId = StringGroup.generateStringObjId(strObj);
                const index = this.stringGroupHashes_[kvId];

                return index;
            }).sort((a, b) => {
                return b - a;
            });

            indexesToRemove.forEach(index => {
                stringsList.splice(index, 1);
            });
            this.stringGroupHash_();
        }

        /**
         * Discards all entries in both hash and list.
         * @public
         */
        discardAllStrings() {
            this.getConfig().kv = [];
            this.stringGroupHashes_ = {};
        }

        /**
         * Used to generate hashing of given kv object.
         * BE only cares about unique keys, not the value.
         * @param {Object} strObj - kv
         * @returns {string}
         * @static
         */
        static generateStringObjId(strObj) {
            return strObj.key;
        }
    }

    angular.extend(StringGroup.prototype, {
        windowElement: 'prof-stringgroup-create',
        objectName: 'stringgroup',
    });

    return StringGroup;
}]);
