/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name NetworkSecurityPolicyDosCollection
 * @description
 *     Collection of rules of blocked addresses.
 */
angular.module('aviApp').factory('NetworkSecurityPolicyDosCollection', ['Collection',
'NetworkSecurityPolicyDos',
(Collection, NetworkSecurityPolicyDos) => {
    const NetworkSecurityPolicyDosCollection = class extends Collection {
        constructor(args) {
            super(args);

            /**
             * Hash used to determine if an address has been blocked.
             * @type {Object}
             */
            this.blockedAddressesHash = {};
        }

        isCreatable() {
            return false;
        }
    };

    angular.extend(NetworkSecurityPolicyDosCollection.prototype, {
        objectName_: 'networksecuritypolicydos',
        itemClass_: NetworkSecurityPolicyDos,
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'NetworkSecurityPolicyDosDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return NetworkSecurityPolicyDosCollection;
}]);
