/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name MetricsDimensionDurationCollDataSource
 * @description
 *
 *     Params differ a bit from MetricsDimensionListCollDataSource. Data processing is the same.
 *
 */
angular.module('security.vs.vantage.avi').factory('MetricsDimensionDurationCollDataSource', [
'MetricsDimensionListCollDataSource',
function(MetricsDimensionListCollDataSource) {
    class MetricsDimensionDurationCollDataSource extends MetricsDimensionListCollDataSource {
        /** @override */
        getRequestParams_(requestType) {
            const
                params = super.getRequestParams_(requestType),
                { owner_: collection } = this;

            params['dimensions'] = collection.dimension_;

            return params;
        }
    }

    angular.extend(MetricsDimensionDurationCollDataSource.prototype, {
        defaultFields_: [
            {
                id: 'duration',
                preserved: true,
                subscribers: ['__mandatory_field'],
            },
        ],
        defaultParams_: {
            metric_id: 'dos.sum_attack_duration',
            dimension_aggregation: 'sum',
            pad_missing_data: false,
        },
    });

    return MetricsDimensionDurationCollDataSource;
}]);
