/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPsmMatchElementConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/WafPSMMatchElementConfigItem
     * @private
     */
    class WafPSMMatchElementConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPSMMatchElement',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten() {
            return Boolean(this.config.name);
        }
    }

    return WafPSMMatchElementConfigItem;
};

wafPsmMatchElementConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafPSMMatchElementConfigItem
 * @description  WafPSMMatchElement ConfigItem class.
 * @module config-item/WafPSMMatchElementConfigItem
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPSMMatchElementConfigItem', wafPsmMatchElementConfigItemFactory);
