/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, Input, OnInit } from '@angular/core';
import './waf-policy-psm-location-expander-content.less';

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationExpanderContent
 * @module waf/wafPolicyPsmLocationExpanderContent
 * @desc Displays match information about a WAF PSM Location.
 * @param location - WAF PSM Location.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-location-expander-content',
    templateUrl: './waf-policy-psm-location-expander-content.component.html',
})
export class WafPolicyPsmLocationExpanderContentComponent implements OnInit {
    @Input() location: WafPSMLocationConfigItem;

    match: MatchExtendableConfigItem;
    field: string;

    ngOnInit(): void {
        this.match = this.location.config.match;
    }
}
