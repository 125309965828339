/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, Input, Inject } from '@angular/core';
import { isUndefined } from 'underscore';
import './waf-policy-psm-rule-expander-content.less';

interface WafModeLabelHash {
    [key: string]: string;
}

/**
 * @ngdoc component
 * @name wafPolicyPsmRuleExpanderContent
 * @module waf/wafPolicyPsmRuleExpanderContent
 * @desc Displays match information about a WAF PSM Rule.
 * @param rule - WAF PSM Rule.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rule-expander-content',
    templateUrl: './waf-policy-psm-rule-expander-content.component.html',
})
export class WafPolicyPsmRuleExpanderContentComponent {
    @Input() rule: WafPSMRuleConfigItem;

    wafModeLabelHash: WafModeLabelHash;

    constructor(@Inject('schemaService') private schemaService: any) {
        this.wafModeLabelHash = this.schemaService.getEnumValueLabelsHash('WafMode');
    }

    /**
     * Returns the label to use for displaying the PSM Rule's mode.
     */
    getWafModeLabel(): string {
        return this.wafModeLabelHash[this.rule.mode] || 'Use Group Mode';
    }

    /**
     * Returns the label to use for displaying the PSM Rule's match_case.
     */
    getMatchCaseLabel(): string {
        return this.rule.config.match_case === 'SENSITIVE' ? 'True' : 'False';
    }

    /**
     * Returns the label to use for displaying the PSM Rule's match_value_max_length.
     */
    getValueMaxLengthLabel(): string {
        const { match_value_max_length: matchValueMaxLength } = this.rule.config;

        return isUndefined(matchValueMaxLength) ? 'N/A' : matchValueMaxLength.toString();
    }
}
