/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const
    componentName = 'c-grid-table-header',
    minColumnWidth = 40;

/**
 * @typedef {Object} GridTableHeaderColumn
 * @property {number} index - Index in the list of the columns.
 * @property {HTMLElement} $elem - DOM node of the header cell.
 * @property {boolean} isResizable - Flag to mark column resizable.
 */

//controller split into two classes - this one is responsible for resizing of the columns
class ResizableColumnsController {
    constructor($element, $document) {
        this.$element = $element;
        this.$document = $document;

        //TODO listen to the fields list change event and re-render the list of columns
        //for collectionGrid only
        /** @type {boolean} */
        this.isReady = true;

        /** @type {GridTableHeaderColumn[]} */
        this.columns = [];

        /** @type {boolean} */
        this.columnResizeIsActive_ = false;

        /**
         * Min x coordinate available for column resizing event. Set on resize start.
         * @type {number}
         * @protected
         */
        this.minX_ = NaN;

        /**
         * Max x coordinate available for column resizing event. Set on resize start.
         * @type {number}
         * @protected
         */
        this.maxX_ = NaN;

        /**
         * Set while resizing event is happening.
         * @type {GridTableHeaderColumn.index}
         * @protected
         */
        this.activeColumnId_ = NaN;

        this.mouseUpEventHandler_ = this.mouseUpEventHandler_.bind(this);
        this.mouseMoveHandler_ = this.mouseMoveHandler_.bind(this);

        //need to debounce it so that we get all the columns before converting their width to %
        //also need this as timeout for the initial rendering
        this.onColumnSizeUpdate_ = _.debounce(
            this.onColumnSizeUpdate_.bind(this),
            19,
        );
    }

    $onInit() {
        this.$document
            .on('mousemove', this.mouseMoveHandler_)
            .on('mouseup', this.mouseUpEventHandler_);
    }

    $onDestroy() {
        this.$document
            .off('mousemove', this.mouseMoveHandler_)
            .off('mouseup', this.mouseUpEventHandler_);
    }

    /**
     * Adds newly rendered column into the list of all the columns.
     * Called by {@link gridTableHeaderCell} component.
     * All columns have to be added at the same time, otherwise sizing will break, also its
     * hard to preserve correct order, cause rendered element doesn't know where it is placed.
     * @param {HTMLElement} $elem
     * @param {boolean=} isResizable
     * @returns {GridTableHeaderColumn.index} - Index(id) of the added column.
     */
    addColumn($elem, isResizable = true) {
        const index = this.columns.length;

        const column = {
            $elem,
            index,
            isResizable,
        };

        this.columns.push(column);

        this.onColumnSizeUpdate_();

        return index;
    }

    /**
     * Returns true for the last column in the list.
     * @param {GridTableHeaderColumn.index} colIndex
     * @returns {boolean}
     * @protected
     */
    isLastColumn_(colIndex) {
        return colIndex + 1 === this.columns.length;
    }

    /**
     * Returns true when resizable column is passed.
     * @param {GridTableHeaderColumn.index} colIndex
     * @returns {boolean}
     */
    isColumnResizable(colIndex) {
        const column = this.columns[colIndex];

        if (this.isLastColumn_(colIndex) || !column.isResizable) {
            return false;
        }

        const nextColumn = this.columns[column.index + 1];

        return nextColumn.isResizable;
    }

    /**
     * Removes column from the list.
     * @param {GridTableHeaderColumn.index} colIndex
     */
    removeColumn(colIndex) {
        this.columns.splice(colIndex, 1);
        this.onColumnSizeUpdate_();
    }

    /**
     * Kicks off the column resizing process. Called by {@link gridTableHeaderCell} component.
     * @param {GridTableHeaderColumn.index} colIndex
     * @param {Object} event
     */
    startResizing(colIndex, event) {
        const { pageX: posX } = event;

        this.columnResizeIsActive_ = true;
        this.activeColumnId_ = colIndex;

        const
            { $elem: $column } = this.columns[colIndex],
            columnWidth = $column.width(),
            { $elem: $nextColumn } = this.columns[colIndex + 1],
            nextColumnWidth = $nextColumn.width();

        this.minX_ = posX - columnWidth + minColumnWidth;
        this.maxX_ = posX + nextColumnWidth - minColumnWidth;
    }

    /**
     * Event handler for end of column resizing progress.
     * @protected
     */
    mouseUpEventHandler_() {
        if (!this.columnResizeIsActive_) {
            return;
        }

        this.stopResizing_();
    }

    /**
     * Resets resizing state.
     * @protected
     */
    stopResizing_() {
        this.columnResizeIsActive_ = false;
        this.minX_ = NaN;
        this.maxX_ = NaN;
        this.activeColumnId_ = NaN;
    }

    /**
     * Calculates column width in percents relatively to the total grid width.
     * @param {number} width
     * @param {number} gridWidth
     * @returns {number}
     * @protected
     */
    static columnWidthToPercent_(width, gridWidth) {
        return width / gridWidth * 100;
    }

    /**
     * Converts column widths to percents (so that on windows resize we wouldn't need to
     * update styles for columns and table cells).
     * @protected
     */
    //TODO remove all inline width definitions before calculations so that we won't have a
    // problem of subsequent column additions
    convertColWidthToPercent_() {
        const
            headerWidth = this.$element.width(),
            headerWidthList = [];

        //TODO here we want to rest columns size to default,
        // but user set/changed values should also be preserved

        this.columns.forEach(({ $elem }) => {
            headerWidthList.push($elem.outerWidth());
        });

        //const perHeaderWidthList = [];

        this.columns.forEach(({ $elem }, index) => {
            const w = ResizableColumnsController.columnWidthToPercent_(
                headerWidthList[index],
                headerWidth,
            );

            $elem.css('width', `${w}%`);
            //perHeaderWidthList.push(w);
        });

        /*console.log(
                headerWidth,
                headerWidthList.reduce((acc, item) => acc + item, 0),
                perHeaderWidthList.reduce((acc, item) => acc + item, 0)
            );*/
    }

    /**
     * Updates width of the column being resized and it's neighbour on the right.
     * @param {Object} event
     * @protected
     */
    mouseMoveHandler_(event) {
        if (!this.columnResizeIsActive_) {
            return;
        }

        const
            { pageX: posX } = event,
            { activeColumnId_: colIndex } = this,
            { $elem: $column } = this.columns[colIndex],
            { $elem: $nextColumn } = this.columns[colIndex + 1];

        if (posX >= this.minX_ && posX <= this.maxX_) {
            $column.width(posX - this.minX_ + minColumnWidth);
            $nextColumn.width(this.maxX_ + minColumnWidth - posX);

            this.onColumnSizeUpdate_();
        }
    }

    /**
     * Converts column widths to percents and notifies {@link grid} component of the update
     * so that it could redefine table cell styles (to match column widths).
     * Debounced by default.
     * @protected
     */
    onColumnSizeUpdate_() {
        this.convertColWidthToPercent_();

        this.gridCtrl.syncColumnSize(
            this.columns.map(({ $elem }) => $elem.get(0).style.width),
        );
    }
}

//this one is for the rest of functionality (more custom)
class GridTableHeaderController extends ResizableColumnsController {
    constructor(...args) {
        super(...args);
        [this.$element] = args;
    }

    $onInit() {
        super.$onInit();
        this.$element.addClass(componentName);
    }

    /**
     * Communicates the desired sorting to the {@link grid} component.
     * @param {string} fieldSortBy
     */
    setSorting(fieldSortBy) {
        //not sure if we need to disable sorting for disabled grids
        if (!this.isDisabled) {
            this.onSortChange({ fieldName: fieldSortBy });
        }
    }

    /**
     * Returns 0 if passed field is not currently used for sorting. 1 and 2 are returned if
     * it is used, depending whether it is ascending or descending order.
     * @param {string} fieldName
     * @returns {number} - 0, 1 or 2
     */
    getSortingState(fieldName) {
        if (fieldName !== this.sortFieldName) {
            return 0;
        }

        return this.sortOrder ? 1 : 2;
    }

    /**
     * Returns class name of the single action column based on the number of actions to be
     * presented.
     * @returns {string}
     */
    getSingleActionColumnInitialWidthClassName() {
        return `c-grid-table-header-cell--w--${this.singleActionsQ}`;
    }
}

GridTableHeaderController.$inject = [
    '$element',
    '$document',
];

/**
 * @ngdoc component
 * @name gridTableHeader
 * @param {gridFieldConfig[]} fields - List of regular table columns (not including checkbox or
 *     single action columns).
 * @param {boolean} withCheckboxColumn - When true is passed will render left column with
 *     checkboxes per each row.
 * @param {number} singleActionsQ - Max number of single actions per table row.
 * @param {string} sortFieldName - "Field name" used for sorting.
 * @param {boolean} sortOrder - True if descending order, false otherwise.
 * @param {Function} onSortChange - To be called with 'fieldName' property.
 * @param {boolean} isDisabled
 * @desc
 *
 *     This is a wrapper component for table header cells, hence it provides methods to
 *     supply sorting and resizing functionality down the chain.
 *
 *     All the columns have to be added in a batch. Once list has changed, all previously
 *     added columns should get removed and new batch added. Component has control on
 *     rendering by toggling this.isReady (not yet implemented).
 *
 *     Checkbox template should be passed through transclution.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi').component('gridTableHeader', {
    bindings: {
        fields: '<',
        withCheckboxColumn: '<',
        singleActionsQ: '<',
        sortFieldName: '<',
        sortOrder: '<',
        onSortChange: '&',
        isDisabled: '<',
    },
    require: {
        gridCtrl: '^^grid',
    },
    transclude: {
        gridTableHeaderCheckbox: 'gridTableHeaderCheckbox',
    },
    controller: GridTableHeaderController,
    templateUrl: 'src/components/common/grid/grid-table-header/grid-table-header.html',
});
