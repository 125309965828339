/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleGslbSiteSelectionActionConfig
 * @description DnsPolicyRuleGslbSiteSelectionActionConfig,
 *      maps to DnsRuleActionGslbSiteSelection in protobof.
 */
const dnsPolicyRuleGslbSiteSelectionActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRuleGslbSiteSelectionActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const { site_name: siteName } = this.getConfig();

            return siteName || 'N/A';
        }
    }

    angular.extend(DnsPolicyRuleGslbSiteSelectionActionConfig.prototype, {
        type: 'DnsRuleActionGslbSiteSelection',
        name: 'GSLB Site',
        defaultPath: 'dnsrule.action.gslb_site_selection',
    });

    return DnsPolicyRuleGslbSiteSelectionActionConfig;
};

dnsPolicyRuleGslbSiteSelectionActionConfigFactory.$inject = [
        'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleGslbSiteSelectionActionConfig',
    dnsPolicyRuleGslbSiteSelectionActionConfigFactory,
);
