/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('AlertListDataTransformer', ['ListDataTransformer',
    function(ListDataTransformer) {
        function AlertListDataTransformer(args) {
            AlertListDataTransformer.superconstructor.call(this, args);
        }

        avi.inherit(AlertListDataTransformer, ListDataTransformer);

        AlertListDataTransformer.prototype.processResponse = function(resp, request) {
            resp = AlertListDataTransformer.superclass.processResponse.call(this, resp, request);

            /* Need this to provide the same structure of events as events collection has. */
            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results.forEach(function(alert) {
                    alert = alert.config;

                    alert.timestamp *= 1000;

                    if ('metric_info' in alert && Array.isArray(alert.metric_info)) {
                        alert.metric_info.forEach(metricInfo => {
                            metricInfo.end_timestamp *= 1000;
                            metricInfo.report_timestamp *= 1000;
                        });
                    }

                    if ('events' in alert && Array.isArray(alert.events)) {
                        alert.events = alert.events.map(function(event) {
                            event.report_timestamp *= 1000;

                            if (alert.action_script_output) {
                                event.action_script_output = alert.action_script_output;
                            }

                            return { data: { config: event } };
                        });
                    }
                });
            }

            return resp;
        };

        return AlertListDataTransformer;
    }]);
