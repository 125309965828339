/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('NetworkInventoryDataTransformer', ['InventoryDataTransformer',
function(InventoryDataTransformer) {
    function NetworkInventoryDataTransformer(args) {
        NetworkInventoryDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(NetworkInventoryDataTransformer, InventoryDataTransformer);

    NetworkInventoryDataTransformer.prototype.processResponse = function(resp, request) {
        function subnet2str(s) {
            return `${s.prefix.ip_addr.addr}/${s.prefix.mask}`;
        }

        resp = this.responseListOffsetControl_(resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results)) {
            resp.data.results.forEach(function(net) {
                const runtimeSubnets = {};

                if (net.discovery && net.discovery.ip_subnet &&
                    Array.isArray(net.discovery.ip_subnet.length)) {
                    net.discovery.ip_subnet.forEach(function(s) {
                        s.asString = subnet2str(s);
                    });
                }

                if (net.runtime && net.runtime.subnet_runtime &&
                    Array.isArray(net.runtime.subnet_runtime)) {
                    net.runtime.subnet_runtime.forEach(function(s) {
                        runtimeSubnets[subnet2str(s)] = s;
                    });
                }

                if (net.config && net.config.configured_subnets &&
                    Array.isArray(net.config.configured_subnets)) {
                    net.config.configured_subnets.forEach(function(s) {
                        s.asString = subnet2str(s);

                        if (s.asString in runtimeSubnets) {
                            _.extend(s, runtimeSubnets[s.asString]);
                        }
                    });
                }
            });
        }

        return resp;
    };

    return NetworkInventoryDataTransformer;
}]);
