/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  trafficCloneProfilePage
 * @description  Page for Geo Profiles containing a Collection Grid of Geo Profiles.
 */
class TrafficCloneProfilePageController {
    constructor(TrafficCloneProfileCollection, CRUDGridConfig) {
        const gridConfig = {
            collection: new TrafficCloneProfileCollection(),
            fields: [{
                name: 'data.config.name',
                sortBy: 'name',
                title: 'Name',
            }, {
                name: 'cloud',
                title: 'Cloud',
                template: '{{ row.data.config.cloud_ref.name() }}',
            }],
        };

        this.gridConfig = CRUDGridConfig(gridConfig);
    }
}

TrafficCloneProfilePageController.$inject = [
        'TrafficCloneProfileCollection',
        'CRUDGridConfig',
];

angular.module('aviApp').component('trafficCloneProfilePage', {
    controller: TrafficCloneProfilePageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
