/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name validatePort
 * @param {Object[]} validatePort - Array of Service Ports (see protobuf Service message
 *      in vs.proto) used to validate user input port.
 * @description
 *      Validates that provided Service Port is in range of array of service ports in
 *      validatePort param.
 */
angular.module('aviApp').directive('validatePort', () => {
    function portValid(servicePorts, port) {
        return _.any(servicePorts, servicePort => {
            return port >= servicePort.port && port <= servicePort.port_range_end;
        });
    }

    function validatePortLink(scope, elem, attr, ngModel) {
        ngModel.$validators.validatePort = (modelValue, viewValue) => {
            if (!viewValue) {
                return true;
            }

            if (Array.isArray(modelValue)) {
                return _.every(modelValue, val => portValid(scope.validatePort, val));
            } else {
                return portValid(scope.validatePort, viewValue);
            }
        };
    }

    return {
        scope: {
            validatePort: '=',
        },
        require: 'ngModel',
        link: validatePortLink,
    };
});
