/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './vs-security-ddos-top-lists.less';

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTable
 * @param {MetricsDimensionCollection} attacksCollection - Collection of attacks.
 * @param {MetricsDimensionCollection} ipgroupsCollection - Collection of client IP addresses.
 * @param {MetricsDimensionCollection} urlsCollection - Collection of URLs.
 * @param {MetricsDimensionCollection} asnsCollection - Collection of ASNs.
 * @param {MetricsDimensionCollection} blockedCollection - Collection of blocked client IPs.
 * @param {VirtualService} row - Virtual Service object containing runtime for Service Engine info.
 * @param {string} filter - Selected series to be filtered.
 * @param {function} onTabChange - Called when user selects a tab from the table.
 * @param {function} onSeriesSelect - Called when user selects a series to be filtered.
 * @description
 *     Displays DDoS collections in a table. The Attacks table is always shown, while the user can
 *     alternate between the Client IP, ASN, URL, and Blocked tables via tabs. Tables show Name,
 *     Rate, and Duration data. The Client IP and ASN tables display the AS Name associated with
 *     the IP address and AS number. The user can also select a filter to display series data and
 *     chart information. "View Full List" opens up a modal to display the collection grid for that
 *     selected tab by itself. Collections are passed in as bindings individually in order to use
 *     the $onChanges lifecycle hook to update gridConfig collections.
 */
class VsSecurityDdosTopListsController {
    constructor(NetworkSecurityPolicyDosCollection, AviModal) {
        this.NetworkSecurityPolicyDosCollection_ = NetworkSecurityPolicyDosCollection;
        this.AviModal_ = AviModal;
    }

    /**
     * Sets the active table tabs and gridConfigs.
     */
    $onInit() {
        this.active = 'attack';
        this.subactive = 'ipgroup';

        /**
         * Collection used to poll for blocked addresses.
         * @type {Collection}
         */
        this.securityPolicyCollection = new this.NetworkSecurityPolicyDosCollection_({
            params: {
                name: this.virtualservice.getName(),
            },
        });

        this.securityPolicyCollection.load();

        this.attackTypeGridConfig = {
            collection: this.attacksCollection,
            fields: [{
                name: 'name',
                title: 'Name',
                template:
                    `<vs-security-ddos-top-list-grid-cell-name
                        row="row"
                        on-series-select="config.options.handleSeriesSelect(filter)"
                        disable-select="config.options.disableSelect(filter)"
                        show-tooltip="true" />`,
            }, {
                name: 'duration',
                title: 'Duration (sec)',
                template: '<vs-security-ddos-top-list-grid-cell-duration row="row" />',
            }, {
                name: 'rate',
                title: 'Rate (/sec)',
                template:
                    '<vs-security-ddos-top-list-grid-cell-rate row="row"/>',
            }],
            rowClass: row => this.filterIsSelected(row.getName()) && 'selected' || '',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
            options: {
                item: this.virtualservice,
                handleSeriesSelect: filter => this.onSeriesSelect({
                    filter: ['attack', filter],
                    dimension: this.active === 'blocked' ? 'ipgroup' : this.active,
                }),
                disableSelect: filter => this.disableSelect('attack', filter),
            },
        };

        this.clientipGridConfig = {
            collection: this.ipgroupsCollection,
            fields: [{
                name: 'ip',
                title: 'IP Address',
                template:
                    `<vs-security-ddos-top-list-grid-cell-name
                        row="row"
                        on-series-select="config.options.handleSeriesSelect(filter)"
                        disable-select="config.options.disableSelect(filter)" />`,
            }, {
                name: 'asname',
                title: 'AS Name',
                template: '{{ row.data.asname }}',
            }, {
                name: 'duration',
                title: 'Duration (sec)',
                template: '<vs-security-ddos-top-list-grid-cell-duration row="row" />',
            }, {
                name: 'rate',
                title: 'Rate (/sec)',
                template:
                    `<vs-security-ddos-top-list-grid-cell-rate
                        row="row"
                        metric-data="config.options.item.data" />`,
            }],
            rowClass:
                row => this.filterIsSelected(row.getName()) && 'selected' || '',
            singleactions: [{
                title: 'Block',
                class: 'icon-block',
                do: row => {
                    row.blockAddress(this.virtualservice.data.config)
                        .then(() => {
                            this.securityPolicyCollection.load();
                            this.blockedCollection.load();
                        });
                },
                hidden: row => this.securityPolicyCollection.blockedAddressesHash[row.id],
            }],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
            options: {
                item: this.virtualservice,
                handleSeriesSelect: filter => this.onSeriesSelect({
                    filter: ['ipgroup', filter],
                    dimension: 'attack',
                }),
                disableSelect: filter => this.disableSelect('ipgroup', filter),
            },
            permission: 'PERMISSION_NETWORKSECURITYPOLICY',
        };

        this.urlGridConfig = {
            collection: this.urlsCollection,
            fields: [{
                name: 'name',
                title: 'URL',
                template:
                    `<vs-security-ddos-top-list-grid-cell-name
                        row="row"
                        on-series-select="config.options.handleSeriesSelect(filter)"
                        disable-select="config.options.disableSelect(filter)" />`,
            }, {
                name: 'duration',
                title: 'Duration (sec)',
                template: '<vs-security-ddos-top-list-grid-cell-duration row="row" />',
            }, {
                name: 'rate',
                title: 'Rate (/sec)',
                template:
                    `<vs-security-ddos-top-list-grid-cell-rate
                        row="row"
                        metric-data="config.options.item.data" />`,
            }],
            rowClass: row => this.filterIsSelected(row.getName()) && 'selected' || '',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
            options: {
                item: this.virtualservice,
                handleSeriesSelect: filter => this.onSeriesSelect({
                    filter: ['url', filter],
                    dimension: 'attack',
                }),
                disableSelect: filter => this.disableSelect('url', filter),
            },
        };

        this.asnGridConfig = {
            collection: this.asnsCollection,
            fields: [{
                name: 'asn',
                title: 'AS Number',
                template:
                    `<vs-security-ddos-top-list-grid-cell-name
                        row="row"
                        on-series-select="config.options.handleSeriesSelect(filter, dimension)"
                        disable-select="config.options.disableSelect(filter)" />`,
            }, {
                name: 'asname',
                title: 'AS Name',
                template: '{{ row.data.asname }}',
            }, {
                name: 'duration',
                title: 'Duration (sec)',
                template: '<vs-security-ddos-top-list-grid-cell-duration row="row" />',
            }, {
                name: 'rate',
                title: 'Rate (/sec)',
                template:
                    `<vs-security-ddos-top-list-grid-cell-rate
                        row="row"
                        metric-data="config.options.item.data" />`,
            }],
            rowClass: row => this.filterIsSelected(row.getName()) && 'selected' || '',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
            options: {
                item: this.virtualservice,
                handleSeriesSelect: filter => this.onSeriesSelect({
                    filter: ['asn', filter],
                    dimension: 'attack',
                }),
                disableSelect: filter => this.disableSelect('asn', filter),
            },
        };

        this.blockedGridConfig = {
            collection: this.blockedCollection,
            fields: [{
                name: 'ip',
                title: 'IP Address',
                template:
                    `<vs-security-ddos-top-list-grid-cell-name
                        row="row"
                        on-series-select="config.options.handleSeriesSelect(filter)"
                        disable-select="config.options.disableSelect(filter)" />`,
            }, {
                name: 'asname',
                title: 'AS Name',
                template: '{{ row.data.asname }}',
            }, {
                name: 'duration',
                title: 'Duration (sec)',
                template: '<vs-security-ddos-top-list-grid-cell-duration row="row" />',
            }, {
                name: 'rate',
                title: 'Rate (/sec)',
                template:
                    `<vs-security-ddos-top-list-grid-cell-rate
                        row="row"
                        metric-data="config.options.item.data" />`,
            }],
            singleactions: [{
                title: 'Unblock',
                class: 'icon-check',
                do: row => {
                    row.unblockAddress(this.virtualservice.data.config)
                        .then(() => {
                            this.securityPolicyCollection.load();
                            this.blockedCollection.load();
                        });
                },
                hidden: () => false,
            }],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
            options: {
                item: this.virtualservice,
                handleSeriesSelect: filter => this.onSeriesSelect({
                    filter: ['ipgroup', filter],
                    dimension: 'attack',
                }),
                disableSelect: filter => this.disableSelect('blocked', filter),
            },
            permission: 'PERMISSION_NETWORKSECURITYPOLICY',
        };
    }

    /**
     * Checks for changes to the bound collections to reset the gridConfig collections.
     * @param  {Object} changes - angular onChanges object.
     */
    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.attacksCollection) {
            this.attackTypeGridConfig.collection = changes.attacksCollection.currentValue;
        }

        if (changes.ipgroupsCollection) {
            this.clientipGridConfig.collection = changes.ipgroupsCollection.currentValue;
        }

        if (changes.urlsCollection) {
            this.urlGridConfig.collection = changes.urlsCollection.currentValue;
        }

        if (changes.asnsCollection) {
            this.asnGridConfig.collection = changes.asnsCollection.currentValue;
        }
    }

    /**
     * Sets the active property to the selected tab.
     * @param  {string} tab - Name of the tab to be set to active.
     */
    selectTab(tab) {
        this.active = tab;

        if (tab !== 'attack') {
            this.subactive = tab;
        }

        this.onTabChange({ tab });
    }

    filterIsSelected(filter) {
        return filter === this.filter;
    }

    /**
     * Returns true to disable filters from being clickable if the filter's tab is the same as
     * the active tab, or if the active tab is 'blocked'.
     * @param  {string} tab - Name of the tab.
     * @param {string} filter - Name of the filter.
     * @return {boolean}
     */
    disableSelect(tab, filter) {
        return this.active === tab || this.active === 'blocked' || this.filter === filter;
    }

    /**
     * Opens the modal to show the full list of the DDoS collection.
     */
    viewFullList() {
        const bindingHash = {
            attack: ['Attack Types', this.attackTypeGridConfig],
            ipgroup: ['Attacking Client IPs', this.clientipGridConfig],
            url: ['Attacking URLs', this.urlGridConfig],
            asn: ['Attacking ASNs', this.asnGridConfig],
            blocked: ['Active Clients Blocked', this.blockedGridConfig],
        };

        const modalTuple = bindingHash[this.active];

        this.AviModal_.open('virtualservice-security-ddos-full-list', {
            title: modalTuple[0],
            gridConfig: modalTuple[1],
        });
    }

    $onDestroy() {
        this.securityPolicyCollection.destroy();
    }
}

VsSecurityDdosTopListsController.$inject = [
    'NetworkSecurityPolicyDosCollection',
    'AviModal',
];

angular.module('security.vs.vantage.avi').component('vsSecurityDdosTopLists', {
    bindings: {
        attacksCollection: '<',
        ipgroupsCollection: '<',
        urlsCollection: '<',
        asnsCollection: '<',
        blockedCollection: '<',
        virtualservice: '<',
        filter: '@',
        onTabChange: '&',
        onSeriesSelect: '&',
    },
    controller: VsSecurityDdosTopListsController,
    templateUrl: 'src/components/applications/virtualservice/vs-security-tab/' +
            'vs-security-ddos/vs-security-ddos-top-lists/vs-security-ddos-top-lists.html',
});
