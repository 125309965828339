/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function upgradeNodeListDataSourceFactory(DataSource) {
    /**
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.DataSource
     * @description DataSource for UpgradeNodeList.
     * @author Akul Aggarwal
     */
    class UpgradeNodeListDataSource extends DataSource {
        constructor(args = {}) {
            const params = Object.assign(args,
                {
                    defaultFields: [
                        {
                            id: 'config',
                            preserved: true,
                            subscribers: ['__default_field'],
                        },
                    ],
                });

            super(params);

            this.updateInterval_ = 15;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params.objectName_ = this.owner_.objectName_;

            return params;
        }

        /** @override */
        processResponse_(resp, request) {
            this.owner_.setUpgradeNodeListData(resp.data.results);

            return resp;
        }
    }

    return UpgradeNodeListDataSource;
}

upgradeNodeListDataSourceFactory.$inject = ['DataSource'];

angular.module('avi/upgrade')
    .factory('UpgradeNodeListDataSource', upgradeNodeListDataSourceFactory);
