/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Component on the VS WAF Analytics page, used to show a preview of selected WAF rules to be added
 * to a WAF Policy.
 * @ngdoc component
 * @name wafExceptionPreview
 * @param {WafPolicy} wafPolicy
 */
class WafExceptionPreviewController {
    constructor(
            $rootScope,
            wafExceptionState,
            getSubnetString,
            AviAlertService,
    ) {
        this._$rootScope = $rootScope;
        this._wafExceptionState = wafExceptionState;
        this.getSubnetString = getSubnetString;
        this._AviAlertService = AviAlertService;

        this.setExceptions = this.setExceptions.bind(this);
        this._wafExceptionState.on('exceptionsChanged', this.setExceptions);

        this.data = [];
    }

    $onInit() {
        this.setExceptions();
    }

    /**
     * Returns the name of either a group or a rule.
     * @param {string} type - Either 'group' or 'rule'.
     * @param {string} id - Group name or Rule ID.
     * @return {string}
     */
    getName(type, id) {
        switch (type) {
            case 'rule':
                return this.wafPolicy.getRuleNameByRuleId(id, true);
            default:
                return id;
        }
    }

    /**
     * Creates the this.data array of exceptions to add. Called after exceptions have been
     * changed on wafExceptionState.
     */
    setExceptions() {
        const exceptionsHash = this._wafExceptionState.getGroupedSelectedExceptions();
        const groups = exceptionsHash['group'] || [];
        const rules = exceptionsHash['rule'] || [];

        this.data = groups.concat(rules);
    }

    /**
     * Click handler for removing an exception from the right panel.
     * @param {string} type - Either 'group' or 'rule'.
     * @param {string} id - Identifier of the group or rule.
     * @param {Object} exception - Exception to be removed.
     */
    handleRemoveException(type, id, exception) {
        this._wafExceptionState.removeException(type, id, exception);
    }

    /**
     * Adds an exception to the WafPolicy.
     */
    handleAddExceptions() {
        this.data.forEach(({ type, id, exceptions }) => {
            exceptions.forEach(exception => this.wafPolicy.addException(type, id, exception));
        });

        this.wafPolicy.save()
            .then(() => this._wafExceptionState.removeAllExceptions())
            .catch(({ data }) => this._AviAlertService.throw(data))
            .finally(() => this.wafPolicy.load());
    }

    $onDestroy() {
        this._wafExceptionState.unbind('exceptionsChanged', this.setExceptions);
        this._wafExceptionState.removeAllExceptions();
    }
}

WafExceptionPreviewController.$inject = [
    '$rootScope',
    'wafExceptionState',
    'getSubnetString',
    'AviAlertService',
];

angular.module('aviApp').component('wafExceptionPreview', {
    controller: WafExceptionPreviewController,
    bindings: {
        wafPolicy: '<',
    },
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/' +
            'waf-exception-preview/waf-exception-preview.html',
});
