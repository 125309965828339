/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Map for duration types.
 * @static @const {Object<string, string>}
 */
const DURATION_TYPE = {
    HOURS: 'hours',
    MINUTES: 'minutes',
    SECONDS: 'seconds',
};

/**
 * Converts seconds to a smaller value in hours or minutes greater than or equal to 1.
 * @param seconds
 * @returns {{value: number, type: string}} Object describing conversion of seconds input to
 *     the rounded value in hours or minutes.
 */
function durationConverter(seconds) {
    const hour = 60 * 60;
    const minute = 60;

    const hours = seconds / hour;
    const minutes = seconds / minute;

    if (hours >= 1) {
        return {
            value: +hours.toFixed(0),
            type: DURATION_TYPE.HOURS,
        };
    } else if (minutes >= 1) {
        return {
            value: +minutes.toFixed(0),
            type: DURATION_TYPE.MINUTES,
        };
    }

    return {
        value: seconds || 0,
        type: DURATION_TYPE.SECONDS,
    };
}

/**
 * @ngdoc directive
 * @name durationParser
 * @restrict A
 * @description
 *     Parses ngModel $viewValue to hours, minutes or seconds based on type specified.
 * @example
 *      <input ng-model="1000" duration-parser="minutes">
 *      <!-- ngModel.$modelValue will be 17 -->
 */
angular.module('aviApp').directive('durationParser', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            durationParser: '=',
        },
        link(scope, element, attr, ngModelCtrl) {
            /**
             * Used for testing.
             * @private {ng.ngModel.NgModelController}
             */
            scope.ngModelCtrl_ = ngModelCtrl;
            ngModelCtrl.$parsers.push(function(value) {
                value = value || 0;

                const duration = scope.durationParser || DURATION_TYPE.SECONDS;

                switch (duration) {
                    case DURATION_TYPE.HOURS:
                        return value * 60 * 60;
                    case DURATION_TYPE.MINUTES:
                        return value * 60;
                    default:
                        return value;
                }
            });
        },
    };
});

/**
 * @ngdoc directive
 * @description
 *     Creates component with an numeric input field and drop-down selector for hours, minutes
 *     or seconds. Auto-converts between hours, minutes and seconds at start to the closest
 *     whole value based on ngModel value.
 * @example
 *    <div duration-combo-box="1000"></div>
 *    <!-- Will display  17 in the input field with "minutes" selected in the drop-down
 *         initially -->
 */
angular.module('aviApp').directive('durationComboBox', function() {
    const link = function(scope, element) {
        element.addClass('duration-combo-box');

        const durations = [
            {
                value: DURATION_TYPE.HOURS,
                name: 'Hours',
            }, {
                value: DURATION_TYPE.MINUTES,
                name: 'Minutes',
            }, {
                value: DURATION_TYPE.SECONDS,
                name: 'Seconds',
            },
        ];

        scope.selectedDuration = DURATION_TYPE.SECONDS;
        scope.durations = durations;

        const unWatch = scope.$watch(function() {
            return scope.value;
        }, function(newValue, oldValue) {
            if (newValue !== oldValue) {
                unWatch();

                const duration = durationConverter(newValue);

                scope.selectedDuration = duration.type;
                scope.value = duration.value;
            }
        });
    };

    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            value: '=ngModel',
            required: '=',
            placeholder: '@',
        },
        link,
        templateUrl: 'src/js/directives/duration-combo-box/duration-combo-box.html',
    };
});
