/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('AlertActionController', [
'$scope', '$stateParams', 'Collection', 'AlertActionCollection', 'Regex',
'SnmpProfileCollection', 'CRUDGridConfig',
function($scope, $stateParams, Collection, AlertActionCollection, Regex,
SnmpProfileCollection, CRUDGridConfig) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();
    $scope.Regex = Regex;

    // Instantiate collection
    $scope.collection = new AlertActionCollection();

    $scope.EmailCollection = new Collection({
        objectName: 'alertemailconfig',
        windowElement: 'adm-email-create',
    });

    $scope.SyslogCollection = new Collection({
        objectName: 'alertsyslogconfig',
        windowElement: 'adm-syslog-create',
    });

    $scope.ControlScriptCollection = new Collection({
        objectName: 'alertscriptconfig',
        windowElement: 'prof-controlscripts-create',
    });

    $scope.SnmpProfileCollection = new SnmpProfileCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above.
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        },
        {
            name: 'level',
            title: 'Alert Level',
            transform(row) {
                return row.data.config.level.enumeration('ALERT_');
            },
        },
        {
            name: 'syslog_config_ref',
            title: 'Syslog',
            transform(row) {
                return row.data.config.syslog_config_ref ?
                    row.data.config.syslog_config_ref.name() : '-None-';
            },
        },
        {
            name: 'actions.email_config_ref',
            title: 'Email',
            transform(row) {
                return row.data.config.email_config_ref ?
                    row.data.config.email_config_ref.name() : '-None-';
            },
        },
        {
            name: 'actions.snmp_trap_profile_ref',
            title: 'SNMP Trap',
            transform(row) {
                return row.data.config.snmp_trap_profile_ref ?
                    row.data.config.snmp_trap_profile_ref.name() : '-None-';
            },
        },
        {
            name: 'actions.action_script_config_ref',
            title: 'Control Script',
            transform(row) {
                return row.data.config.action_script_config_ref ?
                    row.data.config.action_script_config_ref.name() : '-None-';
            },
        },
        {
            name: 'external_only',
            title: 'External Only',
            template: '<span class="sel-automatic" ' +
                'ng-if="row.data.config.external_only">Yes</span>' +
                '<span class="sel-automatic" ng-if="!row.data.config.external_only">No</span>',
        },

    ];
}]);
