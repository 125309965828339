/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import './dropdown-list-option.component.less';

const template = '<div class="dropdown-list-option" ' +
    'ng-click="$ctrl.onClick({ value: $ctrl.option.value })">{{ ::$ctrl.option.label }}</div>';

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.dropdownListOptionBindings
 */
class DropdownListOptionComponentController {}

/**
 * @mixin dropdownListOptionComponentBindings
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.DropdownListOption} option - Option object.
 * @property {Function} onClick - Function to be called with option value.
 */

/**
 * @name DropdownListOptionComponent
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.dropdownListOptionComponentBindings} bindings
 * @property {module:avi/component-kit.DropdownListOptionComponentController} controller
 * @description
 *
 *     The component to build a default dropdown list option.
 *
 * @see {@link module:avi/component-kit.DropdownListComponentController}
 * @author Zhiqian Liu
 */
angular.module('avi/component-kit').component('dropdownListOption', {
    bindings: {
        option: '<',
        onClick: '&',
    },
    controller: DropdownListOptionComponentController,
    template,
});
