/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './analytics-profile-modal.less';
import { analyticsProfileAnalyticsTypeLabelHash }
    from '../../../../../js/constants/analytics-enabled-keys.constant';

class AnalyticsProfileModalController {
    constructor(dropDownUtils, $element, schemaService, Schema) {
        this.$element_ = $element;
        this.dropDownUtils_ = dropDownUtils;
        this.schemaService_ = schemaService;

        this.analyticsTypeOptions = [];
        this.Schema = Schema;
    }

    $onInit() {
        const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);

        this.analyticsTypeOptions =
            analyticsPropertyList.map(key => {
                const tooltip = this.schemaService_.getFieldDescription('AnalyticsProfile', key);

                const label =
                    analyticsProfileAnalyticsTypeLabelHash[key];

                return this.dropDownUtils_.createOption(key, label, tooltip);
            });

        this.hsPerformanceBoostRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_performance_boost');

        this.hsMaxAnomalyPenaltyRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_max_anomaly_penalty');

        this.hsMaxResourcesPenaltyRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_max_resources_penalty');

        this.hsMaxSecurityPenaltyRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_max_security_penalty');

        this.hsSecurityChainInvalidityPenaltyRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_security_chain_invalidity_penalty');

        this.hsSecurityHstsPenaltyRange = this.schemaService_
            .getFieldRangeAsTuple('AnalyticsProfile', 'hs_security_hsts_penalty');
    }
}

AnalyticsProfileModalController.modalPortalClassName = 'avi-modal';

AnalyticsProfileModalController.$inject = [
    'dropDownUtils',
    '$element',
    'schemaService',
    'Schema',
];

/**
 * @ngdoc component
 * @name analyticsProfileModal
 * @author Chitra
 * @param {AnalyticsProfile} editable - AnalyticsProfile item.
 * @param {Function} closeModal - Closes modal.
 * @description Creates/edits an AnalyticsProfile item
 */
angular.module('aviApp').component('analyticsProfileModal', {
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: AnalyticsProfileModalController,
    templateUrl: 'src/components/templates/profiles/analytics/' +
        'analytics-profile-modal/analytics-profile-modal.html',
});
