/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function licenseUsageFactory(UpdatableBase) {
    /**
     * @alias module:services/LicenseUsage
     * @extends UpdatableBase
     */
    class LicenseUsage extends UpdatableBase {
        /**
         * Assigns source, transformer, transporter, and objectName_.
         * @param {Object} args - any overrides to be extended
         */
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'LicenseUsageDataSource',
                    transformer: 'LicenseUsageDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            angular.extend(args, {
                defaultDataSources: 'list',
                isStatic: true,
                allDataSources,
            });

            super(args);

            this.objectName_ = 'licenseusage';
        }
    }

    return LicenseUsage;
}

licenseUsageFactory.$inject = ['UpdatableBase'];

/**
 * @ngdoc service
 * @name LicenseUsage
 * @module services/LicenseUsage
 * @author Akul Aggarwal
 * @description
 *
 *      License usage information for summary table.
 *
 */
angular.module('aviApp').factory('LicenseUsage', licenseUsageFactory);
