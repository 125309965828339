/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {{
 *   name: string,
 *   params: Object
 * }} AppState
 */

const { appStates } = require('../constants/app-config/app-state.constants.js');

/**
 * Session storage property name for the last active state.
 * @type {string}
 */
const sessionStorageStatePropName = 'applicationState';

/**
 * @ngdoc service
 * @name appStateHandler
 * @description {@link ui.router.$transitions.onStart} event handler. Used by app.js.
 */
class AppStateHandlerService {
    constructor(
        $state,
        $rootScope,
        $timeout,
        $location,
        Auth,
        Timeframe,
        defaultValues,
        AviMessage,
        AviModal,
        AviAlertService,
    ) {
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.Auth = Auth;
        this.$timeout = $timeout;
        this.Timeframe = Timeframe;
        this.defaultValues_ = defaultValues;
        this.AviMessage_ = AviMessage;
        this.AviModal_ = AviModal;
        this.AviAlertService_ = AviAlertService;
    }

    /**
     * Removes initial backdrop overlay from the page.
     */
    removeInitialOverlay() {
        const mask = $('body > div.avi-welcome:visible');

        if (mask.length) {
            this.$timeout(() => {
                mask.addClass('animated fadeOut');
                this.$timeout(() => mask.remove(), 500);
            }, 200);
        }
    }

    /**
     * Returns the next state that should be transitioned to if the current state is not
     * allowed.
     * @param  {string} stateName
     * @param  {?Object} params
     * @return {string}
     */
    getAllowedNextState(stateName) {
        const { DEFAULT_STATE } = appStates;

        if (!stateName) {
            return DEFAULT_STATE;
        }

        const state = this.$state.get(stateName);
        const stateData = state.data;

        const allowed = this.Auth.isPrivilegeAllowed(
            stateData.permission, stateData.permissionType,
        );

        if (allowed && !state.abstract) {
            return stateName;
        } else {
            const nextState = Object.prototype.hasOwnProperty.call(stateData, 'nextChildState') &&
                stateData.nextChildState || stateData.nextState;

            return this.getAllowedNextState(nextState || nextState.parentState);
        }
    }

    /**
     * Handles states change. Used with ui-router Transition Hooks.
     * @param {Object} transition - ui-router transition object.
     */
    handleTransitionStart = transition => {
        const {
            DEFAULT_STATE,
            LOGIN_STATE,
            LOGGED_OUT_STATE,
            WELCOME_STATE,
            PASSWORD_CHANGE_STATE,
            PLEASE_RELOAD_STATE,
            CONTROLLER_DOWN_STATE,
            PRE_WELCOME_AWS_STATE,
        } = appStates;

        const toState = transition.to();
        const nextState = toState.name;

        if (nextState === CONTROLLER_DOWN_STATE ||
            nextState === LOGGED_OUT_STATE ||
            nextState === '520'
        ) {
            this.removeInitialOverlay();//for force reload of 503 state only
            this.Auth.removeData();
            this.defaultValues_.removeData();

            return;
        }

        const { stateService } = transition.router;
        const toParams = transition.params('to');

        if (!this.Auth.initialized()) {
            const authParams = {
                tenantName: toParams.tenantName,
            };

            //TODO take from localStorage on page reload
            if (this.$rootScope.embeddedMode) {
                authParams.appMode = 'embedded';
            }

            return this.Auth.initialize(authParams)
                .then(state => {
                    // If the state is a string, it's the value returned from
                    // Auth.$window.location.href, which means that there is a redirect to a
                    // third-party site, in which case we should not remove the initial overlay
                    // during redirection.
                    if (typeof state !== 'string') {
                        this.removeInitialOverlay();
                    }

                    return stateService.target(nextState, toParams);
                })
                .catch(() => {
                    this.removeInitialOverlay();

                    return stateService.target(CONTROLLER_DOWN_STATE);
                });
        }

        const isLoggedIn = this.Auth.isLoggedIn();
        const toStateData = toState.data;

        if (isLoggedIn && toStateData && toStateData.permission) {
            const tenantName = this.Auth.getTenantName();

            if (!toParams.tenantName || toParams.tenantName !== tenantName) {
                return stateService.target(
                    nextState, angular.extend({}, toParams, { tenantName }),
                );
            }

            const allowed = this.Auth.isPrivilegeAllowed(
                toStateData.permission,
                toStateData.permissionType,
            );

            if (!allowed) {
                const allowedNextState = this.getAllowedNextState(toStateData.nextState);

                return stateService.target(allowedNextState);
            }
        }

        // after fresh deploy state, admin user password is not yet set
        if (this.Auth.isAdminUserSetupRequired()) {
            const allowedTargetState =
                this.Auth.isAWSCloud() ? PRE_WELCOME_AWS_STATE : WELCOME_STATE;

            if (nextState !== allowedTargetState) {
                return stateService.target(allowedTargetState);
            }

            return;
        }

        // user is not logged in
        if (!isLoggedIn) {
            switch (nextState) {
                case LOGIN_STATE:
                case LOGGED_OUT_STATE:
                case PLEASE_RELOAD_STATE:
                case PASSWORD_CHANGE_STATE:
                    return;

                default:
                    return stateService.target(LOGIN_STATE);
            }
        }

        // admin password is set but UI welcome has not been completed
        if (!this.Auth.isWelcomeWorkflowCompleted()) {
            if (nextState !== WELCOME_STATE) {
                return stateService.target(WELCOME_STATE);
            }

            return;
        }

        // logged in user is heading to welcome when it is not needed
        if (nextState === WELCOME_STATE) {
            return stateService.target(DEFAULT_STATE);
        }

        // user is logged in and is heading to the un-authenticated pages
        if (nextState === LOGIN_STATE ||
            nextState === PLEASE_RELOAD_STATE ||
            nextState === LOGGED_OUT_STATE
        ) {
            return stateService.target(DEFAULT_STATE);
        }

        // all normal states

        // TODO move timeframe business to state change success event
        const suppParams = '$$state' in toState && toState.$$state().params;
        const isTimeframeState = suppParams && 'timeframe' in suppParams;

        if (isTimeframeState) {
            this.Timeframe.setGroup(
                toState.data && toState.data.tfGroup,
                toParams.timeframe,
                'appStateHandler',
            );
        }
    };

    /**
     * Event handler for app state transition success.
     * @param {Object} transition
     */
    handleTransitionSuccess = transition => {
        const targetState = transition.to();
        const { name: targetStateName } = targetState;
        const targetStatePathChunks = targetStateName.split('.');

        if (_.contains(targetStatePathChunks, 'authenticated')) {
            const toParams = transition.params('to');

            AppStateHandlerService.saveState_(targetStateName, toParams);
        }
    };

    /**
     * Event handler for app state transition error.
     * @param {Object} transition
     */
    handleTransitionError = transition => {
        const { detail } = transition.error();

        if (angular.isObject(detail)) {
            const { status } = detail;

            if (status === 404 || status === 403) {
                const {
                    AviMessage_: AviMessage,
                    AviModal_: AviModal,
                    AviAlertService_: AviAlertService,
                } = this;

                const { DEFAULT_STATE } = appStates;

                this.$state.go(DEFAULT_STATE)
                    .then(() => {
                        AviMessage.destroyAll();
                        AviModal.destroyAll();
                        AviAlertService.clear();
                    });
            }
        }
    };

    /**
     * Saves state info into the session storage.
     * @param {string} name
     * @param {Object} params
     * @protected
     */
    static saveState_(name, params = {}) {
        const state = {
            name,
            params,
        };

        sessionStorage.setItem(sessionStorageStatePropName, JSON.stringify(state));
    }

    /**
     * Returns stored state data if present.
     * @return {Object.<string, string|Object>}
     */
    static getLastActiveState() {
        let state = null;

        try {
            const json = sessionStorage.getItem(sessionStorageStatePropName);

            state = JSON.parse(json);
        } catch (e) {
            /* eslint-disable-line-no-empty-block */
        }

        if (!state) {
            return {
                name: '',
            };
        }

        return state;
    }
}

AppStateHandlerService.$inject = [
    '$state',
    '$rootScope',
    '$timeout',
    '$location',
    'Auth',
    'Timeframe',
    'defaultValues',
    'AviMessage',
    'AviModal',
    'AviAlertService',
];

angular.module('aviApp').service('appStateHandler', AppStateHandlerService);
