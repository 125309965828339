/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PoolGroupDeploymentPolicyCollection
 * @description
 *     Pool Group Deployment Policy collection.
 */
angular.module('aviApp').factory('PoolGroupDeploymentPolicyCollection', [
'Collection', 'PoolGroupDeploymentPolicy',
function(Collection, PoolGroupDeploymentPolicy) {
    class PoolGroupDeploymentPolicyCollection extends Collection {}

    angular.extend(PoolGroupDeploymentPolicyCollection.prototype, {
        objectName_: 'poolgroupdeploymentpolicy',
        windowElement_: 'pool-group-deployment-policy-modal',
        itemClass_: PoolGroupDeploymentPolicy,
    });

    return PoolGroupDeploymentPolicyCollection;
}]);
