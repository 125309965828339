/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class AnalyticsProfileController {
    constructor(CRUDGridConfig, AnalyticsProfileCollection) {
        this.collection_ = new AnalyticsProfileCollection();

        const config = {
            collection: this.collection_,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
                sortBy: 'name',
            }],
            singleactions: [{
                title: 'Edit',
                class: 'icon-pencil-4',
                hidden: row => !row.isAllowed(),
                do(row) {
                    row.isEditable() ? row.edit() : this.config.collection.clone(row);
                },
            }],
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection_.destroy();
    }
}

AnalyticsProfileController.$inject = [
    'CRUDGridConfig',
    'AnalyticsProfileCollection',
];

angular.module('aviApp').controller('AnalyticsProfileController', AnalyticsProfileController);
