/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name CollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     Collection Data Source is a layer between Collection and DataTransformer responsible for
 *     initial preparation of request payload for API call and processing received and
 *     pre-processed by DataTransformer data. Tightly bound to Collection by Collection#items
 *     and uses many of it's methods such as updateItemData, appendItem, removeItem on data
 *     processing.
 *
 *     Collection users need to interact with Collection's methods and properties such as sorting,
 *     search string, ordering, viewportSizeChange and some more generic ones - such as
 *     setting up referred_by filter with have to be taken into consideration while making
 *     actual API calls. Collection notifies all it's DataSources when such updates are being
 *     made and each CollDataSource is responsible for setting corresponding internal state
 *     which would be represented by request parameters object.
 *
 *     Other duty of CollDataSource is to deliver new data updates to Collection#items. In case of
 *     `config` list source CollDataSource will figure out where each of Items it got should be
 *     landed as updated/created/moved or be removed at all since it had been removed on the
 *     backend side. In case of any other update types each Item will get a data object to
 *     update itself with received data (list and it's order won't change).
 *
 */

//TODO figure out what to do with page_size
//TODO manual setup (construction run) is a mess. Nobody gonna use it
//TODO After params/sort/search update items list becomes invalid until the next load and
//TODO furthermore - wo flush next update will make state even more inconsistent!
angular.module('aviApp').factory('CollDataSource', [
'$q', 'DataSource', 'Timeframe', 'aviInherit',
function($q, DataSource, Timeframe, aviInherit) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends Base
     * @abstract
     */
    function CollDataSource(args = {}) {
        CollDataSource.superconstructor.call(this, args);

        //extends default API/DS features with ones from args
        _.each(['hasSearch', 'hasSorting', 'hasPagination'], function(propertyName) {
            this[propertyName] = !_.isUndefined(args[propertyName]) ? !!args[propertyName] :
                this[propertyName];
        }, this);

        this.onTimeframeChange_ = this.onTimeframeChange_.bind(this);
        Timeframe.on('change', this.onTimeframeChange_);

        this.onItemDrop_ = this.onItemDrop_.bind(this);
        this.owner_.on('collectionItemDropSuccess', this.onItemDrop_);
    }

    aviInherit(CollDataSource, DataSource);

    /**
     * True if search functionality is available for this DS.
     * @type {boolean}
     * @public
     */
    CollDataSource.prototype.hasSearch = false;

    /**
     * True if sorting functionality is available for this DS.
     * @type {boolean}
     * @public
     */
    CollDataSource.prototype.hasSorting = false;

    /**
     * True if pagination (offset&limit) functionality is available for this DS.
     * @type {boolean}
     * @public
     */
    CollDataSource.prototype.hasPagination = false;

    /**
     * Param name for the basic search functionality. Search param value will be passed up the
     * chain using this property name.
     * @type {string}
     * @inner
     */
    CollDataSource.prototype.defaultSearchParamName_ = '';

    /**
     * True id DS has a `total` number of item provided by backend.
     * @type {boolean}
     * @public
     */
    CollDataSource.prototype.hasTotalNumberOfItems = false;

    /**
     * Event listener for {@link Timeframe} change event.
     * @private
     */
    CollDataSource.prototype.onTimeframeChange_ = function() {
        this.setUpdateInterval(Timeframe.selected().interval);
    };

    /**
     * Kinda event-handler for offset change to be called by Collection.
     * @param {number} offset - How many rows are hidden above the top of viewport.
     * @param {string[]} visibleItemIds - List of visible Item ids.
     * @param {boolean=} noEvent - When truthy no onOffsetChange event will be fired.
     * @returns {ng.$q.promise}
     * @abstract
     */
    CollDataSource.prototype.setOffset = function(offset, visibleItemIds, noEvent) {
        return $q.reject('Abstract `sefOffset` method of `CollDataSource` has been called.');
    };

    /**
     * When settOffset is debounced we want to maintain ability to run same method immediately.
     * @private
     * @abstract
     */
    CollDataSource.prototype.setOffset_ = CollDataSource.prototype.setOffset;

    /**
     * Kinda event handler for viewport size change event to be called by viewport directive
     * through Collection.
     * @param {number} limit - How many items can we show at once.
     * @param {boolean} noEvent - When truthy no onOffsetChange event will be fired.
     * @returns {ng.$q.promise}
     * @abstract
     * @public
     */
    CollDataSource.prototype.setLimit = function(limit, noEvent) {
        return $q.reject('Abstract `setLimit` method of `CollDataSource` has been called.');
    };

    /**
     * When setLimit is debounced we want to maintain ability to run same method immediately.
     * @private
     * @abstract
     */
    CollDataSource.prototype.setLimit_ = CollDataSource.prototype.setLimit;

    /**
     * Sets a search keyword when search is available.
     * @param {string|string[]=} fieldNames - List of searchable fields or just one.
     * @param {string=} keyword - Undefined or empty string will switch search feature off.
     * @returns {boolean} - true when new keyword has been set, false otherwise.
     * @public
     * @abstract
     */
    CollDataSource.prototype.setSearchParam = function(fieldNames, keyword) { return false; };

    /**
     * Sets a sorting parameter when available.
     * @param {string=} propertyName. Sorting will be switched off when undefined or empty
     *     string has been provided.
     * @returns {boolean} - True when new sorting has been set, false otherwise.
     * @public
     * @abstract
     */
    CollDataSource.prototype.setSortParam = function(propertyName) { return false; };

    /**
     * Returns a field name which is selected for sorting. Can have '-' prefix.
     * @returns {string|undefined} - Undefined when no sorting is set.
     * @public
     * @abstract
     */
    CollDataSource.prototype.getSortParam = angular.noop;

    /**
     * Some dataSources know how many Items are available on the back-end side even when we have
     * loaded handful. Aka `count`.
     * @returns {number|undefined} - Undefined if not supported.
     * @abstract
     * @public
     */
    CollDataSource.prototype.getTotalNumberOfItems = angular.noop;

    /**
     * Returns true when API used by this DS provides a total items quantity on data load.
     * @type {boolean}
     * @abstract
     * @public
     */
    CollDataSource.prototype.hasRealTotalNumberOfItems = function() {
        return false;
    };

    /**
     * Sometimes we want to have DataSource notified of Item drop event for consistency
     * purposes. For example to update DS#itemsTotal_ value before we make next load.
     * @param {string=} droppedItemId - Item#id
     * @abstract
     * @protected
     */
    CollDataSource.prototype.onItemDrop_ = function(droppedItemId) {};

    /** @override */
    CollDataSource.prototype.reset = function() {
        this.setSearchParam();
        this.setSortParam();

        CollDataSource.superclass.reset.call(this);
    };

    /**
     * Destroys the data source. Main point is to stop async factories, cancel all pending calls
     * and remove event listeners.
     * @param {boolean=} force - When true is passed will destroy CollDataSource even if it is
     *     `preserved`.
     */
    CollDataSource.prototype.destroy = function(force) {
        const gotDestroyed = CollDataSource.superclass.destroy.call(this, force);

        if (gotDestroyed) {
            Timeframe.unbind('change', this.onTimeframeChange_);
            this.owner_.unbind('collectionItemDropSuccess', this.onItemDrop_);
        }

        return gotDestroyed;
    };

    return CollDataSource;
}]);
