/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function openstackNetworkFactory(
    Item,
) {
    class OpenstackNetwork extends Item {
        /**
         * @override
         */
        isEditable() {
            return false;
        }
    }

    OpenstackNetwork.prototype.objectName = 'openstack-get-tenant-networks';

    return OpenstackNetwork;
}

openstackNetworkFactory.$inject = [
    'Item',
];

/**
 * @ngdoc service
 * @name OpenstackNetwork
 * @author Chitra
 * @description OpenstackNetwork item.
 */
angular.module('aviApp')
    .factory('OpenstackNetwork', openstackNetworkFactory);
