/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const techSupportStatusHash = {
    SYSERR_TECH_SUPPORT_COLLECTION_ABORTED: 'SYSERR_TECH_SUPPORT_COLLECTION_ABORTED',
    SYSERR_TECH_SUPPORT_INVALID_FILENAME: 'SYSERR_TECH_SUPPORT_INVALID_FILENAME',
    SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS:
        'SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS',
    SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS: 'SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS',
    SYSERR_TECH_SUPPORT_COLLECTION_STARTED: 'SYSERR_TECH_SUPPORT_COLLECTION_STARTED',
    SYSERR_TECH_SUPPORT_COLLECTION_ONGOING: 'SYSERR_TECH_SUPPORT_COLLECTION_ONGOING',
    SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS: 'SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS',
    SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS:
        'SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS',
    SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE: 'SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE',
    SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND:
        'SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND',
};

/**
 * @typedef {{TechSupportSharedStatus.type: string}} TechSupportTypes
 * Remaining ones can simply have first letter capitalized to make readable.
 */
const techSupportReadableTypesHash = {
    sys_details: 'System Details',
    metricslogs: 'Metrics Logs',
    debuglogs: 'Debug Logs',
    serviceengine: 'Service Engine',
    virtualservice: 'Virtual Service',
    gslb: 'GSLB',
};

const techSupportTypesHash = {
    CLUSTERING: 'clustering',
    DEBUG_LOGS: 'debuglogs',
    GSLB: 'gslb',
    METRICS_LOGS: 'metricslogs',
    PLACEMENT: 'placement',
    POOL: 'pool',
    PORTAL: 'portal',
    SERVICE_ENGINE: 'serviceengine',
    UPGRADE: 'upgrade',
    VIRTUAL_SERVICE: 'virtualservice',
};

angular.module('aviApp')
    .constant('techSupportStatus', techSupportStatusHash)
    .constant('techSupportReadableTypes', techSupportReadableTypesHash)
    .constant('techSupportTypesHash', techSupportTypesHash);
