/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  IPAMProfileCollection
 * @description  IPAMProfile collection.
 */
const IPAMProfileCollectionFactory = (Collection, IPAMProfile) => {
    class IPAMProfileCollection extends Collection {}

    angular.extend(IPAMProfileCollection.prototype, {
        objectName_: 'ipamdnsproviderprofile',
        itemClass_: IPAMProfile,
        windowElement_: 'ipam-dns-profiles-modal',
        serverDefaultsOverride_: {
            type: 'IPAMDNS_TYPE_INTERNAL',
        },
    });

    return IPAMProfileCollection;
};

IPAMProfileCollectionFactory.$inject = [
        'Collection',
        'IPAMProfile',
];

angular.module('aviApp').factory('IPAMProfileCollection', IPAMProfileCollectionFactory);
