/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name aviValue
 * @author Alex Malitsky
 * @param {number} value
 * @param {string} type - duration/bytes/bits
 * @description
 *
 *     Consistent formatting for typical values like bytes and durations.
 *
 **/
let
    msToStringChunks,
    convert;

class aviValueController {
    constructor(
        _msToStringChunks_,
        _convert_,
    ) {
        msToStringChunks = _msToStringChunks_;
        convert = _convert_;
    }

    $onInit() {
        this.valClass = 'timeValue val';
        this.dimClass = 'timing-units';
    }

    $onChanges({ value: valueChange }) {
        const { currentValue: value } = valueChange;

        const values = [];

        let valueObj;

        switch (this.type) {
            case 'duration':
                if (value < 1) {
                    values.push({
                        value: '< 1',
                        dimension: 'ms',
                    });
                } else if (value || _.isNaN(+value)) {
                    values.push(...msToStringChunks(value, true));
                } else {
                    values.push({
                        value: '',
                        dimension: '',
                    });
                }

                break;

            case 'bytes':
            case 'bits':
                valueObj = convert.getVal(value, this.type);

                values.push({
                    value: +valueObj.value.toFixed(2),
                    dimension: valueObj.unit,
                });

                break;
        }

        this.displayValues = values;
    }
}

aviValueController.$inject = [
    'msToStringChunks',
    'Convert',
];

angular.module('aviApp').component('aviValue', {
    controller: aviValueController,
    bindings: {
        value: '<',
        type: '@',
    },
    template:
        '<span ng-repeat="item in $ctrl.displayValues track by $index">' +
            '<span class="sel-value" ng-class="::$ctrl.valClass">{{item.value}}</span>' +
            '<span ng-class="::$ctrl.dimClass">{{item.dimension}}</span>' +
        '</span>',
});
