/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './main-menu-list.less';
import {
    APPLICATION_PERMISSION,
    OPERATIONS_PERMISSION,
    TEMPLATE_PERMISSION,
    INFRASTRUCTURE_PERMISSION,
    ADMINISTRATION_PERMISSION,
} from '../../../../js/constants/permissions.constant';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.mainMenuListComponent}
 */
class MainMenuListController {
    constructor($filter, $state, $stateParams) {
        this.$filter_ = $filter;
        this.$state_ = $state;
        this.$stateParams_ = $stateParams;
    }

    $onInit() {
        this.states = [
            {
                name: 'Applications',
                permission: APPLICATION_PERMISSION,
                partialState: 'authenticated.application',
            },
            {
                name: 'Operations',
                permission: OPERATIONS_PERMISSION,
                partialState: 'authenticated.operations',
            },
            {
                name: 'Templates',
                permission: TEMPLATE_PERMISSION,
                partialState: 'authenticated.profile',
            },
            {
                name: 'Infrastructure',
                permission: INFRASTRUCTURE_PERMISSION,
                partialState: 'authenticated.infrastructure',
            },
            {
                name: 'Administration',
                permission: ADMINISTRATION_PERMISSION,
                partialState: 'authenticated.administration',
            },
        ];
    }

    /**
     * Returns the full name of the state along with the timeframe as a param.
     * @param {string} partialState - Partial name of the state to go to, ex. authenticated.profile.
     */
    getState(partialState) {
        const stateName = this.$filter_('stateName')(this.$state_.get(partialState));

        return `${stateName}({ timeframe: '${this.$stateParams_.timeframe || ''}' })`;
    }
}

MainMenuListController.$inject = [
    '$filter',
    '$state',
    '$stateParams',
];

/**
 * @name mainMenuListComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.MainMenuListController} controller
 * @description
 *      Dropdown options for the mainMenu dropdown component.
 * @author alextsg
 */
angular.module('avi/navigation').component('mainMenuList', {
    controller: MainMenuListController,
    templateUrl: 'src/components/avi-header/main-menu/main-menu-list/' +
        'main-menu-list.html',
});
