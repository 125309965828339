/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc filter
 * @name healthScoreClass
 * @param {number} score - From 0 to 100.
 * @param {string} state - Runtime.oper_status.state enum.
 * @description
 * Filter to create a unitCard or aviHealthscore classNames from health score as a number
 * and operation state as a string (if provided).
 */
angular.module('aviApp').filter('healthScoreClass', function() {
    const
        highThreshold = 85,
        mediumThreshold = 65,
        lowThreshold = 0;

    const
        operationalHash = {
            OPER_UP: true,
            OPER_ACTIVE: true,
            OPER_ERROR_DISABLED: true,
            OPER_DISABLING: true,
            OPER_DISABLED: false,
            OPER_INACTIVE: false,
            OPER_UNUSED: false,
        };

    return (score, state) => {
        let str = '';

        const goodState = operationalHash[state];

        if (!state || goodState) {
            if (score >= highThreshold || goodState && angular.isUndefined(score)) {
                str = 'high';
            } else if (score >= mediumThreshold) {
                str = 'medium';
            } else if (score >= lowThreshold) {
                str = 'low';
            }
        } else if (operationalHash[state] === false) {
            str = 'disabled';
        } else {
            str = 'down';
        }

        return str;
    };
});
