/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function inventoryMapCollectionFactory(
    Collection,
    InventoryMap,
) {
    const dataSources = {
        list: {
            source: 'InventoryCollDataSource',
            transformer: 'InventoryMapDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health_score',
                'alert',
                'runtime',
            ],
        },
    };

    const defaultDataFields = [
        'config',
        'health_score',
        'alert',
        'runtime',
    ];

    /**
     * @class InventoryMapCollection
     * @extends module:avi/dataModel.Collection
     * @desc
     *
     *     Collection of {@link InventoryMap} items.
     *
     *     InventoryMap works on dashboard only and provides us with alert, health score,
     *     config and runtime for all nested items starting from VS. VS is a root object for
     *     InventoryMap so it uses vs.id as InventoryMap.id.
     *
     * @author Alex Malitsky
     **/
    class InventoryMapCollection extends Collection {
        /** @override */
        isCreatable() {
            return false;
        }
    }

    Object.assign(
        InventoryMapCollection.prototype,
        {
            objectName_: 'inventory-map',
            itemClass_: InventoryMap,
            allDataSources_: dataSources,
            defaultDataFields_: defaultDataFields,
            isStatic_: false,
            defaultViewportSize_: 10,
        },
    );

    return InventoryMapCollection;
}

inventoryMapCollectionFactory.$inject = [
    'Collection',
    'InventoryMap',
];

angular.module('aviApp')
    .factory('InventoryMapCollection', inventoryMapCollectionFactory);
