/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  WafPolicyPsmGroup
 * @description  WafPolicyPsmGroup item.
 */
const wafPolicyPsmGroupFactory = (ObjectTypeItem, GroupCollMetric) => {
    class WafPolicyPsmGroup extends ObjectTypeItem {
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_WAFPOLICYPSMGROUP',
                objectType: 'WafPolicyPSMGroup',
            };

            super(extendedArgs);
        }

        /**
         * Returns the enable state.
         * @returns {boolean}
         */
        get enable() {
            return this.config.enable;
        }

        /**
         * Sets the enable state.
         * @param {boolean} enabled - New state to set.
         */
        set enable(enabled) {
            this.config.enable = enabled;
        }

        /**
         * Returns the RepeatedConfigItem for WafPsmLocations.
         * @returns {RepeatedMessageItem}
         */
        get locations() {
            return this.config.locations;
        }

        /**
         * Returns true if the PSM Group is a learning group.
         * @returns {boolean}
         */
        get isLearningGroup() {
            return this.config.is_learning_group;
        }

        /**
         * Returns a new WafPsmLocation instance.
         * @param {Object} config - Config data.
         * @returns {WafPsmLocation}
         */
        createNewLocation(config = null) {
            return this.createChildByField_('locations', config, true);
        }

        /**
         * Adds a WafPsmLocation instance to the list of WafPsmLocations. If the index exists, that
         * means that a location is being edited.
         * @param {WafPsmLocation} location
         */
        addLocation(location) {
            const { locations } = this.config;
            const indexField = location.getIndex();

            // New rule doesn't have an index.
            if (_.isUndefined(indexField)) {
                const maxIndex = locations.getMaxIndex();
                const newIndex = _.isNaN(+maxIndex) ? 0 : maxIndex + 1;

                location.setIndex(newIndex);
                locations.add(location);
            } else {
                const arrayIndex = locations.getArrayIndexWithIndexField(indexField);

                locations.config[arrayIndex] = location;
            }
        }

        /**
         * @override
         * We pass '*' as the entity_uuid because the metrics are based in the context of VSes, so
         * use '*' to gather all VS data. obj_id is the id of the PSM Group.
         * TODO: Use the inventory API to get a list of VSes for each PSM Group to replace '*'.
         */
        getMetricsTuple() {
            return {
                entity_uuid: '*',
                obj_id: this.id,
                dimension_filter_op: 'METRICS_FILTER_CONTAINS',
            };
        }

        /**
         * @override
         * We are overriding this to pass in the dimension_aggregation to use AggSeries.
         */
        getCollMetricsRequests(fields) {
            const requests = [];

            if (Array.isArray(fields)) {
                _.each(fields, fieldName => {
                    if (this.dataFieldIsApplicable(fieldName)) {
                        if (!(fieldName in this.collMetricsHash)) {
                            this.collMetricsHash[fieldName] = new GroupCollMetric({
                                name: fieldName,
                                dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
                                item: this,
                                subscribers: ['GroupCollMetricRequest'],
                                series: fieldName,
                            });
                        }

                        const request = this.collMetricsHash[fieldName].beforeCall();

                        if (request) {
                            requests.push(request.series);
                        }
                    }
                });
            }

            return requests;
        }
    }

    Object.assign(WafPolicyPsmGroup.prototype, {
        objectName: 'wafpolicypsmgroup',
        windowElement: 'waf-policy-psm-group-modal',
    });

    return WafPolicyPsmGroup;
};

wafPolicyPsmGroupFactory.$inject = [
    'ObjectTypeItem',
    'GroupCollMetric',
];

angular.module('waf.vantage.avi').factory('WafPolicyPsmGroup', wafPolicyPsmGroupFactory);
