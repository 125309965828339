/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './tri-switch.less';

/**
 * @ngdoc component
 * @name triSwitch
 * @param {number} state - Expression evaluating to a number that indicates the state of the
 *     switch. 0 for gray, 1 for green. and 2 for indeterminate (green-dashed).
 * @param {Function} onClick - Function to be called when the tri-switch is clicked.
 * @param {string} label - String to be shown as the label for the tri-switch.
 * @param {boolean} [small=] - True to use the small style.
 * @description 3-state switch component.
 */
class TriSwitchController {
    constructor($element) {
        this._$element = $element;
    }

    $onInit() {
        this._$element.addClass('tri-switch');

        if (this.small) {
            this._$element.addClass('tri-switch--small');
        }
    }

    /**
     * Handles clicking the switch.
     * @param {angular.$event} $event
     */
    handleClick($event) {
        $event.stopPropagation();
        this.onClick();
    }
}

TriSwitchController.$inject = [
    '$element',
];

angular.module('aviApp').component('triSwitch', {
    controller: TriSwitchController,
    bindings: {
        state: '<',
        onClick: '&',
        showGrayed: '<',
        small: '<?',
    },
    template: require('./tri-switch.partial.html'),
});
