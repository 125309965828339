/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/sparkline-card.less';

angular.module('aviApp').directive('sparklineCard', ['chartValueController',
function(chartValueController) {
    return {
        scope: true,
        restrict: 'E',
        templateUrl: '/src/views/components/sparkline-card.tpl.html',
        controller: ['$scope', chartValueController],
        link: function sparklineCardController(scope) {
            scope.padding = {
                top: 5,
                bottom: 5,
                left: 5,
                right: 5,
            };
        },
    };
}]);
