/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './utc-mismatch-popup.less';
import * as moment from 'moment';

/**
 * @class
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.utcMismatchPopupBindings
 * @see {@link module:avi/app.utcMismatchPopupComponent}
 */
class UtcMismatchPopupController {
    constructor(Auth) {
        this.Auth_ = Auth;
    }

    /**
     * @override
     */
    $onInit() {
        this.renderUtcMismatchPopup_();
    }

    /**
    * Renders a popup showing that there's a client clock mismatch.
    * @protected
    */
    renderUtcMismatchPopup_() {
        const { initData } = this.Auth_;
        const { timeDifference: timeDiff } = initData;
        const clientUtcTime = moment.utc();
        const diffInSec = moment.duration(Math.abs(timeDiff), 's');

        // add/subtract matching the way diff was calculated
        this.serverTime = clientUtcTime.subtract(timeDiff, 's').format('L LTS');
        this.diff = Math.abs(timeDiff); // no point to have sign there
        this.diffInHrMin = `${diffInSec.hours()}hr:${diffInSec.minutes()}min`;
        this.localClockIsBehind = timeDiff < 0;
        this.userHasAccessToSysConfig = this.Auth_.isAllowed('systemconfiguration');
    }
}

UtcMismatchPopupController.$inject = [
    'Auth',
];

/**
 * @name utcMismatchPopupComponent
 * @property {module:avi/app.UtcMismatchPopupController} controller
 * @property {module:avi/app.utcMismatchPopupBindings} bindings
 * @memberOf module:avi/app
 * @description Component for UTC Mismatch Popup.
 * @author alextg, chitra
 */
angular.module('avi/app').component('utcMismatchPopup', {
    /**
     * @mixin utcMismatchPopupBindings
     * @memberOf module:avi/app
     * @property {Function} onClose - method to be called when notification gets closed.
     */
    bindings: {
        onClose: '&',
    },
    controller: UtcMismatchPopupController,
    templateUrl: 'src/components/avi-root/utc-mismatch-popup/utc-mismatch-popup.html',
});
