/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const ipAddrRangeConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/IpAddrRangeConfigItem
     * @private
     */
    class IpAddrRangeConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'IpAddrRange',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            const { begin, end } = this.config;

            return `${begin.toString()} - ${end.toString()}`;
        }
    }

    return IpAddrRangeConfigItem;
};

ipAddrRangeConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  IpAddrRangeConfigItem
 * @description  IpAddrRange ConfigItem class.
 * @module config-item/IpAddrRangeConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('IpAddrRangeConfigItem', ipAddrRangeConfigItemFactory);
