/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  WafProfile
 * @description  WafProfile item.
 */
const WafProfileFactory = (Item, defaultValues) => {
    class WafProfile extends Item {
        constructor(args) {
            super(args);

            /**
             * True if config.config['learning_params'] should be deleted before saving.
             * @protected
             */
            this.shouldDeleteLearningParameters_ = false;
        }

        /**
         * Sets the this.shouldDeleteLearningParameters_ flag.
         * @param {boolean} shouldDelete - True to set this.shouldDeleteLearningParameters_ to true.
         */
        setShouldDeleteLearningParameters(shouldDelete) {
            this.shouldDeleteLearningParameters_ = shouldDelete;
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (this.shouldDeleteLearningParameters_) {
                delete config.config['learning_params'];
            }

            return config;
        }

        /** Adds an entry to config.files. */
        addFile() {
            const config = this.getConfig();

            config.files = config.files || [];
            config.files.push({});
        }

        /**
         * Removes an entry from config.files.
         * @param {number=} index - Index of entry to remove.
         */
        removeFile(index = 0) {
            const { files } = this.getConfig();

            files.splice(index, 1);
        }

        /**
         * Getter function for an enum to be used for the 'min_confidence' value.
         * @returns {string}
         */
        get minConfidence() {
            const { config } = this.getConfig();

            return config.min_confidence;
        }

        /**
         * Setter function to set the 'min_confidence' enum value.
         * @param {string} enumValue - Enum to set.
         */
        set minConfidence(enumValue) {
            const { config } = this.getConfig();

            config.min_confidence = enumValue;
        }

        /**
         * Returns true if the learning_params optional object exists in the config.
         * @returns {boolean}
         */
        hasAppLearning() {
            const { config } = this.getConfig();

            return Boolean(config['learning_params']);
        }

        /**
         * Sets the learning_params object to its default values in this.data.config.config. (Not a
         * typo)
         */
        setDefaultAppLearningParams() {
            const { config } = this.getConfig();

            config['learning_params'] = defaultValues.getDefaultItemConfigByType(
                'applearningparams',
            );
        }
    }

    angular.extend(WafProfile.prototype, {
        objectName: 'wafprofile',
        windowElement: 'waf-profile-modal',
    });

    return WafProfile;
};

WafProfileFactory.$inject = [
    'Item',
    'defaultValues',
];

angular.module('aviApp').factory('WafProfile', WafProfileFactory);
