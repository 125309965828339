/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * Informs status of most recent capture.
 * @typedef {string} TechSupportCaptureStatus
 * One of: 'error', 'success', 'inProgress'
 * @private
 */

/**
 * Common object type used by TechSupportCollection and TechSupportCapture
 * @typedef {Object} TechSupportSharedStatus
 * @property {string} fileName - name of actual tar file
 * @property {string} resourceName - name of instance, only for vs/se/pool/gslb
 * @property {string} type - see {@link TechSupportTypes}
 * @private
 */

/**
 * Dictate tech support status section view
 * @typedef {Object} TechSupportCapture
 * extends {@link TechSupportSharedStatus}
 * @property {TechSupportCaptureStatus} captureStatus
 * @property {string} captureError
 * @private
 */

/**
 * config.status object returned by file service collection api for tech support,
 * or just status object returned by tech support status api.
 * @typedef {Object} TechSupportApiStatus
 * @property {string} duration - 'd:hh:ss' time taken to generate
 * @property {string|undefined} key - uid of type's instance
 * @property {string} level - type of tech support
 * @property {string|undefined} name - name of type's instance
 * @property {string} output - location of tar file
 * @property {string} start_time
 * @property {string} status - human readable status
 * @property {string} status_code - found in syserr.proto
 * @private
 */

function techSupportCaptureFactory($http, UpdatableBase) {
    /**
     * @alias module:services/TechSupportCapture
     * @extends UpdatableBase
     * @private
     */
    class TechSupportCapture extends UpdatableBase {
        /**
         * Launches new tech support v2 generation, returns promise.
         * @param {TechSupportApiStatus.level} type - type of tech support desired, e.g. VS,SE, etc
         * @param {string=} id - UUID for instance of type vs, se, pool, or gslb
         * @param {string=} caseNumber - case number which we are going to attach the tech support
         * @returns {promise}
         */
        static generateNewTechSupport(type, id, caseNumber) {
            let url = `api/techsupportv2/${type}`;

            if (id) {
                url += `/${id}`;
            }

            if (caseNumber) {
                url += `?case_number=${caseNumber}`;
            }

            return $http.get(url);
        }

        /**
         * Variable used for trigger event when data is updated.
         * @type {string}
         */
        static HANDLE_CAPTURE_STATUS_UPDATE_EVENT = 'captureStatusUpdate';

        /**
         * Assigns source, transformer, transporter, and objectName_.
         * @param {Object} args - any overrides to be extended
         */
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'TechSupportCaptureDataSource',
                    transformer: 'TechSupportCaptureDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            args = Object.assign(
                {
                    loadOnCreate: true,
                    defaultDataSources: 'list',
                    isStatic: false,
                    allDataSources,
                },
                args,
            );

            super(args);

            this.initCaptureStatus_();
            this.objectName_ = 'techsupportstatusv2';

            if (this.loadOnCreate_) {
                this.load();
            }
        }

        /**
         * Initializes necessary @TechSupportCaptureStatus so instance has
         * access before response returns.
         */
        initCaptureStatus_() {
            /**
             * @type {Object<string, TechSupportCaptureStatus>}
             * @protected
             */
            this.status_ = {
                captureStatus: 'success',
            };
        }

        /**
         * Sets updated transformed data onto instance.
         * @param {TechSupportCaptureStatus} data
         * @protected
         */
        setTechSupportCaptureStatus_(data) {
            /**
             * @type {TechSupportCaptureStatus}
             * @protected
             */
            this.status_ = data;
        }

        /**
         * Returns desired value inside @TechSupportCaptureStatus.
         * @param {string} prop
         * @return {*}
         */
        getTechSupportCaptureVal(prop) {
            return this.status_[prop];
        }

        /**
         * Handles actions to be taken upon data response update.
         */
        triggerUpdateEvent() {
            this.trigger(TechSupportCapture.HANDLE_CAPTURE_STATUS_UPDATE_EVENT);
        }
    }

    return TechSupportCapture;
}

techSupportCaptureFactory.$inject = ['$http', 'UpdatableBase'];

/**
 * @ngdoc service
 * @name TechSupportCapture
 * @module services/TechSupportCapture
 * @author Akul Aggarwal
 * @description
 *
 *      Provides class to create instance of tech support status for last tech
 *      support generation.
 *
 */
angular.module('aviApp').factory('TechSupportCapture', techSupportCaptureFactory);
