/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './virtualservice-detail.less';

/**
 * @ngdoc component
 * @name  virtualserviceDetail
 * @param  {VirtualService} loadedVirtualService
 * @description
 *
 *     VirtualService detail page containing the VirtualService child pages.
 */
class VirtualserviceDetailController {
    constructor(
        $scope,
        $rootScope,
        myAccount,
    ) {
        this.$scope_ = $scope;
        this.myAccount = myAccount;

        // Emulating what is provided on $rootScope from app.js for child pages, since this is
        // now using an isolated scope. TODO: remove when possible.
        Object.assign(
            this.$scope_,
            _.pick(
                $rootScope,
                '$location',
                '$state',
                '$stateParams',
                'Schema',
                'timeouts',
                'allowed',
            ),
        );
    }

    $onInit() {
        const { loadedVirtualService: vs } = this;

        this.$scope_.vs = vs;

        /** @deprecated */
        this.$scope_.VirtualService = vs;
    }

    $onDestroy() {
        this.loadedVirtualService.destroy();
    }
}

VirtualserviceDetailController.$inject = [
    '$scope',
    '$rootScope',
    'myAccount',
];

angular.module('aviApp').component('virtualserviceDetail', {
    bindings: {
        loadedVirtualService: '<resolveVS',
    },
    controller: VirtualserviceDetailController,
    templateUrl: 'src/components/pages/item-detail/virtualservice-detail/' +
        'virtualservice-detail.html',
});
