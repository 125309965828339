/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  Certificate
 * @description  Certificate item.
 */
const CertificateFactory = function(Item, CertificateManagement, $q) {
    class Certificate extends Item {
        /**
         * Returns true if the type of the Certificate is CSR.
         * @return {boolean}
         */
        isCertificateSigningRequest() {
            const { certificate } = this.getConfig();

            return !angular.isUndefined(certificate.certificate_signing_request);
        }

        /**
         * Called when user selects between Self-signed, CSR, and Import types. Sets properties
         * on data.config.
         * @param  {string} type - 'self-signed', 'ca-signed', or 'import'.
         */
        changeType(type) {
            const config = this.getConfig();
            const { certificate } = config;

            certificate.self_signed = type === 'self-signed';

            if (type === 'import') {
                certificate.subject = undefined;
                certificate.self_signed = undefined;
            } else {
                certificate.certificate = undefined;
                config.key = undefined;

                if (type === 'ca-signed') {
                    certificate.days_until_expire = undefined;
                }
            }
        }

        /**
         * Sends certificate validate request
         * @returns {ng.$q.promise}
         */
        validateCertificate() {
            const
                config = this.getConfig(),
                { certificate } = config;

            this.busy = true;
            this.errors = null;

            return this.request('POST', '/api/sslkeyandcertificate/validate', {
                certificate: certificate ? certificate.certificate : undefined,
                certificate_base64: config.certificate_base64,
                key: config.key,
                key_base64: config.key_base64,
                key_passphrase: config.key_passphrase,
            })
                .then(({ data }) => {
                    this._setValidatedCertificate(data.certificate);

                    if (config.type === 'SSL_CERTIFICATE_TYPE_CA') {
                        this._setValidatedKey(data.certificate);
                    } else {
                        this._setValidatedKey(data);
                    }
                })
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Called after validating a certificate and replaces previous certificate values with
         * validated values.
         * @param {Object} certificate - Validated certificate object.
         */
        _setValidatedCertificate(certificate) {
            const config = this.getConfig();

            config.certificate_base64 = false;
            config.certificate = {
                certificate: certificate.certificate,
                subject: certificate.subject,
                key_params: certificate.key_params,
                not_after: certificate.not_after,
            };
        }

        /**
         * Called after validating a certificate and replaces previous key values with validated
         * values.
         * @param {[type]} data [description]
         */
        _setValidatedKey(data) {
            const config = this.getConfig();

            config.key_base64 = false;
            config.key = data.key;
            config.key_params = data.key_params;
        }

        /**
         * @override
         */
        isEditable() {
            const config = this.getConfig();

            return config.type !== 'SSL_CERTIFICATE_TYPE_CA' &&
                config.certificate && config.certificate.self_signed === false &&
                super.isEditable();
        }

        /**
         * @override
         * Sets config.certificate.certificate, config.key, and config.key_passphrase to
         * undefined, but leaves config.certificate.subject as is. This allows showing the user
         * the previous certificate and lets him import a new certificate and key.
         * If config.certificate is set to undefined, the previous certificate data is lost.
         * If nothing is set to undefined, then the user could possibly replace the old key with
         * a new key without replacing the old certificate, causing an error.
         */
        beforeEdit() {
            const config = this.getConfig();

            config.certificate_base64 = true;
            config.key_base64 = true;

            const { certificate } = config;

            if (!angular.isUndefined(certificate)) {
                certificate.certificate = undefined;

                if (!('certificate_signing_request' in certificate)) {
                    certificate.subject = undefined;
                    certificate.not_after = undefined;
                    config.key = undefined;
                }
            } else {
                config.certificate = {};
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config.certificate) {
                delete config.certificate.key_params;
            }

            const { key_params: keyParams } = config;

            if (keyParams) {
                const { algorithm } = keyParams;

                switch (algorithm) {
                    case 'SSL_KEY_ALGORITHM_RSA':
                        delete keyParams.ec_params;
                        break;
                    case 'SSL_KEY_ALGORITHM_EC':
                        delete keyParams.rsa_params;
                        break;
                }
            }

            return config;
        }

        /**
         * If a Certificate Management Profile is selected, load it to check for dynamic_params.
         */
        loadCertificateManagementProfile() {
            const config = this.getConfig();

            config.dynamic_params = undefined;

            if (!config.certificate_management_profile_ref) {
                return;
            }

            const certManagement = new CertificateManagement({
                id: config.certificate_management_profile_ref.slug(),
            });

            this.busy = true;

            certManagement.load()
                .then(rsp => {
                    const scriptParams = certManagement.getConfig().script_params;

                    if (!angular.isArray(scriptParams)) {
                        return;
                    }

                    config.dynamic_params = scriptParams.filter(param => param.is_dynamic);
                })
                .finally(() => this.busy = false);
        }
    }

    angular.extend(Certificate.prototype, {
        objectName: 'sslkeyandcertificate',
        windowElement: 'ssl-certificate-create-application-system',
    });

    return Certificate;
};

CertificateFactory.$inject = [
    'Item',
    'CertificateManagement',
    '$q',
];

angular.module('aviApp').factory('Certificate', CertificateFactory);
