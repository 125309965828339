/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServicePoolMembersInventoryDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     Returns a flat list of GslbService GslbPool members.
 */
angular.module('GSLB.vantage.avi').factory('GSLBServicePoolMembersInventoryDataTransformer', [
'RevisedDataTransformer',
function(RevisedDataTransformer) {
    return class GSLBServicePoolMembersInventoryDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            const results = [];

            if (angular.isObject(resp.data.runtime) && 'groups' in resp.data.runtime) {
                const healthScore = { health_score: 100 };
                const gslbPoolPriorityHash = resp.data['config']['groups'].reduce(
                    (acc, { name, priority }) => {
                        acc[name] = priority;

                        return acc;
                    }, {},
                );

                resp.data['runtime']['groups'].forEach(({ name: gslbPoolName, members }) => {
                    const gslbPoolPriority = gslbPoolPriorityHash[gslbPoolName];

                    members.forEach(member => {
                        const siteStatusHash = {};
                        const config = _.pick(member, [
                            'ip', 'vip_type', 'vs_uuid', 'fqdn', 'public_ip',
                            'vs_name', 'cluster_uuid', 'site_name',
                        ]);
                        //since we wanna rebuild the list from scratch every time

                        config.name =
                            GSLBServicePoolMembersInventoryDataTransformer.getMemberName(config);
                        config.gslbPoolName = gslbPoolName;
                        config.gslbPoolPriority = gslbPoolPriority;
                        config.id = `${gslbPoolName}-${config['ip']['addr']}`;

                        const runtime = {
                            oper_status: member['oper_status'],
                            gslbSiteOperStatuses: siteStatusHash,
                        };

                        if ('datapath_status' in member) {
                            member['datapath_status'].forEach(siteStatus => {
                                const { site_uuid, oper_status, location } = siteStatus;

                                siteStatusHash[site_uuid] = { site_uuid, oper_status, location };
                            });
                        }

                        results.push({ config, runtime, health_score: healthScore });
                    });
                });
            }

            return {
                data: {
                    results,
                    count: results.length,
                },
            };
        }

        /**
         * Returns member name for UI.
         * @returns {string}
         * @static
         */
        static getMemberName({ vip_type: vipType, vs_name: vsName, ip, fqdn, site_name: siteName }) { //eslint-disable-line
            let name;

            if (vipType === 'AVI_VIP') {
                name = `${siteName}:${vsName}`;
            } else {
                name = fqdn || ip['addr'];
            }

            return name;
        }
    };
}]);
