/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/modal/cloud-create.less';

angular.module('aviApp').controller('CloudCreateController', [
'$scope',
'$timeout',
'$q',
'Regex',
'stackHyperVisorTypesFactory',
'Auth',
'SEGroupCollection',
'Schema',
'Cloud',
'cloudService',
'CloudConnectorUserCollection',
'IPAMProfileCollection',
'CertificateCollection',
'OpenstackNetworkCollection',
'AviModal',
'secretStubStr',
'systemInfoService',
'dropDownUtils',
'naturalSort',
function(
    $scope,
    $timeout,
    $q,
    Regex,
    stackHyperVisorTypesFactory,
    Auth,
    SEGroupCollection,
    Schema,
    Cloud,
    cloudService,
    CloudConnectorUserCollection,
    IPAMProfileCollection,
    CertificateCollection,
    OpenstackNetworkCollection,
    AviModal,
    secretStubStr,
    systemInfo,
    dropDownUtils,
    naturalSort,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    // Just reference, used in the template
    $scope.Regex = Regex;
    $scope.Auth = Auth;
    $scope.Schema = Schema;
    $scope.cloudService = cloudService;
    $scope.dropDownUtils = dropDownUtils;
    $scope.templateSeGroupFilePath = 'src/views/infrastructure/modal/cloud-template-se-group.html';

    $scope.seInProviderContextTooltip =
        'Provider mode is turned on in the Tenant Settings. ' +
        'Hence, all SEs can only be created in the provider tenant.';

    // Wizard definition
    $scope.wizard = {
        current: 0,
        steps: [{
            title: 'Cloud Selection',
        }],
        beforeChange() {
            $scope.editable.errors = null;

            return false;
        },
    };

    $scope.forms = {};

    const wizardTabs = {
        CLOUD_VCENTER: [{
            title: 'Select Cloud',
        }, {
            title: 'Infrastructure',
        }, {
            title: 'Data Center',
        }, {
            title: 'Network',
        }],
        CLOUD_OPENSTACK: [{
            title: 'Select Cloud',
        }, {
            title: 'Infrastructure',
        }, {
            title: 'Network',
        }, {
            title: 'Role Mapping',
        }],
        CLOUD_AWS: [{
            title: 'Select Cloud',
        }, {
            title: 'Infrastructure',
        }, {
            title: 'VPC/Network/Encryption',
        }],
        CLOUD_VCA: [{
            title: 'Select Cloud',
        }, {
            title: 'Infrastructure',
        }, {
            title: 'Network',
        }],
        CLOUD_NONE: [{
            title: 'Select Cloud',
        }, {
            title: 'DHCP Settings',
        }],
        CLOUD_LINUXSERVER: [{
            title: 'Select Cloud',
        }, {
            title: 'Linux Server Configuration',
        }],
        CLOUD_OSHIFT_K8S: [{
            title: 'Select Cloud',
        }, {
            title: 'Infrastructure',
        }, {
            title: 'SE Deployment',
        }, {
            title: 'Applications',
        }],
        CLOUD_AZURE: [{
            title: 'Select Cloud',
        }, {
            title: 'Credentials',
        }, {
            title: 'Location/Network',
        }],
        CLOUD_GCP: [{
            title: 'Select Cloud',
        }, {
            title: 'General',
        }, {
            title: 'Location/Network',
        }],
    };

    $scope.wizardTabs = wizardTabs;

    /**
     * Handle cloud type change and update wizard accordingly.
     */
    $scope.onCloudTypeChange = function(cloudType) {
        $scope.editable.onCloudTypeChange(cloudType);

        // resets the IPAM/DNS collection params whevenever changing the cloud type
        this.setIpamDnsCollectionParams_();
        $scope.updateWizard();
    };

    /**
     * Sets Ipam/Dns Profile collection during init and
     * if cloud type gets changed in the config window especially
     * when changing from GCP cloud type to some other type.
     * @protected
     */
    $scope.setIpamDnsCollectionParams_ = function() {
        const dnsProfileTypes = 'IPAMDNS_TYPE_INTERNAL_DNS,IPAMDNS_TYPE_INFOBLOX_DNS,' +
            'IPAMDNS_TYPE_AWS_DNS, IPAMDNS_TYPE_AZURE_DNS,IPAMDNS_TYPE_CUSTOM_DNS';

        $scope.ipamProfileCollection.setParams({
            type: dnsProfileTypes,
            exclude: 'type',
        });

        $scope.dnsIpamProfileCollection.setParams({
            type: dnsProfileTypes,
            exclude: undefined,
        });
    };

    $scope.updateWizard = function() {
        const config = $scope.editable.getConfig();

        if (config.vtype && wizardTabs[config.vtype]) {
            $scope.wizard.steps = wizardTabs[config.vtype];
        }

        //tabs will be used for edit mode
        if (config.url) {
            $scope.wizard.steps.forEach(function(step) {
                step.unlocked = true;
            });
        }
    };

    $scope.getCloudTypeName = Cloud.getCloudTypeName;

    // Collections used
    $scope.seGroupCollection = new SEGroupCollection({ isStatic: true });

    $scope.ipamProfileCollection = new IPAMProfileCollection({
        defaults: {
            type: 'IPAMDNS_TYPE_INTERNAL',
        },
    });

    $scope.dnsIpamProfileCollection = new IPAMProfileCollection({
        defaults: {
            type: 'IPAMDNS_TYPE_INTERNAL_DNS',
        },
    });

    $scope.setIpamDnsCollectionParams_();

    $scope.sshUsers = new CloudConnectorUserCollection({
        createParams: {
            filter: 'ssh',
        },
    });

    const certTypeCAEnum = 'SSL_CERTIFICATE_TYPE_CA';

    /**
     * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
     */
    const certCollectionEnums = Object.keys(Schema.enums.SSLCertificateType.values)
        .filter(type => type !== certTypeCAEnum)
        .join(',');

    // Instantiate collections
    $scope.certCollection = new CertificateCollection({
        params: {
            type: certCollectionEnums,
        },
    });

    $scope.authCollection = new CertificateCollection({
        params: { type: certTypeCAEnum },
        defaults: { type: certTypeCAEnum },
    });

    // window initialization
    $scope.init = function() {
        $scope.updateWizard();
        $scope.ui = {
            oshift: {
                authenticateWithCert: true,
            },
        };

        const config = $scope.editable.getConfig();

        /**
         * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
         */
        const certCollectionEnums = Object.keys(Schema.enums.SSLCertificateType.values)
            .filter(function(type) {
                return type !== 'SSL_CERTIFICATE_TYPE_CA';
            }).join(',');

        // Instantiate collections
        $scope.certCollection = new CertificateCollection({
            params: {
                type: certCollectionEnums,
            },
        });

        if ($scope.editable.id) { //edit mode
            $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': $scope.editable.id });

            $scope.seGroupCollection.setDefaultItemConfigProps({
                cloud_ref: $scope.editable.getRef(),
            });

            $timeout(function() {
                $scope.wizard.next();

                //no way to switch type for existing cloud
                if (config.vtype !== 'CLOUD_NONE') {
                    $scope.wizard.steps[0].unlocked = false;
                }
            });

            if (config.vtype === 'CLOUD_VCENTER') {
                $scope.vcenter.checkThirdPartyIntegration();
            }

            $scope.initCurrentCloud();
        } else {
            $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': undefined });
        }
    };

    /**
     * Calls the initialization method of the currently selected cloud.
     * It's being called during window initialization at Edit mode and
     * during next button click after the cloud selection at Create mode.
     */
    $scope.initCurrentCloud = function() {
        const vType = $scope.editable.getVtype();

        switch (vType) {
            case 'CLOUD_OSHIFT_K8S':
                $scope.oshift.init();
                break;

            case 'CLOUD_AZURE':
                $scope.azure.init();
                break;

            case 'CLOUD_GCP':
                $scope.gcp.init();
                break;

            case 'CLOUD_AWS':
                $scope.aws.init();
                break;
        }
    };

    /**
     * Handler for clicking next button in cloud selection wizard.
     * The relevant cloud init() is made sure to be called after the cloud selection.
     */
    $scope.saveSettings = function() {
        $scope.editable.errors = null;

        if ($scope.wizard.current === 0) {
            $scope.initCurrentCloud();
        }

        $scope.updateWizard();
        $scope.wizard.next();
    };

    /**
     * Navigates to complete screen and then to the system.
     */
    $scope.complete = function() {
        $scope.editable.submit();
    };

    $scope.isUnlocked = function(index) {
        const { steps } = $scope.wizard;
        const unlocked = steps[index] && steps[index].unlocked;
        const { editable: item } = $scope;
        const { config: cfg } = item.data;
        const { vtype } = cfg;
        const itemCfg = cfg[item.vtypeConfigHash[vtype]];

        switch (vtype) {
            case 'CLOUD_VCENTER':
                switch (index) {
                    case 2:
                    case 3:
                        return !!itemCfg.datacenter;
                    default:
                        return unlocked;
                }
            default:
                return unlocked;
        }
    };

    /* ---- Modal methods, activated by save button and other UI elements ---- */
    /*----------------- vCenter ------------------*/
    $scope.vcenter = {
        /**
         * Optional password store in case user changes password and nothing else.
         * Password is not returned after saving vCenter config, so to get list of networks
         * password will be reattached to vCenter config.
         */
        vCenterPassword: '',
        thirdPartyIntegration: 'none',
        goTo(index) {
            if ($scope.isUnlocked(index)) {
                const item = $scope.editable;

                item.cancelTimeouts();

                const { wizard } = $scope;

                switch (index) {
                    case 2:
                        $scope.vcenter.goToDC();
                        break;

                    case 3:
                        wizard.current = index;
                        $scope.vcenter.getNetworksAndVRF();
                        break;

                    default:
                        wizard.current = index;
                        break;
                }
            }
        },

        goToDC() {
            $scope.editable.getvCenterDC().then(() => {
                $scope.wizard.current = 2;
                $scope.vcenter.setDatacenterOptions();
            });
        },

        /**
         * Generates Datacenter dropdown options.
         */
        setDatacenterOptions() {
            const { datacenters } = $scope.editable;

            $scope.datacentersOptions = datacenters
                .map(({ name }) => dropDownUtils.createOption(name, name));
        },

        /**
         * Loads vcenter networks and vrf context.
         */
        getNetworksAndVRF() {
            $scope.vcenter.loadingNetworks = true;
            $scope.editable.getvCenterNetworks()
                .then(() => {
                    $scope.vcenter.setManagementNetworkOptions();
                })
                .finally(() => {
                    $scope.vcenter.loadingNetworks = false;
                });
        },

        /**
         * Generates Management Network dropdown options.
         */
        setManagementNetworkOptions() {
            const { networks } = $scope.editable;

            $scope.managementNetworkOptions = networks.map(({ uuid, name }) => {
                const value = `${uuid}#${name}`;

                return dropDownUtils.createOption(
                    value,
                    name,
                );
            });
        },

        /**
         * Management network field change callback.
         * Creates selected management network object and triggers selected network load.
         */
        onManagementNetworkChange() {
            const { networks } = $scope.editable;
            const { management_network: managementNetwork } = $scope.editable.getCloudConfig();

            const selectedManagementNetwork = _.findWhere(networks, {
                uuid: managementNetwork.slug(),
            });

            selectedManagementNetwork.url = managementNetwork;
            $scope.editable.setNetwork(selectedManagementNetwork);
        },

        loginChange() {
            const cfg = $scope.editable.data.config.vcenter_configuration;

            delete cfg.datacenter;
            delete cfg.management_ip_subnet;
            delete cfg.management_network;

            if (cfg.password === secretStubStr) {
                cfg.password = '';
            }
        },

        vcenterLogin() {
            const cloud = $scope.editable;
            const cfg = cloud.data.config;

            if (!cfg.vcenter_configuration.datacenter) {
                cloud.vCenterLogin().then(function() {
                    $scope.wizard.steps[0].unlocked = false;
                    $scope.vcenter.goToDC();
                });
            } else if ($scope.vcenter.isComplete()) {
                $scope.complete();
            } else {
                $scope.vcenter.goTo(2);
            }
        },

        saveDataCenter() {
            const cloud = $scope.editable;

            this.vCenterPassword = cloud.data.config.vcenter_configuration.password;
            cloud.save(false).then(function() {
                if ($scope.vcenter.isComplete()) {
                    $scope.complete();
                } else {
                    // Reattach password if available to retrieve networks.
                    if (this.vCenterPassword) {
                        cloud.data.config.vcenter_configuration.password = this.vCenterPassword;
                    }

                    $scope.wizard.next();
                    $scope.vcenter.getNetworksAndVRF();

                    $scope.seGroupCollection
                        .setParams({ 'cloud_ref.uuid': $scope.editable.id });

                    $scope.seGroupCollection.setDefaultItemConfigProps({
                        cloud_ref: $scope.editable.getRef(),
                    });
                }
            }.bind(this));
        },

        saveNetwork() {
            if ($scope.editable.data.config.vcenter_configuration.management_network) {
                $scope.busy = true;

                $scope.editable.saveNetworkInfo()
                    .then(function() {
                        return $scope.editable.saveVRFContext();
                    }).then(function() {
                        $scope.complete();
                    }).finally(function() {
                        $scope.busy = false;
                    });
            }
        },

        hasWritePermission() {
            return $scope.editable.data.config.vcenter_configuration.privilege === 'WRITE_ACCESS';
        },

        isComplete() {
            const cfg = $scope.editable.data.config;
            const vcenterCfg = cfg.vcenter_configuration;
            const hasWritePermission = $scope.vcenter.hasWritePermission();

            return !!(cfg.url && vcenterCfg.datacenter &&
                (hasWritePermission ? vcenterCfg.management_network : true));
        },

        checkThirdPartyIntegration() {
            const config = $scope.editable.getConfig();

            if (config.apic_mode) {
                this.thirdPartyIntegration = 'apic';
            } else if (!angular.isUndefined(config.nsx_configuration)) {
                this.thirdPartyIntegration = 'nsx';
            } else {
                this.thirdPartyIntegration = 'none';
            }
        },

        handleThirdPartyIntegrationChange() {
            $scope.editable.handleThirdPartyIntegrationChange(this.thirdPartyIntegration);
        },
    };

    $scope.isEditing = function() {
        return !!$scope.editable.data.config.url;
    };

    /*-------- OpenStack ---------*/
    $scope.openstack = {
        /**
         * Flag to check if Openstack login succeeded.
         */
        isLoggedIn: false,

        goTo(index) {
            if (index === 0) {
                $scope.wizard.current = 0;

                return;
            }

            const { current } = $scope.wizard;
            const cfg = $scope.editable.getCloudConfig();

            switch (current) {
                case 2: {
                    let credentialsCheckPromise = $q.when(true);

                    if (cfg._useContrail) {
                        credentialsCheckPromise = $scope.editable.checkContrailCreds();
                    } else if (cfg.nuage_config) {
                        credentialsCheckPromise = $scope.editable.checkNuageCreds();
                    }

                    credentialsCheckPromise
                        .then(() => {
                            $scope.wizard.steps[current].unlocked = true;
                            $scope.wizard.current = index;
                        });

                    break;
                }

                case 1:
                    this.login().then(() => {
                        this.getSeInProviderContext();
                        this.setTenantDropdownOptions();
                        $scope.wizard.steps[1].unlocked = true;
                        $scope.wizard.steps[2].unlocked = $scope.isEditing();
                        $scope.wizard.steps[3].unlocked = $scope.isEditing();

                        if (!$scope.isEditing()) {
                            delete cfg.nuage_config;
                            delete cfg.admin_tenant;
                            delete cfg.region;
                            delete cfg.mgmt_network_name;
                            delete cfg.contrail_endpoint;
                        }

                        $scope.wizard.current = index;
                    });
                    break;

                default:
                    if ($scope.isUnlocked(index)) {
                        $scope.wizard.steps[current].unlocked = true;
                        $scope.wizard.current = index;
                    }
            }
        },

        /**
         * Advances Openstack config wizard by 1 step.
         */
        nextStep() {
            this.goTo($scope.wizard.current + 1);
        },

        /**
         * Checks openstack credentials and sets tenants.
         */
        login() {
            return $scope.editable.openStackLogin()
                .then(() => {
                    this.setNetworkCollection();
                    this.regions = $scope.editable.getOpenstackRegions();
                    $scope.wizard.steps[0].unlocked = false;
                });
        },

        /**
         * Updates the payload params for openstack_tenant_networks API
         * based on the selected tenant.
         */
        setNetworkCollection() {
            const cloudConfig = $scope.editable.getCloudConfig();

            let tenantId;

            if (cloudConfig.admin_tenant) {
                const adminObj = $scope.editable.getTenantID();

                tenantId = adminObj.id;
            }

            $scope.openstackNetworkscollectionParams =
                $scope.editable.getOpenstackTenantNetworksPayload(tenantId);
        },

        /**
         * Handler for changing the Tenant dropdown.
         */
        onTenantChange() {
            const adminObj = $scope.editable.getTenantID();

            if (adminObj) {
                $scope.editable.onTenantChange(adminObj).then(() => {
                    this.setNetworkCollection();
                    this.regions = $scope.editable.getOpenstackRegions();
                });
            }
        },

        /**
         * Saves the Openstack configtation
         */
        saveConfiguration() {
            $scope.$broadcast('openStackBeforeSave');
            $scope.complete();
        },

        hyperVisorTypes: stackHyperVisorTypesFactory,

        /**
         * Returns true to show Allow Self-signed Certificates checkbox.
         * @return {boolean}
         */
        showAllowSelfSignedCertCheckbox() {
            const config = $scope.editable.getCloudConfig();

            return !angular.isUndefined(config.auth_url) && config.auth_url.beginsWith('https://');
        },

        /**
         * Returns SE in provider context flag.
         * @returns {boolean}
         */
        getSeInProviderContext() {
            systemInfo.load().then(() => {
                $scope.seInProviderContext = systemInfo.hasSeInProviderContext();
            });
        },

        /**
         * Creates dropdown options for the tenants list.
         */
        setTenantDropdownOptions() {
            const { tenant_ids: tenants } = $scope.editable;

            this.tenants = tenants.map(({ id, name }) =>
                dropDownUtils.createOption(id, name));
        },

        /**
         * Creates dropdown options for the regions list.
         */
        setRegionDropdownOptions(regions) {
            this.regions = regions.map(({ id, name }) =>
                dropDownUtils.createOption(id, name));
        },
    };

    /*--------- AWS ---------*/
    $scope.aws = {
        /**
         * Called when AWS cloud is selected or being edited.
         * useCrossAccountAssumeRole flag will be set based on
         * the existence of iam_assume_role.
         */
        init() {
            const cloudConfig = $scope.editable.getCloudConfig();

            if ($scope.editable.id && cloudConfig.iam_assume_role) {
                this.useCrossAccountAssumeRole = true;
                $scope.editable.getAwsIamAssumeRoles();
            }
        },
        goTo(index) {
            if (index == 2) {
                $scope.aws.awsLogin();
            } else if ($scope.isUnlocked(index)) {
                $scope.wizard.current = index;
            }
        },
        awsLogin() {
            $scope.editable.awsLogin()
                .then(() => {
                    $scope.wizard.next();
                    $scope.wizard.steps[0].unlocked = false;
                    $scope.aws.updateSelectedAvailZones();
                });
        },
        saveAwsConfiguration() {
            $scope.editable.saveAwsConfiguration(true);
        },
        /**
         * Used to hide availabilty zones which have been already selected in other
         * dropdowns.
         */
        updateSelectedAvailZones() {
            $scope.awsSelectedZones = [];
            _.each($scope.editable.getCloudConfig().zones, function(zone) {
                if (zone.availability_zone) {
                    $scope.awsSelectedZones.push(zone.availability_zone);
                }
            });
        },
        /**
         * Called by minus button to remove availability zone from layout.
         * Available only when we have at least two zones.
         */
        removeAvailZone(index) {
            $scope.editable.getCloudConfig().zones.splice(index, 1);
            $scope.aws.updateSelectedAvailZones();
        },
        /**
         * Called by plus button to add availability zone to layout. Up to four.
         */
        addAvailZone() {
            $scope.editable.addAwsAvailabilityZone();
        },

        /**
         * Handles the "Cross-Account AssumeRole" checkbox's toggling.
         */
        handleCrossAccountAssumeRoleChange() {
            if (!this.useCrossAccountAssumeRole) {
                $scope.editable.removeAwsVpcIdAndZones();
            } else {
                $scope.editable.getAwsIamAssumeRoles();
            }
        },
    };

    /*---- VCA ----*/
    $scope.vca = {
        VCALogin() {
            $scope.editable.VCALogin().then(function() {
                $scope.wizard.current = 2;
                $scope.wizard.steps[0].unlocked = false;
            });
        },
        saveVCAConfiguration() {
            $scope.editable.save().then(function() {
                $scope.complete();
            });
        },
    };

    $scope.linux = {

        /**
         * Runs /api/cloudconnectoruser task.
         * @param  {Object} host - Linuxserver config host object.
         * @param {string} task - "verify" or "cleanup".
         */
        runCloudConnectorTask(host, task = 'verify') {
            const { linuxserver_configuration: linux } = $scope.editable.getConfig();

            if (!linux || !linux.ssh_user_ref || !host.host_ip || !host.host_ip.addr) {
                return;
            }

            AviModal.open('verify-cloud-connector-user-host', {
                userName: linux.ssh_user_ref,
                host: host.host_ip.addr,
                task,
            });
        },
    };

    $scope.oshift = {
        /**
         * Called when oshift cloud is selected or being edited. Used to set UI variables.
         */
        init: () => {
            const config = $scope.editable.getConfig();
            const oshift = config.oshiftk8s_configuration;

            if (config.url) {
                if (!angular.isUndefined(oshift.service_account_token)) {
                    $scope.ui.oshift.authenticateWithCert = false;
                }
            }
        },
        /**
         * Called when Authentication radio button values are changed. Clears associated input
         * fields.
         */
        handleAuthenticateWithCertChange() {
            const { oshiftk8s_configuration: config } = $scope.editable.getConfig();

            config.client_tls_key_and_certificate_ref = undefined;
            config.ca_tls_key_and_certificate_ref = undefined;
            config.service_account_token = undefined;
        },

        /**
         * Checks K8s credentials and moves to the next wizard step on success.
         * @public
         */
        checkCredsAndNextStep() {
            $scope.editable.checkKubernetesCreds()
                .then(() => $scope.wizard.next());
        },

        /**
         * On edit we want to drop password stub when user is changing endpoints.
         * @public
         */
        dropPrevPasswordStub() {
            const { oshiftk8s_configuration: config } = $scope.editable.getConfig();

            if (config['service_account_token'] === secretStubStr) {
                config['service_account_token'] = undefined;
            }
        },
    };

    /*---- Azure ----*/
    $scope.azure = {
        dnsType: 'none',
        locations: [],
        resourceGroups: [],
        virtualNetworks: [],
        networks: [],
        separateManagementInterfaceToolTip: 'Use this option to use a separate NIC for ' +
            'control-plane communication between Service Engines and Controllers.When this' +
            ' option is not selected, a single NIC is used for both data' +
            ' and control plane traffic.',

        init() {
            const config = $scope.editable.getConfig();
            const { azure_configuration: azure } = config;
            const azureConfig = azure.getConfig();

            if (!this.credentialsCollection) {
                this.userCredentialsCreateParams = {
                    filter: 'azure',
                };

                this.credentialsCollection = new CloudConnectorUserCollection({
                    params: {
                        search: 'azure_',
                    },
                });
            }

            if (!angular.isUndefined(config.dns_provider_ref)) {
                $scope.azure.dnsType = 'other';
            } else if (azureConfig.use_azure_dns) {
                $scope.azure.dnsType = 'azure';
            } else {
                $scope.azure.dnsType = 'none';
            }

            // Editing the cloud, so we can make a request for the networks since the VNet has been
            // selected.
            if ($scope.editable.id) {
                this.useMsiAuthentication = azureConfig.subscription_id &&
                    !azureConfig.cloud_credentials_ref;

                if (azureConfig.subscription_id) {
                    azure.getLocationBasedLists(true)
                        .then(([virtualNetworks, networks]) => {
                            $scope.azure.virtualNetworks = virtualNetworks;

                            if (networks) {
                                this.setNetworkDropdownOptions(networks);
                            }
                        });
                }
            }
        },

        goTo(step) {
            if ($scope.wizard.current === 1 && step === 2) {
                this.handleClickNext();
            } else {
                $scope.wizard.current = step;
            }
        },

        /**
         * If any fields from the first tab of the wizard (Credentials) change, clear fields in the
         * second tab.
         */
        handleCredentialsChange() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            azure.clearLocationResourceGroupAndNetworkFields();
        },

        /**
         * Handler for going to the next step of the Azure cloud configuration modal. Gets the list
         * of Azure locations and Resource Groups based on the credentials from
         * the first step of the modal.
         */
        handleClickNext() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            azure.getAzureLocationsAndResourceGroups()
                .then(([resourceGroups, locations]) => {
                    locations.sort((locationA, locationB) => {
                        const { name: nameA } = locationA;
                        const { name: nameB } = locationB;

                        return naturalSort(nameA, nameB);
                    });
                    $scope.azure.resourceGroups = resourceGroups;
                    $scope.azure.locations = locations;
                    $scope.wizard.next();
                });
        },

        /**
         * Handler for changing the radio buttons for DNS Type. azureConfig.use_azure_dns is only
         * true only if 'Azure DNS' is the DNS Type selected.
         */
        handleDnsTypeChange() {
            const config = $scope.editable.getConfig();
            const { azure_configuration: azure } = config;
            const azureConfig = azure.getConfig();

            delete config.dns_provider_ref;
            azureConfig.use_azure_dns = $scope.azure.dnsType === 'azure';
        },

        /**
         * Handler for changing the Location dropdown. Clears fields based on location, ie. Resource
         * Groups, VNet, Network. Gets the list of Resource Groups and Virtual Networks.
         */
        handleLocationDropdownChange() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            azure.clearLocationBasedFields();
            azure.getLocationBasedLists()
                .then(([virtualNetworks]) => {
                    $scope.azure.virtualNetworks = virtualNetworks;
                });
        },

        /**
         * Handler for changing the VNet field. Gets the list of networks based on the VNet.
         */
        handleVNetChange() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            azure.getAzureNetworks()
                .then(networks => {
                    this.setNetworkDropdownOptions(networks);
                });
        },

        /**
         * Handler for changing the Deployment Mode. Clears the network field.
         */
        handleDeploymentModeChange() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            azure.clearNetwork();
        },

        /**
         * Returns true if the AzureConfigurationConfig ConfigItem is busy.
         * @return {boolean}
         */
        isBusy() {
            const { azure_configuration: azure } = $scope.editable.getConfig();

            return azure.busy;
        },

        /**
         * Handler for changing the MSI Authentication flag.
         * Clears cloud_credentials_ref when it's set.
         */
        handleMsiAuthChange() {
            if ($scope.azure.useMsiAuthentication) {
                const { azure_configuration: azure } = $scope.editable.getConfig();
                const azureConfig = azure.getConfig();

                delete azureConfig.cloud_credentials_ref;
            }
        },

        /**
         * Handler for toggling the use Management network checkbox.
         * Sets the default value for Management network as the
         * chosen Service engine Network when it's checked.
         */
        handleUseManagementNetworkChange() {
            const azureConfig = $scope.editable.getCloudConfig().getConfig();
            const { network_info: network } = azureConfig;

            if (!azureConfig.useManagementNetwork_) {
                delete network[0].management_network_id;
            } else if (network[0].se_network_id) {
                network[0].management_network_id = network[0].se_network_id;
            }
        },

        /** Updates each network with required label and value
         * to be shown in the relevant dropdown.
         */
        setNetworkDropdownOptions(networks) {
            $scope.azure.networks = networks.map(network => {
                const label = `${network.name} - ${network.cidr}`;

                return dropDownUtils.createOption(network.id, label);
            });
        },
    };

    /*---- GCP ----*/
    $scope.gcp = {
        /**
         * Called when GCP cloud is selected or being edited. Used to set UI variables.
         * useDefaultServiceAccount flag will be set based on
         * the existence of GCP Cloud credentials.
         */
        init() {
            $scope.ipamProfileCollection.setParams({
                type: 'IPAMDNS_TYPE_INTERNAL',
                exclude: undefined,
            });

            $scope.dnsIpamProfileCollection.setParams({
                type: 'IPAMDNS_TYPE_INTERNAL_DNS',
                exclude: undefined,
            });

            const gcp = $scope.editable.getCloudConfig();
            const cloudConfig = gcp.getConfig();

            if (!this.credentialsCollection) {
                this.userCredentialsCreateParams = {
                    filter: 'gcp',
                };

                this.credentialsCollection = new CloudConnectorUserCollection({
                    params: {
                        search: 'gcp_',
                    },
                });
            }

            if ($scope.editable.id) {
                this.useDefaultServiceAccount = cloudConfig.se_project_id &&
                    !cloudConfig.cloud_credentials_ref;
            }
        },

        /**
         * Wrapper for getting config of getCloudConfig
         * @return {Object} config - GCPCloud's configuration object.
         */
        getCloudConfig() {
            const gcp = $scope.editable.getCloudConfig();

            return gcp.getConfig();
        },

        /**
         * Advances GCP config wizard by 1 step.
         */
        nextStep() {
            $scope.wizard.afterChange = () => $('.avi-modal-body').scrollTop(0);
            this.goTo($scope.wizard.current + 1);
        },

        /**
         * Navigates to given step in GCP config wizard.
         * @param {number} step - current step in wizard.
         */
        goTo(step) {
            if ($scope.wizard.current === 1 && step === 2) {
                this.onCredentialsSubmit();
            } else {
                $scope.wizard.current = step;
            }
        },

        /**
         * Handler for changing the useDefaultServiceAccount flag.
         * Clears cloud_credentials_ref when it's set.
         * This flag is for enabling the usage of GCP's IAM roles.
         */
        handleDefaultServiceAccountAuthChange() {
            if (this.useDefaultServiceAccount) {
                const gcp = $scope.editable.getCloudConfig();

                gcp.handleDefaultServiceAccountAuthChange();
            }
        },

        /**
         * If any fields from the first tab of the wizard (Credentials) change, clear fields in the
         * second tab.
         */
        handleCredentialsChange() {
            const gcp = $scope.editable.getCloudConfig();

            gcp.clearLocationResourceAndNetworkFields();
        },

        /**
         * Handler for going to the next step of the GCP cloud configuration modal. Gets the list
         * of GCP locations and Resource Groups based on the credentials from
         * the first step of the modal.
         */
        onCredentialsSubmit() {
            const { gcp_configuration: gcp } = $scope.editable.getConfig();

            gcp.getRegionsAndNetworks()
                .then(([regions, networks]) => {
                    this.regionList = this.setDropdownOptions(_.pluck(regions, 'name'));
                    this.networkList = this.setDropdownOptions(_.pluck(networks, 'name'));
                    $scope.wizard.next();

                    if ($scope.editable.id) {
                        this.setAvailableZones_()
                            .then(() => {
                                this.setSubnetworks_();
                            });
                    }
                });
        },

        /**
         * Handler for Region change.
         */
        handleRegionChange() {
            const gcp = $scope.editable.getCloudConfig();

            gcp.clearZonesAndSubNetwork();
            this.setAvailableZones_()
                .then(() => {
                    if ($scope.editable.id) {
                        this.handleVPCNetworkChange();
                    }
                });
        },

        /**
         * Sets available zones list and assigns it to zoneList.
         * @returns {Promise<ng.$q.promise>}
         * @protected
         */
        setAvailableZones_() {
            const gcp = $scope.editable.getCloudConfig();

            return gcp.getAvailableZones()
                .then(zones => {
                    this.zoneList = this.setDropdownOptions(_.pluck(zones, 'name'));
                });
        },

        /**
         * Handler for changing the radio buttons for DNS Type.
         */
        handleDnsTypeChange() {
            const gcp = $scope.editable.getCloudConfig();

            gcp.handleDnsTypeChange();
        },

        /**
         * Handler for changing the VNet field. Gets the list of networks based on the VNet.
         */
        handleVPCNetworkChange() {
            const gcp = $scope.editable.getCloudConfig();

            gcp.clearSubnetwork();
            this.setSubnetworks_();
        },

        /**
         * Sets subnetworks list.
         * @protected
         */
        setSubnetworks_() {
            const gcp = $scope.editable.getCloudConfig();

            gcp.getSubNetworks()
                .then(subnets => {
                    this.subnetworkList = this.setDropdownOptions(_.pluck(subnets, 'name'));
                });
        },

        /**
         * Prepares dropdown options from the given list of names.
         * @param {string[]} names
         * @return {DropDownOption[]}
         */
        setDropdownOptions(names) {
            const dropdownOptions = names.map(name => dropDownUtils.createOption(name, name));

            return dropdownOptions;
        },

        /**
         * Returns true if the GCPConfigurationConfig ConfigItem is busy.
         * @return {boolean}
         */
        isBusy() {
            const gcp = $scope.editable.getCloudConfig();

            return gcp.busy;
        },
    };

    /**
     * @type {Object<string, Object>}
     */
    const cloudApi = {
        CLOUD_VCENTER: $scope.vcenter,
        CLOUD_OPENSTACK: $scope.openstack,
        CLOUD_AWS: $scope.aws,
        CLOUD_VCA: $scope.vca,
        CLOUD_LINUXSERVER: $scope.linux,
        CLOUD_AZURE: $scope.azure,
        CLOUD_GCP: $scope.gcp,
        CLOUD_NONE: {},
    };

    /**
     * Navigates cloud config tabs based on Cloud type and tab index.
     * @param {number} index - Tab index.
     * @param {string} cloudType - Cloud type.
     */
    $scope.goTo = function(index, cloudType) {
        $scope.editable.errors = null;

        const { wizard } = $scope;

        if (wizard.current === 0 && index !== 0) {
            $scope.updateWizard();
        }

        const cfg = cloudApi[cloudType];

        if (cfg && angular.isFunction(cfg.goTo)) {
            cfg.goTo(index);
        } else if ($scope.isUnlocked(index)) {
            wizard.current = index;
        }
    };

    /**
     * Handmade $scope.destroy() of the modal window.
     */
    $scope.$on('$destroy', function() {
        $scope.seGroupCollection.destroy();
        $scope.ipamProfileCollection.destroy();
        $scope.certCollection.destroy();
        $scope.authCollection.destroy();
        $scope.sshUsers.destroy();

        if ($scope.azure.credentialsCollection) {
            $scope.azure.credentialsCollection.destroy();
        }

        if ($scope.gcp.credentialsCollection) {
            $scope.gcp.credentialsCollection.destroy();
        }
    });
}]);
