/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('CertificateManagementCollection', [
'Collection', 'CertificateManagement',
function(Collection, CertificateManagement) {
    const CertificateManagementCollection = function(oArgs) {
        CertificateManagementCollection.superconstructor.call(this, oArgs);
    };

    avi.inherit(CertificateManagementCollection, Collection);

    CertificateManagementCollection.prototype.objectName_ = 'certificatemanagementprofile';
    CertificateManagementCollection.prototype.itemClass_ = CertificateManagement;
    CertificateManagementCollection.prototype.windowElement_ = 'prof-certificate-management-create';

    return CertificateManagementCollection;
}]);

angular.module('aviApp').factory('CertificateManagement', ['Item', 'defaultValues',
function(Item, defaultValues) {
    const CertificateManagement = function(oArgs) {
        CertificateManagement.superconstructor.call(this, oArgs);
    };

    avi.inherit(CertificateManagement, Item);

    CertificateManagement.prototype.objectName = 'certificatemanagementprofile';
    CertificateManagement.prototype.windowElement = 'prof-certificate-management-create';

    CertificateManagement.prototype.sensitiveHash = {};

    /**
     * Used to populate sensitiveHash so we know which params had a previously configured sensitive
     * value, so that we can show a '********' placeholder.
     */
    CertificateManagement.prototype.setSensitiveHash = function() {
        const data = this.data.config;
        const self = this;

        data.script_params.forEach(function(params, index) {
            if (params.is_sensitive) {
                self.sensitiveHash[index] = true;
            }
        });
    };

    /**
     * @override
     */
    CertificateManagement.prototype.beforeEdit = function() {
        const data = this.data.config;

        if (data.script_params && data.script_params.length) {
            this.enableScriptParams = true;
            this.setSensitiveHash();
        } else {
            data.script_params = [defaultValues.getDefaultItemConfigByType('customparams')];
        }
    };

    /**
     * @override
     */
    CertificateManagement.prototype.dataToSave = function() {
        const data = angular.copy(this.data.config);

        if (!this.enableScriptParams) {
            data.script_params = undefined;
        }

        return data;
    };

    return CertificateManagement;
}]);
