/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name parseIpOrHostname
 * @restrict A
 * @description
 *     Parses and formats an input IP address string or hostname. An IPv4 address gets parsed to an
 *     object with a 'V4' type, and a hostname gets parsed to an object with 'DNS' type.
 * @author alextsg
 */
function parseIpOrHostnameDirective(Regex) {
    /**
     * Parses string into IP object with addr and type properties. Both the IP address string and
     * hostname can be set to the 'addr' property.
     * @param  {string} val - Input hostname or IP address string.
     * @return {Object} IpAddr object {addr: '1.2.3.4', type: 'V4'}.
     */
    function parseIpOrHostnameParser(val) {
        if (Regex.ip.test(val)) {
            return {
                addr: val,
                type: 'V4',
            };
        } else if (Regex.hostname.test(val)) {
            return {
                addr: val,
                type: 'DNS',
            };
        } else {
            return '';
        }
    }

    /**
     * Formats IpAddr object into string.
     * @param  {Object} val - IP object {addr: '1.2.3.4', type: 'V4'}.
     * @return {string} String of hostname or IP address.
     */
    function formatter(val) {
        return val ? val.addr : '';
    }

    /**
     * Directive link function
     */
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseIpOrHostnameParser);
        ngModel.$formatters.push(formatter);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}

parseIpOrHostnameDirective.$inject = [
    'Regex',
];

angular.module('avi/component-kit').directive('parseIpOrHostname', parseIpOrHostnameDirective);
