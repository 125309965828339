/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Data model layer of avi ui: item, collection, data-* stuff.
 * @module avi/dataModel
 */

import { diff as deepDiff } from 'deep-diff';

//FIXME on Item create transformAfterLoad should be called
//FIXME transformAfterSave should use transformAfterLoad
function itemFactory(
    $q,
    $state,
    $window,
    Base,
    AviModal,
    Auth,
    AviAlertService,
    secretStubStr,
    defaultValues,
    aviInherit,
) {
    /**
     * @class Item
     * @constructor
     * @extends module:avi/dataModel.Base
     * @memberOf module:avi/dataModel
     * @param {Object=} oArgs - Configuration object, contains argument list, event listeners.
     * @desc
     * Abstract item service, represents single object with properties, provides a way to load
     * and save the data.
     * The object can be instantiated as follows:
     * ````javascript
     * new Item({
     *     id: 'vs-0578abgh',
     *     objectName: 'virtualservice',
     *     data: {},
     *     bind: {},
     * })
     * ````
     *
     * Events list:
     * onBeforeLoad: Is called before preloading the object
     * itemLoadSuccess: Is called when the object preload was successful (response is passed as
     * a parameter).
     * itemLoadFail: Is called when object preload failed (response is passed as a parameter)
     * itemBeforeCreate: Is called before going to create state
     * itemBeforeEdit: Is called before going to edit state
     * itemBeforeSave: Is called before sending save request
     * itemSaveSuccess: Is called when save was successful (response is passed as a parameter)
     * itemSave: Is called after save was completed or failed (response is passed as a
     * parameter)
     * itemCreate: Called when item was created
     * itemUpdate: Called when item was updated
     * itemSaveFail: Is called when save failed (response is passed as a parameter)
     * itemChange: Is called when item.set() was executed to update the data (updates are
     * passed as a parameter).
     * itemBeforeDrop: Is called before delete request sent
     * itemDrop: Is called after delete was completed or failed (response is passed as a
     * parameter)
     * itemDropSuccess: Is called when delete was successful (response is passed as a
     * parameter)
     * itemDropFail: Is called after delete fail (response is passed as a parameter)
     * itemConfigUpdate: Triggered after saving (a config of) an Item.
     **/
    function Item(oArgs = {}) {
        Item.superconstructor.call(this, oArgs);

        // Initialize
        this.objectName = oArgs.objectName || this.objectName;
        this.params = oArgs.params || angular.copy(this.params);
        this.id = oArgs.id || this.id;
        this.windowElement = oArgs.windowElement || this.windowElement;
        this.collection = oArgs.collection || this.collection;
        this.opener = oArgs.opener || this.opener;
        this.events = oArgs.events || this.events;
        this.data = oArgs.data || this.data || {};
        this.loadOnEdit = !_.isUndefined(oArgs.loadOnEdit) ?
            oArgs.loadOnEdit : this.loadOnEdit;

        /**
         * Name of the permission associated with the Item, ex. PERMISSION_VIRTUALSERVICE or
         * PERMISSION_POOL. Used to determine, for example, if the user has permissions to edit or
         * delete the Item.
         * @type {string}
         */
        this.permissionName_ = oArgs.permissionName ||
            this.collection && this.collection.permissionName_ ||
            '';

        if (this.data && !this.id && oArgs) {
            this.id = this.getIdFromData(oArgs.data);
        }

        // If object name is still missing then try to get it somewhere
        if (!this.objectName) {
            if (this.opener) {
                this.objectName = this.opener.objectName;
            } else if (this.collection) {
                this.objectName = this.collection.objectName_;
            }
        }

        if (!this.objectName) {
            const errMsg = "Can't create item wo objectName set";

            console.warn(errMsg, this.constructor, oArgs);
        }

        if (oArgs.defaultConfig) {
            this.defaultConfig_ = angular.copy(oArgs.defaultConfig);
        }

        if (!this.defaultConfig_) {
            this.setDefaultConfig_();
        }

        //throttle to avoid double click on edit button
        //TODO be aware of ongoing load request not to initialize it till we have finished
        //TODO the first one
        this.edit = _.throttle(this.edit.bind(this), 999, { trailing: false });
    }

    aviInherit(Item, Base);

    /**
     * Keeps lower-case object name like 'applicationprofile' used to build the url for
     * making calls and by permission checks.
     * @type {string}
     */
    Item.prototype.objectName = '';

    /**
     * Name of Item's details abstract UI state.
     * @type {string}
     * @protected
     */
    Item.prototype.detailsStateName_ = '';

    /**
     * Keeps id (slug) of the current object.
     * @type {string|null}
     */
    Item.prototype.id = null;

    /**
     * Actual data of the object. Contains config, runtime, metrics data.
     * @type {null|Object}
     */
    Item.prototype.data = null;

    /**
     * DOM element of the window that creates/edits the current object
     * @type {string|HTMLElement}
     */
    Item.prototype.windowElement = null;

    /**
     * Url parameters (key - value pairs), will be append to API URL as 'key=value&'.
     * @type {Object}
     */
    Item.prototype.params = {
        include_name: true,
    };

    /**
     * Loading indicator and block for some ongoing API calls.
     * @type {boolean}
     */
    Item.prototype.busy = false;

    /**
     * The promise that is used to catch save or edit operation through the Modal window.
     * @type {angular.$q.promise}
     */
    Item.prototype.modalPromise = null;

    /**
     * The flag that determines either the Item should be reloaded before opening an edit
     * Modal window.
     * @type {boolean}
     */
    Item.prototype.loadOnEdit = true;

    /**
     * Generating and returning the parameters needed to be added to the API URL in order to
     * load the Item.
     * @returns {string[]} Array of parameters as 'key=value'.
     */
    Item.prototype.getLoadParams = function() {
        const filteredParams = this.filterOutParams_(['headers_']);

        return _.map(filteredParams, (val, key) => `${key}=${val}`);
    };

    /**
     * Returns copy of this.params with indicated params removed.
     * @param {string[]} paramsToRemove - list of keys, to identify key/val pairs to remove
     * @returns {Object} - filtered copy of this.params
     * @protected
     */
    Item.prototype.filterOutParams_ = function(paramsToRemove) {
        return _.omit(this.params, paramsToRemove);
    };

    /**
     * Adds each param provided as argument to current params of Item instance.
     * @param {Object} newParams - new params to add
     */
    Item.prototype.addParams = function(newParams) {
        _.extend(this.params, newParams);
    };

    /**
     * Returns headers present in params, if they exist.
     * @returns {Object|null} - headers | null
     * @protected
     */
    Item.prototype.getLoadHeaders_ = function() {
        return this.params.headers_ || null;
    };

    /**
     * Sends a load request. Can be overridden to make a few calls on this phase.
     * @param {string[]} fields - Array of fields (types of data) to be loaded.
     * @returns {angular.$q.promise}
     */
    Item.prototype.loadRequest = function(fields) {
        return this.loadConfig(fields);
    };

    /**
     *
     * Default Item's configuration coming from protobuf.
     * @type {Object|null}
     * @protected
     */
    Item.prototype.defaultConfig_ = null;

    /**
     * Loads configuration object and puts the response into Item#data.config.
     * @param {string[]} fields - Array of fields (types of data) to be loaded.
     * @return {angular.$q.promise}
     */
    Item.prototype.loadConfig = function(fields) {
        const
            headers = this.getLoadHeaders_(),
            params = this.getLoadParams(),
            paramStr = params.join('&');

        let url = `/api/${this.objectName}/${this.id}`;

        if (paramStr) {
            url += `?${paramStr}`;
        }

        this.cancelRequests('config');

        return this.request('get', url, undefined, headers, 'config')
            .then(this.onConfigLoad_.bind(this));
    };

    /**
     * For historical reasons we are saving loadConfig response to Item.data right away
     * before calling transformAfterLoad.
     * @param {Object} rsp
     * @returns {Object}
     * @protected
     */
    Item.prototype.onConfigLoad_ = function(rsp) {
        const
            { data } = rsp,
            { config } = data;

        if (config && 'url' in config) {
            // inventory response
            this.updateItemData(data);
        } else {
            // pure config response
            this.updateItemData({ config: data });
        }

        return rsp;
    };

    /**
     * In case if backend returned an error, it's going to be here.
     * @type {Object|string|null}
     */
    Item.prototype.errors = null;

    /**
     * Loads the Item's data from the back-end.
     * @param {string[]|undefined} fields - Array of fields (types of data) to be loaded.
     * @param {boolean=} ignoreErrors - Don't show error messages when API fails.
     * @returns {angular.$q.promise}
     */
    Item.prototype.load = function(fields, ignoreErrors = false) {
        const self = this;
        const deferred = $q.defer();

        if (this.id) {
            this.trigger('onBeforeLoad');
            this.busy = true;
            this.loadRequest(fields).then(function(rsps) {
                self.transformAfterLoad(rsps);
                self.busy = false;
                self.trigger('itemLoad itemLoadSuccess', rsps);
                deferred.resolve(rsps);
            }, function(errors) {
                self.errors = errors.data;
                self.busy = false;

                if (!self.isDestroyed_) {
                    self.trigger('itemLoad itemLoadFail', errors.data);
                }

                if (!ignoreErrors) {
                    AviAlertService.throw(errors.data);
                }

                deferred.reject(errors);
            });
        } else {
            const errMsg = `Can't load since Item.id for the "${this.objectName}"
                Item is missing`;

            console.error(errMsg);
            deferred.reject({ error: errMsg });
        }

        return deferred.promise;
    };

    /**
     * Gives an option to do anything with this.data.whatever after load has finished.
     */
    Item.prototype.transformAfterLoad = angular.noop;

    /**
     * This function should return the object that will be sent to server on save.
     * If the object requires cleaning before save then this is a place to do that.
     * @returns {Object} - Should return configuration data that is ready for saving.
     */
    Item.prototype.dataToSave = function() {
        return angular.copy(this.getConfig());
    };

    /**
     * Returns a API URI to save an object.
     * @returns {string}
     */
    Item.prototype.urlToSave = function() {
        return `/api/${this.objectName}${this.id ? `/${this.id}` : ''}?include_name`;
    };

    /**
     * Returns URL to delete an object.
     * @param {boolean=} force
     * @returns {string}
     * @protected
     */
    Item.prototype.getUrlToDelete_ = function(force) {
        return `/api/${this.objectName}/${this.id}${force ? '?force_delete' : ''}`;
    };

    /**
     * Returns HTTP Method to delete an Item.
     * @param {boolean=} force
     * @returns {string}
     * @protected
     */
    Item.prototype.getHttpMethodToDelete_ = function(force) {
        return 'DELETE';
    };

    /**
     * Modifies the data after saving the Item and before putting it into the data.
     * @param {Object} rsp - Back-end response after the saving API call.
     * @returns {Object} - Properties of this object will be put into this.data.config.
     */
    Item.prototype.transformDataAfterSave = function(rsp) {
        //If response contains array of objects, the last object is the one that we need
        return rsp.data instanceof Array ? rsp.data[rsp.data.length - 1] : rsp.data;
    };

    /**
     * Used to prep data to be used as the payload, like changing the structure to use the macro API
     * for example.
     * @param {Object} dataToSave - Config data to save.
     * @returns {Object} Object to be used as the payload for the save request.
     * @protected
     */
    Item.prototype.createSaveRequestPayload_ = function(dataToSave) {
        return dataToSave;
    };

    /**
     * Makes an actual API call to save an Item.
     * @returns {angular.$q.promise}
     */
    Item.prototype.saveRequest = function() {
        return this.request(this.id ? 'put' : 'post',
            this.urlToSave(),
            this.createSaveRequestPayload_(this.dataToSave()),
            null,
            'save');
    };

    /**
     * To update configuration we can use patch HTTP request type which will return whole
     * configuration of updated object.
     * @param {Object} payload
     * @returns {angular.$q.promise}
     * @protected
     */
    Item.prototype.patchRequest_ = function(payload) {
        return this.request('patch', this.urlToSave(), payload, null, 'patch');
    };

    /**
     * Used to fulfill a rejected promise when trying to make a 'config' API call
     * while we already have an ongoing 'config' API call. Fakes a backend
     * provided error response.
     * @param {string=} errorMsg - Text to be added to the default message report.
     */
    Item.prototype.isBusyError = function(errorMsg) {
        console.warn('isBusyError of %s, id: %s. %s', this.objectName, this.id,
            errorMsg || '');

        return {
            data: {
                error: `${this.objectName} with id:${this.id} is "busy" now. ${
                    errorMsg || ''}`,
            },
        };
    };

    Item.prototype.emitOnSaveEvents_ = function(type, payload) {
        function emitEvent(text, payload) {
            return function(entity) {
                entity.trigger(text, payload);
            };
        }

        const emitters = [this];

        // currently editable is working with Item.opener events object which can cause them to
        // fire twice
        if (this.opener && this.opener.events !== this.events) {
            emitters.push(this.opener);
        }

        switch (type) {
            case 'save-success':
                emitters.forEach(emitEvent('itemSave itemSaveSuccess', this));

                if (this.collection) {
                    this.collection.trigger('collItemSave collItemSaveSuccess', this);
                }

                break;

            case 'save-fail':
                emitters.forEach(emitEvent('itemSave itemSaveFail', payload));

                if (this.collection) {
                    this.collection.trigger('collItemSave collItemSaveFail', payload);
                }

                break;

            case 'create':
                emitters.forEach(emitEvent('itemCreate', this));

                if (this.collection) {
                    this.collection.trigger('collItemCreate', this);
                }

                break;

            case 'update':
                emitters.forEach(emitEvent('itemUpdate itemConfigUpdate', this));

                if (this.collection) {
                    this.collection.trigger('collItemConfigUpdate', this);
                }

                break;
        }
    };

    /**
     * Saves the object. Send reqests, makes modifications on a config object, notifies
     * Collection and triggers events.
     * @param {boolean=} appendToCollection - True by default.
     * @returns {angular.$q.promise}
     */
    Item.prototype.save = function(appendToCollection) {
        if (angular.isUndefined(appendToCollection)) {
            appendToCollection = true;
        }

        const
            self = this,
            action = this.id ? 'update' : 'create',
            deferred = $q.defer();

        this.errors = null;

        if (this.busy) {
            deferred.reject(this.isBusyError());
        } else {
            this.trigger('itemBeforeSave');
            this.busy = true;

            this.saveRequest().then(function(rsp) {
                const transformedResponse = self.transformDataAfterSave(rsp);

                self.set(transformedResponse);
                self.busy = false;

                if (self.collection) {
                    if (action === 'create' && appendToCollection) {
                        self.collection.append(self);
                    }
                }

                //data === null after Item was destroyed
                if (self.opener && self.opener.data) {
                    self.opener.set(transformedResponse);
                    self.opener.id = self.getIdFromData();
                }

                if (!self.id) {
                    self.id = self.getIdFromData();
                }

                self.emitOnSaveEvents_('save-success');
                self.emitOnSaveEvents_(action);

                deferred.resolve(rsp);
            }, function(errors) {
                self.errors = errors.data;
                self.busy = false;

                self.emitOnSaveEvents_('save-fail', errors);
                deferred.reject(errors);
            });
        }

        return deferred.promise;
    };

    /**
     * Method to apply config changes wo opening modal window. Might be convenient for table
     * view operations (actions).
     * @param {Object} payload
     * @returns {angular.$q.promise}
     */
    Item.prototype.patch = function(payload) {
        if (this.busy) {
            return $q.reject(this.isBusyError());
        } else if (this.id) {
            this.busy = true;
            this.errors = null;
            this.trigger('itemBeforeSave');

            return this.patchRequest_(payload)
                .then(
                    this.patchResponseHandler_.bind(this),
                    this.patchErrorHandler_.bind(this),
                )
                .finally(() => this.busy = false);
        } else {
            return $q.reject('Can\'t patch wo Item id');
        }
    };

    /**
     * HTTP API call response handler.
     * @param {Object} rsp - HTTP response object.
     * @returns {Object} - HTTP response object.
     * @protected
     */
    Item.prototype.patchResponseHandler_ = function(rsp) {
        this.set(this.transformDataAfterSave(rsp));
        this.emitOnSaveEvents_('save-success');

        return rsp;
    };

    /**
     * Event handler for patch API call failure.
     * @param {Object} errRsp
     * @returns {angular.$q.promise} - rejected promise.
     * @protected
     */
    Item.prototype.patchErrorHandler_ = function(errRsp) {
        this.errors = errRsp.data;
        this.emitOnSaveEvents_('save-fail', this.errors);
        AviAlertService.throw(errRsp.data);

        return $q.reject(errRsp.data);
    };

    /**
     * Updates the properties of Item.data.config. Legacy, use updateItemData instead.
     * @param {Item#data#config} configDataToUpdate - Object with properties to update.
     * @deprecated
     */
    Item.prototype.set = function(configDataToUpdate) {
        if (configDataToUpdate && angular.isObject(configDataToUpdate)) {
            this.updateItemData({ config: configDataToUpdate });
        }

        return this;
    };

    /**
     * Updates Item#data by received data. Manual angular.extend.
     * @param {Item#data} newData
     * @returns {boolean} Always true for now.
     */
    Item.prototype.updateItemData = function(newData) {
        if (angular.isObject(newData)) {
            const { data } = this;

            _.each(newData, (val, key) => {
                data[key] = val;

                if (key === 'config') {
                    this.trigger('itemChange', val);
                }
            });
        }

        return true;
    };

    /**
     * Returns Item.id from Item's data object.
     * @param {Item.data} data
     * @returns {Item.id|null} - null for historical reasons.
     * @protected
     */
    Item.prototype.getIdFromData_ = function(data) {
        return data.config && data.config['url'] ? data.config['url'].slug() : null;
    };

    /**
     * Returns an Item.id. If it is set and no `data` is passed id will be returned right away.
     * If it is not yet set or data object is passed, Item.getIdFromData_ will be used to
     * figure out id from Item.data.
     * @param {Object=} data - Item.data.
     * @returns {Item.id|null}
     */
    Item.prototype.getIdFromData = function(data) {
        let res = null;

        if (!data && this.id) {
            res = this.id;
        } else {
            if (!data) {
                data = this.data;
            }

            res = this.getIdFromData_(data);
        }

        return res;
    };

    /**
     * Returns an Item ref/url.
     * @returns {string} Item ref/url. Empty if not ready.
     */
    Item.prototype.getRef = function() {
        const config = this.getConfig();

        return config && config.url || '';
    };

    /**
     * Return item's type name.
     * @returns {string}
     */
    Item.prototype.getItemType = function() {
        return this.objectName;
    };

    /**
     * Returns an Item name.
     * @returns {string} - Item's name. Empty string when not ready.
     */
    Item.prototype.getName = function() {
        const config = this.getConfig();

        return config && (config.name || config.url && config.url.name()) || '';
    };

    /**
     * Item may have more than one runtime data object. This is the way to get them.
     * @param {*=} params - Depends on implementation. Regular Item keeps only one runtime
     *     object and no params needs to be passed.
     * @returns {Object|undefined} - Runtime object or undefined when not ready.
     */
    Item.prototype.getRuntimeData = function(params) {
        return this.data && this.data.runtime || undefined;
    };

    /**
     * Returns alerts data object (received through inventory API).
     * @returns {Object|null}
     */
    Item.prototype.getAlertsData = function() {
        return this.data && this.data.alert || null;
    };

    /**
     * Collection metric API needs a list of ids to be applied as filters.
     * ex: server requires pool and server ids and * can be used for vs.
     * @return {ItemMetricTuple}
     */
    Item.prototype.getMetricsTuple = function() {
        return {
            entity_uuid: this.id,
        };
    };

    /**
     * Deletes the item by appropriate API call to the back-end.
     * @param {boolean=} force - Special back-end flag to remove some related objects. As
     *     for now used by {@link VirtualService} only.
     * @param {Object=} params - Optional parameters for deletion request.
     * @param {boolean=} showAlert - Show Alert dialog if delete fails.
     * @returns {angular.$q.promise}
     * TODO no forced collection reload option
     * TODO call destroy on/after deletion
     */
    Item.prototype.drop = function(force, params, showAlert = true) {
        const self = this;
        let promise;

        if (this.isDroppable()) {
            this.trigger('itemBeforeDrop');

            const url = this.getUrlToDelete_(force);

            const method = this.getHttpMethodToDelete_(force);

            promise = this.request(method, url)
                .then(rsp => {
                    if (this.collection) {
                        this.collection.onItemDrop(this.getIdFromData());
                    }

                    self.trigger('itemDrop itemDropSuccess', rsp);
                    self.destroy();

                    return rsp;
                }, function(errors) {
                    if (showAlert) {
                        AviAlertService.throw(errors.data);
                    }

                    if (self.collection) {
                        self.collection.trigger('collectionItemDrop collectionItemDropFail');
                    }

                    self.trigger('itemDrop itemDropFail', errors.data);

                    return $q.reject(errors);
                });
        } else {
            promise = $q.reject({ error: 'Can not delete system default object' });
        }

        return promise;
    };

    /**
     * Gives an option to prepare Item#data.config before going to edit mode.
     * @abstract
     */
    Item.prototype.beforeEdit = angular.noop;

    /**
     * Returns a copy of the data.
     * @returns {Object}
     */
    Item.prototype.getDataCopy = function() {
        return angular.copy(this.data);
    };

    /**
     * Loads an Item when needed, opens Modal window which resolves a promise when done.
     * @param {string|HTMLElement=} windowElement - DOM selector or jQuery element of the window
     *     element.
     * @param {Object=} params - All properties of this object will be passed into
     *     {@link AviModal.open Modal window} angular.scope.
     * @return {ng.$q.promise}
     */
    //TODO Set a flag or keep promise unresolved to prevent following method calls when we have
    // modal opened
    Item.prototype.edit = function(windowElement, params = {}) {
        windowElement = windowElement || this.windowElement;

        if (this.loadingModal) {
            return $q.reject(this.isBusyError());
        }

        const deferred = $q.defer();

        // Cloning entire editable object
        const editable = new this.constructor({
            id: this.id,
            //FIXME: Should probably make copy of config, not whole data
            data: this.getDataCopy(),
            windowElement,
            //FIXME: shouldn't copy events, why needed?
            events: this.events,
            opener: this,
            collection: this.collection,
            loadOnEdit: this.loadOnEdit,
        });
        // FIXME copied Item never gets destroyed? Especially if not a part of collection
        // FIXME should be cloned through config: this.dataToSave() rather then config
        // reference

        params.editable = editable;
        editable.modalPromise = deferred;

        this.loadingModal = true;

        //beforeEdit and modal components are using defaultValues
        const promises = [
            defaultValues.load(),
        ];

        //loads full config when we can
        if (this.id && this.loadOnEdit) {
            promises.push(editable.load());
        }

        $q.all(promises)
            .then(() => {
                editable.beforeEdit();
                editable.trigger('itemBeforeEdit');
                editable.setPristine();
                this.openModal(editable.windowElement, params);
            })
            .catch(error => deferred.reject(error))
            .finally(() => this.loadingModal = false);

        return deferred.promise;
    };

    /**
     * Wrapper for AviModal to allow for the modification of params/bindings.
     * @param  {string} windowElement - Modal ID.
     * @param  {Object} params        - Properties to be passed to the modal.
     */
    Item.prototype.openModal = function(windowElement, params) {
        return AviModal.open(windowElement, params);
    };

    /**
     * Checks if the current state of editable Item differs from the `backed-up` state.
     * @return {boolean} - True when different.
     */
    Item.prototype.modified = function() {
        if (this.data) {
            return !!deepDiff(
                this.backup,
                this.dataToSave(),
                this.modifiedDiffFilter,
            );
        }

        return false;
    };

    /**
     * Is used by JS DeepDiff.diff function as a prefilter for editable objects comparison at
     * the modal windows.
     * By default filters out AngularJS $$hashKey property only.
     * @param {Array<string>} path - Path (array of properties names) to the current properties
     *    being compared. Is partially broken in deepDiff v1.7.
     * @param {string} key - Property name of a compared value.
     * @return {boolean} - DeepDiff.diff won't go deeper or mark it as a difference when true
     *    is returned.
     */

    Item.prototype.modifiedDiffFilter = function(path, key) {
        return key === '$$hashKey';
    };

    /**
     * Saves a copy of Item#data.config for future comparison.
     * //TODO replace by object-hash
     */
    Item.prototype.setPristine = function() {
        this.backup = this.dataToSave();
    };

    /**
     * Closes create/edit Modal window (called by cancel button), raises confirm dialog if
     * object has changed.
     */
    Item.prototype.dismiss = function(silent) {
        if (!silent && this.modified() && !$window.confirm('Dismiss changes?')) {
            return;
        }

        if (this.modalPromise) {
            this.modalPromise.reject('Cancelled by user.');
            this.modalPromise = null;
        }

        this.backup = null;

        AviModal.destroy(this.windowElement);
    };

    /**
     * Called by save button on the Modal window to save an Item.
     * @returns {angular.$q.promise}
     */
    Item.prototype.submit = function() {
        const { windowElement } = this;

        return this.save().then(() => {
            if (windowElement) {
                //potential race condition with removal of Item inside collection
                AviModal.destroy(windowElement);
            }

            if (this.modalPromise) {
                this.modalPromise.resolve(this);
                this.modalPromise = null;
            }

            this.backup = null;
        });
    };

    /**
     * Returns true if edit function is available for this object for the current user.
     * @returns {boolean}
     */
    Item.prototype.isEditable = function() {
        if (!this.windowElement) {
            return false;
        }

        return this.isInTenant(Auth.getTenantName()) && this.isAllowed();
    };

    /**
     * Returns config object of data object.
     * @returns {?Object}
     */
    Item.prototype.getConfig = function() {
        return this.data && this.data.config || null;
    };

    /**
     * Returns a parsed version of any payload taking into account the secretStubStr. If the
     * uuid exists and the payload password is equal to the secretStubStr, add the "uuid" to the
     * payload while removing "username" and "password".
     * This also covers the case where the user accidentally clears the password by clicking on
     * it. If the password is empty then the uuid is supplied in the payload.
     * @param  {Object} payload - Object used for any POST request containing the "username" and
     *     "password" properties
     * @param {string} key - Key to be used in the payload for the uuid. Defaults to 'uuid'.
     * @return {Object}
     */
    Item.prototype.getSecretStubPayload_ = function(payload, key = 'uuid') {
        if (this.id && (!payload.password || payload.password === secretStubStr)) {
            payload[key] = this.id;
            delete payload.username;
            delete payload.password;
        }

        return payload;
    };

    /**
     * Checks if Item has write permission.
     * @returns {boolean}
     */
    Item.prototype.isAllowed = function() {
        return Auth.isAllowed(
            this.permissionName_ || this.objectName.replace('-inventory', ''),
            'w',
        );
    };

    /**
     * Checks if object is in specified tenant.
     * @param {string} tenantName
     * @returns {boolean}
     */
    Item.prototype.isInTenant = function(tenantName) {
        const config = this.getConfig();

        if (tenantName && config) {
            const isAllTenants = tenantName === '*';
            const isAdmin = tenantName === 'admin';
            const dataTenantRef = this.getTenantRef();
            const dataTenantName = dataTenantRef.name();

            return isAllTenants || isAdmin || tenantName === dataTenantName;
        }

        return false;
    };

    /**
     * Returns true if delete action is allowed for this object and current user.
     * @returns {boolean}
     */
    Item.prototype.isDroppable = function() {
        return !this.isProtected() && this.isInTenant(Auth.getTenantName()) && this.isAllowed();
    };

    /**
     * Returns true if this object is protected (cannot be deleted). Used for some
     * predefined object like profiles.
     * @return {boolean}
     */
    Item.prototype.isProtected = function() {
        return defaultValues.isSystemObject(
            this.getItemType().replace('-inventory', ''),
            this.id,
        );
    };

    /**
     * Parses the list of instances and calls dataToSave on each instance, then filters out
     * undefined results. If the resulting list is empty, return undefined as the final
     * output. Used in optional Repeated lists of objects.
     * @static
     * @param {ConfigItem[]|Item[]} - List of Item or List of ConfigItem instances with
     *     dataToSave methods.
     * @return {Object[]|undefined}
     */
    Item._filterRepeatedInstances = function(instances) {
        let dataToSave = _.compact(instances.map(instance => instance.dataToSave()));

        if (!dataToSave.length) {
            dataToSave = undefined;
        }

        return dataToSave;
    };

    /**
     * Item's destructor. Cancels all ongoing API calls.
     * @override
     */
    Item.prototype.destroy = function() {
        const gotDestroyed = Item.superclass.destroy.call(this);

        if (gotDestroyed) {
            this.data = null;
            this.backup = null;
            this.windowElement = '';
            this.params = null;
            this.collection = null;
            this.opener = null;
        }

        return gotDestroyed;
    };

    /**
     * Returns an object with router params for Item's inner details state.
     * @param {string=} innerState
     * @returns {Object}
     * @protected
     * @abstract
     */
    Item.prototype.getDetailsPageStateParams_ = function(innerState) {
        return { id: this.id };
    };

    /**
     * Goes to details page of the current item.
     * @param {string=} innerState - Child state within {@link Item.detailsStateName_}.
     * @returns {ng.$q.promise}
     */
    Item.prototype.goToItemPage = function(innerState) {
        if (this.detailsStateName_) {
            if (!innerState) {
                innerState = $state.get(this.detailsStateName_).defaultChild;
            }

            return $state.go(
                `${this.detailsStateName_}.${innerState}`,
                this.getDetailsPageStateParams_(innerState),
            );
        } else {
            const errMsg =
                `Details state address is not set for Item type "${this.objectName}"`;

            console.error(errMsg);

            return $q.reject(errMsg);
        }
    };

    /**
     * Sets internal property based on defaults provided by Auth service.
     * @protected
     */
    Item.prototype.setDefaultConfig_ = function() {
        const objectName = this.getItemType().replace('-inventory', '');

        if (!objectName) {
            this.defaultConfig_ = null;

            return;
        }

        //TODO apply changes to defaults as collection does
        this.defaultConfig_ = defaultValues.getDefaultItemConfigByType(objectName);
    };

    /**
     * Returns a copied object with default Item configuration.
     * @returns {Object|null}
     * @protected
     */
    Item.prototype.getDefaultConfig_ = function() {
        //if item was instantiated before defaultValues are loaded
        if (!this.defaultConfig_) {
            this.setDefaultConfig_();
        }

        return angular.copy(this.defaultConfig_);
    };

    /**
     * Returns copied object with default Item configuration.
     * @returns {Object|null}
     */
    Item.prototype.getDefaultConfig = function() {
        return this.getDefaultConfig_();
    };

    /**
     * Returns tenant ref this object belongs to. Returns empty string if not set/present.
     * @returns {string}
     */
    Item.prototype.getTenantRef = function() {
        return this.getConfig().tenant_ref || '';
    };

    /**
     * Returns tenant id this object belongs to. Returns empty string if not set/present.
     * @returns {string}
     */
    Item.prototype.getTenantId = function() {
        const tenantRef = this.getTenantRef();

        return tenantRef.slug();
    };

    /**
     * Returns busy status of an Item.
     * @returns {boolean}
     */
    Item.prototype.isBusy = function() {
        return this.busy;
    };

    /**
     * Returns true when item has config (presumably was loaded).
     * @returns {boolean}
     */
    Item.prototype.hasConfig = function() {
        return !!this.getConfig();
    };

    /**
     * Some items want to change the usual timeframe settings based on certain config
     * properties.
     * @returns {boolean}
     */
    Item.prototype.hasCustomTimeFrameSettings = function() {
        return false;
    };

    /**
     * Returns customized time frame object for the passed time frame id.
     * @param {string} tfLabel
     * @returns {Object|null} - Null is returned to use default settings.
     * @see {@link Timeframe}
     */
    Item.prototype.getCustomTimeFrameSettings = function(tfLabel) {
        return null;
    };

    return Item;
}

itemFactory.$inject = [
    '$q',
    '$state',
    '$window',
    'Base',
    'AviModal',
    'Auth',
    'AviAlertService',
    'secretStubStr',
    'defaultValues',
    'aviInherit',
];

angular.module('core.vantage.avi')
    .factory('Item', itemFactory);
