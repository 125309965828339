/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//Controller like a factory for two directives on server's page:
//serverPercentArcChart & serverPercentPileChart, cellSparkline and sparklineCard
//TODO remove with refactored chartConfig
angular.module('aviApp').factory('chartValueController', [
'myAccount', 'DisplayValue', 'GraphSync', 'seriesDataPointService',
function(myAccount, DisplayValue, GraphSync, seriesDataPointService) {
    return function chartValueController(scope) {
        scope.myAccount = myAccount;
        scope.GraphSync = GraphSync;

        const updateValue = () => {
            const displayValueType = myAccount.getValuesDisplayType();
            const { value, unit } = DisplayValue.createCardValueUnit(scope.series,
                displayValueType);

            scope.value = value;
            scope.unit = unit;

            if (scope.errorSeries) {
                scope.errorValue = DisplayValue.createCardValueUnit(
                    scope.errorSeries, displayValueType,
                ).value;
            }
        };

        // Some charts (like sparklines) use a config to determine their values, others pass a
        // series and skip this.

        //TODO some of sparkline card doesn't update on timeframe switching
        //Don't really understand why card.metric !== item.getMetric(card.id)
        if (angular.isUndefined(scope.series)) {
            scope.$watchGroup(
                [
                    'card.metric',
                    'card.metric.getMainSeries()',
                ], ([metric, mainSeries]) => {
                    scope.series = mainSeries || null;

                    const errorTotal = metric && metric.getSeriesByType('errorTotal');

                    scope.errorSeries = errorTotal || null;
                },
            );
        }
        // otherwise series is passed explicitly

        scope.$watch(() => {
            const { series } = scope;
            const firstDataPoint = series && series.getFirstPoint() || null;
            const latestDataPoint = series && series.getLatestPoint(true) || null;

            const firstDataPointHash =
                seriesDataPointService.getDataPointHashString(firstDataPoint);

            const latestDataPointHash =
                seriesDataPointService.getDataPointHashString(latestDataPoint);

            return [
                firstDataPointHash,
                latestDataPointHash,
                myAccount.getValuesDisplayType(),
                GraphSync.mouseOnGraph,
                GraphSync.stuck,
            ].join('|');
        }, updateValue);
    };
}]);
