/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

// Note: on webkit this code causes errors whenever the globe is rotating
// When clipangle is over 90 (meaning a country shouldn't be visible because it's behind the world)
// Its path gets assigned to be '' which is not an acceptable null value
// The error looks to be unavoidable, but the behavior is what we want

// TODO: try refactoring to use d3.timer because it uses requestAnimationFrame which
// should get us smoother animations

import { feature } from 'topojson';

angular.module('aviApp').directive('worldMap', function() {
    const Map = function(scope, elm, attr) {
        /** @const {string} */
        const DEFAULT_MAP_COLOR = '#777';

        elm = $(elm);

        const width = elm.innerWidth() || 200;
        const height = width * 2 / 3;
        const globe = {};
        let projection;
        let path;
        let svg;
        let g;
        let globeTimer;

        if (!attr.globe) {
            projection = d3.geo.mercator()
                .scale(28 / 200 * width)
                .translate([width / 2, height / 1.5]);

            path = d3.geo.path().projection(projection);

            svg = d3.select(elm[0]).append('svg')
                .attr('preserveAspectRatio', 'xMidYMid')
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('width', width)
                .attr('height', height);
            g = svg.append('g');

            import(/* webpackChunkName: "map" */ '../../views/worldmaps/map.json')
                .then(module => {
                    const map = module.default;

                    globe.countries = g.append('g')
                        .attr('id', 'countries')
                        .selectAll('path')
                        .data(feature(map, map.objects.countries).features)
                        .enter()
                        .append('path')
                        .attr('id', function(d) { return d.id; })
                        .attr('fill', DEFAULT_MAP_COLOR)
                        .attr('d', path);
                });
        } else {
            projection = d3.geo.orthographic()
                .scale(90)
                .translate([width / 2, height / 2])
                .clipAngle(90);

            path = d3.geo.path()
                .projection(projection);

            svg = d3.select(elm[0]).append('svg')
                .attr('preserveAspectRatio', 'xMidYMid')
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('width', width)
                .attr('height', height);

            let rotation = 0;
            let hovered;

            const rotate = function() {
                if (!hovered) {
                    projection.rotate([rotation += 5]);

                    if (renderGlobe) {
                        renderGlobe();
                    }
                }

                globeTimer = setTimeout(rotate, 8);
            };

            let renderGlobe;

            g = svg.append('g');
            d3.json('/src/views/world-maps/countries.topo.json', function(map) {
                g.append('path')
                    .datum({ type: 'Sphere' })
                    .attr('class', 'sphere')
                    .attr('d', path)
                    .attr('fill', 'white');
                g.on('mouseover', function() {
                    hovered = true;
                }).on('mouseout', function() {
                    hovered = false;
                });

                globe.countries = g.append('g')
                    .attr('id', 'countries')
                    .selectAll('path')
                    .data(feature(map, map.objects.countries).features)
                    .enter()
                    .append('path')
                    .attr('id', function(d) { return d.id; })
                    .attr('fill', DEFAULT_MAP_COLOR);

                renderGlobe = function() {
                    globe.attr('d', path);
                };

                rotate();
                renderGlobe();
            });
        }

        scope.$on('selectingCountry', function(e, c) {
            globe.countries.attr('fill', function(d) {
                if (c && c.info && c.info.id === d.id) {
                    return 'red';
                }

                return DEFAULT_MAP_COLOR;
            });
        });

        scope.$on('$destroy', function() {
            if (globeTimer) {
                clearTimeout(globeTimer);
            }
        });

        scope.$on('clearfilters', function() {
            if (globe && globe.countries) {
                globe.countries.attr('fill', DEFAULT_MAP_COLOR);
            }
        });
    };

    return {
        restrict: 'A',
        scope: {
            globe: '=',
        },
        link: Map,
    };
});
