/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name generateSsoTokenMessage
 * @description Message that allows a user to generate an authentication token.
 * @param {Function} onCloseMessage - Called when the message is closed.
 */
class GenerateSsoTokenMessageController {
    constructor($http, Auth) {
        this._$http = $http;
        this._Auth = Auth;
    }

    $onInit() {
        this.generated = false;
        this.user = this._Auth.getUsername();
        this.data = {};
        this.error = null;
    }

    /**
     * Makes a request to generate an authentication token.
     */
    generateToken() {
        const payload = {
            hours: this.hours,
        };

        this.data = {};
        this.error = null;
        this._$http.post('/api/user-token', payload)
            .then(({ data }) => {
                this.generated = true;
                this.data = data;
            })
            .catch(({ data }) => this.error = data.error);
    }

    /**
     * Handles Input element click event to select all text inside the element.
     * @param {MouseEvent} event
     */
    focusInput(event) {
        const { target } = event;

        if (target instanceof HTMLInputElement) {
            target.trigger('focus');
            target.select();
        }
    }
}

GenerateSsoTokenMessageController.$inject = [
    '$http',
    'Auth',
];

angular.module('aviApp').component('generateSsoTokenMessage', {
    bindings: {
        onCloseMessage: '&',
    },
    controller: GenerateSsoTokenMessageController,
    templateUrl: 'src/components/messages/generate-sso-token-message/' +
            'generate-sso-token-message.html',
});
