/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/diff-view.less';
import { diff as deepDiff } from 'deep-diff';

/**
 * @ngdoc directive
 * @name diffView
 * @restrict A
 * @param {Object} left - Object to render on the left side (old).
 * @param {Object} right - Object to render on the right side (updated).
 * @param {string} leftLabel - Left column header label.
 * @param {string} rightLabel - Right column header label.
 * @param {boolean=} reverse - By default we assume that `left` object is original and `right`
 *     one was changed and color the diff appropriately. If `right` is the one to be considered
 *     original pass any string to this attribute.
 * @param {boolean=} noDiff - when true objects will be rendered side by side wo comparison and
 *     highlighting.
 * @author Alex Malitsky
 * @description
 *
 *     Renders a table with two columns comparing two objects one of which is expected to be an
 *     updated (or just somehow different) version of another.
 *
 */

angular.module('aviApp').directive('diffView', ['objectToHtmlList',
function(objectToHtmlList) {
    /**
     * Modifies deepDiff.diff array item to original obj structure keeping differences info.
     * Transforms arrays to hashes.
     * @param {Object} diffItem
     * @param {Object} parent
     * @returns {*}
     * @inner
     */
    function parseItem(diffItem, parent) {
        let i,
            pathLength = 0;

        const
            updatedParent = parent,
            leaf = {
                kind: diffItem.kind,
                right: diffItem.rhs,
                left: diffItem.lhs,
            };

        if (diffItem.path) {
            pathLength = diffItem.kind === 'A' ? diffItem.path.length : diffItem.path.length - 1;
        }

        for (i = 0; i < pathLength; i++) {
            if (!parent[diffItem.path[i]]) {
                parent[diffItem.path[i]] = {};
            }

            parent = parent[diffItem.path[i]];
        }

        if (diffItem.kind === 'A') {
            parent[diffItem.index] = parent[diffItem.index] || {};
            parent[diffItem.index] = parseItem(diffItem.item, parent[diffItem.index]);
        } else if (diffItem.path) {
            parent[diffItem.path[diffItem.path.length - 1]] = leaf;
        } else {
            //since it is value and we can't modify any property and return modified object
            return leaf;
        }

        return updatedParent;
    }

    function diffViewLink(scope) {
        let diff = {},
            originalDiff;

        scope.left = scope.left || {};
        scope.right = scope.right || {};

        if (!scope.noDiff) {
            originalDiff = (scope.reverse ? deepDiff(scope.right, scope.left) :
                deepDiff(scope.left, scope.right)) || [];

            originalDiff.forEach(val => {
                diff = parseItem(val, diff);
            });

            scope.leftHtml = objectToHtmlList(
                scope.left,
                diff,
                scope.reverse ? 'right' : 'left',
                scope.right,
            );

            scope.rightHtml = objectToHtmlList(
                scope.right,
                diff,
                scope.reverse ? 'left' : 'right',
                scope.left,
            );
        } else { //just two columns rendered side by side
            originalDiff = [];
            scope.leftHtml = objectToHtmlList(scope.left);
            scope.rightHtml = objectToHtmlList(scope.right);
        }

        /**
         * Represents if there is any difference between previous and current configuration.
         * @type {boolean}
         */
        scope.isDifferent = !!originalDiff.length;

        /**
         * Represents if full-view is toggled as an opposite to diff only.
         * @type {boolean}
         */
        scope.fullView = !scope.isDifferent;

        /**
         * Switches the view between "diff-only" and "full-view".
         */
        scope.switchDiffView = function() {
            if (scope.isDifferent) {
                scope.fullView = !scope.fullView;
            }
        };
    }

    return {
        link: diffViewLink,
        restrict: 'A',
        scope: {
            left: '=',
            right: '=',
            noDiff: '<',
            reverse: '@',
            leftLabel: '@',
            rightLabel: '@',
        },
        templateUrl: 'src/views/components/diff-view.html',
    };
}]);
