/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * This factory is for sharing a single code base for creating alert grid configs
 */
angular.module('aviApp').factory('alertGridConfig', [
'$filter', 'AlertConfig', 'eventGridConfig', '$templateCache', 'Auth',
function($filter, AlertConfig, eventGridConfig, $templateCache, Auth) {
    /**
     * Returns regular grid config for metrics info within alert info drawer.
     * @returns {Grid#config}
     * @inner
     */
    function metricsGridConfigGenerator() {
        return {
            fields: [{
                title: 'Metric',
                name: 'metric_id',
                sortBy: 'metric_id',
            }, {
                title: 'Value',
                name: 'value',
                sortBy: 'value',
            }, {
                title: 'Report Time',
                name: 'report_timestamp',
                sortBy: 'report_timestamp',
                template: '{{row.report_timestamp | noYearTime}}',
            }, {
                title: 'End Time',
                name: 'end_timestamp',
                template: '{{row.end_timestamp | noYearTime}}',
            }, {
                title: 'Step',
                name: 'step',
                template: '{{row.step + "s"}}',
            }],
            rowId(row) {
                return ['metric_id', 'value', 'report_timestamp', 'end_timestamp', 'step'].reduce(
                    function(base, propertyName) {
                        return `${base + row[propertyName]}-`;
                    }, '',
                ).slice(0, -1);
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
        };
    }

    return function(collection, options) {
        if (!collection) {
            console.error('alertGridConfig requires a collection');
        }

        const defaultConfig = {
            fields: [{
                title: 'Timestamp',
                name: 'timestamp', //className
                template: '{{::row.getConfig().timestamp | noYearTime}}',
                sortBy: 'timestamp',
            }, {
                title: 'Resource Name',
                name: 'obj_name',
                transform: row => row.getObjName(),
                sortBy: 'obj_ref',
            }, {
                title: 'Level',
                name: 'level',
                template:
                    '<item-alert-level-icon level="row.level"></item-alert-level-icon>' +
                    '<span>{{ ::row.level }}</span>',
                sortBy: 'level',
            }, {
                title: 'Summary',
                name: 'data.config.summary',
            }],
            defaultSorting: 'timestamp',
            multipleactions: [{
                title: 'Delete',
                do(rows) {
                    this.config.collection.dropItems(_.pluck(rows, 'id'));
                },
            }],
            collection,
            executeBeforeContainerExpand(row) {
                this.eventsGridConfig = undefined;
                this.metricsGridConfig = undefined;

                if ('events' in row.data.config) {
                    this.eventsGridConfig = this.config.getEventsGridConfig();
                }

                if ('metric_info' in row.data.config) {
                    this.metricsGridConfig = this.config.getMetricsGridConfig();
                }
            },
            expandedContainerTemplate: require('./alert-config-event.partial.html'),
            singleactions: [{
                title: 'Expand',
                dontCloseExpander: true,
                do(row, index, event) {
                    this.config.onRowClick(row, event);
                },
            }],
            actions: {
                configAlert(ref) {
                    new AlertConfig({ id: ref.slug() }).edit();
                },
            },
            checkboxDisable(row) {
                return row.data.config.type_tmp === 'discovery' ||
                    !Auth.isPrivilegeAllowed('PERMISSION_ALERT', 'WRITE_ACCESS');
            },
            layout: {
                hideEditColumns: true,
            },
            getEventsGridConfig: eventGridConfig,
            getMetricsGridConfig: metricsGridConfigGenerator,
        };

        return angular.extend(defaultConfig, options);
    };
}]);
