/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name HSGraphMetric
 * @description
 *
 *     HSGraphMetric always participates in updates.
 */
angular.module('aviApp').factory('HSGraphMetric', [
'Metric', function(Metric) {
    return class HSGraphMetric extends Metric {
        /**
         * HS metrics always participates in the coming update.
         * @override
         **/
        requestConfig() {
            return true;
        }
    };
}]);
