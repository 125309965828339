/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function subnetListNetworkFactory(
    $q,
    Item,
    SubnetListNetworkDataTransformer,
) {
    /**
     * @class
     * @name SubnetListNetwork
     * @extends module:avi/dataModel.Item
     * @desc
     *
     *     Network Item for api/networksubnetlist which returns both configured
     *     and discovered networks. Cloud id param is required for it to work properly.
     *
     * @see {@link SubnetListNetworkCollection}
     * @see {@link ConfiguredNetwork}
     * @see {@link ViMgrVMRuntime}
     * @author Alex Malitsky
     */
    class SubnetListNetwork extends Item {
        constructor(args = {}) {
            super(args);
            this.cloudId_ = args.cloudId;
            this.fipCapable_ = args.fip_capable;
        }

        /**
         * Returns a list of configured subnets for this Network.
         * @returns {Object[]}
         * @public
         */
        getSubnets() {
            const config = this.getConfig();

            if ('subnet' in config) {
                return config.subnet.concat();
            }

            return [];
        }

        /**
         * Loads item through the list API with uuid=uuid as a filter.
         * Regular single record loading syntax is not provided by networksubnetlist API. :(
         * @override
         **/
        loadConfig() {
            if (!this.cloudId_) {
                const errMsg = `Can't load ${this.objectName} ${this.id} wo cloudId set`;

                console.error(errMsg);

                return $q.reject(errMsg);
            }

            const headers = this.getLoadHeaders_();
            const params = this.getLoadParams();
            const paramStr = params.join('&');

            const {
                id,
                cloudId_: cloudId,
                fipCapable_: fipCapable,
                objectName,
            } = this;

            let url = `/api/${objectName}?uuid=${id}&cloud_uuid=${cloudId}`;

            if (fipCapable) {
                url += `&fip_capable=${fipCapable}`;
            }

            if (paramStr) {
                url += `&${paramStr}`;
            }

            this.cancelRequests('config');

            return this.request(
                'get',
                url,
                undefined,
                headers,
                'config',
            )
                .then(this.onConfigLoad_.bind(this));
        }

        /** @override */
        onConfigLoad_(rsp) {
            const { results: [config] } = rsp.data;

            if (config) {
                this.updateItemData({ config });
            }

            return rsp;
        }

        /** @override **/
        transformAfterLoad() {
            const config = this.getConfig();

            SubnetListNetworkDataTransformer.networkTransform(config);
        }

        /** @override */
        getName() {
            return this.getConfig().nameWithSubnets || super.getName();
        }

        /** @override */
        isEditable() {
            return false;
        }
    }

    SubnetListNetwork.prototype.objectName = 'networksubnetlist';

    return SubnetListNetwork;
}

subnetListNetworkFactory.$inject = [
    '$q',
    'Item',
    'SubnetListNetworkDataTransformer',
];

/**
 * @ngdoc service
 * @alias SubnetListNetwork
 */
angular.module('aviApp')
    .factory('SubnetListNetwork', subnetListNetworkFactory);
