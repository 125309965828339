/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRulePoolSwitchingActionConfig
 * @description DnsPolicyRulePoolSwitchingActionConfig,
 *      maps to DnsRuleActionPoolSwitching in protobof.
 */
const dnsPolicyRulePoolSwitchingActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRulePoolSwitchingActionConfig extends ActionConfigItem {
        constructor(args) {
            super(args);

            this.poolOrPoolGroup = 'pool';
        }

        handlePoolOrPoolGroupChange() {
            const config = this.getConfig();

            delete config.pool_ref;
            delete config.pool_group_ref;
        }

        /** @override */
        toString() {
            const {
                pool_ref: pool,
                pool_group_ref: poolGroup,
            } = this.getConfig();

            return `Passthrough is served by ${this.poolOrPoolGroup}: \
                    ${pool.name() || poolGroup.name()}`;
        }
    }

    angular.extend(DnsPolicyRulePoolSwitchingActionConfig.prototype, {
        type: 'DnsRuleActionPoolSwitching',
        name: 'Pool Switching',
        defaultPath: 'dnsrule.action.pool_switching',
    });

    return DnsPolicyRulePoolSwitchingActionConfig;
};

dnsPolicyRulePoolSwitchingActionConfigFactory.$inject = [
        'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRulePoolSwitchingActionConfig',
    dnsPolicyRulePoolSwitchingActionConfigFactory,
);
