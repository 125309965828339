/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-confirm-change-crs-version-counter.less';

/**
 * @ngdoc component
 * @name wafPolicyConfirmChangeCrsVersionCounter
 * @author alextsg
 * @param {number} number - Number to be shown.
 * @param {string} name - Name associated with the number shown.
 * @desc Counter of rule changes used in the waf-policy-confirm-change-crs-version-counter
 *     modal.
 */
angular.module('aviApp').component('wafPolicyConfirmChangeCrsVersionCounter', {
    bindings: {
        number: '<',
        name: '@',
    },
    templateUrl:
            'src/components/modals/waf-policy-modal/waf-policy-confirm-change-crs-version/' +
            'waf-policy-confirm-change-crs-version-counter/' +
            'waf-policy-confirm-change-crs-version-counter.html',
});
