/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  loadItem
 * @returns {Function}
 * @description
 *     Returns a function that will load instance of Item with specified Id. Id might consist of
 *     few properties, in which case object is expected to be passed as Id and will be forwarded to
 *     ItemClass constructor.
 */
angular.module('router.vantage.avi').factory('loadItem', function() {
    /**
     * @param {string|object} args - Args or item id to be passed to the constructor.
     * @param {Object} ItemClass - Class of item to be created.
     * @return {ng.$q.promise} - To be resolved with loaded item.
     */
    return (args, ItemClass) => {
        const
            item = new ItemClass(angular.isString(args) ? { id: args } : args),
            promise = item.load(undefined, true);

        return promise.then(() => item);
    };
});
