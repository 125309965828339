/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {string} PrettyTime
 * Readable format: 'YYYY-MM-DD, h:mm:ss' || 'MMM DD,YYYY HH:MM a'
 */

angular.module('aviApp').filter('prettyTime', ['myAccount', function(myAccount) {
    /**
     * Converts input string into readable time format.
     * @param {string} timestamp - takes in YYYYMMDD-HHMMSS
     * @param {number=} type - decides how to format (expects number: 1)
     * @returns {PrettyTime|undefined}
     */
    return function(timestamp, type) {
        if (!timestamp) { return; }

        const time = myAccount.uiProperty.useUTCTime ? moment.utc(timestamp) : moment(timestamp);

        return type === 1 ? time.format('lll') : time.format('YYYY-MM-DD, h:mm:ss a');
    };
}])

/**
 * @ngdoc filter
 * @name prettyTimeWithMs
 * @param {number} timestamp - Unix timestamp.
 * Returns the formatted date for given timestamp.
 */
    .filter('prettyTimeWithMs', ['myAccount', function(myAccount) {
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = myAccount.uiProperty.useUTCTime ?
                moment.utc(timestamp) :
                moment(timestamp);

            return time.format('YYYY-MM-DD, h:mm:ss:SS a');
        };
    }])

    .filter('prettyTimeShort', ['myAccount', function(myAccount) {
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = myAccount.uiProperty.useUTCTime ?
                moment.utc(timestamp) : moment(timestamp);

            return time.format('h:mm:ss a');
        };
    }])

    .filter('noYearTime', ['myAccount', function(myAccount) { //logs list
        return function(timestamp) {
            if (!timestamp) { return; }

            const time = myAccount.uiProperty.useUTCTime ?
                moment.utc(timestamp) : moment(timestamp);

            return time.format('MM/DD h:mm:ss A');
        };
    }])
/**
 * Returns the difference between the current time and the time passed in.
 * @param {string} timestamp - Date string.
 * @param {boolean} useUtc - True to force UTC, in cases where the time passed in is always in UTC
 *     format, regardless of the account settings due to backend constraints.
 */
    .filter('prettyTimeAgo', ['myAccount', function(myAccount) {
        return function(timestamp, useUtc) {
            if (!timestamp) {
                return;
            }

            const time = myAccount.uiProperty.useUTCTime ||
            useUtc ? moment.utc(timestamp) : moment(timestamp);

            return time.fromNow();
        };
    }])

    .filter('smartPrettyTime', ['$stateParams', 'myAccount', function($stateParams, myAccount) {
        return function(timestamp) {
            let timeFormat;

            if ($stateParams.timeframe === 'rt' || $stateParams.timeframe === '6h') {
                timeFormat = 'h:mm:ss a';
            } else if ($stateParams.timeframe === '1y' ||
            $stateParams.timeframe === '1q') {
                timeFormat = 'YYYY-MM-DD, h:mm:ss a';
            } else {
                timeFormat = 'MM-DD h:mm:ss a';
            }

            const time = myAccount.uiProperty.useUTCTime ?
                moment.utc(timestamp) : moment(timestamp);

            return time.format(timeFormat);
        };
    }])

/**
 * @ngdoc filter
 * @name uptime
 * @params {number|string|undefined} from - Unix timestamp.
 * @params {number|string=} to - Unix timestamp.
 * @returns {string} String representation of duration between from and to dates.
 * @description Makes string for the difference between current time and one from database (UNIX
 * timestamp). Need to support second argument to allow live-updated templates.
 **/
    .filter('uptime', ['Auth', function(Auth) {
        return function(from, to = moment().format('X')) {
            let years,
                months,
                days,
                hours,
                minutes,
                secs,
                str = '';

            if (from) {
                from = moment(from, 'X').add(
                    Auth.initialized() && Auth.initData.timeDifference || 0,
                    'seconds',
                );

                if (from.isValid()) {
                    if (!_.isUndefined(to)) {
                        to = moment(to, 'X');

                        if (!to.isValid()) {
                            to = moment();
                        }
                    } else {
                        to = moment();
                    }

                    secs = Math.max(0, moment(to).diff(from, 's'));

                    years = Math.floor(secs / (3600 * 24 * 365));
                    secs -= years * 3600 * 24 * 365;

                    months = Math.floor(secs / (3600 * 24 * 30));
                    secs -= (months * 3600 * 24 * 30);

                    days = Math.floor(secs / (3600 * 24));
                    secs -= (days * 3600 * 24);

                    hours = Math.floor(secs / 3600);
                    secs -= (hours * 3600);

                    minutes = Math.floor(secs / 60);
                    secs -= (minutes * 60);

                    if (years) {
                        str = `${years}Y ${months}M `;
                    } else if (months) {
                        str = `${months}M ${days}D `;
                    } else if (days) {
                        str = `${days}D ${hours}h `;
                    } else if (hours) {
                        str = `${hours}h ${minutes}m `;
                    } else if (minutes) {
                        str = `${minutes}m `;
                    } else if (secs) {
                        str = `${secs}s `;
                    }
                }
            }

            return str;
        };
    }]);
