/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @fileoverview Angular components need to be specified here to be downgraded and used in
 *     AngularJS.
 */
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import {
    AviConfirmationComponent,
} from './angular/modules/notification/components/avi-confirmation';
import {
    ExpanderComponent,
} from './angular/modules/ui-kit/components';
import {
    WafPolicyWhitelistRulesListComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmRulesConfigComponent,
    WafPolicyPsmGroupsConfigComponent,
} from './angular/modules/waf/components';
import { UpdateCardAboutComponent } from './angular/modules/update/components/update-card-about';

/**
 * List out Angular components to downgrade here. Names must be listed explicitly and not generated
 * automatically from the component class since that information gets stripped in the dist build.
 */
const downgradeComponents = [
    {
        ajsName: 'aviConfirmation',
        component: AviConfirmationComponent,
    },
    {
        ajsName: 'wafPolicyWhitelistRulesList',
        component: WafPolicyWhitelistRulesListComponent,
    },
    {
        ajsName: 'wafPolicyPsmLocationsConfig',
        component: WafPolicyPsmLocationsConfigComponent,
    },
    {
        ajsName: 'wafPolicyPsmRulesConfig',
        component: WafPolicyPsmRulesConfigComponent,
    },
    {
        ajsName: 'wafPolicyPsmGroupsConfig',
        component: WafPolicyPsmGroupsConfigComponent,
    },
    {
        ajsName: 'expander',
        component: ExpanderComponent,
    },
    {
        ajsName: 'updateCardAbout',
        component: UpdateCardAboutComponent,
    },
];

downgradeComponents.forEach((componentData: any): void => {
    const { ajsName, component } = componentData;

    if (!ajsName) {
        throw new Error(`Angular JS name needed for ${component}`);
    }

    angular.module('aviApp').directive(ajsName, downgradeComponent({ component }));
});
