/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/unit-card.less';

angular.module('aviApp').directive('unitCard', [
'$state', '$stateParams', '$filter', '$compile', '$timeout', 'Pool', 'Auth',
function($state, $stateParams, $filter, $compile, $timeout, Pool, Auth) {
    function link(scope) {
        let onClickUrl = '';
        const onClickParams = {};

        /**
         * Returns true if stateLabel should be displayed. False otherwise.
         * @return {boolean}
         */
        scope.showStateLabel = function() {
            const { data } = scope.unit;

            if (!data || !data.runtime || !data.runtime.oper_status) {
                return false;
            }

            const { state } = scope.unit.data.runtime.oper_status;

            return state === 'OPER_DISABLING' || state === 'OPER_ERROR_DISABLED' ||
                   scope.unit.data.config.enable_state === 'SE_STATE_DISABLED_FOR_PLACEMENT';
        };

        /**
         * Returns item/unit type when applicable. Used by template.
         * @returns {string|undefined}
         * @public
         */
        scope.getUnitType = function() {
            let res;

            switch (scope.type) {
                case 'vs':
                case 'se':
                    res = scope.type;
                    break;

                case 'pool':
                    //for inventory map only
                    if (scope.unit && scope.unit.data && scope.unit.data.type === 'backup') {
                        res = 'backup ';
                    } else {
                        res = '';
                    }

                    res += scope.type;
            }

            return res;
        };

        scope.$watch('unit.data.runtime.oper_status.state', function(state) {
            scope.stateLabel = state ? $filter('unitStateLabel')(state) : '';
        });

        if (scope.type === 'se') {
            scope.$watch('unit.data.config.enable_state', function(state) {
                scope.stateLabel = state && state === 'SE_STATE_DISABLED_FOR_PLACEMENT' ?
                    'Disabled for Placement' : scope.stateLabel;
            });
        }

        //as we don't have some properties immediately after collection has been loaded
        //TODO replace with event ItemLoad
        scope.$watchCollection('unit.data.config', function(config) {
            if (config) {
                switch (scope.type) {
                    case 'server':
                        if (angular.isObject(scope.extra)) {
                            onClickParams.serverId = config.uuid;
                            onClickUrl = 'authenticated.application.pool-detail.server-detail.' +
                                'analytics';

                            if (scope.extra instanceof Pool) {
                                onClickParams.vsId = scope.extra.getVSId();
                                onClickParams.poolId = scope.extra.id;
                            } else {
                                onClickParams.vsId = scope.extra.vsId;
                                onClickParams.poolId = scope.unit.data.pool_ref &&
                                    scope.unit.data.pool_ref.slug();
                            }
                        } else {
                            console.warn(`unitCard of Pool Server "${config.uuid}" doesn't have
                                Pool as an extra property`);
                            onClickUrl = '';
                        }

                        scope.name = config.uuid;
                        break;

                    case 'pool':
                        onClickParams.vsId = scope.unit.getVSId();
                        onClickParams.poolId = config.uuid || config.url.slug();

                        if (scope.fromState === 'server') {
                            onClickUrl = 'authenticated.application.pool-detail.servers';
                        } else {
                            onClickUrl = 'authenticated.application.pool-detail.analytics';
                        }

                        break;

                    case 'se':
                        onClickParams.seId = config.uuid || config.url.slug();
                        onClickParams.cloudId = config.cloud_ref && config.cloud_ref.slug();
                        onClickUrl = '^.^.infrastructure.cloud.serviceengine-detail.summary';

                        if (angular.isUndefined(config.name) && scope.unit.se_ref) {
                            scope.name = scope.unit.se_ref.name();
                        }

                        break;

                    case 'vs':
                        onClickParams.vsId = config.url.slug();
                        onClickUrl = 'authenticated.application.virtualservice-detail' +
                                     '.analytics';
                        scope.poolsNum = scope.unit.data.poolsNum ||
                            scope.unit.pools &&
                            scope.unit.pools.items &&
                            scope.unit.pools.items.length ||
                            scope.unit.data.pools && scope.unit.data.pools.length;
                        break;
                }
            }
        });

        scope.onClick = function() {
            if (scope.type !== 'net') {
                let permission;

                switch (scope.type) {
                    case 'pool':
                    case 'server':
                        permission = 'PERMISSION_POOL';
                        break;
                    case 'vs':
                        permission = 'PERMISSION_VIRTUALSERVICE';
                        break;
                    case 'se':
                        permission = 'PERMISSION_SERVICEENGINE';
                        break;
                }

                if (!Auth.isAllowed(permission, 'r')) {
                    return;
                }

                onClickParams.timeframe = $stateParams.timeframe;
                $state.go(onClickUrl, onClickParams);
            }
        };

        /**
         * Returns the class names for the unit-card, used in ng-class.
         * @return {string}
         */
        scope.getClassName = function() {
            const { data } = scope.unit;

            let output = $filter('healthScoreClass')(
                data.health_score && data.health_score.health_score,
                data.runtime && data.runtime.oper_status.state,
            );

            if (angular.isArray(data.faults) && data.faults.length) {
                output += ' has-faults';
            }

            return output;
        };
    }

    return {
        restrict: 'E',
        scope: {
            unit: '=',
            //pool for server or object with vs and pool ids
            extra: '=',
            //vs, pool, se, net, server
            type: '@',
            //page user is coming from
            fromState: '@',
        },
        template: require('../../views/components/unit-card.partial.html'),
        link,
    };
}]);
