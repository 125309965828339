/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBSiteInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 */
angular.module('aviApp').factory('GSLBSiteInventoryCollDataSource', [
'InventoryCollDataSource',
function(InventoryCollDataSource) {
    return class GSLBSiteInventoryCollDataSource extends InventoryCollDataSource {
        constructor(args) {
            super(args);
            this.hasSearch = false;
            this.hasSorting = false;
            this.hasPagination = false;
        }

        /** @override */
        getRequestParams_() {
            const request = super.getRequestParams_(this.params_);

            request['objectName_'] = [
                this.owner_.objectName_,
                this.owner_.getGSLBid(),
            ];

            return request;
        }

        /**
         * Since GslbSiteCollection may have only either regular or "non-avi" sites we need to
         * filter out inappropriate ones.
         * @override
         **/
        processResponse_(data, requestParams) {
            const
                { data: resp } = data,
                { nonAvi_: nonAvi } = this.owner_,
                methodName = nonAvi ? 'filter' : 'reject';

            resp.results = _[methodName](resp.results, ({ config }) => config._isNonAviSite);
            resp.count = resp.results.length;

            super.processResponse_(...arguments);
        }

        /** @override */
        isInactive() {
            return !this.owner_.getGSLBid() || super.isInactive();
        }
    };
}]);
