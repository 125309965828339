/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  uploadGeoFilesPage
 * @description  Page for Uploading Geo Files.
 */
class UploadGeoFilesController {
    constructor(CollateUpload, Schema) {
        this.Schema = Schema;
        this.upload = new CollateUpload(
            {
                uri: 'controller://gslb',
                chunkIndexStartsWith: 1,
            },
        );
        this.file = null;
        this.uploadStarted = false;

        /** @type {?string} */
        this.fileFormat = null;
    }

    /**
     * Calls send method from upload instance to upload a file.
     */
    uploadFile() {
        this.uploadStarted = true;

        let destination = '/api/fileservice/gslb';

        if (this.fileFormat) {
            destination += `?geodbformat=${this.fileFormat}`;
        }

        this.upload.send(this.file, this.file.name, destination);
    }
}

UploadGeoFilesController.$inject = [
    'CollateUpload',
    'Schema',
];

angular.module('aviApp').component('uploadGeoFilesPage', {
    controller: UploadGeoFilesController,
    templateUrl: 'src/components/pages/infrastructure/gslb/upload-geo-files-page/' +
        'upload-geo-files-page.html',
});
