/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './custom-tags-config.less';

class CustomTagsConfigController {
    /**
     * Adds Custom Tag object to custom_tags array. Only applicable for AWS/Azure Cloud or SE group.
     */
    addCustomTag() {
        this.customTags.push({});
    }

    /**
     * Removes Custom Tag object from custom_tags array. If custom_tags is empty as a result, set it
     * to undefined.
     * @param {number} index - Index of Custom Tag object to be removed.
     */
    removeCustomTag(index) {
        if (Array.isArray(this.customTags)) {
            this.customTags.splice(index, 1);
        }
    }
}

/**
 * @ngdoc component
 * @name customTagsConfig
 * @param {Object[]}
 * @description
 *     Component for configuring custom tags for Azure/AWS Cloud or SEGroup instance.
 */
angular.module('aviApp').component('customTagsConfig', {
    bindings: {
        customTags: '<',
    },
    controller: CustomTagsConfigController,
    templateUrl: 'src/components/infrastructure/custom-tags-config/custom-tags-config.html',
});
