/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @name timeframes
 * @description
 * Hash of timeframes we use for two types of pages. 'def' for all pages but log, event and alerts.
 */

angular.module('aviApp').constant('timeframes', {
    rt: {
        def: true,
        log: false,
        insights: true,
    },
    '15m': {
        def: false,
        log: true,
    },
    '1h': {
        def: false,
        log: true,
    },
    '3h': {
        def: false,
        log: true,
    },
    '6h': {
        def: true,
        log: true,
        insights: true,
    },
    '1d': {
        def: true,
        log: true,
    },
    '1w': {
        def: true,
        log: true,
    },
    '1m': {
        def: true,
        log: true,
    },
    '1q': {
        def: true,
        log: true,
    },
    '1y': {
        def: true,
        log: true,
    },
    all: {
        def: false,
        log: true,
    },
    custom: {
        def: false,
        log: true,
    },
});
