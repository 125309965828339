/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('avi/app').controller('LoginController', [
'$scope', '$location', 'Auth', '$timeout', '$rootScope', '$state', 'appStateHandler', 'AviModal',
'AviMessage', 'appStateConstants',
function($scope, $location, Auth, $timeout, $rootScope, $state, appStateHandler, AviModal,
AviMessage, appStateConstants) {
    $scope.Auth = Auth;
    // error handling
    $scope.error = '';
    $scope.busy = false;

    /**
     * @type {boolean} - Whether to show logo on the login page or not.
     */
    $scope.hiddenLogo = false;

    /**
     * @private {Object}
     */
    const queryParams = $location.search();
    /**
     * @private {string}
     */
    const csrfToken = queryParams.csrf_token;
    /**
     * @private {string}
     */
    const sessionId = queryParams.session_id;
    /**
     * @private {string}
     */
    const tenantName = queryParams.tenant_name;
    /**
     * @private {string}
     */
    let readOnly = queryParams.read_only;
    /**
     * @private {string}
     */
    const { redirect } = queryParams;

    /**
     * Login success callback handler.
     * @private {Function}
     */
    const loginSuccessHandler = function() {
        // Create Instance of User model

        if (redirect) {
            const urlParts = redirect.replace(new RegExp('~2F', 'g'), '/').split('?');
            const { permissions } = queryParams;

            let stateName,
                params;

            _.find($state.get(), state => {
                if (angular.isUndefined(state.$$state)) {
                    return false;
                }

                const privatePortion = state.$$state();

                if (privatePortion.url) {
                    const match = privatePortion.url.exec(urlParts[0]);

                    if (match) {
                        stateName = state.name;
                        params = angular.extend({}, match, { permissions });

                        return true;
                    }
                }

                return false;
            });

            if (tenantName) {
                return Auth.setAppContext(Auth.getTenantRefByName(tenantName))
                    .then(() => $state.go(stateName, params));
            } else {
                return $state.go(stateName, params);
            }
        }

        if (tenantName) {
            Auth.setAppContext(Auth.getTenantRefByName(tenantName), true);
        } else {
            const profile = Auth.getProfile();

            let stateName = '';
            let stateParams;

            // controller setup not yet done, redirect to the welcome wizard
            if (!Auth.isWelcomeWorkflowCompleted()) {
                stateName = appStateConstants.WELCOME_STATE;
            } else if (profile.upgrade_in_progress) {
                stateName = appStateConstants.UPGRADE_STATE;
            } else {
                ({
                    name: stateName,
                    params: stateParams,
                } = appStateHandler.constructor.getLastActiveState());

                //FIXME user and tenant might be different from the previous session

                if (!stateName) {
                    stateName = appStateConstants.DEFAULT_STATE;
                    stateParams = undefined;
                }
            }

            $state
                .go(stateName, stateParams)
                .then(() => {
                    const { system_config: systemConfig } = Auth.profile;

                    if (systemConfig.linux_configuration &&
                        systemConfig.linux_configuration.motd) {
                        AviMessage.open('motd');
                    }
                })
                .catch(err => {
                    $scope.busy = false;
                    console.error(err);
                });
        }
    };

    /**
     * Login error handler.
     * @param {Object} rsp
     * @private {Function}
     */
    const loginErrorHandler = function(rsp) {
        $scope.busy = false;
        $scope.error = rsp.error;
    };

    /**
     * Navigates to the panel (screen) with slideInLeft animation
     * If there is no current panel, navigate to the first panel with fadeIn animation
     * @param {string} panelClassName - CSS classname of the panel to navigate to
     */
    const goTo = function(panelClassName) {
        $scope.error = null;
        // Using animate.css to slide the panels
        // If any of the container is active
        $('.carousel .panel').removeClass('slideInLeft slideOutLeft slideInRight slideOutRight');

        if ($('.carousel .panel.current').length) {
            const current = $('.carousel .panel.current');

            current.removeClass('current').addClass('slideOutLeft');
            $(`.carousel .panel.${panelClassName}`).show().addClass('slideInRight current');
            $timeout(function() {
                current.hide();
            }, 0);
        } else {
            $('.avi-welcome .panel:first-child').show().addClass('current fadeIn');
        }
    };

    /**
     * Sets busy state for the login i.e. limits user interactions with login screen.
     * @param {boolean=} value - True to set busy state, false otherwise. True by default.
     */
    function setBusy(value) {
        value = angular.isUndefined(value) ? true : value;
        $scope.busy = value;
        $scope.error = '';
    }

    if (sessionId) {
        $scope.hiddenLogo = true;
        setBusy();

        if (angular.isString(readOnly)) {
            readOnly = readOnly.toLowerCase() == 'true';
        }

        if (!redirect) {
            Auth.emptyLocalStorage();
        }

        const payload = {
            session_id: sessionId,
            csrf_token: csrfToken,
            read_only: readOnly,
        };

        Auth.login(payload)
            .then(loginSuccessHandler)
            .catch(() => {
                setBusy(false);
                goTo();
            });
    } else {
        goTo();
    }

    // Put the focus into the form
    $timeout(function() {
        $('.initial-focus').trigger('focus');
    });

    /**
     * Checks the email configuration of the system config. If the SMTP is set to None, don't
     * display the Forgot Password link since the system won't be able to send the password change
     * email anyways.
     * @return {boolean}
     */
    $scope.showForgotPassword = function() {
        return Auth.initialized() && Auth.initData['email_configuration_is_set'];
    };

    // login page
    $scope.login = function() {
        if ($scope.busy) {
            return;
        }

        setBusy();

        const { username, password } = $scope;

        Auth.login({ username, password })
            .then(loginSuccessHandler)
            .catch(loginErrorHandler);
    };

    // login page
    $scope.passwordChangeRequest = function() {
        if ($scope.busy) {
            return;
        }

        setBusy();
        Auth.passwordChangeRequest($scope.recovery.email).then(() => {
            $scope.busy = false;
            $scope.goTo('complete');
            $timeout(function() {
                $scope.backto('log-in');
            }, 3000);
        }, loginErrorHandler);
    };

    /**
     * Navigates to the panel (screen) with the slideInRight animation
     * Used to come back to "previous" screen
     * @param panelClassName - CSS classname of the panel to navigate to
     */
    $scope.backto = function(panelClassName) {
        $scope.error = null;
        $scope.recovery = {};
        $scope.FormPasswordRecovery.$setPristine();
        $('.carousel .panel').removeClass('slideInLeft slideOutLeft slideInRight slideOutRight');

        const current = $('.carousel .panel.current');

        current.removeClass('current').addClass('slideOutRight');
        $(`.carousel .panel.${panelClassName}`).show().addClass('slideInLeft current');
        $timeout(function() {
            current.hide();
        }, 0);
    };

    $scope.$on('$destroy', () => {
        AviMessage.destroyAll();
        AviModal.destroyAll();
    });

    $scope.goTo = goTo;
}]);
