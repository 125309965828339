/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { ssoPolicyTypes } from '../../../../../js/services/items/SSOPolicy';

const { SSO_TYPE_SAML } = ssoPolicyTypes;

/**
 * @class
 * @alias module:components/samlPolicySettings
 * @desc
 *     SAML Settings for virtual service. Only applicable for HTTP app profile type.
 * @author Aravindh Nagarajan, Alex Malitsky
 */
class SamlPolicySettingsController {
    constructor(
        CertificateCollection,
        SSOPolicyCollection,
    ) {
        this.certificateCollection = new CertificateCollection({
            params: {
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            },
        });

        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: SSO_TYPE_SAML,
            },
            defaults: {
                type: SSO_TYPE_SAML,
            },
        });
    }

    /** @override */
    $onDestroy() {
        [
            this.certificateCollection,
            this.ssoPolicyCollection,
        ]
            .forEach(collection => collection.destroy());
    }
}

SamlPolicySettingsController.$inject = [
    'CertificateCollection',
    'SSOPolicyCollection',
];

/**
 * @ngdoc component
 * @alias samlPolicySettings
 * @param {VirtualService#data#config#saml_sp_config} samlSpConfig
 * @param {VirtualService#data#config#sso_policy_ref} ssoPolicyRef
 */
angular.module('aviApp').component('samlPolicySettings', {
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: SamlPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'saml-policy-settings/saml-policy-settings.html',
});
