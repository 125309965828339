/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { ssoPolicyTypes } from '../../../js/services/items/SSOPolicy';

const {
    SSO_TYPE_SAML,
    SSO_TYPE_PINGACCESS,
} = ssoPolicyTypes;

//TODO import these from AuthProfile
const AUTH_PROFILE_TYPE_SAML = 'AUTH_PROFILE_SAML';
const AUTH_PROFILE_TYPE_PINGACESS = 'AUTH_PROFILE_PINGACCESS';

/**
 * Hash of auth profile type by SSO policy type.
 * @type {Object.<string, string>}
 * @private
 */
const authProfileTypeBySsoPolicyTypeHash = {
    [SSO_TYPE_SAML]: AUTH_PROFILE_TYPE_SAML,
    [SSO_TYPE_PINGACCESS]: AUTH_PROFILE_TYPE_PINGACESS,
};

/**
 * @class
 * @alias module:components/modals/ssoPolicyModal
 * @desc Modal component for creating/editing a SSO Policy
 * @author Aravindh Nagarajan, Alex Malitsky
 */
class SSOPolicyModalController {
    constructor(
        AuthProfileCollection,
        schemaService,
    ) {
        this.authProfileCollection = new AuthProfileCollection();

        /**
         * SSO policy types.
         * @type {EnumValue[]}
         */
        this.policyTypes = schemaService.getEnumValues('SSOPolicyType');
    }

    /** @override */
    $onInit() {
        this.onTypeChange_(this.editable.type);
    }

    /**
     * Sets type and updates modal state accordingly.
     * @param {string} type - SSOPolicyType enum.
     */
    setType(type) {
        this.editable.type = type;
        this.onTypeChange_(type);
    }

    /**
     * Updates modal state based on the type passed.
     * @param {string} type - SSOPolicyType enum.
     * @protected
     */
    onTypeChange_(type) {
        const authProfileType = authProfileTypeBySsoPolicyTypeHash[type];
        const { authProfileCollection } = this;

        authProfileCollection.setParams({
            type: authProfileType,
        });

        authProfileCollection.setDefaultItemConfigProps({
            type: authProfileType,
        });
    }

    /** @override */
    $onDestroy() {
        this.authProfileCollection.destroy();
    }
}

SSOPolicyModalController.$inject = [
    'AuthProfileCollection',
    'schemaService',
];

/**
 * @ngdoc component
 * @alias ssoPolicyModal
 * @param {SSOPolicy} editable
 */
angular.module('aviApp').component('ssoPolicyModal', {
    bindings: {
        editable: '<',
    },
    controller: SSOPolicyModalController,
    templateUrl: 'src/components/modals/sso-policy-modal/sso-policy-modal.html',
});
