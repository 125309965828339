/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('NetworkSecurityPolicyDosDataTransformer', [
'RevisedDataTransformer',
RevisedDataTransformer => {
    const NetworkSecurityPolicyDosDataTransformer = class extends RevisedDataTransformer {
        processResponse(rsp) {
            let results = [];

            if (rsp.data && 'rules' in rsp.data && !_.isEmpty(rsp.data.rules)) {
                this.owner_.blockedAddressesHash = rsp.data.rules.reduce((acc, result) => {
                    result.match.client_ip.addrs.forEach(addr => acc[addr.addr] = true);

                    return acc;
                }, {});

                results = rsp.data.rules;
            }

            rsp.data.count = results.length;
            rsp.data.results = results;

            return rsp;
        }
    };

    return NetworkSecurityPolicyDosDataTransformer;
}]);
