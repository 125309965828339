/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './sparkline.less';

/**
 * Sparkline chart component controller.
 */
class SparklineChartController {
    constructor($element, $scope, MetricChart, Tooltip, moment) {
        this.$element = $element;
        this.$scope = $scope;
        this.MetricChart = MetricChart;
        this.Tooltip = Tooltip;
        this.moment = moment;

        /** @type {?Metric} */
        this.metric = null;

        /** @type {?MetricChart} */
        this.chart = null;

        /** @type {?angular.element} */
        this.chartContainer = null;

        /** @type {?ChartSync} */
        this.sync = null;

        this.metricName = '';
        this.chartTitle = '';

        this.renderHandler = this.renderHandler.bind(this);
        this.metricUpdate = this.metricUpdate.bind(this);
        this.resize = this.resize.bind(this);
    }

    $onInit() {
        const { MetricChart, $element, $scope, sync } = this;

        this.chartContainer = $element.find('.chart-container');

        const cfg = {
            width: this.getWidth(),
            height: this.getHeight(),
            axis: {
                x: {
                    visible: false,
                    type: 'time',
                },
                y0: {
                    visible: false,
                },
            },
        };

        this.chart = new MetricChart(cfg, new this.Tooltip(true));

        const { chart } = this;

        chart.render(this.chartContainer.get(0));
        chart.on(MetricChart.METRIC_LINE_RENDER, this.renderHandler);

        this.metricUpdate(this.metric);
        $scope.$watch(() => this.metric, this.metricUpdate);

        if (sync) {
            sync.add(chart);
        }

        this.$scope.$on('$repaintViewport', this.resize);
    }

    $onDestroy() {
        const { chart, sync } = this;

        if (chart) {
            chart.destroy();

            if (sync) {
                sync.remove(chart);
            }
        }
    }

    /**
     * Handles Metric instance update.
     * @param {?Metric} newMetric
     * @param {?Metric=} oldMetric
     */
    metricUpdate(newMetric, oldMetric) {
        if (newMetric && newMetric !== oldMetric) {
            this.metricName = newMetric.getTitle();
            this.chart.setLineMetric(newMetric);
        }
    }

    getWidth() {
        return this.chartContainer.width();
    }

    getHeight() {
        return this.chartContainer.height() || 60;
    }

    /**
     * Handles line chart update event.
     */
    renderHandler() {
        const { metric, chart } = this;
        const { lineSeries0 = [], colors } = chart;

        if (lineSeries0.length > 0) {
            const { dominators } = lineSeries0[0];
            const step = metric.params.step * 1000;

            chart.drawDominators(dominators, step, colors);
            this.chartTitle = this.getTitle();
        }
    }

    resize() {
        this.chart.resize(this.getWidth(), this.getHeight());
    }

    getTitle() {
        const { metric, moment, metricName } = this;

        if (angular.isArray(metric.series) && metric.series.length) {
            const { dominators } = metric.series[0];

            if (angular.isArray(dominators) && dominators.length) {
                const { params } = metric;
                const { step, limit } = params;
                const minuteStep = step / 60;
                const totalMin = minuteStep * limit;
                const domMin = minuteStep * dominators.length;
                const domTime = moment.duration(domMin, 'm').humanize();
                const inPeriod = moment.duration(totalMin, 'm').humanize(true);
                const perc = Math.round((domMin / totalMin) * 100);

                return `${metricName} ${domTime} ${inPeriod} (${perc}%)`;
            }
        }

        return metricName;
    }
}

SparklineChartController.$inject = [
    '$element',
    '$scope',
    'MetricChart',
    'ChartTooltip',
    'moment',
];

/**
 * @ngdoc component
 * @name sparklineChart
 * @description
 *     SparklineChart chart used in Health Score pages.
 * @param metric {Metric}
 * @param sync {ChartSync}
 */
angular.module('aviApp').component('sparklineChart', {
    bindings: {
        metric: '<',
        sync: '<?',
    },
    controller: SparklineChartController,
    templateUrl: 'src/components/common/charts/sparkline/sparkline.html',
});
