/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').filter('joinPorts', function() {
    return function(services) {
        return services.map(service => {
            const { override_network_profile_ref_data: netProf } = service;
            let profType = '';

            if (netProf) {
                profType = netProf.profile.type.enumeration();
            }

            const { port, port_range_end: end } = service;

            return port + (end !== port ? `–${end}` : '') +
                (service.enable_ssl ? ' (SSL)' : '') +
                (profType ? ` (${profType})` : '');
        }).join(', ');
    };
});
