/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function subnetListNetworkCollectionFactory(
    Collection,
    SubnetListNetwork,
    defaultValues,
) {
    /**
     * @class
     * @name SubnetListNetworkCollection
     * @extends module:avi/dataModel.Collection
     * @desc
     *
     *     Collection of networks working for all cloud types also having a list of
     *     subnets - discovered and configured.
     *
     * @see {@link ConfiguredNetworkCollection}
     * @see {@link VMNicIPCollection}
     * @author Alex Malitsky
     **/
    class SubnetListNetworkCollection extends Collection {
        /** @override */
        isCreatable() {
            return false;
        }

        /**
         * Need to pass 'cloudId' property to SubnetListNetwork constructor.
         * @override
         **/
        getNewItem(args = {}) {
            let cloudId = this.getParams('cloud_uuid');

            if (!cloudId) {
                // assuming here that networksubnetlist is using default cloud when
                // cloud_uuid param is not set
                cloudId = defaultValues.getSystemObjectRefByName(
                    'cloud',
                    'Default-Cloud',
                );

                console.warn(
                    'SubnetListNetwork item requested wo cloudId passed',
                );
            }

            args.cloudId = cloudId;

            return super.getNewItem(args);
        }
    }

    const dataSources = {
        list: {
            source: 'ListCollDataSourceWithSearchParam',
            transformer: 'SubnetListNetworkDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(SubnetListNetworkCollection.prototype, {
        objectName_: 'networksubnetlist',
        itemClass_: SubnetListNetwork,
        defaultDataSources_: 'list',
        allDataSources_: dataSources,
    });

    return SubnetListNetworkCollection;
}

subnetListNetworkCollectionFactory.$inject = [
    'Collection',
    'SubnetListNetwork',
    'defaultValues',
];

/**
 * @ngdoc service
 * @alias SubnetListNetworkCollection
 */
angular.module('aviApp')
    .factory('SubnetListNetworkCollection', subnetListNetworkCollectionFactory);
