/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/help.less';

/**
 * @ngdoc directive
 * @name helpPopoverEngine
 * @restricts A
 * @description
 * Help popovers are accessible on any page DOM elements with help-popover-anchor="cache id"
 * attribute. There is a special "initial"
 * help popover type with help-popover-initial="cache id" which can be placed on any DOM element,
 * but will be shown first,
 * at the top of a page and without question mark.
 *
 * Content of these popovers should be preloaded somehow as "<script type="text/ng-template"
 * id="help.popover.smth">content</script>"
 * tag or already be placed in angular cache templates (by grunt build, for example).
 *
 * OnClick helpPopoverEngine finds all landing places by attribute
 * "help-popover-anchor"|"help-popove-initial" and places question marks
 * near them (not for initial) on div.help-popover-backdrop if appropriate cached templates
 * were found. Using id's of landing places
 * helpPopoverEngine loads help popover content from cache templates, wrap them in <help-popover>
 *     transclude directive and places them on same
 * div.help-popover-backdrop.
 *
 * If landing place has attribute
 * help-popover-position = [lt(left top and so on)|ct|rt|lm|cm|rm|lb|cb|rb],
 * question mark will be placed not in left top, but in explicitly set position of this element.
 * Not applicable
 * for initial and if landing element doesn't have size (floats for ex).
 *
 * All popovers work in isolated scope of help-popover-engine directive.
 **/

angular.module('aviApp').directive('helpPopoverEngine', [
    '$compile', '$templateCache', '$timeout', '$transitions',
    function($compile, $templateCache, $timeout, $transitions) {
        function link(scope, elem, attr) {
            const
                qmSize = { height: 28, width: 28 }, //help-popover outerWidth (from css)
                padding = { horizontal: 10, vertical: 0 };//between qMark and popover

            let backdrop,
                domScope,
                landingPlaces,
                initial; // povoper at the top & middle of the page

            const init = function() {
                landingPlaces = domScope.find('[help-popover-anchor]:visible');
                [initial] = domScope.find('[help-popover-initial]:visible');
                scope.popovers = loadContent(landingPlaces, initial);

                if (!scope.popovers.length) {
                    return;
                }

                backdrop = $('<div/>')
                    .addClass('help-popover-backdrop')
                    .toggleClass('from-modal', attr['helpPopoverEngine'] === 'modal')
                    .attr('tabindex', 1)//to make it focusable
                    .attr('ng-click', 'removeBackdrop($event)');

                backdrop = $compile(backdrop)(scope, function(bd) {
                    bd.appendTo('body').trigger('focus');
                });

                backdrop.on('keypress keydown', function(e) {
                    if (e.which === 8) { //backspace
                        e.preventDefault();
                    } else if (e.which === 27) { //esc
                        scope.close();
                    } else if (e.which === 37) {
                        if (scope.active > 0) {
                            scope.$apply('prev();');
                        }
                    } else if (e.which === 39) {
                        if (scope.active < scope.popovers.length - 1) {
                            scope.$apply('next();');
                        }
                    }
                });

                $timeout(function() { //need timeout to have backdrop in DOM
                    scope.popovers.forEach(function(elem, key) {
                        let qmPos = {},
                            qMark,
                            popover,
                            carat;

                        if (!elem.isInitial) {
                            qmPos = qMarkPosition(elem.land);

                            qMark = $('<div/>').html('<i class="icon-help"></i>')
                                .addClass('help-popover-circle')
                                .attr('ng-click', `open('${key}')`)
                                //for FF, which replaces position:absolute from CSS with
                                // position:relative
                                .css('position', 'absolute');
                            qMark = $compile(qMark)(scope);
                            qMark.appendTo(backdrop);
                            qMark.offset(qmPos);

                            carat = $('<div/>')
                                .addClass('logCalloutCarrat transparent')
                                .css('position', 'absolute')//for FF
                                .attr('ng-show', `active !== false && active == ${key}`);
                            carat = $compile(carat)(scope);
                            carat.appendTo(qMark);
                        }

                        popover = $(elem.cont)
                            .attr({
                                'id-on-page': key,
                                'ng-show': `active !== false && active == ${key}`,
                            });

                        if (!elem.isInitial) {
                            popover.addClass('transparent');
                        }

                        popover = $compile(popover)(scope, function(popover) {
                            popover.appendTo(backdrop);
                        });

                        if (!elem.isInitial) {
                            //FF issue - can't get size of popover if no timeout or timeout is short
                            $timeout(function() {
                                const isHidden = popover.hasClass('ng-hide');

                                popover.removeClass('transparent');

                                if (isHidden) {
                                    popover.removeClass('ng-hide');
                                }

                                const pos = popoverPosition(qmPos, popover);

                                if (isHidden) {
                                    popover.addClass('ng-hide');
                                }

                                popover.css(pos);

                                if (qmPos.top + qmSize.height - $(window).height() > -15) {
                                    carat.remove();
                                }

                                if (typeof pos.left !== 'undefined') {
                                    carat.addClass('left');
                                }

                                carat.removeClass('transparent');
                            }, 199);
                        } else {
                            popover.addClass('initial');
                        }
                    });
                });
                scope.active = 0;
            };

            const keypress = function(e) { //questionmark hot key over the whole website
                if (scope.active === false && e.which === 63 && e.target === $('body')[0]) {
                    init();
                }
            };

            scope.active = false;//id of opened popover
            scope.popovers = [];

            scope.open = function(id) { //question mark ngClick
                scope.active = +id;
            };

            scope.close = function() {
                scope.active = false;
                $('div.help-popover-backdrop').remove();
            };

            scope.prev = function() {
                if (scope.active > 0) {
                    scope.active--;
                } else {
                    scope.active = false;
                }
            };

            scope.next = function() {
                if (scope.active < scope.popovers.length - 1) {
                    scope.active++;
                } else {
                    scope.active = false;
                }
            };

            scope.removeBackdrop = function(e) {
                if (e.target === $('div.help-popover-backdrop')[0]) {
                    $('div.help-popover-backdrop').remove();
                }

                e.stopPropagation();
            };

            elem.on('click', init);

            $timeout(function() {
                domScope = attr['helpPopoverEngine'] === 'modal' ?
                    $('div.modals > div.avi-modal:last-child') : $('body');

                domScope.on('keypress', keypress);
            });

            function loadContent(landings, initial) {
                function make(landing) {
                    const id = $(landing).attr('help-popover-anchor') ||
                            $(landing).attr('help-popover-initial');
                    const headerRegExp = /<h1>([^<]+)<\/h1>/i;
                    const isInitial = $(landing).attr('help-popover-initial') !== undefined;

                    let cont = $templateCache.get(`help.popover.${id}`);
                    let res;
                    let header;

                    if (cont) {
                        header = headerRegExp.exec(cont);

                        if (header && header[1]) {
                            header = header[1];
                        } else {
                            header = '';
                        }

                        cont = `<help-popover${params}>${cont}</help-popover>`;
                        res = {
                            id,
                            land: isInitial ? false : $(landing),
                            cont,
                            isInitial,
                            header,
                        };
                    }

                    return res;
                }

                const res = [];
                const params = ' ng-cloak';

                let popover;

                if (initial) {
                    popover = make(initial);

                    if (popover) {
                        res.push(popover);
                    }
                }

                landings.each(function(i, elem) {
                    const popover = make(elem);

                    if (popover) {
                        res.push(popover);
                    }
                });

                return res;
            }

            /* offset of element with anchor and shift value for positioning */
            const qMarkPosition = function(land) {
                let shift;
                const
                    qmPos = land.offset(),
                    landSize = {
                        width: land.outerWidth(false),
                        height: land.outerHeight(false),
                    },
                    screen = { width: $(window).width(), height: $(window).height() },
                    shifts = { lt: 1, ct: 1, rt: 1, lm: 1, cm: 1, rm: 1, lb: 1, cb: 1, rb: 1 };

                /* left, center, right, top, middle, bottom */

                /* question mark position */
                if (land.attr('help-popover-position') &&
                    shifts[land.attr('help-popover-position')] &&
                    landSize.width && landSize.height) {
                    shift = land.attr('help-popover-position');

                    if (shift[0] === 'c') {
                        qmPos.left += (landSize.width - qmSize.width) / 2;
                    } else if (shift[0] === 'r') {
                        qmPos.left += landSize.width - qmSize.width;
                    }

                    if (shift[1] === 'm') {
                        qmPos.top += (landSize.height - qmSize.height) / 2;
                    } else if (shift[1] === 'b') {
                        qmPos.top += landSize.height - qmSize.height;
                    }
                }

                if (qmPos.left > screen.width - qmSize.width) {
                    qmPos.left = screen.width - qmSize.width;
                }

                if (qmPos.top > screen.height - qmSize.height) {
                    qmPos.top = screen.height - qmSize.height;
                }

                qmPos.top = Math.max(0, qmPos.top);
                qmPos.left = Math.max(0, qmPos.left);

                return qmPos;
            };

            const popoverPosition = function(qmPos, popover) {
                const
                    pos = {},
                    margin = 15, //from screen borders
                    screen = { width: $(window).width(), height: $(window).height() };

                const popSize = {
                    width: popover.outerWidth(),
                    height: popover.outerHeight(),
                };

                const chevronIconWidth = 12;

                /* popover position */
                if (screen.width - qmPos.left - qmSize.width - padding.horizontal -
                    popSize.width < margin) {
                    pos.right = screen.width - qmPos.left + padding.horizontal;
                } else {
                    pos.left = qmPos.left + qmSize.width + padding.horizontal + chevronIconWidth;
                }

                const overflow = qmPos.top + padding.vertical + popSize.height - screen.height;

                if (overflow + margin > 0) {
                    pos.top = qmPos.top + padding.vertical - (overflow + margin);
                } else {
                    pos.top = qmPos.top + padding.vertical;
                }

                _.each(pos, function(param, key, obj) { //plain zero checking
                    if (param < 0) {
                        obj[key] = 0;
                    }
                });

                return pos;
            };

            const disabledClassName = 'cursor-not-allowed';

            function checkHelpPopovers() {
                const anchors = domScope.find('[help-popover-anchor]:visible,' +
                        '[help-popover-initial]:visible').toArray();

                const isEnabled = _.any(anchors, anchor => {
                    const id = $(anchor).attr('help-popover-anchor') ||
                        $(anchor).attr('help-popover-initial');

                    return !!$templateCache.get(`help.popover.${id}`);
                });

                elem
                    .toggleClass(disabledClassName, !isEnabled)
                    .parent()
                    .toggleClass(disabledClassName, !isEnabled)
                    .attr('title',
                        isEnabled ? 'Help' : 'No help information is available at this time');
            }

            elem.on('mouseover', checkHelpPopovers);

            const helpMsgIdDuplicates = ['initial', 'lnavigation', 'rnavigation'];

            const unbindStateChange = $transitions.onFinish({}, () => {
                //temp workaround for non-unique help IDs TODO remove by redesign
                helpMsgIdDuplicates.forEach(
                    id => $templateCache.remove(`help.popover.${id}`),
                );

                elem
                    .removeClass(disabledClassName)
                    .parent()
                    .removeClass(disabledClassName);
            });

            scope.$on('userLoggedOut', scope.close);
            scope.$on('$repaintViewport', scope.close);

            scope.$on('$destroy', function() {
                scope.close();

                domScope.off('keypress', keypress);

                unbindStateChange();
            });
        }

        return {
            restrict: 'A',
            scope: {},
            link,
        };
    }]);
