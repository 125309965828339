/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO removeFields without load
//TODO watch Timeframe inside each async function
//TODO remove this poolId || id crap made for poor Pool's Server only
angular.module('aviApp').factory('UpdatableItem', [
'$q', '$injector', 'Item', 'Timeframe', 'AsyncFactory', 'DataTransformers', 'AlertCollection',
'eventsContext', 'CollMetric', 'GroupCollMetric', 'HealthScoreMetric', 'backendDateStringFormat',
'aviInherit',
function($q, $injector, Item, Timeframe, AsyncFactory, DataTransformers, AlertCollection,
eventsContext, CollMetric, GroupCollMetric, HealthScoreMetric, backendDateStringFormat,
aviInherit) {
    /**
     * @class UpdatableItem
     * @constructor
     * @memberOf module:avi/dataModel
     * @param oArgs {object} - Configuration object, contains argument list, event listeners and
     *     more.
     * @extends module:avi/dataModel.Item
     * @desc
     *
     *     Extends {@link Item} by metric data update methods.
     */
    function UpdatableItem(oArgs = {}) {
        const self = this;

        UpdatableItem.superconstructor.call(this, oArgs);

        if (this.id &&
            ['pool', 'serviceengine', 'virtualservice'].indexOf(this.objectName) !== -1) {
            this.alertsPrioritized = new AlertCollection({
                limit: 15,
                params: {
                    'related_refs.contains': this.poolId || this.id,
                    'event_pages.contains': eventsContext(),
                },
                sortBy: '-timestamp',
            });
        }

        // Creates infrastructure to start and stop all async loops of instance
        this.async = {
            stop(remove) {
                Object.keys(self.async.hash).forEach(key => {
                    self.cancelRequests(key);
                    self.async.hash[key].stop();

                    if (!self.isDestroyed_ && key === 'collMetrics') {
                        self.removeMetricData_(
                            _.values(self.async.collMetricsHash),
                        );
                    }

                    if (remove) {
                        //used on controllerChange otherwise we will get
                        // updates after timeframe change even for stopped asyncs
                        if (key === 'collMetrics') {
                            _.each(self.async.collMetricsHash, function(metrics, mName) {
                                metrics.destroy();
                                delete self.async.collMetricsHash[mName];
                            });
                        } else if (!self.isDestroyed_ && (key === 'system_events' ||
                            key === 'config_events')) {
                            delete self.data[key.snakeToCamelCase()];
                        }

                        delete self.async.hash[key];
                    }
                });
            },
            start() {
                Object.keys(self.async.hash).forEach(function(key) {
                    // TODO customizable timeframe for each async
                    const interval = key === 'collMetrics' ?
                        self.collMetricsInterval : Timeframe.selected().interval * 1000;

                    self.async.hash[key].start(interval);
                });
            },
            hash: {},
            collMetricsHash: {},
        };

        // When timeframe changed restart all async loops in this instance
        this.onTimeframeChange = this.onTimeframeChange.bind(this);

        Timeframe.on('change', this.onTimeframeChange);

        this.fields = [];

        const { fields } = oArgs;

        if (fields) {
            if (Array.isArray(fields)) {
                this.fields.push(...fields);
            } else {
                throw new Error('"fields" prop must be an array when set');
            }
        }

        /**
         * Hash of metrics provided by collection this item belongs to. Has nothing to do with
         * Item.async, data is fetched and provided by collection.
         * @type {{string:Metric}}
         */
        this.collMetricsHash = {};
    }

    aviInherit(UpdatableItem, Item);

    /**
     * CollMetrics async function will always start every set number of milliseconds.
     * Every metric inside this function makes a decision if it should take part in this update.
     * Hence they can have different frequency of updates.
     */
    UpdatableItem.prototype.collMetricsInterval = 9990;

    /**
     * Keeps the list of requested fields
     * @type {Array}
     */
    UpdatableItem.prototype.fields = [];

    /**
     * Adds fields to load request
     * @param aFields
     */
    UpdatableItem.prototype.addLoad = function(aFields) {
        this.fields = _.uniq(this.fields.concat(aFields));

        return this.load();
    };

    /**
     * Removes update channels (fields) from the load chain of {@link Item#loadRequest}.
     * @param {string[]|string} aFields - List of "update chanel" names to be removed. Can be
     *     provided as array or just comma-separated list of names.
     */
    UpdatableItem.prototype.removeLoad = function(aFields) {
        function isNotString(val) {
            return typeof val !== 'string';
        }

        if (aFields) {
            if (Array.isArray(aFields)) {
                this.fields = _.difference(this.fields, aFields);
            } else if (!_.any(arguments, isNotString)) { //when all are strings
                this.fields = _.difference(this.fields, arguments);
            }
        }
    };

    /**
     * Before calling load remembers the list of fields
     * if called without fields, previous fields will be used
     * @param aFields
     * @return {*}
     */
    UpdatableItem.prototype.load = function(aFields) {
        // Replace fields
        if (aFields) {
            this.fields = _.uniq(aFields);
        }

        const loadParams = Array.prototype.slice.call(arguments);

        loadParams[0] = this.fields;

        return UpdatableItem.superclass.load.apply(this, loadParams);
    };

    /**
     * Loads and puts into {@link Item.data} `config_events`, `system_events` and `alerts`. Used for
     * the performance charts.
     * @param aFields {string[]} - List of fields.
     * @param oParams {Object=} - Call parameters hash to be added into the API URL.
     * @param iInterval {number=} - Interval between calls (milliseconds).
     * @returns {ng.$q.promise}
     */
    UpdatableItem.prototype.loadEventsAndAlerts = function(aFields, oParams, iInterval) {
        /**
         * Aggregation duration for the events and alerts in seconds. We can show around 30
         * event icons on the chart.
         * @type {{string: number}}
         * @inner
         */
        const stepHash = {
            rt: 60, //up to every minute
            '6h': 15 * 60,
            '1d': 3600, //up to every 1 hour
            '1w': 6 * 3600,
            '1m': 24 * 3600,
            '1q': 7 * 24 * 3600,
            '1y': 2 * 7 * 24 * 3600,
        };

        /**
         * Builds an API URL according to the type and selected timeframe.
         * @param {string} sName - Type of events/alerts we are about to load.
         * @returns {string} - API URL.
         * @inner
         */
        function makeURL(sName) {
            const
                timeframe = Timeframe.selected(),
                duration = timeframe.step * timeframe.limit,
                step = stepHash[timeframe.key],
                //number of points for the events API only
                pageSize = Math.floor(duration / step) + 1;

            let url = '';

            switch (sName) {
                case 'system_events':
                    url = `${eventBaseURL}&page_size=${pageSize}&filter=ne(module,CONFIG)`;
                    break;

                case 'config_events':
                    url = `${eventBaseURL}&page_size=${pageSize}&filter=eq(module,CONFIG)`;
                    break;

                case 'alerts':
                    url = alertBaseURL;
                    break;
            }

            url += `&step=${step}&duration=${duration}`;

            if (oParams && !_.isEmpty(oParams)) {
                url += _.reduce(oParams, function(str, val, key) {
                    return `${str + key}=${val}&`;
                }, '&').slice(0, -1);
            }

            return url;
        }

        /**
         * Update function builder to be passed into {@link AsyncFactory}.
         * @param {string} sName - What type of events/alerts do we load now.
         * @param {ng.$q.promise} deferred - Promise to be resolved/rejected on the first update.
         * @returns {Function}
         * @inner
         */
        function load(sName, deferred) {
            //`result` for alerts API, `results` for events API
            const resultsKey = sName === 'alerts' ? 'result' : 'results';

            return function() {
                return self.request('get', makeURL(sName), undefined, null, sName)
                    .then(function(rsp) {
                        self.data[sName] = {
                            series: rsp.data[resultsKey],
                            total: _.reduce(rsp.data[resultsKey], function(memo, item) {
                                return memo + item.value;
                            }, 0),
                        };

                        // For backward compatibility todo: remove when not used
                        self.data[sName.snakeToCamelCase()] = self.data[sName];
                        deferred.resolve(self.data[sName]);
                    }, function(err) {
                        deferred.reject(err);
                    });
            };
        }

        const self = this;
        const promises = [];
        const sources = ['system_events', 'config_events', 'alerts'];
        const eventBaseURL =
            `/api/analytics/logs?type=2&filter=co(all,"${this.poolId || this.id}")` +
            `&filter=ne(internal,EVENT_INTERNAL)&filter=co(event_pages,${eventsContext()})`;
        const alertBaseURL =
            `/api/alert-count?related_refs.contains=${this.poolId || this.id}` +
            `&event_pages.contains=${eventsContext()}`;

        sources.forEach(function(sName) {
            if (sName in self.async.hash && self.async.hash[sName].isActive()) {
                self.async.hash[sName].stop();
                self.data[sName] = undefined;
                self.data[sName.snakeToCamelCase()] = undefined;
            }

            if (aFields.indexOf(sName) !== -1) {
                const deferred = $q.defer();

                self.async.hash[sName] = new AsyncFactory(load(sName, deferred), {
                    maxSkipped: 3,
                    callback() {
                        self.cancelRequests(sName);
                    },
                });

                self.async.hash[sName].start(iInterval || Timeframe.selected().interval * 1000);

                promises.push(deferred);
            }
        });

        return $q.all(promises);
    };

    /**
     * Makes GET API call to load metrics data.
     * @param {Object} groupCall
     * @param {number|null} startTime
     * @param {Object=} params
     * @return {ng.$q.promise}
     */
    UpdatableItem.prototype.loadMetricsRequest = function(groupCall, startTime, params) {
        const { objectName } = this;
        const id = this.poolId || this.id;
        const metricIds = Object.keys(groupCall.metrics.params.metric_id);
        const { step, limit } = groupCall.metrics.params;

        const paramsHash = {
            metric_id: metricIds.join(),
            step,
            ...params,
        };

        if (startTime) {
            paramsHash.start = moment.utc(startTime).format(backendDateStringFormat);
        } else {
            paramsHash.limit = limit;
        }

        const url = UpdatableItem.getUrl(
            `/api/analytics/metrics/${objectName}/${id}/`,
            paramsHash,
        );

        return this.request('get', url, undefined, null, 'metrics');
    };

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     * @param aFields {array} - Array of fields that has to be loaded
     * @param oParams {hash} - key: value hash with additional parameters
     * @return {*} - Promise
     */
    UpdatableItem.prototype.loadMetrics = function(aFields, oParams, iInterval) {
        const self = this;
        const deferred = $q.defer();

        // Stop any that were running in previous load
        if (self.async.hash.metrics) {
            self.async.hash.metrics.stop();
            self.cancelRequests('metrics');
        }

        // Run the loop
        // 'metrics' is the hash key as well as the key that must be used to create the requests
        // this enabled them to be easily canceled wyth self.async.stop()
        self.async.hash.metrics = new AsyncFactory(function() {
            // Set up an object to collection information about calls that are nessesary to make
            // in order to load the right data.
            // Each field will be asked to participate to the calls defined in the group
            const groupCall = {
                metrics: {
                    params: {
                        metric_id: {},
                        step: Timeframe.selected().step,
                        limit: Timeframe.selected().limit,
                        start: null,
                    },
                    response: null,
                    responseAnomalies: null,
                },
                inventory: {
                    include: {},
                    response: null,
                },
            };

            // Preparing to make calls
            // Each field is asked to participate in group call
            _.each(aFields, function(field) {
                if (DataTransformers[field]) {
                    DataTransformers[field].beforeCall(self.data, groupCall);
                }
            });

            // Remember step & limit
            self.lastStep = Timeframe.selected().step;
            self.lastLimit = Timeframe.selected().limit;

            // Group call http requests
            const calls = {};
            let startTime;

            const metricIds = Object.keys(groupCall.metrics.params.metric_id);
            const { step, limit } = groupCall.metrics.params;
            const { objectName } = self;
            const id = self.poolId || self.id;

            // Making metric request if any fields requested to participate in it
            if (metricIds.length) {
                startTime = null;

                if (groupCall.metrics.params.start) {
                    // Always ask for latest know point, this allows backend to smooth out fake
                    // points without making a second db call
                    startTime = groupCall.metrics.params.start;
                }

                calls.metrics = self.loadMetricsRequest(groupCall, startTime, oParams);

                const anomalyApiUrlParams = {
                    metric_id: metricIds.join(),
                    aggregation: 'METRICS_ANOMALY_AGG_COUNT',
                    aggregation_window: 1,
                    step,
                    limit,
                    ...oParams,
                };

                const anomalyApiUrl = UpdatableItem.getUrl(
                    `/api/analytics/anomaly/${objectName}/${id}/`,
                    anomalyApiUrlParams,
                );

                // Calling to get anomalies
                calls.anomalies = self.request(
                    'get',
                    anomalyApiUrl,
                    undefined,
                    null,
                    'metrics',
                );
            }

            const inventoryFields = Object.keys(groupCall.inventory.include);

            // Making inventory call, used to get healthscore and other stuff
            if (inventoryFields.length) {
                const inventoryApiUrlParams = {
                    include_name: true,
                    include: inventoryFields.join(),
                    step,
                    limit,
                };

                let inventoryApiUrlPath = `/api/${objectName}-inventory/${id}/`;

                // pool server item
                if (self.poolId) {
                    inventoryApiUrlPath += 'server/';
                    inventoryApiUrlParams.all_se = true;
                    inventoryApiUrlParams.server = self.id;
                }

                const inventoryApiUrl = UpdatableItem.getUrl(
                    inventoryApiUrlPath,
                    inventoryApiUrlParams,
                );

                calls.inventory = self.request(
                    'get',
                    inventoryApiUrl,
                    undefined,
                    null,
                    'metrics',
                );
            }

            // Waiting and processing responses
            return $q.all(calls).then(function(rsp) {
                // Fullfill group calls with responses
                if (rsp.metrics) {
                    groupCall.metrics.response = rsp.metrics.data;
                }

                if (rsp.anomalies) {
                    groupCall.metrics.responseAnomalies = rsp.anomalies.data;
                }

                if (rsp.inventory) {
                    //for pool's server we get an array with one element
                    if (rsp.inventory.data.results && rsp.inventory.data.results.length) {
                        [groupCall.inventory.response] = rsp.inventory.data.results;
                    } else {
                        groupCall.inventory.response = rsp.inventory.data;
                    }
                }

                // Each field must work with it's data on it's own
                // every field may have it's specific logic and result of it's gonna place in the
                // model's data
                _.each(aFields, function(field) {
                    if (DataTransformers[field]) {
                        DataTransformers[field].afterCall(self.data, groupCall);
                    }
                });

                // Triggering event, will be called every time update from server has processed
                // So if you wanna rerender something and you can't watch the data you can bind to
                // the event triggered below
                self.trigger('itemUpdate metricsUpdate', self.data);
                deferred.resolve();
            }, function(rsp) {
                //console.log('Updatable Item "%s" load failed: %O', self.objectName, rsp);
                deferred.reject(rsp);
            });
        }, {
            maxSkipped: 5,
            callback() {
                self.cancelRequests('metrics');
            },
        });

        // Start all the async objects we created
        self.async.hash.metrics.start(iInterval || Timeframe.selected().interval * 1000);

        return deferred.promise;
    };

    /**
     * Async updates using collection API. Can be subscribed to and unsubscribed.
     * @param {Object[]} newConfObj
     */
    UpdatableItem.prototype.collMetricsSubscribe = function(newConfObj) {
        const
            configs = [],
            metricsPromises = [],
            { collMetricsHash } = this.async;

        if (Array.isArray(newConfObj)) {
            configs.push(...newConfObj);
        } else {
            configs.push(newConfObj);
        }

        if (collMetricsHash) {
            configs.forEach(config => {
                if (angular.isObject(config) && config.name) {
                    let metric = collMetricsHash[config.name];

                    const { subscriber, name } = config;

                    if (!metric) {
                        config.item = this;

                        if (subscriber) {
                            config.subscribers = [];
                            config.subscribers.push(subscriber);
                            delete config.subscriber;
                        }

                        if (config.metricClassName) {
                            const MetricConstructor = $injector.get(config.metricClassName);

                            metric = new MetricConstructor(config);
                        } else if (name in DataTransformers &&
                            angular.isFunction(DataTransformers[name])) { //modified metric
                            metric = new DataTransformers[name](config);
                        } else {
                            metric = new CollMetric(config);
                        }

                        collMetricsHash[name] = metric;

                        metricsPromises.push(metric.initialize());
                    } else if (subscriber && !_.contains(metric.subscribers, subscriber)) {
                        metric.subscribers.push(subscriber);
                    }
                }
            });
        }

        return $q.all(metricsPromises);
    };

    /**
     * Destroys and removes metrics from asyncCollHash.
     * @param {Object.<string, string>|Array<Object.<string, string>>|string} confList - Name of
     *     metric names to unsubscribe from with the name of Subscriber which is optional.
     *     Can be one object or array or name of subscriber to ubsubscribe from all metrics it
     *     owns.
     */
    UpdatableItem.prototype.collMetricsUnSubscribe = function(confList) {
        const
            list = [],
            { collMetricsHash } = this.async;

        if (!collMetricsHash) {
            return;
        }

        if (Array.isArray(confList)) {
            list.push(...confList);
        } else if (angular.isObject(confList) && 'name' in confList) {
            list.push(confList);
        } else if (angular.isString(confList)) {
            const subscriber = confList; //name of subscriber

            _.each(collMetricsHash, metric => {
                if (_.contains(metric.subscribers, subscriber)) {
                    list.push({
                        name: metric.getId(),
                        subscriber,
                    });
                }
            });
        }

        list.forEach(remConf => {
            if (angular.isObject(remConf) && 'name' in remConf && remConf.name in collMetricsHash) {
                const metric = collMetricsHash[remConf.name];

                let toDrop = !remConf.subscriber;

                if (!toDrop) { //have 'subscriber' passed
                    const
                        { subscribers } = metric,
                        subscriberIndex = subscribers.indexOf(remConf.subscriber);

                    if (subscriberIndex !== -1) {
                        if (subscribers.length > 1) {
                            metric.subscribers.splice(subscriberIndex, 1);
                        } else { //just one subscriber
                            toDrop = true;
                        }
                    }
                }

                if (toDrop) {
                    this.removeMetricData_(metric);
                    metric.destroy();
                    delete collMetricsHash[remConf.name];
                }
            }
        });
    };

    /**
     * Returns Metric instance by its name.
     * @param {string} mName
     * @returns {Metric|null}
     * @private
     */
    UpdatableItem.prototype.metricLookup_ = function(mName) {
        return this.async.collMetricsHash[mName] || this.collMetricsHash[mName] || null;
    };

    /**
     * Returns metric instance(s) being fetched by this Item.
     * @param {string[]} mNames - Unique within Item.async.collMetricsHash metrics id/name.
     * @returns {Metric|Metric[]|null} - Single value is returned when string was passed as an
     *     attribute. Array otherwise.
     * @public
     */
    UpdatableItem.prototype.getMetric = function(mNames) {
        if (angular.isString(mNames)) {
            return this.metricLookup_(mNames);
        }

        return mNames.map(mName => this.metricLookup_(mName));
    };

    //TODO add ability for immediate updates
    UpdatableItem.prototype.startCollMetricsAsync = function() {
        const
            self = this,
            deferred = $q.defer();

        // Stop any that were running in previous load
        if (this.async.hash.collMetrics) {
            this.async.hash.collMetrics.stop();
            this.cancelRequests('collMetrics');
        }

        // Run the loop
        self.async.hash.collMetrics = new AsyncFactory(function() {
            const
                requests = [],
                aRequests = [], //array of request objects for every metric and it's anomalies
                calls = [];

            _.each(self.async.collMetricsHash, metric => {
                const request = metric.beforeCall();

                if (request) {
                    requests.push(request.series);
                    aRequests.push(request.anomalies);
                }
            });

            if (requests.length) {
                const anomalySeries = _.pluck(aRequests, 'series');

                const metricsApiUrlPath = '/api/analytics/metrics/collection/';

                const metricsApiUrlParams = {
                    include_name: true,
                    include_refs: true,
                    pad_missing_data: false,
                    dimension_limit: 1000,
                };

                const metricsApiUrl = UpdatableItem.getUrl(metricsApiUrlPath, metricsApiUrlParams);

                calls[0] = self.request(
                    'post',
                    metricsApiUrl,
                    { metric_requests: requests },
                    undefined,
                    'collMetrics',
                );

                const { objectName } = self;
                const id = self.poolId || self.id;

                const anomalyApiUrlPath = `/api/analytics/anomaly/${objectName}/${id}/`;

                const { step, limit } = Timeframe.selected();

                const anomalyApiUrlParams = {
                    metric_id: anomalySeries.join(),
                    aggregation: 'METRICS_ANOMALY_AGG_COUNT',
                    aggregation_window: 1,
                    step,
                    limit,
                };

                //start for anomalies should be earliest among all metrics
                const anomalyApiStart = aRequests.reduce((base, aRequest) => {
                    let start;

                    if (!_.isUndefined(aRequest.start)) {
                        start = +base < +moment(aRequest.start) ? base : aRequest.start;
                    }

                    return start;
                }, moment.utc());

                if (anomalyApiStart) {
                    anomalyApiUrlParams.start =
                        moment.utc(anomalyApiStart).format(backendDateStringFormat);
                }

                // pool server case
                if (self.poolId) {
                    anomalyApiUrlParams.server = this.id;
                }

                const anomalyApiUrl = UpdatableItem.getUrl(anomalyApiUrlPath, anomalyApiUrlParams);

                calls[1] = self.request(
                    'get',
                    anomalyApiUrl,
                    undefined,
                    null,
                    'collMetrics',
                );

                $q.all(calls).then(([metrics, anomalies]) => {
                    const
                        { series: mSeries } = metrics.data,
                        { series: aSeries } = anomalies.data;

                    _.each(self.async.collMetricsHash, metric => {
                        metric.afterCall(mSeries, aSeries);
                        self.saveMetricData_(metric);
                    });
                    self.trigger('itemUpdate metricsUpdate', self.data);
                    deferred.resolve();
                },
                rsp => deferred.reject(rsp));
            }

            return $q.all(calls);
        }, {
            maxSkipped: 5,
            callback() {
                self.cancelRequests('collMetrics');
            },
        });

        // Start all the async objects we created
        self.async.hash.collMetrics.start(self.collMetricsInterval);

        return deferred.promise;
    };

    /**
     * Returns an Array of config objects to be used by chartConfig service
     * Should be called after subscribing to collMetrics updates.
     * Doesn't include (or works with) GET analytics calls.
     * If list is not provided, will return configs for all metrics.
     * @param list {Array|string=} list of metrics names which should be inside
     * collMetricsHash.
     */
    //TODO drop
    UpdatableItem.prototype.createChartsConfig = function(list) {
        const { collMetricsHash: hash } = this.async;

        let filteredList;

        if (!list) {
            filteredList = Object.keys(hash);
        } else {
            if (angular.isString(list)) {
                list = [list];
            }

            filteredList = _.filter(list, mName => mName in hash);
        }

        return _.reduce(filteredList, (acc, mName) => {
            const
                metric = hash[mName],
                mSeries = metric.getSeriesByType('regular');

            const errorTotalSeries = metric.getSeriesByType('errorTotal');

            const cardConfig = {
                id: mName,
                series: mSeries.map(series => series.getId()),
                errorSeries:
                    metric.getSeriesByType('error')
                        .map(series => series.getId()),
                errorTotal: errorTotalSeries ? errorTotalSeries.getId() : '',
                type: metric.type, //for scatterPlot for ex
                summaryTitle: metric.getTitle(),
                metric,
            };

            const totalSeries = metric.getSeriesByType('total');

            if (totalSeries) {
                cardConfig.totalSeries = totalSeries.getId();
            }

            acc.push(cardConfig);

            return acc;
        }, []);
    };

    /**
     * Since all Collection Metrics subrequests have to provide a unique subrequestId, every Item
     * which participates in such updates as a Collection Item should provide a unique within
     * whole collection id for this purpose.
     * @returns {string} - unique id of subrequest for Collection Metrics API when updates are
     * organized by Collection.
     */
    UpdatableItem.prototype.getGroupCollMetricsRequestId = function() {
        return `collItemRequest:${this.id}`;
    };

    /**
     * When Item is a part of Collection we need to filter out some dataFields (since one list of
     * dataFields list is used throughout Collection instance) which are not applicable for this
     * particular Item due to its configuration state.
     * @param {string} fieldName - dataField name to check.
     * @returns {boolean} - True if applicable, false otherwise.
     * @public
     * @abstract
     */
    UpdatableItem.prototype.dataFieldIsApplicable = function(fieldName) { return true; };

    /**
     * Used by collection when it is responsible for group updates through the collection metric
     * API. Not used by lone Item.
     * @param {string[]} fields - Metric names.
     * @returns {CollMetricsRequest[]} - Requests array to be included into group collection
     * metrics call to be executed by Collection.
     */
    //TODO support unusual metrics
    //TODO remove unused metrics
    //TODO workaround, subscribe/unsubscribe methods are needed
    UpdatableItem.prototype.getCollMetricsRequests = function(fields) {
        const requests = [];

        if (Array.isArray(fields)) {
            _.each(fields, function(fieldName) {
                if (this.dataFieldIsApplicable(fieldName)) {
                    if (!(fieldName in this.collMetricsHash)) {
                        this.collMetricsHash[fieldName] = new GroupCollMetric({
                            name: fieldName,
                            item: this,
                            subscribers: ['GroupCollMetricRequest'],
                            series: fieldName,
                        });
                    }

                    const request = this.collMetricsHash[fieldName].beforeCall();

                    if (request) {
                        requests.push(request.series);
                    }
                }
            }, this);
        }

        return requests;
    };

    /**
     * Used by Collection to deliver updates into Items which participate in a group collection
     * metrics call. Not used by lone Item.
     * @param {CollMetricsFullResponse} resp
     */
    UpdatableItem.prototype.processCollMetricsResponse = function(resp) {
        _.each(this.collMetricsHash, metric => {
            metric.afterCall(resp);
            this.saveMetricData_(metric);
        });
    };

    /**
     * Loads health score time series. No repeated updates, just once.
     * @return {ng.$q.promise}
     * @public
     */
    UpdatableItem.prototype.loadHsGlance = function() {
        const { data } = this;

        if (!data.hsSeriesMetric) {
            data.hsSeriesMetric = new HealthScoreMetric({ item: this });
            data.hsSeries = data.hsSeriesMetric.getMainSeries();
        }

        const
            { hsSeriesMetric } = data,
            itemIdForApi = this.poolId || this.id;

        let requestConfig = hsSeriesMetric.beforeCall();

        if (!requestConfig) {
            // no call is needed since data we have is fresh enough
            return $q.when(true);
        }

        requestConfig = requestConfig.series;

        let url =
            `/api/analytics/healthscore/${this.objectName}/${itemIdForApi}` +
            `?summary=true&step=${requestConfig.step}&limit=${requestConfig.limit}`;

        if (requestConfig.start) {
            url += `&start=${moment.utc(requestConfig.start).toISOString()}`;
        }

        return this.request('get', url, undefined, null, 'loadHsGlance')
            .then(({ data }) => {
                hsSeriesMetric.afterCall(data);
            });
    };

    /**
     * Function passed to Timeframe's change event.
     * Will be redefined inside each Item as func.bind(this) by Constructor
     */
    UpdatableItem.prototype.onTimeframeChange = function() {
        this.async.stop();
        this.async.start();
    };

    /** @override */
    //TODO destroy data.hsSeriesMetric
    UpdatableItem.prototype.destroy = function() {
        const gotDestroyed = UpdatableItem.superclass.destroy.call(this);

        if (gotDestroyed) {
            Timeframe.unbind('change', this.onTimeframeChange);
            this.async.stop(true);

            _.each(this.collMetricsHash, metric => metric.destroy());
            this.collMetricsHash = null;
        }

        return gotDestroyed;
    };

    /**
     * Removes metric series data from Item.data.
     * @param {Metric[]|Metric} metricList
     * @protected
     */
    UpdatableItem.prototype.removeMetricData_ = function(metricList) {
        if (!metricList) {
            return;
        }

        if (!Array.isArray(metricList)) {
            metricList = [metricList];
        }

        const { data } = this;

        metricList.forEach(metric => {
            metric.getSeries().forEach(
                series => delete data[series.getId()],
            );

            if (metric.totalSeries) {
                delete data[metric.totalSeries.getId()];
            }
        });
    };

    /**
     * Saves metric series data into Item.data.
     * @param {Metric[]|Metric} metricList
     * @protected
     */
    // FIXME we need to keep metrics somewhere else and never store flat series hash
    UpdatableItem.prototype.saveMetricData_ = function(metricList) {
        if (!metricList) {
            return;
        }

        if (!Array.isArray(metricList)) {
            metricList = [metricList];
        }

        const { data } = this;

        metricList.forEach(metric => {
            metric.getSeries().forEach(
                series => data[series.getId()] = series,
            );

            if (metric.totalSeries) {
                const { totalSeries } = metric;

                data[totalSeries.getId()] = totalSeries;
            }
        });
    };

    /**
     * Constructs API url from path and params hash.
     * @param {string} path
     * @param {Object=} params - Hash of key & value pairs.
     * @return {string}
     * @static
     */
    UpdatableItem.getUrl = function(path, params) {
        const queryParamsStr = _.reduce(params, (acc, value, key) => `${acc}${key}=${value}&`, '');

        if (!queryParamsStr) {
            return path;
        }

        return `${path}?${queryParamsStr}`;
    };

    return UpdatableItem;
}]);
