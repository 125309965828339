/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name MicroServiceCollection
 * @description Collection of microservice back-end objects.
 * @extends Collection
 */
angular.module('aviApp').factory('MicroServiceCollection', ['Collection', 'MicroService',
function(Collection, MicroService) {
    const MicroServiceCollection = function(oArgs) {
        MicroServiceCollection.superconstructor.call(this, oArgs);
    };

    avi.inherit(MicroServiceCollection, Collection);

    MicroServiceCollection.prototype.objectName_ = 'microservice';
    MicroServiceCollection.prototype.itemClass_ = MicroService;

    MicroServiceCollection.prototype.isCreatable = function() {
        return false;
    };

    return MicroServiceCollection;
}]);

/**
 * @ngdoc service
 * @name MicroService
 * @description Item of microservice back-end object.
 * @extends Item
 */
angular.module('aviApp').factory('MicroService', ['Item', function(Item) {
    const MicroService = function(oArgs) {
        MicroService.superconstructor.call(this, oArgs);
    };

    avi.inherit(MicroService, Item);

    MicroService.prototype.objectName = 'microservice';

    return MicroService;
}]);
