/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name timeframeSelector
 * @restrict E
 * @description
 * Updates timeframe service value when needed and syncs up to its state.
 */
angular.module('aviApp').directive('timeframeSelector', [
'Timeframe', 'myAccount', 'dropDownUtils', function(Timeframe, myAcccount, dropDownUtils) {
    function link(scope) {
        const timeFrameIsAvail = tf => !tf.hiddenInLayout;

        function tfChangeHandler(reason) {
            if (reason !== tfChangeReason) {
                scope.selectedTimeframe = Timeframe.selected().key;
            }
        }

        function tfGroupChangeHandler() {
            // filter timeframe options based on isAvailable and then sort based on index
            const options = _.sortBy(
                _.filter(
                    _.values(Timeframe.options),
                    timeFrameIsAvail,
                ),
                'index',
            );

            // mapping timeframe options to create dropdown options
            scope.options = options.map(
                ({ label, key }) => dropDownUtils.createOption(key, label),
            );
        }

        const tfChangeReason = 'from timeframeSelector';

        tfChangeHandler();
        tfGroupChangeHandler();

        scope.onChange = function({ value }) {
            Timeframe.set(value, tfChangeReason);
            myAcccount.saveDefaultTimeframe(value);
        };

        Timeframe.on('change', tfChangeHandler);
        Timeframe.on('afterGroupChange', tfGroupChangeHandler);

        scope.$on('$destroy', function() {
            Timeframe.unbind('change', tfChangeHandler);
            Timeframe.unbind('afterGroupChange', tfGroupChangeHandler);
        });
    }

    return {
        restrict: 'E',
        scope: true,
        link,
        templateUrl: 'src/views/components/timeframe-selector.tpl.html',
    };
}]);
