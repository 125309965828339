/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/client-insight-nav-timing-chart.less';

angular.module('aviApp').directive('clientInsightNavTimingChart', function() {
    return {
        scope: true,
        restrict: 'AE',
        templateUrl: '/src/views/components/client-insight-nav-timing-chart.html',
        link(scope, elm, attr) {
            scope.timing = scope.$eval(attr.timing);

            scope.$watch(function() {
                const filtered = scope.$eval(attr.filtered);

                return filtered && filtered.endToEnd && filtered.endToEnd.length;
            }, function(minimized) {
                scope.minimized = minimized;
            });

            scope.markStyle = function(barValue, total, index) {
                const adjust = 2 - index;
                const style = { left: `${100 * barValue / total}%`, top: 0 };

                if (scope.minimized) {
                    // style.height = 22 + (adjust * 5) + 'px';
                    // style["margin-top"] = '1px';
                    style.display = 'none';
                } else {
                    style.height = `${36 + adjust * 5}px`;
                }

                return style;
            };

            scope.endToEndBarStyle = function(bar, index) {
                if (scope.timing) {
                    // var top = scope.minimized ? index : (index * 5);
                    const top = index * 5;

                    return {
                        width: `${bar.value * 100 / scope.timing.endToEndTotal}%`,
                        top: `${top}px` };
                }
            };

            scope.sidewaysStackingBarChartStyle = function() {
                // var top = scope.minimized ? 0  : 10;
                const top = 10;

                return { top: `${top}px` };
            };

            scope.sidewaysStackingBarChartContainerStyle = function() {
                if (scope.timing) {
                    return {
                        width: `${scope.timing.pageDownloadTimeTotal /
                        (scope.timing.endToEndTotal || 1) * 100}%`,
                        'margin-top': '10px',
                    };
                }
            };
        },
    };
});
