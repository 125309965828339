/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type Function
 * @name gslbLocationAfterLoad
 * @param {Object} - GslbGeoLocation protobuf.
 * @returns {Object}
 * @description
 *
 *     Makes necessary modifications on the passed GslbGeoLocation object and returns it back.
 */
angular.module('aviApp').constant('gslbLocationAfterLoad', function(config) {
    if (!config || !config.location) {
        return config;
    }

    const { location } = config;

    if ('longitude' in location) {
        location.longitude = Math.toFixedN(location.longitude, 4);
    }

    if ('latitude' in location) {
        location.latitude = Math.toFixedN(location.latitude, 4);
    }

    return config;
});
