window.jQuery = window.$ = require('jquery');
require('jquery-knob');
require('angular');
require('angular-sanitize');
require('@uirouter/angularjs');
require('angular-vs-repeat');
require('angular-promise-extras');
require('angular-drag-and-drop-lists');

//top level
window._ = require('underscore')._;
window.d3 = require('d3');
// eslint-disable-next-line
window.d3v4 = require('d3v4');
window.EventEmitter2 = require('eventemitter2');
window.moment = require('moment');

import './globals.js';
import './less/all.less';

// Angular dependencies
import 'core-js/es/reflect';
import 'zone.js';
import 'reflect-metadata';

import { setAngularLib } from '@angular/upgrade/static';
import { enableProdMode } from '@angular/core';

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

import { platformBrowser } from '@angular/platform-browser';
import { AppModuleNgFactory } from './angular/app.module.ngfactory';
setAngularLib(angular);
platformBrowser().bootstrapModuleFactory(AppModuleNgFactory);

function requireAll (r) {
    r.keys().forEach(r);
}

// Regex is for all js files that don't end with .spec.js
requireAll(require.context('./js', true, /^(?!.*\.spec\.js$).*\.js$/));
requireAll(require.context('./components', true, /^(?!.*\.spec\.js$).*\.js$/));

// Require all html files to copy them into the build/dist folder
requireAll(require.context('./views', true, /\.html$/));
requireAll(require.context('./js', true, /^(?!.*\.partial\.html$).*\.html$/));
requireAll(require.context('./components', true, /^(?!.*\.partial\.html$).*\.html$/));

require('./downgrade-components');
