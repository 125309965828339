/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PoolServerFromHSScatterPlotMetricInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *    DataSource to get Pool Servers inventory date from {@link PoolServersHsScatterMetric}. No
 *    API calls, has ability to filter all servers with a white list of allowed ones.
 *
 */
angular.module('aviApp').factory('PoolServerFromHSScatterPlotMetricInventoryCollDataSource',
    ['ListCollDataSource', 'Server',
function(InventoryCollDataSource, Server) {
    function DataSource(args) {
        DataSource.superconstructor.call(this, args);

        /**
         * Hash of Server ids allowed to be present in a inventory list.
         * @type {{string:boolean}} - Keys are server ids, value is always `true`.
         * @protected
         */
        this.serverWhiteListHash_ = {};
    }

    avi.inherit(DataSource, InventoryCollDataSource);

    DataSource.prototype.hasSearch = false;
    DataSource.prototype.hasSorting = false;
    DataSource.prototype.hasPagination = false;

    DataSource.prototype.defaultParams_ = {};

    /**
     * When working with HS ScatterPlot as a source of server inventory list we need ability to
     * filter all servers by brush selection on the chart. This method sets a list of Servers
     * which can be present in list. Called by corresponding Collection instance.
     * @param {Server#id[]=} serverIds
     * @public
     */
    DataSource.prototype.setServerWhiteList = function(serverIds) {
        if (Array.isArray(serverIds)) {
            this.serverWhiteListHash_ = _.reduce(serverIds, function(hash, serverId) {
                hash[serverId] = true;

                return hash;
            }, {});
        } else {
            this.serverWhiteListHash_ = {};
        }
    };

    /**
     * Filters an inventory list of all servers provided by {@link PoolServersHsScatterMetric}
     * with a white list which is supposed to be set through brush selection on the
     * corresponding {@link scatterPlot | Pool Servers HealthScore ScatterPlot chart}.
     * @protected
     */
    DataSource.prototype.getFilteredServerInventoryList_ = function() {
        const allServers = this.owner_.HSScatterPlotMetric.getServersInventoryList();

        return _.filter(allServers, function(serverData) {
            return Server.getServerUuid(serverData.config) in this.serverWhiteListHash_;
        }, this);
    };

    /**
     * Request actually provides all results with expected format since we are using
     * {@link PoolServersHsScatterMetric} as a data source and no API call is needed.
     * @override
     */
    DataSource.prototype.getRequestParams_ = function() {
        const
            poolId = this.owner_.id,
            results = this.getFilteredServerInventoryList_();

        results.forEach(function(serverData) {
            serverData.config.poolId = poolId;
            serverData.config.uuid = Server.getServerUuid(serverData.config);
        });

        return {
            data: {
                results,
                count: results.length,
            },
        };
    };

    return DataSource;
}]);
