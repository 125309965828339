/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/percent-arc-chart.less';

/**
 * @ngdoc directive
 * @name percentArcChart
 * @scope
 * @restrict A
 * @param {number} percentArcChart - Value between 0 and 1 or between 0 and max or between min and
 *                                   max.
 * @param {number=} max - Maximum value meaning 100%. If not set assume max to be 1.
 * @param {number=} min - Minimal value meaning 0%. Makes sense only when max is set. If not set
 *     assume min to be 0.
 * @description SVG chart to show percent value as an arc.
 * //TODO reInitialize on resize event.
 */

angular.module('aviApp').directive('percentArcChart', function() {
    const link = function(scope, element) {
        let chart,
            svg,
            arc,
            pie,
            className,
            min = 0,
            max = 0;

        function init(initial) {
            //wrapper sizes
            const height = element.height();
            const width = element.width();

            max = 0;
            min = 0;

            const radius = Math.floor(Math.min(height, width / 2) * 0.9);

            svg.attr({ width: radius * 2, height });

            chart.attr('transform', `translate(${radius}, ${height})`);
            arc = d3.svg.arc().outerRadius(radius).innerRadius(Math.round(radius * 0.84));

            if (scope.max && !_.isNaN(+scope.max)) {
                max = +scope.max;

                if (scope.min && !_.isNaN(+scope.min) && +scope.min < +scope.max) {
                    min = +scope.min;
                }
            }

            if (initial) {
                scope.$watch('value', draw);
            }
        }

        function draw() {
            let percent;

            if (max) {
                percent = +scope.value / (max - min);
            } else {
                percent = +scope.value;
            }

            if (_.isNaN(+percent)) {
                percent = 0;
            }

            const data = [percent, 1 - percent];

            chart.selectAll('g.percent-arc').remove();

            const g = chart
                .selectAll('.percent-arc')
                .data(pie(data))
                .enter()
                .append('g')
                .attr('class', function(d, key) {
                    return `percent-arc ${className(key)}`;
                });

            g.append('path').attr('d', arc);
        }

        /* end of var */

        svg = d3.select(element[0]).append('svg');
        chart = svg.append('g');

        pie = d3.layout.pie().sort(null)
            .value(function(d) {
                return +d;
            })
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2);

        className = d3.scale.ordinal().range(['used', 'free']);

        init(true);
    };

    return {
        restrict: 'A',
        scope: {
            value: '=percentArcChart',
            max: '@',
            min: '@',
            ngDisabled: '=',
        },
        link,
    };
});

/**
 * @ngdoc directive
 * @name serverPercentArcChart
 * @scope
 * @restrict E
 * @param {string} label - Text label to be placed inside an arc.
 * @param {Series#data} series - Metric series data object.
 * @description Percent Arc Chart wrapper for Server Analytics page with the text label.
 */
angular.module('aviApp').directive('serverPercentArcChart', [
'chartValueController', function(chartValueController) {
    return {
        restrict: 'E',
        scope: {
            label: '@',
            series: '=',
            ngDisabled: '=',
        },
        controller: ['$scope', chartValueController],
        template: '<div percent-arc-chart="value" max="100" ng-hide="ngDisabled"></div>' +
                  '<div percent-arc-chart="0" max="100" ng-show="ngDisabled"></div>' +
                  '<div class="legend">' +
                    '<span class="name">{{ label }}</span><br>' +
                    '<span class="val" ng-show="ngDisabled">N/A</span>' +
                    '<span class="val" ng-hide="ngDisabled">{{value |number:2}}{{ unit }}</span>' +
                  '</div>',
    };
}]);
