/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name logFilterClick
 * @restrict A
 *
 * @param {string} key - Log property name to build up a filtering string.
 * @param {string|number|boolean} value - Actual value.
 * @param {number} minValue - For numeric values ranges.
 * @param {number} maxValue
 * @param {string} displayValue - String to display in layout.
 * @param {boolean} contains - Whether we should use 'contain' or 'equal to' operator for string
 *     search.
 * @param {Function} onUpdate - onClick handler to pass a filtering string further.
 *
 * @author Alex Malitsky
 * @description
 *
 *     OnClick sends a new search string (i.e param='value') to onUpdate function.
 *
 */
angular.module('logs.vantage.avi').directive('logFilterClick', function() {
    function logFilterClickLink(scope, elem) {
        function quotes(str) {
            let res = str;

            if (_.isString(str)) {
                res = `"${str}"`;
            }

            return res;
        }

        let str;

        if (!scope.minValue || !scope.maxValue) {
            scope.intDisplayValue = scope.displayValue || scope.value;

            str = `${scope.key +
                (scope.contains ? '~' : '')
            }=${quotes(scope.value)}`;
        } else {
            scope.intDisplayValue = scope.displayValue || `${scope.minValue} - ${scope.maxValue}`;
            str = [
                `${scope.key}>${+scope.minValue === 0 ? '=' : ''}${scope.minValue}`,
                `${scope.key}<=${scope.maxValue}`,
            ];
        }

        if (scope.value === 'missing') {
            elem.attr('disabled', true);
        } else {
            elem.on('click', function() {
                scope.onUpdate({ str });
                scope.$apply();
            });
        }
    }

    return {
        restrict: 'A',
        link: logFilterClickLink,
        scope: {
            key: '@',
            value: '<',
            minValue: '@',
            maxValue: '@',
            displayValue: '@',
            contains: '@',
            onUpdate: '&',
        },
        template: '<span>{{intDisplayValue}}</span>',
    };
});
