/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name FQDNDNSResolveControllerService
 * @param {string} fqdn
 * @param {IpAddr} ipAddress
 * @param {IpAddr=} autoAllocateIp - VS only.
 * @param {Function=} onAutoAllocateIpChange - Function to be called on auto allocate checkbox.
 *     VS only.

 * @description
 *
 *     Factory, returning a controller constructor for components responsible for dealing with
 *     resolving domain names and getting&setting a corresponding IP address on
 *     Item#data#config.
 *
 */
//TODO ipAddr can be changed from outside, not sure this is supported now - fqdnModel keeps
// the previous value @am
angular.module('aviApp').factory('FQDNDNSResolveControllerService', [
'Schema', 'Regex', 'DNS', '$q',
function(Schema, Regex, DNS, $q) {
    return function($scope, $attrs) {
        const vm = this;

        this.Schema = Schema;
        this.Regex = Regex;

        this.resolutions = [];
        this.busy = false;
        this.fqdnModel = undefined;

        if ($attrs.autoAllocateIp) {
            this.showAutoAllocateIp = true;
        }

        let cancelLookup = $q.defer();

        this.$onInit = function() {
            this.fqdnModel = this.fqdn || this.ipAddress && this.ipAddress.addr;
            this.setResolutions();
        };

        /**
         * Resets ip_address property of VS.
         */
        this.resetIpAddress = function() {
            this.ipAddress = {
                addr: '',
                type: 'V4',
            };
        };

        /**
         * Perform DNS.lookup on fqdnModel input.
         * @return {ng.$q.promise}
         */
        this.setResolutions = function() {
            // Cancel previous lookup
            cancelLookup.resolve();

            cancelLookup = $q.defer();
            vm.resolutions = [];
            this.busy = true;

            return DNS.lookup(this.fqdnModel, cancelLookup.promise)
                .then(function(ips) {
                    vm.resolutions = ips || [];
                }).finally(function() {
                    vm.busy = false;
                });
        };

        /**
         * Sets ip_address and fqdn fields. If input is valid IP Address, set as ip_address and set
         * fqdn to undefined. Else, set input as fqdn and use DNS lookup to populate ip_address.
         */
        this.lookupDNS = function() {
            this.resetIpAddress();

            if (Regex.ip.test(this.fqdnModel)) {
                this.ipAddress.addr = this.fqdnModel;
                this.fqdn = undefined;
            } else {
                this.fqdn = this.fqdnModel;
                this.setResolutions()
                    .then(function() {
                        if (vm.resolutions.length > 0) {
                            [vm.ipAddress.addr] = vm.resolutions;
                        }
                    });
            }
        };

        /**
         * Handler for auto allocate IP address checkbox change event.
         */
        this.autoAllocateIpChangeHandler = function() {
            this.fqdnModel = undefined;

            this.onAutoAllocateIpChange({
                $event: {
                    autoAllocateIp: this.autoAllocateIp,
                },
            });
        };

        $scope.$on('networkChanged', () => {
            if (!this.ipAddress || !this.ipAddress.addr) {
                this.fqdnModel = undefined;
            }
        });

        this.$onDestroy = function() {
            cancelLookup.resolve();
        };
    };
}]);
