/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name poolGroupDeploymentPolicyPage
 * @description Component for Pool Group Deployment Policy page.
 */
class PoolGroupDeploymentPolicyPageController {
    constructor(CRUDGridConfig, PoolGroupDeploymentPolicyCollection) {
        this.collection = new PoolGroupDeploymentPolicyCollection();

        const config = {
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
            }, {
                name: 'data.config.target_test_traffic_ratio',
                title: 'Target Traffic Ratio',
            }, {
                name: 'data.config.evaluation_duration',
                title: 'Duration',
            }, {
                name: 'data.config.test_traffic_ratio_rampup',
                title: 'Ramup Ratio',
            }, {
                name: 'data.config.rules',
                title: 'Rules',
                template: '{{ row.getRules().length || 0 }}',
            }],
            layout: {
                hideEditColumns: true,
            },
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

PoolGroupDeploymentPolicyPageController.$inject = [
        'CRUDGridConfig',
        'PoolGroupDeploymentPolicyCollection',
];

angular.module('aviApp').component('poolGroupDeploymentPolicyPage', {
    controller: PoolGroupDeploymentPolicyPageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
