/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './controller-faults-message.less';

/**
 * @ngdoc component
 * @name  controllerFaultsMessage
 * @param {ControllerFaultsCollection} collection
 * @param {function} onCloseMessage - Called when close button is clicked.
 * @description
 *     Displays the Controller faults in the system in a small popup appended to div.messages.
 */
angular.module('aviApp').component('controllerFaultsMessage', {
    bindings: {
        collection: '<',
        onCloseMessage: '&',
    },
    templateUrl: 'src/components/common/controller-faults-message/controller-faults-message.html',
});
