/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function wafPolicyPsmGroupMetricFactory(UpdatableBase) {
    /**
     * @alias module:services/WafPolicyPsmGroupMetric
     * @extends UpdatableBase
     */
    class WafPolicyPsmGroupMetric extends UpdatableBase {
        /**
         * Assigns source, transformer, transporter, and objectName_.
         * @param {Object=} args - any overrides to be extended
         */
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'WafPolicyPsmGroupMetricDataSource',
                    transformer: 'WafPolicyPsmGroupMetricDataTransformer',
                    transport: 'CollMetricsDataTransport',
                    fields: [...args.metricIds],
                },
            };

            Object.assign(args, {
                defaultDataSources: 'list',
                isStatic: true,
                allDataSources,
            });

            super(args);

            this.metricIds = args.metricIds;
            this.id = args.id;
            this.objectName_ = 'wafpolicypsmgroup';
        }

        /**
         * Returns the metrics object by an id, ex. a rule_id or a location name.
         * @returns {Object.<string, Object>}
         */
        getMetricsById(id) {
            return this.config && this.config[id] || {};
        }

        /**
         * Returns the sum number for an id and a metric.
         * @returns {number}
         */
        getSumById(id, metricName) {
            const metrics = this.getMetricsById(id);

            return metrics && metrics[metricName] || NaN;
        }
    }

    return WafPolicyPsmGroupMetric;
}

wafPolicyPsmGroupMetricFactory.$inject = [
    'UpdatableBase',
];

/**
 * @ngdoc service
 * @name WafPolicyPsmGroupMetric
 * @module services/WafPolicyPsmGroupMetric
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPolicyPsmGroupMetric', wafPolicyPsmGroupMetricFactory);
