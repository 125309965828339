/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { sample } from 'underscore';

/**
 * @typedef {Object} AvailableInfobloxSubnetOptions - Set of available infoblox v4 and v6 subnets.
 * @property {?DropdownOption[]} v4
 * @property {?DropdownOption[]} v6
 */

/**
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.ipamInfobloxUsableSubnetsConfigBindings
 * @see {@link module:avi/app.ipamInfobloxUsableSubnetsConfig}
 */
class IpamInfobloxUsableSubnetsConfigComponent {
    constructor(getSubnetString, dropDownUtils) {
        this.getSubnetString_ = getSubnetString;
        this.dropDownUtils_ = dropDownUtils;

        /**
         * @type {AvailableInfobloxSubnets}
         */
        this.availableSubnetOptions = {
            v4: [],
            v6: [],
        };
    }

    /** @override */
    $onInit() {
        this.setAvailableSubnetOptions(this.availableSubnetOptions);

        this.gridConfig = {
            rowId: row => this.configuredSubnets.indexOf(row),
            fields: [
                {
                    name: 'ipv4',
                    title: 'IPv4',
                    template: `
                        <dropdown
                            class="full-width"
                            ng-model="row.subnet"
                            parse-any-ip
                            options="config.props.subnets.v4"
                            placeholder="Select IPv4 Subnet"
                            search="true"
                        ></dropdown>
                    `,
                },
                {
                    name: 'ipv6',
                    title: 'IPv6',
                    template: `
                        <dropdown
                            class="full-width"
                            ng-model="row.subnet6"
                            parse-any-ip
                            options="config.props.subnets.v6"
                            placeholder="Select IPv6 Subnet"
                            search="true"
                        ></dropdown>
                    `,
                },
            ],
            singleactions: [
                {
                    title: 'Delete',
                    class: 'icon icon-trash',
                    do: this.handleRemoveSubnet,
                },
            ],
            multipleactions: [
                {
                    title: 'Delete',
                    do: rows => {
                        rows.forEach(this.handleRemoveSubnet);

                        return true;
                    },
                },
            ],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
            props: {
                subnets: this.availableSubnetOptions,
            },
        };
    }

    /** @override */
    $onChanges(changes) {
        if (sample(changes).isFirstChange()) {
            return;
        }

        if (changes.availableSubnets) {
            this.setAvailableSubnetOptions(changes.availableSubnets.currentValue);
        }
    }

    /**
     * Removes a subnet row consisting of a v4 and/or a v6 subnet.
     */
    handleRemoveSubnet = subnet => {
        this.onRemoveSubnet({ subnet });
    }

    /**
     * Returns dropdown options for usable subnet in infoblox configuration section.
     * @param {Object[]?} subnets - Subnet list JSON data.
     * @return {DropDownOption[]} - Dropdown option objects.
     */
    createSubnetOptions(subnets = []) {
        return subnets.map(subnet => {
            const label = this.getSubnetString_(subnet);

            return this.dropDownUtils_.createOption(label);
        });
    }

    /**
     * Sets this.availableSubnetOptions with subnet dropdown options.
     * @param {AvailableInfobloxSubnets} availableSubnets - Hash containing lists of v4 and v6
     *     subnets.
     */
    setAvailableSubnetOptions(availableSubnets) {
        const { v4 = [], v6 = [] } = availableSubnets;

        this.availableSubnetOptions.v4 = this.createSubnetOptions(v4);
        this.availableSubnetOptions.v6 = this.createSubnetOptions(v6);
    }

    /**
     * Returns true if usable subnets exist.
     * @returns {boolean}
     */
    hasUsableSubnetsOptions() {
        const { v4 = [], v6 = [] } = this.availableSubnetOptions;

        return v4.length > 0 || v6.length > 0;
    }
}

IpamInfobloxUsableSubnetsConfigComponent.$inject = [
    'getSubnetString',
    'dropDownUtils',
];

/**
 * @name ipamInfobloxUsableSubnetsConfig
 * @memberOf module: avi/app
 * @property {module:avi/app.IpamInfobloxUsableSubnetsConfigComponent} controller
 * @property {module:avi/app.ipamInfobloxUsableSubnetsConfigBindings} bindings
 * @desc  Component for configuring Infoblox usable subnets.
 * @author alextg
 */
angular.module('avi/app').component('ipamInfobloxUsableSubnetsConfig', {
    /**
     * @mixin ipamInfobloxUsableSubnetsConfigBindings
     * @memberOf module:avi/app
     * @property {InfobloxSubnet[]} configuredSubnets - List of configured subnets.
     * @property {AvailableInfobloxSubnets} availableSubnets - Available usable subnets for the user
     *     to add.
     * @property {Function} onAddSubnet - Called when adding a subnet row.
     * @property {Function} onRemoveSubnet - Called to remove a subnet row.
     */
    bindings: {
        configuredSubnets: '<',
        availableSubnets: '<',
        onAddSubnet: '&',
        onRemoveSubnet: '&',
    },
    controller: IpamInfobloxUsableSubnetsConfigComponent,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles/' +
        'ipam-dns-profiles-modal/ipam-infoblox-usable-subnets-config/' +
        'ipam-infoblox-usable-subnets-config.component.html',
});
