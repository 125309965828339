/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @description
 *     Pool AutoScale Policy collection.
 */
angular.module('aviApp').factory('AutoScalePolicyCollection', ['Collection', 'AutoScalePolicyItem',
function(Collection, AutoScalePolicyItem) {
    const AutoScalePolicyCollection = function(oArgs) {
        AutoScalePolicyCollection.superconstructor.call(this, oArgs);
        this.objectName_ = 'serverautoscalepolicy';
        this.itemClass_ = AutoScalePolicyItem;
        this.windowElement_ = 'app-pool-autoscale-policy';
    };

    avi.inherit(AutoScalePolicyCollection, Collection);

    return AutoScalePolicyCollection;
}]);

/**
 * @ngdoc service
 * @description
 *     Pool AutoScale Policy collection item.
 */
angular.module('aviApp').factory('AutoScalePolicyItem', ['Item', function(Item) {
    const AutoScalePolicyItem = function(oArgs) {
        AutoScalePolicyItem.superconstructor.call(this, oArgs);
        this.objectName = 'serverautoscalepolicy';
        this.windowElement = 'app-pool-autoscale-policy';
    };

    avi.inherit(AutoScalePolicyItem, Item);

    /**
     * @override
     */
    AutoScalePolicyItem.prototype.dataToSave = function() {
        const config = angular.copy(this.data.config);

        if (!config.intelligent_autoscale) {
            config.intelligent_scaleout_margin = undefined;
            config.intelligent_scalein_margin = undefined;
        }

        return config;
    };

    return AutoScalePolicyItem;
}]);
