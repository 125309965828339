/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PersistenceProfile
 */
angular.module('aviApp').factory('PersistenceProfile', [
'Item',
function(Item) {
    class PersistenceProfile extends Item {
        /**
         * Returns profile type as enum value.
         * @returns {string}
         * @public
         */
        getType() {
            return this.getConfig()['persistence_type'];
        }

        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                actualType = this.getType();

            _.each(PersistenceProfile.typeToConfigPropNameHash,
                (propName, type) => {
                    if (type !== actualType) {
                        delete config[propName];
                    }
                });

            this.setIsFederatedFlag_();

            return config;
        }

        /**
         * Event handler for type change event. Used by edit modal.
         * @public
         */
        onTypeChange() {
            const config = this.getConfig();

            PersistenceProfile.allConfigPropNames
                .forEach(propName => delete config[propName]);

            const
                defaultConfig = this.getDefaultConfig_(),
                type = this.getType();

            const propName = PersistenceProfile.typeToConfigPropNameHash[type];

            config[propName] = defaultConfig[propName];

            this.setIsFederatedFlag_();
        }

        /**
         * Resets 'is_federated' field to false for all types but GSLB.
         * @private
         */
        setIsFederatedFlag_() {
            this.getConfig()['is_federated'] = this.getType() === 'PERSISTENCE_TYPE_GSLB_SITE';
        }
    }

    angular.extend(PersistenceProfile.prototype, {
        objectName: 'applicationpersistenceprofile',
        windowElement: 'persistence-profile-modal',
    });

    /**
     *
     * @type {{string: string}}
     * @static
     */
    PersistenceProfile.typeToConfigPropNameHash = {
        PERSISTENCE_TYPE_CLIENT_IP_ADDRESS: 'ip_persistence_profile',
        PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER: 'hdr_persistence_profile',
        PERSISTENCE_TYPE_APP_COOKIE: 'app_cookie_persistence_profile',
        PERSISTENCE_TYPE_HTTP_COOKIE: 'http_cookie_persistence_profile',
    };

    /**
     * @type {string[]}
     * @static
     */
    PersistenceProfile.allConfigPropNames = _.values(PersistenceProfile.typeToConfigPropNameHash);

    return PersistenceProfile;
}]);
