/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    UpdateCardAboutComponent,
} from './components/update-card-about';

const updateComponents = [
    UpdateCardAboutComponent,
];

/**
 * Module containing all upgrade stuff (angular only).
 */
@NgModule({
    declarations: [
        ...updateComponents,
    ],
    entryComponents: [
        UpdateCardAboutComponent,
    ],
    exports: [
        ...updateComponents,
    ],
    imports: [
        CommonModule,
    ],
})
export class UpdateModule { }
