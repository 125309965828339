/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import classnames from 'classnames';
import './state-button.less';

const componentTag = 'state-button';
const MAIN_MENU_TYPE = 'MAIN_MENU';
const CATEGORY_TYPE = 'CATEGORY';

const typeToClassName = {
    [MAIN_MENU_TYPE]: `${componentTag}--main-menu`,
    [CATEGORY_TYPE]: `${componentTag}--category`,
};

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @mixes module:avi/navigation.stateButtonBindings
 * @see {@link  module:avi/navigation.stateButtonComponent}
 */
class StateButtonController {
    constructor($state, $element) {
        this.$state_ = $state;
        this.$element_ = $element;
    }

    /**
     * Basically parses the state string and splits up the state name from the params. This logic is
     * duplicated from ui-router.
     * @param {string} stateRef - State string
     * @returns {Object} State reference object.
     */
    static getStateRefObject(stateRef) {
        let ref = stateRef;
        const paramsOnly = stateRef.match(/^\s*({[^}]*})\s*$/);

        if (paramsOnly) {
            ref = `(${paramsOnly[1]})`;
        }

        const parsed = ref.replace(/\n/g, ' ').match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);

        if (!parsed || parsed.length !== 4) {
            throw new Error(`Invalid state ref ${ref}`);
        }

        return {
            state: parsed[1] || null,
            paramExpr: parsed[3] || null,
        };
    }

    /**
     * Returns the context or parent level of the state. This logic is duplicated from ui-router.
     * @returns {string}
     */
    getStateContext() {
        const $uiView = this.$element_.parent().inheritedData('$uiView');
        const path = $uiView && $uiView.$cfg && $uiView.$cfg.path;

        return Array.isArray(path) && path[path.length - 1].state.name;
    }

    /**
     * Returns true if any of the activeStates or the current state is active.
     * @returns {boolean}
     */
    isActive() {
        if (Array.isArray(this.activeStates)) {
            return _.any(this.activeStates, state => this.$state_.includes(state));
        } else {
            const stateRefObject = StateButtonController.getStateRefObject(this.state);
            const stateObject = this.$state_.get(stateRefObject.state, this.getStateContext());

            return this.$state_.includes(stateObject.name);
        }
    }

    /**
     * Returns a string of classnames.
     * @returns {string}
     */
    getClassName() {
        const typeClassName = typeToClassName[this.type];

        return classnames(
            componentTag,
            typeClassName,
            this.isActive() && `${typeClassName}--active`,
        );
    }
}

StateButtonController.$inject = [
    '$state',
    '$element',
];

/**
 * @name stateButtonComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.StateButtonController} controller
 * @property {module:avi/navigation.stateButtonBindings} bindings
 * @property {boolean} transclude true
 * @description
 *     A button used to navigate to a particular state, and adds an active class if its
 *     state is active. An optional activeStates binding can be passed if the state is considered
 *     active based on other states besides its own. For example, the Virtual Services button in the
 *     header should be shown as active if the user is on both the Virtual Services List page and
 *     the Virtual Service detail page of a specific Virtual Service.
 * @author alextsg
 */
angular.module('avi/navigation').component('stateButton', {
    transclude: true,
    /**
     * @mixin stateButtonBindings
     * @memberOf module:avi/navigation
     * @property {string} type Type of the state button. Used for styling purposes
     * @property {string[]} activeStates An array of state names to be checked if currently active
     *     If passed in, overrides checking if the "state" is active.
     * @property {string} state State to navigate to if the button is clicked,
     *     and used to check if currently active.
     *     If "activeStates" is passed in, those states will be used to check if active instead.
     */
    bindings: {
        type: '@',
        activeStates: '<?',
        state: '@',
    },
    controller: StateButtonController,
    templateUrl: `src/components/${componentTag}/${componentTag}.html`,
});
