/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ServerHealthMonitorCollection
 * @description Health monitors {@link Collection} for the {@link Pool Pool's} {@link Server
 * Servers}.
 */
angular.module('aviApp').factory('ServerHealthMonitorCollection', [
    'Collection', 'ServerHealthMonitor',
    function(Collection, ServerHealthMonitor) {
        const dataSources = {
            list: {
                source: 'ServerHealthScoreMonitorListCollDataSource',
                transformer: 'ServerHealthScoreMonitorListDataTransformer',
                transport: 'ServerHealthScoreMonitorListDataTransport',
                fields: ['config'],
            },
        };

        class ServerHealthMonitorCollection extends Collection {
            constructor(args = {}) {
                if (angular.isUndefined(args.poolId) ||
                    angular.isUndefined(args.serverIp) ||
                    angular.isUndefined(args.serverPort)) {
                    throw new Error('Can\'t work with ServerHealthMonitorCollection without' +
                        ' poolId, server ip or port.');
                }

                super(args);

                /**
                 * @type {string} Pool id of a Pool this Server belongs to.
                 */
                this.poolId = args.poolId;

                /**
                 * @type {string} Server IP address.
                 */
                this.serverIp = args.serverIp;

                /**
                 * @type {string} Server port.
                 */
                this.serverPort = `${args.serverPort}`;
            }

            /** @override */
            isCreatable() {
                return false;
            }
        }

        angular.extend(ServerHealthMonitorCollection.prototype, {
            objectName_: 'server-health-monitors',
            itemClass_: ServerHealthMonitor,
            allDataSources_: dataSources,
            defaultDataSources_: 'list',
            isStatic_: false,
        });

        return ServerHealthMonitorCollection;
    }]);
