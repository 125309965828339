/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AlertConfig
 */
angular.module('aviApp').factory('AlertConfig', ['Item', 'defaultValues',
function(Item, defaultValues) {
    function AlertConfig(oArgs) {
        AlertConfig.superconstructor.call(this, oArgs);
    }

    avi.inherit(AlertConfig, Item);

    AlertConfig.prototype.objectName = 'alertconfig';
    AlertConfig.prototype.windowElement = 'adm-alertconfig-create';

    AlertConfig.prototype.beforeEdit = function() {
        const { config } = this.data;

        if (!config.source) {
            config.source = 'EVENT_LOGS';
        }

        if (!config.alert_rule) {
            config.alert_rule = {
                operator: 'OPERATOR_AND',
            };
        }

        if (config.alert_rule.sys_event_rule) {
            const alert = config.alert_rule;

            if (Array.isArray(alert.sys_event_rule) && alert.sys_event_rule.length === 2) {
                if (alert.sys_event_rule[1].not_cond === true) {
                    alert.operator = 'OPERATOR_NOT';
                }
            }
        } else {
            config.alert_rule.sys_event_rule = [
                defaultValues.getDefaultItemConfigByType('alertruleevent'),
            ];
        }

        if (!config.alert_rule.metrics_rule) {
            config.alert_rule.metrics_rule = [
                defaultValues.getDefaultItemConfigByType('alertrulemetric'),
            ];
        }

        if (angular.isUndefined(config.object_type)) {
            config.object_type = 'none';
        }
    };

    AlertConfig.prototype.dataToSave = function() {
        const config = angular.copy(this.data.config);

        if (config.source === 'EVENT_LOGS') {
            const alert = config.alert_rule;

            if (alert.operator === 'OPERATOR_NOT') {
                alert.operator = 'OPERATOR_AND';
                alert.sys_event_rule[1].not_cond = true;
            } else {
                alert.sys_event_rule.forEach(function(rule) {
                    rule.not_cond = false;
                });
            }

            alert.sys_event_rule = alert.sys_event_rule.filter(function(rule) {
                return !_.isUndefined(rule.event_id);
            });
        } else {
            config.alert_rule.sys_event_rule = undefined;
        }

        if (config.source !== 'METRICS') {
            config.alert_rule.metrics_rule = undefined;
        }

        if (config.object_type === 'none') {
            delete config.object_type;
        }

        return config;
    };

    return AlertConfig;
}]);
