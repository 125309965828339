/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('RoleCollection', ['Collection', 'Role',
function(Collection, Role) {
    const RoleCollection = class extends Collection {
        constructor(oArgs) {
            super(oArgs);
            this.oArgs = oArgs || {};
        }

        isCreatable() {
            return 'isCreatable' in this.oArgs ?
                !!this.oArgs.isCreatable : super.isCreatable();
        }
    };

    angular.extend(RoleCollection.prototype, {
        objectName_: 'role',
        itemClass_: Role,
        windowElement_: 'adm-role-create',
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'RoleListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        serverDefaultsOverride_: {
            name: '',
        },
    });

    return RoleCollection;
}]);
