/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/modal/app-vs-secure-app.less';

/**
 * @ngdoc controller
 * @name VirtualServiceSecureAppController
 * @description This modal window allows user to create and update a set (just two) of Network
 * Security Policy rules to allow traffic flow within a defined set of microservices and
 * this VS. We use a bunch of different objects here. VS has reference to the policy rules -
 * one of them keeps a reference to the MicroServiceGroup which in it's turn keeps a list
 * of MicroService uuids. Need to combine all of these and support different situations when
 * one thing is present but other is not. By default (when VS doesn't have such rules) we
 * put all MicroServices talking with this VS into suggested white list. Black list is made by
 * taking the whole list of MicroServices in a system and excluding all MSes from the white list.
 * Black list is never saved anywhere - only white does (as a list of MS uuid for MS group).
 *
 * We use two rules here: one with higher priority to allow traffic flow within white list and
 * another one with slightly lower priority - to deny traffic from(to) all other destinations.
 **/

//TODO fix confirmation on Modal close
//TODO create a service
//TODO add error handlers for async calls
//TODO switch from VS update into policy update

angular.module('aviApp').controller('VirtualServiceSecureAppController', [
'$scope', '$q', 'MicroServiceCollection', 'MicroServiceGroupCollection', 'MicroServiceGroup',
'MicroServiceGraphFactory', 'NetworkSecurityPolicy',
function($scope, $q, MicroServiceCollection, MicroServiceGroupCollection, MicroServiceGroup,
         MicroServiceGraphFactory, NetworkSecurityPolicy) {
    $scope.$parent.modalScope = $scope;//aviModal thing

    /**
     * Name of MicroService Group for the 'Secure App' allow rule will be made of this prefix
     * and current VS name.
     * @type {string}
     * @inner
     */
    const msGroupPrefix = 'vs-msg-';

    const [allowRuleIndex, denyRuleIndex] = NetworkSecurityPolicy.secAppRuleIndexes;

    /**
     * Template for the allow Network Security Policy 'Secure App' rule.
     * @type {{
     *     name: string,
     *     enable: boolean,
     *     action: string,
     *     match: {
     *         microservice: {
     *             match_criteria: string,
     *             group_ref: undefined|string
     *         }
     *     }
     * }}
     * @inner
     */
    const allowRuleDefaultConfig = {
        name: 'Secure Application allow microService Group rule from UI',
        enable: true,
        action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
        match: {
            microservice: {
                match_criteria: 'IS_IN',
                group_ref: undefined,
            },
        },
        index: allowRuleIndex,
    };

    /**
     * Template for the deny Network Security Policy 'Secure App' rule.
     * @type {{
     *     name: string,
     *     enable: boolean,
     *     action: string,
     *     match: {
     *         client_ip: {
     *             match_criteria: string,
     *             prefixes: Object[]
     *         }
     *     }
     * }}
     * @inner
     */
    const denyRuleDefaultConfig = {
        name: 'Secure Application deny all rule from UI',
        enable: true,
        action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_DENY',
        match: {
            client_ip: {
                match_criteria: 'IS_IN',
                prefixes: [{
                    ip_addr: {
                        addr: '0.0.0.0',
                        type: 'V4',
                    },
                    mask: 0,
                }],
            },
        },
        index: denyRuleIndex,
    };

    /**
     * Collection of all MicroServices present in a system.
     * @type {MicroServiceCollection}
     */
    const msCollection = new MicroServiceCollection({ limit: 1000 });

    /**
     * Representation controller's object having properties to make selector work.
     * @type {Object}
     * @property {string|undefined} selectedUuid
     * @property {string|undefined} selectedList
     * @property {Object[]} whiteList
     * @property {string} whiteList[].uuid
     * @property {string} whiteList[].name
     * @property {Object[]} blackList
     * @property {string} blackList[].uuid
     * @property {string} blackList[].name
     * @property {Object|string|undefined} error
     */
    this.ui = {
        selectedUuid: undefined,
        selectedList: undefined, //white or black
        whiteList: [],
        blackList: [],
        error: undefined,
    };
    /**
     * Will show spinner and no actual modal UI elements until all initialization is complete.
     * @type {boolean}
     */
    this.busy = true;

    /**
     * Builds function to be used by from list/to list buttons. Partial application design
     * pattern.
     * @param {string} moveToColor
     * @returns {Function}
     * @inner
     */
    function moveTo(moveToColor) {
        const
            moveFromColor = moveToColor === 'white' ? 'black' : 'white',
            moveToArrName = `${moveToColor}List`,
            moveFromArrName = `${moveFromColor}List`;

        return function() {
            const
                moveFromArr = this[moveFromArrName],
                moveToArr = this[moveToArrName];

            if (this.selectedUuid && this.selectedList === moveFromColor) {
                const index = _.findIndex(
                    moveFromArr, ms => ms.uuid === this.selectedUuid,
                );

                moveToArr.push(moveFromArr[index]);
                moveFromArr.splice(index, 1);

                if (moveFromArr.length) {
                    this.selectedUuid = moveFromArr[0].uuid;
                } else {
                    this.selectedUuid = undefined;
                    this.selectedList = undefined;
                }
            } else {
                throw new Error(`No ${moveFromColor} List item is selected`);
            }
        };
    }

    this.ui.moveToBlack = moveTo('black');
    this.ui.moveToWhite = moveTo('white');

    this.ui.selectListItem = function(uuid, list) {
        this.selectedUuid = uuid;
        this.selectedList = list;
    };

    this.ui.isListItemSelected = function(uuid, list) {
        return this.selectedUuid && this.selectedList &&
            uuid === this.selectedUuid && this.selectedList === list;
    };

    let allowRule,
        denyRule;

    this.init_ = function() {
        const vs = $scope.editable;

        const rules = vs.networkSecurityPolicy.getRules();

        allowRule = _.findWhere(rules, { index: allowRuleIndex });
        denyRule = _.findWhere(rules, { index: denyRuleIndex });

        if (!allowRule) {
            allowRule = allowRuleDefaultConfig;
            rules.push(allowRule);
        }

        if (!denyRule) {
            denyRule = denyRuleDefaultConfig;
            rules.push(denyRule);
        }

        const msGroupRef = allowRule.match.microservice.group_ref;

        let msGroupPromise;

        if (!msGroupRef) { //had no group ID or rule at all
            const msGroupCollection = new MicroServiceGroupCollection({ limit: 1000 });

            msGroupPromise = msGroupCollection.search(msGroupPrefix + vs.getName())
                .then(() => {
                    return msGroupCollection.items.length ? msGroupCollection.items[0] :
                        msGroupCollection.createNewItem();
                });
        } else {
            const msGroup = new MicroServiceGroup({ id: msGroupRef.slug() });

            msGroupPromise = msGroup.load().then(() => msGroup);
        }

        const whiteListPromise =
            $q.all([
                msGroupPromise,
                msCollection.load().then(() => msCollection),
            ])
                .then(this.generateWhiteList_.bind(this));

        return whiteListPromise
            .then(whiteList => {
                const
                    blackList = [],
                    whiteListHash = {};

                this.ui.whiteList = whiteList;

                whiteList.forEach(({ uuid }) => whiteListHash[uuid] = true);

                msCollection.items.forEach(ms => {
                    if (!(ms.id in whiteListHash)) {
                        blackList.push({
                            uuid: ms.id,
                            name: ms.getName(),
                        });
                    }
                });

                this.ui.blackList = blackList;
                $scope.editable.setPristine();
            })
            .finally(() => this.busy = false);
    };

    $scope.init = this.init_.bind(this);

    /**
     * Returns a list of white listed microservices.
     * @param {MicroServiceGroup} msGroup
     * @param {MicroServiceCollection} msCollection
     * @returns {ng.$q.promise} to be resolved with {{uuid: string, name: string}[]}
     * @private
     */
    this.generateWhiteList_ = function([msGroup, msCollection]) {
        this.msGroup = msGroup;

        if (msGroup.id) { //when we had group
            return _.map(msGroup.getConfig().service_refs, msRef => ({
                uuid: msRef.slug(),
                name: msRef.name(),
            }));
        } else { //put all active MicroServices into white list as a suggestion
            return this.getAllActiveMicroServices_(msCollection);
        }
    };

    /**
     * When creating a new MicroService group we want to include all active MSes talking to this
     * VS right now.
     * @param {MicroServiceCollection} msCollection
     * @returns {ng.$q.promise} to be resolved with {{uuid: string, name: string}[]}
     * @private
     */
    this.getAllActiveMicroServices_ = function(msCollection) {
        const activeMSParams = {
            microservice_levels: 1,
            metric_id: $scope.graphMetric || 'source_insights.avg_bandwidth',
        };

        if ($scope.mValuesAggregation) {
            if ($scope.mValuesAggregation !== 'current') {
                activeMSParams.dimension_aggregation = $scope.mValuesAggregation;
            } else {
                activeMSParams.limit = 1;
            }
        } else {
            activeMSParams.dimension_aggregation = 'avg';
        }

        const activeMicroServices = new MicroServiceGraphFactory({
            params: activeMSParams,
            nodeGroups: true,
        });

        return activeMicroServices.load($scope.editable.id)
            .then(() => {
                const res = [];

                activeMicroServices.nodes.forEach(node => {
                    if ((node.obj_type === 'virtualservice' ||
                        node.obj_type === 'microservice') &&
                        (node.groupId === 'client' || node.groupId === 'both')) {
                        const msId = node.microservice_uuid;

                        res.push({
                            uuid: msId,
                            name: msId in msCollection.itemById &&
                                msCollection.itemById[msId].getName() || msId,
                        });
                    }
                });

                return res;
            });
    };

    /**
     * Saves or creates a microservice group which gonna be referenced by VS Secure App allow rule.
     * @param {boolean} hasSecureAppRule
     * @returns {ng.$q.promise}
     * @private
     */
    this.msGroupSave_ = function(hasSecureAppRule) {
        const
            { msGroup, ui } = this,
            msGroupConfig = msGroup.getConfig();

        msGroupConfig.service_refs = _.pluck(ui.whiteList, 'uuid');

        if (!hasSecureAppRule) {
            msGroupConfig.name = msGroupPrefix + $scope.editable.getName();
            msGroupConfig.description = 'Group created by my Secure My App UI.';
        }

        return msGroup.save().then(() => msGroup.id);
    };

    /**
     * Sets a ref to the microservice group for allow rule and saves VS.
     * @param {string} msGroupId
     * @return {ng.$q.promise}
     * @private
     */
    this.VSSave_ = function(msGroupId) {
        allowRule.match.microservice.group_ref = msGroupId;

        return $scope.editable.save();
    };

    /**
     * There four combinations here - whether we had group and rule before save or not. If group
     * wasn't present we need to save it first to set microservice.group_ref, update policy and
     * save VS afterwards. If VS already had secure app policy we don't need to save it at all.
     * @returns {ng.$q.promise}
     * @public
     */
    this.save = function() {
        const
            hasSecureAppRule = !!allowRule.match.microservice.group_ref,
            msGroupId = this.msGroup.id;//had MicroService group before

        const promises = [];

        if (msGroupId) {
            promises.push(
                this.msGroupSave_(hasSecureAppRule),
            );
        }

        if (!hasSecureAppRule) {
            //when we don't have msGroup id we need to save msGroup first to save VS later on
            if (!msGroupId) {
                promises.push(
                    this.msGroupSave_(hasSecureAppRule)
                        .then(msGroupUuid => this.VSSave_(msGroupUuid)),
                );
            } else {
                promises.push(
                    this.VSSave_(msGroupId),
                );
            }
        }

        return $q.all(promises).then($scope.closeModal.bind($scope));
    };

    $scope.$on('$destroy', () => {
        [this.msGroup, msCollection].forEach(coll => coll && coll.destroy());
    });
}]);
