/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-psm-match-element-config.less';

/**
 * @alias waf/wafPsmMatchElementConfig
 * @private
 */
class WafPsmMatchElementConfigComponent {
    constructor(dropDownUtils) {
        this.wafVariableOptions = dropDownUtils.getEnumDropdownOptions('WafVariable');
    }

    /**
     * Adds a match element for configuration.
     */
    addMatchElement() {
        this.rule.addMatchElement();
    }

    /**
     * Removes a match element.
     * @param {number} index - Index of the match element to remove.
     */
    removeMatchElement(index) {
        const { match_elements: matchElements } = this.rule.config;

        matchElements.remove(index);
    }
}

WafPsmMatchElementConfigComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name wafPsmMatchElementConfig
 * @module waf/wafPsmMatchElementConfig
 * @desc Component for editing a WAF PSM location argument rule's match elements.
 * @param {WafPolicyPSMRule} rule
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPsmMatchElementConfig', {
    bindings: {
        rule: '<',
        preventEdit: '<',
    },
    controller: WafPsmMatchElementConfigComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-psm-match-element-config/waf-psm-match-element-config.component.html',
});
