/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  userProfileCreate
 * @param {UserProfile} editable
 * @param {Function} closeModal
 * @description
 *     Component used to create and edit a UserProfile item.
 */
class UserProfileCreateController {
    constructor(Regex, Schema) {
        this.Regex = Regex;
        this.Schema = Schema;
    }
}

UserProfileCreateController.$inject = [
        'Regex',
        'Schema',
];

angular.module('aviApp').component('userProfileCreate', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: UserProfileCreateController,
    templateUrl:
        'src/components/modals/administration/accounts/user-profile/user-profile-create.html',
});
