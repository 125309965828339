/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('PoolCreateController', [
'$scope', '$q', 'Schema', 'Regex', 'SSLProfileCollection', 'PKIProfileCollection',
'CertificateCollection', 'VMNicIPCollection', 'PersistenceProfileCollection',
'CloudCollection', 'VirtualService', 'IpAddrGroupCollection', 'SubnetListNetworkCollection',
'$filter', 'AutoScalePolicyCollection', 'AutoScaleLaunchConfigCollection',
'AnalyticsProfileCollection', 'poolServersLimit', 'Cloud', 'Server',
'$window', 'getSubnetString', 'schemaService', 'dropDownUtils',
function($scope, $q, Schema, Regex, SSLProfileCollection, PKIProfileCollection,
CertificateCollection, VMNicIPCollection, PersistenceProfileCollection,
CloudCollection, VirtualService, IpAddrGroupCollection, SubnetListNetworkCollection,
$filter, AutoScalePolicyCollection, AutoScaleLaunchConfigCollection,
AnalyticsProfileCollection, poolServersLimit, Cloud, Server,
$window, getSubnetString, schemaService, dropDownUtils) {
    $scope.Schema = Schema;
    $scope.Regex = Regex;
    $scope.forms = {};

    // Used in select servers panel as a dropdown field
    $scope.networks = new SubnetListNetworkCollection({
        params: {
            discovered_only: true,
        },
    });
    $scope.cloudCollection = new CloudCollection({
        isStatic: true,
        params: {
            join: 'ipamdnsproviderprofile:ipam_provider_ref',
        },
    });
    $scope.sslProfileCollection = new SSLProfileCollection();
    $scope.pkiProfileCollection = new PKIProfileCollection();

    $scope.certificateCollection = new CertificateCollection({
        params: {
            type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
        },
    });

    $scope.persistentProfileCollection = new PersistenceProfileCollection();

    const serverCollection = new VMNicIPCollection();

    $scope.ipAddrGroupCollection = new IpAddrGroupCollection();
    $scope.autoScalePolicyCollection = new AutoScalePolicyCollection();
    $scope.autoScaleLaunchConfigCollection = new AutoScaleLaunchConfigCollection();
    $scope.analyticsProfileCollection = new AnalyticsProfileCollection();

    $scope.$parent.modalScope = $scope;//aviModal thing

    // Do not allow LB_ALGORITHM_TOPOLOGY in the dropdownlist for Pool
    $scope.lbAlgorithmOptions = schemaService.getEnumValues('LbAlgorithm')
        .filter(item => item.value !== 'LB_ALGORITHM_TOPOLOGY')
        .map(({ value, label, description }) =>
            dropDownUtils.createOption(value, label, description));

    // Wizard definition
    $scope.wizard = {
        steps: [{
            title: 'Settings',
        }, {
            title: 'Servers',
        }, {
            title: 'Advanced',
        }, {
            title: 'Review',
        }],
        beforeChange: () => $scope.editable.errors = '',
    };

    $scope.tab = 0;

    /* =============
       SERVERS STUFF
       ============= */
    //TODO for most part all methods, functions and grid configs should be shared between
    //PoolCreate and VSBasicCreate

    /**
     * Within the pool we don't allow multiple unresolved domain names (with IP equal to
     * 0.0.0.0). That's why we ignore hostname part here.
     * @param {ServerConfig} server
     * @returns {string}
     * @inner
     */
    const getServerId = function(server) {
        const { default_server_port } = $scope.editable.getConfig();

        return Server.getServerUuid(
            angular.extend({}, server, {
                hostname: server.ip.addr,
                default_server_port,
            }),
        );
    };

    const disableServer = server => server.enabled = false;

    $scope.networkServersGrid = {
        permission: 'PERMISSION_POOL',
        collection: serverCollection,
        fields: [{
            name: 'data.config.hostname',
            title: 'Server',
        }, {
            name: 'data.config.ip.addr',
            title: 'IP Address',
        }, {
            name: 'data.config.mac',
            title: 'MAC Address',
        }],
        searchFields: ['data.config.hostname', 'data.config.ip.addr', 'data.config.mac'],
        multipleactions: [{
            title: 'Add Servers',
            className: 'btn-primary sel-add-servers-by-nw',
            do: servers => {
                addServers(
                    servers.map(server => {
                        const config = server.getConfig();

                        //vm name is not a DNS name
                        config['resolve_server_by_dns'] = false;

                        return config;
                    }),
                );

                $scope.closeNetworkPanel();

                return true;
            },
            bypassPermissionsCheck: true,
        }],
        checkboxDisable: server => getServerId(server.getConfig()) in $scope.serversHash,
        layout: {
            hideEditColumns: true,
            lengthAsTotal: true,
        },
    };

    $scope.serversGrid = {
        fields: [{
            title: 'Status',
            name: 'status',
            template: '{{row.enabled | booleanLabel: "enabled" }}',
            sortBy: 'status',
        }, {
            name: 'hostname',
            title: 'Server Name',
            template: `<input
                class="sel-host"
                type="text"
                ng-pattern="::Regex.anyIPIPPortHostname"
                ng-model="row.hostname"
                ng-disabled="ngDisabled"/>`,
            sortBy: 'hostname',
        }, {
            name: 'resolve_server_by_dns',
            title: 'Resolve by DNS',
            template: '<checkbox ng-model="row.resolve_server_by_dns"></checkbox>',
        }, {
            name: 'ip',
            title: 'IP Address',
            template: `<input
                type="text"
                ng-model="row.ip.addr"
                ng-model-options="{debounce: 500}"
                change-and-validate="config.options.checkServerValidity(row, 'ip', newValue)"
                required
                class="sel-server-ip"
                placeholder="IP Address"
                ng-disabled="ngDisabled || row.resolve_server_by_dns"/>`,
            sortBy: 'ip.addr',
        }, {
            name: 'port',
            title: 'Port',
            template: `<input
                type="number"
                class="sel-server-port"
                ng-model="row.port"
                ng-model-options="{debounce: 500}"
                placeholder="Inherit"
                change-and-validate="config.options.checkServerValidity(row, 'port', newValue)"
                ng-disabled="ngDisabled"/>`,
            sortBy: 'port',
        }, {
            name: 'ratio',
            title: 'Ratio',
            template: `<input
                type="number"
                min="1" max="20"
                ng-pattern="Regex.integer"
                class="sel-server-ratio"
                ng-model="row.ratio"
                ng-disabled="ngDisabled"/>`,
            sortBy: 'ratio',
        }, {
            title: 'Network',
            name: 'network',
            transform: ({ nw_ref: nwRef }) => {
                return nwRef ? nwRef.name() : '';
            },
        }, {
            title: 'Header Value',
            name: 'hdrVal',
            template: `<input
                type="text"
                class="sel-prst-hdr-val"
                ng-model="row.prst_hdr_val"
                placeholder="Header Value"
                ng-disabled="ngDisabled" />`,
        }, {
            title: 'Rewrite Host Header',
            name: 'rewrite_host_header',
            template: '<checkbox ng-model="row.rewrite_host_header"></checkbox>',
        }],
        rowId: getServerId,
        searchFields: ['hostname', 'ip.addr', 'port', 'nw_ref.name()'],
        multipleactions: [{
            title: 'Remove',
            do: serversToDrop => {
                const
                    hashToDrop = {},
                    { serversHash: hash } = $scope,
                    config = $scope.editable.getConfig(),
                    { servers } = config;

                serversToDrop.forEach(server => hashToDrop[getServerId(server)] = true);

                config.servers = _.reject(servers, server => {
                    const
                        serverId = getServerId(server),
                        toBeDropped = serverId in hashToDrop;

                    if (toBeDropped) {
                        delete hash[serverId];
                    }

                    return toBeDropped;
                });

                return true;
            },
        }, {
            title: 'Enable',
            className: 'sel-enable-server icon-check',
            disabled(servers) {
                return !_.find(servers, function(server) {
                    return !server.enabled;
                });
            },
            do(servers) {
                _.each(servers, function(server) {
                    delete server.oper_status;
                    server.enabled = true;
                });

                return true;
            },
        }, {
            title: 'Disable',
            className: 'sel-disable-server icon-minus',
            disabled(servers) {
                return !_.find(servers, function(server) {
                    return server.enabled;
                });
            },
            do(servers) {
                _.each(servers, function(server) {
                    delete server.oper_status;
                    disableServer(server);
                });

                return true;
            },
        }],
        rowClass(row) {
            return !row.enabled ? 'disabled' : '';
        },
        checkboxDisable(row) {
            return !!row.checkboxDisable;
        },
        options: {
            checkServerValidity,
        },
    };

    $scope.disabledServersGrid = {
        fields: [{
            name: 'enabled',
            title: 'Status',
            template: '{{ row.enabled | booleanLabel : "enabled" }}',
            visibility: 'm',
        }, {
            name: 'ip.addr',
            title: 'IP Address',
        }],
        rowId: getServerId,
        multipleactions: [{
            title: 'Enable',
            className: 'sel-enable-server icon-check',
            disabled: servers => {
                return _.every(servers, ({ enabled }) => enabled);
            },
            do: servers => {
                servers.forEach(server => {
                    delete server.oper_status;
                    server.enabled = true;
                });

                return true;
            },
        }, {
            title: 'Disable',
            className: 'sel-disable-server icon-minus',
            disabled: servers => {
                return _.every(servers, ({ enabled }) => !enabled);
            },
            do: servers => {
                servers.forEach(server => {
                    delete server.oper_status;
                    disableServer(server);
                });

                return true;
            },
        }],
        searchFields: ['ip.addr'],
    };

    /**
     * Removes all servers from the list and flushes hash.
     * @inner
     */
    const deletePoolServers = () => {
        $scope.serversHash = {};
        $scope.editable.data.config.servers.length = 0;
    };

    /**
     * We need to update server hashes on initialization or pool port change event.
     * @public
     */
    $scope.serversOnInit = function() {
        const { servers } = $scope.editable.getConfig();

        //we need to maintain server id (ip:port) uniqueness within one pool
        $scope.serversHash = {};

        servers.forEach(server => $scope.serversHash[getServerId(server)] = true);
    };

    /**
     * Adds ServerConfig to the list of servers. Checks for uniqueness and total server number.
     * @param {ServerConfig} server
     * @inner
     */
    const addServer = server => {
        const
            { editable, serversHash: hash } = $scope,
            { servers } = editable.getConfig(),
            newServer = angular.extend({
                enabled: true,
                ratio: 1,
                prst_hdr_val: '',
                resolve_server_by_dns: !!server.hostname,
            }, server),
            serverId = getServerId(newServer);

        if (!server.hostname) {
            server.hostname = server.ip.addr;
        }

        if (servers.length < poolServersLimit) {
            if (!(serverId in hash)) {
                servers.push(newServer);
                hash[serverId] = true;
            } else {
                if (!angular.isString(editable.errors)) {
                    editable.errors = '';
                }

                editable.errors += `Can't add server "${serverId}" to the list because server ` +
                    'with the same IP and port combination is already present in a list. ';
            }
        } else {
            editable.errors = `Can't add more servers, up to ${poolServersLimit} is supported.`;
        }
    };

    /**
     * Convenience wrapper over addServer for batch new servers processing.
     * @param servers
     * @inner
     */
    const addServers = servers => {
        $scope.editable.errors = '';
        servers.forEach(addServer);
    };

    $scope.addServers = addServers;

    /**
     * Event handler to show error from the addPoolServer component.
     * @param {string} error
     * @public
     */
    $scope.addServersOnError = error => $scope.editable.errors = error;

    /**
     * Event handler for server IP and port change events. First we make a regular expression
     * check and then go through the hash to make sure there are no duplicates. We don't use
     * ng-pattern for syntax check for performance reasons - faster to check it right here as well.
     * @param  {ServerConfig} server
     * @param {string} type - Value type: "ip", "port".
     * @param  {number|string} newValue - Either an IP address string or port number.
     * @returns {boolean}
     * @inner
     */
    function checkServerValidity(server, type, newValue) {
        const
            { ip, port } = server,
            oldKey = getServerId(server),
            { serversHash: hash } = $scope;

        let
            newKey,
            check = false;

        if (type === 'ip') {
            if (Regex.anyIP.test(newValue)) {
                newKey = getServerId(
                    { ip: { addr: newValue }, port },
                );
            }
        } else if (type === 'port') {
            if (angular.isUndefined(newValue) || Regex.port.test(newValue)) {
                newKey = getServerId(
                    { ip, port: newValue },
                );
            }
        }

        if (newKey) {
            if (newKey !== oldKey && newKey in hash) {
                check = false;
            } else {
                hash[newKey] = true;
                check = true;
            }
        }

        if (newKey !== oldKey) {
            delete hash[oldKey];
        }

        return check;
    }

    /**
     * Deletes unused fields when switching between IP Address, IP Group, or EPG server
     * selection.
     * @public
     */
    $scope.clearServers = () => {
        if ($scope.ui.errors) {
            $scope.ui.errors = '';
        }

        deletePoolServers();

        const config = $scope.editable.getConfig();

        delete config.ipaddrgroup_ref;
        delete config.apic_epg_name;
        delete config.external_autoscale_groups;

        config.nsx_securitygroup.length = 0;
    };

    /**
     * We need to tell addPoolServers component how many new servers can be added to this pool.
     * @returns {number}
     * @public
     */
    $scope.getNumberOfAvailServerSpots = function() {
        return Math.max(0, poolServersLimit - $scope.editable.getConfig().servers.length);
    };

    /* ==================
       SERVERS STUFF ENDS
       ================== */

    $scope.init = function() {
        const
            { editable: pool } = $scope,
            config = pool.getConfig();

        $scope.ui = {};//cloudInitIsActive
        $scope.ui.cloudIsSet = false;
        $scope.ui.vrfContextIsSet = false;
        // Need some time for the cloud collection calls and should show spinner
        $scope.ui.cloudInitIsActive = true;
        $scope.ui.selectedNetwork = undefined;
        // If no VS or if VS is layer 7 display all options in Pool Down Action
        $scope.ui.vsLayer7OrUndefined = true;
        $scope.serversOnInit();

        $scope.editable.errors = null;
        $scope.wizard.current = 0;

        if (!Array.isArray(config.placement_networks)) {
            config.placement_networks = [];
        }

        $scope.action = '';
        $scope.filter = '';
        $scope.ratio = '';
        $scope.port = '';
        $scope.timeout = '';
        $scope.ui.showSSLProfile = !!config.ssl_profile_ref;
        $scope.VirtualService = new VirtualService();

        const vsId = pool.getVSId();

        if (vsId) {
            $scope.VirtualService.id = vsId;

            $scope.VirtualService.load().then(() => {
                const appType = $scope.VirtualService.appType();

                $scope.ui.vsLayer7OrUndefined = appType !== 'l4' && appType !== 'ssl';
            });
        }

        const { cloudCollection } = $scope;

        //create new dialog, cloud_ref can come from VS create/edit window
        if (!pool.id && !$scope.cloudSelected) {
            cloudCollection.load()
                .then(() => {
                    if (cloudCollection.getTotalNumberOfItems() === 1) {
                        config.cloud_ref = cloudCollection.items[0].getRef();
                        $scope.setCloud();
                    } else {
                        $scope.ui.cloudInitIsActive = false;
                    }
                })
                .catch(({ data }) => {
                    $scope.ui.cloudInitIsActive = false;
                    pool.errors = data;
                });
        } else {
            $scope.setCloud();
        }

        if (config.ipaddrgroup_ref) {
            $scope.ui.ipSelect = 'ip_group';
        } else if (config.apic_epg_name) {
            $scope.ui.ipSelect = 'epg';
        } else if (angular.isArray(config.nsx_securitygroup) && config.nsx_securitygroup.length) {
            $scope.ui.ipSelect = 'sg';
        } else if (!_.isEmpty(config.external_autoscale_groups)) {
            $scope.ui.ipSelect = 'autoscale';
        } else {
            $scope.ui.ipSelect = 'ip_address';
        }
    };

    $scope.showSSLChange = function() {
        if (!$scope.ui.showSSLProfile) {
            delete $scope.editable.data.config.ssl_profile_ref;
            delete $scope.editable.data.config.pki_profile_ref;
            delete $scope.editable.data.config.ssl_key_and_certificate_ref;
        }
    };

    const setCloud = () => {
        function afterCloudInit() {
            const {
                Cloud: cloud,
                editable: pool,
                networks: networksCollection,
            } = $scope;

            const { id: cloudId } = cloud;
            const cloudConfig = cloud.getConfig();

            serverCollection.setParams({ cloud_uuid: cloudId });
            networksCollection.setParams({ cloud_uuid: cloudId });

            if (cloudConfig['apic_mode']) {
                pool.getApicEpgs()
                    .then(data => $scope.apicepgs = data || []);
            } else if (cloudConfig['nsx_configuration']) {
                pool.getSecurityGroups(cloudId)
                    .then(sgs => $scope.securityGroups = sgs || []);
            }

            if (cloud.isServerAutoScaleAvailable()) {
                pool.getAutoscaleGroups()
                    .then(asg => $scope.autoscaleGroups = asg || []);
            }

            //no selection available
            if (pool.id || pool.getVRFContextRef() || !cloud.allowCustomVRFContext()) {
                $scope.onVRFContextSet();
            }
            // else: step for VRF selection will be rendered

            setTimeout(() => {
                $scope.container
                    .find('input[name=pool_name]')
                    .trigger('focus');
            }, 5);
        }

        let promise;

        const { editable: pool } = $scope;

        $scope.ui.cloudInitIsActive = true;

        pool.errors = null;

        const cloudId = pool.getCloudRef().slug();

        $scope.Cloud = $scope.cloudCollection.getItemById(cloudId);

        if ($scope.Cloud && !$scope.Cloud.allowCustomVRFContext()) {
            afterCloudInit();
            $scope.ui.cloudIsSet = true;
            $scope.ui.cloudInitIsActive = false;
            promise = $q.when(true);
        } else {
            $scope.Cloud = new Cloud({
                id: cloudId,
                params: {
                    join: 'ipamdnsproviderprofile:ipam_provider_ref',
                },
            });

            promise = $scope.Cloud.load()
                .then(() => {
                    afterCloudInit();
                    $scope.ui.cloudIsSet = true;
                })
                .catch(({ data }) => $q.reject(pool.errors = data))
                .finally(() => $scope.ui.cloudInitIsActive = false);
        }

        return promise;
    };

    $scope.setCloud = () => setCloud().then(
        () => $scope.editable.setPristine(),
    );

    /**
     * Sets ui.vrfContextIsSet flag. Also sets params of networks if vrf_context exists.
     */
    $scope.onVRFContextSet = function() {
        const
            { editable: pool } = $scope,
            vrfContextRef = pool.getVRFContextRef();

        if (vrfContextRef) {
            $scope.networks.setParams({
                vrf_context_uuid: vrfContextRef.slug(),
            });
        }

        $scope.ui.vrfContextIsSet = true;
    };

    const loadNetworkServers = networkId => {
        serverCollection.reset();

        serverCollection.setParams({
            cloud_uuid: $scope.Cloud.id,
            network_uuid: networkId,
        });

        serverCollection.load();
    };

    $scope.networkSelected = function() {
        let netRef;

        if (netRef = $scope.ui.selectedNetwork) {
            loadNetworkServers(netRef.slug());
        }
    };

    /**
     * Called when selecting a security group from the dropdown. Makes a request to get addresses
     * for populating the servers grid.
     */
    $scope.handleSelectSecurityGroup = function() {
        deletePoolServers();

        $scope.editable.getSecurityGroupAddresses()
            .then(addServers);
    };

    /**
     * Handler for selecting an AutoScale group from the dropdown, or clearing one. Gets a list of
     * servers for that AutoScale group.
     */
    $scope.handleSelectAutoscaleGroup = function() {
        $scope.editable.getAutoscaleGroupServers()
            .then(servers => {
                deletePoolServers();
                addServers(servers);
            });
    };

    $scope.algorithmChanged = function() {
        const config = $scope.editable.getConfig();

        if (config.lb_algorithm === 'LB_ALGORITHM_CONSISTENT_HASH') {
            config.lb_algorithm_hash = 'LB_ALGORITHM_CONSISTENT_HASH_SOURCE_IP_ADDRESS';
        }
    };

    $scope.openNetworkPanel = function($event) {
        $event.stopPropagation();
        $scope.resetNetworkPanel();
        $scope.networkPanelOpened = true;
    };

    $scope.closeNetworkPanel = function() {
        $scope.resetNetworkPanel();
        $scope.networkPanelOpened = false;
    };

    $scope.resetNetworkPanel = function() {
        serverCollection.reset();
        $scope.ui.selectedNetwork = undefined;

        if ($scope.networkServersGrid.reset) {
            $scope.networkServersGrid.reset();
        }
    };

    $scope.gotoTab = function(tab) {
        $scope.editable.errors = null;
        $scope.wizard.current = tab;
    };

    /**
     * Placement networks dropdown on select.
     * Grabs ip_subnet list from network to use as suggestions for e-auto-complete.
     * @param  {Object} net - placement network object
     * @param  {SubnetListNetwork=} network - Network item.
     */
    $scope.getSubnetworks = function(net, network) {
        net.subnet = undefined;
        net.subnetworks = undefined;

        if (network) {
            const subnets = angular.copy(network.getConfig()['subnet']);

            if (subnets) {
                subnets.forEach(subnet => subnet.value = getSubnetString(subnet));
                net.subnetworks = subnets;
            }
        }
    };

    /**
     * If IPAddrGroup is selected, adds servers by single IP addresses or a range. Before
     * adding, a check is done on the IP group to make sure there aren't
     * too many addresses than currently supported.
     * @param  {IpAddrGroup=} ipAddrGroup - Selected row from IP Group dropdown. Undefined when
     *     called on clear event.
     * @public
     **/
    //TODO share this method between PoolCreate and VSBasicCreateControllers
    $scope.ipAddrGroupAdd = function(ipAddrGroup) {
        deletePoolServers();

        if ($scope.ui.errors) {
            $scope.ui.errors = '';
        }

        if (ipAddrGroup) {
            const numberOfIps = ipAddrGroup.getNumberOfIPs();

            if (numberOfIps > poolServersLimit) {
                $scope.ui.errors = `Too many (${$filter('number')(numberOfIps)}) ` +
                    `in IP group. Only ${poolServersLimit} addresses supported.`;

                $scope.editable.getConfig().ipaddrgroup_ref = undefined;
            } else {
                const ips = ipAddrGroup.getIPs();

                addServers(ips.map(ip => ({ ip })));
            }
        }
    };

    /**
     * Selects servers source by type.
     * @param {string} type
     */
    $scope.selectServersByType = type => {
        const { servers } = $scope.editable.getConfig();
        let confirm = true;

        if (servers.length > 0) {
            confirm = $window.confirm('Remove current servers?');
        }

        if (confirm) {
            $scope.ui.ipSelect = type;
            $scope.clearServers();
        }
    };

    /**
     * If Server Reselect checkbox is unchecked, remove related values.
     */
    $scope.onServerReselect = function() {
        const config = $scope.editable.getConfig();

        if (!config.server_reselect.enabled) {
            config.server_reselect.svr_resp_code = undefined;
            config.server_reselect.retry_timeout = undefined;
            config.server_reselect.num_retries = undefined;
            config.server_reselect.retry_nonidempotent = undefined;
        }
    };

    /**
     * Called when TLS SNI checkbox value is changed.
     */
    $scope.onTLSChange = function() {
        const config = $scope.editable.getConfig();

        if (!config.sni_enabled) {
            config.server_name = undefined;
            config.rewrite_host_header_to_sni = false;
        }
    };

    /**
     * Checks if placement network is allowed.
     * @return {boolean}
     */
    $scope.placementNetworkAllowed = function() {
        const vtypes = {
            CLOUD_VCENTER: 0,
            CLOUD_OPENSTACK: 0,
        };

        return $scope.Cloud.getVtype() in vtypes;
    };

    $scope.$on('$destroy', () => {
        const colls = [
            $scope.cloudCollection,
            $scope.sslProfileCollection,
            $scope.pkiProfileCollection,
            $scope.certificateCollection,
            $scope.persistentProfileCollection,
            serverCollection,
            $scope.ipAddrGroupCollection,
            $scope.analyticsProfileCollection,
            $scope.networks,
        ];

        colls.forEach(collection => collection.destroy());

        $scope.VirtualService.destroy();

        $scope.Cloud = undefined;
    });
}]);
