/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBSiteInventoryDataTransformer
 * @author Alex Malitsky
 */
angular.module('aviApp').factory('GSLBSiteInventoryDataTransformer', [
'InventoryDataTransformer', 'systemInfoService',
function(InventoryDataTransformer, systemInfo) {
    return class GSLBSiteInventoryDataTransformer extends InventoryDataTransformer {
        /** @override */
        processResponse(resp) {
            const
                results = [],
                { config } = resp.data,
                runtime = resp.data['runtime']['info']['site'],
                { third_party_sites: nonAviSites } = config,
                localClusterId = systemInfo.getLocalClusterId(),
                leaderClusterId = config['leader_cluster_uuid'],
                gslbConfigHash = {};

            config.sites.forEach(gslbSiteConfig => {
                const id = gslbSiteConfig['cluster_uuid'];

                if (id === leaderClusterId) {
                    gslbSiteConfig.isLeader = true;
                }

                if (id === localClusterId) {
                    gslbSiteConfig.isLocal = true;
                }

                gslbConfigHash[id] = gslbSiteConfig;
            });

            if (angular.isArray(nonAviSites)) {
                nonAviSites.forEach(gslbNonAviSiteConfig => {
                    gslbNonAviSiteConfig._isNonAviSite = true;
                    results.push({
                        config: gslbNonAviSiteConfig,
                    });
                });
            }

            runtime.forEach(gslbSiteRuntime => {
                const fullRuntime = gslbSiteRuntime['site_info'];
                const id = fullRuntime['cluster_uuid'];

                if (id in gslbConfigHash) {
                    let healthScore,
                        dnsVSs;

                    const swVersion = GSLBSiteInventoryDataTransformer.getVersionDataFromString(
                        fullRuntime['sw_version'],
                    );

                    if (angular.isArray(fullRuntime['dns_info']['dns_vs_states'])) {
                        dnsVSs = fullRuntime['dns_info']['dns_vs_states']
                            .map(dnsVSruntimeData => {
                                return `${dnsVSruntimeData.uuid}#${dnsVSruntimeData.name}`;
                            });
                    }

                    const runtime = {
                        oper_status: fullRuntime['oper_status'],
                        swVersion,
                        dnsVSs,
                    };

                    //faking it to make directives work
                    if (runtime['oper_status'].state === 'OPER_UP') {
                        healthScore = { health_score: 100 };
                    }

                    results.push({
                        config: gslbConfigHash[id],
                        runtime,
                        health_score: healthScore,
                    });
                }
            });

            return {
                data: {
                    results,
                    count: results.length,
                },
            };
        }

        /**
         * Parses full Avi Vantage version into chunks like version, build and timestamp.
         * @param str
         * @returns {Object}
         * @static
         */
        static getVersionDataFromString(str) {
            const parts = /([\d.]+)\s?\((\d+)\)\s(.*)/.exec(str);

            if (parts) {
                const [fullString, version, build, timestamp] = parts;

                return {
                    fullString,
                    version,
                    build,
                    timestamp,
                };
            }

            return {};
        }
    };
}]);
