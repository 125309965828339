/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/administration/traffic-capture.less';

angular.module('aviApp').controller('VSTrafficCaptureController', [
'AsyncFactory', 'VSTrafficCapture', 'AviModal', 'Auth', 'VSTrafficCaptureConfig',
function(AsyncFactory, VSTrafficCapture, AviModal, Auth, VSTrafficCaptureConfig) {
    const vm = this;

    const asyncHash = {
        pollTrafficCapture: null,
        pollPreparingCapture: null,
    };

    this.$onInit = function() {
        this.capturingTraffic = false;
        this.preparingCapture = false;

        this.currentCaptureConfig = {};
        this.progress = null;
        this.downloadableCaptures = [];

        this.errors = null;

        asyncHash.pollPreparingCapture = new AsyncFactory(function() {
            vm.preparingCapture = true;
            vm.errors = null;

            return VSTrafficCapture.getCurrentCapture()
                .then(function(rsp) {
                    if (Array.isArray(rsp.data.results) && !rsp.data.results.length) {
                        asyncHash.pollPreparingCapture.stop();
                        vm.preparingCapture = false;
                        reset();
                    }
                }).catch(function(rsp) {
                    vm.errors = rsp.data.error;
                    asyncHash.pollPreparingCapture.stop();
                    vm.preparingCapture = false;
                    reset();
                });
        });

        getDownloadableCaptures();
        getCurrentCapture();
    };

    /**
     * Public properties.
     */
    this.gridConfig = {
        fields: [{
            name: 'modified',
            title: 'Date',
            sortBy: 'modified',
            template: '<span>{{ row.modified | prettyTime:1 }}</span>',
        }, {
            name: 'name',
            title: 'Virtual Service Name',
        }, {
            name: 'size',
            title: 'Size',
            transform(row) {
                return Math.formatBytes(row.size);
            },
        }],
        rowId(row) {
            return row.url;
        },
        searchFields: ['modified', 'name'],
        layout: {
            hideSearch: false,
            hideDisplaying: false,
        },
        multipleactions: [{
            title: 'Delete',
            do(rows) {
                VSTrafficCapture.deleteRows(rows)
                    .then(function() {
                        getDownloadableCaptures();
                    });

                return true;
            },
        }],
        singleactions: [{
            title: 'Download',
            class: 'icon-download-1',
            do(row) {
                VSTrafficCapture.download(row.url);
            },
        }],
        checkboxDisable() {
            return !Auth.isPrivilegeAllowed('PERMISSION_TRAFFIC_CAPTURE', 'WRITE_ACCESS');
        },
    };

    /**
     * @public
     * Opens modal when Edit button is clicked.
     */
    this.editSettings = function() {
        AviModal.open('virtualservice-traffic-capture-config', {
            onSuccess: getCurrentCapture,
            captureConfig: new VSTrafficCaptureConfig(),
        });
    };

    /**
     * @public
     * Switch to stop current ongoing capture. Can only be switched from 'on' to 'off'.
     */
    this.toggleSwitch = function() {
        if (vm.currentCaptureConfig && vm.currentCaptureConfig.uuid) {
            VSTrafficCapture.stopCapture(vm.currentCaptureConfig);

            if (asyncHash.pollTrafficCapture && asyncHash.pollTrafficCapture.isActive()) {
                asyncHash.pollTrafficCapture.stop();
            }

            asyncHash.pollPreparingCapture.start(5000);
        }
    };

    /**
     * @inner
     * Gets list of downloadable captures.
     */
    function getDownloadableCaptures() {
        VSTrafficCapture.getDownloadableCaptures()
            .then(function(rsp) {
                vm.downloadableCaptures = rsp.data.results;
            });
    }

    /**
     * @inner
     * Checks if there is an ongoing traffic capture in progress. If there is, call pollProgress
     * to create a polling function for its progress.
     */
    function getCurrentCapture() {
        VSTrafficCapture.getCurrentCapture()
            .then(function(rsp) {
                [vm.currentCaptureConfig] = rsp.data.results;

                if (vm.currentCaptureConfig && vm.currentCaptureConfig.capture) {
                    pollProgress(vm.currentCaptureConfig.uuid);
                }
            });
    }

    /**
     * @inner
     * @param {string} uuid - Virtual Service uuid.
     * Polls for traffic capture progress.
     * Don't stop polling if there is an error, as the error indicates something that the user can
     * fix while the capture is running.
     * When complete, stops polling for progress and starts polling to check if the capture file
     * is ready for download.
     */
    function pollProgress(uuid) {
        vm.capturingTraffic = true;
        vm.errors = null;

        asyncHash.pollTrafficCapture = new AsyncFactory(function() {
            return VSTrafficCapture.getProgress(uuid)
                .then(function(rsp) {
                    vm.progress = rsp.data;
                    vm.errors = null;

                    if (VSTrafficCapture.isComplete(vm.progress)) {
                        vm.capturingTraffic = false;
                        asyncHash.pollTrafficCapture.stop();
                        asyncHash.pollPreparingCapture.start(5000);
                    }
                }).catch(function(rsp) {
                    vm.errors = rsp.data.error;
                });
        });

        asyncHash.pollTrafficCapture.start(5000);
    }

    /**
     * @inner
     * Cancels all async polling.
     */
    function cancelPolling() {
        _.each(asyncHash, function(asyncInstance, key) {
            if (asyncInstance && asyncInstance.isActive()) {
                asyncInstance.stop();
            }
        });
    }

    /**
     * @inner
     * Clears current capture configuration and progress, then checks for downloadable captures.
     */
    function reset() {
        vm.currentCaptureConfig = {};
        vm.progress = null;

        getDownloadableCaptures();
    }

    /**
     * Cancels requests on $destroy event.
     */
    this.$onDestroy = function() {
        VSTrafficCapture.cancelRequests();
        cancelPolling();
    };
}]);
