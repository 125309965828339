/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type Function
 * @param {string} val - Subnet string.
 * @description
 *     Returns a subnet object given a subnet string with format '1.2.3.4/24'. If format doesn't
 *     match, returns undefined.
 */
//TODO convert to factory, add Regex checks and return undefined for faulty ones. Use getIpAddr.
const getSubnetObject = val => {
    let output = val;

    if (angular.isString(val) && val.split('/').length === 2) {
        const subnet = val.split('/');

        output = {
            ip_addr: {
                addr: subnet[0],
                type: 'V4',
            },
            mask: +subnet[1] || 0,
        };
    }

    return output;
};

angular.module('aviApp').constant('getSubnetObject', getSubnetObject);
