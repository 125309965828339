/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  CRUDGridConfig
 * @description  Returns a basic CRUD gridConfig that allows for Editing and Deleting rows.
 *               Collection and field list should be added before use.
 */
angular.module('aviApp').factory('CRUDGridConfig', function() {
    return function(options) {
        return angular.extend({
            fields: [],
            multipleactions: [{
                title: 'Delete',
                disabled: rows => _.every(rows, row => !row.isDroppable()),
                do(rows) {
                    const toDelete = _.filter(rows, row => row.isDroppable());

                    this.config.collection.dropItems(toDelete);

                    return true;
                },
            }],
            singleactions: [{
                title: 'Edit',
                class: 'icon-pencil-4',
                hidden: row => !row.isEditable(),
                do: row => row.edit(),
            }],
            checkboxDisable: row => !row.isDroppable(),
            defaultSorting: 'name',
            layout: {},
        }, options);
    };
});
