/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('EmailValidationController', [
'$scope', 'Schema', 'Regex', 'Base',
function($scope, Schema, Regex, Base) {
    const vm = this;
    const base = new Base();

    $scope.$parent.modalScope = $scope;

    this.Schema = Schema;
    this.Regex = Regex;

    /**
     * Returns the modal settings depending on the type of validation being done, passed into
     * AviModal.open when opening the modal. Modal can be opened from Administration -> Email/SMTP
     * or Operations -> Notifications.
     */
    const validationSettings = {
        smtp: {
            title: 'Validate Email SMTP Settings',
            textLabel: 'Body',
            showTextOnly: false,
            disableEditRecipients: false,
        },
        email: {
            title: 'Validate Email Settings',
            textLabel: 'Body',
            showTextOnly: false,
            disableEditRecipients: true,
        },
        snmp: {
            title: 'Validate SNMP Trap Settings',
            textLabel: 'Trap Description',
            showTextOnly: true,
            disableEditRecipients: true,
        },
        syslog: {
            title: 'Validate Syslog Settings',
            textLabel: 'Message',
            showTextOnly: true,
            disableEditRecipients: true,
        },
    };

    /**
     * Returns the API URL depending on the type.
     * @param  {string} type - Can be 'smtp', 'email', 'snmp', or 'syslog'.
     * @param  {string=} uuid - uuid of profile to be tested. Only applies to 'email', 'snmp', and
     * 'syslog'.
     * @return {string}
     */
    const getApi = function(type, uuid) {
        const apiHash = {
            smtp: 'api/testemail',
            email: `api/alertemailconfig/${uuid}/testemail`,
            snmp: `api/snmptrapprofile/${uuid}/testsnmptrap`,
            syslog: `/api/alertsyslogconfig/${uuid}/testsyslog`,
        };

        return apiHash[type];
    };

    $scope.init = function() {
        vm.errors = null;
        vm.busy = false;

        // If a config object has been passed in, that means this modal was opened from the
        // Operations -> Notifications -> Email page, in which case the to_emails and cc_emails
        // fields have already been populated.
        if (!_.isUndefined($scope.config)) {
            vm.config = $scope.config;
        }

        angular.extend(vm, validationSettings[$scope.type]);
    };

    /**
     * Make POST request to validate email settings.
     */
    this.submit = function() {
        const settings = validationSettings[$scope.type];
        const api = getApi($scope.type, $scope.uuid);
        const config = angular.copy(this.config);

        if (settings.disableEditRecipients) {
            delete config.to_emails;
            delete config.cc_emails;
        }

        this.busy = true;
        this.errors = null;

        base.request('POST', api, config, null, 'validateemail')
            .then(function() {
                vm.closeModal();
            }).catch(function(rsp) {
                vm.errors = rsp.data;
            }).finally(function() {
                vm.busy = false;
            });
    };

    this.closeModal = function() {
        $scope.closeModal();
    };

    $scope.$on('$destroy', function() {
        base.cancelRequests('validateemail');
    });
}]);
