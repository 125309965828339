/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import './waf-policy-whitelist-rules-list.less';

/**
 * @ngdoc component
 * @name WafPolicyWhitelistRulesListComponent
 * @module waf/WafPolicyWhitelistRulesListComponent
 * @desc Component for displaying a WAF Policy Whitelist rule in the WAF Policy modal.
 * @param rules - RepeatedMessageItem containing WafPolicyWhitelistRule instances.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-whitelist-rules-list',
    templateUrl: './waf-policy-whitelist-rules-list.component.html',
})
export class WafPolicyWhitelistRulesListComponent {
    @Input() rules: RepeatedMessageItem<WafPolicyWhitelistRuleConfigItem>;

    @Output() onEdit = new EventEmitter();

    /**
     * Handler for editing a whitelist rule.
     */
    handleEdit(rule: WafPolicyWhitelistRuleConfigItem): void {
        this.onEdit.emit(rule);
    }

    /**
     * Handler for deleting a whitelist rule.
     * @param index - Index of the whitelist rule to remove.
     */
    handleDeleteRule(index: number): void {
        this.rules.remove(index);
    }

    /**
     * Handler for the drag-and-drop event.
     */
    handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.rules.moveItem(previousIndex, currentIndex);
    }
}
