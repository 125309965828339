/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const hostHdrMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/HostHdrMatchConfigItem
     * @private
     */
    class HostHdrMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'HostHdrMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchCaseLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchCase');
            this.operationLabelsHash_ = schemaService.getEnumValueLabelsHash('HdrMatchOperation');
        }

        /**
         * Returns the label for the match_case value.
         * @returns {string}
         */
        get matchCaseLabel() {
            const { match_case: matchCase } = this.config;

            return this.matchCaseLabelsHash_[matchCase];
        }

        /**
         * Returns the label for the match_criteria value.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.operationLabelsHash_[matchCriteria];
        }

        /**
         * Returns the label for the 'value' config property.
         * @returns {string}
         */
        get valueLabels() {
            const { value = [] } = this.config;

            return value.join(', ');
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${this.valueLabels}`;
        }
    }

    return HostHdrMatchConfigItem;
};

hostHdrMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  HostHdrMatchConfigItem
 * @description  HostHdrMatch ConfigItem class.
 * @module config-item/HostHdrMatchConfigItem
 * @author alextsg
 */
angular
    .module('policies.vantage.avi')
    .factory('HostHdrMatchConfigItem', hostHdrMatchConfigItemFactory);
