/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const componentName = 'c-grid-table-header-cell-resize-handle';

class GridColumnResizeHandleController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        const { $element: $elem } = this;

        $elem.addClass(componentName);

        //to prevent column sorting on click
        $elem[0].addEventListener('click', e => e.stopPropagation(), true);

        $elem.on('mousedown', e => this.gridTableHeaderCellCtrl.startCollResize(e));
    }
}

GridColumnResizeHandleController.$inject = [
       '$element',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCellResizeHandle
 * @description
 *
 *     Forwards mousedown event to the {@link gridTableHeaderCell} component controller.
 *
 * @author Alex Malitsky
 **/
angular.module('grid.ui.vantage.avi').component('gridTableHeaderCellResizeHandle', {
    require: {
        gridTableHeaderCellCtrl: '^^gridTableHeaderCell',
    },
    controller: GridColumnResizeHandleController,
});
