/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './upload-status.less';

/**
 * @ngdoc component
 * @name  uploadStatus
 * @param  {upload} Instance of Upload factory.
 * @description Used to display the status of the upload in progress.
 */
angular.module('aviApp').component('uploadStatus', {
    bindings: {
        upload: '<',
    },
    controller() {
        /**
         * Returns a formatted string of loaded bytes to total bytes. ex. '10KB of 50KB'.
         * @return {string} [description]
         */
        this.getProgressBytes = function() {
            const { uploadStatus } = this.upload;

            return `${Math.formatBytes(uploadStatus.loaded)} of ${
                Math.formatBytes(uploadStatus.total)}`;
        };
    },
    templateUrl: 'src/components/common/upload-status/upload-status.html',
});
