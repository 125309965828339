/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name VsWafTopHitsDataTransport
 * @author Alex Malitsky
 * @description
 *
 *     One API call for each groupby param.
 */
angular.module('aviApp').factory('VsWafTopHitsDataTransport', [
'ListDataTransport',
function(ListDataTransport) {
    class VsWafTopHitsDataTransport extends ListDataTransport {
        /**
         * One call per each group (differs by groupby param value only).
         * @override
         */
        getRequestUrl_(params) {
            params = angular.copy(params);

            const { groupBy_: groupBys } = params;

            delete params['groupBy_'];

            return groupBys.map(groupBy => {
                params.groupby = groupBy;

                return super.getRequestUrl_(params);
            });
        }
    }

    return VsWafTopHitsDataTransport;
}]);
