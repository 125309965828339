/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name createItemByRef
 * @description
 *
 *     Creates an Item using a passed ref/URL to figure out an appropriate Item Class.
 *     Item type (objectName) has to be present in {@link itemObjectTypeToClassName} constant.
 */
angular.module('aviApp').factory('createItemByRef', [
'getItemConstructorByRef',
function(getItemConstructorByRef) {
    return function(ref) {
        let Constructor;

        if (ref) {
            Constructor = getItemConstructorByRef(ref);
        }

        if (Constructor) {
            return new Constructor({ id: ref.slug() });
        } else {
            const errMsg = `Can't resolve ref "${ref}" to constructor className`;

            console.error(errMsg);

            return null;
        }
    };
}]);
