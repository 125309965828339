/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/application/virtualservice-create.less';

angular.module('aviApp').controller('VirtualServiceCreateController', [
'$scope', '$controller', '$q', '$window', 'NetworkProfileCollection',
'SSLProfileCollection', 'SEGroupCollection', 'AnalyticsProfileCollection',
'StringGroupCollection', 'VirtualServiceCollection',
'AuthProfileCollection', 'Base', 'Schema', 'schemaService', 'Cloud', 'ApplicationProfile',
'NetworkProfile', 'PoolGroupCollection', 'TrafficCloneProfileCollection', 'WafPolicyCollection',
'ErrorPageProfileCollection', 'defaultValues', 'dropDownUtils',
function(
$scope, $controller, $q, $window, NetworkProfileCollection,
SSLProfileCollection, SEGroupCollection, AnalyticsProfileCollection, StringGroupCollection,
VirtualServiceCollection, AuthProfileCollection, Base, Schema, schemaService, Cloud,
ApplicationProfile, NetworkProfile, PoolGroupCollection, TrafficCloneProfileCollection,
WafPolicyCollection, ErrorPageProfileCollection, defaultValues, dropDownUtils,
) {
    const base = new Base();

    $controller('VirtualServiceCreateCommonController', { $scope });

    /**
     * @type {number} This value for Pool's server port will be considered `default` and used as
     * fallback option when we don't want to set some specific value.
     **/
    let defaultPoolServerPort = 80;

    $scope.serverNetworkProfileCollection = new NetworkProfileCollection({
        params: { 'profile.type': 'PROTOCOL_TYPE_TCP_PROXY' },
    });

    $scope.vsEndPointCollection = new VirtualServiceCollection({
        isStatic: true,
        params: {
            type: 'VS_TYPE_VH_PARENT',
        },
    });
    $scope.authProfileCollection = new AuthProfileCollection();

    $scope.vsEndPointCollection.isCreatable = function() { return false; };

    $scope.sslProfileCollection = new SSLProfileCollection();
    $scope.analyticsProfileCollection = new AnalyticsProfileCollection();
    $scope.stringGroupCollection = new StringGroupCollection();
    $scope.poolGroupCollection = new PoolGroupCollection();
    $scope.trafficCloneProfileCollection = new TrafficCloneProfileCollection();
    $scope.wafPolicyCollection = new WafPolicyCollection();
    $scope.errorPageProfCollection = new ErrorPageProfileCollection();

    $scope.SEGroupCollection = new SEGroupCollection({
        isStatic: true,
        params: {
            sort: 'name',
        },
        bind: {
            collectionLoadSuccess() {
                $scope.setSEGroup();
            },
        },
    });

    $scope.editMode = {
        requestPolicy: null,
        responsePolicy: null,
        securityPolicy: null,
        networkSecurityPolicy: null,
        datascriptPolicy: null,
    };

    /**
     * Switches port layout from basic (single value) to advanced with ranges.
     */
    $scope.basicServicePortViewChange = function() {
        $scope.ui.basicServicePort = !$scope.ui.basicServicePort;

        //when have switched from advanced to basic set all port_range_ends to port
        if ($scope.ui.basicServicePort && $scope.editable && $scope.editable.data) {
            $scope.editable.data.config.services.forEach(function(service) {
                service.port_range_end = service.port;
            });
        }
    };

    // For edit tabs
    $scope.resolutions = [];
    //$scope.readonly = false;
    // Wizard definition
    $scope.wizard = {
        current: 0,
        steps: [{
            title: 'Settings',
        }, {
            title: 'Policies',
        }, {
            title: 'Analytics',
        }, {
            title: 'Advanced',
        }],
        beforeChange() {
            // Clear errors if exist
            $scope.editable.errors = null;

            // Check if need to discard
            if (confirmDiscardSubObject()) {
                $scope.resetSubObjects();

                return false;
            }

            return true;
        },
    };

    $scope.init = function() {
        defaultPoolServerPort = defaultValues.getDefaultItemConfig('pool.default_server_port') ||
            defaultPoolServerPort;

        /**
         * We need to keep track of all loads to set pristine form state when data is 100%
         * ready.
         * @type {ng.$q.promise[]}
         * @inner
         */
        const promises = [];

        $scope.wizard.current = 0;

        const
            { editable: vs } = $scope,
            config = vs.getConfig();

        $scope.ui = {};

        const { ui } = $scope;

        ui.cloudIsSet = false;
        ui.vrfContextIsSet = false;
        //need some time for the cloud collection calls and should show spinner
        ui.cloudInitIsActive = true;
        ui.isVHVS = config.type !== 'VS_TYPE_NORMAL';//virtual hosting VS
        ui.basicServicePort = true;
        ui.showPlacement = true;
        ui.appProfileRef = config['application_profile_ref'];

        ui.poolOrPoolGroup = config['pool_group_ref'] ? 'poolgroup' : 'pool';

        ui.clientInsightsTypes = [
            'ACTIVE',
            'PASSIVE',
            'NO_INSIGHTS',
        ];

        ui.includeUrl = true;

        //show the Rate Limiter subtab if at least one of the nested properties is defined
        ui.activeRateLimiters = !!(config.connections_rate_limit ||
            config.requests_rate_limit || config.performance_limits);
        $scope.newServer = { address: '' };

        $scope.resetSubObjects();

        if (!vs.id) { //create new dialog
            promises.push(
                vs.loadNetProfileData(),
                vs.loadAppProfileData(),
            );

            const setCloudPromise = $scope.cloudCollection.load()
                .then(() => {
                    if ($scope.cloudCollection.getTotalNumberOfItems() < 2) {
                        //go on with the default cloud_uuid
                        return setCloud();
                    } else {
                        $scope.ui.cloudInitIsActive = false;
                    }
                })
                .catch(({ data }) => {
                    $scope.ui.cloudInitIsActive = false;
                    $scope.editable.errors = data;
                });

            promises.push(setCloudPromise);
        } else { //can take cloud_ref from collection.defaults if needed
            promises.push(setCloud());
        }

        $scope.ui.basicServicePort = !_.any(config.services, service => {
            return service.nwOverride || service.port !== service.port_range_end;
        });

        // need to call immediately since user can close the modal before promises get
        // resolved and we don't want to show confirmation dialog in this case
        vs.setPristine();

        $q.all(promises).finally(() => {
            updateModalSettingsAfterAppProfileChange();
            vs.setPristine();
        });

        $scope.policySetIndex = 0;
        $scope.dnsPolicyIndex = 0;
        $scope.topologyPolicyIndex = 0;

        $scope.httpPolicySetSelectOptions = [];
        $scope.dnsPolicySelectOptions = [];
        $scope.topologyPolicySelectOptions = [];

        const { httpPolicySets } = vs;
        const { dns_policies: dnsPolicies } = vs.getConfig();
        const { topology_policies: topologyPolicies } = vs.getConfig();

        if (Array.isArray(httpPolicySets)) {
            $scope.httpPolicySetSelectOptions = httpPolicySets.map((policySet, i) => {
                return dropDownUtils.createOption(i, policySet.getName());
            });
        }

        if (Array.isArray(dnsPolicies)) {
            $scope.dnsPolicySelectOptions = dnsPolicies.map((policyContainer, i) => {
                return dropDownUtils.createOption(i, policyContainer.getPolicyName());
            });
        }

        if (Array.isArray(topologyPolicies)) {
            $scope.topologyPolicySelectOptions = topologyPolicies.map((policyContainer, i) => {
                return dropDownUtils.createOption(i, policyContainer.getPolicyName());
            });
        }
    };

    /**
     * Called by setCloud.
     */
    function afterCloudInit() {
        const
            {
                editable: vs,
                Cloud: cloud,
            } = $scope,
            cloudConfig = cloud.getConfig(),
            config = vs.getConfig();

        const collectionsToSetDefaults = [
            $scope.SEGroupCollection,
            $scope.poolCollection,
            $scope.poolGroupCollection,
            $scope.trafficCloneProfileCollection,
        ];

        const cloudRef = cloud.getRef();
        const { id: cloudId } = cloud;

        collectionsToSetDefaults.forEach(collection => {
            collection.setDefaultItemConfigProps({ cloud_ref: cloudRef });
        });

        collectionsToSetDefaults.forEach(collection => {
            collection.setParams({ 'cloud_ref.uuid': cloudId });
        });

        [
            $scope.networks,
            $scope.poolNetworks,
        ]
            .forEach(collection => collection.setParams({ cloud_uuid: cloudId }));

        vs.getVSVip().setCloudRef(cloudRef);

        if (cloudConfig.apic_mode) {
            $scope.apicgraphs = [];

            base.request('get', 'api/apicgraphinstances/all').then(resp => {
                $scope.apicgraphs = resp.data;
            });
        }

        config.cloud_type = cloudConfig.vtype;

        // skipping manual VRF context selection
        if (vs.id || vs.getVRFContextRef() || !cloud.allowCustomVRFContext()) {
            $scope.onVRFContextSet();
        }//else user has to select VRFContext
    }

    const setCloud = function() {
        $scope.ui.cloudInitIsActive = true;
        $scope.editable.errors = null;

        $scope.Cloud = new Cloud({
            id: $scope.editable.getCloudRef().slug(),
            params: {
                join: 'ipamdnsproviderprofile:ipam_provider_ref',
            },
        });

        return $scope.Cloud.load()
            .then(() => {
                afterCloudInit();
                $scope.ui.cloudIsSet = true;
            })
            .catch(({ data }) => $scope.editable.errors = data)
            .finally(() => $scope.ui.cloudInitIsActive = false);
    };

    /**
     * ngClick wrapper for inner setCloud function.
     */
    $scope.setCloud = () => setCloud().then(
        () => $scope.editable.setPristine(),
    );

    /**
     * Sets ui.vrfContextIsSet flag. Also sets params of networks if vrf_context exists.
     */
    $scope.onVRFContextSet = function() {
        const
            { editable: vs } = $scope,
            vrfContextRef = vs.getVRFContextRef();

        if (vrfContextRef) {
            const vrfContextId = vrfContextRef.slug();

            const networkCollectionOptions = {
                vrf_context_uuid: vrfContextId,
            };

            const {
                poolCollection,
                poolNetworks,
                networks,
            } = $scope;

            //FIXME switch this on once AV-45712 is done
            /*poolCollection.setParams({
                refers_to: `vrfcontext:${vrfContextId}`,
                depth: 1
            });

            poolGroupCollection.setParams({
                refers_to: `vrfcontext:${vrfContextId}`,
                depth: 1
            });*/

            poolCollection.setDefaultItemConfigProps({ vrf_ref: vrfContextRef });

            poolNetworks.setParams(networkCollectionOptions);

            networks.setParams(networkCollectionOptions);

            vs.getVSVip().setVrfContext(vrfContextRef);
        }

        $scope.ui.vrfContextIsSet = true;
    };

    $scope.unlockAllSteps = function() {
        _.each($scope.wizard.steps, function(step) {
            step.unlocked = true;
        });
    };

    const stringOperationEnumObjects = schemaService.getEnumValues('StringOperation');

    // remove not supported Regex checking in URL patterns when inserting RUM
    $scope.urlMatchOperations = stringOperationEnumObjects.filter(
        ({ value }) => !value.startsWith('REGEX'),
    );

    $scope.resetSubObjects = function() {
        $scope.editMode = {
            requestPolicy: null,
            responsePolicy: null,
            securityPolicy: null,
            networkSecurityPolicy: null,
            datascriptPolicy: null,
            logFilter: null,
        };
    };

    $scope.gotoTab = function(tabIndex) {
        if (!confirmDiscardSubObject()) {
            return;
        }

        $scope.resetSubObjects();
        // Clear errors if exist
        $scope.editable.errors = null;
        $scope.wizard.current = tabIndex;
    };

    /**
     * Called by subtabNav component on tab change.
     */
    $scope.handleSubtabChange = function() {
        if (!confirmDiscardSubObject()) {
            return;
        }

        $scope.resetSubObjects();
        // Clear errors if exist
        $scope.editable.errors = null;
    };

    /**
     * Renders a confirmation dialog for the user while he is editing policies and wants to move
     * from the active tab.
     * @returns {boolean}
     * @inner
     */
    function confirmDiscardSubObject() {
        const { editMode } = $scope;

        let message = '';

        if (editMode.securityPolicy ||
            editMode.requestPolicy ||
            editMode.responsePolicy ||
            editMode.networkSecurityPolicy ||
            editMode.datascriptPolicy) {
            message = 'Discard policy changes?';
        } else if (editMode.logFilter) {
            message = 'Discard Client Filter changes?';
        }

        return message ? $window.confirm(message) : true;
    }

    /**
     * Updates NetworkProfile collection params (filters by type) and sets default values for
     * the new ones.
     * @param {string[]} profileTypes
     * @inner
     */
    function setNetworkProfileCollectionParams(profileTypes) {
        const { networkProfileCollection: collection } = $scope;

        let defaultProfileType = 'PROTOCOL_TYPE_TCP_PROXY';

        // no filter needed if all types are avail
        if (profileTypes.length === NetworkProfile.types.length) {
            collection.setParams({ 'profile.type': undefined });
        } else {
            collection.setParams({ 'profile.type': profileTypes.join() });
            ([defaultProfileType] = profileTypes);
        }

        collection.setDefaultItemConfigProps({
            profile: { type: defaultProfileType },
        });
    }

    /**
     * Sets default port for newly created pools.
     * @param {number=} port
     * @inner
     */
    function setPoolCollectionParams(port = defaultPoolServerPort) {
        const { editable: vs } = $scope;

        // HTTPS VS service port is 443 but 80 is used for HTTP backend (pool)
        if (vs.isHTTPS()) {
            port = defaultPoolServerPort;
        }

        $scope.poolCollection.setDefaultItemConfigProps({
            default_server_port: port,
        });
    }

    /**
     * Updates various collections properties and ui (form) flags based on selected VS
     * application profile type. Called on init as well.
     */
    function updateModalSettingsAfterAppProfileChange() {
        const
            { editable: vs } = $scope,
            appProfileType = vs.appType(),
            allowedNetProfileTypes = ApplicationProfile.getAllowedNetProfileTypes(appProfileType);

        $scope.ui.isVHVS = vs.getConfig()['type'] !== 'VS_TYPE_NORMAL';

        setNetworkProfileCollectionParams(allowedNetProfileTypes);

        setPoolCollectionParams(vs.getDefaultServicePort());

        $scope.setWizardBasedOnDns();
    }

    /**
     * Sets selected application profile on VS instance.
     * Called on application profile selection event.
     * @param {ApplicationProfile} appProfile
     */
    $scope.onAppProfileChange = function(appProfile) {
        const { editable: vs } = $scope;

        vs.setAppProfile(appProfile.getRef(), appProfile.getConfig())
            .finally(updateModalSettingsAfterAppProfileChange);
    };

    /**
     * Event handler for network profile change.
     * @param {NetworkProfile} netProfile
     * @public
     */
    $scope.onNetworkProfileChange = function(netProfile) {
        const config = $scope.editable.getConfig();

        config['network_profile_ref_data'] = netProfile.getConfig();
    };

    /**
     * Sets VS Type as Child or Normal. Called on ng-change for Virtual Hosting VS checkbox.
     * @public
     **/
    $scope.onVSTypeToggle = function() {
        const config = $scope.editable.getConfig();

        if ($scope.ui.isVHVS) {
            config['type'] = 'VS_TYPE_VH_CHILD';
            $scope.onVSTypeChange('child');
        } else {
            const { type: prevType } = config;

            config['type'] = 'VS_TYPE_NORMAL';

            if (prevType === 'VS_TYPE_VH_CHILD') {
                $scope.editable.setDefaultService();
            }
        }
    };

    /**
     * Called when Virtual Hosting VS type is changed from Parent to Child and vice versa.
     * @param {string} type - 'child' or 'parent'.
     * @public
     */
    $scope.onVSTypeChange = function(type) {
        const
            { editable: vs } = $scope,
            config = vs.getConfig();

        switch (type) {
            case 'child':
                config['type'] = 'VS_TYPE_VH_CHILD';
                config.services.length = 0;

                config['ssl_key_and_certificate_refs'] = [];
                config['ssl_profile_ref'] = undefined;
                break;

            case 'parent': {
                config['type'] = 'VS_TYPE_VH_PARENT';
                vs.setDefaultService(443);

                const { ssl_key_and_certificate_refs: certsList } = config;

                if (!certsList || !certsList.length) {
                    config['ssl_key_and_certificate_refs'] = [
                        defaultValues.getSystemObjectRefByName(
                            'sslkeyandcertificate',
                            'System-Default-Cert',
                        ),
                    ];
                }

                if (!config['ssl_profile_ref']) {
                    config['ssl_profile_ref'] =
                        defaultValues.getSystemObjectRefByName(
                            'sslprofile',
                            'System-Standard',
                        );
                }

                break;
            }
        }
    };

    $scope.serverNetworkProfileDisabled = function() {
        return $scope.getNetProfileType() !== 'PROTOCOL_TYPE_TCP_PROXY';
    };

    $scope.sslDisabled = function() {
        if (!$scope.editable || !$scope.editable.data || !$scope.editable.data.config) {
            return false;
        }

        return !_.find($scope.editable.data.config.services, function(port) {
            return port.enable_ssl;
        });
    };

    $scope.addPort = function() {
        const
            { editable: vs } = $scope,
            { constructor: VirtualService } = vs,
            { services } = vs.getConfig(),
            [{ enable_ssl, nwOverride }] = services.slice(-1);

        services.push(
            VirtualService.getDefaultServiceConfig({
                port: null,
                enable_ssl,
                nwOverride,
            }),
        );
    };

    $scope.removePort = function(serviceToRemove) {
        const { services } = $scope.editable.data.config;

        if (services.length > 1) {
            const pos = services.indexOf(serviceToRemove);

            if (pos !== -1) {
                services.splice(pos, 1);
            }
        }
    };

    $scope.changeBasicAuth = function() {
        if ($scope.editable.data.enable_basic_auth) {
            $scope.editable.data.config.client_auth = {};
            $scope.editable.data.config.client_auth.type = 'HTTP_BASIC_AUTH';
            $scope.editable.data.config.client_auth.request_uri_path = {
                match_criteria: 'BEGINS_WITH',
            };
        } else {
            delete $scope.editable.data.config.client_auth;
        }
    };

    /**
     * ngClick callback for the UI checkbox "enable rate limiters".
     * @type {function}
     */
    $scope.rateLimitersSwitch = function() {
        const defaultRateLimitConfig = {
            action: {
                type: 'RL_ACTION_NONE',
            },
        };

        if ($scope.ui.activeRateLimiters) {
            $scope.editable.data.config.requests_rate_limit =
                angular.copy(defaultRateLimitConfig);

            $scope.editable.data.config.connections_rate_limit =
                angular.copy(defaultRateLimitConfig);
        } else {
            $scope.editable.data.config.requests_rate_limit = undefined;
            $scope.editable.data.config.connections_rate_limit = undefined;
            $scope.editable.data.config.performance_limits = undefined;
        }
    };

    $scope.setServicePort = function(service) {
        if (service.port > service.port_range_end) {
            service.port_range_end = service.port;
        }
    };

    /**
     * Returns a string of ports and port ranges to be displayed in the Review tab of VS
     * creation.
     * @return {string} List of port and port ranges along with SSL enabled property.
     */
    $scope.getPortsList = function() {
        const ports = $scope.editable.data.config.services.map(function(service) {
            let output = '';

            if (service.port === service.port_range_end) {
                output += service.port;
            } else {
                output += `${service.port}-${service.port_range_end}`;
            }

            if (service.enable_ssl) {
                output += ' (SSL Enabled)';
            }

            return output;
        });

        return ports.join(', ');
    };

    /**
     * Sets SEGroup from SEGroupCollection to be used for setting Active Standby SE Tag.
     */
    $scope.setSEGroup = function() {
        const { config } = $scope.editable.data;

        $scope.seGroup = config.se_group_ref ?
            $scope.SEGroupCollection.getItemById(config.se_group_ref.slug()) : undefined;
    };

    /**
     * Called when user checks the SSL checkbox when configuring port numbers. If the port is 80 and
     * the user checks SSL, we change it to 443, and vice versa.
     * @param  {Object} service - Service port object.
     */
    $scope.handleSSLPortChange = function(service) {
        if (service.enable_ssl && service.port === 80 && service.port_range_end === 80) {
            service.port_range_end = 443;
            service.port = 443;
        } else if (!service.enable_ssl && service.port === 443 && service.port_range_end === 443) {
            service.port_range_end = 80;
            service.port = 80;
        }
    };

    /**
     * Called when user switches between adding a Pool or Pool Group. Removes the existing value.
     */
    $scope.handlePoolOrPoolGroupChange = () => {
        const { config } = $scope.editable.data;

        delete config.pool_ref;
        delete config.pool_group_ref;
    };

    /**
     * Checks if VS has at least one service with port number.
     * @return {boolean}
     */
    $scope.validateServices = function() {
        if (!$scope.editable) {
            return false;
        }

        return _.any($scope.editable.data.config.services, ({ port }) => port);
    };

    /**
     * Returns true if the Placement Subnet input field should be shown, allowing the user to add
     * a placement subnet.
     * @return {boolean}
     */
    $scope.showPlacementSubnetOnly = function() {
        const vtype = $scope.Cloud.getVtype();

        return vtype === 'CLOUD_NONE' || vtype === 'CLOUD_LINUXSERVER';
    };

    /**
     * We want to set default names for pool and poolGroup created from current modal window.
     * @public
     */
    $scope.onNameChange = function() {
        const
            vsName = $scope.editable.getName(),
            poolName = vsName ? vsName.replace(/:/g, '') : '';

        $scope.poolCollection.setDefaultItemConfigProps({ name: `${poolName}-pool` });
        $scope.poolGroupCollection.setDefaultItemConfigProps({ name: `${poolName}-pool-group` });
    };

    /**
     * Returns an application profile type.
     * @returns {string} - app profile type enum
     * @public
     */
    $scope.getAppProfileType = function() {
        return $scope.editable.getAppProfileConfig()['type'];
    };

    /**
     * Returns a network profile type.
     * @returns {*}
     */
    $scope.getNetProfileType = function() {
        return $scope.editable.getDefaultNetProfileType();
    };

    /**
     * Returns true if VS has HTTP application profile type.
     * @returns {boolean}
     * @public
     */
    $scope.isHTTPVS = function() {
        return $scope.editable.appType() === 'http';
    };

    /**
     * Checkbox click event handler for the service NetworkProfile override option.
     * @param {Object} service
     * @public
     */
    $scope.switchServiceOverrideNetworkProfile = function(service) {
        if (!service.nwOverride) {
            service['override_network_profile_ref'] = undefined;
        }
    };

    /**
     * If the VS is of type DNS, an additional tab called 'Static DNS Records' is shown in the
     * wizard.
     */
    $scope.setWizardBasedOnDns = () => {
        const { wizard } = $scope;
        const steps = [{
            title: 'Settings',
        }, {
            title: 'Policies',
        }, {
            title: 'Analytics',
        }, {
            title: 'Advanced',
        }];

        if ($scope.editable.isDNS()) {
            wizard.steps = steps.concat({ title: 'Static DNS Records' });
        } else {
            wizard.steps = steps;
        }
    };

    $scope.$on('$destroy', () => {
        const colls = [
            $scope.cloudCollection,
            $scope.applicationProfileCollection,
            $scope.networkProfileCollection,
            $scope.networks,
            $scope.poolNetworks,
            $scope.SEGroupCollection,
            $scope.poolCollection,
            $scope.poolGroupCollection,
            $scope.certificateCollection,
            $scope.errorPageProfCollection,
        ];

        colls.forEach(collection => collection.destroy());

        base.cancelRequests();
    });
}]);
