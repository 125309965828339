/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import './software-page.component.less';
import { uploadStatesHash } from 'services/upload';

const {
    UPLOAD_STATE_IN_PROGRESS,
    UPLOAD_STATE_PROCESSING,
    UPLOAD_STATE_COMPLETE,
} = uploadStatesHash;

/**
 * @constructor
 * @memberOf module:avi/upgrade
 */
class SoftwarePageController {
    constructor(
        Upload,
        ImageCollection,
    ) {
        this.upload_ = new Upload(
            {
                destination: '/api/image/?',
            },
        );

        this.ImageCollection_ = ImageCollection;

        /**
         * Name of the file to be uploaded.
         * @type {string}
         * @protected
         */
        this.fileName_ = '';

        /**
         * Collection to hold software image object.
         * @type {module:avi/upgrade.ImageCollection|null}
         * @protected
         */
        this.imageCollection_ = null;

        /**
         * Config object for grid listing images.
         * @type {Object|null}
         * @protected
         */
        this.imageCollectionGridConfig = null;
    }

    /** @override */
    $onInit() {
        this.imageCollection_ = new this.ImageCollection_();
        this.initImageCollectionGridConfig_();
    }

    /**
     * Decide operations after a file is selected from local machine.
     * @param {File} file
     * @return {ng.$q.promise}
     */
    onFileSelect(file) {
        const { name: fileName } = file;

        this.fileName_ = fileName;

        return this.upload_.send(file, fileName)
            .then(rsp => {
                this.imageCollection_.load();

                return true;
            });
    }

    /**
     * Called when the close button of upload section notification is clicked.
     * @return {number}
     */
    getUploadPecentage() {
        if (this.upload_.isState(UPLOAD_STATE_COMPLETE)) {
            return 100;
        }

        // percentage suspends at 90% for the window time before the whole upload process is done
        return Math.round(this.upload_.getUploadPercentage() * 0.9);
    }

    /**
     * Decide message to be shown in notification when uploading succeeds.
     * @return {string}
     */
    getSuccessMessage() {
        return `Upload Complete! ${this.fileName_}`;
    }

    /**
     * Decide message to be shown under progress bar.
     * @return {string}
     */
    getProgressMessage() {
        const { state } = this.upload_;
        let progressOperation = '';

        switch (state) {
            case UPLOAD_STATE_IN_PROGRESS:
                progressOperation = 'Uploading';
                break;

            case UPLOAD_STATE_PROCESSING:
                progressOperation = 'Processing';
                break;

            case UPLOAD_STATE_COMPLETE:
                progressOperation = 'Processing complete';
                break;
        }

        return `${progressOperation} ${this.fileName_}`;
    }

    /**
     * Initiate grid config for image list.
     * @protected
     */
    initImageCollectionGridConfig_() {
        const fields = [{
            title: 'Type',
            name: 'type',
            template: require('../image-type.partial.html'),
        }, {
            title: 'Version',
            name: 'version',
            template: require('../image-version.partial.html'),
        }, {
            title: 'Attributes',
            name: 'attributes',
            template: '{{ row.category }}',
        }];

        const singleactions = [{
            title: 'Delete',
            class: 'icon-trash',
            do: row => this.imageCollection_.dropItems(row),
            disabled: row => row.isUsedByController(),
        }];

        const multipleactions = [{
            title: 'Delete',
            do: rows => {
                this.imageCollection_.dropItems(rows);

                return true;
            },
        }];

        const layout = {
            hideEditColumns: true,
        };

        const checkboxDisable = row => row.isUsedByController();

        this.imageCollectionGridConfig = {
            collection: this.imageCollection_,
            fields,
            singleactions,
            multipleactions,
            layout,
            checkboxDisable,
            permission: 'PERMISSION_IMAGE',
        };
    }

    /** @override */
    $onDestroy() {
        this.imageCollection_.destroy();

        if (this.upload_.isInProgress()) {
            this.upload_.cancelUpload();
        }
    }
}

SoftwarePageController.$inject = [
    'Upload',
    'ImageCollection',
];

/**
 * @name SoftwarePageComponent
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.softwarePageBindings} bindings
 * @property {module:avi/upgrade.SoftwarePageController} controller
 * @description
 *
 *     Component for controller software page.
 *
 * @author Zhiqian Liu
 */
angular.module('avi/upgrade').component('softwarePage', {
    controller: SoftwarePageController,
    templateUrl: 'src/components/pages/administration/controller/software-page/' +
        'software-page.component.html',
});
