/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('SSLProfileListController', [
'$scope', 'SSLProfileCollection', '$templateCache', 'CRUDGridConfig',
function($scope, SSLProfileCollection, $templateCache, CRUDGridConfig) {
    const gridConfig = {
        collection: new SSLProfileCollection(),
    };

    gridConfig.fields = [
        {
            name: 'name',
            title: 'Name',
            template: '<span class="sel-name">{{row.getName()}}</span>',
            sortBy: 'name',
        }, {
            name: 'ciphers',
            title: 'Accepted Ciphers',
            className: 'sel-ciphers',
            transform: row => {
                return row.getConfig()['accepted_ciphers'];
            },
        }, {
            name: 'vers',
            title: 'Accepted Versions',
            template: require('./sslprofile-list-grid-accepted-versions.partial.html'),
        },
    ];

    gridConfig.createActions = [{
        label: 'Application Profile',
        action() {
            gridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_PROFILE_TYPE_APPLICATION',
            });

            gridConfig.collection.create();
        },
    }, {
        label: 'System Profile',
        action() {
            gridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_PROFILE_TYPE_SYSTEM',
            });

            gridConfig.collection.create();
        },
    }];

    gridConfig.layout = {
        hideEditColumns: true,
    };

    gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);
