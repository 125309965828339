/**************************************************************************
 *
 * AVI CONFIDENTIAL

 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * Url for licensing data-flow.
 * @type {string}
 * @inner
 */
const url = '/api/licensing';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @description
 *      Service to manage data flow between license page components and server.
 * @author Aravindh Nagarajan
 */
class LicensingService {
    constructor($http) {
        /**
         * @type {angular.$http}
         * @protected
         */
        this.$http_ = $http;
    }

    /**
     * Makes server call to load list of licenses.
     * @return {ng.$q.promise} To be resolved with List of licenses
     */
    loadLicenseList() {
        return this.$http_.get(url)
            .then(({ data }) => data);
    }

    /**
     * Makes server call to delete a license by its id.
     * @param {string} licenseId
     * @return {ng.$q.promise} To be resolved with with a response object
     */
    deleteLicense(licenseId) {
        return this.$http_.delete(`${url}/${licenseId}`);
    }

    /**
     * Parses license file contents and Triggers license file upload server call.
     * @param {string} license License file content
     * @return {ng.$q.promise} To be resolved with with a response object
     */
    uploadLicenseFile(license) {
        const licenseFilePayload = LicensingService.parseLicenseFile_(license);

        return this.$http_.put(url, licenseFilePayload);
    }

    /**
     * Makes call to add a new license by its serial key.
     * @param {string} licenseKey
     * @return {ng.$q.promise} To be resolved with with a response object
     */
    applyLicenseKey(licenseKey) {
        const requestPayload = {
            serial_key: licenseKey,
        };

        return this.$http_.put(url, requestPayload);
    }

    /**
     * Process payload for License file upload request.
     * If argument is a JSON, returns the same.
     * or enclose it in an object and returns.
     * @param {string} license
     * @returns {Object|undefined}
     * @protected
     */
    static parseLicenseFile_(license) {
        try {
            const jsonObj = JSON.parse(license);

            if (jsonObj && typeof jsonObj === 'object' && jsonObj !== null) {
                return jsonObj;
            }
        } catch (errors) {
            return {
                license_text: license,
            };
        }
    }
}

LicensingService.$inject = [
    '$http',
];

angular.module('avi/licensing').service('licensingService', LicensingService);
