/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import {
    portalStatus,
    defaultPortalUrl,
    configUpdateDuration,
} from '../../../../../js/constants/avi-cloud.constants';
import { waitFor } from '../../../../../js/utilities/timer';
import './avi-cloud-page.less';

/**
 * @memberOf module:avi/cportal
 * @class AviCloudPageController
 * @constructor
 * @mixes module:avi/cportal.aviCloudPageBindings
 * @see {@link module:avi/cportal.aviCloudPageComponent}
 */
class AviCloudPageController {
    constructor(
        AviCloudService,
        AviConfirmService,
        AviModal,
    ) {
        this.aviCloudService_ = AviCloudService;
        this.AviConfirmService_ = AviConfirmService;
        this.aviModal_ = AviModal;
        /**
         * Default Avi cloud connected status.
         * @type {boolean}
         */
        this.aviCloudConnected = false;

        /**
         * Default controller registration status.
         * @type {boolean}
         */
        this.controllerRegistered = false;

        /**
         * Default variable for error message.
         * @type {string}
         */
        this.error = '';

        /**
         * Name of existing controller.
         * @type {string}
         */
        this.controllerName = '';

        /**
         * This Url represent Portal which is connected to controller.
         * @type {string}
         */
        this.portalUrl = '';

        /**
         * @type {boolean}
         * @protected
         */
        this.isDestroyed_ = false;
    }

    /**
     * @override
     */
    $onInit() {
        this.controllerName = this.systemInfoService_.getLocalClusterName();
        this.setPortalUrl_();
        this.updateStatus_();
        this.refreshAviCloudStatus_();
    }

    /**
     * Function to set Portal Url.
     * @protected
     */
    setPortalUrl_() {
        this.aviCloudService_.getPortalInfo()
            .then(rsp => this.portalUrl = rsp.portal_url || defaultPortalUrl)
            .catch(error => this.error = error);
    }

    /**
     * Update's Controller registration and Avi cloud connectivity status
     * @protected
     */
    setStatus_() {
        const {
            registration_status: registrationStatus,
            connectivity_status: connectivityStatus,
            error,
        } = this.systemInfoService_.getAviCloudStatus();
        const { AVICLOUD_REGISTERED, AVICLOUD_CONNECTED } = portalStatus;

        this.error = error || '';
        this.controllerRegistered =
            registrationStatus === AVICLOUD_REGISTERED;
        this.aviCloudConnected =
            connectivityStatus === AVICLOUD_CONNECTED;
    }

    /**
     * Function calls systemInfoService_.load(true) passing force=true to get updated data
     * ignoring cache and then it updates registration and connection status.
     * @return {ng.$q.promise}
     * @protected
     */
    updateStatus_(force = false) {
        if (this.isDestroyed_) {
            return;
        }

        if (force) {
            return this.systemInfoService_.load(true)
                .then(() => this.setStatus_());
        }

        return Promise.resolve(this.setStatus_());
    }

    /**
     * Function to de-register Controller with Portal
     */
    deregisterController() {
        const message = 'Are you sure you want to Deregister this controller?';

        this.AviConfirmService_.confirm(message)
            .then(() => {
                this.busy = true;

                this.aviCloudService_.deregisterController()
                    .then(() => waitFor(configUpdateDuration))
                    .then(() => this.updateStatus_(true))
                    .catch(error => this.error = error)
                    .finally(() => this.busy = false);
            });
    }

    /**
     * Function to authenticate user with Portal.
     * @return {ng.$q.promise}
     * @protected
     */
    connectAviCloud_() {
        if (this.aviCloudConnected) {
            // If controller is already connected with portal then promise will
            // be resolved and returned.
            return Promise.resolve();
        } else {
            return this.aviCloudService_.openLoginWindow();
        }
    }

    /**
     * This function authenticates user with the portal and then
     * it connects existing controller with Portal.
     * @return {ng.$q.promise}
     */
    connectAndRegisterController() {
        return this.connectAviCloud_()
            .then(() => this.updateCtrlAndRegistrationDetails_())
            .then(() => this.openRegistrationModal_())
            .catch(error => this.error = error);
    }

    /**
     * This function is used to reconnect controller with portal in case
     * when controller is registered but connection is lost.
     */
    reconnectController() {
        this.busy = true;

        this.connectAviCloud_()
            .then(() => waitFor(configUpdateDuration))
            .then(() => this.updateStatus_(true))
            .catch(error => this.error = error)
            .finally(() => this.busy = false);
    }

    /**
     * Open dialog modal for registraion with opt-in features.
     * @return {ng.$q.promise}
     * @protected
     */
    openRegistrationModal_() {
        if (!this.aviCloudConnected) {
            return Promise.reject();
        }

        return this.aviModal_.open('avi-cloud-modal',
            {
                onComplete: () => this.updateCtrlAndRegistrationDetails_(),
            });
    }

    /**
     * Updates controller and registration details.
     * @return {ng.$q.promise}
     * @protected
     */
    updateCtrlAndRegistrationDetails_() {
        this.busy = true;

        return waitFor(configUpdateDuration)
            .then(() => {
                this.controllerName = this.systemInfoService_.getLocalClusterName();

                return this.updateStatus_(true);
            })
            .finally(() => this.busy = false);
    }

    /**
     * This function returns updated cloud status.
     * @protected
     */
    refreshAviCloudStatus_() {
        this.busy = true;

        this.aviCloudService_.refreshStatus()
            .then(() => waitFor(configUpdateDuration))
            .then(() => this.updateStatus_(true))
            .catch(error => this.error = error)
            .finally(() => this.busy = false);
    }

    /**
     * Hide error message.
     */
    hideErrorMessage() {
        this.error = '';
    }

    /**
     * @override
     */
    $onDestroy() {
        this.isDestroyed_ = true;
    }
}

AviCloudPageController.$inject = [
    'AviCloudService',
    'AviConfirmService',
    'AviModal',
];

/**
 * @memberOf module:avi/cportal
 * @name aviCloudPageComponent
 * @property {module:avi/cportal.AviCloudPageController} controller
 * @property {module:avi/cportal.aviCloudPageBindings} bindings
 * @description
 *      Component used to represent Avi cloud page.
 * @author Ram Pal
 */
angular.module('avi/cportal').component('aviCloudPage', {
    /**
     * @mixin aviCloudPageBindings
     * @memberOf module:avi/cportal
     * @property {systemInfoService} systemInfoService_ System information
     */
    bindings: {
        systemInfoService_: '<loadedSystemInfoService',
    },
    controller: AviCloudPageController,
    templateUrl: 'src/components/pages/administration/settings/avi-cloud-page/avi-cloud-page.html',
});
