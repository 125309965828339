/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './error-page-profile-modal.less';

class ErrorPageProfileModalController {
    static createPageConfig(index = 0, enable = true, bodyRef = '', redirect = '') {
        return {
            index,
            enable,
            match: {
                match_criteria: 'IS_IN',
                status_codes: [],
                ranges: [],
            },
            error_page_body_ref: bodyRef,
            error_redirect: redirect,
            redirect_: !!redirect,
        };
    }

    constructor($window, Schema, Regex, ErrorPageBodyCollection) {
        this.$window_ = $window;
        this.Schema = Schema;
        this.Regex = Regex;
        this.bodyCollection = new ErrorPageBodyCollection();
    }

    addPage() {
        const config = this.editable.getConfig();

        if (!Array.isArray(config.error_pages)) {
            config.error_pages = [];
        }

        config.error_pages.push(
            ErrorPageProfileModalController.createPageConfig(config.error_pages.length),
        );
    }

    removePage(index = 0) {
        if (this.$window_.confirm('Remove page?')) {
            const config = this.editable.getConfig();

            config.error_pages.splice(index, 1);
        }
    }
}

ErrorPageProfileModalController.$inject = [
    '$window',
    'Schema',
    'Regex',
    'ErrorPageBodyCollection',
];

/**
 * @ngdoc component
 * @name errorPageProfileModal
 * @param {ErrorPageProfile} editable
 * @description
 *     Error Page Profile config modal.
 */
angular.module('aviApp').component('errorPageProfileModal', {
    controller: ErrorPageProfileModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/error-page-profile-modal/error-page-profile-modal.html',
});
