/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServiceFQDNCollection
 * @author Alex Malitsky
 * @description
 *
 *     Collection of GSLBServiceFQDN Items, list of which comes from GslbServiceConfig.domain_names.
 *     Needs Loaded GSLB Item to get a list of DNS VSes with names.
 */
angular.module('GSLB.vantage.avi').factory('GSLBServiceFQDNCollection', [
'Collection', 'GSLBServiceFQDN',
function(Collection, GSLBServiceFQDN) {
    class GSLBServiceFQDNCollection extends Collection {
        constructor(args) {
            super(args);

            if (args && args.gslb && args.gslbService) {
                this.gslb = args.gslb;
                this.gslbService = args.gslbService;
            } else {
                console.error(`Can't create GSLBServiceFQDNCollection collection wo GSLB or
                    GSLBService instance`);
            }
        }
    }

    angular.extend(GSLBServiceFQDNCollection.prototype, {
        objectName_: 'GSLBServiceFQDN',
        itemClass_: GSLBServiceFQDN,
        allDataSources_: {
            list: {
                source: 'GSLBServiceFQDNListCollDataSource',
                transformer: 'GSLBServiceFQDNListDataTransformer',
                transport: 'IdentityDataTransport',
                fields: ['config'],
            },
            collection: {
                source: 'CollMetricsCollDataSource',
                transformer: 'CollMetricsDataTransformer',
                transport: 'CollMetricsDataTransport',
                fields: [
                    'dns_client.avg_udp_queries',
                    'dns_client.avg_errored_queries',
                    'dns_client.pct_errored_queries',
                ],
                dependsOn: 'config',
            },
        },
        isStatic_: false,
    });

    return GSLBServiceFQDNCollection;
}]);
