/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('SeGroupInventoryDataTransformer', [
'InventoryDataTransformer', 'Auth',
function(InventoryDataTransformer, Auth) {
    function SeGroupInventoryDataTransformer(args) {
        SeGroupInventoryDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(SeGroupInventoryDataTransformer, InventoryDataTransformer);

    //Need to support old weird data structure
    SeGroupInventoryDataTransformer.prototype.processResponse = function(resp, request) {
        resp = this.responseListOffsetControl_(resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results)) {
            resp.data.results = resp.data.results.map(function(seGroup) {
                if (this.owner_.showTenantName && Auth.context.tenant_ref === '*') {
                    const tenantName = ` (${seGroup.config.tenant_ref.name()})`;

                    seGroup.config.name += tenantName;
                    seGroup.config.url += tenantName;
                }

                return {
                    config: seGroup.config,
                    inventory: seGroup,
                };
            }, this);
        }

        return resp;
    };

    return SeGroupInventoryDataTransformer;
}]);
