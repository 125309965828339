/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const WAF_ACTION_ALLOW_PARAMETER = 'WAF_ACTION_ALLOW_PARAMETER';

const WafPolicyFactory = (
    ObjectTypeItem,
    WafRuleGroupConfigItem,
    $q,
    WafPolicyPsmGroupCollection,
) => {
    class WafPolicy extends ObjectTypeItem {
        /**
         * @constructor
         */
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_WAFPOLICY',
                objectType: 'WafPolicy',
            };

            super(extendedArgs);

            if (this.getConfig()) {
                this._ruleIdToRuleHash = this._getRuleIdToRuleHash();
                this._groupNameToGroupHash = this._getGroupNameToGroupHash();
            }

            /**
             * Reference to a new PSM group, if one needs to be created for a WAF Policy with
             * enable_app_learning set to true.
             */
            this.newPsmGroup_ = null;
        }

        /**
         * @override
         */
        urlToSave() {
            return '/api/macro';
        }

        /**
         * After save, PSM groups_refs_data objects are returned outside of the WafPolicy data as
         * part of the rsp.data array. We need to merge them back into config.
         * @override
         */
        transformDataAfterSave(rsp) {
            const { data: responses } = rsp;

            // If response contains array of objects, the last object is the one that we need
            if (!Array.isArray(responses)) {
                return responses;
            }

            const { length: responseLength } = responses;
            const wafPolicyData = responses[responseLength - 1];

            // No groups_refs_data.
            if (responseLength === 1) {
                return wafPolicyData;
            }

            const { positive_security_model: psm } = wafPolicyData;
            const { group_refs: psmGroupRefs = [] } = psm;
            const groupRefDataHash = responses.reduce((acc, groupRefData, index) => {
                if (index === responseLength - 1) {
                    return acc;
                }

                // _last_modified is returned in the rsp. If we send _last_modified within the
                // groupRefData, the backend will complain.
                delete groupRefData._last_modified;
                acc[groupRefData.url] = { ...groupRefData };

                return acc;
            }, {});

            if (psmGroupRefs.length) {
                psm.group_refs_data = psmGroupRefs.map(groupRef => groupRefDataHash[groupRef]);
            }

            return wafPolicyData;
        }

        /**
         * @override
         */
        transformAfterLoad() {
            this._ruleIdToRuleHash = this._getRuleIdToRuleHash();
            this._groupNameToGroupHash = this._getGroupNameToGroupHash();
        }

        /**
         * @override
         */
        beforeEdit() {
            const { whitelist, positive_security_model: psm } = this.getConfig();

            if (_.isEmpty(whitelist)) {
                this.setNewChildByField_('whitelist');
            }

            if (_.isEmpty(psm)) {
                this.setNewChildByField_('positive_security_model');
            }
        }

        /**
         * @override
         */
        createSaveRequestPayload_(dataToSave) {
            return {
                model_name: 'wafpolicy',
                data: dataToSave,
            };
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(super.dataToSave());
            const { positive_security_model: psm = {} } = config;

            // We want to delete the group_refs since we're working with the group_refs_data.
            delete psm.group_refs;

            return config;
        }

        /**
         * Returns a name to be used for the PSM Learning Group.
         * @returns {string}
         */
        getNewPsmGroupName() {
            const wafPolicyName = this.getName();

            return `${wafPolicyName || ''}-Learning-Group`;
        }

        /**
         * Returns a config object for a new PSM Learning Group. When a user sets
         * 'enable_app_learning' to true on a WAF Policy, and the WAF Policy doesn't contain a PSM
         * Group with 'is_learning_group' enabled, we need to create a new PSM Group with
         * 'is_learning_group' enabled. This function is called to create that new PSM Group config.
         * @protected
         * @returns {Object}
         */
        createNewPsmLearningGroup_() {
            const wafPolicyPsmGroupCollection = new WafPolicyPsmGroupCollection();
            const newPsmGroup = wafPolicyPsmGroupCollection.createNewItem();
            const { config: newPsmGroupConfig } = newPsmGroup;

            newPsmGroupConfig.name = this.getNewPsmGroupName();
            newPsmGroupConfig.is_learning_group = true;
            newPsmGroupConfig.hit_action = WAF_ACTION_ALLOW_PARAMETER;

            return newPsmGroup;
        }

        /**
         * Checks a list of PSM Group config items and returns true if any of them are learning
         * groups.
         * @param {WafPolicyPSMGroup[]} psmGroups - List of PSM group config items.
         * @returns {boolean}
         */
        static hasLearningGroup(psmGroups = []) {
            return _.any(psmGroups, group => group.isLearningGroup);
        }

        /**
         * Returns true if any PSM Groups are learning groups.
         * @returns {boolean}
         */
        hasLearningGroup() {
            const { positive_security_model: psm } = this.getConfig();
            const { group_refs_data: psmGroups } = psm.config;

            return WafPolicy.hasLearningGroup(psmGroups);
        }

        /**
         * Adds or removes a new PSM Learning Group config to the list of group_refs_data, depending
         * on enable_app_learning.
         */
        setPsmLearningGroup() {
            if (this.appLearning && !this.hasLearningGroup()) {
                this.addPsmLearningGroup();
            } else if (!this.appLearning && this.newPsmGroup_) {
                this.removePsmLearningGroup();
            }
        }

        /**
         * Adds a PSM Learning Group to the WAF Policy.
         */
        addPsmLearningGroup() {
            const { positive_security_model: psm } = this.getConfig();

            this.newPsmGroup_ = this.createNewPsmLearningGroup_();
            psm.config.group_refs_data.push(this.newPsmGroup_);
        }

        /**
         * Removes a PSM Learning Group from the WAF Policy.
         */
        removePsmLearningGroup() {
            const { positive_security_model: psm } = this.getConfig();
            const { group_refs_data: groupRefsData } = psm.config;
            const newPsmGroupIndex = _.findIndex(groupRefsData, this.newPsmGroup_);

            groupRefsData.splice(newPsmGroupIndex, 1);
            this.newPsmGroup_ = null;
        }

        /**
         * If the user sets enable_app_learning to true and a new learning group was created, we
         * want to update the PSM Learning Group name as the user changes the WAF Policy name.
         */
        setNewPsmGroupName() {
            if (!this.newPsmGroup_) {
                return;
            }

            this.newPsmGroup_.config.name = this.getNewPsmGroupName();
        }

        /**
         * Returns a hash of all rule IDs to Rules within the Waf Policy. Used for lookup.
         * @return {Object}
         */
        _getRuleIdToRuleHash() {
            const output = {};
            const config = this.getConfig();

            WafPolicy.groupsProperties.forEach(groupProperty => {
                const repeatedGroupConfigItems = config[groupProperty];

                if (!repeatedGroupConfigItems || repeatedGroupConfigItems.isEmpty()) {
                    return;
                }

                repeatedGroupConfigItems.config.forEach(groupConfigItem => {
                    angular.extend(output, groupConfigItem.getRuleIdHash());
                });
            });

            return output;
        }

        /**
         * Returns a hash of all group names to Groups within the Waf Policy. Used for lookup.
         * @return {Object}
         */
        _getGroupNameToGroupHash() {
            const output = {};
            const config = this.getConfig();

            WafPolicy.groupsProperties.forEach(groupProperty => {
                const repeatedGroupConfigItems = config[groupProperty];

                if (!repeatedGroupConfigItems || repeatedGroupConfigItems.isEmpty()) {
                    return;
                }

                repeatedGroupConfigItems.config.forEach(groupConfigItem => {
                    output[groupConfigItem.getName()] = groupConfigItem;
                });
            });

            return output;
        }

        /**
         * Returns a WafRuleGroupConfig ConfigItem given the group name.
         * @param {string} groupName - Name of the group. The name is unique within the Waf
         *     Policy.
         * @return {WafRuleGroupConfig}
         */
        getGroupByGroupName(groupName) {
            return this._groupNameToGroupHash[groupName];
        }

        /**
         * Returns a WafRuleConfig ConfigItem given a rule ID.
         * @param {string} ruleId - Rule ID, unique within the Waf Policy.
         * @return {WafRuleConfig}
         */
        getRuleByRuleId(ruleId) {
            return this._ruleIdToRuleHash[ruleId];
        }

        /**
         * Returns the name of a rule given a rule ID. If rule is not found, returns the rule
         * ID.
         * @param {string} ruleId - Rule ID, unique within the Waf Policy.
         * @param {boolean=} fullName
         * @return {string}
         */
        getRuleNameByRuleId(ruleId, fullName) {
            const ruleConfigItem = this.getRuleByRuleId(ruleId);

            return ruleConfigItem ? ruleConfigItem.getName(fullName) : '';
        }

        /**
         * Returns the max index of all the groups within config[groupsProperty].
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @return {number}
         */
        getMaxGroupIndex(groupsProperty) {
            const config = this.getConfig();
            const maxIndexGroup = _.max(config[groupsProperty].config, group => group.getIndex());

            return !_.isEmpty(maxIndexGroup) && maxIndexGroup.getIndex() + 1 || 0;
        }

        /**
         * Returns a new WafRuleGroupConfig instance.
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @param {WafRuleGroupConfig=} group - If defined, create above that group.
         * @return {WafRuleGroupConfig}
         */
        addNewGroup(groupsProperty, groupToCreateAbove) {
            const { [groupsProperty]: repeatedGroup } = this.getConfig();
            const maxIndexGroup = _.max(repeatedGroup.config, group => group.getIndex());
            const newIndex = !_.isEmpty(maxIndexGroup) ? maxIndexGroup.getIndex() + 1 : 0;
            const newGroup = this.createChildByField_(
                groupsProperty,
                { index: newIndex },
                true,
                { newGroup: true },
            );

            repeatedGroup.add(newGroup);

            if (groupToCreateAbove instanceof WafRuleGroupConfigItem) {
                const targetIndex = groupToCreateAbove.getIndex();
                const targetArrayIndex = this.getArrayIndexFromGroupIndex(
                    groupsProperty,
                    targetIndex,
                );

                const currentArrayIndex = repeatedGroup.config.length - 1;

                this.moveGroup(groupsProperty, currentArrayIndex, targetArrayIndex);
            }
        }

        /**
         * Removes a group.
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @param {WafRuleGroupConfig} group
         * @memberof WafPolicy
         */
        removeGroup(groupsProperty, group) {
            const { [groupsProperty]: repeatedGroup } = this.getConfig();
            const index = repeatedGroup.config.indexOf(group);

            if (index > -1) {
                repeatedGroup.remove(index);
            }
        }

        /**
         * Returns the array position index from the group.index property.
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @param {number} groupIndex - Index from group.index.
         * @return {number}
         */
        getArrayIndexFromGroupIndex(groupsProperty, groupIndex) {
            const { [groupsProperty]: repeatedGroup } = this.getConfig();

            return _.findIndex(repeatedGroup.config, group => group.getIndex() === groupIndex);
        }

        /**
         * Moves group to a new index. All groups in-between need to have their indices shifted.
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @param {number} oldIndex - Index of the original position of the group.
         * @param {number} newIndex - Index of the new position.
         */
        moveGroup(groupsProperty, oldIndex, newIndex) {
            let newIndexCounter = newIndex;
            /**
             * newIndex moves towards the direction of oldIndex
             */
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndexCounter) {
                this.swapGroup(groupsProperty, oldIndex, newIndexCounter);
                newIndexCounter += increment;
            }
        }

        /**
         * Given two indices of groups, swaps positions in the rules array along with the index
         * property in the rule.
         * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapGroup(groupsProperty, oldIndex, newIndex) {
            const { [groupsProperty]: repeatedGroup } = this.getConfig();

            const oldGroup = repeatedGroup.at(oldIndex);
            const newGroup = repeatedGroup.at(newIndex);

            repeatedGroup.config[oldIndex] = newGroup;
            repeatedGroup.config[newIndex] = oldGroup;

            /**
             * Actual 'index' property of the rule.
             */
            const oldIndexValue = oldGroup.getIndex();
            const newIndexValue = newGroup.getIndex();

            oldGroup.setIndex(newIndexValue);
            newGroup.setIndex(oldIndexValue);
        }

        /**
         * Adds a group to the WafPolicy. If the group is a new group and does not contain an
         * index, add it to the end of the list. Otherwise, replace an existing group with the
         * group as it has been edited.
         * @param {WafRuleGroupConfig} newGroup
         */
        saveGroup(newGroup) {
            const fieldName = newGroup.getFieldName();
            const { [fieldName]: repeatedGroup } = this.getConfig();

            if (_.isUndefined(newGroup.getIndex())) {
                const maxIndexGroup = _.max(repeatedGroup.config, group => group.getIndex());
                const newIndex = !_.isEmpty(maxIndexGroup) ? maxIndexGroup.getIndex() + 1 : 0;

                newGroup.setIndex(newIndex);
                repeatedGroup.add(newGroup);
            } else {
                const newIndex = newGroup.getIndex();
                const oldIndex = _.findIndex(repeatedGroup.config, group => {
                    return group.getIndex() === newIndex;
                });

                // Index exists, so rule is being edited.
                if (oldIndex !== -1) {
                    repeatedGroup.config[oldIndex] = newGroup;
                } else {
                    repeatedGroup.add(newGroup);
                }
            }
        }

        /**
         * Returns the ConfigItem based on the type and identifier.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Identifier, either the group name or rule ID.
         * @return {ConfigItem|null}
         */
        getConfigItemById(type, id) {
            return type === 'rule' ? this.getRuleByRuleId(id) : this.getGroupByGroupName(id);
        }

        /**
         * Returns true if the group or rule already contains the exception.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Identifier, either the group name or rule ID.
         * @param {Object} exception - Exception to check.
         * @return {boolean}
         */
        hasMatchingException(type, id, exception) {
            const configItem = this.getConfigItemById(type, id);

            return configItem.hasMatchingException(exception);
        }

        /**
         * Adds an exception to the group or rule.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Identifier, either the group name or rule ID.
         * @param {Object} exception - Exception to add.
         */
        addException(type, id, exception) {
            const configItem = this.getConfigItemById(type, id);

            configItem.addExcludeListEntry(_.pick(exception, _.identity));
        }

        /**
         * Returns true if policy mode delegation is allowed.
         * @return {boolean}
         */
        modeDelegationIsAllowed() {
            return Boolean(this.getConfig()['allow_mode_delegation']);
        }

        /**
         * Returns policy mode.
         * @return {string} - WafMode enum type.
         */
        getMode() {
            return this.getConfig().mode;
        }

        /**
         * Returns true if the rule group is editable.
         * @param {WafRuleGroupConfigItem} group
         * @returns {boolean}
         */
        allowEditingGroup(group) {
            return group.getFieldName() !== 'crs_groups';
        }

        /**
         * Updates the WafPolicy with a set of CRS rules.
         * @param {string} wafCrsUuid - uuid of the wafcrs object.
         * @param {boolean} commit - True to commit the CRS update. False to just preview.
         */
        updateCrsRules(wafCrsUuid, commit = false) {
            const wafPolicyUuid = this.id;
            const api = `/api/wafpolicy/${wafPolicyUuid}/update-crs-rules`;
            const payload = {
                waf_crs_ref: wafCrsUuid,
                commit,
            };

            this.busy = true;
            this.errors = null;

            return this.request('PUT', api, payload)
                .catch(({ data }) => {
                    this.errors = data.error;

                    return $q.reject(data.error);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Getter for the whitelist property.
         * @return {WafPolicyWhitelist}
         */
        get whitelist() {
            return this.getConfig().whitelist;
        }

        /**
         * Returns the number of configured whitelist rules.
         * @returns {number}
         */
        getWhitelistRulesCount() {
            return this.whitelist.getRulesCount();
        }

        /**
         * Returns a new WafPolicyWhitelistRule instance.
         * @returns {WafPolicyWhitelistRule}
         */
        createNewWhiteListRule() {
            return this.whitelist.createNewRule();
        }

        /**
         * Adds a whitelist rule to the config.
         * @param {WafPolicyWhitelistRule} rule
         */
        addWhitelistRule(rule) {
            this.whitelist.addRule(rule);
        }

        /**
         * Getter for the positive_security_model config object.
         * @returns {WafPositiveSecurityModel}
         */
        get psm() {
            return this.getConfig().positive_security_model;
        }

        /**
         * Getter for the 'enable_app_learning' flag.
         * @returns {boolean}
         */
        get appLearning() {
            return this.getConfig().enable_app_learning;
        }

        /**
         * Setter for the 'enable_app_learning` flag.
         * @param {enableAppLearning} [false=] - Value to set.
         */
        set appLearning(enableAppLearning = false) {
            this.getConfig().enable_app_learning = enableAppLearning;
        }
    }

    Object.assign(WafPolicy.prototype, {
        objectName: 'wafpolicy',
        windowElement: 'waf-policy-modal',
        params: {
            include_name: true,
            join: 'wafpolicypsmgroup:positive_security_model.group_refs',
        },
    });

    WafPolicy.groupsProperties = [
        'pre_crs_groups',
        'crs_groups',
        'post_crs_groups',
    ];

    return WafPolicy;
};

WafPolicyFactory.$inject = [
    'ObjectTypeItem',
    'WafRuleGroupConfigItem',
    '$q',
    'WafPolicyPsmGroupCollection',
];

/**
 * @ngdoc factory
 * @name  WafPolicy
 * @description  WafPolicy item.
 */
angular.module('aviApp').factory('WafPolicy', WafPolicyFactory);
