/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {{
 *    name: string
 *    groupby: string
 *    data: Array<{name: string, count: number}>
 * }} WafHitGroup
 */

class VSWafTopHitsController {
    constructor(
            Regex,
            VsWafTopHits,
            wafExceptionState,
            getSubnetObject,
            AviAlertService,
            VsWafTopHitsDataSource,
            AviModal,
            myAccount,
    ) {
        this._Regex = Regex;
        this.VsWafTopHits_ = VsWafTopHits;
        this._wafExceptionState = wafExceptionState;
        this._getSubnetObject = getSubnetObject;
        this._AviAlertService = AviAlertService;
        this._VsWafTopHitsDataSource = VsWafTopHitsDataSource;
        this.aviModal_ = AviModal;
        this._myAccount = myAccount;

        /**
         * @type {WafHitGroup[]}
         */
        this.hitGroups = [{
            type: 'group',
        }, {
            type: 'rule',
        }, {
            type: 'tag',
        }, {
            type: 'clientIp',
            showTotal: true,
        }, {
            type: 'path',
            showTotal: true,
        }, {
            type: 'matchElement',
            showTotal: true,
        }, {
            name: 'Popular Combinations',
            type: 'combination',
        }];

        this.hitGroups.forEach(groupSettings => {
            if (!('name' in groupSettings)) {
                const { type } = groupSettings;

                groupSettings.name = VsWafTopHitsDataSource.propertyLabelsByType[type];
            }
        });
    }

    $onInit() {
        this.topHits = new this.VsWafTopHits_({ vsId: this.vs.id });
        this.topHits.load();
    }

    $onDestroy() {
        this.topHits.destroy();
    }

    /**
     * When a WafRuleGroupConfig or WafRuleConfig of the WafPolicy needs to have its enable
     * state toggled, we must make a save request for the WafPolicy.
     */
    handleWafPolicyChildToggle() {
        const wafPolicy = this.vs.getWAFPolicy();

        wafPolicy.save().then(() => wafPolicy.load());
    }

    /**
     * Sets and resets record's filters on topHits instance.
     * @param {Object} record - List entry.
     * @public
     */
    setFilter(record) {
        const { topHits } = this;

        if (topHits.isBusy()) {
            return;
        }

        this.topHits.setFilter(
            record.filters,
        );

        this.topHits.load();
    }

    /**
     * @public
     */
    removeFilters() {
        const { topHits } = this;

        if (topHits.isBusy()) {
            return;
        }

        if (topHits.noActiveFilters()) {
            return;
        }

        topHits.removeFilters();
        topHits.load();
    }

    /**
     * Opens a preview modal which logs list matching active filters.
     * @public
     */
    openLogsList() {
        this.aviModal_.open('waf-top-hits-logs-list-modal', {
            filtersHash: this.topHits.getActiveFiltersHash(),
            vsId: this.vs.id,
        });
    }

    /**
     * Checks whether passed record filters are used by the topHits instance.
     * @param {Object} record - List entry.
     * @returns {boolean}
     * @public
     */
    isFilterActive(record) {
        return this.topHits.isFilterActive(record.filters);
    }

    /**
     * Returns true if the preview button should be enabled.
     * @return {boolean}
     */
    allowPreviewException() {
        const activeFiltersHash = this.topHits.getActiveFiltersHash();

        const {
            group: GROUP_NAME,
            rule: RULE_ID,
            clientIp: CLIENT_IP,
            path: URI_PATH,
            matchElement: MATCH_ELEMENT,
        } = this._VsWafTopHitsDataSource.fieldNameToPropertyName;

        if (_.isEmpty(activeFiltersHash)) {
            return false;
        }

        const exceptionProps = [
                CLIENT_IP,
                URI_PATH,
                MATCH_ELEMENT,
        ];

        const wafPolicy = this.vs.getWAFPolicy();

        const
            { [RULE_ID]: ruleId } = activeFiltersHash,
            hasRule = ruleId && wafPolicy.getRuleByRuleId(ruleId);

        const
            { [GROUP_NAME]: groupName } = activeFiltersHash,
            hasGroup = groupName && wafPolicy.getGroupByGroupName(groupName);

        const hasException = _.any(exceptionProps,
            prop => !angular.isUndefined(activeFiltersHash[prop]));

        return (hasRule || hasGroup) && hasException;
    }

    /**
     * Returns an object containing id, type, and exception properties based on the
     *     activeFiltersHash.
     * @return {Object}
     */
    getSelectedExceptionProps() {
        const activeFiltersHash = this.topHits.getActiveFiltersHash();
        const {
            group: GROUP_NAME,
            rule: RULE_ID,
            clientIp: CLIENT_IP,
            path: URI_PATH,
            matchElement: MATCH_ELEMENT,
        } = this._VsWafTopHitsDataSource.fieldNameToPropertyName;

        const exception = {
            uri_path: activeFiltersHash[URI_PATH],
            match_element: activeFiltersHash[MATCH_ELEMENT],
        };

        const clientIp = activeFiltersHash[CLIENT_IP];

        if (clientIp) {
            const subnet = this._Regex.subnet.test(clientIp) ? clientIp : `${clientIp}/32`;

            exception.client_subnet = this._getSubnetObject(subnet);
        }

        let type;
        let id;

        if (!angular.isUndefined(activeFiltersHash[RULE_ID])) {
            type = 'rule';
            id = activeFiltersHash[RULE_ID];
        } else {
            type = 'group';
            id = activeFiltersHash[GROUP_NAME];
        }

        return {
            type,
            id,
            exception,
        };
    }

    /**
     * Returns true if the WafPolicy already contains the exception.
     * @return {boolean}
     */
    wafHasException() {
        const { type, id, exception } = this.getSelectedExceptionProps();

        return this.vs.getWAFPolicy().hasMatchingException(type, id, exception);
    }

    /**
     * Returns true if the wafExceptionState already contains the exception.
     * @return {boolean}
     */
    wafPreviewHasException() {
        const { type, id, exception } = this.getSelectedExceptionProps();

        return this._wafExceptionState.exceptionAlreadyExists(type, id, exception);
    }

    /**
     * Click handler for adding an exception to the preview.
     */
    addExceptionPreview() {
        const { type, id, exception } = this.getSelectedExceptionProps();

        if (this.wafHasException()) {
            this._AviAlertService.throw('The WAF Policy already contains this exception.');
        } else {
            this._wafExceptionState.addException(type, id, exception);
            this._myAccount.toggleSidebar(true);
        }
    }
}

VSWafTopHitsController.$inject = [
    'Regex',
    'VsWafTopHits',
    'wafExceptionState',
    'getSubnetObject',
    'AviAlertService',
    'VsWafTopHitsDataSource',
    'AviModal',
    'myAccount',
];

/**
 * @ngdoc component
 * @name vsWafTopHits
 * @params {VirtualService} vs
 * @description
 *     Displays top WAF hits from VS Logs API.
 */
angular.module('aviApp').component('vsWafTopHits', {
    bindings: {
        vs: '<',
    },
    controller: VSWafTopHitsController,
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/' +
            'vs-waf-top-hits/vs-waf-top-hits.html',
});
