/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const matchTargetConfigItemFactory = MatchExtendableMessageItem => {
    /**
     * @alias module:config-item/MatchTargetConfigItem
     * @private
     */
    class MatchTargetConfigItem extends MatchExtendableMessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'MatchTarget',
                ...args,
            };

            super(extendedArgs);
        }
    }

    return MatchTargetConfigItem;
};

matchTargetConfigItemFactory.$inject = [
    'MatchExtendableConfigItem',
];

/**
 * @ngdoc factory
 * @name  MatchTargetConfigItem
 * @description  MatchTarget ConfigItem class.
 * @module config-item/MatchTargetConfigItem
 * @author alextsg
 */
angular.module('policies.vantage.avi')
    .factory('MatchTargetConfigItem', matchTargetConfigItemFactory);
