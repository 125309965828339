/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const controllerSiteFactory = Item => {
    class ControllerSite extends Item {
        /**
         * Return data#config#address of this controller site.
         * @return {string}
         */
        getAddress() {
            return this.getConfig().address;
        }
    }

    Object.assign(ControllerSite.prototype, {
        objectName: 'controllersite',
    });

    return ControllerSite;
};

controllerSiteFactory.$inject = [
    'Item',
];

/**
 * @ngdoc factory
 * @name  ControllerSite
 * @description  ControllerSite item.
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory('ControllerSite', controllerSiteFactory);
