/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  SSLProfile
 * @description
 *     SSLProfile item.
 */
const SSLProfileFactory = (
        Item,
        Schema,
) => {
    class SSLProfile extends Item {
        /**
         * Map cipher enum data to cipher object list.
         * @return {Object[]}
         */
        static getCipherObjectList() {
            const cipherObjectList = _.map(Schema.enums.AcceptedCipherEnums.values,
                (val, key) => {
                    return {
                        cipher: key,
                        description: val.options.text.value,
                        performance:
                            val.options.ssl_performance.value.enumeration('SSL_SCORE_'),
                        compatibility:
                            val.options.ssl_compatibility.value.enumeration('SSL_SCORE_'),
                        security:
                            val.options.ssl_security.value.enumeration('SSL_SCORE_'),
                        securityScore:
                            val.options.ssl_security_score &&
                                val.options.ssl_security_score.value,
                        enabled: false,
                        pfs: key.indexOf('ECDHE') > -1,
                    };
                });

            return cipherObjectList;
        }

        /**
         * Returns SSL Profile type.
         * @return {string} - enum: SSLProfileType
         */
        getType() {
            return this.getConfig().type;
        }

        /**
         * Returns enabled cipher list based on cipher enum cache.
         * @return {string[]} - enum: AcceptedCipherEnums
         * @protected
         */
        getEnabledCipherList_() {
            const cipherEnums = [],
                { cipher_enums_tmp: tmpCipherEnums } = this.getConfig();

            tmpCipherEnums.forEach(cipher => {
                if (cipher.enabled) {
                    cipherEnums.push(cipher.cipher);
                }
            });

            return cipherEnums;
        }

        /**
         * Resets enabled flag through all ciphers we have in the cipher cache list.
         * @param {boolean} init - True if called when cipher list is being initiated.
         * @protected
         */
        resetCipherList_(init) {
            const
                config = this.getConfig(),
                { cipher_enums: cipherEnums } = config;
            let editableCiphersHash = {};

            config.cipher_enums_tmp = SSLProfile.getCipherObjectList();

            const { cipher_enums: defaultCipherEnums } = this.getDefaultConfig();

            if (init && cipherEnums) {
            //create a dictionary of ciphers we have in editable
                editableCiphersHash = _.invert(cipherEnums);
            } else {
            //create a dictionary of ciphers by default values
                editableCiphersHash = _.invert(defaultCipherEnums);
            }

            config['cipher_enums_tmp'].forEach(cipher => {
                cipher.enabled = cipher.cipher in editableCiphersHash;
            });

            // Sort first based on if it's enabled or not, and if enabled, sort by index found
            // in the Hash. If not enabled, then sort by Security Score.
            config['cipher_enums_tmp'].sort((a, b) => {
                if (a.enabled < b.enabled) {
                    return 1;
                } else if (a.enabled > b.enabled) {
                    return -1;
                } else if (a.enabled && b.enabled) {
                    if (+editableCiphersHash[a.cipher] < +editableCiphersHash[b.cipher]) {
                        return -1;
                    } else {
                        return 1;
                    }
                }

                if (a.securityScore < b.securityScore) {
                    return 1;
                } else if (a.securityScore > b.securityScore) {
                    return -1;
                }

                return 0;
            });
        }

        /**
         * Restores default cipher string.
         * @protected
         */
        resetCipherString_() {
            const config = this.getConfig();

            config['accepted_ciphers'] = this.getDefaultConfig()['accepted_ciphers'];
        }

        /**
         * Make changes on ciphers based on cipher types.
         * @param {string} newType - Name of the new cipher type.
         */
        onCipherTypeChange(newType) {
            const config = this.getConfig();

            if (newType === 'list') {
                delete config.accepted_ciphers;
                delete config.tls13_ciphers;

                this.resetCipherList_();
            } else {
                delete config.cipher_enums;
                delete config.cipher_enums_tmp;

                this.resetCipherString_();
            }
        }

        /**
         * Handler for profile type change.
         * @param {string} type - selected SSL profile type
         */
        onProfileTypeChange(type) {
            const config = this.getConfig();

            config.type = type;
        }

        /**
         * Updates Config when SSL Session reuse checkbox is changed.
         * If checkbox is unchecked,
         * delete the SSL Session Timeout value.
         */
        onSslSessionReuseChange() {
            const config = this.getConfig();

            if (!config.enable_ssl_session_reuse) {
                delete config.ssl_session_timeout;
            }
        }

        /**
         * Updates the accepted Versions list.
         * @param {string[]} versions - List of selected accepted versions.
         */
        updateAcceptedVersions(versions) {
            const config = this.getConfig();

            config.accepted_versions = versions;
        }

        /**
         * Backend translates list of ciphers (enums) into string and provides both but want to
         * support only one type in time - either list or string.
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();
            const { accepted_versions: acceptedVersions } = config;

            if (!_.isUndefined(config.cipher_enums)) { //gonna be list tab
                delete config.accepted_ciphers;
                delete config.tls13_ciphers;
                // init cipher list
                this.resetCipherList_(true);
            }

            config.accepted_versions = _.pluck(acceptedVersions, 'type');

            return config;
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());
            const acceptedVersions = angular.copy(config.accepted_versions);

            if (_.isUndefined(config.accepted_ciphers)) {
                config.cipher_enums = this.getEnabledCipherList_();
            } else {
                delete config.cipher_enums;
            }

            config.accepted_versions = acceptedVersions.reduce((result, version) => {
                result.push({ type: version });

                return result;
            }, []);

            delete config.cipher_enums_tmp;

            return config;
        }
    }

    Object.assign(SSLProfile.prototype, {
        objectName: 'sslprofile',
        windowElement: 'ssl-tls-profile-modal',
    });

    return SSLProfile;
};

SSLProfileFactory.$inject = [
        'Item',
        'Schema',
];

angular.module('aviApp').factory('SSLProfile', SSLProfileFactory);
