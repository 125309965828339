/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ServerCollection
 * @author Alex Malitsky
 * @description
 *
 *     Pool's Servers collection.
 *     Can be loaded from Pool's inventory pool-inventory/poolId/server API or across many
 *     Pools, through pool-inventory/server API or from {@link PoolServersHsScatterMetric} when
 *     used with a {@link scatterPlotAndTable | Pool servers HealthScore ScatterPlot chart}.
 *
 */
angular.module('aviApp').factory('ServerCollection', [
'Collection', 'Server', 'PoolServersHsScatterMetric',
function(Collection, Server, PoolServersHsScatterMetric) {
    const
        allDataSources = {
            list: {//main, across pools
                source: 'PoolServerInventoryCollDataSource',
                transformer: 'PoolServerInventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config', 'health', 'runtime'],
            },
            'collection-metrics': {
                source: 'CollMetricsCollDataSource',
                transformer: 'CollMetricsDataTransformer',
                transport: 'CollMetricsDataTransport',
                fields: [
                    'l4_server.avg_bandwidth',
                    'l4_server.max_open_conns',
                    'l4_server.avg_total_rtt',
                    'l7_server.avg_complete_responses',
                    'l7_server.avg_error_responses',
                    'l7_server.pct_response_errors',
                ],
                dependsOn: 'config',
            },
        },
        fromHSScatterPlotMetricListDSConfig = {//from metric
            source: 'PoolServerFromHSScatterPlotMetricInventoryCollDataSource',
            transformer: 'RevisedDataTransformer',
            transport: 'IdentityDataTransport',
        },
        forSinglePoolListDSConfig = {//all servers of a single pool
            source: 'OnePoolServerInventoryCollDataSource',
        };

    class ServerCollection extends Collection {
        constructor(args = {}) {
            const
                forScatterPlot = args.HSScatterPlotMetric instanceof PoolServersHsScatterMetric,
                singlePool = 'poolId' in args;

            args.allDataSources = angular.copy(allDataSources);

            const { list: listDSConfig } = args.allDataSources;

            if (forScatterPlot) {
                angular.extend(listDSConfig, fromHSScatterPlotMetricListDSConfig);
            } else if (singlePool) {
                angular.extend(listDSConfig, forSinglePoolListDSConfig);
            } else { //page_size on a pool level
                args.limit = 200;
            }

            super(args);

            if (forScatterPlot) {
                this.HSScatterPlotMetric = args.HSScatterPlotMetric;
            } else if (singlePool) {
                this.poolId = args.poolId;
            }
        }

        /**
         * When working with HS ScatterPlot as a source of server inventory list we need to
         * filter all servers by selection on the chart. This method sets a list of Servers
         * which can be present in Collection. Such list will be used while processing the whole
         * list of servers gotten from the {@link PoolServersHsScatterMetric}.
         * @param {Server#id[]=} serverIds - List of server ids which are selected by user on the
         *     ScatterPlot chart.
         */
        setWhiteList(serverIds) {
            this.singleDataSourceMethodCall_(
                this.getDataSourceByFieldName('list'),
                'setServerWhiteList',
                [serverIds], //passing args array
            );
        }
    }

    angular.extend(ServerCollection.prototype, {
        itemClass_: Server,
        objectName_: 'pool-inventory',
        defaultDataSources_: 'list',
        isStatic_: false,
    });

    return ServerCollection;
}]);
