/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
/**
 * @alias module:component/errorPage
 * @private
 */
class ErrorPageComponent {
    constructor(
        $state,
        AviModal,
        AviMessage,
        errorPageService,
        windowLocation,
        getLocationWoHash,
    ) {
        this.$state_ = $state;
        this.AviModal_ = AviModal;
        this.AviMessage_ = AviMessage;
        this.errorPageService_ = errorPageService;
        this.windowLocation_ = windowLocation;
        this.getLocationWoHash_ = getLocationWoHash;

        this.statusCode = NaN;
        this.progressPercentage = NaN;
    }

    $onInit() {
        this.AviMessage_.destroyAll();
        this.AviModal_.destroyAll();

        const { statusCode } = this.$state_.current.data;

        this.statusCode = statusCode;

        this.errorPageService_.startPollingClusterState(this.clusterStateHandler_);

        if (statusCode === 503) {
            //TODO: reserved for new upgrade status polling call
        }
    }

    /**
     * Getter for the cluster polling active status.
     * @returns {boolean}
     */
    get pollingActive() {
        return this.errorPageService_.pollingActive;
    }

    /**
     * Getter for the upgrade active status.
     * @returns {boolean}
     */
    get upgradeActive() {
        return this.errorPageService_.upgradeActive;
    }

    /**
     * Handler called on every successful cluster poll request. Updates the progress percentage and
     * redirects the user to the login page if the controller is up.
     */
    clusterStateHandler_ = clusterState => {
        if (!clusterState) {
            return;
        }

        const { state, progress } = clusterState;

        this.progressPercentage = progress;

        if (state && _.isString(state) && !state.indexOf('CLUSTER_UP')) {
            //going to the default UI page with hard refresh
            this.windowLocation_.replace(this.getLocationWoHash_(true));
        }
    }

    $onDestroy() {
        this.errorPageService_.cancelRequests();
    }
}

ErrorPageComponent.$inject = [
    '$state',
    'AviModal',
    'AviMessage',
    'errorPageService',
    'windowLocation',
    'getLocationWoHash',
];

/**
 * @ngdoc component
 * @name ErrorPageComponent
 * @author alextsg
 * @module components/errorPage
 * @desc
 *
 *     Error page component. It is continuously polling controller's status updating the
 *     progress percentage and when done redirects user to the main UI page with a full page
 *     reload.
 */
angular.module('aviApp').component('errorPage', {
    controller: ErrorPageComponent,
    templateUrl: 'src/components/pages/error-page/error-page.html',
});
