/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './segroup-license-selector.less';

class SegroupLicenseSelectorController {
    constructor(
        $http,
        $timeout,
        $element,
        schemaService,
        dropDownUtils,
        Cloud,
        SEGroupFlavorCollection,
        SEGroup,
    ) {
        this.$http = $http;
        this.$timeout = $timeout;
        this.$element = $element;
        this.schemaService = schemaService;
        this.dropDownUtils = dropDownUtils;
        this.Cloud = Cloud;
        this.SEGroupFlavorCollection = SEGroupFlavorCollection;
        this.SEGroup = SEGroup;

        this.azureLicenseCostsUrl = 'https://azuremarketplace.microsoft.com/en-us/marketplace/' +
            'apps/avi-networks.avi-vantage-adc';
    }

    $onInit() {
        this.$element.addClass('segroup-license-selector');

        /**
         * Prop to display all flavors, or recommended only,
         * if applicable to current cloud.
         * @type {boolean}
         */
        this.showNonRecommendedFlavors = false;

        this.setSEBandwidthTypes_();

        if (this.supportsFlavors()) {
            this.createFlavorsCollection_();
        }
    }

    /**
     * Creates Flavors Collection if SEGroup supports flavors.
     * @protected
     */
    createFlavorsCollection_() {
        this.flavorsCollection = new this.SEGroupFlavorCollection({
            cloudId: this.cloud.id,
        });

        this.updateFlavorsParams();
    }

    /**
     * Triggers timeout function to force ng-change fn to end of event loop.
     * Done because ng-change fires before ng-model is changed.
     * https://github.com/angular/angular.js/issues/4558
     * @param {boolean} radioChange - if licence type (PAYG/BYOL) is toggled
     */
    updateFlavorsParams(radioChange) {
        this.$timeout(this.updateFlavorParams_.bind(this, radioChange), 0);
    }

    /**
     * Updates collection's params to be used in API call for flavors.
     * @param {boolean} radioChange - if licence type (PAYG/BYOL) is toggled
     * @protected
     */
    updateFlavorParams_(radioChange) {
        if (radioChange) {
            this.setSEBandwidthTypes_();
        }

        this.flavorsCollection.reset();

        const
            { segroup } = this,
            params = {
                license_type: segroup.getLicenseType(),
            };

        if (segroup.isMeteredLicenseType()) {
            params.show_only_recommended = !this.showNonRecommendedFlavors;
        }

        if (segroup.licenseTypeIsSeBandwidth()) {
            params.se_usage_type = segroup.getSeBandwidthType();
        }

        this.flavorsCollection.setParams(params);
    }

    /**
     * Called on init or on license model radio-button change (BYOL/PAYG),
     * in order to format seBandwidthTypes list.
     * @protected
     */
    setSEBandwidthTypes_() {
        const isMetered = this.segroup.isMeteredLicenseType();

        let bandwidthTypes = this.schemaService.getEnumValues('SEBandwidthType');

        if (isMetered) {
            const
                { allowedMeteredBandwidthTypes } = this.SEGroup,
                allowedHash = _.invert(allowedMeteredBandwidthTypes),
                { segroup } = this;

            bandwidthTypes = bandwidthTypes.filter(({ value }) => value in allowedHash);

            if (!(segroup.getSeBandwidthType() in allowedHash)) {
                [segroup.getConfig()['se_bandwidth_type']] = allowedMeteredBandwidthTypes;
            }
        } // no filtering needed for non metered

        /**
         * List of seBandwidth types.
         * @type {DropDownOption[]}
         */
        this.seBandwidthTypes = bandwidthTypes
            .map(({ value, label }) => this.dropDownUtils.createOption(value, label));
    }

    /**
     * Decides whether to show checkbox; if PAYG licence model is selected.
     * @returns {boolean}
     */
    showAllFlavorsCheckbox() {
        return this.supportsFlavors() && this.segroup.isMeteredLicenseType();
    }

    /**
     * Informs if current cloud for this SEGroup supports flavors.
     * @returns {boolean}
     */
    supportsFlavors() {
        switch (this.cloud.getVtype()) {
            case 'CLOUD_AZURE':
            case 'CLOUD_AWS':
            case 'CLOUD_OPENSTACK':
            case 'CLOUD_GCP':
                return true;
            default:
                return false;
        }
    }

    /**
     * Shows dropdown selection for seBandwidth if applicable.
     * @returns {boolean}
     */
    showSeBandwidthDropdown() {
        return this.segroup.licenseTypeIsSeBandwidth();
    }

    /**
     * Informs if currently selected license_type for SEGroup is of type CORES.
     * @returns {boolean}
     */
    coresLicenseTypeSelected() {
        return this.segroup.licenseTypeIsCores();
    }

    /**
     * Decides when to disable user from selecting flavor.
     * @returns {boolean}
     */
    disableFlavorSelection() {
        const { segroup } = this;

        return !segroup.getLicenseType() ||
            (this.showSeBandwidthDropdown() && !segroup.getSeBandwidthType());
    }

    /** @override */
    $onDestroy() {
        if (this.flavorsCollection) {
            this.flavorsCollection.destroy();
        }
    }
}

SegroupLicenseSelectorController.$inject = [
    '$http',
    '$timeout',
    '$element',
    'schemaService',
    'dropDownUtils',
    'Cloud',
    'SEGroupFlavorCollection',
    'SEGroup',
];

/**
 * @ngdoc component
 * @name SegroupLicenseSelector
 * @param {Cloud} cloud - Cloud Instance
 * @param {SEGroup} segroup - SEGroup Instance
 * @description
 *     Component for selecting licensing info and flavors for SEGroup instance.
 */
angular.module('aviApp').component('segroupLicenseSelector', {
    bindings: {
        cloud: '<',
        segroup: '<',
    },
    controller: SegroupLicenseSelectorController,
    templateUrl: 'src/components/infrastructure/segroup/segroup-license-selector' +
                 '/segroup-license-selector.html',
});
