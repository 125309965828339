/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name wafRule
 * @description Component for configuring a WAF rule.
 * @params {boolean} editMode - Group edit mode. When truthy or set to true later expands the
 *     rule and vice versa.
 * @params {boolean} allowEdit
 */
class WafRuleController {
    $onInit() {
        this.expanded = false;

        if (this.editMode) {
            this.expanded = true;
        }
    }

    /**
     * Called when Show/Hide Rule is clicked.
     */
    handleExpand() {
        this.expanded = !this.expanded;
    }

    /**
     * Called when the trash icon is clicked to remove a rule.
     */
    handleRemoveRule() {
        this.onRemoveRule();
    }

    /**
     * Handler for clicking the rule checkbox within a group. Sets the group enable flag based
     * on its rules.
     */
    handleEnableRuleChange() {
        const { rule } = this;
        const enabled = rule.isEnabled();

        rule.setEnabledState(!enabled);

        if (this.onEnableRuleChange) {
            this.onEnableRuleChange();
        }
    }

    /**
     * Handler for clicking the rule textarea.
     * @param {jQuery} $event
     */
    handleFocusRule($event) {
        if (!this.editMode) {
            $event.target.select();
        }
    }

    /**
     * Returns the tooltip for the icon next to the rule name.
     * @return {string}
     */
    getIconTooltipText() {
        const hasExclude = this.rule.hasExcludeListEntries();
        const hasCustom = this.rule.hasCustomMode();

        if (hasExclude && hasCustom) {
            return 'This rule has a custom mode and exceptions configured.';
        }

        if (hasExclude) {
            return 'This rule has exceptions configured.';
        }

        if (hasCustom) {
            return 'This rule has a custom mode configured.';
        }
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.editMode) {
            const { currentValue: editMode } = changes.editMode;

            this.expanded = editMode;
        }
    }
}

angular.module('aviApp').component('wafRule', {
    controller: WafRuleController,
    bindings: {
        rule: '<',
        editMode: '<',
        allowEdit: '<',
        onEnableRuleChange: '&?',
        onRemoveRule: '&',
        showGrayTriSwitch: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule/waf-rule.html',
});
