/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('VirtualService', [
'$q', 'UpdatableItem', 'RangeParser', 'PoolCollection', 'HTTPRedirectAction',
'AviAlertService', 'PoolGroupCollection', 'DNS', 'HttpPolicySet', 'NetworkSecurityPolicy',
'VsVip', 'DnsPolicyContainerConfig', 'createItemByRef', 'DnsRecordConfig', 'Item',
'WafPolicy', 'Cloud', 'ApplicationProfile', 'defaultValues',
function($q, UpdatableItem, RangeParser, PoolCollection, HTTPRedirectAction,
AviAlertService, PoolGroupCollection, DNS, HttpPolicySet, NetworkSecurityPolicy, VsVip,
DnsPolicyContainerConfig, createItemByRef, DnsRecordConfig, Item, WafPolicy, Cloud,
ApplicationProfile, defaultValues) {
    const VirtualService = function(oArgs) {
        VirtualService.superconstructor.call(this, oArgs);

        if (this.id) {
            const referredBy = `virtualservice:${this.id}`;

            this.pools = new PoolCollection({
                isStatic: true,
                params: {
                    referred_by: referredBy,
                },
            });

            this.poolgroups = new PoolGroupCollection({
                isStatic: true,
                limit: 1000,
                params: {
                    referred_by: referredBy,
                },
            });
        }

        this.httpPolicySets = [new HttpPolicySet()];
        this.networkSecurityPolicy = new NetworkSecurityPolicy();

        if (this.getConfig()) {
            this.transformAfterLoad();
        }
    };

    avi.inherit(VirtualService, UpdatableItem);

    const { prototype: proto } = VirtualService;

    proto.objectName = 'virtualservice';
    proto.windowElement = 'app-vs-create';
    proto.detailsStateName_ =
        'authenticated.application.virtualservice-detail';

    proto.params = {
        include_name: true,
        join: [
            'network_profile_ref',
            'server_network_profile_ref',
            'application_profile_ref',
            'pool_ref',
            'pool_group_ref',
            'cloud_ref',
            'network_security_policy_ref',
            'ssl_profile_ref',
            'ssl_key_and_certificate_refs',
            'http_policies.http_policy_set_ref',
            'vsvip_ref',
            'dns_policies.dns_policy_ref',
            'topology_policies.dns_policy_ref',
            'networkprofile:services.override_network_profile_ref',
            'waf_policy_ref',
            'wafpolicypsmgroup:waf_policy_ref_data.positive_security_model.group_refs',
        ].join(),
    };

    /**
     * Names of property in #data.config with the same type DnsPolicies in protobuf.
     * @type {string[]}
     */
    proto.dnsPoliciesPropNames = ['dns_policies', 'topology_policies'];

    /**
     * Generic dataToSave method for all DnsPolicies properties.
     */
    proto.dnsPoliciesDataToSave = function(config, propertyName) {
        if (config[propertyName] && config[propertyName].length > 0) {
            const policies = [];

            config[propertyName].forEach((dnsPolicyContainerConfig, i) => {
                const index = 11 + i;

                // set index if it's not set yet
                if (!dnsPolicyContainerConfig.hasIndex()) {
                    dnsPolicyContainerConfig.setIndex(index);
                }

                // set name if it's not set yet
                if (!dnsPolicyContainerConfig.getPolicyName()) {
                    let policyPrefix = '';

                    switch (propertyName) {
                        case 'dns_policies':
                            policyPrefix = 'DNS-Policy';
                            break;
                        case 'topology_policies':
                            policyPrefix = 'Topology-Policy';
                            break;
                    }

                    const name =
                        `${this.getName()}-${this.getCloudRef().name()}-${policyPrefix}-${i}`;

                    dnsPolicyContainerConfig.setPolicyName(name);
                }

                const policiesObj = dnsPolicyContainerConfig.dataToSave();

                policies.push(policiesObj);
            });

            config[propertyName] = policies;
        }
    };

    /**
     * Generic transformAfterLoad method for all DnsPolicies properties.
     */
    proto.dnsPoliciesTransformAfterLoad = function(config, propertyName) {
        const { [propertyName]: policiesData } = config;

        if (policiesData) {
            if (Array.isArray(policiesData) && policiesData.length) {
                config[propertyName] = policiesData.map(dnsPolicyObj => {
                    let policyContainer = dnsPolicyObj;

                    if (!(dnsPolicyObj instanceof DnsPolicyContainerConfig)) {
                        policyContainer = new DnsPolicyContainerConfig({
                            data: { config: dnsPolicyObj },
                        });
                    }

                    return policyContainer;
                });
            }
        } else {
            config[propertyName] = [new DnsPolicyContainerConfig()];
        }
    };

    proto.dataToSave = function() {
        const data = angular.copy(this.getConfig());

        const refDataToDrop = [
            'application_profile_ref_data',
            'network_profile_ref_data',
            'server_network_profile_ref_data',
            'waf_policy_ref_data',
            'pool_group_ref_data',
            'ssl_profile_ref_data',
            'ssl_key_and_certificate_refs_data',
            'cloud_ref_data',
        ];

        refDataToDrop.forEach(propName => delete data[propName]);

        // basic create uses pool_ref_data to create Pool for the new VS
        if (this.id) {
            delete data['pool_ref_data'];
        }

        // Filter out services with no port number, removes layout parameters
        data.services = _.reject(data.services, function(service) {
            const portIsNotDefined = !service.port;

            if (!portIsNotDefined) {
                service.port = +service.port;

                delete service.nwOverride;
                delete service.override_network_profile_ref_data;
            }

            return portIsNotDefined;
        });

        // If SSL is not enabled in any of the services (ports) and if the type is not
        // Child, need to remove ssl_profile_ref & ssl_key_and_certificate_refs.
        if (!this.sslEnabled() && data.type !== 'VS_TYPE_VH_CHILD') {
            delete data.ssl_profile_ref;

            if (data.type !== 'VS_TYPE_VH_CHILD') {
                delete data.ssl_key_and_certificate_refs;
            }
        }

        // transform sample_uris
        if (data.analytics_policy && data.analytics_policy.client_insights_sampling) {
            const { sample_uris } = data.analytics_policy.client_insights_sampling;

            sample_uris.string_group_refs = [];
            sample_uris.match_str = [];

            if (sample_uris.tmp.data) {
                if (sample_uris.tmp.type == 'group') {
                    sample_uris.string_group_refs.push(sample_uris.tmp.data);
                } else if (sample_uris.tmp.type == 'custom') {
                    _.each(sample_uris.tmp.data.split(','), function(item) {
                        sample_uris.match_str.push(item.trim());
                    });
                }

                delete sample_uris.tmp;
            }

            if (!sample_uris.string_group_refs.length && !sample_uris.match_str.length) {
                delete data.analytics_policy.client_insights_sampling.sample_uris;
            }

            // transform skip_uris
            const { skip_uris } = data.analytics_policy.client_insights_sampling;

            skip_uris.string_group_refs = [];
            skip_uris.match_str = [];

            if (skip_uris.tmp && skip_uris.tmp.data) {
                if (skip_uris.tmp.type == 'group') {
                    skip_uris.string_group_refs.push(skip_uris.tmp.data);
                } else if (skip_uris.tmp.type == 'custom') {
                    _.each(skip_uris.tmp.data.split(','), function(item) {
                        skip_uris.match_str.push(item.trim());
                    });
                }

                delete skip_uris.tmp;
            }

            if (!skip_uris.string_group_refs.length && !skip_uris.match_str.length) {
                delete data.analytics_policy.client_insights_sampling.skip_uris;
            }

            // transform client_ip
            const { client_ip } = data.analytics_policy.client_insights_sampling;

            client_ip.match_criteria = 'IS_IN';
            client_ip.group_refs = [];
            client_ip.addrs = [];
            client_ip.ranges = [];
            client_ip.prefixes = [];

            if (client_ip.tmp.data) {
                if (client_ip.tmp.type == 'group') {
                    client_ip.group_refs.push(client_ip.tmp.data);
                } else if (client_ip.tmp.type == 'custom') {
                    _.each(client_ip.tmp.data.split(','), function(item) {
                        const rangeOrIp = RangeParser.ipRange2Json(item.trim());

                        if (rangeOrIp) {
                            if (rangeOrIp.begin) { // assume this is range
                                client_ip.ranges.push(rangeOrIp);
                            } else if (rangeOrIp.mask) { // assume prefix
                                client_ip.prefixes.push(rangeOrIp);
                            } else { // otherwise ip
                                client_ip.addrs.push(rangeOrIp);
                            }
                        }
                    });
                }

                delete client_ip.tmp;
            }

            if (!client_ip.group_refs.length && !client_ip.addrs.length &&
                !client_ip.ranges.length && !client_ip.prefixes.length) {
                delete data.analytics_policy.client_insights_sampling.client_ip;
            }
        }

        //QoS block, no Limit for DOS attacks when no limit was ser for max connections per clientIP
        if (!data.max_cps_per_client) {
            data.limit_doser = false;
        }

        const appType = this.appType();

        if (this.httpPolicySets.length > 0 && appType !== 'dns') {
            const policySets = [];

            this.httpPolicySets.forEach((policySet, i) => {
                let index = 11 + i;

                if (Array.isArray(data.http_policies)) {
                    const prevConfig = data.http_policies[i];

                    if ('index' in prevConfig) {
                        index = prevConfig.index;
                    }
                }

                const httpPolicySetData = angular.copy(policySet.dataToSave());

                if (httpPolicySetData) {
                    const policy = {
                        http_policy_set_ref_data: {},
                        index,
                    };

                    if (!httpPolicySetData.name) {
                        policy.http_policy_set_ref_data.name =
                            `${this.getName()}-${data.cloud_ref.name()}-HTTP-Policy-Set-${i}`;
                    }

                    angular.extend(policy.http_policy_set_ref_data, httpPolicySetData);
                    policySets.push(policy);
                }
            });

            data.http_policies = policySets;
        }

        const networkSecurityPolicyData = this.networkSecurityPolicy.dataToSave();

        if (networkSecurityPolicyData) {
            let policyConfigData = data.network_security_policy_ref_data;

            if (!policyConfigData) {
                data.network_security_policy_ref_data = {};
                policyConfigData = data.network_security_policy_ref_data;
            }

            //creation
            if (!networkSecurityPolicyData.name) {
                policyConfigData.name = `vs-${this.getName()}-${data.cloud_ref.name()}-ns`;
            }

            angular.extend(policyConfigData, networkSecurityPolicyData);
        }

        if (this.isDNS()) {
            this.dnsPoliciesPropNames.forEach(propName => {
                this.dnsPoliciesDataToSave(data, propName);
            });
        } else {
            this.dnsPoliciesPropNames.forEach(propName => {
                delete data[propName];
            });
        }

        /**
         * If creating a new Virtual Service and the user has configured a 'vsvip_ref', this VS
         * will be sharing a VsVip object and 'vsvip_ref_data' needs to be deleted or a new VsVip
         * will be created.
         */
        if (!this.id && !angular.isUndefined(data.vsvip_ref)) {
            delete data.vsvip_ref_data;
        } else {
            data.vsvip_ref_data.setName(`vsvip-${this.getName()}-${data.cloud_ref.name()}`);
            data.vsvip_ref_data = data.vsvip_ref_data.dataToSave();
        }

        delete data.vip;
        delete data.dns_info;
        delete data.fqdn;
        delete data.east_west_placement;

        // virtualservice of type VH_APP shouldn't have ip_address
        if (data.type === 'VS_TYPE_VH_CHILD') {
            delete data.vsvip_ref_data;
            delete data.services;
        } else {
            delete data.vh_domain_name;
            delete data.vh_parent_vs_ref;
        }

        if (data.client_auth) {
            // fixup the request-uri-path for backend format
            if (data.client_auth.request_uri_path) {
                const { request_uri_path: reqUriPath } = data.client_auth;

                reqUriPath.string_group_refs = [];
                reqUriPath.match_str = [];

                if (reqUriPath.tmp && reqUriPath.tmp.data) {
                    if (reqUriPath.tmp.type == 'group') {
                        reqUriPath.string_group_refs.push(reqUriPath.tmp.data);
                    } else if (reqUriPath.tmp.type == 'custom') {
                        _.each(reqUriPath.tmp.data.split(','), function(item) {
                            reqUriPath.match_str.push(item.trim());
                        });
                    }

                    delete reqUriPath.tmp;
                }

                if (!reqUriPath.string_group_refs.length && !reqUriPath.match_str.length) {
                    delete data.client_auth.req_uri_path;
                }
            }
        }

        //removes l7 rate limiter for all types of VS but http
        if (data.requests_rate_limit && !this.isHTTP()) {
            data.requests_rate_limit = undefined;
        }

        //removes invalid null from optional field 'period' when 'count' is 0
        if (data.connections_rate_limit && !data.connections_rate_limit.count &&
            data.connections_rate_limit.period === null) {
            data.connections_rate_limit.period = undefined;
        }

        if (data.requests_rate_limit) {
            if (!data.requests_rate_limit.count && data.requests_rate_limit.period === null) {
                data.requests_rate_limit.period = undefined;
            }

            if (data.requests_rate_limit.action && data.requests_rate_limit.action.redirect &&
                data.requests_rate_limit.action.type === 'RL_ACTION_REDIRECT') {
                HTTPRedirectAction.beforeSave(data.requests_rate_limit.action.redirect);
            }
        }

        if (this.pool && this.pool.autoPopulatedServers()) {
            delete data.pool_ref_data.servers;
        }

        if (this.getDefaultNetProfileType() === 'PROTOCOL_TYPE_TCP_FAST_PATH') {
            delete data['server_network_profile_ref'];
        }

        if (appType !== 'dns') {
            delete data['static_dns_records'];
            delete data['dns_policies'];
        } else if (angular.isArray(data.static_dns_records)) {
            data.static_dns_records = Item._filterRepeatedInstances(data.static_dns_records);
        }

        if (!this.isHTTP()) {
            delete data['sso_policy_ref'];
        }

        if (!this.hasDataScriptSupport()) {
            delete data.vs_datascripts;
        }

        let url = '/api/virtualservice';

        if (this.id) {
            url += `/${this.id}`;
        }

        if (data.sharedVipVS) {
            delete data.sharedVipVS;
        }

        return {
            uri_path: url,
            data,
        };
    };

    proto.urlToSave = function() {
        return '/api/macro';
    };

    /**
     * After submitting call to check for issues in the VS
     * @return {ng.$q.promise}
     * @override
     */
    proto.submit = function() {
        // Make call to check if there are issues with this VS
        //not sure if we need to return this promise or not
        return VirtualService.superclass.submit.call(this)
            .then(() => {
                this.checkForIssues();
            });
    };

    /**
     * Makes runtime call and checks if the current VS has any issues to be resolved
     * If there is resolution for the issue the corresponding window will be opened.
     * @returns {ng.$q.promise}
     */
    proto.checkForIssues = function() {
        return this.request('get', `/api/virtualservice/${this.id}/runtime`)
            .then(({ data }) => {
                const params = { runtime: data };

                const
                    { oper_status: operStatus } = data,
                    netIssueCodes = [];

                let { reason_code_string: code } = operStatus;

                switch (operStatus.state) {
                    case 'OPER_AWAIT_MANUAL_PLACEMENT':
                        return this.edit('app-vs-placevs', params);

                    case 'OPER_RESOURCES':
                        netIssueCodes.push(
                            'SYSERR_RM_STATIC_NO_POOL',
                            'SYSERR_RM_STATIC_POOL_EXHAUSTED',
                            'SYSERR_RM_SE_MGMT_NO_STATIC_IPS_CONFIGURED',
                            'SYSERR_RM_SE_MGMT_STATIC_IPS_EXHAUSTED',
                        );

                        if (_.contains(netIssueCodes, code)) {
                            code = 'SYSERR_RM_SRVR_MULT_NETWORKS';
                        }

                        if (code) {
                            try {
                                return this.edit(`app-vs-resolution-${code}`, params);
                            } catch (e) {
                                //when there is no corresponding modal template
                                //exception gonna be logged by angular
                                return $q.reject(e);
                            }
                        }
                }

                return $q.when('VS has no errors to resolve');
            });
    };

    //  FIXME: switch enable toggling to PATCH requests or better special API called
    //  /vs/vsId/retryplacement
    proto.disableAndEnable = function() {
        const self = this;

        if (!this.id) {
            return $q.reject({
                error: 'VS id is missing',
            });
        }

        const api = `/api/${self.objectName}/${self.id}`;

        return this.request('get', api)
            .then(function({ data }) {
                // Disable VS
                data.enabled = false;

                return self.request('put', api, data);
            }).then(function({ data }) {
                // Enable it back
                data.enabled = true;

                return self.request('put', api, data);
            }).catch(function(rsp) {
                return $q.reject(rsp);
            });
    };

    proto.beforeEdit = function() {
        const
            data = this.getConfig(),
            promises = [];

        if (!('analytics_policy' in data)) {
            data.analytics_policy = this.getDefaultConfig_()['analytics_policy'];
        }

        // compile sample_uris in client_insights
        if (!data.analytics_policy.client_insights_sampling) {
            data.analytics_policy.client_insights_sampling = {};
        }

        if (!data.analytics_policy.client_insights_sampling.sample_uris) {
            data.analytics_policy.client_insights_sampling.sample_uris = {
                match_criteria: 'BEGINS_WITH',
            };
        }

        const { sample_uris } = data.analytics_policy.client_insights_sampling;

        if (sample_uris.string_group_refs && sample_uris.string_group_refs.length) {
            sample_uris.tmp = {
                type: 'group',
                data: sample_uris.string_group_refs[0],
            };
        } else {
            sample_uris.tmp = {
                type: 'custom',
                data: sample_uris.match_str ? sample_uris.match_str.join(', ') : '',
            };
        }

        // compile skip_uris
        if (!data.analytics_policy.client_insights_sampling) {
            data.analytics_policy.client_insights_sampling = {};
        }

        if (!data.analytics_policy.client_insights_sampling.skip_uris) {
            data.analytics_policy.client_insights_sampling.skip_uris = {
                match_criteria: 'BEGINS_WITH',
            };
        }

        const { skip_uris } = data.analytics_policy.client_insights_sampling;

        if (skip_uris.string_group_refs && skip_uris.string_group_refs.length) {
            skip_uris.tmp = {
                type: 'group',
                data: skip_uris.string_group_refs[0],
            };
        } else {
            skip_uris.tmp = {
                type: 'custom',
                data: skip_uris.match_str ? skip_uris.match_str.join(', ') : '',
            };
        }

        // compile client_ip
        if (!data.analytics_policy.client_insights_sampling) {
            data.analytics_policy.client_insights_sampling = {};
        }

        if (!data.analytics_policy.client_insights_sampling.client_ip) {
            data.analytics_policy.client_insights_sampling.client_ip = { match_criteria: 'IS_IN' };
        }

        const { client_ip } = data.analytics_policy.client_insights_sampling;

        if (client_ip.group_refs && client_ip.group_refs.length) {
            client_ip.tmp = {
                type: 'group',
                data: client_ip.group_refs[0],
            };
        } else {
            client_ip.tmp = {
                type: 'custom',
                data: _.map(client_ip.addrs, function(o) { return o.addr; })
                    .concat(_.map(client_ip.ranges, function(o) {
                        return `${o.begin.addr}-${o.end.addr}`;
                    }))
                    .concat(_.map(client_ip.prefixes, function(o) {
                        return `${o.ip_addr.addr}/${o.mask}`;
                    }))
                    .join(', '),
            };
        }

        // init datascripts
        if (!data.vs_datascripts) {
            data.vs_datascripts = [];
        }

        // initialize http basic auth info
        if (data.client_auth && data.client_auth.type === 'HTTP_BASIC_AUTH') {
            this.data.enable_basic_auth = true;

            if (!data.client_auth.request_uri_path) {
                data.client_auth.request_uri_path = { match_criteria: 'BEGINS_WITH' };
            }

            // convert StringMatch to internal representation
            const { request_uri_path: reqUriPath } = data.client_auth;

            if (reqUriPath.string_group_refs && reqUriPath.string_group_refs.length) {
                reqUriPath.tmp = {
                    type: 'group',
                    data: reqUriPath.string_group_refs[0],
                };
            } else {
                reqUriPath.tmp = {
                    type: 'custom',
                    data: reqUriPath.match_str ? reqUriPath.match_str.join(', ') : '',
                };
            }
        } else {
            this.data.enable_basic_auth = false;
        }

        //Untockenize the host and path fields for the redirect action
        if (data.requests_rate_limit) {
            if (data.requests_rate_limit.action && data.requests_rate_limit.action.redirect &&
                data.requests_rate_limit.action.type === 'RL_ACTION_REDIRECT') {
                HTTPRedirectAction.beforeEdit(data.requests_rate_limit.action.redirect);
            }
        }

        if ('services' in data) {
            data.services.forEach(service => {
                // plainly UI property for the form checkbox "NW profile override"
                service.nwOverride = !!service.override_network_profile_ref;

                if (!('port_range_end' in service)) {
                    service.port_range_end = service.port;
                }

                // we assume hasXXXNetProfile method is not used within edit mode
                delete service.override_network_profile_ref_data;
            });
        } else {
            data.services = [];
        }

        data.vsvip_ref_data.beforeEdit();

        if (angular.isArray(data.static_dns_records)) {
            data.static_dns_records.forEach(dnsRecord => dnsRecord.beforeEdit());
        }

        $q.all(promises)
            .finally(this.setPristine.bind(this));
    };

    /**
     * Returns true for HTTP(S) VSes only.
     * @returns {boolean}
     * @public
     */
    proto.isHTTP = function() {
        return this.appType() === 'http';
    };

    /**
     * Returns true for HTTPS VSes. Requires appProfile data loaded.
     * @returns {boolean}
     */
    proto.isHTTPS = function() {
        if (this.isHTTP()) {
            const { http_profile: httpProfile } = this.getAppProfileConfig();

            return ApplicationProfile
                .getHttpProfileSslEverywhereEnabledValue(httpProfile);
        }

        return false;
    };

    /**
     * Returns true for VS having DNS application profile.
     * @returns {boolean}
     * @public
     */
    proto.isDNS = function() {
        return this.appType() === 'dns';
    };

    /**
     * Determines what kind of VS is it. Usually returns meaningful part of application profile
     * type, but for app types 'ssl' and 'l4' returns part of the main network profile type.
     * @returns {string} - Empty string if not set/loaded.
     * @public
     */
    proto.appType = function() {
        //provided by inventory API only
        const
            { app_profile_type: appProfileTypeFromInventory } = this.data,
            appProfile = this.getAppProfileConfig(),
            appProfileType = appProfile ? appProfile['type'] : appProfileTypeFromInventory;

        if (!appProfileType) {
            return '';
        }

        return appProfileType.slice('APPLICATION_PROFILE_TYPE_'.length).toLowerCase();
    };

    /**
     * Returns true if dataScripts can be attached to a virtual service.
     * @return {boolean}
     */
    proto.hasDataScriptSupport = function() {
        const appProfileType = this.appType();

        return appProfileType !== 'sip';
    };

    /**
     * Returns true for VSes with SSL app profile type or HTTP VS with at least one service
     * using SSL.
     * @returns {boolean}
     * @public
     */
    //FIXME doesn't work for VS_TYPE_VH_CHILD VSes since they don't have services list
    proto.sslEnabled = function() {
        const config = this.getConfig();

        if (config) {
            if (this.appType() === 'ssl') {
                return true;
            } else if (this.isHTTP()) {
                return _.some(config.services, service => service['enable_ssl']);
            }
        }

        return false;
    };

    proto.policyEnabled = function() {
        return _.some(this.httpPolicySets, policySet => policySet.hasRules());
    };

    /**
     * Ignores response argument and tangles with Item.data.config.
     * @override
     */
    proto.transformAfterLoad = function() {
        this.transformAfterLoad_(this.getConfig());
    };

    /**
     * Updates passed object as expected and returns it back.
     * @params {Item.data.config}
     * @returns {Item.data.config}
     * @protected
     */
    proto.transformAfterLoad_ = function(config) {
        if (!('services' in config)) {
            config['services'] = [];
        }

        const { http_policies: httpPolicies } = config;

        if (Array.isArray(httpPolicies)) {
            this.httpPolicySets = httpPolicies.map(httpPolicy => {
                const { http_policy_set_ref_data: httpPolicyData } = httpPolicy;
                const httpPolicySet = new HttpPolicySet();

                httpPolicySet.updateItemData(
                    angular.copy({
                        config: httpPolicyData,
                    }),
                );

                return httpPolicySet;
            });
        }

        const { network_security_policy_ref_data: netSecurityPolicyData } = config;

        if (netSecurityPolicyData) {
            this.networkSecurityPolicy.updateItemData(
                angular.copy({ config: netSecurityPolicyData }),
            );
        }

        this.dnsPoliciesPropNames.forEach(propName => {
            this.dnsPoliciesTransformAfterLoad(config, propName);
        });

        if (config['waf_policy_ref_data'] &&
            !(config['waf_policy_ref_data'] instanceof WafPolicy)) {
            config['waf_policy_ref_data'] = new WafPolicy(
                {
                    data: {
                        config: config.waf_policy_ref_data,
                    },
                },
            );
        }

        const { static_dns_records: dnsRecords } = config;

        if (dnsRecords) {
            config['static_dns_records'] = dnsRecords.map(dnsRecord => {
                if (dnsRecord instanceof DnsRecordConfig) {
                    return dnsRecord;
                }

                return new DnsRecordConfig({ data: { config: dnsRecord } });
            });
        }

        if (!(config['vsvip_ref_data'] instanceof VsVip)) {
            config['vsvip_ref_data'] = new VsVip({ data: { config: config.vsvip_ref_data } });
        }

        return config;
    };

    proto.saveRequest = function() {
        return VirtualService.superclass.saveRequest.call(this)
            .then(({ data }) => {
                const action = this.id ? 'edit' : 'create';

                if (action === 'create') {
                    //get id from the last object if it's missing
                    this.id = data.slice(-1)[0].uuid;
                }

                return this.load()
                    .then(([configRsp]) => {
                        if (action === 'create') {
                            this.id = null;
                        }

                        return configRsp;
                    });
            });
    };

    /**
     * Makes request to get the lists of candidate service engines and hosts.
     * @param  {string} vipId - vip_id of the VIP to scale out.
     * @return {Object} Object containing service engines and hosts.
     */
    proto.getCandidateServiceEnginesAndHosts = function(vipId) {
        const api =
            `/api/${this.objectName}/${this.id}/candidatesehostlist?include_name&vip_id=${vipId}`;

        return this.request('GET', api)
            .then(({ data }) => {
                if (Array.isArray(data) && data.length) {
                    return data[0];
                }

                return {};
            });
    };

    /**
     * Makes a request to scale out the VIP address.
     * @param  {Object} payload - Contains the params for scaling out the VIP.
     * @return {ng.$q.promise}
     */
    proto.scaleOut = function(payload) {
        return this.request('POST', `/api/virtualservice/${this.id}/scaleout`, payload);
    };

    /**
     * Makes a request to scale in the VIP address.
     * @param  {Object} payload - Contains the params for scaling in the VIP.
     * @return {ng.$q.promise}
     */
    proto.scaleIn = function(payload) {
        return this.request('POST', `/api/virtualservice/${this.id}/scalein`, payload);
    };

    /**
     * Makes a request to migrate the VIP address.
     * @param  {Object} payload - Contains the params for migrating the VIP.
     * @return {ng.$q.promise}
     */
    proto.migrate = function(payload) {
        return this.request('POST', `/api/virtualservice/${this.id}/migrate`, payload);
    };

    /**
     * Deletes children associated with VS being deleted.
     * @param  {string[]} refs - Array of reference URLs used for deletion.
     * @return {ng.$q.promise}
     * @protected
     */
    proto.dropChildren_ = function(refs) {
        const deleteRequests = refs.map(ref => this.request('delete', ref));

        return $q.all(deleteRequests).catch(errors => AviAlertService.throw(errors.data));
    };

    /** @override */
    proto.drop = function(force, params) {
        let promise = VirtualService.superclass.drop.call(this, force, params);

        if (angular.isObject(params) && params.withChildren) {
            const children = [];
            const { poolgroups, pools } = this.data;

            if (angular.isArray(poolgroups) && poolgroups.length) {
                children.push(poolgroups);
            }

            if (angular.isArray(pools) && pools.length) {
                children.push(pools);
            }

            children.forEach(childRefs => {
                promise = promise.then(() => this.dropChildren_(childRefs));
            });
        }

        return promise;
    };

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     * @param fields - Array of fields that has to be loaded
     * @return {*} - Promise
     */
    proto.loadRequest = function(aFields) {
        return $q.all([
            this.loadConfig(aFields),
            this.loadEventsAndAlerts(aFields),
            this.loadMetrics(aFields),
        ]);
    };

    /**
     * Transforming the response of loadConfig right away, not waiting for loadRequest to go
     * through.
     * @override
     **/
    proto.onConfigLoad_ = function(rsp) {
        const res = VirtualService.superclass.onConfigLoad_.call(this, rsp);

        this.transformAfterLoad();

        return res;
    };

    /**
     * Checks if this VS has specific Network Security Policy rules called 'secure application'.
     * @param {boolean=} isEnabled - When true we should check verify rules to be enabled.
     * @param {boolean=} canBePartial - When true we will return true even if only one of two
     *     rules has been found.
     * @returns {boolean} - True if rules (or rule when canBePartial is true) with certain {@link
     *     NetworkSecurityPolicy._secAppRuleIndexes index values and properties} are present for
     *     this VS.
     */
    proto.hasSecureAppRule = function(isEnabled, canBePartial) {
        return this.networkSecurityPolicy.hasSecureAppRule(isEnabled, canBePartial);
    };

    /**
     * Checks whether we have network security rules other then SecureApp created through UI
     * Modal window with certain indexes and property values.
     * @returns {boolean} True when we have other rules.
     */
    proto.hasRuleBesidesSecureApp = function() {
        return this.networkSecurityPolicy.hasRuleBesidesSecureApp();
    };

    /**
     * Removes specific Network Security Policy rules called 'secure app'. Before removal will
     * check if at least one of them is present for this VS. Deletes the referenced microservice
     * group also (if it is not used anywhere else in a database).
     * @returns {ng.$q.promise}
     */
    proto.removeSecureAppRule = function() {
        const msGroupUuid = this.networkSecurityPolicy.removeSecureAppRule();
        let promise;

        if (msGroupUuid) {
            promise = this.save();

            //no need to return promise - we don't care about delete failure
            promise.then(() => {
                this.request('DELETE', `/api/microservicegroup/${msGroupUuid.slug()}`);
            });
        } else {
            promise = $q.reject('Can\'t remove the Secure App rules which VS doesn\'t have');
        }

        return promise;
    };

    /**
     * Checks VS cloud type to figure out whether AppMap functionality is available for
     * particular VS. Returns undefined if VS is not ready.
     * @returns {boolean|undefined}
     * @public
     */
    proto.isAppMapAvail = function() {
        return Cloud.isContainerCloud(this.getCloudType());
    };

    /**
     * Sets and saves an enabled state of VS.
     * @param  {boolean} enabled - Value to set for 'enabled' property.
     * @return {ng.$q.promise}
     */
    proto.setEnabledState = function(enabled) {
        return this.patch({ replace: { enabled } });
    };

    /**
     * VS uses macro API to save, let's use direct VS api for patch requests.
     * @override
     **/
    proto.patchRequest_ = function(payload) {
        return this.request(
            'patch',
            `/api/virtualservice/${this.id}?include_name`,
            payload,
            null,
            'patch',
        );
    };

    /**
     * Replaces all existent services with the only one of default configuration.
     * @param {number=} port - Service port. If not passed default for the application profile
     *     will be selected.
     */
    proto.setDefaultService = function(port = this.getDefaultServicePort()) {
        const { services } = this.getConfig();

        services.length = 0;

        services.push(
            VirtualService.getDefaultServiceConfig({ port }),
        );
    };

    /**
     * Returns default service port for this VS. It depends on application profile type.
     * @returns {number}
     */
    proto.getDefaultServicePort = function() {
        const appProfileType = this.appType();

        //HTTPS and HTTP application profiles have the same type of HTTP
        if (this.isHTTPS()) {
            return 443;
        } else {
            return ApplicationProfile.getDefaultServicePort(appProfileType);
        }
    };

    /**
     * Returns the vip_summary of the Virtual Service where a scaling/migrating process is occuring.
     * @return {Object} - Virtual Service vip_summary object.
     */
    proto.getActiveVipSummary = function() {
        const runtime = this.getRuntimeData();

        if (angular.isUndefined(runtime)) {
            return;
        }

        return _.find(runtime.vip_summary, summary => {
            return summary.scaleout_in_progress || summary.scalein_in_progress ||
                summary.migrate_in_progress;
        });
    };

    /**
     * Returns the status of the Virtual Service runtime.
     * @return {string|undefined}
     */
    proto.getRuntimeStatus = function() {
        let status;
        const activeVipSummary = this.getActiveVipSummary();

        if (angular.isUndefined(activeVipSummary)) {
            return;
        }

        if (activeVipSummary.scaleout_in_progress) {
            return status = 'SCALE_OUT';
        } else if (activeVipSummary.scalein_in_progress) {
            return status = 'SCALE_IN';
        } else if (activeVipSummary.migrate_in_progress) {
            return status = 'MIGRATE';
        }

        return status;
    };

    /**
     * Returns true if any policies or datascripts are present in the Virtual Service.
     * @return {boolean}
     */
    proto.hasPolicies = function() {
        const config = this.getConfig();

        if (!config) {
            return false;
        }

        if (angular.isArray(config.vs_datascripts) && config.vs_datascripts.length) {
            return true;
        }

        return this.policyEnabled() || this.networkSecurityPolicy.hasRules();
    };

    /**
     * Goes through all VIPs and returns a list of IP addresses VS is configured with. Undefined
     * when not ready (for one-time bindings).
     * @param {string=} format - Only `floatOrReg`, `allV4` and `hash` (default) are supported.
     * @returns {IpAddr.addr[]|Object[]}
     * @public
     */
    proto.getIPAddresses = function(format = 'hash') {
        const
            vsVip = this.getVSVip(),
            hashes = vsVip.getVipAddressHashes();

        switch (format) {
            case 'floatOrReg':
                return hashes.map(
                    ({ floating_ip: floatingIp, ip_address: ip }) => (floatingIp || ip)['addr'],
                );

            //flat list of floating and regular IPs across all VIPs
            case 'allV4':
                return hashes.reduce(
                    (acc, { floating_ip: floatingIp, ip_address: ip }) => {
                        if (floatingIp) {
                            acc.push(floatingIp['addr']);
                        }

                        if (ip) {
                            acc.push(ip['addr']);
                        }

                        return acc;
                    }, [],
                );

            default:
                return hashes;
        }
    };

    /**
     * Returns the DnsInfo object from VsVip based on the index. Index defaults to 0.
     * @param {number=} index - Index of the DnsInfo object from the dns_info list.
     * @param {Object} DnsInfo object.
     */
    proto.getDnsInfo = function(index = 0) {
        return this.getVSVip().getDnsInfo(index);
    };

    /**
     * Returns the Vip object from VsVip based on the index. Index defaults to 0.
     * @param {number=} index - Index of the Vip object from the vip list.
     * @return {Object} Vip object.
     */
    proto.getVip = function(index = 0) {
        return this.getVSVip().getVip(index);
    };

    /**
     * Returns true if VirtualService or its ConfigItems are busy.
     * @return {boolean}
     */
    proto.isBusy = function() {
        const config = this.getConfig();

        return this.busy || this.pool && this.pool.busy || config && this.getVSVip().isBusy();
    };

    /**
     * Returns the cloud_ref configured on the VirtualService.
     * @return {string}
     */
    proto.getCloudRef = function() {
        return this.getConfig()['cloud_ref'];
    };

    /**
     * Returns the VRF context ref configured for this VS.
     * @returns {string}
     */
    proto.getVRFContextRef = function() {
        return this.getConfig()['vrf_context_ref'] || '';
    };

    /**
     * Returns an application profile configuration of this VS.
     * @returns {ApplicationProfileConfig}
     * @public
     */
    proto.getAppProfileConfig = function() {
        return this.getConfig()['application_profile_ref_data'];
    };

    /**
     * Returns a network profile configuration of this VS.
     * @returns {NetworkProfileConfig}
     * @public
     */
    proto.getNetProfileConfig = function() {
        return this.getConfig()['network_profile_ref_data'];
    };

    /**
     * Loads network profile by 'network_profile_ref' and puts it into 'network_profile_ref_data`
     * of VS config.
     * @returns {ng.$q.promise}
     * @public
     */
    proto.loadNetProfileData = function() {
        return this.loadConfigRefData_('network_profile_ref');
    };

    /**
     * Loads application profile by 'application_profile_ref' and puts it into
     * 'application_profile_ref_data` of VS config.
     * @returns {ng.$q.promise}
     * @public
     */
    proto.loadAppProfileData = function() {
        return this.loadConfigRefData_('application_profile_ref');
    };

    /**
     * Sets application profile ref and data with values passed. Also executes certain
     * transformations after these two got updated. Transformations might require loading of
     * extra data, that's why promise is being returned. Not called on init.
     * @param {Item#data#config#url} ref
     * @param {ApplicationProfile#data#config} appProfileConfig - When not passed will make an API
     *     call to load it.
     * @returns {ng.$q.promise} - Rejects only if {@link VirtualService.onAppProfileChange_}
     *     fails or application profile data fails to load.
     */
    proto.setAppProfile = function(ref, appProfileConfig = null) {
        const
            config = this.getConfig(),
            newId = ref.slug(),
            id = config['application_profile_ref'] ? config['application_profile_ref'].slug() : '',
            prevAppProfileType = this.appType();

        //comparing uuids instead of refs
        if (id === newId) {
            if (!config['application_profile_ref_data'] && appProfileConfig) {
                config['application_profile_ref_data'] = appProfileConfig;
            }

            return $q.when(true);
        }

        config['application_profile_ref'] = ref;

        if (appProfileConfig) {
            config['application_profile_ref_data'] = appProfileConfig;

            return this.onAppProfileChange_(prevAppProfileType);
        } else {
            return this.loadAppProfileData()
                .then(() => this.onAppProfileChange_(prevAppProfileType));
        }
    };

    /**
     * Takes care of changing certain properties on application profile change event. Such changes
     * are quite disruptive, hence this is NOT an init or beforeEdit. If newly selected
     * application profile type equals to the previously selected profile type (or previously
     * selected profile type is not passed) this method doesn't do a thing.
     * @param {string} prevAppProfileType - App profile type we had before the recent change.
     * @returns {ng.$q.promise} - Rejected only if previous profile wasn't passed ot current
     *     profile data is not available.
     * @protected
     */
    proto.onAppProfileChange_ = function(prevAppProfileType) {
        const appProfileType = this.appType();

        if (!appProfileType) {
            return $q.reject('current application profile data is not available');
        }

        if (!prevAppProfileType) {
            return $q.reject('no transformation is made since prev profile type is not passed');
        }

        // when switching within one app profile type we don't want to transform anything
        if (appProfileType === prevAppProfileType) {
            return $q.when('no application profile type change');
        }

        const
            config = this.getConfig(),
            defaultNetProfileRef = ApplicationProfile.getDefaultNetProfileRef(appProfileType);

        let promise = $q.when(true);

        //setting default network profile for the newly selected application profile
        if (!config['network_profile_ref'] ||
            defaultNetProfileRef.slug() !== config['network_profile_ref'].slug()) {
            config['network_profile_ref'] = defaultNetProfileRef;
            promise = this.loadNetProfileData();
        }

        //virtual hosting is supported by HTTP type only, hence not carried over
        config['type'] = 'VS_TYPE_NORMAL';

        //setting defaults for creation only
        if (!this.id && (appProfileType === 'ssl' || this.isHTTPS())) {
            //TODO need public method to set these
            config['ssl_key_and_certificate_refs'] = [
                defaultValues.getSystemObjectRefByName(
                    'sslkeyandcertificate',
                    'System-Default-Cert',
                ),
            ];

            config['ssl_profile_ref'] =
                defaultValues.getSystemObjectRefByName(
                    'sslprofile',
                    'System-Standard',
                );
        }

        const noSSL = appProfileType !== 'ssl' && appProfileType !== 'http';

        //dropping these on edit as well cause they aren't compatible
        if (noSSL) {
            config['ssl_key_and_certificate_refs'] = [];
            config['ssl_profile_ref'] = undefined;
        }

        //TODO drop irrelevant policies
        const analyticsPolicy = this.getAnalyticsPolicy();

        // leaving bare minimum for analytics policy
        // TODO can use defaults here (with beforeEdit transformations)
        if (analyticsPolicy) {
            if ('client_log_filters' in analyticsPolicy) {
                analyticsPolicy['client_log_filters'].length = 0;
            }

            analyticsPolicy['client_insights'] = 'NO_INSIGHTS';
            //TODO take care of client_insights_sampling
        }

        //creation only
        if (!this.id) {
            this.setDefaultService();
        } else if (noSSL) {
            this.setServicesSSL(false);
        }

        if (!this.isHTTP()) {
            delete config['sso_policy_ref'];
        }

        return promise;
    };

    /**
     * Returns stripped names of all network profile types used by this VS.
     * @returns {{ShortAppProfileType: true}}
     * @public
     */
    proto.getNetProfileTypesHash = function() {
        const
            typesHash = {},
            { services } = this.getConfig(),
            sliceStartPos = 'PROTOCOL_TYPE_'.length;

        typesHash[
            this.getDefaultNetProfileType().slice(sliceStartPos)
        ] = true;

        if (services) {
            services.forEach(
                ({ override_network_profile_ref_data: netProfileConfig }) => {
                    if (netProfileConfig) {
                        typesHash[
                            netProfileConfig['profile']['type'].slice(sliceStartPos)
                        ] = true;
                    }
                },
            );
        }

        return typesHash;
    };

    /**
     * Returns `main` network profile used by this VS. Services might use network profile
     * override feature. Has nothing to do with "default" values.
     * @returns {string} - ProtocolType enum value
     */
    proto.getDefaultNetProfileType = function() {
        const netProfileConfig = this.getNetProfileConfig();

        if (!netProfileConfig) {
            return '';
        }

        return netProfileConfig['profile']['type'];
    };

    /**
     * Checks whether VS is using passed network profile type.
     * @param {ShortAppProfileType} type
     * @param {boolean?} only - When passed `type` should be the only netProfileType used by VS
     *     to return true.
     * @returns {boolean}
     * @public
     */
    proto.hasNetProfileType = function(type, only) {
        const typesHash = this.getNetProfileTypesHash();

        if (!(type in typesHash)) {
            return false;
        }

        return !only || Object.keys(typesHash).length === 1;
    };

    /**
     * Returns true if VS is using "TCP proxy" network profile type.
     * @param {boolean?} only - When passed has to be only profile used by VS to return true.
     * @returns {boolean}
     * @public
     */
    proto.hasTCPProxyNetProfile = function(only) {
        return this.hasNetProfileType('TCP_PROXY', only);
    };

    /**
     * Returns true if VS has TCPFastPath network profile (and only it when `only` param has
     * been passed).
     * @param {boolean?} only
     * @returns {boolean}
     * @public
     */
    proto.hasTCPFastPathNetProfile = function(only) {
        return this.hasNetProfileType('TCP_FAST_PATH', only);
    };

    /**
     * Returns true if VS is using "UDP fast path" network profile type.
     * @returns {boolean}
     * @public
     */
    proto.hasUDPNetProfile = function() {
        return this.hasNetProfileType('UDP_FAST_PATH');
    };

    /**
     * Loads "_data" of a "_ref" config property and puts it into VS config.
     * @param {string} refPropertyName - Top level config property name.
     * @returns {ng.$q.promise} - To be resolved with a loaded Item config.
     * @protected
     */
    //TODO make sure only one at a time
    //FIXME setting .busy this way doesn't work anymore
    proto.loadConfigRefData_ = function(refPropertyName) {
        const ref = this.getConfig()[refPropertyName];

        let errMsg = '';

        if (ref) {
            const item = createItemByRef(ref);

            this.busy = true;

            return item.load()
                .then(() =>
                    this.getConfig()[`${refPropertyName}_data`] = item.getConfig())
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(data);
                })
                .finally(() => {
                    item.destroy();
                    this.busy = false;
                });
        } else {
            errMsg = `${refPropertyName} is not set on VS config`;
        }

        console.error(errMsg);

        return $q.reject(errMsg);
    };

    /**
     * Function to check whether VS is referencing a pool or pool group.
     * @returns {boolean}
     * @public
     */
    proto.hasPoolRef = function() {
        const { pool_ref: poolRef, pool_group_ref: poolGroupRef } = this.getConfig();

        return !!(poolRef || poolGroupRef);
    };

    /**
     * Returns default VS.Service (protobuf message Service) config extended by passed config.
     * @param {VSServiceConfig=} config
     * @returns {VSServiceConfig}
     * @static
     */
    VirtualService.getDefaultServiceConfig = function(config = null) {
        if (config) {
            //set SSL on for 443
            if (config['port'] === 443 && !('enable_ssl' in config)) {
                config['enable_ssl'] = true;
            }

            //set port_range_end to port by default
            if (!('port_range_end' in config) && 'port' in config) {
                config['port_range_end'] = config['port'];
            }
        }

        return angular.extend({
            port: 80,
            port_range_end: 80,
            enable_ssl: false,
            nwOverride: false,
        }, config);
    };

    /** @override */
    proto.getDetailsPageStateParams_ = function() {
        return { vsId: this.id };
    };

    /**
     * Adds a DNS Record to config.static_dns_records.
     * @param {DnsRecordConfig} record - DnsRecordConfig Config Item.
     */
    proto.addDnsRecord = function(record) {
        const config = this.getConfig();

        config.static_dns_records = config.static_dns_records || [];
        config.static_dns_records.push(record);
    };

    /**
     * Replaces an existing DnsRecordConfig with a new one, used when editing a DnsRecordConfig.
     * @param {DnsRecordConfig} oldDnsRecord - DnsRecordConfig to be replaced.
     * @param {DnsRecordConfig} newDnsRecord - Modified DnsRecordConfig.
     */
    proto.replaceDnsRecord = function(oldDnsRecord, newDnsRecord) {
        const index = this.getConfig().static_dns_records.indexOf(oldDnsRecord);
        const { static_dns_records: dnsRecords } = this.getConfig();

        dnsRecords[index] = newDnsRecord;
    };

    /**
     * Removes an existing DnsRecordConfig from config.static_dns_records.
     * @param {DnsRecordConfig} dnsRecord - Record to be removed.
     */
    proto.removeDnsRecord = function(dnsRecord) {
        const index = this.getConfig().static_dns_records.indexOf(dnsRecord);
        const { static_dns_records: dnsRecords } = this.getConfig();

        dnsRecords.splice(index, 1);
    };

    /**
     * Returns true if VS has WAF configured. HTTP(S) VS only.
     * @returns {boolean}
     * @public
     */
    proto.hasWAFPolicy = function() {
        return !!this.getConfig()['waf_policy_ref'];
    };

    /**
     * Returns WAF policy Item when applicable.
     * @returns {WafPolicy|null}
     * @public
     */
    proto.getWAFPolicy = function() {
        return this.getConfig()['waf_policy_ref_data'] || null;
    };

    /**
     * Returns cloud type this VS belongs to.
     * @returns {string|undefined}
     * @public
     */
    proto.getCloudType = function() {
        return this.getConfig()['cloud_type'];
    };

    /**
     * Returns VSVip instance when set.
     * @returns {VSVip|null}
     * @public
     */
    proto.getVSVip = function() {
        return this.getConfig()['vsvip_ref_data'] || null;
    };

    /** @override */
    proto.destroy = function() {
        const gotDestroyed = VirtualService.superclass.destroy.call(this);

        if (gotDestroyed) {
            [this.pools, this.poolgroups]
                .forEach(collection => collection && collection.destroy());
        }

        return gotDestroyed;
    };

    /**
     * Returns configuration of analytics policy attached to this vs.
     * @returns {Object|null}
     * @public
     */
    proto.getAnalyticsPolicy = function() {
        return this.getConfig()['analytics_policy'] || null;
    };

    /**
     * Returns true when real time metrics is switched on.
     * @returns {boolean}
     * @public
     */
    proto.hasRealTimeMetrics = function() {
        const policy = this.getAnalyticsPolicy(),
            { has_pool_with_realtime_metrics: hasPoolWithRealtimeMetrics } = this.data;

        return hasPoolWithRealtimeMetrics &&
            policy &&
            'metrics_realtime_update' in policy &&
            policy['metrics_realtime_update']['enabled'] || false;
    };

    /**
     * Returns virtual hosting type for VS, assuming type was returned by BE API.
     * @returns {string} - child, parent, or normal; value of VirtualServiceType enum | ''
     */
    proto.getVHType = function() {
        return this.getConfig().type || '';
    };

    /**
     * Checks if VS is a virtual hosting child.
     * @returns {boolean}
     */
    proto.isVHChild = function() {
        return this.getVHType() === 'VS_TYPE_VH_CHILD';
    };

    /**
     * Returns parentVS ref for virtual hosting child.
     * @returns {string} - parent ref or empty str
     */
    proto.getVHParentRef = function() {
        return this.getConfig().vh_parent_vs_ref || '';
    };

    /**
     * Returns IP addresses of parent VS for VH child.
     */
    proto.getVHParentIPs = function(format = 'hash') {
        return $q((resolve, reject) => {
            if (!this.isVHChild()) {
                return reject('Not a VH child VS.');
            }

            const parentRef = this.getVHParentRef();
            const parentVS = new VirtualService({ id: parentRef.slug() });
            const headerParam = this.getLoadHeaders_();

            parentVS.addParams({ headers_: headerParam });

            parentVS.load()
                .then(() => {
                    const parentIPs = parentVS.getIPAddresses(format);

                    resolve(parentIPs);
                })
                .finally(() => parentVS.destroy());
        });
    };

    /** @override */
    proto.hasCustomTimeFrameSettings = function() {
        return !this.collection && this.hasRealTimeMetrics();
    };

    /** @override */
    proto.getCustomTimeFrameSettings = function(tfLabel) {
        if (this.hasCustomTimeFrameSettings() && tfLabel === 'rt') {
            return {
                step: 5,
                limit: 360,
            };
        }

        return null;
    };

    /**
     * Sets the VS services "enable_ssl" flag.
     * @param {boolean=} enabled
     */
    proto.setServicesSSL = function(enabled = false) {
        const config = this.getConfig();

        //boolean type only
        enabled = !!enabled;

        config['services'].forEach(service => service['enable_ssl'] = enabled);
    };

    return VirtualService;
}]);
