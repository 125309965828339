/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  clientIpMatch
 * @module match/clientIpMatch
 * @description Component for configuring a ClientIpMatch Config Item.
 * @param {ClientIpMatchConfigItem} clientIpMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('clientIpMatch', {
    bindings: {
        clientIpMatch: '<',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/matches/' +
        'client-ip-match/client-ip-match.component.html',
});
