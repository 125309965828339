/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const quickHelpTemplate = 'src/views/components/help/1.2/proapplist-quick-help.html';

/**
 * @type {string} templateHtml - Collection Grid Template Html.
 */
const templateHtml = `<div class="sel-app-profile-list panel templatesList">
                          <collection-grid
                              config="$ctrl.gridConfig"
                              help-popover-anchor="applicationtable"
                              help-popover-position="lm"
                          ></collection-grid>
                          <div 
                              class="help-popover-storage"
                              ng-include="${quickHelpTemplate}"
                          ></div>
                      </div>`;

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @see {@link module:avi/profiles/application.applicationProfileListComponent}
 */
class ApplicationProfileListController {
    constructor(ApplicationProfileCollection, CRUDGridConfig) {
        this.ApplicationProfileCollection_ = ApplicationProfileCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        const gridConfigOptions = {
            collection: new this.ApplicationProfileCollection_(),
            singleactions: [{
                title: 'Edit',
                class: 'icon-pencil-4',
                hidden: row => !row.isAllowed(),
                do(row) {
                    row.isEditable() ? row.edit() : this.config.collection.clone(row);
                },
            }],
            fields: [{

                name: 'data-config-name',
                title: 'Name',
                sortBy: 'name',
                template: '{{ row.getName() }}',
            },
            {
                name: 'type',
                title: 'Type',
                template: '{{ row.getType() | lookup: "APPLICATION_PROFILE_TYPE_" }}',
            }],
            permission: 'PERMISSION_APPLICATIONPROFILE',
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ApplicationProfileListController.$inject = [
    'ApplicationProfileCollection',
    'CRUDGridConfig',
];

/**
 * @name applicationProfileListComponent
 * @property {module:avi/profiles/application.ApplicationProfileListController} controller
 * @memberOf module:avi/profiles/application
 * @description Application profile listing component.
 * @author chitra
 */
angular.module('avi/profiles/application').component('applicationProfileList', {
    controller: ApplicationProfileListController,
    template: templateHtml,
});
