/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @description Alert Metric collection.
 */
angular.module('aviApp').factory('AlertMetricsCollection', [
'Collection', 'AlertMetricsItem',
function(Collection, AlertMetricsItem) {
    class AlertMetricsCollection extends Collection {
        constructor(args) {
            super(args);
            this.setParams({ alert_metrics: true });
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(AlertMetricsCollection.prototype, {
        objectName_: 'analytics/metric_id',
        itemClass_: AlertMetricsItem,
        defaultDataSources_: 'list',
        allDataSources_: {
            list: {
                source: 'ListCollDataSourceWithSearchParam',
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return AlertMetricsCollection;
}]);
