/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name logDataTransform
 * @author Alex Malitsky
 * @description
 *
 *    For each log entry, gotten from API request same transformations should be done in filters
 *    and logCallout controller. Not used by logCallOuts.
 *
 */
angular.module('aviApp').service('logDataTransform', [
'Schema', 'noValueLogLabel',
function(Schema, noValueLogLabel) {
    /** @type {string} */
    const noValueLabel = noValueLogLabel;

    function getDnsRequestResponseRecordLabel(record) {
        let label = '';

        const { type } = record;

        switch (type) {
            case 'DNS_RECORD_CNAME':
                label = `Type: CNAME,  Name: ${record['cname']}`;
                break;

            case 'DNS_RECORD_NS':
                label = `Type: NS, Name: ${record['nsname']}`;
                break;

            case 'DNS_RECORD_A':
            case 'DNS_RECORD_AAAA': {
                const ip = record.addr_ip || record.addr6_ip_str;

                label = `Type: ${type.replace('DNS_RECORD_', '')}, IP Address: ${ip}`;

                if ('site_name' in record) {
                    label += ` (${record['site_name']}:${record['vs_name']})`;
                }

                break;
            }
            default:
                label = `Type: ${record['type']}`;
        }

        if ('ttl' in record) {
            label += ` TTL: ${record['ttl']}`;
        }

        return label;
    }

    function modifyRespCode(code) {
        const res = { val: code };
        let descrObj;

        if (code) {
            descrObj = Schema.enums.HTTPResponseCodes.values[`HTTP_RESPONSE_CODE_${code}`] ?
                Schema.enums.HTTPResponseCodes.values[`HTTP_RESPONSE_CODE_${code}`].options :
                false;

            if (descrObj) {
                res.descr = descrObj.text.value;
            }
        }

        return res;
    }

    const noValueDisplay = function(value) {
        return {
            display: value === '' ? noValueLabel : value,
            val: value,
        };
    };

    const parsers = {
        server_ip(str) {
            return noValueDisplay(str);
        },
        server_ip6(str) {
            return noValueDisplay(str);
        },
        http_version(str) {
            const res = { val: str };

            if (str || str === 0) {
                res.display = `HTTP/${str}`;
            }

            return res;
        },
        response_code: modifyRespCode,
        server_response_code: modifyRespCode,
        ssl_cipher(str) {
            const
                res = { val: str },
                sslRegExp = /^.*?auth:([^\s]+)?.*?PFS:([^\s]+)?.*$/i;

            const ssl = sslRegExp.exec(str);

            if (ssl && ssl[1] && ssl[2]) {
                res.ssl_cert_type = ssl[1];
                res.ssl_pfs = ssl[2];
            }

            return res;
        },
        significance(str) {
            const
                res = { val: str },
                enums = Schema.enums['AviDefinedFilters']['values'],
                signReasons = str.split(', ');

            if (str) {
                res.display = signReasons.map(function(val) {
                    const res = {};

                    res.val = val;

                    const descr = _.find(enums, function(elem) {
                        return elem.options.text.value === val;
                    });

                    if (descr) {
                        res.descr = descr.options.e_description.value;
                    }

                    return res;
                });
            }

            return res;
        },
        spdy_version(str) {
            let res;

            if (str) {
                res = { val: str };
                res.display = str.slug();
            }

            return res;
        },
        microservice_name(str) {
            return {
                display: str || 'External-Client',
                val: str,
            };
        },
        protocol(str) {
            return {
                display: str.enumeration('PROTOCOL_'),
                val: str,
            };
        },
        dns_request(dnsRequest) {
            const { opt_record: optRecord } = dnsRequest;
            let options;

            if (optRecord && (options = optRecord['options']) &&
                Array.isArray(options) && options.length) {
                const [option] = options;

                if (option['subnet_ip']) {
                    option['subnet_ip'] = {
                        display: option['subnet_ip'],
                        val: option['subnet_ip'],
                    };
                }
            }

            return dnsRequest;
        },
        dns_response(responseObj, row) {
            const getDnsRequestResponseRecordShortLabel = record =>
                record.cname || record.nsname ||
                    'addr_ip' in record && record['addr_ip'] ||
                    'dns_ips' in record && record['dns_ips'].join(', ') || '';

            let label = '';

            if ('records' in responseObj) {
                //dns_qtype should be set and we might have it processed
                const qType = row['dns_qtype'] && angular.isString(row['dns_qtype']) ?
                    row['dns_qtype'] : row['dns_qtype'].val;

                responseObj['records'].forEach(function(record) {
                    record.display = getDnsRequestResponseRecordLabel(record);

                    if (!label && (!qType || record['type'] === qType)) {
                        label = getDnsRequestResponseRecordShortLabel(record);
                    }
                });

                //no label found by type
                if (!label) {
                    const suitableRecord =
                        _.find(responseObj['records'], getDnsRequestResponseRecordShortLabel);

                    if (suitableRecord) {
                        label = getDnsRequestResponseRecordShortLabel(suitableRecord);
                    }
                }
            }

            return {
                display: label,
                val: responseObj,
            };
        },
        dns_qtype(str) {
            return {
                display: str.enumeration('DNS_RECORD_').toUpperCase(),
                val: str,
            };
        },
        dns_etype(str) {
            return {
                display: str.enumeration('DNS_ENTRY_'),
                val: str,
            };
        },
    };

    /**
     * Transformer for log entry property.
     * @param {string} name - Log property name.
     * @param {*} val - Particular property value.
     * @param {Object=} row - Log entry.
     * @returns {*} Transformed property.
     * @public
     */
    this.propOnLoad = function(name, val, row) {
        if (name in parsers) {
            return parsers[name](val, row);
        } else {
            return val;
        }
    };

    /**
     * Modifies log entry on load using all defined property parsers.
     * @param {Object} row
     * @returns {*} Transformed log entry object.
     * @public
     */
    this.rowOnLoad = function(row) {
        function logType(row) {
            return row['adf'] && 'adf' || row['udf'] && 'udf' || 'ndf';
        }

        row.id = [
            row.service_engine,
            row.vcpu_id,
            logType(row),
            row.log_id,
            row.report_timestamp,
        ].join('/');

        row.dt_start = moment(row.report_timestamp).subtract(row.total_time, 'ms');

        if (typeof row.data_transfer_time === 'undefined') { //pseudo, have no d_t_t for layer 4
            row.data_transfer_time = row.total_time - row.server_rtt - row.client_rtt;
        }

        if ('waf_log' in row) {
            const wafLogTime = [
                'latency_request_header_phase',
                'latency_request_body_phase',
                'latency_response_header_phase',
                'latency_response_body_phase',
            ].reduce(
                (acc, fieldName) => acc + Number(row['waf_log'][fieldName]),
                0,
            );

            row['waf_log_time'] = Math.round(wafLogTime / 1000);
        }

        _.each(parsers, (parser, fieldName) => {
            if (fieldName in row) {
                row[fieldName] = parser(row[fieldName], row);
            }
        });

        return row;
    };
}]);
