/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name ServerAutoScaleListController
 * @description Controller for Server AutoScale page.
 **/
angular.module('aviApp').controller('ServerAutoScaleListController', [
'$scope', 'CRUDGridConfig', 'AutoScalePolicyCollection', 'Regex',
function($scope, CRUDGridConfig, AutoScalePolicyCollection, Regex) {
    this.Regex = Regex;

    this.collection = new AutoScalePolicyCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'data.config.min_size',
        title: 'Min Size',
        template: '{{ row.data.config.min_size || \'None\' }}',
    }, {
        name: 'data.config.max_size',
        title: 'Max Size',
        template: '{{ row.data.config.max_size || \'None\' }}',
    }, {
        name: 'data.config.intelligent_autoscale',
        title: 'Intelligent AutoScale',
        template: '{{ row.data.config.intelligent_autoscale ? \'Enabled\' : \'Disabled\'}}',
    }];
}]);
