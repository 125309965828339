/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const componentName = 'c-grid-table-header-cell';

const template =
        `<div class="${componentName}__content-wrapper" ng-transclude></div>
        <grid-table-header-cell-sort-icon
            state="$ctrl.getSortingState()"
            ng-if="::$ctrl.isSortable()"></grid-table-header-cell-sort-icon>
        <grid-table-header-cell-resize-handle ng-if="$ctrl.isResizable()">
        </grid-table-header-cell-resize-handle>`;

class GridTableHeaderCellController {
    constructor($element, gridConfigTools) {
        this.$element = $element;
        this.columnId_ = NaN;
        this.gridConfigTools_ = gridConfigTools;

        this.onClick = this.onClick.bind(this);
    }

    $onInit() {
        const { field } = this;

        this.columnId_ = this.gridHeaderCtrl.addColumn(
            this.$element,
            field.resizable,
        );

        if (this.isSortable()) {
            this.$element.on('click', this.onClick);
        }

        this.addElemClassNames_();
    }

    /**
     * Appends classes to the cell on init.
     * @protected
     */
    addElemClassNames_() {
        const classNames = [
                componentName,
                'sel-grid-field',
        ];

        const fieldClassName = this.gridConfigTools_.getFieldClassName(this.field);

        classNames.push(
            `${componentName}--field-name--${fieldClassName}`,
        );

        if (this.isSortable()) {
            classNames.push(`${componentName}--sortable`);
        }

        this.$element.addClass(classNames.join(' '));
    }

    /**
     * Kicks off resizing ot the column. Proxy method of {@link gridTableHeader} component.
     * @param {Object} e - Mousedown event object.
     */
    startCollResize(e) {
        this.gridHeaderCtrl.startResizing(this.columnId_, e);
    }

    /**
     * Returns true for resizable columns.
     * @returns {boolean}
     */
    isResizable() {
        return this.gridHeaderCtrl.isColumnResizable(this.columnId_);
    }

    /**
     * Returns true for cells with sorting capability.
     * @returns {boolean}
     */
    isSortable() {
        return !!this.field.sortBy;
    }

    /**
     * Returns state of the sorting on the column.
     * @returns {number} - 0, 1 or 2
     */
    getSortingState() {
        return this.gridHeaderCtrl.getSortingState(this.field.name);
    }

    /**
     * Event handler for the cell click event.
     */
    onClick() {
        this.gridHeaderCtrl.setSorting(this.field.name);
    }

    $onDestroy() {
        this.gridHeaderCtrl.removeColumn(this.columnId_);
    }
}

GridTableHeaderCellController.$inject = [
    '$element',
    'gridConfigTools',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCell
 * @param {Object} field - Config object of the grid's "field".
 * @desc
 *
 *     Wrapping directive for the table header cell. Besides wrapping passed content
 *     into the template adds sorting and resize capabilities.
 *
 *     Works in conjunction with {@link gridTableHeader} component controller.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi').component('gridTableHeaderCell', {
    bindings: {
        field: '<',
    },
    require: {
        gridHeaderCtrl: '^^gridTableHeader',
    },
    transclude: true,
    controller: GridTableHeaderCellController,
    template,
});
