/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  MetricsDimensionAsnameCollDataSource
 * @description
 *
 *     This is supplementary data for MetricsDimension Items. Requires passing of IP or
 *     ipGroups lists to get `asnames`.
 */
angular.module('security.vs.vantage.avi').factory('MetricsDimensionAsnameCollDataSource', [
'ListCollDataSource',
function(ListCollDataSource) {
    class MetricsDimensionAsnameCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_() {
            const
                params = { objectName_: 'ipproperties' },
                seriesIds = this.owner_.getItemIds().join();

            if (seriesIds) {
                if ('asname-asn' in this.fields_) {
                    params['asn'] = seriesIds;
                } else if ('asname-ipgroup' in this.fields_) {
                    params['ip'] = seriesIds;
                }
            }

            return params;
        }

        /**
         * Prevents load if we already have all the names.
         * @override
         */
        preventLoad_() {
            const { owner_: collection } = this;

            return !collection.getNumberOfItems() || _.every(
                collection.items, item => item.data.asname,
            );
        }
    }

    angular.extend(MetricsDimensionAsnameCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        hasTotalNumberOfItems: false,
        defaultParams_: {},
        defaultFields_: [],
    });

    return MetricsDimensionAsnameCollDataSource;
}]);
