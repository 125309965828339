/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} UserActivity
 * @property {number} concurrent_sessions
 * @property {number} failed_login_attempts
 * @property {string} last_login_ip
 * @property {string} last_login_timestamp
 * @property {string} last_password_update
 * @property {boolean} logged_in
 * @property {string} name
 * @property {string} url
 * @property {string} uuid
 */

const userFactory = ($http, Item, Auth, passwordGenerator) => {
    /**
     * @class
     * @name User
     * @extends module:avi/dataModel.Item
     * @desc
     *  User item.
     *
     * @see {@link UserCollection}
     * @author Aravindh Nagarajan
     */
    class User extends Item {
        constructor(args) {
            super(args);

            if (!this.opener && this.id) {
                // Adding onSave event listener to notify Auth service on successful save.
                this.bind('itemSaveSuccess', this.onUserSave_);
            }
        }

        /** @override */
        transformAfterLoad() {
            this.decodeUIProperty_();
        }

        /** @override */
        beforeEdit() {
            const userConfig = this.getConfig();

            if (!userConfig.access || !userConfig.access.length) {
                userConfig.access = [];

                // Add an empty set of tenant-role map,
                // for new user with empty access list.
                this.addTenantRoleMap();
            }
        }

        /** @override */
        transformDataAfterSave(rsp) {
            this.decodeUIProperty_(rsp.data);

            return rsp.data;
        }

        /**
         * Parses UI Property and sets to config data.
         * @param {object|undefined} data
         * @protected
         */
        decodeUIProperty_(data) {
            const config = data || this.getConfig();
            let uiProperty = config.ui_property;
            const displayValues = ['avg', 'max', 'sum', 'current'];

            if (uiProperty && typeof uiProperty === 'string') {
                config.ui_property = JSON.parse(uiProperty);
                uiProperty = config.ui_property;

                if (!_.contains(displayValues, uiProperty.valuesToDisplay)) {
                    uiProperty.valuesToDisplay = 'avg';
                }
            } else if (uiProperty === undefined || uiProperty === '') {
                // Defaults
                config.ui_property = {
                    useUTCTime: false,
                    defaultTimeframe: '6h',
                    valuesToDisplay: 'avg',
                };
            }
        }

        /**
         * Adds new row to tenant and role access.
         */
        addTenantRoleMap() {
            const access = this.getAccessList();
            const mapTemplate = {
                tenant_ref: undefined,
                role_ref: undefined,
            };

            access.push(mapTemplate);
        }

        /**
         * Loads user's activity.
         * @param {string} username
         * @return {ng.$q.promise} - To be resolved with UserActivity
         * @static
         */
        static loadUserActivity(username) {
            const url = `/api/useractivity/?name=${username}`;

            return $http.get(url).then(({ data }) => {
                const { results } = data;
                const [activity] = results;

                return activity;
            });
        }

        /**
         * Returns the name of the user.
         * Full name if its available or username.
         * @return {string}
         * @override
         */
        getName() {
            const config = this.getConfig();

            return config.full_name || config.username;
        }

        /**
         * Removes a row from tenant-role mapping.
         * @param {number} index
         */
        removeTenantRoleMap(index) {
            const accessList = this.getAccessList();

            accessList.splice(index, 1);
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());
            const { access } = config;

            if (Array.isArray(access) && access.length > 0) {
                const [access1] = access;

                // "All Tenants" is supported for single role only at this time
                if (access1.all_tenants) {
                    config.access = [access1];
                }

                // If user has only 1 tenant - it should be default one
                if (!config.default_tenant_ref) {
                    config.default_tenant_ref = access1.tenant_ref;
                } else {
                    const hasDefaultTenant = _.findWhere(access, {
                        tenant_ref: config.default_tenant_ref,
                    });

                    if (!hasDefaultTenant) {
                        config.default_tenant_ref = access1.tenant_ref;
                    }
                }
            }

            // Serialize ui property before saving
            if (config.ui_property) {
                config.ui_property = JSON.stringify(config.ui_property);
            }

            if (!config.password) {
                delete config.password;
            }

            delete config.confirm_password;

            return config;
        }

        /**
         * Generates random string to be used as a password.
         * @param {number=} length - Length of the password string. Defaults to 10.
         */
        generatePassword(length) {
            const config = this.getConfig();

            config.password = passwordGenerator.generate(length || 10);
            config.require_password_confirmation = false;
        }

        /**
         * Special case for user object, all users are editable if there is permission.
         * @return {boolean}
         */
        isEditable() {
            const config = this.getConfig();

            // Enforce to disable editing remote users if keystone auth is enabled
            if (Auth.isKeystoneAuthEnabled() &&
                !config.local && config.default_tenant_ref.slug() !== 'admin') {
                return false;
            }

            return super.isEditable.call(this);
        }

        /** @override */
        isDroppable() {
            if (this.isProtected()) {
                return false;
            }

            return this.isAllowed();
        }

        /**
         * Returns access list from config.
         * @return {Object[]|null}
         */
        getAccessList() {
            return this.getConfig().access || null;
        }

        /**
         * Getter for username
         * @return {string}
         */
        get username() {
            return this.getConfig().username || '';
        }

        /**
         * Returns true if User has access to the passed tenant.
         * @param {string} tenantRef
         * @return {boolean}
         */
        hasTenant(tenantRef) {
            const tenantAccessList = this.getAccessList();

            return !!_.findWhere(tenantAccessList, { tenant_ref: tenantRef });
        }

        /**
         * Fires on successful user save.
         * If current user is being edited,
         * Control goes to AuthService to update Tenant List.
         * @protected
         */
        onUserSave_ = () => {
            if (this.username === Auth.getUsername()) {
                Auth.onCurrentUserChange(this);
            }
        }
    }

    Object.assign(User.prototype, {
        objectName: 'user',
        windowElement: 'user-modal',
    });

    return User;
};

userFactory.$inject = [
    '$http',
    'Item',
    'Auth',
    'passwordGenerator',
];

/**
 * @ngdoc service
 * @alias User.
 */
angular.module('aviApp').factory('User', userFactory);
