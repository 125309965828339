/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Module for all things Cloud related.
 * @module avi/cloud
 */

angular.module('avi/cloud').factory('Cloud', [
'$q',
'UpdatableItem',
'$timeout',
'Auth',
'ConfiguredNetwork',
'Base',
'Schema',
'VRFContextCollection',
'CloudLicenseTypes',
'secretStubStr',
'AzureConfigurationConfig',
'GcpConfigurationConfig',
'ConfigItem',
function(
    $q,
    UpdatableItem,
    $timeout,
    Auth,
    ConfiguredNetwork,
    Base,
    Schema,
    VRFContextCollection,
    CloudLicenseTypes,
    secretStubStr,
    AzureConfigurationConfig,
    GcpConfigurationConfig,
    ConfigItem,
) {
    /**
     * Hash of container type clouds.
     */
    const containerClouds = {
        CLOUD_OSHIFT_K8S: true,
    };

    const cloudsAllowingNetworkCreation = angular.extend({
        CLOUD_LINUXSERVER: true,
        CLOUD_GCP: true,
        CLOUD_NONE: true,
    }, containerClouds);

    /**
     * @memberOf module:avi/cloud
     * @constructor
     * @class
     * @name Cloud
     */
    const Cloud = function(oArgs) {
        Cloud.superconstructor.call(this, oArgs);

        /**
         * Used to get VRF context if required.
         * @type {Base}
         */
        this.vrfContextRequest = new Base();
        /**
         * Chache for VRF context object.
         * @type {Object|null}
         */
        this.vrfContext = null;

        /**
         * vCenter Datacenter polling timeout.
         * @protected
         * @type {?ng.Promise}
         */
        this.vcenterDCTimeout_ = null;

        /**
         * vCenter Networks polling timeout.
         * @protected
         * @type {?ng.Promise}
         */
        this.vcenterNetworkTimeout_ = null;

        /**
         * Debounced {@link getAwsIamAssumeRoles} method.
         * @type {Function}
         */
        this.getAwsIamAssumeRoles = _.debounce(this.getAwsIamAssumeRoles, 250);

        /**
         * Unique identifier for AWS AssumeRole API request.
         * @protected
         * @type {string}
         */
        this.awsAssumeRoleRequestGroup_ = 'awsAssumeRoleRequestGroup';

        /**
         * List of AssumeRoles for AWS Cloud.
         * @type {Array<{role: string, account: string}>}
         */
        this.iamAssumeRoles = [];
    };

    avi.inherit(Cloud, UpdatableItem);

    Cloud.prototype.objectName = 'cloud';
    Cloud.prototype.windowElement = 'infra-cloud-create';

    Cloud.prototype.createLinuxServerHostAttr = function(key, value) {
        if (!angular.isUndefined(key) && !angular.isUndefined(value)) {
            if (typeof value === 'object') {
                value = value.type === 'All' ? 'All' : value.value;
            }

            return {
                attr_key: key,
                attr_val: value,
            };
        }
    };

    Cloud.prototype.addLinuxServerHost = function() {
        const cfg = this.data.config.linuxserver_configuration;
        const hosts = cfg.hosts || [];

        hosts.push({
            host_ip: {},
            host_attr_: {
                CPU: {
                    type: 'All',
                    value: 'All',
                },
                MEMORY: {
                    type: 'All',
                    value: 'All',
                },
                DPDK: 'Yes',
                SE_INBAND_MGMT: cfg.se_inband_mgmt ? 'True' : 'False',
            },
        });
        cfg.hosts = hosts;
    };

    Cloud.prototype.removeLinuxServerHost = function(host) {
        const { hosts } = this.data.config.linuxserver_configuration;
        const i = hosts.indexOf(host);

        if (i > -1) {
            hosts.splice(i, 1);
        }
    };

    /**
     * Requests VRF management context based on cloud id.
     * @returns {Promise}
     */
    Cloud.prototype.getVRFContext = function() {
        const cloudId = this.data && (this.data.uuid || this.data.config && this.data.config.uuid);

        if (cloudId) {
            const vrfContextURL = `/api/vrfcontext?name=management&cloud_uuid=${cloudId}`;

            return this.vrfContextRequest.request('GET', vrfContextURL)
                .then(function({ data }) {
                    if (data && data.count) {
                        [this.vrfContext] = data.results;
                    }
                }.bind(this));
        } else {
            return $q.reject(false);
        }
    };

    /**
     * Saves current VRF context.
     * @param {string} defaultGateway - Gateway to save for example 10.20.30.40.
     * @returns {Promise}
     */
    Cloud.prototype.saveVRFContext = function(defaultGateway) {
        const self = this;

        let
            staticRoutes,
            { vrfContext } = this;

        if (!vrfContext && this.data.mvrf && this.data.mvrf.uuid) {
            vrfContext = this.data.mvrf;
        }

        if (vrfContext && vrfContext.url) {
            staticRoutes = vrfContext.static_routes;

            if (defaultGateway) {
                if (staticRoutes && staticRoutes.length) {
                    const route = staticRoutes[0];

                    if (route.next_hop.addr !== defaultGateway) {
                        route.next_hop.addr = defaultGateway;
                    } else {
                        return $q.reject(false);
                    }
                } else {
                    vrfContext.static_routes = [{
                        next_hop: {
                            addr: defaultGateway,
                        },
                    }];
                    staticRoutes = vrfContext.static_routes;
                }
            }

            staticRoutes = _.values(staticRoutes);

            if (staticRoutes && staticRoutes.length) {
                vrfContext.static_routes = staticRoutes;

                const staticRoute = staticRoutes[0];

                if (!staticRoute.next_hop.addr) {
                    vrfContext.static_routes = undefined;
                } else {
                    staticRoute.route_id = staticRoute.route_id || '1';
                    staticRoute.next_hop.type = staticRoute.next_hop.type || 'V4';

                    if (!staticRoute.prefix) {
                        staticRoute.prefix = {
                            ip_addr: {
                                type: 'V4',
                                addr: '0.0.0.0',
                            },
                            mask: 0,
                        };
                    }
                }
            }

            return this.vrfContextRequest.request('PUT', vrfContext.url, vrfContext)
                .then(function(response) {
                    self.vrfContext = response.data;
                });
        }

        return $q.reject(false);
    };

    /**
     * Remove mesos cloud properties to default SE deployment settings when switching between
     *     service types.
     * @param  {string} type - Cloud being edited.
     */
    Cloud.prototype.resetDefaultSEDeployment = function(type) {
        const config = this.data.config[this.vtypeConfigHash[type]] || {};

        delete config.fleet_endpoint;
        delete config.ssh_se_deployment;
    };

    /**
     * @override
     */
    Cloud.prototype.transformAfterLoad = function() {
        const config = this.getConfig();

        if (!_.isEmpty(config.azure_configuration)) {
            const { azure_configuration: azureConfig } = config;

            config.azure_configuration = new AzureConfigurationConfig({
                data: { config: azureConfig },
            });
        }

        if (!_.isEmpty(config.gcp_configuration)) {
            const { gcp_configuration: gcpConfig } = config;

            config.gcp_configuration = new GcpConfigurationConfig({
                data: { config: gcpConfig },
            });
        }
    };

    /**
     * BeforeEdit for AWS configuration.
     * @protected
     */
    Cloud.prototype.awsBeforeEdit_ = function() {
        const
            config = this.getConfig(),
            cloudConfig = this.getCloudConfig();

        this.getAwsRegions();

        if (config['proxy_configuration']) {
            config.useProxy = true;
        }

        if (!('zones' in cloudConfig)) {
            cloudConfig['zones'] = [];
        }

        const { zones } = cloudConfig;

        if (!zones.length) {
            zones.push({});
        }

        config.custom_tags = config.custom_tags || [];

        const { aws_configuration: defaultAWSConfig } = this.getDefaultConfig_();

        ['ebs', 's3', 'sqs']
            .forEach(encType => {
                const propName = `${encType}_encryption`;

                if (!(propName in cloudConfig)) {
                    cloudConfig[propName] =
                        defaultAWSConfig[propName] || { mode: 'AWS_ENCRYPTION_MODE_NONE' };
                }
            });

        cloudConfig._dnsType = 1;

        if (cloudConfig['route53_integration']) {
            cloudConfig._dnsType = 2;
        } else if (config['dns_provider_ref']) {
            cloudConfig._dnsType = 3;
        }
    };

    /**
     * @override
     */
    Cloud.prototype.beforeEdit = function() {
        const
            config = this.getConfig(),
            vtype = this.getVtype(),
            cloudConfig = this.getCloudConfig();

        switch (vtype) {
            case 'CLOUD_OPENSTACK': {
                const { provider_vip_networks: providerVipNetworks } = cloudConfig;

                if (!providerVipNetworks) {
                    cloudConfig.provider_vip_networks = [];
                }

                if (cloudConfig.nuage_vsd_host) {
                    cloudConfig.nuage_config = true;
                }

                if (cloudConfig.contrail_endpoint) {
                    cloudConfig._useContrail = true;
                }

                break;
            }

            case 'CLOUD_AWS':
                this.awsBeforeEdit_();
                break;

            case 'CLOUD_OSHIFT_K8S':
                addAttributes(cloudConfig);
                cloudConfig._useNuage = !!cloudConfig.nuage_controller;

                if (!cloudConfig.http_container_ports) {
                    cloudConfig.http_container_ports = [''];
                }

                break;

            case 'CLOUD_LINUXSERVER':
                this.setLinuxSEUsagePathRadio();

                if (Array.isArray(cloudConfig.hosts)) {
                    cloudConfig.hosts.forEach(host => {
                        const attrs = host.host_attr;

                        if (Array.isArray(attrs)) {
                            host.host_attr_ = {};

                            const hostAttr = host.host_attr_;

                            attrs.forEach(attr => {
                                const key = attr.attr_key.toUpperCase();
                                const val = attr.attr_val.toLowerCase().capitalize();

                                if (key === 'CPU' || key === 'MEMORY') {
                                    this.createUnionHostAttr(hostAttr, key, val);
                                } else {
                                    hostAttr[key] = val;
                                }

                                const useCustomPath = key === 'SYS_DISK_PATH' ||
                                                    key === 'SYS_DISK_SIZE_GB' ||
                                                    key === 'LOG_DISK_PATH' ||
                                                    key === 'LOG_DISK_SIZE_GB';

                                if (!host.customPaths && useCustomPath) {
                                    host.customPaths = true;
                                }
                            });
                        }
                    });
                }

                break;

            case 'CLOUD_AZURE':
                config.azure_configuration.beforeEdit();
                break;

            case 'CLOUD_VCENTER': {
                const { management_network: managementNetwork } = cloudConfig;

                if (managementNetwork) {
                    const name = managementNetwork.name();
                    const uuid = managementNetwork.slug();

                    cloudConfig.management_network = `${uuid}#${name}`;
                }

                break;
            }

            case 'CLOUD_GCP':
                if (!_.isEmpty(config.dns_provider_ref)) {
                    config.dnsType = 'other';
                } else {
                    config.dnsType = 'none';
                }

                cloudConfig.beforeEdit();
                break;
        }
    };

    /**
     * Handles change for Linux Cloud Host Attributes that can be 'All' or a number.
     * @param {{type: string, value: string}} hostAttrUnion - Host attribute union value object.
     * @param {string} value - Value to set for hostAttrUnion object value field.
     */
    Cloud.prototype.handleUnionHostAttrChange = function(hostAttrUnion, value) {
        if (typeof hostAttrUnion === 'object') {
            hostAttrUnion.value = value;
        }
    };

    /**
     * Creates union Host Attribute value that can be 'All' or a number.
     * @param {Object} hostAttr - Host attributes hashmap.
     * @param {string} key - Host attr key.
     * @param {string} value - Host attr value.
     * @returns {{type: string, value: string}} - Host attr union value object.
     */
    Cloud.prototype.createUnionHostAttr = function(hostAttr, key, value) {
        return hostAttr[key] = {
            type: value === 'All' ? 'All' : 'Custom',
            value,
        };
    };

    /**
     * Hash of cloud vtypes to cloud configuration objects.
     * @type {Object}
     */
    Cloud.prototype.vtypeConfigHash = {
        CLOUD_VCENTER: 'vcenter_configuration',
        CLOUD_OPENSTACK: 'openstack_configuration',
        CLOUD_AWS: 'aws_configuration',
        CLOUD_VCA: 'vca_configuration',
        CLOUD_LINUXSERVER: 'linuxserver_configuration',
        CLOUD_OSHIFT_K8S: 'oshiftk8s_configuration',
        CLOUD_AZURE: 'azure_configuration',
        // CLOUD_GCP doesn't exist in pb yet
        CLOUD_GCP: 'gcp_configuration',
    };

    /**
     * Returns cloud configuration object by vtype.
     * @param {string=} vtype - Optional Cloud vtype, if ignored use active config vtype.
     * @return {Object} - Cloud config.
     */
    Cloud.prototype.getCloudConfig = function(vtype = '') {
        const config = this.getConfig();

        vtype = vtype || this.getVtype();

        return config[this.vtypeConfigHash[vtype]];
    };

    /**
     * Adds empty port to http_container_ports on current cloud config.
     */
    Cloud.prototype.addPort = function() {
        const cloudConfig = this.getCloudConfig();

        if (!Array.isArray(cloudConfig.http_container_ports)) {
            cloudConfig.http_container_ports = [''];
        } else {
            cloudConfig.http_container_ports.push('');
        }
    };

    /**
     * Handles OpenStack Management Network change.
     */
    Cloud.prototype.openStackNetworkChange = function() {
        const config = this.getCloudConfig();
        const { mgmt_network_name: mgmtNetName } = config;
        const mgmtNet = _.find(this.networks, net => net.name === mgmtNetName);

        if (mgmtNet && mgmtNet.external) {
            config.external_networks = true;
        }
    };

    /** @override */
    Cloud.prototype.dataToSave = function() {
        const
            config = angular.copy(this.getConfig()),
            vtype = this.getVtype(),
            cloudConfig = config[this.vtypeConfigHash[vtype]];

        _.each(this.vtypeConfigHash, (vtypeConfigProp, currVtype) => {
            if (currVtype !== vtype) {
                delete config[vtypeConfigProp];
            }
        });

        if (vtype !== 'CLOUD_VCENTER' || !this._hasValidNsxConfiguration()) {
            delete config.nsx_configuration;
        }

        if (vtype !== 'CLOUD_VCENTER' || !config.apic_mode) {
            delete config.apic_configuration;
            delete config.apic_mode;
        }

        switch (vtype) {
            case 'CLOUD_AWS':
                config.dhcp_enabled = true;
                config.ipam_provider_ref = undefined;

                if (cloudConfig.route53_integration) {
                    delete config.dns_provider_ref;
                }

                if (!config.useProxy) {
                    delete config.proxy_configuration;
                }

                if (!this.awsSqsEncryptionIsSupported()) {
                    delete cloudConfig['sqs_encryption'];
                }

                if (config.custom_tags && !config.custom_tags.length) {
                    delete config.custom_tags;
                }

                delete config.useProxy;

                break;

            case 'CLOUD_OPENSTACK': {
                if (!cloudConfig.nuage_config) {
                    delete cloudConfig.nuage_vsd_host;
                    delete cloudConfig.nuage_username;
                    delete cloudConfig.nuage_password;
                    delete cloudConfig.nuage_port;
                    delete cloudConfig.nuage_organization;
                }

                delete cloudConfig.nuage_config;

                if (!cloudConfig._useContrail) {
                    delete cloudConfig.contrail_endpoint;
                    delete cloudConfig._useContrail;
                }

                if (!cloudConfig.privilege) {
                    cloudConfig.privilege = 'WRITE_ACCESS';
                }

                const { provider_vip_networks: providerVipNetworks } = cloudConfig;

                if (providerVipNetworks && providerVipNetworks.length) {
                    providerVipNetworks.forEach((network, index) => {
                        const providerVipNetwork = {};

                        providerVipNetwork.os_network_uuid = network.os_network_uuid.slug();

                        if (network.os_tenant_uuids &&
                            network.os_tenant_uuids.length) {
                            providerVipNetwork.os_tenant_uuids = network.os_tenant_uuids;
                        }

                        providerVipNetworks[index] = providerVipNetwork;
                    });
                } else {
                    cloudConfig.provider_vip_networks = undefined;
                }

                // dropping refs here since keystone is expecting names only
                if ('role_mapping' in cloudConfig) {
                    cloudConfig['role_mapping'].forEach(record => {
                        const { avi_role: roleRef } = record;

                        record['avi_role'] = roleRef.name() || roleRef;
                    });
                }

                config.dhcp_enabled = true;
                config.ipam_provider_ref = undefined;

                break;
            }

            case 'CLOUD_VCA':
                config.vca_configuration.privilege = 'WRITE_ACCESS';
                config.dhcp_enabled = true;
                config.ipam_provider_ref = undefined;

                break;

            case 'CLOUD_LINUXSERVER':
                if (Array.isArray(cloudConfig.hosts)) {
                    cloudConfig.hosts.forEach(host => {
                        const hostAttr = host.host_attr_;

                        if (!angular.isUndefined(hostAttr)) {
                            const attrs = [];

                            _.each(Object.keys(hostAttr), prop => {
                                attrs.push(
                                    this.createLinuxServerHostAttr(prop, hostAttr[prop]),
                                );
                            });

                            host.host_attr = attrs;
                            delete host.host_attr_;
                        }
                    });
                }

                break;

            case 'CLOUD_OSHIFT_K8S': {
                setSSHUser(cloudConfig);
                removeAttributes(cloudConfig);

                if (!cloudConfig._useNuage) {
                    delete cloudConfig.nuage_controller;
                }

                const httpPorts = cloudConfig.http_container_ports;

                if (Array.isArray(httpPorts) && !httpPorts.join('')) {
                    delete cloudConfig.http_container_ports;
                }

                if (cloudConfig.se_deployment_method === 'SE_CREATE_POD') {
                    delete cloudConfig.use_service_cluster_ip_as_ew_vip;
                }

                break;
            }

            case 'CLOUD_AZURE':
                config.azure_configuration = this.getCloudConfig().dataToSave();
                break;

            case 'CLOUD_GCP':
                delete config.dnsType;

                config.gcp_configuration = this.getCloudConfig().dataToSave();
                break;
        }

        return config;
    };

    /**
     * Sets SSHUser based on cloud config.
     * @param {Object} config - Cloud-specific configuration.
     */
    function setSSHUser(config) {
        const sshDeploy = config.ssh_se_deployment;

        if (sshDeploy && angular.isString(sshDeploy.ssh_user)) {
            sshDeploy.ssh_user = sshDeploy.ssh_user.name() || sshDeploy.ssh_user;
        }
    }

    /**
     * Removes se_exclude_attributes and se_include_attributes from cloud config if applicable.
     * @param {Object} config - Cloud-specific configuration.
     */
    function removeAttributes(config) {
        if (!config.excludeAttributes) {
            delete config.se_exclude_attributes;
        }

        delete config.excludeAttributes;

        if (!config.includeAttributes) {
            delete config.se_include_attributes;
        }

        delete config.includeAttributes;
    }

    /**
     * Adds se_exclude_attributes and se_include_attributes from cloud config.
     * @param {Object} config - Cloud-specific configuration.
     */
    function addAttributes(config) {
        if (!config.se_exclude_attributes) {
            config.se_exclude_attributes = [{}];
        } else {
            config.excludeAttributes = true;
        }

        if (!config.se_include_attributes) {
            config.se_include_attributes = [{}];
        } else {
            config.includeAttributes = true;
        }
    }

    /**
     * Used on setting/updating Cloud type for consistency of Cloud state.
     * @param {string} prevVtype - Previous cloud type. Used to determine whether to clear IPAM and
     *     DNS refs, since they should not be cleared for container clouds going to no-orchestrator
     *     due to a potential IPAM leak.
     */
    Cloud.prototype.removeCloudConfig = function(prevVtype) {
        const { config } = this.data;

        _.each(this.vtypeConfigHash, vtypeConfig => delete config[vtypeConfig]);

        delete config.apic_mode;
        delete config.apic_configuration;
        delete config.nsx_configuration;

        if (!(prevVtype in containerClouds)) {
            delete config.ipam_provider_ref;
            delete config.dns_provider_ref;
            delete config.east_west_ipam_provider_ref;
            delete config.east_west_dns_provider_ref;
        }
    };

    /**
     * Resets DHCP Checkboxes in Datacenter tab to their defaults.
     */
    Cloud.prototype.resetDhcpSettings = function() {
        const config = this.getConfig();
        const defaultCloudConfig = this.getDefaultConfig_();
        const dhcpSettingsFields = [
            'dhcp_enabled',
            'ip6_autocfg_enabled',
            'enable_vip_static_routes',
            'prefer_static_routes',
        ];

        _.each(dhcpSettingsFields, field => {
            config[field] = defaultCloudConfig[field] || false;
        });
    };

    /**
     * Called by radio typed buttons on Cloud Create/Edit Modal.
     * @param {string=} cloudType - Type of the Cloud we are about to switch.
     */
    Cloud.prototype.onCloudTypeChange = function(cloudType) {
        const
            config = this.getConfig(),
            currVtype = this.getVtype();

        this.removeCloudConfig(currVtype);

        if (cloudType) {
            config.vtype = cloudType;
        }

        const
            vtype = this.getVtype(),
            defaultCloudConfig = this.getDefaultConfig_();

        let cloudConfig;

        // Azure cloud uses ConfigItem, which can take care of its own defaults.
        if (vtype === 'CLOUD_AZURE') {
            config.azure_configuration = new AzureConfigurationConfig();
            config.azure_configuration.beforeEdit();
            cloudConfig = config.azure_configuration.getConfig();
        } else if (vtype === 'CLOUD_GCP') {
            config.gcp_configuration = new GcpConfigurationConfig();
            config.gcp_configuration.beforeEdit();
            cloudConfig = config.gcp_configuration.getConfig();
        } else {
            const configName = this.vtypeConfigHash[vtype];

            config[configName] = defaultCloudConfig[configName];
            cloudConfig = config[configName];
        }

        switch (vtype) {
            case 'CLOUD_VCENTER':
                config.apic_configuration = defaultCloudConfig['apic_configuration'];
                config.apic_mode = defaultCloudConfig['apic_mode'];
                config.nsx_configuration = defaultCloudConfig['nsx_configuration'];
                this.resetDhcpSettings();
                break;

            case 'CLOUD_AWS':
                this.awsBeforeEdit_();
                break;

            case 'CLOUD_LINUXSERVER':
                this.setLinuxSEUsagePathRadio();
                break;

            case 'CLOUD_OSHIFT_K8S':
                cloudConfig.se_exclude_attributes = [{}];
                cloudConfig.se_include_attributes = [{}];
                break;

            case 'CLOUD_OPENSTACK':
                cloudConfig.provider_vip_networks = [];
                break;

            case 'CLOUD_GCP':
                config.dnsType = 'none';
                break;
        }

        this.resetLicenseType_();

        return $q.when(true);
    };

    /**
     * Resets current license_type if doesn't belong to allowed licenses in new Cloud.
     * @protected
     */
    Cloud.prototype.resetLicenseType_ = function() {
        const
            config = this.getConfig(),
            { vtype: vType } = config,
            { license_type: currentLicenseType } = config,
            allowedLicenseTypes = CloudLicenseTypes[vType];

        if (currentLicenseType in allowedLicenseTypes) {
            return;
        }

        delete config.license_type;

        if (vType === 'CLOUD_NONE') {
            config.license_type = 'LIC_CORES';
        }
    };

    /**
     * Saves a Cloud without any particularly set Cloud type.
     * @returns {promise}
     */
    Cloud.prototype.setCloudNone = function() {
        this.onCloudTypeChange('CLOUD_NONE');

        return this.save(false);
    };

    /**
     * Verifies vCenter credentials.
     * @returns {promise} - Will be resolved or rejected depending on the check results.
     */
    Cloud.prototype.vCenterLoginVerify = function() {
        if (!this.busy) {
            this.errors = null;
            this.busy = true;

            const {
                username,
                password,
                vcenter_url: vCenterUrl,
            } = this.getCloudConfig();

            const payload = {};

            if (password && password !== secretStubStr) {
                payload['username'] = username;
                payload['password'] = password;
                payload['vcenter_url'] = vCenterUrl;
            } else {
                payload['cloud_uuid'] = this.id;
            }

            return this.request('post', '/api/vimgrvcenterruntime/verify/login', payload)
                .then(rsp => {
                    this.busy = false;

                    return rsp;
                }, rsp => {
                    this.busy = false;
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                });
        }

        return $q.reject(this.isBusyError());
    };

    /**
     * Sends rediscover command to server if discovery or credentials fail.
     * @return {promise} - Ng Http Promise.
     */
    Cloud.prototype.vCenterRediscover = function() {
        const api = '/api/vimgrvcenterruntime/initiate/rediscover';

        return this.request('post', api, { cloud: this.id });
    };

    /**
     * Verifies vCenter APIC credentials.
     * @returns {promise} - Will be resolved or rejected depending on the results of check.
     */
    Cloud.prototype.APICLogin = function() {
        const self = this;
        let
            payload,
            promise;

        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            payload = _.pick(this.data.config.apic_configuration,
                ['apic_name', 'apic_username', 'apic_password']);

            promise = this.request('post', '/api/apic/verify/login', payload)
                .catch(function(rsp) {
                    self.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(function() {
                    self.busy = false;
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Called in vCenterLogin to verify NSX credentials.
     * @return {ng.$q.promise}
     */
    Cloud.prototype.nsxLogin = function() {
        let promise;

        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            const nsxConfig = this.getConfig().nsx_configuration;
            const payload = {
                nsx_name: nsxConfig.nsx_manager_name,
                nsx_username: nsxConfig.nsx_manager_username,
                nsx_password: nsxConfig.nsx_manager_password,
            };

            promise = this.request('POST', '/api/nsx/verify/login', payload)
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Checks the provided credentials of vCenter normally before saving a Cloud item.
     * @param {boolean=} fromWelcome - Set to true when called from welcome
     * wizard to skip APIC related checks.
     * @returns {promise} - Will be resolved if all checks have passed.
     */
    Cloud.prototype.vCenterLogin = function(fromWelcome) {
        return this.vCenterLoginVerify()
            .then(() => {
                const config = this.getConfig();
                let promise = $q.when();

                if (!fromWelcome) {
                    if (config.apic_mode) {
                        promise = this.APICLogin();
                    } else if (this._hasValidNsxConfiguration()) {
                        promise = this.nsxLogin();
                    }
                }

                return promise;
            });
    };

    Cloud.prototype.getvCenterDC = function() {
        this.datacenters = [];

        return this.getDatacenters().then(function(rsp) {
            this.datacenters = rsp.data.resource.vcenter_dc_info;

            if (this.datacenters.length === 1) {
                const dc1 = this.datacenters[0];
                const cfg = this.data.config.vcenter_configuration;

                cfg.datacenter_ref = dc1.url;
                cfg.datacenter = dc1.name;
            }
        }.bind(this));
    };

    Cloud.prototype.getDatacenters = function() {
        return this.vCenterLoginVerify();
    };

    /**
     * Gets vmware networks and vrfContext
     * @return {ng.$q.promise}
     */
    Cloud.prototype.getvCenterNetworks = function() {
        const { management_network: mgmtNetwork } = this.getCloudConfig();

        this.networks = [];

        if (this.network) {
            this.network.destroy();
            this.network = null;
        }

        this.selected_subnet_object = null;

        return this.getVMWareNetworks()
            .then(({ data }) => {
                this.networks = data.resource.vcenter_pg_names;

                return this.getVRFContext();
            }).then(() => {
                if (mgmtNetwork) {
                    this.setNetwork({ url: mgmtNetwork });
                }
            });
    };

    /**
     * Sets a vCenter's subnet after selecting and loading of a vCenter management network.
     */
    Cloud.prototype.setNetworkSubnet = function() {
        if (this.network) {
            this.network.beforeEdit();

            // Make sure subnet and add pool are defined
            const { config: netConfig } = this.network.data;

            this.network_subnets = netConfig.configured_subnets;

            if (this.network_subnets.length) {
                // First one should be selected by default
                [this.selected_subnet_object] = this.network_subnets;
                this.data.config.vcenter_configuration.selected_subnet =
                    this.network_subnets[0].subnet;

                if (Array.isArray(this.network_subnets[0].static_ipaddr_tmp) &&
                    !this.network_subnets[0].static_ipaddr_tmp.length) {
                    this.network_subnets[0].static_ipaddr_tmp = [''];
                }
            } else {
                this.network_subnets[0] = {};
                this.network_subnets[0].static_ipaddr_tmp = [''];
                delete this.data.config.vcenter_configuration.selected_subnet;
            }
        }
    };

    /**
     * Called after management network selection (by user from table or on initial Modal's load).
     * @param {Object} network - Network Item.
     * @returns {promise}
     */
    Cloud.prototype.setNetwork = function(network) {
        const cfg = this.data.config;
        const vcenterConfig = cfg.vcenter_configuration;

        if (!network.url) {
            network.url = `/api/vipgnameinfo/${network.uuid}`;
        }

        vcenterConfig.management_network = network.url;

        if (this.network) {
            this.network.destroy();
        }

        this.network = new ConfiguredNetwork({
            id: network.url.slug(),
        });

        if (vcenterConfig.selected_subnet) {
            delete vcenterConfig.selected_subnet;
        }

        if (!this.busy) {
            this.errors = null;
            this.busy = true;
            this.network.load('', true).then(() => {
                this.setNetworkSubnet();
            }).catch(() => {
                network.dhcp_enabled = true;
                network._overrideURL = '/api/network';
                network.cloud_ref = cfg.url;
                network.cloud_uuid = cfg.uuid;
                network.dhcp_enabled = cfg.dhcp_enabled;
                this.network_subnets = [
                    {
                        static_ipaddr_tmp: [''],
                    },
                ];
                network.configured_subnets = this.network_subnets;
                this.network.data.config = network;
            }).finally(() => {
                this.busy = false;
            });
        } else {
            this.isBusyError('By setNetwork');
        }
    };

    Cloud.prototype.selectSubnet = function() {
        // Just get the subnet object to be able to edit it's ip pool
        const self = this;

        this.selected_subnet_object = _.find(this.network_subnets, function(subnet) {
            return subnet.subnet === self.selected_subnet;
        });
    };

    /**
     * Creates a Subnet object from a subnet string, ex. 10.10.10.10/24.
     * @param  {string} subnet Subnet string, should contain both IP address and mask separated by
     *                         a slash.
     * @return {Object}        Subnet object containing ip_addr and mask.
     */
    Cloud.prototype.subnetStringToObj = function(subnet) {
        const subnetChunks = subnet.split('/');
        let subnetObj;

        if (subnetChunks.length == 2) {
            subnetObj = {
                ip_addr: {
                    addr: subnetChunks[0],
                    type: 'V4',
                },
                mask: +subnetChunks[1],
            };
        }

        return subnetObj;
    };

    Cloud.prototype.copySubnetFromCloud = function() {
        const cfg = this.network.data.config;

        if (cfg.dhcp_enabled) {
            return;
        }

        const subnet = this.data.config.vcenter_configuration.selected_subnet;

        if (subnet) {
            if (!cfg.configured_subnets || !cfg.configured_subnets.length) {
                cfg.configured_subnets = [{}];
            }

            cfg.configured_subnets[0].subnet = subnet;
        }

        return subnet;
    };

    Cloud.prototype.copyStaticAddrFromCloud = function() {
        const cfg = this.network.data.config;

        if (cfg.dhcp_enabled) {
            return;
        }

        const networkSubnets = this.network_subnets;

        if (networkSubnets && networkSubnets.length) {
            if (!cfg.configured_subnets) {
                cfg.configured_subnets = [{}];
            }

            cfg.configured_subnets[0].static_ipaddr_tmp = networkSubnets[0].static_ipaddr_tmp;
        }
    };

    Cloud.prototype.saveNetwork = function() {
        const self = this;
        const dhcpEnabled = this.network.data.config.dhcp_enabled;

        this.copySubnetFromCloud();

        return this.network.save().then(function() {
            self.busy = false;

            return self.saveVCenterNetwork();
        }).catch(function(rsp) {
            if (rsp.status == 409) {
                return self.network.load('', true).then(function() {
                    self.network.data.config.dhcp_enabled = dhcpEnabled;
                    self.copyStaticAddrFromCloud();

                    return self.saveNetwork();
                });
            } else {
                self.busy = false;
                self.errors = rsp.data;

                return $q.reject(rsp);
            }
        });
    };

    Cloud.prototype.saveNetworkInfo = function() {
        let promise;

        if (!this.busy && this.network) {
            this.busy = true;
            this.errors = null;
            promise = this.saveNetwork();
        } else {
            promise = $q.reject(this.isBusyError('By saveNetworkInfo'));
        }

        return promise;
    };

    Cloud.prototype.saveVCenterNetwork = function() {
        const cCfg = this.data.config.vcenter_configuration;

        if (this.network.data.config.dhcp_enabled) {
            delete cCfg.management_ip_subnet;
        } else {
            const subnet = this.subnetStringToObj(cCfg.selected_subnet);

            if (subnet) {
                cCfg.management_ip_subnet = subnet;
            } else {
                delete cCfg.management_ip_subnet;
            }
        }

        return this.save(false);
    };

    /**
     * Checks openstack credentials and sets tenants.
     * @returns {promise} - Will be resolved or rejected depending on the results of check.
     */
    Cloud.prototype.openStackLogin = function() {
        let
            payload,
            promise;

        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            payload = _.pick(this.getCloudConfig(),
                ['username', 'password', 'auth_url']);

            if (this.getConfig().uuid &&
                (!payload.password || payload.password === secretStubStr)) {
                payload.uuid = this.getConfig().uuid;
                delete payload.username;
                delete payload.password;
            }

            promise = this.request('post', '/api/openstack-verify-credentials', payload)
                .then(rsp => {
                    this.busy = false;

                    const cloudConfig = this.getCloudConfig();

                    this.oldTenant = cloudConfig.admin_tenant;
                    this.oldRegion = cloudConfig.region;

                    this.tenant_ids = rsp.data.tenants;

                    if (this.getCloudConfig().admin_tenant) {
                        // If tenant was there already then we need to preload networks for it
                        const tenantObj = this.getTenantID();

                        this.onTenantChange(tenantObj);
                    }
                })
                .catch(rsp => {
                    this.busy = false;
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    Cloud.prototype.getTenantID = function() {
        const selectedTenant = this.getCloudConfig().admin_tenant;

        return _.find(this.tenant_ids, function(tenant) {
            return tenant.name === selectedTenant;
        });
    };

    Cloud.prototype.onTenantChange = function(tenantObj) {
        if (!tenantObj) {
            return $q.when(false);
        }

        let promise;

        const cloudConfig = this.getCloudConfig();

        if (!this.busy) {
            this.busy = true;
            this.errors = null;
            this.networks = [];

            if (tenantObj.name !== this.oldTenant) {
                delete cloudConfig.region;
                delete cloudConfig.mgmt_network_name;
                cloudConfig.provider_vip_networks = [];
                this.oldTenant = cloudConfig.admin_tenant;
            }

            const payload = this.getOpenstackTenantNetworksPayload(tenantObj.id);

            promise = this.request('post', '/api/openstack-get-tenant-networks',
                payload, null, 'openstack-get-tenant-networks')
                .then(rsp => {
                    this.networksByRegion = rsp.data.networks;
                    this.mapOpenstackNetworksByRegion_();
                    this.mapProviderNetworksToOpenstackNetworks_();
                })
                .catch(rsp => {
                    delete cloudConfig.region;
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => {
                    this.busy = false;
                });
        } else {
            promise = $q.reject(this.isBusyError('By onTenantChange'));
        }

        return promise;
    };

    /**
     * Returns  credentials object for Openstack tenant networks API that require them.
     * @param {string} tenantId - tenant Id.
     * @returns {Object}
     */
    Cloud.prototype.getOpenstackTenantNetworksPayload = function(tenantId) {
        const config = this.getConfig();
        const cloudConfig = this.getCloudConfig();

        const payload = _.pick(cloudConfig,
            ['username', 'password', 'auth_url', 'use_internal_endpoints']);

        if (config.uuid &&
            (!payload.password || payload.password === secretStubStr)) {
            payload.uuid = config.uuid;
            delete payload.username;
            delete payload.password;
        }

        payload.tenant_uuid = tenantId;

        return payload;
    };

    /**
     * Maps the networks based on their regions.
     * If there is no region selected, the first region in the list gets assigned
     * to the cloudConfig region.
     */
    Cloud.prototype.mapOpenstackNetworksByRegion_ = function() {
        const cloudConfig = this.getCloudConfig();

        const regions = this.getOpenstackRegions();

        if (!cloudConfig.region && regions.length) {
            cloudConfig.region = regions[0].name;
        }

        if (cloudConfig.region) {
            this.onRegionChange();
        } else {
            delete cloudConfig.region;
        }
    };

    /**
     * Returns region list.
     * @returns {Object.<string, string>}
     */
    Cloud.prototype.getOpenstackRegions = function() {
        return _.map(this.networksByRegion, function(region) {
            return { name: region.region };
        });
    };

    /**
     * Maps each existing provider network to the relevant Openstack
     * network based on the given network uuid and creates
     * ref by appending name along with its uuid.
     */
    Cloud.prototype.mapProviderNetworksToOpenstackNetworks_ = function() {
        const cloudConfig = this.getCloudConfig();
        const { region } = cloudConfig;

        const networks = _.findWhere(this.networksByRegion, { region });

        const { networks: regionBasedNetworks } = networks;

        const { provider_vip_networks: providerVipNetworks } = cloudConfig;

        providerVipNetworks.forEach(providerVip => {
            if (providerVip && providerVip.os_network_uuid &&
                providerVip.os_tenant_uuids) {
                const network = _.findWhere(regionBasedNetworks,
                    { id: providerVip.os_network_uuid });

                if (network) {
                    const url = `${network.id}#${network.name}`;

                    providerVip.os_network_uuid = url;
                }
            }
        });
    };

    /**
     * UI thing. TODO should be moved to CloudCreate and Welcome controllers.
     */
    Cloud.prototype.onRegionChange = function() {
        const cloudConfig = this.getCloudConfig();
        const { region } = cloudConfig;

        if (this.oldRegion !== region) {
            cloudConfig.mgmt_network_name = null;
            this.oldRegion = region;
        }

        if (region) {
            const networks = _.findWhere(this.networksByRegion, { region });

            this.networks = networks ? networks.networks : null;
        }
    };
    /**
     * Checks credentials of OpenStack Nuage plugin.
     * @returns {promise} - Will be resolved or rejected depending on the results of check.
     */

    Cloud.prototype.checkNuageCreds = function() {
        const osConfig = this.getCloudConfig();
        const self = this;

        let
            payload,
            promise;

        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            payload = {
                vsd_host: osConfig.nuage_vsd_host,
                vsd_port: osConfig.nuage_port,
                password: osConfig.nuage_password,
                username: osConfig.nuage_username,
                organization: osConfig.nuage_organization,
            };

            payload = this.getSecretStubPayload_(payload);
            payload.vsd_username = payload.username;
            payload.vsd_password = payload.password;
            delete payload.username;
            delete payload.password;

            promise = this.request('post', '/api/nuage-verify-credentials', payload)
                .catch(function(rsp) {
                    self.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(function() {
                    self.busy = false;
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Makes request to check contrail credentails.
     * @return {ng.$q.promise}
     */
    Cloud.prototype.checkContrailCreds = function() {
        if (this.busy) {
            return $q.reject(this.isBusyError());
        }

        const osConfig = this.getCloudConfig();

        const { username, password, auth_url, contrail_endpoint, admin_tenant } = osConfig;

        let payload = {
            username,
            password,
            auth_url,
            contrail_endpoint,
            tenant_name: admin_tenant,
        };

        payload = this.getSecretStubPayload_(payload);

        this.busy = true;
        this.errors = null;

        return this.request('POST', 'api/contrail-verify-credentials', payload)
            .catch(rsp => {
                this.errors = rsp.data;

                return $q.reject(rsp);
            }).finally(() => this.busy = false);
    };

    /**
     * Continuously checks vCenter inventory_state by calling server API, recursive.
     * @param ip {string} - VCenter IP.
     * @param onSuccess {callback} - Will be called when vCenter is ready.
     * @param onFail {callback} - Will be called if something failed, like wrong credentials.
     */
    Cloud.prototype.getVMWareRuntime = function(ip, onSuccess, onFail) {
        const
            calls = 0,
            maxAttempts = 50,
            def = $q.defer(),
            self = this;

        let promise;

        function recursiveFetch() {
            const api = `/api/vimgrvcenterruntime?include_name&cloud_ref.uuid=${
                self.data.config.uuid}`;

            self.request('get', api, null, null, 'vmware-discovery').then(
                function(rsp) {
                    const r = rsp.data.results;

                    if (r.length) {
                        const state = r[0].inventory_state;

                        if (state === 'CENTER_DISCOVERY_COMPLETE' ||
                            state === 'VCENTER_DISCOVERY_WAITING_DC') {
                            self.busy = false;

                            if (typeof onSuccess === 'function') {
                                onSuccess(rsp);
                            }

                            def.resolve(rsp);
                        } else if (state == 'VCENTER_DISCOVERY_BAD_CREDENTIALS') {
                            self.busy = false;
                            self.errors = {
                                error: 'Could not retrieve data centers because of ' +
                                    'incorrect username or password.',
                            };

                            if (typeof onFail === 'function') {
                                onFail(rsp, 'Incorrect username/password');
                            }

                            def.reject(rsp);
                        } else if (calls >= maxAttempts) {
                            self.busy = false;
                            self.errors = {
                                error: `Could not retrieve data centers in ${
                                    maxAttempts} attempts.`,
                            };

                            if (typeof onFail === 'function') {
                                onFail(rsp, `Could not retrieve data centers in ${maxAttempts
                                } attempts.`);
                            }

                            def.reject(rsp);
                        } else {
                            $timeout(recursiveFetch, 5000);
                        }
                    }
                }, function(rsp) {
                    self.busy = false;
                    self.errors = rsp.data;

                    if (typeof onFail === 'function') {
                        onFail(rsp, 'Error retrieving data centers');
                    }

                    def.reject(rsp);
                },
            );
        }

        if (!this.busy) {
            this.errors = null;
            this.busy = true;

            recursiveFetch();
            promise = def.promise;
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Cancels any pending timeouts.
     */
    Cloud.prototype.cancelTimeouts = function() {
        if (this.vcenterDCTimeout_) {
            $timeout.cancel(this.vcenterDCTimeout_);
            this.vcenterDCTimeout_ = null;

            if (this.busy) {
                this.busy = false;
            }
        }

        if (this.vcenterNetworkTimeout_) {
            $timeout.cancel(this.vcenterNetworkTimeout_);
            this.vcenterNetworkTimeout_ = null;

            if (this.busy) {
                this.busy = false;
            }
        }
    };

    /**
     * Overrides {@link Item#dismiss} method.
     * @param {boolean} silent
     * @override
     */
    Cloud.prototype.dismiss = function(silent) {
        UpdatableItem.prototype.dismiss.call(this, silent);
        this.cancelTimeouts();
    };

    /**
     * Standard API response with 'runtime' is not present, so overriding.
     * @return {Object|null}
     * @override
     * */
    Cloud.prototype.getRuntimeData = function() {
        return this.data && this.data.status || null;
    };

    /**
     * Gets the list of networks.
     * @returns {promise} - Resolved with the list of networks or rejected on error.
     */
    Cloud.prototype.getVMWareNetworks = function() {
        const self = this;

        this.busy = true;

        const cfg = this.data.config;
        const vCfg = cfg.vcenter_configuration;
        const url = '/api/vimgrvcenterruntime/retrieve/portgroups?page_size=200';
        let payload = {};

        if (cfg.uuid && (!vCfg.password || vCfg.password === secretStubStr)) {
            payload.cloud_uuid = cfg.uuid;
        } else {
            payload = _.pick(this.data.config.vcenter_configuration,
                ['username', 'password', 'vcenter_url', 'datacenter']);
        }

        return self.request('post', url, payload).then(function(rsp) {
            self.busy = false;

            return rsp;
        }, function(rsp) {
            self.busy = false;
            self.errors = rsp.data;

            return $q.reject(rsp);
        });
    };

    /* APIs for AWS Cloud */

    /**
     * Requests AWS AssumeRole list.
     * @returns {ng.Promise}
     */
    Cloud.prototype.getAwsIamAssumeRoles = function() {
        const cfg = this.getCloudConfig();

        this.cancelRequests(this.awsAssumeRoleRequestGroup_);

        const [payload, api] = [this.getAwsCredentialsPayload_(), '/api/aws-get-assume-roles'];

        this.errors = null;
        cfg._iamRoleBusy = true;
        this.busy = true;
        cfg._iamRoleMessage = '';

        return this.request('post', api, payload, null, this.awsAssumeRoleRequestGroup_)
            .then(({ data }) => {
                this.iamAssumeRoles = data.assume_roles;

                if (!this.iamAssumeRoles.length) {
                    cfg._iamRoleMessage = 'no IAM cross account roles';
                }
            }).catch(rsp => {
                this.errors = rsp.data;

                return $q.reject(rsp);
            }).finally(() => {
                cfg._iamRoleBusy = false;
                this.busy = false;
            });
    };

    /**
     * Loads AWS encryption keys, expecting certain AWS configuration fields to be set up in
     * cloud config.
     * @param {string} type - s3/ebs/sqs.
     * @returns {ng.$q.promise}
     * @public
     */
    Cloud.prototype.loadAwsEncryptionKeys = function(type) {
        const
            url = '/api/aws-get-kms-cmks',
            reqName = `aws-enc-keys-${type}`;

        const payload = this.getAwsCredentialsPayload_();

        payload.service = type;
        payload.vpc = null;

        this.cancelRequests(reqName);

        this.errors = null;
        this.busy = true;

        return this.request('post', url, payload, null, reqName)
            .then(
                ({ data }) => {
                    //in case if the previous pending call got cancelled
                    this.errors = null;

                    return Cloud.parseAWSEncryptionKeys_(data.keys);
                },
            )
            .catch(
                ({ data }) => $q.reject(this.errors = data),
            )
            .finally(
                () => this.busy = false,
            );
    };

    /**
     * Transforms AWS keys objects for UI use. Puts default one to the top of the list.
     * @param {Object[]} keys
     * @returns {Object[]}
     * @static
     * @protected
     */
    Cloud.parseAWSEncryptionKeys_ = function(keys) {
        if (!Array.isArray(keys)) {
            return [];
        }

        let defaultKeyIndex;

        keys.forEach((key, index) => {
            const {
                alias,
                arn,
                default: isDefault,
            } = key;

            key.shortArn = arn.replace(Cloud.AWSEncryptionKeyArnRegex_, '');

            const { shortArn } = key;

            key.id = `${shortArn} | ${alias}`;
            key.label = alias ? `${alias} - ${shortArn}` : shortArn;

            if (isDefault) {
                defaultKeyIndex = index;
            }
        });

        // when found and not 0, let's move it to the top of the list
        if (defaultKeyIndex) {
            const [defaultKey] = keys.splice(defaultKeyIndex, 1);

            keys.unshift(defaultKey);
        }

        return keys;
    };

    /**
     * Regular expression to drop key prefix of arn.
     * @type {RegExp}
     * @protected
     */
    Cloud.AWSEncryptionKeyArnRegex_ = /^.*:key\//i;

    Cloud.prototype.getAwsRegions = function() {
        const self = this;
        let promise;

        if (!this.busy) {
            this.errors = null;
            this.busy = true;

            promise = this.request('get', '/api/aws-get-regions')
                .then(function(rsp) {
                    self.region_list = rsp.data.regions;

                    if (self.region_list.length === 1) {
                        self.data.config.aws_configuration.region = self.region_list[0].name;
                    }
                }, function(rsp) {
                    self.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(function() {
                    self.busy = false;
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Returns AWS credentials object for AWS API that require them.
     * @returns {Object}
     * @protected
     */
    Cloud.prototype.getAwsCredentialsPayload_ = function() {
        const awsConfig = this.getCloudConfig();

        const {
            access_key_id: key,
            secret_access_key: secret,
        } = awsConfig;

        const payload = {
            username: key === secretStubStr ? undefined : key,
            password: secret === secretStubStr ? undefined : secret,
            region: awsConfig.region,
            iam_assume_role: awsConfig.iam_assume_role,
            use_iam_roles: awsConfig.use_iam_roles,
        };

        const
            config = this.getConfig(),
            { proxy_configuration: proxy } = config;

        if (proxy) {
            payload.proxy_host = proxy.host;
            payload.proxy_port = proxy.port;
            payload.proxy_user = proxy.username;
            payload.proxy_pass = proxy.password;
        }

        if (config.uuid && (!payload.username || !payload.password)) {
            payload.uuid = config.uuid;
            delete payload['proxy_pass'];
        }

        return payload;
    };

    /**
     * Checks the AWS credentials and sets the VPC list (and Avail Zones) if succeeded.
     * If there is only one VPC in the list, that one would be assigned to awsCloud Config' vpc id
     * and the previously selected zone object is getting cleared. If there are more than
     * one VPCs available, the previous selected values are getting cleared in getAwsAzs.
     * @returns {promise}
     */
    Cloud.prototype.awsLogin = function() {
        let
            payload,
            promise;

        if (!this.busy) {
            payload = this.getAwsCredentialsPayload_();

            return this.getVPCList(payload)
                .then(() => {
                    const cloudConfig = this.getCloudConfig();
                    const { vpc_list: vpcList } = this;

                    if (vpcList.length === 1) {
                        cloudConfig.vpc = vpcList[0].name;
                        cloudConfig.vpc_id = vpcList[0].id;
                        cloudConfig.zones = [{}];
                    }

                    if (cloudConfig.vpc_id) {
                        return this.getAwsAzs();
                    }
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Gets and sets VPC List.
     * @param  {Object} payload - Payload object for request.
     * @return {ng.$q.promise}
     */
    Cloud.prototype.getVPCList = function(payload) {
        this.busy = true;
        this.errors = null;

        return this.request('post', 'api/aws-verify-credentials', payload)
            .then(rsp => this.vpc_list = rsp.data.vpcs)
            .catch(rsp => {
                this.errors = rsp.data;

                return $q.reject(rsp);
            })
            .finally(() => this.busy = false);
    };

    /**
     * Checks if availability zone of data.config is present in a list of zones gotten from server
     * when selecting VPC. For ones which are present checks the names of management networks and
     * removes ones not present in a list of subnets. AWS cloud type only.
     * @protected
     * @param {Object} zone - {availability_zone:string, mgmt_network_uuid: string}
     * @returns {boolean} - True when zone is present in a list and false if not.
     */
    Cloud.prototype.haveAwsZoneInList_ = function(zone) {
        const res = !zone.availability_zone || zone.availability_zone in this.az_nw_map;

        //check if we have a previously saved mgmt_network there
        if (res && this.az_nw_map && zone.availability_zone && zone.mgmt_network_uuid) {
            const validNetwork = _.find(this.az_nw_map[zone.availability_zone], subnet => {
                return subnet.id === zone.mgmt_network_uuid;
            });

            if (!validNetwork) {
                delete zone.mgmt_network_uuid;
            }
        }

        return res;
    };

    /**
     * Checks whether SQS encryption is available for the current AWS cloud configuration.
     * @returns {boolean}
     * @public
     */
    Cloud.prototype.awsSqsEncryptionIsSupported = function() {
        const {
            region,
            use_sns_sqs: useSnsSqs,
        } = this.getCloudConfig();

        if (useSnsSqs && region) {
            return region in Cloud.awsSqsEncryptionRegions_;
        }

        return false;
    };

    /**
     * Processes backend response with availability zones and management networks. Removes outdated
     * ones from the config object. AWS cloud type only.
     * @protected
     * @param {Object} rsp - Backend API response object.
     */
    Cloud.prototype.processAwsNetworks_ = function(rsp) {
        this.az_nw_map = {};

        _.each(rsp.data.networks, az => this.az_nw_map[az.availability_zone] = az.subnets);

        const { aws_configuration: awsConfig } = this.getConfig();

        if (!angular.isArray(awsConfig.zones)) {
            awsConfig.zones = [];
        }

        //sync with existing avail zones and subnets if we have more then one empty default one
        if (awsConfig.zones.length > 1 && awsConfig.zones[0].availability_zone) {
            awsConfig.zones = _.filter(awsConfig.zones, zone => this.haveAwsZoneInList_(zone));
        }

        if (!awsConfig.zones.length) {
            this.addAwsAvailabilityZone();
        }

        if (rsp.data.networks.length === 1 && !awsConfig.zones[0].availability_zone) {
            awsConfig.zones[0].availability_zone = rsp.data.networks[0].availability_zone;
        }
    };

    /**
     * Add AWS availability zone.
     */
    Cloud.prototype.addAwsAvailabilityZone = function() {
        const { aws_configuration: awsConfig } = this.getConfig();

        if (!angular.isArray(awsConfig.zones)) {
            awsConfig.zones = [];
        }

        awsConfig.zones.push({});
    };

    /**
     * Handles AWS DNS Settings radio group change.
     */
    Cloud.prototype.awsDnsTypeChange = function() {
        const cfg = this.data.config;
        const awsCfg = cfg.aws_configuration;

        switch (awsCfg._dnsType) {
            case 1:
                awsCfg.route53_integration = false;
                delete cfg.dns_provider_ref;
                break;
            case 2:
                delete cfg.dns_provider_ref;
                awsCfg.route53_integration = true;
                break;
            case 3:
                awsCfg.route53_integration = false;
                break;
        }
    };

    /**
     * Gets AWS availability zones.
     * @returns {promise}
     */
    Cloud.prototype.getAwsAzs = function() {
        let
            payload,
            promise;

        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            const cloudConfig = this.getCloudConfig();

            const vpc = _.findWhere(this.vpc_list, { id: cloudConfig.vpc_id });

            if (vpc) {
                cloudConfig.vpc = vpc.name;
            } else {
                cloudConfig.zones = [{}];

                this.az_nw_map = {};

                delete cloudConfig.vpc;
                delete cloudConfig.vpc_id;

                this.busy = false;

                return $q.when(false);
            }

            payload = this.getAwsCredentialsPayload_();
            payload.vpc = cloudConfig.vpc_id;

            promise = this.request('post', 'api/aws-get-networks', payload)
                .then(rsp => this.processAwsNetworks_(rsp))
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => {
                    this.busy = false;
                });
        } else {
            promise = $q.reject(this.isBusyError());
        }

        return promise;
    };

    /**
     * Saves Cloud with AWS configuration after filtering out the empty availability zones.
     * @param {boolean=} submit - When true Item#submit will be called, Item#save otherwise. Be
     *     aware of $event when using with ng-click.
     * @returns {*}
     */
    Cloud.prototype.saveAwsConfiguration = function(submit) {
        this.data.config.aws_configuration.zones =
            _.filter(this.data.config.aws_configuration.zones, zone => {
                return zone.availability_zone && zone.mgmt_network_uuid;
            });

        return submit ? this.submit() : this.save();
    };

    /**
     * Removes the previously selected vpc and zones from aws_configuration
     * when "Cross-Account Assume Role" checkbox is unchecked.
     * It resets iam_assume_role flag as well.
     */
    Cloud.prototype.removeAwsVpcIdAndZones = function() {
        const cloudConfig = this.getCloudConfig();

        delete cloudConfig.iam_assume_role;
        delete cloudConfig.vpc_id;
        delete cloudConfig.zones;
    };

    /**
     * Function to be called on ng-change when useProxy checkbox is selected/de-selected. Removes
     * proxy_configuration object from Cloud.
     */
    Cloud.prototype.removeProxyConfig = function() {
        if (!this.data.config.useProxy) {
            delete this.data.config.proxy_configuration;
        }
    };

    /* ----- vCloudAir APIs ----- */

    Cloud.prototype.VCALogin = function() {
        const self = this;

        const payload = {
            username: this.data.config.vca_configuration.vca_username,
            password: this.data.config.vca_configuration.vca_password,
            instance: this.data.config.vca_configuration.vca_instance,
            vdc: this.data.config.vca_configuration.vca_vdc,
        };

        this.errors = null;

        return this.request('post', '/api/vca-get-networks', payload).then(function(rsp) {
            if (rsp.data.networks && rsp.data.networks.length) {
                self.vca_nw_list = rsp.data.networks[0].subnets;
            } else {
                self.vca_nw_list = [];
            }
        }, function(rsp) {
            self.errors = rsp.data;
        });
    };

    /**
     * Returns the full name of Cloud type.
     * @param {string} cloudType - Cloud type, for ex. 'CLOUD_VCENTER'.
     * @returns {string}
     * @static
     */
    Cloud.getCloudTypeName = function(cloudType) {
        return Schema.enums['CloudType'].values[cloudType] &&
            Schema.enums['CloudType'].values[cloudType].options.e_description.value || '';
    };

    /**
     * Allow creation of Networks depending on cloud type.
     * @return {boolean}
     * @public
     */
    Cloud.prototype.allowNetworkCreate = function() {
        return this.getVtype() in cloudsAllowingNetworkCreation || false;
    };

    /**
     * Returns true if we want to show button to Select Servers by Network.
     * @return {boolean}
     */
    Cloud.prototype.allowSelectServersByNetwork = function() {
        if (!Auth.isCategoryAllowed('INFRASTRUCTURE', 'rw')) {
            return false;
        }

        if (!this.allowNetworkCreate()) {
            return true;
        }

        if (!this.hasIpamProviderProfile()) {
            return false;
        }

        return this.hasAwsIpamProfile() || this.hasAzureIpamProfile() ||
            this.hasGcpIpamProfile();
    };

    /**
     * Used to set radio value before editing.
     */
    Cloud.prototype.setLinuxSEUsagePathRadio = function() {
        const linux = this.data.config.linuxserver_configuration;

        if (!linux) {
            return;
        }

        if (!_.isUndefined(linux.se_sys_disk_path) && !_.isUndefined(linux.se_log_disk_path)) {
            this.seUsagePath = 'BOTH';
        } else if (!_.isUndefined(linux.se_sys_disk_path)) {
            this.seUsagePath = 'SINGLE';
        } else {
            this.seUsagePath = 'AUTO';
        }
    };

    /**
     * Called on ng-change of Directory Path for SE Usage radio buttons.
     */
    Cloud.prototype.setLinuxSEUsagePath = function() {
        const
            { linuxserver_configuration: linux } = this.getConfig(),
            { linuxserver_configuration: linuxDefaults } = this.getDefaultConfig_();

        linux.se_log_disk_size_GB = linuxDefaults.se_log_disk_size_GB;
        linux.se_sys_disk_size_GB = linuxDefaults.se_sys_disk_size_GB;

        if (this.seUsagePath === 'AUTO') {
            linux.se_sys_disk_path = undefined;
            linux.se_sys_disk_size_GB = undefined;
            linux.se_log_disk_path = undefined;
            linux.se_log_disk_size_GB = undefined;

            if (Array.isArray(linux.hosts)) {
                linux.hosts.forEach(function(host) {
                    host.customPaths = false;
                    this.setLinuxServerCustomPaths(host);
                }, this);
            }
        } else if (this.seUsagePath === 'SINGLE') {
            linux.se_log_disk_path = undefined;
            linux.se_log_disk_size_GB = undefined;
        }
    };

    /**
     * Used to set attribute values based on customPaths checkbox and existing length of host_attr.
     * @param {Object} host - Linuxserver host object.
     */
    Cloud.prototype.setLinuxServerCustomPaths = function(host) {
        const attr = host.host_attr_ || {};

        if (host.customPaths) {
            attr['SYS_DISK_PATH'] = '';
            attr['SYS_DISK_SIZE_GB'] = 10;
            attr['LOG_DISK_PATH'] = '';
            attr['LOG_DISK_SIZE_GB'] = 5;
        } else {
            delete attr['SYS_DISK_PATH'];
            delete attr['SYS_DISK_SIZE_GB'];
            delete attr['LOG_DISK_PATH'];
            delete attr['LOG_DISK_SIZE_GB'];
        }

        host.host_attr_ = attr;
    };

    /**
     * Sets vCenter access privilege.
     * @param {String} privilege - 'write' or 'read' access.
     */
    Cloud.prototype.setVcenterPrivilege = function(privilege) {
        const
            config = this.getConfig(),
            vcenterConfig = config.vcenter_configuration;

        if (privilege === 'write') {
            vcenterConfig.privilege = 'WRITE_ACCESS';
            delete config.dns_provider_ref;
            delete config.ipam_provider_ref;
        } else if (privilege === 'read') {
            vcenterConfig.privilege = 'READ_ACCESS';
        }
    };

    /**
     * Handles ng-change event on Openstack Auth URL input.
     */
    Cloud.prototype.handleOpenstackAuthUrlChange = function() {
        const config = this.getCloudConfig();

        if (!angular.isUndefined(config.auth_url) && config.auth_url.beginsWith('https://')) {
            config.insecure = true;
        } else {
            config.insecure = false;
        }
    };

    /**
     * Returns true if the type of cloud supports custom VRF contexts.
     * @return {boolean}
     */
    Cloud.prototype.allowCustomVRFContext = function() {
        const allowedTypes = {
            CLOUD_VCENTER: true,
            CLOUD_LINUXSERVER: true,
            CLOUD_NONE: true,
        };

        return this.getVtype() in allowedTypes;
    };

    /**
     * Called when changing between 3rd party integration in vCenter cloud.
     * @param  {string} integration - 'nsx' or 'none'.
     */
    Cloud.prototype.handleThirdPartyIntegrationChange = function(integration) {
        const config = this.getConfig();

        config.apic_mode = false;
        delete config.apic_configuration;

        if (integration === 'nsx') {
            config.nsx_configuration = this.getDefaultConfig_().nsx_configuration;
        } else {
            delete config.nsx_configuration;
        }
    };

    /**
     * Checks nsx_configuration object to check if it's populated with required properties.
     * @return {boolean}
     */
    Cloud.prototype._hasValidNsxConfiguration = function() {
        const { nsx_configuration: nsx } = this.getConfig();

        return angular.isObject(nsx) && !angular.isUndefined(nsx.nsx_manager_name);
    };

    /**
     * Returns the Cloud vtype.
     * @return {string|null}
     */
    Cloud.prototype.getVtype = function() {
        const config = this.getConfig();

        return config && config.vtype;
    };

    /**
     * Returns true if the Cloud has a configured DNS provider profile.
     * @returns {boolean}
     */
    Cloud.prototype.hasDnsProviderProfile = function() {
        const config = this.getConfig();

        if (!angular.isUndefined(config['dns_provider_ref'])) {
            return true;
        }

        if (this.getVtype() === 'CLOUD_AZURE') {
            return !!this.getCloudConfig()['use_azure_dns'];
        }

        return false;
    };

    /**
     * Returns true if the Cloud has a configured IPAM provider profile.
     */
    Cloud.prototype.hasIpamProviderProfile = function() {
        return !angular.isUndefined(this.getConfig()['ipam_provider_ref']);
    };

    /**
     * Checks K8s credentials.
     * @returns {ng.$q.promise} - To be resolved with true (in case of success) or false.
     * @public
     */
    Cloud.prototype.checkKubernetesCreds = function() {
        if (!this.busy) {
            const
                endpoint = 'k8s-verify-login',
                { oshiftk8s_configuration: config } = this.getConfig(),
                { service_account_token: token } = config;

            this.busy = true;
            this.errors = null;

            const payload = {
                nodes: config['master_nodes'],
                ca_key_cert_uuid: config['ca_tls_key_and_certificate_ref'],
                client_key_cert_uuid: config['client_tls_key_and_certificate_ref'],
            };

            if (token === secretStubStr) {
                this.busy = false;

                return $q.resolve(true);
            } else {
                payload.account_token = token;
            }

            return this.request('post', `/api/${endpoint}`, payload, undefined, endpoint)
                .then(() => true)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(false);
                })
                .finally(() => this.busy = false);
        } else {
            return $q.reject(this.isBusyError());
        }
    };

    /**
     * Checks whether cloud has an Azure IPAM profile associated. Requires IPAM provider profile
     * to be pre-loaded through join.
     * //TODO switch to cloud inventory API use once implemented
     * @returns {boolean}
     * @public
     */
    Cloud.prototype.hasAzureIpamProfile = function() {
        if (this.hasIpamProviderProfile()) {
            return this.getIpamProfileType() === 'IPAMDNS_TYPE_AZURE';
        }

        return false;
    };

    /**
     * Returns true if GCP is deployed.
     * @return {boolean}
     */
    Cloud.prototype.hasGcpIpamProfile = function() {
        if (this.getVtype() === 'CLOUD_LINUXSERVER' && this.hasIpamProviderProfile()) {
            return this.getIpamProfileType() === 'IPAMDNS_TYPE_GCP';
        }

        return false;
    };

    /**
     * Returns true if cloud instance has an IPAM provider profile with "IPAMDNS_TYPE_AWS" type.
     * @returns {boolean}
     */
    Cloud.prototype.hasAwsIpamProfile = function() {
        if (this.hasIpamProviderProfile()) {
            return this.getIpamProfileType() === 'IPAMDNS_TYPE_AWS';
        }

        return false;
    };

    /**
     * Returns true if the cloud is a container type cloud.
     */
    Cloud.prototype.isContainerCloud = function() {
        return this.getVtype() in containerClouds;
    };

    /**
     * Returns true if Linux Cloud with GCP Ipam profile with use_gcp_network as false.
     * Floating IP support for LinuxServer is provided based on this condition.
     * @returns {boolean}
     */
    Cloud.prototype.isLinuxCloudWithNoGCPNetwork = function() {
        const ipamProfile = this.getIpamProviderProfileConfig();

        return this.getVtype() === 'CLOUD_LINUXSERVER' &&
            this.hasGcpIpamProfile() && !ipamProfile.gcp_profile.use_gcp_network;
    };

    /**
     * Returns config object of IpamDnsProviderProfile protobuf message (if set and loaded).
     * @return {Object|null}
     */
    Cloud.prototype.getIpamProviderProfileConfig = function() {
        return this.getConfig()['ipam_provider_ref_data'] || null;
    };

    /**
     * Returns actual IPAM's profile (nested in IpamDnsProviderProfile) config type.
     * @returns {string}
     */
    Cloud.prototype.getIpamProfileType = function() {
        return this.getIpamProviderProfileConfig()['type'];
    };

    /**
     * Returns a list of Availability Zones.
     * @return {ng.$q.promise}
     */
    Cloud.prototype.getAvailabilityZones = function() {
        if (!this.id) {
            return $q.when([]);
        }

        this.busy = true;
        this.errors = null;

        return this.request('GET', `/api/cloud/${this.id}/availability-zones`)
            .then(({ data }) => data.zones)
            .catch(({ data }) => {
                this.errors = data;

                return $q.reject(data);
            })
            .finally(() => this.busy = false);
    };

    /**
     * Sets the mgmt_network_name field when a management network is selected in a dropdown. Done
     * here instead of with ng-model because both mgmt_network_uuid and mgmt_network_name need to be
     * set on selection.
     * @param {Object} selected- selected object from Dropdown directive.
     * @param {number} index - Index of the availability zone in the aws config.
     */
    Cloud.prototype.setManagementNetwork = function(selected, index) {
        const { aws_configuration: { zones } } = this.getConfig();
        const networks = this.az_nw_map[zones[index].availability_zone];
        const { name } = _.find(networks, network => network.id === selected.value);

        zones[index].mgmt_network_name = name;
    };

    /**
     * Returns true if Cloud or its ConfigItems are busy.
     * @return {boolean}
     */
    Cloud.prototype.isBusy = function() {
        const cloudConfig = this.getCloudConfig();

        return this.busy || (cloudConfig instanceof ConfigItem && cloudConfig.busy) || false;
    };

    /**
     * Returns the errors from Cloud or its ConfigItems.
     * @return {string|Object}
     */
    Cloud.prototype.getErrors = function() {
        const cloudConfig = this.getCloudConfig();

        return this.errors || (cloudConfig instanceof ConfigItem && cloudConfig.errors);
    };

    /**
     * Adds empty attr to ns_exclude_attributes array.
     */
    Cloud.prototype.addNSExcludeAttr = function() {
        const config = this.getCloudConfig();

        if (config) {
            config.ns_exclude_attributes = config.ns_exclude_attributes || [];
            config.ns_exclude_attributes.push({});
        }
    };

    /**
     * Removes attr from ns_exclude_attributes array at index.
     * @param {number} index
     */
    Cloud.prototype.removeNSExcludeAttr = function(index = 0) {
        const config = this.getCloudConfig();

        if (config && Array.isArray(config.ns_exclude_attributes)) {
            config.ns_exclude_attributes.splice(index, 1);
        }
    };

    /**
     * Adds empty attr to ns_include_attributes array.
     */
    Cloud.prototype.addNSIncludeAttr = function() {
        const config = this.getCloudConfig();

        if (config) {
            config.ns_include_attributes = config.ns_include_attributes || [];
            config.ns_include_attributes.push({});
        }
    };

    /**
     * Removes attr from ns_include_attributes array at index.
     * @param {number} index
     */
    Cloud.prototype.removeNSIncludeAttr = function(index = 0) {
        const config = this.getCloudConfig();

        if (config && Array.isArray(config.ns_include_attributes)) {
            config.ns_include_attributes.splice(index, 1);
        }
    };

    /**
     * Returns true for cloud types which provide an endpoint to fetch subnet list.
     * @returns {boolean}
     * @public
     */
    Cloud.prototype.hasSubnetList = function() {
        if (!(this.getVtype() in Cloud.subnetListCloudTypes)) {
            return false;
        }

        if (this.hasIpamProviderProfile()) {
            //certain IPAM profiles also affect that API endpoint
            const type = this.getIpamProfileType();

            return type.indexOf('IPAMDNS_TYPE_INFOBLOX') === -1;
        }

        return true;
    };

    /** @override */
    Cloud.prototype.destroy = function() {
        const gotDestroyed = Cloud.superclass.destroy.call(this);

        if (gotDestroyed) {
            if (this.network) {
                this.network.destroy();
            }
        }

        return gotDestroyed;
    };

    /**
     * Returns true for the clouds where autoscale is available
     * @returns {boolean}
     */
    Cloud.prototype.isServerAutoScaleAvailable = function() {
        const cloudType = this.getVtype();

        return cloudType === 'CLOUD_AWS' || cloudType === 'CLOUD_AZURE' ||
            this.hasAzureIpamProfile() || this.hasGcpIpamProfile();
    };

    /**
     * Checks whether passed cloud or cloud type belongs to container cloud types.
     * @param {string|CloudConfig} cloudType
     * @returns {boolean}
     * @static
     * @public
     */
    Cloud.isContainerCloud = function(cloudType) {
        const type = angular.isObject(cloudType) ? cloudType['vtype'] : cloudType;

        return type in containerClouds;
    };

    /**
     * Returns true for cloud types which provide an endpoint to fetch subnet list.
     * @param {string} type
     * @returns {boolean}
     * @public
     * @static
     * @deprecated Since certain IPAM profile types also affect this feature.
     */
    Cloud.hasSubnetList = function(type) {
        return type in Cloud.subnetListCloudTypes;
    };

    /**
     * Returns true for cloud types allowing network creation.
     * @param {string} type - Cloud type.
     * @returns {boolean}
     * @static
     */
    Cloud.allowsNetCreation = function(type) {
        return type in cloudsAllowingNetworkCreation;
    };

    /**
     * List of container cloud types (ones which support application map).
     * @type {string[]}
     * @static
     * @public
     */
    Cloud.containerCloudTypes = Object.keys(containerClouds);

    /**
     * Hash of cloud types providing a list of subnetworks.
     * @type {{string: boolean}}
     * @static
     * @public
     */
    Cloud.subnetListCloudTypes = {
        CLOUD_OPENSTACK: true,
        CLOUD_VCENTER: true,
        CLOUD_AWS: true,
    };

    /**
     * AWS provides SQS encryption for certain regions only.
     * @type {{string: boolean}}
     * @protected
     * @static
     */
    Cloud.awsSqsEncryptionRegions_ = {
        'us-east-1': true, //N Virginia
        'us-east-2': true, //Ohio
        'us-west-2': true, //Oregon
    };

    /**
     * Informs if provided licenseType is "metered" (i.e. PAYG) or not.
     * @param {string} licenseType - LicenseType enum value.
     * @returns {boolean}
     * @static
     */
    Cloud.isMeteredLicenseType = function(licenseType) {
        return licenseType.indexOf('METERED') !== -1;
    };

    return Cloud;
}]);
