/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name scrollIntoView
 * @description
 *      Scrolls element into view if attribute expression evaluates to true.
 * @example
 *      <div scroll-into-view="true"></div>
 *      <span scroll-into-view="foo == bar"></span>
 *      <span scroll-into-view="1 == 1"></span>
 */
angular.module('aviApp').directive('scrollIntoView', function() {
    return (scope, element, attr) => {
        setTimeout(() => {
            const val = scope.$eval(attr.scrollIntoView);

            if (val) {
                const el = element.get(0);

                if (angular.isFunction(el.scrollIntoView)) {
                    el.scrollIntoView();
                }
            }
        });
    };
});
