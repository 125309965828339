/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './dropdown-menu-rollback-option.component.less';

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @mixes module:avi/upgrade.dropdownMenuRollbackOptionComponentBindings
 */
class DropdownMenuRollbackOptionComponentController {}

/**
 * Option object for Rollback Dropdown Menu.
 * Extends {@link module:avi/component-kit.DropdownListOption}
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name DropdownMenuRollbackOption
 * @property {string} title - Title of the option. This is an child own property added on top of the
 *     parent type DropdownListOption.
 * @property {string} label - Label/content of the option to show in the list.
 * @property {string} value - Value of the option; serves as a unique ID.
 */

/**
 * @mixin dropdownMenuRollbackOptionComponentBindings
 * @memberOf module:avi/upgrade
 * @property {DropdownMenuRollbackOption} option - Option object.
 * @property {Function} onClick - Function to be called with option value.
 */

/**
 * @name dropdownMenuRollbackOptionComponent
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.dropdownMenuRollbackOptionComponentBindings} bindings
 * @property {module:avi/upgrade.DropdownMenuRollbackOptionComponentController} controller
 * @description
 *
 *     The component to build a rollback dropdown menu option for system update.
 *
 * @see {@link module:avi/component-kit.DropdownMenuComponent}
 * @author Zhiqian Liu
 */
angular.module('avi/upgrade').component('dropdownMenuRollbackOption', {
    bindings: {
        option: '<',
        onClick: '&',
    },
    controller: DropdownMenuRollbackOptionComponentController,
    templateUrl: 'src/components/pages/administration/controller/system-update/' +
        'dropdown-menu-rollback-option/dropdown-menu-rollback-option.component.html',
});
