/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name VRFContextCollection
 */
angular.module('aviApp').factory('VRFContextCollection', [
'Collection', 'VRFContext',
function(Collection, VRFContext) {
    class VRFContextCollection extends Collection {
        /**
         * Returns true if any custom VRF Contexts exist, as in there exists a VRF Context that is
         * neither the default 'management' or 'global' VRF Contexts.
         * @return {Boolean} True if custom VRF Contexts exist, false otherwise.
         * @public
         */
        hasCustomVRFContext() {
            return _.any(this.items, vrf => {
                const name = vrf.getName();

                return name !== 'management' && name !== 'global';
            });
        }
    }

    const dataSourcesHash = {
        list: {
            source: 'VRFContextCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    angular.extend(
        VRFContextCollection.prototype, {
            objectName_: 'vrfcontext',
            windowElement_: 'infra-vrf-context-create',
            itemClass_: VRFContext,
            allDataSources_: dataSourcesHash,
            defaultDataSources_: 'list',
        },
    );

    return VRFContextCollection;
}]);
