/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function aggEventCollectionFactory(
    EventCollection,
    AggEvent,
) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/events.EventCollection
     * @desc Collection of {@link module:avi/events.AggEvent AggEvents}.
     */
    class AggEventCollection extends EventCollection {}

    const dataSources = {
        list: {
            source: 'EventListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    AggEventCollection.prototype.itemClass_ = AggEvent;
    AggEventCollection.prototype.allDataSources_ = dataSources;

    return AggEventCollection;
}

aggEventCollectionFactory.$inject = [
    'EventCollection',
    'AggEvent',
];

angular.module('avi/events')
    .factory('AggEventCollection', aggEventCollectionFactory);
