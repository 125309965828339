/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../../less/pages/application/virtualservice-config.less';

angular.module('aviApp').controller('VirtualServiceListController', [
'$scope', 'CRUDGridConfig', '$state', 'VirtualServiceCollection', '$templateCache', 'Auth',
'AviModal', '$q', 'AviAlertService', 'getGridMetricFieldConfig',
function($scope, CRUDGridConfig, $state, VirtualServiceCollection, $templateCache, Auth,
AviModal, $q, AviAlertService, getGridMetricFieldConfig) {
    const gridMetricFields = [{
        require: 'l7_client.avg_complete_responses',
        title: 'RPS',
        fullTitle: 'Requests per second',
        visibility: 'd',
    }, {
        require: 'l4_client.avg_complete_conns',
        title: 'CPS',
        fullTitle: 'Connections per second',
        visibility: 'd',
    }, {
        require: 'l4_client.max_open_conns',
        visibility: 'd',
    }, {
        require: 'l4_client.avg_bandwidth',
        visibility: 'd',
    },
        'l4_client.avg_total_rtt',
        'l4_server.avg_total_rtt',
        'l4_client.avg_errored_connections',
        'l4_client.avg_rx_pkts',
        'l4_client.avg_tx_pkts',
        'l4_client.avg_policy_drops',
        'l4_client.avg_dos_attacks',
        'l7_client.avg_error_responses',
        'l7_client.pct_response_errors',
    ];

    const gridConfig = {};

    gridConfig.layout = {
        includeTimeframeSelector: true,
        includeMetricsValueSelector: true,
    };

    gridConfig.collection = new VirtualServiceCollection({
        bind: {
            // After new item has been created throw user to analytics page
            collItemCreate: ({ id }) => {
                $state.go('^.virtualservice-detail.analytics', { vsId: id });
            },
        },
    });

    gridConfig.createActions = [
        {
            label: 'Basic Setup',
            action() {
                this.config.collection.create('app-vs-create-basic');
            },
        }, {
            label: 'Advanced Setup',
            action() {
                this.config.collection.create();
            },
        },
    ];

    const gridFields = [
        {
            require: 'config',
            name: 'name',
            title: 'Name',
            template: require('./vs-grid-name-column.partial.html'),
            sortBy: 'name',
            visibility: 'm',
        }, {
            // Style based on health name, don't remove
            name: 'health',
            require: 'health,runtime',
            title: 'Health',
            template: '<avi-healthscore item = "row" stop-async-on-hide="true"></avi-healthscore>',
            sortBy: 'health_score',
            visibility: 'm',
        }, {
            require: 'config',
            name: 'address',
            title: 'Address',
            template: '<virtualservice-grid-address row="row" />',
            visibility: 'd',
        }, {
            require: 'config',
            name: 'fqdn',
            title: 'App Domain Name',
            template: require('./vs-grid-fqdn-column.partial.html'),
            visibility: 'd',
        }, {
            require: 'config',
            name: 'services',
            title: 'Service Ports',
            template: '{{ config.getServicesString(row.data.config.services) }}',
            visibility: 'd',
        }, {
            require: 'config',
            name: 'pools',
            title: 'Pools',
            template: require('./vs-grid-pools-column.partial.html'),
            visibility: 'd',
        }, {
            require: 'config',
            name: 'poolgroup',
            title: 'Pool Group',
            template: '{{ row.data.poolgroups | repeatedRefs }}',
        }, {
            require: 'config',
            name: 'se_group',
            title: 'Service Engine Group',
            template: '{{row.data.config.se_group_ref.name()}}',
        }, {
            require: 'runtime',
            name: 'service_engines',
            title: 'Service Engines',
            template: '<virtualservice-grid-service-engine row="row" />',
        }, {
            require: 'config',
            name: 'cloud',
            title: 'Cloud',
            template: '{{ row.getCloudRef().name() }}',
            sortBy: 'cloud_ref',
        }, {
            require: 'config',
            name: 'vrf_context',
            title: 'VRF Context',
            template: '{{ row.getVRFContextRef.name() }}',
        }, {
            require: 'runtime',
            name: 'service_engine_count',
            title: 'Total Service Engines',
            visibility: 'd',
            transform(vs) {
                const runtimeData = vs.getRuntimeData();

                if (runtimeData && Array.isArray(runtimeData.vip_summary)) {
                    return runtimeData.vip_summary.reduce((acc, vip) => {
                        if (Array.isArray(vip.service_engine)) {
                            acc += vip.service_engine.length;
                        }

                        return acc;
                    }, 0);
                }

                return 0;
            },
        }, {
            require: 'config',
            name: 'vrf_context',
            title: 'VRF Context',
            template: '{{ row.getVRFContextRef().name() || "None" }}',
            visibility: 'optional',
        },
    ];

    //let's add metric series fields
    gridFields.push(
        ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)),
    );

    // TODO: Alerts column will not show if PERMISSION_ALERT changes from NO_ACCESS or
    // WRITE_ACCESS to READ_ACCESS unless page is reloaded.
    if (Auth.isPrivilegeAllowed('PERMISSION_ALERT')) {
        gridFields.push({
            require: 'alert',
            name: 'alerts',
            title: 'Alerts',
            template: '<item-alert-bell item="row"></item-alert-bell>',
        });
    }

    gridConfig.fields = gridFields;

    function isVSDisabled(vs) {
        return !vs.data.config.enabled;
    }

    function toggleMultiple(selected, enable) {
        const promises = [];

        selected.forEach(function(vs) {
            if (vs.data.config.enabled !== enable) {
                promises.push(vs.setEnabledState(enable));
            }
        });

        return $q.all(promises)
            .then(function() {
                return gridConfig.collection.load();
            }).catch(function(rsp) {
                AviAlertService.throw(rsp.data);
            });
    }

    gridConfig.multipleactions = [{
        title: 'Delete',
        disabled: rows => _.every(rows, row => !row.isDroppable()),
        do(rows) {
            AviModal.open(
                'virtualservice-delete', {
                    rows,
                    collection: this.config.collection,
                }, 'avi-modal modal3',
            );

            return false;
        },
    }, {
        title: 'Enable',
        disabled(selected) {
            return !_.some(selected, function(vs) {
                return isVSDisabled(vs);
            });
        },
        do(selected) {
            toggleMultiple(selected, true);

            return true;
        },
    }, {
        title: 'Disable',
        disabled(selected) {
            return !_.some(selected, function(vs) {
                return !isVSDisabled(vs);
            });
        },
        do(selected) {
            toggleMultiple(selected, false);

            return true;
        },
    }];

    /**
     * Gets string of VS services to be displayed in grid.
     * @param  {Object} services - VS list of services.
     * @return {String} Comma-separated list of VS services.
     */
    gridConfig.getServicesString = function(services = []) {
        return services.map(service => {
            let result = service.port;

            if (service.port_range_end !== service.port) {
                result += `-${service.port_range_end}`;
            }

            if (service.enable_ssl) {
                result += ' (SSL)';
            }

            return result;
        }).join(', ');
    };

    $scope.gridConfig = CRUDGridConfig(gridConfig);

    $scope.$on('$destroy', () => {
        gridConfig.collection.destroy();
    });
}]);
