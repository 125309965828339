/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('TenantUsersCollDataSource', [
'CollDataSource', function(CollDataSource) {
    /**
     * @param {Object} args
     * @constructor
     * @extends CollDataSource
     */
    function TenantUsersCollDataSource(args) {
        TenantUsersCollDataSource.superconstructor.call(this, args);
    }

    avi.inherit(TenantUsersCollDataSource, CollDataSource);

    TenantUsersCollDataSource.prototype.getRequestParams_ = function() {
        return {
            objectName_: 'user',
            includeName_: true,
            page_size: 200,
        };
    };

    /** @override */
    TenantUsersCollDataSource.prototype.isInactive = function() {
        return !this.owner_.getNumberOfItems();
    };

    /** @override */
    TenantUsersCollDataSource.prototype.processResponse_ = function(resp) {
        const
            allTenantsKey = '__any_tenant|', //should be in sync with TenantUsersDataTransformer
            respHash = {};

        //translates array back to hash
        resp.data.results.forEach(function(tenant) {
            respHash[tenant.tenantId] = tenant;
        });

        _.each(this.owner_.itemById, function(tenant, tenantId) {
            if ('users' in tenant.data && Array.isArray(tenant.data.users)) {
                tenant.data.users.length = 0;
            } else {
                tenant.data.users = [];
            }

            if (tenantId in respHash) {
                Array.prototype.push.apply(tenant.data.users, respHash[tenantId].users);
            }

            if (allTenantsKey in respHash) {
                Array.prototype.push.apply(tenant.data.users, respHash[allTenantsKey].users);
            }
        }, this.owner_);

        return resp;
    };

    return TenantUsersCollDataSource;
}]);
