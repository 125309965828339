/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name InventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     There are three main differences between InventoryCollDataSource and ListCollDataSource which
 *     it extends.
 *
 *     First thing to notice is an extra property `fields_` of ListDataTransportRequestParams.
 *     This one will be finally transformed into query URL part as 'include=field1,field2'.
 *
 *     InventoryCollDataSource uses step and limit values as requests parameters meaning that it has
 *     to listen {@link Timeframe} change event and update them accordingly.
 *
 *     InventoryCollDataSource can work as a main source for `config` data and be used to generate
 *     an ordered list of Items (exactly as ListCollDataSource) or it can provide additional data
 *     such as runtime, alert or health_score and fulfill existent Items with it.
 *
 */
angular.module('aviApp').factory('InventoryCollDataSource', [
'ListCollDataSource', 'Timeframe',
function(ListCollDataSource, Timeframe) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends ListCollDataSource
     */
    function InventoryCollDataSource(args) {
        InventoryCollDataSource.superconstructor.call(this, args);
        this.setStepAndLimitParams_();
    }

    avi.inherit(InventoryCollDataSource, ListCollDataSource);

    /**
     * Dictionary to translate internal field names into ones backend is expecting from us. I.e
     * for grid configs we use `health` but inventory expects `health_score` to be passed as an
     * include value.
     * @type {{string: string}}
     * @private
     */
    InventoryCollDataSource.prototype.dataFields_ = {
        health: 'health_score',
    };

    /**
     * In general we use inventory not as a main source of data hence config is not needed. We
     * can include `config` here only if inventory is the source of `config` data for the list.
     * @overrides
     */
    InventoryCollDataSource.prototype.defaultFields_ = [];

    /** @override */
    InventoryCollDataSource.prototype.getRequestParams_ = function() {
        const params =
            InventoryCollDataSource.superclass.getRequestParams_.call(this, this.params_);

        params['fields_'] = _.map(this.fields_, function(field) {
            return field.id in this.dataFields_ && this.dataFields_[field.id] || field.id;
        }, this);

        return params;
    };

    /**
     * Sets step and limit DS parameters based on currently selected Timeframe.
     * @private
     */
    InventoryCollDataSource.prototype.setStepAndLimitParams_ = function() {
        const tf = Timeframe.selected();

        this.params_['step'] = tf.step;
        this.params_['limit'] = tf.limit;
    };

    /**
     * Event handler for {@link Timeframe} change event. Calls setSetAndLimitParams_ and load if
     * we have some active fields different from `config` (which doesn't depend on step&limit
     * values).
     * @private
     * @override
     */
    InventoryCollDataSource.prototype.onTimeframeChange_ = function() {
        this.setStepAndLimitParams_();

        InventoryCollDataSource.superclass.onTimeframeChange_.call(this);

        if (_.size(this.fields_) > 1 || !('config' in this.fields_)) {
            this.load();
        }
    };

    return InventoryCollDataSource;
}]);
