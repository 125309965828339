/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/* For toggling details (drawers) in tables. Mostly for logs, events, alerts.
* Can work with disabled details views for certain rows. @am */
angular.module('aviApp').factory('detailsExpander', function() {
    return function() {
        let openedQ = 0,
            disabledQ = 0;

        this.items = [];
        this.allOpened = false;
        this.isEmpty = false;//true when we have no expandable items

        this.setLength = function(len, disLen) {
            openedQ = 0;
            disabledQ = disLen || 0;//no expander for disLen elements of len
            this.allOpened = len === disabledQ;
            this.isEmpty = this.allOpened;
            this.items.length = 0;

            for (let i = 0; i < len; i++) {
                this.items[i] = false;
            }
        };

        this.toggle = function(id) {
            if (this.items.length === disabledQ) { return; }

            if (typeof id === 'undefined') {
                this.allOpened = !this.allOpened;

                for (let i = 0; i < this.items.length; i++) {
                    this.items[i] = this.allOpened;
                }

                openedQ = (this.items.length - disabledQ) * this.allOpened;
            } else {
                openedQ += 1 - 2 * this.items[id];
                this.items[id] = !this.items[id];
                this.allOpened = openedQ + disabledQ === this.items.length;
            }
        };
    };
});
