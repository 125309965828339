/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
angular.module('aviApp').factory('eventSearchParser', function() {
    /**
     * Returns Logs API query filter based on pegjs parsed object.
     * @param {Object} parsed - pegjs parsed result.
     * @return {string} - Logs API query filter.
     */
    function getQueryFilter(parsed) {
        if (parsed) {
            const {
                op,
                field,
                wildcard,
                quoted,
                query,
            } = parsed;

            let quote = '';

            if (quoted === 'double' || quoted === 'single' || wildcard) {
                quote = '"';
            }

            return `${op}(${field},${quote}${query}${quote})`;
        }
    }

    /**
     * Parses search term to logs API filters.
     * @param {string} search - Search term.
     * @return {string[]} - Logs API filters.
     */
    function parse(search) {
        const ret = [];
        let parsed;

        try {
            parsed = avi.QueryParser.parse(`event_all: ${search}`);
        } catch (e) {
            search = JSON.stringify(search).slice(1, -1);
            parsed = avi.QueryParser.parse(`event_all: ${search}`);
        }

        if (Array.isArray(parsed)) {
            const ors = [];

            for (let i = 0; i < parsed.length; i++) {
                const val = parsed[i];

                if (val === 'OR') {
                    const q1 = getQueryFilter(parsed[i - 1]);
                    const q2 = getQueryFilter(parsed[i + 1]);

                    if (q1 && q2) {
                        if (!_.contains(ors, q1)) {
                            ors.push(q1);
                        }

                        if (!_.contains(ors, q2)) {
                            ors.push(q2);
                        }

                        const j = ret.indexOf(q1);

                        if (j > -1) {
                            ret.splice(j, 1);
                        }

                        const k = ret.indexOf(q2);

                        if (k > -1) {
                            ret.splice(k, 1);
                        }

                        i++;
                    }
                } else {
                    const filter = getQueryFilter(val);

                    if (filter) {
                        ret.push(filter);
                    }
                }
            }

            if (ors.length) {
                ret.push(`OR(${ors.join()})`);
            }
        }

        return ret;
    }

    return { parse };
});
