/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-whitelist-rule-modal.less';

/**
 * @alias waf/wafPolicyWhitelistRuleModal
 * @private
 */
class WafPolicyWhitelistRuleModalComponent {
    constructor(schemaService) {
        this.actionsTypes = schemaService.getEnumValues('WafPolicyWhitelistAction');
    }

    /**
     * Returns true if the location is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.rule.getIndex());
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }
}

WafPolicyWhitelistRuleModalComponent.$inject = [
    'schemaService',
];

/**
 * @ngdoc component
 * @name wafPolicyWhitelistRuleModal
 * @module waf/wafPolicyWhitelistRuleModal
 * @desc Component for editing a WAF Policy whitelist rule.
 * @param {WafPolicyWhitelistRule} rule
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPolicyWhitelistRuleModal', {
    bindings: {
        rule: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: WafPolicyWhitelistRuleModalComponent,
    templateUrl: 'src/components/modals/waf-policy-whitelist-rule-modal/' +
        'waf-policy-whitelist-rule-modal.component.html',
});
