/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/* Works with HTTP response codes and significance in log details AND plain strings
 * in attribute. Don't hides on hover and waits after mouseleave event on parent. @am */
angular.module('aviApp').directive('logTooltip', ['$timeout', function($timeout) {
    const link = function(scope, elem, attr) {
        let tooltip,
            timeout,
            content = '';

        const
            cancelTimeout = function() {
                if (timeout) {
                    $timeout.cancel(timeout);
                    timeout = false;
                }
            },
            remove = function(ms) {
                if (tooltip) {
                    return $timeout(function() {
                        tooltip.remove();
                        tooltip = false;
                        $('body').off('mouseup', fastRemove);
                    }, ms || 1000);
                }
            },
            fastRemove = function() {
                cancelTimeout();
                remove(1);
            },
            add = function() {
                const reposition = function() { //for horizontal align:center relative to parent
                    const left = elem.offset().left + elem.innerWidth() / 2 -
                        tooltip.innerWidth() / 2;

                    tooltip.offset({ left: Math.max(0, left) });
                };

                if (!content) {
                    return;
                }

                tooltip = $('<div>')
                    .addClass('log-tooltip')
                    .css({
                        bottom: $(window).height() - elem.offset().top + 5,
                        left: elem.offset().left - 50,
                    })
                    .html(content)
                    .on('mouseenter', cancelTimeout)
                    .on('mouseleave', function() {
                        cancelTimeout();
                        timeout = remove(100);
                    })
                    .on('mouseup', function(e) {
                        e.stopPropagation();
                    })
                    .appendTo($('body'));
                reposition();
                $('body').one('mouseup', fastRemove);
            };

        elem
            .on('mouseenter', function() {
                if (!tooltip) {
                    if (!content) {
                        content = attr['logTooltip'];
                    }

                    //removeOthers();
                    add();
                } else {
                    cancelTimeout();
                }
            })
            .on('mouseleave', function() {
                cancelTimeout();
                timeout = remove(100);
            });

        scope.$on('userLoggedOut', fastRemove);
        scope.$on('$repaintViewport', fastRemove);
    };

    return {
        restrict: 'A',
        link,
    };
}]);
