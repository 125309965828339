/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleQueryNameMatchConfig
 * @description DnsPolicyRuleQueryNameMatch ConfigItem class.
 */
const MatchConfigController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleQueryNameMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'BEGINS_WITH';
            config.query_domain_names = config.query_domain_names || [];
            config.string_group_refs = config.string_group_refs || [];
        }

        /** @override */
        dataToSave() {
            let config = super.dataToSave();

            if (!config.match_criteria) {
                config = undefined;
            }

            return config;
        }

        /** @override */
        toString() {
            const
                {
                    match_criteria: matchCriteria,
                    query_domain_names: queryDomainNames,
                    string_group_refs: stringGroupRefs,
                } = this.getConfig(),
                value = schemaService.getEnumValue('StringOperation', matchCriteria).label,
                resultList = [];

            _.each(queryDomainNames, name => resultList.push(`"${name}"`));

            _.each(stringGroupRefs, item => {
                resultList.push(`group "${item.name() || item.slug()}"`);
            });

            return `${value.enumeration().toLowerCase()} ${resultList.join(' or ')}`;
        }
    }

    angular.extend(DnsPolicyRuleQueryNameMatchConfig.prototype, {
        type: 'DnsQueryNameMatch',
        name: 'Query Name',
        defaultPath: 'dnsrule.match.query_name',
    });

    return DnsPolicyRuleQueryNameMatchConfig;
};

MatchConfigController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('DnsPolicyRuleQueryNameMatchConfig', MatchConfigController);
