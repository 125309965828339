/**************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './avi-icon.component.less';

const componentName = 'avi-icon';

/**
 * Hash to convert desired effect into sl- postfix, for simple line icons.
 * @type {{success: string, warning: string, error: string, info: string}}
 * @private
 */
const typeToIcon = {
    success: 'icon-check',
    error: 'icon-minus',
    warning: 'icon-exclamation',
    info: 'icon-info',
};

/**
 * @alias module:component/aviIcon
 * @private
 */
class AviIconController {
    /** @override */
    $onInit() {
        const
            { type } = this,
            typeProp = typeToIcon[type] || type || typeToIcon.success;

        this.iconTypeClass = `sl-${typeProp}`;
        this.statusClass = `${componentName}--${type || 'success'}`;
    }
}

/**
 * @ngdoc component
 * @name aviIcon
 * @module component/aviIcon
 * @param {string=} type - type of icon to fill in for sl-(type), type available in local hash
 * @author Akul Aggarwal
 * @description
 *
 *     Component to generate a 'sl-' icon.
 *
 */
angular.module('kit.ui.vantage.avi').component('aviIcon', {
    bindings: {
        type: '@?',
    },
    transclude: true,
    controller: AviIconController,
    templateUrl: `src/components/common/${componentName}/${componentName}.component.html`,
});
