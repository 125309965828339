/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  dnsNtpPage
 * @param {Object} loadedSystemConfigService - resolved SystemConfig service object.
 * @description
 *     Component for the DNS/NTP page in Administration/Settings.
 */
class DnsNtpPageController {
    $onInit() {
        this.ntpAuthenticationKeysGridConfig = {
            fields: [{
                name: 'key_number',
                title: 'Key Number',
            }, {
                name: 'algorithm',
                title: 'Algorithm',
                template: '{{row.algorithm.enumeration(\'NTP_AUTH_ALGORITHM_\').toUpperCase()}}',
            }, {
                name: 'key',
                title: 'Key',
            }],
            rowId: ({ key_number: keyNumber, algorithm, key }) => keyNumber + algorithm + key,
            layout: {
                hideDisplaying: true,
            },
        };

        this.ntpServersGridConfig = {
            fields: [{
                name: 'server.addr',
                title: 'Server',
            }, {
                name: 'key_number',
                title: 'Key Number',
                template: '{{ row.key_number || \'-\' }}',
            }],
            rowId: ({ key_number: keyNumber, server }) => keyNumber + server.addr,
            searchFields: [
                'server.addr',
                'key_number',
            ],
            layout: {
                hideDisplaying: true,
            },
        };
    }

    /**
     * Opens dnsNtpModal modal to edit system settings.
     */
    editDnsNtp() {
        this.systemConfig.edit('dns-ntp-modal');
    }

    /**
     * Flattens the list of DNS servers.
     * @returns {string}
     * @public
     */
    getDnsServersList() {
        let config,
            dnsConfig,
            dnsServerList;

        if ((config = this.systemConfig.getConfig()) &&
                (dnsConfig = config.dns_configuration) &&
                (dnsServerList = dnsConfig.server_list) &&
                dnsServerList.length) {
            return _.pluck(dnsServerList, 'addr').join(', ');
        } else {
            return 'N/A';
        }
    }
}

angular.module('aviApp').component('dnsNtpPage', {
    bindings: {
        systemConfig: '<loadedSystemConfigService',
    },
    controller: DnsNtpPageController,
    templateUrl: 'src/components/pages/administration/settings/dns-ntp-page/dns-ntp-page.html',
});
