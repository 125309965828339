/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ServerHealthScoreMonitorListCollDataSource', [
'ListCollDataSource',
function(ListCollDataSource) {
    class ServerHealthScoreMonitorListCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_(params) {
            const { owner_: owner } = this;

            // We want to get all_se or primary_only from params.
            // primary_only is used when corresponding VS.runtime.one_plus_one_ha is true or
            // serviceenginegroup?referred_by=virtualservice:vsId&
            // ha_mode=HA_MODE_LEGACY_ACTIVE_STANDBY
            // returns at least one result. Otherwise use default all_se.
            return angular.extend(
                super.getRequestParams_(params),
                {
                    objectName_: [
                        'pool',
                        owner.poolId,
                        'runtime',
                        'server',
                    ],
                    server_ip: owner.serverIp,
                    server_port: owner.serverPort,
                },
            );
        }
    }

    angular.extend(ServerHealthScoreMonitorListCollDataSource.prototype, {
        defaultParams_: {
            all_se: true,
        },
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
    });

    return ServerHealthScoreMonitorListCollDataSource;
}]);
