/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceVipAzure
 * @description
 *     Allows the user to select a network and subnet for a Virtual Service in the AWS cloud.
 * @param {VsVip} vsVip
 * @param {Cloud} cloud
 */
class VirtualserviceVipAzureController {
    constructor(
        Schema,
        Regex,
        SubnetListNetworkCollection,
    ) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.SubnetListNetworkCollection_ = SubnetListNetworkCollection;

        this.availabilityZones = [];
    }

    $onInit() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const vipConfig = this.vsVip.getConfig();
        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;

        const networkCollectionParams = {
            auto_allocate_only: true,
            cloud_uuid: this.cloud.id,
            fip_capable: autoAllocateFloatingIp || undefined,
        };

        const { vrf_context_ref: vrfContextRef } = vipConfig;

        if (vrfContextRef) {
            networkCollectionParams.vrf_context_uuid = vrfContextRef.slug();
        }

        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection_({
            params: networkCollectionParams,
        });

        if (this.cloud.hasAzureIpamProfile()) {
            const ipamProviderProfile = this.cloud.getIpamProviderProfileConfig();
            const azureIpamProfile = ipamProviderProfile && ipamProviderProfile.azure_profile;

            if (azureIpamProfile && azureIpamProfile.use_enhanced_ha) {
                this.cloud.getAvailabilityZones()
                    .then(zones => this.availabilityZones = zones);
            }
        }
    }

    /**
     * Sets subnetListNetworkCollection to get only fip_capable networks if Elastic IP is
     * selected.
     */
    handleAssignElasticIpChange() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;

        this.vsVip.setAutoAllocateFloatingIpForAllVips(autoAllocateFloatingIp);
        this.subnetListNetworkCollection.setParams({
            fip_capable: autoAllocateFloatingIp || undefined,
        });
    }

    /**
     * Sets the "auto_allocate_ip" boolean along with the "subnet_uuid" when the network is
     * selected.
     * @param {VipConfig} vip - VirtualService vip object.
     * @param {SubnetListNetwork} selected
     */
    handleNetworkChange(vip, selected) {
        if (angular.isUndefined(selected)) {
            return;
        }

        const subnet = selected.data.config.subnet[0];

        vip.setAutoAllocateIp(true);
        vip.setIpAddr(undefined);
        vip.setVipSubnet(subnet.prefix, subnet.uuid);
    }

    /**
     * Handler for clicking Add Vip. Adds a Vip object to the list of vips.
     */
    handleAddVip() {
        if (this.cloud.getVtype() === 'CLOUD_AZURE') {
            this.vsVip.addVip();
        } else if (this.cloud.hasAzureIpamProfile()) {
            const vip0 = this.vsVip.getVip(0);
            const { network_ref: networkRef, subnet_uuid: subnetUuid } = vip0.getConfig();

            this.vsVip.addVip(networkRef, subnetUuid);
        }
    }

    /**
     * Returns true if user should be allowed to set availability zones.
     * @return {boolean}
     */
    showAvailabilityZones() {
        if (this.cloud.hasAzureIpamProfile()) {
            const ipamProviderProfile = this.cloud.getIpamProviderProfileConfig();
            const azureIpamProfile = ipamProviderProfile && ipamProviderProfile.azure_profile;

            if (azureIpamProfile && azureIpamProfile.use_enhanced_ha) {
                return true;
            }
        }

        return false;
    }

    /** @override */
    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
    }
}

VirtualserviceVipAzureController.$inject = [
    'Schema',
    'Regex',
    'SubnetListNetworkCollection',
];

angular.module('aviApp').component('virtualserviceVipAzure', {
    bindings: {
        vsVip: '<',
        cloud: '<',
    },
    controller: VirtualserviceVipAzureController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-azure/virtualservice-vip-azure.html',
});
