/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './vs-waf-page.less';

/**
 * @ngdoc component
 * @name vsWafPage
 * @params {VirtualService} vs - Loaded VS instance, provided by UI Router.
 * @description
 *
 *     VS WAF Analytics: two charts and list of top enforcements.
 */
const componentName = 'vs-waf-page';

const vsWAFController = class {
    constructor($rootScope, $element, myAccount, VsWafTopHits, wafExceptionState) {
        this.$rootScope_ = $rootScope;
        this.$elem_ = $element;
        this.myAccount = myAccount;

        this.wafEnforcedSeriesName = 'l7_client.avg_waf_attacks';
        this.wafEnforcedPctSeriesName = 'l7_client.pct_waf_attacks';

        this.chartMetricConfigs_ = [{
            name: this.wafEnforcedSeriesName,
            series: [
                    this.wafEnforcedSeriesName,
                    this.wafEnforcedPctSeriesName,
            ],
        }];

        this.chartMetricConfigs_
            .forEach(metricConfig => metricConfig.subscriber = componentName);

        this.chartTitle = 'WAF Hits';
        this.chartPadding = {
            left: 25,
            right: 30,
        };
    }

    $onInit() {
        this.$elem_.addClass(componentName);

        const { vs } = this;

        vs.collMetricsSubscribe(this.chartMetricConfigs_);

        vs.startCollMetricsAsync();

        setTimeout(
            () => this.$rootScope_.$broadcast('$repaintViewport'),
        );
    }

    $onDestroy() {
        this.vs.collMetricsUnSubscribe(componentName);
    }
};

vsWAFController.$inject = [
        '$rootScope',
        '$element',
        'myAccount',
];

angular.module('aviApp').component('vsWafPage', {
    bindings: {
        vs: '<resolveVS',
    },
    controller: vsWAFController,
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/vs-waf-page.html',
});
