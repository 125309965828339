/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name getItemConstructorByRef
 * @description
 *
 *     To create an Item we need to have an appropriate constructor.
 *     We can get it by Item's ref\URL using this factory.
 */
angular.module('aviApp').factory('getItemConstructorByRef', [
'$injector', 'itemObjectTypeToClassName',
function($injector, itemObjectTypeToClassName) {
    const refRegex = /.*\/api\/(\w+)/i;

    return function(itemRef) {
        let matches;

        if (matches = refRegex.exec(itemRef)) {
            const
                [, objectName] = matches,
                className = itemObjectTypeToClassName[objectName];

            if (className && $injector.has(className)) {
                return $injector.get(className);
            }
        }

        return null;
    };
}]);
