/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @ngdoc service
 * @name SEGroupFlavorListCollDataSource
 * @desc Flavors DataSource for {@link SEGroupFlavorCollection}.
 */
angular.module('aviApp').factory('SEGroupFlavorListCollDataSource', [
'ListCollDataSource',
function(ListCollDataSource) {
    class SEGroupFlavorListCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_(params) {
            const
                { owner_: owner } = this,
                { cloudId } = owner,
                reqProps = {
                    objectName_: [
                        'cloud',
                        cloudId,
                        'flavors',
                    ],
                };

            return angular.extend(super.getRequestParams_(params), reqProps);
        }
    }

    angular.extend(SEGroupFlavorListCollDataSource.prototype, {
        defaultParams_: {
            includeName_: false,
        },
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
    });

    return SEGroupFlavorListCollDataSource;
}]);
