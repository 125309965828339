/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc filter
 * @name backendErrorMsg
 * @description
 *
 *    Translates an error message object provided by backend into a text message.
 *
 */
angular.module('aviApp').filter('backendErrorMsg', () => {
    return error => {
        let res = '';

        if (error) {
            switch (typeof error) {
                case 'string':
                    res = error;
                    break;

                case 'object': {
                    const keys = Object.keys(error);

                    res = error['error'] ||
                        '__all__' in error && error['__all__'][0] ||
                        keys.length && `${keys[0]}: ${error[keys[0]]}` || '';

                    if (!res) {
                        console.warn('Backend error object %O has an unexpected format', error);
                    }

                    break;
                }
            }
        }

        return res;
    };
});
