/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @restrict E
 * @name cardList
 * @description
 *
 *     Iterates through all cards in config, creating appropriate sparkline cards.
 *
 */
angular.module('aviApp').directive('cardList', [
'myAccount', function(myAccount) {
    const cardListLink = scope => {
        scope.myAccount = myAccount;
        scope.ui = {};

        scope.filterBySeries = ({ id }) => {
            const { seriesList } = scope;

            return !seriesList || _.contains(seriesList, id);
        };
    };

    return {
        scope: {
            item: '<',
            config: '<',
            header: '@',
            seriesList: '<',
            title: '@',
        },
        restrict: 'E',
        templateUrl: '/src/views/components/card-list.html',
        link: cardListLink,
    };
}]);
