/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/components/dos-rate-limiter.less';

/**
 * @ngdoc directive
 * @name dosRateLimiterVs
 * @restrict E
 * @description Rate Limiter inputs for the VS Modal. Can have only two there which are always
 * present on the screen. Since the layout is different and we have update different from Profile's
 * Modal properties need to have this shallow copy of {@link dosRateLimiter}.
 */
angular.module('aviApp').directive('dosRateLimiterVs', ['dosRateLimiterService',
function(dosRateLimiterService) {
    const link = function(scope) {
        scope.limiters = dosRateLimiterService;
        /**
         * Dropdown options for the second (l7) rate limiter.
         * @type {Array<LimiterActionType>}
         */
        scope.l7Actions = _.filter(scope.limiters.actions, function(action) {
            return action.type === 10 || action.type === 11;
        });
    };

    return {
        restrict: 'E',
        scope: true,
        link,
        templateUrl: 'src/views/profile/dos-rate-limiter-vs.html',
    };
}]);
