/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @ngdoc service
 * @name SEGroupFlavorCollection
 * @description Flavors {@link Collection} for {@link SEGroup}.
 */
angular.module('aviApp').factory('SEGroupFlavorCollection', [
'Collection', 'SEGroupFlavor',
function(Collection, SEGroupFlavor) {
    const dataSources = {
        list: {
            source: 'SEGroupFlavorListCollDataSource',
            transformer: 'SEGroupFlavorListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    class SEGroupFlavorCollection extends Collection {
        constructor(args = {}) {
            if (angular.isUndefined(args.cloudId)) {
                throw new Error('Can\'t work with SEGroupFlavorCollection without cloudId');
            }

            super(args);

            /**
             * @type {string} Cloud id of the Cloud this Server belongs to.
             */
            this.cloudId = args.cloudId;
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(SEGroupFlavorCollection.prototype, {
        objectName_: 'segroup-flavor',
        itemClass_: SEGroupFlavor,
        allDataSources_: dataSources,
        defaultDataSources_: 'list',
    });

    return SEGroupFlavorCollection;
}]);
