/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const ipAddrConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/IpAddrConfigItem
     * @private
     */
    class IpAddrConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'IpAddr',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                type: 'V4',
            };
        }

        /**
         * @override
         */
        canFlatten_() {
            return this.isValid();
        }

        /**
         * Returns true if this MessageItem has a valid configuration.
         * @returns {boolean}
         */
        isValid() {
            return Boolean(this.address);
        }

        /**
         * Sets the addr property in the config.
         * @param {string} address - Address to set on config.addr.
         */
        set address(address) {
            this.config.addr = address;
        }

        /**
         * Returns the set address.
         * @returns {string|undefined}
         */
        get address() {
            return this.config.addr;
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return this.address;
        }
    }

    return IpAddrConfigItem;
};

ipAddrConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  IpAddrConfigItem
 * @description  IpAddr ConfigItem class.
 * @module config-item/IpAddrConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('IpAddrConfigItem', ipAddrConfigItemFactory);
