/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const dnsPolicyRuleRateProfileActionConfigFactory = (ActionConfigItem, schemaService) => {
    class DnsPolicyRuleRateProfileActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const {
                count,
                burst_size: bustSize,
                period,
                fine_grain: fineGrain,
            } = this.getConfig();

            const outputChunks = [];

            const { type: valueKey } = this.getConfig().action;

            if (valueKey) {
                const { label } = schemaService.getEnumValue('DnsRuleRLActionType', valueKey);

                outputChunks.push(`Type: "${label}"`);
            }

            if (!_.isUndefined(count)) {
                outputChunks.push(`Count: ${count}`);
            }

            if (!_.isUndefined(bustSize)) {
                outputChunks.push(`Burst Size: ${bustSize}`);
            }

            if (!_.isUndefined(period)) {
                outputChunks.push(`Period: ${period}s`);
            }

            if (fineGrain) {
                outputChunks.push('Fine Grain');
            }

            return outputChunks.join('; ');
        }
    }

    Object.assign(DnsPolicyRuleRateProfileActionConfig.prototype, {
        type: 'DnsRateProfile',
        name: 'Rate Limit',
        defaultPath: 'dnsrule.action.dns_rate_limit',
    });

    return DnsPolicyRuleRateProfileActionConfig;
};

dnsPolicyRuleRateProfileActionConfigFactory.$inject = [
    'ActionConfigItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name DnsPolicyRuleRateProfileActionConfig
 * @description DnsPolicyRuleRateProfileActionConfig,
 *      maps to DnsRateProfile in protobof.
 * @module action/DnsPolicyRuleRateProfileActionConfig
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory(
    'DnsPolicyRuleRateProfileActionConfig', dnsPolicyRuleRateProfileActionConfigFactory,
);
