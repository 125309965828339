/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './main-menu.less';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.mainMenuComponent}
 */
class MainMenuController {
    /**
     * @constructor
     */
    constructor($state, $elem) {
        this.$state = $state;
        this.$elem_ = $elem;

        this.docElement_ = document.documentElement;

        /** @type {jQuery} */
        this.$mainMenu_ = null;

        /** @type {Element} */
        this.mainMenuList_ = null;
    }

    /**
     * Handles closing the menu, removing listeners and classes.
     */
    closeMainMenu = e => {
        if (e) {
            this.mainMenuList_ = this.mainMenuList_ || document.querySelector('.main-menu-list');

            if (this.mainMenuList_) {
                const bounds = this.mainMenuList_.getBoundingClientRect();

                if (!(e.pageX < bounds.right && e.pageX > bounds.left &&
                    e.pageY > bounds.top && e.pageY < bounds.bottom)) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
        }

        this.docElement_.removeEventListener('click', this.closeMainMenu, true);
        this.$elem_.find('.main-menu-list').removeClass('main-menu-list--open');
        this.$elem_.find('.main-menu').removeClass('main-menu--open');
    }

    /**
     * Handles opening the menu, adding listeners and classes.
     * @param {Event} e
     */
    openMainMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        this.$mainMenu_ = this.$mainMenu_ || this.$elem_.find('.main-menu');

        const isOpen = this.$mainMenu_.hasClass('main-menu--open');

        if (!isOpen) {
            this.docElement_.addEventListener('click', this.closeMainMenu, true);
            this.$elem_.find('.main-menu-list').addClass('main-menu-list--open');
            this.$mainMenu_.addClass('main-menu--open');
        }
    }
}

MainMenuController.$inject = [
    '$state',
    '$element',
];

/**
 * @name mainMenuComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.MainMenuController} controller
 * @description
 *     Dropdown component for navigating to the different sections of the application, ie.
 *     Applications, Operations, Templates, Infrastructure, and Administration, referred to as
 *     categories.
 * @author alextsg
 */
angular.module('avi/navigation').component('mainMenu', {
    controller: MainMenuController,
    templateUrl: 'src/components/avi-header/main-menu/main-menu.html',
});
