/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  ipamDnsProfilersExpander
 * @param {IPAMProfile} ipamProfile - IPAMProfile object of selected profile.
 * @description Component to display more fields of IPAM/DMS Profilers.
 */
class IpamDnsProfilersExpanderController {
    constructor(getSubnetString, IPAMProfile) {
        this.getSubnetString_ = getSubnetString;
        this.IpamProfile_ = IPAMProfile;
    }

    /** @override */
    $onInit() {
        /**
         * @type {string[]}
         */
        this.configuredUsableSubnetsList = [];

        const { IPAMDNS_TYPE_INFOBLOX } = this.IpamProfile_.typeHash;

        if (this.ipamProfile.getType() === IPAMDNS_TYPE_INFOBLOX) {
            const { infobloxConfiguredUsableSubnets: usableSubnets } = this.ipamProfile;

            if (usableSubnets) {
                this.configuredUsableSubnetsList = this.flattenUsableSubnets(usableSubnets);
            }
        }
    }

    /**
     * Returns a list of human-readable subnet strings.
     * @param {InfobloxSubnet[]} subnets - List of infoblox subnets containing subnet and/or subnet6
     *     IpAddrPrefix objects.
     * @returns {string[]}
     */
    flattenUsableSubnets(subnets) {
        return subnets.map(({ subnet, subnet6 }) => {
            const subnetStrings = [];

            if (subnet) {
                subnetStrings.push(this.getSubnetString_(subnet));
            }

            if (subnet6) {
                subnetStrings.push(this.getSubnetString_(subnet6));
            }

            return `(${subnetStrings.join(', ')})`;
        });
    }
}

IpamDnsProfilersExpanderController.$inject = [
    'getSubnetString',
    'IPAMProfile',
];

angular.module('aviApp').component('ipamDnsProfilersExpander', {
    bindings: {
        ipamProfile: '<',
    },
    controller: IpamDnsProfilersExpanderController,
    templateUrl: 'src/components/pages/templates/profiles/' +
        'ipam-dns-profilers-expander/ipam-dns-profilers-expander.html',
});
