/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('SnmpController', [
'$scope', 'CRUDGridConfig', 'Collection', 'Regex', 'SnmpProfileCollection',
'AviModal',
function($scope, CRUDGridConfig, Collection, Regex, SnmpProfileCollection, AviModal) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new SnmpProfileCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'trap_servers',
            title: 'Server',
            template:
            '<div class="mb5" ng-repeat="server in row.data.config.trap_servers track by $index">' +
                '<span title="{{ config.joinTraps(row) }}">' +
                    '{{ server.ip_addr.addr }}' +
                '</span></div>',
            visibility: 'm',
        },
    ];

    $scope.gridConfig.singleactions.unshift({
        title: 'Validate Settings',
        class: 'icon-lostlog',
        do(row) {
            AviModal.open('adm-email-validate', {
                uuid: row.data.config.uuid,
                type: 'snmp',
            });
        },
    });

    $scope.Regex = Regex;

    /**
     * Joins trap community and IPs into comma-separated string.
     * @param  {Object} row - grid row containing server information.
     * @return {string}     string of comma-separated <community>:<server>
     */
    $scope.gridConfig.joinTraps = function(row) {
        const output = row.data.config.trap_servers.map(function(server) {
            return `${server.ip_addr.addr} - ${server.community}`;
        });

        return output.join(', ');
    };

    $scope.removeServer = function(server) {
        if ($scope.editable.data.config.trap_servers.length <= 1) {
            return;
        }

        $scope.editable.data.config.trap_servers = _.reject(
            $scope.editable.data.config.trap_servers,
            function(elm) {
                return elm === server;
            },
        );

        if (!$scope.editable.data.config.trap_servers.length) {
            $scope.editable.data.config.trap_servers =
                [{ ip_addr: { type: 'V4', addr: '' }, community: '' }];
        }
    };

    $scope.addServer = function() {
        if (!$scope.editable.data.config.trap_servers.length) {
            $scope.editable.data.config.trap_servers =
                [{ ip_addr: { type: 'V4', addr: '' }, community: '' }];
        }

        $scope.editable.data.config.trap_servers.push(
            { ip_addr: { type: 'V4', addr: '' }, community: '' },
        );
    };
}]);
