/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
/**
 * @ngdoc directive
 * @name stringOrGroupList
 * @restrict E
 * @param {string[]} strings - Custom strings
 * @param {groups[]} groups - List of string group refs
 * @param {boolean=} ngDisabled
 * @param {boolean=} ngRequired
 * @param {boolean=} allowClear
 * @param {boolean} [allowCustom=true] - When not set or true is passed will change text
 *     labels and allow custom string value to be added. Value is watched and on change
 *     will flush the lists of groups and strings.
 *
 * @desc
 *
 *     Repeated list of collection-dropdowns for string group refs and custom strings.
 *
 * @author Alex Malitsky
 */
//TODO rebuild with parser and formatter
angular.module('aviApp').directive('stringOrGroupList', [
'StringGroupCollection',
function(StringGroupCollection) {
    const defaultRow = {
        type: 'group',
        data: '',
    };

    const stringOrGroupListLink = function(scope, elm, attr) {
        scope.isRequired = !_.isUndefined(attr['required']);

        scope.stringGroupCollection = new StringGroupCollection();
        scope.all = {};

        let combineAllowed = true;

        /**
         * allowCustom proxy variable. Need it to run $watch before template ng-ifs.
         * @type {boolean}
         */
        scope.customAllowed = _.isUndefined(scope.allowCustom) || !!scope.allowCustom;

        const combineSourceData = () => {
            if (!combineAllowed) {
                return;
            }

            const strings = _.map(scope.strings,
                string => ({
                    type: 'custom',
                    data: string,
                }));

            const groups = _.map(scope.groups,
                ref => ({
                    type: 'group',
                    data: ref,
                }));

            const { all } = scope;

            all.items = strings.concat(groups);

            // By default there should be at least 1 item
            if (!all.items.length) {
                all.items.push(angular.copy(defaultRow));
            }
        };

        combineSourceData();

        scope.splitStringsAndGroups = function() {
            combineAllowed = false;

            const { items } = scope.all;

            //filtering out `undefined` as well
            scope.strings = _.pluck(
                _.filter(items, ({ type, data }) => type === 'custom' && data),
                'data',
            );

            scope.groups = _.pluck(
                _.filter(items, ({ type }) => type === 'group'),
                'data',
            );

            setTimeout(() => combineAllowed = true);
        };

        scope.$on('$destroy',
            () => scope.stringGroupCollection.destroy());

        // when we switch between allowCustom values we want to wipe out all the values
        // cause custom values might not be supported anymore. Also no point to use list of
        // regexps for reqular string operators.
        scope.$watch('allowCustom', (allowCustom, prevValue) => {
            //angularJS init
            if (allowCustom === prevValue) {
                return;
            }

            const {
                strings,
                groups,
            } = scope;

            let updated = false;

            if (Array.isArray(strings) && strings.length) {
                strings.length = 0;
                updated = true;
            }

            if (Array.isArray(groups) && groups.length) {
                groups.length = 0;
                updated = true;
            }

            if (updated) {
                combineSourceData();
            }

            //to avoid race condition with colleciton-grid loading custom string
            scope.customAllowed = _.isUndefined(allowCustom) || !!allowCustom;
        });

        /**
         * Adds a row to the list.
         */
        scope.addRow = function() {
            scope.all.items.push(angular.copy(defaultRow));
            scope.splitStringsAndGroups();
        };

        /**
         * Removes a row.
         * @param {number} $index
         */
        scope.removeRow = function($index) {
            scope.all.items.splice($index, 1);
            scope.splitStringsAndGroups();
        };
    };

    return {
        scope: {
            strings: '=',
            groups: '=',
            ngRequired: '=',
            ngDisabled: '=',
            allowClear: '<',
            allowCustom: '<?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/string-or-group-list.html',
        link: stringOrGroupListLink,
    };
}]);
