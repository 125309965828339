/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class SipProfileController {
    constructor(Regex) {
        this.Regex = Regex;
    }
}

SipProfileController.$inject = ['Regex'];

/**
 * @ngdoc component
 * @name sipProfile
 * @description
 *    Responsible for rendering a html with two fields: sip_log_depth
 *    and exclude_error_status_codes, input fields validation
 * @param {AnalyticsProfile.config} config
 * @param {Function} handleSipErrCodes - called on blur of exclude_sip_error_codes
 *
 */

angular.module('aviApp').component('sipProfile', {
    bindings: {
        config: '<',
    },
    controller: SipProfileController,
    templateUrl: 'src/components/forms/sip-profile/sip-profile.html',
});
