/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('cardSparkline', [
'$rootScope', 'GraphSync', 'Tooltip', 'ChartService',
function($rootScope, GraphSync, Tooltip, ChartService) {
    // declare jquery plugin that can be called later on window resize
    (function($) {
        $.fn.cardSparkline = function(args) {
            // reset
            const elm = $(this);

            elm.empty();
            elm.off();

            if (args.scope.$parent.ymax) {
                args.ymax = args.scope.$parent.ymax;
            }

            if (!args.data || !args.data.length) {
                elm.append('<div class = "no-data">No Data</div>');

                return;
            }

            const units = {
                throughput: 'Mbps',
                bandwidth: 'Mbps',
                conns_sec: '/sec',
                requests_sec: '/sec',
            };

            args.data.currentValue = args.data[args.data.length - 1].y.toFixed(1);
            args.data.units = args.data.units || units[args.format];
            elm.parent().parent().find('.currentValue').text(
                args.data[args.data.length - 1].y.toFixed(1),
            )
                .addClass(args.data.className || '');
            elm.parent().parent().find('.units').text(args.data.units);

            //       Chart Settings       //
            const settings = {};

            if (!args.small) {
                settings.padding = {
                    bottom: 5,
                    top: 0,
                    left: 0,
                    right: 0,
                };
            }

            settings.hideXAxis = true;
            settings.hideYAxis = true;

            settings.width = args.width;
            settings.height = args.height;

            settings.yMin = 0;
            settings.yMax = args.yMax || null;

            settings.data = args.data;
            // settings.data.stroke = args.data.stroke || args.stroke;
            // settings.data.fill = args.data.fill || args.fill;

            //     Making the Chart and Graphing the area
            const chart = new ChartService(elm, settings);

            chart.graphArea(args.data);

            //     Tooltip Code
            const tooltipSettings = {};

            if (args.small) {
                tooltipSettings.line = true;
            }

            tooltipSettings.radius = 6;
            tooltipSettings.updateCurrentValue = true;
            tooltipSettings.elm = elm;

            tooltipSettings.labelColor = args.data.stroke;
            tooltipSettings.circleColor = args.data.stroke;

            const tooltip = new Tooltip(chart, args.data, tooltipSettings);

            const renderHover = function(graphX) {
                tooltip.updatePosition(graphX);
            };

            // Adding mousemove events that animate tooltips
            d3.select(elm[0])
                .on('mousemove', _.throttle(function() {
                    if (!this) {
                        return;
                    }

                    const mouseX = d3.mouse(this)[0] - chart.padding.left;
                    const graphX = chart.x.invert(mouseX);

                    GraphSync.sync(graphX);
                }, 16))
                .on('mouseout', function() { GraphSync.hide(); });

            const { scope } = args;

            if (scope.removeSyncer) {
                scope.removeSyncer();
            }

            scope.removeSyncer = scope.$on('graphSync', function(e, args) {
                renderHover(args[0]);
            });

            if (scope.removeHide) {
                scope.removeHide();
            }

            scope.removeHide = scope.$on('graphHide', function() {
                elm.parent().parent().find('.currentValue').text(
                    args.data[args.data.length - 1].y.toFixed(1),
                );
                elm.parent().parent().find('.reasonString').text(
                    args.data[args.data.length - 1].reason || '',
                );
                tooltip.hide();
            });
        };
    })(jQuery);

    return {
        restrict: 'A',
        scope: {
            data: '=',
            format: '=',
            ymax: '=',
            ymin: '=',
            small: '=',
            stroke: '=',
            fill: '=',
        },
        link(scope, elm, attr) {
            elm.addClass('card-sparkline');

            const repaint = function() {
                elm.cardSparkline({
                    data: scope.data,
                    format: scope.format,
                    yMax: scope.ymax,
                    yMin: scope.ymin,
                    xAxis: false,
                    width: parseInt(attr.width, 10),
                    height: parseInt(attr.height, 10),
                    small: scope.small,
                    scope,
                    stroke: scope.stroke,
                    fill: scope.fill,
                });
                scope.$emit('repaintingGraph');
            };

            // register listeners
            scope.$watch('data', repaint);
            scope.$parent.$watch('ymax', repaint);
            scope.$on('$repaintViewport', repaint);
        },
    };
}]);
