/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name LogRecordCollection
 * @description
 *
 *     Log records collection.
 *
 *     Differs from regular collections by filter query param  - can be multiple and values
 *     look like "eq(fieldName, fieldValue)".
 *
 *     Also filter values can be set through setFilter rather than setParam.
 */
angular.module('aviApp').factory('LogRecordCollection', [
'Collection', 'LogRecord',
function(Collecton, LogRecord) {
    class LogRecordCollection extends Collecton {
        constructor(args = {}) {
            super(args);

            this.vsId = args.vsId;

            /**
             * Need this to render data transfer bar chart.
             * @type {Object}
             * @property {number} maxDuration - max data transfer duration
             * @property {Moment} minTimestamp
             * @property {Moment} maxTimestamp
             */
            this.timeline = {};
        }

        /**
         * Set's user defined filters on collection.
         * @param args
         * @public
         * @see {@link LogRecordCollDataSource.setFilters}
         */
        setFilter(...args) {
            const dataSource = this.getDataSourceByFieldName('config');

            dataSource.setFilters(...args);
        }

        /** @override */
        emptyData(...args) {
            super.emptyData(...args);
            this.timeline = {};
        }
    }

    angular.extend(LogRecordCollection.prototype, {
        objectName_: angular.copy(LogRecord.prototype.objectName),
        itemClass_: LogRecord,
        sortBy: '-report_timestamp',
        defaultDataSources_: 'list',
        allDataSources_: {
            list: {
                source: 'LogRecordCollDataSource',
                transformer: 'LogRecordListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return LogRecordCollection;
}]);
