/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/*
 * Chart with Overlays
 * Includes both a table and a performance chart
 */
angular.module('aviApp').directive('chartWithOverlays', ['myAccount',
function(myAccount) {
    return {
        restrict: 'AE',
        scope: {
            item: '=',
            config: '=',
            smallChart: '=',
        },
        templateUrl: '/src/views/components/chartWithOverlays.html',
        link(scope) {
            if (!myAccount.uiProperty.performanceControls) {
                myAccount.uiProperty.performanceControls = {};
            }

            scope.hide = myAccount.uiProperty.performanceControls;

            scope.toggle = function(type) {
                scope.hide[type] = !scope.hide[type];
                myAccount.uiProperty.performanceControls = scope.hide;
                scope.$broadcast('events-and-alerts-toggled');
                myAccount.saveUIProperty();
            };
        },
    };
}]);
