/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/healthscore.less';

/**
 * @ngdoc directive
 * @name aviHealthscorePopup
 * @param {Item} item
 * @param {*=} getRuntimeParams - Optional params to get the right runtime object of an Item.
 * @param {boolean} showHealthScore - If true, healthscore details can be displayed.
 * @param {boolean} showOperState - If true, oper state and reason can be displayed.
 * @author Aravindh Nagarajan
 * @restrict AE
 */
angular.module('aviApp').directive('aviHealthscorePopup', [
'VirtualService', 'Timeframe', 'getRuntimeDataFingerprint',
function(VirtualService, Timeframe, getRuntimeDataFingerprint) {
    function aviHealthscorePopupLink(scope) {
        scope.Timeframe = Timeframe;

        /** Checks if there is resolution for an error if error exists of course. */
        scope.resolveAvailable = function({ reason_code_string: reasonCodeString }) {
            return reasonCodeString && _.contains([
                'SYSERR_RM_STATIC_NO_POOL',
                'SYSERR_RM_STATIC_POOL_EXHAUSTED',
                'SYSERR_RM_SE_MGMT_NO_STATIC_IPS_CONFIGURED',
                'SYSERR_RM_SE_MGMT_STATIC_IPS_EXHAUSTED',
                'SYSERR_RM_SRVR_MULT_NETWORKS',
                'SYSERR_RM_SRVR_NO_NETWORK',
                'SYSERR_RM_VIP_MULT_NETWORKS',
                'SYSERR_RM_VIP_NO_NETWORK',
            ], reasonCodeString);
        };

        /**
         * Opens resolve issue dialog for VS.
         * @param {ng.$q.event} $event
         **/
        scope.resolveIssue = function($event) {
            $event.stopPropagation();

            const vs = new VirtualService({ id: scope.item.id });

            vs.load()
                .then(() => vs.checkForIssues())
                .finally(() => vs.destroy());
        };

        scope.$watch(() => {
            const { item } = scope;

            return item && getRuntimeDataFingerprint(item.getRuntimeData(scope.getRuntimeParams));
        }, () => {
            scope.runtimeData = scope.item &&
                scope.item.getRuntimeData(scope.getRuntimeParams) || {};
        });
    }

    return {
        restrict: 'AE',
        scope: {
            item: '=',
            getRuntimeParams: '<',
            showHealthScore: '<',
            showOperState: '<',
        },
        templateUrl: '/src/views/components/healthscore-popup.tpl.html',
        link: aviHealthscorePopupLink,
    };
}]);
