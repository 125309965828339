/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('requestPolicy', [
'Schema', 'Regex', 'RangeParser', 'PoolCollection', '$timeout', 'Pool', 'HTTPRedirectAction',
'$templateCache', 'PoolGroupCollection', 'serverStringBuilder', 'PolicyGridConfig',
'AviConfirmService', 'Server', 'schemaService',
function(Schema, Regex, RangeParser, PoolCollection, $timeout, Pool, HTTPRedirectAction,
         $templateCache, PoolGroupCollection, serverStringBuilder, PolicyGridConfig,
         AviConfirmService, Server, schemaService) {
    return {
        scope: {
            httpRequestPolicy: '=',
            current: '=',
            services: '=',
            readonly: '@',
            vsPoolRef: '&', // Optional. Used to add this pool to content-switch action
            cloudId: '<',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/request-policy.html',
        link(scope, elm) {
            function scrollDown() {
                const
                    selector = '.new-match-list',
                    curOffsetTop = $(selector).offset().top;

                setTimeout(function() {
                    const addMatchDropdown = $(selector);

                    if (addMatchDropdown.length) {
                        const scrollable = elm.closest('.scrollable');

                        scrollable.animate({
                            scrollTop: scrollable.scrollTop() + addMatchDropdown.offset().top -
                                curOffsetTop,
                        });
                    }
                }, 0);
            }

            /**
             * Wipes out the actions array on selection of exclusive action type such as redirect
             * or content switch with a local response.
             * @param {number=} indexToBePreserved - Action to be preserved in a list.
             * @inner
             */
            function emptyActionsList(indexToBePreserved) {
                if (scope.current) {
                    if (indexToBePreserved >= 0 &&
                        indexToBePreserved < scope.current.actionEdit.length) {
                        if (indexToBePreserved > 0) {
                            scope.current.actionEdit.splice(0, indexToBePreserved);
                        }

                        scope.current.actionEdit.length = 1;
                    } else {
                        scope.current.actionEdit.length = 0;
                    }
                }
            }

            scope.Schema = Schema;
            scope.httpResponseStatusCodeValues = schemaService
                .getEnumValues('HTTPLocalResponseStatusCode');
            scope.getServerUuid = Server.getServerUuid;
            scope.httpPolicyValues = _.map(Schema.enums.HTTPPolicyVar.values,
                function(value, key) {
                    return {
                        value: key,
                        displayValue: key.enumeration('HTTP_POLICY_VAR_'),
                    };
                });
            //for header set and remove actions, passed to eAutoComplete
            scope.headersSuggestions = _.map(Schema.enums.HTTPPolicyVar.values, function(val, key) {
                return {
                    displayValue: key.enumeration('HTTP_POLICY_VAR_'),
                    value: key,
                };
            });
            scope.Regex = Regex;
            scope.RangeParser = RangeParser;

            scope.PoolCollection = new PoolCollection({
                isStatic: true,
                params: {
                    referred_by: 'poolgroup:none',
                    'cloud_ref.uuid': scope.cloudId,
                },
            });

            scope.poolGroupCollection = new PoolGroupCollection({
                isStatic: true,
                params: {
                    'cloud_ref.uuid': scope.cloudId,
                },
            });

            scope.ui = {};

            scope.rule2EditMode = function(rule) {
                // Convert matches
                rule.matchEdit = [];
                _.map(rule.match, function(match, id) {
                    if (match instanceof Array) {
                        _.each(match, function(m) {
                            if (scope.matches[id].init) {
                                scope.matches[id].init(m);
                            }

                            rule.matchEdit.push({
                                id,
                                value: m,
                            });
                        });
                    } else {
                        if (scope.matches[id].init) {
                            scope.matches[id].init(match);
                        }

                        rule.matchEdit.push({
                            id,
                            value: match,
                        });
                    }
                });
                // Convert actions
                rule.actionEdit = [];
                _.each(scope.actionKeys, function(actionId) {
                    if (rule[actionId]) {
                        if (actionId === 'hdr_action' && Array.isArray(rule[actionId])) {
                            if (!rule[actionId].length) {
                                return;
                            }

                            rule[actionId].forEach(function(val) {
                                if (val.hdr && val.hdr.value && _.isUndefined(val.hdr.value.var)) {
                                    val.hdr.value.var = '_CUSTOM_VALUE';
                                }
                            });
                        }

                        if (scope.actions[actionId].init) {
                            scope.actions[actionId].init(rule[actionId]);
                        }

                        rule.actionEdit.push({
                            id: actionId,
                            value: rule[actionId],
                        });
                    }
                });

                rule.save = function() {
                    scope.saveRule();
                };
            };

            scope.rule2ReadMode = function(rule) {
                // Convert matches back
                rule.match = {};
                _.map(rule.matchEdit, function(match) {
                    if (scope.matches[match.id].onSubmit) {
                        scope.matches[match.id].onSubmit(match.value);
                    }

                    if (match.id == 'hdrs') {
                        if (!rule.match[match.id] || !(rule.match[match.id] instanceof Array)) {
                            rule.match[match.id] = [];
                        }

                        rule.match[match.id].push(match.value);
                    } else {
                        rule.match[match.id] = match.value;
                    }
                });
                delete rule.matchEdit;

                // Convert actions back
                _.each(scope.actionKeys, function(actionId) {
                    delete rule[actionId];
                });
                _.map(rule.actionEdit, function(action) {
                    if (scope.actions[action.id].onSubmit) {
                        scope.actions[action.id].onSubmit(action.value);
                    }

                    rule[action.id] = action.value;
                });
                delete rule.actionEdit;
                delete rule.save;
            };

            /**
             * Creates a new rule.
             * @param {Object=} toPosition - Determines where to place the new rule.
             * @param {string} toPosition.position - 'above' or 'below' a specified index.
             * @param {number} toPosition.index - Existing index used as reference for position.
             */
            scope.addRule = function(toPosition) {
                const { config } = scope.httpRequestPolicy;

                scope.current = {
                    enable: true,
                    name: `Rule ${
                        config && config.rules ? config.rules.length + 1 : 1}`,
                    matchEdit: [],
                    _toPosition: toPosition,
                };
                scope.rule2EditMode(scope.current);
            };

            /**
             * Duplicates an existing rule and allows for editing.
             * @param {Object} rule - Rule config.
             * @param {Object} toData - Contains position and index properties.
             * @param {string} toData.position - New position relative to the new index, 'above' or
             *     'below'.
             * @param {number} toData.index - New index to be moved to.
             */
            scope.duplicateRule = function(rule, toPosition) {
                scope.current = angular.extend(angular.copy(rule), {
                    name: `${rule.name} - duplicated`,
                    _toPosition: toPosition,
                });

                scope.current.index = undefined;
                scope.rule2EditMode(scope.current);
            };

            scope.editRule = function(rule) {
                scope.current = angular.copy(rule);
                scope.rule2EditMode(scope.current);
            };

            scope.saveRule = function() {
                if (!scope.current) {
                    return;
                }

                let { config } = scope.httpRequestPolicy;

                if (!config) {
                    config = {
                        rules: [],
                    };

                    scope.httpRequestPolicy.config = config;
                }

                if (!Array.isArray(config.rules)) {
                    config.rules = [];
                }

                // Make sure there is no rule with the same name
                scope.error = null;

                if (_.any(config.rules, function(rule) {
                    return rule.name == scope.current.name && rule.index != scope.current.index;
                })) {
                    scope.error = 'Rule name already in use';

                    return;
                }

                scope.rule2ReadMode(scope.current);

                if (!_.isUndefined(scope.current.index)) {
                    angular.copy(scope.current, _.find(config.rules, function(item) {
                        return item.index == scope.current.index;
                    }));
                } else {
                    scope.current.index = _.max(config.rules, function(i) {
                        return i.index;
                    }).index + 1 || 1;
                    config.rules.push(scope.current);
                }

                if (angular.isObject(scope.current._toPosition)) {
                    scope.httpRequestPolicy.moveRule(scope.current, scope.current._toPosition);
                }

                scope.current = null;
            };

            /**
             * Appends a match to the current rule
             * @param type - Match type
             */
            scope.addMatch = function(type) {
                if (!scope.current || !scope.matches[type]) {
                    return;
                }

                scope.current.matchEdit.push({
                    id: type,
                    index: scope.current.matchEdit.length,
                    value: angular.copy(scope.matches[type].default),
                });

                scrollDown();
            };

            /**
             * Appends a match to the current rule
             * @param type - Match type
             */
            scope.addAction = function(type) {
                if (!scope.current || !scope.actions[type]) {
                    return;
                }

                // If redirect action added, remove all other actions
                if (type === 'redirect_action') {
                    emptyActionsList();
                }

                scope.current.actionEdit.push({
                    id: type,
                    value: angular.copy(scope.actions[type].default),
                });

                scrollDown();
            };

            /**
             * Deletes the match from the current rule
             * @param {Object} matchOrMatchValue - the type of the match
             */
            scope.deleteMatch = function(matchOrMatchValue) {
                const index = _.findIndex(scope.current.matchEdit,
                    item => item === matchOrMatchValue || item.value === matchOrMatchValue);

                if (index !== -1) {
                    scope.current.matchEdit.splice(index, 1);
                }
            };

            /**
             * Deletes the action from the current rule
             * @param {Object} action - the type of the match
             */
            scope.deleteAction = function(action) {
                const index = scope.current.actionEdit.indexOf(action);

                if (index !== -1) {
                    scope.current.actionEdit.splice(index, 1);
                }
            };

            // Used to filter out the rules that exist in detail.rules
            scope.matchNotUsed = function(match) {
                if (match == 'hdrs') {
                    return true;
                }

                if (scope.current && scope.current.matchEdit) {
                    const found = _.find(scope.current.matchEdit, function(item) {
                        return item.id == match;
                    });

                    if (found) {
                        return false;
                    }
                }

                return true;
            };

            scope.actionNotUsed = function(action) {
                if (scope.current && scope.current.actionEdit) {
                    return !_.find(scope.current.actionEdit, function(item) {
                        return item.id == action;
                    });
                }

                return true;
            };

            scope.object2Array = function(obj) {
                const arr = [];

                _.each(obj, function(item, key) {
                    arr.push({
                        id: key,
                        data: item,
                    });
                });

                return arr;
            };

            scope.matches = {
                client_ip: {
                    name: 'Client IP',
                    default: {
                        match_criteria: 'IS_IN',
                        _tmp: [{
                            type: 'address',
                            data: '',
                        }],
                        addrs: [],
                        ranges: [],
                    },
                    stringify(m) {
                        const val = [];

                        if (m.addrs) {
                            _.each(m.addrs, function(item) {
                                val.push(item.addr);
                            });
                        }

                        if (m.ranges) {
                            _.each(m.ranges, function(item) {
                                val.push(`${item.begin.addr}-${item.end.addr}`);
                            });
                        }

                        if (m.prefixes) {
                            _.each(m.prefixes, function(item) {
                                val.push(`${item.ip_addr.addr}/${item.mask}`);
                            });
                        }

                        if (m.group_refs) {
                            _.each(m.group_refs, function(item) {
                                val.push(`group ${item.name()}` || item.slug());
                            });
                        }

                        return `${Schema.enums.MatchOperation.values[m.match_criteria]
                            .options.text.value} (${val.join(', ')})`;
                    },
                },
                vs_port: {
                    name: 'Service Port',
                    default: {
                        match_criteria: 'IS_IN',
                        ports: [],
                    },
                    stringify(m) {
                        return m.ports.join(', ');
                    },
                },
                protocol: {
                    name: 'Protocol Type',
                    default: {
                        match_criteria: 'IS_IN',
                        protocols: 'HTTP',
                    },
                    stringify(m) {
                        return m.protocols;
                    },
                },
                method: {
                    name: 'HTTP Method',
                    default: {
                        match_criteria: 'IS_IN',
                        methods: [],
                    },
                    stringify(m) {
                        return `${Schema.enums.MatchOperation.values[m.match_criteria]
                            .options.text.value} (${_.map(m.methods, function(item) {
                            return item.replace(/^HTTP_METHOD_/g, '');
                        }).join(', ')})`;
                    },
                },
                version: {
                    name: 'HTTP Version',
                    default: {
                        match_criteria: 'IS_IN',
                        versions: [], // {version: 'ZERO_NINE'}
                        _tmp: {},
                    },
                    init(m) {
                        m._tmp = {};
                        _.each(m.versions, function(v) {
                            m._tmp[v] = true;
                        });
                    },
                    onSubmit(m) {
                        m.versions = [];
                        _.each(m._tmp, function(value, version) {
                            if (value) {
                                m.versions.push(
                                    version,
                                );
                            }
                        });
                    },
                    stringify(m) {
                        return _.map(m.versions, function(item) {
                            return Schema.enums.HTTPVersion.values[item].options.text.value;
                        }).join(', ');
                    },
                },
                path: {
                    name: 'Path',
                    default: {
                        match_criteria: 'CONTAINS',
                        match_str: [],
                        _tmp: [{
                            type: 'custom',
                            data: '',
                        }],
                    },
                    stringify(m) {
                        const val = [];

                        Array.prototype.push.apply(val, m.match_str);

                        _.each(m.string_group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });

                        return `${Schema.enums.StringOperation.values[m.match_criteria]
                            .options.text.value} (${val.join(', ')})`;
                    },
                },
                query: {
                    name: 'Query',
                    default: {
                        match_criteria: 'QUERY_MATCH_CONTAINS',
                        match_str: [],
                        _tmp: [{
                            type: 'custom',
                            data: '',
                        }],
                    },
                    stringify(m) {
                        const val = [];

                        _.each(m.match_str, function(str) {
                            val.push(`"${str}"`);
                        });

                        _.each(m.string_group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });

                        return `contains ${val.join(' or ')}`;
                    },
                },
                hdrs: {
                    name: 'Headers',
                    default: {
                        match_criteria: 'HDR_EXISTS',
                        hdr: '',
                        value: [],
                    },
                    stringify(hdrs) {
                        return _.map(hdrs, function(hdr) {
                            const schema =
                                Schema.enums.HdrMatchOperation.values[hdr.match_criteria];

                            return `${hdr.hdr} ${
                                schema ? schema.options.text.value : hdr.match_criteria
                            }${hdr.match_criteria == 'HDR_CONTAINS' ? ` ${
                                hdr.value.join(' or ')}` : ''}`;
                        }).join(', ');
                    },
                },
                cookie: {
                    name: 'Cookie',
                    default: {
                        match_criteria: 'HDR_EXISTS',
                        name: '',
                        value: '',
                    },
                    stringify(m) {
                        const schema = Schema.enums.HdrMatchOperation.values[m.match_criteria];

                        return ` ${m.name} ${
                            (schema ? schema.options.text.value : schema).toLowerCase()
                        } ${m.value}`;
                    },
                },
                host_hdr: {
                    name: 'Host Header',
                    default: {
                        match_criteria: 'HDR_EQUALS',
                        value: [''],
                    },
                    init(m) {
                        if (m.match_criteria != 'HDR_EXISTS' &&
                            m.match_criteria != 'HDR_DOES_NOT_EXIST' &&
                            (!m.value || !m.value.length)) {
                            m.value = [''];
                        }
                    },
                    onSubmit(m) {
                        if (m.match_criteria == 'HDR_EXISTS' ||
                            m.match_criteria == 'HDR_DOES_NOT_EXIST') {
                            delete m.value;
                        }
                    },
                    stringify(m) {
                        return `${Schema.enums.HdrMatchOperation.values[m.match_criteria]
                            .options.text.value} '${m.value.join('\' or \'')}'`;
                    },
                },
            };

            /**
             * Definition of actions
             */
            scope.actions = {
                redirect_action: {
                    name: 'Redirect',
                    default: {
                        protocol: 'HTTP',
                        port: 80,
                        host: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        path: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        keep_query: true,
                        status_code: 'HTTP_REDIRECT_STATUS_CODE_302',
                    },
                    init: HTTPRedirectAction.beforeEdit,
                    onSubmit: HTTPRedirectAction.beforeSave,
                    stringify(a) {
                        // Stringify tokens
                        let host = '',
                            path = '';

                        if (a.host && a.host.tokens.length) {
                            host = _.map(a.host.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('.');
                        }

                        if (a.path && a.path.tokens.length) {
                            path = _.map(a.path.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('/');
                        }

                        return `${a.protocol}://${
                            a.host && a.host.tokens && a.host.tokens.length ?
                                host : '<ExistingHost>'
                        }${'port' in a ? `:${a.port}` : ''
                        }/${
                            a.path && a.path.tokens && a.path.tokens.length ?
                                path : '<ExistingPath>'
                        }?${a.keep_query ? '<KeepQueryString>' : '<RemoveQueryString>'}`;
                    },
                },
                hdr_action: {
                    name: 'Modify Header',
                    default: [{
                        action: 'HTTP_REMOVE_HDR',
                    }],
                    stringify(action) {
                        let output = '';

                        _.each(action, function(hdr) {
                            output += `${hdr.action.enumeration('HTTP_')}: `;

                            switch (hdr.action) {
                                case 'HTTP_REPLACE_HDR':
                                    output += `replace "${hdr.hdr.name}" with ${
                                        hdr.hdr.value.var ? hdr.hdr.value.var
                                            .enumeration('HTTP_POLICY_VAR_') : 'Custom Value'
                                    }${hdr.hdr.value.val ? ` "${hdr.hdr.value.val}"` : ''}`;
                                    break;
                                case 'HTTP_ADD_HDR':
                                    output += `add "${hdr.hdr.name}" = ${
                                        hdr.hdr.value.var ? hdr.hdr.value.var
                                            .enumeration('HTTP_POLICY_VAR_') : 'Custom Value'
                                    }${hdr.hdr.value.val ? ` "${hdr.hdr.value.val}"` : ''}`;
                                    break;
                                case 'HTTP_REMOVE_HDR':
                                    output += `remove "${hdr.hdr.name}"`;
                                    break;
                                default:
                                    output += hdr.cookie ? `"${hdr.cookie.name}"` : '';

                                    if (hdr.action !== 'HTTP_REMOVE_COOKIE') {
                                        output += ` = "${hdr.cookie.value}"`;
                                    }
                            }

                            output += '; ';
                        });

                        return output;
                    },
                    onActionChange(action, actionObj) {
                        actionObj = !_.isUndefined(actionObj) ? actionObj : {};

                        switch (action) {
                            case 'HTTP_REMOVE_HDR':
                                actionObj.value = undefined;
                                break;
                            default:
                                actionObj.value = {
                                    var: '_CUSTOM_VALUE',
                                    val: '',
                                };
                        }

                        return actionObj;
                    },
                },
                rewrite_url_action: {
                    name: 'Rewrite URL',
                    default: {
                        action: '',
                        host_hdr: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        path: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        query: {
                            keep_query: true,
                        },
                    },
                    init(action) {
                        let ii,
                            val;

                        if (action.host_hdr && action.host_hdr.tokens) {
                            val = [];

                            for (ii = 0; ii < action.host_hdr.tokens.length; ii++) {
                                val.push(RangeParser.token2str(action.host_hdr.tokens[ii]));
                            }

                            action.host_hdr.value = val.join('.');
                        }

                        if (action.path && action.path.tokens) {
                            val = [];

                            for (ii = 0; ii < action.path.tokens.length; ii++) {
                                val.push(RangeParser.token2str(action.path.tokens[ii]));
                            }

                            if (val[0] === '' && val.length > 1) {
                                val.splice(0, 1);
                                action.path.value = val.join('/');
                            } else if (val[0] === '' && val.length === 1) {
                                action.path.value = '/';
                            } else {
                                action.path.value = val.join('/');
                            }
                        }
                    },
                    onSubmit(action) {
                        // Parse host_hdr
                        if (action.host_hdr && action.host_hdr.value &&
                            action.host_hdr.value !== '') {
                            action.host_hdr = {
                                type: 'URI_PARAM_TYPE_TOKENIZED',
                                tokens: RangeParser.tokensStr2tokens(action.host_hdr.value, '.'),
                            };
                        } else {
                            delete action.host_hdr;
                        }

                        // Parse path
                        if (action.path && action.path.value && action.path.value !== '') {
                            action.path = {
                                type: 'URI_PARAM_TYPE_TOKENIZED',
                                tokens: RangeParser.tokensStr2tokens(action.path.value, '/'),
                            };
                        } else {
                            delete action.path;
                        }
                    },
                    stringify(a) {
                        // Stringify tokens
                        let hostHdr = '',
                            path = '';

                        if (a.host_hdr && a.host_hdr.tokens.length) {
                            hostHdr = _.map(a.host_hdr.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('.');
                        }

                        if (a.path && a.path.tokens && a.path.tokens.length) {
                            path = _.map(a.path.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('/');
                        }

                        return `Host Header: ${
                            hostHdr !== '' ? hostHdr : '<ExistingHostHeader>'
                        }; Path: /${a.path && a.path.tokens && a.path.tokens.length ? path :
                            '<ExistingPath>'}?${a.query.keep_query ? '<KeepQueryString>' :
                            '<RemoveQueryString>'}`;
                    },
                },
                switching_action: {
                    name: 'Content Switch',
                    default: {
                        action: 'HTTP_SWITCHING_SELECT_POOL',
                        status_code: 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200',
                    },
                    init(action) {
                        if (action.action === 'HTTP_SWITCHING_SELECT_POOL' && action.pool_ref) {
                            scope.actions['switching_action'].poolCollectionAddCurrentItem();

                            if (!action.value) {
                                action.value = {};
                            }

                            scope.actions['switching_action'].getPoolServers(action.pool_ref)
                                .then(function() {
                                    const { server } = action;

                                    if (server) {
                                        const value = Server.getServerUuid(action.server);

                                        scope.actions['switching_action']
                                            .serverSelected(action, { value });
                                        scope.ui.server = value;
                                    } else {
                                        scope.ui.server = undefined;
                                    }
                                });
                        }
                    },
                    stringify(action) {
                        let output = '';

                        if (action.action === 'HTTP_SWITCHING_SELECT_POOL') {
                            output += 'Pool: ';
                            output += action.pool_ref.name();

                            if (action.server && action.server.ip && action.server.ip.addr) {
                                output += `     Server: ${action.server.ip.addr}`;

                                if (action.server.port) {
                                    output += `:${action.server.port}`;
                                }
                            }
                        } else {
                            output += `Status Code: ${
                                action.status_code.replace('HTTP_LOCAL_RESPONSE_STATUS_CODE_', '')
                            }${action.file ? `, File: ${action.file.content_type}` : ''}`;
                        }

                        return output;
                    },
                    actionTypeChange(action, ruleIndex) {
                        const actionVal = action.value;

                        delete actionVal.pool_ref;
                        delete actionVal.pool_group_ref;
                        delete actionVal.status_code;
                        delete actionVal.file;
                        delete actionVal.server;

                        scope.ui.server = undefined;

                        if (actionVal.action === 'HTTP_SWITCHING_SELECT_POOL') {
                            scope.actions['switching_action'].poolCollectionAddCurrentItem();
                        } else if (actionVal.action === 'HTTP_SWITCHING_SELECT_LOCAL') {
                            emptyActionsList(ruleIndex);
                            actionVal.status_code = 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200';
                        }
                    },
                    poolCollectionAddCurrentItem() {
                        scope.PoolCollection.bind('collectionBeforeLoad', function() {
                            // Load all pools used by this VS and unused pools
                            this.setParams({ referred_by: 'poolgroup:none' });
                        });
                    },
                    getPoolServers(poolRef) {
                        scope.poolServers = [];

                        const pool = new Pool({
                            id: poolRef.slug(),
                        });

                        return pool.load().then(function() {
                            return scope.poolServers = pool.data.config.servers;
                        });
                    },
                    poolSelected(action) {
                        scope.actions['switching_action'].getPoolServers(action.value.pool_ref);
                        scope.ui.server = undefined;
                        action.value.server = undefined;
                    },
                    serverSelected(action, selected) {
                        if (selected) {
                            const server = _.find(scope.poolServers, srv => {
                                return Server.getServerUuid(srv) === selected.value;
                            });

                            action.value.server = {
                                ip: server.ip,
                                hostname: server.hostname,
                            };

                            if (server.port) {
                                action.value.server.port = server.port;
                            }
                        } else { //on-clear for the dropdown
                            action.value.server = undefined;
                        }
                    },
                    serverStringBuilder,
                },
            };

            scope.onLogTypeChange = function(switchedTo) {
                const currentRule = scope.current;

                switch (switchedTo) {
                    case 'with-headers':
                        currentRule.log = true;
                        currentRule.all_headers = true;
                        break;

                    case 'on':
                        currentRule.log = true;
                        currentRule.all_headers = false;
                        break;

                    default:
                        currentRule.log = false;
                        currentRule.all_headers = false;
                }
            };

            scope.matchKeys = Object.keys(scope.matches);
            scope.actionKeys = Object.keys(scope.actions);

            scope.httpRequestPolicyGridConfig = new PolicyGridConfig({
                collection: scope.httpRequestPolicy,
                controls: {
                    create: {
                        title: 'Create rule',
                        do: () => {
                            const { rows } = scope.httpRequestPolicy;
                            const index = angular.isArray(rows) ?
                                rows.length && rows[rows.length - 1].index || 0 :
                                0;

                            scope.addRule({ index, position: 'below' });
                        },
                    },
                },
                actions: {
                    createAt: ({ index }, position) => scope.addRule({ index, position }),
                },
                singleactions: [{
                    title: 'Delete',
                    class: 'icon icon-trash',
                    do: rule => scope.httpRequestPolicy.delete(rule),
                }, {
                    title: 'Menu',
                    template: require(
                        '../../components/applications/virtualservice/policy/' +
                        'policy-grid/policy-grid-menu.tooltip.partial.html',
                    ),
                    edit: rule => scope.editRule(rule),
                    move: rule => {
                        const rules = scope.httpRequestPolicy.rows;

                        AviConfirmService
                            .prompt('policy-grid-prompt-index', { rule, rules })
                            .then(data => scope.httpRequestPolicy.moveRule(rule, data));
                    },
                    duplicate: rule => {
                        const rules = scope.httpRequestPolicy.rows;

                        AviConfirmService
                            .prompt('policy-grid-prompt-index', { rules })
                            .then(data => scope.duplicateRule(rule, data));
                    },
                }],
            });

            scope.onRedirectProtocolChange = action => {
                const { protocol } = action.value;

                action.value.port = protocol === 'HTTPS' ? 443 : 80;
            };

            scope.$on('$destroy', () => {
                scope.PoolCollection.destroy();
                scope.poolGroupCollection.destroy();
            });
        },
    };
}]);
