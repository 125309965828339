/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Highlights field when value!=initial value (adds class `changed`)
 */
angular.module('aviApp').directive('showChanged', function() {
    return {
        link(scope, element, attrs) {
            // Check for changes
            const findAttr = function(obj, fieldName) {
                const a = fieldName.split('.');

                if (obj && a && a[0]) {
                    return findAttr(obj[a.splice(0, 1)], a.join('.'));
                }

                return obj;
            };

            const checkForChanges = function() {
                const editable = scope.editable || scope.$parent.editable ||
                    scope.$parent.$parent.editable;
                let propName = attrs.showChanged;

                if (!propName && attrs.ngModel) {
                    propName = attrs.ngModel.replace('editable.data.config.', '');
                }

                if (propName && editable && editable.data &&
                    findAttr(editable.data.config, propName) !==
                    findAttr(editable.backup, propName)) {
                    element.addClass('changed');
                } else {
                    element.removeClass('changed');
                }
            };

            element.on('focus blur keyup click change', checkForChanges);
            scope.$watch(attrs.ngModel, checkForChanges);
        },
    };
});
