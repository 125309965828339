/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name modalWizardNav
 * @description
 *     Navigation component for the modalContainer component.
 * @param {Object[]} tabs - List of tabs to be shown.
 * @param {Function} onSelectTab - Function to be called when a tab is clicked.
 * @param {Function} isSelected - Function called to know if a tab is currently selected.
 * @param {boolean} ngDisabled - True to disable the wizard navigation.
 */
class ModalWizardNavController {
    handleSelectTab(tab) {
        this.onSelectTab({ tab });
    }
}

angular.module('aviApp').component('modalWizardNav', {
    controller: ModalWizardNavController,
    bindings: {
        tabs: '<',
        onSelectTab: '&',
        isSelected: '&',
        ngDisabled: '<',
    },
    templateUrl: 'src/components/modals/modal-container/modal-wizard/modal-wizard-nav/' +
            'modal-wizard-nav.html',
});
