/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const componentTag = 'license-selector';

class LicenseSelectorController {
    constructor(
        CloudLicenseTypes,
        dropDownUtils,
        schemaService,
        Cloud,
        defaultValues,
    ) {
        this.CloudLicenseTypes = CloudLicenseTypes;
        this.dropDownUtils = dropDownUtils;
        this.schemaService = schemaService;
        this.Cloud = Cloud;
        this.defaultValues = defaultValues;
    }

    $onInit() {
        /**
         * Provides lookup for when user switches between BYOL and PAYG models.
         * @type {{BYOL: DropDownOption[], PAYG: DropDownOption[]}}
         */
        this.licenseTypesHash = {
            BYOL: [],
            PAYG: [],
        };

        /**
         * List of Cloud license tier types from protobuf.
         * @type {DropDownOption[]}
         */
        this.licenseTierTypes = [];

        /**
         * User configurable default tier set for license_tier.
         * @type {string}
         */
        this.defaultTier = this.defaultValues
            .getDefaultItemConfig('systemconfiguration.default_license_tier');

        /**
         * Determines whether to enable LicenseModel radio buttons.
         * @type {boolean}
         */
        this.singleLicenseModel = true;

        /**
         * Holds actual license types to display as user changes model.
         * @type {DropDownOption[]}
         */
        this.displayedLicenses = [];

        /**
         * Is UI display property only.
         * Options: BYOL or PAYG
         * @type {string}
         */
        this.licenseModel = '';

        this.populateLicenseTypesHash_();
        this.createLicenseTiersDropdown_();
        this.setLicenseModel_();
    }

    /**
     * Creates hashes in this.licenseTypesHash for BYOL and PAYG type licenses.
     * @protected
     */
    populateLicenseTypesHash_() {
        /**
         * List of all possible existing license types for Clouds which exist in protobuf.
         * Is used to get value, label, and description of each license type for dropdown.
         * @type {EnumValue[]}
         */
        const allLicenseTypes = this.schemaService.getEnumValues('LicenseType');

        const cloudSpecificLicenses = this.CloudLicenseTypes[this.vType];

        /**
         * List of Cloud license types belonging to current cloud being edited/created.
         * @type {EnumValue[]}
         */
        const allowedLicenses = _.filter(allLicenseTypes, licenseType => {
            return _.find(cloudSpecificLicenses, licenseVal => {
                return licenseType.value === licenseVal;
            });
        });

        /**
         * List of Cloud license types belonging to current cloud being edited/created.
         * @type {DropDownOption[]}
         */
        const dropdownFormattedLicenses = allowedLicenses.map(license => {
            return this.dropDownUtils.createOption(
                license.value,
                license.label,
                license.description,
            );
        });

        /**
         * For GCP, though both PAYG and BYOL license types are available, PAYG has
         * to be disabled intentionally as there is no support for it as of now.
         */
        dropdownFormattedLicenses.forEach(license => {
            if (this.Cloud.isMeteredLicenseType(license.value)) {
                if (this.vType !== 'CLOUD_GCP') {
                    this.licenseTypesHash.PAYG.push(license);
                }
            } else {
                this.licenseTypesHash.BYOL.push(license);
            }
        });

        if (this.licenseTypesHash.PAYG.length) {
            this.singleLicenseModel = false;
        }
    }

    /**
     * Populates this.licenseTierTypes with available license tiers from protobuf.
     * @protected
     */
    createLicenseTiersDropdown_() {
        /**
         * List of Cloud license tier types from protobuf.
         * @type {EnumValue[]}
         */
        const licenseTierTypes = this.schemaService.getEnumValues('LicenseTierType');

        this.licenseTierTypes = licenseTierTypes.map(({ value, label }) =>
            this.dropDownUtils.createOption(value, label));
    }

    /**
     * Decides licenseModel based on initial conditions.
     * @protected
     */
    setLicenseModel_() {
        this.licenseType = this.licenseType || '';

        const { licenseType } = this;

        if (this.Cloud.isMeteredLicenseType(licenseType)) {
            this.licenseModel = 'PAYG';
        } else {
            this.licenseModel = 'BYOL';
        }

        this.onLicenseModelChange(!licenseType);
    }

    /**
     * Action when user changes radio selection for licenseModel, or is changed during init.
     * @param {boolean} resetLicenseType - true if changing licenseModel,
     *     or when selecting Cloud
     */
    onLicenseModelChange(resetLicenseType) {
        this.displayedLicenses = this.licenseTypesHash[this.licenseModel];

        if (resetLicenseType) {
            this.resetSelectedLicenseType_();
        }
    }

    /**
     * Resets licence_type to first in list, upon user changing licenseModel,
     *     or in case of init cloud selection.
     * @protected
     */
    resetSelectedLicenseType_() {
        this.licenseType = this.displayedLicenses[0].value;

        if (this.onLicenseTypeChange) {
            this.onLicenseTypeChange();
        }

        // let backend deal with license tier for PAYG
        this.licenseTier = this.licenseModel === 'PAYG' ?
            undefined : this.defaultTier;
    }
}

LicenseSelectorController.$inject = [
    'CloudLicenseTypes',
    'dropDownUtils',
    'schemaService',
    'Cloud',
    'defaultValues',
];

/**
 * @ngdoc component
 * @name  LicenseSelector
 * @param {string} licenseType
 * @param {string} licenseTier
 * @param {Function} onLicenseTypeChange - callback to be fired upon change of licenceType
 * @param {string} vType - type of cloud
 * @description
 *      Component for selecting license type and tier.
 */
angular.module('aviApp').component('licenseSelector', {
    bindings: {
        licenseType: '=',
        licenseTier: '=',
        onLicenseTypeChange: '&?',
        vType: '<',
    },
    controller: LicenseSelectorController,
    templateUrl: `src/components/infrastructure/clouds/${componentTag}/` +
        `${componentTag}.component.html`,
});
