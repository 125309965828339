/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name  performanceChartControls
 * @restrict AE
 */
angular.module('aviApp').directive('performanceChartControls', function() {
    return {
        scope: true,
        restrict: 'AE',
        templateUrl: '/src/views/components/performance-chart-controls.html',
        link: function performanceChartControlsLink($scope, element) {
            element.addClass('performance-chart-controls-container');

            /**
             * Returns a sum of series and errorSeries anomalies.
             * @return {number} - Total number of anomalies.
             */
            $scope.countAnomalies = function() {
                const
                    { metric } = $scope.config.getCard(),
                    series = metric && metric.getMainSeries(),
                    errorSeries = metric && metric.getSeriesByType('errorTotal');

                let totalAnomalies = 0;

                if (series) {
                    totalAnomalies += series.getAnomaliesQ();
                }

                if (errorSeries) {
                    totalAnomalies += errorSeries.getAnomaliesQ();
                }

                return totalAnomalies;
            };
        },
    };
});
