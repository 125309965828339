/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './log-filter-number-popover.less';

/**
 * @ngdoc component
 * @name logFilterNumberPopover
 * @description
 *
 *     Shows a list of applicable filtering operators along with the value. On click gonna pass
 *     selected operator up the chain.
 */
const componentName = 'log-filter-number-popover';

const operators = [
        '=',
        '>',
        '>=',
        '!=',
        '<',
        '<=',
];

class LogFilterNumberPopoverController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$element.addClass(componentName);
        this.operators = operators;
    }

    getLabel(operator) {
        return `${operator} ${this.value}`;
    }
}

LogFilterNumberPopoverController.$inject = [
        '$element',
];

angular.module('aviApp').component('logFilterNumberPopover', {
    bindings: {
        onClick: '&',
        value: '<',
    },
    controller: LogFilterNumberPopoverController,
    template:
            `<a ng-repeat="operator in ::$ctrl.operators track by $index"
                ng-click="$ctrl.onClick({operator: operator})"
                class="${componentName}_filter">
                {{::$ctrl.getLabel(operator)}}</a>`,
});
