/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('innerText', function() {
    return {
        restrict: 'A',
        link: function link(scope, elm, attr) {
            const text = $(`<span class="add-on">${attr.innerText}</span>`);

            elm.wrap('<div class="inner-text"/>').after(text);

            if (elm[0].style.width) {
                elm.parent()[0].style.width = elm[0].style.width;
                elm[0].style.width = '';
            }

            if (elm.hasClass('pull-left')) {
                elm.parent().addClass('pull-left');
                elm.removeClass('pull-left');
            }

            if (elm.hasClass('pull-right')) {
                elm.parent().addClass('pull-right');
                elm.removeClass('pull-right');
            }

            const unWatch = scope.$watch(function() {
                return attr.innerText;
            }, function(newValue, oldValue) {
                if (oldValue !== newValue) {
                    text.text(newValue);
                }
            });

            scope.$on('$destroy', function() {
                unWatch();
            });
        },
    };
});
