/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

// Takes a scope and an element, and gives it popup functionality
// TODO replace & remove @am
// elm should have a .base, which will be visible
// popup should have a .popup class, and an ng-show = "display.showPopup"
angular.module('aviApp').factory('popupHelper', ['$document', '$timeout', '$rootScope', function(
    $document, $timeout, $rootScope,
) {
    return function(scope, elm) {
        let popup = elm.find('.popup');
        const base = elm.find('.base');
        let timer;

        scope.display = scope.display || {};

        scope.baseMouseenter = function(e) {
            // console.log('base mouse enter');
            dontHide();

            if (!scope.display.showPopup) {
                scope.goingToShowPopup = $timeout(showPopup, 250);
            }
        };

        /**
         * Checks if SE list is there and contains the right number of SE's
         * If not - VS has to reload
         */
        scope.makeSureSEReady = function() {
            // Check if all SE's are in se_list, otherwise need to load VS again
            //TODO remove if redundant
            if (scope.item.objectName === 'virtualservice' &&
                scope.item.data && scope.item.data.config &&
                !scope.item.data.config['requested_resource'] ||
                !scope.item.data.config['se_list'] ||
                scope.item.data.config['requested_resource']['num_se'] >
                scope.item.data.config['se_list'].length) {
                scope.item.loadConfig();
            }
        };

        scope.baseMouseleave = function(e) {
            // console.log('base mouse leave');
            if (scope.goingToShowPopup) {
                $timeout.cancel(scope.goingToShowPopup);
                scope.goingToShowPopup = false;
            }

            delayedHide();
        };

        scope.baseClick = function(e) {
            // console.log('base click');
            e.stopPropagation();

            // so that you can get rid of stuck popups by clicking elsewhere on the page
            if (scope.display.stuckPopup) {
                $document.off('click.popupHandler');
            } else {
                showPopup();
                $document.one('click.popupHandler', function(e) {
                    e.stopPropagation();
                    hidePopup();
                });
            }

            scope.display.stuckPopup = !scope.display.stuckPopup;
        };

        scope.popupClick = function(e) {
            // console.log('popup click');
            e.stopPropagation();
        };

        scope.popupMouseenter = function(e) {
            // console.log('popup mouse enter');
            e.stopPropagation();
            dontHide();
        };

        scope.popupMouseleave = function(e) {
            // console.log('popup mouseleave');
            e.stopPropagation();
            delayedHide();
        };

        function dontHide() {
            // console.log('don\'t hide');
            scope.mouseHover = true;

            if (timer) {
                $timeout.cancel(timer);
            }
        }

        function delayedHide() {
            // console.log('delayed hide');
            scope.mouseHover = false;

            if (timer) {
                $timeout.cancel(timer);
            }

            timer = $timeout(function() {
                if (!scope.mouseHover && !scope.display.stuckPopup) {
                    hidePopup();
                }
            }, 500);
        }

        function hidePopup() {
            //console.log('hide popup');
            $timeout(function() {
                scope.display.showPopup = false;
                scope.display.stuckPopup = false;
            });

            if (typeof scope.onHide === 'function') {
                scope.onHide();
            }
        }

        function showPopup() {
            if (scope.goingToShowPopup) {
                $timeout.cancel(scope.goingToShowPopup);
                scope.goingToShowPopup = false;
            }

            //console.log('show popup');
            $rootScope.$broadcast('hideOtherPopups', popup.get(0));

            if (scope.onHover && typeof scope.onHover === 'function') {
                scope.onHover();
            }

            // we want to grab the docwidth before the popup shows
            const docWidth = $document.width();
            const docHeight = $document.height();
            const baseWidth = base.width();
            const baseHeight = base.height();

            scope.display.showPopup = true;

            popup.css('margin-top', '-2000px');

            $timeout(function() {
                popup = elm.find('.popup');

                if (!popup.length) {
                    //console.warn('popupHelper.showPopup.timeout: Popup was not found.');
                    return;
                }

                const popupWidth = popup.width();
                const popupHeight = popup.height();
                let offset = base.offset();
                let alreadyShifted;
                let moveLeft;

                popup.css('margin-top', '');

                if (offset.top + popupHeight + baseHeight > docHeight && offset.top > popupHeight) {
                    const moveUp = -popupHeight - 5;

                    popup.css('top', `${moveUp}px`);
                }

                if (offset.left > docWidth / 2) {
                    moveLeft = -popupWidth + baseWidth;
                    popup.css('margin-left', `${moveLeft}px`);
                    alreadyShifted = true;
                }

                // CSS madness that I need to figure out -- Will TODO debugger;
                if (!alreadyShifted) {
                    offset = popup.offset();

                    // right / left adjust
                    if (offset.left > docWidth / 2) {
                        moveLeft = -popupWidth + baseWidth;
                        popup.css('margin-left', `${moveLeft}px`);
                    }
                }

                scope.$broadcast('repaint');
            });
        }

        scope.$on('hideOtherPopups', function(e, authorPopup) {
            if (authorPopup !== popup.get(0)) {
                scope.display.showPopup = false;
                scope.display.stuckPopup = false;
            }
        });

        scope.$on('$destroy', function() {
            $document.off('click.popupHandler');

            if (scope.goingToShowPopup) {
                $timeout.cancel(scope.goingToShowPopup);
            }
        });
    };
}]);
