/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  DnsPolicyConfig
 * @description  DnsPolicyConfig ConfigItem.
 */
const dnsPolicyConfigFactory = (PolicyConfigItem, DnsPolicyRuleConfig) => {
    class DnsPolicyConfig extends PolicyConfigItem {
        /** @override */
        transformAfterLoad_() {
            super.transformAfterLoad_();
            this.sortRulesBy_('index');
        }
    }

    angular.extend(DnsPolicyConfig.prototype, {
        type: 'DnsPolicy',
        windowElement_: 'dns-policy-rule-modal',
        ruleClass_: DnsPolicyRuleConfig,
        rulesPropertyName_: 'rule',
    });

    return DnsPolicyConfig;
};

dnsPolicyConfigFactory.$inject = [
        'PolicyConfigItem',
        'DnsPolicyRuleConfig',
];

angular.module('aviApp').factory('DnsPolicyConfig', dnsPolicyConfigFactory);
