/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  DnsPolicyRuleConfig
 * @description
 *     DnsPolicyRuleConfig PolicyRuleConfigItem class, mapped to the protobuf message DnsRule.
 *     Used when configuring DNS Policy rules.
 */
const dnsPolicyRuleConfigFactory = (
        PolicyRuleConfigItem,
        DnsPolicyRuleTransportProtocolMatchConfig,
        DnsPolicyRuleQueryNameMatchConfig,
        DnsPolicyRuleQueryTypeMatchConfig,
        DnsPolicyRuleGeoLocationMatchConfig,
        DnsPolicyRuleClientIpMatchConfig,
        DnsPolicyRuleAllowDropActionConfig,
        DnsPolicyRuleResponseActionConfig,
        DnsPolicyRuleGslbSiteSelectionActionConfig,
        DnsPolicyRulePoolSwitchingActionConfig,
        DnsPolicyRuleRateProfileActionConfig,
) => {
    class DnsPolicyRuleConfig extends PolicyRuleConfigItem {
        /** @override
             * The property of data#config#enabled in base class is renamed to 'enable' in DnsRule.
             */
        setEnable(enable = true) {
            const config = this.getConfig();

            config.enable = enable;
        }
    }

    angular.extend(DnsPolicyRuleConfig.prototype, {
        type: 'DnsRule',
        matchSettingsHash: {
            client_ip_address: {
                className: DnsPolicyRuleClientIpMatchConfig,
                isRepeated: false,
                isNested: true,
            },
            protocol: {
                className: DnsPolicyRuleTransportProtocolMatchConfig,
                isRepeated: false,
                isNested: true,
            },
            query_name: {
                className: DnsPolicyRuleQueryNameMatchConfig,
                isRepeated: false,
                isNested: true,
            },
            query_type: {
                className: DnsPolicyRuleQueryTypeMatchConfig,
                isRepeated: false,
                isNested: true,
            },
            geo_location: {
                className: DnsPolicyRuleGeoLocationMatchConfig,
                isRepeated: false,
                isNested: true,
            },
        },
        actionSettingsHash: {
            allow: {
                className: DnsPolicyRuleAllowDropActionConfig,
                isRepeated: false,
                isNested: true,
            },
            response: {
                className: DnsPolicyRuleResponseActionConfig,
                isRepeated: false,
                isNested: true,
            },
            gslb_site_selection: {
                className: DnsPolicyRuleGslbSiteSelectionActionConfig,
                isRepeated: false,
                isNested: true,
            },
            pool_switching: {
                className: DnsPolicyRulePoolSwitchingActionConfig,
                isRepeated: false,
                isNested: true,
            },
            dns_rate_limit: {
                className: DnsPolicyRuleRateProfileActionConfig,
                isRepeated: false,
                isNested: true,
            },
        },
    });

    return DnsPolicyRuleConfig;
};

dnsPolicyRuleConfigFactory.$inject = [
        'PolicyRuleConfigItem',
        'DnsPolicyRuleTransportProtocolMatchConfig',
        'DnsPolicyRuleQueryNameMatchConfig',
        'DnsPolicyRuleQueryTypeMatchConfig',
        'DnsPolicyRuleGeoLocationMatchConfig',
        'DnsPolicyRuleClientIpMatchConfig',
        'DnsPolicyRuleAllowDropActionConfig',
        'DnsPolicyRuleResponseActionConfig',
        'DnsPolicyRuleGslbSiteSelectionActionConfig',
        'DnsPolicyRulePoolSwitchingActionConfig',
        'DnsPolicyRuleRateProfileActionConfig',
];

angular.module('aviApp').factory('DnsPolicyRuleConfig', dnsPolicyRuleConfigFactory);
