/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PoolServerVHostMetric
 * @description
 *
 *     PoolVM metric is using hostname as an entity_uuid in request. Multiple seriesIds are used.
 *
 *     Item is pool server.
 */
angular.module('aviApp').factory('PoolServerVHostMetric', [
'$q', 'CollMetric',
function($q, CollMetric) {
    return class PoolServerVHostMetric extends CollMetric {
        constructor(args) {
            super(args);

            this.hostname_ = '';
        }

        /**
         * Because of the fact all series go into Item.data we want to rename these not to
         * overlap with server metrics having same seriesId (server analytics page).
         * @override
         */
        // TODO Remove when Item.data is not used as a hash of all series
        getFullSeriesId_(config) {
            const fullId = super.getFullSeriesId_(config);

            return `poolvm:${fullId}`;
        }

        /** @override */
        initialize() {
            const
                { item } = this,
                vmRef = item.getVMRef();

            // container clouds
            if (!vmRef) {
                this.hostname_ = item.getConfig()['server_node'];

                return $q.when(true);
            }

            const url = `/api/vimgrvmruntime/${vmRef.slug()}`;

            return this.request('get', url)
                .then(this.setHostname_.bind(this));
        }

        setHostname_({ data }) {
            this.hostname_ = data['host'];
        }

        /** @override */
        requestConfig() {
            const config = super.requestConfig();

            config['entity_uuid'] = this.hostname_;

            if (!config['entity_uuid']) {
                console.warn(
                    'Can\'t make a request for PoolServerVHostMetric since there is no hostname',
                );

                return null;
            }

            delete config['pool_uuid'];
            delete config['obj_id'];

            return config;
        }
    };
}]);
