/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
function getLocationWoHashFactory(location) {
    /**
     * @alias module:services/getLocationWoHash.getLocationWoHash
     * @param {boolean=} withSearch - location will include query part if true is passed.
     * @return {string}
     **/
    function getLocationWoHash(withSearch) {
        const {
            origin,
            pathname,
            search,
        } = location;

        const url = `${origin}${pathname}`;

        if (withSearch && search) {
            return `${url}${search}`;
        }

        return url;
    }

    return getLocationWoHash;
}

getLocationWoHashFactory.$inject = [
    'windowLocation',
];

/**
 * @ngdoc service
 * @name getLocationWoHash
 * @module services/getLocationWoHash
 * @desc
 *
 *     Returns current URL wo the hash part. Can be used for the hard UI reload.
 */
//TODO might need more flexible approach of getting URL
// by passing list of chunk types we are interested in
angular.module('deps.vantage.avi')
    .factory('getLocationWoHash', getLocationWoHashFactory);
