/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name eventDetails
 * @param {{event_details: Object}} details - Event details object provided by the back-end.
 * @description Event details for the events grid expander. Have two layouts here - one for
 * config-update event when we show two configs along each other with highlighted differences
 * and another one for all other types when we just represent the event details object as a list.
 */
angular.module('aviApp').directive('eventDetails', ['objectToHtmlList', function(objectToHtmlList) {
    function link(scope, elem) {
        /**
         * We use here only two event types: "other" and "config-update". Template provides two
         * different layouts for those.
         * @type {string}
         */
        scope.eventType = 'other';

        /**
         * Orders array by serverIp and switches it into hash for Pool's Servers (pool config
         * edit event).
         * @param {Object} config
         * @inner
         */
        function transformUpdateConfigBeforeComparison(config) {
            if ('servers' in config && Array.isArray(config['servers'])) {
                config.servers = _.reduce(config.servers, function(hash, server) {
                    let serverId = server.ip.addr;

                    if ('port' in server) {
                        serverId += `:${server.port}`;
                    }

                    hash[serverId] = server;

                    return hash;
                }, {});
            }
        }

        if (scope.details.event_details['config_update_details']) {
            scope.eventType = 'config-update';

            const newConfig = scope.details
                .event_details['config_update_details'].old_resource_data &&
                    JSON.parse(scope.details
                        .event_details['config_update_details'].new_resource_data) ||
                        {};

            const oldConfig = scope.details
                .event_details['config_update_details'].old_resource_data &&
                    JSON.parse(scope.details
                        .event_details['config_update_details'].old_resource_data) ||
                        {};

            [oldConfig, newConfig].forEach(transformUpdateConfigBeforeComparison);

            scope.prevConfig = oldConfig;
            scope.newConfig = newConfig;
        } else {
            const {
                event_details: eventDetails,
                event_description: description,
            } = scope.details;
            const [eventDetailKey] = Object.keys(eventDetails);
            const eventDetailsCopy = angular.copy(eventDetails);

            if (scope.details.event_details['avi_cloud_crs_details']) {
                scope.crsUrl = eventDetailsCopy['avi_cloud_crs_details'].url;
                delete eventDetailsCopy['avi_cloud_crs_details'].url;
            }

            scope.description = description;

            scope.eventDetailKey = eventDetailKey;
            scope.eventDetailValue = objectToHtmlList(eventDetailsCopy[eventDetailKey]);
        }

        //when used inside grid expander
        elem.on('$destroy', function() {
            scope.$destroy();
        });
    }

    return {
        restrict: 'E',
        scope: {
            details: '=',
        },
        link,
        templateUrl: 'src/views/application/partials/event-details.html',
    };
}]);
