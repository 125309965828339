/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleTransportProtocolMatchConfig
 * @description DnsPolicyRuleTransportProtocolMatch MatchConfigItem class.
 */
const dnsPolicyRuleTransportProtocolMatchController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleTransportProtocolMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
            config.protocol = config.protocol || 'DNS_OVER_UDP';
        }

        /** @override */
        toString() {
            const
                config = this.getConfig(),
                { protocol, match_criteria: matchCriteria } = config;

            if (protocol) {
                const value = schemaService.getEnumValue('MatchOperation', matchCriteria).label;
                const modifiedValue = value.replace(/ in/g, '');

                return `${modifiedValue.toLowerCase()} ${protocol.enumeration()}`;
            } else {
                return config.protocols;
            }
        }
    }

    angular.extend(DnsPolicyRuleTransportProtocolMatchConfig.prototype, {
        type: 'DnsTransportProtocolMatch',
        name: 'Transport Protocol',
        defaultPath: 'dnsrule.match.protocol',
    });

    return DnsPolicyRuleTransportProtocolMatchConfig;
};

dnsPolicyRuleTransportProtocolMatchController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleTransportProtocolMatchConfig', dnsPolicyRuleTransportProtocolMatchController,
);
