/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceTrafficCaptureStatus
 * @description  Displays traffic capture status.
 */
function Controller($filter) {
    /**
     * Changes a debug_ip object into a string of IP addresses.
     * @return {string}
     */
    this.getFilterString = function(debug) {
        return $filter('ipObjectToString')(debug);
    };
}

Controller.$inject = [
    '$filter',
];

angular.module('aviApp').component('virtualserviceTrafficCaptureStatus', {
    bindings: {
        config: '<',
        progress: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/operations/virtualservice-traffic-capture-status/' +
            'virtualservice-traffic-capture-status.html',
    transclude: {
        edit: 'a',
        switch: 'div',
    },
});
