/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './tri-checkbox.less';

/**
 * @ngdoc component
 * @name triCheckbox
 * @param {number} state - Expression evaluating to a number that indicates the state of the
 *     checkbox. 0 for unchecked, 1 for checked. and 2 for indeterminate.
 * @param {Function} onClick - Function to be called when the checkbox or label is clicked.
 * @param {string} label - String to be shown as the label for the checkbox.
 * @description
 *     3-state checkbox component, useful for when a single checkbox is representative of a set of
 *     checkboxes.
 */
class TriCheckboxController {
    /**
     * Handles clicking the checkbox or label.
     * @param {angular.$event} $event
     */
    handleClick($event) {
        $event.stopPropagation();
        this.onClick();
    }
}

angular.module('aviApp').component('triCheckbox', {
    controller: TriCheckboxController,
    bindings: {
        state: '<',
        onClick: '&',
        label: '@',
    },
    templateUrl: 'src/components/common/tri-checkbox/tri-checkbox.html',
});
