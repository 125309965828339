/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

// common params for list pages
const
    listParams = '?timeframe',
    commonLogParams = [//alerts, events and logs
        'timeframe',
        'orderby',
    ],
    logParams = commonLogParams.concat([
        'start',
        'end',
        'asc',
        'search',
        'page_size',
        'filter',
    ]),
    eventParams = commonLogParams.concat([
        'start',
        'end',
        'search',
        'inclInternal',
    ]);

const infrastructureDetailParams = {
    cloudId: {
        value: null,
        dynamic: true,
    },
};

// State configs
const logListStateConfig = {
    name: 'logs',
    url: `/log?${logParams.join('&')}`,
    templateUrl: 'src/views/application/log-list.html',
    controller: 'LogListController',
    title: 'Logs',
    data: {
        tfGroup: 'logs',
        hasSidebar: true,
        showTimeframe: true,
    },
    params: {},
};

logParams.forEach(param => logListStateConfig.params[param] = { dynamic: true });

const eventListStateConfig = {
    name: 'events',
    url: `/event?${eventParams.join('&')}`,
    template: '<events-page></events-page>',
    title: 'Events',
    data: {
        tfGroup: 'logs',
        hasSidebar: true,
        showTimeframe: true,
    },
    params: {},
};

eventParams.forEach(param => eventListStateConfig.params[param] = { dynamic: true });

const getEventListStateConfig = params => angular.merge({}, eventListStateConfig, params);

const alertListStateConfig = {
    name: 'alerts',
    url: `/alerts?${commonLogParams.join('&')}`,
    template: '<alerts-page></alerts-page>',
    title: 'Alerts',
    data: {
        tfGroup: 'logs',
        showTimeframe: true,
    },
    params: {},
};

commonLogParams.forEach(param => alertListStateConfig.params[param] = { dynamic: true });

const getAlertListStateConfig = params => angular.merge({}, alertListStateConfig, params);

const insightsStateConfig = {
    name: 'hs-insights',
    url: '/hs-insights',
    defaultChild: 'default',
    abstract: true,
    templateUrl: 'src/views/details-leaf-page-template.html',
    data: {
        tfGroup: 'insights',
        metricsValueSelector: false,
        timeFrameSelector: false,
    },
    children: [{
        name: 'default',
        url: `${listParams}`,
        views: {
            nav: {
                template: '<header/>',
            },
            '': {
                component: 'hsInsights',
            },
        },
    }],
};

const insightsStateConfigForVS = {
    name: 'hs-insights',
    url: `/hs-insights${listParams}`,
    title: 'Health',
    component: 'hsInsights',
    data: {
        tfGroup: 'insights',
    },
};

// Resolve objects
const msAppMapIsAvail = {
    token: 'msAppMapIsAvail',
    resolveFn: msAppMapIsAvail => msAppMapIsAvail(),
    deps: ['msAppMapIsAvail'],
    policy: { async: 'NOWAIT' },
};

const loadedSystemInfoService = {
    token: 'loadedSystemInfoService',
    resolveFn: systemInfo => systemInfo.load().then(() => systemInfo),
    deps: ['systemInfoService'],
};

const resolveVS = {
    token: 'resolveVS',
    resolveFn: (loadItem, { vsId }, Class) => {
        const args = {
            id: vsId,
            fields: [
                'config',
                'health',
                'alert',
                'faults',
            ],
        };

        return loadItem(args, Class);
    },
    deps: ['loadItem', '$stateParams', 'VirtualService'],
};

const resolvePool = {
    token: 'resolvePool',
    resolveFn: (loadItem, { poolId }, Class) => {
        const args = {
            id: poolId,
            fields: [
                'config',
                'health',
                'alert',
                'faults',
            ],
        };

        return loadItem(args, Class);
    },
    deps: ['loadItem', '$stateParams', 'Pool'],
};

const resolvePoolServer = {
    token: 'resolvePoolServer',
    resolveFn: (loadPoolServer, { poolId, serverId }, pool) =>
        loadPoolServer({ poolId, serverId, pool }),
    deps: ['loadPoolServer', '$stateParams', 'resolvePool'],
};

const resolveCloud = {
    token: 'resolveCloud',
    resolveFn: (loadCloud, { cloudId }) => loadCloud(cloudId),
    deps: ['loadCloud', '$stateParams'],
};

/**
 * If appropriate permissions are set, then gslb instance will be loaded.
 * Otherwise null is returned, and child handles the condition.
 */
const resolveGslb = {
    token: 'resolveGslb',
    resolveFn: (Auth, loadItem, loadedSystemInfoService, GSLB) => {
        if (Auth.isAllowed('gslb')) {
            return loadItem(loadedSystemInfoService.getGSLBid(), GSLB);
        } else {
            return null;
        }
    },
    deps: ['Auth', 'loadItem', 'loadedSystemInfoService', 'GSLB'],
};

const resolveGslbService = {
    token: 'resolveGslbService',
    resolveFn: (loadItem, { gslbServiceId }, gslb, GSLBService) => {
        const params = { id: gslbServiceId };

        if (gslb) {
            params.gslb = gslb;
        }

        return loadItem(params, GSLBService);
    },
    deps: ['loadItem', '$stateParams', 'resolveGslb', 'GSLBService'],
};

const loadedSystemConfigService =
    systemConfigService => systemConfigService.load().then(() => systemConfigService);

loadedSystemConfigService.$inject = ['systemConfigService'];

const resolveSE = {
    token: 'resolveSE',
    resolveFn: (loadItem, { seId }, Class) => loadItem(seId, Class),
    deps: ['loadItem', '$stateParams', 'ServiceEngine'],
};

/**
 * @typedef {Object} appStateData
 * @property {string?} permission
 * @property {boolean?} showTimeframe
 * @property {boolean?} metricsControls
 * @property {boolean?} hasSidebar
 */

/**
 * @typedef {Object} appState
 * @property {string} name
 * @property {string?} template
 * @property {string?} templateUrl
 * @property {string?} controller
 * @property {string?} component
 * @property {Object?} params
 * @property {string?} title
 * @property {string?} defaultChild
 * @property {boolean?} abstract
 * @property {string} url
 * @property {appStateData?} data
 * @property {Object[]?} resolve
 * @property {appState[]?} children
*/

/**
 * Application states list.
 * Used to generated states and privilege map.
 * @type {appState[]}
 */
const stateTree = [{
    name: 'authenticated.application',
    abstract: true,
    template: '<div ui-view class="application"/>',
    defaultChild: 'dashboard',
    url: '/applications',
    data: {
        permission: 'APPLICATION',
    },
    resolve: [
        angular.extend(
            {},
            loadedSystemInfoService,
            { policy: { async: 'NOWAIT' } },
        ),
    ],
    children: [{
        name: 'dashboard',
        url: '/dashboard?timeframe',
        controller: 'AppDashboardController',
        templateUrl: 'src/views/application/dashboard.tpl.html',
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
        },
        resolve: [
            msAppMapIsAvail,
        ],
    }, {
        name: 'virtualservice-list',
        url: `/virtualservice${listParams}`,
        controller: 'VirtualServiceListController',
        templateUrl: 'src/components/applications/virtualservice/virtual-service-list/' +
            'virtualservice-list.html',
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
        },
    }, {
        name: 'virtualservice-detail',
        abstract: true,
        url: '/virtualservice/:vsId',
        component: 'virtualserviceDetail',
        defaultChild: 'analytics',
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
        },
        resolve: [
            resolveVS,
        ],
        children: [
            {
                name: 'analytics',
                url: '/analytics?metric&timeframe',
                templateUrl: 'src/views/application/vs-analytics.html',
                controller: 'VirtualServiceAnalyticsController',
                title: 'Analytics',
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
            },
            angular.copy(logListStateConfig),
            angular.copy(insightsStateConfigForVS),
            {
                name: 'clientinsight',
                url: '/clientinsight?timeframe',
                templateUrl: 'src/views/application/clientinsight.html',
                title: 'Clients',
                data: {
                    showTimeframe: true,
                },
            }, {
                name: 'security',
                url: '/security?timeframe',
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
                templateUrl: 'src/views/application/virtualservice-security.html',
                title: 'Security',
            },
            getEventListStateConfig(),
            getAlertListStateConfig(),
            {
                name: 'app-map',
                url: '/app-map?timeframe',
                controller: 'VsAppMapController',
                controllerAs: 'appMap',
                templateUrl: 'src/views/application/vs-app-map.html',
                title: 'AppMap',
                data: {
                    hasSidebar: true,
                    showTimeframe: true,
                },
            }, {
                name: 'dns-records',
                url: '/dns-records?timeframe',
                controller: 'VirtualServiceDnsRecordsController',
                controllerAs: '$ctrl',
                templateUrl: 'src/views/application/virtualservice-dns-records.html',
                title: 'DNS Records',
                data: {
                    showTimeframe: true,
                },
            }, {
                name: 'WAF',
                url: `/WAF${listParams}`,
                component: 'vsWafPage',
                title: 'WAF',
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
            },
        ],
    }, {
        name: 'pool-list',
        url: `/pool${listParams}`,
        templateUrl: 'src/components/applications/pool/pool-list/pool-list.html',
        data: {
            permission: 'PERMISSION_POOL',
        },
        controller: 'PoolListController',
    }, {
        name: 'pool-detail',
        url: '/pool/:poolId/:vsId',
        template: '<div ui-view/>',
        abstract: true,
        controller: 'PoolDetailController',
        defaultChild: 'analytics',
        data: {
            permission: 'PERMISSION_POOL',
        },
        resolve: [
            resolvePool,
            resolveVS,
        ],
        children: [{
            name: 'analytics',
            url: `/analytics?${['metric', 'timeframe'].join('&')}`,
            controller: 'PoolAnalyticsController',
            templateUrl: 'src/views/application/pool-analytics.html',
        },
        angular.copy(insightsStateConfig),
            getEventListStateConfig({
                template: '<events-page><header/></events-page>',
            }),
            getAlertListStateConfig({
                template: '<alerts-page><header/></alerts-page>',
            }),
        angular.copy(logListStateConfig),
        {
            name: 'servers',
            url: '/servers?timeframe',
            templateUrl: 'src/views/application/pool-servers-list.html',
            controller: 'PoolServerListController',
        }, {
            name: 'server-detail',
            url: '/server/:serverId',
            template: '<div ui-view/>',
            abstract: true,
            defaultChild: 'analytics',
            resolve: [
                resolvePoolServer,
            ],
            children: [{
                name: 'analytics',
                url: '/analytics?timeframe',
                templateUrl: 'src/components/applications/server-analytics/' +
                    'server-analytics.html',
                controller: 'ServerAnalyticsController',
            }],
        }],
    }, {
        name: 'pool-group-list',
        url: `/pool-group${listParams}`,
        templateUrl: 'src/views/application/pool-group-list.html',
        data: {
            permission: 'PERMISSION_POOLGROUP',
        },
        controller: 'PoolGroupListController',
        controllerAs: '$ctrl',
    }, {
        name: 'gslbservice-list',
        url: `/gslbservice-list${listParams}`,
        component: 'gslbServiceList',
        data: {
            permission: 'PERMISSION_GSLBSERVICE',
        },
        resolve: [
            resolveGslb,
            loadedSystemInfoService,
        ],
    }, {
        name: 'gslbservice-detail',
        url: '/gslbservice/:gslbServiceId',
        component: 'gslbServiceDetail',
        abstract: true,
        data: {
            permission: 'PERMISSION_GSLBSERVICE',
        },
        resolve: [
            resolveGslbService,
            resolveGslb,
        ],
        children: [{
            name: 'members',
            url: '/members',
            component: 'gslbServiceMemberList',
            title: 'Members Status',
        }, {
            name: 'fqdn-list',
            url: '/fqdn-list',
            component: 'gslbServiceDetailFqdnList',
            title: 'FQDN Insights',
            data: {
                showTimeframe: true,
                permission: 'PERMISSION_GSLB',
            },
        },
            getEventListStateConfig(),
        ],
    }],
}, {
    name: 'authenticated.infrastructure',
    url: '/infrastructure',
    template: '<div ui-view class="infrastructure"/>',
    abstract: true,
    defaultChild: 'dashboard',
    data: {
        permission: 'INFRASTRUCTURE',
    },
    children: [{
        name: 'dashboard',
        url: '/dashboard?timeframe',
        templateUrl: 'src/views/infrastructure/dashboard.html',
        controller: 'InfraDashboardController',
        controllerAs: 'infraDash',
        data: {
            permission: 'PERMISSION_SERVICEENGINE',
        },
    }, {
        name: 'cloud-list',
        url: `/cloud${listParams}`,
        controller: 'CloudListController',
        templateUrl: 'src/components/infrastructure/cloud-list/cloud-list.html',
        data: {
            permission: 'PERMISSION_CLOUD',
        },
    }, {
        name: 'infrastructure-detail',
        component: 'infrastructureDetail',
        abstract: true,
        params: {
            timeframe: {
                value: null,
            },
        },
        resolve: [
            resolveCloud,
        ],
        defaultChild: 'serviceengine-list',
        children: [{
            name: 'serviceengine-list',
            url: `/serviceengine/{cloudId}${listParams}`,
            component: 'serviceEngineList',
            data: {//displaying: duration and values type
                metricsControls: true,
                permission: 'PERMISSION_SERVICEENGINE',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'segroup-list',
            url: `/segroup/{cloudId}${listParams}`,
            component: 'serviceEngineGroupList',
            data: {
                permission: 'PERMISSION_SERVICEENGINEGROUP',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'network-list',
            url: `/network/{cloudId}${listParams}`,
            component: 'networkList',
            data: {
                permission: 'PERMISSION_NETWORK',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'routing',
            abstract: true,
            template: '<subheader /><div ui-view></div>',
            defaultChild: 'staticroute-list',
            data: {
                permission: 'PERMISSION_VRFCONTEXT',
            },
            children: [{
                name: 'staticroute-list',
                title: 'Static Route',
                url: `/staticroute/{cloudId}${listParams}`,
                component: 'staticRouteList',
                params: infrastructureDetailParams,
            }, {
                name: 'bgp-peering',
                title: 'BGP Peering',
                url: `/bgp-peering/{cloudId}${listParams}`,
                component: 'bgpPeeringList',
                params: infrastructureDetailParams,
            }, {
                name: 'vrf-context-list',
                title: 'VRF Context',
                url: `/vrf-context/{cloudId}${listParams}`,
                component: 'vrfContextList',
                params: infrastructureDetailParams,
            }, {
                name: 'gateway-monitor-list',
                title: 'Gateway Monitor',
                url: `/gateway-monitor/{cloudId}${listParams}`,
                component: 'gatewayMonitorList',
                params: infrastructureDetailParams,
            }],
        }],
    }, {
        name: 'cloud',
        url: '/cloud/:cloudId',
        template: '<div ui-view class="cloud"/>',
        abstract: true,
        resolve: [
            angular.extend(
                {},
                resolveCloud,
                { policy: { async: 'NOWAIT' } },
            ),
        ],
        children: [{
            name: 'serviceengine-detail',
            url: '/serviceengine/:seId',
            controller: 'ServiceEngineController',
            template: '<div ui-view class="serviceengine clearfix"/>',
            abstract: true,
            defaultChild: 'summary',
            data: {
                permission: 'PERMISSION_SERVICEENGINE',
            },
            resolve: [
                resolveSE,
            ],
            children: [{
                name: 'summary',
                url: '/summary?metric&timeframe',
                templateUrl: 'src/views/infrastructure/se-analytics.html',
                controller: 'SEAnalyticsController',
            },
                angular.copy(insightsStateConfig),
                getEventListStateConfig({
                    template: '<events-page><header/></events-page>',
                }),
                getAlertListStateConfig({
                    template: '<alerts-page><header/></alerts-page>',
                }),
            ],
        }],
    }, {
        name: 'gslb',
        url: '/gslb',
        template: '<subheader /><div ui-view />',
        abstract: true,
        defaultChild: 'site-configuration',
        resolve: [
            loadedSystemInfoService,
        ],
        data: {
            permission: 'INFRASTRUCTURE_GSLB',
        },
        children: [{
            name: 'site-configuration',
            url: '/site-configuration',
            title: 'Site Configuration',
            controller: 'GSLBController',
            controllerAs: '$ctrl',
            templateUrl: 'src/views/infrastructure/gslb.html',
            data: {
                permission: 'PERMISSION_GSLB',
            },
        }, {
            name: 'geo-profile',
            url: '/geo-profile',
            title: 'Geo Profile',
            component: 'geoProfilePage',
            data: {
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        }, {
            name: 'upload-geo-files',
            url: '/upload-geo-files',
            title: 'Upload Geo Files',
            component: 'uploadGeoFilesPage',
            data: {
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        }],
    }],
}, {
    name: 'authenticated.administration',
    url: '/administration',
    template: `<subheader />
        <div ui-view class="administration" />`,
    abstract: true,
    defaultChild: 'users.user-list',
    data: {
        permission: 'ADMINISTRATION',
    },
    children: [{
        name: 'users',
        abstract: true,
        url: '/users',
        template: '<div ui-view/>',
        defaultChild: 'user-list',
        data: {
            permission: 'ACCOUNT',
        },
        children: [{
            name: 'user-list',
            title: 'Users',
            url: '/user',
            component: 'userList',
            data: {
                permission: 'PERMISSION_USER',
            },
        }, {
            name: 'user-profile-list',
            title: 'User Profiles',
            url: '/user-profile',
            templateUrl: 'src/views/administration/user-profile-list.html',
            data: {
                permission: 'PERMISSION_USER',
            },
        }, {
            name: 'role-list',
            title: 'Roles',
            url: '/role',
            templateUrl: 'src/views/administration/role-list.html',
            data: {
                permission: 'PERMISSION_ROLE',
            },
        }, {
            name: 'tenant-list',
            title: 'Tenants',
            url: `/tenant${listParams}`,
            templateUrl: 'src/views/administration/tenant-list.html',
            controller: 'TenantListController',
            data: {
                permission: 'PERMISSION_TENANT',
            },
        }],
    }, {
        name: 'settings',
        abstract: true,
        url: '/settings',
        template: '<div ui-view/>',
        defaultChild: 'authentication',
        data: {
            permission: 'PERMISSION_SYSTEMCONFIGURATION',
        },
        children: [{
            name: 'authentication',
            title: 'Authentication/Authorization',
            url: '/authentication',
            templateUrl:
                'src/components/administration/authentication/authentication.html',
            controller: 'AuthenticationController',
            controllerAs: '$ctrl',
        }, {
            name: 'systemaccess',
            title: 'Access Settings',
            url: '/systemaccess',
            templateUrl: 'src/views/administration/systemaccess.html',
            controller: 'SystemConfigurationController',
            controllerAs: '$ctrl',
            resolve: {
                loadedSystemConfigService,
            },
        }, {
            name: 'systemconfiguration',
            title: 'DNS/NTP',
            url: '/systemconfiguration',
            component: 'dnsNtpPage',
            resolve: {
                loadedSystemConfigService,
            },
        }, {
            name: 'licensing',
            title: 'Licensing',
            url: '/licensing',
            component: 'licensingPage',
            resolve: {
                loadedSystemConfigService,
            },
        }, {
            name: 'smtp',
            title: 'Email/SMTP',
            url: '/smtp',
            templateUrl: 'src/views/administration/smtp.html',
            controller: 'SystemConfigurationController',
            controllerAs: '$ctrl',
            resolve: {
                loadedSystemConfigService,
            },
        }, {
            name: 'tenant',
            title: 'Tenant Settings',
            url: '/tenant',
            templateUrl: 'src/views/administration/tenant-settings.html',
        }, {
            name: 'upload-packages',
            title: 'Upload HSM Packages',
            url: '/upload-packages',
            templateUrl: 'src/views/administration/upload-packages.html',
            controller: 'UploadPackagesController',
            controllerAs: '$ctrl',
        }, {
            name: 'dns-service',
            title: 'DNS Service',
            url: '/dns-service',
            templateUrl: 'src/views/administration/dns-service.html',
            controller: 'DnsServiceController',
            controllerAs: '$ctrl',
            resolve: {
                loadedSystemConfigService,
            },
        }, {
            name: 'pulse',
            title: 'Pulse',
            url: '/pulse',
            component: 'aviCloudPage',
            resolve: [
                loadedSystemInfoService,
            ],
        }],
    }, {
        name: 'controller-detail',
        abstract: true,
        url: '/controller',
        template: '<div ui-view class="controller-detail"/>',
        defaultChild: 'cluster',
        data: {
            permission: 'PERMISSION_CONTROLLER',
        },
        children: [{
            name: 'cluster',
            title: 'Nodes',
            url: '/cluster',
            templateUrl: 'src/views/infrastructure/cluster-list.html',
            controller: 'ClusterListController',
        },
            getEventListStateConfig(),
            getAlertListStateConfig(),
        {
            name: 'software',
            title: 'Software',
            url: '/software',
            component: 'softwarePage',
            data: {
                permission: 'PERMISSION_IMAGE',
            },
        }, {
            name: 'system-update',
            title: 'System Update',
            url: '/system-update',
            component: 'systemUpdate',
            data: {
                permission: 'PERMISSION_UPGRADE_OPS',
            },
        }],
    }, {
        name: 'system',
        abstract: true,
        url: '/system',
        template: '<div ui-view/>',
        defaultChild: 'backup',
        data: {
            permission: 'SYSTEM',
        },
        children: [{
            name: 'backup',
            title: 'Configuration Backup',
            url: '/backup',
            templateUrl: 'src/views/administration/system/backup.html',
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            controller: 'BackupController',
            controllerAs: '$ctrl',
        }, {
            name: 'techsupport',
            title: 'Tech Support',
            url: '/techsupport',
            component: 'techSupport',
            data: {
                permission: 'PERMISSION_TECHSUPPORT',
            },
            resolve: [
                loadedSystemInfoService,
            ],
        }, {
            name: 'crashreports',
            title: 'Crash Reports',
            url: '/crashreports',
            templateUrl: 'src/views/administration/system/crashreports.html',
            data: {
                permission: 'PERMISSION_TECHSUPPORT',
            },
            controller: 'CrashReportsController',
            controllerAs: '$ctrl',
        }],
    }, {
        name: 'support',
        abstract: true,
        url: '/support',
        template: '<div ui-view/>',
        defaultChild: 'cases',
        children: [{
            name: 'cases',
            title: 'Cases',
            url: '/cases',
            component: 'caseList',
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            resolve: [
                loadedSystemInfoService,
            ],
        }],
    }, {
        name: 'user-credentials',
        template: '<div ui-view/>',
        defaultChild: 'user-credentials',
        abstract: true,
        children: [{
            name: 'user-credentials',
            title: 'User Credentials',

            url: '/user-credentials',
            component: 'userCredentialsPage',
            data: {
                permission: 'PERMISSION_USER_CREDENTIAL',
            },
        }],
    }],
}, {
    name: 'authenticated.operations',
    url: '/operations',
    template: `<subheader />
        <div ui-view class="operations" />`,
    abstract: true,
    defaultChild: 'alerts.allalerts-list',
    data: {
        permission: 'OPERATIONS',
    },
    children: [{
        name: 'alerts',
        url: '/alerts',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'allalerts-list',
        children: [
            getAlertListStateConfig({
                name: 'allalerts-list',
                title: 'All Alerts',
                url: `/allalerts-list?${commonLogParams.join('&')}`,
                data: {
                    permission: 'PERMISSION_ALERT',
                },
            }),
            {
                name: 'alertconfig-list',
                title: 'Alert Config',
                url: '/alertconfig',
                templateUrl: 'src/views/operations/alertconfig-list.html',
                data: {
                    permission: 'PERMISSION_ALERTCONFIG',
                },
            }, {
                name: 'alertactions-list',
                title: 'Alert Actions',
                url: '/alertactions',
                templateUrl: 'src/views/operations/alertaction-list.html',
                data: {
                    permission: 'PERMISSION_ALERT',
                },
            }],
    }, {
        name: 'events',
        url: '/events',
        template: '<div ui-view/>',
        defaultChild: 'events-list',
        abstract: true,
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
            tfGroup: 'logs',
            showTimeframe: true,
        },
        children: [
            getEventListStateConfig({
                name: 'events-list',
                title: 'All Events',
                url: `/events-list?${eventParams.join('&')}`,
            }),
            getEventListStateConfig({
                name: 'configuration',
                title: 'Config Audit Trail',
                url: `/configuration?${eventParams.join('&')}`,
            }),
        ],
    }, {
        name: 'services',
        url: '/services',
        template: '<div ui-view/>',
        defaultChild: 'syslog-list',
        abstract: true,
        children: [{
            name: 'syslog-list',
            title: 'Syslog',
            url: '/syslog',
            templateUrl: 'src/views/operations/syslog-list.html',
            data: {
                permission: 'PERMISSION_ALERTSYSLOGCONFIG',
            },
        }, {
            name: 'email-list',
            title: 'Email',
            url: '/email',
            templateUrl: 'src/views/operations/email-list.html',
            data: {
                permission: 'PERMISSION_ALERTEMAILCONFIG',
            },
        }, {
            name: 'snmp-list',
            title: 'SNMP Trap',
            url: '/snmp',
            templateUrl: 'src/views/operations/snmp-list.html',
            data: {
                permission: 'PERMISSION_SNMPTRAPPROFILE',
            },
        }],
    }, {
        name: 'traffic-capture',
        template: '<div ui-view />',
        defaultChild: 'traffic-capture',
        abstract: true,
        children: [{
            name: 'traffic-capture',
            title: 'Traffic Capture',
            url: '/traffic-capture',
            templateUrl: 'src/views/operations/traffic-capture.html',
            data: {
                permission: 'PERMISSION_TRAFFIC_CAPTURE',
            },
        }],
    }],
}, {
    name: 'authenticated.profile',
    url: '/template',
    template: `<subheader />
        <div ui-view />`,
    abstract: true,
    defaultChild: 'profiles.applicationprofile-list',
    resolve: [
        //to show/hide GSLB monitors tab
        angular.extend(
            {},
            loadedSystemInfoService,
            { policy: { async: 'NOWAIT' } },
        ),
    ],
    data: {
        permission: 'TEMPLATE',
    },
    children: [{
        name: 'profiles',
        url: '/profile',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'applicationprofile-list',
        data: {
            permission: 'PROFILE',
        },
        children: [{
            name: 'applicationprofile-list',
            title: 'Application',
            url: '/applicationprofile',
            component: 'applicationProfileList',
            data: {
                permission: 'PERMISSION_APPLICATIONPROFILE',
            },
        }, {
            name: 'networkprofile-list',
            title: 'TCP/UDP',
            url: '/networkprofile',
            templateUrl: 'src/views/profile/networkprofile-list.html',
            data: {
                permission: 'PERMISSION_NETWORKPROFILE',
            },
        }, {
            name: 'persistenceprofile-list',
            title: 'Persistence',
            url: '/persistenceprofile',
            component: 'persistenceProfileListPage',
            data: {
                permission: 'PERMISSION_APPLICATIONPERSISTENCEPROFILE',
            },
        }, {
            name: 'healthmonitor-list',
            title: 'Health Monitors',
            url: '/healthmonitor',
            controller: 'HealthMonitorListController',
            templateUrl: 'src/views/profile/healthmonitor-list.html',
            data: {
                permission: 'PERMISSION_HEALTHMONITOR',
            },
            resolve: [
                loadedSystemInfoService,
            ],
        }, {
            name: 'analyticsprofile-list',
            title: 'Analytics',
            url: '/analyticsprofile',
            templateUrl: 'src/views/profile/analyticsprofile-list.html',
            data: {
                permission: 'PERMISSION_ANALYTICSPROFILE',
            },
        }, {
            name: 'ipam-profile',
            title: 'IPAM/DNS Profiles',
            url: '/ipam-profile',
            component: 'ipamDnsProfilesPage',
            data: {
                permission: 'PERMISSION_IPAMDNSPROVIDERPROFILE',
            },
        }, {
            name: 'custom-ipam-dns',
            title: 'Custom IPAM/DNS',
            url: '/custom-ipam-dns',
            component: 'customIpamDnsPage',
            data: {
                permission: 'PERMISSION_CUSTOMIPAMDNSPROFILE',
            },
        }, {
            name: 'traffic-clone-profile',
            title: 'Traffic Clone',
            url: '/traffic-clone-profile',
            component: 'trafficCloneProfilePage',
            data: {
                permission: 'PERMISSION_TRAFFICCLONEPROFILE',
            },
        }],
    }, {
        name: 'group',
        url: '/group',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'ipaddrgroup-list',
        data: {
            permission: 'GROUPS',
        },
        children: [{
            name: 'ipaddrgroup-list',
            title: 'IP Groups',
            url: '/ipaddrgroup',
            templateUrl: 'src/views/profile/groups/ipaddrgroup-list.html',
            data: {
                permission: 'PERMISSION_IPADDRGROUP',
            },
        }, {
            name: 'stringgroup-list',
            title: 'String Groups',
            url: '/stringgroup',
            templateUrl: 'src/views/profile/groups/stringgroup-list.html',
            data: {
                permission: 'PERMISSION_STRINGGROUP',
            },
        }, {
            name: 'microservicegroup-list',
            title: 'MicroService Groups',
            url: '/microservicegroup',
            templateUrl: 'src/views/profile/groups/microservicegroup-list.html',
            data: {
                permission: 'PERMISSION_MICROSERVICEGROUP',
            },
        }],
    }, {
        name: 'ssl',
        url: '/ssl',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'certificate-list',
        data: {
            permission: 'SECURITY',
        },
        children: [{
            name: 'certificate-list',
            title: 'SSL/TLS Certificates',
            url: '/certificate',
            templateUrl:
                'src/components/templates/security/certificate-list/certificate-list.html',
            data: {
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            },
        }, {
            name: 'sslprofile-list',
            title: 'SSL/TLS Profile',
            url: '/sslprofile',
            controller: 'SSLProfileListController',
            templateUrl:
                'src/components/templates/security/ssl-profile-list/sslprofile-list.html',
            data: {
                permission: 'PERMISSION_SSLPROFILE',
            },
        }, {
            name: 'pkiprofile-list',
            title: 'PKI Profile',
            url: '/pkiprofile',
            templateUrl: 'src/views/profile/security/pkiprofile-list.html',
            data: {
                permission: 'PERMISSION_PKIPROFILE',
            },
        }, {
            name: 'authprofile-list',
            title: 'Auth Profile',
            url: '/authprofile',
            component: 'authProfilePage',
            data: {
                permission: 'PERMISSION_AUTHPROFILE',
            },
        }, {
            name: 'pingaccessagentprofile-list',
            title: 'PingAccess Agent',
            url: '/pingaccessagentprofile',
            component: 'pingAccessAgentProfileList',
            data: {
                permission: 'PERMISSION_PINGACCESSAGENT',
            },
        }, {
            name: 'certificate-management-list',
            title: 'Certificate Management',
            url: '/certificate-management',
            templateUrl: 'src/views/profile/security/certificate-management-list.html',
            data: {
                permission: 'PERMISSION_CERTIFICATEMANAGEMENTPROFILE',
            },
        }, {
            name: 'hsmgroup-list',
            title: 'HSM Groups',
            url: '/hsmgroup',
            templateUrl: 'src/views/profile/security/hsmgroup-list.html',
            data: {
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            },
        }, {
            name: 'sso-policy-list',
            title: 'SSO Policy',
            url: '/ssopolicy',
            component: 'ssoPolicyList',
            data: {
                permission: 'PERMISSION_SSOPOLICY',
            },
        }],
    }, {
        name: 'scripts',
        url: '/scripts',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'datascriptset-list',
        data: {
            permission: 'SCRIPTS',
        },
        children: [{
            name: 'datascriptset-list',
            title: 'DataScripts',
            url: '/datascripts',
            controller: 'DataScriptSetListController',
            templateUrl: 'src/views/profile/scripts/datascriptset-list.html',
            data: {
                permission: 'PERMISSION_VSDATASCRIPTSET',
            },
        }, {
            name: 'controlscripts-list',
            title: 'ControlScripts',
            url: '/controlscripts',
            controller: 'ControlScriptsController',
            templateUrl: 'src/views/profile/scripts/controlscripts-list.html',
            data: {
                permission: 'PERMISSION_VSDATASCRIPTSET',
            },
        }, {
            name: 'protocol-parser-scripts-list',
            title: 'ProtocolParserScripts',
            url: '/protocolparserscripts',
            component: 'protocolParserScriptsList',
            data: {
                permission: 'PERMISSION_PROTOCOLPARSER',
            },
        }],
    }, {
        name: 'autoscale',
        url: '/autoscale',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'server-autoscale',
        data: {
            permission: 'PERMISSION_AUTOSCALE',
        },
        children: [{
            name: 'server-autoscale',
            title: 'Server AutoScale',
            url: '/server-autoscale',
            templateUrl: 'src/views/profile/autoscale/server-autoscale.html',
        }, {
            name: 'launch-config',
            title: 'Launch Config',
            url: '/launch-config',
            templateUrl: 'src/views/profile/autoscale/launch-config.html',
        }, {
            name: 'webhook',
            title: 'Webhook',
            url: '/webhook',
            templateUrl: 'src/views/profile/webhook.html',
        }, {
            name: 'pool-group-deployment-policy',
            title: 'PoolGroup Deployment',
            url: '/pool-group-deployment-policy',
            component: 'poolGroupDeploymentPolicyPage',
        }],
    }, {
        name: 'waf',
        url: '/waf',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'waf-profile',
        data: {
            permission: 'WAF',
        },
        children: [{
            name: 'waf-profile',
            title: 'WAF Profile',
            url: '/waf-profile',
            component: 'wafProfileList',
            data: {
                permission: 'PERMISSION_WAFPROFILE',
            },
        }, {
            name: 'waf-policy',
            title: 'WAF Policy',
            url: '/waf-policy',
            component: 'wafPolicyList',
            data: {
                permission: 'PERMISSION_WAFPOLICY',
            },
        }, {
            name: 'psm',
            title: 'Positive Security',
            url: '/psm',
            component: 'wafPolicyPsmGroupListPage',
            data: {
                permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            },
        }, {
            name: 'waf-crs',
            title: 'CRS',
            url: '/waf-crs',
            component: 'wafCrsList',
            data: {
                permission: 'PERMISSION_WAFPOLICY',
            },
        }],
    }, {
        name: 'error-page',
        url: '/error-page',
        template: '<div ui-view/>',
        abstract: true,
        defaultChild: 'error-page-profile',
        data: {
            permission: 'ERRORPAGE',
        },
        children: [{
            name: 'error-page-profile',
            title: 'Error Page Profile',
            url: '/error-page-profile',
            component: 'errorPageProfileList',
            data: {
                permission: 'PERMISSION_ERRORPAGEPROFILE',
            },
        }, {
            name: 'error-page-body',
            title: 'Error Page Body',
            url: '/error-page-body',
            component: 'errorPageBodyList',
            data: {
                permission: 'PERMISSION_ERRORPAGEBODY',
            },
        }],
    }],
}];

/**
 * @ngdoc constant
 * @name appStateTree
 * @description
 *      Array containing all the static routes with name, url and permission details.
 * @author Aravindh Nagarajan
 */
angular.module('router.vantage.avi').constant('appStateTree', stateTree);
