/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name getIpAddr
 * @param {string} ipAddrStr - String presentation of IP address.
 * @returns {IpAddr}
 * @description
 *
 *     Wraps IP address string into {@link IpAddr} object form. Convenience method.
 */

/**
 * @typedef {Object} IpAddr - protobuf message.
 * @property {string} addr - Regular IP4 address in a string form.
 * @property {string} type - Protobuf IpAddrType enum list, V4 or DNS for now.
 */

angular.module('aviApp').constant('getIpAddr', (addr, type = 'V4') => ({ addr, type }));
