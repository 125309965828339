/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name smartTitle
 * @restrict A
 * @params {string} smartTitle - Value to display on hover, passed by argument.
 * @author Alex M
 * @description
 *
 *     Adds title attribute to an element when clipping has been detected.
 *     Very naive approach is used to detect overflow event, confirmed to work with display:
 *     block or inline-block elements and always shown for inline elements.
 */
angular.module('aviApp').directive('smartTitle', function() {
    const overflowCheck = $elem => {
        if ($elem.css('display') === 'inline') {
            return true;
        }

        const [elem] = $elem;

        //might not work on IE11
        return elem.offsetWidth < elem.scrollWidth;
    };

    const eventName = 'mouseenter';

    const smartLabelLink = function($scope, $elem, attr) {
        const { smartTitle: label } = attr;

        const mouseEnter = () => {
            if (overflowCheck($elem)) {
                $elem.attr('title', label);
                //let's leave it alone once added
                $elem.off(eventName, mouseEnter);
            }
        };

        $elem.on(eventName, mouseEnter);
    };

    return {
        restrict: 'A',
        scope: false,
        link: smartLabelLink,
    };
});
