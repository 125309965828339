/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/profile/pki-profile-list-page.less';

angular.module('aviApp').controller('PKIProfileListController', [
'$scope', 'CRUDGridConfig', 'PKIProfileCollection',
function($scope, CRUDGridConfig, PKIProfileCollection) {
    const gridConfig = new CRUDGridConfig({
        collection: new PKIProfileCollection(),
        options: {},
    });

    gridConfig.fields = [{
        name: 'name',
        title: 'Name',
        template: '{{row.getName()}}',
        sortBy: 'name',
    }, {
        name: 'data.config.ca_certs.length',
        title: 'CA Certification',
    }, {
        name: 'data.config.crls.length',
        title: 'Certificate Revocation List (CRL)',
    }];

    const getChildGridConfig = type => {
        const
            config = {
                fields: [],
                layout: {
                    hideSearch: true,
                    hideDisplaying: true,
                },
                expandedContainerTemplate:
                    '<pre class="cert-text__expanded">{{::row.text}}</pre>',
            },
            { fields } = config;

        switch (type) {
            case 'ca':
                fields.push({
                    name: 'subject.common_name',
                    title: 'Name',
                }, {
                    name: 'issuer.common_name',
                    title: 'Issued By',
                }, {
                    name: 'not_after',
                    title: 'Expiration Date',
                });

                config.rowId = row => [
                    row.subject.common_name,
                    row.issuer.common_name,
                    row.not_after,
                ].join();

                break;

            case 'crl':
                fields.push({
                    name: 'common_name',
                    title: 'Name',
                }, {
                    name: 'next_update',
                    title: 'Expiration Date',
                });

                config.rowId = row => [
                    row.common_name,
                    row.next_update,
                ].join();

                break;
        }

        return config;
    };

    gridConfig.executeBeforeContainerExpand = function() {
        const { options } = gridConfig;

        options.caCertsGridConfig = getChildGridConfig('ca');
        options.crlsGridConfig = getChildGridConfig('crl');
    };

    gridConfig.expandedContainerTemplate =
        `<div>
            <h3>CA Certifications</h3>
            <grid config="config.options.caCertsGridConfig"
                rows="row.getConfig()['ca_certs']"></grid>

            <h3 class="mt5">Certificate Revocation List (CRL)</h3>
            <grid config="config.options.crlsGridConfig"
                rows="row.getConfig()['crls']"></grid>
        </div>`;

    $scope.gridConfig = gridConfig;
}]);
