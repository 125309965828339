/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ServerHealthScoreMonitorListDataTransport
 * @author Alex Malitsky
 * @description
 *
 *     We have to make two calls at the same time since list of health monitors is made of
 *     normal ones and `internal` which come from a different source. Hence to generate a list
 *     of them we have to get both in one DataTransport. This is a bad approach caused by poor
 *     backend API design.
 *
 */
angular.module('aviApp').factory('ServerHealthScoreMonitorListDataTransport', [
'ListDataTransport',
function(ListDataTransport) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends ListDataTransport
     */
    return class ServerHealthScoreMonitorListDataTransport extends ListDataTransport {
        /**
         * Returns two ordinary list-type URLs where one has `hmonstat` as a last `path` part and
         * another `internal`, all other parameters are the same.
         * @param {ListDataTransportRequestParams} params
         * @private
         */
        getRequestUrl_(params) {
            //only part after the last slash is different between calls
            return ['hmonstat', 'internal'].map(postfix => {
                const params_ = angular.copy(params);

                params_['objectName_'].push(postfix);

                return super.getRequestUrl_(params_);
            });
        }
    };
}]);
