/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc Controller
 * @name SystemConfigurationOsInstallPluginController
 */
angular.module('aviApp').controller('SystemConfigurationOsInstallPluginController', [
'$scope', '$http', 'Regex', 'AviModal',
function($scope, $http, Regex, AviModal) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Regex = Regex;

    $scope.init = function() {
        $scope.busy = false;
        $scope.error = null;

        $scope.instplug = {
            op_type: 'POT_POST',
            prov_name: 'Avi_ADC',
            uuid: $scope.cloudId,
        };
    };

    $scope.install = function() {
        $scope.busy = true;
        $scope.error = null;

        $http.post('/api/os_lbprov_plugin', $scope.instplug)
            .then(function() {
                $scope.busy = false;
                $scope.closeModal();
            }).catch(function(rsp) {
                $scope.busy = false;
                $scope.error = rsp.result;
            });
    };

    $scope.closeModal = function() {
        if (!$scope.busy) {
            AviModal.destroy('adm-sysconf-os-install-plugin');
        }
    };
}]);
