/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} Cluster
 * @description  Cluster item
 */
angular.module('aviApp').factory('Cluster', ['Item', function(Item) {
    /**
     * Cluster Item. Doesn't have a real id (have only one in a system).
     * @param oArgs {Object} - Configuration object for creation of an {@link Item}.
     * @constructor
     * @extends {Item}
     */
    const Cluster = function(oArgs) {
        Cluster.superconstructor.call(this, oArgs);
    };

    avi.inherit(Cluster, Item);

    Cluster.prototype.objectName = 'cluster';
    Cluster.prototype.windowElement = 'cluster-edit';

    /** @override */
    Cluster.prototype.getIdFromData_ = function() {
        return 'default';//fakeId for single instance
    };

    /** @override */
    Cluster.prototype.urlToSave = function() {
        return `/api/${this.objectName}?include_name`;
    };

    /** @override */
    Cluster.prototype.loadConfig = function() {
        const
            params = this.getLoadParams(),
            headers = this.getLoadHeaders_();

        this.cancelRequests('config');

        const api = `/api/${this.objectName + (params.length ? `?${params.join('&')}` : '')}`;

        return this.request('get', api, undefined, headers, 'config')
            .then(rsp => {
                if (rsp.data.config) {
                    _.each(rsp.data, (prop, name) => {
                        this.data[name] = prop;
                    });
                } else {
                    this.data.config = rsp.data;
                }

                // There should be at least 3 nodes
                const data = this.data.config;
                const { nodes } = data;
                const len = nodes.length;

                if (len < 3) {
                    for (let i = len; i < 3; i++) {
                        nodes[i] = {};
                    }
                }

                return rsp;
            });
    };

    /** @override */
    Cluster.prototype.dataToSave = function() {
        const config = angular.copy(this.data.config);

        config.nodes = _.filter(config.nodes, node => {
            let res = false;

            if (node.ip && node.ip.addr) {
                if (!node.name) {
                    node.name = node.ip.addr;
                }

                res = true;
            }

            return res;
        });

        return config;
    };

    return Cluster;
}]);
