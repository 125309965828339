/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class SparklineChartListController {
    constructor($element, $scope, Axis, Chart, d3, chartUtils) {
        this.$element = $element;
        this.$scope = $scope;
        this.Axis = Axis;
        this.Chart = Chart;
        this.d3 = d3;
        this.chartUtils = chartUtils;

        /** @type {?Metric[]} */
        this.metrics = null;

        /** @type {?ChartSync} */
        this.sync = null;

        /** @type {Axis} */
        this.topAxis = null;
        /** @type {Axis} */
        this.bottomAxis = null;
        /**
         * D3 Scale function
         * @type {Function}
         */
        this.xScale = null;

        /** @type {ng.element} */
        this.topAxisContainer = null;
        /** @type {ng.element} */
        this.bottomAxisContainer = null;

        this.updateScale = this.updateScale.bind(this);
    }

    $onInit() {
        const { Axis, Chart, sync } = this;
        const el = this.$element;

        this.topAxisContainer = el.find('.sparkline-list-axis .axis-container.top');
        this.bottomAxisContainer = el.find('.sparkline-list-axis .axis-container.bottom');

        const width = this.getWidth();

        this.xScale = Chart.createScale(Chart.SCALE_TIME, 0, width - 1);

        this.topAxisContainer.attr('width', width);
        this.bottomAxisContainer.attr('width', width);

        this.topAxis = new Axis(this.xScale, Axis.TOP);
        this.topAxis.setTicks(4);
        this.bottomAxis = new Axis(this.xScale, Axis.BOTTOM);
        this.bottomAxis.setTicks(4);

        if (sync) {
            sync.add(this.topAxis);
            sync.add(this.bottomAxis);
        }

        this.topAxis.render(this.topAxisContainer.get(0));
        this.bottomAxis.render(this.bottomAxisContainer.get(0));

        this.metricsChange(this.metrics);
        this.$scope.$watch(() => this.metrics, this.metricsChange.bind(this));

        this.$scope.$on('$repaintViewport', this.resize.bind(this));
    }

    /**
     * Handles Metric instances array change.
     * @param {?Metric[]} newMetrics
     * @param {?Metric[]=} oldMetrics
     */
    metricsChange(newMetrics, oldMetrics) {
        if (angular.isArray(newMetrics) && newMetrics !== oldMetrics) {
            newMetrics.sort((m0, m1) => {
                const [s0] = m0.getSeries();
                const [s1] = m1.getSeries();

                return s0.dominators.length > s1.dominators.length ? -1 : 1;
            });
            newMetrics.forEach(metric => {
                metric.on('seriesUpdate', this.updateScale);
                metric.on('seriesListUpdate', this.updateScale);
            });
            this.updateScale();
        }
    }

    getWidth() {
        return this.$element.width();
    }

    resize() {
        const width = this.getWidth();

        this.xScale.range([0, width - 1]);
        this.topAxis.updateScale(this.xScale);
        this.bottomAxis.updateScale(this.xScale);
        this.topAxisContainer.attr('width', width);
        this.bottomAxisContainer.attr('width', width);
    }

    /**
     * Updates axis based on first chart in chart list.
     */
    updateScale() {
        const { metrics } = this;

        if (Array.isArray(metrics)) {
            let data = [];

            // get series from all metrics to calculate max and min X-value
            metrics.forEach(metric => {
                const [s0] = metric.getSeries();

                data = data.concat(s0.values);
            });
            this.xScale.domain(this.d3.extent(data, d => d.timestamp));
            this.resize();
        }
    }

    /**
     * Indicates if chart list is not empty.
     * @return {boolean}
     */
    hasCharts() {
        if (angular.isArray(this.metrics)) {
            return this.metrics.length > 0;
        }

        return false;
    }
}

SparklineChartListController.$inject = [
    '$element',
    '$scope',
    'Axis',
    'Chart',
    'd3v4',
    'chartUtils',
];

angular.module('aviApp').component('sparklineChartList', {
    bindings: {
        metrics: '<',
        sync: '<?',
    },
    controller: SparklineChartListController,
    templateUrl: 'src/components/common/charts/sparkline/sparkline-list.html',
});
