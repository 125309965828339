/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('SnmpProfileCollection', ['Collection', 'SnmpProfile',
    function(Collection, SnmpProfile) {
        const SnmpProfileCollection = function(oArgs) {
            SnmpProfileCollection.superconstructor.call(this, oArgs);
        };

        avi.inherit(SnmpProfileCollection, Collection);

        SnmpProfileCollection.prototype.objectName_ = 'snmptrapprofile';
        SnmpProfileCollection.prototype.itemClass_ = SnmpProfile;
        SnmpProfileCollection.prototype.windowElement_ = 'adm-snmp-create';

        return SnmpProfileCollection;
    }]);

angular.module('aviApp').factory('SnmpProfile', ['Item', function(Item) {
    const SnmpProfile = function(oArgs) {
        SnmpProfile.superconstructor.call(this, oArgs);
    };

    avi.inherit(SnmpProfile, Item);

    SnmpProfile.prototype.objectName = 'snmptrapprofile';
    SnmpProfile.prototype.windowElement = 'adm-snmp-create';

    SnmpProfile.prototype.beforeEdit = function() {
        const { data } = this;

        if (!data.config.trap_servers) {
            data.config.trap_servers = [{
                ip_addr: { type: 'V4', addr: '' },
                community: '',
            }];
        }
    };

    SnmpProfile.prototype.dataToSave = function() {
        const config = angular.copy(this.getConfig());

        const { trap_servers: trapServers } = config;

        if (Array.isArray(trapServers)) {
            trapServers.forEach(server => {
                switch (server.version) {
                    case 'SNMP_VER2':
                        delete server.user;
                        break;
                    case 'SNMP_VER3':
                        delete server.community;
                        break;
                }
            });
        }

        return config;
    };

    return SnmpProfile;
}]);
