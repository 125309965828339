/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  MetricData
 * @description
 *     An item of a MetricDataCollection, a collection created from a metrics API.
 * @typedef {Object} MetricData
 */
angular.module('aviApp').factory('MetricData', ['Item',
Item => {
    class MetricData extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.id || '';
        }
    }

    return MetricData;
}]);
