/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('AlertConfigCreateController', [
'$scope', 'Regex', 'Schema', 'AlertActionCollection', 'AlertMetricsCollection',
'PoolCollection', 'VirtualServiceCollection', 'ServiceEngineCollection', 'defaultValues',
function($scope, Regex, Schema, AlertActionCollection, AlertMetricsCollection, PoolCollection,
VirtualServiceCollection, ServiceEngineCollection, defaultValues) {
    $scope.Regex = Regex;
    $scope.$parent.modalScope = $scope;//AviModal thing

    const vm = this;

    vm.alertActionCollection = new AlertActionCollection();
    vm.alertMetricsCollection = new AlertMetricsCollection();
    vm.staticAlertMetricsCollection = new AlertMetricsCollection();
    vm.poolCollection = new PoolCollection({ isStatic: true });
    vm.vsCollection = new VirtualServiceCollection({ isStatic: true });
    vm.seCollection = new ServiceEngineCollection({ isStatic: true });

    vm.staticAlertMetricsCollection.load();

    $scope.init = function() {
        const { config } = $scope.editable.data;

        vm.events = getEvents();

        vm.alertMetricsCollection.setParams({
            entity_type: angular.isUndefined(config.object_type) ?
                undefined : config.object_type.toLowerCase(),
        });

        vm.ui = {
            errors: null,
        };
    };

    /**
     * Gets list of events from Schema.
     * @return {string[]} - List of events.
     */
    function getEvents() {
        return _.reduce(Schema.enums.EventId.values, function(base, event, key) {
            if (!(event.options.event_internal && event.options.event_internal.value)) {
                base.push(key);
            }

            return base;
        }, []).sort();
    }

    /**
     * Handles Metric Occurs dropdown change.
     * @param  {Object} metricRule
     */
    vm.metricRuleChange = function(metricRule) {
        if (metricRule && typeof metricRule.metric_id === 'string') {
            metricRule.metric_id = metricRule.metric_id.slug();
        }

        checkDuplicateMetrics();
    };

    /**
     * Checks for duplicate metric_ids.
     */
    function checkDuplicateMetrics() {
        const metrics = $scope.editable.data.config.alert_rule.metrics_rule;
        const metricHash = {};

        const hasDuplicates = _.any(metrics, function(metric) {
            const check = metric.metric_id in metricHash;

            metricHash[metric.metric_id] = true;

            return check;
        });

        vm.ui.errors = hasDuplicates ? 'Metrics selected must be unique.' : null;
    }

    /**
     * Called when user clicks AutoScale Alert checkbox. Sets obj_ref to undefined.
     */
    vm.onAutoscaleAlertChange = function() {
        if ($scope.editable.data.config.autoscale_alert) {
            $scope.editable.data.config.obj_ref = undefined;
        }
    };

    /**
     * Called when user changes between object types. Sets params on alertMetricsCollection.
     */
    vm.onObjectTypeChange = function() {
        const { config } = $scope.editable.data;

        config.obj_ref = undefined;
        config.alert_rule.metrics_rule = [
            defaultValues.getDefaultItemConfigByType('alertrulemetric'),
        ];

        vm.alertMetricsCollection.setParams({
            entity_type: angular.isUndefined(config.object_type) ?
                undefined : config.object_type.toLowerCase(),
        });
    };

    /**
     * Adds default alertrulemetric object to metrics_rule list.
     */
    vm.addEmptyAlertMetric = function() {
        const rule = $scope.editable.data.config.alert_rule;

        rule.metrics_rule = rule.metrics_rule || [];
        rule.metrics_rule.push(defaultValues.getDefaultItemConfigByType('alertrulemetric'));
    };

    /**
     * Adds default alertruleevent object to sys_event_rule list.
     */
    vm.addEmptyEventRule = function() {
        const rule = $scope.editable.data.config.alert_rule;

        rule.sys_event_rule = rule.sys_event_rule || [];
        rule.sys_event_rule.push(defaultValues.getDefaultItemConfigByType('alertruleevent'));

        checkEventRules();
    };

    /**
     * Removes event rule and sets the operator to 'OPERATOR_AND' if set to 'OPERATOR_NOT'.
     * @param  {number} index - Index of event rule.
     */
    vm.removeEventRule = function(index) {
        const rule = $scope.editable.data.config.alert_rule;

        rule.sys_event_rule.splice(index, 1);
        rule.operator = rule.operator === 'OPERATOR_NOT' ? 'OPERATOR_AND' : rule.operator;
    };

    /**
     * Called when source changes from Event to Metrics. Metrics does not allow 'none' to be
     * selected so we need to make sure it gets changed to 'virtualservice'.
     */
    vm.changeSource = function() {
        const { config } = $scope.editable.data;

        if (config.source === 'METRICS' && angular.isUndefined(config.object_type)) {
            config.object_type = 'VIRTUALSERVICE';
        }
    };

    /**
     * Returns metric unit based on its id.
     * @param {string} metricId - Metric id to find metric unit.
     * @returns {string} Metric unit.
     */
    vm.getMetricUnitById = function(metricId) {
        const item = vm.staticAlertMetricsCollection.itemById[metricId];

        if (item) {
            return item.data.config.metric_units.toLowerCase();
        }

        return 'units';
    };

    /**
     * Check to make sure operator can be 'NOT'. If more than 2 events, 'NOT' is not allowed.
     */
    function checkEventRules() {
        const rule = $scope.editable.data.config.alert_rule;

        if (rule.sys_event_rule.length > 2 && rule.operator === 'OPERATOR_NOT') {
            rule.operator = 'OPERATOR_AND';
        }
    }

    $scope.$on('$destroy', function() {
        vm.alertActionCollection.destroy();
        vm.alertMetricsCollection.destroy();
        vm.staticAlertMetricsCollection.destroy();
        vm.poolCollection.destroy();
        vm.vsCollection.destroy();
        vm.seCollection.destroy();
    });
}]);
