/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import './system-update-modal.component.less';
import {
    UpgradeService,
    upgradeTypeHash,
    rollbackTypeHash,
} from 'services/upgrade/upgrade.service';

const UPGRADE_PHASE_MUST_CHECKS = 'must_checks';
const UPGRADE_PHASE_ENFORCE_UPGRADE = 'enforce_upgrade';

const {
    UPGRADE_TYPE_SYSTEM_UPGRADE,
    UPGRADE_TYPE_SYSTEM_ROLLBACK,
} = upgradeTypeHash;

const {
    ROLLBACK_TYPE_IMAGE,
    ROLLBACK_TYPE_PATCH,
} = rollbackTypeHash;

/**
 * Object holding message info returned from upgrade/rollback mustchecks.
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name UpgradeMustCheckMessage
 * @property {boolean} isError - True if it's an error message, false if warning message.
 * @property {string} detail - Detail of what the message is about.
 */

/**
 * @constructor
 * @memberOf module:avi/upgrade
 */
class SystemUpdateModalController {
    constructor($q, $scope, upgradeService, schemaService) {
        this.$q_ = $q;
        this.$scope_ = $scope;
        this.upgradeService_ = upgradeService;

        this.busy = false;
        this.error = '';

        /**
         * Step number of the modal. Starts with 0.
         * 0 - Preview check list + controller / SEG & controller view.
         * 1 - View with spinner and pre-upgrade/rollback must checks running.
         * 2 - View with must checks completed and check result warning list displayed.
         * @type {number}
        */
        this.step = 0;

        this.upgradeType = '';
        this.targetVersion = '';
        this.applyToSystem = false;
        this.actionOnSegFailure = '';

        this.controllerOnlyView = false;

        /**
         * True when upgrade/rollback must-check returns errors.
         * @type {boolean}
         */
        this.hasMustCheckFailed = false;

        /**
         * List of check points coming from backend for users to confirm before continuing with must
         * checks.
         * @type {string[]}
         */
        this.previewCheckList = [];

        /**
         * List of message objects generated from upgrade/rollback must-check returned info.
         * @type {UpgradeMustCheckMessage[]}
         */
        this.mustCheckMsgObjList = [];

        this.segErrorRecoveryEnumObjectList = schemaService.getEnumValues('SeGroupErrorRecovery');
    }

    /** @override */
    $onInit() {
        this.upgradeType = this.upgradeConfig.upgradeType;

        /**
         * Target version to upgrade/rollback to.
         * @type {string}
         */
        this.targetVersion = this.upgradeConfig.targetVersion;

        /**
         * True when applying upgrade/rollback to controller and ALL service engine groups.
         * False when only upgrading/rollback controller.
         * @type {boolean}
         */
        this.applyToSystem = true;

        if (this.isType(UPGRADE_TYPE_SYSTEM_UPGRADE)) {
            /**
             * Action to be taken when SEG upgrade hits error.
             * @type {string}
             */
            this.actionOnSegFailure = 'SUSPEND_UPGRADE_OPS_ON_ERROR';
        }

        /**
         * Indicate whether to show the operation options for SE Groups.
         * False to show controller info only. True to show both controller and SEGs info.
         * @type {boolean}
         */
        this.controllerOnlyView = !this.isSegUpgradeAvailable_();

        this.populatePreviewCheckList_();
    }

    /**
     * Format upgrade error objects to one plain string.
     * @param {Object} - errorObjects
     * @return {string}
     * @protected
     */
    static stringifyUpgradeErrors_(errorObjects) {
        const errorStrList = errorObjects.map(
            ({ status_code: statusCode, details }) => `${statusCode}: ${details.join('; ')}`,
        );

        return errorStrList.join(' ');
    }

    /**
     * Generate modal title based on upgrade/rollback type.
     * @return {string}
     */
    getModalTitle() {
        if (this.isType(UPGRADE_TYPE_SYSTEM_UPGRADE)) {
            return `${this.targetVersion} Upgrade`;
        }

        if (this.isType(UPGRADE_TYPE_SYSTEM_ROLLBACK)) {
            const { rollbackType } = this.upgradeConfig;

            switch (rollbackType) {
                case ROLLBACK_TYPE_IMAGE:
                    return `Rollback to Version ${this.targetVersion}`;

                case ROLLBACK_TYPE_PATCH:
                    return `Clear ${this.targetVersion} Patch`;
            }
        }
    }

    /**
     * Decide upgrade type(upgrade/rollback).
     * @param {string} type - UPGRADE_TYPE_SYSTEM_UPGRADE | UPGRADE_TYPE_SYSTEM_ROLLBACK
     * @return {boolean}
     */
    isType(type) {
        return this.upgradeType === type;
    }

    /**
     * Called when Continue button is clicked.
     * @param {number} step - Step number.
     * @protected
     */
    goToStep_(step) {
        this.step = step;
    }

    /**
     * Decide current page step.
     * @param {number} step - Step number.
     * @return {boolean}
     */
    isStep(step) {
        return this.step === step;
    }

    /**
     * Get check list from upgrade/rollback previews.
     * @protected
     */
    populatePreviewCheckList_() {
        this.busy = true;
        this.error = '';

        this.upgradeService_.getPreUpgradePrompts(this.upgradeConfig)
            .then(({ data: { checks } }) => this.previewCheckList = checks)
            .catch(({ data: { error } }) => {
                this.error = error;
            })
            .finally(() => this.busy = false);
    }

    /**
     * Populate must check message object list, including both warnings and errors.
     * @param {UpgradeMustCheckMessage[]} mustCheckInfoObjects
     * @protected
     */
    populateMustCheckMsgObjList_(mustCheckInfoObjects) {
        this.mustCheckMsgObjList = [];

        mustCheckInfoObjects.forEach(({ maskable, details }) => {
            details.forEach(text => {
                this.mustCheckMsgObjList.push(
                    {
                        isError: !maskable,
                        text,
                    },
                );
            });
        });
    }

    /**
     * When button is Continue(step 0), trigger upgrade/rollback must-check with warnings returned.
     * When button is Confirm(step 2), trigger upgrade/rollback ignoring the must-check warnings.
     */
    onSubmitButtonClick() {
        this.error = '';
        this.busy = false;

        if (this.isStep(0)) { // page is under step 0
            // this call will direct page to step 1: spinner
            this.triggerUpgradeProcedure_(UPGRADE_PHASE_MUST_CHECKS)
                .catch(({ data: { error } }) => {
                    const {
                        errors: msgList,
                        warning: warningOnly,
                    } = error;

                    this.populateMustCheckMsgObjList_(msgList);

                    // there're errors returned by the must-check
                    this.hasMustCheckFailed = !warningOnly;

                    this.goToStep_(2);
                })
                .finally(() => this.busy = false);
        } else { // page is under step 2
            // this call will direct page to step 1: spinner
            this.triggerUpgradeProcedure_(UPGRADE_PHASE_ENFORCE_UPGRADE)
                .then(this.handleSuccessfulUpgradeTrigger_)
                .catch(({ data: { error: { errors } } }) => {
                    this.goToStep_(2);
                    this.error = SystemUpdateModalController.stringifyUpgradeErrors_(errors);
                }).finally(() => this.busy = false);
        }
    }

    /**
     * Handles conditional upon successful trigger of new upgrade event.
     * @fires module:avi/upgrade.UpgradeService#UPGRADE_TRIGGER_EVENT
     * @protected
     */
    handleSuccessfulUpgradeTrigger_ = () => {
        this.closeModal();
        this.$scope_.$broadcast(this.UpgradeService_.UPGRADE_TRIGGER_EVENT);
    };

    /**
     * Trigger upgrade/rollback by phase.
     * @param {string} phase - UPGRADE_PHASE_MUST_CHECKS | UPGRADE_PHASE_ENFORCE_UPGRADE
     *     a) UPGRADE_PHASE_MUST_CHECKS: Trigger pre-upgrade must checks without starting the real
     *        upgrade/rollback by setting skipWarnings to false. Promise fails anyway with warnings
     *        returned in error.
     *     b) UPGRADE_PHASE_ENFORCE_UPGRADE: Enforce upgrade/rollback process by ignoring warnings
     *        from must-checks by setting skipWarnings to true.
     * @return {ng.$q.promise}
     * @protected
     */
    triggerUpgradeProcedure_(phase) {
        let skipWarnings;

        switch (phase) {
            case UPGRADE_PHASE_MUST_CHECKS:
                skipWarnings = false;
                break;

            case UPGRADE_PHASE_ENFORCE_UPGRADE:
                skipWarnings = true;
                break;
        }

        this.busy = true;
        // go to waiting view with spinner
        this.goToStep_(1);

        switch (this.upgradeType) {
            case UPGRADE_TYPE_SYSTEM_UPGRADE:
                return this.upgradeService_.startSystemUpgrade(
                    this.upgradeConfig,
                    skipWarnings,
                    this.applyToSystem,
                    this.actionOnSegFailure,
                );

            case UPGRADE_TYPE_SYSTEM_ROLLBACK:
                return this.upgradeService_.startSystemRollback(
                    this.upgradeConfig,
                    skipWarnings,
                    this.applyToSystem,
                );
        }
    }

    /**
     * Check is SE Group upgrade/rollback feasible.
     * @return {boolean}
     * @protected
     */
    isSegUpgradeAvailable_() {
        return UpgradeService.isSegUpgradeAvailable(this.upgradeConfig);
    }
}

SystemUpdateModalController.$inject = [
    '$q',
    '$scope',
    'upgradeService',
    'schemaService',
];

/**
 * @mixin systemUpdateModalBindings
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.SystemUpgradeConfig} upgradeConfig - Hash containing config of both
 *     system upgrade and rollback.
 * @property {Function} closeModal - Method fired upon user selecting to close modal.
 */

/**
 * @name SystemUpdateModalComponent
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.systemUpdateModalBindings} bindings
 * @property {module:avi/upgrade.SystemUpdateModalController} controller
 * @description
 *
 *     Component for system update page modal. Takes uuids for controller/patch image files and
 *     triggers upgrade procedure by using upgrade service.
 *
 * @author Zhiqian Liu
 */
angular.module('avi/upgrade').component('systemUpdateModal', {
    bindings: {
        upgradeConfig: '<',
        closeModal: '&',
    },
    controller: SystemUpdateModalController,
    templateUrl: 'src/components/pages/administration/controller/system-update/' +
        'system-update-modal/system-update-modal.component.html',
});
