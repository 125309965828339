/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @constant
 * @name itemAlertDataTransform
 * @param {{string: number}} alertsHash - Level to number of alerts hash.
 * @description
 *
 *     Sets highestAlertLevel property on alertsHash with the highest severity level of alerts
 *     present.
 */
const alertLevels = ['high', 'medium', 'low'];

function setHighestAlertLevel(alertsHash) {
    let highestAlertLevel = '';

    _.find(alertLevels, level => {
        if (alertsHash[level]) {
            highestAlertLevel = level;

            return true;
        }
    });

    alertsHash.highestAlertLevel = highestAlertLevel;
}

setHighestAlertLevel.alertLevels = alertLevels;

angular.module('aviApp').constant('itemAlertDataTransform', setHighestAlertLevel);
