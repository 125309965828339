/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const componentName = 'protocol-parser-scripts-list';

class ProtocolParserScriptsListController {
    constructor($element, ProtocolParserCollection, CRUDGridConfig) {
        this.$element_ = $element;
        this.ProtocolParserCollection_ = ProtocolParserCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.ProtocolParserCollection_(),
            fields: [{
                name: 'name',
                title: 'Name',
                template: '{{row.getName()}}',
                sortBy: 'name',
            }],
            permission: 'PERMISSION_PROTOCOLPARSER',
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ProtocolParserScriptsListController.$inject = [
    '$element',
    'ProtocolParserCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name ProtocolParserScriptsList
 * @author Aravindh Nagarajan
 * @description Protocol Parser Scripts list page with options to create/delete/edit.
 */
angular.module('aviApp').component('protocolParserScriptsList', {
    controller: ProtocolParserScriptsListController,
    template:
        '<div class="templatesList"><collection-grid config="$ctrl.gridConfig" /></div>',
});
