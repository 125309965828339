/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './tech-support.component.less';
import { portalStatus } from '../../../js/constants/avi-cloud.constants';

const componentName = 'tech-support';

/**
 * @alias module:component/techSupport
 * @private
 */
class TechSupportController {
    constructor(
        $interval,
        $window,
        AviModal,
        CRUDGridConfig,
        TechSupportCollection,
        TechSupportCapture,
        AviCloudService,
    ) {
        this.$interval = $interval;
        this.$window = $window;
        this.AviModal = AviModal;
        this.CRUDGridConfig = CRUDGridConfig;
        this.TechSupportCollection = TechSupportCollection;
        this.TechSupportCapture = TechSupportCapture;
        this.aviCloudService_ = AviCloudService;

        /**
         * Avi cloud connected status.
         * @type {boolean}
         */
        this.aviCloudConnected = false;

        /**
         * Controller registration status.
         * @type {boolean}
         */
        this.controllerRegistered = false;

        /**
         * Capture status vals to be used to determine view.
         * @type {{statusPending: boolean, generationApiFailed: boolean, showLastTried: boolean}}
         */
        this.generationStatus = {
            showLastTried: false,
            statusPending: true,
            generationApiFailed: false,
        };
    }

    /** @override */
    $onInit() {
        this.initTechSupportCapture_();
        this.setRegistrationStatusAndInitTechSupportCollectionGrid_();
    }

    /**
     * Get registration status of controller to show or hide Suport Case column in
     * tech support collection grid.
     * @protected
     */
    setRegistrationStatusAndInitTechSupportCollectionGrid_() {
        const aviCloudStatus = this.systemInfoService_.getAviCloudStatus();

        this.updateStatus_(aviCloudStatus);
        this.initTechSupportCollectionGridConfig_();
    }

    /**
     * Update's Controller registration and Avi cloud connectivity status
     * @param {Object} data - Response object of registration status.
     * @protected
     */
    updateStatus_(data) {
        const { AVICLOUD_REGISTERED, AVICLOUD_CONNECTED } = portalStatus;

        this.controllerRegistered =
            data.registration_status === AVICLOUD_REGISTERED;
        this.aviCloudConnected =
            data.connectivity_status === AVICLOUD_CONNECTED;
    }

    /**
     * Creates instance of last tech support generation status data object.
     * @protected
     */
    initTechSupportCapture_() {
        this.techSupportCapture = new this.TechSupportCapture();

        this.techSupportCapture.on(
            this.TechSupportCapture.HANDLE_CAPTURE_STATUS_UPDATE_EVENT,
            this.handleCaptureStatusUpdate_,
        );
    }

    /**
      * Handles any successful response from techSupportCapture's update.
      * @protected
     */
    handleCaptureStatusUpdate_ = () => {
        const
            { generationStatus, techSupportCapture } = this,
            { generationApiFailed, showLastTried } = generationStatus;

        const
            inProgress =
                techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'inProgress',
            wasSuccessful =
                techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'success';

        if (inProgress || generationApiFailed) {
            generationStatus.showLastTried = true;
        } else if (wasSuccessful && showLastTried) {
            this.techSupportCollection.load();
        }

        generationStatus.statusPending = false;
    };

    /**
     * Initializes collection and creates config for tech support table.
     * @protected
     */
    initTechSupportCollectionGridConfig_() {
        this.techSupportCollection = new this.TechSupportCollection();

        const fields = [{
            title: 'Creation Date',
            name: 'modified',
            template: '{{ row.getConfig().created }}',
        }, {
            title: 'Type',
            name: 'type',
            template: '{{ row.getConfig().type || "-" }}',
        }, {
            title: 'Resource Name',
            name: 'resourceName',
            template: '{{ row.getConfig().resourceName || "-" }}',
        }, {
            title: 'Duration',
            name: 'duration',
            template: '{{ row.getConfig().duration || "-"}}',
        }, {
            title: 'Size',
            name: 'size',
            template: '{{ row.getConfig().size }}',
        }, {
            title: 'Results',
            name: 'results',
            template: require('./tech-support-results.partial.html'),
        }];

        if (this.aviCloudConnected && this.controllerRegistered) {
            fields.push({
                title: 'Support Case',
                name: 'supportCase',
                transform: row => {
                    let caseDetail = '';
                    const { caseDetails } = row.getConfig();

                    if (caseDetails && 'case_id' in caseDetails) {
                        caseDetail = caseDetails.case_number;
                    }

                    return caseDetail;
                },
            });
        }

        const singleactions = [{
            title: 'Delete',
            class: 'icon icon-trash',
            do: row => row.drop(),
        }, {
            title: 'Download',
            class: 'icon-download-1',
            //Following only true for read/write, page is inaccessible for noaccess anyway
            bypassPermissionsCheck: true,
            do: row => this.$window.location.assign(row.getConfig().url),
        }];

        const config = {
            collection: this.techSupportCollection,
            expandedContainerTemplate: require('./tech-support-expander.partial.html'),
            fields,
            layout: {
                hideEditColumns: true,
            },
            singleactions,
            permission: 'PERMISSION_TECHSUPPORT',
        };

        this.techSupportGridConfig = this.CRUDGridConfig(config);
    }

    /**
     * Triggered upon user selection of Tech Support type/etc in modal.
     * @param {TechSupportApiStatus.level} type - type of tech support desired, e.g. VS,SE, etc
     * @param {string=} id - UUID for instance of type vs, se, pool, or gslb
     * @param {string=} caseNumber - case number which we are going to attach the tech support
     */
    triggerTechSupportGeneration(type, id, caseNumber) {
        this.generationStatus.statusPending = true;
        this.generationStatus.generationApiFailed = false;

        this.TechSupportCapture.generateNewTechSupport(type, id, caseNumber)
            .catch(this.handleGenerateApiError_)
            .finally(this.handleGenerateApiResponse_);
    }

    /**
     * Opens techSupportTypeSelection component for type selection before generation.
     */
    openGenerateTypeSelection() {
        this.AviModal.open(
            'tech-support-generation-modal',
            {
                // Uses $injector.annotate, to make args works, same as Angular does generally
                onGenerate: [
                    'type',
                    'id',
                    'caseNumber',
                    this.triggerTechSupportGeneration.bind(this),
                ],
                controllerRegistered: this.controllerRegistered,
                aviCloudConnected: this.aviCloudConnected,
            },
        );
    }

    /**
     * @param {string} err
     * Note: This shouldn't trigger because button is disabled upon clicking first time,
     * but in case generation can't be triggered even on first attempt.
     * @protected
     */
    handleGenerateApiError_ = err => {
        this.generationStatus.generationApiFailed = true;
        console.error('generation error: ', err);
    };

    /**
     * Handles common operations needed for either success or failure.
     * @protected
     */
    handleGenerateApiResponse_ = () => {
        this.techSupportCapture.load();
    };

    /**
     * Handles click of closing error or success message for tech support status section.
     */
    onClickTechSupportStatusClose() {
        this.generationStatus.showLastTried = false;
    }

    /**
     * Shows generate button in tech support status section.
     * @return {boolean}
     */
    generationAllowed() {
        return !this.lastGenerationSuccessful() &&
            !this.lastGenerationFailed() &&
            !this.isInProgress();
    }

    /**
     * Shows in progress dialog in tech support status section.
     * @return {boolean}
     */
    isInProgress() {
        return this.techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'inProgress';
    }

    /**
     * Shows successful completion dialog in tech support status section.
     * @return {boolean}
     */
    lastGenerationSuccessful() {
        const { techSupportCapture, generationStatus } = this;

        return generationStatus.showLastTried &&
            techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'success';
    }

    /**
     * Shows error state termination dialog in tech support status section.
     * @return {boolean}
     */
    lastGenerationFailed() {
        const { techSupportCapture, generationStatus } = this;

        return generationStatus.showLastTried &&
            techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'error';
    }

    $onDestroy() {
        if (this.techSupportCollection) {
            this.techSupportCollection.destroy();
        }

        if (this.techSupportCapture) {
            this.techSupportCapture.destroy();
        }
    }
}

TechSupportController.$inject = [
    '$interval',
    '$window',
    'AviModal',
    'CRUDGridConfig',
    'TechSupportCollection',
    'TechSupportCapture',
    'AviCloudService',
];

/**
 * @ngdoc component
 * @name techSupport
 * @module component/techSupport
 * @property {systemInfoService} systemInfoService_ System information
 * @author Akul Aggarwal
 * @description
 *
 *     Component to generate new tech support items and show/download existing ones.
 *
 */
angular.module('aviApp').component('techSupport', {
    controller: TechSupportController,
    bindings: {
        systemInfoService_: '<loadedSystemInfoService',
    },
    templateUrl: `src/components/administration/${componentName}/${componentName}.component.html`,
});
