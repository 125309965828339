/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name DNSQueryListDataTransformer
 */
angular.module('aviApp').factory('DNSQueryListDataTransformer', ['ListDataTransformer',
ListDataTransformer => {
    return class DNSQueryListDataTransformer extends ListDataTransformer {
        /**
         * obj_id property has the format fqdn-<Source>:<FQDN>#<Tenant>:<Service>.
         * Example: fqdn-gs:cloud8.avi.com#admin:gs-4 or fqdn-static:xyz.avi.com#admin
         * This is used to retrieve individual segments from the obj_id.
         * @param {string} id - obj_id.
         * @return {Object} Object containing name(FQDN), source, tenant, and service properties.
         * @static
         */
        static parseObjId(id) {
            const
                output = {},
                sourceDict = {
                    gs: 'GSLB Service',
                    vs: 'Virtual Service',
                    static: 'Static',
                };

            const
                hashSplit = id.split('#'),
                prefixSplit = hashSplit[0].split(':'),
                suffixSplit = hashSplit[1].split(':');

            output.name = prefixSplit[1];
            output.source = sourceDict[prefixSplit[0].split('-')[1]];
            [output.tenant, output.service] = suffixSplit;

            return output;
        }

        /** @override */
        processResponse(rsp, req) {
            rsp = this.responseListOffsetControl_(rsp, req);

            let results = [];

            if (rsp.data && 'series' in rsp.data && !_.isEmpty(rsp.data.series)) {
                const objIdHash = rsp.data.series.reduce((acc, series) => {
                    const
                        { name, obj_id } = series.header,
                        prefix = obj_id.substring(0, 4);

                    if (prefix !== 'tcp:' && prefix !== 'udp:') {
                        acc[obj_id] = acc[obj_id] || {};
                        acc[obj_id][name] = series;
                    }

                    return acc;
                }, {});

                results = _.map(objIdHash, (metricIdHash, objId) => {
                    const parsedObjId = DNSQueryListDataTransformer.parseObjId(objId);

                    return angular.extend({
                        id: objId,
                        metrics: metricIdHash,
                    }, parsedObjId);
                });
            }

            rsp.data.results = results;

            return rsp;
        }
    };
}]);
