/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPsmLocationConfigItemFactory = (MessageItem, defaultValues) => {
    /**
     * @alias module:config-item/WafPsmLocationConfigItem
     * @private
     */
    class WafPSMLocationConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPSMLocation',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * We want to remove 'match' from the default configuration so that the user can add matches
         * manually.
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType_) || {};
            const { match, ...defaults } = defaultConfig;

            return {
                ...defaults,
                match: null,
            };
        }

        /**
         * @override
         */
        modifyConfigDataAfterLoad_() {
            if (_.isUndefined(this.config['match'])) {
                this.setNewChildByField_('match');
            }
        }

        /**
         * Returns the name of the location.
         * @returns {string}
         */
        get name() {
            return this.getName();
        }

        /**
         * Returns the number of matches configured.
         * @returns {number}
         */
        get matchCount() {
            return this.config.match.matchCount;
        }

        /**
         * Returns the rules RepeatedMessageItem.
         * @returns {RepeatedMessageItem}
         */
        get rules() {
            return this.config.rules;
        }

        /**
         * Returns the number of rules in the config.
         * @returns {number}
         */
        get rulesCount() {
            return this.config.rules.count;
        }

        /**
         * Returns the parent PSM Group's id.
         * @returns {WafPolicyPsmGroup.id}
         */
        get psmGroupId() {
            return this.parent_.id;
        }

        /**
         * Returns a new WafPolicyWhitelistRule ConfigItem instance.
         * @param {Object} [config=null] - Config object.
         * @returns {WafPSMRule}
         */
        createNewRule(config = null) {
            return this.createChildByField_('rules', config, true);
        }

        /**
         * Adds a rule to the list of rules. If the rule has an index, it means that an existing
         * rule was modified so the existing rule should be replaced. If not, add the new rule to
         * the end of the list.
         * @param {WafPSMRule} rule - Rule to add.
         */
        addRule(rule) {
            const { rules } = this.config;
            const indexField = rule.getIndex();

            // New rule doesn't have an index.
            if (_.isUndefined(indexField)) {
                const maxIndex = rules.getMaxIndex();
                const newIndex = _.isNaN(+maxIndex) ? 0 : maxIndex + 1;

                rule.setIndex(newIndex);
                rules.add(rule);
            } else {
                const arrayIndex = rules.getArrayIndexWithIndexField(indexField);

                rules.config[arrayIndex] = rule;
            }
        }
    }

    return WafPSMLocationConfigItem;
};

wafPsmLocationConfigItemFactory.$inject = [
    'MessageItem',
    'defaultValues',
];

/**
 * @ngdoc factory
 * @name  WafPSMLocationConfigItem
 * @description  WafPsmLocation ConfigItem class.
 * @module config-item/WafPSMLocationConfigItem
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPSMLocationConfigItem', wafPsmLocationConfigItemFactory);
