/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function vmNicIPDataTransformerFactory(
    ListDataTransformer,
) {
    /**
     * @class
     * @name VMNicIPDataTransformer
     * @extends ListDataTransformer
     */
    class VMNicIPDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, requestParams) {
            resp = this.responseListOffsetControl_(resp, requestParams);

            const { data } = resp;

            if (Array.isArray(data.results)) {
                data.results = data.results
                    .map(vm => ({ config: VMNicIPDataTransformer.getIpConfig(vm) }));
            } else {
                data.results = [];
                data.count = 0;
            }

            return resp;
        }

        /**
         * Sets the objectName and removes cloud_uuid from request params.
         * @override
         */
        getRequestConfig(requestParams) {
            const params = super.getRequestConfig(requestParams);
            const { cloud_uuid: cloudId } = params;

            params.objectName_ = ['cloud', cloudId, 'serversbynetwork'];

            delete params.cloud_uuid;

            return params;
        }

        /**
         * Transforms VM object with NICs and IPs into IP address object.
         * @param {Object} vm
         * @return {Object}
         */
        static getIpConfig(vm) {
            const {
                guest_nic: nics,
                uuid: vmId,
                url: vmUrl,
                name: vmHostName,
            } = vm;

            // this is a list but we expect to get only one record due to
            // https://github.com/avinetworks/avi-dev/pull/49704
            const [nic] = nics;

            const {
                guest_ip: ips,
                network_uuid: netId,
                mac_addr: macAddr,
            } = nic;

            // this is a list but we expect to get only one record due to
            // https://github.com/avinetworks/avi-dev/pull/49704
            const [ip] = ips;

            return {
                uuid: vmId,
                vm_ref: vmUrl,
                nw_ref: netId,
                hostname: vmHostName,
                mac: macAddr,
                ip: ip.prefix.ip_addr,
                enabled: true,
            };
        }
    }

    return VMNicIPDataTransformer;
}

vmNicIPDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

/**
 * @ngdoc service
 * @name VMNicIPDataTransformer
 * @author Alex Malitskiy, chitra
 * @description DataTranformer for VMNicIP.
 */
angular.module('aviApp')
    .factory('VMNicIPDataTransformer', vmNicIPDataTransformerFactory);
