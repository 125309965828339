/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const ItemFactory = Item => {
    class CloudConnectorUser extends Item {
        /**
         * Returns true if Azure credentials have been configured.
         * @return {boolean}
         */
        hasAzureCredentials() {
            const config = this.getConfig();

            return this.usesAzureUserpass() || !_.isEmpty(config.azure_serviceprincipal) &&
                    config.azure_serviceprincipal.application_id;
        }

        /**
         * Returns true if GCP credentials have been configured
         */
        hasGCPCredentials() {
            const config = this.getConfig();

            return !_.isEmpty(config.gcp_credentials) && config.public_key;
        }

        /**
         * Returns true if Azure username/password (azure_userpass) is configured as opposed to
         * Application ID (azure_serviceprincipal).
         * @return {boolean}
         */
        usesAzureUserpass() {
            const { azure_userpass: userpass } = this.getConfig();

            return !_.isEmpty(userpass) && userpass.username;
        }

        /**
         * Clears Azure credentials.
         */
        clearAzureCredentials() {
            const config = this.getConfig();

            delete config.azure_userpass;
            delete config.azure_serviceprincipal;
        }

        /**
         * Clears SSH/GCP credentials.
         */
        clearKeys() {
            const config = this.getConfig();

            delete config.private_key;
            delete config.passphrase;
            delete config.public_key;
        }

        /**
         * Generates SSH Key Value pair.
         * @return {ng.$q.promise}
         */
        generateKey() {
            const config = this.getConfig();

            delete config.public_key;
            delete config.private_key;

            return this.save();
        }
    }

    angular.extend(CloudConnectorUser.prototype, {
        objectName: 'cloudconnectoruser',
        windowElement: 'user-credentials-modal',
    });

    return CloudConnectorUser;
};

ItemFactory.$inject = [
    'Item',
];

/**
 * @ngdoc factory
 * @name CloudConnectorUser
 * @description CloudConnectorUser item.
 */
angular.module('aviApp').factory('CloudConnectorUser', ItemFactory);
