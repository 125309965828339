/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Injector } from '@angular/core';

/**
 * Used to upgrade providers from AngularJS to be used in Angular.
 * @param serviceName Service to be injected from AngularJS.
 */
export function matchTargetConfigItemFactory(i: Injector) {
    return i.get('MatchTargetConfigItem');
}

export const matchTargetConfigItemProvider = {
    provide: 'MatchTargetConfigItem',
    useFactory: matchTargetConfigItemFactory,
    deps: ['$injector'],
};

export function schemaServiceFactory(i: Injector) {
    return i.get('schemaService');
}

export const schemaServiceProvider = {
    provide: 'schemaService',
    useFactory: schemaServiceFactory,
    deps: ['$injector'],
};

export function aviModalFactory(i: Injector) {
    return i.get('AviModal');
}

export const aviModalProvider = {
    provide: 'AviModal',
    useFactory: aviModalFactory,
    deps: ['$injector'],
};

export function wafPolicyPsmGroupCollectionFactory(i: Injector) {
    return i.get('WafPolicyPsmGroupCollection');
}

export const wafPolicyPsmGroupCollectionProvider = {
    provide: 'WafPolicyPsmGroupCollection',
    useFactory: wafPolicyPsmGroupCollectionFactory,
    deps: ['$injector'],
};

export function wafPolicyPsmGroupMetricFactory(i: Injector) {
    return i.get('WafPolicyPsmGroupMetric');
}

export const wafPolicyPsmGroupMetricProvider = {
    provide: 'WafPolicyPsmGroupMetric',
    useFactory: wafPolicyPsmGroupMetricFactory,
    deps: ['$injector'],
};

export function timeframeFactory(i: Injector) {
    return i.get('Timeframe');
}

export const timeframeProvider = {
    provide: 'Timeframe',
    useFactory: timeframeFactory,
    deps: ['$injector'],
};

export function appStateTreeFactory(i: Injector): any {
    return i.get('appStateTree');
}

export const appStateTreeProvider = {
    deps: ['$injector'],
    provide: 'appStateTree',
    useFactory: appStateTreeFactory,
};

export function authServiceFactory(i: Injector): any {
    return i.get('Auth');
}

export const authServiceProvider = {
    deps: ['$injector'],
    provide: 'authService',
    useFactory: authServiceFactory,
};
