/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
/**
 * @ngdoc service
 * @name SEGroup
 * @desc
 *     SEGroup Item class.
 */
angular.module('aviApp').factory('SEGroup', [
'Item', 'SubnetListNetwork', 'SubnetListNetworkCollection', '$q', 'getSubnetObject',
function(Item, SubnetListNetwork, SubnetListNetworkCollection, $q, getSubnetObject) {
    class SEGroup extends Item {
        constructor(args = {}) {
            super(args);

            /**
             * Management VRF Context object.
             * @type {?Object}
             */
            this.vrfContext = null;

            /**
             * Static route object for this SE Group.
             * @type {?Object}
             */
            this.staticRoute = null;
        }

        /** @override */
        beforeEdit() {
            const
                config = this.getConfig(),
                { cloud_ref: cloudRef } = config;

            const cloudId = cloudRef.slug();

            this.request('GET', `/api/vrfcontext?name=management&cloud_uuid=${cloudId}`)
                .then(({ data }) => {
                    if (data && data.count) {
                        [this.vrfContext] = data.results;
                        this.staticRoute = this.findStaticRoute();
                    }
                });

            config.vcenter_datastores_tmp = _.pluck(config.vcenter_datastores, 'datastore_name');
            config.service_ip_subnets = config.service_ip_subnets || [];

            config.se_scope = 'any';

            if (config.vcenter_clusters && config.vcenter_clusters.cluster_refs) {
                config.se_scope = 'cluster';
            } else if (config.vcenter_hosts && config.vcenter_hosts.host_refs) {
                config.se_scope = 'host';
            } else if (config.openstack_availability_zones) {
                config.se_scope = 'az';
            }

            const {
                openstack_mgmt_network_name: osMgmtNetName,
                openstack_mgmt_network_uuid: osMgmtNetId,
            } = config;

            if (osMgmtNetName) {
                if (osMgmtNetId) {
                    config['openstack_mgmt_network_uuid'] = `/${osMgmtNetId}#${osMgmtNetName}`;
                } else {
                    const osNetworkCollection = new SubnetListNetworkCollection({
                        params: {
                            discovered_only: true,
                            cloud_uuid: cloudId,
                            name: osMgmtNetName,
                        },
                    });

                    osNetworkCollection.load()
                        .then(() => {
                            //if it hasn't been updated by user yet
                            if (!config['openstack_mgmt_network_uuid']) {
                                if (osNetworkCollection.getNumberOfItems()) {
                                    const [network] = osNetworkCollection.items;

                                    config['openstack_mgmt_network_uuid'] = network.getRef();
                                } else {
                                    delete config['openstack_mgmt_network_name'];
                                }
                            }
                        }).catch(() => {
                            console.warn(`Could not load placement network "${osMgmtNetName}"`);
                        }).finally(
                            () => osNetworkCollection.destroy(),
                        );
                }
            }

            config.custom_tag = config.custom_tag || [];

            if (!config.vip_asg) {
                config.vip_asg = {};
            }

            if (!config.vip_asg.configuration) {
                config.vip_asg.configuration = {};
            }

            if (!config.vip_asg.configuration.zones) {
                config.vip_asg.configuration.zones = [];
            }

            if (!config.vip_asg.policy) {
                const { policy } = this.getDefaultConfig().vip_asg;

                config.vip_asg.policy = policy;
            }

            // If data_network_id is set for a SE Group
            // Resolve the network name by load
            const { data_network_id: dataNetworkId } = config;

            if (dataNetworkId) {
                const network = new SubnetListNetwork({
                    id: dataNetworkId,
                    cloudId,
                });

                network.load()
                    .then(() => {
                        config.data_network_id = network.getRef();
                    }).catch(() => {
                        console.warn(`Could not load network with id "${dataNetworkId}"`);
                    }).finally(network.destroy);
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (!angular.isUndefined(config.instance_flavor)) {
                const { instance_flavor: instanceFlavor } = config;

                // we are saving name here rather than id but for SEGroupFlavor instance
                // name is used for both: id and (surprise!) name, hence ref looks like "name#name"
                config.instance_flavor = instanceFlavor.slug();
            }

            if (config.se_bandwidth_type && !this.licenseTypeIsSeBandwidth()) {
                delete config.se_bandwidth_type;
            }

            if (config.custom_tag && !config.custom_tag.length) {
                delete config.custom_tag;
            }

            config['vcenter_datastores'] =
                config.vcenter_datastores_tmp.map(item => ({ datastore_name: item }));

            delete config.vcenter_datastores_tmp;

            switch (config.se_scope) {
                case 'cluster':
                    if (config.vcenter_hosts) {
                        delete config.vcenter_hosts.host_refs;
                        delete config.vcenter_hosts;
                    }

                    break;

                case 'host':
                    if (config.vcenter_clusters) {
                        delete config.vcenter_clusters.cluster_refs;
                        delete config.vcenter_clusters;
                    }

                    break;

                case 'any':
                    if (config.vcenter_clusters) {
                        delete config.vcenter_clusters.cluster_refs;
                        delete config.vcenter_clusters;
                    }

                    if (config.vcenter_hosts) {
                        delete config.vcenter_hosts.host_refs;
                        delete config.vcenter_hosts;
                    }

                    if (config.openstack_availability_zones) {
                        delete config.vcenter_hosts.openstack_availability_zones;
                    }

                    break;
            }

            delete config.se_scope;

            const { openstack_mgmt_network_uuid: osMgmtNetId } = config;

            if (osMgmtNetId) {
                config['openstack_mgmt_network_name'] = osMgmtNetId.name();
                config['openstack_mgmt_network_uuid'] = osMgmtNetId.slug();
            } else {
                delete config['openstack_mgmt_network_name'];
            }

            const { vip_asg: vipAsg } = config;

            if (vipAsg) {
                const zones = vipAsg.configuration && vipAsg.configuration.zones;

                if (zones && zones.length > 0) {
                    zones.forEach(zone => zone.subnet_uuid = zone.subnet_uuid.slug());
                } else {
                    delete vipAsg.configuration;
                    delete vipAsg.policy;
                }
            }

            // If data_network_id is set, backend expects just the id part.
            if (config.data_network_id) {
                config.data_network_id = config.data_network_id.slug();
            }

            return config;
        }

        /**
         * SEGroup#id for default groups of non default clouds is not returned by `default-values`
         * API. Hence we need to make a simple name check here as used by the backend. Within
         * edit windows method MUST be used with one time binding, cause otherwise if customer
         * provides such name this method will return true for that editable.
         * @override
         */
        isProtected() {
            return this.getName() === 'Default-Group' || super.isProtected();
        }
    }

    SEGroup.prototype.objectName = 'serviceenginegroup';
    SEGroup.prototype.windowElement = 'infra-segroup-create';

    /**
     * Adds additional properties to Static Route for VRF Context API to work.
     * @param {Object} route - Single Static Route object.
     */
    SEGroup.prototype.modStaticRoute = function(route) {
        const config = this.getConfig();

        route.next_hop.type = 'V4';
        route.route_id = config.uuid;
        route.prefix = getSubnetObject('0.0.0.0/0');
    };

    /**
     * Returns single Static Route object based on this SE Group uuid.
     * @returns {?Object}
     */
    SEGroup.prototype.findStaticRoute = function() {
        if (this.vrfContext) {
            const staticRoutes = this.vrfContext.static_routes;
            const config = this.getConfig();
            const { uuid } = config;

            if (angular.isArray(staticRoutes)) {
                for (let i = 0; i < staticRoutes.length; i++) {
                    const route = staticRoutes[i];

                    if (!route.route_id || route.route_id === uuid) {
                        this.modStaticRoute(route);

                        return route;
                    }
                }
            }
        }

        return null;
    };

    /**
     * Checks if Override data Network is set for Azure cloud.
     * @returns {boolean} - True if Data Network is selected.
     */
    SEGroup.prototype.hasDataNetworkOverride = function() {
        const config = this.getConfig();

        const { data_network_id: dataNetworkId } = config;

        return !!dataNetworkId;
    };

    /**
     * Checks if Override Management Network is set for VCenter cloud.
     * @returns {boolean} - True if Management Network ref is selected.
     */
    SEGroup.prototype.hasMgmtNetworkOverride = function() {
        const config = this.getConfig();

        const { mgmt_network_ref: mgmtNetworkRef } = config;

        return !!mgmtNetworkRef;
    };

    /**
     * Reports se_bandwidth_type of SEGroup.
     */
    SEGroup.prototype.getSeBandwidthType = function() {
        return this.getConfig().se_bandwidth_type;
    };

    /**
     * Reports licenseType of SEGroup. Independent of Cloud's.
     * @returns {string}
     */
    SEGroup.prototype.getLicenseType = function() {
        return this.getConfig().license_type;
    };

    /**
     * Reports if SEGroup's selected license_type is SE_BANDWIDTH. Independent of Cloud's.
     * @returns {boolean}
     */
    SEGroup.prototype.licenseTypeIsSeBandwidth = function() {
        return this.getLicenseType().indexOf('SE_BANDWIDTH') !== -1;
    };

    /**
     * Informs if SEGroup's selected license_type is CORES. Independent of Cloud's.
     * @returns {boolean}
     */
    SEGroup.prototype.licenseTypeIsCores = function() {
        return this.getLicenseType().indexOf('CORES') !== -1;
    };

    /**
     * Informs if SEGroup's selected license_type is "metered" (i.e. PAYG) or not.
     * Independent of Cloud's.
     * @returns {boolean}
     */
    SEGroup.prototype.isMeteredLicenseType = function() {
        return this.getLicenseType().indexOf('METERED') !== -1;
    };

    /**
     * Saves VRF Context object to server if Static Route is set.
     * @returns {ng.Promise<Object>}
     */
    SEGroup.prototype.saveVRFContext = function() {
        const { staticRoute } = this;

        if (staticRoute) {
            const { vrfContext } = this;
            const sr = this.findStaticRoute();

            if (!sr) {
                if (!angular.isArray(vrfContext.static_routes)) {
                    vrfContext.static_routes = [];
                }

                vrfContext.static_routes.push(staticRoute);
            }

            if (angular.isUndefined(staticRoute.next_hop.addr)) {
                const i = vrfContext.static_routes.indexOf(staticRoute);

                if (i > -1) {
                    vrfContext.static_routes.splice(i, 1);
                }
            } else {
                this.modStaticRoute(staticRoute);
            }

            return this.request('PUT', vrfContext.url, vrfContext)
                .then(response => this.vrfContext = response.data);
        }
    };

    /** @override */
    SEGroup.prototype.save = function(appendToCollection) {
        const save = Item.prototype.save.call(this, appendToCollection);

        const hasOverriddenNetwork =
                this.hasDataNetworkOverride() || this.hasMgmtNetworkOverride();

        if (hasOverriddenNetwork && this.staticRoute) {
            const deferred = $q.defer();

            this.saveVRFContext().then(() => {
                save.then(r => deferred.resolve(r), r => deferred.reject(r));
            }, error => {
                this.errors = error.data;
                deferred.reject(error);

                return error;
            }).finally(() => this.busy = false);

            return deferred.promise;
        } else {
            return save;
        }
    };

    /**
     * Sets "extra_shared_config_memory" on ng-change of the "Host Geo Profile" checkbox. If change
     * to true, set to 2000, else set to 0.
     * @param {boolean} enable - Host Geo Profile checkbox value.
     */
    SEGroup.prototype.setExtraConfigMemory = function(enable) {
        this.getConfig().extra_shared_config_memory = enable ? 2000 : 0;
    };

    /**
     * Gets a list of datastores for vCenter clouds. If an error occurs, returns an empty array.
     * @return {ng.$q.promise}
     */
    SEGroup.prototype.getDatastores = function() {
        const { cloud_ref: cloudRef } = this.getConfig();

        this.busy = true;
        this.errors = null;

        return this.request('GET', `/api/vimgrvcenterruntime?cloud_uuid=${cloudRef.slug()}`)
            .then(({ data }) => {
                let promise = $q.when([]);

                if (angular.isArray(data.results) && data.results.length === 1) {
                    const api = `api/vimgrvcenterruntime/${data.results[0].uuid}/datastores`;

                    promise = this.request('GET', api)
                        .then(({ data }) => {
                            return angular.isArray(data) && data.length ? data[0].datastores : [];
                        });
                }

                return promise;
            })
            .catch(({ data }) => {
                this.errors = data;

                return [];
            })
            .finally(() => this.busy = false);
    };

    /**
     * Gets a list of security groups, used for AWS and Openstack clouds.
     * @return {ng.$q.promise}
     */
    SEGroup.prototype.getSecurityGroups = function() {
        const { cloud_ref: cloudRef } = this.getConfig();
        const api = `/api/cloud/${cloudRef.slug()}/securitygroups`;

        this.busy = true;
        this.errors = null;

        return this.request('GET', api)
            .then(({ data }) => data.securitygroups)
            .catch(({ data }) => {
                this.errors = data;

                return [];
            })
            .finally(() => this.busy = false);
    };

    /**
     * List of SE bandwidth types supported by SEGroup when Cloud it belongs to has "pay as you
     * go" as licensing model (meaning *METERED* license type). First one is picked as default.
     * @type {string[]} - SEBandwidthType enum.
     * @static
     */
    SEGroup.allowedMeteredBandwidthTypes = [
        'SE_BANDWIDTH_25M',
        'SE_BANDWIDTH_200M',
        'SE_BANDWIDTH_1000M',
    ];

    return SEGroup;
}]);
