/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name persistenceProfileModal
 * @description
 *
 *     Modal for Persistence profile.
 */
class PersistenceProfileModal {
    constructor(Schema, Regex) {
        this.Regex = Regex;
        this.Schema = Schema;
    }

    $onInit() {
        const { editable } = this;

        this.allTypes = this.Schema.enums.PersistenceProfileType.valuesList;

        //for non-gslb ones drops GSLB type from the list
        if (editable.id && editable.getType() !== 'PERSISTENCE_TYPE_GSLB_SITE') {
            this.allTypes = this.allTypes.filter(
                ({ value }) => value !== 'PERSISTENCE_TYPE_GSLB_SITE',
            );
        }
    }
}

PersistenceProfileModal.$inject = [
        'Schema',
        'Regex',
];

angular.module('aviApp').component('persistenceProfileModal', {
    controller: PersistenceProfileModal,
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    templateUrl:
            'src/components/modals/persistence-profile-modal/persistence-profile-modal.html',
});
