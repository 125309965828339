/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/components/roles.less';
import './roles-list-grid-expander.less';

angular.module('aviApp').controller('RoleController', [
'$scope', 'CRUDGridConfig', 'RoleCollection', 'Regex', 'roleService',
function($scope, CRUDGridConfig, RoleCollection, Regex, roleService) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
     * @typedef {{
     *   canRead: boolean,
     *   canWrite: boolean
     * }} NodeAccessSetter
     */

    /**
     * Permissions that will affect {@link permissionDependents}.
     * @private {Array<string>}
     */
    const permissionSetters1 = [
        'PERMISSION_CLOUD',
        'PERMISSION_NETWORKPROFILE',
        'PERMISSION_APPLICATIONPROFILE',
        'PERMISSION_APPLICATIONPERSISTENCEPROFILE',
        'PERMISSION_HEALTHMONITOR',
        'PERMISSION_ANALYTICSPROFILE',
        'PERMISSION_IPADDRGROUP',
        'PERMISSION_STRINGGROUP',
        'PERMISSION_SSLPROFILE',
        'PERMISSION_AUTHPROFILE',
        'PERMISSION_PINGACCESSAGENT',
        'PERMISSION_PKIPROFILE',
        'PERMISSION_SSLKEYANDCERTIFICATE',
    ];

    /**
     * Privilege resources that will affect {@link permissionDependents}.
     * @private {Array<string>}
     */
    const permissionSetters2 = [
        'PERMISSION_CLOUD',
    ];

    /**
     * Permissions that will be affected by permissions defined in {@link permissionSetters1}
     * and {@link permissionSetters2}.
     * @private {Object<string, Array<string>>}
     */
    const permissionDependents = {
        PERMISSION_VIRTUALSERVICE: permissionSetters1,
        PERMISSION_TRAFFIC_CAPTURE: ['PERMISSION_VIRTUALSERVICE'],
        PERMISSION_POOL: ['PERMISSION_VIRTUALSERVICE'].concat(permissionSetters1),
        PERMISSION_POOLGROUP: ['PERMISSION_VIRTUALSERVICE'].concat(permissionSetters1),
        PERMISSION_NETWORKSECURITYPOLICY: permissionSetters1,
        PERMISSION_HTTPPOLICYSET: permissionSetters1,
        PERMISSION_SERVICEENGINE: permissionSetters2,
        PERMISSION_SERVICEENGINEGROUP: permissionSetters2,
        PERMISSION_NETWORK: permissionSetters2,
        PERMISSION_VRFCONTEXT: permissionSetters2,
    };

    /**
     * Defines which properties should be inaccessible inside of Roles node.
     * @private {NodeAccessSetter}
     */
    const permissionLevel1 = {
        canRead: false,
        canWrite: false,
    };

    /**
     * Defines which properties should be inaccessible inside of Roles node.
     * @private {NodeAccessSetter}
     */
    const permissionLevel2 = {
        canWrite: false,
        canRead: true,
    };

    /**
     * This permissions depend on other permissions to set their permission levels.
     * @private {Object<string, NodeAccessSetter>}
     */
    // TODO: This doesn't address cases where a single permission affects different permissions
    // on different levels. A affects B and C, but B is read-only and C is no-access.
    const dynamicPermissionLevels = {
        PERMISSION_CLOUD: permissionLevel2,
        PERMISSION_VIRTUALSERVICE: permissionLevel1,
        PERMISSION_NETWORKPROFILE: permissionLevel2,
        PERMISSION_APPLICATIONPROFILE: permissionLevel2,
        PERMISSION_APPLICATIONPERSISTENCEPROFILE: permissionLevel2,
        PERMISSION_HEALTHMONITOR: permissionLevel2,
        PERMISSION_ANALYTICSPROFILE: permissionLevel2,
        PERMISSION_IPADDRGROUP: permissionLevel2,
        PERMISSION_STRINGGROUP: permissionLevel2,
        PERMISSION_SSLPROFILE: permissionLevel2,
        PERMISSION_AUTHPROFILE: permissionLevel2,
        PERMISSION_PINGACCESSAGENT: permissionLevel2,
        PERMISSION_PKIPROFILE: permissionLevel2,
        PERMISSION_SSLKEYANDCERTIFICATE: permissionLevel2,
        PERMISSION_VRFCONTEXT: permissionLevel1,
    };

    /**
     * This permissions levels will always be set based on specified permission level.
     * @private {Object<string, NodeAccessSetter>}
     */
    const staticPermissionLevels = {};

    $scope.Regex = Regex;

    const gridConfig = new CRUDGridConfig();
    const roleCollection = new RoleCollection();

    $scope.gridConfig = gridConfig;
    gridConfig.collection = roleCollection;
    gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
        dontCloseExpander: true,
        do(row, index, event) {
            this.config.onRowClick(row, event);
        },
    }];

    gridConfig.expandedContainerTemplate = require('./roles-list-grid-expander.partial.html');

    gridConfig.checkboxDisable = () => false;
    gridConfig.singleactions.push({
        title: 'Clone',
        class: 'icon-docs',
        hidden(row) {
            return row.isEditable();
        },
        do(row) {
            roleCollection.clone(row);
        },
    });

    /**
     * @type {Array.<BasicRoleNode>}
     */
    const rolesStructure = roleService.getRoleCategories();

    rolesStructure.forEach(function(role, index) {
        gridConfig.fields.push({
            name: role.permission,
            title: role.name,
            transform: (function(index) {
                return roleRowDataTransform(index);
            })(index),
        });
    });

    /**
     * Returns row transform function for Roles table.
     * @param {number} index - Zero based index matching parent role node inside
     *     roles hierarchy tree.
     * @returns {Function} Row transform function.
     */
    function roleRowDataTransform(index) {
        return roleService.getRowTransformFunctionBasedOnColumnIndex(index);
    }

    /**
     * Sets specified role node access properties based on specified access level object.
     * @param roleNode
     * @param {string} accessType - Same name as access properties on {@link RoleNode}: canRead,
     *     canWrite.
     * @param {NodeAccessSetter} permissionLevel - Permission level object.
     * @returns {boolean} True if specified role node access property was set to false.
     */
    function setRoleAccessLevel(roleNode, accessType, permissionLevel) {
        const accessValue = /** @type {boolean} */ permissionLevel[accessType];

        if (typeof accessValue === 'boolean' && !accessValue) {
            roleNode[accessType] = accessValue;

            if (!roleNode.canRead && roleNode.type === roleService.READ_ACCESS ||
                !roleNode.canWrite && roleNode.type === roleService.WRITE_ACCESS) {
                roleNode.type = roleService.NO_ACCESS;
                roleNode.parentNode.type = '';
            }

            return true;
        }

        return false;
    }

    /**
     * Checks and sets permission type of the role node based on permissions defined in
     * {@link permissionDependents} and {@link dynamicPermissionLevels}.
     * @param {RoleNode} roleNode - Permission role node data structure.
     * @param {string} accessProp - Permission role node access property name i.e. canRead,
     *     canWrite.
     * @returns {boolean} True if role should be disabled based on its permission type.
     */
    $scope.isRoleDisabled = function(roleNode, accessProp) {
        if (!roleNode || !roleNode.children) {
            return false;
        }

        if (roleNode.children.length) {
            for (let i = 0; i < roleNode.children.length; i++) {
                if (roleNode.children[i][accessProp]) {
                    return false;
                }
            }

            return true;
        }

        const { permission } = roleNode;
        const setters = permissionDependents[permission];

        if (setters) {
            const permissionNodeMap = roleService.getCurrentPermissionNodeMap();

            for (let j = 0; j < setters.length; j++) {
                const setter = setters[j];
                const setterNode = /** @type {RoleNode} */ permissionNodeMap[setter];

                if (setterNode.type === roleService.NO_ACCESS) {
                    const permissionLevel = dynamicPermissionLevels[setter];

                    if (setRoleAccessLevel(roleNode, accessProp, permissionLevel)) {
                        roleNode.hint = `${setterNode.name} is required`;

                        return true;
                    }
                }
            }
        } else if (staticPermissionLevels[permission]) {
            return setRoleAccessLevel(roleNode, accessProp, staticPermissionLevels[permission]);
        }

        roleNode[accessProp] = true;
        roleNode.hint = '';

        return false;
    };

    setTimeout(() => {
        const { editable } = $scope;

        if (editable) {
            const config = editable.getConfig();

            config.privileges_.forEach(function(role) {
                $scope.isRoleDisabled(role);
            });
            angular.copy(config, editable.backup);
        }
    });

    this.$onDestroy = function() {
        roleCollection.destroy();
    };

    /**
     * Sets specified RoleNode's type based on its children properties.
     * @param {RoleNode} roleNode
     */
    $scope.setRoleNodeType = function(roleNode) {
        roleService.setRoleNodeType(roleNode);
    };

    /**
     * Sets parent type of the specified role node item and type.
     * @param {RoleNode} item - Role node.
     */
    $scope.setParentPrivilegeType = function(item) {
        const { type, children } = item;

        if (Array.isArray(children)) {
            children.forEach(child => child.type = type);
        }
    };

    /**
     * Handles CSS selectors when parent role is clicked in role config table.
     * @param {MouseEvent} event
     */
    $scope.rowCollapseHandler = function(event) {
        const { currentTarget } = event;

        currentTarget.classList.toggle('collapsed');
    };
}]);
