/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @name UnitLabels
 * @description
 *     A hash of metric unit labels and scales.
 */
angular.module('aviApp').constant('UnitLabels', {
    METRIC_COUNT: {
        units: '',
    },
    PER_SECOND: {
        units: '/sec',
    },
    SECONDS: {
        units: 'sec',
    },
    MILLISECONDS: {
        units: 'ms',
    },
    PERCENT: {
        units: '%',
    },
    GIGA_BYTES: {
        units: 'GB',
    },
    MEGA_BYTES: {
        units: 'MB',
    },
    KILO_BYTES: {
        units: 'KB',
    },
    BYTES: {
        units: 'B',
    },
    KILO_BYTES_PER_SECOND: {
        units: 'KBps',
    },
    BYTES_PER_SECOND: {
        units: 'Bps',
    },
    BITS_PER_SECOND: {
        units: 'bps',
    },
    KILO_BITS_PER_SECOND: {
        units: 'Kbps',
    },
});
