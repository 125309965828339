/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name gslbPoolMemberForm
 * @author Alex Malitsky
 * @restrict E
 *
 * @param {GslbPoolConfig} config - Config of GslbPool we are working on.
 * @param {GSLBService} gslbService - GslbService Item, not updated directly by this
 *     directive. But some of it's methods are used.
 * @param {string=} basicEditMode - This directive is working with one GslbPool members, but when
 *     mode is `active-standby` later we want to create a new group for each member, meaning
 *     that here we have to use an extra input for {@link GslbPoolMember#priority_}.
 *
 * @description
 *
 *     Each GslbPool has a list of members. We use this directive to manage this
 *     list and to set up gslbPool member's properties. Uses GSLB configuration.
 */
angular.module('GSLB.vantage.avi').directive('gslbPoolMemberForm', [
'GSLBVSCollection', 'Schema', 'Regex',
function(GSLBVSCollection, Schema, Regex) {
    function link(scope) {
        scope.Schema = Schema;
        scope.Regex = Regex;

        /**
         * @type {{string: GSLBVSCollection}} - Each GslbSite of GSLB configuration has
         * it's own collection of VirtualServices (which differ by `headers_` param), this hash
         * keeps them all. We need have GSLB configuration loaded to get the list of configured
         * sites.
         * @public
         */
        scope.vsCollections = {};

        const { vsCollections } = scope;

        /**
         * @type {string[]} - Array of strings where cluster_uuid is concatenated with hash
         * symbol and it's name. Will be populated once GSLB config is loaded.
         * @public
         */
        //gslb is supposed to be loaded
        scope.sites = scope.gslbService.getGSLB().getSites();

        const { sites } = scope;

        scope.nonAviSites = scope.gslbService.getGSLB().getNonAviSites();

        const isCreatable = () => false;

        sites.forEach(siteRef => {
            const gslbSiteId = siteRef.slug();

            vsCollections[gslbSiteId] = new GSLBVSCollection({
                gslbSiteId,
                params: {
                    fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                    join: 'vsvip_ref',
                },
            });

            vsCollections[gslbSiteId].isCreatable = isCreatable;
        });

        /**
         * Event handler for layout switch between direct IP address input versus cluster_uuid &
         * vs_uuid selectors.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.isSetByIpChange = function(member) {
            if (member.isSetByIp) {
                delete member['cluster_uuid'];
                delete member['vs_uuid'];
                delete member.allVSIPs;
            } else {
                delete member['fqdn'];
            }

            member['ip'].addr = '';
        };

        /**
         * Event handler for the GslbSite dropdown select or clear events.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.onSiteSelect = function(member) {
            member['vs_uuid'] = undefined;
            member['ip'].addr = '';
            member.allVSIPs = undefined;
        };

        /**
         * Event handler for the VS dropdown select event. We need to set the
         * GslbPoolMemberConfig#ip taking it from the selected VS configuration.
         * @param {GslbPoolMemberConfig} member
         * @public
         */
        scope.onSiteVSSelect = function(member) {
            member.allVSIPs = [];
            member['ip'].addr = '';

            const {
                vs_uuid: vsId,
                cluster_uuid: clusterId,
            } = member;

            if (!angular.isUndefined(vsId)) {
                const vs = vsCollections[clusterId].getItemById(vsId.slug());

                //In case vs is a child:
                if (vs.isVHChild()) {
                    const headerParam = { headers_: { 'X-Avi-Internal-GSLB': clusterId } };

                    vs.addParams(headerParam);

                    vs.getVHParentIPs('allV4')
                        .then(parentIPs => assignMemberIPs(parentIPs, member))
                        .catch(console.error);
                } else {
                    assignMemberIPs(vs.getIPAddresses('allV4'), member);
                }
            }
        };

        /**
         * Handles assigning of IPs for given vs.
         * @param {IpAddr.addr} ips - list of ips
         * @param {GslbPoolMemberConfig} member
         * @private
         */
        function assignMemberIPs(ips, member) {
            if (ips.length) {
                [member['ip'].addr] = ips;
                member.allVSIPs = ips;
            }
        }

        /**
         * `Add Member` button click event handler.
         */
        scope.addMember = function() {
            //for a-s we put each members into a separate group meaning no more than 20 can be added
            if (scope.basicEditMode !== 'active-standby' || scope.config['members'].length < 99) {
                scope.config['members'].push(scope.gslbService.getDefaultPoolMemberConfig());
            }
        };

        scope.$on('$destroy', () => {
            _.each(vsCollections, collection => collection.destroy());
        });
    }

    return {
        restrict: 'E',
        scope: {
            config: '=',
            gslbService: '<',
            basicEditMode: '<',
        },
        link,
        templateUrl: 'src/views/partials/application/gslb-pool-member-form.html',
    };
}]);
