/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name policyGridMatchColumn
 * @description Component for displaying the match configuration in a grid field.
 * @param {PolicyRuleConfigItem} rule
 * @param {PolicyGridConfig} config
 */
class PolicyGridMatchColumnController {
    constructor(PolicyGridService, PolicyRuleConfigItem) {
        this.PolicyGridService_ = PolicyGridService;
        this.PolicyRuleConfigItem_ = PolicyRuleConfigItem;
    }

    $onInit() {
        this.isPolicyRuleConfigItem = this.rule instanceof this.PolicyRuleConfigItem_;

        if (!this.isPolicyRuleConfigItem) {
            this.matches = this.config.collection.matches;
        }
    }

    /**
     * Returns true if rule has any matches
     * @return {boolean}
     */
    hasMatches() {
        if (this.isPolicyRuleConfigItem) {
            return this.rule.hasMatches();
        } else {
            const { match } = this.rule;

            return match && angular.isObject(match) && Object.keys(match).length > 0;
        }
    }

    /**
     * Returns all Matches in the rule.
     * @return {[MatchConfigItem]}
     */
    getMatches() {
        return this.rule.getMatches();
    }

    /**
     * Returns the name of the match property to be displayed.
     * @param {string} property - Match property.
     * @return {string}
     */
    getMatchName(property) {
        return this.PolicyGridService_.getMatchName(property);
    }

    /**
     * Returns a string representation of the match configuration.
     * @param {string} property - Match property.
     * @param {Object} match - Match object containing the config.
     * @return {string}
     */
    getMatchString(property, match) {
        let matchConfig = match;

        if (this.isPolicyRuleConfigItem) {
            matchConfig = match.getConfig();
        }

        return this.PolicyGridService_.getMatchString(property, matchConfig);
    }
}

PolicyGridMatchColumnController.$inject = [
    'PolicyGridService',
    'PolicyRuleConfigItem',
];

angular.module('aviApp').component('policyGridMatchColumn', {
    bindings: {
        rule: '<',
        config: '<',
    },
    controller: PolicyGridMatchColumnController,
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-match-column/policy-grid-match-column.html',
});
