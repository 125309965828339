/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PKIProfileCollection
 */
angular.module('aviApp').factory('PKIProfileCollection', [
'Collection', 'PKIProfile',
function(Collection, PKIProfile) {
    class PKIProfileCollection extends Collection {}

    angular.extend(PKIProfileCollection.prototype, {
        objectName_: 'pkiprofile',
        itemClass_: PKIProfile,
        windowElement_: 'prof-pki-profile-create',
    });

    return PKIProfileCollection;
}]);
