/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name SingleSeriesPerEveryDimensionIdMetric
 * @description
 *
 *     Metric containing one or more Series of a single metric_id but different dimensionIds.
 */
angular.module('metrics.vantage.avi').service('SingleSeriesPerEveryDimensionIdMetric', [
'CollMetric', function(CollMetric) {
    return class SingleSeriesPerEveryDimensionIdMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;
            args.staticSeriesList = false;

            super(args);
        }

        /**
         * Dimension id is used as a series name.
         * @override
         */
        seriesIdToConfig_(dimensionId) {
            return {
                dimensionId,
                seriesId: this.seriesId_,
            };
        }

        /** @override */
        getSeriesConfigBySeriesHeader_(header) {
            const config = super.getSeriesConfigBySeriesHeader_(header);

            config['title'] = config['dimensionId'];

            return config;
        }
    };
}]);
