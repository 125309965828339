/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name header
 * @restrict E
 */
angular.module('aviApp').directive('header', [
'$state', '$filter',
function($state, $filter) {
    function headerLink(scope) {
        const
            stateIncludesFilter = $filter('currentStateIncludes'),
            hasAlerts = stateIncludesFilter('alerts'),
            hasEvents = stateIncludesFilter('events'),
            hasLogs = stateIncludesFilter('logs'),
            isInsights = stateIncludesFilter('hs-insights'),
            stateContext = $filter('stateContext')($state.$current);

        scope.showAsSE = showAs('serviceengine-detail');
        scope.showAsVS = showAs('virtualservice-detail');
        scope.showAsPool = showAs('pool-detail');

        scope.hideMetricValueTypeSelector = hasLogs || hasEvents || hasAlerts || isInsights;
        scope.hideTimeframeSelector = isInsights;

        function showAs(context) {
            return stateContext === context || $state.includes(`**.${context}.**`);
        }
    }

    return {
        restrict: 'E',
        scope: true,
        link: headerLink,
        templateUrl: '/src/views/infrastructure/header.tpl.html',
    };
}]);
