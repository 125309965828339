/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const tenantFactory = (
    UpdatableItem,
    Auth,
) => {
    class Tenant extends UpdatableItem {
        /** @override **/
        beforeEdit() {
            this.backup = angular.copy(this.data.users);
        }

        /** @override **/
        isEditable() {
            // Enforce to disable editing remote users if keystone auth is enabled
            if (Auth.isKeystoneAuthEnabled()) {
                return false;
            }

            return super.isEditable();
        }
    }

    Object.assign(Tenant.prototype, {
        objectName: 'tenant',
        windowElement: 'adm-tenant-create',
    });

    return Tenant;
};

tenantFactory.$inject = [
    'UpdatableItem',
    'Auth',
];

/**
 * @ngdoc service
 * @name Tenant
 * @description
 * Tenant Item class.
 */
angular.module('aviApp').factory('Tenant', tenantFactory);
