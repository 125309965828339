/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name DNSQueryCollection
 * @description
 *
 *     Collection of Metrics with a unique DataTransformer for parsing data.
 */
angular.module('aviApp').factory('DNSQueryCollection', [
'Collection', 'MetricData',
(Collection, MetricData) => {
    class DNSQueryCollection extends Collection {}

    angular.extend(DNSQueryCollection.prototype, {
        itemClass_: MetricData,
        objectName_: 'dnsquery',
        allDataSources_: {
            list: {
                source: 'MetricDataListCollDataSource',
                transformer: 'DNSQueryListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return DNSQueryCollection;
}]);
