/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ListCollDataSourceWithSearchParam
 * @description
 *
 *     ListCollDataSource with 'search' as a search param name. Used for custom APIs which don't
 *     support the usual "name.icontains".
 */
//TODO figure out a better way of defining search param name, not sure it belongs here
angular.module('aviApp').factory('ListCollDataSourceWithSearchParam', [
'ListCollDataSource', function(ListCollDataSource) {
    class ListCollDataSourceWithSearchParam extends ListCollDataSource {}

    angular.extend(ListCollDataSourceWithSearchParam.prototype, {
        defaultSearchParamName_: 'search',
    });

    return ListCollDataSourceWithSearchParam;
}]);
