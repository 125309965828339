/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class IpamDnsProfilesPageController {
    constructor(CRUDGridConfig, IPAMProfileCollection, IPAMProfile) {
        this.CRUDGridConfig_ = CRUDGridConfig;
        this.IPAMProfileCollection_ = IPAMProfileCollection;
        this.IPAMProfile_ = IPAMProfile;
    }

    $onInit() {
        const gridConfigOptions = {
            collection: new this.IPAMProfileCollection_(),
            fields: [
                {
                    name: 'data.config.name',
                    sortBy: 'name',
                    title: 'Name',
                }, {
                    name: 'data.config.type',
                    title: 'Type',
                    template: '{{row.data.config.type | enumText: "IpamDnsType"}}',
                },
            ],
            expandedContainerTemplate: '<ipam-dns-profilers-expander ipam-profile="row"/>',
            expanderDisabled: ipamProfile => {
                const type = ipamProfile.getType();
                const {
                    IPAMDNS_TYPE_AWS,
                    IPAMDNS_TYPE_AWS_DNS,
                    IPAMDNS_TYPE_AZURE,
                    IPAMDNS_TYPE_AZURE_DNS,
                    IPAMDNS_TYPE_OCI,
                    IPAMDNS_TYPE_OPENSTACK,
                } = this.IPAMProfile_.typeHash;

                return type === IPAMDNS_TYPE_AWS ||
                    type === IPAMDNS_TYPE_AWS_DNS ||
                    type === IPAMDNS_TYPE_AZURE ||
                    type === IPAMDNS_TYPE_AZURE_DNS ||
                    type === IPAMDNS_TYPE_OCI ||
                    type === IPAMDNS_TYPE_OPENSTACK;
            },
            createActions: [{
                label: 'IPAM Profile',
                action() {
                    this.config.collection.setDefaultItemConfigProps({
                        type: 'IPAMDNS_TYPE_INTERNAL',
                    });

                    this.config.collection.create();
                },
            }, {
                label: 'DNS Profile',
                action() {
                    this.config.collection.setDefaultItemConfigProps({
                        type: 'IPAMDNS_TYPE_INTERNAL_DNS',
                    });

                    this.config.collection.create();
                },
            }],
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

IpamDnsProfilesPageController.$inject = [
        'CRUDGridConfig',
        'IPAMProfileCollection',
        'IPAMProfile',
];

/**
 * @ngdoc component
 * @name ipamDnsProfilesPage
 * @author alextg, Chitra
 * @description Component for the IPAM/DNS Profiles page.
 */
angular.module('aviApp').component('ipamDnsProfilesPage', {
    controller: IpamDnsProfilesPageController,
    template:
            `<div class="avi-main">
                <div class="panel templatesList">
                    <collection-grid config="$ctrl.gridConfig"></collection-grid>
                </div>
            </div>`,
});
