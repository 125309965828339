/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Module for DataScripts, ControlScripts and ProtocalParserScripts.
 * @module avi/scripts
 */

angular.module('avi/scripts').factory('DataScriptSet', ['Item',
function(Item) {
    /**
     * @class DataScriptSet
     * @constructor
     * @memberOf module:avi/scripts
     * @extends module:avi/dataModel.Item
     * @desc DatascriptSet {@link module:avi/dataModel.Item Item} class implementation.
     * @author Alex Malitsky
     */
    class DataScriptSet extends Item {
        /** @override */
        beforeEdit() {
            this.transformAfterLoad_(this.getConfig());

            const
                typesHash = {},
                { datascript: list } = this.getConfig(),
                dataScriptList = [];

            list.forEach(({ evt }, index) => typesHash[evt] = list[index]);

            DataScriptSet.supportedEventTypes.forEach(eventType => {
                const eventConfig = typesHash[eventType];

                if (!eventConfig) {
                    dataScriptList.push(this.getDefaultDSConfig_(eventType));
                } else {
                    dataScriptList.push(eventConfig);
                }
            });

            this.getConfig().datascript = dataScriptList;
        }

        /**
         * Returns basic configuration of VSDataScript.
         * @param {string} eventType
         * @returns {Object} of VSDataScript protobuf type.
         * @private
         */
        getDefaultDSConfig_(eventType) {
            return {
                evt: eventType,
                script: '',
            };
        }

        /**
         * Modifies Item's configuration to be consistent.
         * @param {Object} config
         * @returns {*}
         * @private
         */
        transformAfterLoad_(config) {
            if (!('datascript' in config)) {
                config['datascript'] = [];
            }

            return config;
        }

        /** @override */
        transformAfterLoad() {
            this.transformAfterLoad_(this.getConfig());
        }

        /** @override */
        transformDataAfterSave({ data: config }) {
            this.data.config = this.transformAfterLoad_(config);

            return this.getConfig();
        }

        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { datascript: list } = config;

            config['datascript'] = list
                .filter(datascript => {
                    const { script } = datascript;

                    if (script) {
                        datascript['script'] = script
                            .replace(/[\u2018\u2019]/g, '\'')
                            .replace(/[\u201C\u201D]/g, '"');

                        return true;
                    }

                    return false;
                });

            return config;
        }

        /**
         * Checks whether current config can be saved.
         * @returns {boolean}
         * @public
         */
        hasValidConfig() {
            const { datascript: list } = this.getConfig();

            return _.some(list, ({ script }) => script);
        }

        /**
         * Returns a list of event types used by this set as list of enums.
         * @return {string[]}
         * @public
         */
        getListOfEventTypes() {
            const
                { datascript: list } = this.getConfig(),
                types = _.pluck(list, 'evt');

            return _.unique(types);
        }
    }

    angular.extend(DataScriptSet.prototype, {
        objectName: 'vsdatascriptset',
        windowElement: 'datascriptset-modal',
    });

    /**
     * Returns human readable string out of VSDataScriptEvent enum value.
     * @param {string} type
     * @returns {string}
     * @static
     */
    DataScriptSet.getDSEventLabel = function(type) {
        return type.enumeration('VS_DATASCRIPT_EVT_HTTP_');
    };

    /**
     * List of L4 DataScript types.
     * @type {string[]}
     * @static
     */
    DataScriptSet.l4EventTypes = [
        'VS_DATASCRIPT_EVT_L4_REQUEST',
        'VS_DATASCRIPT_EVT_L4_RESPONSE',
    ];

    /**
     * List of SSL DataScript types.
     * @type {string[]}
     * @static
     */
    DataScriptSet.sslEventTypes = [
        'VS_DATASCRIPT_EVT_SSL_HANDSHAKE_DONE',
    ];

    /**
     * List of HTTP DataScript types.
     * @type {string[]}
     * @static
     */
    DataScriptSet.httpEventTypes = [
        'VS_DATASCRIPT_EVT_HTTP_REQ',
        'VS_DATASCRIPT_EVT_HTTP_REQ_DATA',
        'VS_DATASCRIPT_EVT_HTTP_LB_DONE',
        'VS_DATASCRIPT_EVT_HTTP_RESP',
        'VS_DATASCRIPT_EVT_HTTP_RESP_DATA',
        'VS_DATASCRIPT_EVT_HTTP_RESP_FAILED',
    ];

    /**
     * List of all Datascript event types
     * @type {string[]}
     * @static
     */
    DataScriptSet.supportedEventTypes = DataScriptSet.l4EventTypes
        .concat(DataScriptSet.sslEventTypes, DataScriptSet.httpEventTypes);

    return DataScriptSet;
}]);
