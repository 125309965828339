/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './repeated-inputs.less';

/**
 * @ngdoc directive
 * @name repeatedInputs
 * @restrict E
 * @description
 *     Used for input fields that are mapped to a repeated list in the protobuf, and '+/-'
 *     are desired.
 */
angular.module('aviApp').directive('repeatedInputs', function() {
    function repeatedInputsLink($scope, elm, attr, ngModelCtrl) {
        ngModelCtrl.$isEmpty = viewValue => !viewValue.length;

        /**
         * For undefined or array with zero length we gonna create an array with one empty
         * string value.
         * modelValue > viewValue
         * @param {string[]|undefined} modelVal
         * @returns {string[]}
         * @inner
         */
        function formatItemsList(modelVal) {
            const viewVal = [];

            if (!modelVal || !Array.isArray(modelVal) || !modelVal.length) {
                viewVal.push('');
            } else {
                viewVal.push(...modelVal);
            }

            return viewVal;
        }

        /**
         * Parser function that replaces undefined values with empty strings, since we don't want
         * the undefined values to be converted to null values in the save request.
         * @param {string[]|undefined} values
         * @inner
         * @returns {string[]|undefined}
         */
        function parseUndefinedValues_(values) {
            if (values) {
                return values.map(value => (_.isUndefined(value) ? '' : value));
            }
        }

        ngModelCtrl.$formatters.push(formatItemsList);
        ngModelCtrl.$parsers.push(parseUndefinedValues_);

        $scope.ngModelCtrl = ngModelCtrl;
        attr.$observe('required', required => {
            $scope.required = required;
        });
        $scope.placeholder = attr.placeholder || '';

        /**
         * Called when '+' button is clicked. Adds input field.
         * @public
         **/
        $scope.addInput = function() {
            ngModelCtrl.$setViewValue(
                ngModelCtrl.$viewValue.concat(''),
            );
        };

        /**
         * Called when '-' button is clicked. Removes input field.
         * @param {number} index - Index of element where '-' button clicked.
         * @public
         */
        $scope.removeInput = function(index) {
            const newValue = ngModelCtrl.$viewValue.concat();

            newValue.splice(index, 1);
            ngModelCtrl.$setViewValue(newValue);
        };

        /**
         * ngChange event listener for every repeated input.
         * @param {number} index - Index of the changed element.
         * @public
         */
        $scope.particularValueChange = function(index) {
            ngModelCtrl.$setViewValue(ngModelCtrl.$viewValue.concat());
        };
    }

    return {
        scope: {
            inputPattern: '<',
            maxInputs: '@',
            ngDisabled: '<',
            itemName: '@',
        },
        require: 'ngModel',
        link: repeatedInputsLink,
        templateUrl: 'src/js/directives/repeated-inputs/repeated-inputs.html',
    };
});
