/**************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './gslb-service-detail.component.less';

class GslbServiceDetailController {
    $onInit() {
        this.gslbService.addLoad('health');
    }

    $onDestroy() {
        this.gslbService.destroy();

        if (this.gslb_) {
            this.gslb_.destroy();
        }
    }
}

const componentTag = 'gslb-service-detail';
const templateUrl =
    `src/components/pages/application/gslb/${componentTag}/${componentTag}.component.html`;

/**
 * @ngdoc component
 * @name  gslbServiceDetail
 * @param {GSLBService} gslbService - gslbService object
 * @param {GSLB} gslb_ - GSLB instance, only included here to destroy as needed.
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Wrapper component for all GslbService details components/pages.
 */
angular.module('GSLB.vantage.avi').component('gslbServiceDetail', {
    bindings: {
        gslbService: '<resolveGslbService',
        gslb_: '<resolveGslb',
    },
    controller: GslbServiceDetailController,
    templateUrl,
});
