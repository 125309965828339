/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name StringMatchConfig
 * @description StringMatchConfig ConfigItem class.
 */
const stringMatchConfigFactory = (MatchConfigItem, schemaService) => {
    class StringMatchConfig extends MatchConfigItem {
        constructor(args) {
            super(args);

            this.transformAfterLoad_();
        }

        /**
         * Transform data for use.
        */
        transformAfterLoad_() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || '';
            config.match_str = config.match_str || [];
            config.string_group_refs = config.string_group_refs || [];
        }

        /** @override */
        toString() {
            const
                {
                    match_criteria: matchCriteria,
                    match_str: matchStr,
                    string_group_refs: stringGroupRefs,
                } = this.getConfig(),
                val = [];

            if (matchStr) {
                val.push(...matchStr);
            }

            stringGroupRefs.forEach(item => {
                val.push(`group ${item.name() || item.slug()}`);
            });

            return `${schemaService.getEnumValue('StringOperation', matchCriteria).label
                .toLowerCase()} ${val.join(', ')}`;
        }
    }

    angular.extend(StringMatchConfig.prototype, {
        type: 'StringMatch',
        name: 'String',
    });

    return StringMatchConfig;
};

stringMatchConfigFactory.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('StringMatchConfig', stringMatchConfigFactory);
