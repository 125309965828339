/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const componentName = 'file-upload-textarea';

class FileUploadTextArea {
    constructor($element, $attrs) {
        this.$element = $element;
        this.$attrs = $attrs;
        this.fileMode = false;

        /**
         * File content, ngModel.$viewValue.
         * @type {undefined|string}
         */
        this.fileContent = undefined;
    }

    $onInit() {
        this.$element.addClass(componentName);

        this.ngModelCtrl.$render = this.updateViewValue_.bind(this);

        const { $attrs } = this;

        this.isRequired = 'required' in $attrs;

        // ng-required is supported through this
        $attrs.$observe('required', required => {
            this.isRequired = !!required || required === '';
        });
    }

    /**
     * Updates ngModel viewValue.
     * @protected
     */
    updateViewValue_() {
        this.fileContent = this.ngModelCtrl.$modelValue;
    }

    /**
     * Updates ngModel modelValue.
     */
    updateModelValue() {
        this.ngModelCtrl.$setViewValue(this.fileContent || undefined);
    }
}

FileUploadTextArea.$inject = [
    '$element',
    '$attrs',
];

/**
 * @ngdoc component
 * @name fileUploadTextarea
 * @param {string} label
 * @param {string|Object=} helpText
 * @param {string=} placeholder
 * @param {string=} type - To be passed into fileUpload component
 * @see fileUpload
 * @author Alex Malitsky
 * @description
 *
 *     Component to populate string value though text-area or uploaded file.
 */
angular.module('aviApp').component('fileUploadTextarea', {
    bindings: {
        label: '<',
        helpText: '<?',
        placeholder: '@?',
        type: '@?',
    },
    controller: FileUploadTextArea,
    templateUrl: `src/components/forms/inputs/file-upload/${componentName}/` +
            `${componentName}.component.html`,
    require: {
        ngModelCtrl: 'ngModel',
    },
});
