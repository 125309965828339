/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AppMapVSListCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     Since {@link MicroServiceGraphFactory} is a `config` source for {@link
 *     AppMapVSCollection} there is no need in actual API calls and we can use
 *     {@link IdentityDataTransport} passing actual `response` in our `request`.
 *
 */
angular.module('aviApp').factory('AppMapVSListCollDataSource', ['ListCollDataSource',
function(ListCollDataSource) {
    function AppMapVSListCollDataSource(args) {
        AppMapVSListCollDataSource.superconstructor.call(this, args);
    }

    avi.inherit(AppMapVSListCollDataSource, ListCollDataSource);

    AppMapVSListCollDataSource.prototype.hasSearch = false;
    AppMapVSListCollDataSource.prototype.hasSorting = false;
    AppMapVSListCollDataSource.prototype.hasPagination = false;

    AppMapVSListCollDataSource.prototype.defaultParams_ = {};

    AppMapVSListCollDataSource.prototype.getRequestParams_ = function() {
        //need offset&limit for compatibility with ListCollDataSource.processResponse_
        const params = {
            offset_: 0,
            limit_: 0,
            rootNodeId: this.owner_.msGraph.getVsId(),
            nodeType: this.owner_.nodesType,
            results:
                this.owner_.msGraph.getVsImmediateNeighbors(undefined, this.owner_.nodesType),
        };

        return params;
    };

    return AppMapVSListCollDataSource;
}]);
