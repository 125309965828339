/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class ProtocolParserModalController {
    constructor(
        schemaService,
    ) {
        this.fileUploadHelpText = schemaService
            .getFieldDescription('ProtocolParser', 'parser_code');
    }
}

ProtocolParserModalController.$inject = [
    'schemaService',
];

/**
 * @ngdoc component
 * @name protocolParserModal
 * @param {ProtocolParser} editable
 * @author Aravindh Nagarajan
 * @desc Modal component for creating/editing a protocol parser
 */
angular.module('aviApp').component('protocolParserModal', {
    bindings: {
        editable: '<',
    },
    controller: ProtocolParserModalController,
    templateUrl: 'src/components/modals/protocol-parser-modal/protocol-parser-modal.html',
});
