/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/compression-filters.less';

angular.module('aviApp').directive('compressionFilters', [
'Regex', 'Schema', 'IpAddrGroupCollection', 'StringGroupCollection',
function(Regex, Schema, IpAddrGroupCollection, StringGroupCollection) {
    return {
        scope: {
            data: '=',
            layer: '=',
            current: '=',
            ngDisabled: '=',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/compression-filters.html',
        link(scope) {
            scope.Regex = Regex;
            scope.Schema = Schema;
            scope.IpAddrGroupCollection = new IpAddrGroupCollection();
            scope.StringGroupCollection = new StringGroupCollection();

            scope.gridConfig = {
                fields: [{
                    name: 'name',
                    title: 'Name',
                }, {
                    name: 'ip_addrs_ref',
                    title: 'Client IP',
                    transform(row) {
                        return row.ip_addrs_ref ?
                            `${Schema.enums.MatchOperation.values[row.match].options.text.value
                            } ${row.ip_addrs_ref.name()}` : 'Any';
                    },
                }, {
                    name: 'devices_ref',
                    title: 'User Agent',
                    transform(row) {
                        return row.devices_ref ? row.devices_ref.name() : 'Any';
                    },
                }, {
                    name: 'level',
                    title: 'Compression',
                    transform(row) {
                        return Schema.enums.CompressionFilterLevel.values[
                            row.level].options.text.value;
                    },
                }],
                rowId: 'name',
                searchFields: ['name', 'ip_addrs_ref.name()'],
                multipleactions: [{
                    title: 'Delete',
                    do(selected) {
                        scope.data = _.filter(scope.data, function(filter) {
                            return !_.find(selected, function(item) {
                                return filter == item;
                            });
                        });

                        return true;
                    },
                }],
                singleactions: [{
                    title: 'Edit',
                    class: 'sel-edit-btn icon-pencil-4',
                    do(filter) {
                        scope.editFilter(filter);
                    },
                }],
                checkboxDisable() {
                    return scope.ngDisabled;
                },
                withReordering: true,
            };

            scope.reindex = function() {
                // Reindex it
                _.each(scope.data, function(item, index) {
                    item.index = index;
                });
            };

            scope.object2Array = function(obj) {
                const arr = [];

                _.each(obj, function(item, key) {
                    arr.push({
                        id: key,
                        data: item,
                    });
                });

                return arr;
            };

            scope.toggleAll = function() {
                const expand = !scope.allExpanded();

                if (!scope.data) {
                    return;
                }

                _.each(scope.data, function(rule) {
                    rule.showDetails = expand;
                });
            };

            scope.remove = function(index) {
                scope.data.splice(index, 1);
            };

            scope.changeState = function(state) {
                _.each(scope.data, function(r) {
                    if (scope.table.selected[scope.table.keyFn(r)]) {
                        r.enabled = state;
                    }
                });
            };

            scope.removeMultiple = function() {
                scope.data = _.filter(scope.data, function(r) {
                    return !scope.table.selected[scope.table.keyFn(r)];
                });
                scope.table.selected = {};
            };

            /**
             * Swaps rules updating their indexes.
             * @param {number} from
             * @param {number} to
             */
            scope.onRowMove = function(from, to) {
                scope.current = null;

                const
                    { data: rules } = scope,
                    targetRule = rules[from],
                    swappedRule = rules[to],
                    { index: targetIndex } = targetRule,
                    { index: swapIndex } = swappedRule;

                targetRule.index = swapIndex;
                swappedRule.index = targetIndex;

                rules[from] = swappedRule;
                rules[to] = targetRule;
            };

            scope.addFilter = function() {
                if (!scope.data) {
                    scope.data = [];
                }

                const filter = {
                    name: `Filter ${scope.data.length + 1}`,
                    match: 'IS_IN',
                    level: 'NORMAL_COMPRESSION',
                };

                scope.editFilter(filter);
            };

            scope.editFilter = function(filter) {
                // Work with copy of the object
                filter = angular.copy(filter);

                filter.save = function() {
                    scope.saveFilter();
                };

                scope.current = filter;
            };

            scope.saveFilter = function() {
                if (!scope.current) {
                    return;
                }

                // Make sure there is no item with the same name
                scope.error = null;

                if (_.any(scope.data, function(item) {
                    return item.name == scope.current.name &&
                            item.index != scope.current.index;
                })) {
                    scope.error = 'Filter name already in use';

                    return;
                }

                delete scope.current.save;

                if (!scope.data) {
                    scope.data = [];
                }

                if (scope.current.index !== undefined) {
                    angular.copy(scope.current, _.find(scope.data, function(item) {
                        return item.index == scope.current.index;
                    }));
                } else {
                    scope.current.index = _.max(
                        scope.data, function(i) {
                            return i.index;
                        },
                    ).index + 1 || 1;
                    scope.data.push(scope.current);
                }

                scope.current = null;
            };

            scope.$on('$destroy', () => {
                scope.StringGroupCollection.destroy();
                scope.IpAddrGroupCollection.destroy();
            });
        },
    };
}]);
