/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @alias module:match/methodMatch
 * @private
 */
class MethodMatchComponent {
    constructor(dropDownUtils) {
        this.methods = dropDownUtils.getEnumDropdownOptions('HTTPMethod');
    }
}

MethodMatchComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  methodMatch
 * @module match/methodMatch
 * @description Component for configuring a MethodMatch Config Item.
 * @param {MethodMatchConfigItem} methodMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('methodMatch', {
    bindings: {
        methodMatch: '<',
        preventEdit: '<',
    },
    controller: MethodMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/method-match/' +
        'method-match.component.html',
});
