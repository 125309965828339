/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class VsAccessPolicySettingsController {
    constructor(defaultValues) {
        this.defaultValues_ = defaultValues;
    }

    /** @override */
    $onInit() {
        const {
            samlSpConfig,
            ssoPolicyRef,
        } = this;

        /**
         * Model value for Access type radio buttons (saml/pingaccess/none).
         * UI Specific.
         * @type {string}
         */
        this.accessPolicyType = this.getAccessPolicyType_(samlSpConfig, ssoPolicyRef);
    }

    /**
     * Returns access policy type (SAML/PingAccess/None) of a VS.
     * @param {Object} samlSpConfig
     * @param {string} ssoPolicyRef
     * @returns {string} access policy type
     * @protected
     */
    getAccessPolicyType_(samlSpConfig, ssoPolicyRef) {
        if (!_.isEmpty(samlSpConfig)) {
            return 'saml';
        } else if (ssoPolicyRef) {
            return 'pingaccess';
        }

        return 'none';
    }

    /**
     * Fires when access type is changed.
     * Clears ssoPolicyRef and samlSpConfig fields.
     */
    clearAccessPolicySettings() {
        delete this.samlSpConfig;
        delete this.ssoPolicyRef;

        if (this.accessPolicyType === 'saml') {
            this.setDefaultSamlSettings_();
        }
    }

    /**
     * Fires when user clicks at SAML radio button.
     * Sets samlSpConfig default values to show in input fields.
     * @protected
     */
    setDefaultSamlSettings_() {
        this.samlSpConfig = {};

        const defaultSamlConfig =
            this.defaultValues_.getDefaultItemConfigByType('SAMLSPConfig');

        Object.assign(this.samlSpConfig, defaultSamlConfig);
    }
}

VsAccessPolicySettingsController.$inject = [
    'defaultValues',
];

/**
 * @ngdoc component
 * @name vsAccessPolicySettings
 * @description
 *     Access policy settings (SAML & PingAccess) for virtual service.
 *     Only applicable for HTTP Virtual Services.
 * @param {VirtualService#data#config#saml_sp_config} samlSpConfig
 * @param {VirtualService#data#config#sso_policy_ref} ssoPolicyRef
 * @author Aravindh Nagarajan
 */
angular.module('aviApp').component('vsAccessPolicySettings', {
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: VsAccessPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'vs-access-policy-settings.html',
});
