/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ServiceEngineCollection', ['Collection', 'ServiceEngine',
function(Collection, ServiceEngine) {
    function ServiceEngineCollection(args) {
        ServiceEngineCollection.superconstructor.call(this, args);
    }

    avi.inherit(ServiceEngineCollection, Collection);

    ServiceEngineCollection.prototype.objectName_ = 'serviceengine-inventory';
    ServiceEngineCollection.prototype.itemClass_ = ServiceEngine;
    ServiceEngineCollection.prototype.windowElement_ = 'infra-se-edit';
    ServiceEngineCollection.prototype.isStatic_ = false;

    ServiceEngineCollection.prototype.allDataSources_ = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'InventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health',
                'runtime',
                'alert',
            ],
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'se_if.avg_bandwidth',
                'se_stats.avg_cpu_usage',
                'se_stats.max_num_vs',
                'se_stats.avg_mem_usage',
                'se_stats.avg_disk1_usage',
                'se_stats.avg_packet_buffer_usage',
                'se_stats.avg_persistent_table_usage',
                'se_stats.avg_ssl_session_cache_usage',
                'se_stats.pct_syn_cache_usage',
                'se_stats.avg_connections',
                'se_stats.pct_connections_dropped',
                'se_if.avg_rx_bytes',
                'se_if.avg_rx_pkts',
                'se_stats.avg_rx_bytes_dropped',
                'se_stats.avg_rx_pkts_dropped',
                'se_stats.avg_tx_bandwidth',
                'se_if.avg_tx_bytes',
                'se_if.avg_tx_pkts',
                'se_stats.avg_connection_mem_usage',
                'se_stats.avg_dynamic_mem_usage',
            ],
            dependsOn: 'config',
        },
    };

    ServiceEngineCollection.prototype.defaultDataSources_ = 'inventory';

    ServiceEngineCollection.prototype.isCreatable = function() {
        return false;
    };

    return ServiceEngineCollection;
}]);
