/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/administration/system.less';

angular.module('aviApp').controller('BackupController', [
'$scope', 'SchedulerCollection', 'BackupCollection',
function($scope, SchedulerCollection, BackupCollection) {
    this.schedulerCollection = new SchedulerCollection({
        params: {
            join: 'backupconfiguration:backup_config_ref',
            name: 'Default-Scheduler',
        },
    });

    const backupCollection = new BackupCollection();

    /**
     * Load the schedulerCollection, then set this.subscriber to the default Subscriber item.
     */
    this.schedulerCollection.load()
        .then(() => {
            if (this.schedulerCollection.getNumberOfItems()) {
                [this.scheduler] = this.schedulerCollection.items;
                this.scheduler.bind('itemSaveSuccess', () => this.scheduler.load());
            }
        });

    this.gridConfig = {
        collection: backupCollection,
        fields: [{
            name: 'data.config.timestamp',
            title: 'Timestamp',
        }, {
            name: 'local_file_url',
            title: 'Local File',
            template:
                `<span ng-show="::!row.data.config.local_file_url">N/A</span>
                <a
                    ng-show="::row.data.config.local_file_url"
                    href
                    ng-click="row.downloadBackup('local')">
                    {{ ::row.data.config.local_file_url }}
                </a>`,
        }, {
            name: 'remote_file_url',
            title: 'Remote File',
            template:
                `<span ng-show="::!row.data.config.remote_file_url">N/A</span>
                <span ng-show="::row.data.config.remote_file_url">
                    {{ ::row.data.config.remote_file_url }}
                </span>`,
        }],
        layout: {
            includeCreateButton: false,
        },
    };

    /**
     * Returns a description of the frequency.
     * @return {string} Ex. 'Back up every 1 day, 6 maximum backups stored'.
     */
    this.getFrequencyString = () => {
        const { config } = this.scheduler.data;

        return `Back up every ${config.frequency}
            ${config.frequency_unit.enumeration('SCHEDULER_FREQUENCY_UNIT_').toLowerCase()}(s),
            ${config.backup_config_ref_data.maximum_backups_stored} maximum backups stored`;
    };

    /**
     * Returns a string of fields related to the remote backup configuration.
     * @return {string}
     */
    this.getRemoteString = () => {
        const
            backupConfig = this.scheduler.data.config.backup_config_ref_data,
            {
                remote_hostname: remoteHostname,
                ssh_user_ref: sshUserRef,
                remote_directory: remoteDirectory,
            } = backupConfig;

        const output = [];

        if (remoteHostname) {
            output.push(`Server address: ${remoteHostname}`);
        }

        if (sshUserRef) {
            output.push(`User: ${sshUserRef.name()}`);
        }

        if (remoteDirectory) {
            output.push(`Directory: ${remoteDirectory}`);
        }

        return output.join(', ');
    };

    $scope.$on('$destroy', () => {
        backupCollection.destroy();
        this.schedulerCollection.destroy();
    });
}]);
