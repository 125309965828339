/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class WafRulesCollapsibleListController {
    constructor($element) {
        /** @type {angular.element} */
        this.$element = $element;

        /** @type {boolean} */
        this.expanded = false;
    }

    /**
     * Returns all collapsible list elements.
     * @returns {angular.element}
     */
    getListItems() {
        return this.$element.find('.collapsible-list__waf-item');
    }

    /**
     * Collapses all collapsible list elements.
     */
    collapse() {
        this.expanded = false;
        this.getListItems().removeClass('visible');
    }

    /**
     * Expands all collapsible list elements.
     */
    expand() {
        this.expanded = true;
        this.getListItems().addClass('visible');
    }
}

WafRulesCollapsibleListController.$inject = [
    '$element',
];

/**
 * @ngdoc directive
 * @name collapsibleList
 * @restrict Attribute
 * @description
 *      Looks-up child elements by specific class to show or hide them.
 */
angular.module('aviApp').directive('wafRulesCollapsibleList', () => {
    return {
        restrict: 'A',
        controller: WafRulesCollapsibleListController,
    };
});
