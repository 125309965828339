/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import { Component, Inject } from '@angular/core';
import './update-card-about.component.less';

/**
 * @description Version-info card used in update page, current controller version.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'update-card-about',
    templateUrl: './update-card-about.component.html',
})
export class UpdateCardAboutComponent {
    /**
     * Holds version data.
     */
    public versionData: {
        build: string;
        patch: string;
        Version: string;
        Date: string;
    };

    public constructor(@Inject('authService') private authService: any) {
        this.versionData = this.authService.initData.version;
    }
}
