/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './item-detail-page-header.less';

/**
 * @ngdoc component
 * @name  itemDetailPageHeader
 * @param {VirtualService|GslbService} item
 * @param {string} itemType - For now we need itemType to be set to use the right template for
 *     this component. For now only "vs" or "gslbservice" are supported.
 * @description
 *
 *     Item detail page header component. Consists of subheader that includes the unit info and
 *     healthscore along with nav bar for navigating child pages.
 *
 *     Tricky (workaround) part is the flexible width based on page content (sidebar).
 */
class itemDetailPageHeaderController {
    constructor($scope, $elem, $state, $transitions, myAccount) {
        this.$scope_ = $scope;
        this.$elem_ = $elem;
        this.$state_ = $state;
        this.$transitions_ = $transitions;
        this.myAccount_ = myAccount;

        this.setSidebarClasses_ = this.setSidebarClasses_.bind(this);
    }

    $onInit() {
        this.setSidebarClasses_();

        this.destroyTransitionListener_ =
                this.$transitions_.onSuccess({}, this.setSidebarClasses_);

        //sidebar expand/collapse event
        this.$scope_.$on('repaint', this.setSidebarClasses_);
    }

    /**
     * Returns true if current details child page is using sidebar.
     * @return {boolean}
     * @private
     */
    hasSidebar_() {
        return this.$state_.current.data && !!this.$state_.current.data.hasSidebar || false;
    }

    /**
     * We need to change the width of header depending on particular page layout (usage and
     * display status of it's sidebar). This function applies required classes when needed.
     * @return {boolean}
     * @private
     */
    setSidebarClasses_() {
        const hasSidebar = this.hasSidebar_();

        this.$elem_
            .toggleClass('page-with-sidebar', hasSidebar)
            .toggleClass('page-sidebar-collapsed',
                hasSidebar && !this.myAccount_.uiProperty.sideRailOpen);
    }

    $onDestroy() {
        this.destroyTransitionListener_();
    }
}

itemDetailPageHeaderController.$inject = [
    '$scope',
    '$element',
    '$state',
    '$transitions',
    'myAccount',
];

const templateUrl = function({ itemType }) {
    const
        folder = 'src/components/common/item-detail-page-header/',
        suffix = '-detail-page-header.html';

    if (!itemType) {
        console.error('Can\'t use itemDetailPageHeader wo setting the item type');
    }

    return folder + itemType + suffix;
};

templateUrl.$inject = ['$attrs'];

angular.module('aviApp').component('itemDetailPageHeader', {
    bindings: {
        item: '<',
        itemType: '@',
    },
    controller: itemDetailPageHeaderController,
    templateUrl,
});
