/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
const IMAGE_TYPE_SYSTEM = 'IMAGE_TYPE_SYSTEM';
const IMAGE_TYPE_PATCH = 'IMAGE_TYPE_PATCH';

const IMAGE_CATEGORY_CONTROLLER = 'Controller';
const IMAGE_CATEGORY_SE = 'Service Engine';
const IMAGE_CATEGORY_HYBRID = 'Controller & Service Engine';

const imageFactory = Item => {
    /**
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.Item
     * @desc
     *
     *      Image item representing file for system operations, maps to Image in protobuf.
     *
     * @author Zhiqian Liu
     */
    class Image extends Item {
        /**
         * Getter for type of this image. 'IMAGE_TYPE_SYSTEM' or 'IMAGE_TYPE_PATCH'.
         * @return {string}
         */
        get type() {
            return this.getConfig().type;
        }

        /**
         * Getter for version of this image.
         * @return {string}
         */
        get version() {
            const {
                controller_info: controllerInfo,
                se_info: seInfo,
            } = this.getConfig();

            const pacakgeDetails = controllerInfo || seInfo;

            const {
                version,
                patch_version: patchVersion,
                build_no: buildNumber,
            } = pacakgeDetails.build;

            switch (this.type) {
                case IMAGE_TYPE_SYSTEM:
                    return `${version}-${buildNumber}`;

                case IMAGE_TYPE_PATCH:
                    return `${version}-${patchVersion}-${buildNumber}`;
            }

            return '';
        }

        /**
         * Getter to decide is the image a controller image, SE image or both.
         * @return {string}
         */
        get category() {
            const {
                controller_info: controllerInfo,
                se_info: seInfo,
            } = this.getConfig();

            if (controllerInfo && seInfo) {
                return IMAGE_CATEGORY_HYBRID;
            } else if (controllerInfo) {
                return IMAGE_CATEGORY_CONTROLLER;
            } else {
                return IMAGE_CATEGORY_SE;
            }
        }

        /** @override */ //TODO delete when url is added to inventory data
        getIdFromData_(data) {
            if (!data.config) {
                return '';
            }

            return data.config.url ? data.config.url.slug() : data.config.uuid;
        }

        /**
         * Decide is this image of a specific type.
         * @param {string} type - Can be 'IMAGE_TYPE_SYSTEM' or 'IMAGE_TYPE_PATCH'.
         * @return {boolean}
         */
        isType(type) {
            return type === this.type;
        }

        /**
         * Decide is this image of a specific category.
         * @param {string} category - Can be 'IMAGE_TYPE_SYSTEM' or 'IMAGE_TYPE_PATCH'.
         * @return {boolean}
         */
        isCategory(category) {
            return category === this.category;
        }

        /**
         * Decide is this image used only by controller.
         * @return {boolean}
         */
        isUsedByController() {
            const { usedByController } = this.getRuntimeData();

            return usedByController;
        }
    }

    Object.assign(Image.prototype, {
        objectName: 'image',
    });

    return Image;
};

imageFactory.$inject = [
    'Item',
];

angular.module('avi/upgrade').factory('Image', imageFactory);

/**
 * Hash for image types.
 */
export const imageTypeHash = {
    IMAGE_TYPE_SYSTEM,
    IMAGE_TYPE_PATCH,
};

/**
 * Hash for image categories.
 */
export const imageCategoryHash = {
    IMAGE_CATEGORY_CONTROLLER,
    IMAGE_CATEGORY_SE,
    IMAGE_CATEGORY_HYBRID,
};
