/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosFullList
 * @param {Object} gridConfig - Collection Grid config object for DDoS collections.
 * @param {string} title - Title of the modal.
 * @param {function} closeModal - Closes modal.
 * @description
 *     Displays the full list of DDoS Attacks/Client IPs/URLs/ASNs/Blocked IPs.
 */
angular.module('aviApp').component('virtualserviceSecurityDdosFullList', {
    bindings: {
        gridConfig: '<',
        title: '@',
        closeModal: '&',
    },
    templateUrl: 'src/components/modals/applications/virtualservice/' +
            'virtualservice-security-ddos-full-list/' +
            'virtualservice-security-ddos-full-list.html',
});
