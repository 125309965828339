/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './pool-health-monitors-selector.component.less';

const componentName = 'pool-health-monitors-selector';

/**
 * @constructor
 * @memberOf module:avi/pool
 * @mixes module:avi/pool.poolHealthMonitorsSelectorBindings
 * @see
 *  {@link module:avi/pool.poolHealthMonitorsSelectorComponent poolHealthMonitorsSelectorComponent}
 */
class PoolHealthMonitorsSelectorController {
    constructor(
        HealthMonitorCollection,
        schemaService,
    ) {
        const healthmonitorTypesEnum = schemaService.getEnumValues('HealthMonitorType');

        const applicableMonitorTypes = _.pluck(healthmonitorTypesEnum, 'value').join();

        this.healthMonitorCollection = new HealthMonitorCollection({
            params: {
                type: applicableMonitorTypes,
                exclude: undefined,
                fields: 'name',
            },
        });
    }

    /** @override */
    $onInit() {
        this.updateSelectedMonitorFilter();
    }

    /**
     * Update healthMonitorCollection to filter out selected monitors.
     */
    updateSelectedMonitorFilter() {
        const { monitors: monitorList } = this;
        let params;

        const selectedHealthMonitorIds = monitorList.reduce((selectedMonitors, monitor) => {
            if (monitor) {
                selectedMonitors.push(monitor.slug());
            }

            return selectedMonitors;
        }, []);

        if (selectedHealthMonitorIds.length) {
            params = {
                'uuid.in': selectedHealthMonitorIds.join(),
                exclude: 'uuid.in',
            };
        } else {
            params = {
                'uuid.in': undefined,
                exclude: undefined,
            };
        }

        this.healthMonitorCollection.setParams(params);
    }

    /**
     * Removes a row from health monitor list.
     * @param {number} index Index of the row to be deleted.
     */
    removeHealthMonitor(index) {
        const { monitors } = this;

        monitors.splice(index, 1);
    }

    /**
     * Adds a row to health monitor list.
     */
    addHealthMonitor() {
        const { monitors } = this;

        monitors.push('');
    }

    /** @override */
    $onDestroy() {
        this.healthMonitorCollection.destroy();
    }
}

PoolHealthMonitorsSelectorController.$inject = [
    'HealthMonitorCollection',
    'schemaService',
];

/**
 * @name poolHealthMonitorsSelectorComponent
 * @memberOf module:avi/pool
 * @description
 *      Repeated Healthmonitor collection-dropdown component
 *      Used in Pool create/edit modal.
 * @property {module:avi/pool.PoolHealthMonitorsSelectorController} controller
 * @property {module:avi/pool.poolHealthMonitorsSelectorBindings} bindings
 * @author Aravindh Nagarajan
 */
angular.module('avi/pool').component('poolHealthMonitorsSelector', {
    /**
     * @mixin poolHealthMonitorsSelectorBindings
     * @memberof module:avi/pool
     * @property {string[]} monitors Array of healthmonitor refs.
     */
    bindings: {
        monitors: '<',
    },
    controller: PoolHealthMonitorsSelectorController,
    templateUrl:
        `src/components/applications/pool/${componentName}/${componentName}.component.html`,
});
