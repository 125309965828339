/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name authProfileVerifySaml
 * @param {AuthProfile} config
 * Auth Profile modal popup triggered by a singleaction. Shows details for the SAML config.
 */
class AuthProfileVerifySamlController {
    constructor(Schema) {
        this.Schema = Schema;
    }
}

AuthProfileVerifySamlController.$inject = ['Schema'];

angular.module('aviApp').component('authProfileVerifySaml', {
    controller: AuthProfileVerifySamlController,
    bindings: {
        config: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/pages/templates/profiles/auth-profile-page/' +
        'auth-profile-verify-saml/auth-profile-verify-saml.html',
});
