/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/profile/network-profile.less';

/**
 * Network Profile Modal Controller function
 */
function NetworkProfileModalController($scope, Regex, schemaService) {
    $scope.$parent.modalScope = $scope;//AviModal thing
    $scope.Regex = Regex;
    $scope.protocolTypesEnums = schemaService.getEnumValues('ProtocolType');

    /**
    * handle protocol type change.
    */
    $scope.onTypeChange = function(selected) {
        $scope.editable.getConfig().profile.type = selected;
        $scope.editable.removeDsrProfile();
    };
}

NetworkProfileModalController.$inject = [
    '$scope',
    'Regex',
    'schemaService',
];

/**
 * @ngdoc controller
 * @name  NetworkProfileModalController
 * @description Controller for Network profile modal.
 */
angular.module('aviApp').controller('NetworkProfileModalController', NetworkProfileModalController);
