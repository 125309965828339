/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * This service provides API to login and setup the system initially
 */
const WRITE_ACCESS = 'WRITE_ACCESS';
const NO_ACCESS = 'NO_ACCESS';

/** @alias Auth */
function AuthService(
    $q,
    $http,
    $timeout,
    $window,
    Schema,
    $rootScope,
    $state,
    statePermissionTreeService,
    $location,
    appDefaultState,
    appStateConstants,
    defaultValues,
) {
    /**
     * Application idle timeout in milliseconds.
     * @private {number}
     */
    this.autoLogoutDelay_ = 0;

    /**
     * @private {angular.$q}
     */
    this.$q_ = $q;

    /**
     * @private {angular.$http}
     */
    this.$http_ = $http;

    /**
     * @private {angular.$timeout}
     */
    this.$timeout_ = $timeout;

    /**
     * @private {Schema}
     */
    this.Schema_ = Schema;

    /**
     * @private {angular.$rootScope}
     */
    this.$rootScope_ = $rootScope;

    /**
     * @private {angular.$state}
     */
    this.$state_ = $state;

    this.$window_ = $window;

    /**
     * @private {string}
     */
    this.defaultAppState_ = appDefaultState;

    /**
     * @protected
     */
    this.defaultValues_ = defaultValues;

    // Keeps initial-data response.
    this.initData = null;
    // Keeps user profile
    // Profile available after user login
    this.profile = null;
    // Keeps current tenant roles as context
    this.context = null;

    /**
     * Hashmap for mapping privilege resource to its type.
     * @example
     *     {'PERMISSION_INTERNAL': 'WRITE_ACCESS'}
     * @type {Object<string, string>|null}
     */
    this.privilegeAccessMap = null;

    /**
     * @private {Object}
     */
    this.privilegeResource_ = statePermissionTreeService.getStatePermissionTree();

    /**
     * @type {LocationHashbangUrl}
     */
    this.$location = $location;

    /**
     * @type {Object<string, string>}
     */
    this.queryParams = this.$location.search();

    /**
     * Checks for Keystone Auth.
     * @private {boolean}
     */
    this.isKeystoneAuthEnabled_ = false;

    /**
     * Allowed access privilege types.
     * @private {Object<string, number>}
     */
    this.privilegeLevels = {
        WRITE_ACCESS: 2,
        READ_ACCESS: 1,
        NO_ACCESS: 0,
    };

    /**
     * Backend error object or custom error message goes here.
     * @type {Object|string}
     */
    this.error = null;

    /**
     * Application mode - default (normal mode) is an empty string and embedded for the iframe.
     * @type {string}
     * @protected
     */
    this.appMode_ = '';

    /**
     * @type {Promise|null}
     */
    this.logoutTimer = null;

    this.logout = this.logout.bind(this);
    this.storageListener = this.storageListener.bind(this);
    this.userActivityHandler = _.throttle(this.userActivityHandler.bind(this), 30000, {
        trailing: false,
    });

    this.apiCallErrorHandler_ = this.apiCallErrorHandler_.bind(this);

    this._appStateConstants = appStateConstants;
}

/**
 * Returns current Openstack keystone flag.
 * @returns {boolean}
 */
AuthService.prototype.isKeystoneAuthEnabled = function() {
    return this.isKeystoneAuthEnabled_;
};

/**
 * Returns version of the controller.
 * @returns {string}
 */
AuthService.prototype.getControllerVersion = function() {
    return this.initialized() && this.initData.version.Version || '';
};

/**
 * Gets Openstack keystone flag.
 * @returns {promise}
*/
AuthService.prototype.getKeystoneAuth = function() {
    const request = '/api/cloud?vtype=CLOUD_OPENSTACK&' +
        'openstack_configuration.use_keystone_auth=true&fields=url&page_size=1';

    this.isKeystoneAuthEnabled_ = false;
    this.error = null;

    return this.$http_.get(request)
        .then(function(response) {
            return this.isKeystoneAuthEnabled_ = response.data && !!response.data.count;
        }.bind(this), this.apiCallErrorHandler_);
};

/**
 * Initializes the system by getting configuration.
 * @param {Object=} params - Optional parameters for the initialization.
 * @returns {ng.$q.promise}
 */
AuthService.prototype.initialize = function(params = {}) {
    if (params.tenantName) {
        sessionStorage.setItem('tenant_ref', params.tenantName);
    }

    const afterInitContext = () => {
        return this.getInitData()
            .then(rsp => {
                this.initAutoLogout();

                //TODO save to the localStorage for the after-reload consistency
                if (params && params.appMode) {
                    this.appMode_ = params.appMode;
                }

                return rsp;
            });
    };

    if (this.initialized()) {
        return this.$q_.when(true);
    } else {
        //can't use finally here because we want to resolve even when initContext fails
        return this.initContext()
            .then(afterInitContext)
            .catch(afterInitContext);
    }
};

/**
 * Returns HTTP Promise that resolved initial data API.
 * @returns {angular.$q.promise}
 */
AuthService.prototype.getInitData = function() {
    this.error = null;

    return this.$http_.get('/api/initial-data?include_name')
        .then(this.initDataOnLoad_.bind(this))
        .catch(this.apiCallErrorHandler_);
};

/**
 * Parses the initial-data API response.
 * @param {Object} data
 * @private
 */
AuthService.prototype.initDataOnLoad_ = function({ data }) {
    this.initData = data;

    // time difference between client and server(controller) in seconds
    // current_time comes with timezone info
    this.initData.timeDifference = moment().diff(moment(data['current_time']), 's');

    if (this.isLoggedIn()) {
        return;
    }

    const { local } = this.queryParams;

    if (angular.isUndefined(local) || local !== '1') {
        if (data.sso && !data.sso_logged_in) {
            return this.$window_.location.href = '/sso/login/';
        } else if (data.sso_logged_in) {
            return this.login()
                .then(() => this.$state_.go(this.defaultAppState_));
        }
    }
};

/**
 * Removes initData to force app reinitialization. Used by 503 page.
 */
AuthService.prototype.removeData = function() {
    this.initData = null;
};

/**
 * Checks if application already has initial data.
 * @returns {boolean}
 */
AuthService.prototype.initialized = function() {
    return !!this.initData;
};

/**
 * Initializes context according to user profile roles and default tenant.
 * @returns {ng.Promise} Returns Angular promise when context is resolved.
 */
AuthService.prototype.initContext = function() {
    this.initProfile();

    const { profile } = this;

    if (profile) {
        const { tenants } = profile;
        const { default_tenant_ref: defaultTenantRef } = profile.user;
        let hasDefaultTenant = false;

        if (defaultTenantRef) {
            const defaultTenantName = defaultTenantRef.name();

            hasDefaultTenant = !!_.findWhere(tenants, { name: defaultTenantName });
        }

        const hasAdminTenant = !!_.findWhere(tenants, { name: 'admin' });
        const tenant =
            this.queryParams.tenant_name ||
            sessionStorage.getItem('tenant_ref') ||
            hasDefaultTenant && defaultTenantRef ||
            hasAdminTenant && 'admin' ||
            tenants[0].name;

        // Tenant can either be a ref or name.
        // to maintain consistancy, switchToTenant should always receive tenantRef.
        const tenantName = tenant.name() || tenant;

        return this.switchToTenant(this.getTenantRefByName(tenantName));
    }

    return this.$q_.reject();
};

/**
 * Takes the profile data out from session storage.
 * @returns {boolean}
 */
AuthService.prototype.initProfile = function() {
    if (!this.profile) {
        const profile = this.getStoredProfile();

        if (profile) {
            this.profile = JSON.parse(profile);
        } else {
            return false;
        }
    }

    return true;
};

/**
 * Returns true if the system requires setup through welcome screen.
 * @returns {boolean}
 */
AuthService.prototype.isSetupRequired = function() {
    return this.initData && this.initData['user_initial_setup'];
};

/**
 * Returns true if the user has not been logged (his profile is missing).
 * @returns {boolean}
 */
AuthService.prototype.isLoggedIn = function() {
    const profile = this.getProfile();

    if (profile) {
        const idleTimeout = profile.controller.api_idle_timeout;

        if (idleTimeout <= 0) {
            return true;
        } else {
            const lastSessionRefresh = +localStorage.getItem('lastSessionRefresh');
            const utc = moment.utc();

            if (lastSessionRefresh && utc.diff(
                moment(lastSessionRefresh),
            ).valueOf() < idleTimeout * 60 * 1000) {
                return true;
            } else {
                this.emptyLocalStorage();
            }
        }
    } else {
        this.$timeout_.cancel(this.logoutTimer);
        this.emptyLocalStorage();
    }

    return false;
};

/**
 * Logs user in.
 * @param {Object} user - Object containing username and password.
 * @param {Function} onSuccess - Called if successfull.
 * @param {Function} onFail - Failure callback.
 * @returns {promise}
 */
AuthService.prototype.login = function(user, onSuccess, onFail) {
    return this.$http_.post('/login?include_name=true', user)
        .then(({ data }) => {
            const prevUser = sessionStorage.getItem('userBeforeLogout');

            if (_.isEmpty(user) || prevUser !== user.username) {
                sessionStorage.clear();
            }

            this.profile = data;
            // Put the profile into localStorage
            this.storeProfile();
            this.initAutoLogout();

            return this.initContext();
        }).then(() => {
            if (angular.isFunction(onSuccess)) {
                onSuccess(this.profile);
            }

            return this.profile;
        }).catch(({ data }) => {
            if (angular.isFunction(onFail)) {
                onFail(data);
            }

            return this.$q_.reject(data);
        });
};

/**
 * Updates current profile.
 * @param {Object} data - Object with key-values to be updated.
 */
AuthService.prototype.updateProfile = function(data) {
    angular.extend(this.profile, data);
    this.storeProfile();
};

/**
 * Saves {@link this.profile} to local storage.
 */
AuthService.prototype.storeProfile = function() {
    localStorage.setItem('profile', JSON.stringify(this.profile));
};

/**
 * Requests password change from server.
 * @param {string} token
 * @param {string} username
 * @param {string} password
 */
AuthService.prototype.passwordChange = function(token, username, password) {
    return this.$http_.post('/api/passwordchange', { token, username, password })
        .then(() => this.login({ username, password }));
};

/**
 * Requests password reset from server.
 * @param {string} email
 */
AuthService.prototype.passwordChangeRequest = function(email) {
    return this.$http_.post('/api/passwordchangerequest', { email });
};

//TODO save MyAccount data if we have a pending saveDelayed
//have three types of logout - by click, autologout initiated by UI and on getting
//401 response code from backend
AuthService.prototype.logout = function() {
    this.resetLogout();

    return this.$http_.post('/logout');
};

AuthService.prototype.resetLogout = function() {
    window.removeEventListener('mousemove', this.userActivityHandler);
    this.$timeout_.cancel(this.logoutTimer);
    this.$rootScope_.$broadcast('userLoggedOut');
    this.$timeout_(function() {
        $('.avi-modal').aviModal('hide');
        $('.modal-scrollable, .modal-backdrop').hide();
    });

    if (this.profile && this.context) {
        sessionStorage.setItem('userBeforeLogout', this.getUsername());
    }

    this.context = null;

    this.$window_.jsErrors = [];
    this.$window_.jsErrorsHash = {};

    this.emptyLocalStorage();
    delete this.$http_.defaults.headers.common['X-Avi-Tenant'];

    let sso;

    //FIXME initData is expected to be present when resetLogout is called
    if (this.initData) {
        sso = this.initData.sso;
    }

    this.removeData();

    return this.resetLoginState(sso);
};

/**
 * Sets application state to login page.
 * @param {boolean} ssoEnabled - True to redirect to the logged-out state.
 */
AuthService.prototype.resetLoginState = function(ssoEnabled = false) {
    const {
        LOGIN_STATE,
        LOGGED_OUT_STATE,
        PLEASE_RELOAD_STATE,
    } = this._appStateConstants;

    let state;

    switch (true) {
        case ssoEnabled:
            state = LOGGED_OUT_STATE;
            break;
        case this.appMode_ === 'embedded':
            state = PLEASE_RELOAD_STATE;
            break;
        default:
            state = LOGIN_STATE;
    }

    return this.$state_.go(state);
};

/**
 * Clears stored profile data from localStorage.
 */
AuthService.prototype.emptyLocalStorage = function() {
    localStorage.removeItem('profile');
    this.profile = null;
    localStorage.removeItem('lastSessionRefresh');
    localStorage.removeItem('lastActive');
    localStorage.removeItem('controllerFaults');
    this.privilegeAccessMap = null;
};

/**
 * Checks privileges for the given object
 * @param {string} category - Privilege resource.
 * @param {string} privilege - Can be 'r' for read and/or 'w' for write
 * @returns {boolean}
 */
AuthService.prototype.isAllowed = function(category, privilege) {
    if (!this.privilegeAccessMap || !(category in this.privilegeAccessMap)) {
        category = this.Schema_.permission[category];
    }

    return this.isCategoryAllowed(category, privilege);
};

/**
 * Returns true if administrator user account setup not yet done.
 * @returns {boolean}
 */
AuthService.prototype.isAdminUserSetupRequired = function() {
    return this.initialized() && this.initData['user_initial_setup'];
};

/**
 * Returns true if the controller created in AWS cloud.
 * @returns {boolean}
 */
AuthService.prototype.isAWSCloud = function() {
    return this.initialized() && this.initData['is_aws_cloud'];
};

/**
 * Returns true if the controller set up done.
 * @returns {boolean}
 */
AuthService.prototype.isWelcomeWorkflowCompleted = function() {
    return this.initialized() && this.initData['welcome_workflow_complete'];
};

/**
 * Creates or returns privilege hashmap with privilege resource as key and privilege type
 * as value.
 * @returns {Object<string, string>|null} Privilege hashmap.
 */
AuthService.prototype.createPrivilegeMap = function() {
    if (this.privilegeAccessMap) {
        return this.privilegeAccessMap;
    }

    if (!this.context) {
        return null;
    }

    this.privilegeAccessMap = {};

    const privilegeMap = this.privilegeAccessMap;
    const { privileges } = this.context;

    for (let i = 0; i < privileges.length; i++) {
        const privilege = privileges[i];

        privilegeMap[privilege.resource] = privilege.type;
    }

    this.buildParentPrivileges(this.privilegeResource_);

    // Add/Override additional permissions from URL query.
    const queryPerms = this.queryParams.permissions;

    if (queryPerms) {
        const ps = queryPerms.split(',');

        for (let j = 0; j < ps.length; j += 2) {
            if (ps[j] && ps[j + 1]) {
                privilegeMap[ps[j]] = ps[j + 1];
            }
        }
    }

    return privilegeMap;
};

/**
 * Assigns privilege type to parent privilege based on its child privileges.
 * @param {Object} permissionStructure - Parent privilege based on {@link privilegeResource}
 *    constant data structure.
 * @param {string=} parentPermission - Optional reference to the parent
 *    permission where {@param permissionStructure} is its child permissions structure.
 *    If specified, it will be added to {@link privilegeAccessMap} with access level set
 *    based on its children.
 */
AuthService.prototype.buildParentPrivileges = function(permissionStructure, parentPermission) {
    const permissionMap = this.privilegeAccessMap;

    _.each(Object.keys(permissionStructure), permission => {
        const permissionsOrAccessLevel = permissionStructure[permission];

        if (!(permission in permissionMap)) {
            if (Array.isArray(permissionsOrAccessLevel)) {
                for (let i = 0; i < permissionsOrAccessLevel.length; i++) {
                    const p = permissionsOrAccessLevel[i];

                    if (p in permissionMap && this.privilegeLevels[permissionMap[p]] > 0) {
                        permissionMap[permission] = permissionMap[p];
                    } else if (typeof p === 'object') {
                        this.buildParentPrivileges(p, permission);
                    }
                }

                if (parentPermission && permissionMap[permission]) {
                    permissionMap[parentPermission] = permissionMap[permission];
                }
            } else if (angular.isString(permissionsOrAccessLevel)) {
                permissionMap[permission] = permissionsOrAccessLevel;
            }
        }
    });
};

/**
 * Checks if specified privilege resource has read or write access.
 * @param {string} privilege - Privilege  resource.
 * @param {string=} type - Optional parameter privilege type. If not provided any access
 *     besides NO_ACCESS qualifies.
 * @returns {boolean} True if Privilege resource has read or write access type or its
 *     type matches specified type.
 */
AuthService.prototype.isPrivilegeAllowed = function(privilege, type) {
    if (type === NO_ACCESS) {
        return false;
    }

    const privilegeMap = this.createPrivilegeMap();

    if (!privilegeMap || !(privilege in privilegeMap)) {
        return false;
    }

    const existentType = privilegeMap[privilege];

    if (existentType === NO_ACCESS) {
        return false;
    }

    // expecting an exact match
    if (type) {
        return type === existentType;
    }

    // since it is better than NO_ACCESS and there is nothing to match with
    return true;
};

/**
 * Checks privileges for the given object
 * @param {string} category - Category key.
 * @param {string} privilegeType - Privilege string. Possible values are 'r', 'w', 'rw'
 *     or 'wr'. 'wr' and 'rw' mean read OR write.
 * @returns {boolean}
 */
AuthService.prototype.isCategoryAllowed = function(category, privilegeType = 'r') {
    const privilegeMap = this.createPrivilegeMap();

    if (!privilegeMap || !(category in privilegeMap)) {
        return false;
    }

    // Find privilege type related to the category
    const existentType = privilegeMap[category];

    if (existentType === NO_ACCESS) {
        return false;
    }

    switch (privilegeType) {
        case 'r':
        case 'R':
        case 'wr':
        case 'WR':
        case 'rw':
        case 'RW':
            return true;

        case 'w':
        case 'W':
            return existentType === WRITE_ACCESS;
    }

    return false;
};

/**
 * Returns tenant reference based on tenant name.
 * For All Tenants(*), TenantRef and tenantName are same.
 * @param {string} tenantName - Tenant name.
 * @returns {string}
 */
AuthService.prototype.getTenantRefByName = function(tenantName) {
    if (tenantName === '*') {
        return tenantName;
    }

    if (this.profile && this.profile.tenants) {
        const { tenants } = this.profile;
        const l = tenants.length;

        for (let i = 0; i < l; i++) {
            const tenant = tenants[i];

            if (tenant.url.name() === tenantName) {
                return tenant.url;
            }
        }
    }

    return '';
};

/**
 * Reloads tenant-list for the current user.
 * So if any change happened in Tenant configuration,
 * that will get reflected in Tenant selector.
 * This flow is not needed for admin user.
 * when admin user adds/removes a tenant,
 * App gets reloaded (419 flow), so we dont have to do it manually.
 * @param {User} user
 */
AuthService.prototype.onCurrentUserChange = function(user) {
    if (!this.isAdminUser()) {
        const currentTenantRef = this.getCurrentTenantRef();

        // If user does not have access to the current tenant.
        // We need to change tenant context on the app level.
        if (!user.hasTenant(currentTenantRef)) {
            this.setAppContext();
        } else {
            this.loadUserProfile();
        }
    }
};

/**
 * @returns {Array=} Tenants list.
 */
AuthService.prototype.getTenants = function() {
    return this.profile && this.profile.tenants || [];
};

/**
 * @returns {string} Tenant name.
 */
AuthService.prototype.getTenantName = function() {
    const { context } = this;

    if (context && angular.isString(context.tenant_ref)) {
        return context.tenant_ref.name() || context.tenant_ref;
    }

    return '';
};

/**
 * True if current user is admin.
 * @return {boolean}
 */
AuthService.prototype.isAdminUser = function() {
    return this.getUsername() === 'admin';
};

/**
 * Loads and updates Current user's profile (tenants and user details).
 * See {@link Auth#setAppContext}.
 * @return {ng.$q.promise} to be resolved with an object (Auth.profile)
 */
AuthService.prototype.loadUserProfile = function() {
    const api = '/api/user-tenant-list?include_name';

    return this.$http_.get(api).then(({ data }) => {
        this.updateProfile(data);

        return data;
    });
};

/**
 * Reloads user tenant information. See {@link Auth#switchToTenant}.
 * @param {string=} tenantRef
 * @param {boolean=} forced True will reset application state to default page. False by
 *     default.
 * @return {ng.$q.promise}
 */
AuthService.prototype.setAppContext = function(tenantRef = '', forced = false) {
    tenantRef = tenantRef || this.getCurrentTenantRef();

    const setContextPromise = this.loadUserProfile()
        .then(profile => {
            const { tenants } = profile;
            const tenantName = tenantRef.name() || tenantRef;

            const hasCurrentTenant = tenantName === '*' ||
                _.findWhere(tenants, { name: tenantName });

            tenantRef = hasCurrentTenant ? tenantRef : tenants[0].url;

            return this.switchToTenant(tenantRef, forced);
        });

    if (!forced) {
        setContextPromise
            .then(() => {
                const { current: currentState } = this.$state_;
                const { data: stateData } = currentState;

                if (stateData && stateData.permission &&
                    !this.isPrivilegeAllowed(stateData.permission)) {
                    this.$state_.go(this.defaultAppState_);
                } else {
                    this.$state_.reload();
                }
            });
    }

    return setContextPromise;
};

/**
 * Triggers switch-to-tenant call,
 * Sets the tenant context in Auth service & sessionStorage.
 * @param {string} tenantRef - Tenant ref.
 * @param {boolean} forced - Equals to true when we explicitly switch tenant context
 *     through navigation menu.
 * @returns {ng.Promise} Returns Angular $http promise.
 */
AuthService.prototype.switchToTenant = function(tenantRef = '', forced = false) {
    const
        tenant = tenantRef.name() || tenantRef,
        api = '/api/switch-to-tenant',
        reqConfig = {
            params: { tenant_name: tenant },
            paramSerializer: 'httpParamFullSerializer',
        };

    return this.$http_.get(api, reqConfig).then(response => {
        const privileges = response.data;

        this.context = {
            tenant_ref: tenantRef,
            privileges,
        };
        sessionStorage.setItem('tenant_ref', tenant);
        this.privilegeAccessMap = null;
        // Set global header into http provider
        this.$http_.defaults.headers.common['X-Avi-Tenant'] = tenant;

        this.$rootScope_.$broadcast('setContext');

        //To get the correct references of system objects redefined on the tenant level
        this.defaultValues_.load(true);

        if (forced) {
            return this.$state_.go(this.defaultAppState_, undefined, { reload: true });
        }
    }).catch(() => {
        sessionStorage.removeItem('tenant_ref');

        return this.initContext();
    });
};

AuthService.prototype.getStoredProfile = function() {
    return localStorage.getItem('profile');
};

/**
 * The timer enforces the UI to logout after 15 min of inactivity,
 * this should cover case when there is no communication with the server
 * and client doesn't know that the session is already invalid.
 * Can be used only once.
 */
AuthService.prototype.initAutoLogout = function() {
    const profile = this.getProfile();

    if (profile) {
        this.autoLogoutDelay_ = profile.controller.api_idle_timeout * 60 * 1000 - 5000;

        if (this.autoLogoutDelay_ > 0) {
            window.addEventListener('mousemove', this.userActivityHandler);
            window.addEventListener('storage', this.storageListener);
            this.userActivityHandler_();
        }
    }
};

AuthService.prototype.getProfile = function() {
    let profile = this.profile || this.getStoredProfile();

    if (typeof profile === 'string') {
        profile = JSON.parse(profile);
    }

    return profile;
};

/**
 * Sets last user activity timestamp to localStorage and resets session timer.
 * @private
 */
AuthService.prototype.userActivityHandler_ = function() {
    // Save to localStorage in case user is active in a different tab.
    localStorage.setItem('lastActive', moment.utc().valueOf());
    this.resetSessionTimer();
};

/**
 * Throttles {@link userActivityHandler_} private method.
 */
AuthService.prototype.userActivityHandler = function() {
    this.userActivityHandler_();
};

/**
 * Handles session timer timeout callback.
    */
AuthService.prototype.logoutTimerHandler = function() {
    const lastActive = +localStorage.getItem('lastActive');
    const activityDiff = moment.utc().diff(moment(lastActive).utc()).valueOf();

    if (activityDiff < this.autoLogoutDelay_) {
        this.resetSessionTimer();
    } else {
        this.logout();
    }
};

/**
 * Resets session timer and starts new countdown.
 */
AuthService.prototype.resetSessionTimer = function() {
    if (this.autoLogoutDelay_ > 0) {
        this.$timeout_.cancel(this.logoutTimer);
        localStorage.setItem('lastSessionRefresh', moment.utc().valueOf());
        this.$http_.get('/api/refresh-session');
        this.logoutTimer = this.$timeout_(
            this.logoutTimerHandler.bind(this),
            this.autoLogoutDelay_,
        );
    }
};

/**
 * Handles localStorage change event.
 * @param {StorageEvent} event
 */
AuthService.prototype.storageListener = function(event) {
    if (event.key === 'profile' && event.newValue === null) {
        window.removeEventListener('mousemove', this.userActivityHandler);
        window.removeEventListener('storage', this.storageListener);

        this.removeData();
        this.resetLoginState();
    }
};

/**
 * Updates AuthService#error with error object received from the backend.
 * @param {Object} errRsp
 * @returns {ng.$q.promise} - Rejected promise with error object provided by backend.
 * @protected
 */
AuthService.prototype.apiCallErrorHandler_ = function(errRsp) {
    return this.$q_.reject(this.error = errRsp.data);
};

/**
 * Returns the name of current user. Undefined when not applicable.
 * @returns {string}
 */
AuthService.prototype.getUsername = function() {
    if (this.isLoggedIn()) {
        return this.getCurrentUserData().username || '';
    }
};

/**
 * Returns current user information. Undefined when not applicable.
 * @returns {Object}
 */
AuthService.prototype.getCurrentUserData = function() {
    return this.getProfile().user;
};

/**
 * Returns the ID of current user. Undefined when not applicable.
 * @return {string}
 */
AuthService.prototype.getUserId = function() {
    return this.getCurrentUserData().uuid;
};

/**
 * Returns current tenant reference
 * @return {string}
 */
AuthService.prototype.getCurrentTenantRef = function() {
    return this.context.tenant_ref;
};

/**
 * Returns whether current user is a super user
 * @return {boolean}
 */
AuthService.prototype.isSuperUser = function() {
    return this.profile.user.is_superuser;
};

AuthService.$inject = [
    '$q',
    '$http',
    '$timeout',
    '$window',
    'Schema',
    '$rootScope',
    '$state',
    'statePermissionTreeService',
    '$location',
    'appDefaultState',
    'appStateConstants',
    'defaultValues',
];

/**
 * @ngdoc service
 * @name Auth
 * @desc
 *
 *     Authentication and app initialization service.
 *     Handles login/logout, initialData, user permissions settings (context) and profile.
 */
angular.module('aviApp').service('Auth', AuthService);
