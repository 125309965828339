/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function eventCollectionFactory(
    $q,
    Collection,
    Event,
) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/dataModel.Collection
     * @desc
     *
     *     Collection of {@link module:avi/events.Event Events}.
     *
     * @author Alex Malitsky
     */
    class EventCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;

            const extendedArgs = {
                ...restArgs,
                params: {
                    type: 2,
                    ...params,
                },
            };

            super(extendedArgs);
        }

        /**
         * Accurate flag, represents percent_remaining property of the events list response.
         * @type {boolean}
         * @protected
         */
        isAccurate_ = true;

        /**
         * Setting range parameters and calling load
         * @param {Moment} tBegin - Begin time
         * @param {Moment} tEnd  - End time
         * @return {ng.$q.promise}
         */
        loadRange(tBegin, tEnd) {
            let errMsg,
                promise;

            if (!tBegin || !tEnd) {
                errMsg = 'EventCollection.loadRange failed: begin or end is not passed';
                console.error(errMsg);
                promise = $q.reject(errMsg);
            } else {
                tBegin = moment(tBegin);
                tEnd = moment(tEnd);

                // Events use duration and end to create a range
                this.setParams({
                    duration: tEnd.diff(tBegin, 's'),
                    end: tEnd.format(),
                });

                promise = this.load(undefined, true);
            }

            return promise;
        }

        /**
         * Setting step and limit parameters and calling load
         * @param {number} step - Current timeframe step
         * @param {number} limit - Current timeframe limit
         * @returns {ng.$q.promise}
         */
        loadDuration(step, limit) {
            let promise,
                errMsg;

            if (!step || !limit) {
                errMsg = 'EventCollection.loadDuration failed: step or limit is not passed';
                console.error(errMsg);
                promise = $q.when(errMsg);
            } else {
                this.setParams({
                    duration: step * limit,
                    end: undefined,
                });

                promise = this.load(undefined, true);
            }

            return promise;
        }

        /**
         * Returns true if percent_remaining = 0, else false.
         * @returns {boolean}
         */
        isAccurate() {
            return this.isAccurate_;
        }
    }

    const dataSources = {
        list: {
            source: 'EventListCollDataSource',
            transformer: 'EventListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(EventCollection.prototype, {
        objectName_: 'analytics/logs',
        itemClass_: Event,
        defaultDataSources_: 'list',
        allDataSources_: dataSources,
    });

    return EventCollection;
}

eventCollectionFactory.$inject = [
    '$q',
    'Collection',
    'Event',
];

angular.module('avi/events')
    .factory('EventCollection', eventCollectionFactory);
