/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/** Renders DLT and PLT for ClientInsights page
  *
  */

angular.module('aviApp').directive('clientInsightEndToEnd', function() {
    return {
        scope: {
            data: '=',
            active: '=',
        },
        restrict: 'A',
        templateUrl: '/src/views/components/client-insight-end-to-end.html',
        link(scope, elm) {
            scope.$watch('data.endToEnd.base.endToEnd', function() {
                main();
            });

            scope.$watch('data.endToEnd.filtered.endToEnd', function() {
                main();
            });

            const main = function() {
                updateMax();
                // telling child scopes that they need to resize
                scope.$broadcast('resizeStackedEndToEnd');
            };

            const getMax = function(endToEnd) {
                if (!endToEnd) { return 0; }

                let max = 0;

                for (let i = 0; i < endToEnd.length; i++) {
                    if (endToEnd[i].value > max) { max = endToEnd[i].value; }
                }

                return max;
            };

            // We want to find the largest single value from both base or filtered
            const updateMax = function() {
                if (!scope.data) { return; }

                let baseMax,
                    filteredMax;

                if (scope.data.endToEnd && scope.data.endToEnd.base) {
                    baseMax = getMax(scope.data.endToEnd.base.endToEnd);
                } else {
                    baseMax = 0;
                }

                if (scope.data.endToEnd && scope.data.endToEnd.filtered) {
                    filteredMax = getMax(scope.data.endToEnd.filtered.endToEnd);
                } else {
                    filteredMax = 0;
                }

                scope.max = _.max([baseMax, filteredMax]);
            };

            scope.myWidth = function(time) {
                if (time.endToEnd) {
                    const max = getMax(time.endToEnd);
                    const width = `${max / (scope.max || max) * 100}%`;

                    return { width };
                }
            };
        },
    };
});

angular.module('aviApp').controller('clientInsightEndToEndMainController', [
'$scope', '$timeout', function($scope, $timeout) {
    $scope.$watch('data.filteredEndToEnd.main', function() {
        if ($scope.data && $scope.data.baseEndToEnd && $scope.data.baseEndToEnd.barSettings) {
            $scope.data.baseEndToEnd.barSettings.height = 5;
            _.each($scope.data.baseEndToEnd.main, function(d) {
                $timeout(function() {
                    d.viewableTitle = '';
                });
            });
        }
    });

    $scope.$on('clearFilteredEndToEnd', function() {
        if ($scope.data && $scope.data.baseEndToEnd && $scope.data.baseEndToEnd.barSettings) {
            $timeout(function() {
                $scope.data.baseEndToEnd.barSettings.height = 35;
                $scope.$broadcast('assignTitles');
            });
        }
    });
}]);
