/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';

import { UIKitModule } from './modules/ui-kit';
import { NotificationModule } from './modules/notification';
import { WafModule } from './modules/waf';
import { UpdateModule } from './modules/update';

import {
    appStateTreeProvider,
    authServiceProvider,
    aviModalProvider,
    schemaServiceProvider,
    matchTargetConfigItemProvider,
    wafPolicyPsmGroupCollectionProvider,
    wafPolicyPsmGroupMetricProvider,
    timeframeProvider,
} from './ajs-upgraded-providers';

@NgModule({
    imports: [
        BrowserModule,
        UpgradeModule,
        FormsModule,
        RouterModule.forRoot([]),
        UIKitModule,
        NotificationModule,
        WafModule,
        UpdateModule,
    ],
    providers: [
        schemaServiceProvider,
        aviModalProvider,
        matchTargetConfigItemProvider,
        wafPolicyPsmGroupCollectionProvider,
        wafPolicyPsmGroupMetricProvider,
        timeframeProvider,
        { provide: APP_BASE_HREF, useValue: '/' },
        appStateTreeProvider,
        authServiceProvider,
    ],
})
export class AppModule {
    constructor(private upgrade: UpgradeModule) {}

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, ['aviApp'], { strictDi: true });
    }
}
