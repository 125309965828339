/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name SSLProfileCollection
 */
angular.module('aviApp').factory('SSLProfileCollection', ['Collection', 'SSLProfile',
function(Collection, SSLProfile) {
    function SSLProfileCollection(oArgs) {
        SSLProfileCollection.superconstructor.call(this, oArgs);
    }

    avi.inherit(SSLProfileCollection, Collection);

    SSLProfileCollection.prototype.objectName_ = 'sslprofile';
    SSLProfileCollection.prototype.itemClass_ = SSLProfile;
    SSLProfileCollection.prototype.windowElement_ = 'ssl-tls-profile-modal';
    SSLProfileCollection.prototype.serverDefaultsOverride_ = {
        tags: [],
    };

    return SSLProfileCollection;
}]);
