/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ListDataTransport
 * @author Alex Malitsky
 * @description
 *
 *     Generic DataTransport for GET requests through API URLs looking like
 *     /api/smth1/smth2?include_name&param1=val&param2=val.
 *
 *     Basically we have two lists of values here used to build up a URL - first one to be
 *     concatenated by forward slashes (values only, order matters) creating a `path` part and
 *     other one (`query` part of the URL) to be concatenated by ampersand (keys and values, order
 *     doesn't matter). Between them we use a separator '/?'.
 *
 *     'include_name' parameter is special in a sense that it doesn't need a value but should be
 *     included (when set) to the query URL part.
 *
 *     Payload is not used.
 */

/**
 * @typedef {Object} ListDataTransportRequestParams
 * @property {string|string[]} objectName_ - These values will generate a URL path.
 * @property {boolean} includeName_ - Special property without value for the query string.
 * @property {{string:string}} headers_ - Hash of HTTP headers. Optional.
 * @property {string|string[]} * - Any other properties will be concatenated by
 * ampersand to generate a query URL part. If property's value has an array type it will
 * generate a key-value pair for each of the values with the same key name. I.e.
 * filter=val1&filter=val2.
 */

/**
 * List results are returned by the backend in a such form. DataTransformers are usually working
 * with and preserving this data structure.
 * @typedef {Object} ListDataTransportResponse
 * @property {Object[]} results - Array of objects returned by backend.
 * @property {number} count - Number of Items which backend has. In general it is not equal to
 * results length as we usually ask for specific page with explicitly set page size.
 */
angular.module('aviApp').service('ListDataTransport', [
'DataTransport', 'moment', 'aviInherit',
function(DataTransport, moment, aviInherit) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends DataTransport
     */
    function ListDataTransport(args) {
        ListDataTransport.superconstructor.call(this, args);
    }

    aviInherit(ListDataTransport, DataTransport);

    /** @override */
    ListDataTransport.prototype.getRequestUrl_ = function(params) {
        let url;

        if (params['objectName_']) {
            url = `${this.getRequestUrlPath_(params)}/?${this.getRequestUrlQuery_(params)}`;
        } else {
            throw new Error('Can\'t build up inventory API URL without an objectName');
        }

        return url;
    };

    /**
     * Generates a `path` part of URL.
     * @param {ListDataTransportRequestParams} params
     * @returns {string} `path` part of API URL.
     * @private
     */
    ListDataTransport.prototype.getRequestUrlPath_ = function(params) {
        let base = this.apiUrlPrefix_;

        if (typeof params['objectName_'] === 'string') {
            base += params['objectName_'];
        } else if (Array.isArray(params['objectName_'])) {
            base += params['objectName_'].join('/');
        }

        return base;
    };

    /**
     * Generates a `query` part of URL. Special values will be excluded.
     * @param {ListDataTransportRequestParams} params
     * @returns {string} `query` part of API URL.
     * @private
     */
    ListDataTransport.prototype.getRequestUrlQuery_ = function(params) {
        let str = '';

        params = angular.copy(params);

        if (params && typeof params === 'object') {
            if (params['includeName_']) {
                str += 'include_name&';
            }

            delete params['offset_'];
            delete params['limit_'];

            delete params['objectName_'];
            delete params['includeName_'];
            delete params['headers_'];

            //unix timestamp or moment object to ISO string
            ['start', 'end'].forEach(propName => {
                if (propName in params) {
                    const value = params[propName];

                    if (angular.isNumber(value) || moment.isMoment(value)) {
                        params[propName] = moment.utc(value)
                            .toISOString();
                    }
                }
            });

            str = _.reduce(params, function(base, val, key) {
                let str = base;

                if (Array.isArray(val)) { //multiple values with the same key (i.e. 'filter')
                    str += _.reduce(val, function(base, val) {
                        return `${base + key}=${val}&`;
                    }, '');
                } else {
                    str += `${key}=${val}&`;
                }

                return str;
            }, str);
        }

        return str;
    };

    /**
     * Returns HTTP headers hash if corresponding parameters are set.
     * @param {ListDataTransportRequestParams} params
     * @override
     */
    ListDataTransport.prototype.getRequestHeaders_ = function(params) {
        return typeof params['headers_'] === 'object' ?
            angular.copy(params['headers_']) : undefined;
    };

    return ListDataTransport;
}]);
