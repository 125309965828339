/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function openstackNetworkDataTransformerFactory(
    ListDataTransformer,
) {
    /**
     * @class
     * @name OpenstackNetworkDataTransformer
     * @extends ListDataTransformer
     */
    class OpenstackNetworkDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            const { data } = resp;

            if (data && 'networks' in data && Array.isArray(data.networks)) {
                const { networks, region } = data.networks[0];

                networks.forEach(OpenstackNetworkDataTransformer.networkTransform);

                data.results = networks.map(row => ({ config: row }));

                data.count = networks.length;
                data.region = region;
                delete data.networks;
            }

            return resp;
        }

        /**
         * Sets url for Openstack network config being passed
         * @param {Object} network
         */
        static networkTransform(config) {
            config.url = `${config.id}#${config.name}`;
        }
    }

    return OpenstackNetworkDataTransformer;
}

openstackNetworkDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

/**
 * @ngdoc service
 * @alias openstackNetworkDataTransformer
 * @author Chitra
 * @description
 *
 *     Returning the list of networks.
 *     Used by {@link OpenstackNetworkCollection}.
 *
 */
angular.module('aviApp')
    .factory('OpenstackNetworkDataTransformer', openstackNetworkDataTransformerFactory);
