/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './user-modal-tenant-settings.less';

class UserModalTenantSettingsController {
    constructor(
        Auth,
        RoleCollection,
        TenantCollection,
    ) {
        this.roleCollection = new RoleCollection({
            params: {
                tenant: Auth.getCurrentTenantRef().slug(),
            },
        });

        this.tenantCollection = new TenantCollection({
            isStatic: true,
            defaultDataSources: ['inventory'],
        });

        // For default tenant collection-dropdown
        this.defaultTenantCollection = new TenantCollection({
            isStatic: true,
            defaultDataSources: ['inventory'],
        });

        /**
         * True if the current user is admin.
         * Used to restrict tenant creation for admin user.
         * @type {boolean}
         */
        this.isAdminUser = Auth.getUsername() === 'admin';
    }

    $onInit() {
        this.updateSelectedTenantsFilter();
    }

    /**
     * Remove a row from access map and update tenantCollection.
     * @param {number} Index of the item to be deleted
     */
    removeTenantRoleMap(index) {
        this.user.removeTenantRoleMap(index);
        this.updateSelectedTenantsFilter();
    }

    /**
     * Update tenantCollection to filter out already selected tenantCollection.
     */
    updateSelectedTenantsFilter() {
        const accessList = this.user.getAccessList();

        const selectedTenants = accessList.reduce((tenants, access) => {
            const { tenant_ref: tenantRef = '' } = access;

            if (tenantRef) {
                tenants.push(tenantRef.slug());
            }

            return tenants;
        }, []);

        if (selectedTenants.length) {
            this.tenantCollection.setParams({
                'uuid.in': selectedTenants.join(),
                exclude: 'uuid.in',
            });
        } else {
            this.tenantCollection.setParams({
                'uuid.in': undefined,
                exclude: undefined,
            });
        }

        this.setDefaultTenants_();
    }

    /**
     * Generates options for default tenants dropdown.
     * @protected
     */
    setDefaultTenants_() {
        const accessList = this.user.getAccessList();

        this.defaultTenantOptions = accessList
            .reduce((options, row) => {
                const { tenant_ref: tenantRef } = row;

                if (tenantRef) {
                    options.push({
                        label: tenantRef.name(),
                        value: tenantRef,
                    });
                }

                return options;
            }, []);
    }

    /** @override */
    $onDestroy() {
        [
            this.roleCollection,
            this.defaultTenantCollection,
            this.tenantCollection,
        ].forEach(collection => collection.destroy());
    }
}

UserModalTenantSettingsController.$inject = [
    'Auth',
    'RoleCollection',
    'TenantCollection',
];
/**
 * @ngdoc component
 * @name  userModalTenantSettings
 * @param {User} user - User item config
 * @description
 *      Component for multiple tenant-role selection in
 *      Administration/Accounts/User/Create-Edit modal.
 * @author Aravindh Nagarajan
 */
angular.module('aviApp').component('userModalTenantSettings', {
    bindings: {
        user: '<',
    },
    controller: UserModalTenantSettingsController,
    templateUrl: 'src/components/modals/administration/accounts/' +
        'user/user-modal-tenant-settings/user-modal-tenant-settings.html',
});
