/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleResponseActionConfig
 * @description DnsPolicyRuleResponseActionConfig,
 *      maps to DnsRuleActionResponse in protobof.
 */
const dnsPolicyRuleResponseActionConfigFactory = (ActionConfigItem, $filter) => {
    class DnsPolicyRuleResponseActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const
                { rcode, truncation, authoritative } = this.getConfig(),
                getBooleanLabel = $filter('booleanLabel'),
                outputs = [];

            if (rcode) {
                const rCode = rcode.slice('DNS_RCODE_'.length).toUpperCase();

                outputs.push(`Response Code: "${rCode}"`);
            }

            if (truncation) {
                const truncatedLabel = getBooleanLabel(truncation);

                outputs.push(`Truncation: ${truncatedLabel}`);
            }

            if (authoritative) {
                const authoritativeLabel = getBooleanLabel(authoritative);

                outputs.push(`Authoritative: ${authoritativeLabel}`);
            }

            return outputs.join('; ');
        }
    }

    angular.extend(DnsPolicyRuleResponseActionConfig.prototype, {
        type: 'DnsRuleActionResponse',
        name: 'Query Response',
        defaultPath: 'dnsrule.action.response',
    });

    return DnsPolicyRuleResponseActionConfig;
};

dnsPolicyRuleResponseActionConfigFactory.$inject = [
        'ActionConfigItem',
        '$filter',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleResponseActionConfig', dnsPolicyRuleResponseActionConfigFactory,
);
