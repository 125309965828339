/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const imageCollectionFactory = (Collection, Image) => {
    const allDataSources = {
        inventory: {
            source: 'LimitedListCollDataSource',
            transformer: 'ImageInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'runtime',
            ],
        },
    };

    /**
     * @class ImageCollection
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.Collection
     * @description
     *     Collection of {@link Image} items.
     * @author Zhiqian Liu
     */
    class ImageCollection extends Collection {
        constructor(args = {}) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_IMAGE',
                allDataSources,
            };

            super(extendedArgs);
        }
    }

    Object.assign(ImageCollection.prototype, {
        objectName_: 'image-inventory',
        itemClass_: Image,
        defaultDataSources_: 'inventory',
    });

    return ImageCollection;
};

imageCollectionFactory.$inject = [
    'Collection',
    'Image',
];

angular.module('avi/upgrade').factory('ImageCollection', imageCollectionFactory);
