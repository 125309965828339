/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, Input, OnInit } from '@angular/core';
import {
    WafPolicyWhitelistRuleExpanderContentService,
} from './waf-policy-whitelist-rule-expander-content.service';
import './waf-policy-whitelist-rule-expander-content.less';

/**
 * @ngdoc component
 * @name WafPolicyWhitelistRuleExpanderContent
 * @module waf/WafPolicyWhitelistRuleExpanderContent
 * @desc Displays match and action information about a WAF whitelist rule.
 * @param {WafPolicyWhitelistRule} rule
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-whitelist-rule-expander-content',
    templateUrl: './waf-policy-whitelist-rule-expander-content.component.html',
})
export class WafPolicyWhitelistRuleExpanderContentComponent implements OnInit {
    @Input() rule: WafPolicyWhitelistRuleConfigItem;

    match: MatchTargetConfigItem;
    actionLabels: string;
    matchFields: string[];
    field: string;

    constructor(private expanderService: WafPolicyWhitelistRuleExpanderContentService) {}

    ngOnInit(): void {
        const { match, actions } = this.rule.config;

        this.match = match;
        this.actionLabels = this.expanderService.getActionLabels(actions);

        // We filter out client_ip because for the other match fields, we can just use
        // $ctrl.match.config[field].toString() to get the string representation of the
        // ConfigItem's data, but with client_ip, we need to get the string representation from
        // flatProps_ in the config, since the IpOrStringGroup component doesn't work with
        // ConfigItems.
        this.matchFields = match.fields.filter((field: string) => field !== 'client_ip');
    }
}
