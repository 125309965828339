/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceTrafficCaptureConfig
 * @description  Configures traffic capture settings.
 * @param {object} captureConfig - Contains config properties and methods.
 * @param {function} onSuccess - Called after successful config save.
 * @param {function} closeModal - Called to close config modal.
 */
function Controller(Schema, Regex, VirtualServiceCollection) {
    const vm = this;

    this.Schema = Schema;
    this.Regex = Regex;

    this.virtualServiceCollection = new VirtualServiceCollection({ isStatic: true });

    this.$onInit = function() {
        this.config = this.captureConfig.config;
    };

    /**
     * @public
     * Populates the config object with traffic capture fields.
     */
    this.saveConfig = function() {
        this.captureConfig.save()
            .then(function() {
                vm.onSuccess();
                vm.closeModal();
            });
    };

    /**
     * @public
     * Sets captureParams fields to default when user switches between Duration and Number of
     * Pkts.
     */
    this.onParamsChange = function() {
        vm.config.capture_params.duration = vm.captureConfig.getDefaultCaptureParams().duration;
    };

    /**
     * @public
     * Sets filter to '' when user switches between All and Filter.
     */
    this.onFilterChange = function() {
        vm.config.debug_ip = undefined;
    };

    this.$onDestroy = function() {
        vm.captureConfig.cancelRequests();
    };
}

Controller.$inject = [
    'Schema',
    'Regex',
    'VirtualServiceCollection',
];

angular.module('aviApp').component('virtualserviceTrafficCaptureConfig', {
    bindings: {
        captureConfig: '<',
        onSuccess: '&',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/virtualservice-traffic-capture-config/' +
            'virtualservice-traffic-capture-config.html',
});
