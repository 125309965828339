/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('NamesHelper', function() {
    // todo -- break this into a couple of objects, combine into single object,
    // and then return that single object
    const helper = {};

    helper.commonDict = {
        dos: 'DoS',
        req: 'Requests',
        pkts: 'Packets',
        resp: 'Response',
        lb: 'LB',
        udp: 'UDP',
        rx: 'RX',
        tx: 'TX',
        vs: 'VS',
    };

    helper.dict = {
        // Summary names
        new_connections: {
            title: 'New Connections',
            color: 1,
        },
        new_requests: {
            title: 'New Requests',
            color: 1,
        },
        // Groups
        'end_to_end.vs': {
            title: 'End to End Timing',
            color: 1,
        },
        'end_to_end.pool': {
            title: 'End to End Timing',
            color: 1,
        },
        // VS insights page
        'l7_client.avg_satisfactory_responses': {
            title: 'Average Satisfactory Responses',
            color: 1,
        },
        'l7_client.avg_tolerated_responses': {
            title: 'Average Tolerated Responses',
            color: 'warning',
        },
        'l7_client.avg_frustrated_responses': {
            title: 'Average Frustrated Responses',
            color: 'bad',
        },
        'l7_server.avg_satisfactory_responses': {
            title: 'Average Satisfactory Responses',
            color: 1,
        },
        'l7_server.avg_tolerated_responses': {
            title: 'Average Tolerated Responses',
            color: 'warning',
        },
        'l7_server.avg_frustrated_responses': {
            title: 'Average Frustrated Responses',
            color: 'bad',
        },
        'l4_client.apdexc': {
            title: 'Connection Apdex',
            color: 2,
        },
        'l7_client.apdexr': {
            title: 'Response Apdex',
            color: 3,
        },
        'l4_client.avg_l4_client_latency': {
            title: 'Client Latency',
            color: 6,
        },
        health_performance_score: {
            title: 'Performance',
            color: 1,
        },
        'l7_server.avg_total_requests': {
            title: 'Requests',
            color: 2,
        },
        'l4_client.apdexrtt': {
            title: 'Apdex RTT',
            color: 7,
        },
        Total: {
            title: 'Total',
            color: 2,
        },
        total: {
            title: 'Total',
            color: 1,
        },
        total5: {
            title: 'Total',
            color: 5,
        },
        health_resources_score: {
            title: 'Resource Penalty',
            color: 'bad',
        },
        'l4_client.avg_complete_conns': {
            title: 'Conns',
            color: 2,
        },
        'l4_client.avg_new_established_conns': {
            title: 'Open Conns',
            color: 4,
        },
        'l4_client.avg_connections_dropped': {
            title: 'Dropped Connections',
            color: 'bad',
        },
        'l4_client.avg_lossy_connections': {
            title: 'Lossy Connections',
            color: 'warning',
        },
        'l7_client.avg_resp_4xx': {
            title: '4xx',
            color: 'bad',
        },
        'l4_server.avg_resp_4xx': {
            title: 'Server 4xx',
            color: 'bad',
        },
        'l7_server.avg_resp_4xx': {
            title: 'Server 4xx',
            color: 'bad',
        },
        'l7_server.avg_resp_5xx': {
            title: 'Server 5xx',
            color: 'warning',
        },
        'l4_server.avg_resp_5xx': {
            title: 'Server 5xx',
            color: 'warning',
        },
        'l7_client.avg_resp_5xx': {
            title: '5xx',
            color: 'warning',
        },
        'l7_client.rum_apdexr': {
            title: 'PageLoad Apdex',
            color: 7,
        },
        'l7_server.avg_error_responses': {
            title: 'Request Errors',
            color: 'bad',
        },
        'l7_server.pct_response_errors': {
            title: 'Request Errors, %',
            color: 'bad',
        },
        'l7_client.avg_error_responses': {
            title: 'Request Errors',
            color: 'bad',
        },
        'l7_server.apdexr': {
            title: 'Response Apdex',
            color: 7,
        },
        'l4_server.apdexc': {
            title: 'Connection Apdex',
            color: 7,
        },
        'l4_server.avg_uptime': {
            title: 'Average Uptime',
            color: 1,
        },
        'l7_server.sum_get_reqs': {
            title: 'GET',
            color: 4,
        },
        'l7_server.sum_post_reqs': {
            title: 'POST',
            color: 7,
        },
        'l7_server.sum_other_reqs': {
            title: 'Others',
            color: 1,
        },
        'l7_server.avg_resp_1xx': {
            title: '1xx Info',
            color: 2,
        },
        'l7_server.avg_resp_2xx': {
            title: '2xx OK',
            color: 3,
        },
        'l7_server.avg_resp_3xx': {
            title: '3xx Redirect',
            color: 4,
        },
        'l4_server.max_open_conns': {
            title: 'Open Conns',
            color: 4,
        },
        'l4_server.avg_complete_conns': {
            title: 'New Connections',
            color: 1,
        },
        'l4_server.avg_bandwidth': {
            title: 'Throughput',
            color: 2,
        },
        'collection.server_total_satisfactory_responses': {
            title: 'Satisfactory Responses',
            color: 1,
        },
        'collection.server_total_tolerated_responses': {
            title: 'Tolerated Responses',
            color: 'warning',
        },
        'collection.server_total_frustrated_responses': {
            title: 'Frustrated Responses',
            color: 'bad',
        },
        'l4_server.avg_lossy_connections': {
            title: 'Lossy Connections',
            color: 'warning',
        },
        'l4_server.avg_errored_connections': {
            title: 'Bad Connections',
            color: 'bad',
        },
        'l4_client.max_open_conns': {
            title: 'Open Conns',
            color: 2,
        },
        'l7_client.pct_response_errors': {
            title: 'Request Errors, %',
            color: 'bad',
        },
        'collection.virtualservice_total_satisfactory_responses': {
            title: 'Satisfied Responses',
            color: 1,
        },
        'collection.virtualservice_total_frustrated_responses': {
            title: 'Frustrated Responses',
            color: 'bad',
        },
        'collection.virtualservice_total_tolerated_responses': {
            title: 'Tolerated Responses',
            color: 'warning',
        },
        'l4_client.avg_errored_connections': {
            title: 'Bad Connections',
            color: 'bad',
        },
        'l7_client.sum_post_reqs': {
            title: 'Post Requests',
            color: 2,
        },
        'l7_client.sum_get_reqs': {
            title: 'Get Requests',
            color: 3,
        },
        'l7_client.sum_other_reqs': {
            title: 'Other Requests',
            color: 4,
        },
        'l7_client.avg_client_data_transfer_time': {
            title: 'Data Transfer',
            color: 3,
        },
        'l7_server.avg_application_response_time': {
            title: 'App Response',
            color: 6,
        },
        'l4_client.avg_total_rtt': {
            title: 'Client RTT',
            color: 1,
        },
        'l4_server.avg_total_rtt': {
            title: 'Server RTT',
            color: 2,
        },
        // Response Codes:
        'l7_server.response_code_4xx': {
            title: '4xx Errors',
            color: 'bad',
        },
        'l7_server.response_code_5xx': {
            title: '5xx Errors',
            color: 'warning',
        },
        'l7_client.response_code_4xx': {
            title: 'Avi Generated 4xx',
            color: 1,
        },
        'l7_client.response_code_5xx': {
            title: 'Avi Generated 5xx',
            color: 'warning',
        },
        'se_stats.avg_cpu_usage': {
            title: 'CPU Usage',
            color: 3,
        },
        'se_if.avg_bandwidth': {
            title: 'Throughput',
            color: 2,
        },
        'se_stats.avg_connection_mem_usage': {
            title: 'Connection Memory Usage',
            color: 1,
        },
        'se_stats.avg_mem_usage': {
            title: 'Memory Usage',
            color: 6,
        },
        'se_stats.avg_packet_buffer_usage': {
            title: 'Buffer Usage',
            color: 3,
        },
        'se_stats.avg_ssl_session_cache_usage': {
            title: 'SSL Cache Usage',
            color: 4,
        },
        'se_stats.avg_disk1_usage': {
            title: 'Disk1 Usage',
            color: 7,
        },
        'se_stats.avg_persistent_table_usage': {
            title: 'Persistent Table Usage',
            color: 1,
        },
        'se_stats.max_se_bandwidth': {
            title: 'Max Usage',
            color: 2,
        },
        'se_stats.avg_connections': {
            title: 'Conns',
            color: 6,
        },
        'se_stats.pct_connections_dropped': {
            title: 'Dropped Conns, %',
            color: 3,
        },
        'se_stats.pct_syn_cache_usage': {
            title: 'Syn Cache Usage',
            color: 1,
        },
        'se_stats.avg_rx_pkts_dropped': {
            title: 'RX Packets Drop',
            color: 4,
        },
        'se_stats.avg_rx_bytes_dropped': {
            title: 'RX Bytes Drop',
            color: 4.2,
        },
        'se_stats.avg_tx_bandwidth': {
            title: 'TX Throughput',
            color: 7.2,
        },
        'se_stats.max_num_vs': {
            title: '# VSes Time Series',
            color: 6,
        },
        'se_stats.avg_dynamic_mem_usage': {
            title: 'Dynamic Memory Usage',
            color: 4.2,
        },
        serviceengine: {
            title: 'Service Engine',
            color: 3,
            small: 'SE',
        },
        pool: {
            title: 'Pool',
            color: 4,
            small: 'Pool',
        },
        virtualservice: {
            title: 'Virtual Service',
            color: 7,
            small: 'VS',
        },
        anomaly_penalty: {
            title: 'Anomaly Penalty',
            color: 'bad',
            small: 'Anomaly',
        },
        resources_penalty: {
            title: 'Resource Penalty',
            color: 'warning',
            small: 'Resource',
        },
        security_penalty: {
            title: 'Security Penalty',
            color: 'bad',
            small: 'Security',
        },
        performance_value: {
            title: 'Performance Score',
            color: 1,
            small: 'Performance',
        },
        health_score: {
            title: 'Health Score',
            color: 2,
            small: 'Health',
        },
        'l7_client.avg_client_txn_latency': {
            title: 'Client TXN Latency',
            color: 7,
        },
        'l4_client.avg_rx_pkts': {
            title: 'RX Packets',
            color: 4,
        },
        'l4_client.avg_tx_pkts': {
            title: 'TX Packets',
            color: 3,
        },
        'l4_client.avg_bandwidth': {
            title: 'Throughput',
            color: 1,
        },
        'l4_client.max_num_active_se': {
            title: '# Service Engines',
            color: 1,
        },
        health_anomaly_score: {
            title: 'Anomaly Score',
            color: 'bad',
        },
        'l4_server.avg_new_established_conns': {
            title: 'New Connections',
            color: 1,
        },
        'l7_client.avg_total_requests': {
            title: 'Total Requests',
            color: 3,
        },
        'l7_client.avg_complete_responses': {
            title: 'Requests',
            color: 1,
            chartSettings: {
                excludeTotals: true,
            },
        },
        'l7_server.avg_complete_responses': {
            title: 'Requests',
            color: 1,
        },
        'vm_stats.avg_net_usage': {
            title: 'Net Usage',
            color: 1,
        },
        'vm_stats.avg_net_received': {
            title: 'Net Received',
            color: 2,
        },
        'vm_stats.avg_cpu_usage': {
            title: 'CPU',
            color: 3,
        },
        'l4_client.pct_connection_errors': {
            title: 'Connection Errors',
            color: 4,
        },
        'l7_client.avg_resp_1xx': {
            title: '1xx',
            color: 1,
        },
        'l7_client.avg_resp_2xx': {
            title: '2xx',
            color: 2,
        },
        'l7_client.avg_resp_3xx': {
            title: '3xx',
            color: 3,
        },
        'l7_server.avg_resp_4xx_errors': {
            title: '4xx',
            color: 'bad',
        },
        'l7_server.avg_resp_5xx_errors': {
            title: '5xx',
            color: 'warning',
        },
        'l7_client.avg_resp_4xx_avi_errors': {
            title: 'Avi 4xx',
            color: 5,
        },
        'l7_client.avg_resp_5xx_avi_errors': {
            title: 'Avi 5xx',
            color: 7,
        },
        'server.vm_stats.avg_cpu_usage': {
            title: 'CPU',
            color: 4,
        },
        'server.vm_stats.avg_mem_usage': {
            title: 'Memory',
            color: 6,
        },
        'server.vm_stats.avg_virtual_disk_throughput_usage': {
            title: 'Disk Bandwidth',
            color: 7.2,
        },
        'vm_stats.avg_virtual_disk_throughput_usage': {
            title: 'Disk Bandwidth',
            color: 7.2,
        },
        'vm_stats.avg_mem_usage': {
            title: 'Memory',
            color: 6,
        },
        'l7_client.avg_ssl_auth_rsa': {
            title: 'RSA',
            color: 2,
        },
        'l7_client.avg_ssl_auth_ecdsa': {
            title: 'EC',
            color: 7,
        },
        'l7_client.avg_ssl_handshakes_non_pfs': {
            title: 'Non-PFS',
            color: 2,
        },
        'l7_client.avg_ssl_auth_dsa': {
            title: 'DSA',
            color: 4,
        },
        'l7_client.avg_ssl_rsa_pfs': {
            title: 'RSA PFS',
            color: 7,
        },
        'l7_client.avg_ssl_rsa_non_pfs': {
            title: 'Non-PFS',
            color: 6,
        },
        'l7_client.avg_ssl_non_pfs': {
            title: 'RSA Non-PFS',
            color: 6,
        },
        'l7_client.avg_ssl_ecdsa_pfs': {
            title: 'ECDSA PFS',
            color: 4,
        },
        'l7_client.avg_ssl_ecdsa_non_pfs': {
            title: 'ECDSA Non-PFS',
            color: 1,
        },
        'l7_client.avg_ssl_ver_tls10': {
            title: 'TLS 1.0',
            color: 6,
        },
        'l7_client.avg_ssl_ver_tls11': {
            title: 'TLS 1.1',
            color: 1,
        },
        'l7_client.avg_ssl_ver_tls12': {
            title: 'TLS 1.2',
            color: 7,
        },
        'l7_client.avg_ssl_ver_tls13': {
            title: 'TLS 1.3',
            color: 4,
        },
        'l7_client.avg_ssl_connections': {
            title: 'Transactions',
            color: 2,
            chartSettings: {
                excludeTotals: true,
            },
        },
        'l7_client.avg_ssl_handshakes_new': {
            title: 'New SSL Sessions',
            color: 1,
        },
        'l7_client.avg_ssl_handshakes_reused': {
            title: 'Reused SSL Sessions',
            color: 3,
        },
        'l7_client.avg_ssl_handshake_protocol_errors': {
            title: 'SSL Protocol Errors',
            color: 4,
        },
        'l7_client.avg_ssl_handshake_network_errors': {
            title: 'SSL Network Errors',
            color: 5,
        },
        'l7_client.avg_ssl_handshakes_timedout': {
            title: 'SSL Timeouts',
            color: 6,
        },
        'l7_client.avg_ssl_errors': {
            title: 'Failed Transactions',
            color: 2,
        },
        'l7_client.pct_ssl_failed_connections': {
            title: 'Failed Transactions',
            color: 2,
        },
        'l7_client.avg_ssl_handshakes_pfs': {
            title: 'PFS',
            color: 1,
        },
        'l7_client.avg_ssl_kx_ecdh': {
            title: 'ECDH',
            color: 2,
        },
        'l7_client.avg_ssl_kx_dh': {
            title: 'DH',
            color: 3,
        },
        'l7_client.avg_ssl_kx_rsa': {
            title: 'RSA',
            color: 4,
        },
        'l4_client.avg_network_dos_attacks': {
            title: 'L4 Attacks',
            color: 6,
        },
        'l4_client.avg_application_dos_attacks': {
            title: 'L7 Attacks',
            color: 2,
        },
        'dos.sum_attack_duration': {
            title: 'Attack Duration',
            color: 3,
        },
        'l4_client.avg_policy_drops': {
            title: 'Blocked Connections',
            color: 3,
        },
        avg_attack_count_attack: {
            title: 'Number of Attacks',
            color: 2,
        },
        avg_attack_count_ipgroup: {
            title: 'Number of Attacks',
            color: 2,
        },
        avg_attack_count_url: {
            title: 'Number of Attacks',
            color: 2,
        },
        avg_attack_count_blocked: {
            title: 'Blocked',
            color: 2,
        },
        attack_level: {
            title: 'Attack Level',
            color: 2,
        },
        'l4_client.avg_dos_attacks': {
            title: 'DoS Attacks',
            color: 2,
        },
        'l4_client.pct_pkts_dos_attacks': {
            title: 'Packets DoS Attacks',
            color: 2,
        },
        'l4_client.pct_application_dos_attacks': {
            title: 'Application DoS Attacks',
            color: 2,
        },
        'l4_client.pct_dos_rx_bytes': {
            title: 'DoS RX Bytes',
            color: 2,
        },
        'l4_client.pct_policy_drops': {
            title: 'Policy Drops',
            color: 2,
        },
        'l4_client.pct_connections_dos_attacks': {
            title: 'Connection DoS Attacks',
            color: 2,
        },
        'l4_client.pct_dos_bandwidth': {
            title: 'DoS Bandwidth',
            color: 2,
        },
        'l4_server.sum_lb_fail_count': {
            title: 'LB Fail Count',
            color: 2,
        },
        'l4_server.avg_est_capacity': {
            title: 'Estimated Capacity',
            color: 2,
        },
        'l4_server.avg_available_capacity': {
            title: 'Available Capacity',
            color: 1,
        },
        'dns_client.avg_complete_queries': {
            title: 'DNS Queries',
            color: 4,
            chartSettings: {
                excludeTotals: true,
            },
        },
        'dns_client.avg_resp_type_a': {
            title: 'A',
            color: 1,
        },
        'dns_client.avg_resp_type_aaaa': {
            title: 'AAAA',
            color: 2,
        },
        'dns_client.avg_resp_type_ns': {
            title: 'NS',
            color: 3,
        },
        'dns_client.avg_resp_type_srv': {
            title: 'SRV',
            color: 4,
        },
        'dns_client.avg_resp_type_mx': {
            title: 'MX',
            color: 6,
        },
        'dns_client.avg_resp_type_other': {
            title: 'Other',
            color: 7,
        },
        'dns_client.pct_errored_queries': {
            title: 'Errors (%)',
            color: 'bad',
        },
        'dns_client.avg_errored_queries': {
            title: 'Errors (/sec)',
            color: 'bad',
        },
        'dns_client.avg_tcp_queries': {
            title: 'TCP Queries',
            color: 2,
        },
        'dns_client.avg_udp_queries': {
            title: 'UDP Queries',
            color: 4,
        },
        'dns_client.avg_udp_passthrough_queries': {
            color: 1,
        },
        'dns_client.avg_tcp_passthrough_queries': {
            color: 2,
        },
        'dns_client.avg_udp_passthrough_resp_time': {
            title: 'UDP Passthrough Response Time',
            color: 3.2,
        },
        'dns_client.avg_local_responses': {
            color: 3,
        },
        'dns_client.avg_gslbpool_member_not_available': {
            title: 'GSLB Pool Member Not Available',
            color: 'bad',
        },
        'dns_client.avg_invalid_queries': {
            color: 2.2,
        },
        'dns_client.avg_domain_lookup_failures': {
            title: 'Invalid domains',
            color: 'warning',
        },
        'dns_client.avg_unsupported_queries': {
            color: 6,
        },
        'tenant_stats.avg_num_backend_servers': {
            title: 'Backend Servers',
            color: 2,
        },
        'tenant_stats.avg_num_se_cores': {
            title: 'SE Cores Available',
            color: 3,
        },
        'tenant_stats.max_total_se_throughput': {
            title: 'Throughput across SEs',
            color: 4,
        },
        'tenant_stats.sum_total_vs_client_bytes': {
            title: 'Throughput across Virtual Services',
            color: 5,
        },
        'tenant_stats.max_num_ses': {
            title: 'Max SE Hosts',
            color: 6,
        },
        'tenant_stats.avg_num_ses': {
            title: 'Average SE Hosts',
            color: 1.2,
        },
        'tenant_stats.max_num_sockets': {
            title: 'Max SE Sockets',
            color: 2.2,
        },
        'tenant_stats.avg_num_sockets': {
            title: 'Average SE Sockets',
            color: 3.2,
        },
        'l7_client.avg_waf_attacks': {
            title: 'WAF Attacks',
            color: 4.2,
        },
        'l7_client.pct_waf_attacks': {
            title: 'WAF Attacks, %',
            color: 7.2,
        },
        'l7_client.avg_total_http2_requests': {
            title: 'HTTP2 Requests',
            color: 1.2,
        },
    };

    const regularColors = [
        'green',
        'blue',
        'purple',
        'grape',
        'yellow',
        'fuchsia',
        'green-two',
        'blue-two',
        'purple-two',
        'grape-two',
        'fuchsia-two', //10
    ];

    helper.numToString = {
        1: regularColors[0], // green
        2: regularColors[1], // blue
        3: regularColors[2], // purple
        4: regularColors[3], // grape
        5: 'grey',
        6: regularColors[4], // yellow
        7: regularColors[5], // fuchsia
        1.2: regularColors[6], // green-two
        2.2: regularColors[7], // blue-two
        3.2: regularColors[8], // purple-two
        4.2: regularColors[9], // grape-two
        7.2: regularColors[10], // fuchsia-two
        bad: 'bad',
        warning: 'warning',
    };

    /**
     * Returns color class name buy group index.
     * @param {number=} index
     * @returns {string}
     * @public
     */
    helper.getColorClassNameByGroupIndex = function(index = 0) {
        const
            { length: numberOfColors } = regularColors,
            groupIndex = index - Math.floor(index / numberOfColors) * numberOfColors;

        return `chart-color-${regularColors[groupIndex]}`;
    };

    /**
     * filter out word indexes to use only numbered when need to automatically assign
     * colors to series.
     */
    helper.colorsList = _.filter(Object.keys(helper.numToString), function(index) {
        return !_.isNaN(+index);
    });

    //for collection metrics
    helper.totalSeriesTitles = {
        end_to_end: {
            title: 'End to End Timing',
            color: 1,
        },
        se_throughput_by_vs: {
            title: 'Virtual Service Throughput',
            color: 7.2,
        },
        se_throughput_by_interface: {
            title: 'Interface Throughput',
            color: 7,
        },
        vs_certificate_key_exchange: {
            title: 'Certificate',
            color: 2,
        },
        certificate_pie_chart: {
            title: 'Certificate',
            color: 2,
        },
        vs_ssl_tls_version: {
            title: 'TLS Version',
            color: 3,
        },
        avg_ssl_connections: {
            title: 'Transactions',
            color: 2,
        },
        avg_ssl_errors: {
            title: 'Failed Transactions',
            color: 2,
        },
        vs_key_exchange: {
            title: 'Key Exchange',
            color: 3,
        },
        avg_attack_count: {
            title: 'Attack Count',
            color: 3,
        },
        avg_attack_count_attack: {
            title: 'Attack Rate',
            color: 2,
        },
        avg_attack_count_ipgroup: {
            title: 'Attack Rate',
            color: 2,
        },
        avg_attack_count_url: {
            title: 'Attack Rate',
            color: 2,
        },
        avg_attack_count_asn: {
            title: 'Attack Rate',
            color: 2,
        },
        avg_attack_count_blocked: {
            title: 'Attack Rate',
            color: 2,
        },
        rx: {
            title: 'Rx Packets',
        },
        tx: {
            title: 'Tx Packets',
        },
        dns_client_avg_complete_queries: {
            title: 'DNS Query Types',
            color: 4,
        },
        dns_client_avg_queries_by_type: {
            title: 'TCP vs UDP',
            color: 1,
        },
        dns_pass_through_or_locally: {
            title: 'Local vs Passthrough Queries',
            color: 3,
        },
        avg_ssl_pfs: {
            title: 'PFS vs Non-PFS Traffic',
            color: 6,
        },
        http_1x_vs_2: {
            title: 'HTTP vs HTTP2 Requests',
        },
    };

    /**
     * to update labels list on fly when we have dynamic set of metrics and their Ids
     * group can be used to use NamesHelper to define different colors within a group
     * @param objHash {object} hash of objects to update/replace config objects at dictionary
     * {fullMetricId : {title: text, color: number, group: text}}
     *
     */
    helper.updateDict = function(objHash) {
        //number of items of that group to pick up next color from list of available colors
        const
            self = this,
            groupLengthDict = {};

        _.each(objHash, function(obj, index) {
            let colorIndex;

            if (!self.dict[index]) {
                if (!obj.color && obj.group) {
                    if (!groupLengthDict[obj.group]) {
                        groupLengthDict[obj.group] = _.filter(self.dict, function(cObj) {
                            return cObj.group && cObj.group === obj.group;
                        }).length;
                    }

                    if (groupLengthDict[obj.group] < self.colorsList.length) {
                        colorIndex = groupLengthDict[obj.group];
                    } else {
                        colorIndex = groupLengthDict[obj.group] % self.colorsList.length;
                    }

                    obj.color = self.colorsList[colorIndex];
                    groupLengthDict[obj.group]++;
                }

                self.dict[index] = obj;
            }
        });
    };

    /**
     * Removes some dynamically added titles from dictionary. Called by metrics object.
     * If group is provided, will delete only config having this particular group.
     * @param objHash {object} hash of objects to remove from dict {fullMetricId : {group: text}}
     */
    helper.removeFromDict = function(objHash) {
        const self = this;

        _.each(objHash, function(obj, index) {
            if (self.dict[index] && (!obj.group || obj.group === self.dict[index].group)) {
                delete self.dict[index];
            }
        });
    };

    /**
     * Returns a title text for the passed series names. Checks both hashes for defined title or
     * makes some basic transformations over it's name trying to get a human readable string out
     * of it.
     * @param {string} seriesName
     * @param {boolean=} noFallback - By design method is returning seriesName if nothing was
     *     found in dictionary. Passing true can switch it to return empty string when nothing
     *     was found.
     * @returns {string} - Series title text
     */
    helper.getTitle = function(seriesName, noFallback = false) {
        let title = '';

        if (seriesName && typeof seriesName === 'string') {
            if (seriesName in this.dict || seriesName.toLowerCase() in this.dict) {
                title = (this.dict[seriesName] || this.dict[seriesName.toLowerCase()]).title;
            }

            if (!title && !noFallback) {
                const parts = seriesName.split('.');

                if (parts.length === 2) {
                    const notWanted = {
                        sum: true,
                        avg: true,
                        pct: true,
                    };

                    title = _.reduce(parts[1].split('_'), function(memo, s) {
                        if (!s.length) {
                            return;
                        }

                        // No pct, sum, avg
                        if (s in notWanted) {
                            return memo;
                        }

                        if (s in this.commonDict) {
                            return `${memo} ${this.commonDict[s]}`;
                        }

                        return memo + (memo ? ' ' : '') + s.slice(0, 1).toUpperCase() + s.slice(1);
                    }, '', this);
                } else {
                    title = seriesName;
                }
            }
        }

        return title;
    };

    /**
     * Looks up a color name to be used for chart's layout. Returns blue when not found.
     * @param {string} seriesName
     * @returns {string}
     */
    helper.getColorClass = function(seriesName) {
        const res = seriesName && typeof seriesName === 'string' &&
            this.totalSeriesTitles[seriesName] ||
            this.totalSeriesTitles[seriesName.toLowerCase()] ||
            this.dict[seriesName] || this.dict[seriesName.toLowerCase()];

        return ` chart-color-${res && 'color' in res ? this.numToString[res.color] : 'blue'}`;
    };

    /**
     * Gets chartSettings of series.
     * @param  {string} name - Name of series.
     * @return {{}|null}
     */
    helper.getChartSettings = function(name) {
        const res = this.dict[name];

        return res && res.chartSettings ? angular.copy(res.chartSettings) : null;
    };

    /**
     * Returns a header string to show for the related metric representation.
     * @param {string} mName - {@link Metric#name}.
     * @returns {string|undefined}
     */
    helper.totalSeriesTitle = function(mName) {
        return this.totalSeriesTitles[mName] && this.totalSeriesTitles[mName].title || '';
    };

    return helper;
});
