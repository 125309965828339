/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('IpAddrGroupCreateController', [
'$scope', 'Regex', 'Base', 'CloudCollection',
function($scope, Regex, Base, CloudCollection) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Regex = Regex;
    $scope.cloudCollection = new CloudCollection({ isStatic: true });

    const base = new Base();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance declared
     * above.
     */
    $scope.serversGrid = {
        fields: [{
            name: 'ip_addr',
            title: 'IP Address',
            template: '{{row.addr}}',
        }],
        rowId: 'addr',
        searchFields: ['addr'],
        multipleactions: [{
            title: 'Remove',
            do(servers) {
                $scope.editable.data.config.servers =
                    _.filter($scope.editable.data.config.servers, function(row) {
                        return !_.find(servers, function(server) {
                            return row == server;
                        });
                    });

                return true;
            },
        }],
    };

    $scope.init = function() {
        let apicEpgName;

        $scope.ui = {
            newAddr: {},
            select: 'ip',
            busy: false,
            disableGrid: false,
            countryCodes: undefined,
            apicepgs: undefined,
        };

        if ($scope.editable.data.config.country_codes) {
            $scope.ui.select = 'country_code';
        } else if ($scope.editable.data.config.apic_epg_name) {
            $scope.ui.select = 'epg';
            $scope.ui.disableGrid = true;
            apicEpgName = $scope.editable.data.config.apic_epg_name.split(':');

            if (apicEpgName.length === 3) {
                $scope.editable.data.config.apic_epg_name =
                    `${apicEpgName[1]}:${apicEpgName[2]}`;
            }
        }

        base.request('GET', '/api/geoipdb/').then(function(rsp) {
            if (rsp && rsp.data) {
                $scope.ui.countryCodes = _.sortBy(rsp.data.country, 'name');
            }
        });

        base.request('GET', '/api/apicepgs/all').then(function(rsp) {
            if (rsp && rsp.data) {
                $scope.ui.apicepgs = rsp.data;
            }
        });

        $scope.cloudCollection.load().then(function() {
            $scope.ui.apic_mode = _.any($scope.cloudCollection.items, function(cloud) {
                return cloud.data.config.apic_mode === true;
            });
        });
    };

    $scope.importFile = function() {
        let containsValidIPs = false;

        $scope.editable.errors = '';

        _.each($scope.ui.newAddr.file.split('\n'), function(addr) {
            addr = addr.trim();

            if (addr && addr.match(Regex.anyIPIPSubnetIPv4RangeList)) {
                $scope.addAddr(addr);
                containsValidIPs = true;
            }
        });

        if (!containsValidIPs) {
            $scope.editable.errors = 'File does not contain any valid IP addresses';
        }

        $scope.ui.newAddr.file = '';
    };

    $scope.addAddr = function(addr) {
        $scope.ui.disableGrid = false;
        $scope.editable.errors = '';

        if (addr == null || _.isUndefined(addr) || addr === '') {
            return;
        }

        addr = addr.trim();

        if (!$scope.editable.data.config.servers) {
            $scope.editable.data.config.servers = [];
        }

        if (duplicateAddress(addr)) {
            $scope.editable.errors = 'IP Address, Range, or Mask already exists.';
        } else {
            $scope.editable.data.config.servers.push({
                addr,
            });
        }

        $scope.ui.newAddr = {};
    };

    $scope.selectEPG = function() {
        $scope.editable.data.config.servers = [];
        $scope.editable.data.config.tenant = $scope.Auth.context.tenant_ref.name();
        $scope.ui.busy = true;
        base.request('GET', `api/apicepgeps/?epg_name=${$scope.editable.data.config.apic_epg_name}`)
            .then(function(rsp) {
                const serverList = rsp.data;

                _.each(serverList, function(server) {
                    $scope.editable.data.config.servers.push({
                        addr: server.ep_ip.addr,
                    });
                });
                $scope.ui.disableGrid = true;
                $scope.ui.busy = false;
            }).catch(function(errors) {
                $scope.editable.errors = errors.data;
                $scope.ui.busy = false;
            });
    };

    $scope.clearOptions = function() {
        $scope.ui.disableGrid = false;
        $scope.ui.newAddr.file = '';
        $scope.ui.newAddr = {};

        if ($scope.editable.data.config.country_codes) {
            delete $scope.editable.data.config.country_codes;
        }

        if ($scope.editable.data.config.apic_epg_name) {
            delete $scope.editable.data.config.apic_epg_name;
        }

        $scope.editable.data.config.servers = [];
    };

    /**
     * Returns true if address already exists in the list of server addresses.
     * @param  {string} address - IP address, range, or mask.
     * @return {boolean}
     */
    function duplicateAddress(address) {
        return _.some($scope.editable.data.config.servers, function(server) {
            return server.addr === address;
        });
    }

    $scope.$on('$destroy', function() {
        $scope.cloudCollection.destroy();
        base.cancelRequests();
    });
}]);
