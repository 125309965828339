/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @alias module:match/hostHdrMatch
 * @private
 */
class HostHdrMatchComponent {
    constructor(dropDownUtils, Regex) {
        this.criteria = dropDownUtils.getEnumDropdownOptions('HdrMatchOperation');
        this.Regex = Regex;
    }
}

HostHdrMatchComponent.$inject = [
    'dropDownUtils',
    'Regex',
];

/**
 * @ngdoc component
 * @name  hostHdrMatch
 * @module match/hostHdrMatch
 * @description Component for configuring a HostHdrMatch Config Item.
 * @param {HostHdrMatchConfigItem} hostHdrMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('hostHdrMatch', {
    bindings: {
        hostHdrMatch: '<',
        preventEdit: '<',
    },
    controller: HostHdrMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/' +
        'host-hdr-match/host-hdr-match.component.html',
});
