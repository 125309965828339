/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AppMapVS
 * @author Alex Malitsky
 * @description
 *
 *     VirtualServices for the sidebar of AppMap VS tab. Have very tiny data#config with uuid,
 *     name, nodeType and rootNodeUuid only. Tuple used by Collection Metrics calls depends on
 *     nodeType since actually entity_uuid is a target Id and pool_uuid is a source Id.
 *
 */
angular.module('aviApp').factory('AppMapVS', [
'UpdatableItem', function(UpdatableItem) {
    function AppMapVS(args) {
        this.constructor.superconstructor.call(this, args);
    }

    avi.inherit(AppMapVS, UpdatableItem);

    /** @override */
    AppMapVS.prototype.getIdFromData_ = function(data) {
        return data && data.config && data.config.uuid || '';
    };

    /** @override */
    AppMapVS.prototype.getMetricsTuple = function() {
        const
            tuple = {},
            {
                uuid,
                rootNodeUuid,
                groupId,
            } = this.getConfig();

        if (groupId === 'client') {
            tuple['entity_uuid'] = rootNodeUuid; //target
            tuple['pool_uuid'] = uuid; //source
        } else {
            tuple['entity_uuid'] = uuid;
            tuple['pool_uuid'] = rootNodeUuid;
        }

        return tuple;
    };

    return AppMapVS;
}]);
