/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafExcludeListEntryConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/WafExcludeListEntryConfigItem
     * @private
     */
    class WafExcludeListEntryConfigItem extends MessageItem {
        /**
         * @constructor
         */
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafExcludeListEntry',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten_() {
            return this.isValid();
        }

        /**
         * Returns a string representation of the subnet.
         * @return {string}
         */
        getSubnet() {
            const { client_subnet: clientSubnet } = this.config;
            const subnetString = clientSubnet && clientSubnet.subnet;

            return subnetString || 'Any client IP subnet';
        }

        /**
         * Returns a string representation of the path.
         * @return {string}
         */
        getPath() {
            const { uri_path: uriPath } = this.config;

            return !angular.isUndefined(uriPath) ? `PATH=${uriPath}` : 'any URI Path';
        }

        /**
         * Returns a string representation of the match element.
         * @return {string}
         */
        getMatchElement() {
            const { match_element: matchElement } = this.config;

            return !angular.isUndefined(matchElement) ?
                `Match Element=${matchElement}` :
                'any Match Element';
        }

        /**
         * Outputs a string representation of the configured fields.
         * @return {string}
         */
        getString() {
            const output = [];

            output.push(this.getSubnet(), this.getPath(), this.getMatchElement());

            return output.join(', and ');
        }

        /**
         * Returns true if the exclude list entry has any field populated.
         * @return {boolean}
         */
        isValid() {
            const { client_subnet: clientSubnet } = this.config;
            const fields = [
                'uri_path',
                'match_element',
            ];

            return _.any(fields, field => !_.isUndefined(this.config[field])) ||
                (clientSubnet && clientSubnet.isValid());
        }

        /**
         * Returns true if an exception matches the configured exception.
         * @param {Object} exception - Exception containing subnet, path, and match element.
         * @return {boolean}
         */
        hasMatchingException(exception) {
            const { config } = this;

            return WafExcludeListEntryConfigItem.hasMatchingException(exception, config) > -1;
        }

        /**
         * Checks to see if an exception alredy exists within a list of exceptions. Returns an
         * index >= 0 if exists, otherwise returns -1.
         * @static
         * @param {Object} exception - contains client_subnet, uri_path, and match_element
         *     properties.
         * @param {Object|Object[]} exceptions - Either a single exception or a list of
         *     exceptions.
         * @return {number}
         */
        static hasMatchingException(exception, exceptions = []) {
            const exceptionsList = angular.isArray(exceptions) ? exceptions : [exceptions];
            const strippedException = _.pick(exception, _.identity);

            return _.findIndex(exceptionsList, configuredException => {
                const strippedConfiguredException = configuredException instanceof MessageItem ?
                    configuredException.getDataToSave() :
                    _.pick(configuredException, _.identity);

                return _.isEqual(strippedConfiguredException, strippedException);
            });
        }

        /**
         * @override
         */
        get requiredFields_() {
            return [
                'client_subnet',
                'uri_match_criteria',
                'match_element_criteria',
            ];
        }

        /**
         * Returns the uri_match_criteria configItem config object.
         * @returns {MessageItemData.config}
         */
        getUriMatchCriteriaConfig() {
            return this.config.uri_match_criteria && this.config.uri_match_criteria.config;
        }

        /**
         * Returns the match_element_criteria configItem config object.
         * @returns {MessageItemData.config}
         */
        getMatchElementCriteriaConfig() {
            return this.config.match_element_criteria && this.config.match_element_criteria.config;
        }
    }

    return WafExcludeListEntryConfigItem;
};

wafExcludeListEntryConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafExcludeListEntryConfigItem
 * @description  WafExcludeListEntry MessageItem class.
 * @module config-item/WafExcludeListEntryConfigItem
 * @author alextsg
 */
angular
    .module('aviApp')
    .factory('WafExcludeListEntryConfigItem', wafExcludeListEntryConfigItemFactory);
