/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @name defaultChartConfig
 * @description
 *      Defines basic configuration for Chart component.
 */
angular.module('charts').constant('defaultChartConfig', {
    width: 0,
    height: 0,
    padding: {
        left: 0,
        right: 0,
        top: 1,
        bottom: 1,
    },
    axis: {
        x: {
            type: 'linear',
            visible: true,
            domain: [],
        },
        y0: {
            type: 'linear',
            visible: true,
            domain: [],
        },
        y1: {
            visible: false,
        },
    },
});
