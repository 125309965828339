/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  HttpPolicySet
 * @description  HttpPolicySet item.
 */
function HttpPolicySetFactory(Item, HttpPolicy) {
    class HttpPolicySet extends Item {
        constructor(args) {
            super(args);

            const baseMatches = [
                'client_ip',
                'vs_port',
                'protocol',
                'method',
                'version',
                'path',
                'query',
                'hdrs',
                'cookie',
                'host_hdr',
            ];

            this.data['config'] = this.getDefaultConfig_() || {};

            this.policies = {
                http_request_policy: new HttpPolicy({
                    matches: baseMatches.concat(),
                    actions: [
                        'redirect_action',
                        'hdr_action',
                        'rewrite_url_action',
                        'switching_action',
                    ],
                }),
                http_response_policy: new HttpPolicy({
                    matches: baseMatches.concat([
                        'loc_hdr',
                        'status',
                        'rsp_hdrs',
                    ]),
                    actions: [
                        'hdr_action',
                        'loc_hdr_action',
                    ],
                }),
                http_security_policy: new HttpPolicy({
                    matches: baseMatches.concat(),
                    actions: [
                        'action',
                    ],
                }),
            };
        }

        /**
         * Override to populate HttpPolicy instances in this.policies with the new data.
         * @param {Object} data - HttpPolicySet data.
         * @override
         */
        updateItemData(data) {
            super.updateItemData(data);

            const config = this.getConfig();

            _.each(this.policies,
                (policy, property) => policy.updateData(config[property]));
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            config.index = config.index || 11;

            _.each(this.policies, (policyObj, policyName) => {
                config[policyName] = policyObj.dataToSave();
            });

            return config;
        }

        /**
         * Returns true if any httpPolicySet policies contain any rules.
         */
        hasRules() {
            return _.any(this.policies, policy => policy.hasRules());
        }
    }

    angular.extend(HttpPolicySet.prototype, {
        objectName: 'httppolicyset',
    });

    return HttpPolicySet;
}

HttpPolicySetFactory.$inject = [
        'Item',
        'HttpPolicy',
];

angular.module('aviApp').factory('HttpPolicySet', HttpPolicySetFactory);
