/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './add-pool-servers.less';

const types = {
    pool: {
        placeholder: 'sub.corp.com, 1.2.3.4, 1.2.3.4-1.2.3.10, 1.2.3.4:80, ' +
            '2001::1, [2001::1]:80',
        inputRegex: null,
        ipListRegex: null,
    },
    vs: {
        placeholder: 'sub.corp.com, 1.2.3.4, 1.2.3.4-1.2.3.10, 2001::1', //no ports here
        inputRegex: null,
        ipListRegex: null,
    },
};

let
    poolServersLimit,
    DNS,
    regex,
    getIpAddr,
    rangeParser;

class AddPoolServerController {
    constructor($element, _DNS_, _regex_, _RangeParser_, _getIpAddr_, _poolServersLimit_) {
        poolServersLimit = _poolServersLimit_;
        DNS = _DNS_;
        regex = _regex_;
        rangeParser = _RangeParser_;
        getIpAddr = _getIpAddr_;

        this.resolutionCall_ = null;
        this.resolutionCallId_ = 0;

        this.$element_ = $element;

        /**
         * When domain name is provided by user this list will have IPs it resolves to.
         * @type {string[]}
         */
        this.resolutions = [];

        const {
            pool: poolRegExps,
            vs: vsRegExps,
        } = types;

        poolRegExps.inputRegex = regex.anyIPIPPortIPv4RangeHostnameList;
        poolRegExps.ipListRegex = regex.anyIPIPPortIPv4RangeList;

        vsRegExps.inputRegex = regex.anyIPIPv4RangeHostnameList;
        vsRegExps.ipListRegex = regex.anyIPIPv4RangeList;

        [
            'removeFormControlFromParent_',
            'serverStrToNumberOfIPs_',
            'serverStrToServers_',
        ].forEach(methodName => this[methodName] = this[methodName].bind(this));
    }

    /**
     * Converts IP address, range or domain name into the number of underlying IPs. To be
     * passed into reduce.
     * @param {number} acc
     * @param {string} serverStr - String value of IP, range or domain name.
     * @returns {number}
     * @private
     */
    serverStrToNumberOfIPs_(acc, serverStr) {
        if (regex.anyIPIPv4Range.test(serverStr)) {
            acc += rangeParser.getNumberOfIpsFromRange(serverStr);
        } else if (regex.anyIPPort.test(serverStr)) {
            acc++;
        } else if (regex.hostname.test(serverStr)) {
            acc += this.resolutions.length || 1;
        }

        return acc;
    }

    /**
     * Removes all whitespaces from a passed string and splits the result by comma.
     * @param {string} str
     * @returns {string[]}
     * @static
     * @private
     */
    static strValueToList_(str) {
        return str.replace(/\s/g, '').split(',');
    }

    /**
     * Checks number of IPs concealed in a string provided by the user. If more then allowed
     * will send an error message up the chain.
     * @param {string[]} serverStrList - Strings with IPs, ranges or IP:port.
     * @returns {boolean}
     * @private
     */
    checkQuantity_(serverStrList) {
        const
            { max } = this,
            numberOfIps = serverStrList.reduce(this.serverStrToNumberOfIPs_, 0);

        let errMsg = '';

        if (numberOfIps > max) {
            errMsg = `Too many (${numberOfIps}) IP addresses. ` +
                `Can add up to ${max} addresses. Up to ${poolServersLimit} in total.`;
        }

        //removes error message when everything is ok
        this.onError({ error: errMsg });

        return numberOfIps && numberOfIps <= max || false;
    }

    /**
     * Function to be passed into map to convert strings into list of ServerConfigs.
     * @param {ServerConfig[]} servers
     * @param {string} serverStr - Strings with IPs, ranges or IP:port.
     * @returns {ServerConfig[]}
     * @private
     */
    serverStrToServers_(servers, serverStr) {
        let chunks;

        if (regex.ipAddrRange.test(serverStr)) {
            servers.push(
                ...rangeParser.getIpsFromRange(serverStr).map(ip => ({ ip })),
            );
        } else if (chunks = regex.ipAddrWithPortWG.exec(serverStr)) {
            servers.push({
                ip: getIpAddr(chunks[1]),
                port: +chunks[2],
            });
        } else if (chunks = regex.ipv6Port.exec(serverStr)) {
            servers.push({
                ip: getIpAddr(chunks[2], 'V6'),
                port: +chunks[3],
            });
        } else if (regex.ip.test(serverStr)) {
            servers.push({
                ip: getIpAddr(serverStr, 'V4'),
            });
        } else if (regex.ipv6.test(serverStr)) {
            servers.push({
                ip: getIpAddr(serverStr, 'V6'),
            });
        } else if (regex.hostname.test(serverStr)) {
            const hostname = serverStr;

            if (!this.resolutions.length) {
                servers.push({
                    hostname,
                    ip: getIpAddr('0.0.0.0'),
                });
            } else {
                this.resolutions.forEach(ip =>
                    servers.push({
                        hostname,
                        ip: getIpAddr(ip),
                    }));
            }
        }

        return servers;
    }

    /**
     * Reads the user input, calculates number of IPs and if lower than allowed generates
     * server config objects out of them.
     * @public
     */
    submit() {
        const serverStrList = AddPoolServerController.strValueToList_(this.serversStr);

        if (this.checkQuantity_(serverStrList)) {
            const servers = serverStrList.reduce(this.serverStrToServers_, []);

            this.onSubmit({ servers });
            this.serversStr = '';
            this.resolutions.length = 0;

            this.focusInputElement_();
        }
    }

    /**
     * Makes an API call to resolve a domain name provided by user. Cancels previous call is
     * there is one ongoing.
     * @public
     */
    getDomainNameResolutions() {
        this.stopResolution_();

        this.resolutions.length = 0;

        const
            ipListRegExp = this.getIPListRegExp_(),
            { serversStr, cloudId } = this;

        if (!ipListRegExp.test(serversStr) && regex.hostname.test(serversStr)) {
            const resolutionCallId = ++this.resolutionCallId_;

            this.resolutionCall_ = DNS.lookupDelayed(serversStr, cloudId);

            this.resolutionCall_.promise
                .then(ips => {
                    if (ips && ips.length && ips[0] !== serversStr) {
                        this.resolutions.push(...ips);
                    }
                })
                .finally(() => {
                    if (resolutionCallId === this.resolutionCallId_) {
                        this.resolutionCall_ = null;
                    }
                });
        }
    }

    /**
     * @returns {boolean}
     * @public
     */
    submitIsDisabled() {
        return this.form.$invalid || !this.serversStr ||
            this.resolutionInProgress() || !this.max;
    }

    /**
     * Different for each type and special when there is no room for new servers.
     * @returns {string}
     * @public
     */
    getPlaceholder() {
        return this.max ? types[this.type].placeholder :
            `Reached the limit (${poolServersLimit}) of Servers in a Pool`;
    }

    /**
     * Different regexp is used for each type.
     * @returns {RegExp}
     * @public
     */
    getInputRegExp() {
        return types[this.type].inputRegex;
    }

    /**
     * We need to differenciate between list of IPs or Ranges and domain name. This returns
     * appropriate RegExp for IP, IP range and IP:port list.
     * @returns {RegExp}
     * @private
     */
    getIPListRegExp_() {
        return types[this.type].ipListRegex;
    }

    /**
     * @returns {boolean}
     * @public
     */
    resolutionInProgress() {
        return !!this.resolutionCall_;
    }

    /** @private */
    focusInputElement_() {
        this.$element_.find('input.servers-str').trigger('focus');
    }

    /** @private */
    stopResolution_() {
        if (this.resolutionCall_) {
            this.resolutionCall_.cancel();
            this.resolutionCall_ = null;
        }
    }

    /**
     * This component uses form directive but we don't want to affect the parent form which
     * might be present up the DOM tree.
     * @private
     */
    removeFormControlFromParent_() {
        if (this.parentForm) {
            this.parentForm.$removeControl(this.form);
        }
    }

    $onInit() {
        setTimeout(this.removeFormControlFromParent_, 5);
    }

    $onDestroy() {
        this.stopResolution_();
    }
}

AddPoolServerController.$inject = [
    '$element',
    'DNS',
    'Regex',
    'RangeParser',
    'getIpAddr',
    'poolServersLimit',
];

/**
 * @ngdoc component
 * @name addPoolServers
 * @author Alex Malitsky, Chitra
 * @param {string=} cloudId - current cloud's uuid.
 * @param {number} max - Max number of servers allowed to be added.
 * @param {Function} onError - To be called with "error" argument to pass error message up the
 *     chain.
 * @param {Function} onSubmit - To be called with servers as argument to pass ServerConfig objects
 *     up the chain.
 * @param {string} type - "vs" or "pool"
 * @description
 *
 *     Smart text input with "add Servers" button for VS Basic Create and Pool Create. Takes a
 *     list of IPs, IP ranges, IP with port or single(!) domain name. Converts them into the
 *     ServerConfig objects controlling only total number and passes em up the chain.
 *
 *     VS basic and Pool are using different regular expressions and placeholders.
 *
 *     Throws an error only if there are more servers than allowed.
 */
angular.module('aviApp').component('addPoolServers', {
    bindings: {
        cloudId: '<?',
        max: '<?',
        onError: '&',
        onSubmit: '&',
        type: '@',
    },
    controller: AddPoolServerController,
    templateUrl: 'src/components/forms/inputs/add-pool-servers/add-pool-servers.html',
    require: {
        parentForm: '?^^form',
    },
});
