/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const componentName = 'avi-form-label-tooltip';

const template =
    `<div class="${componentName}__value ${componentName}__desc">{{::$ctrl.description}}</div>
    <div ng-if="::$ctrl.range">
        <div class="${componentName}__header">Range</div>
        <div class="${componentName}__value">{{::$ctrl.range}}</div>
    </div>
    <div ng-if="::$ctrl.specialValues">
        <div class="${componentName}__header">Special values</div>
        <div class="${componentName}__value">{{::$ctrl.specialValues}}</div>
    </div>`;

class FormLabelTooltipController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$element.addClass(componentName);
    }
}

FormLabelTooltipController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name aviFormLabelTooltip
 * @author Alex Malitsky
 * @param {string} description - arbitrary text to be rendered
 * @param {string=} range - Values range to be rendered (when applicable)
 * @param {string=} specialValues - Special field values to be rendered (when applicable)
 * @desc
 *
 *     Form label tooltip/hint component.
 *
 *     Can be used by {@link aviFormLabel} to render arbitrary text or object field options
 *     coming from {@link Schema}, such as description, range and special values.
 */
// TODO we need to pass special values as hash and render it here as a list
angular.module('aviApp').component('aviFormLabelTooltip', {
    bindings: {
        description: '@',
        range: '@?',
        specialValues: '@?',
    },
    controller: FormLabelTooltipController,
    template,
});
