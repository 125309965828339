/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Message of the Day controller class.
 */
class Motd {
    constructor(Auth) {
        this.Auth = Auth;
    }
}

Motd.$inject = ['Auth'];

/**
 * @ngdoc component
 * @name motd
 * @restrict E
 * @description
 *      Message of the Day popup.
 */
angular.module('aviApp').component('motd', {
    bindings: {
        closeModal: '&',
    },
    controller: Motd,
    templateUrl: 'src/components/common/motd/motd.html',
});
