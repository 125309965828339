/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function vsInventoryDataTransformerFactory(
    InventoryDataTransformer,
    itemAlertDataTransform,
    FaultService,
) {
    /**
     * @class VSInventoryDataTransformer
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @desc
     *
     *     Parses response faults, alerts and modifies search param value to search through
     *     multiple fields.
     *
     * @author Alex Malitsky
     */
    class VSInventoryDataTransformer extends InventoryDataTransformer {
        /** @override */
        processResponse(resp, request) {
            resp = this.responseListOffsetControl_(resp, request);

            const { data } = resp;

            if (Array.isArray(data.results)) {
                data.results.forEach(result => {
                    if ('faults' in result) {
                        result.faults = FaultService.parseFaults(result.faults);
                    }

                    if ('alert' in result) {
                        itemAlertDataTransform(result.alert);
                    }
                });
            }

            return resp;
        }
    }

    return VSInventoryDataTransformer;
}

vsInventoryDataTransformerFactory.$inject = [
    'InventoryDataTransformer',
    'itemAlertDataTransform',
    'FaultService',
];

angular.module('aviApp')
    .factory('VSInventoryDataTransformer', vsInventoryDataTransformerFactory);
