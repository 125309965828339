/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPolicyWhitelistRuleFactory = (MessageItem, defaultValues) => {
    const supportedMatches = ['client_ip', 'method', 'path', 'host_hdr'];

    /**
     * @alias module:config-item/WafPolicyWhitelistRuleConfigItem
     * @private
     */
    class WafPolicyWhitelistRuleConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPolicyWhitelistRule',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * We want to remove 'match' from the default configuration so that the user can add matches
         * manually.
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            const type = this.objectType_.toLowerCase();
            const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
            const { match, ...defaults } = defaultConfig;

            return {
                ...defaults,
                match: null,
            };
        }

        /**
         * Returns the list of supported matches. TODO: refactor to use Schema when
         * "match_target_options" is available.
         * @returns {string[]}
         */
        get supportedMatches() {
            return supportedMatches;
        }

        /**
         * Returns the number of matches configured.
         * @returns {number}
         */
        get matchCount() {
            return this.supportedMatches.filter(this.hasMatchByField).length;
        }

        /**
         * Returns the enable state.
         * @returns {boolean}
         */
        get enable() {
            return this.config.enable;
        }

        /**
         * Sets the enable state.
         * @param {boolean} enabled - New state to set.
         */
        set enable(enabled) {
            this.config.enable = enabled;
        }

        /**
         * Returns true if the match exists in this.config.match.
         * @param {string} matchFieldName - Match field name.
         * @returns {boolean}
         */
        hasMatchByField = matchFieldName => {
            return this.config.match ? this.config.match.hasMatchByField(matchFieldName) : false;
        }
    }

    return WafPolicyWhitelistRuleConfigItem;
};

wafPolicyWhitelistRuleFactory.$inject = [
    'MessageItem',
    'defaultValues',
];

/**
 * @ngdoc factory
 * @name  WafPolicyWhitelistRuleConfigItem
 * @description  WafPolicyWhitelist ConfigItem class.
 * @module config-item/WafPolicyWhitelistRuleConfigItem
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPolicyWhitelistRuleConfigItem', wafPolicyWhitelistRuleFactory);
