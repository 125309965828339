/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('AuthenticationTypeController', [
'$scope', 'AviModal', 'AuthProfileCollection', 'systemConfigService',
function($scope, AviModal, AuthProfileCollection, systemConfig) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    const vm = this;

    vm.settings = null;
    vm.authProfileCollection = new AuthProfileCollection();

    vm.ui = {
        busy: true,
        localAuth: true,
        errors: null,
    };

    /**
     * Init function to be called when modal opens.
     */
    $scope.init = function() {
        vm.settings = angular.copy(systemConfig.getConfig());

        if (vm.settings['admin_auth_configuration']) {
            vm.ui.localAuth = false;
        }

        vm.ui.busy = false;
    };

    /**
     * Sets the admin_auth_configuration object based on 'local' or 'ldap' binding.
     */
    vm.setAuthType = function(type) {
        const { settings } = vm;

        switch (type) {
            case 'local':
                vm.ui.localAuth = true;
                settings.admin_auth_configuration = undefined;
                break;
            case 'remote': {
                vm.ui.localAuth = false;

                const authConfig = settings.admin_auth_configuration || {};

                if (angular.isUndefined(authConfig.allow_local_user_login)) {
                    authConfig.allow_local_user_login = true;
                }

                settings.admin_auth_configuration = authConfig;
                break;
            }
        }
    };

    /**
     * Saves Auth type setting with a request through Auth service.
     */
    vm.saveAuthType = function() {
        vm.ui.busy = true;
        vm.ui.errors = null;

        systemConfig.legacySave(vm.settings).then(() => {
            $scope.onSuccess();
            this.closeModal();
        }).catch(() => {
            this.ui.errors = systemConfig.errors;
        }).finally(() => {
            this.ui.busy = false;
        });
    };

    vm.closeModal = function() {
        AviModal.destroy('adm-authentication-type');
    };

    $scope.$on('$destroy', function() {
        vm.authProfileCollection.destroy();
    });
}]);
