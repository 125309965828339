/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  objectTypeMatch
 * @module match/objectTypeMatch
 * @description Wrapper component for configuring a match config item.
 * @param {ConfigItem} matchFieldConfigItem - A config item instance associated with a match type.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('objectTypeMatch', {
    bindings: {
        matchFieldConfigItem: '<',
        preventEdit: '<',
    },
    templateUrl: 'src/components/forms/match-config/object-type-match/' +
        'object-type-match.component.html',
});
