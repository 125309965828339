/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('VirtualServicePlaceVSController', [
'$scope', '$controller', '$http',
function($scope, $controller, $http) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.init = function() {
        $scope.error = null;
        $scope.data = {
            busy: true,
        };

        $http.get(`/api/virtualservice/${$scope.editable.data.config.url.slug()
        }/candidatesehostlist?include_name`).then(function(rsp) {
            $scope.data.candidate = rsp.data;
        }).finally(function() {
            $scope.data.busy = false;
        });
    };

    $scope.place = function() {
        const vsId = $scope.editable.data.config.url.slug();

        $http.post(`/api/virtualservice/${vsId}/initialplacement`, {
            se_placement_params: [{
                to_se_ref: $scope.data.selected_se ? $scope.data.selected_se.se_ref : undefined,
                to_new_se: $scope.data.new_se,
                to_host_ref: $scope.data.new_se && $scope.data.selected_host ?
                    $scope.data.selected_host.host_ref : undefined,
            }],
        }).then(function() {
            return $scope.editable.checkForIssues();
        }).catch(function(rsp) {
            $scope.error = rsp.data.error;
        }).finally(function() {
            $scope.editable.dismiss(true);
        });
    };
}]);
