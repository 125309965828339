/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const inSubnet = require('insubnet');

/* Updates provided ip address with the base IP for passed network mask. */
angular.module('aviApp').directive('subnetBaseIp', function() {
    function link(scope, elem, attr, ngModel) {
        const baseSubnetIp = function(str) {
            let ip,
                prefix,
                res,
                baseIP;

            const parts = str.split('/');

            if (parts.length === 2 && inSubnet.isIP(parts[0]) && !_.isNaN(+parts[1])) {
                ip = inSubnet.toInt(parts[0]);
                prefix = +parts[1];
                baseIP = inSubnet.toInt(ip & 0xFFFFFFFF << 32 - prefix & 0xFFFFFFFF, 0);
                res = `${baseIP}/${prefix}`;
            }

            return res || str;
        };

        elem.on('focusout', function() {
            let val = ngModel.$modelValue;

            if (val) {
                val = baseSubnetIp(val);

                if (ngModel.$modelValue !== val) {
                    elem.val(val);
                    ngModel.$setViewValue(val);
                    scope.$apply();
                }
            }
        });
    }

    return {
        restrict: 'A',
        link,
        scope: false,
        require: 'ngModel',
    };
});

/**
 * Ip address validator
 * Checks if entered IPs and/or ranges are within the subnet.
 * Depends on the library (https://github.com/LouisT/inSubnet)
 *
 * Example of usage:
 * <input type="text" in-subnet="'1.1.1.1/24'">
 *     OR
 * <input type="text" in-subnet="subnetStringVariable">
 */
angular.module('aviApp').directive('inSubnet', ['getSubnetString', function(getSubnetString) {
    function link(scope, elem, attr, ngModel) {
        scope.$watch('inSubnet', () => ngModel.$validate());

        ngModel.$validators.inSubnet = function(modelValue, viewValue) {
            if (!viewValue) {
                return true;
            }

            /**
             * Assign inSubnet to inSubnetVal to avoid collision
             * with inSubnet library import(line no:1)
             */
            const { inSubnet: inSubnetValue } = scope;
            const subnet =
                angular.isString(inSubnetValue) ? inSubnetValue : getSubnetString(inSubnetValue);

            return !_.find(viewValue.split(/[,-]/), function(ip) {
                return !inSubnet.Auto(ip.trim(), subnet);
            });
        };
    }

    return {
        scope: {
            inSubnet: '=',
        },
        require: 'ngModel',
        link,
    };
}]);
