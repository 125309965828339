/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Network Profile List Controller function.
 */
function NetworkProfileListController($scope, CRUDGridConfig, NetworkProfileCollection) {
    $scope.gridConfig = new CRUDGridConfig();

    $scope.collection = new NetworkProfileCollection();

    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            title: 'Name',
            name: 'name',
            template: '<span class="sel-name"> {{row.data.config.name}} </span>',
            sortBy: 'name',
        }, {
            title: 'Type',
            name: 'type',
            template: '<span class="sel-type" ng-if="row.data.config.profile.type==' +
                '\'PROTOCOL_TYPE_TCP_PROXY\'">TCP Proxy</span>' +
                '<span class="sel-type" ng-if="row.data.config.profile.type==' +
                '\'PROTOCOL_TYPE_TCP_FAST_PATH\'">TCP Fast Path</span>' +
                '<span class="sel-type" ng-if="row.data.config.profile.type==' +
                '\'PROTOCOL_TYPE_UDP_FAST_PATH\'">UDP Fast Path</span>',
        }, {
            name: 'auto',
            title: 'Auto Learn',
            template: '<span class="sel-automatic" ng-if="row.data.config.profile.type==' +
                '\'PROTOCOL_TYPE_TCP_PROXY\' && row.data.config.profile.tcp_proxy_profile' +
                '.automatic">Yes</span>',
        },
    ];

    $scope.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.$on('$destroy', () => $scope.collection.destroy());
}

NetworkProfileListController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'NetworkProfileCollection',
];

/**
 * @ngdoc controller
 * @name  NetworkProfileListController
 * @description Controller for Network profile list.
 */
angular.module('aviApp').controller('NetworkProfileListController', NetworkProfileListController);

