/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('RoleListDataTransformer', ['ListDataTransformer', 'roleService',
function(ListDataTransformer, roleService) {
    function RoleListDataTransformer(args) {
        RoleListDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(RoleListDataTransformer, ListDataTransformer);

    /**
     * Transforms loaded roles data.
     * @override
     */
    RoleListDataTransformer.prototype.processResponse = function(resp, request) {
        resp = RoleListDataTransformer.superclass.processResponse.call(this, resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results)) {
            resp.data.results.forEach(function(object) {
                object.config.privileges_ = roleService.processRoles(
                    angular.copy(roleService.getRoleCategories()),
                    roleService.createPrivilegeHashMap(object.config.privileges),
                );
            });
        }

        return resp;
    };

    return RoleListDataTransformer;
}]);
