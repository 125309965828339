/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  ErrorPageBody
 * @description  ErrorPageBody item.
 */
const ErrorPageBody = Item => {
    class ErrorPageBody extends Item {}

    angular.extend(ErrorPageBody.prototype, {
        objectName: 'errorpagebody',
        windowElement: 'error-page-body-modal',
    });

    return ErrorPageBody;
};

ErrorPageBody.$inject = [
        'Item',
];

angular.module('aviApp').factory('ErrorPageBody', ErrorPageBody);
