/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function subnetListNetworkDataTransformerFactory(
    ListDataTransformer,
    getSubnetString,
) {
    /**
     * @class
     * @name SubnetListNetworkDataTransformer
     * @extends ListDataTransformer
     */
    class SubnetListNetworkDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            resp = super.processResponse(resp, request);

            const { data } = resp;

            if (data && 'results' in data && Array.isArray(data.results)) {
                data.results.forEach(
                    ({ config }) => SubnetListNetworkDataTransformer.networkTransform(config),
                );
            }

            return resp;
        }

        /**
         * SubnetListNetwork transformer function.
         * @param {Object} network
         */
        static networkTransform(network) {
            network['nameWithSubnets'] = network['name'];

            const { subnet: subnets } = network;

            if (Array.isArray(subnets) && subnets.length) {
                const subnetIds = subnets.map(({ prefix }) => getSubnetString(prefix));

                network['nameWithSubnets'] += ` - ${subnetIds.join(', ')}`;
            }
        }
    }

    return SubnetListNetworkDataTransformer;
}

subnetListNetworkDataTransformerFactory.$inject = [
    'ListDataTransformer',
    'getSubnetString',
];

/**
 * @ngdoc service
 * @alias SubnetListNetworkDataTransformer
 * @desc
 *
 *     Appending list of subnets to the network name.
 *     Used by {@link SubnetListNetworkCollection}.
 *
 */
angular.module('aviApp')
    .factory('SubnetListNetworkDataTransformer', subnetListNetworkDataTransformerFactory);
