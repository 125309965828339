/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  metricValuesSelector
 * @description  Displays traffic capture status.
 */
function Controller(myAccount) {
    this.$onInit = function() {
        this.displayValue = myAccount.uiProperty.valuesToDisplay;
    };

    this.onDisplayValueChange = function() {
        myAccount.setValuesToDisplay(this.displayValue);
    };
}

Controller.$inject = [
        'myAccount',
];

angular.module('aviApp').component('metricValuesSelector', {
    controller: Controller,
    templateUrl: 'src/components/common/metrics-display-values/metrics-display-values.tpl.html',
});
