/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  WafCrs
 * @description  WafCrs item.
 */
const wafCrsFactory = (Item, Auth) => {
    class WafCrs extends Item {
        /**
         * Always set to true since dropping this item just means deleting a user's previously
         * uploaded file.
         */
        isDroppable() {
            return !!Auth.isAllowed('wafpolicy', 'w');
        }
    }

    angular.extend(WafCrs.prototype, {
        objectName: 'wafcrs',
    });

    return WafCrs;
};

wafCrsFactory.$inject = [
    'Item',
    'Auth',
];

angular.module('aviApp').factory('WafCrs', wafCrsFactory);
