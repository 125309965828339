/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('charts').factory('Lines', ['d3v4', 'chartUtils', function(d3, chartUtils) {
    /**
     * Class for creating and managing SVG path elements.
     */
    class Lines {
        /**
         * Creates D3 Line function from specified X and Y D3 Scale functions.
         * @param {Function} xScale
         * @param {Function} yScale
         * @returns {Function} - D3 Line function.
         */
        static createLineFunction(xScale, yScale) {
            return d3.line()
                .curve(d3.curveMonotoneX)
                .x(d => xScale(d[0]))
                .y(d => yScale(d[1]));
        }

        /**
         * @param {Function} xScale - X-axis scale function.
         * @param {Function} y0Scale - Left Y-axis scale function.
         * @param {Function=} y1Scale - Right Y-axis scale function.
         */
        constructor(xScale, y0Scale, y1Scale = null) {
            this.xScale = xScale;
            this.y0Scale = y0Scale;
            this.y1Scale = y1Scale;
            this.line0Fn = Lines.createLineFunction(this.xScale, this.y0Scale);
            this.line1Fn = Lines.createLineFunction(this.xScale, this.y1Scale);
            this.element = d3.select(chartUtils.createElement()).attr('class', 'line-container');
            this.lines0 = this.element.append('g').attr('class', 'lines');
            this.lines1 = this.element.append('g').attr('class', 'lines');

            /** @type {ChartSeries[]} */
            this.series0 = null;

            /** @type {ChartSeries[]} */
            this.series1 = null;

            /** @type {string[]} */
            this.colors = null;
        }

        /**
         * Updates all D3 Scale functions at once.
         * @param {Function} xScale
         * @param {Function} y0Scale
         * @param {Function=} y1Scale
         */
        updateScales(xScale, y0Scale, y1Scale) {
            this.xScale = xScale;
            this.y0Scale = y0Scale;
            this.y1Scale = y1Scale;
            this.line0Fn = Lines.createLineFunction(xScale, y0Scale);
            this.line1Fn = Lines.createLineFunction(xScale, y1Scale);
        }

        /**
         * Translates lines inside its parent element by specified X and Y offset values.
         * @param {number} x
         * @param {number} y
         */
        position(x, y) {
            this.element.attr('transform', `translate(${x}, ${y})`);
        }

        /**
         * Attaches line container element to specified parent.
         * @param {Element} parent
         */
        render(parent) {
            parent.appendChild(this.element.node());
        }

        /**
         * Removes Line DOM element from its parent.
         */
        remove() {
            const node = this.element.node();

            node.remove();
        }

        /**
         * Updates line paths for specified series.
         * @param {ChartSeries[]} series
         * @param {d3.Selection} container - Container with line paths.
         * @param {Function} lineFn - Line function.
         * @param {string[]=} colors
         */
        updateLines(series, container, lineFn, colors = []) {
            const lines = container
                .selectAll('.line-path')
                .data(series);

            lines.enter().append('path');
            lines.exit().remove();

            container
                .selectAll('path')
                .attr('class', 'line-path')
                .style('stroke', (d, i) => colors[i])
                .attr('d', d => lineFn(d.data));
        }

        /**
         * Updates line paths from specified series.
         * @param {ChartSeries[]} series0
         * @param {ChartSeries[]=} series1
         * @param {string[]=} colors
         */
        update(series0, series1 = null, colors = []) {
            this.series0 = series0;
            this.series1 = series1;
            this.colors = colors;

            if (Array.isArray(series0)) {
                const cs = colors.slice(0, series0.length);

                this.updateLines(series0, this.lines0, this.line0Fn, cs);
            }

            if (Array.isArray(series1)) {
                const cs = colors.slice(series0.length, colors.length);

                this.updateLines(series1, this.lines1, this.line1Fn, cs);
            }
        }

        resize() {
            this.update(this.series0, this.series1, this.colors);
        }
    }

    return Lines;
}]);
