/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @ngdoc component
 * @name  virtualserviceVipAws
 * @description
 *     Allows the user to select a network and subnet for a Virtual Service in the AWS cloud.
 * @param {VsVip} vsVip
 * @param {Cloud} cloud
 */
class VirtualserviceVipAwsController {
    constructor(
        Schema,
        Regex,
        SubnetListNetworkCollection,
        SubnetListNetwork,
    ) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.SubnetListNetworkCollection_ = SubnetListNetworkCollection;
        this.SubnetListNetwork_ = SubnetListNetwork;
    }

    $onInit() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const vipConfig = this.vsVip.getConfig();

        // Find network name to display if network_ref doesn't contain name
        this.vsVip.getVipList().forEach(vip => {
            const vipConfig = vip.getConfig();
            const { network_ref: networkRef } = vipConfig;

            if (networkRef && !networkRef.name()) {
                const netId = networkRef.slug();

                vipConfig.network_ref = `${networkRef}#${netId}`;

                const { id: cloudId } = this.cloud;

                const network = new this.SubnetListNetwork_({
                    id: netId,
                    cloudId,
                });

                network.load()
                    .then(() => vipConfig.network_ref = `${networkRef}#${network.getName()}`)
                    .finally(() => network.destroy());
            }
        });

        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;
        const params = {
            auto_allocate_only: true,
            cloud_uuid: this.cloud.id,
            fip_capable: autoAllocateFloatingIp || undefined,
        };
        const { vrf_context_ref: vrfContextRef } = vipConfig;

        if (vrfContextRef) {
            params.vrf_context_uuid = vrfContextRef.slug();
        }

        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection_({ params });
    }

    /**
     * Sets subnetListNetworkCollection to get only fip_capable networks if Elastic IP is
     * selected.
     */
    handleAssignElasticIpChange() {
        const vip0 = this.vsVip.getVip(0);
        const vip0Config = vip0.getConfig();
        const { auto_allocate_floating_ip: autoAllocateFloatingIp } = vip0Config;

        this.vsVip.setAutoAllocateFloatingIpForAllVips(autoAllocateFloatingIp);
        this.subnetListNetworkCollection.setParams({
            fip_capable: autoAllocateFloatingIp || undefined,
        });
    }

    /**
     * Sets the "auto_allocate_ip" boolean along with the "subnet_uuid" when the network is
     * selected.
     * @param  {Object} vip - VirtualService vip object.
     * @param  {SubnetListNetwork} selected
     */
    handleNetworkChange(vip, selected) {
        if (angular.isUndefined(selected)) {
            return;
        }

        const subnet = selected.data.config.subnet[0];

        vip.setAutoAllocateIp(true);
        vip.setIpAddr(undefined);
        vip.setVipSubnet(subnet.prefix, subnet.uuid);
    }

    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
    }
}

VirtualserviceVipAwsController.$inject = [
    'Schema',
    'Regex',
    'SubnetListNetworkCollection',
    'SubnetListNetwork',
];

angular.module('aviApp').component('virtualserviceVipAws', {
    bindings: {
        vsVip: '<',
        cloud: '<',
    },
    controller: VirtualserviceVipAwsController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-aws/virtualservice-vip-aws.html',
});
