/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function vmNicIPCollectionFactory(
    Collection,
    VMNicIP,
) {
    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'VMNicIPDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    const fields = [
        'name',
        'vs_lb_weight',
        'guest_nic.mac_addr',
        'guest_nic.network_name',
        'guest_nic.guest_ip',
        'powerstate',
        'guest_nic.network_uuid',
    ].join();

    /**
     * @class
     * @name VMNicIPCollection
     * @extends module:avi/dataModel.Collection
     * @author Alex Malitsky
     * @desc
     *
     *     List of VM NIC IP objects.
     *
     * @see {@link VMNicIPDataTransformer}
     * @see {@link VMNicIP}
     */
    class VMNicIPCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    fields,
                    ...params,
                },
            };

            super(extendedArgs);
        }
    }

    Object.assign(VMNicIPCollection.prototype, {
        objectName_: 'serversbynetwork',
        itemClass_: VMNicIP,
        sortBy_: 'name',
        allDataSources_: dataSources,
    });

    return VMNicIPCollection;
}

vmNicIPCollectionFactory.$inject = [
    'Collection',
    'VMNicIP',
];

/**
 * @ngdoc service
 * @alias VMNicIPCollection
 **/
angular.module('aviApp')
    .factory('VMNicIPCollection', vmNicIPCollectionFactory);
