/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/* Grid of subnets for a network with IP ranges. Edit & add as well. @am */
angular.module('aviApp').directive('modalIpAddrListEdit', [
    '$timeout', 'Regex', 'Schema',
    function($timeout, Regex, Schema) {
        function link(scope, elem) {
            scope.Regex = Regex;
            scope.Schema = Schema;
            scope.ui = {};

            // need filtered copy of data to show/hide discovered subnets in grid
            // and filter out ones with equal subnet.subnet but different types
            // type_tmp === undefined for type = config
            const filterData = function() {
                let
                    configuredDict,
                    discovery = [];

                if (scope.excludeDiscovered) {
                    scope.filteredData = scope.config;
                } else { //don't show discovered subnet if we have same subnet with type = config
                    configuredDict = {};

                    if (scope.config && scope.config.length) {
                        scope.config.forEach(function(sn) {
                            configuredDict[sn.subnet] = true;
                        });

                        //need array copy to avoid modification of editable.data.discovery
                        discovery = angular.copy(_.filter(scope.discovery, function(sn) {
                            return !configuredDict[sn.subnet];
                        }));
                    } else {
                        scope.config = [];
                        discovery = angular.copy(scope.discovery) || [];
                    }

                    discovery.forEach(function(val) {
                        val.type_tmp = 'discovery';
                    });

                    scope.filteredData = scope.config.concat(discovery);
                }

                updateUniqueSubnetList();
            };

            /**
             * When in add mode, set the uniqueSubnetValues from the filteredData,
             * in the format of ngModel for unique value comparison
             *
             * When in edit mode,
             * update the uniqueSubnetValues to not include the value currently being edited
             */
            const updateUniqueSubnetList = function() {
                const {
                    edited,
                    filteredData = [],
                } = scope;

                // we don't care about discovered networks here
                let configuredSubnets =
                    filteredData.filter(subnet => subnet.type_tmp !== 'discovery');

                configuredSubnets = _.pluck(configuredSubnets, 'subnet');

                // edit mode - filter out the value currently being edited
                if (edited !== false) {
                    const { subnet: currentSubnetValue } = scope.current;

                    configuredSubnets =
                        configuredSubnets.filter(subnet => subnet !== currentSubnetValue);
                }

                scope.uniqueSubnetValues = configuredSubnets;
            };

            //focus on subnet field onEdit & onAdd
            const focusInput = function() {
                elem.find('input[ng-model="current.subnet"]').trigger('focus');
            };

            scope.current = false;//currently editable or new object
            scope.edited = false;//index of editable object in scope.config
            scope.error = false;

            scope.gridConfig = {
                fields: [{
                    name: 'ip_subnet',
                    title: 'IP Subnet',
                    transform(row) {
                        return row.subnet;
                    },
                }, {
                    name: 'type',
                    title: 'Type',
                    transform(row) {
                        return row.type_tmp === 'discovery' ? 'Discovered' : 'Configured';
                    },
                }, {
                    name: 'ip_addr_pool',
                    title: 'IP Address Pool',
                    transform(row) {
                        let str = '';

                        if (row.static_ipaddr_tmp && row.static_ipaddr_tmp.length) {
                            str = row.static_ipaddr_tmp.join(', ');
                        }

                        return str;
                    },
                }],
                rowId(row) {
                    const { subnet, type_tmp: mode } = row;

                    return `${subnet}-${mode}`;
                },
                searchFields: ['subnet', 'type_tmp', 'prefix.ip_addr.addr', 'prefix.mask'],
                multipleactions: [{
                    title: 'Delete',
                    do(selected) {
                        selected.forEach(function(sel) {
                            const index = scope.config.indexOf(sel);

                            if (index !== -1) {
                                scope.config.splice(index, 1);
                            }
                        });

                        return true;
                    },
                }],
                singleactions: [{
                    title: 'Edit',
                    class: 'sel-edit-btn icon-pencil-4',
                    do(row) {
                        scope.edit(row);
                    },
                }],
                checkboxDisable(row) {
                    return row.type_tmp === 'discovery';
                },
            };

            scope.edit = function(sn) {
                scope.current = sn;

                //need array to show input for IP range inside ng-repeat
                if (!scope.current.static_ipaddr_tmp ||
                    scope.current.static_ipaddr_tmp.length === 0) {
                    scope.current.static_ipaddr_tmp = [''];
                    scope.ui.addStaticIp = false;
                } else {
                    scope.ui.addStaticIp = true;
                }

                scope.edited = scope.config.indexOf(sn);

                if (scope.edited === -1) {
                    scope.edited = false;
                }

                $timeout(focusInput);
                updateUniqueSubnetList();
            };

            scope.add = function() {
                scope.current = {
                    subnet: '',
                    static_ipaddr_tmp: [''],
                };
                scope.edited = false;

                $timeout(focusInput);
                updateUniqueSubnetList();
            };

            //save and cancel methods are passed to outer controller to enable cancel,
            //save and close(x) buttons
            scope.save = function() {
                scope.error = false;

                const { current } = scope;

                if (!scope.ui.addStaticIp) {
                    current.static_ipaddr_tmp = [];
                }

                current.static_ipaddr_tmp = _.compact(current.static_ipaddr_tmp);
                current.static_ipaddr_tmp = _.uniq(current.static_ipaddr_tmp);

                // Even if current subnet is discovery need to change it to config
                current.type_tmp = 'config';

                if (scope.edited !== false) {
                    scope.config[scope.edited] = current;
                    current.prefix = angular.copy(current.subnet);
                } else {
                    scope.config.push(current);
                }

                scope.ui.addStaticIp = false;
                scope.current = false;
                scope.edited = false;
            };

            scope.cancel = function() {
                scope.ui.addStaticIp = false;
                scope.current = false;
                scope.error = false;
                scope.edited = false;
            };

            /* need to use this as we can modify list in multiple ways and different scopes */

            //after saving, edition or deletion of subnet
            scope.$watch('config', filterData, true);

            //when we are opening edit window for other network
            scope.$watch('discovery', filterData);

            //checkbox 'exclude discovered'
            scope.$watch('excludeDiscovered', filterData);
        }

        return {
            scope: {
                config: '=',
                discovery: '=',
                excludeDiscovered: '=',
                current: '=',
                cancel: '=onCancel', // These two are used to pass functions to parent controller.
                save: '=onSave',
            },
            restrict: 'E',
            link,
            templateUrl: 'src/views/components/modal-ip-addr-list-edit.html',
        };
    }]);
