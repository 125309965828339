/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name ServiceEngineController
 */
angular.module('aviApp').controller('ServiceEngineController', [
'$scope', 'resolveSE', 'myAccount', 'resolveCloud', 'Auth',
function($scope, se, myAccount, cloudPromise, Auth) {
    $scope.myAccount = myAccount;

    cloudPromise.then(cloud => {
        if (!$scope.$$destroyed) {
            $scope.cloud = cloud;
        } else {
            cloud.destroy();
        }
    });

    $scope.se = se;
    $scope.Auth = Auth;

    /** @deprecated */
    $scope.ServiceEngine = se;

    $scope.$on('$destroy', () => {
        se.destroy();

        const { cloud } = $scope;

        if (cloud) {
            cloud.destroy();
        }
    });
}]);
