/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const dropDownUtilsFactory = schemaService => {
    /**
     * Can be either object or simple type value.
     * @typedef {Object} DropDownOption
     * @property {string} label
     * @property {*} value
     * @property {string} tooltip
     */

    /**
     * Creates "option" object used in dropdown list.
     * @param {string|number} value
     * @param {string=} label in case if label is blank, it will be same as value
     * @param {string=} tooltip
     * @returns {DropDownOption}
     * @public
     */
    function createOption(value, label = value, tooltip = '') {
        return {
            value,
            label,
            tooltip: tooltip || label,
        };
    }

    /**
     * Creates DropDownOption from HTML element.
     * @param {HTMLElement} elem
     * @returns {DropDownOption}
     * @public
     */
    function createOptionFromHtml(elem) {
        const $elem = $(elem);
        const value = $elem.val().trim();
        const label = $elem.text().trim();
        const tooltip = $elem.attr('tooltip');

        return createOption(value, label, tooltip);
    }

    /**
     * Returns dropdown options given a list of EnumValues.
     * @param {EnumValue[]} values - Array of EnumValues.
     * @returns {DropDownOption[]}
     */
    function createOptionsFromEnumProps(values = []) {
        return values.map(({ value, label, description }) => {
            return createOption(value, label, description);
        });
    }

    /**
     * Returns dropdown options given an enum name.
     * @param {string} enumName - Name of an enum.
     * @returns {DropDownOption[]}
     */
    function getEnumDropdownOptions(enumName) {
        return _.compose(createOptionsFromEnumProps, schemaService.getEnumValues)(enumName);
    }

    return {
        createOption,
        createOptionFromHtml,
        createOptionsFromEnumProps,
        getEnumDropdownOptions,
    };
};

dropDownUtilsFactory.$inject = [
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  dropDownUtils
 * @author alogashov, alextsg
 * @desc Factory containing helpful methods associated with the Dropdown directive.
 */
angular.module('kit.ui.vantage.avi').factory('dropDownUtils', dropDownUtilsFactory);
