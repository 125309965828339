/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').filter('stateName', function() {
    return function(state) {
        if (state.abstract && state.defaultChild !== undefined) {
            return `${state.name}.${state.defaultChild}`;
        } else {
            return state.name;
        }
    };
})

    .filter('stateNav', function() {
        return function(state) {
        // ignore leaf states (summary, config, performance)
        // and get to abstract state (application, vs, pool)
            const parts = state.name.split('.');

            return `src/views/components/${parts[1]}/nav.tpl.html`;
        };
    })

    .filter('stateContext', function() {
        return function(state) {
        // ignore leaf states (summary, config, performance)
        // and get to abstract state (application, vs, pool)
            const parts = state.name.split('.');

            return parts[parts.length - 2];
        };
    })

    .filter('stateGroup', function() {
        return function(state) {
        // ignore leaf states (summary, config, performance)
        // and get to abstract state (application, vs, pool)
            const parts = state.name.split('.');
            let name = parts[1].capitalize();

            if (name === 'Application') {
                name += 's';
            }

            if (name === 'Profile') {
                name = 'Templates';
            }

            return name;
        };
    })

    .filter('currentStateIncludes', ['$state', function($state) {
        return function(string) {
            return _.contains($state.current.name.split('.'), string);
        };
    }])

/**
 * @ngdoc filter
 * @name leafStateName
 * @param {ng.$state|string} str - State object or just a state name.
 */
    .filter('leafStateName', function() {
        return function(arg) {
            let res = '',
                str,
                pointPos;

            if (typeof arg === 'string') {
                str = arg;
            } else if (typeof arg === 'object' && typeof arg.name === 'string') {
                str = arg.name;
            }

            if (str) {
                pointPos = str.lastIndexOf('.');

                if (pointPos > -1) {
                    res = str.slice(pointPos + 1);
                }
            }

            return res;
        };
    });
