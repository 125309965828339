/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/unit-tree.less';

angular.module('aviApp').directive('unitTree', [
'PoolGroupCollection', 'GSLBService', '$state',
function(PoolGroupCollection, GSLBService, $state) {
    function link(scope, elem) {
        let chart,
            chartContainer;

        const drawConnections = function() {
            if (!scope.treeOpen) {
                return;
            }

            if (chart) {
                chart.remove();
            }

            if (chartContainer && chartContainer.selectAll('path').size()) {
                chartContainer.selectAll('path').remove();
            }

            let vsPos,
                line,
                vs2poolDraw;

            const serverSelector = '~servers-list > div.servers > ul:first-child ' +
                'unit-card[type="server"],~servers-list > ul > li >' +
                'unit-card[type="server"]:first-child';

            //makes Bezier curve between two points in different units
            const interpolation = function(points) {
                const straightCurvature = 1 / 20;
                let path = '';

                const trueOrFalse = function() {
                    return Math.random() >= 0.5;
                };

                points.forEach(function(val, key, arr) {
                    //control points of Bezier curve
                    let cpx1,
                        cpx2,
                        cpy1,
                        cpy2;
                    //only to add curvature to straight lines
                    let dy,
                        dx;

                    if (key) {
                        path += ' C';
                        cpx2 = (val[0] + arr[key - 1][0]) / 2;
                        cpx1 = (val[0] + arr[key - 1][0]) / 2;

                        //would be straight horizontal line if don't do this
                        if (Math.abs(val[1] - arr[key - 1][1]) < 9) {
                            dy = Math.abs(val[0] - arr[key - 1][0]) * straightCurvature;
                            cpy2 = val[1];
                            cpy1 = val[1];

                            if (trueOrFalse()) { //curvature direction
                                cpy1 -= dy;
                                cpy2 += dy;
                            } else {
                                cpy1 += dy;
                                cpy2 -= dy;
                            }
                        //would be straight vertical line if don't do this
                        } else if (Math.abs(val[0] - arr[key - 1][0]) < 9) {
                            dx = Math.abs(val[1] - arr[key - 1][1]) * straightCurvature;
                            cpy1 = (val[1] + arr[key - 1][1]) / 2;
                            cpy2 = cpy1;
                            cpx1 = val[0];
                            cpx2 = cpx1;

                            if (trueOrFalse()) {
                                cpx1 -= dx;
                                cpx2 += dx;
                            } else {
                                cpx1 += dx;
                                cpx2 -= dx;
                            }
                        } else {
                            cpy1 = arr[key - 1][1];
                            cpy2 = val[1];
                        }

                        path += `${cpx1.toFixed()},${cpy1.toFixed()} ${cpx2.toFixed()},${
                            cpy2.toFixed()} ${val[0].toFixed()},${val[1].toFixed()}`;
                    } else {
                        path += `${val[0].toFixed()},${val[1].toFixed()}`;
                    }
                });

                return path;
            };

            function relativeOffset(childEl, parentEl) {
                if (childEl && parentEl) {
                    let currentEl = childEl.offsetParent;
                    let x = childEl.offsetLeft;
                    let y = childEl.offsetTop;

                    while (currentEl && currentEl !== parentEl) {
                        x += currentEl.offsetLeft;
                        y += currentEl.offsetTop;
                        currentEl = currentEl.offsetParent;
                    }

                    return { x, y };
                }

                return { x: 0, y: 0 };
            }

            //Find position in element to draw line from (to).
            const countConnectionPoints = function(type, elm) {
                const
                    point = [0, 0], //[x,y]
                    margin = [2, -1];

                const p = relativeOffset(elm.get(0), elem.find('.unit-tree-list').get(0));
                const top = p.y;
                const left = p.x;
                const width = elm.outerWidth();
                const height = elm.outerHeight();

                switch (type) {
                    case 'right':
                        point[0] = Math.floor(left + width) + margin[0];
                        point[1] = Math.round(top + height / 2);
                        break;
                    case 'left':
                        point[0] = Math.floor(left) - margin[0];
                        point[1] = Math.round(top + height / 2);
                        break;
                    case 'top':
                        point[0] = Math.round(left + width / 2);
                        point[1] = Math.ceil(top) - margin[1];
                        break;
                    case 'bottom':
                        point[0] = Math.round(left + width / 2);
                        point[1] = Math.floor(top + height) + margin[1];
                        break;
                    default:
                        console.warn('vsTree.drawConnections.countConnectionPoints: "%s" is a' +
                            'wrong type of connection point. DOM Node: %o', type, elm);
                }

                return point;
            };

            // returned function appends path.pathClassName into group with appropriate className
            // have few groups of lines: vs, pool and net to avoid mess in SVG and be able to
            // implement updating
            const makeDrawFunc = function(parentPos, gClassName, pathClassName, connPlacement) {
                return function(unit, key) {
                    key = key || '0';

                    const side = connPlacement || 'left';
                    const pos = countConnectionPoints(side, unit);

                    if (!chartContainer.select(`g.${gClassName}`).size()) {
                        chartContainer.append('g').attr('class', gClassName);
                    }

                    chartContainer.select(`g.${gClassName}`)
                        .selectAll(`path.${pathClassName}${key}`)
                        .data([[parentPos, pos]])
                        .enter()
                        .append('path')
                        .attr({
                            class: pathClassName + key,
                            d: line,
                            'stroke-linejoin': 'round',
                            'stroke-linecap': 'round',
                        });
                };
            };

            line = d3.svg.line()
                .x(function(d) { return d[0]; })
                .y(function(d) { return d[1]; })
                .interpolate(interpolation);

            chart = d3.select(elem.find('div.background')[0])
                .append('svg')
                .attr({
                    class: 'chart sel-background-chart',
                    width: '100%',
                    height: '100%',
                });

            chartContainer = chart.append('g')
                .attr({ class: 'connections' });

            const vs = elem.find('unit-card[type=vs]');
            const pools = elem.find('unit-card[type=pool]');

            if (vs.length && pools.length) {
                vsPos = countConnectionPoints('right', vs);
                vs2poolDraw = makeDrawFunc(vsPos, 'vs', 'vs2pool_');
                pools.each(function(poolKey) {
                    const pool = $(this);

                    if (!pool.is('[pool-type^=ab], [pool-type=backup], [pool-type=poolgroup]')) {
                        vs2poolDraw(pool, poolKey);//draws connections between vs and pool
                    } else if (pool.is('[pool-type=poolgroup]') && pool.is(
                        '.poolgroup ul:first-child li:first-of-type [pool-type=poolgroup]',
                    )) {
                        vs2poolDraw(pool, poolKey);
                    } else if (poolKey > 0 && pool.is('[pool-type=backup]')) {
                        //line between parent and child pools
                        makeDrawFunc(countConnectionPoints('bottom', $(pools.get(poolKey - 1))),
                            'pool2pool', `pool2pool_${poolKey}`, 'bottom')(pool, poolKey);
                    } else if (poolKey > 0 && pool.is('[pool-type=ab-child]')) {
                        //line from VS to the point between pools and than from that point to both
                        const
                            parentPool = $(pools.get(poolKey - 1)),
                            parent = countConnectionPoints('left', parentPool),
                            child = countConnectionPoints('left', pool),
                            between = [
                                (parent[0] + child[0]) / 2,
                                (parent[1] + child[1]) / 2,
                            ];

                        makeDrawFunc(between, 'vs', 'vs2ab_pools', 'right')(vs);

                        makeDrawFunc(
                            between,
                            'vs',
                            'ab_pools2ab_parent_pool_',
                            'bottom',
                        )(parentPool, poolKey - 1);

                        makeDrawFunc(
                            between,
                            'vs',
                            'ab_pools2ab_child_pool_',
                            'top',
                        )(pool, poolKey);
                    }

                    let nets;

                    if (pool.is('[pool-type=poolgroup]')) {
                        nets = pool.parent().find(
                            '~ul > li > div.net-wrapper > unit-card[type="net"]',
                        );
                    } else {
                        nets = pool.find('~ul > li > div.net-wrapper > unit-card[type="net"]');
                    }

                    const poolPosR = countConnectionPoints('right', pool);

                    if (nets.length) {
                        const pool2netDraw = makeDrawFunc(poolPosR, `pool_${poolKey}`, 'pool2net_');

                        nets.each(function(netKey) {
                            const net = $(this);
                            const netPosR = countConnectionPoints('right', net);

                            pool2netDraw(net, netKey);

                            //have two possible layouts dependent on servers quantity
                            const servers = net.parent().find(serverSelector);

                            const net2serverDraw = makeDrawFunc(
                                netPosR, `pool_${poolKey}_net_${netKey}`, 'net2server_',
                            );

                            servers.each(function(serverKey) {
                                net2serverDraw($(this), serverKey);
                            });
                        });
                    } else {
                        let servers;

                        if (pool.is('[pool-type=poolgroup]')) {
                            servers = pool.parent().find(serverSelector);
                        } else {
                            servers = pool.find(serverSelector);
                        }

                        if (servers.length) {
                            const pool2serverDraw =
                                makeDrawFunc(poolPosR, `pool_${poolKey}pool2server_`);

                            servers.each(function(serverKey) {
                                pool2serverDraw($(this), serverKey);
                            });
                        }
                    }
                });
            }

            const $gslbTree = elem.find('.unit-tree-list.gslb-service-tree');

            if ($gslbTree.length) {
                const $gslb = $gslbTree.find('.gslb-service-unit-name');
                const $domains = $gslbTree.find('.gslb-service-tree-domains');
                const $groups = $gslbTree.find('.gslb-service-unit-tree-groups > li');

                const gslbPos = countConnectionPoints('right', $gslb);
                const gslbToDomainDraw = makeDrawFunc(gslbPos, 'g_d', 'g_d0');

                gslbToDomainDraw($domains);

                const domainPos = countConnectionPoints('right', $domains);
                const domainDraw = makeDrawFunc(domainPos, 'd_gr', 'd0_gr');

                $groups.each((key, value) => {
                    const $group = $(value);

                    domainDraw($group, `gr_${key}`, `d_gr_${key}`);
                });
            }
        };

        const repaint = _.debounce(drawConnections, 99);//onResize

        scope.toggleTree = function() {
            let open = scope.treeOpen;

            open = !open;
            scope.treeOpen = open;
        };

        scope.$watch(() => scope.treeOpen, (newValue, oldValue) => {
            repaint();
        });

        //creates a string like (p 7s; p n 1s n n;)
        //to watch number of connections between pool & net & servers
        //TODO: need to figure out how to handle updating pool groups.
        const networksAndServersFootPrint = function() {
            const { unit } = scope;

            if (unit && unit.pools) {
                return _.reduce(scope.unit.pools, function(base, pool) {
                    let res = 'p ';

                    if (_.size(pool.networks)) {
                        res += _.reduce(pool.networks, function(base, network) {
                            let res = 'n ';

                            if (_.size(network.servers)) {
                                res = `${_.size(network.servers)}ns `;
                            }

                            return base + res;
                        }, '');
                    } else if (_.size(pool.servers)) {
                        res += `${_.size(pool.servers)}s `;
                    }

                    return `${base + res}; `;
                }, '');
            }
        };

        scope.$on('repaint', function() {
            repaint();
        });

        scope.isObjectEmpty = function(object) {
            return _.isEmpty(object);
        };

        /**
         * Sets $scope.poolIdsHash to filter out pools already displayed in poolgroups.
         */
        const setPoolIdsHash = function() {
            if (scope.unit && scope.unit.poolgroups) {
                const items = scope.unit.poolgroups;

                scope.poolIdsHash = PoolGroupCollection.getPoolIdsHash(items);
            }
        };

        setTimeout(function() { //can be vulnerable! @am
            scope.$watchGroup(['unit.pools', networksAndServersFootPrint], () => {
                setPoolIdsHash();
                repaint();
            });
        }, 0);

        scope.hasSes = function() {
            const { unit } = scope;

            if (!unit || !unit.ses) {
                return false;
            }

            return Object.keys(unit.ses).length > 0;
        };

        scope.isGslbService = function() {
            return scope.unit instanceof GSLBService;
        };

        // TODO(@logashoff): Once Health Score for GSLB Service is implemented, move to unitCard.js
        scope.goToGslbService = () => {
            $state.go('authenticated.application.gslbservice-detail.members', {
                gslbServiceId: scope.unit.data.config.uuid,
            });
        };
    }

    return {
        restrict: 'E',
        scope: {
            unit: '=',
            treeOpen: '=',
        },
        templateUrl: 'src/views/components/dashboard-unit-tree.html',
        link,
    };
}]);
