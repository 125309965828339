/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServiceFQDNListCollDataSource
 * @description
 *
 *     getRequestParams actually builds the response from GSLB DNS VS sites and GSLBService domain
 *     names.
 */
angular.module('aviApp').factory('GSLBServiceFQDNListCollDataSource', [
'LimitedListCollDataSource',
function(LimitedListCollDataSource) {
    return class GSLBServiceFQDNListCollDataSource extends LimitedListCollDataSource {
        /**
         * Making up the list of VS&FQDN combinations.
         * @override
         **/
        getRequestParams_() {
            const results = [],
                { gslb, gslbService } = this.owner_,
                domainNames = gslbService.getDomainNames(),
                gslbServiceName = gslbService.getName(),
                DNSVSSites = gslb.getDNSVSSites();

            _.each(DNSVSSites, ({ clusterId, name: siteName, dnsVSs }) => {
                dnsVSs.forEach(vsId => {
                    domainNames.forEach(domainName => {
                        results.push({
                            domainName,
                            siteName,
                            gslbServiceName,
                            vsId,
                            clusterId,
                        });
                    });
                });
            });

            //need offset&limit for compatibility with ListCollDataSource.processResponse_
            return {
                offset_: 0,
                limit_: 0,
                results,
            };
        }

        /** @override */
        processResponse_(resp, requestParams) {
            this.processConfigResponse_(resp, requestParams);
        }
    };
}]);
