/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPolicyWhitelistFactory = MessageItem => {
    /**
     * @alias module:config-item/WafPolicyWhitelistConfigItem
     * @private
     */
    class WafPolicyWhitelistConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPolicyWhitelist',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten_() {
            return !this.config.rules.isEmpty();
        }

        /**
         * Returns the number of rules in the config.
         * @returns {number}
         */
        getRulesCount() {
            return this.config.rules.count;
        }

        /**
         * Returns a new WafPolicyWhitelistRule ConfigItem instance.
         * @param {Object} [config=null] - Config object.
         * @return {WafPolicyWhitelistRule}
         */
        createNewRule(config = null) {
            return this.createChildByField_('rules', config, true);
        }

        /**
         * Adds a rule to the list of rules. If the rule has an index, it means that an existing
         * rule was modified so the existing rule should be replaced. If not, add the new rule to
         * the end of the list.
         * @param {WafPolicyWhitelistRule} rule - Rule to add.
         */
        addRule(rule) {
            const { rules } = this.config;
            const indexField = rule.getIndex();

            // New rule doesn't have an index.
            if (_.isUndefined(indexField)) {
                const maxIndex = rules.getMaxIndex();
                const newIndex = _.isNaN(+maxIndex) ? 0 : maxIndex + 1;

                rule.setIndex(newIndex);
                rules.add(rule);
            } else {
                const arrayIndex = rules.getArrayIndexWithIndexField(indexField);

                rules.config[arrayIndex] = rule;
            }
        }
    }

    return WafPolicyWhitelistConfigItem;
};

wafPolicyWhitelistFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafPolicyWhitelistConfigItem
 * @description  WafPolicyWhitelist ConfigItem class.
 * @module config-item/WafPolicyWhitelistConfigItem
 * @author alextsg
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPolicyWhitelistConfigItem', wafPolicyWhitelistFactory);
