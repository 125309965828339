/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Escape specific characters in requests to the backend.
 * @param {string|number} query - Query (or any string) that needs to have characters escaped.
 * @param {string} escapeChar - Character to escape.
 * @example
 * Double-quotes fail if used in a request for logs:
 * /api/analytics/logs?filter=co(all,"""") will fail. These double-quotes need to be escaped:
 * /api/analytics/logs?filter=co(all,\"\"\"\")
 */
angular.module('aviApp').constant('escapeCharacter', (query, escapeChar) => {
    const escapeRegex = new RegExp(escapeChar, 'g');

    return query.toString().replace(escapeRegex, `\\${escapeChar}`);
});
