/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateCreateRoot
 * @param {Certificate} editable - Certificate item.
 * @param {Function} closeModal - Closes modal.
 * @description  Modal for SSL Root Certificate. User validates and imports a certificate.
 */
class CertificateCreateRootController {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }

    $onInit() {
        this.validated = false;
    }

    /**
     * Clears certificate key information when Paste/Upload radio buttons are selected.
     */
    clearCertificate() {
        this.editable.data.config.certificate = undefined;
        this.validated = false;
    }

    /**
     * Sends certificate validate request and sets the Certificate config.
     * @returns {promise}
     */
    validateCert() {
        this.editable.validateCertificate()
            .then(() => this.validated = true);
    }
}

CertificateCreateRootController.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('sslCertificateCreateRoot', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: CertificateCreateRootController,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/modals/' +
            'ssl-certificate-create-root/ssl-certificate-create-root.html',
});
