/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name VsWafTopHitsDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     Instead of regular flat list of results transforms response into a hash by top hits list
 *     type. Each list entry is of {@link VsWafTopHitEntry} type.
 *
 *     Each group has a list of entries and a count (number of unique entries of the whole list,
 *     not just loaded part).
 *
 */
angular.module('aviApp').factory('VsWafTopHitsDataTransformer', [
'RevisedDataTransformer', 'VsWafTopHitsDataSource',
function(RevisedDataTransformer, VsWafTopHitsDataSource) {
    class VsWafTopHitsDataTransformer extends RevisedDataTransformer {
        /**
         * Returns a hash of lists by groupby fieldName.
         * @param {Object[]} rsps
         * @param {Object} request
         * @returns {{string: Object[]}}
         */
        processResponse(rsps, request) {
            const groups = {};

            rsps.forEach(({ data }, index) => {
                const
                    { [index]: groupBy } = request['groupBy_'],
                    { count } = data;

                groups[groupBy] = {
                    count,
                };

                const { [groupBy]: listType } = VsWafTopHitsDataSource.propNameToFieldName;

                groups[groupBy].list = data.results.map(result => {
                    const
                        { count } = result,
                        filters = {};

                    let name;

                    // multiple filters ("combinations" type)
                    if (groupBy.indexOf(',') !== -1) {
                        const propertyNames = groupBy.split(',');

                        propertyNames.forEach(
                            propName => filters[propName] = result[propName],
                        );

                        name = propertyNames.map(fieldName => result[fieldName]).join(' & ');
                    } else {
                        name = result[groupBy];
                        filters[groupBy] = name;
                    }

                    return {
                        type: listType,
                        name,
                        filters,
                        count,
                    };
                });
            });

            return groups;
        }
    }

    return VsWafTopHitsDataTransformer;
}]);
