/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('charts').factory('Guideline', ['d3v4', 'chartUtils', function(d3, chartUtils) {
    /**
     * Vertical line on a chart to highlight selected Y-values.
     */
    class Guideline {
        /**
         * @param {number} height - Guideline height.
         * @param {Function} xScale - D3 X-axis scale function.
         * @param {Function} y0Scale - D3 left Y-axis scale function.
         * @param {Function=} y1Scale - D3 right Y-axis scale function.
         */
        constructor(height, xScale, y0Scale, y1Scale) {
            this.height = height;
            this.xScale = xScale;
            this.y0Scale = y0Scale;
            this.y1Scale = y1Scale;

            this.guideline = null;
            this.circles0 = null;
            this.circles1 = null;

            this.colors = [];
        }

        /**
         * Updates all D3 Scale functions.
         * @param {Function} xScale
         * @param {Function} y0Scale
         * @param {Function=} y1Scale
         */
        updateScales(xScale, y0Scale, y1Scale) {
            this.xScale = xScale;
            this.y0Scale = y0Scale;
            this.y1Scale = y1Scale;
        }

        /**
         * Resizes guideline height.
         * @param {number} height
         */
        setHeight(height) {
            this.height = height;

            if (this.guideline) {
                this.guideline.select('.v-line').attr('y2', this.height);
            }
        }

        /**
         * Positions guideline container relative to its parent.
         * @param {number} x
         * @param {number} y
         */
        position(x, y) {
            if (this.guideline) {
                this.guideline.attr('transform', `translate(${x}, ${y})`);
            }
        }

        /**
         * Displays guideline on the chart.
         * @returns {boolean} - True if able to show guideline.
         */
        show() {
            if (this.guideline) {
                this.guideline.style('opacity', 1);

                return true;
            }

            return false;
        }

        /**
         * Hides guideline on the chart.
         * @returns {boolean} - True if able to hide guideline.
         */
        hide() {
            if (this.guideline) {
                this.guideline.style('opacity', 0);

                return true;
            }

            return false;
        }

        /**
         * Attaches guideline container to specified element.
         * @param {Element} parent
         */
        render(parent) {
            if (!this.guideline) {
                const guideline = d3.select(chartUtils.createElement()).attr('class', 'guideline');

                this.guideline = guideline;

                const group = guideline.append('g').attr('class', 'guideline-container');

                group.append('line')
                    .attr('class', 'v-line')
                    .attr('x1', 0)
                    .attr('y1', 0)
                    .attr('x2', 0)
                    .attr('y2', this.height);

                this.circles0 = group.append('g').attr('class', 'circles');
                this.circles1 = group.append('g').attr('class', 'circles');

                this.hide();
            }

            parent.appendChild(this.guideline.node());
        }

        /**
         * Removes guideline from its parent element.
         */
        remove() {
            if (this.guideline) {
                const node = this.guideline.node();
                const { parentElement } = node;

                if (parentElement) {
                    parentElement.removeChild(node);
                }
            }
        }

        /**
         * Updates circles position for specified line series.
         * @param {number} xValue
         * @param {ChartPoint[][]} points
         * @param {d3.Selection} container
         * @param {Function} yScale
         * @param {string[]} colors
         */
        updateCircles(xValue, points, container, yScale, colors = []) {
            if (points.length > 0) {
                const [set0] = points;

                if (chartUtils.validatePosition(xValue, set0)) {
                    const dataIndex = chartUtils.getIndexByXValue(xValue, set0);
                    const circles = container.selectAll('.v-circle').data(points);

                    circles.enter()
                        .append('circle')
                        .attr('class', 'v-circle')
                        .attr('cx', 0)
                        .attr('cy', 0);

                    circles.exit().remove();

                    container
                        .selectAll('.v-circle')
                        .attr('class', 'v-circle')
                        .style('fill', (d, i) => colors[i])
                        .attr('cy', pts => {
                            const d0 = pts[dataIndex - 1];
                            const d1 = pts[dataIndex];
                            const d = chartUtils.clampPoint(xValue, d0, d1);

                            return yScale(d[1]);
                        });
                }
            }
        }

        /**
         * Repositions vertical line horizontally and circles vertically
         * based on calculated xValue position.
         * @param {number} xValue
         * @param {ChartPoint[][][]} points
         */
        update(xValue, points) {
            const [set0, set1] = points;
            const [series0] = set0;

            if (chartUtils.validatePosition(xValue, series0)) {
                const dataIndex = chartUtils.getIndexByXValue(xValue, series0);
                const p0 = series0[dataIndex - 1];
                const p1 = series0[dataIndex];
                const p = chartUtils.clampPoint(xValue, p0, p1);
                const xOffset = this.xScale(p[0]);

                this.guideline
                    .select('.guideline-container')
                    .attr('transform', `translate(${xOffset}, 0)`);

                this.updateCircles(xValue, set0, this.circles0, this.y0Scale, this.colors[0]);

                if (Array.isArray(set1) && set1.length > 0) {
                    this.updateCircles(xValue, set1, this.circles1, this.y1Scale, this.colors[1]);
                } else {
                    this.circles1.html('');
                }
            }
        }
    }

    return Guideline;
}]);
