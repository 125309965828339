/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function techSupportCaptureDataTransformerFactory(
    $filter,
    RevisedDataTransformer,
    techSupportStatus,
    techSupportReadableTypes,
) {
    /**
     * @alias module:services/TechSupportCaptureDataTransformer
     * @extends RevisedDataTransformer
     * @private
     */
    class TechSupportCaptureDataTransformer extends RevisedDataTransformer {
        /**
         * Returns status.status if previous capture failed.
         * @param {TechSupportApiStatus} status
         * @param {TechSupportCaptureStatus} captureStatus
         * @return {string} - error string or empty
         */
        static getCaptureError(status, captureStatus) {
            // Note: status.errors aren't meant to be used here, only data.status if:
            if (captureStatus === 'error') {
                return status.status;
            } else {
                return '';
            }
        }

        /**
         * Returns status of generation state.
         * @param {TechSupportApiStatus} status
         * @return {TechSupportCaptureStatus}
         */
        static getCaptureStatus(status) {
            const {
                SYSERR_TECH_SUPPORT_COLLECTION_ABORTED,
                SYSERR_TECH_SUPPORT_INVALID_FILENAME,
                SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS,
                SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS,
                SYSERR_TECH_SUPPORT_COLLECTION_STARTED,
                SYSERR_TECH_SUPPORT_COLLECTION_ONGOING,
                SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS,
                SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS,
                SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE,
                SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND,
            } = techSupportStatus;

            switch (status.status_code) {
                case SYSERR_TECH_SUPPORT_COLLECTION_ABORTED:
                case SYSERR_TECH_SUPPORT_INVALID_FILENAME:
                    return 'error';
                case SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS:
                case SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS:
                case SYSERR_TECH_SUPPORT_COLLECTION_STARTED:
                case SYSERR_TECH_SUPPORT_COLLECTION_ONGOING:
                    return 'inProgress';
                case SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS:
                case SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS:
                case SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE:
                case SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND:
                default:
                    return 'success';
            }
        }

        /**
         * Strips excess and returns fileName from provided output path.
         * @param {TechSupportApiStatus} status
         * @returns {string} - file name or empty string
         */
        static getFileNameFromStatus(status) {
            const { output } = status;

            if (!output) {
                return '';
            }

            const indexOfParam = 'tech_support/',
                indexOfParamLength = indexOfParam.length,
                i = output.indexOf(indexOfParam) + indexOfParamLength;

            return output.slice(i);
        }

        /**
         * Returns human readable format for type of tech support log.
         * @param {string} level - tech support type returned by API
         * @returns {string}
         */
        static getTechSupportReadableTypeProp(level) {
            const type = level || '';

            return techSupportReadableTypes[type] || $filter('capitalize')(type);
        }

        /**
         * @param {TechSupportApiStatus} status
         * @return {string} - returns name, if available, else ''.
         */
        static getResourceName(status) {
            return status.name || '';
        }

        /**
         * Looks up tech support readable type.
         * @param {TechSupportApiStatus} status
         * @return {string} type - '' or human readable tech support type
         */
        static getType(status) {
            const { level } = status;

            if (!level) {
                return '';
            }

            return TechSupportCaptureDataTransformer.getTechSupportReadableTypeProp(level);
        }

        /**
         * Provides data used by both TechSupportCollection and TechSupportCapture.
         * @param {TechSupportApiStatus} status
         * @return {TechSupportSharedStatus}
         */
        static getTransformedCaptureStatusData(status) {
            const {
                getFileNameFromStatus,
                getResourceName,
                getType,
            } = TechSupportCaptureDataTransformer;

            return {
                fileName: getFileNameFromStatus(status),
                resourceName: getResourceName(status),
                type: getType(status),
            };
        }

        /**
         * Final data used for last tech support capture view.
         * @param {TechSupportApiStatus} data
         * @return {TechSupportCaptureStatus}
         */
        static getTransformedCaptureData(data) {
            const {
                getCaptureStatus,
                getTransformedCaptureStatusData,
                getCaptureError,
            } = TechSupportCaptureDataTransformer;
            /**
             * @type {TechSupportCaptureStatus}
             */
            const result = getTransformedCaptureStatusData(data);

            /**
             * @type {TechSupportCaptureStatus}
             */
            result.captureStatus = getCaptureStatus(data);
            result.captureError = getCaptureError(data, result.captureStatus);

            return result;
        }

        /**
         * @override
         */
        processResponse(rsp) {
            /**
             * @type {TechSupportCaptureStatus}
             */
            const techSupportCaptureData =
                TechSupportCaptureDataTransformer.getTransformedCaptureData(rsp.data);

            return { data: techSupportCaptureData };
        }
    }

    return TechSupportCaptureDataTransformer;
}

techSupportCaptureDataTransformerFactory.$inject = [
    '$filter',
    'RevisedDataTransformer',
    'techSupportStatus',
    'techSupportReadableTypes',
];

/**
 * @ngdoc service
 * @name TechSupportCaptureDataTransformer
 * @module services/TechSupportCaptureDataTransformer
 * @author Akul Aggarwal
 * @description
 *
 *     Transforms data in @TechSupportCapture before consumed.
 *     Has methods which are common for @TechSupportCapture and @TechSupportCollection.
 *     First transformation happens using
 *     @TechSupportCaptureDataTransformer.getTransformedCaptureStatusData,
 *     then additional transformations happen.
 *
 */
angular.module('aviApp').factory('TechSupportCaptureDataTransformer',
    techSupportCaptureDataTransformerFactory);
