/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name ListCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     This DS is responsible for 'config' data of the list Items and their ordering. It is
 *     aware of viewport size, offset, sorting, search and other API specific params and use all of
 *     these for fetching and updating data it has.
 *
 *     Besides simple setters and getters this DS is responsible for merging coming data into
 *     the list, preserving the order and appending/removing Items from the list.
 *
 *     Uses {@link ListDataTransport} for data fetching.
 *
 */

angular.module('aviApp').factory('ListCollDataSource', ['$q', 'CollDataSource',
'DebouncePromiseFactory', 'aviInherit',
function($q, CollDataSource, DebouncePromiseFactory, aviInherit) {
    /**
     * @param {Object} args
     * @constructor
     * @extends CollDataSource
     */
    function ListCollDataSource(args) {
        ListCollDataSource.superconstructor.call(this, args);

        this.itemsTotal_ = 0;
        this.offset_ = 0;
        this.limit_ = 1000;

        // normally we want to debounce these methods keeping in mind that their calls are
        // supposed to return promises, but on special occasions we need to call them
        // instantly cancelling previous postponed call if any.
        const debouncedSetLimit = new DebouncePromiseFactory(this.setLimit.bind(this), 99);

        this.setLimit = debouncedSetLimit.method;
        //way to call same method wo delay
        this.setLimit_ = debouncedSetLimit.immediate;

        const debouncedSetOffset = new DebouncePromiseFactory(this.setOffset.bind(this), 99);

        this.setOffset = debouncedSetOffset.method;
        this.setOffset_ = debouncedSetOffset.immediate;

        /**
         * Hash of key value search pairs.
         * @type {{key:value}}
         * @private
         */
        this.searchParams_ = {};
    }

    aviInherit(ListCollDataSource, CollDataSource);

    ListCollDataSource.prototype.defaultParams_ = {
        includeName_: true,
    };

    /** @override */
    ListCollDataSource.prototype.defaultFields_ = [
        {
            id: 'config',
            preserved: true,
            subscribers: ['__mandatory_field'],
        },
    ];

    ListCollDataSource.prototype.hasSearch = true;
    ListCollDataSource.prototype.hasSorting = true;
    ListCollDataSource.prototype.hasPagination = true;
    ListCollDataSource.prototype.hasTotalNumberOfItems = true;
    ListCollDataSource.prototype.defaultSearchParamName_ = 'name.icontains';

    /**
     * Generates a request parameters object to be passed to DataTransformer and further to
     * perform an actual network call.
     * @params {string} requestType - Only one option is `onOffsetOrLimitUpdate` to load a set of
     *     following Items. Undefined for all other cases.
     * @returns {ListDataTransportRequestParams}
     * @private
     * @override
     */
    ListCollDataSource.prototype.getRequestParams_ = function(requestType) {
        const params = ListCollDataSource.superclass.getRequestParams_.call(this, requestType);

        params['objectName_'] = this.owner_.objectName_;

        if (this.hasPagination) {
            if (requestType === 'onOffsetOrLimitUpdate') { //load extra ones only
                params['offset_'] = this.owner_.getNumberOfItems();
                params['limit_'] = Math.ceil(this.limit_ * this.owner_.overLimitCoeff_ * 2);
            } else {
                params['offset_'] = this.offset_;
                params['limit_'] = Math.ceil(this.limit_ * (1 + this.owner_.overLimitCoeff_ * 2));
            }
        }

        if (this.hasSearch && !_.isEmpty(this.searchParams_)) {
            //TODO remove this fallback once API supports inclusion search within fields
            if (_.size(this.searchParams_) === 1 && 'name' in this.searchParams_) {
                params[this.defaultSearchParamName_] = this.searchParams_.name;
            } else {
                if ('search' in params) {
                    console.error(
                        `API call search param gonna be overwritten, had '${params['search']}'.`,
                    );
                }

                params['search'] = _.map(this.searchParams_, (value, key) => `(${key},${value})`)
                    .join('|');
            }
        }

        return params;
    };

    /**
     * Sets a sorting parameter of CollDataSource#params_.
     * @override
     */
    //TODO return true only if sorting has been actually changed
    ListCollDataSource.prototype.setSortParam = function(propertyName) {
        let res = false;

        if (this.hasSorting) {
            if (propertyName && typeof propertyName === 'string') {
                if (this.params_['sort'] && this.params_['sort'] === propertyName) {
                    if (this.params_['sort'].indexOf('-') === 0) {
                        this.params_['sort'] = this.params_['sort'].slice(1);
                    } else {
                        this.params_['sort'] = `-${this.params_['sort']}`;
                    }
                } else {
                    this.params_['sort'] = propertyName;
                }
            } else {
                delete this.params_['sort'];
            }

            res = true;
        }

        return res;
    };

    /** @override */
    ListCollDataSource.prototype.getSortParam = function() {
        return this.hasSorting ? this.params_['sort'] : undefined;
    };

    /**
     * Extends a CollDataSource#params_ object with passed params. All properties with undefined
     * values will be removed.
     * @override
     */
    ListCollDataSource.prototype.setParams = function(params) {
        if (angular.isObject(params)) {
            // backwards compatibility with previous collection version so that we can get the
            // collection default page_size and set it as limit
            if ('page_size' in params) {
                const pageSize = params.page_size;

                if (angular.isNumber(pageSize) && pageSize > 0 && pageSize < 201) {
                    this.limit_ = params.pageSize;
                }

                delete params.page_size;
            }
        }

        return ListCollDataSource.superclass.setParams.call(this, params);
    };

    /**
     * Sets the search params for the following API calls. For now we support search for one
     * value throughout multiple fields. But not different values for each field.
     * @param {string[]|string=} fieldNames - One or multiple fields to search through.
     * @param {string=} str - Keyword to search for.
     * @override
     */
    //TODO return true only if search has been actually changed
    ListCollDataSource.prototype.setSearchParam = function(fieldNames, str) {
        let res = false;

        if (this.hasSearch) {
            if (fieldNames && !angular.isArray(fieldNames)) {
                fieldNames = [fieldNames];
            }

            const searchWasEmpty = _.isEmpty(this.searchParams_);

            this.searchParams_ = {};

            if (fieldNames && str && fieldNames.length && angular.isString(str)) {
                fieldNames.forEach(fieldName => this.searchParams_[fieldName] = str);
                res = true;
            } else if (!searchWasEmpty) {
                res = true;
            }
        }

        return res;
    };

    /**
     * Updates existent Collection Items with received data.
     * @param {ListDataTransportResponse} resp
     * @private
     */
    ListCollDataSource.prototype.processNonConfigResponse_ = function(resp) {
        _.each(resp.data.results, function(iData) {
            const itemId = this.getItemIdFromData(iData);

            if (itemId in this.itemById) {
                delete iData['config'];
                this.updateItemData(itemId, iData);
            }
        }, this.owner_);
    };

    /**
     * Since list APIs provide both types of data: `config` which can be used to make up the
     * list and non-config (inventory) which can be used for any existent Items updates we are
     * able to process results in two different ways.
     * @param {{data: ListDataTransportResponse}} resp - Backend response transformed by
     *     corresponding DataTransformer.
     * @param {ListDataTransportRequestParams} requestParams - Result of
     *     ListCollDataSource#getRequestParams_ call which was used as a payload for the
     *     network call which is about to be processed.
     * @private
     * @overrides
     */
    ListCollDataSource.prototype.processResponse_ = function(resp, requestParams) {
        if ('config' in this.fields_) { //might become more complex than this
            this.processConfigResponse_(resp, requestParams);
        } else {
            this.processNonConfigResponse_(resp, requestParams);
        }
    };

    /**
     * Creates lists of Item ids to be moved, removed, created or updated depending on Items
     * list we had and response we've got. Each Item can be included into single list only.
     * @param {Item#id[]} oldIds - Ordered list of Item ids we have.
     * @param {Item#id[]} newIds - Ordered list of Item ids we've got from the backend.
     * @param {number} offset - Offset for the list of results we've got.
     * @param {number} limit - Number of Items we were asking for. Not always equal to offset +
     *     newItems.length since we could ask for ten Items and get only four.
     * @returns {{toMove: Item#id[], toRemove: Item#id[], toCreate: Item#id[], toUpdate: Item#id[]}}
     * @private
     */
    ListCollDataSource.prototype.calcMergeDiff_ = function(oldIds, newIds, offset, limit) {
        //ones we are about to replace with a new response
        const prevItems = oldIds.slice(offset, offset + Math.min(limit, newIds.length));

        //these used to be out of the updated part
        const otherPrevItems = _.difference(oldIds, prevItems);

        //these will be moved into current updated part from somewhere else
        const toBeMoved = _.intersection(otherPrevItems, newIds);

        //we had these on the pulled part of list but no more
        const toBeRemoved = _.difference(prevItems, newIds);

        const toBeCreated = _.difference(newIds, oldIds);

        const toBeUpdated = _.intersection(prevItems, newIds);

        //remove tail items when length of received results is smaller than limit we've asked for
        if (newIds.length < limit && offset + newIds.length < oldIds.length) {
            const tailItemsToBeRemoved = _.difference(
                oldIds.slice(offset + newIds.length), toBeMoved, toBeRemoved,
            );

            Array.prototype.push.apply(toBeRemoved, tailItemsToBeRemoved);
        }

        return {
            toMove: toBeMoved,
            toRemove: toBeRemoved,
            toCreate: toBeCreated,
            toUpdate: toBeUpdated,
        };
    };

    /**
     * Gets a preprocessed response data and translates it to Collection in appropriate manner.
     * @param {{data: ListDataTransportResponse}} resp - Backend response transformed by
     *     corresponding DataTransformer.
     * @param {ListDataTransportRequestParams} requestParams - Result of
     *     ListCollDataSource#getRequestParams_ call which was used as a payload for the network
     *     call which is about to be processed.
     * @private
     */
    //TODO take care of visibleIds and offset?
    ListCollDataSource.prototype.processConfigResponse_ = function(resp, requestParams) {
        function getItemId(item) {
            return item.getIdFromData();
        }

        const
            offset = requestParams.offset_ || 0,
            owner = this.owner_,
            newItemIdsList = [], //gotten list of ids
            newItemIdHash = {},
            newItemsDataHash = {},
            toBeInserted = [];//actual array of updated Items to be spliced into this.items

        let limit,
            mergeDiff,
            allPrevItemIdsList,
            offsetShift = 0; //how many we removed before the offset index

        //our best guess on total count
        if (this.hasPagination && !this.hasTotalNumberOfItems) {
            const
                {
                    limit_: limit,
                    offset_: offset,
                } = requestParams,
                { length } = resp.data.results;

            this.itemsTotal_ = this.calcFakeItemsTotal_(offset, limit, length);
        } else if ('count' in resp.data) {
            this.itemsTotal_ = resp.data['count'];
        }

        //here we need to replace old items if this is async or append to the list if those are
        //new
        if (Array.isArray(resp.data.results)) {
            limit = Math.max(requestParams.limit_ || owner.getNumberOfItems(),
                resp.data.results.length);

            _.each(resp.data.results, iData => {
                const id = owner.getItemIdFromData(iData);

                if (!(id in newItemIdHash)) {
                    newItemIdsList.push(id);
                    newItemsDataHash[id] = iData;
                    newItemIdHash[id] = true;
                } else {
                    //this is never the case unless backend (unlikely) or Item.getIdFromData
                    //method is broken
                    console.error('Received/tried to process Item with duplicated IDs: "%s"' +
                        ' Collection: %O, new item data: %O', id, owner, iData);
                }
            });

            allPrevItemIdsList = _.map(owner.items, getItemId);

            mergeDiff = this.calcMergeDiff_(allPrevItemIdsList, newItemIdsList, offset, limit);

            _.each(mergeDiff.toMove, function(itemId) {
                const
                    item = this.itemById[itemId],
                    prevIndex = this.items.indexOf(item),
                    newIndex = newItemIdsList.indexOf(itemId);

                if (prevIndex < offset - offsetShift) {
                    offsetShift++;
                }

                this.items.splice(prevIndex, 1);

                toBeInserted[newIndex] = this.updateItemData(itemId, newItemsDataHash[itemId]);
            }, owner);

            //need to update all fields we are subscribed to
            _.each(mergeDiff.toUpdate, function(itemId) {
                const insertIndex = newItemIdsList.indexOf(itemId);

                toBeInserted[insertIndex] = this.updateItemData(itemId, newItemsDataHash[itemId]);
            }, owner);

            _.each(mergeDiff.toCreate, itemId => {
                const insertIndex = newItemIdsList.indexOf(itemId);

                toBeInserted[insertIndex] = owner.createNewItem({ data: newItemsDataHash[itemId] });
                owner.itemById[itemId] = toBeInserted[insertIndex];
            });

            //actual in-place pushing, we should not update item array anywhere else
            Array.prototype.splice.apply(owner.items,
                [offset - offsetShift, limit].concat(toBeInserted));

            //should be the last step - important!
            _.each(mergeDiff.toRemove, owner.removeItem.bind(owner));

            owner.trigger('collectionLoad collectionLoadSuccess', owner.items);

            if (mergeDiff.toCreate.length || mergeDiff.toRemove.length) {
                owner.trigger(ListCollDataSource.LIST_CHANGE_EVENT);
            }
        }
    };

    /**
     * Returns a number of Items backend has for last set of query parameters used for data load.
     * @override
     */
    ListCollDataSource.prototype.getTotalNumberOfItems = function() {
        return (this.hasTotalNumberOfItems || this.hasPagination) ?
            this.itemsTotal_ : undefined;
    };

    /** @override */
    ListCollDataSource.prototype.hasRealTotalNumberOfItems = function() {
        return this.hasTotalNumberOfItems;
    };

    /**
     * Checks whether it is time to pre-load results for the future scrolling down or if
     * viewport size has increased.
     * @returns {ng.$q.promise}
     * @private
     */
    //TODO when isStatic `count` property won't be updated
    ListCollDataSource.prototype.onOffsetOrLimitUpdate_ = function() {
        let promise;

        if (this.hasPagination) {
            if (this.owner_.getNumberOfItems() < Math.min(this.itemsTotal_,
                Math.ceil(this.offset_ + this.limit_ * (1 + this.owner_.overLimitCoeff_)))) {
                // we don't want to reload the whole viewport & extra, but just extra
                promise = this.load('onOffsetOrLimitUpdate');
            } else {
                promise = $q.when('No need to load list items now.');
            }
        } else {
            promise = $q.when(`pagination is not provided by DS instance with id: ${this.id}`);
        }

        return promise;
    };

    /** @override */
    ListCollDataSource.prototype.setOffset = function(offset, visibleItemIds, noEvent) {
        let promise;

        if (this.hasPagination) {
            offset = typeof offset === 'number' && offset > 0 ? offset : 0;

            if (offset !== this.offset_) {
                this.offset_ = offset;
                promise = noEvent ?
                    $q.when('No onOffsetChange event has been requested') :
                    this.onOffsetOrLimitUpdate_();
            } else {
                promise = $q.when('equals to the current offset');
            }
        } else {
            promise = $q.when(`pagination is not provided by DS instance with id: ${this.id}`);
        }

        return promise;
    };

    ListCollDataSource.prototype.setOffset_ = ListCollDataSource.prototype.setOffset;

    ListCollDataSource.prototype.setLimit = function(limit, noEvent) {
        let promise;

        if (this.hasPagination) {
            limit = typeof limit === 'number' && _.isFinite(limit) && limit > 0 ?
                Math.round(limit) : this.owner_.getDefaultViewportSize();

            if (limit !== this.limit_) {
                this.limit_ = limit;
                promise = noEvent ?
                    $q.when('No onOffsetChange event has been requested') :
                    this.onOffsetOrLimitUpdate_();
            } else {
                promise = $q.when('equals to the current limit');
            }
        } else {
            promise = $q.when(`pagination is not provided by DS instance with id: ${this.id}`);
        }

        return promise;
    };

    ListCollDataSource.prototype.setLimit_ = ListCollDataSource.prototype.setLimit;

    /**
     * Resets ListCollDataSource#offset_ and ListCollDataSource#itemsTotal_ values. To be called by
     * Collection.
     * @override
     */
    ListCollDataSource.prototype.onDataFlush = function() {
        this.setOffset_(0, undefined, true);
        this.itemsTotal_ = 0;
    };

    /**
     * If DS keeps a total number of Items we want to decrease it by one on Collection Item drop
     * event.
    **/
    ListCollDataSource.prototype.onItemDrop_ = function() {
        if ((this.hasTotalNumberOfItems || this.hasPagination) && this.itemsTotal_ > 0) {
            this.itemsTotal_--;
        }
    };

    /**
     * For APIs not providing total number of items but supporting pagination we can make our best
     * to figure it on every update. This is fluffy and temporary.
     * Basically when we've got full page of results, we assume there are more entries unless we
     * get less than we've asked for and only then returned number is a real count.
     * @param {number} offset
     * @param {number} limit
     * @param {number} respLength
     * @returns {number}
     * @protected
     */
    ListCollDataSource.prototype.calcFakeItemsTotal_ = function(offset, limit, respLength) {
        const actualCountFound = respLength && respLength < limit;

        if (actualCountFound) {
            return offset + respLength;
        }

        if (!respLength) {
            return Math.max(0, Math.min(this.itemsTotal_, offset - limit));
        } else { //received full list we've asked for, +1 so that collection asks for the next page
            const lastFoundIndex = offset + limit;

            return this.itemsTotal_ < lastFoundIndex ? lastFoundIndex + 1 : this.itemsTotal_;
        }
    };

    ListCollDataSource.LIST_CHANGE_EVENT = 'listChangedOnUpdate';

    return ListCollDataSource;
}]);
