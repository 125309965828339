/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('AlertCollection', ['$q', 'Collection', 'Alert',
function($q, Collection, Alert) {
    function AlertCollection(oArgs) {
        AlertCollection.superconstructor.call(this, oArgs);
    }

    avi.inherit(AlertCollection, Collection);

    AlertCollection.prototype.objectName_ = 'alert';
    AlertCollection.prototype.itemClass_ = Alert;

    AlertCollection.prototype.defaultDataSources_ = 'list';
    AlertCollection.prototype.allDataSources_ = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'AlertListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    /**
     * Setting range parameters and calling load
     * @param tBegin {date} - Begin time
     * @param tEnd {date} - End time
     * @return {promise}
     */
    AlertCollection.prototype.loadRange = function(tBegin, tEnd) {
        let errMsg,
            promise;

        if (!tBegin || !tEnd) {
            errMsg = 'AlertCollection.loadRange failed: begin or end is not passed';
            console.error(errMsg);
            promise = $q.reject(errMsg);
        } else {
            // Remove ts_duration from previous loadDuration requests
            // calculate beginning and end using timeDifference

            this.setParams({
                ts_duration: undefined,
                ts_start: moment.utc(tBegin).format('YYYY-MM-DDTHH:mm:ss'),
                ts_end: moment.utc(tEnd).format('YYYY-MM-DDTHH:mm:ss'),
            });

            promise = this.load(undefined, true);
        }

        return promise;
    };

    /**
     * Setting step and limit parameters and calling load
     * @param step {int} - Current timeframe step
     * @param limit {int} - Current timeframe limit
     * @return {promise}
     */
    AlertCollection.prototype.loadDuration = function(step, limit) {
        let errMsg,
            promise;

        if (!step || !limit) {
            errMsg = 'AlertCollection.loadDuration failed: step or limit is not passed';
            promise = $q.reject(errMsg);
            console.error(errMsg);
        } else {
            // Remove ts_start, ts_end from previous loadRange requests
            // set duration in seconds
            this.setParams({
                ts_duration: step * limit,
                ts_start: undefined,
                ts_end: undefined,
            });

            promise = this.load(undefined, true);
        }

        return promise;
    };

    /**
     * Alerts API doesn't support search yet, but we want to provide ability to return only
     * one alert with passed id. Search string should have an appropriate format like
     * alertId=<uuid>.
     * @override
     */
    AlertCollection.prototype.setSearch = function(str) {
        const
            searchByUuidExp = /alertId\s*=\s*['"]?([\w-]+)['"]?/i,
            prevUuidParamValue = this.getParams('uuid');

        let
            res = false,
            regExpRes;

        //special case for uuid access
        if (str && typeof str === 'string' && (regExpRes = searchByUuidExp.exec(str))) {
            if (regExpRes[1] !== prevUuidParamValue) {
                this.setParams({ uuid: regExpRes[1] });
                res = true;
            }

            //empty any search string we used to have
            res = this.constructor.superclass.setSearch.call(this, undefined) || res;
        } else { //regular search
            this.setParams({ uuid: undefined });

            if (prevUuidParamValue) {
                res = true;
            }

            res = this.constructor.superclass.setSearch.call(this, str) || res;
        }

        return res;
    };

    AlertCollection.prototype.isCreatable = function() { return false; };

    AlertCollection.prototype.isEditable = function() { return false; };

    return AlertCollection;
}]);
