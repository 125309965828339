/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function healthMonitorFactory(Item, defaultValues) {
    function HealthMonitor(oArgs) {
        HealthMonitor.superconstructor.call(this, oArgs);
    }

    avi.inherit(HealthMonitor, Item);

    HealthMonitor.prototype.objectName = 'healthmonitor';
    HealthMonitor.prototype.windowElement = 'prof-healthmonitor-create';
    HealthMonitor.prototype.params = {
        include_name: true,
    };

    /**
     * Returns true if any field is present in https_monitor#ssl_attributes, either an
     * 'ssl_profile_ref', 'pki_profile_ref', or 'ssl_key_and_certificate_ref'.
     * @return {boolean}
     */
    HealthMonitor.prototype.hasSslAttributes = function() {
        const { https_monitor: healthMonitor } = this.getConfig();

        return this.isHttpsType() &&
            !_.isEmpty(healthMonitor.ssl_attributes) &&
            _.any(healthMonitor.ssl_attributes, value => !_.isEmpty(value));
    };

    /**
     * Removes fields set on https_monitor#ssl_attributes.
     */
    HealthMonitor.prototype.clearSslAttributes = function() {
        if (!this.isHttpsType()) {
            return;
        }

        this.getConfig().https_monitor.ssl_attributes = {};
    };

    /**
     * Handles Health Monitor type change.
     * Only works on HM creation.
     */
    HealthMonitor.prototype.onTypeChange = function() {
        this.clearIrrelevantMonitorSubObjects_();
        this.resetMonitorConfigIfUnset_();
    };

    /**
     * Clears all existing and non-relevant monitor sub-objects (typeConfigProps_).
     * @protected
     */
    HealthMonitor.prototype.clearIrrelevantMonitorSubObjects_ = function() {
        const
            config = this.getConfig(),
            propNameToPreserve = HealthMonitor.typeToConfigProp_[config.type];

        /**
         * Removes propName if exists, and if is not propNameToPreserve.
         * @param {string} propName - one of typeConfigProps_
         * @inner
         */
        function deleteMonitor(propName) {
            if (!(propName in config)) {
                return;
            }

            if (propNameToPreserve !== propName) {
                delete config[propName];
            }
        }

        HealthMonitor.typeConfigProps_.forEach(deleteMonitor);
    };

    /**
     * Initializes default or empty monitor object (of type typeConfigProps_), if applicable,
     * and if doesn't exist.
     * @protected
     */
    HealthMonitor.prototype.resetMonitorConfigIfUnset_ = function() {
        const
            config = this.getConfig(),
            monitorConfig = HealthMonitor.typeToConfigProp_[config.type];

        // Note: Following only needed because resetMonitorConfigIfUnset_ is used in beforeEdit,
        // otherwise resetMonitorConfigIfUnset_ would override existing sub-object with empty one.
        if (config[monitorConfig]) {
            return;
        }

        if (monitorConfig) {
            config[monitorConfig] = this.getDefaultConfig()[monitorConfig] || {};
        }
    };

    /**
     * @override
     */
    HealthMonitor.prototype.beforeEdit = function() {
        this.clearIrrelevantMonitorSubObjects_();
        // Note: Following needed only because currently existing HTTPS Health Monitors may not
        // have the HTTPS_MONITOR sub-object, which may cause error in UI
        this.resetMonitorConfigIfUnset_();
    };

    /**
     * @override
     */
    HealthMonitor.prototype.dataToSave = function() {
        const config = angular.copy(this.getConfig());

        if (this.isDnsType() && 'monitor_port' in config && !config['is_federated']) {
            delete config['monitor_port'];
        }

        return config;
    };

    /**
     * Returns Health Monitor type.
     * @return {string}
     */
    HealthMonitor.prototype.getType = function() {
        return this.getConfig().type;
    };

    /**
     * Checks if Health Monitor item is HTTPS type.
     * @returns {boolean}
     */
    HealthMonitor.prototype.isHttpsType = function() {
        return this.getType() === 'HEALTH_MONITOR_HTTPS';
    };

    /**
     * Checks if Health Monitor item is HTTPS type.
     * @returns {boolean}
     */
    HealthMonitor.prototype.isDnsType = function() {
        return this.getType() === 'HEALTH_MONITOR_DNS';
    };

    /**
     * List of all possible configs per each HMon type.
     * @type {string[]}
     * @static
     * @protected
     */
    HealthMonitor.typeConfigProps_ = [
        'external_monitor',
        'http_monitor',
        'https_monitor',
        'tcp_monitor',
        'udp_monitor',
        'dns_monitor',
        'sip_monitor',
        'radius_monitor',
    ];

    /**
     * Correspondence hash between HMon type and it's config property.
     * @type {{string: string}}
     * @protected
     * @static
     */
    HealthMonitor.typeToConfigProp_ = {
        HEALTH_MONITOR_PING: '', //no fields needed
        HEALTH_MONITOR_TCP: 'tcp_monitor',
        HEALTH_MONITOR_UDP: 'udp_monitor',
        HEALTH_MONITOR_HTTP: 'http_monitor',
        HEALTH_MONITOR_HTTPS: 'https_monitor',
        HEALTH_MONITOR_DNS: 'dns_monitor',
        HEALTH_MONITOR_EXTERNAL: 'external_monitor',
        HEALTH_MONITOR_SIP: 'sip_monitor',
        HEALTH_MONITOR_RADIUS: 'radius_monitor',
    };

    return HealthMonitor;
}

healthMonitorFactory.$inject = [
    'Item',
    'defaultValues',
];

/**
 * @name HealthMonitor
 * @ngdoc service
 * @description
 *
 *     Health Monitor item.
 *
 */
angular.module('aviApp').factory('HealthMonitor', healthMonitorFactory);
