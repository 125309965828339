/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function vipConfigFactory(Schema, Regex, ConfigItem, RangeParser) {
    class VipConfig extends ConfigItem {
        constructor(args) {
            const extendedArgs = angular.extend({ type: 'Vip' }, args);

            super(extendedArgs);
            this.data._cloudRef = args && args.data && args.data.cloudRef;
        }

        /**
         * Sets the _cloudRef property on VipConfig#data#config#data. Used when manually
         * retrieving the name of the network if it's not present as part of the network_ref.
         * @param {string} cloudRef - Cloud url.
         */
        setCloudRefOnData(cloudRef) {
            this.data._cloudRef = cloudRef;
        }

        /**
         * Sets the VipConfig#data#config#auto_allocate_ip field.
         * @param {boolean} enabled - True to enable auto_allocate_ip.
         */
        setAutoAllocateIp(enabled = false) {
            const config = this.getConfig();

            config.auto_allocate_ip = enabled;
        }

        /**
         * Sets the VipConfig#data#config#auto_allocate_floating_ip field.
         * @param {boolean} enabled - True to enable auto_allocate_floating_ip.
         */
        setAutoAllocateFloatingIp(enabled = false) {
            const config = this.getConfig();

            config.auto_allocate_floating_ip = enabled;
        }

        /**
         * Clears the VipConfig#data#config#network_ref property.
         * If ipam_network_subnet exists, clears
         * VipConfig#data#config#ipam_network_subnet#network_ref as well.
         */
        clearVipNetwork() {
            const config = this.getConfig();

            delete config.network_ref;

            if (angular.isObject(config.ipam_network_subnet)) {
                config.ipam_network_subnet.network_ref = undefined;
            }
        }

        /**
         * Sets the VipConfig#data#config#subnet and VipConfig#data#config#subnet_uuid
         * properties.
         * @param {IpAddrPrefix} subnet - Prefix for IP subnet.
         * @param {string} uuid - Subnet uuid.
         * @param {boolean} isIpamSubnet - If true, sets the subnet and uuid on
         *     VipConfig#data#config#ipam_network_subnet. Otherwise, set those properties on
         *     VipConfig#data#config.
         */
        setVipSubnet(subnet, uuid, isIpamSubnet = false) {
            if (angular.isObject(subnet)) {
                let config = this.getConfig();

                if (isIpamSubnet) {
                    if (!angular.isObject(config.ipam_network_subnet)) {
                        config.ipam_network_subnet = {};
                    }

                    config = config.ipam_network_subnet;
                }

                switch (subnet.ip_addr.type) {
                    case 'V4':
                        config.subnet = subnet;
                        config.subnet_uuid = uuid;
                        break;
                    case 'V6':
                        config.subnet6 = subnet;
                        config.subnet6_uuid = uuid;
                        break;
                }
            }
        }

        /**
         * Clears the Vip#data#config#subnet and Vip#data#config#subnet_uuid properties.
         * If ipam_network_subnet exists clears the subnet properties in that object as well.
         * @param {boolean=} isOpenStack - true, if the cloud type is openstack
         */
        clearVipSubnet(isOpenStack) {
            const config = this.getConfig();

            // config fields to clear
            const networkFields = [
                'subnet',
                'subnet_uuid',
                'subnet6',
                'subnet6_uuid',
                'floating_subnet_uuid',
            ];

            if (isOpenStack) {
                // Fields to clear for openstack vs
                const openStackFields = [
                    'port_uuid',
                    'network_ref',
                ];

                networkFields.push(...openStackFields);
            }

            let ipamSubnet;

            if (angular.isObject(config.ipam_network_subnet)) {
                ipamSubnet = config.ipam_network_subnet;
            }

            networkFields.forEach(field => {
                if (field in config) {
                    delete config[field];
                }

                if (ipamSubnet && field in ipamSubnet) {
                    delete ipamSubnet[field];
                }
            });
        }

        /**
         * Clears Vip's Placement networks's subnet.
         */
        clearPlacementNetworkSubnet() {
            const config = this.getConfig();
            const { placement_networks: placementNetworks } = config;

            if (placementNetworks && placementNetworks.length) {
                placementNetworks.forEach(placementNetwork => {
                    delete placementNetwork.subnet;
                    delete placementNetwork.subnet6;
                });
            }
        }

        /**
         * Sets the Vip#data#config#ip_address property.
         * @param {string|IpAddr|undefined} address - IP address string, object, or undefined.
         * @param {string} prop - ip_address or ip6_address.
         */
        setIpAddr(address, prop = 'ip_address') {
            this._setIpAddr(prop, address);
        }

        /**
         * Sets the Vip#data#config#floating_ip property.
         * @param {string|IpAddr|undefined} address - IP address string, object, or undefined.
         */
        setFloatingIpAddr(address) {
            this._setIpAddr('floating_ip', address);
        }

        /**
         * Clears the Vip#data#config#floating_ip and Vip#data#config#floating_subnet_uuid
         * fields.
         */
        clearFloatingIp() {
            this.setFloatingIpAddr(undefined);
        }

        /**
         * Returns true if floating IP is configured.
         * @return {boolean}
         */
        floatingIpIsEnabled() {
            const config = this.getConfig();

            return !!(config.floating_ip && config.floating_ip.addr ||
                    config.auto_allocate_floating_ip);
        }

        /**
         * Sets the IP address in the VipConfig object for a specified property.
         * @param {string} prop - Property in the VipConfig object to update. Either
         *     'floating_ip' or 'ip_address'.
         * @param {string|IpAddr|undefined} address - IP address string, IpAddr object, or
         *     undefined.
         */
        _setIpAddr(prop, address) {
            const config = this.getConfig();

            if (angular.isUndefined(address) || angular.isObject(address)) {
                config[prop] = address;
            } else if (Regex.anyIP.test(address)) {
                config[prop] = RangeParser.ipRange2Json(address);
            } else {
                config[prop] = undefined;
            }
        }

        /**
         * Returns either IPv4 or IPv6 address.
         * @return {string}
         */
        getAnyVipAddress() {
            return this.getVipAddress() || this.getIP6VipAddress();
        }

        /**
         * Returns the IP address string in the Vip object.
         * @return {string}
         */
        getVipAddress() {
            const config = this.getConfig();

            return config.ip_address && config.ip_address.addr;
        }

        /**
         * Returns ip6_address address.
         * @return {string}
         */
        getIP6VipAddress() {
            const config = this.getConfig();

            return config.ip6_address && config.ip6_address.addr;
        }

        /**
         * Returns a hash of IP addresses used by this VIP.
         * @returns {{string: ipAddr}}
         * @public
         */
        getVipAddressHash() {
            const config = this.getConfig();

            return _.pick(config, VipConfig.ipProps);
        }

        /**
         * Returns true if Vip has been configured with an IP address or a network.
         * @return {boolean}
         */
        isSet() {
            const config = this.getConfig();

            return !!(this.getAnyVipAddress() || config.network_ref ||
                    config.ipam_network_subnet && config.ipam_network_subnet.network_ref);
        }

        /**
         * Returns vipId, which is string with a number, unique within one VSVip.
         * @returns {string}
         * @public
         */
        get id() {
            return this.getConfig()['vip_id'];
        }
    }

    /**
     * List of property names with IP addresses of the VIP.
     * @type {string[]}
     * @static
     */
    VipConfig.ipProps = [
        'ip_address',
        'floating_ip',
        'ip6_address',
        'floating_ip6',
    ];

    return VipConfig;
}

vipConfigFactory.$inject = [
    'Schema',
    'Regex',
    'ConfigItem',
    'RangeParser',
];

/**
 * @ngdoc factory
 * @name  VipConfig
 * @description  Vip ConfigItem class.
 */
angular.module('aviApp').factory('VipConfig', vipConfigFactory);
