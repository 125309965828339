/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name naturalSort
 * @returns {number}
 * @description
 *
 *      Comparator for natural sorting.
 *      A negative number if the reference string occurs before the compare string;
 *      positive if the reference string occurs after the compare string;
 *      0 if they are equivalent.
 */
function naturalSort(a = '', b = '') {
    return `${a}`.localeCompare(`${b}`, undefined, { numeric: true });
}

angular.module('utils.vantage.avi')
    .constant('naturalSort', naturalSort);
