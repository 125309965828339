/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name parseIpRange
 * @restrict A
 * @description
 *      Parses IpAddr or IpAddrRange or IpAddrPrefix proto message to string and vice versa.
 */
angular.module('aviApp').directive('parseAnyIp', [
'RangeParser', 'getSubnetString',
function(RangeParser, getSubnetString) {
    /**
     * NgModelCtrl parser: viewValue to modelValue.
     * @param {string} str
     * @returns {IpAddr|IpAddrRange|IpAddrPrefix|null}
     * @private
     */
    function parseAnyIPParser(str) {
        if (!str) {
            return str;
        }

        return RangeParser.ipRange2Json(str);
    }

    /**
     * NgModelCtrl formatter: modelValue to the viewValue.
     * @param {IpAddr|IpAddrRange|IpAddrPrefix} obj
     * @returns {string}
     * @private
     */
    function parseAnyIPFormatter(obj) {
        if (angular.isObject(obj)) {
            if ('begin' in obj && 'end' in obj) {
                return `${obj.begin.addr}-${obj.end.addr}`;
            } else if ('mask' in obj) {
                return getSubnetString(obj);
            } else if ('addr' in obj) {
                return obj.addr;
            }
        }

        return '';
    }

    //need preLink to work correctly with directives having priority set
    //so that they have a chance to register formatters/parsers before these
    function parseAnyIpPreLink(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseAnyIPParser);
        ngModel.$formatters.push(parseAnyIPFormatter);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        compile: () => ({
            pre: parseAnyIpPreLink,
        }),
    };
}]);
