/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ClusterNodeCollection', ['$q', 'Collection', 'ClusterNode',
    function($q, Collection, ClusterNode) {
        /**
         * Collection of the cluster's nodes. Have one cluster in a system which has up to three
         * nodes. Need to call a cluster `config` and `runtime` APIs for the node's name, ip,
         * state and role.
         * @param {Object} args
         * @constructor
         */
        function ClusterNodeCollection(args) {
            ClusterNodeCollection.superconstructor.call(this, args);
        }

        avi.inherit(ClusterNodeCollection, Collection);

        ClusterNodeCollection.prototype.objectName_ = 'cluster';
        ClusterNodeCollection.prototype.itemClass_ = ClusterNode;
        ClusterNodeCollection.prototype.isStatic_ = false;

        ClusterNodeCollection.prototype.defaultDataSources_ = 'list';
        ClusterNodeCollection.prototype.allDataSources_ = {
            list: {
                source: 'ClusterNodeListCollDataSource',
                transformer: 'ClusterNodeDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
            'runtime-list': {
                source: 'ClusterNodeRuntimeCollDataSource',
                transformer: 'ClusterNodeDataTransformer',
                transport: 'ListDataTransport',
                fields: ['runtime'],
                dependsOn: 'config',
            },
        };

        return ClusterNodeCollection;
    }]);

angular.module('aviApp').factory('ClusterNode', ['UpdatableItem', function(UpdatableItem) {
    /**
     * Cluster Node Item. Uses name as Id (doesn't have a real id).g status
     * @param oArgs {Object}
     * @constructor
     */
    const ClusterNode = function(oArgs) {
        ClusterNode.superconstructor.call(this, oArgs);
    };

    avi.inherit(ClusterNode, UpdatableItem);

    /** @override */
    ClusterNode.prototype.getIdFromData_ = function(data) {
        return data && data.config && data.config.name || '';
    };

    return ClusterNode;
}]);
