/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name convertBytes
 * @description
 *      Formats ngModel by dividing it by provided number. ngModel can be any number.
 *      The specified convertBytes value will be used to divide ngModel.
 * @example
 *      Input displays 1 when ngModel=1024 or displays 2 when ngModel=2048.
 *      <input convert-bytes="1024">
 *
 *      Input displays 5 when ngModel is 10 or displays 10 when ngModel=20.
 *      <input convert-bytes="2">
 *
 */
angular.module('aviApp').directive('convertBytes', () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attrs, ngModel) {
            const multiplier = +attrs['convertBytes'] || 1;

            ngModel.$formatters.push(value => +value / multiplier);
            ngModel.$parsers.push(value => +value * multiplier);
        },
    };
});
