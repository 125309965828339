/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const pathMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/PathMatchConfigItem
     * @private
     */
    class PathMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'PathMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchCaseLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchCase');
            this.operationLabelsHash_ = schemaService.getEnumValueLabelsHash('StringOperation');
        }

        /**
         * Returns the label to display for this.config.match_case.
         * @returns {string}
         */
        get matchCaseLabel() {
            const { match_case: matchCase } = this.config;

            return this.matchCaseLabelsHash_[matchCase];
        }

        /**
         * Returns the label to display for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.operationLabelsHash_[matchCriteria];
        }

        /**
         * Returns the label to display for this.config.match_str.
         * @returns {string}
         */
        get matchStrings() {
            const { match_str: matchString = [] } = this.config;

            return matchString.join(', ');
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${this.matchStrings}`;
        }
    }

    return PathMatchConfigItem;
};

pathMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  PathMatchConfigItem
 * @description  PathMatch ConfigItem class.
 * @module config-item/PathMatchConfigItem
 * @author alextsg
 */
angular.module('policies.vantage.avi').factory('PathMatchConfigItem', pathMatchConfigItemFactory);
