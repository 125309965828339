/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const BASE_URL = '/api/projectx';
const RETURN_URL = '/cportal/after-cportal-registration.html';
const PORTAL_LOGIN_URL = `${BASE_URL}/login?return_url=${RETURN_URL}`;
const STATUS_URL = `${BASE_URL}/status`;
const STATUS_REFRESH_URL = `${STATUS_URL}/?update=true`;
const REGISTRATION_URL = `${BASE_URL}/register`;
const PORTAL_INFO_URL = '/api/customerportalinfo';

class AviCloudService {
    constructor($window, $http, $q, Cluster) {
        this.$window_ = $window;
        this.$http_ = $http;
        this.$q_ = $q;

        /**
         * Reference of Cluster Item
         * @type {Object}
         */
        this.Cluster_ = Cluster;

        /**
         * Interval object
         * @type {Object}
         */
        this.interval = null;
    }

    /**
     * Function is used to authenticate user with Salesforce.
     * @return {ng.$q.promise}
     */
    openLoginWindow() {
        const deferred = this.$q_.defer();

        const windowDimensions = 'width=800, height=600, top=200, left=450';

        const loginWindow = this.$window_.open(PORTAL_LOGIN_URL, 'login', windowDimensions);

        this.interval = setInterval(() => {
            if (loginWindow.closed) {
                clearInterval(this.interval);
                deferred.resolve();
            }
        }, 1000);

        return deferred.promise;
    }

    /**
     * This function is used to refresh the registration status
     * @return {ng.$q.promise} Promise returned from http request.
     */
    refreshStatus() {
        return AviCloudService.handleHttpResponse_(this.$http_.get(STATUS_REFRESH_URL));
    }

    /**
     * It Register's Controller with Portal
     * @param {Object} payload - Object with controller name and description
     * @return {ng.$q.promise} Promise returned from http request.
     */
    registerController(payload) {
        return AviCloudService.handleHttpResponse_(this.$http_.post(REGISTRATION_URL, payload));
    }

    /**
     * It De-register's Controller with Portal
     * @return {ng.$q.promise} Promise returned from http request.
     */
    deregisterController() {
        const payload = {
            status: 'Obsolete',
        };

        return AviCloudService.handleHttpResponse_(this.$http_.put(REGISTRATION_URL, payload));
    }

    /**
     * Retuns basic details regarding Cluster.
     * @return {ng.$q.promise} Promise returned from http request.
     */
    getClusterDetails() {
        const cluster = new this.Cluster_();

        return AviCloudService.handleHttpResponse_(cluster.loadConfig())
            .finally(() => cluster.destroy());
    }

    /**
     * This function returns customer portal information.
     * @return {ng.$q.promise} Promise returned from http request.
     */
    getPortalInfo() {
        return AviCloudService.handleHttpResponse_(this.$http_.get(PORTAL_INFO_URL));
    }

    /**
     * This function handles http response and throws error in case of exception.
     * @param {Object} request - Http request object.
     * @return {ng.$q.promise} Handles response returned from Http request.
     * @protected
     */
    static handleHttpResponse_(request) {
        return request
            .then(response => response.data)
            .catch(error => { throw error.data; });
    }

    /**
     * @override
     */
    $onDestroy() {
        clearInterval(this.interval);
    }
}

AviCloudService.$inject = [
    '$window',
    '$http',
    '$q',
    'Cluster',
];

/**
 * @ngdoc service
 * @name AviCloudService
 * @author Ram Pal
 * @description
 *     AviCloudService is a stateless service which contains functions related to
 *     login, status, refresh, registation, de-registration process.
 */
angular.module('avi/cportal')
    .service('AviCloudService', AviCloudService);
