/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2017] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const CustomIpamDnsProfileFactory = Item => {
    class CustomIpamDnsProfile extends Item {
        static createCustomParam() {
            return {
                name: '',
                value: '',
                is_sensitive: false,
                is_dynamic: false,
            };
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!Array.isArray(config.script_params)) {
                config.script_params = [];
            }
        }

        /**
         * Removes element from script_params array by index.
         * @param {number} index
         * @public
         */
        removeScriptParam(index) {
            const config = this.getConfig();

            config.script_params.splice(index, 1);
        }

        /**
         * Adds new CustomParams object to script_params array.
         * @public
         */
        addScriptParam() {
            const config = this.getConfig();

            config.script_params.push(CustomIpamDnsProfile.createCustomParam());
        }
    }

    angular.extend(CustomIpamDnsProfile.prototype, {
        objectName_: 'customipamdnsprofile',
        windowElement_: 'custom-ipam-dns-profile-modal',
    });

    return CustomIpamDnsProfile;
};

CustomIpamDnsProfileFactory.$inject = ['Item'];

/**
 * @ngdoc factory
 * @name CustomIpamDnsProfile
 * @description
 *      CustomIpamDnsProfile item.
 */
angular.module('aviApp').factory('CustomIpamDnsProfile', CustomIpamDnsProfileFactory);
