/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name systemInfoService
 * @author Alex Malitsky
 * @description
 *
 *     Sharable instance of system info data with a few convenience methods. Not an Item.
 */
angular.module('aviApp').service('systemInfoService', [
'$q', 'Base',
function($q, Base) {
    /**
     * @class
     * @extends Base
     */
    class SystemInfo extends Base {
        constructor() {
            super();

            /**
             * Actual data of a System Info service.
             * @type {?Object}
             */
            this.data = null;

            /**
             * Internal property to figure out whether loading is in process.
             * @type {?angular.$q.promise}
             * @private
             */
            this.loadPromise_ = null;

            /**
             * When set to true load will pull data from API, would use cache (this.data) otherwise.
             * @type {boolean}
             * @protected
             */
            this.dataCacheExpired_ = true;

            /**
             * Timeout id returned by setTimeout. NaN by default.
             * @type {number}
             * @protected
             */
            this.dataCacheInvTimeout_ = NaN;

            this.invalidateDataCache_ = this.invalidateDataCache_.bind(this);
            this.onLoad_ = this.onLoad_.bind(this);
        }

        /**
         * Checks whether it is loaded.
         * @returns {boolean}
         * @public
         */
        isReady() {
            return !!this.getData();
        }

        /**
         * Loads system info data. Only one pending API call may be present in any point of time.
         * Made for ui-router dependencies so that many states could request info data and it won't
         * cause multiple API calls. Also if loaded data is considered "fresh" no reloading will
         * be made and cached version gonna be used instead.
         * @param {boolean} [force=false] if true then we will ignore cached data and make api call.
         *      By default it is false.
         * @returns {angular.$q.promise}
         * @public
         */
        load(force = false) {
            let promise = this.loadPromise_;

            if (!promise) {
                if (force || this.dataCacheExpired_) {
                    this.loadPromise_ =
                        this.request('get', '/api/system-info', null, undefined, 'default')
                            .then(this.onLoad_)
                            .finally(() => this.loadPromise_ = null);

                    promise = this.loadPromise_;
                } else {
                    promise = $q.when(this.getData());
                }
            }

            return promise;
        }

        /**
         * Returns avicloudstatus object.
         * @returns {Object|null}
         * @public
         */
        getAviCloudStatus() {
            return this.data['avicloudstatus'] || null;
        }

        /**
         * Returns cluster object.
         * @returns {Object|null}
         * @public
         */
        getClusterInfo() {
            return this.data['cluster'] || null;
        }

        /**
         * Processes and saves data provided by the backend. Also sets cache invalidation timeout.
         * @param {Object} data
         * @returns {Object}
         * @protected
         */
        onLoad_({ data }) {
            this.data = data;

            this.dataCacheExpired_ = false;

            clearTimeout(this.dataCacheInvTimeout_);

            this.dataCacheInvTimeout_ =
                setTimeout(this.invalidateDataCache_, SystemInfo.dataCacheMaxAge_ * 1000);

            return data;
        }

        /**
         * Sets data cache expired flag to true and drops pending timeout if present.
         * @protected
         */
        invalidateDataCache_() {
            this.dataCacheExpired_ = true;
            clearTimeout(this.dataCacheInvTimeout_);
            this.dataCacheInvTimeout_ = NaN;
        }

        /**
         * Compares GSLB config leader cluster_id of with cluster_id of a current controller.
         * Used by GSLB related Items and Collections to figure out whether they are changeable.
         * @returns {boolean}
         * @public
         */
        localSiteIsGSLBLeader() {
            return this.haveGSLBConfig() &&
                this.data['gslb']['leader_cluster_uuid'] === this.getLocalClusterId();
        }

        /**
         * Checks whether back-end has a GSLB configuration to show or hide some navigational tabs.
         * @returns {boolean}
         * @public
         */
        haveGSLBConfig() {
            return this.isReady() && 'leader_cluster_uuid' in this.data['gslb'];
        }

        /**
         * Returns a current\local cluster uuid.
         * @public
         */
        getLocalClusterId() {
            return this.getClusterInfo()['uuid'];
        }

        /**
         * Returns a current\local cluster name.
         * @public
         */
        getLocalClusterName() {
            return this.getClusterInfo()['name'];
        }

        /**
         * Returns GSLB id.
         * @returns {string}
         * @public
         */
        getGSLBid() {
            return this.data['gslb']['uuid'];
        }

        /**
         * Returns systemInfo data object.
         * @returns {Object|null}
         * @public
         */
        getData() {
            return this.data;
        }

        /**
         * Checks whether the SE is in provider context or not
         * with the tenant settings changed to per-tenant SE mode
         * @returns {boolean}
         * @public
         */
        hasSeInProviderContext() {
            return !!this.getData()['systemconfiguration']['global_tenant_config']
                ['se_in_provider_context'];
        }
    }

    /**
     * Duration in seconds for which loaded data is considered "fresh" (cached).
     * @type {number}
     * @protected
     * @static
     */
    SystemInfo.dataCacheMaxAge_ = 15;

    return new SystemInfo();
}]);
