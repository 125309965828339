/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

angular.module('aviApp').service('roleService', RoleService);
/**
 * @typedef {Object} RoleNode
 * @property {string} name
 * @property {string} permission
 * @property {string} type
 * @property {boolean} assorted
 * @property {RoleNode[]} children
 * @property {RoleNode} parentNode
 * @property {boolean} canWrite
 * @property {boolean} canRead
 * @property {string} hint
 */

/**
 * @typedef {Object} BasicRoleNode
 * @property {string} name
 * @property {string} permission
 * @property {BasicRoleNode[]} children
 */

/**
 * Role structure.
 * @const {Array<BasicRoleNode>}
 */
// TODO(logashoff): Generate this array from Schema object.
const roleCategories = [
    {
        name: 'Application',
        permission: 'APPLICATION',
        children: [
            {
                name: 'Virtual Service',
                permission: 'PERMISSION_VIRTUALSERVICE',
            }, {
                name: 'Pool',
                permission: 'PERMISSION_POOL',
            }, {
                name: 'Pool Group',
                permission: 'PERMISSION_POOLGROUP',
            }, {
                name: 'HTTP Policy Set',
                permission: 'PERMISSION_HTTPPOLICYSET',
            }, {
                name: 'Network Security Policy',
                permission: 'PERMISSION_NETWORKSECURITYPOLICY',
            }, {
                name: 'AutoScale',
                permission: 'PERMISSION_AUTOSCALE',
            }, {
                name: 'DNS Policy',
                permission: 'PERMISSION_DNSPOLICY',
            },
        ],
    }, {
        name: 'Profiles',
        permission: 'PROFILE',
        children: [
            {
                name: 'TCP/UDP Profile',
                permission: 'PERMISSION_NETWORKPROFILE',
            }, {
                name: 'Application Profile',
                permission: 'PERMISSION_APPLICATIONPROFILE',
            }, {
                name: 'Persistence Profile',
                permission: 'PERMISSION_APPLICATIONPERSISTENCEPROFILE',
            }, {
                name: 'Health Monitor',
                permission: 'PERMISSION_HEALTHMONITOR',
            }, {
                name: 'Analytics Profile',
                permission: 'PERMISSION_ANALYTICSPROFILE',
            }, {
                name: 'IPAM/DNS Profile',
                permission: 'PERMISSION_IPAMDNSPROVIDERPROFILE',
            }, {
                name: 'Custom IPAM/DNS Profile',
                permission: 'PERMISSION_CUSTOMIPAMDNSPROFILE',
            }, {
                name: 'Traffic Clone',
                permission: 'PERMISSION_TRAFFICCLONEPROFILE',
            },
        ],
    }, {
        name: 'Group & Script',
        permission: 'GROUPS',
        children: [
            {
                name: ' IP Address Group',
                permission: 'PERMISSION_IPADDRGROUP',
            }, {
                name: 'String Group',
                permission: 'PERMISSION_STRINGGROUP',
            }, {
                name: 'DataScripts',
                permission: 'PERMISSION_VSDATASCRIPTSET',
            }, {
                name: 'MicroService Group',
                permission: 'PERMISSION_MICROSERVICEGROUP',
            }, {
                name: 'ProtocolParserScripts',
                permission: 'PERMISSION_PROTOCOLPARSER',
            },
        ],
    }, {
        name: 'Security',
        permission: 'SECURITY',
        children: [
            {
                name: 'SSL/TLS Profile',
                permission: 'PERMISSION_SSLPROFILE',
            }, {
                name: 'Authentication Profile',
                permission: 'PERMISSION_AUTHPROFILE',
            }, {
                name: 'PingAccess Agent',
                permission: 'PERMISSION_PINGACCESSAGENT',
            }, {
                name: 'PKI Profile',
                permission: 'PERMISSION_PKIPROFILE',
            }, {
                name: 'SSL/TLS Certificates',
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            }, {
                name: 'Certificate Management Profile',
                permission: 'PERMISSION_CERTIFICATEMANAGEMENTPROFILE',
            }, {
                name: 'Hardware Security Module Group',
                permission: 'PERMISSION_HARDWARESECURITYMODULEGROUP',
            }, {
                name: 'SSO Policy',
                permission: 'PERMISSION_SSOPOLICY',
            },
        ],
    }, {
        name: 'WAF',
        permission: 'WAF',
        children: [
            {
                name: 'WAF Profile',
                permission: 'PERMISSION_WAFPROFILE',
            }, {
                name: 'WAF Policy',
                permission: 'PERMISSION_WAFPOLICY',
            }, {
                name: 'Positive Security',
                permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            },
        ],
    }, {
        name: 'Error Page',
        permission: 'ERRORPAGE',
        children: [
            {
                name: 'Error Page Profile',
                permission: 'PERMISSION_ERRORPAGEPROFILE',
            }, {
                name: 'Error Page Body',
                permission: 'PERMISSION_ERRORPAGEBODY',
            },
        ],
    }, {
        name: 'Operations',
        permission: 'OPERATIONS',
        children: [
            {
                name: 'Alert Config',
                permission: 'PERMISSION_ALERTCONFIG',
            }, {
                name: 'Alert',
                permission: 'PERMISSION_ALERT',
            }, {
                name: 'Alert Action',
                permission: 'PERMISSION_ACTIONGROUPCONFIG',
            }, {
                name: 'Syslog',
                permission: 'PERMISSION_ALERTSYSLOGCONFIG',
            }, {
                name: 'Email',
                permission: 'PERMISSION_ALERTEMAILCONFIG',
            }, {
                name: 'SNMP Traps',
                permission: 'PERMISSION_SNMPTRAPPROFILE',
            }, {
                name: 'Traffic Capture',
                permission: 'PERMISSION_TRAFFIC_CAPTURE',
            },
        ],
    }, {
        name: 'Infrastructure',
        permission: 'INFRASTRUCTURE',
        children: [
            {
                name: 'Cloud',
                permission: 'PERMISSION_CLOUD',
            }, {
                name: 'Service Engine',
                permission: 'PERMISSION_SERVICEENGINE',
            }, {
                name: 'Service Engine Group',
                permission: 'PERMISSION_SERVICEENGINEGROUP',
            }, {
                name: 'Network',
                permission: 'PERMISSION_NETWORK',
            }, {
                name: 'VRF Context',
                permission: 'PERMISSION_VRFCONTEXT',
            }, {
                name: 'User Credentials',
                permission: 'PERMISSION_USER_CREDENTIAL',
            },
        ],
    }, {
        name: 'Administration',
        permission: 'ADMINISTRATION',
        children: [
            {
                name: 'System Settings',
                permission: 'PERMISSION_SYSTEMCONFIGURATION',
            }, {
                name: 'Controller',
                permission: 'PERMISSION_CONTROLLER',
            }, {
                name: 'Reboot',
                permission: 'PERMISSION_REBOOT',
            }, {
                name: 'Upgrade',
                permission: 'PERMISSION_UPGRADE',
            }, {
                name: 'Troubleshooting',
                permission: 'PERMISSION_TECHSUPPORT',
            }, {
                name: 'Internal',
                permission: 'PERMISSION_INTERNAL',
            }, {
                name: 'Controller Site',
                permission: 'PERMISSION_CONTROLLERSITE',
            }, {
                name: 'Software',
                permission: 'PERMISSION_IMAGE',
            },
        ],
    }, {
        name: 'Accounts',
        permission: 'ACCOUNT',
        children: [
            {
                name: 'Users',
                permission: 'PERMISSION_USER',
            }, {
                name: 'Roles',
                permission: 'PERMISSION_ROLE',
            }, {
                name: 'Tenant',
                permission: 'PERMISSION_TENANT',
            },
        ],
    }, {
        name: 'GSLB',
        permission: 'GSLB',
        children: [
            {
                name: 'GSLB Configuration',
                permission: 'PERMISSION_GSLB',
            }, {
                name: 'GSLB Services',
                permission: 'PERMISSION_GSLBSERVICE',
            }, {
                name: 'GSLB Geo Profile',
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        ],
    },
];

/**
 * Maps permission name to it's type in {@link roleCategories}.
 * @private {Object<string, RoleNode>}
 */
const permissionNodeMap = {};

/**
 * Role service. Handles most interactions between, Role, RoleCollection and RoleController.
 * @constructor
 */
function RoleService() {

}

/**
 * @const {string}
 */
RoleService.prototype.NO_ACCESS = 'NO_ACCESS';

/**
 * @const {string}
 */
RoleService.prototype.READ_ACCESS = 'READ_ACCESS';

/**
 * @const {string}
 */
RoleService.prototype.WRITE_ACCESS = 'WRITE_ACCESS';

/**
 * Returns roles category structure.
 * @returns {Array<BasicRoleNode>}
 */
RoleService.prototype.getRoleCategories = function() {
    return roleCategories;
};

/**
 * Returns current permission node map which can be modified by multiple services.
 * @returns {Object<string, string>}
 */
RoleService.prototype.getCurrentPermissionNodeMap = function() {
    return permissionNodeMap;
};

/**
 * Processes {@link BasicRoleNode} array and returns array containing {@link RoleNode} items.
 * Modifies {@link permissionNodeMap} by mapping privilege type to created permission node.
 * @param {Array<BasicRoleNode>} source - Source array with role structure similar to
 *     {@link roleCategories}.
 * @param {Object<string, string>} privilegeTypeMap - Resource-type privilege hash map.
 * @param {RoleNode} [parentNode] - Optional parent node.
 * @return {Array<RoleNode>} Data for Roles UI table.
 */
RoleService.prototype.processRoles = function(source, privilegeTypeMap, parentNode) {
    const target = [];

    for (let i = 0; i < source.length; i++) {
        const category = source[i];
        const privilegeNode = {
            name: category.name,
            permission: category.permission,
            type: privilegeTypeMap[category.permission] || this.NO_ACCESS,
            assorted: false,
            children: [],
            parentNode,
            canWrite: true,
            canRead: true,
            hint: '',
        };

        permissionNodeMap[category.permission] = privilegeNode;
        target.push(privilegeNode);

        if (category.children && category.children.length) {
            privilegeNode.children = this.processRoles(
                category.children, privilegeTypeMap, privilegeNode,
            );
            this.setRoleNodeType(privilegeNode);
        }
    }

    return target;
};

/**
 * Sets specified RoleNode's type based on its children properties.
 * @param {RoleNode} roleNode
 */
RoleService.prototype.setRoleNodeType = function(roleNode) {
    if (roleNode && roleNode.children || !roleNode.children.length) {
        let allSelected = true;
        let hasRead = false;
        let hasWrite = false;
        let hasNoAccess = false;
        let [{ type }] = roleNode.children;

        for (let i = 0; i < roleNode.children.length; i++) {
            const child = roleNode.children[i];

            if (!child.type || child.type !== type) {
                allSelected = false;
            }

            if (!hasNoAccess && child.type === this.NO_ACCESS) {
                hasNoAccess = true;
            }

            if (!hasRead && child.type === this.READ_ACCESS) {
                hasRead = true;
            }

            if (!hasWrite && child.type === this.WRITE_ACCESS) {
                hasWrite = true;
            }

            type = child.type;
        }

        roleNode.assorted = this.isAssorted(hasRead, hasWrite, hasNoAccess);

        if (allSelected) {
            roleNode.type = type;
        } else {
            roleNode.type = '';
        }
    }
};

/**
 * Checks if passed arguments are assorted i.e. more than 1 is of the same value.
 * @param {boolean} hasRead
 * @param {boolean} hasWrite
 * @param {boolean} hasNoAccess
 * @returns {boolean} True if arguments are assorted.
 */
RoleService.prototype.isAssorted = function(hasRead, hasWrite, hasNoAccess) {
    return hasRead && hasWrite || hasRead && hasNoAccess || hasWrite && hasNoAccess;
};

/**
 * Maps privilege resource to its type.
 * @param {Array<{permission: string, type: string}>} data
 * @returns {Object<string, string>}
 */
RoleService.prototype.createPrivilegeHashMap = function(data) {
    const hashMap = {};

    if (!data || !data.length) {
        return hashMap;
    }

    for (let i = 0; i < data.length; i++) {
        const item = data[i];

        hashMap[item.resource] = item.type;
    }

    return hashMap;
};

/**
 * Converts role nodes back to server defined privilege objects.
 * @param {Array<RoleNode>} roles
 * @returns {Object.<string, Object>}
 */
RoleService.prototype.flattenRoles = function(roles) {
    const privileges = {};

    for (let i = 0; i < roles.length; i++) {
        const parent = roles[i];
        const { children } = parent;

        children.forEach(child => {
            const {
                permission: resource,
                type,
            } = child;

            if (resource && type) {
                privileges[resource] = {
                    resource,
                    type,
                };
            }
        });
    }

    return privileges;
};

/**
 * Returns roles row transform function for Roles table assuming each parent node in
 * {@link roleCategories} corresponds to each Role table column.
 * @param {number} columnIndex - Column index in Roles table. Matches each parent node in
 *     {@link roleCategories}.
 * @returns {Function} Row transform function.
 */
RoleService.prototype.getRowTransformFunctionBasedOnColumnIndex = function(columnIndex) {
    const self = this;

    return function(row) {
        const privileges = row.getPrivileges();
        const { children } = privileges[columnIndex];

        if (!children) {
            return '';
        }

        let hasRead = false;
        let hasWrite = false;
        let hasNoAccess = false;

        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            if (!hasRead && child.type === self.READ_ACCESS) {
                hasRead = true;
            }

            if (!hasWrite && child.type === self.WRITE_ACCESS) {
                hasWrite = true;
            }

            if (!hasNoAccess && child.type === self.NO_ACCESS) {
                hasNoAccess = true;
            }

            if (hasRead && hasWrite && hasNoAccess) {
                break;
            }
        }

        if (self.isAssorted(hasRead, hasWrite, hasNoAccess)) {
            return 'Assorted';
        } else if (hasNoAccess) {
            return 'No Access';
        } else if (hasRead) {
            return 'Read';
        } else if (hasWrite) {
            return 'Write';
        }

        return '';
    };
};
