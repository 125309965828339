/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * API data transformed through transformer.
 * @typedef {Object}
 * @name UpgradeNodeListTransformedData
 * @memberOf module:avi/upgrade
 * @property {number} seGroupCount - number of seGroups in progress
 * @property {module:avi/upgrade.UpgradeStatusNode} controllerNode -
 *     controller node as returned by api
 */

const
    NODE_CONTROLLER_CLUSTER = 'NODE_CONTROLLER_CLUSTER',
    NODE_SE_GROUP = 'NODE_SE_GROUP',
    NODE_SE_TYPE = 'NODE_SE_TYPE';

export const upgradeNodeTypes = {
    NODE_CONTROLLER_CLUSTER,
    NODE_SE_GROUP,
    NODE_SE_TYPE,
};

function upgradeNodeListFactory(UpdatableBase) {
    /**
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.UpdatableBase
     * @description UpdateCard status's service to retrieve in-progress data.
     * @author Akul Aggarwal
     */
    class UpgradeNodeList extends UpdatableBase {
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'UpgradeNodeListDataSource',
                    transformer: 'UpgradeNodeListDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            const extendedArgs = {
                ...args,
                params: {
                    state: 'UPGRADE_IN_PROGRESS_STATE',
                    //node_type: '', //TODO: enable once API supports multi-node filtering
                },
                defaultDataFields: ['config'],
                isStatic: false,
                allDataSources,
            };

            super(extendedArgs);

            this.objectName_ = 'upgradestatusinfo';

            /**
             * Controller node, of returned.
             * @type {module:avi/upgrade.UpgradeStatusNode|null}
             */
            this.controllerNode_ = null;

            /**
             * Number of seGroups returned.
             * @type {number}
             */
            this.seGroupCount_ = 0;
        }

        /**
         * Returns controller node.
         * @return {module:avi/upgrade.UpgradeStatusNode|null}
         */
        getControllerNode() {
            return this.controllerNode_ || null;
        }

        /**
         * Calculates and returns percentage controller update completed.
         * @return {number}
         * TODO: Enable once API supports
         */
        getControllerUpgradePercentage() {
            // const { tasks_completed: completed, total_tasks: total } = this.controllerNode_;
            // let percentage = 100;
            //
            // if (total) {
            //     percentage = completed / total * 100;
            // }

            // return percentage;
        }

        /**
         * Fetches number of seGroups in-progress state.
         * @return {number}
         */
        getSeGroupCount() {
            return this.seGroupCount_;
        }

        /**
         * Sets internal instance props from transformed API data.
         * @param {module:avi/upgrade.UpgradeNodeListTransformedData} data
         */
        setUpgradeNodeListData(data) {
            const { controllerNode, seGroupCount } = data;

            this.controllerNode_ = controllerNode;
            this.seGroupCount_ = seGroupCount;
        }
    }

    return UpgradeNodeList;
}

upgradeNodeListFactory.$inject = [
    'UpdatableBase',
];

angular.module('avi/upgrade').factory('UpgradeNodeList', upgradeNodeListFactory);
