/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name CloudCollection
 */
angular.module('aviApp').factory('CloudCollection', [
'Collection', 'Cloud', function(Collection, Cloud) {
    class CloudCollection extends Collection {}

    const serverDefaultsOverride = {
        rancher_configuration: undefined,
        docker_configuration: undefined,
    };

    angular.extend(CloudCollection.prototype, {
        objectName_: 'cloud-inventory',
        isStatic_: false,
        itemClass_: Cloud,
        windowElement_: 'infra-cloud-create',
        defaultDataSources_: 'inventory',
        serverDefaultsOverride_: serverDefaultsOverride,
        allDataSources_: {
            inventory: {
                source: 'InventoryCollDataSource',
                transformer: 'InventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config', 'status'],
            },
        },
    });

    return CloudCollection;
}]);
