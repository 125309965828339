/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name InventoryMap
 * @description
 *
 *     Besides VS config this Item keeps two nested arrays of Pools and ServiceEngines and
 *     has a special method to efficiently implement data updates coming from API.
 *
 */
angular.module('aviApp').factory('InventoryMap',
    ['UpdatableItem', 'VirtualService', 'InventoryMapPool', 'ServiceEngine',
function(UpdatableItem, VirtualService, InventoryMapPool, ServiceEngine) {
    /**
     * List of data properties considered to be about Item itself, not nested objects.
     * @type {string[]}
     * @inner
     */
    const ownItemProperties = [
        'config',
        'runtime',
        'health_score',
        'alert',
        'serversNum',
        'poolsNum',
        'faults',
    ];

    function InventoryMap(oArgs) {
        InventoryMap.superconstructor.call(this, oArgs);

        this.vs = new VirtualService({ data: _.pick(oArgs.data, ownItemProperties) });

        this.pools = {};

        this.ses = {};

        this.updateItemData(oArgs.data);
    }

    avi.inherit(InventoryMap, UpdatableItem);

    InventoryMap.prototype.objectName = 'inventory-map';
    InventoryMap.prototype.windowElement = null;

    /** @override */
    InventoryMap.prototype.isEditable = function() { return false; };

    /**
     * When data not passed we use Item#id or Item#vs#id properties. When passed we need to pass
     * data argument to getIdFromData of parent class so that it will get id from slug of VS URL.
     * @param {Item#data} data
     * @returns {string}
     * @override
     */
    InventoryMap.prototype.getIdFromData = function(data) {
        let res;

        if (!data && this.id) {
            res = this.id;
        } else if (!data) {
            res = this.vs.getIdFromData();
        } else {
            res = this.getIdFromData_(data);
        }

        return res;
    };

    /**
     * @param {Object} vsData - Item#data with arrays of pools and serviceengines.
     * @override
     */
    InventoryMap.prototype.updateItemData = function(vsData) {
        const
            updatedPoolsHash = {},
            updatedSesHash = {};

        _.each(vsData.pools, function(poolData) {
            const id = poolData.config.uuid;

            updatedPoolsHash[id] = true;

            if (!(id in this.pools)) { //add new pools
                this.pools[id] = new InventoryMapPool(poolData);
            } else if (this.itemDataDiff_(this.pools[id].data, poolData) ||
                !angular.equals(this.pools[id].networksArr, poolData.networks) ||
                !angular.equals(this.pools[id].serversArr, poolData.servers)) {
                this.pools[id].updateItemData(poolData);
            }
        }, this);

        //remove pools we don't have in response anymore
        _.each(this.pools, function(pool, id, hash) {
            if (!(id in updatedPoolsHash)) {
                pool.destroy();
                delete hash[id];
            }
        });

        if (Array.isArray(vsData.poolgroup_refs)) {
            vsData.poolgroup_refs.forEach(poolgroup => {
                poolgroup.members.forEach(member => {
                    member.pool = this.pools[member.pool_ref.slug()];
                });
            });

            this.poolgroups = vsData.poolgroup_refs;
        }

        _.each(vsData.serviceengines, function(seData) {
            const id = seData.config.uuid;

            updatedSesHash[id] = true;

            if (!(id in this.ses)) {
                this.ses[id] = new ServiceEngine({ data: seData });
            } else if (this.itemDataDiff_(this.ses[id].data, seData)) {
                this.ses[id].updateItemData(seData);
            }
        }, this);

        _.each(this.ses, function(se, id, hash) {
            if (!(id in updatedSesHash)) {
                se.destroy();
                delete hash[id];
            }
        });

        if (this.itemDataDiff_(this.vs.data, vsData)) {
            this.vs.updateItemData(_.pick(vsData, ownItemProperties));
        }
    };

    /**
     * Compares two Item data objects to figure out whether they are same. Omits non owned
     * properties.
     * @param {Item#data} oldData
     * @param {Item#data} newData
     * @returns {boolean} - true if equal, false otherwise
     */
    InventoryMap.prototype.itemDataDiff_ = function(oldData, newData) {
        return _.any(ownItemProperties, function(key) {
            return !angular.equals(oldData[key], newData[key]);
        });
    };

    /** @override */
    InventoryMap.prototype.destroy = function() {
        _.each(this.pools, function(pool) {
            pool.destroy();
        });

        _.each(this.ses, function(se) {
            se.destroy();
        });

        this.vs.destroy();

        InventoryMap.superclass.destroy.call(this);
    };

    return InventoryMap;
}]);
