/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function licenseUsageDataTransformerFactory(
    schemaService,
    RevisedDataTransformer,
    licenseSummaryService,
) {
    const cumulativeLicenseProps = {
        max_ses: {
            usedKey: 'num_ses',
            totalKey: 'licensed_ses',
            label: 'Hosts',
        },
        cores: {
            usedKey: 'num_se_vcpus',
            totalKey: 'licensed_cores',
            label: 'Service Engine vCPUs',
        },
        sockets: {
            usedKey: 'num_sockets',
            totalKey: 'licensed_sockets',
            label: 'Service Engine Sockets',
        },
    };

    /**
     * @alias module:services/LicenseUsageDataTransformer
     * @extends RevisedDataTransformer
     */
    class LicenseUsageDataTransformer extends RevisedDataTransformer {
        /**
         * Makes @LicenseHashMap, @LicenseType[], and @LicenseTierType[].
         * Above three allow user flexibility to manipulate license summary data.
         * @param {Object} tierHash - license usage data returned from API
         * @protected
         */
        static getUsageHashMap_(tierHash) {
            /**
             * License tier type.
             * i.e. 'ENTERPRISE_16' or 'ENTERPRISE_18'
             * @typedef {string} LicenseTierType
             */

            /**
             * @type {LicenseTierType[]}
             */
            const tierTypes = Object.keys(tierHash);

            /**
             * Holds information for each licenseType and licenseTier paired together,
             * to make a 2-D array for easy lookup and organisation by type or tier.
             * i.e. keys are 'ENTERPRISE_16-SE_BANDWIDTH_25M: ' or 'ENTERPRISE_18-cores'
             * @typedef {Object<string, LicenseHashVal>} LicenseHashMap
             */

            /**
             * @type {LicenseHashMap}
             */
            const licenseHashMap = {};

            const
                { getLicenseHashKey } = licenseSummaryService,
                { ENTERPRISE_16: enterprise16, ENTERPRISE_18: enterprise18 } = tierHash,
                { licensed_se_bandwidth_limits: licensedSeLimits16 } = enterprise16,
                { licensed_se_bandwidth_limits: licensedSeLimits18 } = enterprise18,
                seBandwidthLicenseEnums = schemaService.getEnumValues('SEBandwidthType');

            const seLimitKeys = _.union(
                Object.keys(licensedSeLimits16),
                Object.keys(licensedSeLimits18),
            );

            /**
             * license types
             * @typedef {string} LicenseType
             * i.e. 'cores' or 'SE_BANDWIDTH_25M'
             */

            /**
             * @type {LicenseType[]}
             */
            const allLimitKeys = _.union(seLimitKeys, Object.keys(cumulativeLicenseProps));

            tierTypes.forEach(tierKey => {
                /**
                 * Assigns key and val for SE_BANDWIDTH_XXXM licenses hash.
                 * @param {number} value
                 * @param {string} label
                 * @param {string} description
                 * @inner
                 */
                function addSeLicense({ value, label, description }) {
                    const hashKey = getLicenseHashKey(tierKey, value);

                    /**
                     * @typedef {Object} LicenseHashVal
                     * @property {number} used
                     * @property {number} total
                     * @property {string} label
                     * @property {string} description
                     */

                    /**
                     * @type {LicenseHashVal}
                     */
                    const hashVal = {
                        used: tierHash[tierKey][value],
                        total: tierHash[tierKey].licensed_se_bandwidth_limits[value],
                        label,
                        description,
                    };

                    licenseHashMap[hashKey] = hashVal;
                }

                seBandwidthLicenseEnums.forEach(addSeLicense);

                //For remaining (cumulative) licenses - i.e. hosts, vCPUs, Sockets
                _.each(cumulativeLicenseProps, ({ usedKey, totalKey, label }, key) => {
                    licenseHashMap[getLicenseHashKey(tierKey, key)] =
                        LicenseUsageDataTransformer.getLicenseHashVal_(
                            tierHash[tierKey][usedKey],
                            tierHash[tierKey][totalKey],
                            label,
                            key,
                        );
                });
            });

            return {
                licenseHashMap,
                limitKeys: allLimitKeys,
                tierTypes,
            };
        }

        /**
         * Returns values needed for @LicenseHashMap.
         * @param {number} used - number of used instances
         * @param {number} total - number of total instances
         * @param {string} label
         * @param {string} fieldName - fieldName for object in schema.pb
         * @returns {LicenseHashVal}
         * @protected
         */
        static getLicenseHashVal_(used, total, label, fieldName) {
            return {
                used,
                total,
                label,
                description: schemaService.getFieldDescription('CumulativeLicense', fieldName),
            };
        }

        /**
         * @param {Object} rsp
         * @returns {{data: {
         *     hashMap: LicenseHashMap,
         *     limitKeys: LicenseType[],
         *     tierTypes: LicenseTierType[]
           }}}
         * @override
         */
        processResponse(rsp) {
            return { data: LicenseUsageDataTransformer.getUsageHashMap_(rsp.data.pertier) };
        }
    }

    return LicenseUsageDataTransformer;
}

licenseUsageDataTransformerFactory.$inject = [
    'schemaService',
    'RevisedDataTransformer',
    'licenseSummaryService',
];

/**
 * @ngdoc service
 * @name LicenseUsageDataTransformer
 * @module services/LicenseUsageDataTransformer
 * @author Akul Aggarwal
 * @description
 *
 *     Process response for license usage summary table. Makes hash table
 *     for 2-dimensional license lookup, along with tierType and licenseName lists.
 *
 */
angular.module('aviApp').factory('LicenseUsageDataTransformer', licenseUsageDataTransformerFactory);
