/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name vrfContextList
 * @description Component for the VRF Context page.
 */
class VrfContextListController {
    constructor(CRUDGridConfig, infraCloudState) {
        this._infraCloudState = infraCloudState;

        const gridFields = [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }];

        this.gridConfig = new CRUDGridConfig({
            fields: gridFields,
        });

        this.handleVrfCollectionChange = this.handleVrfCollectionChange.bind(this);

        this._infraCloudState.on(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }

    $onInit() {
        this.gridConfig.collection =
                this._infraCloudState.getVrfContextCollection();
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Resets the gridConfig collection.
     */
    handleVrfCollectionChange() {
        this.gridConfig.collection = this._infraCloudState.getVrfContextCollection();
    }

    $onDestroy() {
        const { _infraCloudState: infraCloudState } = this;

        infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }
}

VrfContextListController.$inject = [
    'CRUDGridConfig',
    'infraCloudState',
];

angular.module('aviApp').component('vrfContextList', {
    controller: VrfContextListController,
    template:
        `<div class="infrastructure-routing">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-segroup-list segroup">
            </collection-grid>
        </div>`,
});
