/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * This collection is for making a collection call when the response is an array of objects.
 * propertyToDisplay is the key in the object that should be used for the values/options in
 * CollectionDropdown. Apic epgs and apicgraphinstances only.
 */
angular.module('aviApp').factory('ArrayOfObjectsCollection', ['Collection',
function(Collection) {
    function ArrayOfObjectsCollection(oArgs) {
        if (!oArgs.propertyToDisplay) {
            throw new Error('Can\'t create ArrayOfObjectsCollection without `propertyToDisplay`');
        }

        ArrayOfObjectsCollection.superconstructor.call(this, oArgs);

        this.propertyToDisplay = oArgs.propertyToDisplay;
    }

    avi.inherit(ArrayOfObjectsCollection, Collection);

    ArrayOfObjectsCollection.prototype.allDataSources_ = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ArrayOfObjectsListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    return ArrayOfObjectsCollection;
}]);
