/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * For openStack and cloudStack we have to filter out the 'default' value.
 * Internal structure of enum is simplified for the further UI usage.
 * @return {array<object>} - Available Hypervisor types as {id: & label:}.
 */
angular.module('aviApp').factory('stackHyperVisorTypesFactory', ['Schema', function(Schema) {
    const arr = [];

    _.each(Schema.enums.Hypervisor.values, function(val, key) {
        if (key !== 'DEFAULT') {
            arr.push({
                id: key,
                label: val.options.e_description.value,
            });
        }
    });

    return arr.sort((a, b) => {
        if (a.label > b.label) {
            return 1;
        } else if (a.label < b.label) {
            return -1;
        } else {
            return 0;
        }
    });
}]);
