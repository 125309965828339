/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Action enums from the protobuf.
 */
export const WAF_ACTION = 'WafAction';
export const WAF_POLICY_WHITELIST_ACTION = 'WafPolicyWhitelistAction';

/**
 * @alias waf/wafLogsService
 * @private
 */
class WafLogsService {
    constructor(schemaService) {
        const actionEnums = [WAF_ACTION, WAF_POLICY_WHITELIST_ACTION];

        /**
         * @type {Object<string, Object<string, string>>}
         */
        this.valueLabelsHashes_ = actionEnums.reduce((acc, actionEnum) => {
            acc[actionEnum] = schemaService.getEnumValueLabelsHash(actionEnum);

            return acc;
        }, {});
    }

    /**
     * Returns text to display based on an enum group and action enum.
     * @param {string} actionEnum - Group of action enums.
     * @param {string} value - Action enum.
     * @returns {string} Text to display from the enum.
     */
    getActionLabel_(actionEnum, value) {
        if (!(actionEnum in this.valueLabelsHashes_)) {
            throw new Error(`Enum ${actionEnum} does not exist in this service.`);
        }

        return this.valueLabelsHashes_[actionEnum][value];
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @param {string} actionEnum - WAF action enum.
     * @param {string[]} [actions=[]] - List of action enum values.
     * @returns {string} List of text to display from the enums.
     */
    getActionLabels(actionEnum, actions = []) {
        return actions.map(action => this.getActionLabel_(actionEnum, action)).join(', ');
    }
}

WafLogsService.$inject = [
    'schemaService',
];

/**
 * @ngdoc service
 * @name wafLogsService
 * @author alextsg
 * @module waf/wafLogsService
 * @desc Stateless service for WAF logs. Used to create lookup hash of labels for WAF actions.
 */
angular.module('waf.vantage.avi').service('wafLogsService', WafLogsService);
