/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @typedef {Object} TechSupportApiConfig
 * @description config object returned by tech support status api
 * @property {TechSupportApiStatus} status
 * @property {string} modified
 * @property {string} size - in bytes
 * @property {string} url - to download or find file
 * @private
 */

function techSupportCollectionFactory(FileServiceCollection, TechSupport) {
    /**
     * @alias module:services/TechSupportCollection
     * @extends FileServiceCollection
     * @private
     */
    class TechSupportCollection extends FileServiceCollection {
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'ListCollDataSource',
                    transformer: 'TechSupportDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            const extendedArgs = Object.assign(
                {
                    isStatic: true,
                    allDataSources,
                    params: {
                        uri: 'controller://tech_support',
                    },
                },
                args,
            );

            FileServiceCollection.prototype.itemClass_ = TechSupport;

            super(extendedArgs);
        }
    }

    return TechSupportCollection;
}

techSupportCollectionFactory.$inject = [
    'FileServiceCollection',
    'TechSupport',
];

/**
 * @ngdoc services
 * @name TechSupportCollection
 * @module services/TechSupportCollection
 * @author Akul Aggarwal
 * @description
 *
 *     TechSupportCollection extends @FileServiceCollection, uses Tech Support item.
 *
 */
angular.module('aviApp').factory('TechSupportCollection', techSupportCollectionFactory);
