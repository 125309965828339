/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ClusterNodeDataTransformer', ['RevisedDataTransformer',
function(RevisedDataTransformer) {
    function ClusterNodeDataTransformer(args) {
        ClusterNodeDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(ClusterNodeDataTransformer, RevisedDataTransformer);

    ClusterNodeDataTransformer.prototype.processResponse = function(resp) {
        let results = [],
            clusterIp;

        if (resp.data && typeof resp.data === 'object') {
            if ('nodes' in resp.data) {
                clusterIp = resp.data.virtual_ip && resp.data.virtual_ip.addr;

                results = _.map(resp.data['nodes'], function(nodeConfigData) {
                    return {
                        config: {
                            name: nodeConfigData.name,
                            ip: nodeConfigData.ip.addr,
                            cluster_ip: clusterIp,
                        },
                    };
                });
            } else if ('node_states' in resp.data) {
                results = _.map(resp.data['node_states'], function(nodeRuntimeData) {
                    return {
                        config: {
                            name: nodeRuntimeData.name,
                        },
                        runtime: {
                            role: nodeRuntimeData.role,
                            state: nodeRuntimeData.state,
                        },
                    };
                });
            }

            resp.data.results = results;
            resp.data.count = results.length;
        }

        return resp;
    };

    return ClusterNodeDataTransformer;
}]);
