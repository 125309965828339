/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const methodMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/MethodMatchConfigItem
     * @private
     */
    class MethodMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'MethodMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchOperationLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchOperation');
            this.methodLabelsHash_ = schemaService.getEnumValueLabelsHash('HTTPMethod');
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                match_criteria: 'IS_IN',
            };
        }

        /**
         * Returns the label to display for this.config.methods.
         * @returns {string}
         */
        get methodLabels() {
            const { methods = [] } = this.config;

            return methods.map(method => this.methodLabelsHash_[method]).join(', ');
        }

        /**
         * Returns the label to display for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.matchOperationLabelsHash_[matchCriteria];
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCriteriaLabel} ${this.methodLabels}`;
        }
    }

    return MethodMatchConfigItem;
};

methodMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  MethodMatchConfigItem
 * @description  MethodMatch ConfigItem class.
 * @module config-item/MethodMatchConfigItem
 * @author alextsg
 */
angular
    .module('policies.vantage.avi')
    .factory('MethodMatchConfigItem', methodMatchConfigItemFactory);
