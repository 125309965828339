/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name httpInterceptor
 * @description
 *
 *     We want to escape some URL query part characters as well as handle some 4** and 5**
 *     respons codes.
 */
angular.module('aviApp').factory('httpInterceptor', [
'$q', '$rootScope', '$injector', '$state', 'encodeURIQueryPartService',
'responseErrorInterceptorExceptions',
function($q, $rootScope, $injector, $state, encodeURIQueryPartService, exceptions) {
    const respCode401allowed = {
        login: true,
        'please-reload': true,
        passwordchange: true,
    };

    const request = config => {
        config.url = encodeURIQueryPartService(config.url);

        return config;
    };

    const responseError = rsp => {
        const
            Auth = $injector.get('Auth'),
            { config: rspConfig } = rsp,
            { url } = rspConfig,
            { status } = rsp,
            skip = url in exceptions && _.contains(exceptions[url], status);

        if (!skip) {
            switch (status) {
                case 401: {
                    rsp.silent = true;

                    const { name: stateName } = $state.$current;

                    if (!(stateName in respCode401allowed)) {
                        Auth.resetLogout();
                    }

                    break;
                }

                // Permission change HTTP status
                case 419: {
                    const
                        myAccount = $injector.get('myAccount'),
                        $http = $injector.get('$http');

                    myAccount.load();

                    return Auth.setAppContext()
                        .then(() => $http(rsp.config));
                }

                case 503:
                case 520:
                    if (Auth.isLoggedIn()) {
                        Auth.logout();
                    }

                    $state.go(status.toString());
                    break;
            }
        }

        return $q.reject(rsp);
    };

    return {
        request,
        responseError,
    };
}]);
