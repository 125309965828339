/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Enums for AppLearningConfidenceLabel.
 */
const CONFIDENCE_LOW = 'CONFIDENCE_LOW';
const CONFIDENCE_PROBABLE = 'CONFIDENCE_PROBABLE';
const CONFIDENCE_HIGH = 'CONFIDENCE_HIGH';
const CONFIDENCE_VERY_HIGH = 'CONFIDENCE_VERY_HIGH';

/**
 * Fields corresponding to AppLearningConfidenceLabel enums in WafProfile.
 */
const CONFIDENCE_LOW_FIELD = 'confid_low_value';
const CONFIDENCE_PROBABLE_FIELD = 'confid_probable_value';
const CONFIDENCE_HIGH_FIELD = 'confid_high_value';
const CONFIDENCE_VERY_HIGH_FIELD = 'confid_very_high_value';

/**
 * @alias waf/wafProfileModalService
 * @private
 */
class WafProfileModalService {
    constructor(schemaService, WafProfile, defaultValues) {
        this.schemaService_ = schemaService;
        this.WafProfile_ = WafProfile;
        this.defaultValues_ = defaultValues;

        this.confidenceEnums_ = [
            CONFIDENCE_LOW,
            CONFIDENCE_PROBABLE,
            CONFIDENCE_HIGH,
            CONFIDENCE_VERY_HIGH,
        ];

        this.confidenceEnumsToLabelsHash_ = {
            [CONFIDENCE_LOW]: 'Low',
            [CONFIDENCE_PROBABLE]: 'Probable',
            [CONFIDENCE_HIGH]: 'High',
            [CONFIDENCE_VERY_HIGH]: 'Very High',
        };

        this.confidenceEnumsToFieldsHash_ = {
            [CONFIDENCE_LOW]: CONFIDENCE_LOW_FIELD,
            [CONFIDENCE_PROBABLE]: CONFIDENCE_PROBABLE_FIELD,
            [CONFIDENCE_HIGH]: CONFIDENCE_HIGH_FIELD,
            [CONFIDENCE_VERY_HIGH]: CONFIDENCE_VERY_HIGH_FIELD,
        };

        this.sliderInterval = Math.round(100 / (this.confidenceEnums_.length - 1));
    }

    /**
     * Returns a percentage value based on a number.
     * @param {number} value - Number to be used as a percentage.
     * @returns {number}
     */
    valueToPercentage(value) {
        return value / 100;
    }

    /**
     * Returns a hash of labels based on configured confidence percentages. The labels contain
     * either 'None' or something like 'Low (70-90%)', where the '(70-90%)' part is taken from
     * previously configured values in the 'confidence_override' object.
     * @param {Object<string,number>} overrideConfig
     * @returns {Object<string,string>}
     */
    getMinConfidenceLabelsHash(overrideConfig) {
        overrideConfig = !_.isEmpty(overrideConfig) ?
            overrideConfig :
            this.defaultValues_.getDefaultItemConfigByType('applearningconfidenceoverride');

        return this.confidenceEnums_.reduce((acc, enumValue, index) => {
            const label = this.confidenceEnumsToLabelsHash_[enumValue];
            const field = this.confidenceEnumsToFieldsHash_[enumValue];
            const nextEnum = this.confidenceEnums_[index + 1];
            const nextField = this.confidenceEnumsToFieldsHash_[nextEnum];
            const minPercentage = this.valueToPercentage(overrideConfig[field]);
            const maxPercentage = enumValue === CONFIDENCE_VERY_HIGH ?
                100 :
                this.valueToPercentage(overrideConfig[nextField]);

            acc[enumValue] = `${label} (${minPercentage}-${maxPercentage}%)`;

            return acc;
        }, {});
    }

    /**
     * Returns a hash of slider values to labels.
     * @returns {Object<string,string>}
     */
    getMinConfidenceSliderLabelHash() {
        return this.confidenceEnums_.reduce((acc, enumValue, index) => {
            acc[index * this.sliderInterval] = this.confidenceEnumsToLabelsHash_[enumValue];

            return acc;
        }, {});
    }

    /**
     * Returns the slider value based on a configured enum.
     * @param {string} enumValue
     * @returns {number}
     */
    getSliderValueByMinConfidenceEnum(enumValue) {
        const index = this.confidenceEnums_.indexOf(enumValue);

        return index * this.sliderInterval;
    }

    /**
     * Returns the 'min_confidence' enum value to set based on the slider value.
     * @param {number} sliderValue - Slider value.
     * @returns {string}
     */
    getMinConfidenceEnumBySliderValue(sliderValue) {
        const index = sliderValue / this.sliderInterval;

        return this.confidenceEnums_[index];
    }

    /**
     * Returns the range of allowed values for the 'sampling_percent' field.
     * @returns {string[]}
     */
    getLearningSamplingPercentRange() {
        return this.schemaService_.getFieldRangeAsTuple('AppLearningParams', 'sampling_percent');
    }
}

WafProfileModalService.$inject = [
    'schemaService',
    'WafProfile',
    'defaultValues',
];

/**
 * @ngdoc service
 * @name wafProfileModalService
 * @module waf/wafProfileModalService
 * @description Service for the WAF Profile modal.
 */
angular.module('waf.vantage.avi').service('wafProfileModalService', WafProfileModalService);
