/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

avi.QueryParser = (function() {
  /*
   * Generated by PEG.js 0.8.0.
   *
   * http://pegjs.majda.cz/
   */

  function peg$subclass(child, parent) {
    function ctor() { this.constructor = child; }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
  }

  function SyntaxError(message, expected, found, offset, line, column) {
    this.message  = message;
    this.expected = expected;
    this.found    = found;
    this.offset   = offset;
    this.line     = line;
    this.column   = column;

    this.name     = "SyntaxError";
  }

  peg$subclass(SyntaxError, Error);

  function parse(input) {
    var options = arguments.length > 1 ? arguments[1] : {},

        peg$FAILED = {},

        peg$startRuleIndices = { start: 0 },
        peg$startRuleIndex   = 0,

        peg$consts = [
          "\"",
          { type: "literal", value: "\"", description: "\"\\\"\"" },
          "'",
          { type: "literal", value: "'", description: "\"'\"" },
          [],
          peg$FAILED,
          /^[ ]/,
          { type: "class", value: "[ ]", description: "[ ]" },
          /^[a-zA-Z0-9]/,
          { type: "class", value: "[a-zA-Z0-9]", description: "[a-zA-Z0-9]" },
          /^[`~!@#$%\^&*()-_=+{[}\]|\\;:,<.>\/?]/,
          { type: "class", value: "[`~!@#$%\\^&*()-_=+{[}\\]|\\\\;:,<.>\\/?]", description: "[`~!@#$%\\^&*()-_=+{[}\\]|\\\\;:,<.>\\/?]" },
          " ",
          { type: "literal", value: " ", description: "\" \"" },
          function(chars) {
              return chars.join('');
            },
          /^[0-9]/,
          { type: "class", value: "[0-9]", description: "[0-9]" },
          null,
          ".",
          { type: "literal", value: ".", description: "\".\"" },
          function(integral, fractional) {
              return parseNumber(integral, fractional);
            },
          "false",
          { type: "literal", value: "false", description: "\"false\"" },
          "'false'",
          { type: "literal", value: "'false'", description: "\"'false'\"" },
          "\"false\"",
          { type: "literal", value: "\"false\"", description: "\"\\\"false\\\"\"" },
          "true",
          { type: "literal", value: "true", description: "\"true\"" },
          "'true'",
          { type: "literal", value: "'true'", description: "\"'true'\"" },
          "\"true\"",
          { type: "literal", value: "\"true\"", description: "\"\\\"true\\\"\"" },
          "0",
          { type: "literal", value: "0", description: "\"0\"" },
          "'0'",
          { type: "literal", value: "'0'", description: "\"'0'\"" },
          "\"0\"",
          { type: "literal", value: "\"0\"", description: "\"\\\"0\\\"\"" },
          "1",
          { type: "literal", value: "1", description: "\"1\"" },
          "'1'",
          { type: "literal", value: "'1'", description: "\"'1'\"" },
          "\"1\"",
          { type: "literal", value: "\"1\"", description: "\"\\\"1\\\"\"" },
          ",",
          { type: "literal", value: ",", description: "\",\"" },
          function(str) {
              return str;
          },
          /^[([]/,
          { type: "class", value: "[([]", description: "[([]" },
          /^[)\]]/,
          { type: "class", value: "[)\\]]", description: "[)\\]]" },
          function(first, second) {
              return [first].concat(second);
          },
          "obj_type",
          { type: "literal", value: "obj_type", description: "\"obj_type\"" },
          "event_id",
          { type: "literal", value: "event_id", description: "\"event_id\"" },
          "module",
          { type: "literal", value: "module", description: "\"module\"" },
          "obj_name",
          { type: "literal", value: "obj_name", description: "\"obj_name\"" },
          "reason_code",
          { type: "literal", value: "reason_code", description: "\"reason_code\"" },
          "client_src_port",
          { type: "literal", value: "client_src_port", description: "\"client_src_port\"" },
          "client_dest_port",
          { type: "literal", value: "client_dest_port", description: "\"client_dest_port\"" },
          "total_time",
          { type: "literal", value: "total_time", description: "\"total_time\"" },
          "rtt",
          { type: "literal", value: "rtt", description: "\"rtt\"" },
          "mss",
          { type: "literal", value: "mss", description: "\"mss\"" },
          "rx_bytes",
          { type: "literal", value: "rx_bytes", description: "\"rx_bytes\"" },
          "tx_bytes",
          { type: "literal", value: "tx_bytes", description: "\"tx_bytes\"" },
          "total_bytes",
          { type: "literal", value: "total_bytes", description: "\"total_bytes\"" },
          "rx_pkts",
          { type: "literal", value: "rx_pkts", description: "\"rx_pkts\"" },
          "tx_pkts",
          { type: "literal", value: "tx_pkts", description: "\"tx_pkts\"" },
          "total_pkts",
          { type: "literal", value: "total_pkts", description: "\"total_pkts\"" },
          "out_of_orders",
          { type: "literal", value: "out_of_orders", description: "\"out_of_orders\"" },
          "retransmits",
          { type: "literal", value: "retransmits", description: "\"retransmits\"" },
          "timeouts",
          { type: "literal", value: "timeouts", description: "\"timeouts\"" },
          "zero_window_size_events",
          { type: "literal", value: "zero_window_size_events", description: "\"zero_window_size_events\"" },
          "vcpu_id",
          { type: "literal", value: "vcpu_id", description: "\"vcpu_id\"" },
          "server_dest_port",
          { type: "literal", value: "server_dest_port", description: "\"server_dest_port\"" },
          "server_src_port",
          { type: "literal", value: "server_src_port", description: "\"server_src_port\"" },
          "server_rtt",
          { type: "literal", value: "server_rtt", description: "\"server_rtt\"" },
          "server_total_bytes",
          { type: "literal", value: "server_total_bytes", description: "\"server_total_bytes\"" },
          "server_rx_bytes",
          { type: "literal", value: "server_rx_bytes", description: "\"server_rx_bytes\"" },
          "server_tx_bytes",
          { type: "literal", value: "server_tx_bytes", description: "\"server_tx_bytes\"" },
          "server_total_pkts",
          { type: "literal", value: "server_total_pkts", description: "\"server_total_pkts\"" },
          "server_rx_pkts",
          { type: "literal", value: "server_rx_pkts", description: "\"server_rx_pkts\"" },
          "server_tx_pkts",
          { type: "literal", value: "server_tx_pkts", description: "\"server_tx_pkts\"" },
          "server_out_of_orders",
          { type: "literal", value: "server_out_of_orders", description: "\"server_out_of_orders\"" },
          "server_retransmits",
          { type: "literal", value: "server_retransmits", description: "\"server_retransmits\"" },
          "server_timeouts",
          { type: "literal", value: "server_timeouts", description: "\"server_timeouts\"" },
          "server_zero_window_size_events",
          { type: "literal", value: "server_zero_window_size_events", description: "\"server_zero_window_size_events\"" },
          function(variable) {
              return setLastVariable(variable, 'number');
            },
          "significance",
          { type: "literal", value: "significance", description: "\"significance\"" },
          "client_ip6",
          { type: "literal", value: "client_ip6", description: "\"client_ip6\"" },
          "server_ip6",
          { type: "literal", value: "server_ip6", description: "\"server_ip6\"" },
          "server_conn_src_ip6",
          { type: "literal", value: "server_conn_src_ip6", description: "\"server_conn_src_ip6\"" },
          "vs_ip6",
          { type: "literal", value: "vs_ip6", description: "\"vs_ip6\"" },
          "client_location",
          { type: "literal", value: "client_location", description: "\"client_location\"" },
          "client_ip",
          { type: "literal", value: "client_ip", description: "\"client_ip\"" },
          "server_ip",
          { type: "literal", value: "server_ip", description: "\"server_ip\"" },
          "server_conn_src_ip",
          { type: "literal", value: "server_conn_src_ip", description: "\"server_conn_src_ip\"" },
          "service_engine",
          { type: "literal", value: "service_engine", description: "\"service_engine\"" },
          "network_security_policy_rule_name",
          { type: "literal", value: "network_security_policy_rule_name", description: "\"network_security_policy_rule_name\"" },
          "analytics_policy_rule_name",
          { type: "literal", value: "analytics_policy_rule_name", description: "\"analytics_policy_rule_name\"" },
          "pool_name",
          { type: "literal", value: "pool_name", description: "\"pool_name\"" },
          "server_name",
          { type: "literal", value: "server_name", description: "\"server_name\"" },
          "microservice_name",
          { type: "literal", value: "microservice_name", description: "\"microservice_name\"" },
          "microservice",
          { type: "literal", value: "microservice", description: "\"microservice\"" },
          "dns_qtype",
          { type: "literal", value: "dns_qtype", description: "\"dns_qtype\"" },
          "gslbservice_name",
          { type: "literal", value: "gslbservice_name", description: "\"gslbservice_name\"" },
          "gslbpool_name",
          { type: "literal", value: "gslbpool_name", description: "\"gslbpool_name\"" },
          "gslbservice",
          { type: "literal", value: "gslbservice", description: "\"gslbservice\"" },
          "dns_fqdn",
          { type: "literal", value: "dns_fqdn", description: "\"dns_fqdn\"" },
          "protocol",
          { type: "literal", value: "protocol", description: "\"protocol\"" },
          "dns_etype",
          { type: "literal", value: "dns_etype", description: "\"dns_etype\"" },
          "vs_ip",
          { type: "literal", value: "vs_ip", description: "\"vs_ip\"" },
          "sni_hostname",
          { type: "literal", value: "sni_hostname", description: "\"sni_hostname\"" },
          "ssl_session_id",
          { type: "literal", value: "ssl_session_id", description: "\"ssl_session_id\"" },
          "ssl_cipher",
          { type: "literal", value: "ssl_cipher", description: "\"ssl_cipher\"" },
          "ssl_version",
          { type: "literal", value: "ssl_version", description: "\"ssl_version\"" },
          function(variable) {
              return setLastVariable(variable, 'string');
            },
          "connection_ended",
          { type: "literal", value: "connection_ended", description: "\"connection_ended\"" },
          function(variable) {
              return setLastVariable(variable, 'bool');
            },
          "client_rtt",
          { type: "literal", value: "client_rtt", description: "\"client_rtt\"" },
          "request_length",
          { type: "literal", value: "request_length", description: "\"request_length\"" },
          "server_response_length",
          { type: "literal", value: "server_response_length", description: "\"server_response_length\"" },
          "server_response_code",
          { type: "literal", value: "server_response_code", description: "\"server_response_code\"" },
          "app_response_time",
          { type: "literal", value: "app_response_time", description: "\"app_response_time\"" },
          "response_length",
          { type: "literal", value: "response_length", description: "\"response_length\"" },
          "response_code",
          { type: "literal", value: "response_code", description: "\"response_code\"" },
          "data_transfer_time",
          { type: "literal", value: "data_transfer_time", description: "\"data_transfer_time\"" },
          "compression_percentage",
          { type: "literal", value: "compression_percentage", description: "\"compression_percentage\"" },
          "waf_log.latency_request_header_phase",
          { type: "literal", value: "waf_log.latency_request_header_phase", description: "\"waf_log.latency_request_header_phase\"" },
          "waf_log.latency_request_body_phase",
          { type: "literal", value: "waf_log.latency_request_body_phase", description: "\"waf_log.latency_request_body_phase\"" },
          "waf_log.latency_response_header_phase",
          { type: "literal", value: "waf_log.latency_response_header_phase", description: "\"waf_log.latency_response_header_phase\"" },
          "waf_log.latency_response_body_phase",
          { type: "literal", value: "waf_log.latency_response_body_phase", description: "\"waf_log.latency_response_body_phase\"" },
          "waf_log.rule_logs.rule_id",
          { type: "literal", value: "waf_log.rule_logs.rule_id", description: "\"waf_log.rule_logs.rule_id\"" },
          "persistent_session_id",
          { type: "literal", value: "persistent_session_id", description: "\"persistent_session_id\"" },
          "http_version",
          { type: "literal", value: "http_version", description: "\"http_version\"" },
          "method",
          { type: "literal", value: "method", description: "\"method\"" },
          "uri_path",
          { type: "literal", value: "uri_path", description: "\"uri_path\"" },
          "uri_query",
          { type: "literal", value: "uri_query", description: "\"uri_query\"" },
          "referer",
          { type: "literal", value: "referer", description: "\"referer\"" },
          "user_agent",
          { type: "literal", value: "user_agent", description: "\"user_agent\"" },
          "client_device",
          { type: "literal", value: "client_device", description: "\"client_device\"" },
          "client_browser",
          { type: "literal", value: "client_browser", description: "\"client_browser\"" },
          "client_os",
          { type: "literal", value: "client_os", description: "\"client_os\"" },
          "xff",
          { type: "literal", value: "xff", description: "\"xff\"" },
          "persistent_cookie",
          { type: "literal", value: "persistent_cookie", description: "\"persistent_cookie\"" },
          "host",
          { type: "literal", value: "host", description: "\"host\"" },
          "etag",
          { type: "literal", value: "etag", description: "\"etag\"" },
          "http_security_policy_rule_name",
          { type: "literal", value: "http_security_policy_rule_name", description: "\"http_security_policy_rule_name\"" },
          "http_request_policy_rule_name",
          { type: "literal", value: "http_request_policy_rule_name", description: "\"http_request_policy_rule_name\"" },
          "http_response_policy_rule_name",
          { type: "literal", value: "http_response_policy_rule_name", description: "\"http_response_policy_rule_name\"" },
          "response_content_type",
          { type: "literal", value: "response_content_type", description: "\"response_content_type\"" },
          "request_content_type",
          { type: "literal", value: "request_content_type", description: "\"request_content_type\"" },
          "rewritten_uri_path",
          { type: "literal", value: "rewritten_uri_path", description: "\"rewritten_uri_path\"" },
          "rewritten_uri_query",
          { type: "literal", value: "rewritten_uri_query", description: "\"rewritten_uri_query\"" },
          "redirected_uri",
          { type: "literal", value: "redirected_uri", description: "\"redirected_uri\"" },
          "server_side_redirect_uri",
          { type: "literal", value: "server_side_redirect_uri", description: "\"server_side_redirect_uri\"" },
          "spdy_version",
          { type: "literal", value: "spdy_version", description: "\"spdy_version\"" },
          "user_id",
          { type: "literal", value: "user_id", description: "\"user_id\"" },
          "waf_log.rule_logs.rule_group",
          { type: "literal", value: "waf_log.rule_logs.rule_group", description: "\"waf_log.rule_logs.rule_group\"" },
          "waf_log.rule_logs.tags",
          { type: "literal", value: "waf_log.rule_logs.tags", description: "\"waf_log.rule_logs.tags\"" },
          "waf_log.status",
          { type: "literal", value: "waf_log.status", description: "\"waf_log.status\"" },
          "waf_log.rule_logs.matches.match_element",
          { type: "literal", value: "waf_log.rule_logs.matches.match_element", description: "\"waf_log.rule_logs.matches.match_element\"" },
          "waf_log.rule_logs.rule_name",
          { type: "literal", value: "waf_log.rule_logs.rule_name", description: "\"waf_log.rule_logs.rule_name\"" },
          "request_id",
          { type: "literal", value: "request_id", description: "\"request_id\"" },
          function(variable) {
              return setLastVariable(variable, 'string');
            },
          "persistence_used",
          { type: "literal", value: "persistence_used", description: "\"persistence_used\"" },
          "cache_hit",
          { type: "literal", value: "cache_hit", description: "\"cache_hit\"" },
          "=",
          { type: "literal", value: "=", description: "\"=\"" },
          "!=",
          { type: "literal", value: "!=", description: "\"!=\"" },
          "~=",
          { type: "literal", value: "~=", description: "\"~=\"" },
          "!~=",
          { type: "literal", value: "!~=", description: "\"!~=\"" },
          "^=",
          { type: "literal", value: "^=", description: "\"^=\"" },
          function(literal) {
              return setLastOp(literal, 'string');
            },
          ">=",
          { type: "literal", value: ">=", description: "\">=\"" },
          ">",
          { type: "literal", value: ">", description: "\">\"" },
          "<=",
          { type: "literal", value: "<=", description: "\"<=\"" },
          "<",
          { type: "literal", value: "<", description: "\"<\"" },
          function(literal) {
              return setLastOp(literal, 'number');
            },
          function(literal) {
              return setLastOp(literal, 'bool');
            },
          function(field, op, arr) {
              return finishArr(field, op, arr);
            },
          function(field, op, query) {
              return finishExpr(field, op, query, 'single');
            },
          function(field, op, query) {
              return finishExpr(field, op, query, 'double');
            },
          function(field, op, query) {
              return finishExpr(field, op, query);
            },
          function(query) {
              return finishWildcard(query);
            },
          function(query) {
              return finishEventWildcard(query);
            },
          "l4: ",
          { type: "literal", value: "l4: ", description: "\"l4: \"" },
          function() { start(); },
          function(first) {
              return finishExprList(first, []);
            },
          "l7: ",
          { type: "literal", value: "l7: ", description: "\"l7: \"" },
          "l4_all: ",
          { type: "literal", value: "l4_all: ", description: "\"l4_all: \"" },
          function(first, rest) {
              return finishExprList(first, rest);
            },
          "l7_all: ",
          { type: "literal", value: "l7_all: ", description: "\"l7_all: \"" },
          "event_all: ",
          { type: "literal", value: "event_all: ", description: "\"event_all: \"" }
        ],

        peg$bytecode = [
          peg$decode("7L*5 \"7P*/ \"7N*) \"7R*# \"7T"),
          peg$decode(". \"\"2 3!"),
          peg$decode(".\"\"\"2\"3#"),
          peg$decode(" $0&\"\"1!3'+,$,)&0&\"\"1!3'\"\"\" %"),
          peg$decode("0(\"\"1!3)"),
          peg$decode("0*\"\"1!3+"),
          peg$decode("7$*; \"7%*5 \". \"\"2 3!*) \".\"\"\"2\"3#"),
          peg$decode("7$*; \"7%*5 \".\"\"\"2\"3#*) \".,\"\"2,3-"),
          peg$decode("7$*; \"7%*5 \". \"\"2 3!*) \".,\"\"2,3-"),
          peg$decode("! $7&+&$,#&7&\"\"\" %+' 4!6.!! %"),
          peg$decode("!. \"\"2 3!+J$ $7',#&7'\"+8%. \"\"2 3!+(%4#6.#!!%$## %$\"# %\"# %"),
          peg$decode("!.\"\"\"2\"3#+J$ $7(,#&7(\"+8%.\"\"\"2\"3#+(%4#6.#!!%$## %$\"# %\"# %"),
          peg$decode("!!.\"\"\"2\"3#+E$ $7(,#&7(\"+3%.\"\"\"2\"3#+#%'#%$## %$\"# %\"# %*V \"!. \"\"2 3!+E$ $7',#&7'\"+3%. \"\"2 3!+#%'#%$## %$\"# %\"# %+! (%"),
          peg$decode("7)*) \"7**# \"7+"),
          peg$decode("! $0/\"\"1!30+,$,)&0/\"\"1!30\"\"\" %+o$!.2\"\"2233+H$ $0/\"\"1!30+,$,)&0/\"\"1!30\"\"\" %+#%'\"%$\"# %\"# %*# \" 1+)%4\"64\"\"! %$\"# %\"# %"),
          peg$decode(".5\"\"2536*\xA1 \".7\"\"2738*\x95 \".9\"\"293:*\x89 \".;\"\"2;3<*} \".=\"\"2=3>*q \".?\"\"2?3@*e \".A\"\"2A3B*Y \".C\"\"2C3D*M \".E\"\"2E3F*A \".G\"\"2G3H*5 \".I\"\"2I3J*) \".K\"\"2K3L"),
          peg$decode("!.M\"\"2M3N+J$ $7#,#&7#\"+8%7,*# \" 1+(%4#6O#! %$## %$\"# %\"# %"),
          peg$decode("!0P\"\"1!3Q+U$7,+K% $70,#&70\"+9%0R\"\"1!3S+)%4$6T$\"\"!%$$# %$## %$\"# %\"# %"),
          peg$decode(".U\"\"2U3V*M \".W\"\"2W3X*A \".Y\"\"2Y3Z*5 \".[\"\"2[3\\*) \".]\"\"2]3^"),
          peg$decode("._\"\"2_3`*\u016D \".a\"\"2a3b*\u0161 \".c\"\"2c3d*\u0155 \".e\"\"2e3f*\u0149 \".g\"\"2g3h*\u013D \".i\"\"2i3j*\u0131 \".k\"\"2k3l*\u0125 \".m\"\"2m3n*\u0119 \".o\"\"2o3p*\u010D \".q\"\"2q3r*\u0101 \".s\"\"2s3t*\xF5 \".u\"\"2u3v*\xE9 \".w\"\"2w3x*\xDD \".y\"\"2y3z*\xD1 \".{\"\"2{3|*\xC5 \".}\"\"2}3~*\xB9 \".\"\"23\x80*\xAD \".\x81\"\"2\x813\x82*\xA1 \".\x83\"\"2\x833\x84*\x95 \".\x85\"\"2\x853\x86*\x89 \".\x87\"\"2\x873\x88*} \".\x89\"\"2\x893\x8A*q \".\x8B\"\"2\x8B3\x8C*e \".\x8D\"\"2\x8D3\x8E*Y \".\x8F\"\"2\x8F3\x90*M \".\x91\"\"2\x913\x92*A \".\x93\"\"2\x933\x94*5 \".\x95\"\"2\x953\x96*) \".\x97\"\"2\x973\x98"),
          peg$decode("!73+' 4!6\x99!! %"),
          peg$decode(".\x9A\"\"2\x9A3\x9B*\u0161 \".\x9C\"\"2\x9C3\x9D*\u0155 \".\x9E\"\"2\x9E3\x9F*\u0149 \".\xA0\"\"2\xA03\xA1*\u013D \".\xA2\"\"2\xA23\xA3*\u0131 \".\xA4\"\"2\xA43\xA5*\u0125 \".\xA6\"\"2\xA63\xA7*\u0119 \".\xA8\"\"2\xA83\xA9*\u010D \".\xAA\"\"2\xAA3\xAB*\u0101 \".\xAC\"\"2\xAC3\xAD*\xF5 \".\xAE\"\"2\xAE3\xAF*\xE9 \".\xB0\"\"2\xB03\xB1*\xDD \".\xB2\"\"2\xB23\xB3*\xD1 \".\xB4\"\"2\xB43\xB5*\xC5 \".\xB6\"\"2\xB63\xB7*\xB9 \".\xB8\"\"2\xB83\xB9*\xAD \".\xBA\"\"2\xBA3\xBB*\xA1 \".\xBC\"\"2\xBC3\xBD*\x95 \".\xBE\"\"2\xBE3\xBF*\x89 \".\xC0\"\"2\xC03\xC1*} \".\xC2\"\"2\xC23\xC3*q \".\xC4\"\"2\xC43\xC5*e \".\xC6\"\"2\xC63\xC7*Y \".\xC8\"\"2\xC83\xC9*M \".\xCA\"\"2\xCA3\xCB*A \".\xCC\"\"2\xCC3\xCD*5 \".\xCE\"\"2\xCE3\xCF*) \".\xD0\"\"2\xD03\xD1"),
          peg$decode("!75+' 4!6\xD2!! %"),
          peg$decode(".\xD3\"\"2\xD33\xD4"),
          peg$decode("!77+' 4!6\xD5!! %"),
          peg$decode(".}\"\"2}3~*\u0119 \"._\"\"2_3`*\u010D \".a\"\"2a3b*\u0101 \".\xD6\"\"2\xD63\xD7*\xF5 \".\xD8\"\"2\xD83\xD9*\xE9 \".\"\"23\x80*\xDD \".\x81\"\"2\x813\x82*\xD1 \".\x83\"\"2\x833\x84*\xC5 \".\xDA\"\"2\xDA3\xDB*\xB9 \".\xDC\"\"2\xDC3\xDD*\xAD \".\xDE\"\"2\xDE3\xDF*\xA1 \".c\"\"2c3d*\x95 \".\xE0\"\"2\xE03\xE1*\x89 \".\xE2\"\"2\xE23\xE3*} \".\xE4\"\"2\xE43\xE5*q \".\xE6\"\"2\xE63\xE7*e \".\xE8\"\"2\xE83\xE9*Y \".\xEA\"\"2\xEA3\xEB*M \".\xEC\"\"2\xEC3\xED*A \".\xEE\"\"2\xEE3\xEF*5 \".\xF0\"\"2\xF03\xF1*) \".\xF2\"\"2\xF23\xF3"),
          peg$decode("!79+' 4!6\x99!! %"),
          peg$decode(".\x9A\"\"2\x9A3\x9B*\u0269 \".\x9C\"\"2\x9C3\x9D*\u025D \".\x9E\"\"2\x9E3\x9F*\u0251 \".\xA0\"\"2\xA03\xA1*\u0245 \".\xA2\"\"2\xA23\xA3*\u0239 \".\xAC\"\"2\xAC3\xAD*\u022D \".\xA4\"\"2\xA43\xA5*\u0221 \".\xCC\"\"2\xCC3\xCD*\u0215 \".\xCE\"\"2\xCE3\xCF*\u0209 \".\xD0\"\"2\xD03\xD1*\u01FD \".\xF4\"\"2\xF43\xF5*\u01F1 \".\xF6\"\"2\xF63\xF7*\u01E5 \".\xF8\"\"2\xF83\xF9*\u01D9 \".\xFA\"\"2\xFA3\xFB*\u01CD \".\xFC\"\"2\xFC3\xFD*\u01C1 \".\xFE\"\"2\xFE3\xFF*\u01B5 \".\u0100\"\"2\u01003\u0101*\u01A9 \".\u0102\"\"2\u01023\u0103*\u019D \".\u0104\"\"2\u01043\u0105*\u0191 \".\u0106\"\"2\u01063\u0107*\u0185 \".\u0108\"\"2\u01083\u0109*\u0179 \".\u010A\"\"2\u010A3\u010B*\u016D \".\u010C\"\"2\u010C3\u010D*\u0161 \".\u010E\"\"2\u010E3\u010F*\u0155 \".\xAE\"\"2\xAE3\xAF*\u0149 \".\u0110\"\"2\u01103\u0111*\u013D \".\u0112\"\"2\u01123\u0113*\u0131 \".\xB2\"\"2\xB23\xB3*\u0125 \".\xB4\"\"2\xB43\xB5*\u0119 \".\xA6\"\"2\xA63\xA7*\u010D \".\xA8\"\"2\xA83\xA9*\u0101 \".\xAA\"\"2\xAA3\xAB*\xF5 \".\u0114\"\"2\u01143\u0115*\xE9 \".\u0116\"\"2\u01163\u0117*\xDD \".\u0118\"\"2\u01183\u0119*\xD1 \".\u011A\"\"2\u011A3\u011B*\xC5 \".\u011C\"\"2\u011C3\u011D*\xB9 \".\u011E\"\"2\u011E3\u011F*\xAD \".\u0120\"\"2\u01203\u0121*\xA1 \".\u0122\"\"2\u01223\u0123*\x95 \".\xB6\"\"2\xB63\xB7*\x89 \".\xB8\"\"2\xB83\xB9*} \".\xC8\"\"2\xC83\xC9*q \".\u0124\"\"2\u01243\u0125*e \".\u0126\"\"2\u01263\u0127*Y \".\u0128\"\"2\u01283\u0129*M \".\u012A\"\"2\u012A3\u012B*A \".\u012C\"\"2\u012C3\u012D*5 \".\u012E\"\"2\u012E3\u012F*) \".\xCA\"\"2\xCA3\xCB"),
          peg$decode("!7;+' 4!6\u0130!! %"),
          peg$decode(".\u0131\"\"2\u01313\u0132*) \".\u0133\"\"2\u01333\u0134"),
          peg$decode("!7=+' 4!6\xD5!! %"),
          peg$decode(".\u0135\"\"2\u01353\u0136*M \".\u0137\"\"2\u01373\u0138*A \".\u0139\"\"2\u01393\u013A*5 \".\u013B\"\"2\u013B3\u013C*) \".\u013D\"\"2\u013D3\u013E"),
          peg$decode("!7#*# \" 1+B$7?+8%7#*# \" 1+(%4#6\u013F#!!%$## %$\"# %\"# %"),
          peg$decode(".\u0135\"\"2\u01353\u0136*Y \".\u0137\"\"2\u01373\u0138*M \".\u0140\"\"2\u01403\u0141*A \".\u0142\"\"2\u01423\u0143*5 \".\u0144\"\"2\u01443\u0145*) \".\u0146\"\"2\u01463\u0147"),
          peg$decode("!7#*# \" 1+B$7A+8%7#*# \" 1+(%4#6\u0148#!!%$## %$\"# %\"# %"),
          peg$decode(".\u0135\"\"2\u01353\u0136*) \".\u0137\"\"2\u01373\u0138"),
          peg$decode("!7#*# \" 1+B$7C+8%7#*# \" 1+(%4#6\u0149#!!%$## %$\"# %\"# %"),
          peg$decode("!76+>$7@+4%71+*%4#6\u014A##\"! %$## %$\"# %\"# %*\xCD \"!76+>$7@+4%7++*%4#6\u014B##\"! %$## %$\"# %\"# %*\xA1 \"!76+>$7@+4%7*+*%4#6\u014C##\"! %$## %$\"# %\"# %*u \"!74+>$7B+4%7.+*%4#6\u014D##\"! %$## %$\"# %\"# %*I \"!78+>$7D+4%7/+*%4#6\u014D##\"! %$## %$\"# %\"# %"),
          peg$decode("!7<+>$7@+4%71+*%4#6\u014A##\"! %$## %$\"# %\"# %*\xCD \"!7<+>$7@+4%7++*%4#6\u014B##\"! %$## %$\"# %\"# %*\xA1 \"!7<+>$7@+4%7*+*%4#6\u014C##\"! %$## %$\"# %\"# %*u \"!7:+>$7B+4%7.+*%4#6\u014D##\"! %$## %$\"# %\"# %*I \"!7>+>$7D+4%7/+*%4#6\u014D##\"! %$## %$\"# %\"# %"),
          peg$decode("!72+>$7@+4%7)+*%4#6\u014A##\"! %$## %$\"# %\"# %*u \"!72+>$7@+4%7++*%4#6\u014B##\"! %$## %$\"# %\"# %*I \"!72+>$7@+4%7*+*%4#6\u014C##\"! %$## %$\"# %\"# %"),
          peg$decode("7E*/ \"!7-+' 4!6\u014E!! %"),
          peg$decode("7F*/ \"!7-+' 4!6\u014E!! %"),
          peg$decode("7G*/ \"!7)+' 4!6\u014F!! %"),
          peg$decode("!.\u0150\"\"2\u01503\u0151+& 4!6\u0152! %"),
          peg$decode("!7K+2$7E+(%4\"6\u0153\"! %$\"# %\"# %"),
          peg$decode("!.\u0154\"\"2\u01543\u0155+& 4!6\u0152! %"),
          peg$decode("!7M+2$7F+(%4\"6\u0153\"! %$\"# %\"# %"),
          peg$decode("!.\u0156\"\"2\u01563\u0157+& 4!6\u0152! %"),
          peg$decode("!7O+o$7H+e% $!7#+-$7H+#%'\"%$\"# %\"# %,8&!7#+-$7H+#%'\"%$\"# %\"# %\"+)%4#6\u0158#\"! %$## %$\"# %\"# %"),
          peg$decode("!.\u0159\"\"2\u01593\u015A+& 4!6\u0152! %"),
          peg$decode("!7Q+o$7I+e% $!7#+-$7I+#%'\"%$\"# %\"# %,8&!7#+-$7I+#%'\"%$\"# %\"# %\"+)%4#6\u0158#\"! %$## %$\"# %\"# %"),
          peg$decode("!.\u015B\"\"2\u015B3\u015C+& 4!6\u0152! %"),
          peg$decode("!7S+o$7J+e% $!7#+-$7J+#%'\"%$\"# %\"# %,8&!7#+-$7J+#%'\"%$\"# %\"# %\"+)%4#6\u0158#\"! %$## %$\"# %\"# %")
        ],

        peg$currPos          = 0,
        peg$reportedPos      = 0,
        peg$cachedPos        = 0,
        peg$cachedPosDetails = { line: 1, column: 1, seenCR: false },
        peg$maxFailPos       = 0,
        peg$maxFailExpected  = [],
        peg$silentFails      = 0,

        peg$result;

    if ("startRule" in options) {
      if (!(options.startRule in peg$startRuleIndices)) {
        throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
      }

      peg$startRuleIndex = peg$startRuleIndices[options.startRule];
    }

    function text() {
      return input.substring(peg$reportedPos, peg$currPos);
    }

    function offset() {
      return peg$reportedPos;
    }

    function line() {
      return peg$computePosDetails(peg$reportedPos).line;
    }

    function column() {
      return peg$computePosDetails(peg$reportedPos).column;
    }

    function expected(description) {
      throw peg$buildException(
        null,
        [{ type: "other", description: description }],
        peg$reportedPos
      );
    }

    function error(message) {
      throw peg$buildException(message, null, peg$reportedPos);
    }

    function peg$computePosDetails(pos) {
      function advance(details, startPos, endPos) {
        var p, ch;

        for (p = startPos; p < endPos; p++) {
          ch = input.charAt(p);
          if (ch === "\n") {
            if (!details.seenCR) { details.line++; }
            details.column = 1;
            details.seenCR = false;
          } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
            details.line++;
            details.column = 1;
            details.seenCR = true;
          } else {
            details.column++;
            details.seenCR = false;
          }
        }
      }

      if (peg$cachedPos !== pos) {
        if (peg$cachedPos > pos) {
          peg$cachedPos = 0;
          peg$cachedPosDetails = { line: 1, column: 1, seenCR: false };
        }
        advance(peg$cachedPosDetails, peg$cachedPos, pos);
        peg$cachedPos = pos;
      }

      return peg$cachedPosDetails;
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) { return; }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildException(message, expected, pos) {
      function cleanupExpected(expected) {
        var i = 1;

        expected.sort(function(a, b) {
          if (a.description < b.description) {
            return -1;
          } else if (a.description > b.description) {
            return 1;
          } else {
            return 0;
          }
        });

        while (i < expected.length) {
          if (expected[i - 1] === expected[i]) {
            expected.splice(i, 1);
          } else {
            i++;
          }
        }
      }

      function buildMessage(expected, found) {
        function stringEscape(s) {
          function hex(ch) { return ch.charCodeAt(0).toString(16).toUpperCase(); }

          return s
            .replace(/\\/g,   '\\\\')
            .replace(/"/g,    '\\"')
            .replace(/\x08/g, '\\b')
            .replace(/\t/g,   '\\t')
            .replace(/\n/g,   '\\n')
            .replace(/\f/g,   '\\f')
            .replace(/\r/g,   '\\r')
            .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function(ch) { return '\\x0' + hex(ch); })
            .replace(/[\x10-\x1F\x80-\xFF]/g,    function(ch) { return '\\x'  + hex(ch); })
            .replace(/[\u0180-\u0FFF]/g,         function(ch) { return '\\u0' + hex(ch); })
            .replace(/[\u1080-\uFFFF]/g,         function(ch) { return '\\u'  + hex(ch); });
        }

        var expectedDescs = new Array(expected.length),
            expectedDesc, foundDesc, i;

        for (i = 0; i < expected.length; i++) {
          expectedDescs[i] = expected[i].description;
        }

        expectedDesc = expected.length > 1
          ? expectedDescs.slice(0, -1).join(", ")
              + " or "
              + expectedDescs[expected.length - 1]
          : expectedDescs[0];

        foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";

        return "Expected " + expectedDesc + " but " + foundDesc + " found.";
      }

      var posDetails = peg$computePosDetails(pos),
          found      = pos < input.length ? input.charAt(pos) : null;

      if (expected !== null) {
        cleanupExpected(expected);
      }

      return new SyntaxError(
        message !== null ? message : buildMessage(expected, found),
        expected,
        found,
        pos,
        posDetails.line,
        posDetails.column
      );
    }

    function peg$decode(s) {
      var bc = new Array(s.length), i;

      for (i = 0; i < s.length; i++) {
        bc[i] = s.charCodeAt(i) - 32;
      }

      return bc;
    }

    function peg$parseRule(index) {
      var bc    = peg$bytecode[index],
          ip    = 0,
          ips   = [],
          end   = bc.length,
          ends  = [],
          stack = [],
          params, i;

      function protect(object) {
        return Object.prototype.toString.apply(object) === "[object Array]" ? [] : object;
      }

      while (true) {
        while (ip < end) {
          switch (bc[ip]) {
            case 0:
              stack.push(protect(peg$consts[bc[ip + 1]]));
              ip += 2;
              break;

            case 1:
              stack.push(peg$currPos);
              ip++;
              break;

            case 2:
              stack.pop();
              ip++;
              break;

            case 3:
              peg$currPos = stack.pop();
              ip++;
              break;

            case 4:
              stack.length -= bc[ip + 1];
              ip += 2;
              break;

            case 5:
              stack.splice(-2, 1);
              ip++;
              break;

            case 6:
              stack[stack.length - 2].push(stack.pop());
              ip++;
              break;

            case 7:
              stack.push(stack.splice(stack.length - bc[ip + 1], bc[ip + 1]));
              ip += 2;
              break;

            case 8:
              stack.pop();
              stack.push(input.substring(stack[stack.length - 1], peg$currPos));
              ip++;
              break;

            case 9:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1]) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 10:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1] === peg$FAILED) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 11:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1] !== peg$FAILED) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 12:
              if (stack[stack.length - 1] !== peg$FAILED) {
                ends.push(end);
                ips.push(ip);

                end = ip + 2 + bc[ip + 1];
                ip += 2;
              } else {
                ip += 2 + bc[ip + 1];
              }

              break;

            case 13:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (input.length > peg$currPos) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 14:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length) === peg$consts[bc[ip + 1]]) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 15:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length).toLowerCase() === peg$consts[bc[ip + 1]]) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 16:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (peg$consts[bc[ip + 1]].test(input.charAt(peg$currPos))) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 17:
              stack.push(input.substr(peg$currPos, bc[ip + 1]));
              peg$currPos += bc[ip + 1];
              ip += 2;
              break;

            case 18:
              stack.push(peg$consts[bc[ip + 1]]);
              peg$currPos += peg$consts[bc[ip + 1]].length;
              ip += 2;
              break;

            case 19:
              stack.push(peg$FAILED);
              if (peg$silentFails === 0) {
                peg$fail(peg$consts[bc[ip + 1]]);
              }
              ip += 2;
              break;

            case 20:
              peg$reportedPos = stack[stack.length - 1 - bc[ip + 1]];
              ip += 2;
              break;

            case 21:
              peg$reportedPos = peg$currPos;
              ip++;
              break;

            case 22:
              params = bc.slice(ip + 4, ip + 4 + bc[ip + 3]);
              for (i = 0; i < bc[ip + 3]; i++) {
                params[i] = stack[stack.length - 1 - params[i]];
              }

              stack.splice(
                stack.length - bc[ip + 2],
                bc[ip + 2],
                peg$consts[bc[ip + 1]].apply(null, params)
              );

              ip += 4 + bc[ip + 3];
              break;

            case 23:
              stack.push(peg$parseRule(bc[ip + 1]));
              ip += 2;
              break;

            case 24:
              peg$silentFails++;
              ip++;
              break;

            case 25:
              peg$silentFails--;
              ip++;
              break;

            default:
              throw new Error("Invalid opcode: " + bc[ip] + ".");
          }
        }

        if (ends.length > 0) {
          end = ends.pop();
          ip = ips.pop();
        } else {
          break;
        }
      }

      return stack[0];
    }


        function parseNumber(integral, fractional) {
            var string = integral.join('');

            if (fractional) {
                return parseFloat(string + '.' + fractional[1].join(''));
            } else {
                var number = parseInt(string);

                if (number > Number.MAX_SAFE_INTEGER) {
                    return string;
                }

                return number;
            }
        }

        function start() {
            avi.ParsedQuery = {};
            avi.ParsedQuery.complete = [];
            reset();
        }

        function reset() {
            avi.ParsedQuery.lastVariable = null;
            avi.ParsedQuery.lastVariableType = null;
            avi.ParsedQuery.lastOp = null;
            avi.ParsedQuery.lastOpType = null;
            avi.ParsedQuery.lastQueryStart = null;
        }

        function setLastVariable(value, type) {
            avi.ParsedQuery.lastVariable = value;
            avi.ParsedQuery.lastVariableType = type;
            return value;
        }

        function setLastOp(value, type) {
            var ops = {
                    '=': 'eq',
                    '!=': 'ne',
                    '>=': 'ge',
                    '>': 'gt',
                    '<=': 'le',
                    '<': 'lt',
                    '~=': 'co',
                    '!~=': 'nc',
                    '^=': 'sw'
                };
            avi.ParsedQuery.lastOp = ops[value];
            avi.ParsedQuery.lastOpType = type;
            avi.ParsedQuery.lastQueryStart = column() + value.length - 1;
            return ops[value];
        }

        function finishExpr(field, op, query, quoted) {
            var obj;
            reset();
            obj = {
                field: field,
                op: op,
                query: query,
                quoted: quoted || ''
            };
            avi.ParsedQuery.complete.push(obj);
            //console.log('Finished expression: %O', obj);
            return avi.ParsedQuery.complete[avi.ParsedQuery.complete.length - 1];
        }

        function finishArr(field, op, arr) {
            var obj;
            reset();
            obj = {
                field: field,
                op: op,
                query: arr,
                array: true
            };
            avi.ParsedQuery.complete.push(obj);
            //console.log('Finished expression: %O', obj);
            return avi.ParsedQuery.complete[avi.ParsedQuery.complete.length - 1];
        }

        function finishEventWildcard(query) {
            reset();
            if (query === "OR") {
                avi.ParsedQuery.complete.push(query);
            } else {
                 avi.ParsedQuery.complete.push({
                    field: 'all',
                    op: 'co',
                    query: query,
                    wildcard: true
                });
            }
            return avi.ParsedQuery.complete[avi.ParsedQuery.complete.length - 1];
        }

        function finishWildcard(query) {
            reset();
            avi.ParsedQuery.complete.push({
                field: 'all',
                op: 'co',
                query: query,
                wildcard: true
            });
            //console.log('Finished wildcard expression: %s', query);
            return avi.ParsedQuery.complete[avi.ParsedQuery.complete.length - 1];
        }

        function finishExprList(first, rest) {
            var ret = [first];
            for (var i = 0; i < rest.length; ++i) {
                ret.push(rest[i][1]);
            }
            return ret;
        }


    peg$result = peg$parseRule(peg$startRuleIndex);

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail({ type: "end", description: "end of input" });
      }

      throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos);
    }
  }

  return {
    SyntaxError: SyntaxError,
    parse:       parse
  };
})();
