/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './user-card.less';

const componentName = 'user-card';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @mixes module:avi/navigation.userCardBindings
 * @see {@link  module:avi/navigation.userCardComponent}
 */
class UserCardController {
    constructor(
        Auth,
        AviModal,
    ) {
        this.AviModal_ = AviModal;
        this.Auth_ = Auth;
        /**
         * User's last login detail.
         * @type {string}
         */
        this.lastLogin = '';

        /**
         * Logged in user's information.
         * @type {Object}
         */
        this.userData = Auth.getCurrentUserData();
    }

    /** @override */
    $onChanges({ userActivity }) {
        if (userActivity.currentValue) {
            this.setLastLogin_(userActivity.currentValue);
        }
    }

    /**
     * Sets last login of user in readable format.
     * Will be called only for the users with 'PERMISSION_USER' access.
     * @param {UserActivity} userActivity
     * @protected
     */
    setLastLogin_(userActivity) {
        let lastLogin = '';

        if (userActivity && userActivity.last_login_timestamp) {
            lastLogin += moment
                .utc(userActivity.last_login_timestamp)
                .add(this.Auth_.initData.timeDifference, 'seconds')
                .fromNow();
        }

        this.lastLogin = lastLogin.capitalize();
    }

    /**
     * Opens the Account modal popup.
     */
    handleOpenMyAccount() {
        this.AviModal_.open('prof-myaccount-edit');
    }

    /** @override */
    $onDestroy() {
        this.AviModal_.destroy('prof-myaccount-edit');
    }
}

UserCardController.$inject = [
    'Auth',
    'AviModal',
];

/**
 * @name userCardComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.UserCardController} controller
 * @property {module:avi/navigation.userCardBindings} bindings
 * @desc
 *      Component for List item containing user account details
 *      (name, email, last login information).
 * @author Aravindh Nagarajan
 */
angular.module('avi/navigation').component('userCard', {
    /**
     * @mixin userCardBindings
     * @memberOf module:avi/navigation
     * @property {UserActivity} userActivity Loggedin user's activity information
     */
    bindings: {
        userActivity: '<',
    },
    controller: UserCardController,
    templateUrl:
        `src/components/avi-header/user-menu/${componentName}/${componentName}.html`,
});
