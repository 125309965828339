/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name SEGroupCollection
 * @desc Collection of {@link SEGroup SEGroups}.
 */
angular.module('aviApp').factory('SEGroupCollection', [
'Collection', 'SEGroup',
function(Collection, SEGroup) {
    class SEGroupCollection extends Collection {
        constructor(args = {}) {
            super(args);

            /**
             * Displays the tenant name in parentheses next to the SE Group name. False by
             * default, set to true in SE Create/Edit, when we want to show the tenant names in
             * the SE Group dropdown.
             * @type {boolean}
             */
            this.showTenantName = args.showTenantName || false;
        }
    }

    const dataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'SeGroupInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'virtualservices',
                'serviceengines',
            ],
        },
    };

    angular.extend(SEGroupCollection.prototype, {
        objectName_: 'serviceenginegroup-inventory',
        itemClass_: SEGroup,
        windowElement_: 'infra-segroup-create',
        serverDefaultsOverride_: {
            vcenter_datastores: [],
        },
        isStatic_: false,
        showTenantName: false,
        defaultDataSources_: 'inventory',
        allDataSources_: dataSources,
    });

    return SEGroupCollection;
}]);
