/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/grid-search.less';

/**
 * @ngdoc directive
 * @name gridSearch
 * @restrict Element
 * @description
 *    Collapsed search field with search icon. Expands search input when clicked
 * @param {string=} placeholder - Search field placeholder text.
 * @param {Function=} ngChange - Function to execute when input changes.
 * @param {boolean=} ngDisabled
 * @param {*} ngModel
 */
angular.module('aviApp').directive('gridSearch', () => {
    const link = (scope, element, attrs, ngModelCtrl) => {
        const $inputEl = element.find('.grid-search-input');

        if (angular.isFunction(scope.ngChange)) {
            scope.$watch(() => scope.ngModel, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    scope.ngChange();
                }
            });
        }

        scope.onBlur = () => {
            if (!ngModelCtrl.$viewValue) {
                element.removeClass('expanded');
            }
        };

        scope.focus = () => {
            element.addClass('expanded');
            $inputEl.trigger('focus');
        };
    };

    return {
        link,
        restrict: 'E',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            placeholder: '@?',
            ngChange: '&?',
            ngDisabled: '<?',
        },
        template: `
            <div class="grid-search"
                 ng-class="{disabled: ngDisabled}">
                <div class="search-icon"
                     ng-click="focus()">
                    <i class="icon-search"></i>
                </div>
                <input class="grid-search-input"
                       type="text"
                       placeholder="{{::placeholder}}"
                       ng-disabled="ngDisabled"
                       ng-blur="onBlur()"
                       ng-model="ngModel">
            </div>`,
    };
});
