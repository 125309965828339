/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name policyGridMatchColumn
 * @description Component for displaying the match configuration in a grid field.
 * @param {DnsRuleConfig} rule
 */
class DnsPolicyGridMatchColumnController {
    /**
     * Returns true if rule has any matches
     * @return {boolean}
     */
    hasMatches() {
        return this.rule.hasMatches();
    }

    /**
     * Returns all Matches in the rule.
     * @return {MatchConfigItem[]}
     */
    getMatches() {
        return this.rule.getMatches();
    }
}

angular.module('aviApp').component('dnsPolicyGridMatchColumn', {
    bindings: {
        rule: '<',
    },
    controller: DnsPolicyGridMatchColumnController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-grid-match-column/dns-policy-grid-match-column.html',
});
