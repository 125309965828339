/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-confirm-change-crs-version.less';

/**
 * @ngdoc component
 * @name wafPolicyConfirmChangeCrsVersion
 * @author alextsg
 * @param {Function} onSubmit - AviConfirmService onSubmit binding, called on confirm. The boolean
 *     saveUncommitted is passed as the param.
 * @param {Function} onCancel - AviConfirmService onCancel binding, called on cancel.
 * @param {Function} closeModal - AviConfirmService closeModal binding, called to close this modal.
 * @param {WafCrs} wafCrs - wafcrs object to be changed to.
 * @description Confirmation modal prompting the user to proceed changing to this CRS version or
 *     cancel.
 */
class WafPolicyConfirmChangeCrsVersionController {
    constructor() {
        this.saveUncommitted = true;
        this.loadedChangeLog = false;
        this.addedRules = 0;
        this.changedRules = 0;
        this.removedRules = 0;
    }

    $onInit() {
        this.loadPreview()
            .then(({ data }) => {
                const {
                    added_rules: addedRules = 0,
                    changed_rules: changedRules = 0,
                    removed_rules: removedRules = 0,
                } = data;

                this.addedRules = addedRules;
                this.changedRules = changedRules;
                this.removedRules = removedRules;
                this.loadedChangeLog = true;
            });
    }

    /**
     * Called if the user confirms changing to this CRS version.
     */
    handleSubmit() {
        const data = {
            saveUncommitted: this.modified && this.saveUncommitted,
        };

        this.onSubmit({ data });
        this.closeModal();
    }

    /**
     * Called if the user decides not to change to this CRS version.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }
}

angular.module('aviApp').component('wafPolicyConfirmChangeCrsVersion', {
    bindings: {
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        wafCrs: '<',
        loadPreview: '&',
        modified: '<',
    },
    controller: WafPolicyConfirmChangeCrsVersionController,
    templateUrl:
            'src/components/modals/waf-policy-modal/waf-policy-confirm-change-crs-version/' +
            'waf-policy-confirm-change-crs-version.html',
});
