/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('PoolGroupListController', [
'CRUDGridConfig', 'PoolGroupCollection',
function(CRUDGridConfig, PoolGroupCollection) {
    this.gridConfig = new CRUDGridConfig();
    this.gridConfig.collection = new PoolGroupCollection();

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'number_of_pools',
        title: 'Number of Pools',
        template: '{{ row.data.config.members.length || 0 }}',
    }, {
        name: 'data.virtualservices',
        title: 'Virtual Services',
        transform(row) {
            const { virtualservices } = row.data;

            return _.pluck(virtualservices, 'name').join(', ');
        },
    }, {
        name: 'cloud',
        title: 'Cloud',
        template: '{{ row.getCloudRef() | name }}',
        visibility: 'optional',
    }];

    this.gridConfig.expandedContainerTemplate = '<pool-group-list-expander row="row"/>';
}]);
