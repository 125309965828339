/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const avgFactory = (d3, chartUtils) => {
    /**
     * Horizontal line used to display in Chart component.
     */
    class Average {
        constructor() {
            /** @type {number|string} */
            this.value = 0;

            /** @type {d3.Selection} */
            this.element = d3.select(chartUtils.createElement())
                .attr('class', 'average-line-container');

            this.line = this.element.append('line')
                .attr('class', 'average-line');

            this.label = this.element.append('text')
                .attr('class', 'average-label');
        }

        /**
         * Changes position relative to its parent element.
         * @param {number} x
         * @param {number} y
         * @param {number} width - Average line width.
         */
        position(x, y, width) {
            this.element.attr('transform', `translate(${x}, ${y})`);

            this.line
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', width)
                .attr('y2', 0);
        }

        /**
         * Sets display value for the average line.
         * @param {number|string} value
         */
        setValue(value) {
            this.value = value;

            if (angular.isNumber(value)) {
                value >>= 0;
            }

            this.label.text(`${value} avg.`);
        }

        /**
         * Attaches axis to parent element.
         * @param {Element} parent
         */
        render(parent) {
            parent.appendChild(this.element.node());
        }
    }

    return Average;
};

avgFactory.$inject = ['d3v4', 'chartUtils'];

angular.module('charts').factory('Average', avgFactory);
