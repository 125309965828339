/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-psm-rule-modal.less';

const WAF_MODE_NONE = 'WAF_MODE_NONE';

/**
 * @alias waf/wafPolicyPsmRuleModal
 * @private
 */
class WafPolicyPsmRuleModalComponent {
    constructor(schemaService, dropDownUtils, Regex) {
        this.schemaService_ = schemaService;
        this.wafParanoiaLevelOptions = dropDownUtils.getEnumDropdownOptions('WafParanoiaLevel');
        this.Regex = Regex;
    }

    $onInit() {
        const wafModeNoneOption = {
            value: WAF_MODE_NONE,
            label: 'Use Policy Mode',
            description: 'Use the policy mode.',
        };

        this.modeOptions = [wafModeNoneOption].concat(this.schemaService_.getEnumValues('WafMode'));
        this.mode = this.rule.mode || WAF_MODE_NONE;
    }

    /**
     * Returns true if the rule is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.rule.getIndex());
    }

    /**
     * Handler for changing the mode radio buttons.
     */
    handleModeChange() {
        if (this.mode === WAF_MODE_NONE) {
            this.rule.mode = undefined;
        } else {
            this.rule.mode = this.mode;
        }
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }
}

WafPolicyPsmRuleModalComponent.$inject = [
    'schemaService',
    'dropDownUtils',
    'Regex',
];

/**
 * @ngdoc component
 * @name wafPolicyPsmRuleModal
 * @module waf/wafPolicyPsmRuleModal
 * @desc Component for editing a WAF PSM location argument rule.
 * @param {WafPolicyPSMRule} rule
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @param {string[]} breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPolicyPsmRuleModal', {
    bindings: {
        rule: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        preventEdit: '<',
        breadcrumbs: '<',
    },
    controller: WafPolicyPsmRuleModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-policy-psm-rule-modal.component.html',
});
