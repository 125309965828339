/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleGeoLocationMatchConfig
 * @description DnsPolicyRuleGeoLocationMatch MatchConfigItem class.
 */
const MatchConfigController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleGeoLocationMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
        }

        /** @override */
        toString() {
            const
                { match_criteria: matchCriteria } = this.getConfig(),
                value = schemaService.getEnumValue('MatchOperation', matchCriteria).label,
                modifiedValue = value.replace(' in', '').toLowerCase();

            let {
                geolocation_name: name,
                geolocation_tag: tag,
            } = this.getConfig();

            name = name || 'N/A';
            tag = tag || 'N/A';

            return `${modifiedValue} Name: ${name}; Tag: ${tag}`;
        }
    }

    angular.extend(DnsPolicyRuleGeoLocationMatchConfig.prototype, {
        type: 'DnsGeoLocationMatch',
        name: 'Geographical Location',
        defaultPath: 'dnsrule.match.geo_location',
    });

    return DnsPolicyRuleGeoLocationMatchConfig;
};

MatchConfigController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('DnsPolicyRuleGeoLocationMatchConfig', MatchConfigController);
