/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './network-and-subnet.less';

/**
 * @ngdoc component
 * @name networkAndSubnet
 * @description
 *     Allows the user to configure a network and subnet. The Network is selected from a collection
 *     dropdown from the networksubnetlist API, and the Subnet is selected from a dropdown, with the
 *     options being populated from the selected Network's subnets.
 *
 *     When editing, this component has to load the network to retrieve the name along with
 *     its subnets to populate the dropdown, since in the case of OpenStack and AWS the
 *     network will not have a name unless we make a request for it.
 * @param {string} network - Binding to the network_ref property of the config object. Will be
 *     populated with the network_ref string.
 * @param {string} subnet - Binding to the subnet property of the config object. Will be populated
 *     with the subnet string as "1.2.3.4/24", since the dropdown component doesn't support setting
 *     objects. Will likely need to be manually converted into the subnet object when saving and to
 *     the subnet string when editing.
 * @param {Object} networkHelp - Schema property to be used for the help icon on the Network
 *     dropdown.
 * @param {Object} subnetHelp - Schema property to be used for the help icon on the Subnet dropdown.
 * @param {Cloud.id} cloudId
 */
class NetworkAndSubnetController {
    constructor(
        SubnetListNetworkCollection,
        ConfiguredNetwork,
        getSubnetString,
    ) {
        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
        this._Network = ConfiguredNetwork;
        this._getSubnetString = getSubnetString;
        this.subnets = [];
    }

    /** @override */
    $onInit() {
        if (!angular.isUndefined(this.cloudId)) {
            this.subnetListNetworkCollection.setParams({
                cloud_uuid: this.cloudId,
            });
        }

        if (!angular.isUndefined(this.network)) {
            const network = new this._Network({
                id: this.network.slug(),
                params: {
                    cloud_uuid: this.cloudId,
                    include_name: true,
                },
            });

            this.busy = true;

            network.load()
                .then(() => {
                    if (!this.network.name()) {
                        this.network = network.getRef();
                    }

                    this.setSubnets(network);
                })
                .finally(() => {
                    this.busy = false;
                    network.destroy();
                });
        }
    }

    /** @override */
    $onDestroy() {
        this.subnetListNetworkCollection.destroy();
    }

    /** @override */
    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.cloudId) {
            this.subnetListNetworkCollection.setParams({
                cloud_uuid: this.cloudId,
            });

            this.network = undefined;
            this.subnet = undefined;
        }
    }

    /**
     * Handler for Network dropdown change event.
     * @param  {ConfiguredNetwork=} selected - Network object. Undefined when selected item
     *     got removed.
     */
    handleNetworkChange(selected) {
        this.subnet = undefined;
        this.setSubnets(selected);
    }

    /**
     * Given a network object, sets this.subnets to its list of subnets.
     * @param {ConfiguredNetwork} network
     */
    setSubnets(network) {
        this.subnets.length = 0;

        if (network) {
            const subnets = network.getSubnets();

            this.subnets.push(
                ...subnets.map(({ prefix }) => ({ value: this._getSubnetString(prefix) })),
            );
        }
    }
}

NetworkAndSubnetController.$inject = [
    'SubnetListNetworkCollection',
    'ConfiguredNetwork',
    'getSubnetString',
];

angular.module('aviApp').component('networkAndSubnet', {
    bindings: {
        network: '=',
        subnet: '=',
        networkHelp: '<',
        subnetHelp: '<',
        cloudId: '@',
    },
    controller: NetworkAndSubnetController,
    templateUrl: '/src/components/common/network-and-subnet/network-and-subnet.html',
});
