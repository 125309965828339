/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function configuredNetworkCollectionFactory(
    Collection,
    ConfiguredNetwork,
    Cloud,
) {
    const dataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'NetworkInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'runtime',
                'discovery',
            ],
        },
    };

    /**
     * @class
     * @name ConfiguredNetworkCollection
     * @extends module:avi/dataModel.Collection
     * @see Cloud.allowNetworkCreate
     * @see {@link SubnetListNetworkCollection}
     */
    class ConfiguredNetworkCollection extends Collection {
        /** @constructor */
        constructor(args = {}) {
            super(args);
            //TODO use params.cloud_uuid instead
            this.cloudType = args.cloudType || '';
        }

        /** @override */
        isCreatable() {
            return Cloud.allowsNetCreation(this.cloudType) && super.isCreatable();
        }
    }

    Object.assign(ConfiguredNetworkCollection.prototype, {
        objectName_: 'network-inventory',
        itemClass_: ConfiguredNetwork,
        windowElement_: 'infra-network-edit',
        defaultDataSources_: 'inventory',
        allDataSources_: dataSources,
    });

    return ConfiguredNetworkCollection;
}

configuredNetworkCollectionFactory.$inject = [
    'Collection',
    'ConfiguredNetwork',
    'Cloud',
];

/**
 * @ngdoc service
 * @alias ConfiguredNetworkCollection
 * @desc
 *
 *     Contains configured networks only. Creation is available for this collection.
 *
 */
angular.module('aviApp')
    .factory('ConfiguredNetworkCollection', configuredNetworkCollectionFactory);
