/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name getRuntimeDataFingerprint
 * @description
 *
 *     Method returns a hash of the passed runtimeData with can be used in a $watch to figure
 *     out that it got changed.
 */
//TODO replace with an Item event `runtimeDataUpdated`
angular.module('aviApp').factory('getRuntimeDataFingerprint', function() {
    /**
     * @returns {string|undefined} - Meaningful runtime values joined with comma.
     */
    return runtimeData => {
        if (angular.isObject(runtimeData)) {
            const result = [];
            const { health_score: healthScore, oper_status: operStatus } = runtimeData;

            if (healthScore) {
                ['resources_penalty',
                    'health_score',
                    'anomaly_penalty',
                    'performance_score',
                    'security_penalty',
                ].forEach(filedName => result.push(healthScore[filedName]));
            }

            if (operStatus) {
                result.push(operStatus['state']);

                const { reason } = operStatus;

                if (angular.isArray(reason)) {
                    Array.prototype.push.apply(result, reason);
                } else {
                    result.push(reason);
                }
            }

            return result.join();
        }
    };
});
