/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name errorPageProfile
 * @description Error Page Body list page.
 */
class ErrorPageBodyController {
    constructor(ErrorPageBodyCollection, CRUDGridConfig) {
        this.ErrorPageBodyCollection = ErrorPageBodyCollection;
        this.CRUDGridConfig = CRUDGridConfig;
    }

    $onInit() {
        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.ErrorPageBodyCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }];
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ErrorPageBodyController.$inject = [
        'ErrorPageBodyCollection',
        'CRUDGridConfig',
];

angular.module('aviApp').component('errorPageBodyList', {
    controller: ErrorPageBodyController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig" />
            </div>`,
});
