/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name dnsRecordConfigModal
 * @description
 *     Modal component for creating or editing a DNS Record in a Virtual Service.
 * @param {DnsRecordConfig=} dnsRecord - DnsRecordConfig config item. If empty it means a new DNS
 *     Record is being created.
 * @param {Function} closeModal - Closes the modal.
 * @param {Function} onSubmit - Called when Submit is clicked.
 */
class DnsRecordConfigModalController {
    constructor(Schema, Regex, DnsRecordConfig) {
        this.Schema = Schema;
        this.Regex = Regex;
        this._DnsRecordConfig = DnsRecordConfig;
        this.editing = false;
        this.recordTypes = angular.copy(DnsRecordConfig.recordTypes);
    }

    $onInit() {
        if (!_.isEmpty(this.dnsRecord)) {
            this.editing = true;
            this.record = this.dnsRecord.getCopy();
        } else {
            this.record = new this._DnsRecordConfig();
            this.record.beforeEdit();
        }
    }

    /**
     * Called when the Submit button is clicked. Calls the bound onSubmit function and closes
     * the modal.
     */
    handleSubmit() {
        this.onSubmit({ record: this.record });
        this.closeModal();
    }
}

DnsRecordConfigModalController.$inject = [
        'Schema',
        'Regex',
        'DnsRecordConfig',
];

angular.module('aviApp').component('dnsRecordConfigModal', {
    controller: DnsRecordConfigModalController,
    bindings: {
        dnsRecord: '<',
        closeModal: '&',
        onSubmit: '&',
    },
    templateUrl: 'src/components/modals/dns-record-config-modal/dns-record-config-modal.html',
});
