/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  userCredentialsModal
 * @param {CloudConnectorUser} editable - CloudConnectorUser item.
 * @param {function} closeModal - Closes modal.
 * @description
 *     Creates SSH user credentials or Azure credentials. An SSH key can be generated, or a private
 *     key can be imported. For Azure credentials, either a username/password or application ID can
 *     be configured.
 */
class Controller {
    constructor(Schema, Regex, secretStubStr) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.secretStubStr = secretStubStr;

        /**
         * Used to toggle between SSH Key and Password inputs.
         * @type {string}
         */
        this.usePassword = false;

        /**
         * Type of credentials used, either 'ssh' or 'azure'.
         */
        this.credentialsType = 'ssh';

        /**
         * Type of azure credentials used, either 'userpass' or 'serviceprincipal'.
         */
        this.azureAuthType = 'userpass';

        /**
         * Type of authentication used for SSH credentials. False for SSH Key, true for
         * password.
         */
        this.usePassword = false;

        /**
         * Type of key to be used for SSH credentials, either 'generate' or 'import'.
         */
        this.keyType = 'generate';

        /**
         * True when ah SSH key value pair has been generated.
         */
        this.keyGenerated = false;
    }

    $onInit() {
        this.usePassword = 'password' in this.editable.getConfig();

        if (this.editable.id) {
            if (this.editable.hasAzureCredentials()) {
                this.credentialsType = 'azure';
                this.azureAuthType = this.editable.usesAzureUserpass() ?
                    'userpass' :
                    'serviceprincipal';
            } else if (this.editable.hasGCPCredentials()) {
                this.credentialsType = 'gcp';
            }
        } else if (this.filter) {
            this.credentialsType = this.filter;
        }
    }

    /**
     * Returns a string to be used as the title for the modal, since the title changes based on
     * the state (Edit vs. New), credentials (Azure vs. SSH), and the name.
     * @return {string}
     */
    getModalTitle() {
        const modalState = this.editable.id ? 'Edit' : 'New';

        let credentials;

        switch (this.credentialsType) {
            case 'azure':
                credentials = 'Azure';
                break;

            case 'gcp':
                credentials = 'GCP';
                break;

            default:
                credentials = 'SSH';
                break;
        }

        return `${modalState} ${credentials} Credentials: ${this.editable.getName()}`;
    }

    /**
     * Clears fields when the credentialsType is changed between Azure and SSH.
     */
    handleCredentialsTypeChange() {
        this.clearAzureCredentials();
        this.clearKeys();
    }

    /**
     * Clears Azure credentials. Called when credentialsType is changed or if the Azure
     * authentication is changed.
     */
    clearAzureCredentials() {
        this.editable.clearAzureCredentials();
    }

    /**
     * Clears keys when user switches between Generate and Import radio buttons.
     */
    clearKeys() {
        this.editable.clearKeys();
    }

    /**
     * Generates SSH key and makes a save request. Modal stays open since we want to display the
     * public SSH key to the user.
     */
    generateKey() {
        this.editable.generateKey()
            .then(() => this.keyGenerated = true);
    }

    /**
     * Imports private key and saves the SSH User. Calls editable.submit() and closes the modal.
     * @returns {ng.Promise}
     */
    importKey() {
        delete this.editable.data.config.public_key;

        return this.editable.submit();
    }

    /**
     * Submits config object to server.
     * @returns {ng.Promise}
     */
    handleClickSave() {
        return this.editable.submit();
    }

    /**
     * Closes the modal and manually resolves the modalPromise. This is done because the modal
     * isn't closed automatically for the Generate option.
     */
    closeWithResolve() {
        this.closeModal();

        if (this.editable.modalPromise) {
            this.editable.modalPromise.resolve(this.editable);
            this.editable.modalPromise = null;
        }
    }
}

Controller.$inject = [
    'Schema',
    'Regex',
    'secretStubStr',
];

angular.module('aviApp').component('userCredentialsModal', {
    bindings: {
        editable: '=',
        filter: '@',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/user-credentials-modal/user-credentials-modal.html',
});
