/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name sharedVsVip
 * @description
 *     Component used to set the 'vs_vip_ref' on Virtual Service creation. A 'vs_vip_ref' can be set
 *     only on creation, when the user is able to select a 'vs_vip_ref' from a CollectionDropdown.
 *     When editing a Virtual Service, the 'vs_vip_ref' can neither be changed to another ref nor
 *     removed.
 * @param {VirtualService} virtualservice
 */
class SharedVsVipController {
    constructor(Schema, Collection, VsVip) {
        this.Schema = Schema;
        this.VsVip = VsVip;
        this.vsCollection = new Collection({
            objectName: 'virtualservice',
            params: {
                join: 'vsvip_ref',
            },
        });
    }

    $onInit() {
        const cloudRef = this.virtualservice.getCloudRef();

        if (cloudRef) {
            this.vsCollection.setParams({
                'cloud_ref.uuid': cloudRef.slug(),
            });
        }

        this.vsVip = this.virtualservice.getConfig()['vsvip_ref_data'];
        this.vipAddresses = this.vsVip.getVipAddresses();
        this.fqdns = this.vsVip.getFqdns();
    }

    $onDestroy() {
        this.vsCollection.destroy();
    }

    /**
     * Handler for ngChange event on CollectionDropdown change. Sets this.vipAddresses and
     * this.fqdns based on the vip and dnsInfo of the selected VsVip object.
     * @param {Item} item - Item instance.
     */
    handleVsVipChange(item) {
        const config = item.getConfig();

        if (config.vsvip_ref_data) {
            const vsConfig = this.virtualservice.getConfig();

            vsConfig.vsvip_ref = config.vsvip_ref;
            this.vsVip = new this.VsVip({
                data: {
                    config: config.vsvip_ref_data,
                },
            });
            vsConfig.vsvip_ref_data = this.vsVip;
            this.vipAddresses = this.vsVip.getVipAddresses();
            this.fqdns = this.vsVip.getFqdns();
        } else {
            this.vipAddresses = [];
            this.fqdns = [];
        }

        this.vipAddressesStr = this.vipAddresses.join(', ');
    }
}

SharedVsVipController.$inject = [
    'Schema',
    'Collection',
    'VsVip',
];

angular.module('aviApp').component('sharedVsVip', {
    controller: SharedVsVipController,
    bindings: {
        virtualservice: '=',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
            'shared-vs-vip/shared-vs-vip.html',
});
