/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name PoolDetailController
 * @description
 *
 *     Controller above all pool details page components.
 */
angular.module('aviApp').controller('PoolDetailController', [
'$scope', '$state', 'myAccount', 'resolvePool', 'resolveVS',
function($scope, $state, myAccount, pool, vs) {
    $scope.myAccount = myAccount;
    $scope.$state = $state;

    $scope.pool = pool;
    /** @deprecated */
    $scope.Pool = pool;

    pool.vs_ = vs;
    pool.vsId_ = vs.id;

    $scope.vs = vs;
    /** @deprecated */
    $scope.VirtualService = vs;

    $scope.$on('$destroy', () => {
        vs.destroy();
        pool.destroy();
    });
}]);
