/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function baseFactory($q, $http) {
    /**
     * @class Base
     * @memberOf module:avi/dataModel
     * @constructor
     * @param {Object=} args
     * @desc
     *     Events API and http requests wrapper.
     **/
    function Base(args = {}) {
        this.events = {};
        this.outstandingRequestCancel = {};

        /**
         * @type {boolean}
         * @protected
         */
        this.isDestroyed_ = false;

        if (angular.isObject(args.bind)) {
            this.bindMultiple(args.bind);
        }
    }

    /**
     * Event listeners by event id.
     * @type {Object|null}
     */
    Base.prototype.events = null;

    /**
     * Makes a call to the url provided with ability to cancel an ongoing call through
     * {@link Base.cancelRequests}.
     * @param {string} method - HTTP method.
     * @param {string} url - Url of request.
     * @param {object=} data - Object to send (optional).
     * @param {object=} headers - Additional headers to be added to request.
     * @param {string=} group - Grouping calls to be able to cancel at once whole group of calls.
     * @return {ng.$q.promise}
     */
    Base.prototype.request = function(method, url, data, headers, group = '') {
        const
            deferred = $q.defer(),
            cancelRequestDefered = $q.defer();

        // Remove hash (for IE)
        url = url.replace(/#.+$/, '');
        $http({
            method,
            url,
            data,
            timeout: cancelRequestDefered.promise,
            headers: headers || undefined,
        })
            .then(rsp => { deferred.resolve(rsp); })
            .catch(rsp => { deferred.reject(rsp); })
            .finally(() => {
                this.outstandingRequestCancel[group]
                    .splice(this.outstandingRequestCancel[group].indexOf(cancelRequestDefered), 1);
            });

        // Returned promise should have an option to cancel the call
        deferred.promise.cancel = function(reason) {
            cancelRequestDefered.resolve(reason);
        };

        // Collecting requests to be able to cancel them later
        if (!this.outstandingRequestCancel[group]) {
            this.outstandingRequestCancel[group] = [];
        }

        this.outstandingRequestCancel[group].push(cancelRequestDefered);

        return deferred.promise;
    };

    /**
     * Cancels all pending requests using promise
     */
    Base.prototype.cancelRequests = function(group) {
        if (group) {
            if (!this.outstandingRequestCancel[group]) {
                // No-op when group doesn't exist
                return;
            }

            // Just cancel the group
            this.outstandingRequestCancel[group].forEach(function(deferred) {
                deferred.resolve();
            });
        } else {
            // Cancel them all
            const { outstandingRequestCancel } = this;

            Object.keys(this.outstandingRequestCancel).forEach(function(key) {
                outstandingRequestCancel[key].forEach(function(deferred) {
                    deferred.resolve();
                });
            });
        }
    };

    /**
     * Adds multiple event listeners.
     * @param {Object} oListeners
     */
    Base.prototype.bindMultiple = function(oListeners) {
        let iListeners,
            vListener,
            iListener;

        _.each(Object.keys(oListeners), sEvent => {
            vListener = oListeners[sEvent];

            if (vListener instanceof Array) {
                iListeners = vListener.length;

                for (iListener = 0; iListener < iListeners; iListener++) {
                    this.bind(sEvent, vListener[iListener]);
                }
            } else {
                this.bind(sEvent, vListener);
            }
        });
    };

    /**
     * Creates event listener
     * @param {string} sEvent - The name of event
     * @param {Function} fListener - Callback function
     * @param {boolean} bUnshift - If true then the event will be removed after event fire
     */
    Base.prototype.bind = function(sEvent, fListener, bOnetime, bUnshift) {
        if (typeof fListener !== 'function') {
            return false;
        }

        // If multiple events
        const aEvents = sEvent.split(' ');

        if (aEvents.length > 1) {
            for (let iEvent = 0; iEvent < aEvents.length; iEvent++) {
                const params = [aEvents[iEvent]]
                    .concat([...arguments].slice(1));

                this.bind(...params);
            }

            return;
        }

        const oEvents = this.events;
        let oEvent = oEvents[sEvent];

        if (!oEvent) {
            oEvents[sEvent] = {
                listeners: [],
            };
            oEvent = oEvents[sEvent];
        } else {
            this.unbind(sEvent, fListener);
        }

        if (bOnetime) {
            oEvent.listeners[bUnshift ? 'unshift' : 'push']({
                listener: fListener,
                onetime: true,
            });
        } else {
            oEvent.listeners[bUnshift ? 'unshift' : 'push'](fListener);
        }
    };

    /**
     * Shortcut to bind
     * @param {string} sEvent - The name of event
     * @param {Function} fListener - Callback function
     * @param {boolean=} bOnetime - If true then the event will be removed after event fire
     */
    Base.prototype.on = function(sEvent, fListener, bOnetime) {
        this.bind(sEvent, fListener, bOnetime);
    };

    /**
     * Shortcut for one time bind.
     * @param {string} sEvent - event type.
     * @param {Function} fListener - Event handler function.
     */
    Base.prototype.one = function(sEvent, fListener) {
        this.bind(sEvent, fListener, true);
    };

    /**
     * Removes event listener
     * @param {string} sEvent - Event name
     * @param {Function} fListener - Callback function
     * @returns {number} - Number of removed listeners
     */
    Base.prototype.unbind = function(sEvent, fListener) {
        let iRemoved = 0;

        if (!this.isDestroyed()) {
            // If multiple events
            const aEvents = sEvent.split(' ');

            if (aEvents.length > 1) {
                for (let iEvent = 0; iEvent < aEvents.length; iEvent++) {
                    const params = [aEvents[iEvent]]
                        .concat([...arguments].slice(1));

                    this.unbind(...params);
                }

                return;
            }

            const oEvents = this.events;

            if (!oEvents[sEvent]) {
                return 0;
            }

            const aListeners = oEvents[sEvent].listeners;
            let oListener;

            for (let iListener = aListeners.length - 1; iListener >= 0; iListener--) {
                oListener = aListeners[iListener];

                if (oListener == fListener || oListener.listener == fListener) {
                    aListeners.splice(iListener, 1);
                    iRemoved++;
                }
            }
        }

        return iRemoved;
    };

    /**
     * Removes ontime event listeners
     * @param {string} sEvent - Event name
     * @returns {number} - Number of removed listeners
     */
    Base.prototype.removeOnetimeEventListeners = function(sEvent) {
        // If multiple events
        const aEvents = sEvent.split(' ');

        if (aEvents.length > 1) {
            for (let iEvent = 0; iEvent < aEvents.length; iEvent++) {
                const params = [aEvents[iEvent]]
                    .concat([...arguments].slice(1));

                this.removeOnetimeEventListeners(...params);
            }

            return;
        }

        const oEvents = this.events;

        if (!oEvents[sEvent]) {
            return 0;
        }

        const aListeners = oEvents[sEvent].listeners;
        let iRemoved = 0;

        for (let iListener = aListeners.length - 1; iListener >= 0; iListener--) {
            if (aListeners[iListener].onetime) {
                aListeners.splice(iListener, 1);
                iRemoved++;
            }
        }

        return iRemoved;
    };

    /**
     * Fires event. Multiple arguments allowed, will be passed to listener functions
     * @param {string} sEvent  - Event name
     */
    Base.prototype.trigger = function(sEvent) {
        if (!this.isDestroyed()) {
            // If multiple events
            const aEvents = sEvent.split(' ');

            if (aEvents.length > 1) {
                let iEvent;

                for (iEvent = 0; iEvent < aEvents.length; iEvent++) {
                    const params = [aEvents[iEvent]]
                        .concat([...arguments].slice(1));

                    this.trigger(...params);
                }

                return;
            }

            const { events: oEvents } = this;

            if (!oEvents[sEvent]) {
                return;
            }

            // Duplicate array because it may be modified from within the listeners
            const
                aListeners = oEvents[sEvent].listeners.slice(),
                iListeners = aListeners.length;

            // Remove one-time event listeners
            this.removeOnetimeEventListeners(sEvent);

            let iListener;

            for (iListener = 0; iListener < iListeners; iListener++) {
                // Remove first argument
                const aArgs = [...arguments].slice(1);
                // Call event listener in scope of this object
                const oListener = aListeners[iListener];

                try {
                    if (angular.isFunction(oListener)) {
                        oListener.apply(this, aArgs);
                    } else {
                        oListener.listener.apply(this, aArgs);
                    }
                } catch (e) {
                    console.error(
                        `Event listener for "${sEvent}" event raised an exception`,
                        this,
                        e,
                    );
                }
            }
        } else {
            console.error(`Trying to trigger "${sEvent}" event on destroyed object`, this);
        }
    };

    /**
     * Returns a hash of events which have listeners and their quantity for every event type.
     * @return {{string:number}} Event name as a key and number of listeners as a value.
     */
    Base.prototype.getEventsStackInfo = function() {
        return _.reduce(this.events, function(base, event, eventName) {
            const listenersQ = event.listeners.length;

            if (listenersQ) {
                base[eventName] = listenersQ;
            }

            return base;
        }, {});
    };

    /**
     * Service to unbind all events and cancel all pending requests.
     * @return {boolean}
     */
    Base.prototype.destroy = function() {
        if (!this.isDestroyed()) {
            this.trigger('beforeDestroy');
            this.isDestroyed_ = true;
            this.cancelRequests();
            this.events = null;

            return true;
        }

        return false;
    };

    /**
     * Getter to figure out whether element got destroyed.
     * @return {boolean}
     */
    Base.prototype.isDestroyed = function() {
        return !!this.isDestroyed_;
    };

    return Base;
}

baseFactory.$inject = [
    '$q',
    '$http',
];

angular.module('core.vantage.avi')
    .factory('Base', baseFactory);
