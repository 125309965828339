/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Inject } from '@angular/core';
import './waf-policy-psm-locations-config.less';

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationsConfigComponent
 * @module waf/wafPolicyPsmLocationsConfigComponent
 * @desc Component for displaying a WAF Policy Whitelist rule in the WAF Policy modal.
 * @param breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @param psmGroup - WAF Policy PSM Group
 * @param preventEdit - True to prevent editing.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-locations-config',
    templateUrl: './waf-policy-psm-locations-config.component.html',
})
export class WafPolicyPsmLocationsConfigComponent {
    @Input() breadcrumbs: string[];
    @Input() psmGroup: WafPolicyPsmGroup;
    @Input() preventEdit: boolean;

    constructor(@Inject('AviModal') private AviModal: AviModalService) {}

    /**
     * Creates a new location and opens the modal to edit it.
     */
    addLocation(): void {
        this.editLocation(this.psmGroup.createNewLocation());
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    editLocation(location: WafPSMLocationConfigItem): void {
        const breadcrumb = `Positive Security Group: ${this.psmGroup.getName()}`;
        const breadcrumbs = Array.isArray(this.breadcrumbs) ?
            this.breadcrumbs.concat(breadcrumb) :
            [breadcrumb];

        this.AviModal.open('waf-policy-psm-location-modal', {
            location: location.clone(),
            preventEdit: this.preventEdit,
            breadcrumbs,
            onSubmit: ['location', (location: WafPSMLocationConfigItem): void => {
                this.psmGroup.addLocation(location);
            }],
        });
    }
}
