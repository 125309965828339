/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name policyGridActionColumn
 * @description Component for displaying the action configuration in a grid field.
 * @param {PolicyRuleConfigItem} rule
 * @param {PolicyGridConfig} config
 */
class PolicyGridActionColumnController {
    constructor(PolicyGridService, PolicyRuleConfigItem) {
        this.PolicyGridService_ = PolicyGridService;
        this.PolicyRuleConfigItem_ = PolicyRuleConfigItem;
    }

    $onInit() {
        this.isPolicyRuleConfigItem = this.rule instanceof this.PolicyRuleConfigItem_;

        if (!this.isPolicyRuleConfigItem) {
            this.actions = this.config.collection.actions;
        }
    }

    /**
     * Returns all actions in the rule.
     * @return {ActionConfigItem[]}
     */
    getActions() {
        return this.rule.getActions();
    }

    /**
     * Returns the name of the action property to be displayed.
     * @param {string} property - Action property.
     */
    getActionName(property) {
        return this.PolicyGridService_.getActionName(property, this.rule);
    }

    /**
     * Returns a string representation of the action configuration.
     * @param {string} property - Action property.
     * @param {Object} action - Action object containing the config.
     */
    getActionString(property, action) {
        return this.PolicyGridService_.getActionString(property, action, this.rule);
    }
}

PolicyGridActionColumnController.$inject = [
    'PolicyGridService',
    'PolicyRuleConfigItem',
];

angular.module('aviApp').component('policyGridActionColumn', {
    bindings: {
        rule: '<',
        config: '<',
    },
    controller: PolicyGridActionColumnController,
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-action-column/policy-grid-action-column.html',
});
