/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const componentName = 'sso-policy-list';

class SSOPolicyListController {
    constructor($element, SSOPolicyCollection, CRUDGridConfig) {
        this.$element_ = $element;
        this.SSOPolicyCollection_ = SSOPolicyCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.SSOPolicyCollection_(),
            fields: [{
                name: 'name',
                title: 'Name',
                template: '{{row.getName()}}',
                sortBy: 'name',
            },
            {
                name: 'default_auth_profile',
                title: 'Auth Profile',
                template: '{{row.getDefaultAuthProfileRef() | name}}',
            }],
            permission: 'PERMISSION_SSOPOLICY',
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

SSOPolicyListController.$inject = [
    '$element',
    'SSOPolicyCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name SSOPolicyList
 * @author Aravindh Nagarajan
 * @description SSO Policy list page with options to create/delete/edit a policy.
 */
angular.module('aviApp').component('ssoPolicyList', {
    controller: SSOPolicyListController,
    template:
        '<div class="templatesList"><collection-grid config="$ctrl.gridConfig" /></div>',
});
