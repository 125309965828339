/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleAllowDropActionConfig
 * @description DnsPolicyRuleAllowDropActionConfig, maps to DnsRuleActionAllowDrop in protobof.
 */
const dnsPolicyRuleAllowDropActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRuleAllowDropActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            let output = '';
            const { allow, reset_conn: resetConn } = this.getConfig();

            output += `${allow ? 'Allow ' : 'Drop '} Query`;

            if (resetConn) {
                output += '; Reset TCP Connection';
            }

            return output;
        }
    }

    angular.extend(DnsPolicyRuleAllowDropActionConfig.prototype, {
        type: 'DnsRuleActionAllowDrop',
        name: 'Allow/Drop Query',
        defaultPath: 'dnsrule.action.allow',
    });

    return DnsPolicyRuleAllowDropActionConfig;
};

dnsPolicyRuleAllowDropActionConfigFactory.$inject = [
        'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleAllowDropActionConfig', dnsPolicyRuleAllowDropActionConfigFactory,
);
