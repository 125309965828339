/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name LaunchConfigListController
 * @description Controller for AutoScale Launch Config page.
 **/
angular.module('aviApp').controller('LaunchConfigListController', [
'$scope', 'CRUDGridConfig', 'AutoScaleLaunchConfigCollection', 'Regex',
function($scope, CRUDGridConfig, AutoScaleLaunchConfigCollection, Regex) {
    this.Regex = Regex;

    this.collection = new AutoScaleLaunchConfigCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }];

    this.gridConfig.layout = {
        hideEditColumns: true,
    };
}]);
