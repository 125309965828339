/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/pie-chart-dashboard.less';

/**
 * @fileOverview Creates a Pie Chart with a legend containing names and percentage breakdown
 * of the different series. An optional highlight is supported that displays the combined percentage
 * of desired series.
 */
angular.module('aviApp').directive('pieChartDashboard', [
'myAccount', 'NamesHelper', 'GraphSync',
function(myAccount, NamesHelper, graphSync) {
    function pieChartDashboardLink(scope) {
        let sum; //sum of all values shown on the chart

        const dictionary = {
            'l7_client.avg_ssl_rsa_non_pfs': 'l7_client.avg_ssl_non_pfs',
        };

        scope.padding = {
            top: 5,
            bottom: 5,
            left: 5,
            right: 5,
        };

        scope.hovered = null; //updated by pieChart directive

        // Tuple containing percentage values.
        scope.highlights = [0, 0];

        function updateValues() {
            const
                { config } = scope,
                valuesToDisplay = myAccount.getValuesDisplayType();

            sum = 0;

            if (Array.isArray(config.highlight)) {
                config.highlight.forEach((highlight, index) => scope.highlights[index] = 0);
            }

            const metric = scope.item.getMetric(config.metricName);

            let maxSeries;

            scope.values = _.map(scope.seriesList, sName => {
                const
                    series = metric.getSeries(sName),
                    res = {
                        id: sName,
                    };

                const
                    value = series.getValue(
                        graphSync.mouseOnGraph ? 'exact' : valuesToDisplay,
                        graphSync.mouseOnGraph,
                    ) || 0;

                res.value = value;

                sum += value;

                res.className = dictionary[sName] ?
                    NamesHelper.getColorClass(dictionary[sName]) :
                    series.getColorClassName();

                res.title = dictionary[sName] ?
                    NamesHelper.getTitle(dictionary[sName]) :
                    series.getTitle();

                if (!maxSeries || value > maxSeries.value) {
                    maxSeries = angular.copy(res);
                }

                return res;
            });

            if (maxSeries && maxSeries.value && (graphSync.mouseOnGraph ||
                (valuesToDisplay !== 'max' && valuesToDisplay !== 'sum'))) {
                maxSeries.unit = '%';
                maxSeries.value = +(maxSeries.value * 100 / sum).toFixed(2);
                scope.maxSeries = maxSeries;
            } else {
                scope.maxSeries = null;

                if (valuesToDisplay === 'max' || valuesToDisplay === 'sum') {
                    scope.values = null;
                }
            }

            if (scope.values) {
                scope.values.forEach(value => {
                    value.percent = +(value.value * 100 / sum).toFixed(2);

                    if (Array.isArray(config.highlight)) {
                        const index = _.findIndex(config.highlight,
                            highlight => _.contains(highlight.combine, value.id));

                        scope.highlights[index] += value.percent;
                    }
                });
            }

            scope.seriesList = scope.config.series;
        }

        updateValues();

        myAccount.on('valuesToDisplayChange', updateValues);

        scope.item.on('metricsUpdate', updateValues);

        graphSync.on('GraphSync', updateValues);

        scope.$on('$destroy', () => {
            myAccount.unbind('valuesToDisplayChange', updateValues);
            graphSync.unbind('GraphSync', updateValues);
            scope.item.unbind('metricsUpdate', updateValues);
        });
    }

    return {
        scope: {
            config: '<',
            item: '<',
        },
        restrict: 'A',
        templateUrl: '/src/views/components/pie-chart-dashboard.html',
        link: pieChartDashboardLink,
    };
}]);
