/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/components/cell-sparkline.less';

/**
 * @ngdoc directive
 * @name cellSparkline
 * @param {Series} series - Timeseries to be passed to Sparkline directive.
 * @param {boolean} mouseUpdatesCharts
 * @description
 *
 *     Layer between {@link cell | Cell directive} and {@link sparkline | Sparkline directive}.
 *
 */
//TODO use the proper popover service here
angular.module('aviApp').directive('cellSparkline', [
'chartValueController', '$templateCache', '$compile',
function(chartValueController, $templateCache, $compile) {
    function cellSparklineLink(scope, element) {
        const sparkPopup = require('./sparkline-popup.partial.html');
        const compiled = $compile(sparkPopup)(scope);
        const body = $('body');

        element
            .on('mouseover', () => {
                body.append(compiled);

                const
                    { top, left } = element.offset(),
                    height = element.innerHeight(),
                    width = element.innerWidth();

                compiled.offset({
                    top: top + height + 10,
                    left: left + width * 0.5 - 75,
                });
            })
            .on('mouseout', () => compiled.remove());

        scope.$watch('series.getUnits()', units => {
            scope.fractionSize = !units || units === 'METRIC_COUNT' ? 0 : 1;
        });

        scope.$on('$destroy', () => compiled.remove());
    }

    return {
        scope: {
            series: '<',
            mouseUpdatesCharts: '@',
        },
        link: cellSparklineLink,
        restrict: 'E',
        templateUrl: '/src/js/directives/cell-sparkline/cell-sparkline.html',
        controller: ['$scope', chartValueController],
    };
}]);
