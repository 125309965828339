/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2020] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './custom-params-list.component.less';

const componentName = 'custom-params-list';

/**
 * Object configuring custom param. Map to CustomParams in protobuf.
 * @typedef {Object}
 * @memberOf module:avi/app
 * @name CustomParams
 * @property {string} name
 * @property {string} value
 * @property {?boolean} is_sensitive
 * @property {?boolean} is_dynamic
 */

/**
 * @constructor
 * @memberOf module:avi/app
 * @mixes module:avi/app.customParamsListComponenetBindings
 */
class CustomParamsListController {
    constructor() {
        /**
         * @type {boolean}
         * Decide to have 'sensitive' checkbox or not.
         */
        this.hasSensitive = angular.isUndefined(this.hasSensitive) ? false : this.hasSensitive;

        /**
         * @type {boolean}
         * Decide to have 'dynamic' checkbox or not.
         */
        this.hasDynamic = angular.isUndefined(this.hasDynamic) ? false : this.hasDynamic;

        this.addButtonLabel = this.addButtonLabel || 'Add Custom Params';
    }

    /**
     * Create and return new CustomParams object.
     * @return {CustomParams}
     * @protected
     */
    createCustomParamsObject_() {
        const customParamsObj = {
            name: '',
            value: '',
        };

        if (this.hasSensitive) {
            customParamsObj.is_sensitive = false;
        }

        if (this.hasDynamic) {
            customParamsObj.is_dynamic = false;
        }

        return customParamsObj;
    }

    /**
     * Removes element from customParamsList by index.
     * @param {number} index
     */
    removeCustomParamsObj(index) {
        this.paramsList.splice(index, 1);
    }

    /**
     * Adds new CustomParams object to customParamsList.
     */
    addCustomParamsObj() {
        this.paramsList.push(this.createCustomParamsObject_());
    }
}

/**
 * @mixin customParamsListComponenetBindings
 * @memberOf module:avi/app
 * @property {CustomParams[]} paramsList - List holding CustomParams objects.
 * @property {boolean=} [hasSensitive=false] - Flag deciding whether to have 'Sensitive' checkbox.
 * @property {boolean=} [hasDynamic=false] - Flag deciding whether to have 'Dynamic' checkbox.
 * @property {string=} addButtonLabel - Label for the adding button.
 */

/**
 * @name customParamsListComponent
 * @property {module:avi/app.CustomParamsListController} controller
 * @property {module:avi/app.customParamsListComponenetBindings} bindings
 * @memberOf module:avi/app
 * @description
 *      Component to provide list to configure groups of
 *      custom params for (custom) IPAM/DNS profiles.
 * @author Zhiqian Liu
 */
angular.module('avi/app').component('customParamsList', {
    controller: CustomParamsListController,
    bindings: {
        paramsList: '<',
        hasSensitive: '<?',
        hasDynamic: '<?',
        addButtonLabel: '@?',
    },
    templateUrl:
        `src/components/templates/profiles/${componentName}/${componentName}.component.html`,
});
