/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @class DatascriptsetModalController
 * @constructor
 * @memberOf module:avi/scripts
 * @mixes module:avi/scripts.datascriptsetModalComponentBindings
 * @description Edit modal for DataScriptSet.
 * @author Alex Malitsky, chitra
 */
class DatascriptsetModalController {
    constructor(
        $element,
        Regex,
        PoolCollection,
        IpAddrGroupCollection,
        StringGroupCollection,
        PoolGroupCollection,
        DataScriptSet,
        ProtocolParserCollection,
        schemaService,
    ) {
        this.Regex = Regex;
        this.$element = $element;
        this.poolGroupCollection = new PoolGroupCollection({ isStatic: true });
        this.poolCollection = new PoolCollection({ isStatic: true });
        this.ipGroupCollection = new IpAddrGroupCollection();
        this.stringGroupCollection = new StringGroupCollection();
        this.protocolParserCollection = new ProtocolParserCollection();
        this.DataScriptSet = DataScriptSet;
        this.schemaService_ = schemaService;
    }

    $onInit() {
        this.dataScriptHelpText = this.schemaService_.getFieldDescription('VSDataScript', 'evt');
    }

    /**
     * Filter to list http event scripts
     * @param {Object} eventConfig
     * @return {boolean}
     */
    isHttpEvent = (eventConfig = {}) => {
        return _.contains(this.DataScriptSet.httpEventTypes, eventConfig.evt);
    };

    /**
     * Filter to list SSL event scripts
     * @param {Object} [eventConfig={}]
     * @return {boolean}
     */
    isSslEvent = (eventConfig = {}) => {
        return _.contains(this.DataScriptSet.sslEventTypes, eventConfig.evt);
    };

    /**
     * Filter to list L4 event scripts
     * @param {Object} eventConfig
     * @return {boolean}
     */
    isL4Event = (eventConfig = {}) => {
        return _.contains(this.DataScriptSet.l4EventTypes, eventConfig.evt);
    };

    $onDestroy() {
        [
            this.ipGroupCollection,
            this.stringGroupCollection,
            this.poolCollection,
            this.poolGroupCollection,
            this.protocolParserCollection,
        ].forEach(collection => collection.destroy());
    }
}

DatascriptsetModalController.$inject = [
    '$element',
    'Regex',
    'PoolCollection',
    'IpAddrGroupCollection',
    'StringGroupCollection',
    'PoolGroupCollection',
    'DataScriptSet',
    'ProtocolParserCollection',
    'schemaService',
];

/**
 * @typedef datascriptsetModalComponent
 * @property {module:avi/scripts.DatascriptsetModalController} controller
 * @property {module:avi/scripts.datascriptsetModalComponentBindings} bindings
 * @memberOf module:avi/scripts
 */
angular.module('avi/scripts').component('datascriptsetModal', {
    /**
     * @mixin datascriptsetModalComponentBindings
     * @property {module:avi/scripts.DataScriptSet} editable - DataScriptSet item to be editable.
     * @property {Function} closeModal - method to be called when closing the modal.
     * @memberOf module:avi/scripts
     */
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: DatascriptsetModalController,
    templateUrl: 'src/components/modals/datascriptset-modal/datascriptset-modal.html',
});
