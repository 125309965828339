/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Function to convert SQL-styled offset and limit to page and page_size in a possible
 * efficient way.
 * As our API doesn't support them yet. Throw away after implementation. @am
 */
angular.module('aviApp').factory('limitAndOffset2pageSizeAndPage', function() {
    return function(limit, offset = 0) {
        if (!limit) {
            console.warn(
                "limitAndOffset2pageSizeAndPage can't work without provided limit value",
            );

            return [0, 0];
        }

        let
            pageSize = limit,
            page = Math.floor(offset / pageSize) + 1;

        while (pageSize < limit * 2 && (offset < (page - 1) * pageSize ||
            page * pageSize < offset + limit)) {
            pageSize++;
            page = Math.floor(offset / pageSize) + 1;
        }

        return [pageSize, page];
    };
});
