/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import {
    USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE,
} from '../../constants/my-account.constant';

angular.module('aviApp').controller('AlertsController', [
'$scope', '$location', '$stateParams', '$state', 'logTimeframes', 'AlertCollection',
'alertGridConfig', 'eventsContext', 'Timeframe', 'myAccount',
function(scope, $location, $stateParams, $state,
         logTimeframes, AlertCollection, alertGridConfig, eventsContext, Timeframe, myAccount) {
    const
        defaults = {
            timeframe: '15m', //for use instead of custom
            allTimeStart: '2014-01-01 00:00 +0000',
            orderby: '-timestamp',
        },
        ordersBy = {
            timestamp: true,
            obj_ref: true,
            level: true,
        };//params to be saved in stateParams and URI

    /* save and modify parameters in one place (well, mostly) */
    function Params() {
        let endIsNow = true;

        this.end = false;
        this.start = false;
        this.orderby = defaults.orderby;

        //duration - timeframe string, start&end - dates in 'moment' type
        this.updateTimeframe = function(duration, start, end) {
            if (typeof duration === 'undefined') { //update on forceRefresh
                if (this.start && this.end && endIsNow && this.duration &&
                    this.timeframe !== 'custom') {
                    this.end = moment();
                    this.start = this.end.clone().subtract(this.duration, 's');
                }
            } else if (duration !== 'custom' && typeof duration === 'string' &&
                logTimeframes[duration]) {
                this.end = moment();
                endIsNow = true;

                if (duration === 'all') {
                    this.start = moment(defaults.allTimeStart, 'YYYY-MM-DD HH:mm Z');
                    this.duration = this.end.diff(this.start, 's');
                } else {
                    this.duration = logTimeframes[duration].range;
                    this.start = this.end.clone().subtract(this.duration, 's');
                }

                this.timeframe = duration;
            } else if (duration === 'custom') {
                if (typeof start === 'object') {
                    this.start = start;

                    if (typeof end === 'object') {
                        this.end = end;
                        endIsNow = false;
                    } else if (endIsNow || !this.end) {
                        if (!this.end) {
                            console.warn('params.updateTimeframe: ' +
                                'unexpected behaviour: end is false');
                        }

                        this.end = moment();
                        endIsNow = true;
                    }

                    this.duration = this.end.diff(this.start, 's');
                    this.timeframe = 'custom';
                } else {
                    console.warn('params.updateTimeframe: custom timeframe ' +
                        'given without start object');
                    this.updateTimeframe(defaults.timeframe);

                    return false;
                }
            }
        };

        this.updateStParamsAndURI = function() { //update stateParams and URI
            _.each($state.current.params, (prevValue, key) => {
                const value = this[key];
                let stateParamValue = null;

                if (value && (angular.isUndefined(defaults[key]) || value !== defaults[key])) {
                    stateParamValue = `${this[key]}`;
                }

                $location.search(key, stateParamValue);
            });
        };
    }

    scope.params = new Params();

    const { params } = scope;

    scope.$state = $state;

    scope.setInitialState = function() {
        let { key: duration } = Timeframe.selected();

        if (duration === 'custom') {
            duration = defaults.timeframe;
            Timeframe.set(duration);
        }

        params.updateTimeframe(duration);

        if ($stateParams.orderby && (
            $stateParams.orderby in ordersBy ||
            $stateParams.orderby[0] === '-' && $stateParams.orderby.slice(1) in ordersBy)) {
            params.orderby = $stateParams.orderby;
        } else {
            params.orderby = defaults.orderby;
        }

        const
            slug = $stateParams.poolId || $stateParams.vsId ||
                $stateParams.seId || $stateParams.controllerId;

        const item = scope.Pool || scope.VirtualService || scope.ServiceEngine;

        //can have no Item at controller and applcation pages
        if (item) {
            item.addLoad(['health', 'alert', 'faults']);
        }

        scope.collection = new AlertCollection({
            params: {
                'event_pages.contains': eventsContext(),
            },
        });

        //default is expected for controller only. Vulnerable! @am
        if (slug && slug !== 'default') {
            scope.collection.setParams({ 'related_refs.contains': slug });
        }

        scope.gridConfig = alertGridConfig(scope.collection, { defaultSorting: params.orderby });

        scope.collection.on('collectionLoadSuccess', function() {
            params.orderby = scope.collection.getSorting();
            params.updateStParamsAndURI();
        });

        Timeframe.on('change', scope.ui.onTimeframeChange);

        myAccount.on(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE, scope.ui.fetch);
    };

    function UI() {
        const fetch = function() {
            scope.fetchAlerts();
        };

        this.fetch = fetch;

        this.start = () => scope.params.start;
        this.end = () => scope.params.end;

        this.forceRefresh = function() {
            scope.params.updateTimeframe(/*scope.params.timeframe, scope.params.start*/);
            fetch();
        };

        this.onTimeframeChange = function() {
            const val = Timeframe.selected().key;

            if (val !== scope.params.timeframe) {
                scope.params.updateTimeframe(val);
                scope.fetchAlerts();
            }
        };
    }

    //used by layout components
    scope.ui = new UI();

    /* content load */
    scope.fetchAlerts = function(initial) {
        scope.collection.setParams({ ts_duration: scope.params.duration });

        if (!initial) { //as initial is made by collection-grid
            scope.collection.load(undefined, true);
        }
    };

    scope.setInitialState();
    scope.fetchAlerts(true);

    scope.$on('$destroy', () => {
        const item = scope.Pool || scope.VirtualService || scope.ServiceEngine;

        if (item) {
            item.async.stop(true);
        }

        scope.collection.destroy();

        Timeframe.unbind('change', scope.ui.onTimeframeChange);

        myAccount.unbind(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE, scope.ui.fetch);
    });
}]);
