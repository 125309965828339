/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name CloudStatusModalController
 */
angular.module('aviApp').controller('CloudStatusModalController', CloudStatusModalController);

CloudStatusModalController.$inject = ['cloudService', 'Schema', '$scope'];

/**
 * Modal cloud status details controller.
 * @param {CloudService} cloudService
 * @param {Object} Schema
 * @param {angular.$scope} $scope
 * @constructor
 */
function CloudStatusModalController(cloudService, Schema, $scope) {
    /**
     * @type {CloudService}
     */
    this.cloudService = cloudService;
    /**
     * @type {Object}
     */
    this.Schema = Schema;
    /**
     * @type {Object}
     */
    this.hostDiagFields = Schema.pb.VcenterHostDiag.fields;
    /**
     * @type {Object}
     */
    this.networkDiagFields = Schema.pb.VcenterMgmtNwDiag.fields;

    $scope.$parent.modalScope = $scope;
}
