/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name SeThroughputByVsMetric
 * @description
 *
 *     Fetches 'l4_client.avg_bandwidth' for all VSes driven by particular SE.
 *
 *     List of VSes we fetch is defined on the initialization phase and on response processing
 *     as well. Each VS has it's own series.
 *
 *     Item is ServiceEngine, but series returned belong to VSes.
 *
 */
angular.module('aviApp').service('SeThroughputByVsMetric', ['CollMetric',
function(CollMetric) {
    return class SeThroughputByVsMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;
            args.staticSeriesList = false;
            args.seriesId = args.seriesId || 'l4_client.avg_bandwidth';

            super(args);
        }

        /** @override */
        requestConfig() {
            return {
                id: this.name,
                metric_id: this.seriesId_,
                entity_uuid: '*',
                serviceengine_uuid: this.item.id,
                include_refs: true, //need this to get VS name in response
            };
        }

        /**
         * ItemId (which is actually VS id) is the short series id string presentation.
         * @override
         */
        seriesIdToConfig_(itemId) {
            return {
                itemId,
                seriesId: this.seriesId_,
            };
        }

        /** @override */
        getSeriesConfigBySeriesHeader_(header) {
            const config = super.getSeriesConfigBySeriesHeader_(header);

            config['title'] = header['entity_ref'].name();

            return config;
        }
    };
}]);
