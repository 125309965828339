/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  WafPolicyPsmGroupCollection
 * @description  Collection of {WafPolicyPsmGroup} items.
 */
const wafPolicyPsmGroupCollectionFactory = (Collection, WafPolicyPsmGroup) => {
    const allDataSources = {
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'waf_psmgroup.sum_flagged',
                'waf_psmgroup.sum_evaluated',
            ],
            dependsOn: 'config',
        },
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    class WafPolicyPsmGroupCollection extends Collection {
        constructor(args) {
            const extendedArgs = {
                ...args,
                permissionName: 'PERMISSION_WAFPOLICYPSMGROUP',
            };

            super(extendedArgs);
        }
    }

    Object.assign(WafPolicyPsmGroupCollection.prototype, {
        objectName_: 'wafpolicypsmgroup',
        itemClass_: WafPolicyPsmGroup,
        windowElement_: 'waf-policy-psm-group-modal',
        allDataSources_: allDataSources,
    });

    return WafPolicyPsmGroupCollection;
};

wafPolicyPsmGroupCollectionFactory.$inject = [
    'Collection',
    'WafPolicyPsmGroup',
];

angular
    .module('waf.vantage.avi')
    .factory('WafPolicyPsmGroupCollection', wafPolicyPsmGroupCollectionFactory);
