/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import {
    USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE,
} from '../../constants/my-account.constant';

angular.module('aviApp').controller('MyAccountController', MyAccountController);

MyAccountController.$inject = [
    '$scope',
    'myAccount',
    'AviModal',
    '$state',
    '$stateParams',
    'AviMessage',
];

/**
 * My Account controller constructor.
 * @param {angular.$scope} $scope
 * @param {MyAccount} myAccount
 * @param {AviModal} AviModal
 * @param {$StateProvider} $state
 * @param {Object} $stateParams
 * @constructor
 */
function MyAccountController($scope, myAccount, AviModal, $state, $stateParams, AviMessage) {
    $scope.$parent.modalScope = $scope;

    /**
     * @type {MyAccount}
     */
    this.myAccount = myAccount;
    this.myAccount.load();

    /**
     * @type {AviModal}
     */
    this.AviModal = AviModal;

    /**
     * @type {AviMessage}
     */
    this.AviMessage = AviMessage;

    /**
     * @type {boolean}
     */
    this.busy = false;

    /**
     * @type {string}
     */
    this.errors = '';

    /**
     * @type {$StateProvider}
     */
    this.$state = $state;

    /**
     * @type {Object}
     */
    this.$stateParams = $stateParams;

    /**
     * @type {function(this:MyAccountController)}
     */
    this.errorHandler = this.errorHandler.bind(this);

    myAccount.getControllerProperties();
}

/**
 * Handles server API errors.
 * @param {Object} response
 */
MyAccountController.prototype.errorHandler = function(response) {
    this.errors = response.data.error;
    this.busy = false;
};

/**
 * Saves user preferences and account data to server.
 */
MyAccountController.prototype.save = function() {
    const { myAccount } = this;

    this.busy = true;
    this.errors = '';

    myAccount.saveAccount().then(() => {
        if (myAccount.uiProperty.useUTCTime !== myAccount.uiPropertyClone.useUTCTime) {
            myAccount.trigger(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE);
        }

        myAccount.saveUIProperty();
        this.$stateParams.timeframe = myAccount.uiProperty.defaultTimeframe;
        this.close();
    }, this.errorHandler);

    myAccount.saveControllerProperties();
};

/**
 * Cancels and reverts any changes.
 */
MyAccountController.prototype.cancel = function() {
    this.myAccount.revertChanges();
    this.close();
};

/**
 * Dismisses all modal windows.
 */
MyAccountController.prototype.close = function() {
    this.busy = false;
    this.errors = '';
    this.AviMessage.destroyAll();
    this.AviModal.destroyAll();
};
