/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('AuthProfileCollection', ['Collection', 'AuthProfile',
function(Collection, AuthProfile) {
    function AuthProfileCollection(oArgs) {
        AuthProfileCollection.superconstructor.call(this, oArgs);
    }

    avi.inherit(AuthProfileCollection, Collection);

    AuthProfileCollection.prototype.objectName_ = 'authprofile';
    AuthProfileCollection.prototype.itemClass_ = AuthProfile;
    AuthProfileCollection.prototype.windowElement_ = 'prof-auth-profile-create';

    return AuthProfileCollection;
}]);
