/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name ClusterListController
 */
angular.module('aviApp').controller('ClusterListController', [
'$scope', 'ClusterNodeCollection', 'Cluster',
function($scope, ClusterNodeCollection, Cluster) {
    const collection = new ClusterNodeCollection();

    $scope.gridConfig = {
        collection,
        fields: [{
            require: 'config',
            name: 'name',
            title: 'Name',
            template: '{{row.data.config.name}}',

        }, {
            name: 'management_ip',
            title: 'Management Hostnames',
            template: '{{row.data.config.ip}}',
        }, {
            name: 'virtual_ip',
            title: 'Cluster IP',
            template: '{{row.data.config.cluster_ip}}',
        }, {
            name: 'role',
            title: 'Role',
            template: '{{row.data.runtime.role.enumeration("CLUSTER_")}}',
        }, {
            name: 'state',
            title: 'State',
            require: 'runtime',
            template: '<span ng-if="row.data.runtime"><i class="icon-circle" ' +
                'ng-class="row.data.runtime.state === \'CLUSTER_ACTIVE\' ? \'active\' :' +
                '\'inactive\'"></i>{{row.data.runtime.state.enumeration("CLUSTER_")}}</span>' +
                '<span avi-loader ng-if="!row.data.runtime"></span>',
        }],
        layout: {
            hideSearch: true,
            hideEditColumns: true,
            lengthAsTotal: true,
        },
    };

    //reload collection on Cluster successful save event
    $scope.Cluster = new Cluster({
        id: 'default', //fake Id
        bind: {
            itemSaveSuccess() {
                collection.load();
            },
        },
    });

    $scope.$on('$destroy', () => {
        collection.destroy();
        $scope.Cluster.destroy();
    });
}]);
