/**************************************************************************
 *
 * AVI CONFIDENTIAL

 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * Module for licensing.
 * @module avi/licensing
 */

/**
 * Fires upon successful license upload.
 * @event module:avi/licensing#UPDATE_LICENSE_SUMMARY
 * @see module:avi/licensing#UPDATE_LICENSE_SUMMARY
 */

const componentSelector = 'licensing-page';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing.licensingPageComponentBindings
 * @see {@link module:avi/licensing.licensingPageComponent licensingPageComponent}
 */
class LicensingPageController {
    /**
     * Event name for license upload success.
     * @type {string}
     */
    static UPDATE_LICENSE_SUMMARY = 'UPDATE_LICENSE_SUMMARY';

    constructor(
        $scope,
        $q,
        Auth,
        defaultValues,
        schemaService,
        dropDownUtils,
        licensingService,
    ) {
        this.$scope = $scope;
        this.$q = $q;
        this.Auth = Auth;
        this.defaultValues = defaultValues;
        this.schemaService = schemaService;
        this.dropDownUtils = dropDownUtils;

        /**
         * @type {module:avi/licensing.licensingService}
         * @protected
         */
        this.licensingService_ = licensingService;

        /**
         * UI properties.
         * @type {Object}
         */
        this.ui = {
            busy: true,
            errors: null,
        };

        this.setGridConfig_();
        this.loadGridData_();
        this.createLicenseTierDropdownOptions_();
    }

    setGridConfig_() {
        const fields = [{
            name: 'license_name',
            title: 'Description',
        }, {
            name: 'license_id',
            title: 'License ID',
        }, {
            name: 'license_type',
            title: 'Type',
        }, {
            name: 'tier',
            title: 'Tier',
            template: '{{ row.tier_type | enum }}',
        }, {
            name: 'cores',
            title: 'SE vCPUs',
            template: '{{row.cores || 0}}',
        }, {
            name: 'sockets',
            title: 'SE Sockets',
            template: '{{row.sockets || 0}}',
        }, {
            name: 'max_ses',
            title: 'Hosts',
            template: '{{row.max_ses || 0}}',
        }, {
            name: 'start_on',
            title: 'Start Date',
            transform: license => this.startOn_(license).format('ll'),
        }, {
            name: 'valid_until',
            title: 'Expiry',
            transform: license => {
                if (this.validUntil_(license).isBefore()) {
                    return 'Expired';
                }

                return this.validUntil_(license).format('ll');
            },
        }];

        const multipleActions = [{
            title: 'Delete',
            do: rows => {
                this.ui.busy = true;
                this.resetErrors_();

                this.deleteLicenses(rows)
                    .then(() => {
                        this.reload_();
                    })
                    .catch(this.handleLoadError_.bind(this))
                    .finally(this.handleLoadFinally_.bind(this));

                return true;
            },
            disabled: rows => {
                let disabled = this.ui.busy;

                if (!disabled) {
                    disabled = rows.length === 1 && rows[0].license_id === 'Trial';
                }

                return disabled;
            },
        }];

        /**
         * Configures grid directive
         * @type {object}
         */
        this.gridConfig = {
            fields,
            rowId: 'license_id',
            layout: {
                hideSearch: true,
            },
            searchFields: ['license_name', 'license_id', 'license_type'],
            rowClass: license => {
                return this.validUntil_(license).isBefore() ? 'disabled' : '';
            },
            checkboxDisable: row => row.license_id === 'Trial',
            multipleactions: multipleActions,
        };
    }

    createLicenseTierDropdownOptions_() {
        const enumVals = this.schemaService.getEnumValues('LicenseTierType');

        this.licenseTierDropdownOptions = enumVals.map(({ value, label, description }) => {
            return this.dropDownUtils.createOption(value, label, description);
        });
    }

    /**
     * Updates page's tables.
     * @fires module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @protected
     */
    reload_ = () => {
        this.resetErrors_();
        this.loadGridData_();
        this.$scope.$broadcast(LicensingPageController.UPDATE_LICENSE_SUMMARY);
    };

    /**
     * Fires after a license is uploaded.
     */
    reloadLicenseGrid() {
        this.reload_();
    }

    /**
     * Updates data for bottom License table.
     * @return {Promise<any>}
     * @protected
     */
    loadGridData_() {
        this.ui.busy = true;

        return this.licensingService_.loadLicenseList()
            .then(licenseList => this.data = licenseList)
            .catch(this.handleLoadError_.bind(this))
            .finally(this.handleLoadFinally_.bind(this));
    }

    /**
     * @param {Object} rsp
     * @protected
     */
    handleLoadError_(rsp) {
        this.ui.errors = rsp.data;
    }

    /**
     * @protected
     */
    handleLoadFinally_() {
        this.ui.busy = false;
    }

    /**
     * @protected
     */
    resetErrors_() {
        this.ui.errors = null;
    }

    licenseTierChange() {
        this.systemConfig
            .save()
            .then(() => this.defaultValues.load(true))
            .catch(() => this.systemConfig.load());
    }

    /**
     * Returns date of license expiration.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    validUntil_(license) {
        return moment(license.valid_until).add(this.Auth.initData.timeDifference, 'seconds');
    }

    /**
     * Returns start date.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    startOn_(license) {
        return moment(license.start_on).add(this.Auth.initData.timeDifference, 'seconds');
    }

    /**
     * Deletes passed license(s).
     * @param {Object[]} rows
     * @returns {ng.$q.promise} To be resolved with list of Response objects.
     */
    deleteLicenses(rows) {
        const deletePromises =
            rows.map(({ license_id: licenseId }) =>
                this.licensingService_.deleteLicense(licenseId));

        return this.$q.all(deletePromises);
    }
}

LicensingPageController.$inject = [
    '$scope',
    '$q',
    'Auth',
    'defaultValues',
    'schemaService',
    'dropDownUtils',
    'licensingService',
];

/**
 * @name licensingPageComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.licensingPageComponentBindings} bindings
 * @property {module:avi/licensing.LicensingPageController} controller
 * @description System>Settings>Licensing page component.
 * @author Akul Aggarwal, Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licensingPage', {
    /**
     * @mixin licensingPageComponentBindings
     * @memberOf module:avi/licensing
     * @property {SystemConfig} systemConfig
     */
    bindings: {
        systemConfig: '<loadedSystemConfigService',
    },
    controller: LicensingPageController,
    templateUrl: 'src/components/pages/administration/settings/' +
        `${componentSelector}/${componentSelector}.component.html`,
});

export { LicensingPageController };
