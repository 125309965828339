/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name VsWafTopHits
 * @author Alex Malitsky
 * @description
 *
 *     Keeps a hash of top hits lists.
 *     Each such list has aggregated log records {@link VsWafTopHitEntry} as well as numeric
 *     'count' property showing how many entries are present in the whole list.
 */

/**
 * @typedef {Object} VsWafTopHitEntry
 * @property {string} type - One of group/rule/tag/clientIp/matchElement/combination.
 * @property {string} name
 * @property {number} count
 * @property {{string: *}} filters - Hash of log record property names and their values this
 * record represents.
 */
angular.module('aviApp').factory('VsWafTopHits', [
'UpdatableBase', function(UpdatableBase) {
    class VsWafTopHits extends UpdatableBase {
        constructor(args) {
            angular.extend(args, {
                isStatic: true,
                defaultDataSources: 'config',
                allDataSources: {
                    config: {
                        source: 'VsWafTopHitsDataSource',
                        transformer: 'VsWafTopHitsDataTransformer',
                        transport: 'VsWafTopHitsDataTransport',
                        fields: [//top hits types
                            'group',
                            'rule',
                            'tag',
                            'clientIp',
                            'path',
                            'matchElement',
                        ],
                    },
                },
            });

            super(args);

            this.vsId = args.vsId;

            this.groups = {};
        }

        /** @override */
        emptyData() {
            super.emptyData();
            this.groups = {};
        }

        /**
         * Checks whether at least one group has entries. If no we assume there are no WAF logs
         * for the selected timeframe.
         * @returns {boolean}
         * @public
         */
        isEmpty() {
            return !_.any(this.groups, ({ count }) => count);
        }

        /**
         * Returns a top list by it's type.
         * @param {VsWafTopHitEntry.type} listType
         * @returns {Object|null}
         * @public
         */
        getListByType(listType) {
            return this.groups[listType] || null;
        }

        /**
         * Sets corresponding filters on instance.
         * @param {{string: *}} filters
         * @public
         */
        setFilter(filters) {
            const dataSource = this.getDataSourceByFieldName('config');

            dataSource.setFilters(filters);
        }

        /**
         * Checks whether passed filter object is active.
         * @param {{string: *}} filters
         * @returns {boolean}
         * @public
         */
        isFilterActive(filters) {
            const dataSource = this.getDataSourceByFieldName('config');

            return dataSource.isFilterActive(filters);
        }

        /**
         * Returns a hash of active filters set on instance.
         * @returns {{string: *}}
         */
        getActiveFiltersHash() {
            const dataSource = this.getDataSourceByFieldName('config');

            return dataSource.getActiveFiltersHash();
        }

        /**
         * Returns true if no filters were set by user.
         * @returns {boolean}
         * @public
         */
        noActiveFilters() {
            return _.isEmpty(this.getActiveFiltersHash());
        }

        /**
         * Removes all filters set by user.
         * @public
         */
        removeFilters() {
            const dataSource = this.getDataSourceByFieldName('config');

            dataSource.removeFilters();
        }
    }

    return VsWafTopHits;
}]);
