/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('GraphSync', ['Base', function(Base) {
    // Inherit from base to allow event listeners.
    const graphSync = new Base();

    graphSync.sync = _.throttle(function(mouse) {
        this.mouseOnGraph = mouse;
        this.trigger('GraphSync mouseOnGraph', this.mouseOnGraph);
    }, 16);

    graphSync.hide = function() {
        this.mouseOnGraph = false;
        this.trigger('GraphSync hide', this.mouseOnGraph);
    };

    graphSync.toggle = function() {
        this.stuck = !this.stuck;
        this.trigger('GraphSync toggle', this.mouseOnGraph);
    };

    /**
     * Returns unix timestamp when mouse is hovering over chart, false otherwise.
     * @returns {boolean|number}
     * @public
     */
    graphSync.mouseIsOnGraph = function() {
        return this.mouseOnGraph;
    };

    return graphSync;
}]);
