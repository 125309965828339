/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const repeatedMessageItemFactory = MessageBase => {
    /**
     * @alias module:services/RepeatedMessageItem
     * @private
     */
    class RepeatedMessageItem extends MessageBase {
        /**
         * @override
         */
        get emptyConfig_() { //eslint-disable-line class-methods-use-this
            return [];
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return [];
        }

        /**
         * @override
         */
        updateConfig(newConfigs = [], skipDataTransformation = false) {
            this.data.config = newConfigs.map(config => {
                const newConfigItem = this.parent_.createChildByField_(
                    this.fieldName_,
                    config,
                    true,
                );

                // If getIndex doesn't return anything then we just use the newly created
                // MessageItem.
                const newIndex = newConfigItem.getIndex();
                const hasIndex = !_.isUndefined(newIndex);

                if (hasIndex) {
                    const existingConfigItem = this.config.find(configItem => {
                        return configItem.getIndex() === newIndex;
                    });

                    if (existingConfigItem) {
                        existingConfigItem.updateConfig(config, skipDataTransformation);

                        return existingConfigItem;
                    }
                }

                return newConfigItem;
            });
        }

        /**
         * Calls and returns the result of flattenConfig on every child MessageItem.
         * @param {boolean} [bypassCheck=] - True to bypass the canFlatten check, used for cloning.
         * @returns {Object[]|undefined}
         * @protected
         */
        flattenConfig_(bypassCheck = false) {
            return RepeatedMessageItem.removeEmptyRepeated_(
                this.config.map(configItem => configItem.flattenConfig_(bypassCheck)),
            );
        }

        /**
         * Calls and returns the result of getDataToSave on every child MessageItem.
         * @returns {Object[]|undefined}
         */
        getDataToSave() {
            return RepeatedMessageItem.removeEmptyRepeated_(
                this.config.map(configItem => configItem.getDataToSave()),
            );
        }

        /**
         * Return undefined if the list is empty.
         * @param {Object[]} [configs=[]]
         * @returns {Object[]|undefined}
         * @protected
         */
        static removeEmptyRepeated_(configs = []) {
            return configs.length ? configs : undefined;
        }

        /**
         * Adds an entry to the config.
         * @param {MessageBase|Object} config - MessageItem or config to add to the list.
         */
        add(config) {
            const configItem = config instanceof MessageBase ?
                config :
                this.parent_.createChildByField_(this.fieldName_, config, true);

            this.config.push(configItem);
        }

        /**
         * Removes an entry from the config.
         * @param {number} [index=0] - Index to remove.
         */
        remove(index = 0) {
            this.config.splice(index, 1);
        }

        /**
         * Clears all entries from the config.
         */
        removeAll() {
            this.config.length = 0;
        }

        /**
         * Returns the MessageItem at the index.
         * @param {number} index - Index of the MessageItem.
         */
        at(index = 0) {
            return this.config[index];
        }

        /**
         * Returns the array index based on the 'index' property.
         * @param {number} index - Index corresponding to the 'index' property, not the index within
         *     the array.
         * @returns {number}
         */
        getArrayIndexWithIndexField(index) {
            const { config } = this;
            const arrayIndex = config.findIndex(configItems => configItems.getIndex() === index);

            return arrayIndex > -1 ? arrayIndex : NaN;
        }

        get count() {
            return this.config.length;
        }

        /**
         * Returns true if the config does not contain any MessageItems.
         * @returns {boolean}
         */
        isEmpty() {
            return this.count === 0;
        }

        /**
         * Calls a method on each of the child MessageItems.
         * @param {string} methodName - Name of the method to call.
         * @protected
         */
        recursiveConfigItemCall_(methodName) {
            this.config.forEach(configItem => configItem.recursiveConfigItemCall_(methodName));
        }

        /**
         * Returns the highest existing 'index' property number, or NaN if none.
         * @returns {number}
         */
        getMaxIndex() {
            const maxIndexConfigItem = _.max(this.config, configItem => configItem.getIndex());

            return !_.isEmpty(maxIndexConfigItem) ? maxIndexConfigItem.getIndex() : NaN;
        }

        /**
         * Moves item to a new index. All items in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the item.
         * @param {number} newIndex - Index of the new position.
         */
        moveItem(oldIndex, newIndex) {
            let newIndexCounter = newIndex;
            /**
             * newIndex moves towards the direction of oldIndex
             */
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndexCounter) {
                this.swapItem(oldIndex, newIndexCounter);
                newIndexCounter += increment;
            }
        }

        /**
         * Given two indices of items, swaps positions in the config along with the index property
         * in the item.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapItem(oldIndex, newIndex) {
            const { config } = this;

            const oldItem = this.at(oldIndex);
            const newItem = this.at(newIndex);

            config[oldIndex] = newItem;
            config[newIndex] = oldItem;

            /**
             * Actual 'index' property of the item.
             */
            const oldIndexValue = oldItem.getIndex();
            const newIndexValue = newItem.getIndex();

            oldItem.setIndex(newIndexValue);
            newItem.setIndex(oldIndexValue);
        }
    }

    return RepeatedMessageItem;
};

repeatedMessageItemFactory.$inject = [
    'MessageBase',
];

/**
 * @ngdoc factory
 * @name RepeatedMessageItem
 * @description
 *     This is a unique type of MessageItem that simply acts as a layer between a MessageItem and
 *     repeated MessageItem children. Each of the children's parent is still the parent MessageItem,
 *     and this is just responsible for managing the array of MessageItem children, such as adding
 *     or removing.
 *
 *     The RepeatedMessageItem instance gets instantiated in every MessageItem config even if its
 *     data doesn't exist. This is so that when adding child MessageItems we can always assume that
 *     this is available. When before the save request is made the array is empty, the property is
 *     simply removed.
 * @module services/RepeatedMessageItem
 * @author alextsg
 */
angular.module('core.vantage.avi').factory('RepeatedMessageItem', repeatedMessageItemFactory);
