/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.dropdownListComponentBindings
 */
class DropdownListComponentController {
    constructor($scope, $element, $compile) {
        this.$scope_ = $scope;
        this.$element_ = $element;
        this.$compile_ = $compile;
    }

    /** @override */
    $onInit() {
        const template = this.getTemplate_();

        this.$compile_(template)(this.$scope_)
            .appendTo(this.$element_);
    }

    /**
     * Generate list template.
     * @return {string}
     * @protected
     */
    getTemplate_() {
        const defaultOptionComponentName = 'dropdownListOption';

        let { optionComponentName } = this;

        optionComponentName = optionComponentName || defaultOptionComponentName;

        const optionComponentTagName = optionComponentName.camelCaseToDash();

        const template =
            `<${optionComponentTagName}
                ng-repeat="option in $ctrl.options track by option.value"
                option="option"
                on-click="$ctrl.onSelect({ value })"
            ></${optionComponentTagName}>`;

        return template;
    }
}

DropdownListComponentController.$inject = [
    '$scope',
    '$element',
    '$compile',
];

/**
 * @mixin dropdownListComponentBindings
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.DropdownListOption[]} options - Options to select on.
 * @property {Function} onSelect - Function to be called with value.
 * @property {string=} optionComponentName - The custom component name(in camel case) used for
 *     options. Default component will be used if this is not present.
 */

/**
 * @name DropdownListComponent
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.dropdownListComponentBindings} bindings
 * @property {module:avi/component-kit.DropdownListComponentController} controller
 * @description
 *
 *     Component for list of options primarily used by dropdown menu.
 *
 * @author Zhiqian Liu
 */
angular.module('avi/component-kit').component('dropdownList', {
    bindings: {
        options: '<',
        onSelect: '&',
        optionComponentName: '@?',
    },
    controller: DropdownListComponentController,
});
