/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AlertConfigCollection
 */
angular.module('aviApp').factory('AlertConfigCollection', ['Collection', 'AlertConfig',
function(Collection, AlertConfig) {
    const AlertConfigCollection = function(oArgs) {
        AlertConfigCollection.superconstructor.call(this, oArgs);
    };

    avi.inherit(AlertConfigCollection, Collection);

    AlertConfigCollection.prototype.objectName_ = 'alertconfig';
    AlertConfigCollection.prototype.itemClass_ = AlertConfig;
    AlertConfigCollection.prototype.windowElement_ = 'adm-alertconfig-create';

    AlertConfigCollection.prototype.serverDefaultsOverride_ = {
        alert_filter: [],
    };

    return AlertConfigCollection;
}]);
