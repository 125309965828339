/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('Backup', ['Item', '$window', (Item, $window) => {
    const Backup = class extends Item {
        /**
         * Makes request to download backup configuration file.
         * @param  {string} type - Used to specify local backup or remote backup. Local by default.
         */
        downloadBackup(type) {
            let location = this.data.config.local_file_url;

            if (type === 'remote') {
                location = this.data.config.remote_file_url;
            }

            $window.location.assign(`api/fileservice?uri=${location}`);
        }
    };

    angular.extend(Backup.prototype, {
        objectName: 'backup',
        windowElement: 'backup-create',
    });

    return Backup;
}]);
