/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name MsMapGraphChartHighlightFactory
 * @description Provides a highlighting functionality for the group of immediate neighbour Nodes
 * on the {@link msMapGraphChart Microservice Map Graph Chart}.
 */

/**
 * Node object of the MsMapGraphChartHighlight service. Every node keeps two hashes for nodes and
 * links, which have direct connections with this one. UUID of original object is represented by
 * a key in another hash object where objects of this type are stored.
 * @typedef {Object} MsMapGraphChartHighlightNode
 * @property {{string:boolean}} nodes - String key is a UUID of node having direct connection with
 *     this one.
 * @property {{string:boolean}} links - String key is an ID of link directly connected with this
 *     node.
 */

angular.module('aviApp').factory('MsMapGraphChartHighlightFactory', function() {
    const HighlightFactory = function(args) {
        /**
         * Keeps the UUID of selected (hovered) node, which is the "root" node of highlighted group.
         * @type {string}
         * @protected
         */
        this.selectedNodeUuid_ = '';

        /**
         * SVG element where all chart nodes and links svg elements can be found.
         * @type {SVGElement}
         */
        this.canvas_ = args && args.canvas;

        /**
         * @type {{string:MsMapGraphChartHighlightNode}} - Where string is a Node's UUID.
         * @protected
         */
        this.graph_ = {};

        /**
         * Disabled flag to restrict drawing in special cases, like during drag event.
         * @type {boolean}
         * @protected
         */
        this.tempDisabled_ = false;

        if (!this.canvas_) {
            throw new Error('Can\'t instantiate an instance of HighlightFactory without svg' +
                ' element as a canvas.');
        }
    };

    /**
     * Updates the {@link MsMapGraphChartHighlightFactory#graph_} property. After update
     * clears highlighting or draws it depending on the service activity state.
     * @param {MSGraphEdge[]} links
     * @public
     */
    HighlightFactory.prototype.updateGraph = function(links) {
        this.graph_ = {};

        _.each(links, function(link) {
            //each link has target and source nodes, that is why we need another _each
            _.each(link.name, function(nodeId, key, twoNodes) {
                if (!(nodeId in this.graph_)) {
                    this.graph_[nodeId] = {
                        links: {},
                        nodes: {},
                    };
                    this.graph_[nodeId].nodes[twoNodes[0]] = true;
                    this.graph_[nodeId].nodes[twoNodes[1]] = true;
                } else {
                    //since we already have node itself in a list of immediate neighbours, we
                    // need to push only it's counterpart
                    this.graph_[nodeId].nodes[
                        twoNodes[0] === nodeId ? twoNodes[1] : twoNodes[0]
                    ] = true;
                }

                this.graph_[nodeId].links[link.id] = true;
            }, this);
        }, this);

        if (this.isActive()) {
            if (!(this.selectedNodeUuid_ in this.graph_)) {
                this.clear();
            } else {
                this.draw();
            }
        }
    };

    /**
     * Checks whether provided node has an immediate link with
     * {@link MsMapGraphChartHighlightFactory#selectedNodeUuid_ | selected (hovered) node}.
     * @param {MSGraphNode} node
     * @returns {boolean}
     * @protected
     */
    HighlightFactory.prototype.isNodeWithinGroup_ = function(node) {
        return node.uuid === this.selectedNodeUuid_ ||
            this.graph_[this.selectedNodeUuid_] &&
            node.uuid in this.graph_[this.selectedNodeUuid_].nodes || false;
    };

    /**
     * Checks whether provided link has an immediate connection with
     * {@link MsMapGraphChartHighlightFactory#selectedNodeUuid_ | selected (hovered) node}.
     * @param {MSGraphEdge} link
     * @returns {boolean}
     * @protected
     */
    HighlightFactory.prototype.isLinkWithinGroup_ = function(link) {
        return this.graph_[this.selectedNodeUuid_] &&
            link.id in this.graph_[this.selectedNodeUuid_].links || false;
    };

    /**
     * Disables highlighting functionality and removes the current highlighting.
     * @public
     */
    HighlightFactory.prototype.disable = function() {
        this.clear();
        this.tempDisabled_ = true;
    };

    /**
     * Enables highlighting functionality and redraws layout when selected Node UUID is passed.
     * @param {string=} selectedNodeUuid - When provided will call
     *     {@link MsMapGraphChartHighlightFactory#draw} with that node uuid as selected.
     * @public
     */
    HighlightFactory.prototype.enable = function(selectedNodeUuid) {
        this.tempDisabled_ = false;

        if (selectedNodeUuid) {
            this.draw(selectedNodeUuid);
        }
    };

    /**
     * Checks if highlight service is enabled.
     * @returns {boolean}
     * @public
     */
    HighlightFactory.prototype.isEnabled = function() {
        return !this.tempDisabled_;
    };

    /**
     * Checks if highlight service is active, meaning that we have selected "root" node and
     * highlighting is supposed to be present on the user's screen.
     * @returns {boolean}
     * @public
     */
    HighlightFactory.prototype.isActive = function() {
        return !!this.selectedNodeUuid_;
    };

    /**
     * Applies highlighting classes over the links and nodes present on the
     * {@link MsMapGraphChartHighlightFactory#canvas_ canvas}.
     * @param {string=} hoveredNodeUuid
     * @public
     */
    HighlightFactory.prototype.draw = function(hoveredNodeUuid) {
        if (hoveredNodeUuid) {
            this.selectedNodeUuid_ = hoveredNodeUuid;
        }

        if (this.isEnabled() && this.isActive()) {
            this.canvas_.selectAll('g.node')
                .classed('covered', function(n) {
                    return !this.isNodeWithinGroup_(n);
                }.bind(this));

            this.canvas_.selectAll('g.link')
                .classed('covered', function(l) {
                    return !this.isLinkWithinGroup_(l);
                }.bind(this));
        }
    };

    /**
     * Removes the highlighting classes from the canvas if service is in active state.
     * @public
     */
    HighlightFactory.prototype.clear = function() {
        if (this.isActive()) {
            this.selectedNodeUuid_ = '';
            this.canvas_.selectAll('g.node.covered, g.link.covered')
                .classed('covered', false);
        }
    };

    return HighlightFactory;
});
