/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name cloudAwsEncryption
 * @param {string} type - s3/ebs/sqs.
 * @param {Cloud} cloud - Cloud reference.
 * @param {Object} config - "${type}_encryption" sub-object of cloud configuration.
 *     AwsEncryption protobuf type.
 * @description
 *
 *     Encryption settings for AWS cloud.
 */
const settings = {
    s3: {
        checkboxLabel: 'SE S3 Bucket',
    },
    ebs: {
        checkboxLabel: 'SE AMI/EBS volumes',
    },
    sqs: {
        checkboxLabel: 'SQS Queues',
    },
};

const MODE_SSE_KMS = 'AWS_ENCRYPTION_MODE_SSE_KMS';
const MODE_NONE = 'AWS_ENCRYPTION_MODE_NONE';

class CloudAwsEncryptionController {
    constructor(Schema) {
        this.pb = Schema.pb;
        this.keyLabel = 'AWS KMS Master Key ARN ID';
        this.MODE_SSE_KMS = MODE_SSE_KMS;
        this.MODE_NONE = MODE_NONE;
        this.keys = [];
    }

    $onInit() {
        const {
            config,
            type,
        } = this;

        if (config.mode !== MODE_NONE) {
            this.loadKeys();
        }

        this.configPropName = `${type}_encryption`;
        this.checkboxLabel = `Use Encryption for ${settings[type].checkboxLabel}`;
    }

    /**
     * Populates list of keys making an API call through {@link Cloud.loadAwsEncryptionKeys}.
     * @public
     */
    loadKeys() {
        this.busy = true;
        this.keys.length = 0;

        this.cloud.loadAwsEncryptionKeys(this.type)
            .then(keys => this.keysOnLoad_(keys))
            .finally(() => this.busy = false);
    }

    /**
     * Puts received keys into this.keys and resets config['master_key'] if not found.
     * @param {Object[]} keysList
     * @protected
     */
    keysOnLoad_(keysList) {
        const {
            keys,
            config,
        } = this;

        keys.length = 0;
        keys.push(...keysList);

        const { master_key: masterKey } = config;

        if (masterKey && !_.findWhere(keys, { arn: masterKey })) {
            config['master_key'] = undefined;
        }
    }

    /**
     * Removes config['master_key'] and calls for keys if mode is not set to NONE.
     * @public
     */
    onModeChange() {
        const {
            config,
            keys,
        } = this;

        config['master_key'] = undefined;

        if (config.mode !== MODE_NONE) {
            this.loadKeys();
        } else {
            keys.length = 0;
        }
    }
}

CloudAwsEncryptionController.$inject = [
        'Schema',
];

angular.module('aviApp').component('cloudAwsEncryption', {
    bindings: {
        type: '@',
        config: '<',
        cloud: '<',
    },
    templateUrl: 'src/components/infrastructure/clouds/aws/' +
            'cloud-aws-encryption/cloud-aws-encryption.html',
    controller: CloudAwsEncryptionController,
});
