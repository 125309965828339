/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  VsVip
 * @description
 *     VsVip top-level Item that is configured as vsvip_ref_data in VirtualService#data#config.
 */
function vsVipFactory(Item, $q, VipConfig, DnsInfoConfig) {
    class VsVip extends Item {
        constructor(args) {
            super(args);

            this.data.config = angular.extend(
                this.getDefaultConfig_() || {},
                args && args.data && args.data.config,
            );

            const config = this.getConfig();

            if (angular.isArray(config['vip'])) {
                const cloudRef = config['cloud_ref'];

                config['vip'] = config['vip'].map(vipConfig => {
                    return vipConfig instanceof VipConfig ?
                        vipConfig :
                        new VipConfig({ data: { config: vipConfig, cloudRef } });
                });
            }

            if (angular.isArray(config['dns_info'])) {
                config['dns_info'] = config['dns_info'].map(dnsConfig => {
                    return dnsConfig instanceof DnsInfoConfig ?
                        dnsConfig :
                        new DnsInfoConfig({ data: { config: dnsConfig } });
                });
            }
        }

        /**
         * Returns the Vip object from the vip list based on the index passed in. Defaults to 0.
         * @param {number=} index - Index of the Vip object from the vip list.
         * @return {Object} - Vip object.
         */
        getVip(index) {
            return this.getVipList()[index];
        }

        /**
         * Returns a reference to the list of Vip objects current VsVip has.
         * @returns {Vip[]}
         * @public
         */
        getVipList() {
            return this.getConfig()['vip'];
        }

        /**
         * Sets the name of the VsVip object.
         * @param {string} name - Name to set.
         */
        setName(name) {
            const config = this.getConfig();

            config.name = config.name || name;
        }

        /**
         * The cloud_ref for a VsVip object can be set only once.
         * @param {string} cloudRef - Cloud ref url.
         */
        setCloudRef(cloudRef) {
            const config = this.getConfig();

            config.cloud_ref = cloudRef;
            this.getVipList()
                .forEach(vip => vip.setCloudRefOnData(cloudRef));
        }

        /**
         * The vrf_context_ref for a VsVip object can be set only once.
         * @param {string} vrfContextRef - VRF Context ref url.
         */
        setVrfContext(vrfContextRef) {
            const config = this.getConfig();

            config.vrf_context_ref = vrfContextRef;
        }

        /**
         * Adds a Vip object to the VirtualService vip list.
         * @param {string=} networkRef - Network ref to populate the next Vip with.
         * @param {string=} subnetUuid - Subnet uuid to populate the next Vip with.
         */
        addVip(networkRef, subnetUuid) {
            const vipList = this.getVipList();

            const id = vipList.reduce((acc, vip) => {
                return Math.max(acc, +vip.id || 0);
            }, 0) + 1;

            vipList.push(new VipConfig({ data: {
                config: {
                    vip_id: id, network_ref: networkRef, subnet_uuid: subnetUuid,
                },
            } }));
        }

        /**
         * Removes a Vip object from the VirtualService vip list based on the vip_id.
         * @param  {number} id - vip_id of the Vip to be removed.
         */
        removeVip(index) {
            this.getVipList().splice(index, 1);
        }

        /**
         * Returns an array of configured Vip addresses.
         * @return {string[]}
         */
        getVipAddresses() {
            const vipList = this.getVipList();
            const vips = [];

            vipList.forEach(vip => {
                const ipv4 = vip.getVipAddress();
                const ipv6 = vip.getIP6VipAddress();

                if (ipv4) {
                    vips.push(ipv4);
                }

                if (ipv6) {
                    vips.push(ipv6);
                }
            });

            return _.compact(vips);
        }

        /**
         * Returns a list of VIP ip address hashes.
         * @returns {Object[]}
         * @public
         */
        getVipAddressHashes() {
            return this.getVipList().map(vip => {
                const hash = vip.getVipAddressHash();

                hash.vip_id = vip.id;

                return hash;
            });
        }

        /**
         * Returns the DnsInfo object from the dns_info list based on the index passed in.
         * Defaults to 0.
         * @param {number=} index - Index of the dnsInfo object.
         * @param {Object} DnsInfo object.
         */
        getDnsInfo(index = 0) {
            const { dns_info: dnsInfoList } = this.getConfig();

            return dnsInfoList[index];
        }

        /**
         * Adds a default DnsInfo object to the dns_info list.
         */
        addDnsInfo() {
            const config = this.getConfig();

            config.dns_info.push(new DnsInfoConfig());
        }

        /**
         * Removes a DnsInfo object from the dns_info list.
         * @param {number=} index - Index of the dnsInfo object to remove.
         */
        removeDnsInfo(index = 0) {
            const { dns_info: dnsInfoList } = this.getConfig();

            dnsInfoList.splice(index, 1);
        }

        /**
         * Sets the fqdn field. If no index is passed in, sets config.fqdn. Otherwise, sets
         * config.dns_info[index].fqdn.
         * @param {string} fqdn - fqdn string.
         * @param {number=} index - Index of the dnsInfo object in the dns_info list.
         */
        setFqdn(fqdn, index = 0) {
            this.getDnsInfo(index).fqdn = fqdn;
        }

        /**
         * Returns an array of configured fqdns.
         * @return {string[]}
         */
        getFqdns() {
            const config = this.getConfig();

            if (config['dns_info']) {
                return _.compact(config['dns_info'].map(dnsInfo => dnsInfo.getFqdn()));
            }

            return [];
        }

        /**
         * Gets number of networks that are fip_capable.
         * @param  {string} networkRef - network_ref URL.
         * @return {ng.$q.promise}
         */
        _getFipNetworksCount(networkRef) {
            const api = '/api/networksubnetlist/?auto_allocate_only=true&fip_capable=true&' +
                `cloud_uuid=${this.getConfig().cloud_ref.slug()}&search=${networkRef.slug()}`;

            return this.request('GET', api)
                .then(({ data }) => data.count)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(data);
                });
        }

        /**
         * Sets the auto_allocate_floating_ip field on every Vip object. Also needs to verify
         * that the network_refs in every Vip object are fip_capable, by calling
         * this._getFipNetworksCount if auto_allocate_floating_ip is set to true.
         * If not, remove that network and subnet.
         * @param {boolean=} enabled - Boolean value to set auto_allocate_floating_ip to.
         * @return {ng.$q.promise}
         */
        setAutoAllocateFloatingIpForAllVips(enabled = false) {
            const vipList = this.getVipList();
            const promises = [];

            vipList.forEach(vip => {
                const vipConfig = vip.getConfig();

                vip.setAutoAllocateFloatingIp(enabled);
                vip.clearFloatingIp();

                if (enabled && vipConfig.network_ref) {
                    const promise = this._getFipNetworksCount(vipConfig.network_ref)
                        .then(count => {
                            if (!count) {
                                vip.clearVipNetwork();
                                vip.clearVipSubnet();
                                vip.setIpAddr(undefined);
                            }
                        });

                    promises.push(promise);
                }
            });

            this.busy = true;

            return $q.all(promises)
                .finally(() => this.busy = false);
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            if (!Array.isArray(config.vip)) {
                config.vip = [];
                this.addVip();
            }

            if (!Array.isArray(config.dns_info)) {
                config.dns_info = [];
                this.addDnsInfo();
            }

            config.vip.forEach(vip => vip.beforeEdit());
        }

        /**
         * @override
         */
        dataToSave() {
            const config = this.getConfig();

            if ('vip' in config) {
                /**
                 * Set all auto_allocate_floating_ip fields to match the first Vip object's.
                 */
                const vipList = this.getVipList();

                if (vipList.length > 1) {
                    const vip0 = this.getVip(0);
                    const { auto_allocate_floating_ip: isElastic } = vip0.getConfig();

                    vipList.forEach(vip => vip.setAutoAllocateFloatingIp(isElastic));
                }

                config['vip'] = Item._filterRepeatedInstances(vipList);
            }

            if ('dns_info' in config) {
                config['dns_info'] = Item._filterRepeatedInstances(config['dns_info']);
            }

            config.vip.forEach(vip => {
                if (_.isEmpty(vip.ipam_network_subnet)) {
                    delete vip.ipam_network_subnet;
                }
            });

            return config;
        }

        /**
         * Returns true if the VsVip or any child ConfigItem instances are busy. First checks
         * this.busy, then any VipConfig instance, then any DnsInfoConfig.
         * @return {boolean}
         */
        isBusy() {
            if (this.busy) {
                return true;
            }

            const config = this.getConfig();
            const vipsAreBusy = 'vip' in config && _.any(config['vip'], vip => vip.busy);

            if (vipsAreBusy) {
                return true;
            }

            const dnsInfosAreBusy = 'dns_info' in config &&
                _.any(config['dns_info'], dnsInfo => dnsInfo.busy);

            if (dnsInfosAreBusy) {
                return true;
            }

            return false;
        }

        /**
         * Parses the list of instances and calls dataToSave on each instance, then filters out
         * undefined results. If the resulting list is empty, return undefined as the final
         * output. Used in optional Repeated lists of objects.
         * @return {Object[]|undefined}
         */
        static _filterRepeatedInstances(instances) {
            let dataToSave = _.compact(instances.map(instance => instance.dataToSave()));

            if (!dataToSave.length) {
                dataToSave = undefined;
            }

            return dataToSave;
        }
    }

    angular.extend(VsVip.prototype, {
        objectName: 'vsvip',
        windowElement: 'vs-vip-modal',
    });

    return VsVip;
}

vsVipFactory.$inject = [
    'Item',
    '$q',
    'VipConfig',
    'DnsInfoConfig',
];

angular.module('aviApp').factory('VsVip', vsVipFactory);
