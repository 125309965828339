/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

class PreAwsWelcomePageController {
    constructor(
        Base,
        $state,
        AsyncFactory,
        appStateConstants,
        Auth,
    ) {
        this.base_ = new Base();
        this.$state_ = $state;
        this.Auth_ = Auth;
        this.AsyncFactory_ = AsyncFactory;
        this.appStateConstants_ = appStateConstants;

        this.busy = false;
        this.userAdminStatus = null;
    }

    $onInit() {
        this.startPolling();
    }

    /**
     * Polls initial_data API to check for admin user account status.
     */
    startPolling() {
        if (this.userAdminStatus && this.userAdminStatus.isActive()) {
            this.cancelPolling();
        }

        this.userAdminStatus = new this.AsyncFactory_(() => {
            return this.getInitData()
                .then(resp => this.handlePollResponse_(resp))
                .catch(this.restartPolling_);
        }, {
            maxSkipped: 2,
            callback: this.cancelPolling.bind(this),
        });

        this.userAdminStatus.start(10000);
    }

    /**
     * Immediately stops and starts running async factory in case of
     */
    restartPolling_() {
        this.cancelPolling();
        this.startPolling();
    }

    /**
     * Handles the response from the poll request
     */
    handlePollResponse_(resp) {
        const { data } = resp;
        const { LOGIN_STATE } = this.appStateConstants_;

        this.busy = false;

        if (!data.user_initial_setup) {
            this.cancelPolling();
            this.Auth_.removeData();
            this.$state_.go(LOGIN_STATE);
        }
    }

    /**
     * Gets the initial data.
     * @return {ng.$q.promise}
     */
    getInitData() {
        this.busy = true;

        return this.base_.request('GET', '/api/initial-data?include_name')
            .then(({ data }) => ({ data }))
            .finally(() => this.busy = false);
    }

    /**
     * Cancels polling request.
     */
    cancelPolling() {
        this.busy = false;
        this.base_.cancelRequests();
        this.userAdminStatus.stop();
    }

    $onDestroy() {
        this.cancelPolling();
    }
}

PreAwsWelcomePageController.$inject = [
    'Base',
    '$state',
    'AsyncFactory',
    'appStateConstants',
    'Auth',
];

/**
 * @ngdoc component
 * @name preAwsWelcomePage
 * @description
 *     preAwsWelcomePage is used for AWS cloud users.
 *     Used to refresh page to check whether the admin user set up done through CLI
 *     and when done, redirects user to the controller settings page.
 *
 */
angular.module('aviApp').component('preAwsWelcomePage', {
    controller: PreAwsWelcomePageController,
    templateUrl: 'src/components/pages/pre-welcome-aws/pre-welcome-aws.html',
});
