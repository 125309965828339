/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateExport
 * @description  Displays the configured Key and Certificate to be copied/exported.
 * @param {string} uuid - UUID of the Certificate.
 * @param {function} closeModal - Closes modal.
 */
function Controller(Base, Schema, clipboardService) {
    const base = new Base(),
        vm = this;

    this.Schema = Schema;

    this.copyToClipboard = function(message, target) {
        clipboardService.copy(message, target);
    };

    this.$onInit = function() {
        this.busy = true;
        this.errors = null;

        base.request('GET', `/api/sslkeyandcertificate/${this.uuid}?export_key=true`, null,
            null, 'sslcertificateexport')
            .then(function(rsp) {
                vm.config = rsp.data;
            }).catch(function(rsp) {
                vm.errors = rsp.data;
            }).finally(function() {
                vm.busy = false;
            });
    };

    /**
     * Highlights the textarea text on focus.
     * @param  {Event} $event - jQuery event.
     */
    this.onFocus = function($event) {
        $event.target.select();
    };

    this.$onDestroy = function() {
        base.cancelRequests('sslcertificateexport');
    };
}

Controller.$inject = [
    'Base',
    'Schema',
    'clipboardService',
];

angular.module('aviApp').component('sslCertificateExport', {
    bindings: {
        uuid: '@',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-export/ssl-certificate-export.html',
});
