/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Node object (VirtualService) of the microService graph.
 * @typedef {Object} MSGraphNode
 * @property {number} healthscore - HealthScore value for the VS.
 * @property {string} name - Name of the node.
 * @property {string} uuid - Unique Id of the node. For VS this is an UUID.
 * @property {number|undefined} num_servers - Number of servers inside VS. Undefined for all
 * other Node types.
 * @property {string} obj_type - Type of node: virtualservice, IP or user (added by UI code).
 * @property {boolean|undefined} secured - Whether VS is "secured" by specific network security
 * policy rules. Present for obj_type VS only. {@link VirtualService.hasSecureAppRule}.
 * @property {string|undefined} security_action - Whether it is in black or white list. Undefined
 * when rule is not set. For ex.: "NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW".
 * @property {boolean|undefined} isolated - Set to true when this node doesn't have links with
 * any other node on the graph. API returns isolated nodes only when parameter
 * 'include_inactive_security_nodes' is set to true or is undefined (default value is true).
 * @property {{oper_status:Object}|undefined} runtime - Runtime object provided by the backend.
 * @property {number|undefined} eccentricity - How "deep" in levels did we go to get the graph of
 * this node. Set for the root node only.
 */

/**
 * Edge (connection between two {@link MSGraphNode nodes}) of the microService graph.
 * @typedef {Object} MSGraphEdge
 * @property {string} id - Link id made of source uuid, colon and target uuid.
 * @property {number} target - Array index of the target node.
 * @property {number} source - Array index of the source node.
 * @property {Object.<string,{value:number}>} metrics - Object where the key is a metric name and
 * value is an object with a metric value.
 * @property {string[]} name - Array of source and target UUIDs.
 * @property {number} policy_drops - If there is a policy present which restricts the data flow
 * between nodes we should see some packets drops.
 * @property {string|undefined} security_action - Whether it is in black or white list. Undefined
 * when rule is not set. For ex.: "NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW".
 */

import { sha1 } from 'object-hash';

/**
 * @ngdoc service
 * @name MicroServiceGraphFactory
 * @description Making ongoing API calls for the graph of connections (traffic flow) between
 * microservices.
 */
angular.module('aviApp').factory('MicroServiceGraphFactory', [
'$q', 'Base', 'AsyncFactory', 'Timeframe',
    function($q, Base, AsyncFactory, Timeframe) {
        /**
         * @param oArgs {{params: Object, updInterval:number, nodeGroups:boolean}}
         * @constructor
         * @extends {Base}
         */
        const MicroServiceGraphFactory = function(oArgs) {
            MicroServiceGraphFactory.superconstructor.call(this, oArgs);

            this.asyncFactory = new AsyncFactory(this.pollingFunction.bind(this), {
                maxSkipped: 3,
                callback: this.restart.bind(this),
            });

            this.nodes = [];
            this.links = [];

            this.onTimeframeChange = this.onTimeframeChange.bind(this);

            Timeframe.on('change', this.onTimeframeChange);

            this.params = oArgs && oArgs.params || {};
            this.updInterval = oArgs && oArgs.updInterval || 0;

            /**
             * When set to true we will use {@link MicroServiceGraphFactory.setNodesGroup} to set
             * the groupId property of the graph's nodes after receiving the back-end response.
             * @type {boolean}
             * */
            this.nodeGroups = oArgs && oArgs.nodeGroups || false;
        };

        avi.inherit(MicroServiceGraphFactory, Base);

        /** @type {AsyncFactory} */
        MicroServiceGraphFactory.prototype.asyncFactory = null;

        /**
         * VirtualService Id which is the root of the graph.
         * @private
         * @type {string}
         */
        MicroServiceGraphFactory.prototype.vsId_ = '';

        /**
         * Dictionary of parameters to make up an API call URL. For example step, limit and
         * metric_id.
         * @type {Object.<string,*>|null}
         */
        MicroServiceGraphFactory.prototype.params = null;

        /**
         * Array of microservices.
         * @type {MSGraphNode[]}
         */
        MicroServiceGraphFactory.prototype.nodes = null;

        /**
         * Array of connections (traffic flow) between microservices.
         * @type {MSGraphEdge[]}
         */
        MicroServiceGraphFactory.prototype.links = null;

        /**
         * Custom interval of updates in seconds can be set for the asyncFactory. If not set will be
         * controlled by the {@link Timeframe} service.
         * @type {number|undefined}
         */
        MicroServiceGraphFactory.prototype.updInterval = 0;

        /**
         * Flag is set to true when loading of new data is taking place.
         * @type {boolean}
         */
        MicroServiceGraphFactory.prototype.busy = false;

        /**
         * Since vsId is a private variable, this function will return the ID of the graph's `root`
         * VS object.
         * @returns {string|undefined}
         */
        MicroServiceGraphFactory.prototype.getVsId = function() {
            return this.vsId_;
        };

        /**
         * Returns a list of VS nodes having immediate connection with node of a passed vsId.
         * Can include node itself when it has a self-reference.
         * @param {string=} vsId - When not passed root node will be used.
         * @param {string=} nodeType - Filters results list by nodeGroup such as `client`,
         *     `server` or `both`. When `client` or `server` is used `both` are also returned.
         *     Can be used only when root VS uuid is used as vsId.
         * @returns {MSGraphNode[]}
         */
        MicroServiceGraphFactory.prototype.getVsImmediateNeighbors = function(vsId, nodeType) {
            const
                neighbors = [],
                neighborHash = {};

            if (vsId && vsId !== this.getVsId()) {
                nodeType = '';
            }

            vsId = vsId || this.getVsId();

            const vsNodesHash = this.nodes.reduce(function(base, node) {
                if (node.obj_type === 'virtualservice') {
                    base[node.uuid] = node;
                }

                return base;
            }, {});

            if (vsId in vsNodesHash) {
                this.links.forEach(function(edge) {
                    const vsIdPos = edge.name.indexOf(vsId);

                    if (vsIdPos !== -1) {
                        const otherNodeId = edge.name[Math.abs(vsIdPos - 1)];

                        if (otherNodeId in vsNodesHash && !(otherNodeId in neighborHash) &&
                            (!nodeType ||
                            vsNodesHash[otherNodeId].groupId === 'both' ||
                            vsNodesHash[otherNodeId].groupId === nodeType)) {
                            neighbors.push(vsNodesHash[otherNodeId]);
                            neighborHash[otherNodeId] = vsNodesHash[otherNodeId];
                        }
                    }
                });
            }

            return neighbors;
        };

        /**
         * Builds up the API URL string using a vsId and params object.
         * @returns {string}
         */
        MicroServiceGraphFactory.prototype.makeUrl = function() {
            let params = 'pool=*&';

            if (!this.vsId_) {
                throw new Error('Can\'t update graph without VS id.');
            }

            params += _.reduce(this.params, function(str, param, key) {
                if (!_.isUndefined(param)) {
                    str += `${key}=${param}&`;
                }

                return str;
            }, '');

            if (_.isUndefined(this.params['limit'])) {
                params += `limit=${Timeframe.selected().limit}&`;
            }

            if (_.isUndefined(this.params['step'])) {
                params += `step=${Timeframe.selected().step}&`;
            }

            return `api/analytics/microservicemap/virtualservice/${this.vsId_}?${params}`;
        };

        /**
         * Loads graph initially and starts it's ongoing updates.
         * @param {string} vsId - VS id of the graph's root.
         * @param {Object.<string,*>=} params - Hash with parameters, which will update the existing
         *     this.params.
         */
        MicroServiceGraphFactory.prototype.start = function(vsId, params) {
            this.stop(true);

            if (vsId && typeof vsId === 'string') {
                this.vsId_ = vsId;

                if (params && typeof params === 'object') {
                    angular.extend(this.params, params);
                }

                //by default will use timeframe
                this.asyncFactory.start((this.updInterval || Timeframe.selected().interval) * 1000);
            } else {
                throw new Error('Can\'t start updates without VS id.');
            }
        };

        /**
         * Immediately updates the graph by stopping and starting running async factory.
         */
        MicroServiceGraphFactory.prototype.restart = function() {
            if (this.asyncFactory.isActive() && !!this.vsId_) {
                this.stop();
                this.asyncFactory.start((this.updInterval || Timeframe.selected().interval) * 1000);
            } else {
                throw new Error('Can\'t restart AsyncFactory which is not running or has no VS' +
                    ' id.');
            }
        };

        /**
         * One time load. Can't be used along with AsyncFactory updates.
         * @param {string} vsId
         * @param {Object.<string,*>=} params - Hash with parameters, which will update the existing
         *     this.params.
         * @returns {ng.$q.promise}
         */
        MicroServiceGraphFactory.prototype.load = function(vsId, params) {
            const deferred = $q.defer();
            let { promise } = deferred;

            if (!this.asyncFactory.isActive()) {
                if (vsId && typeof vsId === 'string') {
                    this.stop(true);

                    this.vsId_ = vsId;

                    if (params && typeof params === 'object') {
                        angular.extend(this.params, params);
                    }

                    promise = this.pollingFunction();
                } else {
                    deferred.reject('Can\'t load without VS id.');
                }
            } else {
                deferred.reject('Can\'t load while polling is happening.');
            }

            return promise;
        };

        /**
         * Stops asyncFactory and ongoing API call.
         * @param {boolean=} force - When set to true function resets the data of this Item as well.
         */
        MicroServiceGraphFactory.prototype.stop = function(force) {
            this.asyncFactory.stop();
            this.cancelRequests();
            this.busy = false;

            if (force) {
                this.reset();
            }
        };

        /**
         * Processes backend response to set groupId property on the graph's nodes. Values are:
         * server, client, both or undefined (no value - for the root node only). Preserves the
         * structure of the back-end response.
         * @param {{nodes:Array, edges:Array}} graph
         * @returns {{nodes:Array, edges:Array}}
         */
        MicroServiceGraphFactory.prototype.setNodesGroup = function(graph) {
            const
                rootUuid = this.getVsId(),
                nodeTypes = {};

            _.each(graph.edges, function(edge) {
                const
                    targetId = graph.nodes[edge.target].uuid,
                    sourceId = graph.nodes[edge.source].uuid;

                if (targetId !== rootUuid) {
                    if (!(targetId in nodeTypes)) {
                        nodeTypes[targetId] = 'server';
                    } else if (nodeTypes[targetId] !== 'server') {
                        nodeTypes[targetId] = 'both';
                    }
                }

                if (sourceId !== rootUuid) {
                    if (!(sourceId in nodeTypes)) {
                        nodeTypes[sourceId] = 'client';
                    } else if (nodeTypes[sourceId] !== 'client') {
                        nodeTypes[sourceId] = 'both';
                    }
                }
            });

            _.each(graph.nodes, function(node) {
                if (!node.isolated && node.uuid !== rootUuid) {
                    node.groupId = nodeTypes[node.uuid];
                }
            });

            return graph;
        };

        /**
         * Postprocessing of the server response.
         * @param {{nodes:Array, edges:Array}} resp - Object, provided by the back-end with edges
         *     and vertexes.
         */
        MicroServiceGraphFactory.prototype.afterLoad = function(resp) {
            const
                linkedNodes = {},
                seriesName = _.keys(resp.data)[0],
                data = angular.copy(resp.data[seriesName]);

            let updateStatus;

            if (data && 'edges' in data && 'nodes' in data) {
                _.each(data.edges, function(edge) {
                    edge.id = `${edge.name[0]}:${edge.name[1]}`;

                    if (this.params['include_inactive_security_nodes'] !== false) {
                        linkedNodes[edge.name[0]] = true;
                        linkedNodes[edge.name[1]] = true;
                    }

                    if ('source_insights.avg_bandwidth' in edge.metrics) { //wanna have Kbps
                        edge.metrics['source_insights.avg_bandwidth'].value /= 1024;
                    }
                }, this);

                if (this.params['include_inactive_security_nodes'] !== false) {
                    _.each(data.nodes, function(node) {
                        if (!(node.uuid in linkedNodes)) {
                            node.isolated = true;
                        }
                    });
                }

                if (this.nodeGroups) {
                    this.setNodesGroup(data);
                }

                updateStatus = this.getUpdateStatus_(data.nodes, data.edges);

                if (updateStatus > 0) {
                    this.nodes = data.nodes;
                    this.links = data.edges;
                }

                this.trigger('dataUpdate afterLoadSuccess', updateStatus);
            }
        };

        /**
         * Compares data we have now with what we've just got from the backend. Returns 0 if no
         * changes are found, 1 if nodes lists differ, 2 if links lists differ, 3 if some
         * Node's property got updated, 4 if property(ies) of link(s) is(are) different.
         * @param {MSGraphNode[]} nodes
         * @param {MSGraphEdge[]} links
         * @returns {number} - From 0 to 4.
         * @protected
         */
        MicroServiceGraphFactory.prototype.getUpdateStatus_ = function(nodes, links) {
            /**
             * Reduces an array of {@link MSGraphEdge} into array of link object hashes to compare
             * significant properties.
             * @param {string[]} arr - Reduce "base". The one we will return later on.
             * @param {MSGraphEdge} link
             * @returns {string[]} - Array of links hashes.
             * @inner
             */
            function buildLinkHashes(arr, link) {
                const
                    metricKey = _.keys(link.metrics)[0],
                    metricValue = link.metrics && link.metrics[metricKey] &&
                        link.metrics[metricKey].value;

                arr.push(sha1({
                    id: link.id,
                    groupId: link.groupId,
                    policy_drops: link.policy_drops,
                    security_action: link.security_action,
                    mVal: metricValue,
                }));

                return arr;
            }

            /**
             * Reduces an array of {@link MSGraphNode} into an array of Node object hashes to
             * compare significant properties.
             * @param {string[]} arr - Reduce "base". The one we will return later on.
             * @param {MSGraphNode} node
             * @returns {string[]} - Array of nodes hashes.
             * @inner
             */
            function buildNodeHashes(arr, node) {
                const hash = _.pick(node,
                    ['uuid', 'name', 'healthscore', 'num_servers', 'obj_type', 'secured',
                    'security_action', 'eccentricity']);

                hash.oper_status = node.runtime && node.runtime.oper_status &&
                    node.runtime.oper_status.state;

                arr.push(sha1(hash));

                return arr;
            }

            let res = 0,
                nodeHashes,
                prevNodeHashes,
                linkHashes,
                prevLinkHashes,
                linkIds,
                prevLinkIds;

            const prevNodesList = _.pluck(this.nodes, 'uuid');
            const nodesList = _.pluck(nodes, 'uuid');

            //check nodes at first
            if (prevNodesList.length === nodesList.length &&
                !_.difference(prevNodesList, nodesList).length) {
                prevLinkIds = _.pluck(this.links, 'id');
                linkIds = _.pluck(links, 'id');

                //then links
                if (linkIds.length === prevLinkIds.length &&
                    !_.difference(prevLinkIds, linkIds).length) {
                    prevNodeHashes = _.reduce(this.nodes, buildNodeHashes, []);
                    nodeHashes = _.reduce(nodes, buildNodeHashes, []);

                    if (_.difference(prevNodeHashes, nodeHashes).length) {
                        res = 3;//nodes properties got updated
                    } else {
                        prevLinkHashes = _.reduce(this.links, buildLinkHashes, []);
                        linkHashes = _.reduce(links, buildLinkHashes, []);

                        if (_.difference(prevLinkHashes, linkHashes).length) {
                            res = 4;//links metrics values got updated
                        }
                    }
                } else {
                    res = 2;//list of links got updated
                }
            } else {
                res = 1;//list of nodes got updated
            }

            return res;
        };

        /**
         * Callback for the AsyncFactory {@link AsyncFactory#pollingFunction}.
         * @returns {Promise}
         */
        MicroServiceGraphFactory.prototype.pollingFunction = function() {
            const self = this;

            this.busy = true;

            return this.request('get', this.makeUrl())
                .then(function(resp) {
                    self.busy = false;

                    return self.afterLoad(resp);
                }, function() {
                    self.busy = false;
                });
        };

        /**
         * Callback for the {@link Timeframe} change event. Will restart updates according to the
         * selected timeframe interval.
         */
        MicroServiceGraphFactory.prototype.onTimeframeChange = function() {
            if (this.vsId_ && !this.updInterval && this.asyncFactory.isActive()) {
                this.start(this.vsId_);
            }
        };

        /**
         * Removes all data from the instance as well as VS id.
         */
        MicroServiceGraphFactory.prototype.reset = function() {
            const updateStatus = this.getUpdateStatus_([], []);

            this.vsId_ = '';
            this.nodes = [];
            this.links = [];

            this.trigger('dataUpdate afterReset', updateStatus);
        };

        /**
         * Destroys the instance stopping the {@link AsyncFactory}, ongoing API calls and removes
         * all data.
         */
        MicroServiceGraphFactory.prototype.destroy = function() {
            this.stop(true);
            Timeframe.unbind('change', this.onTimeframeChange);
        };

        return MicroServiceGraphFactory;
    }]);
