/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  gslbNonAviModal
 * @description  Modal component for GSLB non-Avi site.
 * @param  {Gslb} editable
 * @param  {number=} siteIndex - Index of the site being modified.
 */
class GslbNonAviModalController {
    constructor(Schema) {
        this.Schema = Schema;
    }

    $onInit() {
        const config = this.editable.getConfig();

        if (!('url' in config) || !angular.isUndefined(this.siteIndex) &&
                !(this.siteIndex in config[this.editable.constructor.nonAviSitesConfigPropName])) {
            this.siteIndex = 0;
        }

        if (!angular.isUndefined(this.siteIndex)) {
            this.site = this.editable.getNonAviSiteByIndex(this.siteIndex);
        }
    }
}

GslbNonAviModalController.$inject = [
        'Schema',
];

angular.module('aviApp').component('gslbNonAviModal', {
    bindings: {
        editable: '<',
        siteIndex: '@',
    },
    controller: GslbNonAviModalController,
    templateUrl: '/src/components/infrastructure/gslb/gslb-non-avi-modal/' +
            'gslb-non-avi-modal.html',
});
