/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-psm-location-modal.less';

/**
 * @alias waf/wafPolicyPsmLocationModal
 * @private
 */
class WafPolicyPsmLocationModalComponent {
    /**
     * Returns true if the location is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.location.getIndex());
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }
}

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationModal
 * @module waf/wafPolicyPsmLocationModal
 * @desc Component for editing a WAF Policy PSM Location.
 * @param {WafPSMLocationConfigItem} location
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @param {string[]} breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPolicyPsmLocationModal', {
    bindings: {
        location: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        preventEdit: '<',
        breadcrumbs: '<',
    },
    controller: WafPolicyPsmLocationModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-location-modal/' +
        'waf-policy-psm-location-modal.component.html',
});
