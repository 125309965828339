/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name compileTemplate
 * @element any
 * @restrict A
 * @description
 *     Alternative to ngBindHtml. Inserts template string inside the element, but does not
 *     sanitize it or escapes the content. Only use with trusted HTML string.
 * @example
 *      <div compile-template="<span class="foo" some-custom-directive>foobar</span>"></div>
 */
angular.module('aviApp').directive('compileTemplate', ['$compile', function($compile) {
    return {
        restrict: 'A',
        link(scope, element, attributes) {
            scope.$watch(attributes.compileTemplate, function(newValue) {
                element.html($compile(newValue)(scope) || '');
            });
        },
    };
}]);
