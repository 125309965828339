/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const dataTransportByPostFactory = ListDataTransport => {
    class DataTransportByPost extends ListDataTransport {
        /** @constructor */
        constructor(args = {}) {
            args.httpMethod = 'post';
            super(args);
        }

        /**
         * Returns the payload object for POST request.
         * @override
         */
        getRequestPayload_(params) {
            return params || undefined;
        }

        /**
         * Appends include_name and include_refs to query string.
         * @override
         */
        getRequestUrlQuery_(params) {
            let str = '';

            if (params['includeName_']) {
                str += 'include_name&';
            }

            if (params['includeRefs_']) {
                str += 'include_refs';
            }

            return str;
        }
    }

    return DataTransportByPost;
};

dataTransportByPostFactory.$inject = [
    'ListDataTransport',
];

/**
 * @ngdoc factory
 * @name  DataTransportByPost
 * @author Chitra
 * @description
 *      DataTransport layer using POST method.
 *      The payload params except include_name and include_refs are being passed through
 *      POST payload.
 */
angular.module('aviApp')
    .factory('DataTransportByPost', dataTransportByPostFactory);
