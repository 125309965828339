/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const objectTypeItemFactory = (UpdatableItem, MessageBase, RepeatedMessageItem) => {
    /**
     * @alias module:services/ObjectTypeItem
     * @private
     */
    class ObjectTypeItem extends UpdatableItem {
        constructor(args) {
            super(args);

            // objectType is the name used for the object in Schema.js, whereas objectName is used
            // for the API. If the objectType is set, then the Item should go through the ConfigItem
            // flow and its config will be set to contain ConfigItem instances rather than flat
            // object data.
            const { objectType, data } = args;

            this.objectType_ = objectType || this.objectType_;

            if (!this.objectType_) {
                throw new Error(`objectType not found for ${this}`);
            }

            this.messageMap_ = MessageBase.createMessageMap(this.objectType_);

            if (data && data.config) {
                this.updateConfigItems_(data.config);
            }
        }

        /**
         * @override
         */
        updateItemData(newData) {
            if (angular.isObject(newData)) {
                this.updateConfigItems_(newData.config);
            }

            return true;
        }

        /**
         * @override
         */
        getDataCopy() {
            const flattenedConfig = this.flattenConfig_();
            const itemData = {
                ...this.data,
                config: flattenedConfig,
            };

            return angular.copy(itemData);
        }

        /**
         * Given a field name of a config, create a MessageItem corresponding to that field.
         * @param {string} fieldName - name of the property in the config.
         * @param {Object=} childConfig - Config data object to be set in the child MessageItem.
         * @param {boolean=} skipRepeated - True if RepeatedMessageItem should not be created, and
         *     the ConfigItemClass should be created directly.
         * @param {Object=} optionalArgs - Optional arguments to pass to the child constructor.
         * @returns {RepeatedMessageItem|MessageItem}
         * @protected
         */
        createChildByField_(fieldName, childConfig, skipRepeated = false, optionalArgs = {}) {
            const { ConfigItemClass, objectType, isRepeated } = this.messageMap_[fieldName];
            const args = {
                objectType,
                fieldName,
                config: childConfig,
                parent: this,
                ...optionalArgs,
            };

            const constructor = isRepeated && !skipRepeated ? RepeatedMessageItem : ConfigItemClass;

            return new constructor(args);
        }

        /**
         * Creates a new RepeatedMessageItem or MessageItem instance and sets it as a property on
         * the config.
         * @param {string} fieldName - Property of a MessageItem to set on the config.
         * @protected
         */
        setNewChildByField_(fieldName, ...args) {
            this.data.config[fieldName] = this.createChildByField_(fieldName, ...args);
        }

        /**
         * Updates the current config with new config data, and calls lifecycle hooks used to modify
         * data after loading.
         * @param {Object} newConfig - New config data.
         * @protected
         */
        updateConfigItems_(newConfig) {
            this.data.config = this.data.config || {};

            const configItems = _.reduce(
                this.messageMap_,
                this.getSetConfigDataReducer_(newConfig),
                {},
            );

            this.data.config = {
                ...newConfig,
                ...configItems,
            };
        }

        /**
         * Returns plain object config data from each child MessageItem.
         * @returns {Object}
         * @protected
         */
        flattenConfig_() {
            const config = this.getConfig();
            const flattenedConfigItems = _.reduce(
                this.messageMap_,
                (acc, messageMapProps, field) => {
                    const value = config[field];

                    if (!_.isUndefined(value)) {
                        acc[field] = value.flattenConfig_();
                    }

                    return acc;
                },
                {},
            );

            const flattenedConfig = {
                ...config,
                ...flattenedConfigItems,
            };

            return angular.copy(flattenedConfig);
        }

        /**
         * Calls a method on each MessageItem in the config and returns the resulting instance as a
         * new config object.
         * @param {MessageItemTreeConfig} config
         * @param {string} methodName - Name of the method to call.
         * @returns {MessageItemTreeConfig}
         * @protected
         */
        childConfigItemMap_(config, methodName) {
            // If config is null or undefined, just return it back.
            if (!config) {
                return config;
            }

            const configItemsHash = _.reduce(this.messageMap_, (acc, messageMapProps, field) => {
                if (!_.isUndefined(config[field])) {
                    acc[field] = config[field][methodName]();
                }

                return acc;
            }, {});

            return {
                ...config,
                ...configItemsHash,
            };
        }

        /**
         * Returns a clone of each child MessageItem.
         * @returns {MessageItemTreeConfig}
         */
        getConfigCopy() {
            return this.childConfigItemMap_(this.getConfig(), 'clone');
        }

        /**
         * Calls beforeSave on each child MessageItem.
         * @returns {MessageItemTreeConfig}
         */
        dataToSave() {
            return this.childConfigItemMap_(this.getConfigCopy(), 'getDataToSave');
        }

        /**
         * Reducer used when updating or creating new MessageItems.
         * @param {Object} newConfig - New config object to update the current config with.
         * @returns {Function} Reducer function to set MessageItem instances.
         * @protected
         */
        getSetConfigDataReducer_(newConfig) {
            return (configItems, messageMapProps, field) => {
                const { isRepeated } = messageMapProps;
                const newValue = newConfig[field];

                if (_.isUndefined(newValue) && !isRepeated) {
                    return configItems;
                }

                if (this.getConfig()[field] instanceof MessageBase) {
                    const configItem = this.getConfig()[field];

                    configItem.updateConfig(newValue);
                    configItems[field] = configItem;

                    return configItems;
                }

                configItems[field] = this.createChildByField_(field, newValue);

                return configItems;
            };
        }

        /**
         * Returns the Item's config data.
         * @returns {Object}
         */
        get config() {
            return this.getConfig();
        }
    }

    return ObjectTypeItem;
};

objectTypeItemFactory.$inject = [
    'UpdatableItem',
    'MessageBase',
    'RepeatedMessageItem',
];

/**
 * @ngdoc factory
 * @name ObjectTypeItem
 * @description
 *     An item to be used with MessageItems. If your item is compatible with the MessageItem
 *     architecture, you should extend ObjectTypeItem rather than Item and define an objectType.
 * @module services/ObjectTypeItem
 * @author alextsg
 */
angular.module('core.vantage.avi').factory('ObjectTypeItem', objectTypeItemFactory);
