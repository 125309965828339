/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('HSMGroupListController', [
'$scope', 'HSMGroupCollection', 'CRUDGridConfig', 'AviModal',
function($scope, HSMGroupCollection, CRUDGridConfig, AviModal) {
    this.collection = new HSMGroupCollection();

    this.gridConfig = new CRUDGridConfig();
    this.gridConfig.collection = this.collection;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'type',
        title: 'Type',
        template: '{{row.data.config.hsm.type | enum: "HSM_TYPE_" }}',
    }];

    this.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }, {
        title: 'Open window for client certificate download',
        class: 'icon-lostlog',
        do: ({ data }) => {
            AviModal.open('prof-hsmgroup-download-cert', {
                nodes: data.config.hsm.sluna.node_info,
                name: data.config.name,
            });
        },
        hidden(row) {
            return row.data.config.hsm.type !== 'HSM_TYPE_SAFENET_LUNA';
        },
    }];
}]);
