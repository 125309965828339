/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './virtualservice-vip-address.less';

/**
 * @ngdoc component
 * @name  virtualserviceVipAddress
 * @param {Cloud} cloud
 * @param {VirtualService} editable
 * @param {string} createMode - 'advanced' or 'basic'. Used to differentiate between 'advanced' and
 *     'basic' Virtual Service creation. Vip sharing is only allowed in 'advanced' creation.
 * @description
 *     Handles inputs for VIP Address section of Virtual Service create/edit. Wrapper component for
 *     the various cases of VIP address configuration, by cloud type and IPAM/DNS provider profile.
 */
class VipAddressController {
    constructor(Schema, Regex, AzureConfigurationConfig) {
        this.Schema = Schema;
        this.Regex = Regex;
        this._AzureConfigurationConfig = AzureConfigurationConfig;
    }

    $onInit() {
        const config = this.editable.getConfig();

        this.vsVip = config['vsvip_ref_data'];
        this.vsRefs = config['shared_vs_refs'];
        this.showAdvanced = !angular.isUndefined(this.editable.getConfig().vsvip_ref);
    }

    /**
     * Toggles the view between 'Basic' and 'Advanced'. 'Basic' allows the user to configure
     * the Virtual Service VIP address(es), while 'Advanced' allows the user to select an
     * existing VsVip object and populate the 'vsvip_ref' property.
     */
    toggleAdvanced() {
        this.showAdvanced = !this.showAdvanced;
        delete this.editable.getConfig()['vsvip_ref'];
    }

    /**
     * Displays the FQDN input(s) if the cloud has a DNS provider profile or if the cloud is AWS
     * and has route53_integration enabled.
     * @return {boolean} True to show, false to hide.
     */
    showFqdn() {
        const cloudConfig = this.cloud.getConfig();
        const { aws_configuration: awsConfig } = cloudConfig;
        let { azure_configuration: azureConfig } = cloudConfig;

        if (azureConfig instanceof this._AzureConfigurationConfig) {
            azureConfig = azureConfig.getConfig();
        }

        return this.cloud.hasDnsProviderProfile() ||
                this.cloud.getVtype() === 'CLOUD_AWS' && awsConfig.route53_integration ||
                this.cloud.getVtype() === 'CLOUD_AZURE' &&
                (azureConfig.use_azure_dns || azureConfig.dns_provider_ref);
    }

    /**
     * If true, allows the user to add an FQDN input. True when the cloud has a DNS provider
     * profile and when the cloud is AWS, has route53_integration enabled, and currently has
     * 0 FQDNs configured, since AWS+route53_integration doesn't yet support multiple FQDN.
     * @return {boolean}
     */
    showAddFqdn() {
        const cloudConfig = this.cloud.getConfig();
        const {
            aws_configuration: awsConfig,
            azure_configuration: azureConfig,
        } = cloudConfig;

        const { dns_info: dnsList } = this.vsVip.getConfig();
        let hasDns = false;

        if (Array.isArray(dnsList)) {
            hasDns = dnsList.length > 0;
        }

        const vtype = this.cloud.getVtype();
        const isAws = vtype === 'CLOUD_AWS';
        const isAzure = vtype === 'CLOUD_AZURE';

        return !hasDns && (isAws && awsConfig.route53_integration || isAzure &&
                    (azureConfig.use_azure_dns || cloudConfig.dns_provider_ref));
    }

    /**
     * Returns a comma-separated list of Virtual Service names that the VsVip is being shared
     * with, ie. modifying the VsVip will affect these Virtual Services. The Virtual Service
     * refs come from the read-only 'shared_vs_refs' property returned by /api/virtualservice.
     * @return {string}
     */
    getSharedVipVirtualservices() {
        return this.vsRefs.map(ref => ref.name()).join(', ');
    }

    /**
     * Returns true if an address needs to be manually added, as opposed to cases in Openstack
     * and AWS where a network can be selected.
     * @return {boolean}
     */
    allowManualAddressOnly() {
        const autoClouds = {
            CLOUD_OPENSTACK: true,
            CLOUD_AWS: true,
            CLOUD_AZURE: true,
        };

        const vtype = this.cloud.getVtype();

        return !(vtype in autoClouds) && (!this.cloud.hasAzureIpamProfile());
    }

    /**
     * Wrapper for getting cloud type.
     * @return {string}
     */
    getCloudType() {
        return this.cloud.getVtype();
    }
}

VipAddressController.$inject = [
    'Schema',
    'Regex',
    'AzureConfigurationConfig',
];

angular.module('aviApp').component('virtualserviceVipAddress', {
    bindings: {
        editable: '<',
        cloud: '<',
        createMode: '@',
    },
    controller: VipAddressController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-address.html',
});
