/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO fire events for fields, datasources, offset&limit updates?
//TODO search/sorting/params should be passed not to config only, inventory won't work wo them
//TODO busy property should be reconsidered
function collectionFactory(
    $q,
    UpdatableBase,
    Item,
    AviModal,
    Auth,
    aviInherit,
    defaultValues,
) {
    /**
     * @class Collection
     * @constructor
     * @memberOf module:avi/dataModel
     * @extends module:avi/dataModel.UpdatableBase
     * @param {Object=} oArgs - Configuration object, contains argument list, event listeners and
     *     more.
     * @desc
     *
     *     Collection provides ability to load an ordered list of Items. Collection can use a list
     *     of CollDataSources responsible for getting different information about listed Items
     *     through different APIs. List can be ordered, filtered (by search parameter for example)
     *     and continuously update itself in certain intervals.
     *
     *     Collection works together with directives/services rendering it's layout in
     *     browser. Such services should inform Collection with the viewport size, number of hidden
     *     above the viewport's top border Items, scrolling events, list of visible Item ids, data
     *     fields it is interested in and etc so that Collection knows which part of the list should
     *     be fetched/updated with what type of data.
     *
     *     Collection also provides methods to drop (actual deletion) Items as well as create new
     *     ones.
     *
     *     Most common users of Collection are directives {@link collectionGrid},
     *     {@link collectionDropdown} with it's variations and {@link infiniteScroll}.
     *
     * @author Alex Malitsky
     **/
    function Collection(oArgs = {}) {
        Collection.superconstructor.call(this, oArgs);

        /**
         * Hash of instantiated items by id.
         * @type {{string: Item}}
         **/
        this.itemById = {};

        /**
         * List of instantiated items.
         * @type {Item[]}
         **/
        this.items = [];

        /**
         * Ids of currently visible Collection Items. Updated through
         * {@link Collection#updateItemsVisibility} by Collection user (directive, service, etc).
         * Used to reduce amount of fetched data.
         * @type {Item#id[]}
         * @protected
         */
        this.visibleItemIds_ = [];

        /**
         * For the grid view when we have some Items hidden above the current viewport (by
         * scrolling) and we want to know the number so that we won't fetch updates for them
         * until they become visible back again. Updated through {@link
         * Collection#updateItemsVisibility} by Collection user.
         * @type {number}
         * @protected
         */
        this.visibleItemsOffset_ = 0;

        /**
         * To allow smooth scrolling we always want to have more Items than we show and
         * preload a next set of options before user gets to the last one. Used by DataSources.
         * @type {number}
         * @protected
         */
        this.overLimitCoeff_ = typeof oArgs.overLimitCoeff === 'number' &&
            oArgs.overLimitCoeff >= 0 && _.isFinite(oArgs.overLimitCoeff) ?
            oArgs.overLimitCoeff : this.overLimitCoeff_;

        /** @inner */
        const defaultViewportSize = oArgs.limit;

        /**
         * Collection limit
         * @type {number}
         * @protected
         */
        this.limit_ = oArgs.limit || 0;

        if (typeof defaultViewportSize === 'number' && defaultViewportSize > 0 &&
            defaultViewportSize < 201) {
            this.defaultViewportSize_ = defaultViewportSize;
            this.overLimitCoeff_ = 0;//assume that we pass limit when we don't use infinite scroll
        } else if (typeof oArgs.params === 'object' &&
            typeof oArgs.params['page_size'] === 'number' && oArgs.params['page_size'] > 0 &&
            oArgs.params['page_size'] < 201) {
            this.defaultViewportSize_ = oArgs.params['page_size'];
        } else {
            this.defaultViewportSize_ = this.defaultViewportSize_;
        }

        if (typeof oArgs.params === 'object' && 'page_size' in oArgs.params) {
            delete oArgs.params['page_size'];
        }

        /**
         * Collection user (directive, service, etc) can set the size of the viewport trough
         * {@link Collection#updateViewportSize} indicating how many items do we want to load in
         * one shot (and how many extra do we want to keep below to make scrolling seamless.
         * @type {number}
         * @protected
         */
        this.viewportSize_ = this.defaultViewportSize_;

        this.updateViewportSize(this.viewportSize_, true, true);

        /**
         * Kinda id of collection, used by many {@link CollDataSource DataSources} to
         * produce a request params object for API call. Often becomes a part of URL for `config`
         * API calls and is used for permission checks.
         * @type {string}
         * @protected
         */
        this.objectName_ = oArgs.objectName || this.objectName_;

        /**
         * Name of the permission associated with the Collection, ex. PERMISSION_VIRTUALSERVICE or
         * PERMISSION_POOL. Used to determine, for example, if the user has permissions to create a
         * new Item.
         */
        this.permissionName_ = oArgs.permissionName || '';

        /**
         * Item constructor to {@link Collection#append append} new Items to the Collection or
         * just (@link Collection#getNewItem create} them.
         * @type {Item}
         * @protected
         */
        this.itemClass_ = oArgs.itemClass || this.itemClass_;

        /**
         * {@link AviModal} window Id which is used for {@link Collection#create Collection Item
         * create}.
         * @type {string}
         * @protected
         **/
        this.windowElement_ = oArgs.windowElement || this.windowElement_ ||
            this.itemClass_.prototype.windowElement;

        this.setNewItemDefaults(!angular.isUndefined(oArgs.defaults) ? oArgs.defaults :
            angular.copy(this.defaults_));

        /**
         * Constant object to be applied over Item's default configuration before applying
         * mutable `defaults_`.
         * @type {{string: *}}
         * @protected
         */
        this.serverDefaultsOverride_ = angular.copy(this.serverDefaultsOverride_);

        //these provide compatibility with the previous Collection version, deprecated stuff
        if (oArgs.sortBy && angular.isString(oArgs.sortBy)) {
            this.sortBy_ = oArgs.sortBy;
        }

        if (this.sortBy_ && angular.isString(this.sortBy_)) {
            this.getDataSourceByFieldName('config').setSortParam(this.sortBy_);
        }

        if (oArgs.data && Array.isArray(oArgs.data) && oArgs.data.length) {
            _.each(oArgs.data, function(iData) {
                this.appendItem(iData);
            }, this);
        }

        //copies list from prototype
        this.searchFields_ = (oArgs.searchFields || this.searchFields_).concat();

        //should we keep the default search string when passed?
        if (angular.isString(oArgs.search)) {
            this.setSearch(oArgs.search);
        }

        /**
         * Params to be passed to the create controller or component.
         * @type {Object|null}
         */
        this.createParams_ = !angular.isUndefined(oArgs.createParams) ?
            angular.copy(oArgs.createParams) : null;

        if (this.loadOnCreate_) {
            this.load();
        }
    }

    aviInherit(Collection, UpdatableBase);

    /** @override */
    Collection.prototype.defaultDataSources_ = 'list';

    /** @override */
    Collection.prototype.defaultDataFields_ = 'config';

    /**
     * Searchable config property names.
     * @type {string[]}
     * @protected
     */
    Collection.prototype.searchFields_ = ['name'];

    /**
     * Hash of all available data sources for this Collection. Ids must be unique within
     * Collection and do not intersect with data field names.
     * @type {{string: CollDataSourceConfig}}
     * @protected
     */
    Collection.prototype.allDataSources_ = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    /**
     * For compatibility with previous collection version these params will be used to set
     * up the initial parameter values though {@link Collection#setParams} by constructor.
     * @type {{string: *}|null}
     * @deprecated
     * @protected
     */
    Collection.prototype.params_ = null;

    /**
     * Default viewport size (aka limit) to be used for Collections without smart layout informing
     * about its size. This is deprecated, and setting "limit" as an argument to the collection
     * constructor is the new and right way to set `page_size`.
     * @type {number}
     * @protected
     */
    Collection.prototype.defaultViewportSize_ = 30;

    /**
     * Returns a private property value.
     * @returns {number}
     */
    Collection.prototype.getDefaultViewportSize = function() {
        return this.defaultViewportSize_;
    };

    /**
     * Returns collection limit.
     * @returns {number}
     */
    Collection.prototype.getLimit = function() {
        return this.limit_;
    };

    Collection.prototype.overLimitCoeff_ = 0.25;

    Collection.prototype.objectName_ = '';

    Collection.prototype.windowElement_ = '';

    Collection.prototype.itemClass_ = Item;

    Collection.prototype.serverDefaultsOverride_ = null;

    Collection.prototype.isStatic_ = true;

    /**
     * Loading indicator.
     * @type {boolean}
    **/
    Collection.prototype.busy = false;

    /**
     * In case of backend returned error, it's going to be here.
     * @type {Object|string|null}
     **/
    Collection.prototype.errors = null;

    /**
     * Default config properties for the new Items created by this Collection.
     * @type {Object|null}
     * @protected
     **/
    Collection.prototype.defaults_ = {};

    /**
     * Set item defaults.
     * @param {Object} defaultObject
     */
    Collection.prototype.setNewItemDefaults = function(defaultObject) {
        this.defaults_ = defaultObject;
    };

    /**
     * Shortcut for {@link Item#getIdFromData} method with only difference that `this` is not
     * defined so it works only when {@link Item#data.config} is being passed as an argument.
     * @param {Item#data#config} data
     * @returns {Item#id}
     */
    Collection.prototype.getItemIdFromData = function(...data) {
        const methodName = 'getIdFromData';

        if (methodName in this.itemClass_) { //static method is defined
            return this.itemClass_[methodName](...data);
        } else {
            return this.itemClass_.prototype[`${methodName}_`].call(undefined, ...data);
        }
    };

    /**
     * Sets the sorting param on data source responsible for `config` data field.
     * @param {string=} value - Back-end field name to be used for sorting.
     * @returns {boolean} True if new value has been set or false otherwise.
     */
    //TODO control the list of sorting parameters through `config` DS
    Collection.prototype.setSorting = function(value) {
        if (_.isUndefined(value) || angular.isString(value)) {
            return this.getDataSourceByFieldName('config').setSortParam(value);
        }

        return false;
    };

    /**
     * Sets the sorting parameter on the data source responsible for `config` data field,
     * empties Collection and calls {@link Collection#load}.
     * @param {string=} propertyName
     * @returns {angular.$q.promise}
     */
    Collection.prototype.sort = function(propertyName) {
        let promise;

        if (this.setSorting(propertyName)) {
            promise = this.load(undefined, true);
        } else {
            promise = $q.reject('Wrong arguments passed.');
        }

        return promise;
    };

    /**
     * Sorts and loads provided offset and limit.
     * @param {string} propertyName - Name of property to sort.
     * @param {number} offset - Items offset to load.
     * @param {number} limit - Number of items to load.
     * @returns {ng.Promise}
     */
    Collection.prototype.sortPage = function(propertyName, offset = 0, limit = 0) {
        let promise;

        if (this.setSorting(propertyName)) {
            promise = this.loadPage(offset, limit);
        } else {
            promise = $q.reject('Wrong arguments passed.');
        }

        return promise;
    };

    /**
     * Returns the current sorting parameter set on `config` field data source.
     * @returns {string|undefined}
     */
    Collection.prototype.getSorting = function() {
        return this.getDataSourceByFieldName('config').getSortParam();
    };

    /**
     * Sets the search parameter over the data source responsible for `config` data field.
     * @param {string=} str
     * @returns {boolean} True when new search value has been set or false otherwise.
     */
    Collection.prototype.setSearch = function(str) {
        let res = false;

        if (_.isUndefined(str) || str === null || angular.isString(str)) {
            res = this.getDataSourceByFieldName('config').setSearchParam(
                this.getSearchFields_(),
                str,
            );
        }

        return res;
    };

    /**
     * Sets the search parameter on the data source responsible for `config` data field,
     *     empties Collection and calls {@link Collection#load}.
     * @param {string=} str
     * @returns {angular.$q.promise}
     */
    Collection.prototype.search = function(str) {
        let promise;

        if (this.setSearch(str)) {
            promise = this.load(undefined, true);
        } else {
            promise = $q.reject('Wrong arguments passed.');
        }

        return promise;
    };

    /**
     * Collection may support search by a few config properties. Here we can get a list of those.
     * @returns {string[]}
     * @protected
     */
    Collection.prototype.getSearchFields_ = function() {
        return this.searchFields_.concat();
    };

    /**
     * Extends the {@link Collection#defaults_} with provided object. Important difference from
     * angular.extend is the removal of all variables with `undefined` values after extending.
     * @param {Item#data.config} params
     * @param {boolean=} rewrite - Old values will be removed before extending by new ones.
     * @returns {boolean} - True if operation was successful, false otherwise.
     */
    //TODO may need `delete` (aka fallback to defaults) support through an extra param
    Collection.prototype.setDefaultItemConfigProps = function(params, rewrite) {
        let res = false;

        if (angular.isObject(params)) {
            if (angular.isObject(this.defaults_)) {
                if (rewrite) {
                    this.defaults_ = {};
                }

                angular.merge(this.defaults_, params);

                res = true;
            } else {
                console.error('Can\'t set default values of collection "%s" since property ' +
                    '"defaults_" has type different from "object"', this.objectName_);
            }
        }

        return res;
    };

    /**
     * Returns default data object for the new Item.
     * @returns {Item#data.config} - Default Item's config object.
     * @protected
     */
    Collection.prototype.getDefaultItemConfig_ = function() {
        const res = angular.merge(
            defaultValues.getDefaultItemConfigByType(
                this.objectName_.replace('-inventory', '').toLowerCase(),
            ) || {},
            this.serverDefaultsOverride_,
        );

        // Then override it with customizable defaults
        if (angular.isObject(this.defaults_)) {
            angular.merge(res, this.defaults_);
        } else if (angular.isFunction(this.defaults_)) { //DEPRECATED
            this.defaults_(res);
        }

        return res;
    };

    /**
     * Returns the number of Items we current have in Collection.
     * @returns {number}
     */
    Collection.prototype.getNumberOfItems = function() {
        return this.items.length;
    };

    /**
     * Returns the `total` number of Collection Items backend has. Provided by data source
     * of the `config` field. Undefined if not supported.
     * @returns {number|undefined}
     */
    Collection.prototype.getTotalNumberOfItems = function() {
        const config = this.getDataSourceByFieldName('config');

        if (!config) {
            return 0;
        }

        return config.getTotalNumberOfItems();
    };

    /**
     * Returns true for APIs which provide total number of items on every update. False otherwise.
     * @returns {boolean}
     */
    Collection.prototype.hasRealTotalNumberOfItems = function() {
        const configDS = this.getDataSourceByFieldName('config');

        return configDS.hasRealTotalNumberOfItems();
    };

    /**
     * Removes an Item from Collection. Doesn't make any API calls - just calls
     * Item#destroy and removes it from {@link Collection#items} and {@link Collection#itemById}.
     * @param {Item|Item#id} item - Instance or it's id.
     */
    Collection.prototype.removeItem = function(item) {
        let index,
            visIdsIndex,
            id;

        if (item && typeof item === 'string' && item in this.itemById) {
            item = this.itemById[item];
        }

        if (item instanceof this.itemClass_) {
            id = item.getIdFromData();

            index = this.items.indexOf(item);

            if (index !== -1) {
                this.items.splice(index, 1);
            }

            if (id in this.itemById) {
                delete this.itemById[id];
            }

            visIdsIndex = this.visibleItemIds_.indexOf(id);

            if (visIdsIndex !== -1) {
                this.visibleItemIds_.splice(visIdsIndex, 1);
            }

            item.destroy();
        } else {
            throw new Error(`Wrong Item id or instance has been passed to removeItem method: ${
                item}`);
        }
    };

    /**
     * Finds an Item in Collection and extends it's Item#data with newData object.
     * @param {Item|Item#id} item - Instance or it's id.
     * @param newData {Item#data} - I.e: {config: {id: 'xyz'}, runtime: undefined}
     * @returns {Item|undefined} Undefined if wrong arguments or Item was not found in Collection.
     */
    Collection.prototype.updateItemData = function(item, newData) {
        let res;

        if (angular.isObject(newData) && item) {
            if (item in this.itemById) {
                item = this.itemById[item];
            }

            if (item instanceof this.itemClass_) {
                res = item;
                item.updateItemData(newData);
            }
        }

        return res;
    };

    /**
     * Appends item to {@link Collection#items} and {@link Collection#itemById}.
     * @param {Item|Item#data} iData - Instance or object with at least `config` properties.
     * @param {number=} index - When passed points Item ot the certain place in a list,
     *     otherwise appends it to the end of the list.
     * @param {boolean=} loadOnAppend - When true calls {@link Collection#load}.
     * @returns {Item|undefined}
     */
    Collection.prototype.appendItem = function(iData, index, loadOnAppend) {
        let res,
            item,
            itemId;

        const isInstance = iData instanceof this.itemClass_;

        if (isInstance || (angular.isObject(iData) && this.getItemIdFromData(iData))) {
            item = isInstance ? iData : this.getNewItem({ data: iData });
            itemId = item.getIdFromData();

            if (itemId && !(itemId in this.itemById)) {
                item.windowElement = this.windowElement_;
                item.collection = this;

                //TODO set event listeners

                index = typeof index === 'number' && index >= 0 && index <= this.items.length ?
                    index : this.items.length;

                this.items[index] = item;
                this.itemById[itemId] = item;

                if (loadOnAppend) {
                    this.load();
                }

                res = item;
            } else {
                console.error('Can\'t append Item %O since Collection "%s" already has as Item' +
                    ' with same id "%s" or it is faulty.', item, this.objectName_, itemId);
            }
        }

        return res;
    };

    /**
     * Appends Item to the Collection and calls Collection#load after. Legacy thing.
     * @param {Item} item
     * @returns {Item|undefined}
     * @deprecated Use {@link Collection#appendItem} instead.
     */
    Collection.prototype.append = function(item) {
        return this.appendItem(item, undefined, true);
    };

    /**
     * Shortcut for Item's constructor. No default values, no relation to Collection, just
     * direct access to Item's constructor. When you too lazy to inject Item Class directly.
     * Discouraged.
     * @param {*=} args - Item's constructor properties.
     * @returns {Item} new Instance without any connection to Collection.
     */
    Collection.prototype.getNewItem = function(args) {
        if (!angular.isObject(args)) {
            args = {};
        }

        if (!args.objectName && !this.itemClass_.prototype.objectName) {
            args.objectName = this.objectName_;
        }

        return new this.itemClass_(args);// eslint-disable-line new-cap
    };

    /**
     * Creates a new instance of itemClass with default values populated for Item#data.config.
     * @param {*=} args - Item constructor properties.
     * @param {boolean=} isLone - True if we this Item won't have any connection with the
     *     Collection.
     * @returns {Item}
     */
    Collection.prototype.createNewItem = function(args, isLone) {
        const constrArgs = { data: {} };

        if (!isLone) {
            constrArgs.collection = this;
        }

        const
            haveArgsObject = angular.isObject(args),
            haveDataPassed = haveArgsObject && angular.isObject(args.data);

        //haveDataPassed will be overwritten, also might not be present yet
        if (!haveDataPassed) {
            constrArgs.data.config = this.getDefaultItemConfig_();
        }

        if (haveArgsObject) {
            angular.extend(constrArgs, args);
        }

        const item = this.getNewItem(constrArgs);

        // Make sure new instance has objectName and windowElement
        if (!item.objectName) {
            item.objectName = this.objectName_;
        }

        if (!item.windowElement) {
            item.windowElement = this.windowElement_;
        }

        return item;
    };

    /**
     * Gets an Item by Item's id.
     * @param {Item#id} itemId
     * @returns {Item|undefined} - Undefined if Item with provided id was not found in Collection.
     */
    Collection.prototype.getItemById = function(itemId) {
        let item;

        if (itemId && typeof itemId === 'string' && itemId in this.itemById) {
            item = this.itemById[itemId];
        }

        return item;
    };

    /**
     * Looks up Collection Item by name.
     * @param {string} itemName
     * @returns {Item|null}
     */
    Collection.prototype.getItemByName = function(itemName) {
        return itemName && _.find(this.items, item => item.getName() === itemName) || null;
    };

    /**
     * Returns true if create function is available for Collection's objectName.
     * @returns {boolean}
     */
    Collection.prototype.isCreatable = function() {
        const isAllowed = Auth.isAllowed(
            this.permissionName_ || this.objectName_.replace('-inventory', ''),
            'w',
        );

        return this.windowElement_ && Auth.context && Auth.context.tenant_ref !== '*' &&
            isAllowed || false;
    };

    /**
     * Opens create dialog window via {@link AviModal}. Makes sure defaults have been loaded.
     * @param {string=} windowElement - Modal window unique id. When not set default edit modal
     *     id will be used.
     * @param {Object=} params - All properties of this object will be passed into
     *     {@link AviModal.open Modal window} scope.
     * @returns {angular.$q.promise}
     */
    Collection.prototype.create = function(windowElement, params) {
        const editParams = angular.merge({}, this.createParams_, params);

        return defaultValues.load()
            .then(() => this.createNewItem().edit(windowElement, editParams));
    };

    /**
     * Used when a non-admin tenant edits an admin-created profile/group. Creates a new profile
     * Collection Item but copies the settings from another. Used for profiles and groups.
     * @param {Item} item - Item to be copied.
     * @return {ng.$q.promise}
     */
    Collection.prototype.clone = function(item) {
        let configLoadPromise;

        if (item.loadOnEdit) {
            configLoadPromise = item.load().then(() => item.getConfig());
        } else {
            configLoadPromise = $q.when(item.getConfig());
        }

        return configLoadPromise.then(config => {
            config = angular.copy(config);

            ['uuid', 'tenant_ref', 'url']
                .forEach(fieldName => delete config[fieldName]);

            return this.createNewItem({ data: { config } }).edit();
        });
    };

    /**
     * Loads specified offset and limit.
     * @param {number} offset - Number of items to skip.
     * @param {number} limit - Number of items to load.
     */
    Collection.prototype.loadPage = function(offset = 0, limit = 0) {
        this.viewportSize_ = limit;
        this.overLimitCoeff_ = 0;
        this.groupDataSourceMethodCall_('setOffset_', offset, undefined, true).finally(() => {
            this.groupDataSourceMethodCall_('setLimit_', limit, true).finally(() => {
                this.emptyData(false);
                this.load();
            });
        });
    };

    /**
     * Called by viewer to inform collection of currently visible items. Notifies all data sources.
     * @param {number|string[]} itemIds - Array of ids or number of items (page_size). When
     *     not provided all items are set as visible.
     * @param {number=} offset - number of Items hidden above the viewport's top. Defaults to 0.
     * @param {boolean=} immediateCall - SetLimit is usually debounced and sometimes we want
     *     to call it instantly. When true is passed will be called instantly.
     * @returns {ng.$q.promise}
     */
    Collection.prototype.updateItemsVisibility = function(itemIds, offset, immediateCall) {
        const prevVisibleItems = angular.copy(this.visibleItemIds_);

        this.visibleItemsOffset_ = offset || 0;

        if (itemIds && typeof itemIds === 'number') {
            itemIds = _.map(this.items.slice(offset, offset + itemIds), function(item) {
                return item.getIdFromData();
            });
        } else if (_.isUndefined(itemIds) || itemIds === 0) {
            itemIds = this.getItemIds();
        }

        if (Array.isArray(itemIds)) {
            this.visibleItemIds_ = itemIds;
        } else if (typeof itemIds === 'number' && itemIds > 0) {
            //indexes of items inside this.items
            this.visibleItemIds_ = _.pluck(
                this.items.slice(this.visibleItemsOffset_, this.visibleItemsOffset_ + itemIds),
                'id',
            );
        } else {
            this.visibleItemIds_.length = 0;
        }

        if (prevVisibleItems.length !== this.visibleItemIds_.length ||
            _.intersection(prevVisibleItems, this.visibleItemIds_).length !==
            prevVisibleItems.length) {
            this.trigger('visibleItemsListUpdate', this.visibleItemIds_, prevVisibleItems);
        }

        return this.groupDataSourceMethodCall_(
            immediateCall ? 'setOffset_' : 'setOffset',
            this.visibleItemsOffset_,
            this.visibleItemIds_,
        );
    };

    /**
     * Returns list of Item ids this collection has.
     * @returns {Item.id[]}
     */
    Collection.prototype.getItemIds = function() {
        return Object.keys(this.itemById);
    };

    /**
     * Called by viewer to inform Collection of the viewport size, meaning how many Items do we
     * need to load and show. Notifies data sources.
     * @param {number=} size
     * @param {boolean=} dontLoad - Pass true to avoid immediate loading of DataSources.
     * @param {boolean=} immediateCall - SetLimit is usually debounced and sometimes we want
     *     to call it instantly. When true is passed will be called instantly.
     * @returns {ng.$q.promise}
     */
    Collection.prototype.updateViewportSize = function(size, dontLoad, immediateCall) {
        if (typeof size === 'number' && _.isFinite(size) && size > 0) {
            this.viewportSize_ = Math.round(size);
        } else {
            this.viewportSize_ = this.defaultViewportSize_;
        }

        return this.groupDataSourceMethodCall_(
            immediateCall ? 'setLimit_' : 'setLimit',
            this.viewportSize_,
            dontLoad,
        );
    };

    /**
     * Return the current viewport size set for Collection.
     * @returns {number}
     */
    Collection.prototype.getViewportSize = function() {
        return this.viewportSize_;
    };

    /**
     * Actually deletes Items by making API calls to the back-end through Item#drop.
     * @param {Item|Item[]|Item.id| Item.id[]} ids
     * @param {boolean=} reload - When set to true Collection will reload itself after dropping.
     * @param {boolean=} force - Special back-end flag to remove some related objects.
     * @param {Object=} dropParams - Optional parameters to be passed to Item#drop.
     * @param {boolean=} showAlert - Display Avi Alert when delete fails.
     * @returns {angular.$q.promise}
     */
    Collection.prototype.dropItems = function(ids, reload, force, dropParams, showAlert = true) {
        const promises = [];

        let promise,
            deletedSome = false;

        if (ids) {
            reload = _.isUndefined(reload) || !!reload;
            force = !!force;

            if (!Array.isArray(ids)) {
                ids = [ids];
            }

            _.each(ids, function(id) {
                if (id instanceof this.itemClass_) {
                    id = id.getIdFromData();
                }

                if (id && typeof id === 'string' && id in this.itemById) {
                    promises.push(
                        this.itemById[id].drop(force, dropParams, showAlert)
                            .then(function(rsp) {
                                deletedSome = true;

                                return rsp;
                            }),
                    );
                }
            }, this);

            if (promises.length) {
                promise = $q.all(promises)
                    .finally((function() {
                        if (deletedSome && reload) {
                            return this.load();
                        }
                    }).bind(this));
            } else {
                promise = $q.reject(`No id's were found in Collection "${
                    this.objectName_}".`);
            }
        } else {
            promise = $q.reject('No id\'s were provided.');
        }

        return promise;
    };

    /**
     * Removes all references from Collection to Item. Called by Item#drop method.
     * @param {Item#id} itemId
     * @fires Collection#"collectionItemDrop collectionItemDropSuccess"
     */
    //TODO differs from group removal - no reload happens
    Collection.prototype.onItemDrop = function(itemId) {
        let res = false,
            itemIndex,
            visibleItemIndex;

        if (itemId && typeof itemId === 'string' && itemId in this.itemById) {
            itemIndex = _.findIndex(this.items, function(item) {
                return item.id === itemId;
            });

            if (itemIndex > -1) {
                this.items.splice(itemIndex, 1);
            }

            delete this.itemById[itemId];

            visibleItemIndex = this.visibleItemIds_.indexOf(itemId);

            if (visibleItemIndex > -1) {
                this.visibleItemIds_.splice(visibleItemIndex, 1);
            }

            /**
             * Triggers after successful DELETE API operation on Item belonging to the instance.
             * @event Collection#"collectionItemDrop collectionItemDropSuccess"
             * @type {Item#id}
             */
            this.trigger('collectionItemDrop collectionItemDropSuccess', itemId);

            res = true;
        }

        return res;
    };

    /**
     * Empties the Collection by removing all Items and notifying the `config` field data source.
     * @param {boolean} update - Optional boolean to trigger flush event. Defaults to True.
     */
    Collection.prototype.emptyData = function(update = true) {
        _.each(this.itemById, item => this.removeItem(item));

        this.items.length = 0;
        this.itemById = {};

        if (update) {
            this.updateItemsVisibility();
            this.getDataSourceByFieldName('config').onDataFlush();
            Collection.superclass.emptyData.call(this);
        }
    };

    /**
     * Removes all Items from Collection and all non-required data sources.
     **/
    //TODO reset params through data source
    Collection.prototype.reset = function() {
        if (this.isDestroyed()) {
            return;
        }

        this.emptyData();
        this.setDefaultItemConfigProps({}, true);
        this.setSearch();
        this.updateViewportSize(undefined, true);

        Collection.superclass.reset.call(this);

        //sorting is reset by superclass.reset
        this.setSorting(this.sortBy_);
    };

    /**
     * Destroys the Collection. Main point is to stop all updates, network calls and remove
     * event listeners. Takes care of Items and all data sources.
     * @returns {boolean} True if got destroyed, false if had been destroyed before.
     */
    Collection.prototype.destroy = function() {
        const gotDestroyed = Collection.superclass.destroy.call(this);

        if (gotDestroyed) {
            this.emptyData(false);
        }

        return gotDestroyed;
    };

    return Collection;
}

collectionFactory.$inject = [
    '$q',
    'UpdatableBase',
    'Item',
    'AviModal',
    'Auth',
    'aviInherit',
    'defaultValues',
];

angular.module('core.vantage.avi')
    .factory('Collection', collectionFactory);
