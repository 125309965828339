/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} Scheduler
 */
angular.module('aviApp').factory('Scheduler', ['Item', Item => {
    const Scheduler = class extends Item {
        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.data.config);

            return {
                model_name: 'scheduler',
                data: config,
            };
        }

        /**
         * @override
         */
        urlToSave() {
            return '/api/macro';
        }
    };

    angular.extend(Scheduler.prototype, {
        objectName: 'scheduler',
        windowElement: 'scheduler-create',
        params: {
            include_name: true,
            join: 'backupconfiguration:backup_config_ref',
        },
    });

    return Scheduler;
}]);
