/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name activateAviModalJQueryPlugin
 * @description
 *
 *     Factory to be called once with jQuery instance passed to register
 *     a jQuery plugin which shows or hides Modal windows while stacking them.
 *
 *     @function external:"jQuery.fn".aviModal.
 *     @param {string} action - Hide or show.
 *
 */
angular.module('aviApp').factory('activateAviModalJQueryPlugin', function() {
    return function($) {
        let container,
            backdrop,
            backdropTransparent;

        const winStack = [];

        function initialize() {
            if (!container) {
                container = $('<div class="modal-scrollable" style="z-index: 1099;"/>');

                backdrop = $('<div class="modal-backdrop animated fadeIn"' +
                    'style="z-index: 1098;"/>');

                backdropTransparent =
                    $('<div class="modal-backdrop" style="z-index: 1099; opacity:0"/>');

                $(document.body).append([container, backdrop, backdropTransparent]);
            }
        }

        $.fn.aviModal = function(action) {
            initialize();

            if (action === 'hide') {
                $(this)
                    .hide()
                    .trigger('hidden');

                // Clear the form to look pristine
                $(this).find('.changed')
                    .removeClass('changed');

                if (winStack.length === 1) {
                    container.hide()
                        .children()
                        .removeClass('first');

                    backdrop.hide();
                    backdropTransparent.hide();
                }

                winStack.pop();

                const zIndex = 10000 + 10 * winStack.length;

                backdropTransparent.css('z-index', zIndex);
                backdrop.css('z-index', zIndex - 1);
            } else {
                const win = $(this);

                //skip if we already have this modal opened (ex: fast double click)
                if (winStack.indexOf(win) == -1) {
                    win.show().addClass('fadeInDown').trigger('focus');

                    setTimeout(function() {
                        win.removeClass('fadeInDown');
                    }, 500);

                    if (!winStack.length) {
                        win.addClass('first');
                    }

                    winStack.push(win);

                    win.css({
                        'z-index': 10000 + 10 * winStack.length + 1,
                        top: `${4 + winStack.length}%`,
                        left: `${6.5 + winStack.length}%`,
                    });

                    const zIndex = 10000 + 10 * winStack.length;

                    // Put window in a stack for further manipulations
                    container.show();
                    backdrop
                        .show()
                        .css('z-index', zIndex - 1);

                    backdropTransparent
                        .show()
                        .css('z-index', zIndex);
                }
            }
        };

        // Catch escape click
        $(window).on('keyup', function(event) {
            if (event.keyCode === 27 && winStack.length) {
                // If there are confirm buttons then instead of just closing the window
                // need to trigger click
                const closeButtons = $(winStack[winStack.length - 1])
                    .find('button.close-button, button.close');

                if (closeButtons.length) {
                    $(closeButtons[0]).trigger('click');
                } else {
                    console.warn('jQuery aviModal plugin: can\'t close modal since close button' +
                        ' wasn\'t found');
                }
            }
        });
    };
});
