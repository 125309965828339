/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  FaultService
 * @description
 *     Service that manages both Controller and Virtual Service faults.
 *     Virtual Service faults come from the "virtualservice-inventory" request, and
 *     handleVirtualserviceUpdate is called if faults are detected.
 *     Controller faults come from the "controller-inventory" request, and this service is
 *     responsible for creating the ControllerFaultCollection instance and loading/destroying it.
 *     The collection is not static and continues to load.
 *     If a Controller fault is detected, a message is compiled to be displayed to the user. The
 *     user can close that message, and it will stay closed until a different set of faults is
 *     returned in the response.
 *
 * @typedef {Object[]} VirtualServiceFaults
 * @description
 *     An array of Virtual Service fault objects.
 */
class FaultService {
    constructor($rootScope, $state, $transitions, $compile, messageService,
            ControllerFaultCollection) {
        this.$rootScope_ = $rootScope;
        this.$state_ = $state;
        this.$compile_ = $compile;
        this.messageService_ = messageService;
        this.ControllerFaultCollection_ = ControllerFaultCollection;

        this.controllerFaultsMessageScope_ = null;
        this.compiledControllerFaultsMessage_ = null;

        // This hash is used to keep track of virtualservice ids to faults. The virtualservice
        // faults message should only be compiled if the incoming faults differ from previous
        // faults.
        this.virtualserviceFaultsHash = {};
        this.currentVirtualserviceId = '';

        this.virtualserviceFaultsMessageScope_ = null;
        this.compiledVirtualserviceFaultsMessage_ = null;

        /**
         * Flag to show fault message on login only.
         * @type {boolean}
         */
        this.ignoreControllerFaults_ = false;

        $transitions.onSuccess({}, this.stateChangeSuccessHandler_.bind(this));
    }

    /**
     * Called when app state has changed. If the state is not within Virtual Service Detail,
     * remove the message.
     * @private
     */
    stateChangeSuccessHandler_() {
        if (!this.$state_.params.vsId ||
                this.$state_.params.vsId !== this.currentVirtualserviceId) {
            if (this.virtualserviceFaultsMessageScope_ ||
                    this.compiledVirtualserviceFaultsMessage_) {
                this.destroyVirtualserviceFaultsMessage_();
            }
        }
    }

    /**
     * Set listeners to listen for $rootScope $broadcast events. Loads/destroys collection
     * and opens/closes message.
     */
    setListeners() {
        this.$rootScope_.$on('setContext', () => this.start());
        this.$rootScope_.$on('userLoggedOut', () => {
            this.ignoreControllerFaults_ = false;
            this.stop();
        });
    }

    /**
     * Called when user is logged in to the application. Should be called both when the user
     * logs in and when user refreshes the page. Sets localStorage.controllerFaults and calls
     * getControllerFault_();
     */
    start() {
        this.stop();

        if (!localStorage.getItem('controllerFaults')) {
            localStorage.setItem('controllerFaults', '');
        }

        this.getControllerFaults_();
    }

    /**
     * Called after ControllerFaultCollection load.
     * @private
     */
    onCollectionLoadSuccess_() {
        const
            faults = this.controllerFaultCollection_.getFaultDescriptions(),
            desc = faults.sort().join(','),
            descCache = localStorage.getItem('controllerFaults');

        if (descCache !== desc) {
            if (desc && desc.length) {
                if (this.compiledControllerFaultsMessage_) {
                    this.destroyControllerFaultsMessage_();
                }

                this.compileControllerFaultsMessage_();
            }

            localStorage.setItem('controllerFaults', desc);
        }
    }

    /**
     * Binds collectionLoadSuccess event to compile Controller faults message if new incoming
     * faults are detected, then loads ControllerFaultCollection.
     * @private
     */
    getControllerFaults_() {
        if (this.ignoreControllerFaults_) {
            return;
        }

        this.ignoreControllerFaults_ = true;
        this.controllerFaultCollection_ = new this.ControllerFaultCollection_({
            bind: {
                collectionLoadSuccess: this.onCollectionLoadSuccess_.bind(this),
            },
        });

        this.controllerFaultCollection_.load();
    }

    /**
     * Compiles controllerFaultsMessage component to display Controller faults to div.messages.
     * @private
     */
    compileControllerFaultsMessage_() {
        this.controllerFaultsMessageScope_ = this.$rootScope_.$new();

        const scope = this.controllerFaultsMessageScope_;

        scope.collection = this.controllerFaultCollection_;
        scope.handleCloseMessage = this.destroyControllerFaultsMessage_.bind(this);

        this.compiledControllerFaultsMessage_ = this.$compile_(
            `<controller-faults-message
                    class="message wide animated fadeIn"
                    collection="collection"
                    on-close-message="handleCloseMessage()"
                    messenger="controllerFaultsMessage" />`,
        )(scope);

        this.messageService_.append(
            'controllerFaultsMessage', this.compiledControllerFaultsMessage_,
        );
    }

    /**
     * Removes compiled controllerFaultsMessage component.
     * @private
     */
    destroyControllerFaultsMessage_() {
        this.messageService_.hide('controllerFaultsMessage');
        this.messageService_.remove('controllerFaultsMessage');
        this.controllerFaultsMessageScope_.$destroy();
        this.compiledControllerFaultsMessage_ = null;
    }

    /**
     * Called by VirtualServiceFaultDataTransformer if a fault is detected from the response to
     * the "virtualservice-inventory" request. Only do something when state is within Virtual
     * Service detail.
     * @param  {VirtualServiceFaults} faults
     */
    handleVirtualserviceUpdate(faults) {
        if (!this.$state_.params.vsId) {
            return;
        }

        const
            id = this.$state_.params.vsId,
            descriptions = FaultService.getVirtualserviceInventoryFaultsDescriptions_(faults),
            desc = descriptions.sort().join(','),
            descCache = this.getVirtualserviceCachedDescriptions_(id);

        this.currentVirtualserviceId = id;

        if (descCache !== desc) {
            if (desc && desc.length) {
                if (this.compiledVirtualserviceFaultsMessage_) {
                    this.destroyVirtualserviceFaultsMessage_();
                }

                this.compileVirtualserviceFaultsMessage_(faults);
            }

            this.setVirtualserviceCachedDescriptions_(id, desc);
        }
    }

    /**
     * Returns an array of all Virtual Service fault descriptions.
     * @param  {VirtualServiceFaults} faults
     * @return {string[]}
     * @private
     */
    static getVirtualserviceInventoryFaultsDescriptions_(faults) {
        const descriptions = [];

        faults.forEach(item => {
            if (item.id === 'server_faults') {
                item.faults.forEach(fault => {
                    fault.servers.forEach(server => descriptions.push(server.description));
                });
            } else {
                item.faults.forEach(fault => descriptions.push(fault.description));
            }
        });

        return descriptions;
    }

    /**
     * Gets the cached string of fault descriptions for a Virtual Service.
     * @param  {VirtualService.id} id - Virtual Service id.
     * @return {string}
     * @private
     */
    getVirtualserviceCachedDescriptions_(id) {
        return this.virtualserviceFaultsHash[id];
    }

    /**
     * Sets the cached string of fault descriptions for a Virtual Service.
     * @param {VirtualService.id} id - Virtual Service id.
     * @param {string} desc - String of Virtual Service fault descriptions.
     * @private
     */
    setVirtualserviceCachedDescriptions_(id, desc) {
        this.virtualserviceFaultsHash[id] = desc;
    }

    /**
     * Compiles virtualserviceFaults component to display Virtual Service faults to
     * div.messages.
     * @param  {VirtualServiceFaults} faults
     * @private
     */
    compileVirtualserviceFaultsMessage_(faults) {
        if (this.virtualserviceFaultsMessageScope_ ||
                this.compiledVirtualserviceFaultsMessage_) {
            this.destroyVirtualserviceFaultsMessage_();
        }

        this.virtualserviceFaultsMessageScope_ = this.$rootScope_.$new();

        const scope = this.virtualserviceFaultsMessageScope_;

        scope.faults = faults;
        scope.handleCloseMessage = this.destroyVirtualserviceFaultsMessage_.bind(this);

        this.compiledVirtualserviceFaultsMessage_ = this.$compile_(
            `<virtualservice-faults
                    class="message wide animated fadeIn"
                    faults="faults"
                    view="message"
                    on-close-message="handleCloseMessage()"
                    messenger="virtualserviceFaultsMessage" />`,
        )(scope);

        this.messageService_.append(
            'virtualserviceFaultsMessage', this.compiledVirtualserviceFaultsMessage_,
        );
    }

    /**
     * Removes compiled virtualserviceFaults component.
     * @private
     */
    destroyVirtualserviceFaultsMessage_() {
        this.messageService_.hide('virtualserviceFaultsMessage');
        this.messageService_.remove('virtualserviceFaultsMessage');
        this.virtualserviceFaultsMessageScope_.$destroy();
        this.compiledVirtualserviceFaultsMessage_ = null;
    }

    /**
     * Called when user is logged out from the application. Destroys controllerFaultCollection
     * to stop polling for data.
     */
    stop() {
        localStorage.removeItem('controllerFaults');
        this.controllerFaultCollection_ && this.controllerFaultCollection_.destroy();

        if (this.controllerFaultsMessageScope_ || this.compiledControllerFaultsMessage_) {
            this.destroyControllerFaultsMessage_();
        }

        if (this.virtualserviceFaultsMessageScope_ ||
                this.compiledVirtualserviceFaultsMessage_) {
            this.destroyVirtualserviceFaultsMessage_();
        }
    }

    /**
     * Transforms the faults object from the response into an array of faults with "type" as the
     * "id".
     * @param  {Object} faults - Virtual Service faults object from "virtualservice-inventory"
     *     request.
     * @return {Object[]}
     */
    parseFaults(faults) {
        return _.map(faults, (faults, type) => {
            return {
                id: type,
                type,
                faults,
            };
        });
    }
}

FaultService.$inject = [
    '$rootScope',
    '$state',
    '$transitions',
    '$compile',
    'messageService',
    'ControllerFaultCollection',
];

angular.module('aviApp').service('FaultService', FaultService);
