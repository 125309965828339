/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function seGroupFlavorListDataTransformerFactory(ListDataTransformer, naturalSort) {
    /**
     * Compares two SEGroup flavors using naturalSort by passing down their name properties.
     * @param {string} nameA
     * @param {string} nameB
     * @return {number}
     * @protected
     */
    function seGroupFlavorComparator(
        { config: { name: nameA } },
        { config: { name: nameB } },
    ) {
        return naturalSort(nameA, nameB);
    }

    return class SEGroupFlavorListDataTransformer extends ListDataTransformer {
        /**
         * Sorts the list alphabetically. Esp useful since no search is provided by API.
         * @override
         **/
        processResponse(resp, requestParams) {
            resp = super.processResponse(resp, requestParams);

            const { data: { results } } = resp;

            results.sort(seGroupFlavorComparator);

            return resp;
        }
    };
}

/**
 * @ngdoc service
 * @name SEGroupFlavorListDataTransformer
 * @desc Data transformer for SEGroupFlavor collection.
 */
angular.module('aviApp').factory(
    'SEGroupFlavorListDataTransformer', [
        'ListDataTransformer',
        'naturalSort',
        seGroupFlavorListDataTransformerFactory,
    ],
);
