/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/performance-chart.less';

/**
 * @ngdoc directive
 * @name performanceChart
 * @restrict AE
 */
angular.module('aviApp').directive('performanceChart', [
'GraphSync', 'ChartService', 'Tooltip', 'Overlay', 'NamesHelper', 'Convert', 'Timeframe',
'seriesDataPointService',
function(GraphSync, ChartService, Tooltip, Overlay, NamesHelper, Convert, Timeframe,
seriesDataPointService) {
    function link(scope, elm, attr) {
        // Tracks which series should not be graphed, passed to ChartService as well
        scope.hiddenSeries = [];

        if (scope.useZoom) {
            scope.zoomIn = function() {
                scope.chart.zoomIn();
            };

            scope.zoomOut = function() {
                scope.chart.zoomOut();
            };
        }

        // -------- Basic Settings ----------- //
        function resetSettings() {
            scope.tooltips = {};
            scope.display = null;
            scope.legend = {
                display: false,
            };
            scope.settings = {
                useZoom: scope.useZoom || false,
                padding: {
                    top: 45,
                    right: 20,
                    bottom: 20,
                    left: 50,
                },
                axisSettings: {
                    xTicks: elm.width() < 700 ? 4 : 10,
                },
            };
        }

        resetSettings();

        function makeChart() {
            scope.settings.timeTickFormat = Timeframe.selected().timeTickFormat;

            return new ChartService(chartElm, scope.settings);
        }

        const chartElm = $(elm.find('.performance-chart-graph'));

        function recreateTheChart() {
            destroyChart();

            // Look at the first series in the active card to see if data has been loaded
            const
                { metric } = scope.config.getCard(),
                series = metric.getMainSeries();

            if (series && series.hasData()) {
                scope.chart = makeChart();
                render();
                handleNewCollection();
            }
        }

        // Behavior
        // -------------- Listeners and Watchers ---------------------//
        scope.$watch(() => {
            // Chart has been changed to different time frame (series.values.length will be zero)
            // Chart needs to be created (scope.chart undefined, series.values has length)
            // Chart needs to be updated (scope.chart defined, series.values has length)
            const
                { metric } = scope.config.getCard(),
                series = metric && metric.getMainSeries(),
                firstDataPoint = series && series.getFirstPoint() || null,
                latestDataPoint = series && series.getLatestPoint(true) || null;

            const firstDataPointHash =
                seriesDataPointService.getDataPointHashString(firstDataPoint);
            const latestDataPointHash =
                seriesDataPointService.getDataPointHashString(latestDataPoint);

            return `${firstDataPointHash}|${latestDataPointHash}`;
        }, () => {
            const
                { metric } = scope.config.getCard(),
                series = metric && metric.getMainSeries(),
                { chart } = scope;

            if (series) {
                if (chart && !series.hasData()) {
                    destroyChart();
                    makeOverlays();
                } else if (chart) {
                    render();
                    updateDisplayData();
                    updateGraph();
                } else if (series.hasData()) {
                    scope.chart = makeChart();
                    render();
                    handleNewCollection();
                }
            } else if (chart) {
                destroyChart();
                makeOverlays();
            }
        });

        scope.$watch('config.active', () => {
            // scope.config.active is changed when a new card is selected
            // when that happens we need to check if we have data for
            // the selected card and if so render & recreate
            scope.hiddenSeries.length = 0;

            const
                { metric } = scope.config.getCard(),
                series = metric && metric.getMainSeries();

            if (series && series.hasData()) {
                render();
                recreateTheChart();
            } else {
                // if no data, remake overlays so there aren't random overlays on an empty chart
                makeOverlays();
            }
        });

        // // ------ Destroying the Chart ------------//
        scope.$on('$destroy', function() {
            destroyChart();
        });

        scope.$on('repaint', recreateTheChart);

        // ----------- Making Overlays ---------//
        function removeAnomalies() {
            Overlay.removeAnomalies();
        }

        function makeOverlays() {
            Overlay.removeAll();

            if (!(scope.chart && scope.item)) {
                return;
            }

            Overlay.createAnomalies({
                elm,
                chart: scope.chart,
                padding: scope.settings.padding,
                item: scope.item,
                display: scope.display,
                config: scope.config,
            });

            Overlay.createEvents({
                elm,
                chart: scope.chart,
                padding: scope.settings.padding,
                item: scope.item,
                config: scope.config,
            });

            Overlay.onZoom = function() {
                destroyTooltips();
            };
        }

        scope.$watchGroup([
            'item.data.configEvents',
            'item.data.systemEvents',
        ], makeOverlays);

        scope.$on('events-and-alerts-toggled', makeOverlays);

        scope.$watchCollection('config.selectedDot', () => {
            // This is how we unselect dots, currently only done by hide table in
            // ChartWithOverlaysTables
            if (scope.config.selectedDot.type === null && scope.config.selectedDot.dot === null) {
                Overlay.clearAll();
                makeOverlays();
            }
        });

        scope.$watch(
            () => GraphSync.mouseOnGraph,
            value => {
                if (value && scope.chart) {
                    refreshTooltips();
                }
            },
        );

        // ------------- Scope Methods ------------ //
        scope.mouseMoveHandler = _.throttle(e => {
            const { chart } = scope;

            if (chart && chart.x && !GraphSync.stuck) {
                const close = chart.x.invert(
                    e.pageX - scope.settings.padding.left - elm.offset().left,
                );

                const
                    { metric } = scope.config.getCard(),
                    series = metric.getMainSeries();

                GraphSync.sync(
                    chart.findByX(series, close).timestamp,
                );

                updateTooltipPositions();
            }
        }, 16);

        scope.click = function() {
            GraphSync.toggle();
            _.each(scope.tooltips, function(tooltip) {
                tooltip.toggleStuck();
            });
        };

        scope.mouseLeave = function() {
            if (!GraphSync.stuck) {
                GraphSync.hide();
                _.each(scope.tooltips, function(tooltip) {
                    setReasonString();
                    tooltip.hide();
                });
            }
        };

        function setReasonString(point) {
            if (!point) {
                scope.currentReasonString = '';
                /**
                 * Value to get a color for the Reason string using HS filter.
                 * @type {number}
                 */
                scope.currentReasonColorValue = 0;

                return;
            }

            if (point.is_null && !point.reason) {
                scope.currentReasonString = 'No data';
                scope.currentReasonColorValue = 0;
            } else {
                scope.currentReasonString = point.reason;
                scope.currentReasonColorValue = point.value;
            }
        }

        // -------------- Main Graphing Function Code --------------- //
        // Handles case when we have a new collection or switch collections
        function render() {
            const
                { metric } = scope.config.getCard(),
                series = metric.getMainSeries();

            if (series && series.hasData()) {
                scope.unit = Convert.getVal(
                    series.getValue('max'),
                    series.getUnits(),
                ).unit;
            }
        }

        /**
         * Creates scope.display object which is used by the ChartService
         *
         */
        function updateDisplayData() {
            scope.display = {
                series: [],
                hiddenSeries: scope.hiddenSeries,
            };

            const { metric } = scope.config.getCard();

            if (!metric) {
                return;
            }

            const totalSeries = metric.getSeriesByType('total');

            if (totalSeries) {
                scope.display.totalSeries = totalSeries;
            }

            scope.display.series.push(...metric.getSeriesByType('regular'));

            const errorSeries = metric.getSeriesByType('error');

            if (errorSeries.length) {
                scope.display.errorSeries = errorSeries;
            }
        }

        function handleNewCollection() {
            let promise;

            updateDisplayData();

            // Graphing new Collection
            destroyTooltips();
            removeAnomalies();

            if (attr.type === 'bar') {
                promise = scope.chart.graphNewBarChartCollection(scope.display);
            } else {
                promise = scope.chart.graphNewCollection(scope.display);
            }

            promise.then(function() {
                if (!scope.chart) {
                    return;
                }

                refreshTooltips();
                makeOverlays();
                createEntriesForLegend();
            });
        }

        // Handles updating the chart after that -- making overlays, graphing, and tooltips
        function updateGraph() {
            if (attr.type === 'bar') {
                scope.chart.updateBarChartCollection(scope.display);
            } else {
                scope.chart.updateCollection(scope.display);
            }

            makeOverlays();
            refreshTooltips();
        }

        // ----------- Creating, Destroying, and Resetting Chart ---------//

        function destroyChart() {
            chartElm.empty();

            if (scope.chart) {
                scope.chart.destroy();
                scope.chart = null;
            }

            destroyTooltips();
            resetSettings();
        }

        // ---------------- Tooltip Code --------------//
        function destroyTooltips() {
            _.each(scope.tooltips, function(tooltip) {
                tooltip.destroy();
            });
            scope.tooltips = {};
        }

        function makeTooltips() {
            // make the first or last item the label.
            // if error series, make first item in the list the label
            // if stacked or single make the last item the label
            // This ensures that the label is always on the tallest graphed point
            let first = true,
                order = allVisibleSeries();

            const { metric } = scope.config.getCard();

            if (!metric.getSeriesByType('error').length) {
                order = order.reverse();
            }

            _.each(order, series => {
                const type = first ? 'label' : null;

                first = false;

                scope.tooltips[series] = new Tooltip({
                    chart: scope.chart,
                    data: metric.getSeries(series),
                    settings: {},
                    type,
                });
            });
        }

        function updateTooltipPositions() {
            const text = makeTooltipText();

            _.each(scope.tooltips, (tooltip, seriesName) => {
                // Check if mouse is on graph and series is not hidden
                if (GraphSync.mouseOnGraph && scope.hiddenSeries.indexOf(seriesName) === -1) {
                    // lookup point
                    const
                        { metric } = scope.config.getCard(),
                        series = metric.getSeries(seriesName);

                    let point = series.getDataPoint(GraphSync.mouseOnGraph);

                    // if switching times, need to lookup point with scope.chart.findByX because it
                    // will not match new time series
                    if (!point) {
                        point = scope.chart.findByX(series, GraphSync.mouseOnGraph);

                        if ((!point || !point.timestamp) && process.env.NODE_ENV !== 'production') {
                            console.warn('No point %i in %s timeseries, data of item: %O',
                                GraphSync.mouseOnGraph, seriesName, series);

                            return;
                        }

                        GraphSync.sync(point.timestamp);
                    }

                    setReasonString(point);
                    tooltip.updatePosition(point, text, !!GraphSync.stuck);
                } else {
                    setReasonString();
                    tooltip.hide();
                }
            });
        }

        function makeTooltipText() {
            const { metric } = scope.config.getCard();

            return _.map(allVisibleSeries(), fullSeriesId => {
                const series = metric.getSeries(fullSeriesId);

                return {
                    text: series.getTitle(),
                    className: series.getColorClassName(),
                    data: series,
                    metric,
                };
            });
        }

        function refreshTooltips() {
            destroyTooltips();
            makeTooltips();
            updateTooltipPositions();
        }

        // ----------- Legend -------------//
        function createEntriesForLegend() {
            function singleSeriesIsShown() {
                return scope.hiddenSeries.length + 1 === allSeriesArr.length;
            }

            const
                allSeriesArr = allSeries(),
                allSeriesKey = allSeriesArr.join('');

            if (!scope.legend[allSeriesKey]) {
                scope.legend[allSeriesKey] = allSeriesArr.map(fullSeriesId => {
                    const series = scope.item.data[fullSeriesId];

                    return {
                        text: series.getTitle(),
                        className: series.getColorClassName(),
                        name: fullSeriesId,
                        isLastVisible() {
                            return singleSeriesIsShown() && !this.isHidden();
                        },
                        isHidden() {
                            return scope.hiddenSeries.indexOf(this.name) !== -1;
                        },
                        toggle() {
                            const index = scope.hiddenSeries.indexOf(this.name);
                            let wasUpdated = false;

                            if (index === -1) {
                                if (!singleSeriesIsShown()) {
                                    wasUpdated = true;
                                    scope.hiddenSeries.push(this.name);
                                }
                            } else {
                                wasUpdated = true;
                                scope.hiddenSeries.splice(index, 1);
                            }

                            if (wasUpdated) {
                                updateDisplayData();
                                render();
                                updateGraph();
                            }
                        },
                    };
                });
            }

            scope.legend.entries = scope.legend[allSeriesKey];
            scope.legend.display = scope.legend.entries.length > 1;
        }

        // ------------ Helpers ---------- //
        function allSeries() {
            const { metric } = scope.config.getCard();

            return metric && metric.getSeries()
                .map(series => series.getId()) || [];
        }

        function allVisibleSeries() {
            return _.difference(allSeries(), scope.hiddenSeries);
        }
    }

    return {
        restrict: 'AE',
        replace: true,
        transclude: {
            controls: '?performanceChartControls',
        },
        scope: {
            config: '=',
            item: '=',
            smallChart: '=',
            useZoom: '@',
        },
        templateUrl: '/src/views/components/performance-chart.html',
        link,
    };
}]);
