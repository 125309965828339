/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').service('HealthDataTransformer', ['DataTransformer',
function(DataTransformer) {
    /**
     * Base data transformer class
     * @constructor
     */
    const HealthDataTransformer = function() {
        HealthDataTransformer.superconstructor.call(this, Array.prototype.slice.call(arguments));
    };

    avi.inherit(HealthDataTransformer, DataTransformer);

    HealthDataTransformer.prototype.name = 'health';

    /**
     * Model is executing this function to ask the field to participate in a call group
     * @param data - model data hash, used to figure out the earliest start time for the call
     *     and also may do other preparations
     * @param groupCall - the object that the fields put their parameters in to participate
     *     in the request
     */
    HealthDataTransformer.prototype.beforeCall = function(data, groupCall) {
        if (groupCall.inventory) {
            groupCall.inventory.include.health_score = true;
            groupCall.inventory.include.runtime = true;
        }

        return null;
    };

    /**
     * Model is executing this function when the call already happened and the purpose of the
     * call is to share the response data between the fields
     * each field is taking whatever data it needs to generate the right value
     *
     * @param data - model data hash, here used to update fields value
     * @param groupCall - the group call object, at this point it contains server response that
     *     the field want to access to generate value
     */
    HealthDataTransformer.prototype.afterCall = function(data, groupCall) {
        // Just puts the result into data. If there is no result, preserves previous version.
        if (groupCall.inventory) {
            const { response } = groupCall.inventory;

            if (response) {
                data.health_score = response.health_score;
                data.runtime = response.runtime;

                //  child VS has a list of parent VS vips here
                //  let's merge it into runtime.vip_summary
                if ('parent_vs_vip' in response && 'vip_summary' in data.runtime) {
                    const
                        { parent_vs_vip: vips } = response,
                        { runtime } = data,
                        vipsHash = {};

                    vips.forEach(vipConfig => vipsHash[vipConfig.vip_id] = vipConfig);

                    runtime['vip_summary'].forEach(vip => {
                        if (vip.vip_id in vipsHash) {
                            angular.extend(vip, vipsHash[vip.vip_id]);
                        }
                    });
                }

                // Used by VS only.
                if ('has_pool_with_realtime_metrics' in response) {
                    data.has_pool_with_realtime_metrics = response.has_pool_with_realtime_metrics;
                }
            }
        }
    };

    return HealthDataTransformer;
}]);
