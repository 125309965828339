/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name authProfilePage
 * @description Component for the Auth Profile page.
 */
class AuthProfilePageController {
    constructor(CRUDGridConfig, AuthProfileCollection, AviModal) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._AuthProfileCollection = AuthProfileCollection;
        this._AviModal = AviModal;
    }

    $onInit() {
        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._AuthProfileCollection();
        this.gridConfig.fields = [
            {
                name: 'data.config.name',
                title: 'Name',
                sortBy: 'name',
            }, {
                name: 'data.config.type',
                title: 'Type',
                template: '<span>{{ row.data.config.type | enum:"AUTH_PROFILE_" }}</span>',
                sortBy: 'type',
            },
        ];
        this.gridConfig.singleactions.push({
            title: 'Verify',
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('prof-auth-profile-verify-ldap', {
                    ldap: row.data.config,
                });
            },
            hidden: row => row.getConfig().type !== 'AUTH_PROFILE_LDAP',
        }, {
            title: 'Verify',
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('auth-profile-verify-saml', {
                    config: row.data.config,
                });
            },
            hidden: row => row.getConfig().type !== 'AUTH_PROFILE_SAML',
        });
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

AuthProfilePageController.$inject = [
    'CRUDGridConfig',
    'AuthProfileCollection',
    'AviModal',
];

angular.module('aviApp').component('authProfilePage', {
    controller: AuthProfilePageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>
        </div>`,
});
