/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridDuration
 * @param {MetricsDimension} row - MetricsDimension item.
 * @description
 *     Displays the duration for the MetricsDimension item. If the "valuesToDisplay" is not "sum",
 *     display "N/A".
 */
class VsSecurityDdosTopListGridCellDurationController {
    constructor(myAccount, GraphSync) {
        this.myAccount_ = myAccount;
        this.GraphSync_ = GraphSync;
    }

    /**
     * Returns SUM value of attack duration series.
     * @returns {number|string}
     * @public
     */
    getDuration() {
        const displayValueType = this.myAccount_.getValuesDisplayType();

        if (displayValueType === 'sum' && !this.GraphSync_.mouseIsOnGraph()) {
            const series = this.row.getSeriesBySeriesId('dos.sum_attack_duration');

            return series && series.getValue(displayValueType) || 'N/A';
        }

        return 'N/A';
    }
}

VsSecurityDdosTopListGridCellDurationController.$inject = [
    'myAccount',
    'GraphSync',
];

angular.module('security.vs.vantage.avi')
    .component('vsSecurityDdosTopListGridCellDuration', {
        bindings: {
            row: '<',
        },
        controller: VsSecurityDdosTopListGridCellDurationController,
        template: '<span>{{ $ctrl.getDuration() }}</span>',
    });
