/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name httpParamFullSerializer
 * @description
 *
 *     Encodes query URL part (keys and values) when serializing em to URL.
 *
 *     Full copy of angular $HttpParamSerializerProvider.$get but instead of `encodeUriQuery`
 *     'window.encodeURIComponent' is used. Also parameters aren't ordered by name.
 *
 *     Copied from angular v1.6.
 */
angular.module('aviApp').factory('httpParamFullSerializer', ['$window',
function($window) {
    const { encodeURIComponent } = $window;

    const serializeValue = v => {
        if (angular.isObject(v)) {
            return angular.isDate(v) ? v.toISOString() : angular.toJson(v);
        }

        return v;
    };

    return function(params) {
        if (!params) {
            return '';
        }

        const parts = [];

        _.each(params, (value, key) => {
            if (value === null || angular.isUndefined(value)) {
                return;
            }

            if (Array.isArray(value)) {
                value.forEach(v =>
                    parts.push(
                        `${encodeURIComponent(key)}=${encodeURIComponent(serializeValue(v))}`,
                    ));
            } else {
                parts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(serializeValue(value))}`,
                );
            }
        });

        return parts.join('&');
    };
}]);
