/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} MessageMapProps
 * @property {string} objectType - Type of the object/message in Schema.
 * @property {Function} ConfigItemClass - Constructor function of the objectType.
 * @property {boolean} isRepeated - True if the ConfigItem exists as a series within an array.
 */

/**
 * @typedef {Object.<string, MessageMapProps>} MessageMap
 */

const messageBaseFactory = ($injector, defaultValues, schemaService) => {
    /**
     * @alias module:services/MessageBase
     * @private
     */
    class MessageBase {
        /**
         * @constructor
         * @param {Object} args - Constructor argument.
         * @param {string} args.objectType - type of ConfigItem corresponding to Schema.
         * @param {string=} args.fieldName - name of the field the ConfigItem is being set to.
         * @param {Object=} args.parent - parent Item or ConfigItem.
         * @param {Object=} args.defaultConfig - Default configuration used on creation of the
         *     ConfigItem.
         * @param {Object=} args.config - Config data to be set on data.config. Object form only.
         */
        constructor(args = {}) {
            const {
                objectType,
                fieldName = '',
                parent = null,
                defaultConfig,
                config,
                isClone = false,
            } = args;

            if (_.isUndefined(objectType)) {
                throw new Error(`${this} is missing an objectType`);
            }

            this.objectType_ = objectType;
            this.fieldName_ = fieldName;
            this.parent_ = parent;
            this.messageMap_ = MessageBase.createMessageMap(this.objectType_);
            this.isClone_ = isClone;

            const defaultConfig_ = defaultConfig ||
                this.defaultConfigOverride_ ||
                defaultValues.getDefaultItemConfigByType(this.objectType_.toLowerCase()) ||
                this.emptyConfig_;

            this.data = {
                defaultConfig_,
                config: this.emptyConfig_,
            };

            this.updateConfig(config || this.defaultConfig_, isClone);
        }

        /**
         * Getter function for an empty config.
         */
        get emptyConfig_() {
            return {};
        }

        /**
         * Getter function for the config to override the message default.
         */
        get defaultConfigOverride_() {
            return null;
        }

        /**
         * Getter function for the config data.
         * @returns {Object}
         */
        get config() {
            return this.data.config;
        }

        /**
         * Getter function for the defaultConfig.
         * @returns {Object}
         * @protected
         */
        get defaultConfig_() {
            return this.data.defaultConfig_;
        }

        /**
         * Getter function for the objectType/
         * @returns {string}
         */
        get objectType() {
            return this.objectType_;
        }

        /**
         * Returns the config object.
         * @returns {Object}
         */
        getConfig() {
            return this.config;
        }

        /**
         * Updates the ConfigItem's config with new config data, then calls
         * modifyConfigDataAfterLoad_ to allow modifying config data as ConfigItem instances.
         * @param {Object} newConfig - Flat config data object.
         * @param {boolean} [skipDataTransformation=] - True to skip modifying the data on update.
         */
        updateConfig(newConfig, skipDataTransformation = false) {
            angular.noop(newConfig, skipDataTransformation);
        }

        /**
         * Sets child config items with new config data.
         * @param {Object} newConfig - Flat config data object.
         */
        setConfigData_(newConfig) {
            angular.noop(newConfig);
        }

        /**
         * Overwrite to modify config data as ConfigItem instances. Should mutate config data in
         * place.
         * @protected
         */
        modifyConfigDataAfterLoad_() { //eslint-disable-line class-methods-use-this
            angular.noop();
        }

        /**
         * Creates a new ConfigItem with the same config data. Used when making a save request.
         * @returns {MessageItem}
         */
        clone() {
            const config = angular.copy(this.flattenConfig_(true));

            return new this.constructor({
                objectType: this.objectType_,
                fieldName: this.fieldName_,
                parent: this.parent_,
                config,
                isClone: true,
            });
        }

        /**
         * Returns the flat config data (without any ConfigItem instances).
         * @param {boolean} [bypassCheck=] - True to bypass the canFlatten check, used for cloning.
         * @returns {Object}
         * @protected
         */
        flattenConfig_(bypassCheck) {
            return angular.copy(this.config);
        }

        /**
         * Overwrite to modify config data before it gets set in ConfigItems. Receives flat config
         * data (without any ConfigItem instances) as an input and should also return flat config
         * data.
         * @param {Object} data - Flat config data.
         * @returns {Object}
         * @protected
         */
        dataAfterLoad_(data) { //eslint-disable-line class-methods-use-this
            return data;
        }

        /**
         * Overwrite to modify config data before it gets sent in the save request. Receives flat
         * config data (without any ConfigItem instances) as an input and should also return flat
         * config data.
         * @param {Object} data - Flat config data.
         * @returns {Object}
         */
        dataToSave(data) { //eslint-disable-line class-methods-use-this
            return data;
        }

        /**
         * Overwrite to modify config data before it gets sent in the save request. Child
         * ConfigItem instances are present in the config. Should mutate config data in place.
         * @protected
         */
        modifyConfigDataBeforeSave_() { //eslint-disable-line class-methods-use-this
            angular.noop();
        }

        /**
         * Gets the ConfigItem constructor function based on the `${objectType}ConfigItem` naming
         * scheme.
         * @param {string} objectType - type of MessageItem.
         * @returns {Function|undefined}
         */
        static getConfigItemClass(objectType) {
            const configItemClassName = `${objectType}ConfigItem`;

            try {
                return $injector.get(configItemClassName);
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.warn(`No ConfigItem found for ${objectType}`);
                }
            }
        }

        /**
         * Creates a messageMap for a ConfigItem, which is a hash of the properties within the
         * ConfigItem's config that are messages to their {@link MessageMapProps}s.
         * @param {string} objectType - type of the ConfigItem.
         * @returns {MessageMap}
         */
        static createMessageMap(objectType) {
            const messageFields = schemaService.getMessageFields(objectType);
            const MessageItem = $injector.get('MessageItem');

            return _.reduce(messageFields, (messageMap, messageFieldProp, field) => {
                const { objectType: childObjectType } = messageFieldProp;

                messageMap[field] = {
                    ...messageFieldProp,
                    ConfigItemClass: MessageBase.getConfigItemClass(childObjectType) || MessageItem,
                };

                return messageMap;
            }, {});
        }
    }

    return MessageBase;
};

messageBaseFactory.$inject = [
    '$injector',
    'defaultValues',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name MessageBase
 * @module services/MessageBase
 * @description
 *     Base ConfigItem class. Intended to mirror protobuf objects (ex. Vip, DnsInfo) used in
 *     configuration. This is a base class that MessageItem and RepeatedMessageItem extend from.
 *     It's an abtrasct class that isn't intended to be used directly. You can think of this class
 *     as a leaf node that does not contain nested MessageItems in its config.
 * @author alextsg
 */
angular.module('core.vantage.avi').factory('MessageBase', messageBaseFactory);
