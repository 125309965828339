/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { portalStatus } from '../../../../../js/constants/avi-cloud.constants';
import './case-list.less';

const componentName = 'case-list';

class CaseListController {
    constructor($element, CaseCollection, CRUDGridConfig) {
        this.$element_ = $element;
        this.CaseCollection_ = CaseCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * Default Avi cloud connected status.
         * @type {boolean}
         */
        this.aviCloudConnected = false;

        /**
         * Default controller registration status.
         * @type {boolean}
         */
        this.controllerRegistered = false;
    }

    /**
     * @override
     */
    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.CaseCollection_(),
            multipleactions: [],
            singleactions: [{
                title: 'Attachment',
                class: 'sl-icon-paper-clip',
                hidden: row => !row.getConfig().case_attachments,
            }, {
                title: 'Edit',
                class: 'icon-pencil-4',
                do: row => row.edit(),
            }],
            defaultSorting: '',
            fields: [
                {
                    name: 'created_date',
                    title: 'Date Opened',
                    template: '{{ row.getConfig().created_date | prettyTime:1 }}',
                },
                {
                    name: 'case_number',
                    title: 'Case Number',
                    template: '{{ row.getConfig().case_number }}',
                },
                {
                    name: 'subject',
                    title: 'Subject',
                    template: '{{ row.getConfig().subject }}',
                },
                {
                    name: 'type',
                    title: 'Type',
                    template: '{{ row.getConfig().type }}',
                },
                {
                    name: 'last_modified_date',
                    title: 'Last Updated',
                    template: '{{ row.getConfig().last_modified_date | prettyTime:1 }}',
                },
                {
                    name: 'status',
                    title: 'Status',
                    template: '{{ row.getConfig().status }}',
                },
            ],
            permission: 'PERMISSION_CONTROLLER',
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
        };

        this.updateStatus_(this.systemInfoService_.getAviCloudStatus());

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /**
     * Update's Controller registration and Avi cloud connectivity status
     * @protected
     */
    updateStatus_(data) {
        const { AVICLOUD_REGISTERED, AVICLOUD_CONNECTED } = portalStatus;

        this.controllerRegistered =
            data.registration_status === AVICLOUD_REGISTERED;
        this.aviCloudConnected =
            data.connectivity_status === AVICLOUD_CONNECTED;
    }

    /**
     * @override
     */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

CaseListController.$inject = [
    '$element',
    'CaseCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name CaseList
 * @author Ashish Verma, Ram Pal
 * @description Case list page with options to create/edit a case.
 */
angular.module('avi/cportal').component('caseList', {
    bindings: {
        systemInfoService_: '<loadedSystemInfoService',
    },
    controller: CaseListController,
    templateUrl: 'src/components/pages/administration/support/portal/case-list.html',
});
