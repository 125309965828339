/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function licenseUsageDataSourceFactory(DataSource) {
    /**
     * @alias module:services/LicenseUsageDataSource
     * @extends DataSource
     */
    class LicenseUsageDataSource extends DataSource {
        constructor(args) {
            angular.extend(args, {
                defaultFields: [
                    {
                        id: 'config',
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    limit: 365,
                    step: 86400,
                },
            });

            super(args);
        }

        /** @override */
        processResponse_({ data }) {
            this.owner_.config = data;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params.objectName_ = this.owner_.objectName_;

            return params;
        }
    }

    return LicenseUsageDataSource;
}

licenseUsageDataSourceFactory.$inject = ['DataSource'];

/**
 * @ngdoc service
 * @name LicenseUsageDataSource
 * @module services/LicenseUsageDataSource
 * @author Akul Aggarwal
 * @description
 *
 *     DataSource to get license summary info.
 */
angular.module('aviApp').factory('LicenseUsageDataSource', licenseUsageDataSourceFactory);
