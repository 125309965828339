/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  verifyCloudConnectorUserHost
 * @description  Verifies that a host is configured with the cloud connector user ssh credentials.
 *     Does not save or update items, but makes a POST request to validate host settings.
 * @param {string=} userName - SSH user name. Used to retrieve the SSH uuid if uuid is not provided.
 * @param {string=} userUuid - SSH uuid. Used in request for verification.
 * @param {string=} host - IP address of host. Used in payload.
 * @param {string} closeModal - Function to close modal, provided by AviModal.
 */
function VerifyCloudConnectorUserHostController(Base, Regex) {
    const base = new Base();
    const vm = this;

    this.Regex = Regex;

    /**
     * "verify" or "cleanup" host.
     * @type {string}
     */
    this.task = 'verify';

    this.$onInit = function() {
        if (this.host) {
            vm.disableEdit = true;
        }

        if (!this.userUuid && this.userName) {
            const userName = this.userName.name() || this.userName;

            vm.busy = true;
            vm.errors = null;

            base.request('GET', `api/cloudconnectoruser?name=${userName}`)
                .then(rsp => {
                    vm.busy = false;
                    vm.userUuid = rsp.data.results[0].uuid;

                    if (vm.host) {
                        return vm.runTask();
                    }
                }).catch(rsp => {
                    vm.busy = false;
                    vm.errors = rsp.data;
                });
        }
    };

    /**
     * Returns modal header title based on the task.
     * @return {string}
     */
    this.getTitle = function() {
        switch (this.task) {
            case 'verify':
                return 'Verify that the host is set up with SSH credentials';
            case 'cleanup':
                return 'Cleanup existing SE configuration';
        }
    };

    /**
     * Clears output text. Called on input change.
     */
    this.clearOutput = function() {
        this.output = '';
    };

    /**
     * Makes API call to verify or cleanup host and cloudconnectoruser config.
     * @return {ng.$q.promise}
     */
    this.runTask = function() {
        if (this.busy) {
            return;
        }

        const task = this.task === 'verify' ? 'test' : this.task;

        const api = `api/cloudconnectoruser/${this.userUuid}/${task}`;

        this.busy = true;
        this.errors = null;
        this.output = '';

        return base.request('POST', api, { host: vm.host }, null, 'cloudconnectoruserhostverify')
            .then(function(rsp) {
                vm.output = rsp.data;
            }).catch(function(rsp) {
                vm.errors = rsp.data;
            }).finally(function() {
                vm.busy = false;
            });
    };

    /**
     * Cancels requests on $destroy event.
     */
    this.$onDestroy = function() {
        base.cancelRequests('cloudconnectoruserhostverify');
    };
}

VerifyCloudConnectorUserHostController.$inject = [
    'Base',
    'Regex',
];

angular.module('aviApp').component('verifyCloudConnectorUserHost', {
    bindings: {
        userName: '@',
        userUuid: '@',
        host: '@',
        closeModal: '&',
        task: '@',
    },
    controller: VerifyCloudConnectorUserHostController,
    templateUrl: 'src/components/modals/verify-cloud-connector-user-host/' +
            'verify-cloud-connector-user-host.html',
});
