/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name Timeframe
 * @desciption
 * This object shares selected timeframe across application.
 *
 * If controller needs timeframe it can be injected and used as follows:
 * Timeframe.value_             // Which will give selected value like `rt` or `6h`
 * Timeframe.set(newValue)     // This way new value would be set and `change` event triggered
 * Timeframe.selected().limit  // Will give the limit for the current selection
 * Timeframe.selected().step   // Will give the step for the current selection
 *
 * You can do Timeframe.setGroup(groupName) to switch timeframe options to the new group,
 * value would be checked for existence.
 *
 * You can watch timeframe change by Timeframe.on('change', function(event) {}) and update your
 * stuff upon change. If timeframe was changed by interacting with directive then event would be
 * click event on dropdown element.
 *
 * @typedef {Object} timeframe
 * @property {string} label - Short label.
 * @property {number} step - Duration on which backend metric data aggregation gonna be made in
 * seconds.
 * @property {number} limit - Number of metric data points to be fetched from the back-end.
 * @property {number} interval - Frequency of the data updates: one time in provided number
 * of seconds.
 * @property {Array.<Array.<string, Function>>|undefined} timeTickFormat - Argument for
 * d3.time.format.multi function to use for the time labels on the {@link performanceChart}.
 * When not provided d3js default date format will be used.
 */
// TODO update timeframe visibility through explicit method which triggers the corresponding event.
// TODO Refactor different timeframes into one data scructure to avoid duplication and keep it
// consistent and manageable @am
angular.module('aviApp').factory('Timeframe', [
'Base', '$stateParams', '$location', 'logTimeframes',
function(Base, $stateParams, $location, logTimeframes) {
    /**
     * @class
     * @param oArgs {Object=} - Configuration object, contains argument list, event
     *     listeners and more
     * @constructor
     */
    function Timeframe(oArgs) {
        Timeframe.superconstructor.call(this, oArgs);

        /**
         * For each timeframe group we have a sorted array of timeframe objects with key and
         * numeric value.
         * @type {{string:Object[]}}
         * @private
         */
        this.orderedGroupTimeframes_ = {};

        /**
         * Hash of numeric timeframe values (in seconds, 1 or Infinity) for each timeframe we
         * support. So that we can search for closer ones.
         * @type {{string:number}}
         * @private
         */
        this.numericTimeframesHash_ = {};

        // By default set options to analytics
        _.each(this.groups, (group, groupKey) => {
            const orderedList = [];

            _.each(group, (elm, key) => {
                const { range } = elm;

                if (!(key in this.numericTimeframesHash_)) {
                    this.numericTimeframesHash_[key] = Timeframe.getNumericRange_(range);
                }

                orderedList.push({
                    key,
                    range: this.numericTimeframesHash_[key],
                });

                elm.key = key;
            });

            this.orderedGroupTimeframes_[groupKey] =
                orderedList.sort(({ range: a }, { range: b }) => a - b);
        });

        this.selectedGroup = 'default';
        this.options = this.groups[this.selectedGroup];

        /** @type {string} Name of the selected timeframe group. For ex.: default or logs. */
        this.setGroup('default', undefined, 'Timeframe constructor');
    }

    /**
     * Inherit from Base only to get event driven features
     */
    avi.inherit(Timeframe, Base);

    /**
     * We are looking for timeframes which are close when we don't have an exact same in another
     * group. To do so we need an ability to compare em using numeric values.
     * @param {number|string} range - Number of seconds or 'custom' or 'all'.
     * @returns {number}
     * @static
     * @private
     */
    Timeframe.getNumericRange_ = function(range) {
        if (angular.isString(range)) {
            switch (range) {
                case 'custom':
                    range = 1;
                    break;

                case 'all':
                    range = Infinity;
            }
        }

        return range;
    };

    /**
     * Maps meaningful group name to the option list defined above
     * @type {{string:{string:timeframe}}}
     */
    Timeframe.prototype.groups = {
        // Option list specific only for logs
        logs: logTimeframes,

        // default options
        default: {
            // special settings for Items with real time metrics on
            rt: {
                index: 0, //only for layout ordering
                label: 'Past 30 Minutes',
                step: moment.duration(5, 'm').asSeconds(), // or five seconds
                limit: 6, //or 360
                interval: 10,
                range: moment.duration(30 * 60 - 1, 's').asSeconds(),
                timeTickFormat: [
                    ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                    ['%I %p', function() { return true; }], //HH AM
                ],
            },
            '6h': {
                index: 1,
                label: 'Past 6 Hours',
                step: moment.duration(5, 'm').asSeconds(),
                limit: 72,
                interval: 30,
                range: moment.duration(6, 'h').asSeconds(),
                timeTickFormat: [
                    ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                    ['%I %p', function(d) { return d.getHours(); }], //HH AM
                    ['%a %d', function() { return true; }], //Sun DD
                ],
            },
            '1d': {
                index: 2,
                label: 'Past Day',
                step: moment.duration(5, 'm').asSeconds(),
                range: moment.duration(1, 'd').asSeconds(),
                limit: 288,
                interval: 30,
                timeTickFormat: [
                    ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                    ['%I %p', function(d) { return d.getHours(); }], //HH AM/PM
                    ['%a %d', function() { return true; }], //Sun DD
                ],
            },
            '1w': {
                index: 3,
                label: 'Past Week',
                step: moment.duration(1, 'h').asSeconds(),
                range: moment.duration(1, 'w').asSeconds(),
                limit: 168,
                interval: 30,
                timeTickFormat: [
                    ['%I %p', function(d) { return d.getHours(); }], //HH AM/PM
                    ['%a %d', function(d) { return d.getDate() != 1; }], //Sun DD
                    ['%b %d', function() { return true; }], //Jan 01
                ],
            },
            '1m': {
                index: 4,
                label: 'Past Month',
                step: moment.duration(1, 'day').asSeconds(),
                range: moment.duration(1, 'M').asSeconds(),
                limit: 30,
                interval: 30,
                timeTickFormat: [
                    ['%a %d', function(d) { return d.getDate() != 1; }], //Sun DD
                    ['%b %d', function() { return true; }], //Jan 01
                ],
            },
            '1q': {
                index: 5,
                label: 'Past Quarter',
                step: moment.duration(1, 'd').asSeconds(),
                range: moment.duration(3, 'M').asSeconds(),
                limit: 90,
                interval: 30,
                timeTickFormat: [
                    ['%b %d', function(d) { return d.getMonth(); }], //Jan DD
                    ['%Y', function() { return true; }], //YYYY
                ],
            },
            '1y': {
                index: 6,
                label: 'Past Year',
                step: moment.duration(1, 'd').asSeconds(),
                range: moment.duration(3, 'y').asSeconds(),
                limit: 365,
                interval: 30,
                timeTickFormat: [
                    ['%b %d', function(d) { return d.getDate() != 1; }], //Jan DD
                    ['%b', function(d) { return d.getMonth(); }], //Jan
                    ['%Y', function() { return true; }], //YYYY
                ],
            },
        },
        insights: {
            '6h': {
                index: 1,
                label: 'Past 6 Hours',
                step: moment.duration(5, 'm').asSeconds(),
                limit: 72,
                interval: 30,
                range: moment.duration(6, 'h').asSeconds(),
                timeTickFormat: [
                    ['%I:%M', d => d.getMinutes()], //HH:MM
                    ['%I %p', d => d.getHours()], //HH AM
                    ['%a %d', () => true], //Sun DD
                ],
            },
        },
    };

    /**
     * Keeps currently selected list of options
     * Yes, it's used to render options in dropdown
     * @type {hash}
     */
    Timeframe.prototype.options = {};

    /**
     * Keeps selected timeframe value. Use {@link Timeframe.set} to update.
     * @type {string}
     * @protected
     */
    Timeframe.prototype.value_ = '6h';

    /**
     * Selected timeframe value used by default. Must be supported by all timeframe groups.
     * @type {string}
     * @protected
     */
    Timeframe.prototype.defaultValue_ = '6h';

    /**
     * Sets the default Timeframe value (which usually comes from the user profile).
     * @param {string} value
     * @public
     */
    Timeframe.prototype.setDefaultValue = function(value) {
        if (value && value in this.groups['default']) {
            this.defaultValue_ = value;
        }
    };

    /**
     * Returns default value of selected timeframe.
     * @returns {string}
     * @public
     */
    Timeframe.prototype.getDefaultValue = function() {
        return this.defaultValue_;
    };

    /**
     * Changes options based on group name. Groups defined above
     * @param {string=} groupName - Supported group name.
     * @param {string=} newValue - Value to be set in a new group. If not passed,
     *     current value will be preserved or selected group default value will be used (if
     *     current value is not present in a newly selected group).
     * @param {string=} reason - String to be passed with generated even on a timeframe change.
     */
    Timeframe.prototype.setGroup = function(groupName, newValue = this.value_,
                                            reason = 'afterGroupChange') {
        if (!groupName || !angular.isString(groupName) || !(groupName in this.groups)) {
            groupName = 'default';
        }

        const valueToBeSet = this.getClosestValue_(groupName, newValue.toLowerCase());

        if (groupName !== this.selectedGroup) {
            this.selectedGroup = groupName;
            this.options = this.groups[this.selectedGroup];
            this.trigger('afterGroupChange', reason);
        }

        this.set(valueToBeSet, reason);
    };

    /**
     * Returns the closest to the current (or just default) "old" value from the "new" group.
     * @param groupName {string} - Group name we are interested at ("new").
     * @param requested {string=} - When passed we will try to find a timeframe of the
     *     "new" group which is closest to this argument. Otherwise current
     *     {@link Timeframe.value_} will be used for this purpose.
     * @returns {string}
     * @protected
     */
    Timeframe.prototype.getClosestValue_ = function(groupName, requested) {
        const comingFromGroup = this.selectedGroup,
            ordered = this.orderedGroupTimeframes_[groupName],
            { groups } = this;

        if (!(requested in groups[comingFromGroup]) && !(requested in groups[groupName])) {
            requested = this.value_;
        }

        const tfNumRange = this.numericTimeframesHash_[requested];

        let
            res = this.getDefaultValue(),
            found = false;

        _.any(ordered, ({ key, range }, index, list) => {
            if (range === tfNumRange) { //have same in new group, use it
                found = true;
                res = key;
            } else if (range > tfNumRange) { //have no same but larger one
                found = true;

                if (!index) { //first one is larger, hence no choice and we go for it
                    res = key;
                } else {
                    const { key: prevEntryKey, range: prevEntryRange } = list[index - 1];

                    if (range - tfNumRange <= tfNumRange - prevEntryRange) {
                        res = key;
                    } else {
                        res = prevEntryKey;
                    }
                }
            }

            return found;
        });

        if (!found) { //we have no same and all others are smaller, go for the last one
            res = ordered[ordered.length - 1].key;
        }

        return res;
    };

    /**
     * Setter
     * @param {string} newValue - New timeframe value.
     * @param {string=} reason - Message sent with the generated event.
     */
    Timeframe.prototype.set = function(newValue, reason) {
        if (newValue && typeof newValue === 'string') {
            newValue = newValue.toLowerCase();

            if (newValue !== this.value_) {
                if (newValue in this.options) {
                    this.value_ = newValue;
                    this.trigger('change', reason);
                    $stateParams.timeframe = this.value_;
                    $location.search('timeframe', this.value_);//update URL string
                } else {
                    console.warn('Trying to select a nonexistent timeframe \'%s\', in active ' +
                        ' group: \'%s\'', newValue, this.selectedGroup);
                }
            }
        }
    };

    /**
     * Returns selected option that includes `limit`, `step` and `interval`
     * @return {object}
     */
    Timeframe.prototype.selected = function() {
        return this.options[this.value_];
    };

    return new Timeframe();
}]);

angular.module('aviApp').value('logTimeframes', {
    '15m': {
        index: 0,
        label: 'Past 15 Minutes',
        range: moment.duration(15, 'm').asSeconds(),
        step: 5,
        limit: 360,
        interval: 5,
    },
    '1h': {
        index: 1,
        label: 'Past Hour',
        range: moment.duration(1, 'h').asSeconds(),
        step: moment.duration(5, 'm').asSeconds(),
        limit: 12,
        interval: 30,
    },
    '3h': {
        index: 2,
        label: 'Past 3 Hours',
        range: moment.duration(3, 'h').asSeconds(),
        step: moment.duration(5, 'm').asSeconds(),
        limit: 36,
        interval: 30,
    },
    '6h': {
        index: 3,
        label: 'Past 6 Hours',
        range: moment.duration(6, 'h').asSeconds(),
        step: moment.duration(5, 'm').asSeconds(),
        limit: 72,
        interval: 30,
    },
    '1d': {
        index: 4,
        label: 'Past Day',
        range: moment.duration(1, 'd').asSeconds(),
        step: moment.duration(5, 'm').asSeconds(),
        limit: 288,
        interval: 30,
    },
    '1w': {
        index: 5,
        label: 'Past Week',
        range: moment.duration(1, 'w').asSeconds(),
        step: moment.duration(1, 'h').asSeconds(),
        limit: 168,
        interval: 30,
    },
    '1m': {
        index: 6,
        label: 'Past Month',
        range: moment.duration(1, 'M').asSeconds(),
        step: moment.duration(1, 'days').asSeconds(),
        limit: 30,
        interval: 30,
    },
    '1q': {
        index: 7,
        label: 'Past Quarter',
        range: moment.duration(3, 'M').asSeconds(),
        step: moment.duration(1, 'days').asSeconds(),
        limit: 90,
        interval: 30,
    },
    '1y': {
        index: 8,
        label: 'Past Year',
        range: moment.duration(1, 'y').asSeconds(),
        step: moment.duration(1, 'days').asSeconds(),
        limit: 365,
        interval: 30,
    },
    all: {
        index: 9,
        label: 'All Time',
        range: 'all',
        step: moment.duration(1, 'days').asSeconds(),
        limit: 365,
        interval: 30,
    },
    custom: {
        index: 10,
        label: 'Custom',
        range: 'custom',
        hiddenInLayout: true,
        step: 300,
        limit: 72,
        interval: 30,
    },
});
