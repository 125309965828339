/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function gridCtrlCssRulesMixin(classMixin, cssobj) {
    const mixin = {};

    /**
     * Sets certain properties on the instance required for this mixin to work.
     * @param {string} componentName
     * @protected
     */
    mixin.cssRulesOnInit_ = function(componentName) {
        const
            cssRulesObj = {},
            cellRules = {},
            cssHandler = cssobj(cssRulesObj),
            { classNamePrefix_: classNamePrefix } = this;

        const
            tableBodySelector =
                    `.${componentName}.${classNamePrefix} .${componentName}__table-body`,
            tableCellSelector = `.${componentName}__table-cell`;

        cssRulesObj[tableBodySelector] = {
            [tableCellSelector]: cellRules,
        };

        this.cellRules_ = cellRules;
        this.cssHandler_ = cssHandler;
    };

    /**
     * Does a clean up on component instance destroy event.
     * @protected
     */
    mixin.cssRulesOnDestroy_ = function() {
        this.cssHandler_.cssdom.remove();
    };

    /**
     * Sets CSS rules for actual grid rows based on column header (th) element sizes.
     * Needed to support customizable column width.
     * @param {number[]} colsWidth
     */
    mixin.syncColumnSize = function(colsWidth) {
        if (this.isDestroyed_) {
            return;
        }

        const {
            cellRules_: cellRules,
            cssHandler_: cssHandler,
        } = this;

        colsWidth.forEach((width, index) =>
            cellRules[`&:nth-child(${index + 1})`] = { width });

        cssHandler.update();
    };

    return GridCtrl => classMixin(GridCtrl, angular.copy(mixin));
}

gridCtrlCssRulesMixin.$inject = [
    'classMixin',
    'cssobj',
];

/**
 * @ngdoc service
 * @name gridCtrlCssRulesMixin
 * @type {Function}
 * @mixin
 * @desc
 *
 *     Methods to set and update CSS rules for table cells.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi')
    .factory('gridCtrlCssRulesMixin', gridCtrlCssRulesMixin);
