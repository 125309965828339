/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class RateProfile {
    constructor(Schema, Regex, rateService) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.rateService = rateService;
    }

    /**
     * Handles protocol change.
     */
    protocolChange() {
        const { action } = this.profile;

        if (action.redirect && angular.isString(action.redirect.protocol)) {
            const { redirect } = action;

            redirect.port = redirect.protocol === 'HTTPS' ? 443 : 80;
        }
    }
}

RateProfile.$inject = ['Schema', 'Regex', 'dosRateLimiterService'];

/**
 * @ngdoc component
 * @name rateProfile
 * @param {Object[]} actions - List of RateLimiterAction for RateProfile action field
 *      to display in Action dropdown.
 * @param {Object} profile - RateProfile object to modify.
 * @param {string} profName - RateProfile name to display as title.
 * @description
 *      UI component to modify instance of RateProfile object.
 */
angular.module('aviApp').component('rateProfile', {
    bindings: {
        actions: '<',
        profile: '<',
        profName: '@',
    },
    controller: RateProfile,
    templateUrl: 'src/components/templates/profiles/rate-limiter/rate-profile.html',
});
