/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('GatewayMonitorCollection', ['$q', 'Collection', 'GatewayMonitor',
    function($q, Collection, GatewayMonitor) {
    // GatewayMonitorCollection assumes VRF is global.
        function GatewayMonitorCollection(args) {
            GatewayMonitorCollection.superconstructor.call(this, args);
            this.vrf = args && args.vrf || 'global';
        }

        avi.inherit(GatewayMonitorCollection, Collection);

        GatewayMonitorCollection.prototype.objectName_ = 'vrfcontext';
        GatewayMonitorCollection.prototype.windowElement_ = 'infra-gateway-monitor-create';
        GatewayMonitorCollection.prototype.itemClass_ = GatewayMonitor;

        GatewayMonitorCollection.prototype.defaultDataSources_ = 'list';
        GatewayMonitorCollection.prototype.allDataSources_ = {
            list: {
                source: 'ListCollDataSource',
                transformer: 'GatewayMonitorListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        return GatewayMonitorCollection;
    }]);

angular.module('aviApp').factory('GatewayMonitor', ['Item', function(Item) {
    function GatewayMonitor(oArgs) {
        GatewayMonitor.superconstructor.call(this, oArgs);
    }

    avi.inherit(GatewayMonitor, Item);

    GatewayMonitor.prototype.objectName = 'vrfcontext';
    GatewayMonitor.prototype.windowElement = 'infra-gateway-monitor-create';

    GatewayMonitor.prototype.loadOnEdit = false;

    /** @override */
    GatewayMonitor.prototype.getIdFromData_ = function(data) {
        return data && data.config && data.config.id || '';
    };

    /**
     * @override
     */
    GatewayMonitor.prototype.dataToSave = function() {
        const config = angular.copy(this.getConfig());

        if (config.gateway_ip && config.gateway_ip.addr) {
            config.id = config.gateway_ip.addr;
        }

        return config;
    };

    /**
     * @override
     */
    GatewayMonitor.prototype.saveRequest = function() {
        if (!this.collection) {
            return;
        }

        const { entireObject: vrfContext } = this.collection;

        vrfContext.gateway_mon = vrfContext.gateway_mon || [];

        checkForEdit(vrfContext, this.data.config);

        const config = this.dataToSave();

        vrfContext.gateway_mon.push(config);

        return this.request('PUT', vrfContext.url, vrfContext, null, 'save')
            .then(function() {
                return config;
            });
    };

    /**
     * If id exists, it means that a gateway_ip is being edited, so we remove the original from the
     * gateway_mon list before pushing new gateway_ip.
     * @param  {Object} vrfContext - VRFContext object.
     * @param  {Object} config - GatewayMonitor config object.
     */
    function checkForEdit(vrfContext, config) {
        // If id exists, it means that a gateway_ip is being edited, so we remove it from the
        // original list before pushing.
        if (config && config.id) {
            vrfContext.gateway_mon = _.reject(vrfContext.gateway_mon, function(gateway) {
                return gateway.gateway_ip.addr === config.id;
            });
        }
    }

    /**
     * @override
     */
    GatewayMonitor.prototype.drop = function() {
        if (!this.collection) {
            return;
        }

        const
            { entireObject: vrfContext } = this.collection,
            { addr } = this.data.config.gateway_ip;

        vrfContext.gateway_mon = _.reject(vrfContext.gateway_mon, function(gateway) {
            return gateway.gateway_ip.addr === addr;
        });

        if (vrfContext.gateway_mon.length === 0) {
            vrfContext.gateway_mon = undefined;
        }

        return this.request('PUT', vrfContext.url, vrfContext);
    };

    return GatewayMonitor;
}]);
