/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @fileoverview This file contains functions related to Date, Time and Delay.
 */

/**
 * Function to return promise to be resolved after provided delay.
 * @param {number} delay - Waiting time for the promise to get resolved.
 * @return {Promise}
 */
export const waitFor = delay => {
    return new Promise(resolve => setTimeout(resolve, delay));
};
