/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  aviConfirm
 * @param {string} message - Message to be displayed for confirmation.
 * @param {function} onConfirm - Called if user clicks Confirm.
 * @param {function} onCancel - Called if user clicks Cancel or closes Modal.
 * @param {function} closeModal - Closes modal through AviModal.
 */
class Controller {
    confirm() {
        this.onConfirm();
        this.closeModal();
    }

    cancel() {
        this.onCancel();
        this.closeModal();
    }
}

angular.module('aviApp').component('aviConfirm', {
    bindings: {
        message: '@',
        onConfirm: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/common/avi-confirm/avi-confirm.html',
});
