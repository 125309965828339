/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

window.avi = {};

const subModules = [
    ['charts'],
    ['deps.vantage.avi'],
    ['core.vantage.avi'],
    ['utils.vantage.avi'],
    ['router.vantage.avi'],
    ['GSLB.vantage.avi'],
    ['metrics.vantage.avi'],
    ['logs.vantage.avi'],
    ['security.vs.vantage.avi'],
    ['grid.ui.vantage.avi', ['utils.vantage.avi']],
    ['kit.ui.vantage.avi'],
    ['avi/navigation'],
    ['policies.vantage.avi'],
    ['waf.vantage.avi'],
    ['avi/component-kit'],
    ['avi/dataModel'],
    ['avi/cportal'],
    ['avi/profiles/application'],
    ['avi/cloud'],
    ['avi/upgrade'],
    ['avi/licensing'],
    ['avi/scripts'],
    ['avi/events'],
    ['avi/app'],
    ['avi/core'],
    ['avi/pool'],
];

//submodules init
subModules.forEach(
    ([moduleName, deps = []]) => angular.module(moduleName, deps),
);

angular.module('aviApp', [
    'ngSanitize',
    'ui.router',
    'dndLists',
    'ngPromiseExtras',
    'vs-repeat',
].concat(
    subModules.map(([moduleName]) => moduleName),
));

// This exposes the internalStateObj from ui-router that allows us to match URLs with state
// names.
angular.module('aviApp').config(['$stateProvider', function($stateProvider) {
    // This fn is called by StateBuilder each time a state is registered
    $stateProvider.decorator('parent', function(internalStateObj, parentFn) {
        // The first arg is the internal state. Capture it and add an accessor to public state
        // object.
        internalStateObj.self.$$state = function() {
            return internalStateObj;
        };

        // pass through to default .parent() function
        return parentFn(internalStateObj);
    });
}]);

// setup routes
angular.module('aviApp').config([
'$stateProvider', '$urlRouterProvider', '$httpProvider', '$compileProvider', '$qProvider',
'appDefaultState', 'Schema', 'appStateTree',
function($stateProvider, $urlRouterProvider, $httpProvider, $compileProvider, $qProvider,
appDefaultState, Schema, appStateTree) {
    let debugMode = true;

    const stateRoutes = angular.copy(appStateTree);

    // This config runs only on production environments to switch off some debug data (like
    // angular.scope) from class names and DOM nodes.
    if (process.env.NODE_ENV === 'production') {
        $compileProvider.debugInfoEnabled(false);
        $qProvider.errorOnUnhandledRejections(false);

        debugMode = false;
    }

    //true for dev builds, false otherwise
    window.avi.debugMode = debugMode;

    //need this wrapper $location > $state to avoid circular changes of state & location
    //causing endless digest loop from when and otherwise
    function goToState(toStatename = appDefaultState) {
        return ['$match', '$state', '$stateParams', function($match, $state, $stateParams) {
            return $state.go(toStatename, $stateParams);
        }];
    }

    const loginParams = [
        'csrf_token',
        'session_id',
        'tenant_name',
        'permissions',
        'read_only',
        'redirect',
    ];

    const loadedSystemInfoService = {
        token: 'loadedSystemInfoService',
        resolveFn: systemInfo => systemInfo.load().then(() => systemInfo),
        deps: ['systemInfoService'],
    };

    $stateProvider
        .state('login', {
            url: `/login?${loginParams.join('&')}`,
            templateUrl: 'src/components/login/login.html',
            controller: 'LoginController',
        })
        .state('welcome', {
            url: '/welcome',
            templateUrl: 'src/views/welcome.html',
        })
        .state('pre-welcome-aws', {
            url: '/pre-welcome-aws',
            component: 'preAwsWelcomePage',
        })
        .state('503', {// Server error
            url: '/503',
            component: 'errorPage',
            data: {
                statusCode: 503,
            },
        })
        .state('520', {// Server unknown error
            url: '/520',
            component: 'errorPage',
            data: {
                statusCode: 520,
            },
        })
        .state('500', {
            url: '/500',
            component: 'errorPage',
            data: {
                statusCode: 500,
            },
        })
        .state('logged-out', {
            url: '/logged-out',
            component: 'loggedOutPage',
        })
        .state('passwordchange', {
            url: '/passwordchange/:username/:token',
            templateUrl: 'src/views/passwordchange.html',
        })
        .state('authenticated', {
            component: 'aviRoot',
            url: '/:tenantName',
            params: {
                tenantName: '',
            },
            resolve: [
                {
                    token: 'loadedMyAccount',
                    resolveFn: myAccount => myAccount.load(),
                    deps: ['myAccount'],
                }, {
                    token: 'loadedDefaultValues',
                    resolveFn: defaultValues => defaultValues.load(),
                    deps: ['defaultValues'],
                    policy: { async: 'NOWAIT' },
                },
                angular.extend(
                    {},
                    loadedSystemInfoService,
                    { policy: { async: 'NOWAIT' } },
                ),
            ],
        })
        // logout when used inside an iframe (embedded appMode)
        .state('please-reload', {
            url: '/please-reload',
            templateUrl: 'src/views/please-reload.html',
        });

    function generateStateRoutes(routes, parentStateName) {
        let previousState;

        for (let i = 0; i < routes.length; i++) {
            const state = routes[i];
            const path = parentStateName ? `${parentStateName}.` : '';

            state.data = state.data || {};

            const nextState = routes[i + 1];

            if (parentStateName) {
                state.data.parentState = parentStateName;
                state.name = path + state.name;
            }

            const stateName = state.name;

            if (nextState) {
                state.data.nextState = path + nextState.name;
            }

            if (previousState) {
                state.data.previousState = previousState;
            }

            const stateCopy = angular.extend({}, state);

            $stateProvider.state(stateName, stateCopy);

            if (state.children && state.children.length) {
                stateCopy.data.nextChildState = `${stateName}.${state.children[0].name}`;
                generateStateRoutes(state.children, stateName);
            }

            previousState = stateName;
        }
    }

    generateStateRoutes(stateRoutes);

    //----------------------------------------------------------------------------------------
    // Redirection to avoid abstract states. For manual url updates and links from outer world
    //----------------------------------------------------------------------------------------
    $urlRouterProvider
        .when('', goToState())
        .when('/', goToState())
        .when('/authenticated', goToState())
        .when('/authenticated/applications', goToState())
        .when('/authenticated/applications/pool/',
            goToState('authenticated.application.pool-list'))
        .when('/authenticated/applications/virtualservice/{vsId}',
            goToState('authenticated.application.virtualservice-detail.analytics'))
        .when('/authenticated/applications/pool/{poolId}/{vsId}',
            goToState('authenticated.application.pool-detail.analytics'))
        .when('/authenticated/infrastructure',
            goToState('authenticated.infrastructure.dashboard'))
        .when('/authenticated/template',
            goToState('authenticated.profile.profiles.applicationprofile-list'))
        .when('/authenticated/template/profile',
            goToState('authenticated.profile.profiles.applicationprofile-list'))
        .when('/authenticated/template/group',
            goToState('authenticated.profile.group.ipaddrgroup-list'))
        .when('/authenticated/template/ssl',
            goToState('authenticated.profile.ssl.certificate-list'))
        .when('/authenticated/template/scripts',
            goToState('authenticated.profile.scripts.datascriptset-list'))
        .when('/authenticated/template/autoscale',
            goToState('authenticated.profile.autoscale.server-autoscale'))
        .when('/authenticated/infrastructure/cloud/{cloudId}/serviceengine/{seId}',
            goToState('authenticated.infrastructure.cloud.serviceengine-detail.summary'))
        .when('/authenticated/administration/controller/{controllerId}/',
            goToState('authenticated.administration.controller-detail.events'))
        .otherwise($injector => {
            const $state = $injector.get('$state');

            return $state.go(appDefaultState);
        });

    const
        { defaults } = $httpProvider,
        { common: headers } = defaults.headers;

    defaults.xsrfCookieName = 'csrftoken';
    defaults.xsrfHeaderName = 'X-CSRFToken';

    headers['X-Avi-UserAgent'] = 'UI';
    headers['X-Avi-Version'] = Schema['version'];

    $httpProvider.interceptors.push('httpInterceptor');

    $compileProvider
        .commentDirectivesEnabled(false)
        .cssClassDirectivesEnabled(false);
}]);
