/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name networkList
 * @description
 *
 *     Component for the Network List page.
 */
class NetworkListController {
    constructor(
        CRUDGridConfig,
        ConfiguredNetworkCollection,
        infraCloudState,
        getSubnetString,
        $state,
    ) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._ConfiguredNetworkCollection = ConfiguredNetworkCollection;
        this._infraCloudState = infraCloudState;
        this._getSubnetString = getSubnetString;
        this._$state = $state;
    }

    $onInit() {
        const cloud = this._infraCloudState.getCloud();
        const slug = cloud.id;
        const vtype = cloud && cloud.getVtype();

        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._ConfiguredNetworkCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
            defaults: {
                cloud_ref: slug,
            },
            bind: {
                collItemConfigUpdate: () => this.gridConfig.collection.load(),
            },
            cloudType: vtype,
        });

        this.gridConfig.fields = [{
            require: 'config, runtime',
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'discSubnets',
            require: 'discovery',
            title: 'Discovered Subnets',
            transform: row => {
                if (row.data.config && row.data.config.exclude_discovered_subnets) {
                    return 'Excluded';
                }

                const { discovery } = row.data;

                if (discovery && discovery.ip_subnet && discovery.ip_subnet.length) {
                    return discovery.ip_subnet.map(s => this._getSubnetString(s.prefix))
                        .join(', ');
                } else {
                    return 'None';
                }
            },
            visibility: 'm',
        }, {
            name: 'confSubnets',
            title: 'Configured Subnets [Free/Configured IPs]',
            transform(row) {
                if (row.data.config &&
                        row.data.config.configured_subnets &&
                        row.data.config.configured_subnets.length) {
                    return row.data.config.configured_subnets.map(s => {
                        let str = s.asString;

                        if (s.total_ip_count) {
                            str += ` [${s.free_ip_count}/${s.total_ip_count}]`;
                        }

                        return str;
                    }).join(', ');
                } else {
                    return 'None';
                }
            },
            visibility: 'm',
        }, {
            name: 'vrf',
            title: 'VRF Context',
            template: '{{ row.getConfig().vrf_context_ref.name() }}',
            visibility: 'optional',
        }];

        if (!cloud.allowNetworkCreate()) {
            delete this.gridConfig.multipleactions;
        }

        // Modify singleactions to take $scope.Cloud as a param.
        this.gridConfig.singleactions = [{
            title: 'Edit',
            class: 'icon-pencil-4',
            hidden(row) {
                return !row.isEditable();
            },
            do(row) {
                row.edit(null, {
                    Cloud: cloud,
                });
            },
        }];

        this.gridConfig.expanderDisabled = row => {
            const config = row.getConfig();
            let res = true;

            if (config && config.configured_subnets && config.configured_subnets.length) {
                res = !_.some(config.configured_subnets, s => s.used_ip_count);
            }

            return res;
        };

        this.gridConfig.expandedContainerTemplate =
            `<div class="rule-details">
                <span class="small-header">Used IP addresses:</span>
                <div
                    ng-repeat="s in row.data.config.configured_subnets"
                    style="margin:3px 0">
                    <span><strong>{{ s.asString }}</strong></span>:
                    <span ng-repeat="ip in s.ip_alloced">
                        {{ ip.ip.addr + ' (' + ip.se_ref.name() + ') ' + ($last ? '' : ', ') }}
                    </span>
                </div>
            </div>`;

        this._infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    /**
     * Handler for the 'cloudChange' event. Sets the Network collection based on the cloud and
     * loads it.
     */
    handleCloudChange = () => {
        this._$state.reload(this._$state.$current.name);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
        this._infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }
}

NetworkListController.$inject = [
    'CRUDGridConfig',
    'ConfiguredNetworkCollection',
    'infraCloudState',
    'getSubnetString',
    '$state',
];

angular.module('aviApp').component('networkList', {
    controller: NetworkListController,
    template:
        `<div class="infrastructure-detail">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-network-list">
            </collection-grid>
        </div>`,
});
