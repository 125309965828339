/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './virtualservice-grid-address.less';

/**
 * @ngdoc component
 * @name  virtualserviceGridAddress
 * @description  Displays a list of addresses in the Virtual Service grid.
 * @param  {VirtualService} row
 */
class VirtualserviceGridAddressController {
    constructor($filter) {
        this.getUnitStateLabel_ = $filter('unitStateLabel');
    }

    /**
     * Returns a string of comma-separated VIP addresses.
     * @return {string}
     */
    getVips() {
        const runtime = this.row.getRuntimeData();

        if (angular.isUndefined(runtime) || !angular.isArray(runtime.vip_summary)) {
            return;
        }

        const vips = runtime.vip_summary.reduce((acc, vip) => {
            let addr = '';

            if (!_.isEmpty(vip.ip_address)) {
                addr = vip.ip_address.addr;

                if (!_.isEmpty(vip.floating_ip)) {
                    addr += `(${vip.floating_ip.addr})`;
                }
            } else if (!_.isEmpty(vip.floating_ip)) {
                addr = vip.floating_ip.addr;
            }

            if (!_.isEmpty(vip.ip6_address)) {
                if (addr.length > 0) {
                    addr += ', ';
                }

                addr += vip.ip6_address.addr;

                if (!_.isEmpty(vip.floating_ip6)) {
                    addr += `(${vip.floating_ip6.addr})`;
                }
            } else if (!_.isEmpty(vip.floating_ip6)) {
                addr = vip.floating_ip6.addr;
            }

            const { state, reason } = vip.oper_status;
            let status = this.getUnitStateLabel_(state);

            if (state !== 'OPER_UP') {
                status += `: ${reason[0]}`;
            }

            acc.push(`${addr} (${status})`);

            return acc;
        }, []);

        return vips.join(', ');
    }

    getIconClass(state) {
        return this.getUnitStateLabel_(state).toLowerCase() === 'up' ?
            'icon-arrow-up' : 'icon-arrow-down';
    }
}

VirtualserviceGridAddressController.$inject = [
    '$filter',
];

angular.module('aviApp').component('virtualserviceGridAddress', {
    bindings: {
        row: '<',
    },
    controller: VirtualserviceGridAddressController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-list/' +
            'virtualservice-grid-address/virtualservice-grid-address.html',
});
