/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc Factory
 * @name  msAppMapIsAvail
 * @author Alex Malitsky
 * @description
 *
 *     Used by angular-ui-router as a resolve object to check whether we have at least one VS which
 *     belongs to one of clouds supporting msAppMap.
 *
 */
angular.module('router.vantage.avi').factory('msAppMapIsAvail', [
'$http', 'Cloud', function($http, Cloud) {
    const url =
        `api/virtualservice?cloud_type=${Cloud.containerCloudTypes.join()}&fields=name&page_size=1`;

    return () => $http.get(url)
        .then(
            ({ data }) => (data ? !!data.count : false),
            () => false,
        );
}]);
