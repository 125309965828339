/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  webhookCreate
 * @param {Webhook} editable - Webhook item.
 * @param {function} closeModal - Closes modal.
 * @description
 *     Creates/edits a webhook item.
 */
class Controller {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }
}

Controller.$inject = [
        'Schema',
        'Regex',
];

angular.module('aviApp').component('webhookCreate', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/templates/profiles/webhook-create.html',
});
