/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ClusterNodeRuntimeCollDataSource', ['ListCollDataSource',
function(ListCollDataSource) {
    function ClusterNodeRuntimeCollDataSource(args) {
        ClusterNodeRuntimeCollDataSource.superconstructor.call(this, args);
    }

    avi.inherit(ClusterNodeRuntimeCollDataSource, ListCollDataSource);

    ClusterNodeRuntimeCollDataSource.prototype.hasSearch = false;
    ClusterNodeRuntimeCollDataSource.prototype.hasSorting = false;
    ClusterNodeRuntimeCollDataSource.prototype.hasPagination = false;

    ClusterNodeRuntimeCollDataSource.prototype.defaultParams_ = {};

    ClusterNodeRuntimeCollDataSource.prototype.defaultFields_ = [
        {
            id: 'runtime',
            preserved: true,
            subscribers: ['__mandatory_field'],
        },
    ];

    ClusterNodeRuntimeCollDataSource.prototype.getRequestParams_ = function() {
        const params = ClusterNodeRuntimeCollDataSource.superclass.getRequestParams_
            .call(this, this.params_);

        params['objectName_'] = [
            this.owner_.objectName_,
            'runtime',
        ];

        return params;
    };

    return ClusterNodeRuntimeCollDataSource;
}]);
