/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './sensitive-field-rule-modal.less';

/**
 * @ngdoc component
 * @name sensitiveFieldRuleModal
 * @description Modal for configuring a Sensitive Field rule.
 * @param {SensitiveFieldRuleConfig} rule
 *      Sensitive Field rule. SensitiveFieldRuleConfig ConfigItem.
 * @param {Function} onSubmit - Called when the user saves the rule.
 * @param {Function} closeModal - Called to close the modal.
 */
class SensitiveFieldRuleModalController {
    constructor(Schema, schemaService) {
        this.Schema = Schema;
        this.errors = null;

        const stringOperationEnumObjects = schemaService.getEnumValues('StringOperation');

        // regex operations are not supported
        this.matchPatterns = stringOperationEnumObjects.filter(
            ({ value }) => !value.startsWith('REGEX'),
        );
    }

    /**
     * Called when the Enable Action checkbox is changed.
     */
    handleEnableAction() {
        if (this.rule.actionEnabled) {
            this.rule.addLogAction();
        } else {
            this.rule.removeLogAction();
        }
    }

    /**
     * Called when the Submit button is clicked. Saves the rule.
     */
    handleSubmit() {
        this.errors = null;
        this.onSubmit({ rule: this.rule })
            .then(() => this.closeModal())
            .catch(({ data }) => this.errors = data);
    }
}

SensitiveFieldRuleModalController.$inject = [
    'Schema',
    'schemaService',
];

angular.module('aviApp').component('sensitiveFieldRuleModal', {
    controller: SensitiveFieldRuleModalController,
    bindings: {
        rule: '<',
        onSubmit: '&',
        closeModal: '&',
    },
    templateUrl: 'src/components/templates/profiles/analytics/sensitive-field-rule-modal/' +
                    'sensitive-field-rule-modal.html',
});
