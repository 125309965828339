/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name durationDates2str
 * @author Alex Malitsky
 * @description
 *
 *     Used by LogBarChart
 *     Returns an array of two strings, representing two dates: start and end, in format,
 *     depending on duration between these dates.
 *     For ex: ['10:03:51 am','10:05:10 am'] or ['03/23 11:48 AM', '06/15 09:17 PM'].
 *     Instead of stop one can pass integer with ms number as duration between start and stop.
 *     Start and stop should be Moment objects or strings to be parsed by Moment constructor.
 *
 **/
angular.module('aviApp').factory('durationDates2str', ['myAccount', function(myAccount) {
    return function(start, stop) {
        const res = [];

        let diff,
            format = '';

        start = myAccount.uiProperty.useUTCTime ?
            moment.utc(start) : moment(start);

        if (typeof stop === 'number' && start.isValid()) {
            diff = stop;
            stop = start.clone().add(stop, 'ms');
        } else if (start.isValid()) {
            stop = myAccount.uiProperty.useUTCTime ?
                moment.utc(stop) : moment(stop);
            diff = stop.diff(start);
        }

        if (!start.isValid() || !stop.isValid() || stop.isBefore(start)) { return; }

        if (diff < 4 * 3600 * 1000) {
            format = 'h:mm:ss a';
        } else {
            format = 'MM/DD h:mm A';
        }

        res[0] = start.format(format);
        res[1] = stop.format(format);

        return res;
    };
}]);
