/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './category-navbar.less';

const componentTag = 'category-navbar';
const pathPrefix = `src/components/avi-header/${componentTag}`;

const stateTemplatePathHash = {
    application: `${pathPrefix}/${componentTag}-applications.html`,
    operations: `${pathPrefix}/${componentTag}-operations.html`,
    profile: `${pathPrefix}/${componentTag}-templates.html`,
    infrastructure: `${pathPrefix}/${componentTag}-infrastructure.html`,
    administration: `${pathPrefix}/${componentTag}-administration.html`,
};

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.categoryNavbarComponent}
 */
class CategoryNavbarController {
    constructor($state, $stateParams, systemInfoService) {
        this.$state_ = $state;
        this.$stateParams = $stateParams;
        this.systemInfoService_ = systemInfoService;
    }

    /**
     * Returns true if a GSLB config exists.
     * @returns {boolean}
     */
    hasGSLBConfig() {
        return this.systemInfoService_.haveGSLBConfig();
    }

    /**
     * Returns the template path of the current category.
     * @returns {string}
     */
    getNavbarTemplatePath() {
        const stateParts = this.$state_.$current.name.split('.');

        return stateTemplatePathHash[stateParts[1]];
    }
}

CategoryNavbarController.$inject = [
    '$state',
    '$stateParams',
    'systemInfoService',
];

/**
 * @name categoryNavbarComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.CategoryNavbarController} controller
 * @desc
 *      Component in the header showing the substates of a category.
 * @author alextsg
 */
angular.module('avi/navigation').component('categoryNavbar', {
    controller: CategoryNavbarController,
    templateUrl: `${pathPrefix}/${componentTag}.html`,
});
