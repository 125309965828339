/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name LogCalloutController
 * @author Alex Malitsky (shame to admit)
 * @description
 *     Used inside LogController and logCallout directive, works with their values (sic!).
 *     scope.params.layer & scope.params.queryString - from logController, scope.calloutType -
 *     from directive.
 *     Most of callout types use fetch only once, on initial load. e2e has links in header to load
 *     different types.
 **/
//TODO, remove wrapper from callout templates, check timeouts and calls on destroy, rebuild
angular.module('logs.vantage.avi').controller('LogCalloutController', [
    '$scope', '$http', '$q', '$timeout', 'detailsExpander', 'logDataTransform', 'noValueLogLabel',
    'logApiMaxEntriesLimit',
    function($scope, $http, $q, $timeout, DetailsExpander, logDataTransform, noValueLogLabel,
             logApiMaxEntriesLimit) {
        const getQueryString = (groupBy, pageSize = 10) =>
            `groupby=${groupBy}&page=1&page_size=${pageSize}&orderby=-count`;

        const templates = {
            'waf_log.rule_logs.tags': {
                header: 'WAF Tags',
                name: 'WAF Tags',
                param: 'waf_log.rule_logs.tags',
                query: getQueryString('waf_log.rule_logs.tags'),
            },
            'waf_log.rule_logs.rule_id': {
                header: 'WAF Rules',
                name: 'WAF Rules',
                params: [
                    'waf_log.rule_logs.rule_id',
                    'waf_log.rule_logs.rule_name',
                ],
                query: getQueryString('waf_log.rule_logs.rule_id,waf_log.rule_logs.rule_name'),
            },
            'waf-phase-latency': {
                header: 'WAF Latency',
                name: 'WAF Latency',
                phases: [{
                    name: 'Request Header',
                    groupby: 'waf_log.latency_request_header_phase',
                }, {
                    name: 'Request Body',
                    groupby: 'waf_log.latency_request_body_phase',
                }, {
                    name: 'Response Header',
                    groupby: 'waf_log.latency_response_header_phase',
                }, {
                    name: 'Response Body',
                    groupby: 'waf_log.latency_response_body_phase',
                }],
            },
            'waf-rule-groups': {
                header: 'WAF Groups',
                name: 'WAF Groups',
                params: [
                    'waf_log.rule_logs.rule_id',
                    'waf_log.rule_logs.rule_name',
                    'waf_log.rule_logs.rule_group',
                ],
                query: getQueryString(
                    'waf_log.rule_logs.rule_id,' +
                    'waf_log.rule_logs.rule_group,' +
                    'waf_log.rule_logs.rule_name', 100,
                ),
            },
            country: {
                header: 'Top Locations',
                name: 'Location',
                query: getQueryString('client_location'),
                unique: 'Unique Location',
                param: 'client_location',
            },
            device: {
                header: 'Top Devices',
                name: 'Device',
                query: getQueryString('client_device'),
                param: 'client_device',
            },
            host: {
                header: 'Top Hosts',
                name: 'Host',
                query: getQueryString('host'),
                param: 'host',
            },
            browser: {
                header: 'Top Browsers',
                name: 'Browser',
                query: getQueryString('client_browser'),
                param: 'client_browser',

            },
            'request-type': {
                header: 'Top Request Types',
                name: 'Request type',
                query: getQueryString('method'),
                param: 'method',
            },
            'response-code': {
                header: 'Top Response Codes',
                name: 'Response code',
                query: getQueryString('response_code'),
                param: 'response_code',

            },
            'ip-addr': {
                header: 'Top IP Addresses',
                name: 'IP',
                query: getQueryString('client_ip,client_ip6'),
                param: 'client_ip',
                unique: 'Unique IP Address',
                uniquePluralEnd: 'es',
            },
            response_content_type: {
                header: 'Top Content Types',
                name: 'Content Type',
                query: getQueryString('response_content_type'),
                param: 'response_content_type',
            },
            'server-ip-addr': {
                header: 'Top Server IP Addresses',
                name: 'Server IP',
                query: getQueryString('server_ip,server_ip6'),
                param: 'server_ip',
                unique: 'Unique IP Address',
                uniquePluralEnd: 'es',
            },
            'e2e-timing': {
                header: {
                    total_time: 'Total Response Times',
                    data_transfer_time: 'Data Transfer Times',
                    client_rtt: 'Client Round Trip Times',
                    server_rtt: 'Server Round Trip Times',
                    app_response_time: 'Application Response Times',
                },
                name: 'Time Range (ms)',
                query: {
                    total_time: '',
                    data_transfer_time: '',
                    client_rtt: '',
                    server_rtt: '',
                    app_response_time: '',
                    //for average values in the header
                    average7: 'cols=AVG(data_transfer_time),AVG(client_rtt),' +
                        'AVG(app_response_time),AVG(server_rtt),AVG(total_time)&groupby=full',
                    average4: 'cols=AVG(client_rtt),AVG(server_rtt),AVG(total_time)&groupby=full',
                },
            },
            http_version: {
                header: 'HTTP Version',
                name: 'HTTP Version',
                query: getQueryString('http_version'),
                param: 'http_version',
            },
            uri_path: {
                header: 'URL Paths',
                name: 'URL Path',
                query: getQueryString('uri_path'),
                param: 'uri_path',
            },
            referrer: {
                header: 'Referer',
                name: 'Referer',
                query: getQueryString('referer'),
                param: 'referer',
                unique: 'Unique Referer',
            },
            client_os: {
                header: 'Top Operating Systems',
                name: 'Operating System',
                query: getQueryString('client_os'),
                param: 'client_os',
            },
            request_length: {
                header: 'Request Lengths',
                name: 'Request Length',
                param: 'request_length',
            },
            response_length: {
                header: 'Response Lengths',
                name: 'Response Length',
                param: 'response_length',
            },
            policy: {
                header: 'Top Policies',
                name: 'Rule Name',
                subheader: {
                    network: 'Network Security',
                    http: 'HTTP Security',
                    http_req: 'HTTP Request',
                    http_resp: 'HTTP Response',
                },
                query: {
                    /* groupby */
                    network: 'network_security_policy_rule_name',
                    http: 'http_security_policy_rule_name',
                    http_req: 'http_request_policy_rule_name',
                    http_resp: 'http_response_policy_rule_name',
                },
                param: 'policy',
            },
            significance: {
                header: 'Top Significance',
                name: 'Primary Reason',
                query: 'groupby=significance&page=1&page_size=64&orderby=-count',
                param: 'significance',
            },
            ssl: {
                header: 'SSL/TLS Information',
                name: 'Version',
                subheader: {
                    ssl: 'SSL/TLS Version',
                    pfs: 'Perfect Forward Secrecy',
                    ap: 'Authentication Protocol',
                    ep: 'Encryption Protocol',
                },
                key: {
                    /* way to find data inside response array */
                    pfs: 'PFS',
                    ap: 'auth',
                    ep: 'enc',
                },
                query: {
                    /* groupby */
                    ssl: 'ssl_version',
                    other: 'ssl_cipher', /* for all others */
                },
            },
            user_id: {
                header: 'Top User IDs',
                name: 'User ID',
                query: getQueryString('user_id'),
                param: 'user_id',

            },
            microservice_name: {
                header: 'Top Microservices',
                name: 'Client Application',
                query: getQueryString('microservice_name'),
                param: 'microservice_name',
            },
            dns_fqdn: {
                header: 'Top FQDN/Domain Names',
                name: 'Domain Name',
                query: getQueryString('dns_fqdn'),
                param: 'dns_fqdn',
            },
            dns_qtype: {
                header: 'Top Query Types',
                name: 'Query Type',
                query: getQueryString('dns_qtype'),
                param: 'dns_qtype',
            },
            gslbservice_name: {
                header: 'Top GSLB Service Names',
                name: 'GSLB Service Name',
                query: getQueryString('gslbservice_name'),
                param: 'gslbservice_name',
            },
            rx_bytes: {//dns app type
                header: 'Request Lengths',
                name: 'Request Length',
                param: 'rx_bytes',
            },
            tx_bytes: {
                header: 'Response Lengths',
                name: 'Response Length',
                param: 'tx_bytes',
            },
            service_engine: {
                header: 'Service Engines',
                name: 'Service Engine IP',
                param: 'service_engine',
                query: getQueryString('service_engine'),
            },
            protocol: {
                header: 'Protocols',
                name: 'Protocol',
                param: 'protocol',
                query: getQueryString('protocol'),
            },
            dns_etype: {
                header: 'Record Sources',
                name: 'Record Source',
                param: 'dns_etype',
                query: getQueryString('dns_etype'),
            },
            vs_ip: {
                header: 'VS IP Addresses',
                name: 'VS IP Address',
                param: 'vs_ip',
                query: getQueryString('vs_ip,vs_ip6'),
            },
            'e2e-timing-udp': {
                header: 'End to End (UDP)',
                name: 'Time range (ms)',
                param: 'total_time',
            },
        };

        /** @type {string} */
        const noValueLabel = noValueLogLabel;

        $scope.template = templates[$scope.calloutType];
        $scope.template.type = $scope.calloutType;
        $scope.loading = { list: true, chart: true };
        $scope.isHTTPVS = $scope.params.isHTTPVS;
        $scope.chartData = [];//data for chart
        $scope.items = [];//list for results table
        $scope.expander = new DetailsExpander();
        $scope.popover = {
            updateLayout() {
                $timeout(updateLayout, 9);
            },
        };

        $scope.passSearchFilter = function(str) {
            $scope.ui.updateSearch(str);
            $scope.removeCallout();
        };

        /**
         * Returns callout value if there is more than one groupby param used,
         *      but only one param should be shown.
         * @param {string} param - Logs API request param.
         * @param {Object} record - Logs API response entry.
         * @return {Object} - Data object for callout value.
         */
        const selectValue = (param, record) => {
            let value = record[param];
            const ret = { value, param };
            const either = [
                ['client_ip', 'client_ip6'],
                ['vs_ip', 'vs_ip6'],
                ['server_ip', 'server_ip6'],
            ];

            either.forEach(([p2, p1]) => {
                if (p1 in record && record[p1] !== '') {
                    value = record[p1];
                    ret.param = p1;
                } else if (p2 in record) {
                    value = record[p2];
                    ret.param = p2;
                }
            });

            if (value === '') {
                ret.display = noValueLabel;
            }

            ret.value = value;

            return ret;
        };

        function fetch(key) { //default table
            let query;

            $scope.loading.list = true;
            $scope.items = [];

            const { template } = $scope;

            if (key || typeof template.query !== 'string') {
                key = key || Object.keys(template.query)[0];
                query = template.query[key];
            } else {
                query = template.query;
            }

            $http.get($scope.params.queryString('callout') + query)//for list
                .then(({ data }) => {
                    if (data.results.length > 10) {
                        data.results.length = 10;
                    }

                    const { type, param, params } = template;

                    $scope.items = data.results;

                    const { items } = $scope;

                    data.results.forEach(record => {
                        if (Array.isArray(params)) {
                            record.values = params.map(p => {
                                return {
                                    value: record[p],
                                    param: p,
                                };
                            });
                        } else if (angular.isString(param)) {
                            record.value = selectValue(param, record);
                        }
                    });

                    if (template.unique) {
                        $scope.uniqueQ = data.count;
                    }

                    if (type === 'server-ip-addr') {
                        items.forEach(({ value }) => {
                            const transformed =
                                logDataTransform.propOnLoad('server_ip', value.value);

                            if (transformed) {
                                value.display = transformed.display;
                            }
                        });
                    } else if (type === 'referrer' || type === 'gslbservice_name' ||
                        type === 'uri_path') {
                        items.forEach(({ value }) => {
                            value.display = value.value === '' ? noValueLabel : value.value;
                        });
                    } else if (type === 'response-code') {
                        items.forEach(({ value }) => {
                            const transformed =
                                logDataTransform.propOnLoad('response_code', value.value);

                            if (transformed) {
                                value.display =
                                    `${value.value} (${transformed.descr || 'Unrecognized code'})`;
                            }
                        });
                    } else if (type === 'http_version' || type === 'host' ||
                        type === 'request-type' || type === 'dns_fqdn' ||
                        type === 'response_content_type' || type === 'device' ||
                        type === 'browser' || type === 'client_os' || type === 'user_id') {
                        items.forEach(({ value }) => {
                            if (value.value === '') {
                                value.display = noValueLabel;
                            }
                        });
                    } else if (type === 'microservice_name') {
                        items.forEach(({ value }) => {
                            if (value.value === '') {
                                value.display = 'External-Client';
                            }
                        });
                    } else if (type === 'dns_qtype' || type === 'dns_etype' ||
                               type === 'protocol') {
                        items.forEach(({ value }) => {
                            if (value.value) {
                                value.display = logDataTransform.propOnLoad(
                                    type, value.value,
                                ).display;
                            } else {
                                value.display = noValueLabel;
                            }
                        });
                    } else if (type === 'waf-rule-groups') {
                        const wafRuleGroups = {};
                        const idKey = 'waf_log.rule_logs.rule_id';
                        const nameKey = 'waf_log.rule_logs.rule_name';
                        const groupKey = 'waf_log.rule_logs.rule_group';
                        const groupCount = {};

                        items.forEach(item => {
                            const { count, percentage } = item;
                            const ruleId = item[idKey];
                            const ruleName = item[nameKey];
                            const groupName = item[groupKey];
                            const rules = wafRuleGroups[groupName] || [];
                            let tc = groupCount[groupName] || 0;

                            tc += count;
                            groupCount[groupName] = tc;
                            rules.push({
                                ruleId: {
                                    value: ruleId,
                                    param: idKey,
                                },
                                ruleName: {
                                    value: ruleName,
                                    param: nameKey,
                                },
                                count,
                                percentage,
                            });
                            wafRuleGroups[groupName] = rules;
                        });

                        const groupKeys = Object.keys(wafRuleGroups);

                        $scope.wafRuleGroups = groupKeys.map(groupName => {
                            const rules = wafRuleGroups[groupName];
                            const totalRuleCount = groupCount[groupName];

                            return {
                                rules,
                                name: groupName,
                                count: totalRuleCount,
                                value: {
                                    value: groupName,
                                    param: groupKey,
                                },
                            };
                        });
                    }
                }, (data, status) => {
                    const msg = `LogCallout query failed, ${status}.`;

                    console.warn(msg);

                    return $q.reject(msg);
                })
                .finally(() => {
                    $scope.loading.list = false;
                    $scope.popover.updateLayout();
                });
        }

        $scope.topTenList = {
            /* for grid layout */
            fields: [{
                name: 'value',
                title: $scope.template.name,
                template: '<log-callout-value data="row.value"' +
                    'on-click="config.passSearchFilter(str)"></log-callout-value>',
            }, {
                name: 'count',
                title: '# Logs',
            }, {
                name: 'percentage',
                title: '% of Logs',
            }, {
                name: 'percentageBar',
                title: '',
                template: '<div class="progress"><div class="progress-bar" role="progressbar"' +
                    'aria-valuenow="{{ row.percentage }}" aria-valuemin="0" aria-valuemax="100"' +
                    ' style="width: {{ row.percentage }}%"></div></div>',
            }],
            rowId: 'value.value',
            passSearchFilter(str) {
                $scope.passSearchFilter(str);
            },
            searchFields: ['value', 'count', 'percentage'],
        };

        if ($scope.template.type === 'policy') {
            fetchConcurrent($scope.template);
        } else if ($scope.template.type === 'waf-phase-latency') {
            const url = $scope.params.queryString('callout');
            const params = {
                step: 100,
                page_size: 4,
                expstep: true,
            };

            const { phases } = $scope.template;
            const requests = phases.map(phase => {
                const param = angular.copy(params);

                param.groupby = phase.groupby;

                return $http.get(url, {
                    params: param,
                });
            });

            $q.all(requests).then(results => {
                $scope.wafLatencyPhases = results
                    .map(({ data }, i) => {
                        return data.results.map(r => {
                            const phase = phases[i];

                            r.param = phase.groupby;
                            r.value = r[r.param];

                            if (!r.value) {
                                r.display = noValueLabel;
                            }

                            return r;
                        });
                    });
                $scope.popover.updateLayout();
            });
        } else if ($scope.template.type === 'e2e-timing') {
            $scope.e2e = {
                average: {},
                asc: true,
                step: false,
                view: false,
                orderby: false,
                orderBy(name) {
                    if (this.orderby && this.orderby === name) {
                        this.asc = !this.asc;
                    } else {
                        this.orderby = name;
                        this.asc = false;
                    }

                    this.fetchE2E(this.view);
                },
                fetchE2E(key) {
                    const self = this;

                    const fetchE2Eaverage = function() { //values for e2e callout header
                        const query =
                            $scope.template.query[`average${$scope.isHTTPVS ? '7' : '4'}`];

                        return $http.get($scope.params.queryString('callout') + query)
                            .then(({ data }) => [self.average] = data.results)
                            .catch(({ status }) =>
                                console.warn(`LogCallout average query failed. ${status}`));
                    };

                    function fetch(step) {
                        const promises = [];
                        const query =
                            `groupby=${key}&page_size=10&expstep=true&step=${step}` +
                            `&orderby=${self.asc ? '' : '-'}${self.orderby}`;

                        $scope.loading.list = true;
                        $scope.items = [];

                        const listLoad = $http.get($scope.params.queryString('callout') + query)
                            .then(({ data }) => {
                                $scope.items = data.results.map(val => {
                                    const res = val;

                                    res.value = {
                                        value: val[key],
                                        param: self.view,
                                    };

                                    return res;
                                });
                            })
                            .catch(({ status }) =>
                                console.warn(`e2e callout query failed. ${status}`))
                            .finally(() => $scope.loading.list = false);

                        //for list
                        promises.push(listLoad);

                        /* don't request chart info for sorting requests */
                        if (!$scope.chartData.length) {
                            $scope.loading.chart = true;
                            promises.push(fetchChart(key, step));
                        }

                        $q.all(promises).then(function() {
                            $scope.popover.updateLayout();
                        });
                    }

                    if (!key) { //initial start
                        [key] = Object.keys($scope.template.query);
                        this.orderby = key;
                    }

                    this.view = key;

                    if (!this.average.count) {
                        fetchE2Eaverage();//onLoad only
                    }

                    this.step = this.view === 'client_rtt' || this.view === 'server_rtt' ? 10 : 100;
                    fetch(this.step);
                },
                switchView(key) {
                    this.orderby = key;
                    this.asc = true;
                    this.step = false;
                    $scope.chartData = [];
                    this.fetchE2E(key);
                },
            };

            $scope.e2e.fetchE2E();
        } else if (_.contains(
            ['rx_bytes', 'tx_bytes', 'request_length', 'response_length', 'e2e-timing-udp'],
            $scope.template.type,
        )) {
            angular.extend($scope.template, {
                asc: true,
                orderBy(name) {
                    if (this.orderby && this.orderby === name) {
                        this.asc = !this.asc;
                    } else {
                        this.orderby = name;
                        this.asc = false;
                    }

                    this.fetchRlength();
                },
                fetchRlength() {
                    const step = 512;
                    const query =
                        `groupby=${this.param}&step=${step}&page=1&page_size=10&expstep=true` +
                        `&orderby=${this.asc ? '' : '-'}${this.orderby}`;
                    const promises = [];

                    $scope.loading.list = true;
                    $scope.items = [];

                    const listLoad =
                        $http.get($scope.params.queryString('callout') + query)
                            .then(({ data }) => {
                                $scope.items = data.results.map(res => {
                                    const value = res[this.param];
                                    let { start, end } = value;

                                    if ($scope.template.type !== 'e2e-timing-udp') {
                                        if (angular.isNumber(start)) {
                                            start = Math.formatBytes(start);
                                        }

                                        if (angular.isNumber(end)) {
                                            end = Math.formatBytes(end);
                                        }
                                    }

                                    res.value = {
                                        value,
                                        param: $scope.template.param,
                                    };

                                    if (angular.isUndefined(start) || angular.isUndefined(end)) {
                                        res.value = 'missing';
                                    } else {
                                        res.value.display = `${start} ‒ ${end}`;
                                    }

                                    return res;
                                });
                            })
                            .catch(({ status }) =>
                                console.warn(`LogCallout fetchRlength query failed. ${status}`))
                            .finally(() => $scope.loading.list = false);

                    promises.push(listLoad);

                    //sorting does not ask for new chart data
                    if (!$scope.chartData || !$scope.chartData.length) {
                        $scope.loading.chart = true;
                        promises[1] = fetchChart(this.param, step);
                    }

                    $q.all(promises).then(function() {
                        $scope.popover.updateLayout();
                    });
                },
            });

            $scope.template.orderby = $scope.template.param;

            $scope.template.fetchRlength();
        } else if ($scope.template.type === 'significance') {
            fetchSignificance();//one query with grouping of results
        } else if ($scope.template.type === 'ssl') {
            fetchSSL();
        } else {
            fetch();
        }

        function fetchSSL() {
            const promises = {};

            _.each($scope.template.query, function(val, key) {
                const query = `groupby=${val}&page=1&page_size=10&orderby=-count`;

                promises[key] = $http.get($scope.params.queryString('callout') + query);
            });

            $q.all(promises)
                .then(responses => {
                    const { ssl, other } = responses;

                    if (Array.isArray(ssl.data.results)) {
                        const { results } = ssl.data;
                        const children = results.map(val => {
                            const param = $scope.template.query['ssl'];
                            const value = val[param];

                            val.value = { value, param };

                            if (!value) {
                                val.value.display = noValueLabel;
                            }

                            return val;
                        });

                        $scope.items.push({
                            value: $scope.template.subheader['ssl'],
                            children,
                        });
                    }

                    /* all others */
                    _.each($scope.template.key, (param, key) => {
                        const data = other.data['category_results'][$scope.template.key[key]];

                        //redunant structure to support clickable filters from values
                        if (Array.isArray(data)) {
                            const children = data.map(val => {
                                const { value } = val;

                                val.value = {
                                    display: value,
                                    value: `${param}:${value}`,
                                    param: $scope.template.query['other'],
                                    contains: 'true',
                                };

                                return val;
                            });

                            $scope.items.push({
                                value: $scope.template.subheader[key],
                                children,
                            });
                        }
                    });
                    $scope.expander.setLength($scope.items.length);
                    $scope.expander.toggle();
                }, reason => console.warn(`LogCallout:fetchSSL query failed. ${reason}`))
                .finally(() => {
                    $scope.loading.list = false;
                    $scope.popover.updateLayout();
                });
        }

        //in one query we get flat list of groups and children, have to make two level list
        function fetchSignificance() {
            const { param, query } = $scope.template;

            $http.get($scope.params.queryString('callout') + query)//for list
                .then(({ data }) => {
                    const res = [];

                    data.category_results.forEach(parent => {
                        const nonSignificant = parent.primary_reason === 'Non-Significant Log';

                        parent.value = {
                            display: parent.primary_reason,
                            value: nonSignificant ? '' : parent.primary_reason,
                            contains: nonSignificant ? undefined : 'true',
                            param,
                        };

                        parent.children = parent.secondary_reasons.map(val => {
                            const value = val[param];
                            const display = `${parent.value.value}: ${value}`;

                            val.value = {
                                value: display,
                                contains: 'true',
                                display,
                                param,
                            };

                            return val;
                        });
                        res.push(parent);
                    });
                    $scope.expander.setLength(res.length);
                    $scope.items = res;
                })
                .catch(({ status }) =>
                    console.warn(`LogCallout fetchSignificance query failed. ${status}`))
                .finally(() => {
                    $scope.loading.list = false;
                    $scope.popover.updateLayout();
                });
        }

        //policies
        function fetchConcurrent(template) {
            const promises = {};
            const queryBase = $scope.params.queryString('callout');

            _.each(template.query, (val, key) => {
                const { http_policies: httpPolicies } = $scope.VirtualService.getConfig();
                const vsPolicy = httpPolicies[0] && httpPolicies[0].http_policy_set_ref_data &&
                    httpPolicies[0].http_policy_set_ref_data[val.slice(0, -10)];

                if (vsPolicy && Array.isArray(vsPolicy.rules)) {
                    const query = `groupby=${val}&page=1&page_size=10&orderby=-count`;

                    promises[key] = $http.get(queryBase + query);
                }
            });

            $q.all(promises).then(responses => {
                _.each(template.query, (param, key) => {
                    let children;

                    //redunant structure to support clickable filters from values
                    if (responses[key]) {
                        children = responses[key].data.results.map(val => {
                            const value = {
                                value: val[param],
                                param,
                            };

                            val.value = value;

                            if (value.value === '') {
                                value.display = noValueLabel;
                            }

                            return val;
                        });
                        $scope.items.push({
                            value: template.subheader[key],
                            children,
                        });
                    }
                });
                $scope.expander.setLength($scope.items.length);
                $scope.expander.toggle();
            }, reason => console.warn(`LogCallout:fetchConcurrent query failed. ${reason}`))
                .finally(() => {
                    $scope.loading.list = false;
                    $scope.popover.updateLayout();
                });
        }

        function fetchChart(param, step) {
            const url = `${$scope.params.queryString('callout')}step=${step}&groupby=` +
                `${param}&orderby=${param}&page_size=${logApiMaxEntriesLimit}&expstep=true`;

            return $http.get(url)
                .then(({ data }) => {
                    $scope.chartData = data.results.map(d => {
                        d.value = d[param];

                        return d;
                    });
                })
                .catch(({ status }) => console.warn(`LogCallout chart query failed. ${status}`))
                .finally(() => $scope.loading.chart = false);
        }

        function updateLayout() { //resize and replace callout div on content load
            const
                elm = $('div.log-callout:visible'),
                contHeight = elm.find('div.wrap > div.content').height(),
                wrap = elm.find('div.wrap'),
                wrapHeight = wrap.height(),
                margin = 15; //margin from bottom of window & link in sidebar

            let height = elm.height(); //height of content wo padding and margin

            const padding = elm.outerHeight() - height;//padding&border

            if (!elm.length) {
                return;
            }

            /* on fast open&close of popover */
            const oldTop = elm.offset().top - $(window).scrollTop();
            let newTop = elm.offset().top - $(window).scrollTop();

            if (Math.abs(wrapHeight - contHeight) > 3) {
                wrap.height(contHeight);
            }

            if (Math.abs(height - contHeight) > 3) {
                height = contHeight;
                elm.height(height);
            }

            const overflow = oldTop + height + padding - $(window).height();

            if (overflow + margin > 0) {
                newTop = Math.max(margin, oldTop - overflow - margin);
                elm.css({ top: newTop });
            }

            $scope.checkCarrat({ top: newTop, height: height + padding });
        }

        $scope.close = function() {
            $scope.removeCallout();//from logCallout directive
        };
    }]);
