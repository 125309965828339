/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  VsVipListDataTransformer
 * @description
 *     DataTransformer for the VsVipCollection. Modifies the name of the VsVip so that when used in
 *     a CollectionDropdown, the user sees a comma-separated list of VS names sharing the VsVip
 *     instead of a meaningless VsVip name.
 */
const VsVipListDataTransformerFactory = ListDataTransformer => {
    return class VsVipListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(rsp, request) {
            rsp = super.processResponse.call(this, rsp, request);

            if (rsp.data && angular.isArray(rsp.data.results)) {
                rsp.data.results.forEach(result => {
                    const { config } = result;

                    if (angular.isArray(config.vs_refs) && config.vs_refs.length) {
                        const actualVsVipName = config.name;
                        const vsNames = config.vs_refs.map(ref => ref.name()).join(', ');

                        config.name = vsNames;
                        config.url = config.url.replace(`#${actualVsVipName}`, `#${vsNames}`);
                    }
                });
            }

            return rsp;
        }
    };
};

VsVipListDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

angular.module('aviApp').factory('VsVipListDataTransformer', VsVipListDataTransformerFactory);
