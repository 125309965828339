/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type function
 * @description
 *     Returns a string representation of server information in the format
 *     <ip>:<port> (<hostname>)
 */
const serverStringBuilder = function(server) {
    let output = server.ip.addr;

    if (!angular.isUndefined(server.port)) {
        output += `:${server.port}`;
    }

    if (!angular.isUndefined(server.hostname)) {
        output += ` (${server.hostname})`;
    }

    return output;
};

angular.module('aviApp').constant('serverStringBuilder', serverStringBuilder);
