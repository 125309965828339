/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateGridNameColumn
 * @description  Displays the status icon of the SSL Certificate along with a tooltip.
 * @param {Certificate} row - Certificate object.
 */
function Controller(Schema, $templateCache) {
    this.$onInit = function() {
        this.config = this.row.data.config;

        this.chainTooltip = require('./certificate-list-chain-tooltip.partial.html');
    };

    /**
     * Returns an output string of the Certificate name, 'Self-signed', 'Incomplete', or 'N/A'
     * depending on the chain of SSL certificates.
     * @param {Certificate} - Certificate config object.
     */
    this.getCertName = function(config) {
        let output = 'N/A';

        if (config.status === 'SSL_CERTIFICATE_PENDING') {
            output = 'Incomplete';
        } else if (config.certificate.self_signed) {
            output = 'Self-signed';
        } else if (config.ca_certs && config.ca_certs.length) {
            output = config.name;
        }

        return output;
    };
}

Controller.$inject = [
    'Schema',
    '$templateCache',
];

angular.module('aviApp').component('sslCertificateGridNameColumn', {
    bindings: {
        row: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-grid-name-column/ssl-certificate-grid-name-column.html',
});
