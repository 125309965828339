/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name CloudLicenseTypes
 * @returns {Object} - Hash where CloudType enum is a key and value is a list of LicenseType enums.
 */
angular.module('aviApp').factory('CloudLicenseTypes', [
'Schema', 'CloudType', function(Schema, CloudType) {
    const
        licenses = {},
        { values } = Schema.enums.CloudType;

    _.each(CloudType, prop => {
        if (prop in values) {
            const { cloud_type_options: options } = values[prop].options;

            if (options.value && Array.isArray(options.value.allowed_licenses)) {
                licenses[prop] = options.value.allowed_licenses.concat();
            }
        }
    });

    return licenses;
}]);
