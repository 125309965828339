/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('networkSecurityPolicy', [
'Schema', 'Regex', '$timeout', '$templateCache', 'MicroServiceGroupCollection', 'PolicyGridConfig',
'AviConfirmService',
function(Schema, Regex, $timeout, $templateCache, MicroServiceGroupCollection, PolicyGridConfig,
AviConfirmService) {
    return {
        scope: {
            policy: '=',
            current: '=',
            services: '=',
            readonly: '@',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/network-security-policy.html',
        link(scope, elm) {
            scope.data = scope.policy.getConfig();

            scope.Schema = Schema;
            scope.Regex = Regex;

            scope.rule2EditMode = function(rule) {
                // Convert matches
                rule.matchEdit = [];
                _.map(rule.match, function(match, id) {
                    if (match instanceof Array) {
                        _.each(match, function(m) {
                            if (scope.matches[id].init) {
                                scope.matches[id].init(m);
                            }

                            rule.matchEdit.push({
                                id,
                                value: m,
                            });
                        });
                    } else {
                        if (scope.matches[id].init) {
                            scope.matches[id].init(match);
                        }

                        rule.matchEdit.push({
                            id,
                            value: match,
                        });
                    }
                });

                rule.save = function() {
                    scope.saveRule();
                };
            };

            scope.rule2ReadMode = function(rule) {
                // Convert matches back
                rule.match = {};
                _.map(rule.matchEdit, function(match) {
                    if (scope.matches[match.id].onSubmit) {
                        scope.matches[match.id].onSubmit(match.value);
                    }

                    if (match.id == 'hdrs') {
                        if (!rule.match[match.id] || !(rule.match[match.id] instanceof Array)) {
                            rule.match[match.id] = [];
                        }

                        rule.match[match.id].push(match.value);
                    } else {
                        rule.match[match.id] = match.value;
                    }
                });
                delete rule.matchEdit;
                delete rule.save;
            };

            /**
             * Creates a new rule.
             * @param {Object=} toPosition - Determines where to place the new rule.
             * @param {string} toPosition.position - 'above' or 'below' a specified index.
             * @param {number} toPosition.index - Existing index used as reference for position.
             */
            scope.addRule = function(toPosition) {
                scope.current = {
                    enable: true,
                    name: `Rule ${
                        scope.data && scope.data.rules ? scope.data.rules.length + 1 : 1}`,
                    matchEdit: [],
                    action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
                    _toPosition: toPosition,
                };
                scope.rule2EditMode(scope.current);
            };

            /**
             * Duplicates an existing rule and allows for editing.
             * @param {Object} rule - Rule config.
             * @param {Object} toData - Contains position and index properties.
             * @param {string} toData.position - New position relative to the new index, 'above' or
             *     'below'.
             * @param {number} toData.index - New index to be moved to.
             */
            scope.duplicateRule = function(rule, toPosition) {
                scope.current = angular.extend(angular.copy(rule), {
                    name: `${rule.name} - duplicated`,
                    _toPosition: toPosition,
                });

                scope.current.index = undefined;
                scope.rule2EditMode(scope.current);
            };

            scope.editRule = function(rule) {
                scope.current = angular.copy(rule);
                scope.rule2EditMode(scope.current);
            };

            scope.saveRule = function() {
                if (!scope.current) {
                    return;
                }

                if (!scope.data) {
                    scope.data = { rules: [] };
                }

                if (!(scope.data.rules instanceof Array)) {
                    scope.data.rules = [];
                }

                // Make sure there is no rule with the same name
                scope.error = null;

                if (_.any(scope.data.rules, function(rule) {
                    return rule.name == scope.current.name && rule.index != scope.current.index;
                })) {
                    scope.error = 'Rule name already in use';

                    return;
                }

                scope.rule2ReadMode(scope.current);

                if (scope.current.index !== undefined) {
                    angular.copy(scope.current, _.find(scope.data.rules, function(item) {
                        return item.index == scope.current.index;
                    }));
                } else {
                    scope.current.index = _.max(scope.data.rules,
                        function(i) { return i.index; }).index + 1 || 1;
                    scope.data.rules.push(scope.current);
                }

                if (angular.isObject(scope.current._toPosition)) {
                    scope.policy.moveRule(scope.current, scope.current._toPosition);
                }

                scope.current = null;
            };

            /**
             * Appends a match to the current rule
             * @param type - Match type
             */
            scope.addMatch = function(type) {
                if (!scope.current || !scope.matches[type]) {
                    return;
                }

                scope.current.matchEdit.push({
                    id: type,
                    index: scope.current.matchEdit.length,
                    value: angular.copy(scope.matches[type].default),
                });

                // Scroll down
                const curOffsetTop = $('.new-match-list').offset().top;

                $timeout(function() {
                    if (!$('.new-match-list').length) {
                        return;
                    }

                    const scrollable = $(elm).closest('.scrollable');

                    scrollable.animate({
                        scrollTop: $(scrollable).scrollTop() +
                            ($('.new-match-list').offset().top - curOffsetTop),
                    });
                });
            };

            /**
             * Deletes the match from the current rule
             * @param {Object} matchOrMatchValue - the type of the match
             */
            scope.deleteMatch = function(matchOrMatchValue) {
                const index = _.findIndex(scope.current.matchEdit,
                    item => item === matchOrMatchValue || item.value === matchOrMatchValue);

                if (index !== -1) {
                    scope.current.matchEdit.splice(index, 1);
                }
            };

            /**
             * Removes the rule from the policy
             * @param rule
             */
            scope.removeRule = function(r) {
                if (scope.current && scope.current == r) {
                    scope.current = null;
                }

                scope.data.rules = _.filter(scope.data.rules, function(v, i) {
                    return v != r;
                });
            };

            // Used to filter out the rules that exist in detail.rules
            scope.matchNotUsed = function(match) {
                if (match == 'hdrs') {
                    return true;
                }

                if (scope.current && scope.current.matchEdit) {
                    const found = _.find(scope.current.matchEdit, function(item) {
                        return item.id == match;
                    });

                    if (found) {
                        return false;
                    }
                }

                return true;
            };

            scope.object2Array = function(obj) {
                const arr = [];

                _.each(obj, function(item, key) {
                    arr.push({
                        id: key,
                        data: item,
                    });
                });

                return arr;
            };

            scope.removeHeaderMatch = function(match, index) {
                match.value.splice(index, 1);

                if (!match.value.length) {
                    scope.deleteMatch(match.id);
                }
            };

            scope.matches = {
                client_ip: {
                    name: 'Client IP',
                    default: {
                        match_criteria: 'IS_IN',
                        _tmp: [{
                            type: 'custom',
                            data: '',
                        }],
                        addrs: [],
                        ranges: [],
                    },
                    stringify(m) {
                        const val = [];

                        if (m.addrs) {
                            _.each(m.addrs, function(item) {
                                val.push(item.addr);
                            });
                        }

                        if (m.ranges) {
                            _.each(m.ranges, function(item) {
                                val.push(`${item.begin.addr}-${item.end.addr}`);
                            });
                        }

                        if (m.prefixes) {
                            _.each(m.prefixes, function(item) {
                                val.push(`${item.ip_addr.addr}/${item.mask}`);
                            });
                        }

                        if (m.group_refs) {
                            _.each(m.group_refs, function(item) {
                                val.push(`group ${item.name()}` || item.slug());
                            });
                        }

                        return `${Schema.enums.MatchOperation.values[m.match_criteria]
                            .options.text.value} (${val.join(', ')})`;
                    },
                },
                vs_port: {
                    name: 'Service Port',
                    default: {
                        match_criteria: 'IS_IN',
                        ports: [],
                    },
                    stringify(m) {
                        return m.ports.join(', ');
                    },
                },
                microservice: {
                    name: 'MicroService',
                    default: {
                        match_criteria: 'IS_IN',
                        group_ref: '',
                    },
                    stringify(m) {
                        return `${Schema.enums.MatchOperation.values[m.match_criteria]
                            .options.text.value} ${m.group_ref.name()}`;
                    },
                },
            };

            scope.matchKeys = Object.keys(scope.matches);

            scope.microServiceGroupCollection = new MicroServiceGroupCollection();

            scope.networkSecurityPolicyGridConfig = new PolicyGridConfig({
                collection: scope.policy,
                controls: {
                    create: {
                        title: 'Create rule',
                        do: () => {
                            const { rows } = scope.policy;
                            const index = angular.isArray(rows) ?
                                rows.length && rows[rows.length - 1].index || 0 :
                                0;

                            scope.addRule({ index, position: 'below' });
                        },
                    },
                },
                actions: {
                    createAt: ({ index }, position) => scope.addRule({ index, position }),
                },
                singleactions: [{
                    title: 'Delete',
                    class: 'icon icon-trash',
                    do: rule => scope.policy.delete(rule),
                }, {
                    title: 'Menu',
                    template: require(
                        '../../components/applications/virtualservice/policy/' +
                        'policy-grid/policy-grid-menu.tooltip.partial.html',
                    ),
                    edit: rule => scope.editRule(rule),
                    move: rule => {
                        const rules = scope.policy.rows;

                        AviConfirmService
                            .prompt('policy-grid-prompt-index', { rule, rules })
                            .then(data => scope.policy.moveRule(rule, data));
                    },
                    duplicate: rule => {
                        AviConfirmService
                            .prompt('policy-grid-prompt-index', { rules: scope.policy.rows })
                            .then(data => scope.duplicateRule(rule, data));
                    },
                }],
            });
        },
    };
}]);
