/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name wafTopHitsContainer
 * @param {WafPolicy=} wafPolicy
 * @param {Object[]} data - Object containing 'name' and 'count' properties.
 * @param {Function=} onEnable - Function called when the switch button is clicked.
 * @param {string} containerTitle - Title to be shown in the container box.
 * @param {string=} type - Either 'group', 'rule', or undefined.
 * @param {boolean=} showTotal - True to show the total at the bottom of the container.
 */
angular.module('aviApp').component('wafTopHitsContainer', {
    bindings: {
        wafPolicy: '<',
        topHitsList: '<',
        onEnable: '&',
        onSelectFilter: '&',
        filterIsSelected: '&',
        containerTitle: '<',
        isLoading: '<',
        type: '<',
        showTotal: '<',
    },
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/' +
            'waf-top-hits-container/waf-top-hits-container.html',
});
