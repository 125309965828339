/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2017] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
class CustomIpamDnsProfileModalController {
    constructor(CollateUpload) {
        this.Upload = CollateUpload;
        this.fileName = '';
        this.file = null;
        this.filePath = 'controller://ipamdnsscripts';
        this.fileAPI = '/api/fileservice/ipamdnsscripts';

        this.onFileSelect = this.onFileSelect.bind(this);
    }

    $onInit() {
        this.upload = new this.Upload();
        this.fileName = this.getFileName();
    }

    /**
     * Handles file selection.
     * @param {File} file
     */
    onFileSelect(file) {
        this.uploadFile(file);
    }

    /**
     * Get script_uri file name
     * @return {string}
     */
    getFileName() {
        const { script_uri: scriptUri } = this.editable.getConfig();

        return scriptUri ?
            scriptUri.replace(`${this.filePath}/`, '') :
            '';
    }

    /**
     * Uploads selected file.
     * @param {File} file
     */
    uploadFile(file = this.file) {
        const { filePath, fileAPI, editable } = this;

        if (file && file.name) {
            const config = editable.getConfig();

            config.script_uri = `${filePath}/${file.name}`;
            this.upload.send(file, file.name, fileAPI, filePath);
        }
    }
}

CustomIpamDnsProfileModalController.$inject = ['CollateUpload'];

/**
 * @ngdoc component
 * @name customIpamDnsProfileModal
 * @param {CustomIpamDnsProfile} editable
 * @description
 *     Custom IPAM DNS Profile config modal.
 */
angular.module('aviApp').component('customIpamDnsProfileModal', {
    controller: CustomIpamDnsProfileModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/custom-ipam-dns-profile-modal/' +
        'custom-ipam-dns-profile-modal.html',
});
