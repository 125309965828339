/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name checkboxGroupRequired
 * @restrict element
 * @param  {Object} ngModel - Object containing keys for each checkbox.
 * @description Used when a group of checkboxes requires at least 1 selection. Checks object passed
 *     in as ngModel for at least one true property.
 * @example
 *
 *  ngModel is an object with keys for each checkbox:
 *   scope.choices: {
 *       choice1: false,
 *       choice2: false
 *   }
 *
 *   <checkbox-group-required ng-model="data.choices">
 *       <checkbox ng-model="choices.choice1"></checkbox>
 *       <checkbox ng-model="choices.choice2"></checkbox>
 *   </checkbox-group-required>
 */
angular.module('aviApp').directive('checkboxGroupRequired', function() {
    return {
        scope: {
            ngModel: '=',
        },
        restrict: 'E',
        require: 'ngModel',
        link(scope, elm, attr, ngModel) {
            const validate = function() {
                const any = _.any(scope.ngModel, function(value) {
                    return value;
                });

                ngModel.$setValidity('require', any);
            };

            scope.$watchCollection('ngModel', validate);
        },
    };
});
