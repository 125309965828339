/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name ActionConfigItem
 * @description ActionConfigItem, abstraction of actions in policy/profile rules.
 */
const actionConfigItemFactory = ConfigItem => {
    class ActionConfigItem extends ConfigItem {
        /**
         * Name/label of this action to be shown in UI.
         * @return {string}
         */
        getName() {
            return this.name;
        }

        /**
         * Search Method, returns true if the action string contains term.
         * @param {string} searchTerm
         * @return {boolean}
         */
        hasTerm(searchTerm) {
            const content = this.toString();

            return content.contains(searchTerm, true);
        }

        /** @override */
        dataToSave() {
            const
                configCopy = angular.copy(this.getConfig()),
                config = ConfigItem._removeEmptyRepeated(configCopy);

            return config;
        }
    }

    angular.extend(ActionConfigItem.prototype, {
        type: 'AbstractAction',
        name: 'Abstract Action',
        defaultPath: '',
    });

    return ActionConfigItem;
};

actionConfigItemFactory.$inject = [
    'ConfigItem',
];

angular.module('aviApp').factory('ActionConfigItem', actionConfigItemFactory);
