/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @typedef {Object} MetricDataCollection
 * @description  Collection of Metrics.
 */
angular.module('aviApp').factory('MetricDataCollection', [
'Collection', 'MetricData', (Collection, MetricData) => {
    class MetricDataCollection extends Collection {}

    angular.extend(MetricDataCollection.prototype, {
        itemClass_: MetricData,
        objectName_: 'metricdata',
        allDataSources_: {
            list: {
                source: 'MetricDataListCollDataSource',
                transformer: 'MetricDataListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return MetricDataCollection;
}]);
