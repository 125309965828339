/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './nsm-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * @alias module:waf/nsmLog
 * @private
 */
class NsmLogComponent {
    constructor(AviAlertService, AviConfirmService, Auth) {
        this.AviAlertService_ = AviAlertService;
        this.AviConfirmService_ = AviConfirmService;
        this.Auth_ = Auth;

        /**
         * Used to show the Add Rule exception button.
         * @type {boolean}
         */
        this.hoverRule = false;
    }

    /**
     * Handler for the mouse hover on the rule.
     * @param {boolean} [hover=false] - True if the mouse is hovering over the rule. False
     *     otherwise.
     */
    handleRuleHover(hover = false) {
        this.hoverRule = hover;
    }

    /**
     * Determines if the buttons to add exceptions should be shown.
     * @returns {boolean}
     */
    showAddException() {
        return !_.isUndefined(this.wafPolicy) && !this.wafPolicy.busy;
    }

    /**
     * Opens the exceptions dialog to add group exceptions.
     * @param {angular.$event} $event
     */
    addGroupException($event) {
        $event.stopPropagation();

        this.openExceptionsDialog_(this.rules);
    }

    /**
     * Opens the exceptions dialog to add a rule exception.
     * @param {angular.$event} $event
     * @param {WafRule} rule
     * @param {string} ruleId - WAF Rule ID.
     */
    addRuleException($event, rule, ruleId) {
        $event.stopPropagation();

        this.openExceptionsDialog_([rule], ruleId);
    }

    /**
     * Opens the exceptions dialog to add group or rule exceptions.
     * @param {WafRules[]} wafLogRules
     * @param {string} ruleId - WAF Rule ID.
     * @protected
     */
    openExceptionsDialog_(wafLogRules, ruleId) {
        if (this.wafPolicy) {
            const wafGroup = this.wafPolicy.getGroupByGroupName(this.groupName);

            if (!wafGroup) {
                return this.AviAlertService_.throw(`Group ${this.groupName} no longer exists.`);
            }

            let wafRule;

            if (ruleId) {
                wafRule = this.wafPolicy.getRuleByRuleId(ruleId);

                if (!wafRule) {
                    return this.AviAlertService_.throw(`Rule ${ruleId} no longer exists.`);
                }
            }

            this.AviConfirmService_.prompt('waf-exceptions-modal', {
                subnet: `${this.clientIp}/24`,
                wafGroup,
                wafRule,
                wafLogRules,
                uriPath: this.uriPath,
            }).then(({ wafGroup, wafRule }) => {
                if (wafRule) {
                    wafGroup.replaceRule(wafRule);
                }

                this.wafPolicy.saveGroup(wafGroup);
                this.wafPolicy.save();
            });
        }
    }

    /**
     * Returns true if adding exceptions is allowed. False otherwise.
     * @returns {boolean}
     */
    showOpenerActions() {
        return this.Auth_.isAllowed('wafpolicy', 'w');
    }
}

NsmLogComponent.$inject = [
    'AviAlertService',
    'AviConfirmService',
    'Auth',
];

/**
 * @ngdoc component
 * @name  nsmLog
 * @description Component for an NSM Log.
 * @param {string} groupName - Name of the WAF Group.
 * @param {WafRule[]} rules - Rules belonging to the WAF Group.
 * @param {WafPolicy} [wafPolicy=] - Optional because the Virtual Service may no longer have the
 *     WafPolicy.
 * @param {string} clientIp - Client IP of the log.
 * @param {string} uriPath - URI Path of the log.
 * @module waf/nsmLog
 * @author alextsg
 */
angular.module('logs.vantage.avi').component('nsmLog', {
    bindings: {
        groupName: '@',
        rules: '<',
        wafPolicy: '<?',
        clientIp: '@',
        uriPath: '@',
    },
    controller: NsmLogComponent,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'nsm-logs-list/nsm-log/nsm-log.component.html',
});
