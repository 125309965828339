/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name gslbPoolPriorityField
 * @author Alex Malitsky
 * @param {GslbPoolConfig#priority} value
 * @restrict A
 * @description
 *
 *    Simple input wrapper to avoid code duplication.
 *
 */

angular.module('aviApp').directive('gslbPoolPriorityField', ['Schema', 'Regex',
function(Schema, Regex) {
    return {
        templateUrl: 'src/views/partials/application/gslb-pool-priority-field.html',
        restrict: 'A',
        controller: ['$scope', scope => {
            scope.Schema = Schema;
            scope.Regex = Regex;
        }],
        scope: {
            value: '=',
        },
    };
}]);
