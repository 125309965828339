/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateGridStatusColumn
 * @description  Displays the status icon of the SSL Certificate along with a tooltip.
 * @param {Certificate} row - Certificate object.
 */
function Controller(Schema) {
    this.$onInit = function() {
        this.statusTooltip = require('./certificate-list-status-tooltip.partial.html');
    };

    /**
     * Returns the description string for the certificate's expiry status.
     */
    this.getExpiryStatus = function() {
        const config = this.row.getConfig();
        const
            statusEnums = Schema.enums.SSLCertificateStatus.values,
            expiryEnums = Schema.enums.SSLCertificateExpiryStatus.values;

        if (config.status === 'SSL_CERTIFICATE_PENDING') {
            return statusEnums[config.status].options.e_description.value;
        } else if (config.status === 'SSL_CERTIFICATE_FINISHED') {
            return expiryEnums[config.certificate.expiry_status].options.e_description.value;
        }
    };

    /**
     * Returns the class name for the certificate's expiry status.
     */
    this.getExpiryStatusClass = function() {
        const config = this.row.getConfig();
        let output = '';

        const dict = {
            SSL_CERTIFICATE_GOOD: 'status-good',
            SSL_CERTIFICATE_EXPIRY_WARNING: 'status-expiring',
            SSL_CERTIFICATE_EXPIRED: 'status-expired',
            SSL_CERTIFICATE_PENDING: 'status-pending',
        };

        if (config.status === 'SSL_CERTIFICATE_PENDING') {
            output = dict[config.status];
        } else if (config.status === 'SSL_CERTIFICATE_FINISHED') {
            output = dict[config.certificate.expiry_status];
        }

        return output;
    };
}

Controller.$inject = [
    'Schema',
];

angular.module('aviApp').component('sslCertificateGridStatusColumn', {
    bindings: {
        row: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-grid-status-column/ssl-certificate-grid-status-column.html',
});
