/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name  HSMGroupDownloadCertController
 * @description
 *     Controller for prof-hsmgroup-download-cert modal that allows the user to download client
 *     certificates of a Safenet Luna HSM Group.
 */
angular.module('aviApp').controller('HSMGroupDownloadCertController', [
'$scope', 'AviModal', '$document',
function($scope, AviModal, $document) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    this.gridConfig = {
        fields: [{
            name: 'client_ip',
            title: 'IP Address',
        }],
        rowId: 'client_ip',
        layout: {
            hideDisplaying: true,
        },
        singleactions: [{
            title: 'Download as file',
            class: 'icon-download',
            do: row => {
                $document[0].location = `data:application/octet-stream,${
                    encodeURIComponent(row.client_cert)}`;
            },
        }],
    };

    this.closeModal = function() {
        AviModal.destroy('prof-hsmgroup-download-cert');
    };
}]);
