/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const caseFactory = Item => {
    /**
     * @class
     * @name module:items/Case
     * @extends module:avi/dataModel.Item
     * @desc Case item
     */
    class Case extends Item {
        /**
         * @override
         */
        getIdFromData_(data) {
            return data.config && data.config['id'] ? data.config['id'] : '';
        }

        /**
         * Returns case number and subject of the case.
         * @returns {string} - Case number with subject. Empty string when not ready.
         * @override
         */
        getName() {
            const config = this.getConfig();
            let name = '';

            if (config) {
                if (config.case_number) {
                    name = `${config.case_number} | ${config.subject}`;
                } else {
                    name = config.subject;
                }
            }

            return name;
        }
    }

    Object.assign(Case.prototype, {
        objectName: 'projectx/case',
        windowElement: 'case-modal',
        permissionName_: 'PERMISSION_CONTROLLER',
    });

    return Case;
};

caseFactory.$inject = [
    'Item',
];

/**
 * @ngdoc service
 * @alias Case
 * @author Ram Pal
 * @desc Case item
 */
angular.module('avi/cportal')
    .factory('Case', caseFactory);
