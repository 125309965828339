/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('securityPolicy', [
'Schema', 'Regex', 'RangeParser', '$timeout', '$templateCache', 'defaultValues',
'HTTPRedirectAction', 'PolicyGridConfig', 'AviConfirmService',
function(Schema, Regex, RangeParser, $timeout, $templateCache, defaultValues, HTTPRedirectAction,
PolicyGridConfig, AviConfirmService) {
    function link(scope, elm) {
        scope.Schema = Schema;
        scope.Regex = Regex;
        scope.RangeParser = RangeParser;

        scope.rule2EditMode = function(rule) {
            // Convert matches
            rule.matchEdit = [];
            _.each(rule.match, function(match, id) {
                if (match instanceof Array) {
                    _.each(match, function(m) {
                        if (scope.matches[id].init) {
                            scope.matches[id].init(m);
                        }

                        rule.matchEdit.push({
                            id,
                            value: m,
                        });
                    });
                } else {
                    if (scope.matches[id].init) {
                        scope.matches[id].init(match);
                    }

                    rule.matchEdit.push({
                        id,
                        value: match,
                    });
                }
            });

            rule.save = function() {
                scope.saveRule();
            };

            if (rule.action && rule.action.rate_limit && rule.action.rate_limit.action &&
                rule.action.rate_limit.action.redirect) {
                HTTPRedirectAction.beforeEdit(rule.action.rate_limit.action.redirect);
            }
        };

        scope.rule2ReadMode = function(rule) {
            // Convert matches back
            rule.match = {};
            _.map(rule.matchEdit, function(match) {
                if (scope.matches[match.id].onSubmit) {
                    scope.matches[match.id].onSubmit(match.value);
                }

                if (match.id == 'hdrs') {
                    if (!rule.match[match.id] || !(rule.match[match.id] instanceof Array)) {
                        rule.match[match.id] = [];
                    }

                    rule.match[match.id].push(match.value);
                } else {
                    rule.match[match.id] = match.value;
                }
            });
            delete rule.matchEdit;
            delete rule.save;
        };

        /**
         * Creates a new rule.
         * @param {Object=} toPosition - Determines where to place the new rule.
         * @param {string} toPosition.position - 'above' or 'below' a specified index.
         * @param {number} toPosition.index - Existing index used as reference for position.
         */
        scope.addRule = function(toPosition) {
            const { config } = scope.httpSecurityPolicy;

            scope.current = {
                enable: true,
                name: `Rule ${config && config.rules ? config.rules.length + 1 : 1}`,
                action: {
                    status_code: 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200',
                },
                matchEdit: [],
                _toPosition: toPosition,
            };
            scope.rule2EditMode(scope.current);
        };

        /**
         * Duplicates an existing rule and allows for editing.
         * @param {Object} rule - Rule config.
         * @param {Object} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         */
        scope.duplicateRule = function(rule, toPosition) {
            scope.current = angular.extend(angular.copy(rule), {
                name: `${rule.name} - duplicated`,
                _toPosition: toPosition,
            });

            scope.current.index = undefined;
            scope.rule2EditMode(scope.current);
        };

        scope.actionChanged = function() {
            const { action } = scope.current;

            delete action.status_code;
            delete action.https_port;
            delete action.file;
            delete action.rate_limit;

            switch (action.action) {
                case 'HTTP_SECURITY_ACTION_SEND_RESPONSE':
                    action.status_code = 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200';
                    break;

                case 'HTTP_SECURITY_ACTION_REDIRECT_TO_HTTPS':
                    action.https_port = 443;
                    break;

                case 'HTTP_SECURITY_ACTION_RATE_LIMIT':
                    action.rate_limit =
                        defaultValues.getDefaultItemConfig('httpsecurityrule.action.rate_limit');
                    break;
            }
        };

        /**
         * Called when Rate Limiter Action dropdown value has been changed.
         */
        scope.setRateLimiterAction = function() {
            const
                { action } = scope.current.action.rate_limit,
                redirect = defaultValues.getDefaultItemConfig(
                    'httpsecurityrule.action.rate_limit.action.redirect',
                );

            if (action.type === 'RL_ACTION_REDIRECT') {
                action.redirect = angular.copy(redirect);
                action.redirect.protocol = 'HTTP';
                action.redirect.port = 80;
            }
        };

        /**
         * Gets the RateLimiterActionType enums from Schema with the L4 enums filtered out.
         * @return {String[]} Array of RateLimiterActionType enums.
         */
        scope.getRateLimiterActions = function() {
            const actions = Object.keys(Schema.enums.RateLimiterActionType.values);

            return actions.filter(function(type) {
                return type !== 'RL_ACTION_DROP_CONN' && type !== 'RL_ACTION_RESET_CONN';
            });
        };

        scope.editRule = function(rule) {
            scope.current = angular.copy(rule);
            scope.rule2EditMode(scope.current);
        };

        scope.saveRule = function() {
            if (!scope.current) {
                return;
            }

            let { config } = scope.httpSecurityPolicy;

            if (!config) {
                config = {
                    rules: [],
                };

                scope.httpSecurityPolicy.config = config;
            }

            if (!Array.isArray(config.rules)) {
                config.rules = [];
            }

            // Make sure there is no rule with the same name
            scope.error = null;

            if (_.any(config.rules, function(rule) {
                return rule.name == scope.current.name && rule.index != scope.current.index;
            })) {
                scope.error = 'Rule name already in use';

                return;
            }

            const { action } = scope.current;

            if (action.action === 'HTTP_SECURITY_ACTION_SEND_RESPONSE') {
                delete action.https_port;
                delete action.rate_limit;
            } else if (action.action === 'HTTP_SECURITY_ACTION_REDIRECT_TO_HTTPS') {
                delete action.status_code;
                delete action.rate_limit;
            } else if (action.action === 'HTTP_SECURITY_ACTION_RATE_LIMIT') {
                delete action.https_port;
                delete action.status_code;

                if (action.rate_limit.action.type !== 'RL_ACTION_REDIRECT') {
                    delete action.rate_limit.action.redirect;
                }

                if (action.rate_limit.action.type !== 'RL_ACTION_LOCAL_RSP') {
                    delete action.rate_limit.action.status_code;
                    delete action.rate_limit.action.file;

                    if (action.rate_limit.action.type === 'RL_ACTION_REDIRECT') {
                        HTTPRedirectAction.beforeSave(action.rate_limit.action.redirect);
                    }
                }
            }

            scope.rule2ReadMode(scope.current);

            if (!_.isUndefined(scope.current.index)) {
                angular.copy(scope.current, _.find(config.rules, function(item) {
                    return item.index == scope.current.index;
                }));
            } else {
                scope.current.index = _.max(config.rules, function(i) {
                    return i.index;
                }).index + 1 || 1;
                config.rules.push(scope.current);
            }

            if (angular.isObject(scope.current._toPosition)) {
                scope.httpSecurityPolicy.moveRule(scope.current, scope.current._toPosition);
            }

            scope.current = null;
        };

        /**
         * Appends a match to the current rule
         * @param type - Match type
         */
        scope.addMatch = function(type, event) {
            if (!scope.current || !scope.matches[type]) {
                return;
            }

            scope.current.matchEdit.push({
                id: type,
                index: scope.current.matchEdit.length,
                value: angular.copy(scope.matches[type].default),
            });

            // Scroll down
            const curOffsetTop = $('.new-match-list').offset().top;

            $timeout(function() {
                if (!$('.new-match-list').length) {
                    return;
                }

                const scrollable = $(elm).closest('.scrollable');

                scrollable.animate({
                    scrollTop: $(scrollable).scrollTop() +
                        ($('.new-match-list').offset().top - curOffsetTop),
                });
            });
        };

        /**
         * Deletes the match from the current rule
         * @param {Object} matchOrMatchValue - the type of the match
         */
        scope.deleteMatch = function(matchOrMatchValue) {
            const index = _.findIndex(scope.current.matchEdit,
                item => item === matchOrMatchValue || item.value === matchOrMatchValue);

            if (index !== -1) {
                scope.current.matchEdit.splice(index, 1);
            }
        };

        // Used to filter out the rules that exist in detail.rules
        scope.matchNotUsed = function(match) {
            if (match == 'hdrs') {
                return true;
            }

            if (scope.current && scope.current.matchEdit) {
                const found = _.find(scope.current.matchEdit, function(item) {
                    return item.id == match;
                });

                if (found) {
                    return false;
                }
            }

            return true;
        };

        scope.object2Array = function(obj) {
            const arr = [];

            _.each(obj, function(item, key) {
                arr.push({
                    id: key,
                    data: item,
                });
            });

            return arr;
        };

        scope.removeHeaderMatch = function(match, index) {
            match.value.splice(index, 1);

            if (!match.value.length) {
                scope.deleteMatch(match.id);
            }
        };

        scope.matches = {
            client_ip: {
                name: 'Client IP',
                default: {
                    match_criteria: 'IS_IN',
                    _tmp: [{
                        type: 'address',
                        data: '',
                    }],
                    addrs: [],
                    ranges: [],
                },
                stringify(m) {
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, function(item) {
                            val.push(item.addr);
                        });
                    }

                    if (m.ranges) {
                        _.each(m.ranges, function(item) {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, function(item) {
                            val.push(`${item.ip_addr.addr}/${item.mask}`);
                        });
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });
                    }

                    return `${Schema.enums.MatchOperation.values[m.match_criteria]
                        .options.text.value} (${val.join(', ')})`;
                },
            },
            vs_port: {
                name: 'Service Port',
                default: {
                    match_criteria: 'IS_IN',
                    ports: [],
                },
                stringify(m) {
                    return m.ports.join(', ');
                },
            },
            protocol: {
                name: 'Protocol Type',
                default: {
                    match_criteria: 'IS_IN',
                    protocols: 'HTTP',
                },
                stringify(m) {
                    return m.protocols;
                },
            },
            method: {
                name: 'HTTP Method',
                default: {
                    match_criteria: 'IS_IN',
                    methods: [],
                },
                stringify(m) {
                    return `${Schema.enums.MatchOperation.values[m.match_criteria]
                        .options.text.value} (${
                        _.map(m.methods, function(item) {
                            return item.replace(/^HTTP_METHOD_/g, '');
                        }).join(', ')})`;
                },
            },
            version: {
                name: 'HTTP Version',
                default: {
                    match_criteria: 'IS_IN',
                    versions: [], // {version: 'ZERO_NINE'}
                    _tmp: {},
                },
                init(m) {
                    m._tmp = {};
                    _.each(m.versions, function(v) {
                        m._tmp[v] = true;
                    });
                },
                onSubmit(m) {
                    m.versions = [];
                    _.each(m._tmp, function(value, version) {
                        if (value) {
                            m.versions.push(
                                version,
                            );
                        }
                    });
                },
                stringify(m) {
                    return _.map(m.versions, function(item) {
                        return Schema.enums.HTTPVersion.values[item].options.text.value;
                    }).join(', ');
                },
            },
            path: {
                name: 'Path',
                default: {
                    match_criteria: 'CONTAINS',
                    match_str: [],
                    _tmp: [{
                        type: 'custom',
                        data: '',
                    }],
                },
                stringify(m) {
                    const val = [];

                    Array.prototype.push.apply(val, m.match_str);

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `${Schema.enums.StringOperation.values[m.match_criteria]
                        .options.text.value} (${val.join(', ')})`;
                },
            },
            query: {
                name: 'Query',
                default: {
                    match_criteria: 'QUERY_MATCH_CONTAINS',
                    match_str: [],
                    _tmp: [{
                        type: 'custom',
                        data: '',
                    }],
                },
                stringify(m) {
                    const val = [];

                    _.each(m.match_str, function(str) {
                        val.push(`"${str}"`);
                    });

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `contains ${val.join(' or ')}`;
                },
            },
            hdrs: {
                name: 'Headers',
                default: {
                    match_criteria: 'HDR_EXISTS',
                    hdr: '',
                    value: [],
                },
                stringify(hdrs) {
                    return _.map(hdrs, function(hdr) {
                        const schema = Schema.enums.HdrMatchOperation.values[hdr.match_criteria];

                        return `${hdr.hdr} ${schema ?
                            schema.options.text.value : hdr.match_criteria
                        }${hdr.match_criteria == 'HDR_CONTAINS' ?
                            ` ${hdr.value.join(' or ')}` : ''}`;
                    }).join(', ');
                },
            },
            cookie: {
                name: 'Cookie',
                default: {
                    match_criteria: 'HDR_EXISTS',
                    name: '',
                    value: '',
                },
                stringify(m) {
                    const schema = Schema.enums.HdrMatchOperation.values[m.match_criteria];

                    return ` ${m.name} ${(schema ?
                        schema.options.text.value : schema).toLowerCase()} ${m.value}`;
                },
            },
            host_hdr: {
                name: 'Host Header',
                default: {
                    match_criteria: 'HDR_EQUALS',
                    value: [''],
                },
                init(m) {
                    if (m.match_criteria != 'HDR_EXISTS' &&
                        m.match_criteria != 'HDR_DOES_NOT_EXIST' &&
                        (!m.value || !m.value.length)) {
                        m.value = [''];
                    }
                },
                onSubmit(m) {
                    if (m.match_criteria == 'HDR_EXISTS' ||
                        m.match_criteria == 'HDR_DOES_NOT_EXIST') {
                        delete m.value;
                    }
                },
                stringify(m) {
                    return `${Schema.enums.HdrMatchOperation.values[m.match_criteria]
                        .options.text.value
                    } '${m.value.join('\' or \'')}'`;
                },
            },
        };

        scope.onLogTypeChange = function(switchedTo) {
            const currentRule = scope.current;

            switch (switchedTo) {
                case 'with-headers':
                    currentRule.log = true;
                    currentRule.all_headers = true;
                    break;

                case 'on':
                    currentRule.log = true;
                    currentRule.all_headers = false;
                    break;

                default:
                    currentRule.log = false;
                    currentRule.all_headers = false;
            }
        };

        scope.matchKeys = Object.keys(scope.matches);

        scope.httpSecurityPolicyGridConfig = new PolicyGridConfig({
            collection: scope.httpSecurityPolicy,
            controls: {
                create: {
                    title: 'Create rule',
                    do: () => {
                        const { rows } = scope.httpSecurityPolicy;
                        const index = angular.isArray(rows) ?
                            rows.length && rows[rows.length - 1].index || 0 :
                            0;

                        scope.addRule({ index, position: 'below' });
                    },
                },
            },
            actions: {
                createAt: ({ index }, position) => scope.addRule({ index, position }),
            },
            singleactions: [{
                title: 'Delete',
                class: 'icon icon-trash',
                do: rule => scope.httpSecurityPolicy.delete(rule),
            }, {
                title: 'Menu',
                template: require(
                    '../../components/applications/virtualservice/policy/' +
                    'policy-grid/policy-grid-menu.tooltip.partial.html',
                ),
                edit: rule => scope.editRule(rule),
                move: rule => {
                    const rules = scope.httpSecurityPolicy.rows;

                    AviConfirmService
                        .prompt('policy-grid-prompt-index', { rule, rules })
                        .then(data => scope.httpSecurityPolicy.moveRule(rule, data));
                },
                duplicate: rule => {
                    AviConfirmService
                        .prompt(
                            'policy-grid-prompt-index', { rules: scope.httpSecurityPolicy.rows },
                        )
                        .then(data => scope.duplicateRule(rule, data));
                },
            }],
        });
    }

    return {
        scope: {
            httpSecurityPolicy: '=',
            current: '=',
            services: '=',
            readonly: '@',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/security-policy.html',
        link,
    };
}]);
