/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc Factory
 * @name  loadCloud
 * @description
 *
 *     Rather weird but efficient way of loading cloud by id or default one if id was not passed.
 */
const name = 'Default-Cloud';

function loadCloud(Collection, Cloud) {
    return cloudId => {
        const params = {};

        if (cloudId) {
            //FIXME we use collection load instead cloud item load cause of silly
            // cloud.loadConfig
            params.uuid = cloudId;
        } else {
            params.name = name;
        }

        const cloudCollection = new Collection({
            objectName: 'cloud',
            params,
        });

        return cloudCollection.load()
            .then(() => {
                const cloudConfig = cloudCollection.items[0].getConfig();

                return new Cloud({ data: { config: cloudConfig } });
            }).finally(
                () => cloudCollection.destroy(),
            );
    };
}

loadCloud.$inject = [
        'Collection',
        'Cloud',
];

angular.module('router.vantage.avi').factory('loadCloud', loadCloud);
