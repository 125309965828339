/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @fileoverview It contain constants related to cases. We have severity and type
 * which we currently support in cases creation.
 */

const SEVERITY_1 = 'Severity 1';
const SEVERITY_2 = 'Severity 2';
const SEVERITY_3 = 'Severity 3';
const SEVERITY_4 = 'Severity 4';
const SEVERITY_5 = 'Severity 5';

const BUG = 'Bug';
const CONFIGURATION_HELP = 'Configuration Help';
const FEATURE_REQUEST = 'Feature Request';
const ISSUE = 'Issue';
const QUESTION = 'Question';

const APIC = 'APIC';
const AWS = 'AWS';
const AZURE = 'Azure';
const CSP = 'CSP';
const GCP = 'GCP';
const KUBERNETES = 'Kubernetes';
const LINUX_SERVER_CLOUD = 'Linux Server Cloud';
const OPENSTACK = 'OpenStack';
const OPENSHIFT = 'Openshift';
const VMWARE = 'VMware';

export const typeHash = {
    BUG,
    CONFIGURATION_HELP,
    FEATURE_REQUEST,
    ISSUE,
    QUESTION,
};

export const severityHash = {
    SEVERITY_1,
    SEVERITY_2,
    SEVERITY_3,
    SEVERITY_4,
    SEVERITY_5,
};

export const environmentHash = {
    APIC,
    AWS,
    AZURE,
    CSP,
    GCP,
    KUBERNETES,
    LINUX_SERVER_CLOUD,
    OPENSTACK,
    OPENSHIFT,
    VMWARE,
};
