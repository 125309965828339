/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './pool-group-list-expander.less';

/**
 * @ngdoc component
 * @name  poolGroupListExpander
 * @description
 *     Displays a grid of pools belonging to the Pool Group sorted by priority_label and ratio.
 * @param {PoolGroup} row - PoolGroup item.
 */
class Controller {
    constructor(PoolGroup, PoolCollection) {
        this.PoolGroup_ = PoolGroup;
        this.PoolCollection_ = PoolCollection;
    }

    $onInit() {
        /**
         * A mapping of priority_label values to numbers used for rowClasses.
         * @type {Object}
         * @example
         *     { '10': 2, '5': 0, '2': 1, '1': 0 }
         */
        this.priorityLabelHash = {};

        this.gridConfig = {
            rowId: 'pool_ref',
            fields: [{
                name: 'name',
                title: 'Name',
                template:
                    `<span
                        ng-if="!row.pool.getVSId()"
                        avi-form-label="'Pool is not used by any VS'"
                    >
                        {{ row.pool_ref.name() }}
                    </span>
                    <a
                        ng-if="row.pool.getVSId()"
                        ui-sref="^.pool-detail.analytics({
                        vsId: row.pool.getVSId(),
                        poolId: row.pool_ref.slug()
                    })">
                        {{ row.pool_ref.name() }}
                    </a>`,
            }, {
                name: 'status',
                title: 'Status',
                template:
                    `<avi-healthscore
                        class="pool-group-status"
                        item="row.pool"
                        stop-async-on-hide="true" />`,
            }, {
                name: 'ratio',
                title: 'Ratio',
                template: '{{ row.ratio }}',
                sortBy: 'ratio',
            }, {
                name: 'deployment_state',
                title: 'Deployment State',
                template: '{{ row.deployment_state.enumeration() }}',
            }, {
                name: 'priority_label',
                title: 'Priority',
                template: '{{ row.priority_label || \'N/A\' }}',
                sortBy: this.PoolGroup_.gridSortByPriority,
            }],
            rowClass: row => {
                if (this.priorityLabelHash[row.priority_label] === 0) {
                    return 'background-blue';
                } else {
                    return `background-gray-${this.priorityLabelHash[row.priority_label]}`;
                }
            },
        };

        const membersCopy = angular.copy(this.row.data.config.members);

        this.gridRows = this.PoolGroup_.sortMembersByPriorityLabel(membersCopy);
        this.setPriorityLabelHash(this.gridRows);

        this.poolCollection_ = new this.PoolCollection_({
            params: {
                referred_by: `poolgroup:${this.row.data.config.uuid}`,
                join: undefined,
            },
            dataFields: ['config', 'runtime', 'health'],
            bind: {
                collectionLoadSuccess: () => {
                    this.gridRows.forEach(row => {
                        row.pool = this.poolCollection_.itemById[row.pool_ref.slug()];
                    });
                },
            },
        });

        this.poolCollection_.load();
    }

    /**
     * Function that populates the priorityLabelHash for adding a rowClass which sets the
     * background color of the rows according to the priority_label.
     * @param  {Object[]} members - Array of sorted PoolGroup members.
     */
    setPriorityLabelHash(members) {
        let currentPriority = 0;

        members.forEach(member => {
            if (!(member['priority_label'] in this.priorityLabelHash)) {
                this.priorityLabelHash[member.priority_label] = currentPriority;
                currentPriority++;
            }
        });
    }

    $onDestroy() {
        this.poolCollection_.destroy();
    }
}

Controller.$inject = [
    'PoolGroup',
    'PoolCollection',
];

angular.module('aviApp').component('poolGroupListExpander', {
    bindings: {
        row: '<',
    },
    controller: Controller,
    template:
        `<grid
            config="$ctrl.gridConfig"
            rows="$ctrl.gridRows">
        </grid>`,
});
