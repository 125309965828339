/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name SensitiveFieldRuleLogActionConfig
 * @description SensitiveFieldRuleLogActionConfig, maps to LogAction(Enum type) in protobof.
 */
const sensitiveFieldRuleLogActionConfigFactory = (ActionConfigItem, Schema) => {
    class SensitiveFieldRuleLogActionConfig extends ActionConfigItem {
        constructor(args = {}) {
            super(args);

            this.enumValues_ = ['LOG_FIELD_REMOVE', 'LOG_FIELD_MASKOFF'];
            this.data.config = { value: args.data && args.data.config || 'LOG_FIELD_REMOVE' };
        }

        /**
         * Getter of the value of the Enum.
         * @return {string}
         */
        get value() {
            return this.getConfig().value;
        }

        /**
         * Setter of the value of the Enum.
         * @param {string} actionValue
         * To wrap an Enum type, a setter is used here for Enum type validation concern.
         */
        set value(actionValue) {
            this.setAction_(actionValue);
        }

        /**
         * Validator for action value setting
         * @param {string} [actionValue='LOG_FIELD_REMOVE']
         */
        setAction_(actionValue = 'LOG_FIELD_REMOVE') {
            if (this.enumValues_.includes(actionValue)) {
                this.getConfig().value = actionValue;
            }
        }

        /** @override */
        dataToSave() {
            const config = super.dataToSave();

            if (!config) {
                return config;
            }

            return config.value;
        }

        /** @override */
        toString() {
            const logAction = Schema.enums.LogAction.values;
            const logActionSchemaValue = logAction[this.value];

            return logActionSchemaValue.options.e_description.value;
        }
    }

    angular.extend(SensitiveFieldRuleLogActionConfig.prototype, {
        type: 'LogAction',
        name: 'Log Action',
    });

    return SensitiveFieldRuleLogActionConfig;
};

sensitiveFieldRuleLogActionConfigFactory.$inject = [
        'ActionConfigItem',
        'Schema',
];

angular.module('aviApp').factory(
    'SensitiveFieldRuleLogActionConfig', sensitiveFieldRuleLogActionConfigFactory,
);
