/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('StringGroupController', [
'$scope', 'CRUDGridConfig', 'StringGroupCollection',
function($scope, CRUDGridConfig, StringGroupCollection) {
    const gridConfig = {
        collection: new StringGroupCollection(),
    };

    gridConfig.fields = [
        {
            name: 'name',
            title: 'Name',
            template: '<span class="sel-string-grp-name">{{row.getName()}}</span>',
            sortBy: 'name',
        }, {
            name: 'type',
            title: 'Type',
            template: '{{row.getConfig().type !== "SG_TYPE_STRING" ? "map" : "string"}}',
        }, {
            name: 'kv_length',
            title: 'Number of Strings',
            template: '<span class="sel-string-grp-num">{{row.getConfig().kv.length}}</span>',
        },
    ];

    gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);
