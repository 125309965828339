/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name getGridMetricFieldConfig
 * @author Alex Malitsky
 * @description
 *
 *     Generates {@link grid} or {@link collectionGrid} field configuration object for metric chart
 *     columns. Takes a full series name to be shown. Can use a passed title or
 *     {@link NamesHelper#getTitle} as a fallback option.
 *
 */
angular.module('aviApp').service('getGridMetricFieldConfig', ['NamesHelper',
function(NamesHelper) {
    /**
     * @param {Series#name|Object} gridFieldConfig - When string passed we take it as a metric
     * id, for objects `require` property (also metric_id) is required.
     * @returns {GridFieldConfig|null} - null returned when wrong argument is passed.
     */
    return gridFieldConfig => {
        const defConfig = {
            metricChart: true,
            disabled(item) {
                return !item.dataFieldIsApplicable(this.require);
            },
        };

        let metricName;

        if (angular.isString(gridFieldConfig)) {
            metricName = gridFieldConfig;
        } else if (angular.isObject(gridFieldConfig) && 'require' in gridFieldConfig) {
            metricName = gridFieldConfig.require;
        } else {
            return null;
        }

        //we want to populate some default values based on the metricName in both param cases
        const config = {
            require: metricName,
            name: metricName,
            title: NamesHelper.getTitle(metricName),
        };

        if (angular.isObject(gridFieldConfig)) {
            angular.extend(config, gridFieldConfig);
        }

        return angular.extend(defConfig, config);
    };
}]);
