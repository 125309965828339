/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class UserCredentialsPageController {
    constructor(CloudConnectorUserCollection, CRUDGridConfig, AviModal) {
        this._CloudConnectorUserCollection = CloudConnectorUserCollection;
        this._CRUDGridConfig = CRUDGridConfig;
        this._AviModal = AviModal;
    }

    $onInit() {
        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._CloudConnectorUserCollection();

        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }];

        this.gridConfig.singleactions.push({
            title: 'Verify Host',
            class: 'icon-lostlog',
            hidden: row => row.hasGCPCredentials(),
            do: row => {
                this._AviModal.open('verify-cloud-connector-user-host', {
                    userUuid: row.id,
                    task: 'verify',
                });
            },
        }, {
            title: 'Download Public Key',
            class: 'icon-download-1',
            hidden: row => !row.getConfig().public_key,
            do(row) {
                const publicKey = encodeURIComponent(row.data.config.public_key);

                document.location = `data:Application/octet-stream,${publicKey}`;
            },
        });
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

UserCredentialsPageController.$inject = [
        'CloudConnectorUserCollection',
        'CRUDGridConfig',
        'AviModal',
];

angular.module('aviApp').component('userCredentialsPage', {
    controller: UserCredentialsPageController,
    template:
            `<div class="avi-main">
                <div class="panel templatesList">
                    <collection-grid config="$ctrl.gridConfig"></collection-grid>
                </div>
            </div>`,
});
