/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  VirtualServiceSecurityService
 * @description Service for the Virtual Service Security page.
 */
class Service {
    constructor(Base) {
        this.base_ = new Base();
    }

    /**
     * Retrieves the SSL patches data.
     * @return {ng.$q.promise}
     */
    getSSLPatches() {
        return this.base_.request('GET', '/api/changelog', null, null, 'changelog')
            .then(rsp => {
                return _.reduce(rsp.data.openssl, (acc, value, key) => {
                    acc.push({
                        date: key,
                        info: value,
                    });

                    return acc;
                }, []).sort(({ date: a }, { date: b }) => moment(b) - moment(a));
            });
    }

    /**
     * Cancels HTTP request for SSL patches data.
     */
    cancelGetSSLPatches() {
        this.base_.cancelRequests('changelog');
    }
}

Service.$inject = [
    'Base',
];

angular.module('aviApp').service('VirtualServiceSecurityService', Service);
