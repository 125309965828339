/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  DnsInfoConfig
 * @description  DnsInfoConfig ConfigItem class.
 */
const dnsInfoConfigFactory = (ConfigItem, DNS) => {
    return class DnsInfoConfig extends ConfigItem {
        constructor(args) {
            const extendedArgs = angular.extend({ type: 'DnsInfo' }, args);

            super(extendedArgs);
        }

        /**
         * Sets the fqdn field of the DnsInfoConfig object.
         * @param {string} fqdn
         */
        setFqdn(fqdn) {
            const config = this.getConfig();

            config.fqdn = fqdn;
        }

        /**
         * Returns DnsInfoConfig#fqdn.
         * @return {string}
         */
        getFqdn() {
            const { fqdn } = this.getConfig();

            return fqdn;
        }

        /**
         * Looks up a domain to get the list of IP addresses it resolves to.
         * @return {ng.$q.promise}
         */
        domainLookup(domain) {
            this.busy = true;
            this.errors = null;

            return DNS.singleLookup(domain)
                .catch(({ data }) => {
                    this.errors = data;

                    return [];
                })
                .finally(() => this.busy = false);
        }

        /**
         * @override
         * If the fqdn string is not present, the entire DnsInfoConfig object should not be part
         * of the save request payload, so return undefined.
         * @return {Object|undefined}
         */
        dataToSave() {
            let config = angular.copy(this.getConfig());

            if (angular.isUndefined(config.fqdn)) {
                config = undefined;
            }

            return config;
        }
    };
};

dnsInfoConfigFactory.$inject = [
    'ConfigItem',
    'DNS',
];

angular.module('aviApp').factory('DnsInfoConfig', dnsInfoConfigFactory);
