/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name SystemConfig
 * @author Alex Malitsky
 * @description
 *
 *     Item Class for the systemconfiguration object. Has verbose transformations on load/before
 *     save and few convenience/legacy save methods. We have only one systemconfiguration which is
 *     provided by {@link systemConfigService}, hence this factory is rarely used directly.
 */
angular.module('aviApp').factory('SystemConfig', [
'Item', 'AviAlertService', '$q',
function(Item, AviAlertService, $q) {
    return class SystemConfig extends Item {
        constructor(args = {}) {
            args.id = 'default';//only one in a system
            args.objectName = 'systemconfiguration';
            super(args);
        }

        /** @override */
        getIdFromData_(data) {
            return data['uuid'];
        }

        /**
         * @returns {boolean}
         * @public
         */
        isReady() {
            return !!this.getConfig();
        }

        /** @override */
        loadConfig() {
            const params = this.getLoadParams();

            this.cancelRequests('config');

            return this.request('get',
                `/api/${this.objectName}/?${params.join('&')}`,
                undefined, null, 'config')
                .then(rsp => {
                    this.data.config = rsp.data;

                    return rsp;
                });
        }

        /** @override */
        urlToSave() {
            return `/api/${this.objectName}/?include_name`;
        }

        /** @public */
        emptyData() {
            this.data.config = null;
        }

        /**
         * Convenience method over Item.patch.
         * @param {Object} tenantConfig - TenantConfiguration protobuf.
         * @returns {angular.$q.promise}
         * @public
         */
        updateTenantSettings(tenantConfig) {
            return this.patch({ replace: {
                global_tenant_config: tenantConfig,
            } });
        }

        /**
         * Convenience method over Item.patch.
         * @returns {angular.$q.promise}
         * @public
         */
        setWelcomeWorkflowComplete() {
            return this.patch({ replace: {
                welcome_workflow_complete: true,
            } });
        }

        /**
         * Immediately after save checks whether portal port has been changed and throws an
         * alert in this case.
         * @public
         */
        checkPortalPortChange() {
            let prevPortalConfig,
                portalConfig;

            if (this.backup && this.isReady() &&
                (prevPortalConfig = this.backup['portal_configuration']) &&
                (portalConfig = this.data.config['portal_configuration'])) {
                const
                    { https_port: httpsPort, http_port: httpPort } = portalConfig,
                    { https_port: prevHTTPSPort, http_port: prevHTTPPort } = prevPortalConfig;

                //ignores different types (undefined, null, 0) of false values, also '80' == 80
                if (httpsPort != prevHTTPSPort && (httpsPort || prevHTTPSPort) ||
                    httpPort != prevHTTPPort && (httpPort || prevHTTPPort)) {
                    AviAlertService.throw(
                        'HTTP or HTTPS port changed. Please re-login at the new port number',
                    );
                }
            }
        }

        /** @override */
        dataToSave() {
            /**
             * If IpAddrMatches have been added, set the match_criteria to 'IS_IN'.
             * @param {Object} ipAddrMatch
             * @inner
             */
            function updateMatchCriteria(ipAddrMatch) {
                if (ipAddrMatch &&
                    (ipAddrMatch.addrs && ipAddrMatch.addrs.length > 0 ||
                    ipAddrMatch.ranges && ipAddrMatch.ranges.length > 0 ||
                    ipAddrMatch.prefixes && ipAddrMatch.prefixes.length > 0 ||
                    ipAddrMatch.group_refs && ipAddrMatch.group_refs.length > 0)) {
                    ipAddrMatch.match_criteria = 'IS_IN';

                    return ipAddrMatch;
                } else {
                    return undefined;
                }
            }

            const
                config = angular.copy(this.getConfig()),
                {
                    portal_configuration: portalConfig,
                    ntp_configuration: ntpConfig,
                    email_configuration: emailConfig,
                    mgmt_ip_access_control: mgmtIPAccessControl,
                } = config,
                { ntp_server_list: ntpServerList } = ntpConfig;

            //flat array of domain names into convoluted form
            ntpConfig['ntp_server_list'] = ntpServerList.map(
                domainName => ({
                    addr: domainName,
                    type: 'DNS',
                }),
            );

            if (portalConfig) {
                let prevPortalConfig;

                // If http_port or https_port had been configured, but user is now trying to remove
                // them to reset back to default, we need to set them to 80 and 443 respectively
                // so that they are not simply undefined. We use _isUndefined for oldHttpPort
                // since it can be undefined, while newHttpPort can be undefined or null (null
                // when user deletes an existing value).
                if (this.backup && (prevPortalConfig = this.backup['portal_configuration'])) {
                    ['http_port', 'https_port'].forEach(fieldName => {
                        if (!portalConfig[fieldName] && prevPortalConfig[fieldName]) {
                            portalConfig[fieldName] = fieldName === 'https_port' ? 443 : 80;
                        }
                    });
                }

                if (!portalConfig['enable_https']) {
                    portalConfig['redirect_to_https'] = false;
                }
            }

            if (mgmtIPAccessControl) {
                ['ssh_access', 'api_access', 'snmp_access', 'shell_server_access']
                    .forEach(fieldName => {
                        if (fieldName in mgmtIPAccessControl) {
                            mgmtIPAccessControl[fieldName] =
                                updateMatchCriteria(mgmtIPAccessControl[fieldName]);
                        }
                    });
            }

            //drop all properties not applicable for selected SMTP type
            if (emailConfig) {
                const fieldsPerType = {
                    SMTP_NONE: [],
                    SMTP_LOCAL_HOST: [
                        'from_email',
                    ],
                    SMTP_SERVER: [
                        'auth_username',
                        'auth_password',
                        'mail_server_name',
                        'mail_server_port',
                        'from_email',
                        'disable_tls',
                    ],
                    SMTP_ANONYMOUS_SERVER: [
                        'from_email',
                        'mail_server_name',
                        'mail_server_port',
                    ],
                };

                if (!emailConfig['smtp_type']) {
                    emailConfig['smtp_type'] = 'SMTP_NONE';
                }

                const { smtp_type: smtpType } = emailConfig;

                config['email_configuration'] = _.pick(emailConfig,
                    'smtp_type', fieldsPerType[smtpType]);
            }

            if ('dns_virtualservice_refs' in config) {
                config['dns_virtualservice_refs'] = _.compact(config['dns_virtualservice_refs']);

                if (!config['dns_virtualservice_refs'].length) {
                    config['dns_virtualservice_refs'] = undefined;
                }
            }

            const { snmp_configuration: snmp } = config;

            if (snmp && snmp.version) {
                switch (snmp.version) {
                    case 'SNMP_VER2':
                        delete snmp.snmp_v3_config;
                        break;
                    case 'SNMP_VER3':
                        delete snmp.community;
                        break;
                }
            } else {
                delete config.snmp_configuration;
            }

            return config;
        }

        /**
         * Dedicated method to execute same data transformations on load and on save as well.
         * @param {Item.data.config} config
         * @returns {Item.data.config}
         * @protected
         */
        transformAfterLoad_(config) {
            let { ntp_configuration: ntpConfig } = config;
            const { admin_auth_configuration: adminAuthConfig = {} } = config;
            const { mapping_rules: mappingRules } = adminAuthConfig;

            if (!ntpConfig) {
                config['ntp_configuration'] = {};
                ntpConfig = config['ntp_configuration'];
            }

            [
                'ntp_servers',
                'ntp_server_list',
                'ntp_authentication_keys',
            ].forEach(fieldName => {
                if (!(fieldName in ntpConfig)) {
                    ntpConfig[fieldName] = [];
                }
            });

            //flatten the domain names array
            //doesn't seem to be used anywhere in UI
            ntpConfig['ntp_server_list'] = _.pluck(ntpConfig['ntp_server_list'], 'addr');

            if (mappingRules) {
                mappingRules.forEach(mappingRule => {
                    const { group_match: groupMatch } = mappingRule;

                    if (groupMatch) {
                        const { criteria } = groupMatch;

                        if (!criteria) {
                            groupMatch.criteria = 'AUTH_MATCH_DOES_NOT_CONTAIN';
                        }
                    }
                });
            }

            if (!('dns_virtualservice_refs' in config)) {
                config['dns_virtualservice_refs'] = [undefined];
            }

            return config;
        }

        /** @override */
        transformAfterLoad() {
            this.transformAfterLoad_(this.getConfig());
        }

        /** @override */
        transformDataAfterSave({ data }) {
            this.data.config = this.transformAfterLoad_(data);

            return this.getConfig();
        }

        /**
         * Previously we operated a copy of SystemConfiguration config wo Item and when done
         * saved it directly with the custom API PUT request.
         * @param {SystemConfig.data.config} config
         * @public
         */
        legacySave(config) {
            const prevConfig = this.data.config;

            this.data.config = config;

            return this.save()
                .catch(rsp => {
                    this.data.config = prevConfig;

                    return $q.reject(rsp);
                });
        }

        /**
         * Returns NTP configuration.
         * @returns {Object|null}
         * @public
         */
        getNTPConfig() {
            const config = this.getConfig();

            return config && config['ntp_configuration'] || null;
        }

        /**
         * Returns DNS configuration.
         * @returns {Object|null}
         */
        getDNSConfig() {
            const config = this.getConfig();

            return config && config['dns_configuration'] || null;
        }
    };
}]);
