/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './user-modal.component.less';

class UserModalController {
    constructor(
        UserProfileCollection,
        Auth,
    ) {
        /**
         * @type {UserProfileCollection}
         */
        this.userProfileCollection = new UserProfileCollection();

        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.auth_ = Auth;

        /**
         * To invalidate Modal form when password and confirm password dont match.
         * @type {boolean}
         */
        this.invalidPassword = false;
    }

    /**
     * Returns true if Loggedin user is a super user.
     * @returns {boolean}
     */
    isSuperUser() {
        return this.auth_.isSuperUser();
    }

    /**
     * Fires on password change.
     * @param {boolean} invalidPassword
     */
    onPasswordChange(invalidPassword) {
        this.invalidPassword = invalidPassword;
    }

    /** @override */
    $onDestroy() {
        this.userProfileCollection.destroy();
    }
}

UserModalController.$inject = [
    'UserProfileCollection',
    'Auth',
];
/**
 * @ngdoc component
 * @name userModal
 * @param {User} editable
 * @author Aravindh Nagarajan
 * @desc
 *      Modal component for creating/editing a User.
 */
angular.module('aviApp').component('userModal', {
    bindings: {
        editable: '<',
    },
    controller: UserModalController,
    templateUrl:
        'src/components/modals/administration/accounts/user/user-modal.component.html',
});
