/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name stringMatch
 * @description
 *     Component for displaying the string match
 * @param {StringMatchConfig} match - StringMatch ConfigItem object.
 * @param {Function=} onDelete - Method to delete the match.
 * @param {boolean=} allowDelete - Showing remove button or not.
 * @param {Object[]=} matchPatterns - Enum object list of custom string operations.
 */
class StringMatchController {
    constructor(schemaService) {
        this.matchPatterns = this.matchPatterns ||
                schemaService.getEnumValues('StringOperation');
    }
}

StringMatchController.$inject = [
        'schemaService',
];

angular.module('aviApp').component('stringMatch', {
    bindings: {
        match: '<',
        allowDelete: '<?',
        onDelete: '&?',
        matchPatterns: '<?',
    },
    controller: StringMatchController,
    templateUrl: '/src/components/templates/profiles/analytics/string-match/string-match.html',
});
