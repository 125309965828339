/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @fileoverview File containing global variables, imported in ./index.js.
 */

/**
 * Used to prevent crashes with the angular-vs-repeat external package. angular-vs-repeat uses a
 * $watch that calls window.requestAnimationFrame, which is wrapped by zone.js for Angular to
 * update the view. In hybrid mode, an update in Angular causes an update in AngularJS, which in
 * this case causes an infinite loop of $digest cycles.
 */
window.__Zone_disable_requestAnimationFrame = true;
