/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name  UserProfileController
 * @description Controller for User Profile List page.
 */
class UserProfileController {
    constructor(CRUDGridConfig, UserProfileCollection) {
        this.collection = new UserProfileCollection();
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        const config = {
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
            }],
        };

        this.gridConfig = this.CRUDGridConfig_(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

UserProfileController.$inject = [
        'CRUDGridConfig',
        'UserProfileCollection',
];

angular.module('aviApp').controller('UserProfileController', UserProfileController);
