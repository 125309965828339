/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const ipAddrPrefixConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/IpAddrPrefixConfigItem
     * @private
     */
    class IpAddrPrefixConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'IpAddrPrefix',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         */
        canFlatten_() {
            return this.isValid();
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                ip_addr: null,
            };
        }

        /**
         * Returns true if the fields are populated.
         * @returns {boolean}
         */
        isValid() {
            return Boolean(this.address) && !_.isUndefined(this.mask);
        }

        /**
         * Returns a string representation of the subnet address and mask. If the mask property
         * exists in this.data.config, we output a slash.
         * @returns {string|undefined}
         */
        get subnet() {
            const { address, mask } = this;
            const suffix = mask ? `/${mask}` : '';

            return address && (address + suffix);
        }

        /**
         * Sets the address and mask properties in the config. If the subnetString contains a slash,
         * we populate mask with either an empty string or the mask number. Otherwise, we delete the
         * mask property. The presence of the mask property helps us determine whehter or not we
         * should show a slash in the output string.
         * @param {string} subnetString - String containing the subnet IP address and mask.
         */
        set subnet(subnetString) {
            let address;
            let mask = NaN;

            if (typeof subnetString === 'string') {
                const subnetParts = subnetString.split('/');

                if (subnetParts.length === 2) {
                    mask = subnetParts[1] === '' ? '' : +subnetParts[1];
                    [address] = subnetParts;
                }
            }

            this.address = address;
            this.mask = mask;
        }

        /**
         * Sets the address property in the IpAddrConfigItem config.
         * @param {string} address - IP address.
         */
        set address(address) {
            const { ip_addr: ipAddrConfigItem } = this.config;

            ipAddrConfigItem.address = address;
        }

        /**
         * Returns the address of the IpAddrConfigItem config.
         * @returns {string}
         */
        get address() {
            const { ip_addr: ipAddrConfigItem } = this.config;

            return ipAddrConfigItem.address;
        }

        /**
         * Sets the mask property in the config.
         * @param {number} mask - Subnet mask.
         */
        set mask(mask) {
            this.config.mask = mask;
        }

        /**
         * Returns the mask property from the config.
         * @returns {number}
         */
        get mask() {
            return this.config.mask;
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            const { ip_addr: ipAddr } = this.config;

            return `${ipAddr}/${this.mask}`;
        }
    }

    return IpAddrPrefixConfigItem;
};

ipAddrPrefixConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  IpAddrPrefixConfigItem
 * @description  IpAddrPrefix ConfigItem class.
 * @module config-item/IpAddrPrefixConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('IpAddrPrefixConfigItem', ipAddrPrefixConfigItemFactory);
