/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name PoolGroupDeploymentPolicy
 * @description
 *     Pool Group Deployment Policy collection item.
 */
angular.module('aviApp').factory('PoolGroupDeploymentPolicy', ['Item', function(Item) {
    class PoolGroupDeploymentPolicy extends Item {
        /**
         * Returns the list of rules in pool group deployment policy.
         * @returns {Object[]}
         * @public
         */
        getRules() {
            return this.getConfig().rules;
        }

        /**
         * Adds a rule to pool group deployment policy with default configs.
         * @public
         */
        addRule() {
            const rules = this.getRules();

            rules.push(angular.copy(PoolGroupDeploymentPolicy.defaultRuleConfig));
        }

        /**
         * Removes a rule from pool group policy deployment by index.
         * @param {number} index
         * @public
         */
        removeRule(index) {
            const rules = this.getRules();

            rules.splice(index, 1);
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!('rules' in config)) {
                config['rules'] = [];
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.data.config);

            if (!config.rules.length) {
                config['rules'] = undefined;
            }

            return config;
        }
    }

    PoolGroupDeploymentPolicy.defaultRuleConfig = {
        metric_id: '',
        operator: 'CO_LE',
        threshold: '',
    };

    angular.extend(PoolGroupDeploymentPolicy.prototype, {
        objectName: 'poolgroupdeploymentpolicy',
        windowElement_: 'pool-group-deployment-policy-modal',
    });

    return PoolGroupDeploymentPolicy;
}]);
