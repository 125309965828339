/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/profile/pkiprofile-create.less';

angular.module('aviApp').controller('PKIProfileCreateController', [
'$scope', 'Regex', 'AviModal',
function($scope, Regex, AviModal) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.Regex = Regex;

    $scope.caCertsGrid = {
        fields: [
            {
                name: 'Name',
                title: 'Name',
                template: '<span ng-if="row.subject.common_name" class="sel-pki-name">' +
                    '{{ row.subject.common_name}}</span>' +
                    '<span ng-if="!row.subject.common_name" class="sel-pki-name">' +
                    '{{row.subject.distinguished_name}}</span>',
            }, {
                name: 'Issued_by',
                title: 'Issued By',
                template: '<span ng-if="row.issuer.common_name" class="sel-pki-name">' +
                    '{{ row.issuer.common_name}}</span>' +
                    '<span ng-if="!row.issuer.common_name" class="sel-pki-name">' +
                    '{{row.issuer.distinguished_name}}</span>',

            }, {
                name: 'not_after',
                title: 'Expiration Date',
            },
        ],
        rowId: 'index',
        multipleactions: [{
            title: 'Remove',
            do(selection) {
                $scope.editable.data.config.ca_certs = _.filter(
                    $scope.editable.data.config.ca_certs, function(row) {
                        return !_.find(selection, function(item) {
                            return row == item;
                        });
                    },
                );

                return true;
            },
        }],
        layout: {
            hideSearch: true,
        },
    };

    $scope.crlsGrid = {
        fields: [{
            name: 'common_name',
            title: 'Name',
            template: '<span ng-if="row.common_name" class="sel-pki-name">' +
                '{{ row.common_name }}</span>' +
                '<span ng-if="!row.common_name" class="sel-pki-name">' +
                '{{row.distinguished_name }}</span>',
        }, {
            name: 'next_update',
            title: 'Expiration Date',
        }, {
            name: 'refresh',
            title: 'Refresh',
            template: '<span ng-if="row.update_interval" class="sel-crl-refresh">' +
                '{{ row.update_interval }} minutes</span>' +
                '<span ng-if="!row.update_interval" class="sel-crl-refresh">none</span>',
        }],
        rowId: 'index',
        multipleactions: [{
            title: 'Remove',
            do(selection) {
                $scope.editable.data.config.crls = _.filter($scope.editable.data.config.crls,
                    function(row) {
                        return !_.find(selection, function(item) {
                            return row == item;
                        });
                    });

                return true;
            },
        }],
        layout: {
            hideSearch: true,
        },
    };

    $scope.addCA = function() {
        $scope.caErrors = null;
        $scope.newCA = {};
        AviModal.open('prof-pki-profile-create-add-ca', { opener: $scope });
    };

    $scope.uploadCA = function(fileContent) {
        $scope.caErrors = null;
        $scope.editable.resolveCA(fileContent)
            .then(function(resolved) {
                $scope.newCA = resolved;
            }).catch(function(rsp) {
                $scope.caErrors = rsp.data;
            });
    };

    $scope.saveCA = function() {
        $scope.newCA.index = $scope.editable.data.config.ca_certs.length;
        $scope.editable.data.config.ca_certs.push(angular.copy($scope.newCA));
        AviModal.destroy('prof-pki-profile-create-add-ca');
    };

    $scope.addCRL = function() {
        $scope.crlErrors = null;
        $scope.newCRL = {};
        $scope.refreshTime = null;
        AviModal.open('prof-pki-profile-create-add-crl', { opener: $scope });
    };

    $scope.uploadCRL = function(fileContent) {
        $scope.crlErrors = null;
        $scope.editable.resolveCRL(fileContent)
            .then(function(resolved) {
                $scope.newCRL = resolved;
            }).catch(function(rsp) {
                $scope.crlErrors = rsp.data;
            });
    };

    $scope.sendUrl = function(url) {
        $scope.crlErrors = null;
        $scope.editable.resolveUrl(url)
            .then(function(resolved) {
                $scope.newCRL = resolved;
            }).catch(function(rsp) {
                $scope.crlErrors = rsp.data;
            });
    };

    $scope.saveCRL = function() {
        $scope.newCRL.index = $scope.editable.data.config.crls.length;

        if ($scope.refreshTime) {
            $scope.newCRL.update_interval = parseInt($scope.refreshTime, 10);
        }

        $scope.editable.data.config.crls.push(angular.copy($scope.newCRL));
        AviModal.destroy('prof-pki-profile-create-add-crl');
    };
}]);
