/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBPoolMember
 * @author Alex Malitsky
 * @description
 *
 *     Item for GslbService GslbPool member taken from gslbservice-inventory API.
 */
angular.module('aviApp').factory('GSLBPoolMember', ['Item', function(Item) {
    class GSLBPoolMember extends Item {
        getIdFromData_(data) {
            return data.config && data.config.id || undefined;
        }

        getRuntimeData(gslbSiteId) {
            const runtime = this.data && this.data.runtime;

            if (runtime && angular.isString(gslbSiteId) &&
                runtime.gslbSiteOperStatuses &&
                gslbSiteId in runtime.gslbSiteOperStatuses) {
                return runtime.gslbSiteOperStatuses[gslbSiteId];
            } else if (angular.isUndefined(gslbSiteId)) {
                return super.getRuntimeData();
            } else { //placeholder when no data was returned by the backend
                return {
                    oper_status: {
                        state: 'OPER_UNAVAIL',
                        reason: ['Object status is unavailable'],
                    },
                };
            }
        }
    }

    angular.extend(GSLBPoolMember.prototype, {
        objectName: 'GSLBPoolMember',
    });

    return GSLBPoolMember;
}]);
