/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Inject, OnDestroy } from '@angular/core';
import { isNumber } from 'underscore';

interface EditEvent {
    group: WafPolicyPsmGroup;
    index?: number;
}

/**
 * @ngdoc component
 * @name WafPolicyPsmGroupsConfigComponent
 * @module waf/WafPolicyPsmGroupsConfigComponent
 * @desc Component for displaying a WAF Policy Whitelist rule in the WAF Policy modal.
 * @param psm - WAF Positive Security Model Config Item
 * @param wafPolicyName - Name of the WAF Policy. Used to display breadcrumbs.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-groups-config',
    templateUrl: './waf-policy-psm-groups-config.component.html',
})
export class WafPolicyPsmGroupsConfigComponent implements OnDestroy {
    @Input() wafPolicyName: string;
    @Input() psm: WafPositiveSecurityModelConfigItem;

    private wafPolicyPsmGroupCollection_: WafPolicyPsmGroupCollection;

    constructor(
        @Inject('WafPolicyPsmGroupCollection')
        private WafPolicyPsmGroupCollection: WafPolicyPsmGroupCollection,
    ) {
        this.wafPolicyPsmGroupCollection_ = new this.WafPolicyPsmGroupCollection();
    }

    /**
     * Creates a new WafPolicyPsmGroup item and edits it.
     */
    addPsmGroup(): void {
        this.editPsmGroup({ group: this.wafPolicyPsmGroupCollection_.createNewItem() });
    }

    /**
     * Edits a WafPolicyPsmGroup item.
     */
    editPsmGroup($event: EditEvent): void {
        const { group, index } = $event;

        group.edit(group.windowElement, {
            breadcrumbs: [`WAF Policy: ${this.wafPolicyName}`],
            onSubmit: ['group', this.getHandleSubmit(index)],
        });
    }

    /**
     * Called to delete a PSM Group.
     */
    deletePsmGroup(group: WafPolicyPsmGroup): void {
        this.psm.deleteGroup(group);
    }

    /**
     * Returns a handleSubmit function based on whether or not a group is being edited or created.
     * @param index - Index of the group.
     */
    getHandleSubmit = (index: number): Function => {
        return (group: WafPolicyPsmGroup): void => {
            if (isNumber(index)) {
                this.psm.editGroup(group, index);
            } else {
                this.psm.addGroup(group);
            }
        };
    }

    ngOnDestroy(): void {
        this.wafPolicyPsmGroupCollection_.destroy();
    }
}
