/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name MicroServiceGroupCollection
 * @description Collection of microservicegroup back-end objects.
 * @extends Collection
 */
angular.module('aviApp').factory('MicroServiceGroupCollection', ['Collection', 'MicroServiceGroup',
function(Collection, MicroServiceGroup) {
    const MicroServiceGroupCollection = function(oArgs) {
        MicroServiceGroupCollection.superconstructor.call(this, oArgs);
    };

    avi.inherit(MicroServiceGroupCollection, Collection);

    MicroServiceGroupCollection.prototype.objectName_ = 'microservicegroup';
    MicroServiceGroupCollection.prototype.itemClass_ = MicroServiceGroup;
    MicroServiceGroupCollection.prototype.windowElement_ = 'prof-microservicegroup-create';

    return MicroServiceGroupCollection;
}]);

/**
 * @ngdoc service
 * @name MicroServiceGroup
 * @description Item of microservicegroup back-end object.
 * @extends Item
 */
angular.module('aviApp').factory('MicroServiceGroup', ['Item', function(Item) {
    const MicroServiceGroup = function(oArgs) {
        MicroServiceGroup.superconstructor.call(this, oArgs);
    };

    avi.inherit(MicroServiceGroup, Item);

    MicroServiceGroup.prototype.objectName = 'microservicegroup';
    MicroServiceGroup.prototype.windowElement = 'prof-microservicegroup-create';

    return MicroServiceGroup;
}]);
