/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { severityHash, typeHash } from '../../constants/case.constant';

const { SEVERITY_4 } = severityHash;
const { BUG } = typeHash;

const caseCollectionFactory = (Collection, Case, Auth) => {
    /**
     * @class
     * @name module:collections/CaseCollection
     * @extends module:avi/dataModel.Collection
     * @desc collection of module:items/Case
     */
    class CaseCollection extends Collection {
        constructor(oArgs) {
            const newoArgs = {
                ...oArgs,
                permissionName: 'PERMISSION_CONTROLLER',
            };

            super(newoArgs);
        }
    }

    const serverDefaultsOverride = {
        version: Auth.getControllerVersion(),
        severity: SEVERITY_4,
        type: BUG,
    };

    Object.assign(CaseCollection.prototype, {
        objectName_: 'projectx/case',
        itemClass_: Case,
        defaultDataSources_: 'list',
        windowElement_: 'case-modal',
        serverDefaultsOverride_: serverDefaultsOverride,
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return CaseCollection;
};

caseCollectionFactory.$inject = [
    'Collection',
    'Case',
    'Auth',
];

/**
 * @ngdoc service
 * @alias CaseCollection
 * @author Ram Pal, Ashish Verma
 * @description  Collection of {Case} items.
 */
angular.module('avi/cportal')
    .factory('CaseCollection', caseCollectionFactory);
