/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * @ngdoc service
 * @name SEGroupFlavor
 * @description Flavor {@link Item} for {@link SEGroup}.
 */
angular.module('aviApp').factory('SEGroupFlavor', [
'Item', function(Item) {
    class SEGroupFlavor extends Item {
        /** @override */
        isEditable() {
            return false;
        }

        /**
         * For historical reasons backend is treating name field as id.
         * @override
         **/
        getIdFromData_(data) {
            return data && data.config && data.config.name;
        }

        /** @override */
        getName() {
            const config = this.getConfig();

            if (!config) {
                return '';
            }

            const addNotRecommended = 'is_recommended' in config && !config.is_recommended;

            if (addNotRecommended) {
                return `${config.name} (not recommended)`;
            }

            return config.name;
        }
    }

    //not used for API calls
    SEGroupFlavor.prototype.objectName = 'segroup-flavor';

    return SEGroupFlavor;
}]);
