/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function wafPolicyPsmGroupMetricDataSourceFactory(DataSource) {
    /**
     * @alias module:services/WafPolicyPsmGroupMetricDataSource
     * @extends DataSource
     */
    class WafPolicyPsmGroupMetricDataSource extends DataSource {
        constructor(args) {
            Object.assign(args, {
                defaultFields: [
                    {
                        id: 'config',
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
                    dimension_filter_op: 'METRICS_FILTER_CONTAINS',
                    entity_uuid: '*',
                },
            });

            super(args);
        }

        /** @override */
        processResponse_(response) {
            this.owner_.config = response;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();
            const { metricIds, id: objId } = this.owner_;

            return metricIds.map(metricId => ({
                metric_id: metricId,
                obj_id: objId,
                id: `${objId}:${metricId}`,
                ...params,
            }));
        }
    }

    return WafPolicyPsmGroupMetricDataSource;
}

wafPolicyPsmGroupMetricDataSourceFactory.$inject = [
    'DataSource',
];

/**
 * @ngdoc service
 * @name WafPolicyPsmGroupMetricDataSource
 * @module services/WafPolicyPsmGroupMetricDataSource
 * @author alextsg
 * @description Data source for WafPolicyPsmGroupMetric
 */
angular
    .module('waf.vantage.avi')
    .factory('WafPolicyPsmGroupMetricDataSource', wafPolicyPsmGroupMetricDataSourceFactory);
