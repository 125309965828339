/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @gdoc service
 * @name NetworkProfileCollection
 */
angular.module('aviApp').factory('NetworkProfileCollection', ['Collection', 'NetworkProfile',
function(Collection, NetworkProfile) {
    function NetworkProfileCollection(oArgs) {
        NetworkProfileCollection.superconstructor.call(this, oArgs);
    }

    avi.inherit(NetworkProfileCollection, Collection);

    const serverDefaultsOverride = {
        profile: {
            type: 'PROTOCOL_TYPE_TCP_PROXY',
            tcp_fast_path_profile: {
                dsr_profile: undefined,
            },
            udp_fast_path_profile: {
                dsr_profile: undefined,
            },
        },
    };

    angular.extend(NetworkProfileCollection.prototype, {
        objectName_: 'networkprofile',
        itemClass_: NetworkProfile,
        windowElement_: 'prof-net-profile-create',
        defaults_: {
            profile: {
                type: 'PROTOCOL_TYPE_TCP_PROXY',
            },
        },
        serverDefaultsOverride_: serverDefaultsOverride,
    });

    return NetworkProfileCollection;
}]);
