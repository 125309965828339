/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  geoProfileModal
 * @description
 *     Component for Geo Profile configuration modal. Configuring a Geo Profile basically starts a
 *     process, in which a file that is "staging" becomes "staged". A request is made to the
 *     back-end to get a list of staging files, which is displayed in dropdowns for the user to
 *     select. If editing, previously selected files are no longer "staging" and are now "staged",
 *     so those dropdowns become disabled, since it would be weird for the selected value to not
 *     be in the list of options in the dropdown.
 * @param  {GeoProfile} editable
 * @param  {Function} closeModal - Closes modal.
 */
class GeoProfileModalController {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }

    $onInit() {
        this.stagingFiles = undefined;
        this.selectedStagingFiles = [];

        this.setSelectedStagingFiles();
        this.editable.getStagingFiles()
            .then(files => this.stagingFiles = files);
    }

    /**
     * Sets this.selectedStagingFiles array to be passed as hiddenOptions to dropdowns, so that
     * previously selected options aren't shown.
     */
    setSelectedStagingFiles() {
        const { entries } = this.editable.getConfig();

        this.selectedStagingFiles = entries.map(entry => entry.file.filename);
    }

    /**
     * Removes a selected entry object from config.files and sets this.selectedStagingFiles.
     * @param  {number} index - Index of the file object to remove.
     */
    removeEntry(index) {
        this.editable.removeEntry(index);
        this.setSelectedStagingFiles();
    }
}

GeoProfileModalController.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('geoProfileModal', {
    controller: GeoProfileModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/infrastructure/gslb/geo-profile-modal/' +
            'geo-profile-modal.html',
});
