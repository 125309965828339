/*
 * **************************************************************************
 *  *
 *  * AVI CONFIDENTIAL
 *  * __________________
 *  *
 *  * [2013] - [2019] Avi Networks Incorporated
 *  * All Rights Reserved.
 *  *
 *  * NOTICE: All information contained herein is, and remains the property
 *  * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 *  * and technical concepts contained herein are proprietary to Avi Networks
 *  * Incorporated, and its suppliers and are covered by U.S. and Foreign
 *  * Patents, patents in process, and are protected by trade secret or
 *  * copyright law, and other laws. Dissemination of this information or
 *  * reproduction of this material is strictly forbidden unless prior written
 *  * permission is obtained from Avi Networks Incorporated.
 *
 */

class PingAccessAgentModalController {
    constructor(
        PingAccessAgentProfileCollection,
        schemaService,
    ) {
        this.pingAccessAgentProfileCollection_ = new PingAccessAgentProfileCollection();

        this.fileUploadHelpText = schemaService
            .getFieldDescription('PingAccessAgent', 'properties_file_data');
    }

    /** @override */
    $onDestroy() {
        this.pingAccessAgentProfileCollection_.destroy();
    }
}

PingAccessAgentModalController.$inject = [
    'PingAccessAgentProfileCollection',
    'schemaService',
];

/**
 * @ngdoc component
 * @name pingAccessAgentModal
 * @param {PingAcccessAgentProfile} editable
 * @desc
 *     Modal component for creating PingAccessAgent Profile
 *     by uploading its agent properties file.
 */
angular.module('aviApp').component('pingAccessAgentModal', {
    bindings: {
        editable: '<',
    },
    controller: PingAccessAgentModalController,
    templateUrl: 'src/components/modals/ping-access-agent-modal/ping-access-agent-modal.html',
});
