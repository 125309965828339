/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('SystemConfigurationEditAccessController', [
'$scope', 'AuthProfileCollection', 'SSLProfileCollection',
'CertificateCollection', 'TenantCollection', 'Schema', 'Regex',
function(scope, AuthProfileCollection, SSLProfileCollection,
CertificateCollection, TenantCollection, Schema, Regex) {
    scope.$parent.modalScope = scope;//AviModal thing

    scope.Regex = Regex;
    scope.Schema = Schema;

    scope.authProfileCollection = new AuthProfileCollection();
    scope.sslProfileCollection = new SSLProfileCollection();
    scope.tenantCollection = new TenantCollection({ isStatic: true });

    const certTypeSystemEnum = 'SSL_CERTIFICATE_TYPE_SYSTEM';

    scope.certificateCollection = new CertificateCollection({
        params: { type: certTypeSystemEnum },
        defaults: { type: certTypeSystemEnum },
    });

    /**
     * Event handler for itemSaveSuccess over systemConfigService Item.
     * @type {Function|undefined}
     * @inner
     */
    let checkPortChange;

    //need to throw an alert if portal port got changed
    scope.init = function() {
        const { editable } = scope;

        checkPortChange = editable.checkPortalPortChange.bind(editable);

        editable.one('itemSaveSuccess', checkPortChange);
    };

    //need to unbind in case it got cancelled
    scope.$on('$destroy', () => {
        if (checkPortChange) {
            scope.editable.unbind('itemSaveSuccess', checkPortChange);
        }
    });
}]);
