/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  ErrorPageProfile
 * @description  ErrorPageProfile item.
 */
const ErrorPageProfile = Item => {
    class ErrorPageProfile extends Item {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (Array.isArray(config.error_pages)) {
                config.error_pages.forEach(page => {
                    page.redirect_ = !!page.error_redirect;
                });
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (Array.isArray(config.error_pages)) {
                config.error_pages.forEach(page => {
                    if (page.redirect_) {
                        delete page.error_page_body_ref;
                    } else {
                        delete page.error_redirect;
                    }

                    delete page.redirect_;
                });
            }

            return config;
        }
    }

    angular.extend(ErrorPageProfile.prototype, {
        objectName: 'errorpageprofile',
        windowElement: 'error-page-profile-modal',
    });

    return ErrorPageProfile;
};

ErrorPageProfile.$inject = [
        'Item',
];

angular.module('aviApp').factory('ErrorPageProfile', ErrorPageProfile);
