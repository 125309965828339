/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLB
 * @author Alex Malitsky
 * @description
 *
 *     Global Service Load Balancer Item. Has a list of GslbSites, one of which is local,
 *     remaining are remote. One GslbSite is considered the owner and can't be dropped.
 */

/**
 *  @typedef {Object} GslbSiteConfig - Protobuf: GslbSite.
 *  @property {string} cluster_uuid
 *  @property {string} name
 *  @property {string} address - FQDN or IP address
 *  @property {IpAddr[]} ip_addresses
 *  @property {number} port
 *  @property {string} username - Controller's credentials.
 *  @property {string} password
 *  @property {GslbSiteDNSVS[]} dns_vses
 *  @property {VirtualService#id[]|undefined} DNSVSUuids_ - Solely UI property, copy of
 *      GslbSite#DNSVSUuids_ to provide revert ability.
 *  @property {string} member_type - SiteMemberType enum.
 */

/**
 *  @typedef {Object} GslbThirdPartySiteConfig - Protobuf: GslbThirdPartySite.
 *  @property {string} cluster_uuid
 *  @property {string} name
 *  @property {boolean} enabled
 */

/**
 * @typedef {Object} DNSConfig - Defined by protobuf.
 * @property {string} domain_name
 */

/**
 * @typedef {Object} GslbConfig - Protobuf: Gslb.
 * @property {string} uuid
 * @property {string|undefined} url
 * @property {string} name
 * @property {DNSConfig[]} dns_configs
 * @property {GslbSite[]} sites - Should have unique name and
 * priority values within config.
 * @property {string} leader_cluster_uuid
 */

/**
 * @typedef {Object} GslbSiteDNSVS
 * @property {string} dns_vs_uuid
 * @property {string[]} domain_names selected from GslbConfig.dns_configs.
 */

angular.module('GSLB.vantage.avi').factory('GSLB', ['$q', 'Item', 'Base', 'defaultValues',
'AviAlertService', 'GSLBVSCollection', 'systemInfoService', 'gslbLocationAfterLoad',
function($q, Item, Base, defaultValues, AviAlertService, GSLBVSCollection, systemInfo,
gslbLocationAfterLoad) {
    const objectName = 'gslb';

    /**
     * @constructor
     * @extends Item
     */
    class GSLB extends Item {
        constructor(args = {}) {
            super(args);

            /**
             * List of IPGroups, used for edit only. Objects of IpAddr, IpAddrRange or
             * IpAddrPrefix types.
             * @type {Object[]}
             */
            this.clientGroupIps = [];
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if (!('dns_configs' in config)) {
                config['dns_configs'] = [];
            }

            if (!config['dns_configs'].length) {
                config['dns_configs'].push({ domain_name: '' });
            }

            config['sites'].forEach(site => {
                if (!('ip_addresses' in site)) {
                    site['ip_addresses'] = [];
                }

                if (!site['ip_addresses'].length) {
                    site['ip_addresses'].push({
                        addr: '',
                        type: 'V4',
                    });
                }

                if (!('dns_vses' in site)) {
                    site['dns_vses'] = [];
                }

                if (!site['dns_vses'].length) {
                    site['dns_vses'].push(
                        this.getDefaultGslbSiteDNSVSConfig(),
                    );
                }

                gslbLocationAfterLoad(site['location']);
            });

            const { clientGroupIps } = this;

            clientGroupIps.length = 0;

            if ('client_ip_addr_group' in config) {
                const { client_ip_addr_group: group } = config;

                ['addrs', 'ranges', 'prefixes'].forEach(propName => {
                    if (propName in group) {
                        clientGroupIps.push(...group[propName]);
                    }
                });
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.data.config);

            config['dns_configs'] = _.reject(config['dns_configs'],
                config => !config['domain_name']);

            if (!config['dns_configs'].length) {
                config['dns_configs'] = undefined;
            }

            const filterLocation = location => {
                if (location && location.source !== 'GSLB_LOCATION_SRC_USER_CONFIGURED') {
                    delete location.location;
                }
            };

            config['sites'].forEach(site => {
                site['ip_addresses'] = _.reject(site['ip_addresses'], ipAddr => !ipAddr.addr);

                if (!site['ip_addresses'].length) {
                    site['ip_addresses'] = undefined;
                }

                if (site['member_type'] === 'GSLB_ACTIVE_MEMBER' &&
                    Array.isArray(site['dns_vses'])) {
                    site['dns_vses'] = site['dns_vses']
                        .filter(({ dns_vs_uuid: vsId }) => vsId);

                    //strip off names from VS uuids
                    site['dns_vses'].forEach(dnsVS =>
                        dnsVS['dns_vs_uuid'] = dnsVS['dns_vs_uuid'].slug());
                } else {
                    site['dns_vses'] = undefined;
                }

                filterLocation(site.location);
            });

            if (Array.isArray(config.third_party_sites)) {
                config.third_party_sites.forEach(site => filterLocation(site.location));
            }

            const { clientGroupIps } = this;

            if (clientGroupIps.length) {
                if (!('client_ip_addr_group' in config)) {
                    config.client_ip_addr_group = {};
                }

                const { client_ip_addr_group: clientGroup } = config;

                clientGroup.addrs = [];
                clientGroup.ranges = [];
                clientGroup.prefixes = [];

                clientGroupIps.forEach(ip => {
                    if (angular.isObject(ip)) {
                        if ('addr' in ip) {
                            clientGroup.addrs.push(ip);
                        } else if ('mask' in ip) {
                            clientGroup.prefixes.push(ip);
                        } else if ('begin' in ip && 'end' in ip) {
                            clientGroup.ranges.push(ip);
                        }
                    }
                });
            } else {
                delete config['client_ip_addr_group'];
            }

            return config;
        }

        /** @override */
        transformAfterLoad() {
            this.clientGroupIps.length = 0;
        }

        /**
         * Checks whether passed controller is marked as `owner` of GSLB configuration.
         * @param {GslbSiteConfig.cluster_uuid|GslbSiteConfig} gslbSite - Gslb Site configuration
         *     or cluster_uuid.
         * @returns {boolean|undefined} - Undefined if data is not ready.
         * @public
         */
        isLeaderSite(gslbSite) {
            const id = angular.isString(gslbSite) && gslbSite ||
                    angular.isObject(gslbSite) && gslbSite['cluster_uuid'];
            let res;

            if (this.data) {
                res = !!id && this.data.config['leader_cluster_uuid'] === id;
            }

            return res;
        }

        /**
         * Returns a name of GslbSite which is the leader of this GSLB configuration.
         * @returns {GslbSiteConfig.name|undefined} - Undefined when gslb has no data yet.
         * @public
         */
        getLeaderSiteName() {
            const config = this.getConfig();

            if (!config) {
                return;
            }

            const { name } = this.getSiteByIndex(
                this.getSiteIndex_(GSLB.sitesConfigPropName, config['leader_cluster_uuid']),
            );

            return name;
        }

        /**
         * Returns a default GslbSite configuration.
         * @param {string=} type - Config property name of sites list this item is meant for.
         * @returns {GslbSiteConfig}
         * @protected
         */
        getDefaultSiteConfig_(type) {
            let config;

            switch (type) {
                case GSLB.nonAviSitesConfigPropName:
                    config = defaultValues.getDefaultItemConfigByType('gslbthirdpartysite');
                    break;

                default:
                    [config] = this.getDefaultConfig_()['sites'];
            }

            return angular.copy(config);
        }

        /**
         * Returns default configuration of GslbSite DNS VS object.
         * @returns {Object} GslbSiteDnsVs protobuf message.
         */
        getDefaultGslbSiteDNSVSConfig() {
            return {
                dns_vs_uuid: '',
                domain_names: [],
            };
        }

        /**
         * Returns GslbSite of a passed index if present in GSLB config.
         * @param {number} index
         * @returns {GslbSiteConfig|undefined} - Undefined if not ready, wrong parameter or not
         * found.
         * @public
         */
        getSiteByIndex(index) {
            return this.getSiteByIndex_(GSLB.sitesConfigPropName, index);
        }

        /**
         * Returns nonAviGslbSite of a passed index if is present in GSLB config.
         * @param {number} index
         * @returns {GslbThirdPartySiteConfig|undefined}
         * @public
         */
        getNonAviSiteByIndex(index) {
            return this.getSiteByIndex_(GSLB.nonAviSitesConfigPropName, index);
        }

        /**
         * Actual lookup is made here.
         * @param {string} listPropertyName
         * @param {number} index
         * @returns {GslbSiteConfig|GslbThirdPartySiteConfig|undefined}
         * @private
         */
        getSiteByIndex_(listPropertyName, index) {
            const list = this.getConfig()[listPropertyName];

            if (Array.isArray(list)) {
                return list[index];
            }
        }

        /**
         * Returns a fist DNS domain name.
         * @returns {string}
         * @public
         */
        getDefaultDNSDomainName() {
            const domains = this.getDNSDomainNames();

            return domains.length ? domains[0] : '';
        }

        /**
         * Returns a list of domain names provided by this GSLB item.
         * @param {boolean=} woDot - With or without dot before the domain name.
         * @returns {string[]}
         * @public
         */
        getDNSDomainNames(woDot = false) {
            const { dns_configs: dnsConfigs } = this.getConfig();
            let domains = [];

            if (Array.isArray(dnsConfigs)) {
                domains = _.pluck(dnsConfigs, 'domain_name');

                if (!woDot) {
                    domains = domains.map(domainName => `.${domainName}`);
                }
            }

            return domains.sort();
        }

        /**
         * Wrapper over addSite for regular GSLB sites.
         * @public
         */
        addSite() {
            this.addSite_(GSLB.sitesConfigPropName);
        }

        /**
         * Wrapper over addSite_ for non avi sites.
         * @public
         */
        addNonAviSite() {
            this.addSite_(GSLB.nonAviSitesConfigPropName);
        }

        /**
         * Non avi Gslb sites use different edit modal component, this component returns such id.
         * @param {string} type - Gslb Site type: regular or non-avi.
         * @returns {string|undefined} - Undefined to use the regular one.
         * @private
         */
        getSiteEditModalId_(type) {
            switch (type) {
                case GSLB.nonAviSitesConfigPropName:
                    return 'gslb-non-avi-modal';
            }
        }

        /**
         * Adds a GslbSite or GslbThirdPartySite with default configuration to GslbConfig and opens
         * a modal to set it's properties. In case of modal dismiss event removes dummy item from
         * GSLB. Need to switch off Item#loadOnEdit before opening modal, otherwise dummy item
         * will be dropped since backend configuration doesn't have it yet.
         * @private
         */
        addSite_(listPropertyName) {
            const
                windowElement = this.getSiteEditModalId_(listPropertyName),
                defaultConfig = this.getDefaultSiteConfig_(listPropertyName),
                prevLoadOnEdit = this.loadOnEdit,
                config = this.getConfig();

            config[listPropertyName] = config[listPropertyName] || [];

            const newIndex = config[listPropertyName].push(defaultConfig) - 1;

            this.loadOnEdit = false;

            this.edit(windowElement, { siteIndex: newIndex })
                .catch(() => config[listPropertyName].pop())
                .finally(() => this.loadOnEdit = prevLoadOnEdit);
        }

        /**
         * Opens a modal to edit properties of GslbSite belonging to GSLB. Wrapper over editSite_.
         * @public
         */
        editSite() {
            return this.editSite_(GSLB.sitesConfigPropName, ...arguments);
        }

        /**
         * Opens a modal to edit properties of GslbThirdPartySite belonging to GSLB.
         * Wrapper over editSite_.
         * @public
         */
        editNonAviSite() {
            return this.editSite_(GSLB.nonAviSitesConfigPropName, ...arguments);
        }

        /**
         * Figures out which modal to open and opens it in a usual manner.
         * @param {string} listPropertyName - Type of GSLB Site we want to edit.
         * @param {number|GslbThirdPartySiteConfig|GslbSiteConfig} site
         * @returns {angular.$q.promise}
         * @private
         */
        editSite_(listPropertyName, site) {
            const
                windowElement = this.getSiteEditModalId_(listPropertyName),
                index = this.getSiteIndex_(listPropertyName, site);

            let promise;

            if (index !== -1) {
                promise = this.edit(windowElement, { siteIndex: index });
            }

            return promise || $q.reject('Passed GslbSite was not found in Item\'s config data');
        }

        /**
         * Wrapper over toggleSiteEnabledFlag_ for regular GSLB sites.
         * @public
         */
        toggleSiteEnabledFlag() {
            return this.toggleSiteEnabledFlag_(GSLB.sitesConfigPropName, ...arguments);
        }

        /**
         * Wrapper over toggleSiteEnabledFlag_ for non-avi GSLB sites.
         * @public
         */
        toggleNonAviSiteEnabledFlag() {
            return this.toggleSiteEnabledFlag_(GSLB.nonAviSitesConfigPropName, ...arguments);
        }

        /**
         * Enables/Disables GslbSite or GslbThirdPartySite of GSLB.
         * @param {string} listPropertyName - Type of site we are working with.
         * @param {GslbSiteConfig|GslbSiteConfig[]|string|string[]} sites - Config or cluster_uuid.
         * @param {boolean=} flagValue - When undefined will flip the current value of enabled flag.
         * @returns {angular.$q.promise}
         * @private
         */
        toggleSiteEnabledFlag_(listPropertyName, sites, flagValue) {
            const
                haveValueToSet = !angular.isUndefined(flagValue),
                sitesToUpdate = [],
                list = this.getConfig()[listPropertyName];

            let promise;

            if (!Array.isArray(sites)) {
                sites = [sites];
            }

            sites.forEach(site => {
                const index = this.getSiteIndex_(listPropertyName, site);

                if (index !== -1) {
                    const siteToUpdate = angular.copy(list[index]);

                    siteToUpdate.enabled = haveValueToSet ? !!flagValue : !siteToUpdate.enabled;
                    sitesToUpdate.push(siteToUpdate);
                } else {
                    console.error('Passed GslbSite was not found in Item\'s config data');
                }
            });

            if (sitesToUpdate.length) {
                const payload = {};

                payload[listPropertyName] = sitesToUpdate;
                promise = this.patch({ add: payload });
            }

            return promise || $q.reject('No sites to update');
        }

        /**
         * Returns a GslbSite index in GslbConfig.sites or GslbThirdPartySite index in
         * GslbConfig.third_party_sites.
         * @param {string} listPropertyName - Sites list config property name.
         * @param {number|GslbSiteConfig|GslbSiteConfig.cluster_uuid} site
         * @returns {number} Index of passed GslbSite in GslbConfig.sites, -1 if not found.
         * @private
         */
        getSiteIndex_(listPropertyName, site) {
            const sites = this.getConfig()[listPropertyName];

            let
                index = -1,
                siteId;

            if (angular.isNumber(site) && site in sites) {
                index = site;
            } else if (site && angular.isString(site) && (siteId = site) ||
                angular.isObject(site) && (siteId = site['cluster_uuid'])) {
                index = _.findIndex(sites, gslbSite => gslbSite['cluster_uuid'] === siteId);
            }

            return index;
        }

        /**
         * Wrapper over dropSites_ for regular sites.
         * @public
         */
        dropSites() {
            return this.dropSites_(GSLB.sitesConfigPropName, ...arguments);
        }

        /**
         * Wrapper over dropSites_ for non avi sites.
         * @public
         */
        dropNonAviSites() {
            return this.dropSites_(GSLB.nonAviSitesConfigPropName, ...arguments);
        }

        /**
         * Drops any GslbSite which is not an `owner` or any NonAviGslbSite from GSLBConfig.
         * @param {string} listPropertyName - Type of site we are working with.
         * @param {number|GslbSiteConfig|number[]|GslbSiteConfig[]} sites - Site config or index in
         *     GslbConfig#sites array.
         * @returns {angular.$q.promise}
         * @private
         */
        dropSites_(listPropertyName, sites) {
            const
                config = this.getConfig(),
                { leader_cluster_uuid: leaderClusterId } = config,
                list = config[listPropertyName],
                sitesToDrop = [];

            let promise;

            if (!Array.isArray(sites)) {
                sites = [sites];
            }

            sites.forEach(site => {
                const index = this.getSiteIndex_(listPropertyName, site);

                if (index !== -1 && (site = list[index]) &&
                    site['cluster_uuid'] !== leaderClusterId) {
                    sitesToDrop.push(site);
                }
            });

            if (sitesToDrop.length) {
                const payload = {};

                payload[listPropertyName] = sitesToDrop;
                promise = this.patch({ delete: payload });
            }

            return promise || $q.reject('No sites to drop');
        }

        /**
         * When editing list of DNS VSes of a GslbSite we need to load their names from the
         * corresponding GslbSites and append them to bare uuids we have in config. When
         * siteIndex is not passed we will load all the names for all GslbSites.
         * @param {number=} siteIndex
         * @returns {angular.$q.promise}
         * @public
         */
        getSiteDNSVSNames(siteIndex) {
            const
                errMsg = 'Item not ready (isDestroyed_) or wrong siteIndex was passed',
                promises = [];

            let sites,
                site;

            if (this.data && (angular.isUndefined(siteIndex) ||
                (site = this.getSiteByIndex(siteIndex)))) {
                if (site) {
                    sites = [site];
                } else {
                    sites = this.getConfig()['sites'];
                }

                this.busy = true;

                sites.forEach(site => {
                    const
                        vsIdToDNSVSList = {},
                        vsUuids = [];

                    if (Array.isArray(site['dns_vses'])) {
                        site['dns_vses'].forEach(dnsVS => {
                            const { dns_vs_uuid: vsId } = dnsVS;

                            if (!(vsId in vsIdToDNSVSList)) {
                                vsIdToDNSVSList[vsId] = [];
                                vsUuids.push(vsId);
                            }

                            vsIdToDNSVSList[vsId].push(dnsVS);
                        });
                    }

                    let promise;

                    if (vsUuids && vsUuids.length) {
                        const vsCollection = new GSLBVSCollection({
                            gslbSiteId: site['cluster_uuid'],
                            gslbTenant: this.getTenantId(),
                            limit: 1000,
                            params: {
                                'uuid.in': vsUuids.join(),
                                fields: 'name',
                            },
                        });

                        promise = vsCollection.load().then(() => {
                            if (!this.isDestroyed()) {
                                //add names to VS uuids in-place
                                vsUuids.forEach(vsUuid => {
                                    const vsItem = vsCollection.getItemById(vsUuid);

                                    if (vsItem) {
                                        const vsName = vsItem.getName();

                                        vsIdToDNSVSList[vsUuid].forEach(dnsVS =>
                                            dnsVS['dns_vs_uuid'] += `#${vsName}`);
                                    }
                                });
                            } else {
                                console.warn(errMsg);

                                return $q.reject(errMsg);
                            }
                        }).finally(() => {
                            vsCollection.destroy();
                        });
                    } else {
                        promise = $q.when('No VSes are present in config - have nothing to load');
                    }

                    promises.push(promise);
                });

                if (promises.length) {
                    $q.all(promises)
                        .finally(() => this.busy = false);
                } else {
                    this.busy = false;
                }
            } else {
                console.warn(errMsg);
                promises.push($q.reject(errMsg));
            }

            return $q.all(promises);
        }

        /**
         * Makes use of private static method to verify whether Site configuration is ready to be
         * used for GSLB. Also checks credentials, gets and sets GslbSite#cluster_uuid and
         * saves/submits an object.
         * @param {number} siteIndex
         * @param {boolean=} submitOnSuccess - If true calls Item#submit when verification went
         *     through, calls Item#save otherwise.
         * @returns {angular.$q.promise} - To be resolved with verification API response of
         * Item#submit return value.
         */
        verifySiteAndSave(siteIndex, submitOnSuccess) {
            const errMsg = 'Item not be ready (might be UI destroyed) or faulty API response';
            let promise,
                site;

            if (this.data && (site = this.getSiteByIndex(siteIndex))) {
                this.busy = true;

                promise = GSLB.verifySite_(site)
                    .then(rsp => {
                        if (this.data && rsp.data) {
                            const oldClusterUuid = site['cluster_uuid'];

                            site['cluster_uuid'] = rsp.data['rx_uuid'];

                            //if cluster_uuid has changed we remove old DNS VSs
                            if (oldClusterUuid !== site['cluster_uuid']) {
                                site['dns_vses'].length = 0;
                            }

                            return rsp;
                        } else {
                            console.warn(errMsg);

                            return $q.reject(errMsg);
                        }
                    }, err => {
                        this.errors = err.data;

                        return $q.reject(err);
                    })
                    .finally(() => {
                        this.busy = false;
                    });

                promise = promise.then(rsp => {
                    if (this.data && rsp.data) {
                        return submitOnSuccess ? this.submit() : this.save();
                    } else {
                        console.warn(errMsg);

                        return $q.reject(errMsg);
                    }
                });
            } else {
                promise = $q.reject('Data is not ready or Site of a passed index wasn\'t found.');
            }

            return promise;
        }

        /**
         * Returns a hash of cluster_id as a key and value keeping an array of DNS VS ids
         * configured on that particular GslbSite. Source of data for GSLBServiceFQDNCollection.
         * @returns {Object.<string,Object>} - Where string is a corresponding cluster id.
         * @public
         */
        getDNSVSSites() {
            const
                res = {},
                { sites } = this.getConfig();

            sites.forEach(({ cluster_uuid: clusterId, dns_vses: dnsVSes, name }) => {
                if (dnsVSes && dnsVSes.length) {
                    res[clusterId] = {
                        clusterId,
                        name,
                        dnsVSs: _.pluck(dnsVSes, 'dns_vs_uuid').concat(),
                    };
                }
            });

            return res;
        }

        /**
         * Returns the list of GslbSite ids.
         * @returns {string[]}
         * @public
         */
        getSites() {
            const { sites } = this.getConfig();

            return sites.map(({ cluster_uuid: clusterUuid, name }) => `${clusterUuid}#${name}`);
        }

        /**
         * Returns the list of non-Avi site ids.
         * @return {string[]}
         */
        getNonAviSites() {
            const
                config = this.getConfig(),
                sites = config[GSLB.nonAviSitesConfigPropName] || [];

            return sites.map(({ cluster_uuid: clusterUuid, name }) => `${clusterUuid}#${name}`);
        }

        /**
         * Returns the list of both Avi and non-Avi site ids.
         * @return {string[]}
         */
        getAllSites() {
            return [...this.getSites(), ...this.getNonAviSites()];
        }

        /**
         * Deletes object from clientGroupIps at index specified.
         * @param index
         */
        deleteClientGroupIp(index) {
            this.clientGroupIps.splice(index, 1);
        }

        /**
         * Adds empty placeholder object to clientGroupIps array.
         */
        addClientGroupIp() {
            this.clientGroupIps.push(undefined);
        }

        /** @override */
        isEditable() {
            return (!systemInfo.haveGSLBConfig() || systemInfo.localSiteIsGSLBLeader()) &&
                super.isEditable();
        }

        /** @override */
        isProtected() {
            return !systemInfo.localSiteIsGSLBLeader() || super.isProtected();
        }

        /**
         * Gets a local Site configuration with ips, port and cluster_uuid.
         * @returns {angular.$q.promise} - To be resolved with local GslbSite config object.
         * @static
         */
        static getLocalSiteConfig() {
            return $q.when(systemInfo.isReady() || systemInfo.load())
                .then(() => {
                    const { cluster: clusterConfig, systemconfiguration: systemConfig } =
                        systemInfo.data;

                    return {
                        cluster_uuid: clusterConfig['uuid'],
                        ip_addresses: _.pluck(clusterConfig['nodes'], 'ip'),
                        port: systemConfig['portal_configuration']['port'],
                        member_type: 'GSLB_ACTIVE_MEMBER',
                    };
                },
                err => {
                    AviAlertService.throw(err.data);

                    return $q.reject(err);
                });
        }

        /**
         * Makes an API call to verify whether this GslbSite is configured to participate in GSLB
         * configuration.
         * @param {GslbSiteConfig} gslbSite
         * @protected
         * @static
         */
        static verifySite_(gslbSite) {
            const payload = _.pick(gslbSite, ['username', 'password', 'ip_addresses', 'port']);

            return new Base().request('post', '/api/gslbsiteops/verify', payload);
        }

        /**
         * Since we use different modals for regular gslbSites and non avi ones we need to
         * dismiss the right one. In case on non avi Gslb site create/edit boolean argument will
         * be passed.
         * @param {boolean} nonAviSiteModal
         * @override
         **/
        dismiss(nonAviSiteModal) {
            //dismiss doesn't support passing modal id unfortunately, let's redefine it for a while
            const { windowElement } = this;

            if (nonAviSiteModal) {
                this.windowElement = this.getSiteEditModalId_(GSLB.nonAviSitesConfigPropName);
            }

            super.dismiss(true);

            this.windowElement = windowElement;
        }
    }

    angular.extend(GSLB.prototype, {
        objectName,
        windowElement: `${objectName}-edit`,
    });

    //config property names for the regular and non-avi sites lists
    GSLB.sitesConfigPropName = 'sites';
    GSLB.nonAviSitesConfigPropName = 'third_party_sites';

    return GSLB;
}]);
