/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Returns a parsed copy of the bindings object, stripping away functions defined as arrays.
 * @param {string} componentId - messageId.
 * @param {Object} bindings - Object of component bindings.
 * @return {Object}
 */
angular.module('aviApp').factory('ComponentBindingsSetter', ['$injector', function($injector) {
    return function(componentId, bindings) {
        const camelId = $.camelCase(componentId);
        const [{ bindToController }] = $injector.get(`${camelId}Directive`);

        _.each(bindings, (value, key, hash) => {
            if (angular.isUndefined(bindToController[$.camelCase(key)])) {
                console.warn(`"${key}" binding not defined in component.`);

                return;
            }

            const bindingSymbol = bindToController[$.camelCase(key)][0];

            if (bindingSymbol === '&' && Array.isArray(value) && value.length) {
                if ($injector.annotate(value).length &&
                        angular.isFunction(value[value.length - 1])) {
                    hash[key] = value[value.length - 1];
                }
            }
        });

        return bindings;
    };
}]);
