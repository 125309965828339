/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import './waf-policy-psm-rule.less';
import {
    WAF_PSMRULE_SUM_EVALUATED_METRIC,
    WAF_PSMRULE_SUM_MATCHED_METRIC,
} from '../../../../../waf.constants';

enum EnableState {
    Disabled = 0,
    Enabled = 1,
}

/**
 * @ngdoc component
 * @name WafPolicyPsmRuleComponent
 * @module waf/WafPolicyPsmRuleComponent
 * @desc Component for displaying a WAF Policy Whitelist rule in the WAF Policy modal.
 * @param onDelete - Called when the user clicks the delete rule button.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rule',
    templateUrl: './waf-policy-psm-rule.component.html',
})
export class WafPolicyPsmRuleComponent {
    @Input() rule: WafPSMRuleConfigItem;
    @Input() preventEdit: boolean;
    @Input() metricsData: WafPolicyPsmGroupMetricSumData;

    @Output() onDelete: EventEmitter<void> = new EventEmitter();
    @Output() onEdit: EventEmitter<void> = new EventEmitter();

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    getEnableState(): EnableState {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable(): void {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Click handler for deleting a whitelist rule.
     */
    handleDelete($event: MouseEvent): void {
        $event.stopPropagation();
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a whitelist rule.
     */
    handleEdit($event: MouseEvent): void {
        $event.stopPropagation();
        this.onEdit.emit();
    }

    /**
     * Returns the sum for WAF_PSMRULE_SUM_EVALUATED_METRIC.
     */
    get evaluatedCount(): number {
        return this.metricsData[WAF_PSMRULE_SUM_EVALUATED_METRIC] || NaN;
    }

    /**
     * Returns the sum for WAF_PSMRULE_SUM_MATCHED_METRIC.
     */
    get matchedCount(): number {
        return this.metricsData[WAF_PSMRULE_SUM_MATCHED_METRIC] || NaN;
    }
}
