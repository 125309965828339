/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name orderedGridRowModifier
 * @description
 *     Combines functionality for both expanding a row and dragging-and-dropping rows within the
 *     grid.
 * @param {string} index - Index of the row.
 * @param {boolean} disableDrag - If evalulated to true, disables dragging and hides the handle
 *     icon.
 * @param {boolean} expanded - If evaluated to true, the row is expanded.
 * @param {string} expandTemplate - Template string that is shown on expand.
 * @param {Object} row - Row object.
 * @param {Object} config - OrderedGrid config object.
 */
const rowClass = 'ordered-grid__body__row';
const expandRowClass = 'ordered-grid__body__row--expanded';
const expandTemplateClass = 'ordered-grid__body__row--expanded-template';

class OrderedGridRowModifier {
    constructor($timeout, $compile) {
        this.restrict = 'A';
        this.scope = {
            index: '@',
            disableDrag: '<',
            expanded: '<',
            expandTemplate: '<',
            row: '<',
            config: '<',
        };

        this._$timeout = $timeout;
        this._$compile = $compile;
    }

    link($scope, $elem) {
        /**
         * Drag-and-drop functions
         */
        const row = $elem[0];
        let mouseTarget = null;

        $elem.attr('draggable', $scope.disableDrag ? null : true);
        row.addEventListener('mousedown', event => mouseTarget = event.target, false);

        const dragstart = event => {
            if (row.contains(mouseTarget)) {
                event.dataTransfer.setData('text/plain', $scope.index);
                event.dataTransfer.effectAllowed = 'move';
                // There's a browser bug that fires the dragend event if the element node is
                // changed immediately, which is why .addClass() is wrapped in a $timeout.
                this._$timeout(() => $elem.addClass('hidden'), 0);
            } else {
                event.preventDefault();
            }
        };

        const dragend = event => {
            $elem.removeClass('hidden');
            event.stopPropagation();
        };

        /**
         * Events on the element being dragged.
         */
        row.addEventListener('dragstart', dragstart, false);
        row.addEventListener('dragend', dragend, false);

        $scope.$watch('disableDrag', (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            }

            $elem.attr('draggable', newVal ? null : true);
        });

        /**
         * Expander functions
         */
        const compiledExpandTemplate = this._$compile(
            `<div class="${rowClass} ${expandRowClass} ${expandTemplateClass}">
                ${$scope.expandTemplate}
            </div>`,
        )($scope);

        $scope.$watch('expanded', (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            }

            if (newVal) {
                $elem.addClass(expandRowClass);
                compiledExpandTemplate
                    .insertAfter($elem)
                    .hide()
                    .slideDown('fast');
            } else {
                $elem.removeClass(expandRowClass);
                compiledExpandTemplate.remove();
            }
        });

        $scope.$on('$destroy', () => {
            row.removeEventListener('dragstart', dragstart);
            row.removeEventListener('dragend', dragend);
        });
    }
}

angular
    .module('aviApp')
    .directive(
        'orderedGridRowModifier', [
            '$timeout',
            '$compile',
            ($timeout, $compile) => new OrderedGridRowModifier($timeout, $compile),
        ],
    );
