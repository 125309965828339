/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './http-status-match.less';

class HTTPStatusMatchController {
    constructor($element, Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
        $element.addClass('http-status-match');
    }
}

HTTPStatusMatchController.$inject = ['$element', 'Schema', 'Regex'];

/**
 * @ngdoc component
 * @requires httpStatusMatch
 * @param {HTTPStatusMatch} match - HTTPStatusMatch instance.
 * @param {string} hideMatchCriteria - If not empty, will hide "IS" and "IS NOT" selector.
 * @description
 *      Component for configuring HTTPStatusMatch instance.
 */
angular.module('aviApp').directive('httpStatusMatch', function() {
    return {
        restrict: 'E',
        controller: HTTPStatusMatchController,
        controllerAs: '$ctrl',
        scope: {
            match: '=',
            hideMatchCriteria: '@?',
        },
        templateUrl: 'src/components/common/http-status-match/http-status-match.html',
    };
});
