/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('avi/scripts').factory('DataScriptSetCollection', [
'Collection', 'DataScriptSet',
function(Collection, DataScriptSet) {
    /**
     * @class DataScriptSetCollection
     * @constructor
     * @memberOf module:avi/scripts
     * @extends module:avi/dataModel.Collection
     */
    class DataScriptSetCollection extends Collection {}

    angular.extend(DataScriptSetCollection.prototype, {
        objectName_: 'vsdatascriptset',
        itemClass_: DataScriptSet,
        windowElement_: 'datascriptset-modal',
    });

    return DataScriptSetCollection;
}]);
