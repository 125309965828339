/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Tags input field
 *
 * Example of usage:
 * <input-tags
 *      ng-model="action.value.tags"
 *      placeholder="Select Pool"
 *      use-as-value="value"
 *      ></input-tags>
 *
 *  Providing `use-as-value` attribute gives an option to generate array of
 *  objects instead of strings and the value
 *  is whithin the property under that name
 *
 */
angular.module('aviApp').directive('inputTags', ['$timeout', function($timeout) {
    return {
        scope: {
            ngModel: '=',
            ngChange: '&',
            ngDisabled: '=',
            ngRequired: '=',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/input-tags.html',
        require: 'ngModel',
        link(scope, elm, attr, ctrl) {
            scope.attr = attr;
            scope.newItem = '';

            /**
             * Returns true if something selected
             * @returns {boolean}
             */
            scope.isNotEmpty = function() {
                return scope.ngModel instanceof Array ? scope.ngModel.length > 0 : scope.ngModel;
            };

            $timeout(function() {
                scope.validate();
            });

            // Focus the input when container clicked
            $('.dropdown-container').on('mousedown', function() {
                $timeout(function() {
                    $(elm).find('input').trigger('focus');
                });
            });
            $(elm).find('input').on('focusout', function() {
                scope.add();
            }).on('keydown', function(event) {
                // When backspace clicked in an empty input
                // Start editing previous element
                if (event.keyCode == 8 && !scope.newItem) {
                    const o = scope.ngModel[scope.ngModel.length - 1];

                    scope.newItem = attr.useAsValue ? o[attr.useAsValue] : o;
                    scope.ngModel.splice(scope.ngModel.length - 1, 1);
                    scope.$$phase || scope.$apply();
                }

                // Resize input according to it's content
                const self = this;

                $(self).attr('size', scope.newItem.length + 1);
            });
            elm.find('input').on('focus keydown', function() {
                elm.find('.placeholder').hide();
            }).on('blur', function() {
                elm.find('.placeholder').show();
            });

            /**
             * Tries to get the name of the option
             * @param value - option value
             * @returns {string}
             */
            scope.getLabel = function(option) {
                return attr.useAsValue ? option[attr.useAsValue] : option;
            };

            /**
             * Adds the item
             * @param item {object}
             */
            scope.add = function() {
                if (!scope.newItem) {
                    return;
                }

                // Make sure ngModel is present and is an array
                if (!(scope.ngModel instanceof Array)) {
                    scope.ngModel = [];
                }

                if (attr.useAsValue) {
                    const o = {};

                    o[attr.useAsValue] = scope.newItem;
                    scope.ngModel.push(o);
                } else {
                    scope.ngModel.push(scope.newItem);
                }

                scope.newItem = '';
                scope.validate();
            };

            scope.validate = function() {
                if (scope.ngRequired) {
                    ctrl.$setValidity('required', scope.isNotEmpty());
                }
            };

            /**
             * Removes one of the selected items (multiple select only)
             * @param index {index} - Element's index
             */
            scope.remove = function(index) {
                scope.ngModel.splice(index, 1);
                scope.validate();
            };
        },
    };
}]);
