/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const mixin = {
    /**
     * Row id of opened row. Empty string when not set.
     * @type {string}
     */
    expandedRowId: '',

    /**
     * Returns true for row with expanded details view (drawer).
     * @param {string} rowId
     * @returns {boolean}
     */
    rowIsExpanded(rowId) {
        return this.expandedRowId === rowId;
    },

    /**
     * Event handler for row expander (drawer) click.
     * @param {*} row
     */
    toggleRowExpander(row) {
        const rowId = this.rowId(row);

        this.expandedRowId = this.expandedRowId === rowId ? '' : rowId;
    },

    /**
     * Returns true for expandable rows. If row is not passed will check whether grid config
     * is asking for this feature at all.
     * @param {*=} row
     * @returns {boolean}
     */
    rowExpanderAvailable(row) {
        const
            { config } = this,
            { expandedContainerTemplate: withTemplate } = config;

        if (!withTemplate) {
            return false;
        }

        if (angular.isUndefined(row)) {
            return true;
        }

        //TODO add try..catch
        return !angular.isFunction(config.expanderDisabled) ||
                !config.expanderDisabled.call(undefined, row);
    },
};

function gridCtrlRowExpanderMixin(classMixin) {
    return GridCtrl => classMixin(GridCtrl, angular.copy(mixin));
}

gridCtrlRowExpanderMixin.$inject = ['classMixin'];

/**
 * @ngdoc service
 * @name gridCtrlRowExpanderMixin
 * @type {Function}
 * @mixin
 * @desc
 *
 *     Mixin with grid table row expander tools.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi')
    .factory('gridCtrlRowExpanderMixin', gridCtrlRowExpanderMixin);
