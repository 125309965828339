/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('CertificateManagementListController', [
'$scope', 'CertificateManagementCollection', 'CRUDGridConfig',
function($scope, CertificateManagementCollection, CRUDGridConfig) {
    const vm = this;

    vm.collection = new CertificateManagementCollection();

    vm.gridConfig = new CRUDGridConfig();
    vm.gridConfig.collection = this.collection;

    vm.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }];

    vm.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];
}]);
