/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('PasswordChangeController', [
'$scope', '$state', 'Auth', '$stateParams', 'appDefaultState',
function($scope, $state, Auth, $stateParams, appDefaultState) {
    // error handling
    $scope.error = '';
    $scope.busy = false;
    $scope.data = {
        username: $stateParams.username,
        token: $stateParams.token,
    };
    $scope.Auth = Auth;

    // login page
    $scope.passwordChange = function() {
        if ($scope.busy) {
            return;
        }

        if ($scope.data.password != $scope.data.confirm_password) {
            $scope.error = 'Passwords don\'t match';

            return;
        }

        $scope.busy = true;
        $scope.error = '';

        Auth.passwordChange(
            $scope.data.token,
            $scope.data.username,
            $scope.data.password,
        )
            .then(() => {
                $scope.busy = false;
                $state.go(appDefaultState);
            }, () => {
                $scope.busy = false;
                $scope.error = 'Password change failed, invalid token';
            });
    };

    /**
     * Used to match password and password confirmation on the account screen
     * @return {boolean}
     */
    $scope.passwordsMatch = function() {
        const { password, confirm_password: confirmPassword } = $scope.data;

        return password === confirmPassword;
    };

    /**
     * Used to highlight passwords if they don't match
     */
    $scope.checkPasswords = function() {
        if ($scope.passwordsMatch()) {
            $('.change-password input[type=password]').removeClass('ng-invalid');
        } else {
            $('.change-password input[type=password]').addClass('ng-invalid');
        }
    };
}]);
