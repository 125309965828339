/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('VirtualServicePoolNoNetworkResolutionController', [
'$scope', '$controller',
function($scope, $controller) {
    //$scope.NetworkResolution comes from NetworkResolutionController.
    $controller('NetworkResolutionController', { $scope });
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
     * Init function called by AviModal when modal is opened. Gets vs_runtime object and pool
     * object.
     */
    $scope.init = function() {
        $scope.error = null;
        $scope.data = {
            vs_runtime: $scope.NetworkResolution.vs_runtime,
            loading: true,
        };
        $scope.busy = true;
        $scope.editable.setPristine();

        // Need runtime object just to get the recomendation
        const slug = $scope.editable.data.config.url.slug();

        $scope.NetworkResolution.getRuntime(slug).then(function() {
            $scope.data.vs_runtime = $scope.NetworkResolution.vs_runtime;

            return $scope.NetworkResolution.loadPool($scope.data.vs_runtime);
        }).then(function() {
            $scope.pool = $scope.NetworkResolution.pool;
        }).finally(function() {
            $scope.data.loading = false;
            $scope.busy = false;
            $scope.error = $scope.NetworkResolution.error;
        });
    };

    /**
     * Called when user selects network from network dropdown. Loads the network and populates list
     * of subnets and networkruntime.
     */
    $scope.networkChanged = function() {
        $scope.data.loading = true;
        $scope.busy = true;

        const networkSlug = $scope.data.network_ref.slug();

        $scope.NetworkResolution.loadNetwork(networkSlug).then(function() {
            $scope.network = $scope.NetworkResolution.network;
        }).finally(function() {
            $scope.error = $scope.NetworkResolution.error;
            $scope.data.loading = false;
            $scope.busy = false;
        });
    };

    /**
     * Called when user hits Save button. Saves Pool along with selected network.
     */
    $scope.submit = function() {
        $scope.busy = true;
        $scope.savePool().then(function() {
            return $scope.saveNetwork();
        }).then(function() {
            return $scope.editable.disableAndEnable();
        }).then(function() {
            return $scope.editable.checkForIssues();
        })
            .then(function() {
                $scope.editable.dismiss(true);
            })
            .catch(function(rsp) {
                $scope.error = rsp.data.error;
            })
            .finally(function() {
                $scope.busy = false;
            });
    };
}]);
