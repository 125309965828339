/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name wafRuleGroups
 * @description
 *     Handles a list of WAF groups.
 * @param {string} [placeholder=] - String to be displayed when no groups exist.
 */
class WafRuleGroupsController {
    /**
     * Returns true if the type of group is not 'crs_groups', where editing of the rules is
     * allowed.
     * @return {boolean}
     */
    allowEditing() {
        const sampleWafGroup = _.sample(this.groups.config);

        return _.isEmpty(sampleWafGroup) ?
            this.type !== 'crs_groups' :
            sampleWafGroup.allowEditing();
    }

    /**
     * Called when the Create Group button is clicked.
     * @param {WafRuleGroupConfig=} group - If defined, create the group above this group.
     */
    handleAddGroup(group) {
        this.onAddGroup({ group });
    }

    /**
     * Called to remove a group from the WafPolicy.
     * @param {WafRuleGroupConfig} group.
     */
    handleRemoveGroup(group) {
        this.onRemoveGroup({ group });
    }

    /**
     * Handler for the drag and drop. Calls onDragAndDrop.
     * @param {number} index - Index of the new position in the list to move to.
     * @param {Object} group - Group data. dndList does not return the instance of the group
     *     class, only the data.
     * @return {boolean} dndList requires returning true to know to handle the data management
     *     manually.
     */
    handleDragAndDrop(index, group) {
        this.onDragAndDrop({ index, group });

        return true;
    }
}

angular.module('aviApp').component('wafRuleGroups', {
    controller: WafRuleGroupsController,
    bindings: {
        groups: '<',
        onAddGroup: '&',
        onRemoveGroup: '&',
        onDragAndDrop: '&',
        type: '@',
        placeholder: '@',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule-groups/waf-rule-groups.html',
});
