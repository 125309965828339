/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name InventoryDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *    Can be used for all list APIs of `inventory` type.
 *
 *    Translates `field_` request property into concatenated string of sources to be included
 *    into inventory response (such as `runtime`, `health_score`, etc).
 *
 *    Processes all `alert` Item data through the {@link itemAlertDataTransform | specific parser}.
 *
 */
angular.module('aviApp').factory('InventoryDataTransformer', [
'ListDataTransformer', 'itemAlertDataTransform',
function(ListDataTransformer, itemAlertDataTransform) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends ListDataTransformer
     */
    function InventoryDataTransformer(args) {
        InventoryDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(InventoryDataTransformer, ListDataTransformer);

    /**
     * Prepares request parameters before passing them to DataTransport.
     * @param {ListDataTransportRequestParams} requestParams - Takes care of special properties such
     *     as `fields_`, `offset_`, `limit_`.
     * @returns {ListDataTransportRequestParams}
     * @override
     * @public
     */
    InventoryDataTransformer.prototype.getRequestConfig = function(requestParams) {
        const params =
            InventoryDataTransformer.superclass.getRequestConfig.call(this, requestParams);

        if ('fields_' in params && Array.isArray(params['fields_'])) {
            params['include'] = params['fields_'].join(',');
        }

        delete params['fields_'];

        return params;
    };

    /**
     * Parses `alert` data of Items in a list to provide a consistent alert properties all over the
     * app.
     * @param {ListDataTransportResponse} resp
     * @param {ListDataTransportRequestParams} request
     * @returns {ListDataTransportResponse}
     * @override
     * @public
     */
    InventoryDataTransformer.prototype.processResponse = function(resp, request) {
        resp = this.responseListOffsetControl_(resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results) &&
            resp.data.results.length) {
            const
                { results } = resp.data,
                sampleRow = _.sample(results);

            if ('alert' in sampleRow) {
                results.forEach(
                    ({ alert }) => itemAlertDataTransform(alert),
                );
            }
        }

        return resp;
    };

    return InventoryDataTransformer;
}]);
