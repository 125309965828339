/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2020] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  IPAMProfile
 * @description
 *     IPAMProfile item. Also responsible for making requests to
 *     '/api/ipamdnsproviderprofiledomainlist/' and '/api/ipamdnsproviderprofilenetworklist/' to
 *     get network and domain lists for IPAM profile configuration. When creating a VS, a GET
 *     request to '/api/ipamdnsproviderprofilenetworklist/' is used to retrieve networks from the
 *     IPAM Profile configuration.
 */
const IPAMDNS_TYPE_INTERNAL = 'IPAMDNS_TYPE_INTERNAL';
const IPAMDNS_TYPE_INTERNAL_DNS = 'IPAMDNS_TYPE_INTERNAL_DNS';
const IPAMDNS_TYPE_INFOBLOX = 'IPAMDNS_TYPE_INFOBLOX';
const IPAMDNS_TYPE_INFOBLOX_DNS = 'IPAMDNS_TYPE_INFOBLOX_DNS';
const IPAMDNS_TYPE_AWS = 'IPAMDNS_TYPE_AWS';
const IPAMDNS_TYPE_AWS_DNS = 'IPAMDNS_TYPE_AWS_DNS';
const IPAMDNS_TYPE_OPENSTACK = 'IPAMDNS_TYPE_OPENSTACK';
const IPAMDNS_TYPE_GCP = 'IPAMDNS_TYPE_GCP';
const IPAMDNS_TYPE_CUSTOM = 'IPAMDNS_TYPE_CUSTOM';
const IPAMDNS_TYPE_CUSTOM_DNS = 'IPAMDNS_TYPE_CUSTOM_DNS';
const IPAMDNS_TYPE_AZURE = 'IPAMDNS_TYPE_AZURE';
const IPAMDNS_TYPE_AZURE_DNS = 'IPAMDNS_TYPE_AZURE_DNS';
const IPAMDNS_TYPE_OCI = 'IPAMDNS_TYPE_OCI';

/**
 * @typedef {Object} AvailableInfobloxSubnets - Set of available infoblox v4 and v6 subnets.
 * @property {?IpAddrPrefix[]} v4
 * @property {?IpAddrPrefix[]} v6
 */

/**
 * @typedef {Object} InfobloxSubnet - Set of infoblox v4 and v6 subnets.
 * @property {?IpAddrPrefix} subnet
 * @property {?IpAddrPrefix} subnet6
 */

const IPAMProfileFactory = (
    Item,
    $http,
    $q,
    UpdatableItem,
    secretStubStr,
    IpamDnsAwsProfileConfig,
    CustomIpamDnsProfile,
) => {
    class IPAMProfile extends Item {
        /**
         * Adds empty string to usable_domains array inside config object.
         * @param {Object} config
         * @static
         */
        static addDomain(config) {
            if (!Array.isArray(config.usable_domains)) {
                config.usable_domains = [];
            }

            config.usable_domains.push('');
        }

        /**
         * Removes element from usable_domains array inside config object.
         * @param {number} index
         * @param {Object} config
         */
        static removeDomain(index, config) {
            if (Array.isArray(config.usable_domains)) {
                config.usable_domains.splice(index, 1);
            }
        }

        /**
         * Returns IpamDnsType enum types hash.
         */
        static get typeHash() {
            return {
                IPAMDNS_TYPE_OCI,
                IPAMDNS_TYPE_INTERNAL,
                IPAMDNS_TYPE_INTERNAL_DNS,
                IPAMDNS_TYPE_INFOBLOX,
                IPAMDNS_TYPE_INFOBLOX_DNS,
                IPAMDNS_TYPE_AWS,
                IPAMDNS_TYPE_AWS_DNS,
                IPAMDNS_TYPE_OPENSTACK,
                IPAMDNS_TYPE_GCP,
                IPAMDNS_TYPE_CUSTOM,
                IPAMDNS_TYPE_CUSTOM_DNS,
                IPAMDNS_TYPE_AZURE,
                IPAMDNS_TYPE_AZURE_DNS,
            };
        }

        /**
         * Gets list of domains for a specific cloud. Since the domains don't come prepended
         * with a '.', it is done here manually.
         * @param  {string} cloudRef - Cloud URL string.
         * @return {ng.$q.promise<string[]>}
         */
        static getIpamDomains(cloudRef) {
            const api = `/api/ipamdnsproviderprofiledomainlist/?cloud_uuid=${cloudRef.slug()}`;

            return $http.get(api)
                .then(({ data }) => data.domains.map(domainName => `.${domainName}`));
        }

        /**
         * Get list of profile property config names.
         * @return {string[]}
         * @protected
         */
        static getProfileConfigPropNames_() {
            const propNameValues = _.values(IPAMProfile.typeToConfig);

            return _.uniq(propNameValues);
        }

        /**
        * Return list of credential property names of infoblox profile.
        * @param {string} type - 'IPAMDNS_TYPE_INFOBLOX' or 'IPAMDNS_TYPE_INFOBLOX_DNS'
        * @return {string[]}
        */
        static getInfobloxProfileFingerPrintPropNames(type) {
            const propNames = ['ip_address', 'username', 'password'];

            if (type === IPAMDNS_TYPE_INFOBLOX) {
                propNames.push('network_view');
            } else if (type === IPAMDNS_TYPE_INFOBLOX_DNS) {
                propNames.push('dns_view');
            }

            return propNames;
        }

        /**
         * Creates a hash of v4 and v6 subnets.
         * @param {InfobloxSubnet[]} usableSubnets - List of configured usable subnets.
         * @return {AvailableInfobloxSubnets}
         */
        static createInfobloxUsableSubnetsHash(usableSubnets = []) {
            const subnetsHash = {
                v4: [],
                v6: [],
            };

            usableSubnets.forEach(usableSubnet => {
                const { subnet, subnet6 } = usableSubnet;

                if (subnet) {
                    subnetsHash.v4.push(subnet);
                }

                if (subnet6) {
                    subnetsHash.v6.push(subnet6);
                }
            });

            return subnetsHash;
        }

        constructor(args) {
            super(args);

            const config = this.getConfig();

            /**
             * Config may not be present when creating a new instance of IPAMProfile.
             */
            if (angular.isObject(config)) {
                if (!(config.aws_profile instanceof IpamDnsAwsProfileConfig)) {
                    config.aws_profile = new IpamDnsAwsProfileConfig({
                        data: {
                            config: config.aws_profile,
                        },
                    });
                }
            }
        }

        /**
         * @override
         */
        transformAfterLoad() {
            const config = this.getConfig();

            if (!_.isEmpty(config.aws_profile)) {
                const awsConfig = this.getAwsProfile();

                config.aws_profile = new IpamDnsAwsProfileConfig({
                    data: {
                        config: awsConfig,
                    },
                    parentId: this.id,
                });
            }
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            if (config.aws_profile && config.aws_profile instanceof IpamDnsAwsProfileConfig) {
                config.aws_profile.beforeEdit();
            }

            if (!('custom_profile' in config)) {
                config.custom_profile = {};
            }

            if (!('dynamic_params' in config.custom_profile)) {
                config.custom_profile.dynamic_params = [];
            }

            switch (this.getType()) {
                case IPAMDNS_TYPE_GCP:
                    this.setDeriveFromControllerInGcpProfile_();
                    break;

                case IPAMDNS_TYPE_INFOBLOX:
                case IPAMDNS_TYPE_INFOBLOX_DNS: {
                    const profileConfig = this.getProfileConfig();

                    if (!angular.isArray(profileConfig.extensible_attributes)) {
                        profileConfig.extensible_attributes = [];
                    }
                }
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            const { type } = config;

            if (type !== IPAMDNS_TYPE_INFOBLOX && type !== IPAMDNS_TYPE_INFOBLOX_DNS) {
                delete config.infoblox_profile;
            }

            if (type !== IPAMDNS_TYPE_INTERNAL && type !== IPAMDNS_TYPE_INTERNAL_DNS) {
                delete config.internal_profile;
            }

            if (type !== IPAMDNS_TYPE_AWS && type !== IPAMDNS_TYPE_AWS_DNS) {
                delete config.aws_profile;
            }

            if (type !== IPAMDNS_TYPE_OPENSTACK) {
                delete config.openstack_profile;
            }

            if (type !== IPAMDNS_TYPE_GCP) {
                delete config.gcp_profile;
            }

            if (type !== IPAMDNS_TYPE_CUSTOM && type !== IPAMDNS_TYPE_CUSTOM_DNS) {
                delete config.custom_profile;
            }

            if (type.indexOf(IPAMDNS_TYPE_AZURE) === -1) {
                delete config.azure_profile;
            }

            switch (config.type) {
                case IPAMDNS_TYPE_AWS:
                    config.aws_profile.clearUsableDomains();
                    config.aws_profile = config.aws_profile.dataToSave();
                    break;

                case IPAMDNS_TYPE_AWS_DNS:
                    config.aws_profile.clearUsableNetworks();
                    config.aws_profile = config.aws_profile.dataToSave();
                    break;

                case IPAMDNS_TYPE_INFOBLOX: {
                    const infoblox = config.infoblox_profile;

                    delete infoblox.usable_domains;

                    if (infoblox.usable_alloc_subnets && infoblox.usable_alloc_subnets.length) {
                        infoblox.usable_alloc_subnets = infoblox.usable_alloc_subnets.filter(
                            ({ subnet, subnet6 }) => subnet || subnet6,
                        );
                    }

                    break;
                }

                case IPAMDNS_TYPE_INFOBLOX_DNS:
                    delete config.infoblox_profile.usable_alloc_subnets;
                    break;

                case IPAMDNS_TYPE_AZURE: {
                    const { azure_profile: azureProfile } = config;

                    delete azureProfile.usable_domains;
                    break;
                }

                case IPAMDNS_TYPE_AZURE_DNS: {
                    const { azure_profile: azureProfile } = config;

                    delete azureProfile.usable_network_uuids;
                    break;
                }

                case IPAMDNS_TYPE_GCP: {
                    const { gcp_profile: gcpProfile } = config;

                    if (!gcpProfile.deriveFromController_) {
                        delete gcpProfile.network_host_project_id;
                        delete gcpProfile.se_project_id;
                        delete gcpProfile.region_name;
                        delete gcpProfile.vpc_network_name;
                    }

                    delete gcpProfile.deriveFromController_;
                }
            }

            return config;
        }

        /**
         * Called on IPAM Profile type change. Resets config fields.
         */
        onTypeChange() {
            this.resetProfiles_();
            this.clearNSRecords();
            this.clearUsableNetworks();
        }

        /**
         * Reset profiles. Called on IPAM type change.
         * @protected
         */
        resetProfiles_() {
            const
                config = this.getConfig(),
                defaultConfigHash = this.getDefaultConfig_(),
                { profileConfigPropNames } = IPAMProfile;

            profileConfigPropNames.forEach(propName => {
                const defaultConfig = defaultConfigHash[propName] || {};
                let profile = defaultConfig;

                switch (propName) {
                    case 'aws_profile':
                        profile = new IpamDnsAwsProfileConfig({
                            data: {
                                config: defaultConfig,
                            },
                            parentId: this.id,
                        });

                        profile.beforeEdit();

                        break;

                    case 'gcp_profile':
                        this.setDeriveFromControllerInGcpProfile_();

                        break;

                    case 'infoblox_profile':
                        profile.extensible_attributes = [];

                        break;
                }

                config[propName] = profile;
            });
        }

        /**
         * Sets deriveFromController_ flag to indicate
         * whether GCP Profile configuration can be derived from Controller.
         * @protected
         */
        setDeriveFromControllerInGcpProfile_() {
            const gcpProfile = this.getProfileConfig();

            if (!_.isEmpty(gcpProfile)) {
                gcpProfile.deriveFromController_ = _.intersection([
                    'network_host_project_id',
                    'se_project_id',
                    'region_name',
                    'vpc_network_name',
                ], Object.keys(gcpProfile)).length > 0;
            }
        }

        /**
         * Called on change when user selects between credentials used for Azure configuration.
         */
        clearAzureCredentials() {
            const { azure_profile: azureProfile } = this.getConfig();

            if (!_.isEmpty(azureProfile)) {
                delete azureProfile.azure_userpass;
                delete azureProfile.azure_serviceprincipal;
            }
        }

        /**
         * Called on change when user selects a different Azure virtual network. Clears existing
         * usable networks and domains since they are dependent on the virtual network.
         */
        clearAzureUsableNetworksAndDomains() {
            const { azure_profile: azureProfile } = this.getConfig();

            if (angular.isArray(azureProfile.usable_network_uuids)) {
                azureProfile.usable_network_uuids.length = 0;
            }

            if (angular.isArray(azureProfile.usable_domains)) {
                azureProfile.usable_domains.length = 0;
            }
        }

        /**
         * Adds DNS service domain entry for internal profiles.
         */
        addNsRecord() {
            const config = this.getConfig();

            config.internal_profile = config.internal_profile || {};

            const internal = config.internal_profile;

            internal.dns_service_domain = internal.dns_service_domain || [];
            internal.dns_service_domain.push({});
        }

        /**
         * Removes all Internal Profile domains.
         */
        clearNSRecords() {
            const ip = this.getConfig().internal_profile;

            if (angular.isObject(ip)) {
                delete ip.dns_service_domain;
            }
        }

        /**
         * Adds usable_subnet entry to Infoblox profiles.
         */
        addInfobloxUsableSubnet() {
            const config = this.getConfig();

            config.infoblox_profile = config.infoblox_profile || {};

            const infoblox = config.infoblox_profile;

            infoblox.usable_alloc_subnets = infoblox.usable_alloc_subnets || [];
            infoblox.usable_alloc_subnets.push({ subnet: undefined, subnet6: undefined });
        }

        /**
         * Removes current item at index from IpamDnsInfobloxProfile::usable_alloc_subnets.
         * @param {number} index
         */
        removeInfobloxUsableSubnet(index) {
            const infoblox = this.getProfileConfig();

            if (Array.isArray(infoblox.usable_alloc_subnets)) {
                infoblox.usable_alloc_subnets.splice(index, 1);
            }
        }

        /**
         * Adds usable_domains entry to Infoblox profiles.
         */
        addUsableDomain() {
            const config = this.getConfig();

            config.infoblox_profile = config.infoblox_profile || {};

            const infoblox = config.infoblox_profile;

            infoblox.usable_domains = infoblox.usable_domains || [];
            infoblox.usable_domains.push('');
        }

        /**
         * Removes usable_domains entry to Infoblox profiles.
         * @param {number} index
         */
        removeInfobloxUsableDomain(index) {
            IPAMProfile.removeDomain(index, this.getProfileConfig());
        }

        /**
         * Adds usable_network_refs entry to the profile based on the IPAM type.
         * @param {string} prop - Usable network property.
         */
        addUsableNetwork(prop) {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];

            config[configProperty] = config[configProperty] || {};

            const configObject = config[configProperty];

            configObject[prop] = configObject[prop] || [];
            configObject[prop].push(undefined);
        }

        /**
         * Removes a usable_network_ref entry from the profile.
         * @param {string} prop - Usable network property.
         * @param {number=} index - Index of the network to remove.
         */
        removeUsableNetwork(prop, index = 0) {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];
            const configObject = config[configProperty];

            configObject[prop].splice(index, 1);
        }

        /**
         * Removes all Internal Profile networks.
         */
        clearUsableNetworks() {
            const internal = this.getConfig().internal_profile;

            if (angular.isObject(internal)) {
                delete internal.usable_network_refs;
            }
        }

        /**
         * Adds empty usable_network_refs entry to Google Cloud profiles.
         */
        addGCPUsableNetwork() {
            const config = this.getConfig();

            config.gcp_profile = config.gcp_profile || {};

            const gcpProfile = config.gcp_profile;

            gcpProfile.usable_network_refs = gcpProfile.usable_network_refs || [];
            gcpProfile.usable_network_refs.push(undefined);
        }

        /**
         * Removes Network ref from GCP network list.
         * @param {number=} index
         */
        removeGCPUsableNetwork(index = 0) {
            const { gcp_profile } = this.getConfig();

            gcp_profile.usable_network_refs.splice(index, 1);
        }

        /**
         * Adds usable domain to Azure domain list.
         */
        addAzureUsableDomain() {
            const config = this.getConfig();

            config.azure_profile = config.azure_profile || {};

            const azureProfile = config.azure_profile;

            azureProfile.usable_domains = azureProfile.usable_domains || [];
            azureProfile.usable_domains.push(undefined);
        }

        /**
         * Removes usable domain from Azure domain list.
         * @param {number=} index
         */
        removeAzureUsableDomain(index = 0) {
            IPAMProfile.removeDomain(index, this.getConfig().azure_profile);
        }

        /**
         * Clears proxy configuration.
         */
        clearProxyConfiguration() {
            delete this.getConfig().proxy_configuration;
        }

        /**
         * @override
         * Also remove proxy_pass.
         */
        getSecretStubPayload_(payload, key = 'uuid') {
            if (this.id && (!payload.password || payload.password === secretStubStr)) {
                payload[key] = this.id;
                delete payload.username;
                delete payload.password;
                delete payload.proxy_pass;
            }

            return payload;
        }

        /**
         * Adds new element to custom_profile.dynamic_params array.
         */
        addCustomDynamicParam() {
            const config = this.getConfig();

            config.custom_profile.dynamic_params.push(CustomIpamDnsProfile.createCustomParam());
        }

        /**
         * Removes element from custom_profile.dynamic_params array at index.
         * @param {number} index
         */
        removeCustomDynamicParam(index) {
            const config = this.getConfig();

            config.custom_profile.dynamic_params.splice(index, 1);
        }

        /**
         * Removes element from custom_profile.usable_domains array at index.
         * @param {number} index
         */
        removeCustomProfileDomain(index) {
            IPAMProfile.removeDomain(index, this.getConfig().custom_profile);
        }

        /**
         * Adds empty domain to custom_profile.usable_domains.
         */
        addCustomProfileDomain() {
            const config = this.getConfig();

            IPAMProfile.addDomain(config.custom_profile);
        }

        /**
         * Returns an object of params used for Infoblox requests. If editing, includes the
         * profile ID.
         * @return {Object}
         */
        _getInfobloxParams() {
            const config = this.getConfig();
            const infoblox = config.infoblox_profile;
            const ip = infoblox.ip_address.addr;
            const defaultInfoblox = this.getDefaultConfig_()['infoblox_profile'];
            const params = angular.extend(defaultInfoblox, infoblox, { ip_address: ip });

            return this.getSecretStubPayload_(params, 'ipamdnsprovider_uuid');
        }

        /**
         * Returns an object of params used for Azure requests. If editing, includes the profile
         * ID.
         * @return {Object}
         */
        _getAzureParams() {
            const { azure_profile: azureProfile } = this.getConfig();
            const {
                azure_userpass: userpass,
                azure_serviceprincipal: serviceprincipal,
            } = azureProfile;

            const params = {
                subscription_id: azureProfile.subscription_id,
            };

            if (!_.isEmpty(azureProfile.virtual_network_ids)) {
                [params.vnet_id] = azureProfile.virtual_network_ids;
            }

            if (!_.isEmpty(userpass)) {
                angular.extend(params, userpass);

                if (params.password === secretStubStr) {
                    params.ipamdnsprovider_uuid = this.id;
                    delete params.password;
                }
            } else {
                angular.extend(params, serviceprincipal);

                if (params.authentication_token === secretStubStr) {
                    params.ipamdnsprovider_uuid = this.id;
                    delete params.authentication_token;
                }

                // Workaround for inconsistent API for now.
                params.authentication_key = params.authentication_token;
            }

            return params;
        }

        /**
         * Returns an object of params based on the type of IPAM profile.
         * @return {Object}
         * @protected
         */
        _getParams() {
            const config = this.getConfig();
            let params;

            switch (config.type) {
                case IPAMDNS_TYPE_INFOBLOX:
                case IPAMDNS_TYPE_INFOBLOX_DNS:
                    params = this._getInfobloxParams();
                    break;

                case IPAMDNS_TYPE_AZURE:
                case IPAMDNS_TYPE_AZURE_DNS:
                    params = this._getAzureParams();
                    break;
            }

            delete params.usable_alloc_subnets;
            delete params.usable_domains;
            delete params.usable_network_uuids;
            delete params.access_key_id;
            delete params.secret_access_key;
            delete params.vpc_id;
            params.type = config.type;
            params.provider = true;

            return params;
        }

        /**
         * Public method for returning an object of params based on the type of IPAM profile.
         * @return {Object}
         */
        getProfileParams() {
            return this._getParams();
        }

        /**
         * Returns lists of AWS networks and VPCs.
         * @param {string} list - Specifies which list to request (usable networks or domains)
         *     in addition to the list of VPCs.
         * @return {ng.$q.promise}
         */
        getAwsLists(list) {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getLists(list, this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Requests AWS AssumeRole list.
         * @returns {ng.$q.promise}
         */
        getAwsIamAssumeRoles() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getIamAssumeRoles(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Requests the list of availability zones to be used for usable networks.
         * @return {ng.$q.promise}
         */
        getAwsAvailabilityZones() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getAvailabilityZones(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Returns a list of AWS domains.
         * @return {ng.$q.promise}
         */
        getAwsDomains() {
            const awsProfile = this.getAwsProfile();

            this.busy = true;

            return awsProfile.getDomains(this.getProxyConfig())
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Virtual Networks.
         * @return {ng.$q.promise}
         */
        _getAzureVirtualNetworks() {
            const api = '/api/azure-get-virtual-networks';
            const params = this._getParams();

            delete params.vnet_id;

            return this.request('POST', api, params);
        }

        /**
         * Makes request for Resource Groups.
         * @return {ng.$q.promise}
         */
        _getAzureResourceGroups() {
            const api = '/api/azure-get-resource-groups';
            const params = this._getParams();

            delete params.vnet_id;

            return this.request('POST', api, params);
        }

        /**
         * Makes request for Azure subnets to be used as networks.
         * @private
         * @return {ng.$q.promise}
         */
        _getAzureNetworks() {
            const api = '/api/azure-get-subnets';
            const params = this._getParams();

            return this.request('POST', api, params);
        }

        /**
         * Makes request for Azure subnets while setting this.busy and this.errors.
         * @public
         * @return {ng.$q.promise}
         */
        getAzureNetworks() {
            this.busy = true;
            this.errors = null;

            return this._getAzureNetworks()
                .then(({ data }) => data.subnets)
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Azure domains while setting this.busy and this.errors.
         * @public
         * @return {ng.$q.promise}
         */
        getDomainList() {
            if (!this.id) {
                return $q.reject('No ipamdnsprovider_uuid');
            }

            this.busy = true;
            this.errors = null;

            return this._getDomainList()
                .then(({ data }) => data.domains || [])
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(this.errors);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Makes requests for Azure-related lists. Gets Virtual Networks and Resource Groups,
         * and loads Networks as well optionally. We choose to load networks here when editing
         * a profile and the Virtual Network(s) has already been selected.
         * @param {string=} list - 'networks' or 'domains' to load respective list.
         * @return {ng.$q.promise}
         */
        getAzureLists(list) {
            const promises = [
                this._getAzureVirtualNetworks().then(({ data }) => data.vnets),
                this._getAzureResourceGroups().then(({ data }) => data.resource_groups),
            ];

            switch (list) {
                case 'networks':
                    promises.push(this._getAzureNetworks().then(({ data }) => data.subnets));
                    break;

                case 'domains':
                    promises.push(this._getDomainList().then(({ data }) => data.domains));
                    break;
            }

            this.busy = true;
            this.errors = null;

            return $q.all(promises)
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Check azure credentials for validation.
         * @return {ng.$q.promise}
         */
        verifyAzureCredentials() {
            this.busy = true;
            this.errors = null;

            return this._getAzureVirtualNetworks()
                .then(rsp => rsp)
                .catch(({ data }) => $q.reject(data))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for the domains list without dealing with this.busy or this.errors, so
         * that they can be managed by the caller.
         * @return {ng.$q.promise}
         * @protected
         */
        _getDomainList() {
            const api = '/api/ipamdnsproviderprofiledomainlist/';

            return this.request('GET', `${api}?ipamdnsprovider_uuid=${this.id}`);
        }

        /**
         * Verify profile credentials based on types.
         * @param {string} type - Profile type.
         * @return {ng.$q.promise}
         */
        verifyProfileCredentials(type) {
            this.busy = true;
            this.errors = null;

            switch (type) {
                case IPAMDNS_TYPE_INFOBLOX:
                case IPAMDNS_TYPE_INFOBLOX_DNS: {
                    const api = '/api/ipamdnsproviderprofilelogin';
                    const url = this.getInfobloxProfileApiCallUrl_(api, type);
                    const promise = this.request('GET', url);

                    return promise
                        .then(({ data }) => data)
                        .catch(({ data }) => $q.reject(data))
                        .finally(() => this.busy = false);
                }

                case IPAMDNS_TYPE_AZURE_DNS:
                case IPAMDNS_TYPE_AZURE:
                    return this.verifyAzureCredentials();
            }
        }

        /**
         * Get list of infoblox profile subnets.
         * Fetch by uuid when the profile already exists and credentials are not being changed.
         * Fetch by credentials if no uuid or changes of credentials are made.
         * @param {boolean=} [withId=true] - True: fetch by uuid; false: fetch by credentials.
         * @return {ng.$q.promise}
         */
        getInfobloxProfileNetworkList(withId = true) {
            this.busy = true;
            this.errors = null;

            const promise = withId ? this.getInfobloxProfileNetworkListWithId_() :
                this.getInfobloxProfileNetworkListWithCredentials_();

            return promise
                .then(({ data }) => IPAMProfile.createInfobloxUsableSubnetsHash(data.alloc_subnets))
                .catch(({ data }) => $q.reject(data))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for the network list with uuid.
         * @return {ng.$q.promise}
         */
        getInfobloxProfileNetworkListWithId_() {
            const api = '/api/ipamdnsproviderprofilenetworklist';

            return this.request('GET', `${api}?ipamdnsprovider_uuid=${this.id}&provider=true`);
        }

        /**
         * Makes request for the network list with credentials.
         * @return {ng.$q.promise}
         */
        getInfobloxProfileNetworkListWithCredentials_() {
            const
                api = '/api/ipamdnsproviderprofilenetworklist',
                url = this.getInfobloxProfileApiCallUrl_(api, IPAMDNS_TYPE_INFOBLOX);

            return this.request('GET', url);
        }

        /**
         * Gets list of infoblox dns profile domains.
         * @param {boolean=} [withId=true] - True: fetch by uuid; false: fetch by credentials.
         * @return {ng.$q.promise}
         */
        getInfobloxDnsProfileDomainList(withId = true) {
            this.busy = true;
            this.errors = null;

            const promise = withId ? this.getDomainListWithId_() :
                this.getDomainListWithCredentials_();

            return promise
                .then(({ data }) => data.domains || [])
                .catch(({ data }) => $q.reject(data))
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for the domain list with uuid.
         * @return {ng.$q.promise}
         */
        getDomainListWithId_() {
            const api = '/api/ipamdnsproviderprofiledomainlist';

            return this.request('GET', `${api}?ipamdnsprovider_uuid=${this.id}&provider=true`);
        }

        /**
         * Makes request for the domain list with credentials.
         * @return {ng.$q.promise}
         */
        getDomainListWithCredentials_() {
            const
                api = '/api/ipamdnsproviderprofiledomainlist',
                url = this.getInfobloxProfileApiCallUrl_(api, IPAMDNS_TYPE_INFOBLOX_DNS);

            return this.request('GET', url);
        }

        /**
         * Generate infoblox profile api call url based on api and type.
         * @param {string} api - The particular api/path that is used to make the call.
         * @param {string} type - Type of infoblox profile.
         *      Could be IPAMDNS_TYPE_INFOBLOX or IPAMDNS_TYPE_INFOBLOX_DNS.
         * @protected
         */
        getInfobloxProfileApiCallUrl_(api, type) {
            const paramHash = this.getInfobloxProfileApiCallParamHash_(type);

            return UpdatableItem.getUrl(api, paramHash);
        }

        /**
         * Get infoblox profile param hash.
         * @param {string} type
         * @return {Object.<string, string>}
         * @protected
         */
        getInfobloxProfileApiCallParamHash_(type) {
            const paramHash = angular.copy(this.getProfileConfig());
            const { addr } = paramHash['ip_address'];
            const paramsToKeep = IPAMProfile.getInfobloxProfileFingerPrintPropNames(type);

            paramHash['ip_address'] = addr;

            const filteredParamHash = _.pick(paramHash, paramsToKeep);

            filteredParamHash.type = type;

            return filteredParamHash;
        }

        /**
         * Clear password for the profile.
         */
        clearProfilePassword() {
            const profileConfig = this.getProfileConfig();

            if (this.isType(IPAMDNS_TYPE_AZURE_DNS)) {
                const { azure_userpass: azureUserpass = {} } = profileConfig;

                delete azureUserpass.password;
            } else {
                delete profileConfig.password;
            }
        }

        /**
         * Checks if current IPAM Profile of specified type.
         * @param {string} type - IPAM/DNS type (enum IpamDnsType).
         * @return {boolean}
         */
        isType(type) {
            return this.getType() === type;
        }

        /**
         * Returns the IPAM Profile type.
         * @return {string|null}
         */
        getType() {
            const config = this.getConfig();

            return config && config.type;
        }

        /**
         * Returns true if Cloud or its ConfigItems are busy.
         * @return {boolean}
         */
        isBusy() {
            const { aws_profile: awsProfile } = this.getConfig();

            return this.busy || awsProfile && awsProfile.busy || false;
        }

        /**
         * Returns the errors from Cloud or its ConfigItems.
         * @return {string|Object}
         */
        getErrors() {
            const { aws_profile: awsProfile } = this.getConfig();

            return this.errors || awsProfile && awsProfile.errors;
        }

        /**
         * Returns this.data.config.aws_profile.
         * @return {IpamDnsAwsProfileConfig}
         */
        getAwsProfile() {
            return this.getConfig().aws_profile;
        }

        /**
         * Returns this.data.config.proxy_configuartion.
         * @return {Object}
         */
        getProxyConfig() {
            return this.getConfig().proxy_configuration;
        }

        /**
         * Clear usable subnets of IPAM infoblox profile.
         */
        clearInfobloxProfileUsableSubnetList() {
            this.infobloxConfiguredUsableSubnets = [];
        }

        /**
         * Returns usable domains of IPAM infoblox DNS profile.
         * @return {string[]|undefined}
         */
        getInfobloxDnsProfileUsableDomainList() {
            return this.getProfileConfig().usable_domains;
        }

        /**
         * Clear usable domains of IPAM infoblox DNS profile.
         */
        clearInfobloxDnsProfileUsableDomaintList() {
            this.getProfileConfig().usable_domains = [];
        }

        /**
         * Returns profile configuration object depending of IPAMProfile type.
         * @return {Object|null}
         */
        getProfileConfig() {
            const config = this.getConfig();
            const { type } = config;
            const configProperty = IPAMProfile.typeToConfig[type];

            return config[configProperty] || null;
        }

        /**
         * Returns usable subnets of IPAM infoblox profile.
         * @return {InfobloxSubnet[]|undefined}
         */
        get infobloxConfiguredUsableSubnets() {
            return this.getProfileConfig().usable_alloc_subnets;
        }

        /**
         * Sets usable subnets for an IPAM infoblox profile.
         * @param {InfobloxSubnet[]} - Subnets to set.
         */
        set infobloxConfiguredUsableSubnets(subnets) {
            this.getProfileConfig().usable_alloc_subnets = subnets;
        }
    }

    angular.extend(IPAMProfile.prototype, {
        objectName: 'ipamdnsproviderprofile',
        windowElement: 'ipam-dns-profiles-modal',
    });

    /**
     * Hash mapping the IPAM/DNS profile type to the configuration property.
     * @type {Object.<string, string>}
     */
    IPAMProfile.typeToConfig = {
        IPAMDNS_TYPE_INTERNAL: 'internal_profile',
        IPAMDNS_TYPE_INTERNAL_DNS: 'internal_profile',
        IPAMDNS_TYPE_INFOBLOX: 'infoblox_profile',
        IPAMDNS_TYPE_INFOBLOX_DNS: 'infoblox_profile',
        IPAMDNS_TYPE_AWS: 'aws_profile',
        IPAMDNS_TYPE_AWS_DNS: 'aws_profile',
        IPAMDNS_TYPE_OPENSTACK: 'openstack_profile',
        IPAMDNS_TYPE_GCP: 'gcp_profile',
        IPAMDNS_TYPE_CUSTOM: 'custom_profile',
        IPAMDNS_TYPE_CUSTOM_DNS: 'custom_profile',
        IPAMDNS_TYPE_AZURE: 'azure_profile',
        IPAMDNS_TYPE_AZURE_DNS: 'azure_profile',
    };

    /**
     * List of profile property config names.
     * @type {string[]}
     */
    IPAMProfile.profileConfigPropNames = IPAMProfile.getProfileConfigPropNames_();

    return IPAMProfile;
};

IPAMProfileFactory.$inject = [
    'Item',
    '$http',
    '$q',
    'UpdatableItem',
    'secretStubStr',
    'IpamDnsAwsProfileConfig',
    'CustomIpamDnsProfile',
];

angular.module('aviApp').factory('IPAMProfile', IPAMProfileFactory);
