/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './virtualservice-faults.less';

/**
 * @ngdoc component
 * @name  virtualserviceFaults
 * @param {VirtualServiceFaults} faults
 * @param {string} view - The place where this component is being shown, either "healthscore" or
 *     "message".
 * @param {function} onCloseMessage - Closes message.
 * @description
 *     Displays a list of Virtual Service faults. Lists are separated by faults of a generic data
 *     structure and faults of a nested structure like server faults, which are nested by pools.
 */
class Controller {
    $onInit() {
        this.showList = this.view === 'message';
        this.count = this.getCount();
        this.genericFaults = this.getGenericFaults();
        this.serverFaults = this.getServerFaults();
    }

    /**
     * Returns the count of total faults. If faults are of type 'server_fault', faults are
     * nested further.
     * @return {number}
     */
    getCount() {
        let counter = 0;

        this.faults.forEach(fault => {
            if (fault.id === 'server_faults') {
                fault.faults.forEach(innerFault => {
                    counter += innerFault.servers.length;
                });
            } else {
                counter += 1;
            }
        });

        return counter;
    }

    /**
     * Returns a list of generic faults.
     * @return {Object[]}
     */
    getGenericFaults() {
        return this.faults.filter(fault => fault.id !== 'server_faults');
    }

    /**
     * Returns a list of server faults.
     * @return {Object[]}
     */
    getServerFaults() {
        return _.find(this.faults, fault => fault.id === 'server_faults');
    }

    /**
     * Toggles the view between showing and hiding the list of faults.
     */
    toggleList() {
        this.showList = !this.showList;
    }
}

angular.module('aviApp').component('virtualserviceFaults', {
    bindings: {
        faults: '<',
        view: '@',
        onCloseMessage: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/applications/virtualservice/' +
            'virtualservice-faults/virtualservice-faults.html',
});
