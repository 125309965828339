/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-profile-modal.less';

/**
 * @ngdoc component
 * @name wafProfileModal
 * @param {WafProfile} editable
 * @description Modal component for Waf Profile
 */
class WafProfileModalController {
    constructor(Schema, Regex, wafProfileModalService) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.wafProfileModalService_ = wafProfileModalService;

        this.pbWafConfigFields = Schema.pb.WafConfig.fields;
    }

    $onInit() {
        this.showLearningParameters = this.editable.hasAppLearning();
        this.editable.setShouldDeleteLearningParameters(!this.showLearningParameters);

        this.learningSamplingPercentRange = this.getLearningSamplingPercentRange();

        this.minConfidenceSliderLabelsHash = this.getMinConfidenceSliderLabelsHash_();
        this.minConfidenceLabelsHash_ = this.getMinConfidenceLabelsHash_();
        this.minConfidence = this.getSliderValueByMinConfidenceEnum();
    }

    /**
     * Returns the range of allowed values for the 'sampling_percent' field.
     * @returns {string[]}
     */
    getLearningSamplingPercentRange() {
        return this.wafProfileModalService_.getLearningSamplingPercentRange();
    }

    /**
     * Returns a hash of slider values to labels.
     * @returns {Object<string,string>}
     */
    getMinConfidenceSliderLabelsHash_() {
        return this.wafProfileModalService_.getMinConfidenceSliderLabelHash();
    }

    /**
     * Returns a hash of labels based on configured confidence percentages. The labels contain
     * either 'None' or something like 'Low (70-90%)', where the '(70-90%)' part is taken from
     * previously configured values in the 'confidence_override' object.
     * @returns {Object<string,string>}
     */
    getMinConfidenceLabelsHash_() {
        const { config } = this.editable.getConfig();
        const { confidence_override: confidenceOverride } = config;

        return this.wafProfileModalService_.getMinConfidenceLabelsHash(confidenceOverride);
    }

    /**
     * Returns the slider value based on the configured enum. Called in $onInit.
     * @returns {number}
     */
    getSliderValueByMinConfidenceEnum() {
        const { minConfidence } = this.editable;

        return this.wafProfileModalService_.getSliderValueByMinConfidenceEnum(minConfidence);
    }

    /**
     * Change handler for the 'min_confidence' slider. The slider shows 4 values (Low, Probable,
     * High, and Very High).
     */
    handleChangeMinConfidence() {
        this.editable.minConfidence = this.getMinConfidenceEnum();
    }

    /**
     * Returns the 'min_confidence' enum value to set based on the slider value.
     * @returns {string}
     */
    getMinConfidenceEnum() {
        return this.wafProfileModalService_.getMinConfidenceEnumBySliderValue(this.minConfidence);
    }

    /**
     * Returns the label of the 'min_confidence' enum value.
     * @returns {string}
     */
    getMinConfidenceLabel() {
        return this.minConfidenceLabelsHash_[this.editable.minConfidence];
    }

    /**
     * Handler for Add File button.
     */
    handleAddFile() {
        this.editable.addFile();
    }

    /**
     * Handler for Remove File trash button.
     * @param {number} index - Index of the file to remove.
     */
    handleRemoveFile(index) {
        this.editable.removeFile(index);
    }

    /**
     * Handler for showing/hiding Application Learning params.
     */
    handleShowLearning() {
        this.editable.setShouldDeleteLearningParameters(!this.showLearningParameters);

        if (this.showLearningParameters && !this.editable.hasAppLearning()) {
            this.editable.setDefaultAppLearningParams();
        }
    }
}

WafProfileModalController.$inject = [
    'Schema',
    'Regex',
    'wafProfileModalService',
];

angular.module('aviApp').component('wafProfileModal', {
    controller: WafProfileModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/waf-profile-modal/waf-profile-modal.html',
});
