/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * "Core" functionality module. Various services and factories.
 * @module avi/core
 */

/**
 * Service to work with data model exported from protobuf during the build.
 * Stateless singleton (excluding cache for internal optimizations).
 * @namespace schemaService
 * @memberOf module:avi/core
 */
function schemaServiceFactory(Schema, naturalSort, Regex) {
    const INT_TYPE = 'int';
    const UINT_TYPE = 'uint';

    /**
     * Hash for Enum Name: Common Prefix.
     * Updated every time getEnumValues() or getEnumValue() is called.
     * @type {Object}
     * @inner
     */
    const commonPrefixHash = {};

    /**
     * Comparator for sorting on values.
     * @param {Object} objA
     * @param {Object} objB
     * @return {boolean}
     * @inner
     */
    function formattedValueComparator(objA, objB) {
        const x = objA.label || objA.value;
        const y = objB.label || objB.value;

        return naturalSort(x, y);
    }

    /**
     * Used only upon initial load, to massage some Schema properties/values.
     * @memberOf module:avi/core.schemaService
     */
    function init() {
        const { enums } = Schema;

        /** Removing these enums since they are not supported by controller. */
        const EnumValueToDrop = {
            PersistenceProfileType: [
                'PERSISTENCE_TYPE_CLIENT_IPV6_ADDRESS',
            ],
            LbAlgorithm: [
                'LB_ALGORITHM_RANDOM',
                'LB_ALGORITHM_FEWEST_TASKS',
                'LB_ALGORITHM_NEAREST_SERVER',
            ],
            HealthMonitorType: [
                'HEALTH_MONITOR_GSLB',
                'HEALTH_MONITOR_GSLB_LOCAL',
            ],
            IpamDnsType: [
                'IPAMDNS_TYPE_CUSTOM',
            ],
            FailActionEnum: [
                'FAIL_ACTION_BACKUP_POOL', // deprecated since 18.1.2
            ],
        };

        const dropEnumValue = (values, enumName) => {
            const hash = enums[enumName].values;

            values.forEach(value => delete hash[value]);
        };

        _.each(EnumValueToDrop, dropEnumValue);

        /**
         * let's flatten some enum dictionaries and add ordered lists to em
         * @deprecated
         */
        [
            'HttpResponseCode',
            'IpamDnsType',
            'DnsErrorResponseType',
            'LogsType',
            'WafParanoiaLevel',
            'SNMP_VER',
            'SNMP_V3_AUTH',
            'SNMP_V3_PRIV',
            'PersistenceProfileType',
        ].forEach(enumName => {
            const enumObj = enums[enumName];

            enumObj.valuesList = getEnumValues(enumName);
        });
    }

    /**
     * @typedef {Object} EnumValue
     * @memberOf module:avi/core.schemaService
     * @property {string} value - value for this Enum found in Schema, is actual name of prop
     * @property {string} label - display value in UI;
     *     uses options.text.value from Schema, or common prefix,
     *     else returns empty and must be added manually
     * @property {string} description- description value in UI;
     *     uses options.e_description.value from Schema,
     *     else returns empty and must be added manually
     */

    /**
     * Returns formatted list of values found for this enum in Schema.
     * @memberOf module:avi/core.schemaService
     * @param {string} enumName - desired Schema property to format values of
     * @returns {EnumValue[]}
     */
    function getEnumValues(enumName) {
        if (!Schema.enums[enumName]) {
            console.error(`"${enumName}" enum not found in schema`);

            return [];
        }

        const
            propOptions = Schema.enums[enumName].values,
            formattedResults = _.map(propOptions, (valObj, key) => getEnumValue(enumName, key));

        return formattedResults.sort(formattedValueComparator);
    }

    /**
     * Returns a hash of enum values to their labels.
     * @memberOf module:avi/core.schemaService
     * @param {string} enumName - desired Schema property to format values of.
     * @returns {Object.<EnumValue.value, EnumValue.label>}
     */
    function getEnumValueLabelsHash(enumName) {
        return getEnumValues(enumName).reduce((hash, { value, label }) => {
            hash[value] = label;

            return hash;
        }, {});
    }

    /**
     * Returns formatted value object found for this enum in Schema.
     * @memberOf module:avi/core.schemaService
     * @param {string} enumName - desired Schema property to format values of
     * @param {string} valueKey - desired value property to be formatted
     * @returns {Object}
     */
    function getEnumValue(enumName, valueKey) {
        if (!Schema.enums[enumName] || !Schema.enums[enumName].values[valueKey]) {
            console.error(`"${enumName}: ${valueKey}" not found in Schema.enums`);

            return {};
        }

        const
            propOptions = Schema.enums[enumName].values,
            enumVals = Object.keys(propOptions),
            valObj = propOptions[valueKey];

        const formatted = { value: valueKey };
        let commonPrefix;

        if (!getEnumValueText(valObj)) {
            if (valueKey in commonPrefixHash) {
                commonPrefix = commonPrefixHash[valueKey];
            } else {
                commonPrefix = getCommonPrefix_(enumVals);
                commonPrefixHash[valueKey] = commonPrefix;
            }

            formatted.label = valueKey.enumeration(commonPrefix);
        } else {
            formatted.label = getEnumValueText(valObj);
        }

        formatted.description = getEnumValueDescription(valObj);

        return formatted;
    }

    /**
     * Returns common prefix indicated by '_' separator, or empty string if none exists.
     * Note: Exposed only for unit testing purposes.
     * @memberOf module:avi/core.schemaService
     * @param {string[]} enumVals -input strings to check for prefix
     * @returns {string}
     * @private
     */
    function getCommonPrefix_(enumVals) {
        const
            enumsCopy = [...enumVals],
            comparator = enumsCopy[0],
            comparatorSplits = [],
            chunks = comparator.split('_');
        let commonPrefix = '',
            prefix = '';

        for (let i = 0; i < chunks.length - 1; i++) {
            prefix += `${chunks[i]}_`;
            comparatorSplits.push(prefix);
        }

        for (let i = comparatorSplits.length - 1; i >= 0; i--) {
            const split = comparatorSplits[i];

            const isCommon = _.every(enumsCopy, val => {
                return val.indexOf(split) === 0;
            });

            if (isCommon) {
                commonPrefix = split;
                break;
            }
        }

        return commonPrefix;
    }

    /**
     * Returns val for given Schema.enums[enum].values[enumObj].options.text.value, if exists.
     * @memberOf module:avi/core.schemaService
     * @param {Object} enumObj - value for particular [enum]
     * @returns {EnumValue.label}
     */
    function getEnumValueText(enumObj) {
        const { text } = enumObj.options;

        if (text && 'value' in text) {
            return text.value;
        }

        return '';
    }

    /**
     * Returns val for given Schema.enums[enum].values[enumObj].options.e_description.value,
     * if exists, else empty string.
     * @memberOf module:avi/core.schemaService
     * @param {Object} enumObj - value for particular [enum]
     * @returns {EnumValue.description}
     */
    function getEnumValueDescription(enumObj) {
        const { e_description: description } = enumObj.options;

        if (description && 'value' in description) {
            return description.value;
        }

        return '';
    }

    /**
     * Returns true if field definition object from {@link Schema} is passed.
     * @memberOf module:avi/core.schemaService
     * @param {*} obj - expected to be Schema.pb object to return true
     * @return {boolean} - true if Schema.pb object is passed in
     * @public
     */
    function isFieldDefinitionObject(obj) {
        return angular.isObject(obj) && 'options' in obj;
    }

    /**
     * Returns the fields of a given objectType.
     * @memberOf module:avi/core.schemaService
     * @param {string} objectType - type of object
     * @return {Object}
     * @protected
     */
    function getObjectFields_(objectType) {
        const objectTypeData = Schema.pb[objectType];

        if (!objectTypeData) {
            throw new Error(`objectType "${objectType}" is not found in Schema.pb`);
        }

        return angular.copy(objectTypeData.fields);
    }

    /**
     * Returns an array of fields for an objectType.
     * @memberOf module:avi/core.schemaService
     * @param {string} objectType - Type of object.
     * @returns {string[]}
     */
    function getObjectFieldNames(objectType) {
        return Object.keys(getObjectFields_(objectType));
    }

    /**
     * Returns message fields for an objectType.
     * @memberOf module:avi/core.schemaService
     * @return {Object}
     */
    function getMessageFields(objectType) {
        const objectFields = getObjectFields_(objectType);

        return _.reduce(objectFields, (messageMap, fieldProps, field) => {
            if (fieldProps.type === 'message') {
                const { message_type: objectType, label } = fieldProps;

                messageMap[field] = {
                    objectType,
                    isRepeated: label === 'repeated',
                };
            }

            return messageMap;
        }, {});
    }

    /**
     * Returns object field definition object by object type and field name.
     * @memberOf module:avi/core.schemaService
     * @param {string} objectType
     * @param {string} fieldName
     * @return {Object}
     * @protected
     */
    function fieldDefinitionObjLookup_(objectType, fieldName) {
        const messageFields = getObjectFields_(objectType);
        const fieldData = messageFields[fieldName];

        if (!fieldData) {
            throw new Error(`field "${fieldName}" of "${objectType}" is not found in Schema`);
        }

        return fieldData;
    }

    /**
     * Returns description of protobuf field, if exists, else empty string.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - name (key) of objectType for this fieldName |
     *     actual objectType from Schema itself
     * @param {string} fieldName - protobuf property to get description of
     * @return {string}
     * @public
     */
    function getFieldDescription(objectTypeOrFieldData, fieldName) {
        let fieldData = objectTypeOrFieldData;

        if (!isFieldDefinitionObject(objectTypeOrFieldData)) {
            fieldData = fieldDefinitionObjLookup_(objectTypeOrFieldData, fieldName);
        }

        const { f_description: description } = fieldData.options;

        if (!description) {
            return '';
        }

        return description.value;
    }

    /**
     * Parses field special values object-like string into an object/hash.
     * @memberOf module:avi/core.schemaService
     * @param {string} string - For ex: "{ 0 : 'Immediate', -1 : 'Infinite' }"
     * @return {{string: string}} - key value pairs
     * @protected
     */
    function parseFieldSpecialValuesStr_(string) {
        const { schemaObjectFieldSpecialValuesHash: regex } = Regex;
        const globalRegex = new RegExp(regex, 'g');
        const hash = {};

        let match = null;

        while (match = globalRegex.exec(string)) {
            const [, key, value] = match;

            hash[key] = value;
        }

        return hash;
    }

    /**
     * Returns special values hash for the object field passed.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - name (key) of objectType for this fieldName |
     *     actual objectType from Schema itself
     * @param {string?} fieldName
     * @return {Object}
     * @public
     */
    function getFieldSpecialValuesHash(objectTypeOrFieldData, fieldName) {
        let fieldData = objectTypeOrFieldData;

        if (!isFieldDefinitionObject(objectTypeOrFieldData)) {
            fieldData = fieldDefinitionObjLookup_(objectTypeOrFieldData, fieldName);
        }

        const { options: fieldOptions } = fieldData;

        if (!('special_values' in fieldOptions)) {
            return {};
        }

        const { value: specialValuesStr } = fieldOptions['special_values'];

        return parseFieldSpecialValuesStr_(specialValuesStr);
    }

    /**
     * Returns object field special values in the text form.
     * @memberOf module:avi/core.schemaService
     * @return {string} - For ex: "0: Disable, -1: Automatic"
     * @see getFieldSpecialValuesHash for list of params
     * @public
     */
    function getFieldSpecialValuesAsText(...args) {
        const specialValuesHash = getFieldSpecialValuesHash(...args);
        const records = _.map(specialValuesHash, (value, key) => `${key}: ${value}`);

        return records.join(', ');
    }

    /**
     * Returns object field values range as text.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - name (key) of objectType for this fieldName |
     *     actual objectType from Schema itself
     * @param {string=} fieldName
     * @return {string}
     * @public
     */
    function getFieldRangeAsText(objectTypeOrFieldData, fieldName) {
        let fieldData = objectTypeOrFieldData;

        if (!isFieldDefinitionObject(objectTypeOrFieldData)) {
            fieldData = fieldDefinitionObjLookup_(objectTypeOrFieldData, fieldName);
        }

        const { options: fieldOptions } = fieldData;

        if (!('range' in fieldOptions)) {
            return '';
        }

        return fieldOptions['range']['value'];
    }

    /**
     * Returns object field values range as a tuple of min and max number values.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - name (key) of objectType for this fieldName |
     *     actual objectType from Schema itself
     * @param {string=} fieldName
     * @return {number[]}
     * @public
     */
    function getFieldRangeAsTuple(objectTypeOrFieldData, fieldName) {
        const fieldRangeAsText = getFieldRangeAsText(objectTypeOrFieldData, fieldName);

        if (!fieldRangeAsText) {
            throw new Error(`Range does not exist for field "${fieldName}"`);
        }

        return fieldRangeAsText.split('-').map(stringValue => Number(stringValue));
    }

    /**
     * @typedef {Object} MergedFieldRange
     * @memberOf module:avi/core.schemaService
     * @property {number[]} range - List of min, max for the input field
     * @property {boolean} merged - True if range & specialValues got merged together.
     */

    /**
     * Merges range values with the special values if they are continuous
     * and precede/follow the regular values range or fall into the regular range.
     * If special values are not present, return range from the input field.
     * If special values fall into the regular range, return regular range.
     * If special values and regular range are mergable with special values,
     * then update min & max to include specialValues.
     *
     * If range & specialValues are not continuous 'merged' flag is set to false.
     *
     * @memberOf module:avi/core.schemaService
     * @param {number[]} range - List of min, max for the input field
     * @param {number[]} [specialValues=[]] - List of specialValues
     * @return {MergedFieldRange}
     * @protected
     */
    function mergeFieldRangeWithSpecialValues_(range, specialValues = []) {
        if (!range.length) {
            throw new Error('Can\'t merge an empty range with special values');
        }

        const { length: spValuesLength } = specialValues;

        if (!spValuesLength) {
            return {
                range,
            };
        }

        const [min, max] = range;

        let spMin = Infinity;
        let spMax = -Infinity;

        let spValuesWithinRangeLength = 0;

        specialValues.forEach(val => {
            // special value falls into the regular range
            if (val >= min && val <= max) {
                spValuesWithinRangeLength++;

                return;
            }

            // here we care only about special values ouf ot the regular range
            if (val > spMax) {
                spMax = val;
            }

            if (val < spMin) {
                spMin = val;
            }
        });

        // if all special values are within the regular range
        if (spValuesWithinRangeLength === spValuesLength) {
            return {
                range,
                merged: true,
            };
        }

        // below we care only about special values ouf ot the regular range

        // continuous list if this is true (assuming all are integers and no repeats)
        if (spMax - spMin !== spValuesLength - spValuesWithinRangeLength - 1) {
            return {
                range,
                merged: false,
            };
        }

        // special values to the right from the range
        if (max + 1 === spMin) {
            return {
                range: [min, spMax],
                merged: true,
            };
        }

        // special values to the left from the range
        if (min - 1 === spMax) {
            return {
                range: [spMin, max],
                merged: true,
            };
        }

        return {
            range,
            merged: false,
        };
    }

    /**
     * Returns list of specialValues for a given fieldName.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - objectType
     * @param {string=} fieldName - fieldName
     * @returns {number[]}
     * @private
     */
    function getSpecialValues_(objectTypeOrFieldData, fieldName) {
        const specialValuesHash = getFieldSpecialValuesHash(objectTypeOrFieldData, fieldName);

        return Object.keys(specialValuesHash).map(Number);
    }

    /**
     * Returns type of the field.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData - objectType
     * @param {string=} fieldName - fieldName
     * @param {boolean} [resolveSubType=false] - Will return enum or message name instead
     *     of 'message' or 'enum'. Has no effect on simple types.
     * @returns {string} Type as 'int', 'uint', 'float', 'enum', 'message' or 'Pool' and
     *                   'HealthMonitorType' when resolveSubType is set to true.
     * @private
     */
    function getFieldType_(
        objectTypeOrFieldData,
        fieldName,
        resolveSubType = false,
    ) {
        let fieldData = objectTypeOrFieldData;

        if (!isFieldDefinitionObject(objectTypeOrFieldData)) {
            fieldData = fieldDefinitionObjLookup_(objectTypeOrFieldData, fieldName);
        }

        const { type } = fieldData;

        if (resolveSubType && (type !== 'enum' && type !== 'message')) {
            throw new Error(`resolveSubType is true but field ${
                fieldName
            } of ${objectTypeOrFieldData} is not of enum or message type`);
        }

        switch (type) {
            case 'int32':
            case 'int64':
                return INT_TYPE;

            case 'uint32':
            case 'uint64':
                return UINT_TYPE;

            case 'enum':
                return resolveSubType ? fieldData.enum_type : type;

            case 'message':
                return resolveSubType ? fieldData.message_type : type;

            default:
                return type;
        }
    }

    /**
     * Returns field type of the given field.
     * @memberOf module:avi/core.schemaService
     * @param {string} objectType
     * @param {string} fieldName
     * @returns {string}
     * @public
     */
    function getEnumFieldType(
        objectType,
        fieldName,
    ) {
        const type = getFieldType_(objectType, fieldName);

        if (type !== 'enum') {
            throw new Error(`field ${fieldName} of ${objectType} is not of enum type`);
        }

        return getFieldType_(objectType, fieldName, true);
    }

    /**
     * @typedef {Object} FieldRange
     * @memberOf module:avi/core.schemaService
     * @property {number[]|undefined} range
     * @property {boolean|undefined} rangeIncludesSpecialValues
     */

    /**
     * Returns range, rangeIncludesSpecialValues flag for an input field.
     * If specialValues are not present, return min, max from the input field range.
     * If specialValues are present and ranges are mergable with specialValues, then
     * update min & max to include specialValues.
     *
     * If specialValues are present and ranges are not mergable with specialValues, then
     * return min & max from the input field range.
     *
     * @example
     *  range = [1, 50];
     *  specialValues = [-1, 0];
     *  getFieldRange_(range, specialValues)
     *      then min is set to '-1' and max is set to '50'
     *
     * @example
     *  range = [10, 100];
     *  specialValues = [0];
     *  getFieldRange_(range, specialValues)
     *      then min is set to '10', and max is set to '100' as range & specialValues
     *      are not continuous
     * @memberOf module:avi/core.schemaService
     * @param {string} objectTypeOrFieldData - object type which param:protobuf property
     * @param {string=} fieldName - field name
     * @returns {FieldRange}
     * @private
     */
    function getFieldRange_(objectTypeOrFieldData, fieldName) {
        const rangesAsText = getFieldRangeAsText(objectTypeOrFieldData, fieldName);
        const range = [];

        if (!rangesAsText) {
            const type = getFieldType_(objectTypeOrFieldData, fieldName);

            if (type === UINT_TYPE) {
                range.push(0);
            }

            return {
                range,
            };
        }

        const separatorIndex = rangesAsText.indexOf('-', 1);

        if (separatorIndex === -1) {
            throw new Error(
                `Invalid range format for ${fieldName} of ${objectTypeOrFieldData}`,
            );
        }

        const min = Number(
            rangesAsText.slice(0, separatorIndex),
        );

        const max = Number(
            rangesAsText.slice(separatorIndex + 1),
        );

        range.push(min, max);

        const specialValues = getSpecialValues_(objectTypeOrFieldData, fieldName);

        if (!specialValues.length) {
            return {
                range,
            };
        }

        const {
            range: mergedRange,
            merged,
        } = mergeFieldRangeWithSpecialValues_([min, max], specialValues);

        //for undefined and true
        if (merged !== false) {
            return {
                range: mergedRange,
                rangeIncludesSpecialValues: true,
            };
        }

        return {
            range,
            rangeIncludesSpecialValues: false,
        };
    }

    /**
     * @typedef {Object} FieldOptions
     * @memberOf module:avi/core.schemaService
     * @property {number[]|undefined} range - Array containing min, max (for numeric types)
     * @property {string} type - Boolean indicating type of the field
     * @property {boolean|undefined} rangeIncludesSpecialValues -
     * True if range & specialValues are merged
     */

    /**
     * Returns range, type, rangeIncludesSpecialValues for a given objectType & fieldname.
     * @memberOf module:avi/core.schemaService
     * @param {string|Object} objectTypeOrFieldData
     * @param {string=} fieldName
     * @return {FieldOptions}
     * @protected
     */
    function getFieldOptions_(objectTypeOrFieldData, fieldName) {
        const {
            range,
            rangeIncludesSpecialValues,
        } = getFieldRange_(objectTypeOrFieldData, fieldName);

        const type = getFieldType_(objectTypeOrFieldData, fieldName);

        return {
            range,
            rangeIncludesSpecialValues,
            type,
        };
    }

    /**
     * @typedef {Object} FieldInputAttrsHash
     * @memberOf module:avi/core.schemaService
     * @property {number|undefined} min - min value for a given input field
     * @property {number|undefined} max - max value for a given input field
     * @property {number|undefined} step - step value for a given input field if type is 'int'
     *     or 'uint'
     */

    /**
     * Returns the hash of attributes to be set for a given fieldName.
     * @memberOf module:avi/core.schemaService
     * @param {string} objectType - type of object
     * @param {string} fieldName - fieldName of a input field
     * @return {FieldInputAttrsHash}
     */
    function getFieldInputAttributes(objectType, fieldName) {
        const {
            range,
            type,
            rangeIncludesSpecialValues,
        } = getFieldOptions_(objectType, fieldName);

        const attrsHash = {};

        const [min, max] = range;

        const hasRange = range.length === 2;

        if (!hasRange) {
            if (type === 'uint') {
                attrsHash.min = 0;
                attrsHash.step = 1;
            } else {
                console.warn(`There is no range for ${fieldName} of ${objectType}`);
            }

            return attrsHash;
        }

        if (type === 'int' || type === 'uint') {
            attrsHash.step = 1;
        }

        const continuousRange = rangeIncludesSpecialValues !== false;

        if (!continuousRange) {
            throw new Error(`min and max cannot be set for ${fieldName} of ${objectType} ` +
                'as the field range & special_values are not continuous');
        }

        attrsHash.min = min;
        attrsHash.max = max;

        return attrsHash;
    }

    // all private methods are exposed for testing purposes only
    return {
        getCommonPrefix_,
        getFieldRange_,
        getSpecialValues_,
        getFieldType_,
        getFieldOptions_,
        getEnumValue,
        getEnumFieldType,
        getEnumValueDescription,
        getEnumValues,
        getEnumValueLabelsHash,
        getEnumValueText,
        getFieldDescription,
        getFieldRangeAsText,
        getFieldRangeAsTuple,
        getFieldSpecialValuesAsText,
        getFieldSpecialValuesHash,
        init,
        isFieldDefinitionObject,
        getFieldInputAttributes,
        mergeFieldRangeWithSpecialValues_,
        getMessageFields,
        getObjectFieldNames,
    };
}

schemaServiceFactory.$inject = [
    'Schema',
    'naturalSort',
    'Regex',
];

angular.module('avi/core').factory('schemaService', schemaServiceFactory);
