/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name hsmGroupModal
 * @description
 *
 *     Modal component of HSMGroup.
 */
class HsmGroupModal {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }

    $onInit() {
        this.handleTypeChange(true);
    }

    /**
     * Calls hsmGroup.onTypeChange and loads SAFENET LUNA nodes when applicable.
     * @param {boolean?} initial
     * @public
     */
    handleTypeChange(initial) {
        const { editable: hsmGroup } = this;

        if (!hsmGroup.id && hsmGroup.getType() === 'SAFENET_LUNA') {
            hsmGroup.populateSLunaNodesList();
        }

        if (!initial) {
            hsmGroup.onTypeChange();
        }
    }
}

HsmGroupModal.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('hsmGroupModal', {
    controller: HsmGroupModal,
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    templateUrl: 'src/components/modals/hsm-group-modal/hsm-group-modal.html',
});
