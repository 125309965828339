/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name DataTransport
 * @author Alex Malitsky
 * @description
 *
 *     Data transport is the lowest level class to work with API network calls. It translates
 *     request parameters object into URL, headers and payload, executes an actual network call and
 *     returns the result (as a promise) without any transformation (in 99.9% of cases).
 *
 *     This layer has no clue of it's user (i.e. Collection), data transitions to be done
 *     with payload or data received, or furthermore - about continuous polling.
 *
 *     Basically this is a tiny wrapper over {@link Base#request} method specialized on particular
 *     API. Easiest example to explain is any list API where you have to make up a URL string of
 *     parameter-keys-equal-sign-values concatenated by ampersand. Know what? You don't need
 *     to create such strings in you code anymore - just pass a hash of parameters to
 *     {@link ListDataTransport#load} and they will be concatenated for you in appropriate manner.
 *
 *     In general every API type significantly different from existing ones in terms of
 *     request URL, headers, payload or HTTP type should have it's own dataTransport.
 *
 *     Only one main method {@link DataTransport#load} is exposed. It takes the request params
 *     object of unified (within all users of this API) structure and returns back the received
 *     response. Exact request params structure must be defined by this Class's children.
 *
 *     It is very abstract, please keep it simple. All usage-specific transformation of
 *     request payload or received data should be done by {@link DataTransformer |
 *     DataTransformers} or {@link CollDataSource | DataSources} or any other custom code.
 *
 *     It does support multiple network calls of the same type but such approach is
 *     strongly discouraged. Please use one instance of {@link DataTransformer} for each channel of
 *     data flow (i.e. - {@link CollDataSource}).
 *
 *     It also supports data transformations over received response but this is a fallback
 *     option only for very generic cases when 100% of this API users always need the same
 *     transformation. I.e. backend for some API always returns UNIX timestamps with
 *     milliseconds (12 digits) while UI uses only 10 (seconds only). For such case it makes
 *     sense to go through the response here and provide a guarantee of unified data format.
 *
 */

/**
 *  @typedef {Object} DataTransportRequestParams
 *  @property {string} url - Full API url string.
 *  @property {Object} payload - Payload for PUT and POST requests.
 *  @property {Object} headers - Hash of header names and values.
 */

angular.module('aviApp').factory('DataTransport', [
'$q', 'IdentityDataTransport', 'aviInherit',
function($q, IdentityDataTransport, aviInherit) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends IdentityDataTransport
     */
    function DataTransport(args) {
        DataTransport.superconstructor.call(this, args);

        if (!args || typeof args !== 'object') {
            args = {};
        }

        this.httpMethod_ = args.httpMethod || 'get';
        this.apiUrlPrefix_ = args.apiUrlPrefix || '/api/';
        this.includeName = !!args.includeName || false;
    }

    aviInherit(DataTransport, IdentityDataTransport);

    /**
     * Makes actual API call by transforming request params into URL and payload and returns
     * result as a promise.
     * @param {*} params - Unified within transport request parameters object.
     * @param {string=} requestId - Optional request id to stop pending one (if any) with the
     *     same id before making a next call.
     * @returns {ng.$q.promise}
     * @public
     */
    DataTransport.prototype.load = function(params, requestId) {
        const request = this.getRequestObject_(params);
        let loadPromise;

        requestId = requestId && typeof requestId === 'string' ? requestId : 'default';

        this.cancelRequests(requestId);

        if (Array.isArray(request)) { //DISCOURAGED, multiple transports in 99% suit MUCH better
            loadPromise = $q.all(_.map(request, singleRequest => {
                if (singleRequest.requestId) {
                    this.cancelRequests(singleRequest.requestId);
                }

                return this.request(singleRequest.httpMethod || this.httpMethod_,
                    singleRequest.url, singleRequest.payload, singleRequest.headers,
                    singleRequest.requestId || requestId);
            }));
        } else { //normal and advised case
            loadPromise = this.request(this.httpMethod_, request.url, request.payload,
                request.headers, requestId);
        }

        return loadPromise.then(this.processResponse_.bind(this));
    };

    /**
     * Translates request params into one or few API call request objects having everything
     * (url&payload) to perform an actual network call.
     * @param {*} params - Unified within transport request parameters object.
     * @returns {DataTransportRequestParams|DataTransportRequestParams[]} If array has been
     * returned DataTransport will make a separate call for each of them.
     * @private
     */
    DataTransport.prototype.getRequestObject_ = function(params) {
        const
            requestData = {
                url: this.getRequestUrl_(params),
                payload: this.getRequestPayload_(params),
                headers: this.getRequestHeaders_(params),
            },
            isArrayHash = {};//each data type has false or array length

        let res,
            multipleRequests = false;

        //need to figure out if we are in multi-thread mode
        _.each(requestData, (data, key) => {
            const falseOrArrLength = Array.isArray(data) && data.length;

            isArrayHash[key] = falseOrArrLength;

            if (falseOrArrLength !== false) {
                multipleRequests = true;
            }
        });

        if (multipleRequests) {
            let multiReqLength;

            const paramsMismatch = _.any(isArrayHash, arrLength => {
                if (arrLength !== false) { //ignore single values
                    if (angular.isUndefined(multiReqLength)) { //capture the first length
                        multiReqLength = arrLength;
                    //other lengths should be equal or single
                    } else {
                        return arrLength !== multiReqLength;
                    }
                }
            });

            if (!paramsMismatch) {
                res = [];

                for (let i = 0; i < multiReqLength; i++) {
                    const request = _.reduce(requestData, (acc, data, key) => {
                        acc[key] = isArrayHash[key] === false ? data : data[i];

                        return acc;
                    }, {});

                    res.push(request);
                }
            } else {
                const errMsg = `Inconsistent behaviour of getRequestUrl_, getRequestPayload_
                    and getRequestHeadersPayload_ - all are supposed to return one
                    undefined/object/string or arrays of those with the equal length`;

                console.error(errMsg, requestData);
                throw new Error(errMsg);
            }
        } else {
            res = requestData;
        }

        return res;
    };

    /**
     * Translates request params into one or few URL string to perform an actual API call(s).
     * @param {*=} params - Unified within transport request parameters object.
     * @returns {string}
     * @private
     * @abstract
     */
    DataTransport.prototype.getRequestUrl_ = function(params) {
        return this.apiUrlPrefix_;
    };

    /**
     * Translates request params into one or few PUT or POST payload objects.
     * @param {*} params - Unified within transport request parameters object.
     * @returns {Object}
     * @private
     * @abstract
     */
    DataTransport.prototype.getRequestPayload_ = function(params) {};

    /**
     * Translates request params into a headers hash.
     * @param {*} params
     * @returns {Object|undefined} - Hash of header names and text values.
     * @private
     * @abstract
     */
    DataTransport.prototype.getRequestHeaders_ = function(params) {};

    return DataTransport;
}]);
