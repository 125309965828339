/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './pingaccess-agent-profile-list.less';

const componentName = 'pingaccess-agent-profile-list';

class PingAccessAgentProfileListController {
    constructor($element, PingAccessAgentProfileCollection, CRUDGridConfig) {
        this.$element_ = $element;
        this.PingAccessAgentProfileCollection_ = PingAccessAgentProfileCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;
    }

    /**
     * Creates the gridConfig and creates an instance of pingAccessAgentProfileCollection.
     */
    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.PingAccessAgentProfileCollection_(),
            singleactions: [{
                title: 'Delete',
                class: 'icon-trash',
                do: row => {
                    this.gridConfig.collection.dropItems(row);

                    return true;
                },
            }],
            expandedContainerTemplate:
                `<pre class="pingaccess-agent-profile-list__expanded-row-details">
                    {{::row.getConfig().properties_file_data}}</pre>`,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    template: '{{::row.getName()}}',
                },
                {
                    name: 'pool',
                    title: 'Pool',
                    template: '{{::row.getConfig().pingaccess_pool_ref | name}}',
                },
            ],
            permission: 'PERMISSION_PINGACCESSAGENT',
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /**
     * Destroys the pingAccessAgentProfileCollection instance, cancelling any pending requests.
    */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

PingAccessAgentProfileListController.$inject = [
    '$element',
    'PingAccessAgentProfileCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name pingAccessAgentProfileList
 * @author Chitra Flarid
 * @description pingAccess profile page for creating new PingAccess Agent.
 */
angular.module('aviApp').component('pingAccessAgentProfileList', {
    controller: PingAccessAgentProfileListController,
    template:
        '<div class="templatesList"><collection-grid config="$ctrl.gridConfig" /></div>',
});
