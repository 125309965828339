/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const SSO_TYPE_PINGACCESS = 'SSO_TYPE_PINGACCESS';
const SSO_TYPE_SAML = 'SSO_TYPE_SAML';

/** @type {Object.<string, string>} */
const ssoPolicyTypes = {
    SSO_TYPE_PINGACCESS,
    SSO_TYPE_SAML,
};

export { ssoPolicyTypes };

const ssoPolicyFactory = Item => {
    /**
     * @class
     * @name module:items/SSOPolicy
     * @extends module:avi/dataModel.Item
     * @desc SSO policy item.
     */
    class SSOPolicy extends Item {
        /**
         * Hash of SSO policy type enums. Key equals to the value.
         * @type {Object.<string, string>}
         */
        static types = ssoPolicyTypes;

        /**
         * Returns default_auth_profile_ref of the nested authentication policy.
         * @return {string} - Empty string if not present/set.
         */
        getDefaultAuthProfileRef() {
            const { authentication_policy: authenticationPolicy } = this.getConfig();

            if (authenticationPolicy) {
                return authenticationPolicy.default_auth_profile_ref;
            }

            return '';
        }

        /**
         * Returns policy type.
         * @return {string} SSOPolicyType enum or empty string if not set.
         */
        get type() {
            return this.getConfig().type || '';
        }

        /**
         * Sets type of the policy.
         * @param {string} type - SSOPolicyType enum value.
         */
        set type(type) {
            const update = type !== this.type;

            this.getConfig().type = type;

            if (update) {
                this.onTypeChange_();
            }
        }

        /**
         * Removes auth profile reference of authentication policy (if present).
         * @protected
         */
        onTypeChange_() {
            const config = this.getConfig();

            if ('authentication_policy' in config) {
                delete config.authentication_policy.default_auth_profile_ref;
            }
        }
    }

    Object.assign(SSOPolicy.prototype, {
        objectName: 'ssopolicy',
        windowElement: 'sso-policy-modal',
    });

    return SSOPolicy;
};

ssoPolicyFactory.$inject = [
    'Item',
];

/**
 * @ngdoc factory
 * @alias SSOPolicy
 * @desc SSOPolicy item.
 * @author Alex Malitsky, Aravindh Nagarajan
 */
angular.module('aviApp')
    .factory('SSOPolicy', ssoPolicyFactory);
