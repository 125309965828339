/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2017] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
const CustomIpamDnsCollectionFactory = (Collection, CustomIpamDnsProfile) => {
    class CustomIpamDnsCollection extends Collection {}

    angular.extend(CustomIpamDnsCollection.prototype, {
        objectName_: 'customipamdnsprofile',
        itemClass_: CustomIpamDnsProfile,
        windowElement_: 'custom-ipam-dns-profile-modal',
    });

    return CustomIpamDnsCollection;
};

CustomIpamDnsCollectionFactory.$inject = [
    'Collection',
    'CustomIpamDnsProfile',
];

/**
 * @ngdoc factory
 * @name  CustomIpamDnsCollection
 * @description
 *      CustomIpamDnsProfile collection.
 */
angular.module('aviApp').factory('CustomIpamDnsCollection', CustomIpamDnsCollectionFactory);
