/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleClientIpMatchConfig
 * @description DnsPolicyRuleClientIpMatchConfig MatchConfigItem class.
 */
const dnsPolicyRuleClientIpMatchConfigFactory = (MatchConfigItem, IpAddrMatchConfig) => {
    class DnsPolicyRuleClientIpMatchConfig extends MatchConfigItem {
        constructor(args) {
            super(args);

            const config = this.getConfig();

            config['client_ip'] = new IpAddrMatchConfig({ data:
                    { config: config['client_ip'] || null },
            });
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config['use_edns_client_subnet_ip'] = !!config['use_edns_client_subnet_ip'];
            config['client_ip'].beforeEdit();
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config['client_ip'] instanceof MatchConfigItem) {
                config['client_ip'] = config['client_ip'].dataToSave();
            }

            return config;
        }

        /** @override */
        toString() {
            const { client_ip: clientIpMatch } = this.getConfig();

            return clientIpMatch ? clientIpMatch.toString() : '';
        }
    }

    angular.extend(DnsPolicyRuleClientIpMatchConfig.prototype, {
        type: 'DnsClientIpMatch',
        name: 'DNS Client IP',
        defaultPath: 'dnsrule.match.client_ip_address',
    });

    return DnsPolicyRuleClientIpMatchConfig;
};

dnsPolicyRuleClientIpMatchConfigFactory.$inject = [
        'MatchConfigItem',
        'IpAddrMatchConfig',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleClientIpMatchConfig', dnsPolicyRuleClientIpMatchConfigFactory,
);
