/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name dnsPolicyActionGslbSiteSelection
 * @param {Object} action
 * @description
 *
 *     Two dropdowns to pick main and fallback GSLB Site names.
 *     GSLB site names come from
 */
class DnsPolicyActionGslbSiteSelectionController {
    constructor(Schema, GSLB, systemInfoService) {
        this.Schema = Schema;
        this.GSLB_ = GSLB;
        this.systemInfoService_ = systemInfoService;

        this.gslbSites = [];
    }

    $onInit() {
        const gslbId = this.systemInfoService_.getGSLBid();

        this.gslb = new this.GSLB_({ id: gslbId });

        if (gslbId) {
            this.gslb.load()
                .then(this.onGslbLoad_.bind(this));
        }
    }

    /**
     * Populates a list of GSLB Site names for dropdowns.
     * @protected
     */
    onGslbLoad_() {
        const { gslbSites } = this;

        gslbSites.length = 0;

        this.gslb.getAllSites().forEach(
            siteSlug => gslbSites.push(siteSlug.name()),
        );
    }

    $onDestroy() {
        this.gslb.destroy();
    }
}

DnsPolicyActionGslbSiteSelectionController.$inject = [
        'Schema',
        'GSLB',
        'systemInfoService',
];

angular.module('aviApp').component('dnsPolicyActionGslbSiteSelection', {
    bindings: {
        action: '<',
    },
    controller: DnsPolicyActionGslbSiteSelectionController,
    templateUrl: 'src/components/applications/virtualservice/' +
            'virtualservice-dns-policy/dns-policy-rule-modal/dns-policy-action/' +
            'gslb-site-selection/dns-policy-action-gslb-site-selection.html',
});
