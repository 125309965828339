/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './tenant-selector.less';

const componentName = 'tenant-selector';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.tenantSelectorComponent}
 */
class TenantSelectorController {
    constructor(
        $scope,
        $http,
        Auth,
        dropDownUtils,
        naturalSort,
    ) {
        this.$scope_ = $scope;
        this.$http_ = $http;
        this.Auth_ = Auth;
        this.dropDownUtils_ = dropDownUtils;
        this.naturalSort_ = naturalSort;

        /**
         * Tenant Selector dropdown options.
         * @type {DropDownOption[]}
         */
        this.tenantOptions = [];

        /**
         * Tenant WatchCollection deregister function.
         * @type {Function|null}
         * @protected
         */
        this.unregisterTenantWatch_ = null;
    }

    /** @override */
    $onInit() {
        // Sets current tenant ref as dropdown model.
        this.selectedTenantRef = this.Auth_.getCurrentTenantRef();

        // Need to watch Auth.profile.tenants only for Non-admin users.
        // For Admin user, on creation/deletion of each tenant,
        // application will be reloaded (419).
        // So component will receive new list of tenants.
        if (!this.Auth_.isAdminUser()) {
            this.unregisterTenantWatch_ = this.$scope_.$watchCollection(() => {
                return this.Auth_.getTenants();
            }, () => {
                this.setTenantDropdownOptions_();
            });
        } else {
            this.setTenantDropdownOptions_();
        }
    }

    /**
     * Sets tenant dropdown option list.
     * @protected
     */
    setTenantDropdownOptions_() {
        const tenants = this.Auth_.getTenants();

        const tenantOptions = tenants
            .map(({ url, name }) => this.dropDownUtils_.createOption(url, name))
            .sort(({ label: labelA }, { label: labelB }) => this.naturalSort_(labelA, labelB));

        if (tenantOptions.length && this.Auth_.isSuperUser()) {
            tenantOptions
                .unshift(this.dropDownUtils_.createOption('*', 'All Tenants'));
        }

        this.tenantOptions = tenantOptions;
    }

    /**
     * Fired when tenant is changed.
     * Sets the selected tenant in Auth context.
     */
    onTenantChange() {
        const { selectedTenantRef } = this;

        this.Auth_.setAppContext(selectedTenantRef, true);
    }

    /** @override */
    $onDestroy() {
        if (this.unregisterTenantWatch_) {
            this.unregisterTenantWatch_();
        }
    }
}

TenantSelectorController.$inject = [
    '$scope',
    '$http',
    'Auth',
    'dropDownUtils',
    'naturalSort',
];

/**
 * @name tenantSelectorComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.TenantSelectorController} controller
 * @desc
 *      Dropdown to switch between tenants.
 *      Found in the header.
 * @author Aravindh Nagarajan
 */
angular.module('avi/navigation').component('tenantSelector', {
    controller: TenantSelectorController,
    templateUrl: `src/components/avi-header/${componentName}/${componentName}.html`,
});
