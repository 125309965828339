/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name DNS
 * @description
 *
 *     DNS Lookup service to resolve domain names to ip(s).
 *
 */
angular.module('aviApp').service('DNS', ['$http', '$q', 'Regex', '$timeout',
function($http, $q, Regex, $timeout) {
    this._singleLookupCancel = $q.defer();

    /**
     * Makes a request to resolve a domain name. Used when concurrent lookups are not desired, so
     * the canceller promise is set here instead of externally.
     * @param {string} domain - Domain to look up
     * @return {ng.$q.promise}
     */
    this.singleLookup = function(domain) {
        // Cancel previous lookup
        this._singleLookupCancel.resolve();
        this._singleLookupCancel = $q.defer();

        return this.lookup(domain, this._singleLookupCancel.promise);
    };

    /**
     * Simply makes request to the server to get ips
     * @param domain - VS data object
     * @param cancellerPromise - a promise that is going to be passed to http call
     *     to be able to cancel it
     * @param {string=} cloudId - Optional cloud_uuid
     * @return {promise}
     */
    this.lookup = function(domain, cancellerPromise, cloudId) {
        let promise;

        if (typeof domain !== 'string' || domain === '') {
            promise = [];
        } else if (this.checkIP(domain) || this.checkIPWithPort(domain)) {
            promise = [domain];
        } else if (domain && domain.split(',').length && this.checkMultipleDomains(domain)) {
            promise = [domain];
        } else {
            const config = {
                timeout: cancellerPromise,
                params: {
                    cloud_uuid: cloudId,
                },
            };

            promise = $http.get(`/api/dnslookup/${domain}`, config)
                .then(rsp => rsp.data.ips);
        }

        return $q.when(promise);
    };

    /**
     * Checks to see if string is valid IP address or IP range.
     * @param  {string} domain - IP address or IP address range.
     * @return {boolean} True if string contains valid IP addresses. False otherwise.
     */
    this.checkIP = function(domain) {
        return (new RegExp(Regex.listOfIpsRanges)).test(domain);
    };

    /**
     * Checks for valid <IP address>:<port> formatted string.
     * @param  {string} domain - Expected to be in <IP>:<port> format.
     * @return {boolean} Returns true if string is valid. False if otherwise.
     */
    this.checkIPWithPort = function(domain) {
        const parts = domain && domain.replace(/\s/g, '').split(':');

        if (_.isUndefined(parts) || parts.length !== 2) {
            return false;
        }

        return (new RegExp(Regex.ip)).test(parts[0]) && (new RegExp(Regex.port)).test(parts[1]);
    };

    /**
     * Returns true if entire list of domains matches either IP address, range,
     * or <ip>:<port> format. Returns false if a single IP string is invalid.
     * @param  {string} domains - String of comma-separated IP addresses or ranges.
     * @return {boolean}
     */
    this.checkMultipleDomains = function(domains) {
        const addresses = domains.replace(/\s/g, '').split(',');
        const self = this;

        return !_.some(addresses, function(address) {
            let valid = false;

            if (self.checkIPWithPort(address) || self.checkIP(address)) {
                valid = true;
            }

            return !valid;
        });
    };

    /**
     * Debouncing lookup call
     * @param {string} domain - Domain or ip string, in case of domain it's
     *     gonna make a resolution call
     * @param {string=} cloudId - current Cloud's uuid.
     * @return {object} - see return statement for details
     */
    let timer,
        promise;

    this.lookupDelayed = function(domain, cloudId) {
        const cancellerDeferred = $q.defer();
        const self = this;
        const deferred = $q.defer();

        $timeout.cancel(timer);

        if (promise) {
            promise.reject();
        }

        timer = $timeout(function() {
            self.lookup(domain, cancellerDeferred.promise, cloudId).then(function(ips) {
                deferred.resolve(ips);
            });
        }, 1000);
        promise = deferred;

        return {
            // Just the promise to be able to catch response
            promise: deferred.promise,
            // Cancel method in case response not neded anymore
            // cancelling timeout and http call
            cancel(reason) {
                cancellerDeferred.resolve(reason);
                $timeout.cancel(timer);
            },
        };
    };
}]);
