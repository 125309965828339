/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function aggEventFactory(Item) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/dataModel.Item
     * @desc
     *
     *     When using groupby in our API calls we get a list of groups with number
     *     of encounters and corresponding percentage relative to the whole set of data.
     *     Supported for events and logs.
     *
     *     Config keeps only 'value', 'count' and 'percentage' props.
     *
     * @author Alex Malitsky
     */
    class AggEvent extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config.value || '';
        }
    }

    return AggEvent;
}

aggEventFactory.$inject = [
    'Item',
];

angular.module('avi/events')
    .factory('AggEvent', aggEventFactory);
