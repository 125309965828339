/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name orderByEnumText
 * @returns {Object[]}
 * @description
 *
 *     Orders object's keys by it's object[key].options.text.value values.
 *     When text is not set uses keys instead.
 */
angular.module('aviApp').factory('orderByEnumText', ['naturalSort',
    function(naturalSort) {
        function orderByEnumText(enumDict) {
            return Object.keys(enumDict)
                .sort((a, b) => {
                    const
                        aTextObj = enumDict[a]['options']['text'],
                        bTextObj = enumDict[b]['options']['text'],
                        aText = aTextObj ? aTextObj['value'] : a,
                        bText = bTextObj ? bTextObj['value'] : b;

                    return naturalSort(aText, bText);
                })
                .map(key => ({ key, value: enumDict[key] }));
        }

        return orderByEnumText;
    },
]);
