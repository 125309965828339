/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './controller-site-selector.component.less';

const componentName = 'controller-site-selector';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.controllerSiteSelectorComponent}
 */
class ControllerSiteSelectorController {
    /** @override */
    constructor(
        $q,
        $location,
        $window,
        ControllerSiteCollection,
    ) {
        this.$q_ = $q;
        this.$location_ = $location;
        this.$window_ = $window;
        this.ControllerSiteCollection_ = ControllerSiteCollection;
        this.controllerSiteCollection = new ControllerSiteCollection();
    }

    /** @override */
    $onInit() {
        /**
         * Indicates if a controller site record is presented.
         * @type {boolean}
         */
        this.hasControllerSites = false;

        /**
         * Dropdown selected controller site ref value.
         * @type {string}
         */
        this.selectedControllerSiteRef = '';

        /**
         * Ref of currently opened controller site.
         * Used to restore selectedControllerSiteRef upon new selection.
         * @type {string}
         */
        this.currentControllerSiteRef = '';

        this.initControllerSiteSpecs_();
    }

    /**
     * Initialize controller site specs.
     * @protected
     */
    initControllerSiteSpecs_() {
        const collection = this.controllerSiteCollection;

        // set page size to 1
        collection.updateViewportSize(1, true, true)
            .then(() => {
                // make call to make a one site load to get total record count property
                collection.load()
                    .then(() => this.onFirstSiteLoad_());
            });
    }

    /**
     * Sets hasControllerSites based on presence of any sites.
     * Obtains current site's name. In absence of it, use host name to display it.
     * @protected
     */
    onFirstSiteLoad_() {
        const collection = this.controllerSiteCollection;
        const itemCount = collection.getTotalNumberOfItems();

        this.hasControllerSites = !!itemCount;

        if (this.hasControllerSites) {
            const controllerSiteItem = collection.items[0];
            const ref = controllerSiteItem.getRef();
            const currentHost = this.$location_.host();
            const { address } = controllerSiteItem.getConfig();

            if (address === currentHost) {
                this.setCurrentSiteRef_(ref);
            } else { // multiple records
                this.getControllerSiteRefByAddress_(currentHost)
                    .then(ref => {
                        if (ref) { //current host exists in list
                            this.setCurrentSiteRef_(ref);
                        } else {
                            this.setCurrentSiteRef_(currentHost);
                        }
                    });
            }
        }
    }

    /**
     * Sets current controller site ref. Updates selected ref as well.
     * @param {string} ref - Ref to be set
     * @protected
     */
    setCurrentSiteRef_(ref) {
        this.selectedControllerSiteRef = ref;
        this.currentControllerSiteRef = ref;
    }

    /**
     * Open new tab of new address when controller site changes.
     * Restore ngModel value to current controller site ref after new tab is opened.
     * @param {ControllerSite} selected
     */
    onControllerSiteChange(selected) {
        const
            addr = selected.getAddress(),
            url = `https://${addr}`;

        this.$window_.open(url, '_blank');
        this.selectedControllerSiteRef = this.currentControllerSiteRef;
    }

    /**
     * Load ControllerSite collection to get ControllerSite ref by address.
     * @param {string} address - IP or hosting address
     * @return {ng.$q.promise} to be resolved with a string
     * @protected
     */
    getControllerSiteRefByAddress_(address) {
        const collection = new this.ControllerSiteCollection_({ limit: 1 });

        collection.setParams({ address, fields: 'url' });

        return collection.load()
            .then(() => {
                if (collection.getTotalNumberOfItems()) {
                    return collection.items[0].getRef();
                }
            })
            .finally(() => collection.destroy());
    }

    /** @override */
    $onDestroy() {
        this.controllerSiteCollection.destroy();
    }
}

ControllerSiteSelectorController.$inject = [
    '$q',
    '$location',
    '$window',
    'ControllerSiteCollection',
];

/**
 * @name controllerSiteSelectorComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.ControllerSiteSelectorController} controller
 * @desc
 *      Dropdown for switching between controller sites.
 * @author Zhiqian Liu
 */
angular.module('avi/navigation').component('controllerSiteSelector', {
    controller: ControllerSiteSelectorController,
    templateUrl: `src/components/avi-header/${componentName}/${componentName}.component.html`,
});
