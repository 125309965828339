/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name poolServerHSScatterSeries
 * @description
 *
 *     Aggregated series for poolServer HS scatter plot. Keeps an aggregated series value (such
 *     as throughput), health score value and operation status of the corresponding pool Server.
 *
 *     Used by {@link PoolServersHsScatterMetric}.
 *
 */
angular.module('aviApp').factory('poolServerHSScatterSeries', [
'AggSeries',
function(AggSeries) {
    /**
     * @class
     * @extends AggSeries
     */
    return class poolServerHSScatterSeries extends AggSeries {
        constructor(args) {
            super(args);

            this.healthScore = NaN;
            this.operStatus = '';
        }

        /**
         * Saves healthscore API response into the Series. To be used by Metric only.
         * @param {Object} runtimeData
         */
        storeRuntimeInfo(runtimeData) {
            const { healthScore, operStatus } = runtimeData;

            this.healthScore = healthScore;
            this.operStatus = operStatus;
        }

        /**
         * @returns {number}
         * @public
         */
        getHealthScoreValue() {
            return this.healthScore;
        }

        /**
         *
         * @returns {string}
         * @public
         */
        getOperStatus() {
            return this.operStatus;
        }
    };
}]);
