/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
function parseNumberAsStringDirective() {
    const parseNumberAsStringLink = function(scope, elm, attr, ngModelCtrl) {
        function parseToString(viewValue) {
            if (viewValue === null) {
                return viewValue;
            }

            return String(viewValue);
        }

        function formatToNumber(modelValue) {
            if (modelValue === undefined) {
                return null;
            }

            return +modelValue;
        }

        ngModelCtrl.$parsers.push(parseToString);
        ngModelCtrl.$formatters.push(formatToNumber);
    };

    return {
        require: 'ngModel',
        restrict: 'A',
        link: parseNumberAsStringLink,
    };
}

/**
 * @ngdoc directive
 * @name  parseNumberAsString
 * @author Aravindh Nagarajan
 * @restrict A
 * @description
 *     For input type="number" that requires the model to be a string, for the protobuf defining the
 *     field as a string instead of an integer.
 * @example
 *     <input
 *         type="number"
 *         parse-number-as-string
 *      />
 */
angular.module('kit.ui.vantage.avi').directive('parseNumberAsString', parseNumberAsStringDirective);
