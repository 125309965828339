/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @description
 *     AutoScale Launch Config collection.
 */
angular.module('aviApp').factory('AutoScaleLaunchConfigCollection', [
    'Collection', 'AutoScaleLaunchConfig',
    function(Collection, AutoScaleLaunchConfig) {
        const AutoScaleLaunchConfigCollection = function(oArgs) {
            AutoScaleLaunchConfigCollection.superconstructor.call(this, oArgs);
            this.objectName_ = 'autoscalelaunchconfig';
            this.itemClass_ = AutoScaleLaunchConfig;
            this.windowElement_ = 'prof-autoscale-launch-config';
        };

        avi.inherit(AutoScaleLaunchConfigCollection, Collection);

        return AutoScaleLaunchConfigCollection;
    }]);

/**
 * @ngdoc service
 * @description
 *     AutoScale Launch Config collection item.
 */
angular.module('aviApp').factory('AutoScaleLaunchConfig', ['Item', function(Item) {
    const AutoScaleLaunchConfig = function(oArgs) {
        AutoScaleLaunchConfig.superconstructor.call(this, oArgs);
        this.objectName = 'autoscalelaunchconfig';
        this.windowElement = 'prof-autoscale-launch-config';
    };

    avi.inherit(AutoScaleLaunchConfig, Item);

    /**
     * @override
     * @return {data}
     */
    AutoScaleLaunchConfig.prototype.dataToSave = function() {
        const data = angular.copy(this.data.config);

        data.image_id = data.image_id || 'default';

        return data;
    };

    return AutoScaleLaunchConfig;
}]);
