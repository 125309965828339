/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import './waf-policy-psm-location.less';

/**
 * @ngdoc component
 * @name wafPolicyPsmLocation
 * @module waf/wafPolicyPsmLocation
 * @desc Component for displaying a WAF Policy PSM Location in the WAF Policy PSM Group modal.
 * @param location - WAF PSM Location
 * @param onDelete - Called when the user clicks the delete rule button.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-location',
    templateUrl: './waf-policy-psm-location.component.html',
})
export class WafPolicyPsmLocationComponent {
    @Input() location: WafPSMLocationConfigItem;
    @Input() preventEdit: boolean;
    @Input() locationMatches: number;

    @Output() onDelete: EventEmitter<void> = new EventEmitter();
    @Output() onEdit: EventEmitter<void> = new EventEmitter();

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    getEnableState(): number {
        return this.location.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable(): void {
        this.location.enable = !this.location.enable;
    }

    /**
     * Click handler for deleting a PSM location.
     */
    handleDelete($event: MouseEvent): void {
        $event.stopPropagation();
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a PSM location.
     */
    handleEdit($event: MouseEvent): void {
        $event.stopPropagation();
        this.onEdit.emit();
    }
}
