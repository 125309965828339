/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name MsMapGraphChartZoomFactory
 * @description
 * Wrapper of zooming behaviour for the {@link msMapGraphChart Microservice Map Graph Chart}.
 */
angular.module('aviApp').factory('MsMapGraphChartZoomFactory', function() {
    /**
     * @param {Object} args
     * @property {number} minScale
     * @property {number} maxScale
     * @property {number} width
     * @property {number} height
     * @property {d3.element} canvas
     * @property {jQuery} buttons
     * @property {jQuery} legend
     * @constructor
     */
    const MsMapGraphChartZoomFactory = function(args) {
        /**
         * Minimal scale available for the chart. Positive value.
         * @type {number}
         * @protected
         */
        this.minScale_ = args.minScale;

        /**
         * Maximum scale available for the chart. Positive value greater or equal to the minimal.
         * @type {number}
         * @protected
         */
        this.maxScale_ = args.maxScale;

        /**
         * D3 element with all zoom event listeners attached. Has a zoomable svg:group.wrapper
         * inside.
         * @type {d3.element}
         * @protected
         */
        this.canvas_ = args.canvas;

        /**
         * jQuery wrapped reference to the buttons "zoom-in" & "zoom-out" which should be
         * disabled depending on the active scale value and its boundaries.
         * @type {jQuery}
         * @protected
         */
        this.buttons_ = args.buttons;

        /**
         * Out of the SVG canvas legend of the chart.
         * @type {jQuery|d3.selection|jQuery[]|d3.selection[]}
         * @protected
         */
        this.legend_ = args.legend;

        /**
         * Height of the canvas and zoomable area.
         * @type {number}
         * @protected
         */
        this.height_ = args.height;

        /**
         * Width of the canvas and zoomable area.
         * @type {number}
         * @protected
         */
        this.width_ = args.width;

        /**
         * @type {d3.behavior} - D3 zoom behavior to be attached to the SVG element (where all
         * event listeners will be attached).
         * @public
         */
        this.behaviour = d3.behavior.zoom()
            .scaleExtent([
                this.minScale_,
                this.maxScale_,
            ])
            .size([
                this.width_,
                this.height_,
            ])
            .on('zoom', this.zoomEventHandler_.bind(this));

        this.buttonClick = _.throttle(this.buttonClick.bind(this), 499);

        this.updateUIElements_();
    };

    /**
     * Sets a canvas size and updates the zoom behavior.
     * @param {number} width
     * @param {number} height
     * @public
     */
    MsMapGraphChartZoomFactory.prototype.updateSize = function(width, height) {
        this.width_ = width;
        this.height_ = height;
        this.behaviour.size([width, height]);
    };

    /**
     * Zoom event handler which does actual job of moving and scaling of a zoomable area.
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.zoomEventHandler_ = function() {
        const
            e = d3.event,
            scale = Math.clipValue(e.scale, this.minScale_, this.maxScale_);

        /* keep it within the boundaries so that multiple zoom in&outs couldn't hide chart */
        const dx = Math.clipValue(e.translate[0],
            this.width_ * ((1 + this.minScale_) / 2 - scale),
            this.width_ * (1 - this.minScale_) / 2);

        const dy = Math.clipValue(e.translate[1],
            this.height_ * ((1 + this.minScale_) / 2 - scale),
            this.height_ * (1 - this.minScale_) / 2);

        this.behaviour.translate([dx, dy]);

        this.canvas_
            .select('g.wrapper')
            .attr('transform',
                `translate(${Math.toFixed3(dx)},${Math.toFixed3(dy)})scale(${scale})`);

        this.updateUIElements_();
    };

    /**
     * Checks if the next zoom step is available. Used to enable/disable zoom buttons.
     * @param {string} direction - "in" or "out".
     * @returns {boolean}
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.isNextZoomStepAvailable_ = function(direction) {
        return Math.toFixed3(this.behaviour.scale()) !==
            Math.toFixed3(this.nextScaleValue_(direction));
    };

    /**
     * Updates chart HTML elements states.
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.updateUIElements_ = function() {
        this.updateZoomButtonsStatus_();
        this.updateChartLegend_();
    };

    /**
     * Adds a transparent class for the legend HTML element when zoom scale exceeds provided
     * limit.
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.updateChartLegend_ = function() {
        let isHidden;

        function toggleTransparencyClass(elm) {
            if (elm instanceof jQuery) {
                elm.toggleClass('transparent', isHidden);
            } else if (elm instanceof d3.selection) {
                elm.classed('transparent', isHidden);
            }
        }

        if (!_.isUndefined(this.legend_)) {
            isHidden = this.behaviour.scale() > 1.15;

            if (Array.isArray(this.legend_)) {
                this.legend_.forEach(toggleTransparencyClass);
            } else {
                toggleTransparencyClass(this.legend_);
            }
        }
    };

    /**
     * Sets the appropriate attribute for the enable/disable buttons. This is implemented wo
     * angularJs to avoid calling $scope.$digest when caused by d3 zoom event handler as same
     * event handler is called when we zoom by ng-click handler (where we can't use
     * $scope.$digest at all).
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.updateZoomButtonsStatus_ = function() {
        const self = this;

        this.buttons_.attr('disabled', function() {
            let isDisabled = null;

            if ($(this).is('.zoom-in') && !self.isNextZoomStepAvailable_('in') ||
                $(this).is('.zoom-out') && !self.isNextZoomStepAvailable_('out')) {
                isDisabled = '';
            }

            return isDisabled;
        });

        return false;
    };

    /**
     * Calculates the next zoom.scale() step if requested by button click.
     * @param {string} direction - Are we zooming "in" or "out".
     * @returns {number}
     * @protected
     */
    MsMapGraphChartZoomFactory.prototype.nextScaleValue_ = function(direction) {
        return Math.clipValue(
            this.behaviour.scale() * (2 ** (direction === 'in' ? 1 : -1)),
            this.minScale_,
            this.maxScale_,
        );
    };

    /**
     * Zooms by button click.
     * @param {string} direction - Are we zooming "in" or "out".
     * @public
     */
    MsMapGraphChartZoomFactory.prototype.buttonClick = function(direction) {
        function coordinates(point) {
            const
                scale = behaviour.scale(),
                translate = behaviour.translate();

            return [
                (point[0] - translate[0]) / scale,
                (point[1] - translate[1]) / scale,
            ];
        }

        function point(coordinates) {
            const
                scale = behaviour.scale(),
                translate = behaviour.translate();

            return [
                coordinates[0] * scale + translate[0],
                coordinates[1] * scale + translate[1],
            ];
        }

        const zw = this.canvas_;
        const desiredScale = this.nextScaleValue_(direction);
        const trans0 = behaviour.translate();

        let
            { behaviour } = this,
            center0,
            coord0,
            center1;

        if (Math.toFixed3(behaviour.scale()) !== Math.toFixed3(desiredScale)) {
            behaviour.center([
                this.width_ / 2,
                this.height_ / 2,
            ]);

            zw.call(behaviour.event);// https://github.com/mbostock/d3/issues/2387

            center0 = behaviour.center();
            coord0 = coordinates(center0);

            behaviour.scale(desiredScale);

            center1 = point(coord0);

            behaviour.translate([
                trans0[0] + center0[0] - center1[0],
                trans0[1] + center0[1] - center1[1],
            ]);

            zw.transition()
                .duration(499)
                .call(behaviour.event);

            behaviour.center(null);
        }
    };

    /**
     * Sets the scale value and zoom state of the canvas to the minimal value of the scale extent.
     * @public
     */
    MsMapGraphChartZoomFactory.prototype.setDefaultScale = function() {
        const
            { behaviour } = this,
            defaultZoomValue = this.minScale_;

        if (behaviour.scale() !== defaultZoomValue) {
            this.canvas_.call(behaviour.event);

            if (this.minScale_ < 1) {
                behaviour.scale(defaultZoomValue);

                behaviour.translate([
                    Math.toFixed3(this.width_ * (1 - defaultZoomValue) / 2),
                    Math.toFixed3(this.height_ * (1 - defaultZoomValue) / 2),
                ]);
            } else { //assume it is equal to one TODO support greater then one if needed
                behaviour.scale(1);
                behaviour.translate([0, 0]);
            }

            this.canvas_.call(behaviour.event);
        }
    };

    return MsMapGraphChartZoomFactory;
});
