/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  MetricsDimensionCollection
 * @typedef {Object} MetricsDimensionCollection
 * @description
 *
 *     Collection of metric Series by dimensionId.
 *     List is provided by metrics GET calls - MetricsDimension per every dimension id.
 *
 */
angular.module('security.vs.vantage.avi').factory('MetricsDimensionCollection', [
'Collection', 'MetricsDimension',
function(Collection, MetricsDimension) {
    class MetricsDimensionCollection extends Collection {
        constructor(args = {}) {
            super(args);

            const { vsRef } = args;

            this.objectName_ += vsRef.slug();
            this.dimension_ = args.dimension;
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(MetricsDimensionCollection.prototype, {
        objectName_: 'analytics/metrics/virtualservice/',
        itemClass_: MetricsDimension,
        defaultDataSources_: [
            'list',
            'duration-ds',
        ],
        allDataSources_: {
            list: {
                source: 'MetricsDimensionListCollDataSource',
                transformer: 'MetricsDimensionDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'], //dos.avg_attack_count, timeseries
            },
            'duration-ds': {
                source: 'MetricsDimensionDurationCollDataSource',
                transformer: 'MetricsDimensionDataTransformer',
                transport: 'ListDataTransport',
                fields: ['duration'], //dos.sum_attack_duration, aggregated data
                dependsOn: 'config',
            },
            asname: {
                source: 'MetricsDimensionAsnameCollDataSource',
                transformer: 'MetricsDimensionAsnameDataTransformer',
                transport: 'ListDataTransport',
                fields: [
                    'asname-ipgroup',
                    'asname-asn',
                ],
                dependsOn: 'config',
            },
        },
        isStatic_: false,
    });

    return MetricsDimensionCollection;
}]);
