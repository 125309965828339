/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name DnsPolicyRuleQueryTypeMatchConfig
 * @description DnsPolicyRuleQueryTypeMatch ConfigItem class.
 */
const MatchConfigController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleQueryTypeMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
            config.query_type = config.query_type || [];
        }

        /** @override */
        toString() {
            const
                {
                    query_type: queryType,
                    match_criteria: matchCriteria,
                } = this.getConfig(),
                value = schemaService.getEnumValue('MatchOperation', matchCriteria).label,
                modifiedValue = value.replace(' in', '').toLowerCase(),
                queries = queryType.map(query => query.enumeration('DNS_RECORD_'));

            return `${modifiedValue} "${queries.join('", "').toUpperCase()}"`;
        }
    }

    angular.extend(DnsPolicyRuleQueryTypeMatchConfig.prototype, {
        type: 'DnsQueryTypeMatch',
        name: 'Query Type',
        defaultPath: 'dnsrule.match.query_type',
    });

    return DnsPolicyRuleQueryTypeMatchConfig;
};

MatchConfigController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('DnsPolicyRuleQueryTypeMatchConfig', MatchConfigController);
