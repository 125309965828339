/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { configUpdateDuration } from '../../../../../../js/constants/avi-cloud.constants';
import { waitFor } from '../../../../../../js/utilities/timer';
import './avi-cloud-modal.component.less';

/**
 * @constructor
 * @memberOf module:avi/cportal
 */
class AviCloudModalController {
    constructor(systemInfoService, Cluster, AviCloudService) {
        /**
         * @type {systemInfoService}
         * @protected
         */
        this.systemInfoService_ = systemInfoService;

        /**
         * Reference of Cluster Item
         * @type {Cluster}
         * @protected
         */
        this.Cluster_ = Cluster;

        /**
         * @type {AviCloudService}
         * @protected
         */
        this.aviCloudService_ = AviCloudService;

        /**
         * Instance of Cluster Item
         * @type {Cluster|null}
         * @protected
         */
        this.cluster_ = null;

        /**
         * Name of existing controller.
         * @type {string}
         */
        this.controllerName = '';
    }

    /** @override */
    $onInit() {
        this.controllerName = this.systemInfoService_.getLocalClusterName();
        this.cluster_ = new this.Cluster_({ id: 'default' });
    }

    /**
     * Open modal dialog for controller edit.
     */
    openEditControllerModal() {
        this.cluster_.edit()
            .then(() => this.updateControllerDetails_());
    }

    /**
     * Update controller details.
     * @return {ng.$q.promise}
     * @protected
     */
    updateControllerDetails_() {
        this.busy = true;

        return waitFor(configUpdateDuration)
            .then(() => this.systemInfoService_.load(true))
            .then(() => this.controllerName = this.systemInfoService_.getLocalClusterName())
            .finally(() => this.busy = false);
    }

    /**
     * Function to register Controller with Portal and notify its completion.
     * @return {ng.$q.promise}
     */
    registerController() {
        this.busy = true;

        const payload = {
            name: this.controllerName,
            description: this.controllerName, //FIXME remove once AV-69114 is resolved.
        };

        return this.aviCloudService_.registerController(payload)
            .then(() => { this.closeModal(); this.onComplete(); })
            .catch(error => this.error = error)
            .finally(() => this.busy = false);
    }

    /**
     * @override
     */
    $onDestroy() {
        this.cluster_.destroy();
    }
}

AviCloudModalController.$inject = [
    'systemInfoService',
    'Cluster',
    'AviCloudService',
];

/**
 * @name AviCloudModalComponent
 * @memberOf module:avi/cportal
 * @property {module:avi/cportal.systemUpdateModalBindings} bindings
 * @property {module:avi/cportal.SystemUpdateModalController} controller
 * @description
 *
 *     Component used to represent Avi cloud modal with opt-in features.
 *
 * @author Ram Pal
 */
angular.module('avi/cportal').component('aviCloudModal', {
    bindings: {
        closeModal: '&',
        onComplete: '&',
    },
    controller: AviCloudModalController,
    templateUrl: 'src/components/pages/administration/settings/avi-cloud-page/' +
        'avi-cloud-modal/avi-cloud-modal.component.html',
});
