/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2020] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import 'less/modal/infra-network-edit.less';

angular.module('aviApp').controller('NetworkCreateController', [
    '$scope', 'VRFContextCollection', 'infraCloudState',
    ($scope, VRFContextCollection, infraCloudState) => {
        $scope.$parent.modalScope = $scope;

        $scope.vrfCollection = new VRFContextCollection();

        /**
         * True if controller has custom VRF contexts.
         * @type {boolean}
         */
        $scope.hasCustomVrfContext = false;

        $scope.init = () => {
            const Cloud = infraCloudState.getCloud();

            $scope.Cloud = Cloud;

            if (Cloud) {
                $scope.vrfCollection.setParams({ 'cloud_ref.uuid': Cloud.id });

                Cloud.loadConfig()
                    .then(() => {
                        $scope.cloudType = Cloud.data.config.vtype;
                    });
            } else {
                $scope.cloudType = $scope.editable.collection.cloudType;
            }

            $scope.vrfCollection.load()
                .then(() => {
                    $scope.hasCustomVrfContext = $scope.vrfCollection.hasCustomVRFContext();
                });
        };

        $scope.$on('$destroy', () => {
            $scope.vrfCollection.destroy();
        });
    }]);
