/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('AlertConfigController', AlertConfigController);

AlertConfigController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'AlertConfigCollection',
];

function AlertConfigController($scope, CRUDGridConfig, AlertConfigCollection) {
    $scope.gridConfig = new CRUDGridConfig();
    $scope.collection = new AlertConfigCollection();

    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'action',
        title: 'Alert Action',
        transform(row) {
            return row.data.config.action_group_ref ?
                row.data.config.action_group_ref.name() : '-None-';
        },
    }, {
        name: 'category',
        title: 'Type',
        transform(row) {
            return row.data.config.category.enumeration();
        },
    }, {
        name: 'group',
        title: 'Alert Group',
        transform(row) {
            return row.data.config.source.enumeration();
        },
    }, {
        name: 'data.config.enabled',
        title: 'Enabled',
        sortBy: 'enabled',
        transform(row) {
            return row.data.config.enabled.toString().capitalize();
        },
    }, {
        name: 'obj',
        title: 'Object',
        template: '{{ ::config.templateFunctions.getObjectName(row.data.config) }}',
    }];

    $scope.gridConfig.expandedContainerTemplate =
        '<alert-config-grid-expander config="row.data.config"></alert-config-grid-expander>';

    $scope.gridConfig.expanderFunctions = {
        getEvents,
        getMetrics,
    };

    $scope.gridConfig.templateFunctions = {
        getObjectName,
    };

    /**
     * Template function to get the names of the events to be displayed in the grid.
     * @param  {object} rule - alert_rule object.
     * @return {string}
     */
    function getEvents(rule) {
        let separator = ', ';

        return _.map(rule.sys_event_rule, function(sysRule) {
            if (sysRule.not_cond === true) {
                separator = ', not ';
            }

            return sysRule.event_id.enumeration();
        }).join(separator);
    }

    /**
     * Template function to get the names of the metrics to be displayed in the grid.
     * @param  {object} rule - alert_rule object.
     * @return {string}
     */
    function getMetrics(rule) {
        return _.pluck(rule.metrics_rule, 'metric_id').join(', ');
    }

    /**
     * Template function to get the name of the object to be displayed in the grid.
     * @param  {AlertConfig} alert
     * @return {string} Name of object.
     */
    function getObjectName(config) {
        let output = 'N/A';

        if (config.obj_ref) {
            output = config.obj_ref.name();
        } else if (config.source === 'EVENT_LOGS') {
            output = 'All Instances';
        }

        return output;
    }

    $scope.$on('$destroy', function() {
        $scope.collection.destroy();
    });
}
