/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const componentName = 'user-list';

class UserListController {
    constructor(
        $element,
        $q,
        $sce,
        Auth,
        AviAlertService,
        CRUDGridConfig,
        UserCollection,
    ) {
        this.$element_ = $element;
        this.$q_ = $q;
        this.$sce_ = $sce;
        this.Auth = Auth;
        this.AviAlertService_ = AviAlertService;
        this.CRUDGridConfig_ = CRUDGridConfig;
        this.UserCollection_ = UserCollection;
    }

    $onInit() {
        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.UserCollection_(),
            permission: 'PERMISSION_USER',
            fields: [
                {
                    name: 'username',
                    title: 'Username',
                    sortBy: 'username',
                    template: '{{row.getConfig().username}}',
                }, {
                    name: 'is_superuser',
                    title: 'Super User',
                    sortBy: 'is_superuser',
                    template:
                        '{{row.getConfig().is_superuser | booleanLabel: "yes"}}',
                }, {
                    name: 'status',
                    title: 'Status',
                    sortBy: 'is_active',
                    template:
                        '{{row.getConfig().is_active | booleanLabel: "active"}}',
                }, {
                    name: 'full_name',
                    title: 'Full Name',
                    sortBy: 'full_name',
                    template: '{{row.getConfig().full_name}}',
                }, {
                    name: 'email',
                    title: 'Email',
                    sortBy: 'email',
                    template: '{{row.getConfig().email}}',
                }, {
                    name: 'tenant',
                    title: 'Tenant (Role)',
                    transform: row => {
                        const { access } = row.getConfig();

                        if (Array.isArray(access)) {
                            let str = '';

                            access.forEach(tenant => {
                                const roleName = tenant.role_ref.name();
                                let tenantName = 'All Tenants';

                                if (!tenant.all_tenants) {
                                    tenantName = tenant.tenant_ref.name();
                                }

                                str += `${tenantName} (${roleName}), `;
                            });
                            str = str.slice(0, -2);

                            return this.$sce_
                                .trustAsHtml(`<span title="${str}">${str}</span>`);
                        }

                        return '';
                    },
                    sortBy: 'default_tenant_ref__name',
                }, {
                    name: 'last_signed_in',
                    title: 'Last Signed In',
                    transform: row => {
                        const
                            config = row.getConfig(),
                            { activity = {} } = config;

                        if (!angular.isUndefined(activity) &&
                            activity.last_login_timestamp) {
                            let str = activity.logged_in ? 'Online since ' : 'Last login ';

                            str += moment
                                .utc(activity.last_login_timestamp)
                                .add(this.Auth.initData.timeDifference, 'seconds')
                                .fromNow();

                            if (!angular.isUndefined(activity.last_login_ip)) {
                                str += ` from  ${activity.last_login_ip}`;
                            }

                            return str;
                        }

                        return 'Never logged-in';
                    },
                },
            ],
        };

        gridConfigOptions.multipleactions = [
            {
                title: 'Delete',
                disabled(users) {
                    return !_.find(users, user => user.isDroppable());
                },
                do: rows => {
                    const promises = _.map(rows, row => row.drop());

                    this.$q_.all(promises)
                        .finally(() => {
                            this.gridConfig.collection.load();
                        });

                    return true;
                },
            }, {
                title: 'Activate',
                className: 'sel-enable-user icon-activate',
                disabled(users) {
                    return !_.any(users,
                        user => user.isEditable() && !user.getConfig().is_active);
                },
                do: users => {
                    _.each(users, user => {
                        if (user.isEditable() &&
                            !user.getConfig().is_active) {
                            user.getConfig().is_active = true;

                            user.save().catch(response => {
                                this.AviAlertService_.throw(response.data);
                                user.getConfig().is_active = false;
                            });
                        }
                    });

                    return true;
                },
            }, {
                title: 'Suspend',
                className: 'sel-disable-user icon-suspend',
                disabled(users) {
                    return !_.any(users,
                        user => user.isEditable() && user.getConfig().is_active);
                },
                do: users => {
                    _.each(users, user => {
                        if (user.isEditable() && user.getConfig().is_active) {
                            user.getConfig().is_active = false;

                            user.save().catch(response => {
                                this.AviAlertService_.throw(response.data);
                                user.getConfig().is_active = true;
                            });
                        }
                    });

                    return true;
                },
            },
        ];

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

UserListController.$inject = [
    '$element',
    '$q',
    '$sce',
    'Auth',
    'AviAlertService',
    'CRUDGridConfig',
    'UserCollection',
];

/**
 * @ngdoc component
 * @name userList
 * @author Aravindh Nagarajan
 * @description User list page with options to create/delete/edit a policy.
 */
angular.module('aviApp').component('userList', {
    controller: UserListController,
    templateUrl:
        'src/components/pages/administration/accounts/user/user-list.html',
});
