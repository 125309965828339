/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name dnsPolicyTab
 * @description
 *     DNS Policy tab in the Advanced Virtual Service create modal.
 *     Only applicable for DNS Virtual Services.
 * @param {DnsPolicyConfig} policy
 */
class DnsPolicyTabController {
    constructor(PolicyGridConfig, $templateCache, AviConfirmService) {
        this._PolicyGridConfig = PolicyGridConfig;
        this._$templateCache = $templateCache;
        this._AviConfirmService = AviConfirmService;
    }

    $onInit() {
        this.dnsPolicyGridConfig = new this._PolicyGridConfig({
            collection: this.policy,
            getRowId: row => row.getIndex(),
            fields: [{
                title: 'Index',
                name: 'index',
                template: '{{ row.getIndex() }}',
            }, {
                title: 'Enable',
                name: 'enable',
                template: '<checkbox ng-model="row.getConfig().enable"></checkbox>',
            }, {
                title: 'Name',
                name: 'name',
                template: '<span class="hide-text-overflow">{{ row.getName() }}</span>',
                allowResize: true,
            }, {
                title: 'Matching Rules',
                name: 'match',
                template:
                        `<policy-grid-match-column
                            rule="row">
                        </policy-grid-match-column>`,
                allowResize: true,
            }, {
                title: 'Action',
                name: 'action',
                template:
                        `<dns-policy-grid-action-column
                            rule="row">
                        </dns-policy-grid-action-column>`,
            }],
            controls: {
                create: {
                    title: 'Create rule',
                    do: () => this.policy.createRule(),
                },
            },
            actions: {
                createAt: ({ index }, position) => this.policy.createRule({ index, position }),
                search: (row, searchTerm) => row.searchBy(searchTerm),
            },
            singleactions: [{
                title: 'Delete',
                class: 'icon icon-trash',
                do: rule => this.policy.delete(rule),
            }, {
                title: 'Menu',
                template: require(
                    '../../../../../components/applications/virtualservice/policy/' +
                        'policy-grid/policy-grid-menu.tooltip.partial.html',
                ),
                edit: rule => this.policy.editRule(rule),
                move: rule => {
                    const { rules } = this.policy;

                    this._AviConfirmService
                        .prompt('policy-grid-prompt-index', { rule, rules })
                        .then(data => this.policy.moveRule(rule, data));
                },
                duplicate: rule => {
                    this._AviConfirmService
                        .prompt('policy-grid-prompt-index', { rules: this.policy.rules })
                        .then(data => this.policy.duplicateRule(rule, data));
                },
            }],
            expandTemplate:
                    `<policy-grid-expander
                        row="row"
                        config="config">
                    </policy-grid-expander>`,
        });
    }
}

DnsPolicyTabController.$inject = [
        'PolicyGridConfig',
        '$templateCache',
        'AviConfirmService',
];

angular.module('aviApp').component('dnsPolicyTab', {
    controller: DnsPolicyTabController,
    bindings: {
        policy: '<',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-dns-policy/' +
            'dns-policy-tab/dns-policy-tab.html',
});
