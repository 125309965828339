/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @private
 */
function startsWith(str) {
    return this.indexOf(str) === 0;
}

String.prototype.endsWith = function(suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

String.prototype.startsWith = startsWith;

String.prototype.beginsWith = startsWith;

String.prototype.contains = function(str, insensitive = false) {
    if (insensitive) {
        return this.toLowerCase().indexOf(str.toLowerCase()) >= 0;
    } else {
        return this.indexOf(str) >= 0;
    }
};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.obj_type = function() {
    const fragment = this.split('#');
    let url = this;

    if (fragment.length) {
        [url] = fragment;
    }

    const parts = url.split('/');

    return parts[parts.length - 2];
};

String.prototype.slug = function() {
    const fragment = this.split('#');
    let url = this;

    if (fragment.length) {
        [url] = fragment;
    }

    const parts = url.split('/');

    return parts[parts.length - 1];
};

/**
 * Returns name part of the ref.
 * @return {string}
 */
String.prototype.name = function() {
    /**
     * @type {number} Index of # in ref.
     */
    const hashIndex = this.indexOf('#');

    //# should not be the first element.
    if (hashIndex > 0) {
        return this.slice(hashIndex + 1);
    }

    return '';
};

(function() {
    const dictionary = {
        TCP: 'TCP',
        UDP: 'UDP',
        DNS: 'DNS',
        ICMP: 'ICMP',
        HTTP: 'HTTP',
        HTTPS: 'HTTPS',
        GZIP: 'GZIP',
        SDCH: 'SDCH',
        IP: 'IP',
        SSL2: 'SSL2',
        SSL3: 'SSL3',
        TLS1: 'TLS1',
        TLS1_1: 'TLS1-1', /* For classname only*/
        TLS1_2: 'TLS1-2',
        TLS1_3: 'TLS1-3',
        L4: 'L4',
        L7: 'L7',
        URI: 'URI',
        SYSLOG: 'SYSLOG',
        SSL: 'SSL',
        VS: 'VS',
        TLS: 'TLS',
        VMDK: 'VMDK',
        QCOW2: 'QCOW2',
        LDAP: 'LDAP',
        NW: 'NW',
        SE: 'SE',
        SSH: 'SSH',
        APIC: 'APIC',
        AWS: 'AWS',
        VIP: 'VIP',
        CC: 'CC',
        VNIC: 'VNIC',
        LB: 'LB',
        GSLB: 'GSLB',
        VCENTER: 'vCenter',
        OPENSTACK: 'OpenStack',
        REALTIME: 'Real Time',
        ROLLINGWINDOW: 'Rolling Window',
        DEDICATED_PAIR: 'Dedicated',
        HDR: 'Header',
        RSP: 'Response',
        RESP: 'Response',
        CONN: 'Connection',
        NETHSM: 'netHSM',
        DOCKER_UCP: 'Docker Universal Control Plane',
        PPP: 'PPP',
        ARAP: 'ARAP',
        PT: 'PT',
        RCMD: 'RCMD',
        X25: 'X25',
        NASI: 'NASI',
        FWPROXY: 'FWPROXY',
        TACACS_PLUS: 'TACACS+',
        SAFENET_LUNA: 'SafeNet Luna',
        UNAVAIL: 'Unavailable',
        MAXMIND: 'MaxMind',
        SAML: 'SAML',
        SSE: 'SSE',
        KMS: 'KMS',
        REQ: 'Request',
        REQ_DATA: 'Request Data',
        PROTOCOL_TYPE_TCP_PROXY: 'TCP',
        PROTOCOL_TYPE_TCP_FAST_PATH: 'Fast Path',
        PROTOCOL_TYPE_UDP_FAST_PATH: 'UDP',
        V4_V6: 'IPv4 and IPv6',
        V4_ONLY: 'Only IPv4',
        V6_ONLY: 'Only IPv6',
        DNS_RECORD_A: 'A/AAAA Record',
        DNS_RECORD_AAAA: 'AAAA Record',
        DNS_RECORD_SRV: 'SRV Record',
        DNS_RECORD_CNAME: 'CNAME Record',
        DNS_RECORD_NS: 'NS Record',
        CO_GE: '>=',
        CO_LE: '<=',
        CO_LT: '<',
        CO_GT: '>',
        CO_EQ: '=',
        CO_NE: '!=',
    };

    /**
     * Modifies a string, typically an enum, by first removing specified prefixes, then
     * stripping underscores, comparing with the dictionary, and formatting capitalization.
     * @param  {string|string[]} prefix - A single string to strip from the enum, or an array
     *    of strings. Stops on first matching value, so order matters.
     * @return {string}
     */
    String.prototype.enumeration = function(prefix) {
        let value = this;

        if (!Array.isArray(prefix)) {
            prefix = [prefix];
        }

        _.any(prefix, function(pre) {
            if (value.startsWith(pre)) {
                value = value.replace(pre, '');

                return true;
            }
        });

        if (!_.isUndefined(dictionary[value])) {
            return dictionary[value];
        }

        return _.map(value.split('_'), function(p) {
            if (!_.isUndefined(dictionary[p])) {
                return dictionary[p];
            } else {
                return p.toLowerCase().capitalize();
            }
        }).join(' ');
    };
})();

String.prototype.inArray = function(arr) {
    for (let i = 0, l = arr.length; i < l; i++) {
        if (arr[i] === this) {
            return true;
        }
    }

    return false;
};

String.prototype.snakeToCamelCase = function() {
    return this.replace(/(_[A-Za-z0-9])/g, function(matches) {
        return matches[1].toUpperCase();
    });
};

/**
 * Converts camelcase strings to dashcase.
 * @return {String}
 */
String.prototype.camelCaseToDash = function() {
    return this.replace(/[A-Z]/g, function(letter, pos) {
        return (pos ? '-' : '') + letter.toLowerCase();
    });
};
