/*************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './license-summary.component.less';
import { LicensingPageController } from '../licensing-page.component';

const
    componentTag = 'license-summary',
    { UPDATE_LICENSE_SUMMARY } = LicensingPageController;

/**
 * @constructor
 * @memberOf module:avi/licensing
 */
class LicenseSummaryController {
    constructor($scope, LicenseUsage, licenseSummaryService) {
        this.$scope = $scope;
        this.LicenseUsage = LicenseUsage;
        this.licenseSummaryService = licenseSummaryService;

        /**
         * List of licenses used in table.
         * @type {Object[]}
         */
        this.licenseSummaryData = [];

        /**
         * True when license summary is being loaded.
         * @protected
         */
        this.busy_ = false;
    }

    /**
     * Getter for busy flag.
     * @returns {boolean}
     */
    get busy() {
        return this.busy_;
    }

    /**
     * Setter for busy flag.
     * @param {boolean} isBusy
     */
    set busy(isBusy) {
        this.busy_ = !!isBusy;
    }

    /**
     * @listens module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @override
     */
    $onInit() {
        this.licenseData = new this.LicenseUsage();
        this.getLicenseSummaryData_();

        //Fires upon user updating data in parent controller
        this.$scope.$on(UPDATE_LICENSE_SUMMARY, this.getLicenseSummaryData_);
    }

    /**
     * Makes api call for licenseSummary.
     * @protected
     */
    getLicenseSummaryData_ = () => {
        this.busy = true;

        this.licenseData.load()
            .then(() => {
                this.setLicenseTypeList_(this.licenseData.config);
            })
            .finally(() => {
                this.busy = false;
            });
    };

    /**
     * Builds licenseSummaryData for summary list in view.
     * @param {LicenseHashMap} hashMap - to be used for lookup
     * @param {LicenseType[]} limitKeys
     * @param {LicenseTierType[]} tierTypes
     * @protected
     */
    setLicenseTypeList_({ licenseHashMap: hashMap, limitKeys, tierTypes }) {
        this.licenseSummaryData =
            this.licenseSummaryService.getLicenseListByType(hashMap, tierTypes, limitKeys);
    }

    /** @override */
    $onDestroy() {
        this.licenseData.destroy();
    }
}

LicenseSummaryController.$inject = [
    '$scope',
    'LicenseUsage',
    'licenseSummaryService',
];

/**
 * @name licenseSummaryComponent
 * @memberOf module:avi/licensing
 * @author Akul Aggarwal
 * @description Summary read-only table for licenses used and available.
 */
angular.module('avi/licensing').component('licenseSummary', {
    controller: LicenseSummaryController,
    templateUrl: 'src/components/pages/administration/settings/licensing-page/' +
        `${componentTag}/${componentTag}.component.html`,
});
