/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
/** @alias uploadedFileDecoder */
function getUploadedFileDecoder($window, TextDecoderLite, base64js) {
    /**
     * Converts ArrayBuffer with file content into regular or base64-encoded string.
     * @param {ArrayBuffer} arrayBuffer
     * @param {boolean=} intoBase64
     * @return {string}
     */
    return function uploadedFileDecoder(arrayBuffer, intoBase64 = false) {
        // need this for base64js and TextDecoderLite, TextDecoder doesn't seem to care
        const uint8arr = new Uint8Array(arrayBuffer);

        if (intoBase64) {
            return base64js.fromByteArray(uint8arr);
        } else {
            // setting this in runtime so that we can unit test both options
            const Decoder = $window.TextDecoder || TextDecoderLite;
            const textDecoder = new Decoder('utf-8');

            return textDecoder.decode(uint8arr);
        }
    };
}

/**
 * @ngdoc service
 * @name uploadedFileDecoder
 * @author Alex Malitsky
 * @desc
 *
 *     Converts ArrayBuffer with file content into regular or base64-encoded string.
 */
angular.module('aviApp').factory('uploadedFileDecoder', [
        '$window', 'TextDecoderLite', 'base64js',
        getUploadedFileDecoder,
]);
