/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './item-alert-level-icon.less';

/**
 * @ngdoc component
 * @name itemAlertLevelIcon
 * @param {string} level - high, low, medium.
 * @description
 *
 *     Circle colored by Item's alert severity.
 */
const componentName = 'item-alert-level-icon';

const template = `<div class = "${componentName}__icon"
        ng-class = "'${componentName}__icon_level_' + $ctrl.level"></div>`;

class itemAlertLevelIconController {
    constructor($element) {
        this.$elem_ = $element;
    }

    $onInit() {
        this.$elem_.addClass(componentName);
    }
}

itemAlertLevelIconController.$inject = ['$element'];

angular.module('aviApp').component('itemAlertLevelIcon', {
    controller: itemAlertLevelIconController,
    bindings: {
        level: '<',
    },
    template,
});
