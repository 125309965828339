/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/application/summary.less';

/**
 * @ngdoc controller
 * @name InfraDashboardController
 */
angular.module('aviApp').controller('InfraDashboardController', [
'$scope', 'ServiceEngineCollection', function($scope, ServiceEngineCollection) {
    const self = this;

    function search() {
        self.collection.search(self.searchString);
    }

    this.sortBy = 'name';
    this.searchString = '';

    this.collection = new ServiceEngineCollection({ sortBy: this.sortBy });

    this.collection.subscribe(['health', 'runtime', 'alert']);

    this.sort = function() {
        this.collection.sort(this.sortBy);
    };

    this.search = _.debounce(search, 250);//evoked by input

    $scope.dashLegendCollapsed = true;

    $scope.$on('$destroy', function() {
        self.collection.destroy();
    });
}]);
