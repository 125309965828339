/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ClusterNodeListCollDataSource', ['ListCollDataSource',
function(ListCollDataSource) {
    function ClusterNodeListCollDataSource(args) {
        ClusterNodeListCollDataSource.superconstructor.call(this, args);
    }

    avi.inherit(ClusterNodeListCollDataSource, ListCollDataSource);

    ClusterNodeListCollDataSource.prototype.hasSearch = false;
    ClusterNodeListCollDataSource.prototype.hasSorting = false;
    ClusterNodeListCollDataSource.prototype.hasPagination = false;

    ClusterNodeListCollDataSource.prototype.defaultParams_ = {};

    return ClusterNodeListCollDataSource;
}]);
