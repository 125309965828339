/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  TrafficCloneProfile
 * @description  TrafficCloneProfile item.
 */
function TrafficCloneProfileFactory(
        Item,
        Cloud,
        defaultValues,
        getSubnetString,
        getSubnetObject,
) {
    class TrafficCloneProfile extends Item {
        /** @override */
        beforeEdit() {
            const {
                clone_servers: cloneServers,
            } = this.getConfig();

            if (Array.isArray(cloneServers)) {
                cloneServers.forEach(
                    server => server.subnet = getSubnetString(server.subnet),
                );
            } else {
                this.addCloneServer();
            }
        }

        /** @override*/
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { clone_servers: cloneServers } = config;

            if (Array.isArray(cloneServers)) {
                cloneServers.forEach(
                    server => server.subnet = getSubnetObject(server.subnet),
                );
            }

            delete config['cloud_ref_data'];

            return config;
        }

        /**
         * Adds a CloneServer object to config.clone_servers.
         */
        addCloneServer() {
            const config = this.getConfig();

            config.clone_servers = config.clone_servers || [];

            const defaultCloneServer =
                defaultValues.getDefaultItemConfigByType('cloneserver') || {};

            config.clone_servers.push(defaultCloneServer);
        }

        /**
         * Removes a CloneServer object from config.clone_servers.
         * @param {number} index - Index of the CloneServer object to remove.
         */
        removeCloneServer(index) {
            const { clone_servers: cloneServers } = this.getConfig();

            cloneServers.splice(index, 1);
        }

        /**
         * Removes all CloneServer objects from config.clone_servers and adds an empty one.
         * Called when changing clouds.
         */
        removeAllCloneServers() {
            const { clone_servers: cloneServers } = this.getConfig();

            cloneServers.length = 0;
            this.addCloneServer();
        }

        /**
         * Returns a number of any clone servers present in config.
         * @returns {number}
         * @public
         */
        getNumberOfCloneServers() {
            return this.getConfig()['clone_servers'].length;
        }

        /**
         * Returns true if there is at least one configured (non empty/default) server in
         * config.
         * @returns {boolean}
         * @public
         */
        hasConfiguredCloneServers() {
            const { clone_servers: servers } = this.getConfig();

            return !!_.find(servers,
                ({ ip_address: ipAddress, mac, network_ref: networkRef, subnet }) =>
                    ipAddress || mac || networkRef || subnet);
        }

        /**
         * Returns true if the network should be shown as a dropdown, in cases of write access
         * clouds like vCenter, OpenStack, and AWS. Otherwise, returns false to show an input
         * for the subnet.
         * @return {boolean}
         */
        allowNetworkConfiguration() {
            const { cloud_ref_data: cloudConfig } = this.getConfig();

            return Cloud.hasSubnetList(cloudConfig['vtype']);
        }

        /**
         * Returns cloud ref this traffic clone profile is set up with.
         * @returns {string}
         * @public
         */
        getCloudRef() {
            return this.getConfig()['cloud_ref'] || '';
        }

        /**
         * Sets cloud ref to the string passed.
         * @param {string} cloudRef
         * @param {Cloud.data.config} cloudConfig
         * @public
         */
        setCloudRef(cloudRef, cloudConfig) {
            const config = this.getConfig();

            config['cloud_ref'] = cloudRef;
            config['cloud_ref_data'] = cloudConfig;
        }
    }

    angular.extend(TrafficCloneProfile.prototype, {
        objectName: 'trafficcloneprofile',
        windowElement: 'traffic-clone-profile-modal',
        params: {
            include_name: true,
            join: 'cloud_ref',
        },
    });

    return TrafficCloneProfile;
}

TrafficCloneProfileFactory.$inject = [
        'Item',
        'Cloud',
        'defaultValues',
        'getSubnetString',
        'getSubnetObject',
];

angular.module('aviApp').factory('TrafficCloneProfile', TrafficCloneProfileFactory);
