/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './cloud-status-tooltip.component.less';
import { UpgradeService } from 'services/upgrade/upgrade.service';
import { CloudService } from 'services/cloud-service/cloudService';

const componentTag = 'cloud-status-tooltip';

/**
 * @constructor
 * @memberOf module:avi/cloud
 * @mixes module:avi/cloud.cloudStatusTooltipBindings
 * @author Akul Aggarwal
 */
class CloudStatusTooltipController {
    /**
     * Assigns seImage data after transformation.
     * @param {Object[]} seImageStatuses - status info for diff versions as delivered by API
     * @protected
     */
    static formatStateData_(seImageStatuses) {
        return seImageStatuses.map(CloudStatusTooltipController.formatState_);
    }

    /**
     * Formats vars needed for tooltip display.
     * @param {string} version - original unformatted version as returned by API
     * @param {string} state - original state returned by API, not yet looked up
     * @return {Object.<string, string>} - versionNumber and stateText to show per se
     * @protected
     */
    static formatState_({ version, state }) {
        const result = {};

        /**
         * Actual formatted version number.
         * @type {string}
         */
        result.versionNumber = UpgradeService.getVersionAsHash(version).version;

        /**
         * Possible prefixes to filter out, as found in ImageState Schema enum.
         * @type {string[]}
         */
        const prefixes = [
            'IMG_STATE',
            'IMG_GEN',
        ];

        /**
         * State of version (in progress, complete, etc)
         * @type {string}
         */
        result.stateText = state.enumeration(prefixes);

        return result;
    }

    constructor() {
        /**
         * Repeated list with each version and it's info.
         * @type {Object[]}
         */
        this.seImageStates = [];
    }

    /** @override */
    $onInit() {
        this.updateSeImageStates_();
        this.registerCloudEventListener_();
    }

    /**
     * Updates version info data.
     * @protected
     */
    updateSeImageStates_ = () => {
        this.seImageStates.length = 0;

        const { se_image_state: seImageStatuses } = this.getCloudStatus_();

        if (seImageStatuses) {
            this.seImageStates = CloudStatusTooltipController.formatStateData_(seImageStatuses);
        }
    };

    /**
     * Returns cloud name.
     * @return {string}
     */
    getCloudName() {
        return this.cloud.getName();
    }

    /**
     * Returns cloud's status object.
     * @return {Object}
     * @protected
     */
    getCloudStatus_() {
        return this.cloud.getRuntimeData();
    }

    /**
     * Returns class which determines color of cloud icon.
     * @return {string}
     */
    getCloudStatusIconName() {
        const { state } = this.getCloudStatus_();

        return CloudService.getStatusIconClass(state);
    }

    /**
     * Returns cloud reason, if provided by API.
     * @return {string}
     */
    getCloudStatusReason() {
        return this.getCloudStatus_().reason || '';
    }

    /**
     * Registers listener to act upon cloud object updating.
     * @protected
     */
    registerCloudEventListener_() {
        this.cloud.on('itemChange', this.updateSeImageStates_);
    }

    /** @override */
    $onDestroy() {
        this.cloud.unbind('itemChange');
    }
}

const templateUrl =
    `/src/components/infrastructure/clouds/${componentTag}/${componentTag}.component.html`;

/**
 * @mixin cloudStatusTooltipBindings
 * @memberOf module:avi/cloud
 * @property {module:avi/cloud.Cloud} cloud - cloud instance
 */

/**
 * @name cloudStatusTooltipComponent
 * @ngdoc component
 * @memberOf module:avi/cloud
 * @property {module:avi/cloud.CloudStatusTooltipController} controller
 * @property {module:avi/cloud.cloudStatusTooltipBindings} bindings
 * @author Akul Aggarwal
 * @description Component for tooltip found in Cloud table, upon hover of status icon.
 */
angular.module('avi/cloud').component('cloudStatusTooltip', {
    bindings: {
        cloud: '<',
    },
    controller: CloudStatusTooltipController,
    templateUrl,
});
