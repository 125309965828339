/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name  WebhookController
 * @description
 *     Controller for Webhook List view.
 */
class WebhookController {
    constructor(CRUDGridConfig, WebhookCollection) {
        this.collection = new WebhookCollection();

        const config = {
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: 'Name',
            }, {
                name: 'data.config.callback_url',
                title: 'Callback URL',
            }, {
                name: 'data.config.verification_token',
                title: 'Verification Token',
            }],
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

WebhookController.$inject = [
        'CRUDGridConfig',
        'WebhookCollection',
];

angular.module('aviApp').controller('WebhookController', WebhookController);
