/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import './avi-confirmation.less';

/**
 * @param {string} title Title for the confirmation popup.
 * @param {Function} onSubmit Function to be called when the Submit button is clicked.
 * @param {Function} onCancel Function to be called when the Cancel button or close button is
 *     clicked.
 */
@Component({
    selector: 'avi-confirmation',
    templateUrl: './avi-confirmation.html',
})
export class AviConfirmationComponent {
    @Input() title: string = '';

    @Output() onSubmit = new EventEmitter();
    @Output() onCancel = new EventEmitter();

    /**
     * Calls the onSubmit binding.
     */
    handleSubmit() {
        this.onSubmit.emit();
    }

    /**
     * Calls the onCancel binding.
     */
    handleCancel() {
        this.onCancel.emit();
    }
}
