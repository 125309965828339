/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Resolving 1st issue: VS VIP Network Unknown
 * The resolution is: get the right network object by selecting it from dropdown list
 * and then setting the right subnet to that network and of course save it. We have to combine the
 * list of configured subnets and discovered subnets to be displayed after selecting a network. If
 * the subnet to be updated with a pool address is in the configured subnets list, then we add
 * the address to the static_ipaddr_tmp array. Otherwise, we have to add a new subnet object into
 * the configured subnets list.
 */

angular.module('aviApp').controller('VirtualServiceNetworkUnknownResolutionController', [
'$scope', '$controller',
function($scope, $controller) {
    //$scope.NetworkResolution comes from NetworkResolutionController.
    $controller('NetworkResolutionController', { $scope });
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
     * Init function called by AviModal when modal is opened. Gets vs_runtime object.
     */
    $scope.init = function() {
        $scope.error = null;
        $scope.data = {
            vs_runtime: $scope.NetworkResolution.vs_runtime,
            addManualSubnet: true,
            loading: true,
        };
        $scope.busy = true;
        $scope.editable.setPristine();

        // Need runtime object just to get the recommendation
        const slug = $scope.editable.data.config.url.slug();

        $scope.NetworkResolution.getRuntime(slug).then(function() {
            $scope.data.vs_runtime = $scope.NetworkResolution.vs_runtime;
        }).finally(function() {
            $scope.data.loading = false;
            $scope.busy = false;
            $scope.error = $scope.NetworkResolution.error;
        });
    };

    /*---------- When a network has been selected ----------*/
    /**
     * Called when user selects network from network dropdown. Loads the network and populates list
     * of subnets and networkruntime.
     */
    $scope.networkChanged = function() {
        $scope.data.loading = true;
        $scope.busy = true;

        const networkSlug = $scope.data.network_ref.slug();

        $scope.NetworkResolution.loadNetwork(networkSlug).then(function() {
            $scope.network = $scope.NetworkResolution.network;
            $scope.data.addManualSubnet = true;

            return $scope.NetworkResolution.getNetworkRuntime($scope.network.data.config.uuid);
        }).then(function() {
            $scope.data.networkruntime = $scope.NetworkResolution.networkruntime;
            getSubnets();
            $scope.setSelectedSubnet();
        }).finally(function() {
            $scope.error = $scope.NetworkResolution.error;
            $scope.data.loading = false;
            $scope.busy = false;
        });
    };

    /**
     * Gets a list of all subnets from configured_subnets and disocvery subnets to be displayed in
     * the dropdown subnet field.
     */
    function getSubnets() {
        const configuredSubnets = $scope.network.data.config.configured_subnets;
        const discoveredSubnets = $scope.network.data.discovery.ip_subnet;

        $scope.NetworkResolution.getSubnets(configuredSubnets, discoveredSubnets);
        $scope.data.subnetsList = $scope.NetworkResolution.subnetsList;
    }
    /*------------------------------------------------------*/

    /**
     * Sets selected_subnet. Called when network has changed and  when user toggles between adding
     * manual subnet or choosing existing one.
     */
    $scope.setSelectedSubnet = function() {
        const {
            addManualSubnet,
            subnetsList,
        } = $scope.data;

        $scope.NetworkResolution.setSelectedSubnet(addManualSubnet, subnetsList);
        $scope.data.selected_subnet = $scope.NetworkResolution.selected_subnet;
        $scope.selectSubnet();
    };

    /**
     * Called when user hits Save button. Saves VS along with selected network.
     */
    $scope.submit = function() {
        $scope.busy = true;
        $scope.saveVS().then(function() {
            return $scope.saveNetwork();
        }).then(function() {
            return $scope.editable.disableAndEnable();
        }).then(function() {
            return $scope.editable.checkForIssues();
        })
            .then(function() {
                $scope.editable.dismiss(true);
            })
            .catch(function(rsp) {
                $scope.error = rsp.data.error;
            })
            .finally(function() {
                $scope.busy = false;
            });
    };
}]);
