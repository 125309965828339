/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('AuthProfileCreateController', [
'$scope', 'Regex', function($scope, Regex) {
    $scope.Regex = Regex;

    $scope.$parent.modalScope = $scope;//AviModal thing

    let oldBaseDN;

    $scope.init = function() {
        oldBaseDN = $scope.editable.data.config.ldap && $scope.editable.data.config.ldap.base_dn;
    };

    $scope.changeMethod = function() {
        const
            config = $scope.editable.getConfig(),
            { ldap: ldapConfig } = config,
            { ldap: defaultLdapConfig } = $scope.editable.getDefaultConfig();

        if (ldapConfig.bind_as_administrator) {
            delete ldapConfig.user_bind;
            ldapConfig.settings = defaultLdapConfig.settings;
        } else {
            delete ldapConfig.settings;
            ldapConfig.user_bind = defaultLdapConfig.user_bind;
        }
    };

    $scope.removeUserGroup = function(index) {
        if ($scope.editable.data.config.http.require_user_groups.length > 1) {
            $scope.editable.data.config.http.require_user_groups.splice(index, 1);
        }
    };

    $scope.addUserGroup = function() {
        if (!$scope.editable.data.config.http.require_user_groups.length) {
            $scope.editable.data.config.http.require_user_groups = [''];
        } else {
            $scope.editable.data.config.http.require_user_groups.push('');
        }
    };

    /**
     * When Base DN is modified, adds its string to the end of Admin Bind DN, User Search DN, and
     * Group Search DN fields.
     * @param {string} oldValue - Previous ngModel value of Base DN, before change.
     */
    $scope.addBaseDNSuffix = function() {
        const { ldap } = $scope.editable.getConfig();

        const suffix = ldap.base_dn || '';
        const { settings } = ldap;

        settings.admin_bind_dn = findAndSplice(settings.admin_bind_dn, oldBaseDN) + suffix;
        settings.user_search_dn = findAndSplice(settings.user_search_dn, oldBaseDN) + suffix;
        settings.group_search_dn = findAndSplice(settings.group_search_dn, oldBaseDN) + suffix;

        oldBaseDN = suffix;
    };

    /**
     * Gets the last index of value(suffix) from the target string and returns a sliced version of
     * that string.
     * @param  {string} field - String where suffix must be removed.
     * @param  {string} value - Suffix string.
     * @return {string} modified field string with suffix removed.
     */
    function findAndSplice(field, value) {
        if (!field) {
            return '';
        }

        const index = field.lastIndexOf(value);

        return index > -1 ? field.slice(0, index) : field;
    }
}]);
