/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('TenantUsersDataTransformer', ['RevisedDataTransformer',
function(RevisedDataTransformer) {
    function TenantUsersDataTransformer(args) {
        TenantUsersDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(TenantUsersDataTransformer, RevisedDataTransformer);

    TenantUsersDataTransformer.prototype.processResponse = function(resp) {
        const
            allTenantsKey = '__any_tenant|', //should be in sync with TenantUsersCollDataSource
            resultsHash = {};

        let results = [];

        if ('results' in resp.data && Array.isArray(resp.data.results)) {
            _.each(resp.data.results, function(user) {
                if (Array.isArray(user.access)) {
                    user.access.forEach(function(access) {
                        const tenantId =
                            access.all_tenants ? allTenantsKey : access.tenant_ref.slug();

                        if (!(tenantId in resultsHash)) {
                            resultsHash[tenantId] = {
                                tenantId,
                                users: [],
                            };
                        }

                        resultsHash[tenantId].users.push({
                            user_ref: user.url,
                            role_ref: access.role_ref,
                        });
                    });
                }
            });

            //for conformity translates hash into array
            results = _.values(resultsHash);
        }

        resp.data.results = results;
        resp.data.count = results.length;

        return resp;
    };

    return TenantUsersDataTransformer;
}]);
