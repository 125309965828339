/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../waf-log-entry.less';
import '../waf-logs-list.less';

/**
 * @ngdoc component
 * @name  wafWhitelistLogsList
 * @description Component for a list of WAF Whitelist Logs.
 * @param {Object[]} logs - Array of whitelist log entries.
 * @module waf/wafWhitelistLogsList
 * @author alextsg
 */
angular.module('logs.vantage.avi').component('wafWhitelistLogsList', {
    bindings: {
        logs: '<',
    },
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'waf-whitelist-logs-list/waf-whitelist-logs-list.component.html',
});
