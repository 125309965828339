/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './pool-group-deployment-policy-modal.less';

/**
 * @ngdoc component
 * @name  poolGroupDeploymentPolicyModal
 * @description
 *     Component for Pool Group Deployment Policy modal.
 * @param  {PoolGroupDeploymentPolicy} editable
 * @param  {Function} closeModal - Closes modal.
 */
class PoolGroupDeploymentPolicyModalController {
    constructor(Schema, Regex, AlertMetricsCollection) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.rulesConfig = {
            fields: [{
                name: 'metric_id',
                title: 'Metric ID',
                required: true,
            }, {
                name: 'operator',
                title: 'Operator',
            }, {
                name: 'threshold',
                title: 'Threshold',
            }],
        };
        this.metricsCollection = new AlertMetricsCollection();
    }

    addRuleHandler() {
        this.editable.addRule();
    }

    removeRuleHandler(index) {
        this.editable.removeRule(index);
    }

    /**
     * Handles Metric ID dropdown change.
     * @param {Object} rule
     */
    metricChangeHandler(rule) {
        if (rule && typeof rule.metric_id === 'string') {
            rule.metric_id = rule.metric_id.slug();
        }
    }
}

PoolGroupDeploymentPolicyModalController.$inject = [
        'Schema',
        'Regex',
        'AlertMetricsCollection',
];

angular.module('aviApp').component('poolGroupDeploymentPolicyModal', {
    controller: PoolGroupDeploymentPolicyModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/templates/autoscale/pool-group-deployment/' +
            'pool-group-deployment-policy-modal/pool-group-deployment-policy-modal.html',
});
