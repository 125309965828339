/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name DataScriptSetListController
 * @description
 *
 *     List view for DataScriptSets.
 */
angular.module('aviApp').controller('DataScriptSetListController', [
'$scope', 'CRUDGridConfig', 'DataScriptSetCollection', 'DataScriptSet',
function($scope, CRUDGridConfig, DataScriptSetCollection, DataScriptSet) {
    const collection = new DataScriptSetCollection();

    $scope.gridConfig = new CRUDGridConfig({
        collection,
        fields: [{
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }, {
            name: 'eventTypes',
            title: 'Event Types',
            transform: row => {
                const listOfTypes = row.getListOfEventTypes().sort();

                return listOfTypes.map(DataScriptSet.getDSEventLabel).join(', ') || 'N/A';
            },
        }],
    });

    $scope.$on('$destroy', () => {
        collection.destroy();
    });
}]);
