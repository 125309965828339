/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AggEventSeriesDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     Agg events time series is using duration or start params instead of step and limit.
 *     Also response has to be modified to mimic the regular metric Series response.
 *
 */
angular.module('aviApp').factory('AggEventSeriesDataTransformer', [
'$window', 'RevisedDataTransformer',
function($window, RevisedDataTransformer) {
    /**
     * @class
     * @extends RevisedDataTransformer
     */
    return class AggEventSeriesDataTransformer extends RevisedDataTransformer {
        /** @override */
        getRequestConfig(params) {
            params = angular.copy(params);

            const { step, limit } = params;

            delete params.limit;

            if (!params.start) {
                params.duration = step * limit;
            }

            return params;
        }

        /** @override **/
        processResponse(resp) {
            const { data } = resp;

            let results;

            if ((results = data['results']) && Array.isArray(results)) {
                results = results.map(({ value, timestamp }) => {
                    return {
                        value,
                        timestamp: +new $window.Date(timestamp),
                    };
                });
            }

            resp.data.series = {
                header: {
                    name: 'agg_events',
                    units: 'METRIC_COUNT',
                    metrics_sum_agg_invalid: false,
                },
                data: results,
            };

            return resp;
        }
    };
}]);
