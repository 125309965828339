/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name dnsPolicyMatch
 * @description Component for configuring a DnsRule match.
 * @param {Function} onRemoveMatch - Called when the close button is clicked.
 * @param {ConfigItem} configItem - Match ConfigItem.
 * @param {string} matchType - Match property used to select the templateUrl.
 */
class DnsPolicyMatchController {
    constructor($injector, Schema, Regex) {
        this._$injector = $injector;
        this.Schema = Schema;
        this.Regex = Regex;
    }

    $onInit() {
        if (this.matchType === 'query-name') {
            const StringGroupCollection = this._$injector.get('StringGroupCollection');

            this.stringGroupCollection = new StringGroupCollection();
        }
    }
}

DnsPolicyMatchController.$inject = [
        '$injector',
        'Schema',
        'Regex',
];

angular.module('aviApp').component('dnsPolicyMatch', {
    controller: DnsPolicyMatchController,
    bindings: {
        onRemoveMatch: '&',
        configItem: '<',
        matchType: '@',
    },
    /**
     * Sets the templateUrl based on the matchType, which changes based on the match being
     * configured.
     */
    templateUrl: ['$attrs', ({ matchType }) => {
        const templateUrl = 'src/components/applications/virtualservice/' +
                'virtualservice-dns-policy/dns-policy-rule-modal/dns-policy-match/dns-policy-match';

        return `${templateUrl}-${matchType}.html`;
    }],
});
