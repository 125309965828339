/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const matchExtendableConfigItemFactory = (MessageItem, RepeatedMessageItem, schemaService) => {
    /**
     * @alias module:config-item/MatchExtendableConfigItem
     * @private
     */
    class MatchExtendableConfigItem extends MessageItem {
        constructor(args) {
            super(args);

            this.fields_ = schemaService.getObjectFieldNames(this.objectType_) || [];
            this.fieldsToDescriptionsHash_ = this.getFieldsToDescriptionsHash_();
            this.childObjectTypesToFieldsHash_ = this.getChildObjectTypesToFieldsHash_();
            this.childFieldsToObjectTypesHash_ = _.invert(this.childObjectTypesToFieldsHash_);

            this.childObjectTypesToLabelsHash_ = {
                IpAddrMatch: 'Client IP',
                PortMatch: 'Service Port',
                ProtocolMatch: 'Protocol Type',
                MethodMatch: 'HTTP Method',
                HTTPVersionMatch: 'HTTP Version',
                PathMatch: 'Path',
                QueryMatch: 'Query',
                HdrMatch: 'Headers',
                CookieMatch: 'Cookie',
                HostHdrMatch: 'Host Header',
            };
        }

        /**
         * @override
         */
        canFlatten_() {
            return this.hasAnyMatch_();
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {};
        }

        /**
         * Returns the supported fields in this.config.
         * @returns {string[]}
         */
        get fields() {
            return this.fields_;
        }

        /**
         * Returns the number of matches configured.
         * @returns {number}
         */
        get matchCount() {
            return this.fields.filter(this.hasMatchByField).length;
        }

        /**
         * Returns true if any match exists in this.config.
         * @returns {boolean}
         */
        hasAnyMatch_() {
            return _.any(this.fields_, this.hasMatchByField);
        }

        /**
         * Returns true if the match exists in this.config. We check for a RepeatedMessageItem
         * because of the HdrMatch message which is a RepeatedMessageItem.
         * @param {string} matchFieldName - Name of the match field name.
         * @returns {boolean}
         */
        hasMatchByField = matchFieldName => {
            const match = this.config[matchFieldName];

            return match instanceof RepeatedMessageItem ? !match.isEmpty() : !_.isUndefined(match);
        }

        /**
         * Adds an empty match ConfigItem.
         * @param {string} matchFieldName - Name of the field of the match to add.
         */
        addMatch(matchFieldName) {
            this.setNewChildByField_(matchFieldName);
        }

        /**
         * Removes the match from the configuration.
         * @param {string} matchFieldName - Name of the field of the match to remove.
         */
        removeMatch(matchFieldName) {
            const match = this.config[matchFieldName];

            if (match instanceof RepeatedMessageItem) {
                match.removeAll();
            } else {
                delete this.config[matchFieldName];
            }
        }

        /**
         * Returns a hash of field names to their descriptions.
         * @returns {Object}
         */
        getFieldsToDescriptionsHash_() {
            return this.fields_.reduce((acc, fieldName) => {
                acc[fieldName] = schemaService.getFieldDescription(this.objectType_, fieldName);

                return acc;
            }, {});
        }

        /**
         * Returns a hash of message object types to their field names.
         * @returns {Object<string, string>}
         */
        getChildObjectTypesToFieldsHash_() {
            return _.reduce(this.messageMap_, (acc, messageMapProps, field) => {
                acc[messageMapProps.objectType] = field;

                return acc;
            }, {});
        }

        /**
         * Returns a label to be displayed for a match field name.
         * @param {string} field - Match field.
         * @returns {string}
         */
        getMatchLabelByField(field) {
            const objectType = this.childFieldsToObjectTypesHash_[field];

            return this.getMatchLabelByObjectType(objectType);
        }

        /**
         * Returns the label for an object type.
         * @param {string} objectType - Message object type.
         */
        getMatchLabelByObjectType(objectType) {
            return this.childObjectTypesToLabelsHash_[objectType];
        }

        /**
         * Returns a value, label, and description for a match field name.
         * @returns {EnumValue}
         */
        getMatchFieldProps_ = matchFieldName => {
            const label = this.getMatchLabelByField(matchFieldName);
            const description = this.fieldsToDescriptionsHash_[matchFieldName];

            return {
                value: matchFieldName,
                label,
                description,
            };
        }

        /**
         * Returns a list of values, labels, and descriptions for a list of match field names.
         * @param {string[]} matches - List of match field names.
         * @returns {EnumValue[]}
         */
        mapMatchFieldProps(matches = []) {
            return matches.map(this.getMatchFieldProps_);
        }
    }

    return MatchExtendableConfigItem;
};

matchExtendableConfigItemFactory.$inject = [
    'MessageItem',
    'RepeatedMessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  MatchExtendableConfigItem
 * @description  Match ExtendableConfigItem class. Used for protobuf messages that contain match
 *     messages from match.proto, ex. MatchTarget that contains IpAddrMatch, PortMatch. A use-case
 *     would be to pass a match instance extending MatchExtendableConfigItem to the matchConfig
 *     component, which works with the methods and properties defined here.
 * @module config-item/MatchExtendableConfigItem
 * @author alextsg
 */
angular.module('policies.vantage.avi')
    .factory('MatchExtendableConfigItem', matchExtendableConfigItemFactory);
