/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

// Todo: change it to factory and create an instance of tooltip and unbind events when destroying
angular.module('aviApp').service('TooltipLight', ['$document', '$window',
function($document, $window) {
    const setPosition = function(element, tooltip) {
        tooltip.css({
            top: $(element).offset().top - $(tooltip).height() - 15,
            left: $(element).offset().left + $(element).width() / 2 - $(tooltip).width() / 2,
        });
    };

    this.create = function(element, text) {
        const tooltip = $(
            `${'<div class="tooltip fade top in" style="display: block;">' +
                '<div class="tooltip-arrow"></div>' +
                '<div class="tooltip-inner">'}${text}</div>` +
            '</div>',
        );

        tooltip.appendTo($document[0].body);
        setPosition(element, tooltip);
        tooltip.hide();
        tooltip.fadeIn();
        $($window).on('resize', function() {
            setPosition(element, tooltip);
        });

        return tooltip;
    };
}]);
