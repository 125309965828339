/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('healthscoreSparkline', ['ChartService', function(ChartService) {
    return {
        restrict: 'EA',
        scope: {
            data: '=',
        },
        template: '<div class = "healthscore-sparkline"></div>',
        replace: true,
        link(scope, elm, attr) {
            const repaint = function() {
                if (!scope.data) { return; }

                scope.chart.graphSingleLine(scope.data);
            };

            scope.chart = new ChartService(elm, {
                height: 100,
                width: 300,
                padding: {
                    left: 5,
                    right: 5,
                    top: 2,
                    bottom: 2,
                },
            });

            // register listeners
            scope.$watch('data.__hidden.toggle', repaint);
            scope.$on('repaint', repaint);
        },
    };
}]);
