/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-exceptions-modal.less';

class WafExceptionsModalComponent {
    constructor(getSubnetObject, getSubnetString) {
        this.getSubnetObject = getSubnetObject;
        this.getSubnetString = getSubnetString;

        this.exclusions = [];
        this.subnet = '';
        this.uriPath = '';
        this.wafGroup = null;
        this.wafRule = null;
        this.wafLogRules = null;
        this.excludeList = [];
    }

    $onInit() {
        const {
            wafGroup,
            wafRule,
            wafLogRules,
            uriPath,
            subnet,
            exclusions,
        } = this;

        const groupConfig = wafGroup.getConfig();

        this.excludeList = groupConfig.exclude_list;

        if (wafRule) {
            const ruleConfig = wafRule.getConfig();

            this.excludeList = ruleConfig.exclude_list;
        }

        // prevent duplicated inputs
        const dupHash = {};

        if (Array.isArray(wafLogRules)) {
            wafLogRules.forEach(rule => {
                if (Array.isArray(rule.matches)) {
                    rule.matches.forEach(match => {
                        const matchElement = match.match_element;

                        if (!match.is_internal &&
                                !this.hasExclusion(matchElement, uriPath, subnet)) {
                            const hash = matchElement + uriPath + subnet;

                            if (!(hash in dupHash)) {
                                dupHash[hash] = 0;
                                exclusions.push({
                                    client_subnet: this.getSubnetObject(subnet),
                                    uri_path: uriPath,
                                    match_element: matchElement,
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    /**
     * Checks if any WAF Group has similar exclusion params.
     * @param {string} matchElement
     * @param {string} uriPath
     * @param {string} clientSubnet
     * @returns {boolean}
     */
    hasExclusion(matchElement, uriPath, clientSubnet) {
        if (Array.isArray(this.excludeList)) {
            let excl;
            let cfg;

            for (let i = 0; i < this.excludeList.length; i++) {
                excl = this.excludeList[i];
                cfg = excl.getConfig();

                if (cfg.match_element === matchElement &&
                        cfg.uri_path === uriPath &&
                        this.getSubnetString(cfg.client_subnet) === clientSubnet) {
                    return true;
                }
            }
        }

        return false;
    }

    removeExclusion(index = 0) {
        this.exclusions.splice(index, 1);
    }

    submit() {
        if (this.exclusions.length > 0) {
            const configItem = this.wafRule || this.wafGroup;

            this.exclusions.forEach(e => configItem.addExcludeListEntry(e));
        }

        this.onSubmit({
            data: {
                wafGroup: this.wafGroup,
                wafRule: this.wafRule,
            },
        });

        this.closeModal();
    }

    cancel() {
        this.onCancel();
        this.closeModal();
    }
}

WafExceptionsModalComponent.$inject = [
    'getSubnetObject',
    'getSubnetString',
];

/**
 * @ngdoc component
 * @name wafExceptionsModal
 * @param {WafRuleGroupConfig} wafGroup
 * @param {Array} wafLogRules - WafRuleLog rule_logs
 * @description
 *     Modal component for managing WAF Group Exceptions in VS Log.
 */
angular.module('waf.vantage.avi').component('wafExceptionsModal', {
    controller: WafExceptionsModalComponent,
    bindings: {
        wafGroup: '<',
        wafRule: '<?',
        wafLogRules: '<',
        subnet: '<',
        uriPath: '<',
        closeModal: '&',
        onCancel: '&',
        onSubmit: '&',
    },
    templateUrl: 'src/components/modals/waf-exceptions-modal/waf-exceptions-modal.component.html',
});
