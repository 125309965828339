/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function configuredNetworkFactory(
    Item,
    rangeParser,
    getSubnetString,
    Regex,
) {
    /**
     * @class
     * @name ConfiguredNetwork
     * @extends module:avi/dataModel.Item
     */
    class ConfiguredNetwork extends Item {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            if ('configured_subnets' in config) {
                // Merge ip and mask into one temporary field
                config['configured_subnets'].forEach(subnet => {
                    subnet.subnet = getSubnetString(subnet['prefix']);

                    subnet.static_ipaddr_tmp =
                        _.pluck(subnet['static_ips'], 'addr')
                            .concat(
                                _.map(subnet['static_ranges'],
                                    ({ begin, end }) => `${begin.addr}-${end.addr}`),
                            );
                });
            } else {
                config['configured_subnets'] = [];
            }

            //TODO figure what is the value of using runtime data for configuration modal
            const { discovery } = this.data;

            if (discovery && 'ip_subnet' in discovery) {
                discovery.ip_subnet
                    .forEach(subnet => subnet.subnet = getSubnetString(subnet.prefix));
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            // Remove empty subnets and inventory type
            config['configured_subnets'] =
                _.filter(config['configured_subnets'], ({ subnet }) => subnet);

            // Transforming temporary fields
            config['configured_subnets'].forEach(subnet => {
                const { subnet: stringSubnet } = subnet;

                if (Regex.anySubnet.test(stringSubnet)) {
                    subnet.prefix = rangeParser.ipRange2Json(stringSubnet);
                } else {
                    delete subnet.prefix;
                }

                delete subnet.subnet;

                subnet.static_ips = [];
                subnet.static_ranges = [];

                if ('static_ipaddr_tmp' in subnet) {
                    subnet.static_ipaddr_tmp.forEach(ipAddrOrRangeList => {
                        ipAddrOrRangeList.split(/\s*,\s*/).forEach(ipAddrOrRange => {
                            const ipAddrOrRangeObj = rangeParser.ipRange2Json(ipAddrOrRange);

                            if (ipAddrOrRangeObj) {
                                if (Regex.anyIPRange.test(ipAddrOrRange)) {
                                    subnet.static_ranges.push(ipAddrOrRangeObj);
                                } else if (Regex.anyIP.test(ipAddrOrRange)) {
                                    subnet.static_ips.push(ipAddrOrRangeObj);
                                }
                            }
                        });
                    });
                }

                delete subnet.static_ipaddr_tmp;

                if (!subnet.static_ips.length) {
                    delete subnet.static_ips;
                }

                if (!subnet.static_ranges.length) {
                    delete subnet.static_ranges;
                }
            });

            return config;
        }

        /**
         * To update configSubnets column in a list view of Networks after saving updated one.
         * Corresponds with NetworkCollection transformAfterLoad.
         * @override
         */
        transformDataAfterSave({ data }) {
            if (data && 'configured_subnets' in data) {
                data['configured_subnets']
                    .forEach(subnet => subnet.asString = getSubnetString(subnet.prefix));
            }

            return data;
        }

        /** @override */
        saveRequest() {
            const config = this.getConfig();
            const postAPI = '/api/network';
            let url = config.uuid ? config.url : postAPI;

            if (config._overrideURL) {
                url = config._overrideURL;
            }

            const method = url === postAPI ? 'post' : 'put';
            const headers = {};

            if (config.url !== url) {
                delete config.url;
                delete config._overrideURL;
                headers.slug = config.uuid;
            }

            return this.request(method, url, this.dataToSave(), headers);
        }

        /**
         * Returns a list of configured subnets for this Network.
         * @public
         * @returns {Subnet[]}
         */
        getSubnets() {
            const
                subnets = [],
                { configured_subnets: configured } = this.getConfig();

            if (configured) {
                subnets.push(...configured);
            }

            return subnets;
        }
    }

    Object.assign(ConfiguredNetwork.prototype, {
        objectName: 'network',
        windowElement: 'infra-network-edit',
        params: {
            include_name: true,
        },
    });

    return ConfiguredNetwork;
}

configuredNetworkFactory.$inject = [
    'Item',
    'RangeParser',
    'getSubnetString',
    'Regex',
];

/**
 * @ngdoc service
 * @alias ConfiguredNetwork
 * @desc
 *
 *     Network item class.
 *
 * @see {@link SubnetListNetwork}
 */
angular.module('aviApp')
    .factory('ConfiguredNetwork', configuredNetworkFactory);
