/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  SSOPolicyCollection
 * @description  Collection of {SSOPolicy} items.
 */
const collectionFactory = (Collection, SSOPolicy) => {
    class SSOPolicyCollection extends Collection {}

    angular.extend(SSOPolicyCollection.prototype, {
        objectName_: 'ssopolicy',
        itemClass_: SSOPolicy,
        windowElement_: 'sso-policy-modal',
    });

    return SSOPolicyCollection;
};

collectionFactory.$inject = [
    'Collection',
    'SSOPolicy',
];

angular.module('aviApp').factory('SSOPolicyCollection', collectionFactory);
