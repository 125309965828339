/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './modal-container-breadcrumbs.less';

/**
 * @ngdoc component
 * @name  modalContainerBreadcrumbs
 * @description Component for displaying breadcrumbs in a modal container.
 * @module common/modalContainerBreadcrumbs
 * @param {string[]} breadcrumbs - Array containing strings to display as breadcrumbs.
 * @author alextsg
 */
angular.module('kit.ui.vantage.avi').component('modalContainerBreadcrumbs', {
    bindings: {
        breadcrumbs: '<',
    },
    templateUrl: 'src/components/modals/modal-container/modal-container-breadcrumbs/' +
        'modal-container-breadcrumbs.component.html',
});
