/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
/**
 * @typedef {Object} gridFieldConfig
 * @property {string} name - Required, used as id and class name.
 * @property {string} title - Text label for the column
 * @property {string|undefined} label - String to be passed to {@link aviFormLabel} directive
 * @property {string|undefined} require - Item's data field name
 * @property {boolean|undefined} metricChart
 * @property {Function|undefined} transform - Function receiving row as argument and returning
 *     rendered string to be rendered in the
 * @property {string|undefined} template - Template string to be rendered by ng.$compile in the
 *     cell. On rendering has access to row, field and gridFieldConfig.
 * @property {string|Function|undefined} sortBy - Row field name to be used for search. Or
 *     comparator getting two rows for comparison.
 * @property {Function|undefined} disabled
 * @see Cell
 */

/**
 * @typedef {Object} gridSingleActionConfig
 * @property {string} title - Unique id and hover title
 * @property {string} class - Class name for the single action icon
 * @property {Function} do - Function to perform an action. Gets row as an argument. Return
 *     value is ignored.
 * @property {Function|undefined} disabled - Greys out single action icon, gets row as an
 *     argument.
 * @property {Function|undefined} hidden - Hides single action icon, gets row as an argument.
 */

/**
 * @typedef {Object} gridMultipleActionConfig
 * @property {string} title - Unique id and corresponding button label
 * @property {string} className - Class name for the single action icon
 * @property {Function} do - Callback to perform an action, should return true to clear rows
 *     selection.
 */

/**
 * @typedef {Object} gridConfig
 * @property {gridFieldConfig[]} fields
 * @property {gridSingleActionConfig[]} singleactions
 * @property {gridMultipleActionConfig[]} multipleactions
 * @property {Function} rowId - Should return unique id per each row passed as an argument.
 * @property {Object} layout
 * @property {string} defaultSorting
 * @property {string[]} searchFields
 * @property {Function} rowClass
 * @property {boolean} withReordering
 * @property {boolean} dragAndDropReordering
 * @property {string} expandedContainerTemplate
 * @property {Function} expanderDisabled
 * @property {Function} executeBeforeContainerExpand
 * @property {Function} executeOnExpandDestroy
 * @property {Function} checkboxDisable
 * @property {boolean} renderAll - If true is passed vs-repeat will render all rows immediately
 */

/**
 * Returns field name stripped of dot symbol to be used as a part of class name.
 * @param {string} name
 * @returns {string}
 */
function getFieldClassName({ name }) {
    return name.replace(/\./g, '-');
}

/**
 * Returns true for disabled actions, false otherwise.
 * @param {Object} action
 * @param {*} row
 * @returns {boolean}
 */
function isActionDisabled(action, row) {
    const { disabled } = action;

    if (angular.isFunction(disabled)) {
        try {
            return disabled.call(undefined, row);
        } catch (e) {
            console.error(
                'grid single action disabled failed',
                e,
                action,
                row,
            );

            return true;
        }
    }

    return !!disabled;
}

/**
 * Returns true for action to be hidden, false otherwise.
 * @param {Object} action
 * @param {*} row
 * @returns {boolean}
 */
function isActionHidden(action, row) {
    const { hidden } = action;

    if (angular.isFunction(hidden)) {
        try {
            return hidden.call(undefined, row);
        } catch (e) {
            console.error(
                'grid single action hidden failed',
                e,
                action,
                row,
            );

            return true;
        }
    }

    return !!hidden;
}

/**
 * Executes single action callback.
 * @param {Object} action
 * @param {*} row
 */
function execAction(action, row) {
    if (isActionDisabled(action, row)) {
        return;
    }

    const { do: actionFunc } = action;

    if (angular.isFunction(actionFunc)) {
        try {
            actionFunc.call(undefined, row);
        } catch (e) {
            console.error(
                'grid single action failed',
                e,
                action,
                row,
            );
        }
    }
}

/**
 * @param {Object} action
 * @param {*[]} rows
 * @return {boolean}
 */
function isMultipleActionDisabled(action, rows) {
    const { disabled } = action;

    if (angular.isFunction(disabled)) {
        try {
            return disabled.call(undefined, rows);
        } catch (e) {
            console.error('grid multiple action disabled failed', e, action);

            return true;
        }
    }

    return !!disabled;
}

/**
 * Calls action on selected rows, after that clears selection if the action returned
 * true.
 * @param {Object} action - The action object (defined in config).
 * @param {*[]} rows
 */
function execMultipleAction(action, rows) {
    const { do: actionDo } = action;

    let done = false;

    if (angular.isFunction(actionDo)) {
        try {
            done = actionDo.call(undefined, rows);
        } catch (e) {
            console.error('grid multiple action do failed', e, action);
        }
    }

    return done;
}

/**
 * @ngdoc constant
 * @type {Object}
 * @name gridConfigTools
 * @desc
 *
 *  Set of methods to work with grid config elements, such as field and action.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi').constant('gridConfigTools', {
    isMultipleActionDisabled,
    execMultipleAction,
    getFieldClassName,
    isActionDisabled,
    isActionHidden,
    execAction,
});
