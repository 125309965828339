/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import {
    imageTypeHash,
    imageCategoryHash,
} from 'services/items/Image';

import {
    UpgradeService,
    upgradeTypeHash,
    rollbackTypeHash,
} from 'services/upgrade/upgrade.service';

import './system-update.component.less';

const {
    IMAGE_TYPE_SYSTEM,
    IMAGE_TYPE_PATCH,
} = imageTypeHash;

const {
    IMAGE_CATEGORY_CONTROLLER,
    IMAGE_CATEGORY_SE,
    IMAGE_CATEGORY_HYBRID,
} = imageCategoryHash;

const {
    UPGRADE_TYPE_SYSTEM_UPGRADE,
    UPGRADE_TYPE_SYSTEM_ROLLBACK,
} = upgradeTypeHash;

const {
    ROLLBACK_TYPE_IMAGE,
    ROLLBACK_TYPE_PATCH,
} = rollbackTypeHash;

/**
 * @constructor
 * @memberOf module:avi/upgrade
 */
class SystemUpdateController {
    constructor(Auth, ImageCollection, AviModal, upgradeService) {
        this.ImageCollection_ = ImageCollection;
        this.AviModal_ = AviModal;
        this.upgradeService_ = upgradeService;

        this.versionData_ = Auth.initData.version;

        /**
         * Collection to hold software image object.
         * @type {module:avi/upgrade.ImageCollection}
         * @protected
         */
        this.imageCollection_ = null;

        /**
         * Config object for grid listing images.
         * @type {Object}
         */
        this.imageCollectionGridConfig = null;

        /**
         * Indicate if any of controller/patch rollback is available.
         * @type {boolean}
         */
        this.enableRollback = false;

        /**
         * Object containing rollback related info.
         * @type {Object|null}
         */
        this.rollbackInfo_ = null;

        /**
         * List of available rollback operations. Two at most: image rollback and patch rollback.
         * @type {module:avi/upgrade.DropdownMenuRollbackOption[]}
         */
        this.rollbackOptions = [];
    }

    /** @override */
    $onInit() {
        this.imageCollection_ = new this.ImageCollection_();
        this.initImageCollectionGridConfig_();

        this.setSystemRollbackInfo_();
    }

    /**
     * Image selection is not valid when:
     *     a) Total number of images is greater than two; at most one system image and one
     * system/controller patch image can be used at the same time.
     *     b) Number of any of the three kinds of images is greater than 1.
     *     c) System patch image co-selected with controller patch image.
     * @param {module:avi/upgrade.Image[]} selectedImages - Selected images that are intended to be
     *     used for upgrade.
     * @return {boolean}
     * @protected
     */
    static isImageSelectionValid_(selectedImages) {
        if (selectedImages.length > 2) {
            return false;
        }

        let systemImageCount = 0;
        let systemPatchImageCount = 0;
        let controllerPatchImageCount = 0;

        selectedImages.forEach(image => {
            const { type, category } = image;

            switch (type) {
                case IMAGE_TYPE_SYSTEM:
                    systemImageCount += 1;
                    break;

                case IMAGE_TYPE_PATCH:
                    switch (category) {
                        case IMAGE_CATEGORY_HYBRID:
                            systemPatchImageCount += 1;
                            break;

                        case IMAGE_CATEGORY_CONTROLLER:
                            controllerPatchImageCount += 1;
                            break;
                    }

                    break;
            }
        });

        return !(systemImageCount > 1 ||
            systemPatchImageCount > 1 ||
            controllerPatchImageCount > 1 ||
            systemPatchImageCount && controllerPatchImageCount);
    }

    /**
     * Set config for system upgrade by retrieving image ids and versions.
     * @param {module:avi/upgrade.Image[]} images
     * @return {module:avi/upgrade.SystemUpgradeConfig}
     * @protected
     */
    static getSystemUpgradeConfig_(images) {
        let systemImageId;
        let controllerPatchImageId;
        let sePatchImageId;
        let targetVersion;

        images.forEach(image => {
            const { type, category, id } = image;

            switch (type) {
                case IMAGE_TYPE_SYSTEM:
                    systemImageId = id;
                    targetVersion = image.version;
                    break;

                case IMAGE_TYPE_PATCH:
                    switch (category) {
                        case IMAGE_CATEGORY_HYBRID:
                            controllerPatchImageId = id;
                            sePatchImageId = id;

                            if (!targetVersion) {
                                targetVersion = image.version;
                            }

                            break;

                        case IMAGE_CATEGORY_CONTROLLER:
                            controllerPatchImageId = id;

                            if (!targetVersion) {
                                targetVersion = image.version;
                            }

                            break;
                    }

                    break;
            }
        });

        return {
            upgradeType: UPGRADE_TYPE_SYSTEM_UPGRADE,
            systemImageId,
            controllerPatchImageId,
            sePatchImageId,
            targetVersion,
        };
    }

    /**
     * Initiate grid config for image list.
     * @protected
     */
    initImageCollectionGridConfig_() {
        const fields = [{
            title: 'Type',
            name: 'type',
            template: require('../image-type.partial.html'),
        }, {
            title: 'Version',
            name: 'version',
            template: require('../image-version.partial.html'),
        }, {
            title: 'Attributes',
            name: 'attributes',
            template: '{{ row.category }}',
        }];

        const multipleactions = [{
            title: 'Upgrade',
            do: rows => {
                this.onUpgradeClick_(rows);

                return true;
            },
            disabled: rows => !SystemUpdateController.isImageSelectionValid_(rows),
        }];

        const layout = {
            hideEditColumns: true,
        };

        /**
         * Disable selecting if:
         * 1. row is an active image or
         * 2. row is a image with a lower base version number than the active one or
         * 3. row is a se patch image or
         * 4. row is a system image with the current active base version number but with a lower
         * build number.
         * @param {module:avi/upgrade.Image} row - Image to be selected.
         * @return {boolean}
         */
        const checkboxDisable = row => {
            const {
                version: imageBaseVersion,
                build: imageBuild,
            } = UpgradeService.getVersionAsHash(row.version);

            const {
                Version: currentBaseVersion,
                build: currentBuild,
            } = this.versionData_;

            const isOlderBuild = row.isType(IMAGE_TYPE_SYSTEM) &&
                (imageBaseVersion === currentBaseVersion && imageBuild <= currentBuild);

            return row.isUsedByController() ||
                imageBaseVersion < currentBaseVersion ||
                row.isCategory(IMAGE_CATEGORY_SE) ||
                isOlderBuild;
        };

        this.imageCollectionGridConfig = {
            collection: this.imageCollection_,
            fields,
            multipleactions,
            layout,
            checkboxDisable,
            permission: 'PERMISSION_UPGRADE_OPS',
        };
    }

    /**
     * Set config for system rollback, including type and the base version to be rollbacked to or
     * the full patch version that need to be reverted.
     * @param {string} type - Rollback type, image or patch.
     * @return {module:avi/upgrade.SystemUpgradeConfig}
     * @protected
     */
    getSystemRollbackConfig_(type) {
        const {
            version,
            patchVersion,
            previousVersion,
            previousPatchVersion,
        } = this.rollbackInfo_;

        let targetVersion;

        switch (type) {
            case ROLLBACK_TYPE_IMAGE:
                targetVersion = previousPatchVersion ?
                    [previousVersion, previousPatchVersion].join('-') : previousVersion;
                break;

            case ROLLBACK_TYPE_PATCH:
                targetVersion = `${version}-${patchVersion}`;
                break;
        }

        return {
            upgradeType: UPGRADE_TYPE_SYSTEM_ROLLBACK,
            rollbackType: type,
            targetVersion,
        };
    }

    /**
     * Open dialog modal for upgrade when the upgrade button in the list view is clicked.
     * @param {module:avi/upgrade.Image[]} images - Selected images.
     */
    onUpgradeClick_(images) {
        const upgradeConfig = SystemUpdateController.getSystemUpgradeConfig_(images);

        this.AviModal_.open(
            'system-update-modal',
            {
                upgradeConfig,
            },
        );
    }

    /**
     * Make API call to get data for system rollback and set rollback related properties.
     * @protected
     */
    setSystemRollbackInfo_() {
        this.upgradeService_.getControllerUpgradeStatus()
            .then(({ data: { results } }) => {
                const [controllerUpgradeStatus] = results;

                // full version string, containing version number, build number and build date
                let {
                    version,
                    previous_version: previousVersion,
                } = controllerUpgradeStatus;

                const {
                    patch_version: patchVersion,
                    previous_patch_version: previousPatchVersion,
                    enable_rollback: enableImageRollback,
                    enable_patch_rollback: enablePatchRollback,
                } = controllerUpgradeStatus;

                //TODO: uncomment this line and delete the line after when patch rollback is allowed
                // this.enableRollback = enableImageRollback || enablePatchRollback;

                this.enableRollback = enableImageRollback;

                if (this.enableRollback) {
                    const currentVersionHash = UpgradeService.getVersionAsHash(version);
                    const previousVersionHash = UpgradeService.getVersionAsHash(previousVersion);

                    const { version: currentBaseVersion } = currentVersionHash;
                    const { version: previousBaseVersion } = previousVersionHash;

                    version = currentBaseVersion;
                    previousVersion = previousBaseVersion;

                    if (enableImageRollback) {
                        // add build number when it's image rollback and base version numbers are
                        // the same for current-previous pair
                        if (currentBaseVersion === previousBaseVersion) {
                            const { build: currentBuild } = currentVersionHash;
                            const { build: previousBuild } = previousVersionHash;

                            version = `${currentBaseVersion}-${currentBuild}`;
                            previousVersion = `${previousBaseVersion}-${previousBuild}`;
                        }
                    }

                    this.rollbackInfo_ = {
                        version,
                        patchVersion,
                        previousVersion,
                        previousPatchVersion,
                        enableImageRollback,
                        enablePatchRollback,
                    };

                    this.setSystemRollbackOptions_(this.rollbackInfo_);
                }
            });
    }

    /**
     * Set options for rollback dropdown menu.
     * @param {Object} rollbackInfo - Info about rollback to generate options.
     * @protected
     */
    setSystemRollbackOptions_(rollbackInfo) {
        const {
            version,
            patchVersion,
            previousVersion,
            previousPatchVersion,
            enableImageRollback,
            //TODO: uncomment this when patch rollback doesn't need to be hidden any more
            // enablePatchRollback,
        } = rollbackInfo;

        this.rollbackOptions = [];

        if (enableImageRollback) {
            const fullVersion = patchVersion ? `${version}-${patchVersion}` : version;
            const fullPreviousVersion = previousPatchVersion ?
                `${previousVersion}-${previousPatchVersion}` : previousVersion;

            const imageRollbackOption = {
                title: 'Version',
                label: `${fullVersion} → ${fullPreviousVersion}`,
                value: ROLLBACK_TYPE_IMAGE,
            };

            this.rollbackOptions.push(imageRollbackOption);
        }

        //TODO: uncomment this when patch rollback doesn't need to be hidden any more
        // if (enablePatchRollback) {
        //     const patchRollbackOption = {
        //         title: 'Clear Patch',
        //         label: `${version}-${patchVersion} → ${version}`,
        //         value: ROLLBACK_TYPE_PATCH,
        //     };

        //     this.rollbackOptions.push(patchRollbackOption);
        // }
    }

    /**
     * Open dialog modal for rollback when a rollback option is clicked.
     * @param {string} rollbackType - 'ROLLBACK_TYPE_IMAGE' | 'ROLLBACK_TYPE_PATCH'
     */
    onRollbackOptionClick(rollbackType) {
        const upgradeConfig = this.getSystemRollbackConfig_(rollbackType);

        this.AviModal_.open(
            'system-update-modal',
            {
                upgradeConfig,
            },
        );
    }

    /** @override */
    $onDestroy() {
        this.imageCollection_.destroy();
    }
}

SystemUpdateController.$inject = [
    'Auth',
    'ImageCollection',
    'AviModal',
    'upgradeService',
];

/**
 * @name SystemUpdateComponent
 * @memberOf module:avi/upgrade
 * @property {module:avi/upgrade.SystemUpdateController} controller
 * @description
 *
 *     Component for system update page. SE patch images are not allowed to be selected for upgrade
 *     under this page. 11 cases(7 of upgrade + 4 of rollback) are handled together with the system
 *     update modal:
 *
 *     Major version:
 *     a) system upgrade: base version controller and all SEGs upgrade, with system flag set to true
 *     b) controller upgrade: base version controller upgrade(with system flag set to false)
 *     c) system rollback: rollback controller and all SEGs to the previous major version
 *     d) controller rollback: rollback controller to the previous major version (with system flag
 *        set to false)
 *
 *     Patch:
 *     e) system patch: controller and SE patches are applied
 *         (can only be a <system patch image + system set to true>)
 *     f) controller patch: only controller patch is applied
 *         (can be a <controller patch image> or a <system patch image + system set to false>)
 *     g) system upgrade + system patch: base version system upgrade + both controller and SE patch
 *         (the controller and se patches are of the same base and patch version)
 *     h) system upgrade + controller patch: base version system upgrade + controller patch
 *         (can only be <system image + controller patch image + system set to true>)
 *     i) controller upgrade + controller patch: base version controller upgrade + controller patch
 *         (can be a <system image + controller patch image + system set to false> or
 *         a <system image + system patch image + system set to false>)
 *     j) system patch rollback: rollback controller and all SEGs with the currently applied patch
 *     k) controller patch rollback: rollback controller with the currently applied patch
 *
 * @author Zhiqian Liu
 */
angular.module('avi/upgrade').component('systemUpdate', {
    controller: SystemUpdateController,
    templateUrl: 'src/components/pages/administration/controller/system-update/' +
        'system-update.component.html',
});
