/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './ssl-bar-chart.less';

/**
 * @ngdoc component
 * @name  sslBarChart
 * @description  Creates a bar chart from SSL score data.
 */
function Controller(AsyncFactory, Timeframe, Base, $element, $scope, $q) {
    const
        vm = this,
        base = new Base(),
        healthscoreAPI = '/api/analytics/healthscore/virtualservice/';

    let pollSSLScoreData;

    // Chart dimensions/properties
    const
        chartHeight = 370,
        chartMargin = { top: 10, bottom: 0, left: 15, right: 15 },
        barHeight = 38,
        barStartPosition = 240;

    this.enabled = false;

    this.summary = {
        value: 0,
        reason: '',
        reason_attr: '',
    };

    this.data = [{
        id: 'pfs_support',
        name: 'PFS Support',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'min_ssl_protocol_strength',
        name: 'SSL Protocol Strength',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'weakest_enc_algo',
        name: 'Weakest Encryption Algorithm',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'min_cipher_strength',
        name: 'Symmetric Encryption Cipher Strength',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'earliest_cert_expiry',
        name: 'Certificate Expiration Time',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'weak_signature_algorithm',
        name: 'Signature Algorithm',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'disable_client_renegotiation',
        name: 'Disable Client Renegotiation',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'cert_chain_verified',
        name: 'Trusted CA Certificate',
        setting: 'N/A',
        score: 0,
    }, {
        id: 'hsts_enabled',
        name: 'HSTS Enabled',
        setting: 'N/A',
        score: 0,
    }];

    this.$onInit = function() {
        this.buildChart();

        pollSSLScoreData = new AsyncFactory(function() {
            vm.error = '';

            return base.request('GET', healthscoreAPI + vm.uuid, null, null, 'sslscore')
                .then(function(rsp) {
                    const [{ data }] = rsp.data.series;

                    if (data) {
                        vm.parseSSLScoreData(data[0].security_threat_level.ssl_score_data);
                        vm.updateChart();
                    }
                }).catch(rsp => {
                    vm.error = rsp.error || rsp.data && rsp.data.error;

                    return $q.reject(rsp.data || rsp);
                });
        });

        pollSSLScoreData.start(30000);
    };

    /**
     * Parses ssl_score_data object from response.
     * @param  {Object} data - ssl_score_data response.
     */
    this.parseSSLScoreData = function(data) {
        vm.enabled = data.ssl_enabled;

        angular.extend(vm.summary, {
            value: data.value,
            reason: data.reason,
            reason_attr: data.reason_attr,
        });

        this.data.forEach(function(category) {
            category.setting = _.isUndefined(data[category.id]) ? 'N/A' : data[category.id];
            category.score = data[`${category.id}_score`] || 0;
        });
    };

    /**
     * Builds bar chart, making sure to first remove existing chart elements.
     */
    this.buildChart = function() {
        const element = $element.find('.chart');

        d3.select(element[0]).selectAll('*').remove();

        const
            width = $element.width() - chartMargin.left - chartMargin.right,
            height = chartHeight - chartMargin.top - chartMargin.bottom;

        const chart = d3.select(element[0])
            .append('svg')
            .attr('width', width + chartMargin.left + chartMargin.right)
            .attr('height', height + chartMargin.top + chartMargin.bottom);

        const bar = chart.selectAll('g')
            .data(this.data)
            .enter()
            .append('g')
            .attr('transform', function(d, i) {
                return `translate(${chartMargin.left},${
                    i * barHeight + chartMargin.top})`;
            })
            .attr('class', 'disabled');

        bar.append('rect')
            .attr('x', barStartPosition)
            .attr('width', 1)
            .attr('height', barHeight - 3);

        bar.append('text')
            .attr('x', 235)
            .attr('y', 3)
            .attr('class', 'name')
            .text(function(d) { return d.name; });

        bar.append('text')
            .attr('x', 235)
            .attr('y', barHeight / 2 + 1)
            .attr('class', 'setting')
            .text(function(d) { return d.setting; });

        bar.append('text')
            .attr('class', 'value')
            .attr('text-position', 'right')
            .attr('x', barStartPosition + 5)
            .attr('y', barHeight / 2)
            .attr('dy', '.3em')
            .text('N/A');
    };

    /**
     * Updates existing chart with new data.
     */
    this.updateChart = function() {
        const indexOfSecurityThreat = _.findIndex(this.data, function(category) {
            return category.id === vm.summary.reason_attr;
        });

        const element = $element.find('.chart');
        const width = $element.width() - chartMargin.left - chartMargin.right;

        const x = d3.scale.linear()
            .domain([0, 5])
            .range([barStartPosition, width < 420 ? width - 30 : width]);

        const bar = d3.select(element[0])
            .selectAll('g')
            .data(this.data)
            .attr('class', function(d, i) {
                let className = '';

                if (!vm.enabled) {
                    className = 'disabled';
                } else if (i === indexOfSecurityThreat) {
                    className = 'threat';
                } else if (d.score < 5) {
                    className = 'poor';
                }

                return className;
            });

        bar.selectAll('rect')
            .transition()
            .duration(200)
            .attr('width', function(d) {
                return Math.max(x(d.score) - barStartPosition + 1, 1);
            });

        bar.selectAll('text.setting')
            .text(function(d) { return d.setting; });

        bar.selectAll('text.value')
            .attr('text-position', function(d) {
                return d.score < 1 || width < 420 ? 'right' : '';
            })
            .text(function(d) {
                return vm.enabled ? d3.format('.1f')(d.score) : 'N/A';
            })
            .transition()
            .duration(200)
            .attr('x', function(d) {
                return x(d.score) + (d.score < 1 || width < 420 ? 5 : -5);
            });
    };

    this.$onDestroy = function() {
        base.cancelRequests('sslscore');

        if (pollSSLScoreData && pollSSLScoreData.isActive()) {
            pollSSLScoreData.stop();
        }
    };

    /**
     * When window is resized, redraw the chart.
     */
    $scope.$on('repaint', function() {
        vm.buildChart();
        vm.updateChart();
    });
}

Controller.$inject = [
    'AsyncFactory',
    'Timeframe',
    'Base',
    '$element',
    '$scope',
    '$q',
];

angular.module('aviApp').component('sslBarChart', {
    bindings: {
        uuid: '@',
    },
    controller: Controller,
    templateUrl: 'src/components/applications/virtualservice/' +
            'vs-security-tab/ssl-bar-chart/ssl-bar-chart.html',
});
