/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function BgpPeeringService($http, $stateParams, $q, defaultValues) {
    /** @type {?Object} */
    let vrfContextList = null;

    /** @type {string} */
    let { cloudId } = $stateParams;

    /** @type {string} */
    this.errors = '';

    /** @type {EventEmitter2} */
    this.emitter = new window.EventEmitter2();

    /**
     * Checks for error string inside error object recursively.
     * @param {Object} error
     * @returns {*}
     */
    function getErrorText(error) {
        if (typeof error === 'string') {
            return error;
        } else {
            const errorKeys = Object.keys(error);

            return errorKeys.reduce((errorMsg, prop) => {
                const value = error[prop];

                if (angular.isString(value)) {
                    errorMsg += value;
                } else if (angular.isObject(value)) {
                    errorMsg += getErrorText(value);
                }

                return errorMsg;
            }, '');
        }
    }

    this.setCloudId = function(id) {
        cloudId = id;
    };

    /**
     * Returns list of VRF objects.
     * @returns {VRFContext[]}
     */
    this.getVrfContextList = function() {
        return vrfContextList;
    };

    /**
     * Returns VRF object.
     * @param  {string} vrfName - Name of VRF Context Object.
     * @return {VRFContext} VRF Context object.
     */
    this.getVrfContext = function(vrfName) {
        vrfName = vrfName || 'global';

        return _.findWhere(vrfContextList, { name: vrfName });
    };

    /**
     * Parses VRF object from angular HTTP response object.
     * @param {Object} response - Angular $http service response object.
     */
    function getVrfContextsFromResponse(response) {
        vrfContextList = response.map(function(rsp) {
            return rsp.data.results[0];
        });
    }

    /**
     * Loads new VRF context.
     * @param {String[]} VRFContextNames - Array of VRF Context names to load.
     * @returns {Promise}
     */
    this.load = function(vrfContextNames) {
        vrfContextNames = vrfContextNames || [];

        const promises = vrfContextNames.map(function(name) {
            const url = `/api/vrfcontext?name=${name}&cloud_ref.uuid=${cloudId
            }&include_name=true`;

            return $http.get(url);
        });

        this.errors = '';

        return $q.all(promises).then(function(response) {
            getVrfContextsFromResponse(response);
            this.emitter.emit('loaded');
        }.bind(this), function(response) {
            this.errors = getErrorText(response.data.error);
        }.bind(this));
    };

    /**
     * Saves provided BGP profile using previously loaded VRF context. Can be null to remove
     * BGP profile from VRF context.
     * @param {?Object} bgpProfile
     * @returns {Promise}
     */
    this.save = function(bgpProfile, vrfName) {
        vrfName = vrfName || 'global';

        const
            vrfContext = angular.copy(this.getVrfContext(vrfName)),
            self = this;

        vrfContext.bgp_profile = dataToSave(bgpProfile);

        this.errors = '';

        return $http.put(vrfContext.url, vrfContext)
            .then(function(response) {
                const vrfContext = self.getVrfContext(vrfName);

                vrfContext.bgp_profile = response.data.bgp_profile;
                self.emitter.emit('saved');
            }).catch(function(response) {
                self.errors = getErrorText(response.data.error);

                return $q.reject(self.errors);
            });
    };

    /**
     * Modifies data before making PUT request.
     * @param  {Object} bgpProfile - BGP Profile object.
     * @return {Object} BGP Profile object.
     */
    function dataToSave(bgpProfile) {
        bgpProfile = angular.copy(bgpProfile);

        if (bgpProfile && Array.isArray(bgpProfile.peers)) {
            bgpProfile.peers.forEach(function(peer) {
                peer.busy = undefined;
                peer.subnetworks = undefined;
                peer.subnetworks6 = undefined;
                peer.bfd = !!peer.bfd;
            });
        }

        return bgpProfile;
    }

    /**
     * Creates or returns existing BGP profile.
     * @returns {Object}
     */
    this.createBgpProfile = function(vrfName) {
        vrfName = vrfName || 'global';

        const bgpProfile = this.getBgpProfile(vrfName) || {};

        if (_.isEmpty(bgpProfile)) {
            let defaultBgpProfileValues =
                defaultValues.getDefaultItemConfigByType('bgpProfile');

            defaultBgpProfileValues =
                _.pick(defaultBgpProfileValues, 'keepalive_interval', 'hold_time');

            Object.assign(bgpProfile, defaultBgpProfileValues);
        }

        bgpProfile.ibgp = angular.isUndefined(bgpProfile.ibgp) ? true : bgpProfile.ibgp;

        return bgpProfile;
    };

    /**
     * Returns existing BGP profile.
     * @returns {Object}
     */
    this.getBgpProfile = function(vrfName) {
        vrfName = vrfName || 'global';

        return this.getVrfContext(vrfName).bgp_profile;
    };

    this.getBgpProfileList = function() {
        return vrfContextList.map(function(vrf) {
            vrf.bgp_profile = vrf.bgp_profile || {};

            return vrf.bgp_profile;
        });
    };

    /**
     * Returns empty IpAddr object.
     * @returns {Object}
     */
    this.newIpAddr = function() {
        return {
            addr: '',
            type: 'V4',
        };
    };

    /**
     * Returns empty IpAddrPrefix object.
     * @returns {Object}
     */
    this.newIpAddrPrefix = function() {
        return {
            ip_addr: this.newIpAddr(),
            mask: 0,
        };
    };
}

BgpPeeringService.$inject = ['$http', '$stateParams', '$q', 'defaultValues'];

angular.module('aviApp').service('bgpPeeringService', BgpPeeringService);
