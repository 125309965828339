/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ArrayOfObjectsListDataTransformer', ['ListDataTransformer',
function(ListDataTransformer) {
    function ArrayOfObjectsListDataTransformer(args) {
        ArrayOfObjectsListDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(ArrayOfObjectsListDataTransformer, ListDataTransformer);

    ArrayOfObjectsListDataTransformer.prototype.processResponse = function(resp) {
        let results = [];

        if (resp.data && Array.isArray(resp.data)) {
            results = _.map(resp.data, function(item) {
                item.name = item[this.propertyToDisplay];
                item.url = item.name;

                return { config: item };
            }, this.owner_);
        }

        return {
            data: {
                results,
                count: results.length,
            },
        };
    };

    return ArrayOfObjectsListDataTransformer;
}]);
