/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').filter('last', function() {
    return function(values) {
        if (values === undefined || values.length === 0) {
            return '0';
        }

        if (values[0] instanceof Array) {
            return values[values.length - 1][1];
        }

        if (values[0] instanceof Object) {
            return values[values.length - 1].y;
        } else {
            return values[values.length - 1];
        }
    };
});

/**
 * @ngdoc filter
 * @name  keysToArray
 * @description
 *     Filter to convert Object keys into an array. Intended purpose is to convert Schema objects
 *     into an array of keys to allow for sorting via orderBy.
 */
angular.module('aviApp').filter('keysToArray', () => {
    return obj => _.keys(obj);
});

/**
 * @ngdoc filter
 * @name  filterByHash
 * @description
 *     Filter items by provided hash. By default, return items existing in the hash. If "true" is
 *     provided as the third argument, return items not in the hash.
 * @param {Object|Object[]|string[]} items - Input array that the filter is being applied to.
 * @param {Object} hash - Hash used to filter for items.
 * @param {string=} property - Property of each item in the array that should be looked up in hash.
 *    If undefined or null, take item as a whole.
 * @param {boolean} notInHash - If true, return item not in hash.
 */
angular.module('aviApp').filter('filterByHash', () => {
    return (items, hash, property, notInHash = false) => {
        if (!angular.isObject(hash)) {
            return items;
        }

        return _.filter(items, item => {
            let value = item;

            if (property && property in item) {
                value = item[property];
            }

            return notInHash ? !(value in hash) : value in hash;
        });
    };
});
