/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import './waf-policy-psm-groups-list.less';

/**
 * @ngdoc component
 * @name WafPolicyPsmGroupsListComponent
 * @module waf/WafPolicyPsmGroupsListComponent
 * @desc Component for displaying a list of WAF PSM groups.
 * @param psm - WafPositiveSecurityModelConfigItem
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-groups-list',
    templateUrl: './waf-policy-psm-groups-list.component.html',
})
export class WafPolicyPsmGroupsListComponent implements OnInit {
    @Input() psm: WafPositiveSecurityModelConfigItem;

    @Output() onEdit = new EventEmitter();
    @Output() onDelete = new EventEmitter();

    groups: WafPolicyPsmGroup[];

    ngOnInit(): void {
        this.groups = this.psm.config.group_refs_data;
    }

    /**
     * Handler for editing a PSM group.
     */
    handleEdit(group: WafPolicyPsmGroup, index: number): void {
        this.onEdit.emit({ group, index });
    }

    /**
     * Handler for deleting a PSM group.
     * @param group - PSM group to remove.
     */
    handleDelete(group: WafPolicyPsmGroup): void {
        this.onDelete.emit(group);
    }

    /**
     * Handler for the drag-and-drop event.
     * @param dragEvent - Object returned by angular/cdk/drag-drop when the user has dropped an item
     *     after dragging it.
     */
    handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.psm.moveGroup(previousIndex, currentIndex);
    }
}
