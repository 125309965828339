/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-crs-list.less';

/**
 * @ngdoc component
 * @name wafCrsList
 * @description WAF CRS page for uploading CRS rules.
 */
class WafCrsListController {
    constructor(WafCrsCollection) {
        this.WafCrsCollection_ = WafCrsCollection;
    }

    /**
     * Creates the gridConfig and creates an instance of WafCrsCollection.
     */
    $onInit() {
        this.wafCrsCollection = new this.WafCrsCollection_({
            params: {
                fields: 'name,release_date',
            },
        });

        this.gridConfig = {
            collection: this.wafCrsCollection,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    template: '{{::row.getName()}}',
                },
                {
                    name: 'date',
                    title: 'Release Date',
                    template: '{{::row.getConfig().release_date}}',
                },
            ],
            expandedContainerTemplate: '<pre>{{::row.getConfig().description}}</pre>',
            permission: 'PERMISSION_WAFPOLICY',
            multipleactions: [{
                title: 'Delete',
                do: rows => {
                    this.wafCrsCollection.dropItems(rows);

                    return true;
                },
            }],
            singleactions: [{
                title: 'Delete',
                class: 'icon-trash',
                do: row => {
                    this.wafCrsCollection.dropItems(row);

                    return true;
                },
            }],
        };
    }

    /**
     * Called when a user has selected a file from a local machine.
     * @param {string} file - JSON stringified file contents.
     */
    handleFileLoad(file) {
        const promise = this.wafCrsCollection.uploadWafCrs(file);

        promise.then(() => this.wafCrsCollection.load());

        return promise;
    }

    /**
     * Destroys the WafCrsCollection instance, cancelling any pending requests.
     */
    $onDestroy() {
        this.wafCrsCollection.destroy();
    }
}

WafCrsListController.$inject = [
    'WafCrsCollection',
];

angular.module('aviApp').component('wafCrsList', {
    controller: WafCrsListController,
    templateUrl: 'src/components/pages/templates/waf/waf-crs-list/waf-crs-list.html',
});
