/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name generateURL
 * @param {Object} urlObject
 * @returns {string}
 * @description
 *
 *      Generates a URL string using a URL object;
 *      this URL string may or may not contain the leading protocol.
 */
angular.module('aviApp').constant('generateURL', function(urlObject) {
    const { protocol, host, path, query, hash } = urlObject;
    // only host are required field in a urlObject, the others are optional
    const url = `${protocol ? `${protocol.toLowerCase()}://` : ''}` +
                `${host}${path || ''}${query || ''}${hash || ''}`;

    return url;
});
