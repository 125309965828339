/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name CollMetricsCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     CollMetricsCollDataSource prepares a list of requests for Collection Metrics API by going
 *     through all visible Collection Items and asking each of them for collection metrics requests
 *     to be fetched through Item#getCollMetricsRequests.
 *
 *     And later it goes through all visible Items passing received response so that they could
 *     update themselves.
 *
 */
angular.module('aviApp').factory('CollMetricsCollDataSource', [
'CollDataSource', 'Timeframe',
function(CollDataSource, Timeframe) {
    /**
     * @param {Object} args
     * @constructor
     * @extends CollDataSource
     */
    function CollMetricsCollDataSource(args) {
        CollMetricsCollDataSource.superconstructor.call(this, args);

        this.onVisibleItemsListUpdate_ = this.onVisibleItemsListUpdate_.bind(this);

        this.owner_.on('visibleItemsListUpdate', this.onVisibleItemsListUpdate_);

        //TODO should we replace it with normal setOffset/setLimit event handlers? Or switch
        //TODO those to events too.
    }

    avi.inherit(CollMetricsCollDataSource, CollDataSource);

    /**
     * Prevents call to be made if we have no requests to send.
     * @param {CollMetricsRequest[]} subrequests
     * @returns {boolean}
     * @private
     * @override
     */
    CollMetricsCollDataSource.prototype.preventLoad_ = function(subrequests) {
        return !subrequests.length;
    };

    /**
     * @returns {CollMetricsRequest[]}
     * @private
     * @override
     */
    CollMetricsCollDataSource.prototype.getRequestParams_ = function() {
        const
            owner = this.owner_,
            fieldNames = _.pluck(this.fields_, 'id'),
            itemIds = owner.visibleItemIds_.length ? owner.visibleItemIds_ : _.keys(owner.itemById),
            requests = [];

        _.each(itemIds, function(itemId) {
            let itemRequests = [];

            if (itemId in owner.itemById) {
                itemRequests = owner.itemById[itemId].getCollMetricsRequests(fieldNames);
            }

            Array.prototype.push.apply(requests, itemRequests);
        }, this);

        return requests;
    };

    /**
     * Updates step&limit parameters and calls load if we have any fields (metric names) to be
     * loaded.
     * @private
     * @override
     */
    CollMetricsCollDataSource.prototype.onTimeframeChange_ = function() {
        const tf = Timeframe.selected();

        this.params_['step'] = tf.step;
        this.params_['limit'] = tf.limit;

        CollMetricsCollDataSource.superclass.onTimeframeChange_.call(this);

        if (!this.isInactive()) {
            this.load();
        }
    };

    /** @override */
    CollMetricsCollDataSource.prototype.isInactive = function() {
        return _.isEmpty(this.fields_);
    };

    /**
     * Goes through all currently visible Items and suggests them to update their metrics data
     * with the received response.
     * @param {CollMetricsFullResponse} resp - It passes whole response object to each Item since
     *     Collection and DataSource both have no clue which request id did Item use.
     * @private
     */
    CollMetricsCollDataSource.prototype.processResponse_ = function(resp) {
        const
            owner = this.owner_,
            itemIds = owner.visibleItemIds_.length ? owner.visibleItemIds_ : _.keys(owner.itemById);

        _.each(itemIds, function(itemId) {
            if (itemId in owner.itemById) {
                owner.itemById[itemId].processCollMetricsResponse(resp);
            }
        });
    };

    /**
     * Event listener for the VisibleItemsListUpdate event evoked by Collection.
     * @returns {ng.$q.promise}
     * @private
     */
    CollMetricsCollDataSource.prototype.onVisibleItemsListUpdate_ = function() {
        return this.load();
    };

    /** @override */
    CollMetricsCollDataSource.prototype.destroy = function() {
        this.owner_.unbind('visibleItemsListUpdate', this.onVisibleItemsListUpdate_);

        CollMetricsCollDataSource.superclass.destroy.call(this);
    };

    return CollMetricsCollDataSource;
}]);
