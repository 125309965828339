/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  WafCrsCollection
 * @description  Collection of {WafCrs} items.
 */
const collectionFactory = (Collection, WafCrs, $q) => {
    class WafCrsCollection extends Collection {
        /**
         * Sends a POST request to upload new CRS versions of WAF CRS rules.
         * @param {string} file - JSON stringified content to upload.
         */
        uploadWafCrs(file) {
            let data;

            try {
                data = JSON.parse(file);
            } catch (e) {
                return $q.reject('Not a valid JSON file.');
            }

            const api = `api/${this.objectName_}`;

            return this.request('POST', api, data, null, 'wafCrsUpload')
                .catch(rsp => $q.reject(rsp.data && rsp.data.error));
        }
    }

    angular.extend(WafCrsCollection.prototype, {
        objectName_: 'wafcrs',
        itemClass_: WafCrs,
        sortBy_: 'name',
    });

    return WafCrsCollection;
};

collectionFactory.$inject = [
    'Collection',
    'WafCrs',
    '$q',
];

angular.module('aviApp').factory('WafCrsCollection', collectionFactory);
