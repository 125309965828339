/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * This factory is for sharing a single code base for creating event grid configs
 */
angular.module('aviApp').factory('eventGridConfig', ['$filter', 'Event',
function($filter, Event) {
    return function(collection, options) {
        const ddosDictionary = {
            DOS_ATTACK: true,
            SE_SYN_SEEN_TABLE_HIGH: true,
            SE_FLOW_TABLE_HIGH: true,
            SE_DOS_ATTACK: true,
        };

        const fields = [
            {
                title: 'Timestamp',
                name: 'report_timestamp',
                sortBy: 'report_timestamp',
                transform(row) {
                    return $filter('noYearTime')(row.getConfig().report_timestamp);
                },
            }, {
                title: 'Resource Name (obj_name)',
                name: 'data.config.obj_name',
            }, {
                title: 'Resource Type (obj_type)',
                name: 'data.config.obj_type',
                sortBy: 'obj_type',
            }, {
                title: 'Event Code (event_id)',
                name: 'data.config.event_id',
                sortBy: 'event_id',
            }, {
                title: 'User',
                name: 'user', // used as id to hide this column for certain pages
                template: require('./display-event-user.partial.html'),
            }, {
                title: 'Description',
                name: 'data.config.event_description',
            }];

        const defaultConfig = {
            fields,
            expandedContainerTemplate: require('./event-details-expander.partial.html'),
            expanderDisabled(row) {
                return row.getConfig().ignore_event_details_display;
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
            rowClass(row) {
                return row.getType() in ddosDictionary ? 'dos-attack' : '';
            },
        };

        if (collection) { //collection grid
            defaultConfig.collection = collection;
        } else { //regular grid
            defaultConfig.rowId = function(row) {
                return Event.getIdFromData(row.data);
            };
        }

        return Object.assign(
            defaultConfig,
            options,
        );
    };
}]);
