/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type Function
 * @description
 *     Returns a subnet string given a subnet object with ip_addr and mask properties. If format
 *     doesn't match, returns undefined.
 */
/**
 * @param {IpAddrPrefix} subnetObj - Subnet object.
 * @returns {string|undefined}
 **/
function getSubnetString(subnetObj) {
    if (angular.isObject(subnetObj) &&
            angular.isObject(subnetObj.ip_addr) &&
            !angular.isUndefined(subnetObj.mask)) {
        return `${subnetObj.ip_addr.addr}/${subnetObj.mask}`;
    }
}

angular.module('aviApp').constant('getSubnetString', getSubnetString);
