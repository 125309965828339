/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('TenantSettingsController', TenantSettingsController);

/**
 * Tenant Settings controller.
 * @param {ng.$rootScope.scope} $scope
 * @param {TenantSettingsService} TenantSettingsService
 * @param {AviModal} AviModal
 * @param {$scope} $scope
 * @constructor
 */
function TenantSettingsController($scope, TenantSettingsService, AviModal) {
    /** @type {AviModal} */
    this.AviModal_ = AviModal;
    /** @type {TenantSettingsService} */
    this.tenantService = TenantSettingsService;

    this.ui = {
        errors: null,
    };

    $scope.$parent.modalScope = $scope;//AviModal thing

    this.copyValues_ = this.copyValues_.bind(this);
}

TenantSettingsController.$inject = ['$scope', 'TenantSettingsService', 'AviModal'];

TenantSettingsController.prototype.$onInit = function() {
    this.configCopy = {};

    const { tenantService } = this;

    tenantService.on(tenantService.parseMessagesEvent, this.copyValues_);
    tenantService.parseMessages();
};

TenantSettingsController.prototype.$onDestroy = function() {
    const { tenantService } = this;

    tenantService.unbind(tenantService.parseMessagesEvent, this.copyValues_);
};

/**
 * Copies tenantService values to local configCopy object.
 * @private
 */
TenantSettingsController.prototype.copyValues_ = function() {
    const { tenantService, configCopy } = this;

    [
        'ipRoutesDomainMessage',
        'seProviderContextMessage',
        'seProviderContext',
        'tenantAccessProviderSeMessage',
        'hasConfig',
    ].forEach(prop => configCopy[prop] = tenantService[prop]);
};

/**
 * Opens settings modal window.
 */
TenantSettingsController.prototype.open = function() {
    this.AviModal_.open('adm-tenant-settings-modal');
};

/**
 * Closes settings modal window.
 */
TenantSettingsController.prototype.close = function() {
    this.AviModal_.destroy('adm-tenant-settings-modal');
    this.tenantService.parseMessages();
    delete this.ui;
};

/**
 * Submits current config to server.
 */
TenantSettingsController.prototype.save = function() {
    const self = this;

    this.tenantService.saveConfig().then(function() {
        self.close();
    }).catch(function(rsp) {
        self.ui.errors = rsp.data.error;
    });
};
