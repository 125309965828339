/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

CloudStatusPopupController.$inject = ['cloudService', 'AviModal'];

/**
 * Cloud status popup controller.
 * @param {CloudService} cloudService
 * @param {AviModal} AviModal
 * @constructor
 */
function CloudStatusPopupController(cloudService, AviModal) {
    this.cloudService = cloudService;
    this.AviModal = AviModal;
}

/**
 * Handles Details link click.
 */
CloudStatusPopupController.prototype.detailsClickHandler = function() {
    this.cloudService.openCloudStatusDetails();
};

/**
 * @ngdoc directive
 * @name cloudStatusPopup
 * @description
 *     Non-modal dialog box for displaying cloud status.
 */
angular.module('aviApp').directive('cloudStatusPopup', function() {
    return {
        restrict: 'A',
        controller: CloudStatusPopupController,
        controllerAs: 'cspCtrl',
        templateUrl: 'src/views/components/infrastructure/cloud-status-popup-message.tpl.html',
    };
});
