/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './sslprofile.less';

const componentName = 'ssl-tls-profile-modal';
const componentPath = 'src/components/modals/templates/security/';

/**
 * @alias module:templates/sslTlsProfileModal
 * @private
 */
class SslProfileModalController {
    constructor(
        $element,
        $timeout,
        schemaService,
        AviConfirmService,
    ) {
        this.$timeout_ = $timeout;
        this.$element_ = $element;
        this.AviConfirmService_ = AviConfirmService;
        this.indicator = {};
        this.ui = {};

        this.fetchIndicatorInfo_ = _.throttle(this.fetchIndicatorInfo_.bind(this), 999);

        //for the accepted_cipher string input
        this.debFetchIndicatorInfo = _.debounce(this.fetchIndicatorInfo_.bind(this), 699);

        this.gridConfig = {
            rowId: 'cipher',
            fields: [
                {
                    name: 'enabled',
                    title: 'Enabled',
                    template: '<checkbox ng-model="row.enabled"' +
                        'ng-change="config.actions.refreshIndicator()"/>',
                },
                {
                    name: 'cipher',
                    title: 'Cipher',
                },
                {
                    name: 'securityScore',
                    title: 'Security Score',
                },
                {
                    name: 'pfs',
                    title: 'PFS',
                    template: '<i ng-if="row.pfs" class="icon-check avi-green"></i>',
                },
                {
                    name: 'performance',
                    title: 'Performance',
                },
                {
                    name: 'compatibility',
                    title: 'Compatibility',
                },
            ],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
            dragAndDropReordering: true,
            rowClass: row => row.enabled && 'enabled' || '',
            actions: {
                refreshIndicator: () => this.fetchIndicatorInfo_(),
            },
        };

        const sslProfileTypes = schemaService.getEnumValues('SSLProfileType');

        /**
         * sslProfileTypes Enumvalues defined in Schema.
         * @type {string[]}
         */
        this.sslProfileTypes = _.pluck(sslProfileTypes, 'value');

        /**
         * allSslVersionTypes Enumvalues defined in Schema.
         * @type {EnumValue[]}
         */
        this.allSslVersionTypes = schemaService.getEnumValues('SSLVersionType');

        const { allSslVersionTypes } = this;

        /**
         * supportedVersionTypes value properties plucked from allSslVersionTypes.
         * This list will comprise of versions which correspond to enabled/checked as well as the
         * unchecked checkboxes which are to be shown to the user.
         * @type {string[]}
         */
        this.supportedVersionTypes = _.pluck(allSslVersionTypes, 'value');
    }

    /**
     * @override
     */
    $onInit() {
        const config = this.editable.getConfig();

        this.ui.tab = undefined;
        this.indicator.ready = false;

        this.initCipherTab();

        // copy of existing accepted_versions assigned as enabledVersionTypes list.
        const { accepted_versions: acceptedVersions } = config;

        this.updateIsTls13Enabled_();

        /**
         * enabledVersionTypes Supported SSLVersion types from config.
         * This list comprises of the versions which correspond to the version checkboxes
         * which are to be in checked/enabled state.
         * @type {string[]}
         */
        this.enabledVersionTypes = [...acceptedVersions];

        this.updateSupportedVersionLists_();
        this.$timeout_(() => this.fetchIndicatorInfo_());
        this.editable.setPristine();
    }

    /**
     * Updates isTls13Enabled flag based on its presence in the current accepted_versions list.
     * @protected
     */
    updateIsTls13Enabled_() {
        const config = this.editable.getConfig();
        const { accepted_versions: acceptedVersions } = config;
        const isTls13Enabled = acceptedVersions.includes('SSL_VERSION_TLS1_3');

        this.isTls13Enabled = isTls13Enabled;

        if (!isTls13Enabled) {
            config.enable_early_data = false;
        }
    }

    /**
     * Updates the supported version list based on the current deciding factors like
     * Profile and ciper tab types. It's being called whenever Profile / Cipher type gets toggled.
     * @protected
     */
    updateSupportedVersionLists_() {
        const type = this.editable.getType();
        const { allSslVersionTypes } = this;
        const supportedVersionTypes = _.pluck(allSslVersionTypes, 'value');

        if (type === 'SSL_PROFILE_TYPE_SYSTEM') {
            const hiddenType = 'SSL_VERSION_TLS1_3';
            const index = supportedVersionTypes.indexOf(hiddenType);

            if (index !== -1) {
                supportedVersionTypes.splice(index, 1);
            }
        }

        this.supportedVersionTypes = supportedVersionTypes;
    }

    /**
     * Checks whether the form is valid by checking whether none of the ssl Types are selected.
     * @returns {boolean}
     */
    formIsValidForTheCheck() {
        return !this.sslTypeNotSelected();
    }

    /**
     * Fetches SSL Rating info.
     * @protected
     */
    fetchIndicatorInfo_() {
        if (this.formIsValidForTheCheck()) {
            this.editable.cancelRequests('checkBeforeSave');

            const { indicator } = this;

            indicator.ready = false;
            delete this.editable.errors;
            delete indicator.error;
            delete indicator.security;
            delete indicator.performance;
            delete indicator.compatibility;

            this.editable.request('post', '/api/sslprofilecheck',
                this.editable.dataToSave(), null, 'checkBeforeSave')
                .then(({ data }) => {
                    delete indicator.error;
                    indicator.ready = true;
                    indicator.security = data.ssl_rating.security_score;
                    indicator.performance = data.ssl_rating.performance_rating
                        .enumeration('SSL_SCORE_');
                    indicator.compatibility = data.ssl_rating.compatibility_rating
                        .enumeration('SSL_SCORE_');
                })
                .catch(({ data }) => {
                    if (data && data.error) {
                        indicator.ready = true;
                        indicator.error = data.error;
                    }
                });
        }
    }

    /**
     * Provides tab switching functionality.
     * @param {string} newTab - Name of the new tab to be set.
     */
    onCipherTabChange(newTab) {
        const { tab: currentTab } = this.ui;

        if (newTab !== currentTab) {
            this.AviConfirmService_.confirm('Changing cipher type will reset to system default ' +
                'values, would you like to continue?')
                .then(() => this.setCipherTab(newTab));
        }
    }

    /**
     * Initializes cipher tab
     */
    initCipherTab() {
        if (this.editable.id && !this.editable.getConfig().cipher_enums) {
            this.ui.tab = 'string';
        } else {
            this.ui.tab = 'list';
        }
    }

    /**
     * Change cipher tab to new one.
     * @param {string} newType - Type of the tab to be changed to.
     */
    setCipherTab(newType) {
        this.editable.onCipherTypeChange(newType);
        this.ui.tab = newType;
        this.fetchIndicatorInfo_();
    }

    /**
     * Decides SSL Profile type, available for creation only.
     * Calls the relevant methods to update the config property and
     * supportedSslVersionTypes list accordingly.
     * @param {string} type - Selected Profile type.
     */
    onProfileTypeChange(type) {
        this.editable.onProfileTypeChange(type);
        this.updateSupportedVersionLists_();
    }

    /**
     * At least one SSL type should be selected.
     */
    sslTypeNotSelected() {
        return !this.enabledVersionTypes || !this.enabledVersionTypes.length;
    }

    /**
     * Called on ng-change of the Enable SSL Session Reuse checkbox. If boolean is set to false,
     * delete the SSL Session Timeout value.
     */
    onSslSessionReuseChange() {
        this.editable.onSslSessionReuseChange();
    }

    /**
     * Callback method called while toggling the accepted versions.
     * @param {string[]} updatedVersions
     */
    onVersionTypeChange(updatedVersions) {
        this.enabledVersionTypes = updatedVersions;
        this.editable.updateAcceptedVersions(updatedVersions);
        this.updateIsTls13Enabled_();
        this.fetchIndicatorInfo_();
    }

    /**
     * @override
     */
    $onDestroy() {
        this.editable.cancelRequests('checkBeforeSave');
    }
}

SslProfileModalController.modalPortalClassName = 'avi-modal';

SslProfileModalController.$inject = [
    '$element',
    '$timeout',
    'schemaService',
    'AviConfirmService',
];

/**
 * @ngdoc component
 * @name sslTlsProfileModal
 * @module templates/sslTlsProfileModal
 * @param {SSLProfile} editable - SSL/TLS Profile item.
 * @param {Function} closeModal - method to be called to close the modal.
 * @author Chitra
 * @desc Component for creating/editing a SSL/TLS Profile.
 */
angular.module('aviApp').component('sslTlsProfileModal', {
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: SslProfileModalController,
    templateUrl: `${componentPath}/${componentName}/${componentName}.html`,
});
