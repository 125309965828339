/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/administration/crash-reports.less';

/**
 * @alias module:controller/CrashReportsController
 * @private
 */
class CrashReportsController {
    /**
     * Returns Core file name based on its file path.
     * @param {string} url - File path to Core file.
     * @returns {string}
     */
    static getNameFromUrl(url) {
        return angular.isString(url) ? url.slice(url.lastIndexOf('/') + 1) : '';
    }

    constructor($http, $window, $q) {
        this.$http = $http;
        this.$window = $window;
        this.rows = [];

        const self = this;

        this.gridConfig = {
            rowId: 'url',
            searchFields: ['url'],
            expandedContainerTemplate: '<pre class="stack-trace">{{::row.stack_trace}}</pre>',
            expanderDisabled(row) {
                const { stack_trace: trace } = row;

                return angular.isUndefined(trace) || trace === '';
            },
            fields: [{
                name: 'name',
                title: 'Filename',
                transform(row) {
                    return CrashReportsController.getNameFromUrl(row.url);
                },
            }],
            singleactions: [{
                title: 'Download',
                class: 'icon-download-1',
                do(row) {
                    $window.open(row.url, '_blank');
                },
            }],
            multipleactions: [{
                title: 'Delete',
                do(rows) {
                    const queue = rows.map(row => {
                        const fileName = CrashReportsController.getNameFromUrl(row.url);
                        const api = `api/fileservice?uri=controller://archive/${fileName}`;

                        return $http.delete(api);
                    });

                    $q.all(queue).finally(() => self.getList());

                    return true;
                },
            }],
        };
        this.getList();
    }

    /**
     * Requests Core file list.
     */
    getList() {
        this.$http.get('/api/fileservice?uri=controller://archive')
            .then(data => this.dataHandler(data));
    }

    /**
     * Handles Core file list.
     * @param {Object} response - Angular $http response object.
     */
    dataHandler(response) {
        this.rows = response.data.results;
    }
}

CrashReportsController.$inject = [
    '$http',
    '$window',
    '$q',
];

/**
 * @ngdoc controller
 * @name CrashReportsController
 * @module controller/CrashReportsController
 * @description
 *
 *     Controls Crash Report (i.e. Cores) list page in Administration>System.
 *
 */
angular.module('aviApp').controller('CrashReportsController', CrashReportsController);
