/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/healthscore.less';

/**
 * @ngdoc directive
 * @name aviHealthscore
 * @restrict AE
 * @param {Item} item
 * @param {string=} stopAsyncOnHide - Popover needs runtime & alert data and loads it on open
 *     event. If this string evaluates to `true` on popover hide it will stop these async updates.
 * @param {*=} getRuntimeParams - Sometimes we need to pass argument to Item.getRuntimeData method
 *     and this optional param may pass it.
 * @param {string=} type - Item type which is being used to render this element.
 * @author Aravindh Nagarajan
 */
angular.module('aviApp').directive('aviHealthscore', [
'$compile', '$filter', 'popoverFactory', 'UpdatableItem', 'getRuntimeDataFingerprint',
function($compile, $filter, popoverFactory, UpdatableItem, getRuntimeDataFingerprint) {
    /**
     * Falsy method to hide popover layouts
     * @return {boolean}
     */
    const noShow = () => false;

    /**
     * Returns true if the runtime status is oper_up
     * @param {Object} runtimeData
     * @return {boolean}
     */
    const isOperUpState = runtimeData => {
        return (runtimeData && runtimeData.oper_status &&
            runtimeData.oper_status.state === 'OPER_UP') || false;
    };

    // Default healthscoresettings methods
    // applicable for all items excluding gslbsite and gslbservice
    const defaultHealthScoreSettings = {
        showHealthScore: isOperUpState,
        showOperState(runtimeData) {
            return (runtimeData && runtimeData.oper_status &&
                runtimeData.oper_status.state !== 'OPER_UP') || false;
        },
    };

    // Layout settings for different Item types.
    const layoutSettings = {
        gslbservice: {
            showHealthScore: noShow,
            showOperState: noShow,
        },
        gslbsite: {
            showHealthScore: noShow,
            showOperState: isOperUpState,
        },
        default: defaultHealthScoreSettings,
    };

    function aviHealthscoreLink(scope, $elem) {
        const unitStateLabelFilter = $filter('unitStateLabel');

        scope.config = scope.type && layoutSettings[scope.type] || layoutSettings['default'];

        const $popoverContent = $('<div avi-healthscore-popup />')
            .attr({
                item: 'item',
                'get-runtime-params': 'getRuntimeParams',
                'show-health-score': 'config.showHealthScore(runtimeData)',
                'show-oper-state': 'config.showOperState(runtimeData)',
            });

        const popoverConfig = {
            className: 'healthScoreCard',
            position: 'left',
            margin: 10,
            width: 300,
            height: 100,
            removeAfterHide: true,
            repositioning: true,
            carat: true,
            hide: {
                parentMouseOut: true,
            },
        };

        class PopoverClass extends popoverFactory {
            show() {
                const { item } = scope;

                this.config.html = $compile($popoverContent)(scope);
                super.show($elem);

                if (item instanceof UpdatableItem) {
                    item.loadHsGlance();
                    item.loadMetrics(['alert', 'health', 'faults']);
                }
            }

            hide() {
                const { item } = scope;

                super.hide();

                //for some items we don't need to update data any time but after hover
                if (scope.stopAsyncOnHide && item instanceof UpdatableItem) {
                    item.async.stop(true);
                }
            }
        }

        const popover = new PopoverClass(popoverConfig);

        scope.runtimeData = {};
        scope.stateLabel = '';

        /**
         * Returns true If an item has healthscore value available
         * @return {boolean}
         */
        scope.isHealthScoreValueAvailable = function() {
            const operStatus = scope.runtimeData['oper_status'];

            return scope.config.showHealthScore(scope.runtimeData) && operStatus &&
                ['OPER_UP', 'OPER_DISABLING', 'OPER_ERROR_DISABLED']
                    .indexOf(operStatus['state']) !== -1 || false;
        };

        scope.showExclamation = function() {
            const noExclMark = [
                'Up',
                'Disabled',
                'Inactive',
                'Unused',
                'Failed to Disable',
                'Disabling',
            ];

            return scope.stateLabel && noExclMark.indexOf(scope.stateLabel) === -1 || false;
        };

        /**
         * Displays healthscore popover when hovering over healthscore icon
         * popover can be shown if either healthscore/operstate is available for an item.
         */
        scope.onMouseEnter = function() {
            if (scope.config.showHealthScore(scope.runtimeData) ||
                scope.config.showOperState(scope.runtimeData)) {
                popover.show($elem);
            }
        };

        scope.$watch(() => {
            const { item } = scope;

            return item && getRuntimeDataFingerprint(item.getRuntimeData(scope.getRuntimeParams));
        }, () => {
            const runtimeData = scope.item && scope.item.getRuntimeData(scope.getRuntimeParams);

            if (runtimeData) {
                scope.runtimeData = runtimeData;

                const operState = runtimeData['oper_status'] && runtimeData['oper_status']['state'];

                scope.stateLabel = operState ? unitStateLabelFilter(operState) : '';
            } else {
                scope.runtimeData = {};
                scope.stateLabel = '';
            }
        });

        scope.$on('$destroy', () => popover.remove());
    }

    return {
        restrict: 'AE',
        scope: {
            item: '<',
            type: '@',
            stopAsyncOnHide: '@',
            getRuntimeParams: '<',
        },
        templateUrl: 'src/views/components/healthscore.tpl.html',
        link: aviHealthscoreLink,
    };
}]);
