/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input } from '@angular/core';
import './expander.less';

/**
 * @ngdoc component
 * @name  expander
 * @description Component for an expander.
 * @module common/expander
 * @param openerClassName - Class name to be added to the expander opener.
 * @param label - If the expander opener just needs a string to be displayed, this can be passed in
 *     instead of using transcluded content.
 * @param opener - Element to transclude as the expander opener.
 * @param openerActions - Element to transclude on the right side of the opener.
 * @param content - Element to transclude as the content to be opened.
 * @example
 *     <expander>
 *         <opener>
 *             <div>EXPAND ME</div>
 *         </opener>
 *         <content>
 *             <div>EXPANDED</div>
 *         </content>
 *     </expander>
 * @author alextsg
 */
@Component({
    selector: 'expander',
    templateUrl: './expander.component.html',
})
export class ExpanderComponent {
    @Input() label?: string;
    @Input() openerClassName?: string;
    @Input() showOpenerActions?: boolean = false;

    expanded: boolean = false;
    hover: boolean = false;

    /**
     * Click handler for clicking the expander.
     */
    handleClick(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Returns the name of the icon class to use.
     */
    getIconClass(): string {
        return this.expanded ? 'sl-icon-arrow-up' : 'sl-icon-arrow-down';
    }

    /**
     * Handler for the mouse hover on the opener element.
     */
    handleHover(hover: boolean = false): void {
        this.hover = hover;
    }
}
