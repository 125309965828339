/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const
    componentName = 'c-grid-table-row-expander',
    template = '<div ng-click="$ctrl.onClick($event)"/>';

class gridTableRowExpanderController {
    constructor($rootScope, $scope, $elem, $compile) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$elem = $elem;
        this.$compile = $compile;
    }

    $onInit() {
        const { $elem, row } = this;

        $elem.addClass(componentName);

        if (this.onInit) {
            this.onInit({ row });
        }

        if (!this.template) {
            return;
        }

        const $scope = this.$rootScope.$new(true, this.$scope);

        $scope.row = row;

        //legacy, deprecated
        $scope.config = this.config;

        this.$compile(this.template)($scope)
            .appendTo($elem.find('> div'));
    }

    $onDestroy() {
        if (this.onDestroy) {
            this.onDestroy({ row: this.row });
        }
    }

    /**
     * Prevents event propagation.
     * @param {Object} event
     */
    onClick(event) {
        event.stopPropagation();
    }
}

gridTableRowExpanderController.$inject = [
    '$rootScope',
    '$scope',
    '$element',
    '$compile',
];

/**
 * @ngdoc component
 * @name gridTableRowExpander
 * @param {string} template
 * @param {*} row
 * @param {gridConfig} config - Passed so that expander template could use it (deprecated).
 * @param {Function=} onInit - Function to be called before template rendering.
 * @param {Function=} onDestroy - Function to be called after template rendering.
 * @desc
 *
 *     Wrapper component for grid table row expander.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi').component('gridTableRowExpander', {
    bindings: {
        template: '<',
        config: '<',
        row: '<',
        onInit: '&?',
        onDestroy: '&?',
    },
    controller: gridTableRowExpanderController,
    template,
});
