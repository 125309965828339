/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './license-upload-panel.component.less';

const componentName = 'license-upload-panel';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing:licenseUploadPanelBindings
 * @see {@link module:avi/licensing.licenseUploadPanelComponent licenseUploadPanelComponent}
 */
class LicenseUploadPanelController {
    constructor(AviModal, AviAlertService, licensingService) {
        /**
         * @type {AviModalService}
         * @protected
         */
        this.aviModal_ = AviModal;

        /**
         * @protected
         */
        this.aviAlertService_ = AviAlertService;

        /**
         * @type {module:avi/licensing.licensingService}
         * @protected
         */
        this.licensingService_ = licensingService;

        /**
         * Holds file content for new license before it is uploaded
         * @type {string}
         */
        this.newLicense = '';
    }

    /**
     * Sends license string to the server
     * @param {string} license License file content
     */
    upload(license) {
        this.licensingService_.uploadLicenseFile(license)
            .then(this.onUploadSuccess)
            .catch(({ data }) => {
                this.aviAlertService_.throw(data.result);
            });
    }

    /**
     * Opens Key Apply Modal.
     */
    openApplyKeyModal() {
        this.aviModal_.open('license-key-modal', {
            onKeyApplySuccess: () => this.onUploadSuccess(),
        });
    }
}

LicenseUploadPanelController.$inject = [
    'AviModal',
    'AviAlertService',
    'licensingService',
];

/**
 * @name licenseUploadPanelComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing:licenseUploadPanelBindings} bindings
 * @property {module:avi/licensing:LicenseUploadPanelController} controller
 * @description
 *      Component for License page upload panel. Contains options to
 *      Upload a license file or apply license key.
 * @author Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licenseUploadPanel', {
    /**
     * @mixin licenseUploadPanelBindings
     * @memberOf module:avi/licensing
     * @property {Function} onUploadSuccess Success handler for License Key/File upload.
     */
    bindings: {
        onUploadSuccess: '&',
    },
    controller: LicenseUploadPanelController,
    templateUrl:
        'src/components/pages/administration/settings/licensing-page/' +
        `${componentName}/${componentName}.component.html`,
});
