/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/timeseries-card.less';

/**
 * @ngdoc directive
 * @name timeseriesCard
 * @restrict E
 * @author Alex Malitsky
 * @param {Metric} metric - Metric instance to be presented.
 * @param {string=} header - Chart header to be rendered. When not passed Metric properties will
 *     be used or {@link NamesHelper}.
 * @param {boolean=} mouseUpdatesCharts - When attribute value resolves to true mouse hover
 *     events are active over the sparkline chart.
 * @param {boolean=} isActive - Active class switcher.
 * @description
 *
 *     Future replacement for {@link sparklineCard} directive which works wo chart config using a
 *     passed {@link Metric} instance.
 *
 */
//TODO fix styles
angular.module('aviApp').directive('timeseriesCard', [
'myAccount', 'DisplayValue',
function(myAccount, DisplayValue) {
    const timeseriesCardController = scope => {
        const { metric } = scope;

        function updateValues() {
            const displayValuesType = myAccount.getValuesDisplayType();

            const { value, unit } = DisplayValue.createCardValueUnit(
                scope.series,
                displayValuesType,
            );

            scope.value = value;
            scope.unit = unit;

            if (scope.errorSeries) {
                scope.errorValue = DisplayValue.createCardValueUnit(
                    scope.errorSeries,
                    displayValuesType,
                ).value;
            }
        }

        scope.series = metric.getMainSeries();
        scope.errorSeries = metric.getErrorSeries();
        scope.header = scope.header || metric.getTitle();

        //TODO watch GraphSync also
        scope.$watchGroup([
            'series.getLatestPointTime()',
            () => myAccount.getValuesDisplayType(),
        ], updateValues);
    };

    return {
        scope: {
            metric: '<',
            header: '<',
            isActive: '<',
            mouseUpdatesCharts: '@',
        },
        restrict: 'E',
        templateUrl: '/src/views/components/timeseries-card.html',
        controller: ['$scope', timeseriesCardController],
    };
}]);
