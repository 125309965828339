/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  alertConfigGridExpander
 * @description  Displays Alert Config rule expander info.
 */
function Controller() {
    this.$onInit = function() {
        const self = this;

        this.alertRule = [this.config.alert_rule];

        this.gridConfig = {
            fields: [{
                title: 'Type',
                name: 'type',
                transform() {
                    return self.config.source.enumeration();
                },
            }],
            rowId(row) {
                return self.config.uuid;
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
        };

        if (Array.isArray(this.config.alert_rule.sys_event_rule)) {
            this.gridConfig.fields.push({
                title: 'Event(s)',
                name: 'event',
                transform(row) {
                    return self.getRuleString(row.sys_event_rule);
                },
            });
        } else if (Array.isArray(this.config.alert_rule.metrics_rule)) {
            this.gridConfig.fields.push({
                title: 'Metric(s)',
                name: 'metric',
                transform(row) {
                    return self.getRuleString(row.metrics_rule);
                },
            });
        }
    };

    /**
     * Returns a string to be displayed in the Event(s) or Metric(s) column. Combines the
     * operator and not_cond booleans.
     * @param  {Object[]} rules - Array of events or metrics.
     * @return {string}
     */
    this.getRuleString = function(rules) {
        const operator = this.config.alert_rule.operator.enumeration('OPERATOR_').toLowerCase();

        return rules.map(function(rule) {
            const id = rule.event_id || rule.metric_id;

            return (rule.not_cond ? 'not ' : '') + id.enumeration();
        }).join(`, ${operator} `);
    };
}

angular.module('aviApp').component('alertConfigGridExpander', {
    bindings: {
        config: '<',
    },
    controller: Controller,
    template: '<grid config="$ctrl.gridConfig" rows="$ctrl.alertRule"></grid>',
});
