/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  SensitiveFieldRuleConfig
 * @description
 *     SensitiveFieldRuleConfig class, mapped to the protobuf message SensitiveFieldRule.
 *     Used when configuring rules in Sensitive Log Profile.
 */
const sensitiveFieldRuleConfigFactory = (
        PolicyRuleConfigItem,
        SensitiveFieldRuleStringMatchConfig,
        SensitiveFieldRuleLogActionConfig,
) => {
    class SensitiveFieldRuleConfig extends PolicyRuleConfigItem {
        constructor(args) {
            super(args);

            this.actionEnabled = this.hasActions();
        }

        /**
         * Set the unique action in Sensitive Field Rule.
         */
        addLogAction() {
            this.addAction('action');
        }

        /**
         * Remove the unique action in Sensitive Field Rule.
         */
        removeLogAction() {
            this.removeAction('action');
        }

        /**
         * @override
         */
        beforeEdit() {
            super.beforeEdit();

            if (!this.hasMatches()) {
                this.addMatch('match');
            }

            this.setEnable();
        }
    }

    angular.extend(SensitiveFieldRuleConfig.prototype, {
        type: 'SensitiveFieldRule',
        matchSettingsHash: {
            match: {
                className: SensitiveFieldRuleStringMatchConfig,
                isRepeated: false,
                isNested: false,
            },
        },
        actionSettingsHash: {
            action: {
                className: SensitiveFieldRuleLogActionConfig,
                isRepeated: false,
                isNested: false,
            },
        },
    });

    return SensitiveFieldRuleConfig;
};

sensitiveFieldRuleConfigFactory.$inject = [
        'PolicyRuleConfigItem',
        'SensitiveFieldRuleStringMatchConfig',
        'SensitiveFieldRuleLogActionConfig',
];

angular.module('aviApp').factory(
    'SensitiveFieldRuleConfig', sensitiveFieldRuleConfigFactory,
);
