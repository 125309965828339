/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import './waf-policy-psm-locations-list.less';
import { WAF_PSMLOCATION_SUM_MATCHED_METRIC } from '../../../../waf.constants';

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationsListComponent
 * @module waf/wafPolicyPsmLocationsListComponent
 * @desc Component for displaying a list of WAF Policy PSM Locations in the WAF PSM Group modal.
 * @param locations - RepeatedMessageItem containing WafPSMLocationConfigItem instances.
 * @param onEdit - Called when the user clicks the edit rule button.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-locations-list',
    templateUrl: './waf-policy-psm-locations-list.component.html',
})
export class WafPolicyPsmLocationsListComponent {
    @Input() locations: RepeatedMessageItem<WafPSMLocationConfigItem>;
    @Input() preventEdit: boolean;
    @Input() psmGroup: WafPolicyPsmGroup;

    @Output() onEdit = new EventEmitter();

    private locationMetric: WafPolicyPsmGroupMetric;

    constructor(
        @Inject('Timeframe') private Timeframe: any,
        @Inject('WafPolicyPsmGroupMetric') private WafPolicyPsmGroupMetric: WafPolicyPsmGroupMetric,
    ) {}

    /**
     * @override
     */
    ngOnInit() {
        const { step, limit } = this.Timeframe.selected();

        this.locationMetric = new this.WafPolicyPsmGroupMetric({
            id: this.psmGroup.id,
            metricIds: [WAF_PSMLOCATION_SUM_MATCHED_METRIC],
            params: {
                step,
                limit,
            },
        });

        this.locationMetric.load();
    }

    /**
     * Handler for editing a whitelist rule.
     */
    handleEdit(location: WafPSMLocationConfigItem): void {
        this.onEdit.emit(location);
    }

    /**
     * Handler for deleting a whitelist rule.
     * @param index - Index of the whitelist rule to remove.
     */
    handleDeleteLocation(index: number): void {
        this.locations.remove(index);
    }

    /**
     * Handler for the drag-and-drop event.
     * @param dragEvent - Object returned by angular/cdk/drag-drop when the user has dropped an item
     *     after dragging it.
     */
    handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.locations.moveItem(previousIndex, currentIndex);
    }

    /**
     * Returns the number of location matches for a location.
     */
    getLocationMatches(locationName: string): number {
        return this.locationMetric.getSumById(locationName, WAF_PSMLOCATION_SUM_MATCHED_METRIC);
    }

    /**
     * @override
     */
    ngOnDestroy(): void {
        this.locationMetric.destroy();
    }
}
