/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name UploadPackagesController
 * @description - Controller for Upload Packages page in Administration.
 *
 */
angular.module('aviApp').controller('UploadPackagesController', ['CollateUpload',
function(CollateUpload) {
    const vm = this;

    vm.ui = {
        file: null,
        uploadStarted: false,
    };

    vm.upload = new CollateUpload(
        {
            uri: 'controller://hsmpackages',
            chunkIndexStartsWith: 1,
        },
    );

    /**
     * @public
     * Calls send method from upload instance to upload a file.
     * @return {ng.$q.promise}
     */
    vm.uploadFile = function() {
        vm.ui.uploadStarted = true;

        let uriName = vm.ui.file.name;
        const extensionIndex = uriName.lastIndexOf('.');

        // Remove file extension from filename
        if (extensionIndex > -1) {
            uriName = uriName.slice(0, extensionIndex);
        }

        vm.upload.send(
            vm.ui.file,
            vm.ui.file.name,
            `/api/fileservice/hsmpackages?hsmtype=${uriName}`,
        );
    };
}]);
