/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class GslbHealthMonitorProxy {
    constructor(Schema) {
        this.schema = Schema;
    }

    /**
     * Adds empty Health Monitor Proxy object to specified site.
     */
    addHealthMonitorProxy() {
        const { site } = this;

        site.hm_proxies = site.hm_proxies || [];
        site.hm_proxies.push({
            site_uuid: '',
        });
    }

    /**
     * Removes Health Monitor Proxy object at index specified.
     * @param {number} index
     */
    removeHealthMonitorProxy(index) {
        const { site } = this;

        if (angular.isArray(site.hm_proxies)) {
            site.hm_proxies.splice(index, 1);
        }
    }
}

GslbHealthMonitorProxy.$inject = ['Schema'];

/**
 * @ngdoc component
 * @nme gslbHmSelect
 * @description
 *      GSLB Site Health Monitor Proxy list create component.
 * @param {GslbSiteConfig} site - GSLB site where HM proxy will be added.
 * @param {GslbSiteConfig[]} sites - Available active GSLB sites.
 */
angular.module('aviApp').component('gslbHmSelect', {
    bindings: {
        site: '<',
        sites: '<',
    },
    controller: GslbHealthMonitorProxy,
    templateUrl: '/src/components/infrastructure/gslb/hm-select/hm-select.html',
});
