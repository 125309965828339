/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AppMapVSListDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     DataTransformer to make small modifications over {@link AppMapVS} items list received
 *     through {@link MicroServiceGraphFactory.getVsImmediateNeighbors}.
 *
 */
angular.module('aviApp').factory('AppMapVSListDataTransformer', ['RevisedDataTransformer',
function(RevisedDataTransformer) {
    function AppMapVSListDataTransformer(args) {
        AppMapVSListDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(AppMapVSListDataTransformer, RevisedDataTransformer);

    /** @override */
    AppMapVSListDataTransformer.prototype.processResponse = function(resp) {
        const res = { data: { results: [], count: 0 } };

        res.data.results = resp.results.map(function(node) {
            const config = {
                name: node.name,
                uuid: node.uuid,
                groupId: node.groupId,
                rootNodeUuid: resp.rootNodeId,
            };

            // we can't have `both` in a list - must be client or server depending on requested
            // nodes type.
            if (config.groupId === 'both') {
                config.groupId = resp.nodeType;
            }

            return { config };
        });

        res.data.count = res.data.results.length;

        return res;
    };

    return AppMapVSListDataTransformer;
}]);
