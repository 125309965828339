/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('charts')
    .factory('Dominators', ['d3v4', 'chartUtils', (d3, chartUtils) => {
        /**
         * Class to create dominating contributors for Chart class.
         */
        class Dominators {
            /**
             * @param {Function} xScaleFn - D3 scale function.
             * @param {Function} yScaleFn - D3 scale function.
             */
            constructor(xScaleFn, yScaleFn) {
                this.xScaleFn = xScaleFn;
                this.yScaleFn = yScaleFn;

                /**
                 * Parent container to store dominant contributors polygon.
                 * @type {d3.Selection}
                 */
                this.container = d3
                    .select(chartUtils.createElement())
                    .attr('class', 'chart-dominators');

                /**
                 * ISO8601 times for dominant contributors.
                 * @type {string[]}
                 */
                this.dominators = [];

                /**
                 * Series values.
                 * @type {ChartPoint[]}
                 */
                this.series = [];

                /**
                 * Interval between each series point in millisec.
                 * @type {number}
                 */
                this.step = 0;
            }

            /**
             * Draws dominating contributors.
             * @param {string[]} dominators - ISO8601 timestamps.
             * @param {ChartPoint[]} series
             * @param {number} step - Dominating contributor time length in milliseconds.
             * @param {string=} color
             */
            draw(dominators, series, step, color) {
                const polyPoints = [];

                this.dominators = dominators;
                this.series = series;
                this.step = step;

                if (angular.isArray(dominators)) {
                    let p0;
                    let p1;
                    let p2;
                    let p3;
                    let ts0;
                    let ts1;
                    let y0;
                    let y1;

                    dominators.forEach(isoTime => {
                        ts0 = new Date(isoTime).getTime();
                        ts1 = ts0 + step;
                        y0 = chartUtils.getSeriesYFromTimestamp(ts0, series);
                        y1 = chartUtils.getSeriesYFromTimestamp(ts1, series);
                        p0 = [this.xScaleFn(ts0), this.yScaleFn(0)];
                        p1 = [this.xScaleFn(ts0), this.yScaleFn(y0)];
                        p2 = [this.xScaleFn(ts1), this.yScaleFn(y1)];
                        p3 = [this.xScaleFn(ts1), this.yScaleFn(0)];
                        polyPoints.push(p0, p1, p2, p3);
                    });

                    const polyStr = polyPoints.map(p => p.join(',')).join(' ');
                    let polySel = this.container.select('polygon.dompoly');

                    if (polySel.empty()) {
                        polySel = this.container
                            .append('polygon')
                            .attr('class', 'dompoly')
                            .style('fill', color);
                    }

                    polySel.attr('points', polyStr);
                }
            }

            /**
             * Attached dominating contributors element to specified element.
             * @param {Element} parent
             */
            render(parent) {
                parent.appendChild(this.container.node());
            }

            /**
             * Updates scale functions.
             * @param {Function} xScaleFn
             * @param {Function} yScaleFn
             */
            updateScale(xScaleFn, yScaleFn) {
                this.xScaleFn = xScaleFn;
                this.yScaleFn = yScaleFn;
            }

            /**
             * Resizes dominant contributors polygon using stored values.
             */
            resize() {
                this.draw(this.dominators, this.series, this.step);
            }
        }

        return Dominators;
    }]);
