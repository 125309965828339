/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('ControllerFaultDataTransformer', [
'RevisedDataTransformer', RevisedDataTransformer => {
    return class ControllerFaultDataTransformer extends RevisedDataTransformer {
        /**
         * API is returning a hash of faults instead of the list.
         * @override
         **/
        processResponse(rsp) {
            let results = [];

            if ('results' in rsp.data) {
                results = _.map(rsp.data.results, (row, type) => {
                    const config = {
                        type,
                        faults: row,
                    };

                    return { config };
                });
            }

            rsp.data.results = results;
            rsp.data.count = results.length;

            return rsp;
        }
    };
}]);
