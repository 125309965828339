/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('EmailController', [
'$scope', 'Collection', 'Regex', 'CRUDGridConfig', 'AviModal',
function($scope, Collection, Regex, CRUDGridConfig, AviModal) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new Collection({
        objectName: 'alertemailconfig',
        windowElement: 'adm-email-create',
    });

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;

    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: 'Name',
        sortBy: 'name',
    }, {
        name: 'data.config.to_emails',
        title: 'To Address',
    }, {
        name: 'data.config.cc_emails',
        title: 'CC Address',
    }];

    $scope.gridConfig.singleactions.unshift({
        title: 'Validate Settings',
        class: 'icon-lostlog',
        do(row) {
            const { config } = row.data;

            AviModal.open('adm-email-validate', {
                config: {
                    to_emails: config.to_emails,
                    cc_emails: config.cc_emails,
                },
                uuid: config.uuid,
                type: 'email',
            });
        },
    });

    // References for form validation
    $scope.Regex = Regex;
}]);
