/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name CollMetricsDataTransport
 * @author Alex Malitsky
 * @description
 *
 *     POST network call with a static URL and payload as an object having a list of
 *     subrequests. Provides metrics data of any kind about any objects in one call.
 *
 *     Can make multiple simultaneous calls to different controllers (GSLB Sites) - these calls
 *     have different headers and payloads.
 *
 */

/**
 * Request object for the collection metrics call. Most of properties are optional
 * @typedef {Object} CollMetricsRequest
 * @property {string} id - Unique within one API call id of request. Can't contain commas. Required.
 * @property {string} entity_uuid - Id of ServiceEngine or VirtualService or smth else.
 * @property {string} pool_uuid - Pool Id
 * @property {string} server - Server id
 * @property {string} metric_id - List of metric names concatenated by comma. Required.
 * @property {number} limit - How many data points of timeseries to analyze.
 * @property {number} step - Duration of time between adjacent datapoints of the timeseries.
 * @property {string} start - Date to start from in ISO format.
 * @property {string} dimension_aggregation
 * @property {boolean} pad_missing_data
 */

/**
 * @typedef {Object} MetricSeriesDataPoint
 * @property {string} timestamp - Date in ISO string format.
 * @property {number} value
 */

/**
 * @typedef {Object} MetricSeries
 * @property {Object} header
 * @property {string} header.name - Metric id.
 * @property {string} header.entity_uuid - Item uuid.
 * @property {string} header.units - Backend enum value such as 'BITS_PER_SECOND'.
 * @property {Object} header.statistics - Min, max, average values.
 * @property {MetricSeriesDataPoint[]} data - Array of data points.
 */

/**
 * Hash of MetricSeries where key is an Item Id.
 * @typedef {{string: MetricSeries[]}} CollMetricsResponsePerItemId
 */

/**
 * Hash of MetricSeries where key is an entity uuid of Item participated in request.
 * @typedef {{string: CollMetricsResponsePerItemId}} CollMetricsResponsePerRequestId
 */

/**
 * Hash of responses where key is a unique ID of the request within one call.
 * @typedef {{string: CollMetricsResponsePerRequestId[]}} CollMetricsFullResponse
 */

angular.module('aviApp').factory('CollMetricsDataTransport', [
'DataTransport',
function(DataTransport) {
    /**
     * @class
     * @extends DataTransport
     */
    return class CollMetricsDataTransport extends DataTransport {
        constructor(args) {
            if (!angular.isObject(args)) {
                args = {};
            }

            args.httpMethod = 'post';
            args.includeName = angular.isUndefined(args.includeName) || !!args.includeName;

            super(args);
        }

        /**
         * Returns a static URL even for multiple simultaneous calls.
         * @returns {string}
         * @override
         */
        getRequestUrl_() {
            const url = `${this.apiUrlPrefix_}analytics/metrics/collection?` +
                'pad_missing_data=false&dimension_limit=1000';

            if (this.includeName) {
                return `${url}&include_name=true&include_refs=true`;
            }

            return url;
        }

        /**
         * @typedef {Object} ReqPayload
         * @property {CollMetricsRequest[]} metric_requests
         */

        /**
         * Wraps the requests array into structure expected by API. For mulit-cluster API calls
         * we need to group requests by different clusterIds and return an array of
         * {metric_requests}
         * @param {CollMetricsRequest[]} requests
         * @returns {ReqPayload[]}
         * @override
         */
        getRequestPayload_(requests) {
            const sample = _.sample(requests);

            //multiple controllers
            if (sample && sample.clusterId) {
                const payloads = [],
                    clusterHash = {};//indexes of subrequests array for each controller

                requests = angular.copy(requests);

                requests.forEach(request => {
                    const { clusterId } = request;

                    if (clusterId) {
                        request.clusterId = undefined;

                        if (!(clusterId in clusterHash)) {
                            clusterHash[clusterId] = payloads.length;
                            payloads.push([request]);
                        } else {
                            payloads[clusterHash[clusterId]].push(request);
                        }
                    } else {
                        console.error('Each request should have a clusterId');
                    }
                });

                return payloads.map(metricRequests => ({ metric_requests: metricRequests }));
            }

            return { metric_requests: requests };
        }

        /**
         * Headers needed only for mutli-cluster API calls.
         * @returns {Object.<string, Object>|undefined}
         * @override
         **/
        getRequestHeaders_(requests) {
            const sample = _.sample(requests);

            if (sample && sample.clusterId) {
                const headers = [],
                    clustersHash = {};

                requests.forEach(({ clusterId }) => {
                    if (clusterId) {
                        if (!(clusterId in clustersHash)) {
                            clustersHash[clusterId] = true;
                            headers.push({ 'X-Avi-Internal-GSLB': clusterId });
                        }
                    } else {
                        console.error('Each request should have a clusterId');
                    }
                });

                return headers;
            }
        }

        /**
         * Returns only series section of API response. For multi-cluster API calls we need to
         * merge them into one hash. We can do that since request ids should be unique among all
         * collection metrics API subrequests.
         * @override
         * @returns {CollMetricsFullResponse}
         **/
        processResponse_(rsps) {
            if (!angular.isArray(rsps)) {
                rsps = [rsps];
            }

            return rsps.reduce((acc, { data }) => angular.extend(acc, data['series']), {});
        }
    };
}]);
