/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @restrict E
 * @name eventSearch
 * @param {string} value - Value we update though input field.
 * @param {Function} fire - ng-change/ng-enter/on-clear event handler.
 * @param {boolean} onChange - If defined ngChange event handler will be set.
 * @author Alex Malitsky
 * @description
 *
 *     On-change event is optional, debounced and switched off by default. Ng-enter and on-clear
 *     events are not debounced but do cancel ng-change timeout when it is set.
 *
 */
angular.module('aviApp').directive('eventSearch', ['$timeout', function($timeout) {
    function link(scope, elem, attr) {
        let timer;

        scope.callFire = function(immediate) {
            $timeout.cancel(timer);

            if (immediate) {
                scope.fire();
            } else {
                timer = $timeout(scope.fire, 500);
            }
        };

        scope.clearSearch = function() {
            scope.value = '';

            $timeout.cancel(timer);

            //not sure why do we need timeout here but it doesn't work without
            $timeout(function() {
                scope.callFire(true);
            });
        };

        scope.onChange = !_.isUndefined(attr['onChange']) ? scope.callFire : angular.noop;
    }

    return {
        restrict: 'E',
        scope: {
            value: '=',
            fire: '&',
        },
        templateUrl: 'src/views/components/event-search.html',
        link,
    };
}]);
