/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './error-page-body-modal.less';

/**
 * @ngdoc component
 * @name errorPageBodyModal
 * @param {ErrorPagebody} editable
 * @description
 *     Error Page Body config modal.
 */
class ErrorPageBodyModalController {
    constructor(Schema) {
        this.Schema = Schema;
    }
}

ErrorPageBodyModalController.$inject = [
        'Schema',
];

angular.module('aviApp').component('errorPageBodyModal', {
    controller: ErrorPageBodyModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/error-page-body-modal/error-page-body-modal.html',
});
