/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import { Injectable, Inject } from '@angular/core';

interface ActionValueLabelHash {
    [key: string]: string;
}

/**
 * @ngdoc service
 * @name WafPolicyWhitelistRuleExpanderContentService
 * @module services/WafPolicyWhitelistRuleExpanderContentService
 * @desc Service used to display action labels for Waf Policy Whitelist actions.
 * @author alextsg
 */
@Injectable()
export class WafPolicyWhitelistRuleExpanderContentService {
    actionValueLabelHash: ActionValueLabelHash;

    constructor(@Inject('schemaService') private schemaService: any) {
        const enumType = 'WafPolicyWhitelistAction';

        this.actionValueLabelHash = this.schemaService.getEnumValueLabelsHash(enumType);
    }

    /**
     * Returns text to display based on an action enum.
     * @param value - Action enum from WafPolicyWhitelistAction.
     * @protected
     * @returns Text to display from the enum.
     */
    getActionLabel_ = (value: string): string => {
        return this.actionValueLabelHash[value];
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @param actions - List of action enums from WafPolicyWhitelistAction.
     * @returns Comma-separated string of text to display from the enums.
     */
    getActionLabels(actions: string[] = []): string {
        return actions.map(this.getActionLabel_).join(', ');
    }
}
