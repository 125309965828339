/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './confirm.less';

/**
 * @ngdoc directive
 * @name confirm
 * @restrict A
 * @param {string} confirmMessage
 * @param {Function} confirmProceed - On confirmation callback.
 * @param {Function} confirmCheck - Function which will be called to make a decision whether we
 *     need to show confirmation dialog (when true was returned) or not.
 * @param {Item} editable
 */
angular.module('aviApp').directive('confirm', ['$compile', 'Base',
function($compile, Base) {
    const confirmTemplate = require('./confirm.partial.html');
    let confirmCompile = null;

    const refersToAPI = '/api/object-graph?refers_to=';

    const base = new Base();

    function link(scope, element) {
        scope.ui = {
            busy: false,
            showTemplateConfirmation: false,
            numOfVS: 0,
            numOfPool: 0,
            applications: [],
        };

        scope.gridConfig = {
            fields: [{
                name: 'name',
                title: 'Name',
            }, {
                name: 'type',
                title: 'Type',
                template: '{{ row.type }}',
            }],
            layout: {
                hideSearch: true,
            },
            rowId(row) {
                return `${row.name}-${row.type}`;
            },
            searchFields: ['name'],
        };

        scope.onConfirm = function() {
            if (typeof scope.confirmProceed === 'function') {
                scope.confirmProceed();
            }

            dismiss();
        };

        scope.onCancel = dismiss;

        function dismiss() {
            if (confirmCompile) {
                confirmCompile.remove();
            }
        }

        element.on('click', function() {
            if (!scope.confirmCheck()) {
                scope.confirmProceed();

                return;
            }

            if ($('.modal-confirm').length) {
                dismiss();

                return;
            }

            if (scope.editable) {
                if (!scope.editable.data.config.url) {
                    scope.confirmProceed();

                    return;
                }

                const
                    { objectName } = scope.editable,
                    { uuid } = scope.editable.data.config,
                    api = `${refersToAPI + objectName}:${uuid}&fields=name`;

                const { ui } = scope;

                ui.showTemplateConfirmation = true;
                ui.busy = true;
                ui.numOfVS = 0;
                ui.numOfPool = 0;

                base.request('GET', api)
                    .then(function(rsp) {
                        if (rsp && rsp.data && Array.isArray(rsp.data.results)) {
                            scope.ui.applications = rsp.data.results;

                            // If no results are returned, it means there aren't any applications
                            // using the profile and should just proceed with onConfirm().
                            if (!rsp.data.count) {
                                scope.onConfirm();

                                return;
                            }

                            // Need to set the number of VSes and Pools using this template.
                            rsp.data.results.forEach(function(result) {
                                if (result.type === 'VirtualService') {
                                    scope.ui.numOfVS++;
                                } else if (result.type === 'Pool') {
                                    scope.ui.numOfPool++;
                                }
                            });
                        } else {
                            scope.onConfirm();
                        }
                    }).catch(function() {
                        scope.onConfirm();
                    }).finally(function() {
                        scope.ui.busy = false;
                    });
            }

            confirmCompile = $compile(confirmTemplate)(scope);

            const modal = element.closest('.avi-modal, .modal-component');

            modal.append(confirmCompile);
            scope.$apply();
        });

        scope.$on('$destroy', function() {
            base.cancelRequests();
            dismiss();
        });
    }

    return {
        restrict: 'A',
        scope: {
            confirmMessage: '@',
            confirmProceed: '&',
            confirmCheck: '&',
            editable: '=',
        },
        link,
    };
}]);
