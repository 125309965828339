/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function applicationProfileCollectionFactory(
    Collection,
    ApplicationProfile,
) {
    /**
     * @class ApplicationProfileCollection
     * @constructor
     * @memberOf module:avi/profiles/application
     * @extends module:avi/dataModel.Collection
     */
    class ApplicationProfileCollection extends Collection {}

    Object.assign(ApplicationProfileCollection.prototype, {
        objectName_: 'applicationprofile',
        itemClass_: ApplicationProfile,
        windowElement_: 'application-profile-modal',
    });

    ApplicationProfileCollection.prototype.serverDefaultsOverride_ = {
        type: 'APPLICATION_PROFILE_TYPE_HTTP',
        http_profile: {
            compression_profile: {
                filter: [],
            },
            cache_config: {
                uri_non_cacheable: undefined,
            },
        },
        dos_rl_profile: {
            rl_profile: {
                client_ip_connections_rate_limit: {
                    count: 0,
                    action: {
                        status_code: undefined,
                        redirect: undefined,
                        type: 'RL_ACTION_NONE',
                    },
                },
                uri_failed_requests_rate_limit: undefined,
                client_ip_scanners_requests_rate_limit: undefined,
                client_ip_to_uri_failed_requests_rate_limit: undefined,
                client_ip_to_uri_requests_rate_limit: undefined,
                uri_requests_rate_limit: undefined,
                uri_scanners_requests_rate_limit: undefined,
                client_ip_requests_rate_limit: undefined,
                client_ip_failed_requests_rate_limit: undefined,
                custom_requests_rate_limit: undefined,
            },
        },
    };

    return ApplicationProfileCollection;
}

applicationProfileCollectionFactory.$inject = [
    'Collection',
    'ApplicationProfile',
];

angular.module('avi/profiles/application')
    .factory('ApplicationProfileCollection', applicationProfileCollectionFactory);
