/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

import './notification.less';

/**
 * @typedef {('success'|'error'|'warning'|'info')} NotificationType
 */

/**
 * @ngdoc component
 * @name notification
 * @param {NotificationType=} type - The type of notification to be shown
 * @description Component displaying a message in 4 different styles, as a success, error, warning,
 *     and info notification.
 */
const typeToClassName = {
    success: 'notification--success',
    error: 'notification--error',
    warning: 'notification--warning',
    info: 'notification--info',
};

const typeToIcon = {
    success: 'sl-icon-check',
    error: 'sl-icon-minus',
    warning: 'sl-icon-exclamation',
    info: 'sl-icon-info',
};

class NotificationController {
    /**
     * Sets some classes to be used in the template to differentiate between a success and error
     * notification.
     */
    $onInit() {
        const notificationType = this.type || 'success';

        this.typeClassName = typeToClassName[notificationType];
        this.typeIcon = typeToIcon[notificationType];
    }
}

angular.module('kit.ui.vantage.avi').component('notification', {
    bindings: {
        type: '@?',
        onClose: '&?',
    },
    transclude: true,
    controller: NotificationController,
    template: require('./notification.partial.html'),
});
