/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name loadPoolServer
 * @description
 *
 *     Takes server config of pool instance and creates it's instance. Pool is supposed to be
 *     loaded through api/pool/poolId to have a list of servers. No API call is made.
 */
angular.module('router.vantage.avi').factory('loadPoolServer', [
'$q', 'Server', function($q, Server) {
    return args => {
        const
            { pool, serverId } = args,
            serverConfig = pool.getServerConfigById(serverId);

        if (!serverConfig) {
            const { poolId } = args;

            return $q.reject(`server ${serverId} is not found in pool ${poolId}`);
        }

        args.data = {
            config: serverConfig,
        };

        return new Server(args);
    };
}]);
