/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name  ControllerFaultCollDataSource
 * @description
 *     DataSource for ControllerFaultCollection. Extends LimitedListCollDataSource as it does not
 *     support pagination or search, and sets the update interval to 3 minutes.
 */
angular.module('aviApp').factory('ControllerFaultCollDataSource', [
'LimitedListCollDataSource', LimitedListCollDataSource => {
    return class ControllerFaultCollDataSource extends LimitedListCollDataSource {
        constructor(args) {
            super(args);
            this.updateInterval_ = 180;
            this.protectedUpdateInterval_ = true;
        }
    };
}]);
