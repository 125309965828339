/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function eventListDataTransformerFactory(ListDataTransformer, Auth) {
    class EventListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            const tenantHash = {};

            resp = super.processResponse(resp, request);

            EventListDataTransformer.setIsAccurate_(resp.data);

            if ('results' in resp.data && Array.isArray(resp.data.results) &&
                resp.data.results.length) {
                Auth.getTenants().forEach(function(tenant) {
                    if (tenant && tenant.tenant_ref) {
                        tenantHash[tenant.tenant_ref.slug()] = tenant.tenant_ref;
                    }
                });

                resp.data.results.forEach(function(event) {
                    event.config.tenant_ref = tenantHash[event.config.tenant] || '';
                });
            }

            return resp;
        }

        /**
         * Set the isAccurate flag to true if percent_remaining = 0, else
         * set it to false.
         * percent_remaining flag > 0, indicates that,
         * event list needs to be reloaded to fetch more records from api
         * @param {Object} data - event response data from api
         * @protected
         */
        static setIsAccurate_(data) {
            data.isAccurate = data.percent_remaining === 0;
        }
    }

    return EventListDataTransformer;
}

eventListDataTransformerFactory.$inject = ['ListDataTransformer', 'Auth'];

/**
 * @ngdoc factory
 * @name EventListDataTransformer
 * @description
 *  Class used for processing the event list response
 */
angular.module('aviApp').factory('EventListDataTransformer', eventListDataTransformerFactory);
