/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name HealthMonitorListController
 * @description
 *
 *     Regular list view controller.
 *
 */
angular.module('aviApp').controller('HealthMonitorListController', [
'$scope', 'CRUDGridConfig', 'HealthMonitorCollection', 'loadedSystemInfoService',
function($scope, CRUDGridConfig, HealthMonitorCollection, systemInfo) {
    const collection = new HealthMonitorCollection({
        params: {
            is_federated: undefined, // need to get both types here
        },
    });

    const fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        }, {
            name: 'type',
            title: 'Type',
            template:
                '<span class="sel-type">{{row.getConfig().type | enum: "HEALTH_MONITOR_"}}</span>',
        }, {
            name: 'send',
            title: 'Send Interval',
            template:
                '<span class="sel-send-interval">{{ row.getConfig().send_interval }} sec</span>',
        }, {
            name: 'recv',
            title: 'Receive Timeout',
            template:
                '<span class="sel-receive-timeout">{{row.getConfig().receive_timeout}} sec</span>',
        }, {
            name: 'data.config.successful_checks',
            title: 'Successful Checks',
        }, {
            name: 'data.config.failed_checks',
            title: 'Failed Checks',
        },
    ];

    if (systemInfo.haveGSLBConfig()) {
        fields.splice(2, 0, {
            name: 'is_federated',
            title: 'Federated',
            template: '{{ row.getConfig().is_federated | booleanLabel:"yes" }}',
        });
    }

    const gridConfig = {
        collection,
        fields,
        singleactions: [{
            title: 'Edit',
            class: 'icon-pencil-4',
            hidden: row => !row.isAllowed(),
            do: row => row.isEditable() && row.edit() || collection.clone(row),
        }],
    };

    $scope.healthMonitorsGridConfig = CRUDGridConfig(gridConfig);

    $scope.$on('$destroy', () => collection.destroy());
}]);
