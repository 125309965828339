/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Component for cluster-edit modal.
 * @ngdoc component
 * @name  clusterEdit
 * @param {Cluster} editable
 * @param {function} closeModal - Closes cluster-edit modal.
 */
class ClusterEditController {
    constructor(Regex, Auth) {
        this.Regex = Regex;
        this.Auth_ = Auth;
        this.passwordRequired = false;
    }

    $onInit() {
        if (this.Auth_.isAWSCloud()) {
            this.passwordRequired = true;
        }
    }
}

ClusterEditController.$inject = [
        'Regex',
        'Auth',
];

angular.module('aviApp').component('clusterEdit', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: ClusterEditController,
    templateUrl: 'src/components/modals/administration/controller/cluster-edit.html',
});
