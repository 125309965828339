/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Module for Avi App. It's the root module for Avi App.
 * @module avi/app
 */

/**
 * @class
 * @constructor
 * @memberOf module:avi/app
 */
class AviRootController {
    constructor($compile, $document, $scope, Auth, popoverFactory, Schema) {
        this.Auth_ = Auth;
        this.PopoverFactory_ = popoverFactory;
        this.$scope_ = $scope;
        this.$compile_ = $compile;
        this.$document_ = $document;

        $scope.Schema = Schema;
    }

    /**
     * @override
     */
    $onInit() {
        if (Math.abs(this.Auth_.initData.timeDifference) > 120) {
            const templateParent = this.$document_[0].querySelector('.messages') ||
                this.$document_[0].body;

            this.utcMismatchPopover_ = this.createPopoverInstance_();
            this.utcMismatchPopover_.show($(templateParent));
        }
    }

    /**
     * Creates popover instance
     * @return {PopoverFactory}
     * @protected
     */
    createPopoverInstance_() {
        const $popoverContent = $('<utc-mismatch-popup/>')
            .attr({ 'on-close': '$ctrl.dismissUTCMessage()' });
        const popoverConfig = {
            className: 'utc-mismatch-popup',
            position: 'bottom',
            removeAfterHide: true,
            hide: {
                onEscape: true,
            },

            html: this.$compile_($popoverContent)(this.$scope_),
        };

        return new this.PopoverFactory_(popoverConfig);
    }

    /**
     * Removes the client clock mismatch popup.
     */
    dismissUTCMessage() {
        if (this.utcMismatchPopover_) {
            this.utcMismatchPopover_.remove();
        }
    }

    /**
     * @override
     */
    $onDestroy() {
        this.dismissUTCMessage();
    }
}

AviRootController.$inject = [
    '$compile',
    '$document',
    '$scope',
    'Auth',
    'popoverFactory',
    'Schema',
];

/**
 * @name aviRootComponent
 * @property {module:avi/app.AviRootController} controller
 * @memberOf module:avi/app
 * @description Application root component.
 * @author alextsg, chitra
 */
angular.module('avi/app').component('aviRoot', {
    controller: AviRootController,
    templateUrl: 'src/components/avi-root/avi-root.html',
});
