/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('poolSubheader', function() {
    return {
        restrict: 'E',
        templateUrl: '/src/views/components/application/pool-subheader.html',
        link: {
            pre(scope, $element) {
                $element
                    .addClass('avi-subheader')
                    .attr({
                        'help-popover-anchor': 'poolinformation',
                        'help-popover-position': 'lm',
                    });
            },
        },
    };
});

angular.module('aviApp').directive('poolNavbar', function() {
    class PoolNavbarDirective {
        constructor($stateParams) {
            this.$stateParams = $stateParams;
        }
    }

    PoolNavbarDirective.$inject = [
        '$stateParams',
    ];

    return {
        restrict: 'E',
        templateUrl: '/src/views/components/application/pool-navbar.html',
        link: {
            pre(scope, $element) {
                $element.addClass('navbar');
            },
        },
        controller: PoolNavbarDirective,
        controllerAs: '$ctrl',
    };
});
