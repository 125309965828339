/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc controller
 * @name MicroServiceGroupListController
 * @description Controller for MicroService Group page.
 **/
angular.module('aviApp').controller('MicroServiceGroupListController', [
'$scope', 'CRUDGridConfig', 'MicroServiceGroupCollection',
function($scope, CRUDGridConfig, MicroServiceGroupCollection) {
    $scope.gridConfig = new CRUDGridConfig();

    // Instantiate collection
    $scope.collection = new MicroServiceGroupCollection();

    // Mofify default grid configuration
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
        },
    ];

    $scope.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig.layout = {
        hideEditColumns: true,
    };
}]);
