/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const GeoLocSelectController = class {
    constructor(Schema, GeoProfileCollection) {
        this.Schema = Schema;
        this.GeoProfileCollection = GeoProfileCollection;
    }

    $onInit() {
        this.setGeoLocSourceOptions_();
    }

    $onDestroy() {
        if (this.profileCollection_) {
            this.profileCollection_.destroy();
        }
    }

    /**
     * Decides which options are available for user to select for Geo Loc Sources.
     * @protected
     */
    setGeoLocSourceOptions_() {
        this.geoLocationOptions = [];
        //Options for geoLocation found in Schema.enums.GslbGeoLocationSource.values
        this.geoLocationOptions.push('GSLB_LOCATION_SRC_USER_CONFIGURED');

        if (this.isPoolMember) {
            this.profileCollection_ = new this.GeoProfileCollection({ limit: 1 });

            this.geoLocationOptions
                .push('GSLB_LOCATION_SRC_INHERIT_FROM_SITE');

            this.profileCollection_.load()
                .then(() => {
                    if (this.profileCollection_.getTotalNumberOfItems()) {
                        this.geoLocationOptions
                            .push('GSLB_LOCATION_SRC_FROM_GEODB');
                    }
                });
        }
    }

    /**
     * Resets location params on location source change event.
     * @public
     */
    onSourceChange() {
        this.site.location.location = {};
    }
};

GeoLocSelectController.$inject = [
    'Schema',
    'GeoProfileCollection',
];

/**
 * @ngdoc component
 * @name geolocSelect
 * @param {GslbPoolMemberConfig|GslbSiteConfig|GslbThirdPartySiteConfig} site
 * @param {boolean=} isPoolMember
 * @description
 *
 *      GSLB Geo Location selector.
 */
angular.module('aviApp').component('geolocSelect', {
    bindings: {
        site: '<',
        isPoolMember: '<?',
    },
    controller: GeoLocSelectController,
    templateUrl: '/src/components/infrastructure/gslb/geoloc-select/geoloc-select.html',
});
