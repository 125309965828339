/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServiceFQDN
 * @author Alex Malitsky
 * @description
 *
 *     Used to show GSLB Service DNS VS metrics info in a grid filtered by a list of FQDNs. That
 *     is why this item is a combination of GslbSite, VS and FQDN.
 */
angular.module('aviApp').factory('GSLBServiceFQDN', [
'UpdatableItem', 'Auth',
function(UpdatableItem, Auth) {
    return class GSLBServiceFQDN extends UpdatableItem {
        constructor(args) {
            super(args);
            this.objectName = 'GSLBServiceFQDN';
        }

        /** @override */
        getIdFromData_(data) {
            const { clusterId, vsId, domainName } = data.config;

            return `${clusterId}:${vsId.slug()}:${domainName}`;
        }

        /** @override */
        getName() {
            const { siteName, vsId, domainName } = this.getConfig();

            return `${siteName}:${vsId.name() || vsId.slice(-10)}:${domainName}`;
        }

        /** @override */
        getRef() {
            return '';
        }

        /**
         * PoolId (second element) is not needed actually but good to have it to reuse a regular
         * mechanism.
         * @override
         **/
        getMetricsTuple() {
            const {
                domainName,
                gslbServiceName,
                vsId,
            } = this.getConfig();

            const tenantName = Auth.getTenantName();

            const objId = `fqdn-gs:${domainName}#${tenantName}:${gslbServiceName}`;

            return {
                entity_uuid: vsId.slug(),
                obj_id: objId,
            };
        }

        /**
         * Only difference is the fact we want to set a clusterId property on every subrequest
         * since we might make few API calls to different controllers in order to get this info.
         * @override
         */
        getCollMetricsRequests(fields) {
            const
                { clusterId } = this.getConfig(),
                requests = super.getCollMetricsRequests(fields);

            requests.forEach(request => request.clusterId = clusterId);

            return requests;
        }

        /** @override */
        isProtected() {
            return true;
        }
    };
}]);
