/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').service('TenantSettingsService', [
'Auth', '$q', 'systemConfigService', 'Base', function(Auth, $q, systemConfig, Base) {
    /**
     * Tenant Settings service.
     * @param {Auth} Auth
     * @param {angular.$q} $q
     * @param {systemConfigService} systemConfig
     * @constructor
     */
    class TenantSettingsService extends Base {
        constructor() {
            super();

            /**
             * Event type triggered when tenant config is updated.
             * @const {string}
             */
            this.parseMessagesEvent = 'parseMessages';

            /**
             * IP Route Domain setting.
             * @type {string}
             */
            this.ipRoutesDomain = '';

            /**
             * Indicates if tenant config is present in the system settings.
             * @type {boolean}
             */
            this.hasConfig = false;

            /** @type {string} IP Routes Domain message. */
            this.ipRoutesDomainMessage = 'N/A';
            /** @type {string} Service Engine message. */
            this.seProviderContextMessage = 'N/A';
            /** @type {string} SE access message */
            this.tenantAccessProviderSeMessage = 'N/A';

            /** @type {Array<string>} All messages for SE Group Privileges. */
            this.seProviderContextMessages = [
                'Service Engines are managed within the provider context, ' +
                'shared across tenants.',
                'Service Engines are managed within the tenant context, ' +
                'not shared across tenants.',
            ];

            /** @type {Array<string>} All messages for IP Route Domain. */
            this.ipRoutesDomainMessages = [
                'Per tenant IP route domain.',
                'Share IP route domain across tenants.',
            ];

            /** @type {Array<string>} All SE Access messages. */
            this.tenantAccessProviderSeMessages = [
                'Tenant has Read Access to Service Engines',
                'Tenant has No Access to Service Engines',
            ];
        }

        /**
         * Sets internal properties based on systemConfig#global_tenant_config meaning that
         * Auth#systemConfig must be present. If not present and user is authenticated this
         * method will load it.
         * @returns {ng.$q.promise}
         * @public
         */
        parseMessages() {
            let promise;

            if (Auth.isLoggedIn()) {
                if (systemConfig.isReady()) {
                    this.parseMessages_(systemConfig.getConfig()['global_tenant_config']);
                    promise = $q.when(true);
                } else {
                    promise = systemConfig.load().then(() => {
                        this.parseMessages_(systemConfig.getConfig()['global_tenant_config']);
                    });
                }
            } else {
                promise = $q.reject('User is not authenticated');
            }

            return promise;
        }

        /**
         * Sets service properties based on systemConfig#global_tenant_config.
         * @param {Object} tenantConfig - TenantConfiguration protobuf message.
         * @protected
         */
        parseMessages_(tenantConfig) {
            if (tenantConfig) {
                this.hasConfig = true;
                this.ipRoutesDomain = tenantConfig.tenant_vrf.toString();
                this.seProviderContext = tenantConfig.se_in_provider_context.toString();
                this.tenantAccessProviderSe = tenantConfig.tenant_access_to_provider_se.toString();

                this.ipRoutesDomainMessage = this.ipRoutesDomain === 'true' ?
                    this.ipRoutesDomainMessages[0] : this.ipRoutesDomainMessages[1];

                this.seProviderContextMessage = this.seProviderContext === 'true' ?
                    this.seProviderContextMessages[0] : this.seProviderContextMessages[1];

                this.tenantAccessProviderSeMessage = this.tenantAccessProviderSe === 'true' ?
                    this.tenantAccessProviderSeMessages[0] : this.tenantAccessProviderSeMessages[1];

                this.trigger(this.parseMessagesEvent);
            }
        }

        /**
         * Submits current values to update system settings API.
         */
        saveConfig() {
            return systemConfig.updateTenantSettings({
                tenant_vrf: this.ipRoutesDomain === 'true',
                se_in_provider_context: this.seProviderContext === 'true',
                tenant_access_to_provider_se: this.tenantAccessProviderSe === 'true',
            }).then(() => {
                this.parseMessages();
            });
        }
    }

    return new TenantSettingsService();
}]);
