/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('WebhookCollection', ['Collection', 'Webhook', 'Auth',
(Collection, Webhook, Auth) => {
    class WebhookCollection extends Collection {
        /**
         * @override
         * Webhook uses PERMISSION_POOL as permission, and PERMISSION_WEBHOOK does not exist.
         */
        isCreatable() {
            return this.windowElement_ && Auth.context && Auth.context.tenant_ref !== '*' &&
                Auth.isAllowed('pool', 'w') || false;
        }
    }

    angular.extend(WebhookCollection.prototype, {
        objectName_: 'webhook',
        itemClass_: Webhook,
        windowElement_: 'webhook-create',
    });

    return WebhookCollection;
}]);
