/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

(aviApp => {
    aviApp.filter('slug', function() {
        return function(url) {
            if (url === undefined) {
                return '';
            }

            return url.slug();
        };
    });

    aviApp.filter('objectTypeFilter', function() {
        const types = {
            VIRTUALSERVICE: 'Virtual Service',
            SERVER: 'Server',
            POOL: 'Pool',
            SERVICEENGINE: 'Service Engine',
            VIRTUALMACHINE: 'Virtual Machine',
            CONTROLLER: 'Controller',
        };

        return function(objType) {
            return objType in types ? types[objType] : '';
        };
    });

    aviApp.filter('replaceString', function() {
        return function(value, str, replacement) {
            return value === str ? replacement : value;
        };
    });

    aviApp.filter('dashIfNotNumber', function() {
        return function(s) {
            return typeof s === 'number' ? s : '-';
        };
    });

    aviApp.filter('networkAdapterName', function() {
        return function(d) {
            if (d.name) {
                return d.name;
            }

            if (d.adapter && d.adapter !== 'Unknown') {
                return d.adapter;
            } else if (d.if_name) {
                return d.if_name;
            } else {
                return '';
            }
        };
    });

    aviApp.filter('noSpaces', function() {
        return function(str) {
            return str && str.split(' ').join('-');
        };
    });
    aviApp.filter('posOrNeg', function() {
        return function(num) {
            if (typeof num !== 'number') {
                return '';
            }

            if (num < 0) {
                return 'neg';
            } else if (num > 0) {
                return 'pos';
            } else {
                return 'nochange';
            }
        };
    });

    aviApp.filter('notDimensionUnknown', function() {
        return function(str) {
            if (str === 'DIMENSION_UNKNOWN') {
                return 'Unknown';
            } else {
                return str;
            }
        };
    });

    aviApp.filter('typeof', function() {
        return function(thing) {
            return typeof thing;
        };
    });

    aviApp.filter('name', function() {
        return function(url) {
            return url ? url.name() : '';
        };
    });

    aviApp.filter('enum', function() {
        return function(value, prefix) {
            return value ? value.enumeration(prefix) : '';
        };
    });

    /**
     * @ngdoc filter
     * @name schemaJson
     * @description
     *     Returns JSON value from Schema object from specified "fields" and "f_json_default"
     *     objects.
     */
    aviApp.filter('schemaJson', () => (object, fieldName, jsonField) => {
        try {
            return object.fields[fieldName].options.f_json_default.value[jsonField];
        } catch (e) {
            return '';
        }
    });

    /**
     * @ngdoc filter
     * @name enumText
     * @description
     *     Looks up text value inside Schema.enums[enumType].values[type].options.text.value
     *     for specified enum and object type.
     * @example
     *     Schema.enums['CloudType'].values['CLOUD_NONE'].options.text.value
     *     Schema.enums['IpamDnsType'].values['IPAMDNS_TYPE_OPENSTACK'].options.text.value
     */
    aviApp.filter('enumText', ['Schema', Schema => (type = '', enumType = '') => {
        const enumTypeObject = Schema.enums[enumType];

        if (enumTypeObject && enumTypeObject.values[type]) {
            const { options } = enumTypeObject.values[type];

            if (options.text && options.text.value) {
                return options.text.value;
            }
        }

        return type;
    }]);

    /**
     * @ngdoc filter
     * @name enumDesc
     * @param {string} enumVal - enum value.
     * @param {string} enumType - Protobuf enum type name.
     * @returns {string} Human readable string.
     * @description
     *
     *     Returns a enum value description from protobuf.
     */
    aviApp.filter('enumDesc', ['Schema', function(Schema) {
        const { enums } = Schema;

        return (enumVal, enumType) => {
            const enumTypeObject = enums[enumType];

            if (enumTypeObject && enumVal in enumTypeObject.values) {
                const { options } = enumTypeObject.values[enumVal];

                if ('e_description' in options && 'value' in options.e_description) {
                    return options.e_description.value;
                }
            }

            return enumVal;
        };
    }]);

    aviApp.filter('capitalize', function() {
        return function(value) {
            return value ? value.capitalize() : '';
        };
    });
    aviApp.filter('dash', function() {
        return function(value) {
            return value ? value.replace('_', '-') : '';
        };
    });

    aviApp.filter('undash', function() {
        return function(value) {
            return value ? value.replace(/-/g, ' ') : '';
        };
    });

    aviApp.filter('unitConversion', function() {
        return function(value) {
            if (!value) {
                return;
            }

            let a = value.split('_');

            a = _.map(a, function(s) {
                return s.slice(0, 1) + s.slice(1).toLowerCase();
            });

            return a.join(' ');
        };
    });

    aviApp.filter('ununderscore', function() {
        return function(value) {
            if (value && typeof value === 'string') {
                return value.replace(/_/g, ' ');
            }

            return '';
        };
    });

    aviApp.filter('formatBytes', function() {
        return function(value) {
            return Math.formatBytes(value);
        };
    });

    aviApp.filter('metricDescription', function() {
        const dictionary = {
            avg_bandwidth:
                'Average network bandwidth in both transmit and receive directions measured at ' +
                'network interface.',
            avg_total_rtt:
                'Average end to end network RTT as experienced by the end clients.',
            sum_connection_errors:
                'Total number of client network connections that were dropped or lossy.',
            avg_new_established_conns:
                'Average rate new client network connections established through virtual service.',
            avg_total_requests:
                'This is average number of HTTP requests received in a time interval.',
            avg_complete_responses:
                'This is average number of HTTP responses served in a time interval.',
            sum_errors:
                'Count of the client and server errors for a virtual service.',
            apdexr:
                'Apdex score computed with threshold(500ms) on average response times across all ' +
                'HTTP Requests.',
            avg_client_txn_latency:
                'Average client transaction latency computed by adding response latencies across ' +
                'all HTTP requests.',
            avg_cpu_wait:
                'Time virtual machine (server) was had to wait for CPU on the physical host. This' +
                'may be an indication of other virtual machines consuming extra CPU',
            avg_cpu_usage:
                'This is the virtual host\'s view of the CPU usage as amount of actively used ' +
                'virtual CPU, as a percentage of total available CPU for the virtual machine.',
        };

        return function(m) {
            const message = dictionary[m];

            return message || 'This metric is so anomalous it defies description!';
        };
    });

    aviApp.filter('jsonPretty', function() {
        return function(obj) {
            return angular.toJson(obj, true);
        };
    });

    aviApp.filter('eventType', function() { //for list of events on events page
        return function(str) {
            return str === 'CONFIG' ? 'Configuration' : 'System';
        };
    });

    /*
        * resourceUrl and mainUrl are both for client insights resource waterfall

        * resource url should return the part of the url that follows the / if it exists
        * main url should return the rest of the url
    */
    aviApp.filter('resourceUrl', function() {
        return function(str) {
            const url = str.split('/');

            // if only slashes are in http://
            if (url.length === 3) {
                return str;
            } else if (url[url.length - 1] !== '') {
                return url[url.length - 1];
            } else if (url[url.length - 2]) {
                return url[url.length - 2];
            } else {
                return str;
            }
        };
    });

    aviApp.filter('mainUrl', function() {
        return function(str) {
            const url = str.split('/');

            if (url.pop() === '') {
                url.pop();
            }

            return `${url.join('/')}/`;
        };
    });

    /* used in log details to show icon of deviceType, browser and OS */
    aviApp.filter('string2iconClass', function() {
        return function(string, type) {
            if (!string) {
                return '';
            }

            switch (type) {
                case 'os':
                    if (string.indexOf('Mac') === 0) {
                        return 'apple';
                    } else if (string.indexOf('Windows') === 0) {
                        return 'windows';
                    } else if (string.indexOf('Ubuntu') === 0) {
                        return 'ubuntu';
                    } else if (string.indexOf('Android') === 0) {
                        return 'android';
                    } else {
                        return 'unknown';
                    }

                case 'device':
                    if (string.indexOf('Computer') === 0) {
                        return 'desktop';
                    } else {
                        return 'tablet';
                    }

                case 'browser':
                    if (string.indexOf('Chrom') === 0) {
                        return 'chrome';
                    } else if (string.indexOf('Firefox') === 0) {
                        return 'firefox';
                    } else if (string.indexOf('IE') === 0) {
                        return 'ie';
                    } else if (string.indexOf('Safari') === 0 ||
                        string.indexOf('AppleMail') === 0) {
                        return 'apple';
                    } else if (string.indexOf('Opera') === 0) {
                        return 'opera';
                    } else if (string.indexOf('Android') === 0) {
                        return 'android';
                    } else {
                        return 'globe';
                    }

                default:
                    return '';
            }
        };
    });

    /* Can get any name of the user group or country code or Unknown @m */
    aviApp.filter('clientLocation2name', ['countryDict', function(countryDict) {
        return function(location) {
            let res = location || 'Unknown';

            if (location && location.length === 2) {
                res = countryDict.iso3toName[countryDict.iso2to3[location.toUpperCase()]] || res;
            } else if (location && location.length === 3) {
                res = countryDict.iso3toName[location.toUpperCase()] || res;
            }

            return res;
        };
    }]);

    aviApp.filter('flagImgSrc', ['countryDict', function(countryDict) {
        return function(isoCode) {
            const c = isoCode.length !== 2 ? countryDict.iso3to2[isoCode] : isoCode;

            return c ? `/src/views/worldmaps/flags/${c.toLowerCase()}.png` : '';
        };
    }]);

    /* number of milliseconds to human readable format @am
     * connected with log list layout by updateDurationLabelLength
     * in LogController
     * Rounds to one decimal place using Number(number.toFixed(1)). We need this so that 0.0 ms
     * becomes 0 ms. Without Number() it would return 0.0 ms.
     */
    aviApp.filter('ms2str', ['msToStringChunks', function(msToStringChunks) {
        return function(time, omitRem) {
            const chunks = msToStringChunks(time, omitRem);

            return chunks.reduce(
                (base, { value, dimension }) => `${base}${value}${dimension} `,
                '',
            ).slice(0, -1);
        };
    }]);

    aviApp.filter('hasSidebar', function() {
        return function(state) {
            const pages = {
                logs: 1,
                analytics: 1,
                insights: 1,
                summary: 1,
                security: 1,
            };
            const s = state.current.name.slice(state.current.name.lastIndexOf('.') + 1);

            return !!pages[s];
        };
    });

    //operational status of any unit (VS, SE, Pool)
    aviApp.filter('unitStateLabel', function() {
        return state => {
            switch (state) {
                case 'OPER_UP':
                case 'OPER_ACTIVE':
                    return 'Up';

                case 'OPER_ERROR_DISABLED':
                    return 'Failed to Disable';

                default:
                    if (state) {
                        if (state.indexOf('OPER_') === 0) {
                            return state.enumeration('OPER_');
                        }

                        return 'Down';
                    }

                    return 'N/A';
            }
        };
    });

    /**
     * @ngdoc filter
     * @name underscoresToTitleCase
     * @description
     *     Converts string "FOO_bar_FooBar" to title case string "Foo Bar Foobar".
     */
    aviApp.filter('underscoresToTitleCase', function() {
        return function(value) {
            if (!value) {
                return value;
            }

            return value.replace(/_/g, ' ').replace(/\w\S*/g, function(word) {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            });
        };
    });

    /**
     * @ngdoc filter
     * @name tenantsNameFilter
     * @description
     *     Filters array of tenant objects by tenant_ref property value name.
     */
    aviApp.filter('tenantsNameFilter', () => (tenants, match) => {
        if (!match || !Array.isArray(tenants) || !tenants.length) {
            return tenants;
        }

        const ret = [];

        tenants.forEach(tenant => {
            if (tenant && angular.isString(tenant.name)) {
                const tenantName = tenant.name.toLowerCase();

                if (tenantName.indexOf(match.toLowerCase()) > -1) {
                    ret.push(tenant);
                }
            }
        });

        return ret;
    });

    /**
     * @ngdoc filter
     * @name removeDuplicateTenants
     * @description
     *     Filters duplicate tenant names from input array containing objects with
     *     tenant_ref property.
     */
    aviApp.filter('removeDuplicateTenants', function() {
        return function(tenants) {
            if (!angular.isArray(tenants)) {
                return tenants;
            }

            let tenantNameHash = {};

            tenants = tenants.filter(function(tenant) {
                if (tenant.tenant_ref) {
                    const tenantName = tenant.tenant_ref.name();

                    if (!(tenantName in tenantNameHash)) {
                        tenantNameHash[tenantName] = 1;

                        return true;
                    }
                }

                return false;
            });

            tenantNameHash = null;

            return tenants;
        };
    });

    /**
     * @ngdoc filter
     * @name ipObjectToString
     * @description
     *     Parses object containing arrays for IP addresses, ranges, and subnet masks and returns a
     *     single comma separated string of IP addresses.
     */
    aviApp.filter('ipObjectToString', function() {
        return function(debug) {
            const filter = [];

            if (_.isUndefined(debug)) {
                return '';
            }

            if (debug.addrs) {
                debug.addrs.forEach(function(addr) {
                    filter.push(addr.addr);
                });
            }

            if (debug.ranges) {
                debug.ranges.forEach(function(range) {
                    filter.push(`${range.begin.addr}-${range.end.addr}`);
                });
            }

            if (debug.prefixes) {
                debug.prefixes.forEach(function(prefix) {
                    filter.push(`${prefix.ip_addr.addr}/${prefix.mask}`);
                });
            }

            return filter.join(', ');
        };
    });
    /**
     * @ngdoc filter
     * @name seGroupHaMode
     * @param {string} mode - Service Engine High Availability mode enum.
     * @returns {string} Human-readable description for the enum value.
     */
    aviApp.filter('seGroupHaMode', function() {
        return function(mode) {
            let res = '';

            switch (mode) {
                case 'HA_MODE_SHARED_PAIR':
                    res = 'Active/Active';
                    break;

                case 'HA_MODE_SHARED':
                    res = 'N+M (buffer)';
                    break;

                case 'HA_MODE_LEGACY_ACTIVE_STANDBY':
                    res = 'Legacy Active/Standby';
                    break;
            }

            return res;
        };
    });

    /**
     * Replaces string with value from hash, or calls String.prototype.enumeration() for further
     * evaluation if value is not present.
     * @param {string} value - original string to be evaluated and modified.
     * @param {string=} prefix - prefix string to strip from original string.
     * @return {string}
     */
    aviApp.filter('lookup', function() {
        const dict = {
            APPLICATION_PROFILE_TYPE_SSL: 'L4 SSL/TLS',
            APPLICATION_PROFILE_TYPE_SIP: 'SIP',
        };

        return function(value, prefix) {
            return dict[value] || value.enumeration(prefix);
        };
    });

    // ----------------------Filters --------------------------------//
    // This is not a great solution, I want to get a list of the possible devices so that I can
    // match them up better Have very close string2iconClass in filters/string.js @am */
    aviApp.filter('iconClassFilter', function() {
        return function(title) {
            const t = title.toLowerCase();
            const result = '';
            // If it's just a string, just check the second part, otherwise check the array
            const iconClasses = [
                { 'icon-desktop': ['computer', 'desktop'] },
                { 'icon-tablet': ['tablet', 'ipad', 'kindle'] },
                { 'icon-mobile': ['mobile', 'phone'] },
                'icon-globe', 'icon-windows',
                { 'icon-apple': ['mac', 'apple', 'ios', 'safari'] },
                { 'icon-linux': ['unix', 'linux'] },
                'icon-firefox', 'icon-chrome', 'icon-opera',
                { 'icon-ie': ['i.e.', 'ie'] }, 'icon-android'];

            for (let i = 0; i < iconClasses.length; i++) {
                const icon = iconClasses[i];

                if (typeof icon === 'string') {
                    if (t.match(new RegExp(icon.split('-')[1]))) {
                        return icon;
                    }
                } else {
                    _.each(icon, (value, key) => {
                        const stringsToCheck = icon[key];

                        for (let j = 0; j < stringsToCheck.length; j++) {
                            const str = stringsToCheck[j];

                            if (t.match(new RegExp(str))) {
                                return key;
                            }
                        }
                    });
                }
            }

            return result;
        };
    });

    aviApp.filter('countryName', ['countryDict', function(countryDict) {
        return function(isoCode) {
            if (isoCode === 'Others') {
                return 'Others';
            } else if (isoCode === 'DIMENSION_UNKNOWN') {
                return 'Unknown';
            } else {
                return countryDict.iso3toName[isoCode] || 'Unknown';
            }
        };
    }]);

    /**
     * @ngdoc filter
     * @name booleanLabel
     * @param {boolean} boolVal
     * @param {string=} type - If "enabled" is passed pair of "Enabled" & "Disabled" will be used.
     * @param {boolean} lowerCase - If true, returns the values as lowercased.
     * @description
     *     Translates boolean values into text labels. Default is "True" and "False".
     */
    aviApp.filter('booleanLabel', () => {
        const pairs = {
            enabled: ['Enabled', 'Disabled'],
            allowed: ['Allowed', 'Disallowed'],
            switch: ['Disable', 'Enable'],
            yes: ['Yes', 'No'],
            active: ['Active', 'Suspended'],
        };

        return function(boolVal, type, lowerCase) {
            let output;

            if (type && type in pairs) {
                const pair = pairs[type];

                output = boolVal ? pair[0] : pair[1];
            } else {
                output = boolVal ? 'True' : 'False';
            }

            if (lowerCase) {
                output = output.toLowerCase();
            }

            return output;
        };
    });

    /**
     * @ngdoc filter
     * @name  countItems
     * @param  {any[]} items - Array of items to be counted.
     * @param  {string} label - String to be used as the label for items.
     * @description
     *     Returns a string of the number of items in an array.
     */
    aviApp.filter('countItems', () => {
        return function(items, label) {
            const append = label || 'item';

            if (angular.isUndefined(items) || !angular.isArray(items)) {
                return `0 ${append}s`;
            }

            return items.length + (items.length === 1 ? ` ${append}` : ` ${append}s`);
        };
    });

    /**
     * @ngdoc filter
     * @name  geoLocation
     * @description
     *     Returns a string showing the location of the request along with the coordinates, ex.
     *     'USA/California/Santa Clara (127°N, 58°E)'
     * @param  {Object} location - Location object containing optional name and latitude+longitude
     *     properties.
     * @return {string}
     */
    aviApp.filter('geoLocation', () => {
        return function(location) {
            if (angular.isUndefined(location)) {
                return;
            }

            let locationString = '';

            if (!angular.isUndefined(location.name)) {
                locationString += `${location.name} `;
            }

            if (!angular.isUndefined(location.latitude) &&
                !angular.isUndefined(location.longitude)) {
                const latitude = `${Math.abs(location.latitude)}\xB0${
                    location.latitude < 1 ? 'S' : 'N'}`;
                const longitude = `${Math.abs(location.longitude)}\xB0${
                    location.longitude < 1 ? 'W' : 'E'}`;

                locationString += `(${latitude}, ${longitude})`;
            }

            return locationString;
        };
    });

    /**
     * @ngdoc filter
     * @name subnetString
     * @description
     *     Returns a subnet string given a subnet object with ip_addr and mask properties.
     * @param {Object} prefix - Subnet object.
     * @return {string}
     */
    aviApp.filter('subnetString', ['getSubnetString', getSubnetString => {
        return prefix => getSubnetString(prefix);
    }]);

    /**
     * @ngdoc filter
     * @name repeatedRefs
     * @description
     *     Returns a comma-separated string of ref names given an array of refs.
     * @param {string[]} refs - Array of refs with names.
     * @return {string}
     */
    aviApp.filter('repeatedRefs', () => {
        return refs => refs.map(ref => ref.name()).join(', ');
    });
})(angular.module('aviApp'));
