/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AviAlertService
 * @description
 *
 *     Service to keep all current error messages and show an alert modal window.
 *
 */
angular.module('aviApp').service('AviAlertService', [
'$rootScope', '$filter', 'AviModal',
function($rootScope, $filter, AviModal) {
    const backendErrorMsgFilter = $filter('backendErrorMsg');

    /**
     * Array of error messages objects having text and counter.
     * @type {{count: number, text: string}[]}
     */
    this.messages = [];

    /**
     * Appends an error's text to the list and shows an alert modal if it was hidden.
     * @param {Object|string=} message - Supposed to be a backend error message or object.
     */
    this.throw = function(message) {
        let sameMessage,
            msgText;

        if (message && (typeof message !== 'object' || !message.silent)) {
            //can be null on cancelled requests, ignore those
            msgText = backendErrorMsgFilter(message);

            sameMessage = _.findWhere(this.messages, { text: msgText });

            if (sameMessage) {
                sameMessage.count++;
            } else {
                this.messages.unshift({
                    count: 1,
                    text: msgText,
                });
            }

            if (!AviModal.isOpen('alert-msg')) {
                AviModal.open('alert-msg');
            }
        }
    };

    /**
     * Empties the list of errors.
     */
    this.clear = function() {
        this.messages.length = 0;
    };

    $rootScope.$on('userLoggedOut', this.clear.bind(this));
    $rootScope.$on('setContext', this.clear.bind(this));
}]);
