/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name parseIpList
 * @restrict A
 * @description Parses and formats comma-separated string of IP addresses or ranges.
 */
angular.module('aviApp').directive('parseIpList', ['RangeParser',
function(RangeParser) {
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseIpAddrList);
        ngModel.$formatters.push(formatIpAddrList);
    }

    /**
     * Parses string into an array of IP objects.
     * @param  {string} val - Comma-separated IP address string.
     * @return {Object[]} Array of IP objects or IP range objects.
     */
    function parseIpAddrList(val) {
        if (!val) {
            return '';
        }

        const
            ips = val.split(','),
            outputList = [];

        const check = _.any(ips, function(ip) {
            const value = RangeParser.ipRange2Json(ip);

            outputList.push(value);

            return value === null;
        });

        return check ? undefined : outputList;
    }

    /**
     * Formats IP objects into comma-separated IP address string.
     * @param  {Object[]} val - Array of IP objects or IP range objects.
     * @return {string} Comma-separated IP address string.
     */
    function formatIpAddrList(val) {
        if (!Array.isArray(val)) {
            return '';
        }

        return val.map(ip => {
            if (ip.addr) {
                return ip.addr;
            } else if (ip.begin && ip.end) {
                return `${ip.begin.addr}-${ip.end.addr}`;
            }

            return undefined;
        }).join(', ');
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
