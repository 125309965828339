/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './user-menu.less';

const componentName = 'user-menu';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.userMenuComponent}
 */
class UserMenuController {
    constructor(
        $scope,
        $compile,
        Auth,
        AviModal,
        messageService,
        popoverFactory,
        User,
    ) {
        this.$scope_ = $scope;
        this.$compile_ = $compile;
        this.Auth = Auth;
        this.AviModal_ = AviModal;
        this.messageService_ = messageService;
        this.User_ = User;

        /**
         * @type {string}
         */
        this.aviPortalUrl = 'https://avinetworks.com/portal';

        /**
         * @type {string}
         * @protected
         */
        this.additionalMenuTemplate_ = require(
            './additional-menu.partial.html',
        );

        /**
         * @type {string}
         * @protected
         */
        this.userCardMenuTemplate_ = require(
            './user-card-menu.partial.html',
        );

        /**
         * Current user's activity.
         * @type {userActivity|null}
         */
        this.userActivity = null;

        /**
         * Extended popoverFactory.
         * @protected
         */
        this.UserMenuPopover_ =
            class UserMenuPopover extends popoverFactory {
                constructor(config = {}, positionTop) {
                    super(config);

                    /**
                     * Top position value of the popover.
                     * @type {string}
                     */
                    this.top_ = positionTop;
                }

                /** @override */
                reposition() {
                    super.reposition();

                    if (this.popover) {
                        this.popover.css({
                            top: this.top_,
                        });
                    }
                }
            };
    }

    /** @override */
    $onInit() {
        //Load useractivity only when current user has 'PERMISSION_USER' access.
        if (this.Auth.isAllowed('user')) {
            this.User_.loadUserActivity(this.Auth.getUsername())
                .then(activity => {
                    this.userActivity = activity;
                });
        }
    }

    /**
     * Compiles popover template and Returns an instance of UserMenuPopoverFactory_.
     * @param {string} popoverContent - Menu content
     * @return {Object}
     * @protected
     */
    createPopoverInstance_(popoverContent) {
        const $popoverContent = $(popoverContent);

        const popoverConfig = {
            className: `${componentName}-popover`,
            position: 'bottom',
            removeAfterHide: true,
            margin: 10,
            repositioning: true,
            hide: {
                onEscape: true,
                outClick: true,
                innerClick: true,
            },
            html: this.$compile_($popoverContent)(this.$scope_),
        };

        return new this.UserMenuPopover_(popoverConfig, '40px');
    }

    /**
     * Gets the popover instance and displays menu.
     * @param {string} menuName Name of the menu to be displayed.
     * @param {angular.$event} event Menu icon click event.
     */
    showMenu(menuName, event) {
        let popover;

        switch (menuName) {
            case 'usercard':
                popover = this.getUserCardPopoverInstance_();
                break;

            case 'additional':
                popover = this.getAdditionalMenuPopoverInstance_();
                break;
        }

        popover.show(event.target);
    }

    /**
     * Returns popover instance for additional menu
     * Creates one if its undefined.
     * @return {popoverFactory}
     * @protected
     */
    getAdditionalMenuPopoverInstance_() {
        if (!this.additionalMenuPopover_) {
            this.additionalMenuPopover_ =
                this.createPopoverInstance_(this.additionalMenuTemplate_);
        }

        return this.additionalMenuPopover_;
    }

    /**
     * Returns popover instance for User-card menu.
     * Creates one if its undefined.
     * @return {popoverFactory}
     * @protected
     */
    getUserCardPopoverInstance_() {
        if (!this.userCardPopover_) {
            this.userCardPopover_ =
                this.createPopoverInstance_(this.userCardMenuTemplate_);
        }

        return this.userCardPopover_;
    }

    /**
     * Opens the dialog to generate a token for authentication.
     */
    openGenerateTokenModal() {
        const popupExists = this.messageService_.messageIsVisible('generateSsoTokenMessage');

        if (!popupExists) {
            const scope = this.$scope_.$new();

            scope.handleCloseMessage = () => {
                this.messageService_.hide('generateSsoTokenMessage');
                this.messageService_.remove('generateSsoTokenMessage');
                scope.$destroy();
            };

            const compiledMessage = this.$compile_(
                `<generate-sso-token-message
                    class="message wide animated fadeIn tokenGenerate"
                    on-close-message="handleCloseMessage()"
                    messenger="generateSsoTokenMessage">
                </generate-sso-token-message>`,
            )(scope);

            this.messageService_.append('generateSsoTokenMessage', compiledMessage);
        }
    }

    /**
     * Opens the About modal popup.
     */
    handleOpenAbout() {
        this.AviModal_.open('app-about');
    }

    /**
     * Logs out of the application.
     */
    handleLogout() {
        this.Auth.logout();
    }

    /** @override */
    $onDestroy() {
        const {
            AviModal_,
            messageService_,
            additionalMenuPopover_,
            userCardPopover_,
        } = this;

        if (additionalMenuPopover_) {
            additionalMenuPopover_.remove();
        }

        if (userCardPopover_) {
            userCardPopover_.remove();
        }

        if (AviModal_.isOpen('app-about')) {
            AviModal_.destroy('app-about');
        }

        messageService_.remove('generateSsoTokenMessage');
    }
}

UserMenuController.$inject = [
    '$scope',
    '$compile',
    'Auth',
    'AviModal',
    'messageService',
    'popoverFactory',
    'User',
];

/**
 * @name userMenuComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.UserMenuController} controller
 * @desc
 *      Component for User menu. Displays Popover for additional and user card menu.
 *      Found in the header.
 * @author alextsg, Aravindh Nagarajan
 */
angular.module('avi/navigation').component('userMenu', {
    controller: UserMenuController,
    templateUrl: `src/components/avi-header/${componentName}/${componentName}.html`,
});
