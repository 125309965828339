/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name virtualserviceDelete
 * Modal that allows the user to delete just the Virtual Service or the Virtual Service along with
 * all its children, ie. pools and pool groups.
 * @param {VirtualService[]} rows
 * @param {VirtualServiceCollection} collection
 * @param {Function} closeModal - closes this modal.
 */
class VirtualserviceDeleteController {
    /**
     * Deletes either the Virtual Service or the Virtual Service and its children depending on
     * the force param.
     * @param  {Boolean} force - If true, deletes all associated chidlren.
     */
    delete(force = false) {
        if (angular.isArray(this.rows) && !angular.isUndefined(this.collection)) {
            this.collection.dropByPriority(this.rows, force);
        }

        this.closeModal();
    }
}

angular.module('aviApp').component('virtualserviceDelete', {
    bindings: {
        rows: '<',
        collection: '<',
        closeModal: '&',
    },
    controller: VirtualserviceDeleteController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-list/' +
            'virtualservice-delete/virtualservice-delete.html',
});
