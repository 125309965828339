/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const imageInventoryDataTransformerFactory = ListDataTransformer => {
    /**
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.ListDataTransformer
     * @desc
     *
     *     Parse response from api/image-inventory. Extract image object and its related info.
     *
     * @author Zhiqian Liu
     */
    class ImageInventoryDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(rsp) {
            const { data } = rsp;
            const { results } = data;

            const imageList = [];

            if (Array.isArray(results)) {
                results.forEach(({ image_info: images }) => {
                    images.forEach(({ image: config, in_use: inUse, in_use_info: inUseInfo }) => {
                        let usedByController = false;

                        if (inUse) {
                            inUseInfo.forEach(({ node_type: nodeType }) => {
                                if (nodeType === 'NODE_CONTROLLER_CLUSTER') {
                                    usedByController = true;
                                }
                            });
                        }

                        imageList.push({
                            config,
                            runtime: {
                                in_use: inUse,
                                in_use_info: inUseInfo || null,
                                usedByController,
                            },
                        });
                    });
                });
            }

            data.results = imageList;
            data.count = imageList.length;

            return rsp;
        }
    }

    return ImageInventoryDataTransformer;
};

imageInventoryDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

angular.module('avi/upgrade')
    .factory('ImageInventoryDataTransformer', imageInventoryDataTransformerFactory);
