/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('HealthMonitorController', [
'$scope', 'CRUDGridConfig', 'HealthMonitorCollection',
function($scope, CRUDGridConfig, HealthMonitorCollection) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    $scope.collection = new HealthMonitorCollection();

    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: 'Name',
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'type',
            title: 'Type',
            template: '<span class="sel-type">' +
            '{{ row.data.config.type | enum:"HEALTH_MONITOR_"}}</span>',
            visibility: 'm',
        }, {
            name: 'send',
            title: 'Send Interval',
            template: '<span class="sel-send-interval">' +
            '{{ row.data.config.send_interval + " sec"}}</span>',
            visibility: 'd',
        }, {
            name: 'recv',
            title: 'Receive Timeout',
            template: '<span class="sel-receive-timeout">' +
            '{{ row.data.config.receive_timeout + " sec"}}</span>',
            visibility: 'd',
        }, {
            name: 'data.config.successful_checks',
            title: 'Successful Checks',
            visibility: 'd',
        }, {
            name: 'data.config.failed_checks',
            title: 'Failed Checks',
            visibility: 'd',
        }, {
            name: 'request',
            title: 'Request',
            template: '{{ config.templateFunctions.getString(row.data.config, \'request\') }}',
            visibility: 'optional',
        }, {
            name: 'response_string',
            title: 'Response String',
            template: '{{ config.templateFunctions.getString(row.data.config, \'response\') }}',
            visibility: 'optional',
        }, {
            name: 'response_code',
            title: 'Response Code',
            template: '{{ config.templateFunctions.getString(row.data.config, \'code\') }}',
            visibility: 'optional',
        },
    ];

    $scope.gridConfig.singleactions = [{
        title: 'Edit',
        class: 'icon-pencil-4',
        hidden: row => !row.isAllowed(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig.templateFunctions = {
        getString,
    };

    /**
     * Used to return a string based on the type of Health Monitor and the desired property.
     * @param  {Object} config - Health monitor config object.
     * @param  {string} target - Desired property. 'request', 'response', or 'code'.
     * @return {string} String to be displayed in grid.
     */
    function getString(config, target) {
        const hash = {
            HEALTH_MONITOR_HTTP: {
                obj: 'http_monitor',
                request: 'http_request',
                response: 'http_response',
                code: 'http_response_code',
            },
            HEALTH_MONITOR_TCP: {
                obj: 'tcp_monitor',
                request: 'tcp_request',
                response: 'tcp_response',
            },
            HEALTH_MONITOR_UDP: {
                obj: 'udp_monitor',
                request: 'udp_request',
                response: 'udp_response',
            },
            HEALTH_MONITOR_DNS: {
                obj: 'dns_monitor',
                request: 'query_name',
                response: 'response_string',
                code: 'rcode',
            },
        };

        let output = 'N/A';

        if (hash[config.type] && hash[config.type][target]) {
            const { obj } = hash[config.type];
            const prop = hash[config.type][target];

            output = config[obj] && config[obj][prop] || 'N/A';
        }

        if (target === 'code') {
            if (config.type === 'HEALTH_MONITOR_DNS') {
                output = output.enumeration('RCODE_');
            }

            if (config.type === 'HEALTH_MONITOR_HTTP') {
                if (Array.isArray(output)) {
                    output = output.map(function(code) {
                        return code.enumeration('HTTP_');
                    });

                    output = output.join(', ');
                }
            }
        }

        return output;
    }

    /* to show code text view onOpen */
    $scope.ui = {
        externalCodeAsText: 'true',
    };
}]);
