/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function wafPolicyPsmGroupMetricDataTransformerFactory(CollMetricsDataTransformer) {
    /**
     * @alias module:services/WafPolicyPsmGroupMetricDataTransformer
     * @extends CollMetricsDataTransformer
     */
    class WafPolicyPsmGroupMetricDataTransformer extends CollMetricsDataTransformer {
        /**
         * @override
         */
        processResponse(series) {
            const res = {};

            _.each(series, dataSet => {
                _.each(dataSet, unitDataSets => {
                    unitDataSets.forEach(({ header }) => {
                        const { obj_id: objId, statistics, name } = header;
                        const id = this.getIdByObjId_(objId);

                        res[id] = res[id] || {};

                        const idData = res[id];

                        if (name in idData) {
                            idData[name] += statistics.sum;
                        } else {
                            idData[name] = statistics.sum;
                        }
                    });
                });
            });

            return res;
        }

        /**
         * Returns the identifier for a location or rule from an obj_id.
         * @param {string} objId - obj_id, in the format <psm_group_uuid>:<id>
         * @returns {string}
         * @protected
         */
        getIdByObjId_(objId) {
            return objId.replace(`${this.owner_.id}:`, '');
        }
    }

    return WafPolicyPsmGroupMetricDataTransformer;
}

wafPolicyPsmGroupMetricDataTransformerFactory.$inject = [
    'CollMetricsDataTransformer',
];

/**
 * @ngdoc factory
 * @name WafPolicyPsmGroupMetricDataTransformer
 * @module services/WafPolicyPsmGroupMetricDataTransformer
 * @author alextsg
 * @description Processes response for WafPolicyPsmGroupMetric
 */
angular
    .module('waf.vantage.avi')
    .factory(
        'WafPolicyPsmGroupMetricDataTransformer',
        wafPolicyPsmGroupMetricDataTransformerFactory,
    );
