/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name HealthScoreMetric
 * @description
 *
 *     Health score series is fetched through GET API requests, therefore we don't extend
 *     CollMetric here. The only difference besides that is the fact that it doesn't support
 *     realtime timeframe, meaning step can't be smaller than five minutes.
 *
 */
angular.module('aviApp').factory('HealthScoreMetric', [
'Metric', 'Timeframe',
function(Metric, Timeframe) {
    return class HealthScoreMetric extends Metric {
        constructor(args = {}) {
            const seriesName = 'health_score';

            args.name = args.name || seriesName;
            args.series = args.series || [seriesName];

            super(args);
        }

        /** @override */
        beforeCall() {
            const reqConfigs = super.beforeCall();

            if (!reqConfigs) {
                return null;
            }

            const
                { series: config } = reqConfigs,
                { key: tfKey } = Timeframe.selected();

            // real time is not supported by health score API, still getting 30 minutes
            if (tfKey === 'rt') {
                const { params } = this;
                const step = 300;
                const limit = 6;

                params.step = step;
                config.step = step;
                params.limit = limit;
                config.limit = limit;
            }

            return reqConfigs;
        }

        /** @override */
        requestConfig() {
            return true;
        }

        /** @override */
        afterCall(resp) {
            return super.afterCall(resp && resp['series']);
        }
    };
}]);
