/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name AppMapVSCollection
 * @author Alex Malitsky
 * @description
 *
 *     Collection of kinda VirtualServices which comes through
 *     {@link MicroServiceGraphFactory.getVsImmediateNeighbors} or just analytics API call (not
 *     implemented). Used for the VS AppMap tab sidebar.
 *
 *     Upon instantiation needs a reference to {@link MicroServiceGraphFactory} instance and
 *     type of VSs we want to show - it can be either `client` or `server` relatively to
 *     MicroServiceGraphFactory root VS (traffic flow direction).
 *
 */
angular.module('aviApp').factory('AppMapVSCollection', ['Collection', 'AppMapVS',
function(Collection, AppMapVS) {
    function AppMapVSCollection(args) {
        if (!args || !args.msGraph || !args.nodeType) {
            throw new Error('Can\'t create AppMapVSCollection without msGraph or nodeType.');
        }

        this.constructor.superconstructor.call(this, args);

        this.msGraph = args.msGraph;

        this.nodesType = args.nodeType;

        this.onGraphDataUpdate_ = this.onGraphDataUpdate_.bind(this);

        this.msGraph.on('dataUpdate', this.onGraphDataUpdate_);
    }

    avi.inherit(AppMapVSCollection, Collection);

    AppMapVSCollection.prototype.objectName_ = 'app-map-virtualservice';//doesn't go to API call
    AppMapVSCollection.prototype.itemClass_ = AppMapVS;
    AppMapVSCollection.prototype.isStatic_ = false;

    AppMapVSCollection.prototype.allDataSources_ = {
        list: {
            source: 'AppMapVSListCollDataSource',
            transformer: 'AppMapVSListDataTransformer',
            transport: 'IdentityDataTransport',
            fields: ['config'],
        },
        collection: {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'source_insights.avg_bandwidth',
                'source_insights.avg_complete_responses',
                'source_insights.avg_total_requests',
                'source_insights.avg_error_responses',
                'source_insights.avg_client_end2end_latency',
                'source_insights.avg_complete_conns',
                'source_insights.max_open_conns',
            ],
            dependsOn: 'config',
        },
    };

    /**
     * Since {@link MicroServiceGraphFactory} is a `config` DataSource of this Collection we
     * want to be in sync with it for significant updates of edges or vertices lists.
     * @param {number} updateStatus
     * @protected
     */
    AppMapVSCollection.prototype.onGraphDataUpdate_ = function(updateStatus) {
        if (updateStatus > 0 && updateStatus < 3) {
            this.load();
        }
    };

    /** @override */
    AppMapVSCollection.prototype.destroy = function() {
        this.msGraph.unbind('dataUpdate', this.onGraphDataUpdate_);

        this.constructor.superclass.destroy.call(this);
    };

    return AppMapVSCollection;
}]);
