/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *  *  *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * Upgrade node object, as returned by API, representing status of SE, SEGroup, or Controller.
 * @typedef {Object}
 * @memberOf module:avi/upgrade
 * @name UpgradeStatusNode
 */

import { upgradeNodeTypes } from './upgrade-node-list.service';

const { NODE_CONTROLLER_CLUSTER, NODE_SE_TYPE } = upgradeNodeTypes;

function upgradeNodeListDataTransformerFactory(
    RevisedDataTransformer,
) {
    /**
     * @constructor
     * @memberOf module:avi/upgrade
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @description Data Transformer for UpgradeNodeList.
     * @author Akul Aggarwal
     */
    class UpgradeNodeListDataTransformer extends RevisedDataTransformer {
        /**
         * Returns controller node if first node is controller node.
         * @param {module:avi/upgrade.UpgradeStatusNode[]} nodeList
         * @return {module:avi/upgrade.UpgradeStatusNode|null}
         */
        static getControllerNode(nodeList) {
            const [firstNode] = nodeList;

            if (UpgradeNodeListDataTransformer.isNodeTypeController(firstNode)) {
                return firstNode;
            } else {
                return null;
            }
        }

        /**
         * Returns number of seGroups in progress.
         * @param {module:avi/upgrade.UpgradeStatusNode[]} nodeList
         * @return {number}
         */
        static getSeGroupCount(nodeList) {
            const { length: nodeListLength } = nodeList;

            if (nodeListLength === 0) {
                return 0;
            }

            const [firstNode] = nodeList;

            if (UpgradeNodeListDataTransformer.isNodeTypeController(firstNode)) {
                return nodeListLength - 1;
            } else {
                return nodeListLength;
            }
        }

        /**
         * Returns formatted/transformed data needed for components.
         * @param {module:avi/upgrade.UpgradeStatusNode[]} nodeList
         * @protected
         * @return {Object<number, module:avi/upgrade.UpgradeStatusNode>}
         */
        static getTransformedUpgradeNodeListData_(nodeList) {
            const {
                getControllerNode,
                getSeGroupCount,
            } = UpgradeNodeListDataTransformer;

            return {
                seGroupCount: getSeGroupCount(nodeList),
                controllerNode: getControllerNode(nodeList),
            };
        }

        /**
         * Indicates if node is Controller type node.
         * @param {module:avi/upgrade.UpgradeStatusNode} node
         * @return {boolean}
         */
        static isNodeTypeController(node) {
            return node && node.node_type === NODE_CONTROLLER_CLUSTER || false;
        }

        /**
         * Filters our SE node types.
         * @param {module:avi/upgrade.UpgradeStatusNode[]} nodeList
         * @protected
         * @returns {module:avi/upgrade.UpgradeStatusNode[]} - without SE types
         */
        //TODO: delete following once API supports multi-node filtering
        static filterOutSeNodes_(nodeList) {
            return _.reject(nodeList, ({ node_type: nodeType }) => nodeType === NODE_SE_TYPE);
        }

        /**
         * @param {Object} resp - API data returned from upgradestatusinfo
         * @return {Object}
         * @protected
         * @override
         */
        processResponse(resp) {
            const { results } = resp.data;

            //TODO: delete following once API supports multi-node filtering
            const filteredResults = UpgradeNodeListDataTransformer.filterOutSeNodes_(results);

            resp.data.results = UpgradeNodeListDataTransformer
                .getTransformedUpgradeNodeListData_(filteredResults);

            return resp;
        }
    }

    return UpgradeNodeListDataTransformer;
}

upgradeNodeListDataTransformerFactory.$inject = [
    'RevisedDataTransformer',
];

angular.module('avi/upgrade').factory(
    'UpgradeNodeListDataTransformer',
    upgradeNodeListDataTransformerFactory,
);
