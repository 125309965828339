/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name wafRuleGroup
 * @description
 *     Component displaying information about a Waf Policy group.
 * @param {WafRuleGroupConfig} config - WafRuleGroupConfig config item.
 */
class WafRuleGroupController {
    $onInit() {
        this.expanded = false;
        this.editing = false;

        if (this.wafGroup.isNewGroup()) {
            this.expanded = true;
            this.editing = true;
            this.wafGroup.unsetNewGroup();
        }
    }

    /**
     * Adds a rule to the group.
     * @param {WafRuleConfig=} rule
     */
    handleAddRule(rule) {
        this.editing = true;
        this.wafGroup.addRule(rule);
    }

    /**
     * Called to remove a group.
     * @param {jQuery} $event
     */
    handleRemoveGroup($event) {
        $event.stopPropagation();
        this.onRemoveGroup();
    }

    /**
     * Handler for clicking the expander button.
     */
    handleExpand() {
        if (this.expanded) {
            this.editing = false;
        }

        this.expanded = !this.expanded;
    }

    /**
     * Handler for clicking the settings button.
     */
    toggleEditing() {
        this.editing = !this.editing;
    }

    /**
     * Returns a state for the group wwitch.
     * @return {number}
     */
    getEnableSwitchState() {
        const wafGroupEnabled = this.wafGroup.isEnabled();

        if (!this.wafGroup.hasRules()) {
            return wafGroupEnabled ? 1 : 0;
        }

        if (wafGroupEnabled && this.wafGroup.hasAllRulesEnabled()) {
            return 1;
        }

        if (wafGroupEnabled && this.wafGroup.hasAnyRuleEnabled()) {
            return 2;
        }

        return 0;
    }

    /**
     * Handler for clicking the group switch. Sets the enable flag of the group and its rules.
     */
    handleEnableSwitchClick() {
        const wafGroupEnabled = this.wafGroup.isEnabled();

        this.wafGroup.setEnabledState(!wafGroupEnabled);
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param {number} newIndex - Index of the new position.
     * @param {Object} rule - Object that was moved. dndList directive passes in the object data
     *     and not the instance.
     */
    handleDragAndDrop(newIndex, rule) {
        const ruleIndex = rule.data.config.index;
        const oldIndex = this.wafGroup.getArrayIndexFromRuleIndex(ruleIndex);

        if (oldIndex < newIndex) {
            newIndex--;
        }

        this.wafGroup.moveRule(oldIndex, newIndex);

        return true;
    }

    /**
     * Returns a string to be used as the title for the filter icon.
     * @return {string}
     */
    getFilterIconTitle() {
        const outputs = [];

        if (this.wafGroup.hasExcludeListEntries()) {
            outputs.push('exceptions');
        }

        if (this.wafGroup.hasRulesWithExcludeListEntries()) {
            outputs.push('rules with exceptions');
        }

        return `This group has ${outputs.join(' and ')} configured.`;
    }
}

angular.module('aviApp').component('wafRuleGroup', {
    controller: WafRuleGroupController,
    bindings: {
        wafGroup: '<config',
        onRemoveGroup: '&',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule-group/waf-rule-group.html',
});
