/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/pages/application/summary.less';

/**
 * @ngdoc controller
 * @name AppDashboardController
 * @description
 *
 *     Application dashboard controller provides three types of layout: list, tree and application
 *     map.
 *
 */
angular.module('aviApp').controller('AppDashboardController', [
'$compile', '$scope', '$state', 'myAccount', '$q', 'InventoryMapCollection',
'VirtualServiceCollection', 'MicroServiceGraphFactory', 'Base', 'msAppMapIsAvail',
'GSLBServiceCollection', 'systemInfoService', 'Cloud', 'Auth', 'popoverFactory',
function($compile_, $scope, $state, myAccount, $q, InventoryMapCollection, VirtualServiceCollection,
MicroServiceGraphFactory, Base, msAppMapIsAvail, GSLBServiceCollection, systemInfo, Cloud, Auth,
PopoverFactory_) {
    const base = new Base();

    /**
     * VS Create options menu template.
     * @type {string}
     */
    const vsCreateOptionsTemplate_ = require(
        '../../../../src/views/application/partials/dashboard-vs-create-options.partial.html',
    );

    /**
     * VS Create Menu Popover Instance
     * @type {popoverFactory|null}
     */
    let vsCreateMenuPopoverInstance_ = null;

    $scope.dashLegendCollapsed = true;

    $scope.myAccount = myAccount;

    msAppMapIsAvail
        .then(isAvail => {
            $scope.msAppMapIsAvail = isAvail;

            if (!isAvail && myAccount.uiProperty.appDashboard.viewType === 'map') {
                myAccount.uiProperty.appDashboard.viewType = 'list';
                myAccount.saveUIProperty();
            }
        });

    $scope.vsCollection = new VirtualServiceCollection({
        isStatic: true,
        bind: {
            collItemCreate(item) {
                $state.go('^.virtualservice-detail.analytics', { vsId: item.id });
            },
        },
    });

    /**
     * Expands or collapses all items in tree view.
     * @param {boolean} expand - True to expand, false to collapse all items.
     */
    $scope.expandTree = (expand = true) => {
        if (myAccount.uiProperty.appDashboard.viewType === 'tree') {
            $scope.collection.items.forEach(item => item._viewExpanded = expand);
        }
    };

    /**
     * Returns true if all items are expanded in tree view.
     * @returns {boolean}
     */
    $scope.isTreeExpanded = () => {
        if (myAccount.uiProperty.appDashboard.viewType === 'tree') {
            return _.all($scope.collection.items, item => item._viewExpanded === true);
        }
    };

    function watchViewType() {
        $scope.$watch(function() {
            return myAccount.uiProperty.appDashboard.viewType;
        }, function(view, oldView) {
            const options = {
                params: {
                    page_size: 10,
                },
                sortBy: $scope.sortBy.current,
            };

            if (oldView !== view) { //initialization of watcher
                myAccount.saveUIProperty();
            }

            $scope.appMapGraph.stop(true);
            $scope.collection && $scope.collection.destroy();

            switch (view) {
                case 'gslbservice':
                    $scope.collection = new GSLBServiceCollection({
                        objectName: 'gslbservice-inventory',
                        dataFields: ['config', 'runtime'],
                    });

                    $scope.collection.load();
                    break;

                case 'tree':
                    options.sortBy = 'name';
                    $scope.collection = new InventoryMapCollection(options);
                    $scope.collection.load();
                    break;

                case 'map':
                    if (!('appMap' in myAccount.uiProperty.appDashboard)) {
                        myAccount.uiProperty.appDashboard.appMap = {};
                    }

                    options.sortBy = 'name';
                    options.params['cloud_type'] = Cloud.containerCloudTypes.join();
                    options.params.include = 'config';
                    options.isStatic = true;

                    $scope.collection = new VirtualServiceCollection(options);//for the dropdown
                    $scope.runAppMap(true);
                    break;

                default:
                    $scope.collection = new VirtualServiceCollection(options);
                    $scope.collection.subscribe(['health', 'alert', 'runtime', 'faults']);
                    break;
            }

            if (view !== 'map') {
                if ($scope.filters.search) {
                    $scope.collection.search($scope.filters.search);
                }
            }
        });
    }

    watchViewType();

    $scope.filters = { search: '' };
    $scope.sortBy = { current: 'name' };

    $scope.sort = function() {
        $scope.collection.sort($scope.sortBy.current);
    };

    function search() {
        $scope.collection.search($scope.filters.search);
    }

    $scope.search = _.debounce(search, 250);//evoked by input

    $scope.appMapGraph = new MicroServiceGraphFactory({
        params: {
            metric_id: 'source_insights.avg_bandwidth',
            include_inactive_security_nodes: false,
            dimension_aggregation: 'avg',
        },
    });

    /**
     * Starts the updates of {@link MicroServiceGraphFactory} instance. Called initially or
     * after selection of VS by the dropdown. Used only for application dashboard appMap tab.
     * @param {boolean=} isInitial - True when called on initial page load or after tab
     *     switching.
     * @returns {ng.$q.promise} - Will be resolved with VS uuid or rejected with the error
     * text.
     */
    $scope.runAppMap = function(isInitial) {
        const apiUrl = 'api/virtualservice?fields=name&page_size=1&';
        const vsId = myAccount.uiProperty.appDashboard.appMap.rootUuid &&
                myAccount.uiProperty.appDashboard.appMap.rootUuid.slug();

        let promise;

        $scope.appMapGraph.stop(true);
        base.cancelRequests();

        promise = vsId;

        if (vsId) {
            if (!isInitial) {
                $scope.appMapGraph.start(vsId);
            } else { //need to check if it still exists
                promise = base.request('get', `${apiUrl}uuid=${vsId}`, null, null, 'check')
                    .then(function(rsp) { //if it is there
                        let res;

                        if (rsp.data.count) {
                            $scope.appMapGraph.start(vsId);
                            res = vsId;
                        } else {
                            res = $q.reject(`VS "${vsId}" saved in a user settings` +
                                ' doesn\'t exist.');
                        }

                        return res;
                    })
                    .catch(function(error) { //pick any first one
                        return base.request('get', `${apiUrl}include_name`, null, null, 'first')
                            .then(function(rsp) {
                                let res,
                                    vs;

                                if (rsp.data.count) {
                                    [vs] = rsp.data.results;
                                    myAccount.uiProperty.appDashboard.appMap.rootUuid =
                                        vs.url;

                                    $scope.appMapGraph.start(vs.uuid);
                                    res = vs.uuid;
                                } else {
                                    res = $q.reject(`${error} No VSs are available in a` +
                                        ' system.');
                                }

                                return res;
                            });
                    })
                    .catch(function(error) {
                        myAccount.uiProperty.appDashboard.appMap.rootUuid = undefined;

                        return $q.reject(error);
                    });
            }
        }

        return $q.when(promise)
            .finally(function() {
                myAccount.saveUIProperty();
            });
    };

    /**
     * Returns true if the "View" dropdown on the dashboard should show the GSLB Service option.
     * @return {boolean}
     */
    $scope.showGSLBDashboardOption = function() {
        return Auth.isAllowed('PERMISSION_GSLBSERVICE', 'rw') && systemInfo.haveGSLBConfig();
    };

    /**
     * Returns VS Create menu popover instance
     * @param {string} menuTemplate
     * @return {popoverFactory}
     * @inner
     */
    function createPopoverInstance(menuTemplate) {
        const $popoverContent = $(menuTemplate);

        const popoverConfig = {
            className: 'dashboard-vs-create-popover',
            position: 'bottom',
            removeAfterHide: true,
            hide: {
                onEscape: true,
                outClick: true,
                innerClick: true,
            },
            html: $compile_($popoverContent)($scope),
        };

        return new PopoverFactory_(popoverConfig);
    }

    /**
     * Displays VS Create Options (Basic/Advanced Setup) Menu.
     * @param {angular.$event} event click event
     */
    $scope.showVsCreateOptions = event => {
        if (!vsCreateMenuPopoverInstance_) {
            vsCreateMenuPopoverInstance_ = createPopoverInstance(vsCreateOptionsTemplate_);
        }

        vsCreateMenuPopoverInstance_.show(event.target);
    };

    $scope.$on('$destroy', function() {
        base.cancelRequests();
        $scope.collection && $scope.collection.destroy();
        $scope.vsCollection && $scope.vsCollection.destroy();
        $scope.appMapGraph.destroy();

        if (vsCreateMenuPopoverInstance_) {
            vsCreateMenuPopoverInstance_.remove();
        }
    });
}]);
