/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateSigningRequest
 * @param {string} signingRequest - Text used as a certificate signing request.
 * @param {function} onChange - Updates parent with certificate information.
 * @description  Allows user to upload/copy-paste a certificate used in the CSR workflow.
 */
function Controller(Schema, clipboardService) {
    this.Schema = Schema;

    this.$onInit = function() {
        this.base64 = true;
    };

    this.copyToClipboard = function(message, target) {
        clipboardService.copy(message, target);
    };

    /**
     * Highlights the textarea text on focus.
     * @param  {Event} $event - jQuery event.
     */
    this.onFocus = function($event) {
        $event.target.select();
    };

    /**
     * Clears the certificate. Called when user changes radio button from upload to copy-paste.
     */
    this.clearCertificate = function() {
        this.certificate = undefined;
        this.handleCertificateChange();
    };

    /**
     * Calls onChange to update certificate data in a parent component.
     */
    this.handleCertificateChange = function() {
        this.onChange({
            base64: this.base64,
            certificate: this.certificate,
        });
    };
}

Controller.$inject = [
    'Schema',
    'clipboardService',
];

angular.module('aviApp').component('sslCertificateSigningRequest', {
    bindings: {
        signingRequest: '@',
        onChange: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-signing-request/ssl-certificate-signing-request.html',
});
