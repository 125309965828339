/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO destroyAll should reject modalPromises

/**
 * @typedef modal
 * @type {Object}
 * @property {string} id - Modal's Id and the filename of template at the same time.
 * Without extensions or folder path.
 * @property {ng.$rootScope} scope - Scope of the Modal's parent DOM node. Actual modal's
 * scope is a child of this one.
 * @property {jQuery} elem - DOM node of the Modal.
 * @property {boolean} isReady - False when we are loading a Modal's template. True for
 * all other cases.
 */
export class AviModalService {
    constructor(
        Auth,
        $timeout,
        $q,
        $compile,
        $templateCache,
        $rootScope,
        $templateRequest,
        Regex,
        Base,
        $injector,
        ComponentTemplateStringBuilder,
        ComponentBindingsSetter,
    ) {
        this.Auth_ = Auth;
        this.Regex_ = Regex;
        this.$q_ = $q;
        this.$injector_ = $injector;
        this.ComponentBindingsSetter_ = ComponentBindingsSetter;
        this.templateStringBuilder_ = ComponentTemplateStringBuilder;
        this.$templateRequest_ = $templateRequest;
        this.$rootScope_ = $rootScope;
        this.$compile_ = $compile;
        this.$timeout_ = $timeout;
        this.$templateCache_ = $templateCache;

        /**
         * Keep track of all open modal windows.
         * @type {Object<string, modal>}
         * @private
         */
        this.activeModals_ = {};

        /**
         * An instance of a Base class to make API calls having ability to cancel them.
         * @type {Base}
         */
        this.baseItem_ = new Base();

        $rootScope.$on('userLoggedOut', this.destroyAll);
        $rootScope.$on('setContext', this.destroyAll);
    }

    /**
     * Disable page scrollbar.
     * @param {boolean} [bool=] - True by default. False to reenable scroll.
     */
    static disableBodyScroll_(bool = true) {
        if (bool) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    }

    /**
     * Puts some useful methods and properties on the modal window parent scope.
     * @param {ng.$scope} scope - Modal's parent scope.
     */
    initModalsParentScope_(scope) {
        const common = {
            Auth: this.Auth_,
            Regex: this.Regex_,
            forms: {},
        };

        Object.assign(scope, common);
    }

    /**
     * Opens the modal window after getting its content through {@link ng.$templateCache}
     * http call. Calls {@link AviModal.getComponent_} or {@link AviModal.getTemplate_}
     * internally.
     * @param {string} modalId
     * @param {Object.<string, *>=} data - Modal's parent scope will be extended by this
     *    objects properties.
     * @param {string=} className - string of classes to be used with modal. Applicable only for
     *     components.
     * @return {ng.$q.promise}
     * @public
     */
    open(modalId, data, className) {
        const camelCase = $.camelCase(modalId);

        if (!this.Auth_.isLoggedIn()) {
            return this.$q_.reject();
        } else if (this.isOpen(modalId)) {
            const error = `Modal window "${modalId}" is already opened or being opened`;

            console.warn(error);

            return this.$q_.reject(new Error(error));
        }

        let contentPromise;

        if (this.$injector_.has(`${camelCase}Directive`)) {
            contentPromise = this.getComponent_(modalId, data, className);
            data = this.ComponentBindingsSetter_(modalId, data);
        } else {
            contentPromise = this.getTemplate_(modalId);
        }

        this.activeModals_[modalId] = {
            id: modalId,
            isReady: false,
        };

        return contentPromise.then(template => this.open_(modalId, data, template));
    }

    /**
     * Returns template string for component.
     * @param  {string} componentId
     * @param  {Object=} bindings - Properties to be bound to the component's controller.
     * @param {string=} className - string of classes to be used with modal. Applicable only for
     *     components.
     * @return {ng.$q.promise}
     */
    getComponent_(componentId, bindings, className) {
        return this.$q_.when(this.templateStringBuilder_(componentId, bindings, className));
    }

    /**
     * Returns HTML modal template.
     * @param  {string} modalId
     * @return {ng.$q.promise}
     */
    getTemplate_(modalId) {
        const fullTplName = `src/views/modals/${modalId}.html`;
        const contentPromise = this.$templateRequest_(fullTplName);

        return this.$q_.when(contentPromise)
            .catch(err => {
                delete this.activeModals_[modalId];

                console.error(
                    'Can\'t find or load template file for modal window "%s". Response: %O',
                    modalId,
                    err,
                );

                return this.$q_.reject(err);
            });
    }

    /**
     * Does actual job of showing modal after getting its content.
     * @param {modal.id} modalId
     * @param {Object.<string, *>=} data - Modal's parent scope will be extended by this
     *    objects properties. Usually has `editable` object as an {@link Item} instance.
     * @param {string} content - Modal's template to be compiled and shown.
     * @protected
     */
    open_(modalId, data, content) {
        const modalParentScope = this.$rootScope_.$new();
        const isComponent = this.$injector_.has(`${$.camelCase(modalId)}Directive`);

        if (isComponent) {
            angular.extend(modalParentScope, data);
        } else {
            this.initModalsParentScope_(modalParentScope);
        }

        /**
         * Function attached to the modalParentScope that closes the modal. Must be declared as
         * a binding in order to be used by the component.
         */
        modalParentScope.closeModal = () => {
            this.destroy(modalId);
        };

        const elem = this.$compile_(content)(modalParentScope);

        if (!isComponent && typeof modalParentScope.modalScope !== 'object') {
            if (elem.is('[ng-controller]')) {
                delete this.activeModals_[modalId];
                throw new Error(`Modal window's (${modalId}) controller should set` +
                    ' a "modalScope" property on the $parent\'s scope referencing the' +
                    ' modal\'s scope.');
            } else { //parent scope is the only scope, no controller
                modalParentScope.modalScope = modalParentScope;
            }
        }

        $('div.modals').append(elem);

        AviModalService.disableBodyScroll_();

        if (!isComponent) {
            if (modalParentScope.modalScope.forms) {
                _.each(modalParentScope.modalScope.forms, function(form) {
                    form.$setPristine();
                });
            }

            if (modalParentScope.modalScope.modalForm) {
                modalParentScope.modalScope.modalForm.$setPristine();
            }

            // Put extra properties into the Modal's scope
            if (typeof data === 'object') {
                angular.extend(modalParentScope.modalScope, data);
            }
        }

        // Open up modal
        elem.aviModal();

        // Keep the link to the container in the scope
        modalParentScope.container = elem;

        // Focus the first input in the window
        this.$timeout_(() => elem.find('input:first').trigger('focus'));

        if (!isComponent && typeof modalParentScope.modalScope.init === 'function') {
            modalParentScope.modalScope.init();
        }

        this.activeModals_[modalId].scope = modalParentScope;
        this.activeModals_[modalId].elem = elem;
        this.activeModals_[modalId].isReady = true;
    }

    /**
     * Closes the Modal, calling {@link ng.$rootScope.$scope.destroy} on the parent
     * scope. For compatibility reasons rewrites provided by `data` set of properties on the
     * scope object and calls it's destroy function if present. Both of these are deprecated
     * now.
     * @param {string} modalId
     */
    destroy(modalId) {
        const modal = this.activeModals_[modalId];
        const deferred = this.$q_.defer();

        if (!this.isOpen(modalId)) {
            console.warn(`Could not find modal window "${modalId}" in opened modals.`);
        } else if (modal.isReady) {
            modal.elem.aviModal('hide');

            //DEPRECATED TODO remove
            if (modal.scope.modalScope && typeof modal.scope.modalScope.destroy === 'function') {
                this.$timeout_(() => {
                    modal.scope.modalScope.destroy();
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise.then(() => {
                modal.scope.$destroy();
                modal.elem.remove();

                delete this.activeModals_[modalId];

                if (!this.hasOpen()) {
                    AviModalService.disableBodyScroll_(false);
                }
            });
        } else { //when we are loading a template right now
            this.baseItem_.cancelRequests(modalId);
            delete this.activeModals_[modalId];
            deferred.resolve();

            return deferred.promise;
        }
    }

    /**
     * Destroys all active modals.
     */
    destroyAll = () => {
        _.each(this.activeModals_, modal => {
            if (modal) {
                this.destroy(modal.id);
            }
        });

        this.baseItem_.cancelRequests();
    }

    /**
     * Checks if specified window is open.
     * @param {modal.id} modalId
     * @returns {boolean} True if specified window id is opened.
     * @public
     */
    isOpen(modalId) {
        return !!modalId && !_.isUndefined(this.activeModals_[modalId]);
    }

    /**
     * Returns true if any modal is open.
     * @returns {boolean}
     */
    hasOpen() {
        return !_.isEmpty(this.activeModals_);
    }
}

AviModalService.$inject = [
    'Auth',
    '$timeout',
    '$q',
    '$compile',
    '$templateCache',
    '$rootScope',
    '$templateRequest',
    'Regex',
    'Base',
    '$injector',
    'ComponentTemplateStringBuilder',
    'ComponentBindingsSetter',
];

/**
 * @ngdoc service
 * @name AviModal
 * @description Operations with modal windows. With ability to show and hide them while
 * supporting stacking.
 *
 * AviModal supports opening components as modals.
 * @example
 *     <caption>
 *         Controller opening modal. Functions containing parameters must be defined as an array,
 *         similarly to Angular's dependency injection.
 *     </caption>
 *     <code>
 *         AviModal.open('component-name', {
 *             uuid: row.data.config.uuid,
 *             config: config,
 *             submit: ['config', function(config) {}],
 *             validate: function() {}
 *         });
 *     </code>
 *     <caption>Component definition</caption>
 *     <code>
 *         aviApp.component('componentName', {
 *             bindings: {
 *                 uuid: '@',
 *                 config: '<',
 *                 submit: '&',
 *                 validate: '&',
 *                 closeModal: '&'
 *             },
 *             controller: Controller,
 *             templateUrl: 'src/views/components/modals/component-name.html'
 *         });
 *     </code>
 */
angular.module('aviApp').service('AviModal', AviModalService);
