/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const componentName = 'auth-profile-ping-access-settings';

class authProfilePingAccessSettingsController {
    constructor(
        PingAccessAgentProfileCollection,
    ) {
        this.PingAccessAgentProfileCollection_ = PingAccessAgentProfileCollection;
    }

    /** @override */
    $onInit() {
        this.pingAccessProfileCollection = new this.PingAccessAgentProfileCollection_();
    }

    /** @override */
    $onDestroy() {
        this.pingAccessProfileCollection.destroy();
    }
}

authProfilePingAccessSettingsController.$inject = [
    'PingAccessAgentProfileCollection',
];

/**
 * @ngdoc component
 * @name authProfilePingAccessSettings
 * @author Chitra Flarid
 * @param {string} paAgentRef
 * @desc
 *     Configuration form for PingAccessAgent Settings object (pa_agent_uuid).
 */
angular.module('aviApp').component('authProfilePingAccessSettings', {
    bindings: {
        paAgentRef: '=',
    },
    controller: authProfilePingAccessSettingsController,
    templateUrl: `src/components/${componentName}/${componentName}.html`,
});
