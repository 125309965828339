/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name passwordGenerator
 * @author Alex Malitsky & stack overflow
 * @description
 *
 *     Secure way to generate random passwords confirming with backend password requirements.
 *     Only generate is an exposed method.
 *
 */
angular.module('aviApp').factory('passwordGenerator', ['$window', function($window) {
    //required groups
    const patterns = ['a-z', 'A-Z', '\\d', '_\\-\\+=%$@'];

    return {
        _patterns: patterns.map(function(expression) {
            return new RegExp(`[${expression}]`);
        }),
        _generalPattern: new RegExp(`[${patterns.join('')}]`),
        _getRandomByte() {
            let result;

            if ($window.crypto && $window.crypto.getRandomValues) {
                result = new Uint8Array(1);
                $window.crypto.getRandomValues(result);
                [result] = result;
            } else if ($window.msCrypto && $window.msCrypto.getRandomValues) {
                result = new Uint8Array(1);
                $window.msCrypto.getRandomValues(result);
                [result] = result;
            } else {
                result = 33 + Math.floor(Math.random() * (256 - 33));
            }

            return result;
        },
        generate_(length, patternToMatch) {
            let i,
                symbol = '',
                str = '';

            for (i = 0; i < length; i++, symbol = '') {
                while (!symbol || !patternToMatch.test(symbol)) {
                    symbol = String.fromCharCode(this._getRandomByte());
                }

                str += symbol;
            }

            return str;
        },
        generate(length) {
            length = Math.max(patterns.length, length);

            let str = this.generate_(length - patterns.length + 1, this._generalPattern);

            this._patterns.forEach(function(pattern) {
                if (!pattern.test(str)) {
                    str += this.generate_(1, pattern);
                }
            }, this);

            if (str.length < length) {
                str += this.generate_(length - str.length, this._generalPattern);
            }

            return str;
        },
    };
}]);
