/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name serviceEngineList
 * @description Component for Service Engine page.
 */
class ServiceEngineListController {
    constructor(
            CRUDGridConfig,
            $templateCache,
            ServiceEngineCollection,
            infraCloudState,
            getGridMetricFieldConfig,
            $window,
    ) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._$templateCache = $templateCache;
        this._ServiceEngineCollection = ServiceEngineCollection;
        this._infraCloudState = infraCloudState;
        this._getGridMetricFieldConfig = getGridMetricFieldConfig;
        this.$window_ = $window;

        const rebootAction = {
            title: 'Reboot',
            className: 'icon-refresh',
            do(rows) {
                const cfm = $window.confirm('Reboot selected Service Engine(s)?');

                if (cfm) {
                    rows.forEach(se => se.reboot());
                }

                return cfm;
            },
        };

        const multipleActions = [
                {
                    title: 'Enable',
                    className: 'sel-enable-se icon-check',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() && !se.isEnabled();
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() && !se.isEnabled()) {
                                se.setEnableState(0);
                            }
                        });

                        return true;
                    },
                }, {
                    title: 'Disable',
                    className: 'sel-disable-se icon-minus',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() &&
                                (se.isEnabled() || se.isDisabledForPlacement());
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() &&
                                (se.isEnabled() || se.isDisabledForPlacement())
                            ) {
                                se.setEnableState(2);
                            }
                        });

                        return true;
                    },
                }, {
                    title: 'Disable for VS Placement',
                    className: 'sel-disable-se icon-minus',
                    disabled(rows) {
                        return !_.some(rows, se => {
                            return se.isEditable() &&
                                (se.isEnabled() || !se.isDisabledForPlacement());
                        });
                    },
                    do(rows) {
                        _.each(rows, se => {
                            if (se.isEditable() &&
                                (se.isEnabled() || !se.isDisabledForPlacement())
                            ) {
                                se.setEnableState(1);
                            }
                        });

                        return true;
                    },
                },
                rebootAction,
        ];

        this.multipleActions_ = {
            openShiftK8S: rebootAction,
            default: multipleActions,
        };
    }

    $onInit() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.gridConfig = new this._CRUDGridConfig();

        this.setMultipleActions_();

        this.gridConfig.collection = new this._ServiceEngineCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
        });

        const gridMetricFields = [
                'se_stats.max_num_vs',
                'se_if.avg_bandwidth',
                'se_stats.avg_cpu_usage',
                'se_stats.avg_mem_usage',
                'se_stats.avg_disk1_usage',
                'se_stats.avg_connections',
                'se_stats.pct_connections_dropped',
                'se_stats.avg_packet_buffer_usage',
                'se_stats.avg_persistent_table_usage',
                'se_stats.avg_ssl_session_cache_usage',
                'se_stats.pct_syn_cache_usage',
                'se_if.avg_rx_pkts',
                'se_if.avg_rx_bytes',
                'se_stats.avg_rx_pkts_dropped',
                'se_stats.avg_rx_bytes_dropped',
                'se_if.avg_tx_bytes',
                'se_if.avg_tx_pkts',
                'se_stats.avg_connection_mem_usage',
                'se_stats.avg_dynamic_mem_usage',
        ];

        this.gridConfig.fields = [{
            name: 'name',
            title: 'Name',
            template: '<a class="sel-se-name" ui-sref="^.^.cloud.serviceengine-detail.' +
                    'summary({seId: \'{{row.id}}\', cloudId: \'' +
                    '{{row.data.config.cloud_ref.slug()}}\'})">' +
                    '{{row.getName()}}</a>',
            sortBy: 'name',
            visibility: 'm',
        }, {
            require: 'health, runtime',
            name: 'health',
            title: 'Health',
            template:
                    '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
            sortBy: 'health_score',
            visibility: 'm',
        }, {
            name: 'enable_state',
            title: 'Enable State',
            template: '{{row.getConfig().enable_state.enumeration("SE_STATE_")}}',
            sortBy: 'enable_state',
        }, {
            require: 'runtime',
            name: 'status',
            title: 'Status',
            template: '{{row.getRuntimeData().oper_status.state | unitStateLabel}}',
            visibility: 'd',
        }, {
            require: 'runtime',
            name: 'uptime',
            title: 'Uptime',
            template: '{{row.getRuntimeData().oper_status.last_changed_time.secs | uptime}}',
        }, {
            name: 'segroup',
            title: 'SE Group',
            template: '{{ row.getConfig().se_group_ref | name }}',
            sortBy: 'se_group_ref__name',
        }, {
            name: 'mgmt-ip',
            title: 'Management IP',
            template: '{{ row.getConfig().mgmt_ip_address.addr }}',
        }, {
            name: 'num_vs',
            title: '# Virtual Services',
            template: '{{ row.getConfig().vs_per_se_refs.length }}',
        }, {
            name: 'availability_zone',
            title: 'Availability Zone',
            template: '{{ row.getConfig().availability_zone || "N/A" }}',
        }, {
            name: 'host_ref',
            title: 'Host',
            template: '{{ row.getConfig().host_ref.name() || "N/A" }}',
        }, {
            require: 'alert',
            name: 'alerts',
            title: 'Alerts',
            template: '<item-alert-bell item="row"></item-alert-bell>',
        }, {
            name: 'version',
            title: 'Version',
            template: '{{ row.getRuntimeData().version }}',
        }];

        const { fields } = this.gridConfig;

        //let's add metric series fields
        fields.push(...gridMetricFields.map(this._getGridMetricFieldConfig));

        this.gridConfig.singleactions.push({
            title: 'delete',
            class: 'icon-trash',
            hidden(row) {
                return !row.isDroppable();
            },
            do: row => this.deleteServiceEngine(row),
        });

        //disable when remove & enable/disable SE are both unavailable
        this.gridConfig.checkboxDisable = row => !(row.isDroppable() || row.isEditable());

        this.gridConfig.expandedContainerTemplate = require(
            './service-engine-list-expander.partial.html',
        );

        this.gridConfig.expanderDisabled =
                row => !row.getConfig() || !('virtualservice_refs' in row.getConfig());

        this.gridConfig.layout = {
            includeTimeframeSelector: true,
            includeMetricsValueSelector: true,
        };

        this.handleCloudChange = this.handleCloudChange.bind(this);
        this._infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
        this._infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }

    /**
     * Triggers delete call for a SE. incase of failed deletion,
     * Gives an option to try force delete.
     * @param {module:avi/serviceengine.ServiceEngine} serviceEngine
     */
    deleteServiceEngine(serviceEngine) {
        const { collection } = this.gridConfig;

        collection.dropItems(serviceEngine, false, false, null, false)
            .catch(({ data }) => {
                const { error } = data;

                if (error) {
                    if (this.$window_.confirm(`${error}. Try force delete?`)) {
                        collection.dropItems(serviceEngine, false, true);
                    }
                }
            });
    }

    /**
     * Handler for the 'cloudChange' event. Sets the Service Engine collection based on the
     * cloud.
     */
    handleCloudChange() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();
        const { collection } = this.gridConfig;

        this.setMultipleActions_();

        collection.setParams({
            'cloud_ref.uuid': slug,
        });

        collection.setDefaultItemConfigProps({
            cloud_ref: slug,
        });

        collection.load();
    }

    /**
     * Sets the multiple action options based on the cloud type selected.
     * For cloud_type "CLOUD_OSHIFT_K8S", display only Delete, Reboot options
     * For other clouds display Delete, Disable, Enable, Disable vs placement and
     * Reboot action buttons.
     * @protected
     */
    setMultipleActions_() {
        const cloudType = this._infraCloudState.getCloud().getVtype();
        const { multipleactions } = this._CRUDGridConfig();

        if (cloudType === 'CLOUD_OSHIFT_K8S') {
            multipleactions.push(this.multipleActions_.openShiftK8S);
        } else {
            multipleactions.push(...this.multipleActions_.default);
        }

        this.gridConfig.multipleactions = multipleactions;
    }
}

ServiceEngineListController.$inject = [
    'CRUDGridConfig',
    '$templateCache',
    'ServiceEngineCollection',
    'infraCloudState',
    'getGridMetricFieldConfig',
    '$window',
];

angular.module('aviApp').component('serviceEngineList', {
    controller: ServiceEngineListController,
    template:
        `<div class="infrastructure-detail">
            <div
                help-popover-anchor="seinformation"
                help-popover-position="lm">
            </div>
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-service-engine-list service-engine-list"
                help-popover-anchor="setable"
                help-popover-position="lt">
            </collection-grid>
            <div
                class="help-popover-storage"
                ng-include="'src/views/components/help/1.2/selist-quick-help.html'">
            </div>
        </div>`,
});
