/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GroupCollMetric
 * @author Alex Malitsky
 * @description
 *
 *     Used by Collection Metric updates processed by {@link Collection}. Differs from {@link
 *     CollMetric} by subrequestIds and fact that Collection uses
 *     {@link CollMetricsDataTransformer} for request and response transformations.
 *
 */
angular.module('aviApp').factory('GroupCollMetric', ['CollMetric',
function(CollMetric) {
    /**
     * @param {Object} args
     * @constructor
     * @extends CollMetric
     */
    return class GroupCollMetric extends CollMetric {
        /** @override */
        getSubrequestId_() {
            const { item, name } = this;

            return `${item.getGroupCollMetricsRequestId()}:${name}`;
        }
    };
}]);
