/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  analyticsHttpRespCodes
 * @description
 *     Responsible for passing 3 types of HTTP response codes to the onUpdateCodes function. Parses
 *     input string and formats code data from the backend.
 * @param {AnalyticsProfile.config} config
 * @param {function} onUpdateCodes - Called when user updates input field.
 */
class Controller {
    constructor(Schema, Regex) {
        this.Schema_ = Schema;
        this.Regex_ = Regex;

        this.codeBlockEnumHash_ = _.reduce(
            Schema.enums.AnalyticsProfileRespCodeBlock.values, (hash, val, key) => {
                hash[val.options.text.value] = key;

                return hash;
            }, {},
        );
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        // Need this workaround as this.config isn't available $onInit due to AviModal.open
        // initialization.
        if (changes.config && angular.isUndefined(changes.config.previousValue)) {
            this.ngModel = this.codeFormatter();
        }
    }

    /**
     * Called on ng-change of input. Parses input for an object of response codes to be passed
     * to the onUpdateCodes function.
     */
    handleInputChange() {
        const output = {
            exclude_http_error_codes: [],
            ranges: [],
            resp_code_block: [],
        };

        if (!angular.isUndefined(this.ngModel)) {
            const codes = this.ngModel.replace(/\s+/g, '').split(',');

            codes.forEach(code => {
                const result = this.codeParser(code);

                if (!angular.isUndefined(result)) {
                    output[result.type].push(result.value);
                }
            });
        }

        this.onUpdateCodes({
            respCodes: output,
        });
    }

    /**
     * Parses code, determining whether it's a single code, code range, or code block. Returns
     * an object with type and value properties.
     * @param  {string} code - HTTP Response code string.
     * @return {Object}
     */
    codeParser(code) {
        if (this.Regex_.listOfStatusCodeOrRanges.test(code)) {
            // Range
            if (code.indexOf('-') !== -1) {
                const
                    range = code.split('-'),
                    begin = +range[0],
                    end = +range[1];

                return {
                    type: 'ranges',
                    value: {
                        begin,
                        end,
                    },
                };
            // Single code
            } else {
                return {
                    type: 'exclude_http_error_codes',
                    value: +code,
                };
            }
        // Code block
        } else if (code.toUpperCase() in this.codeBlockEnumHash_) {
            return {
                type: 'resp_code_block',
                value: this.codeBlockEnumHash_[code.toUpperCase()],
            };
        }
    }

    /**
     * Formats HTTP Response code properties from the backend to return a comma-separated
     * string.
     * @return {string}
     */
    codeFormatter() {
        const output = [];

        if (angular.isArray(this.config.exclude_http_error_codes)) {
            output.push(...this.config.exclude_http_error_codes);
        }

        if (angular.isArray(this.config.ranges)) {
            this.config.ranges.forEach(({ begin, end }) => {
                output.push(`${begin}-${end}`);
            });
        }

        if (angular.isArray(this.config.resp_code_block)) {
            this.config.resp_code_block.forEach(block => {
                output.push(block.enumeration('AP_HTTP_RSP_').toUpperCase());
            });
        }

        return output.join(', ');
    }
}

Controller.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('analyticsHttpRespCodes', {
    bindings: {
        config: '<',
        onUpdateCodes: '&',
    },
    controller: Controller,
    template:
        `<input
            type="text"
            class="sel-http-status-code"
            ng-model="$ctrl.ngModel"
            ng-change="$ctrl.handleInputChange()"
            avi-form-item="$ctrl.Schema_.pb.AnalyticsProfile.fields.exclude_http_error_codes"
            placeholder="400, 401-408, 5XX"
            ng-label="Exclude HTTP Status codes from Error Classification" />`,
});
