/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
/** @alias seriesDataPointService */
function seriesDataPointService() {
    /**
     * Returns string representation of {@link Metric} {@link Series} data point.
     * @param {SeriesDataPoint|null} dataPoint
     * @return {string}
     * @public
     */
    function getDataPointHashString(dataPoint) {
        if (!dataPoint) {
            return '';
        }

        const { timestamp, value } = dataPoint;

        return `${timestamp}-${value}`;
    }

    return {
        getDataPointHashString,
    };
}

/**
 * @ngdoc service
 * @name seriesDataPointService
 * @author Alex Malitsky
 * @desc
 *
 *     Service for handling {@link Series} data point.
 *     To be replaced with SeriesDataPoint configItem class.
 */
angular.module('metrics.vantage.avi')
    .factory('seriesDataPointService', seriesDataPointService);
