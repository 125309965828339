/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './modal-container.less';

/**
 * @ngdoc component
 * @name modalContainer
 * @description
 *     Wrapper around a typical Item config modal. Contains an optional wizard navigation section
 *     for multiple pages. Removing tabs is not supported.
 * @param {string} modalTitle - String to be used as the title for the modal.
 * @param {boolean} busy - Boolean used to show a spinner when busy.
 * @param {string} submitLabel - String to be used for the Submit button.
 * @param {boolean} showCancel - Boolean used to show a Cancel button.
 * @param {Function} confirmCheck - Function to be passed to the confirm directive.
 * @param {Function} onClose - Function to be called when the close button is clicked.
 * @param {Function} onSubmit - Function to be called when the Submit button is clicked.
 * @param {Function} onCancel - Function to be called when the Cancel button is clicked.
 * @param {string[]} [breadcrumbs=] - Array of strings to be displayed as breadcrumbs.
 * @param {boolean} [disableSubmit=] - True to disable submit button.
 */
//TODO ng-enter support
class ModalContainerController {
    constructor() {
        this.modalWizardTabs = [];
        this.selectedTab = null;
    }

    /**
     * Selects a tab.
     * @param {SubtabTab} tab
     */
    selectTab(tab) {
        this.selectedTab = tab;
    }

    /**
     * Returns true if the tab is currently selected.
     * @return {boolean}
     */
    isSelected(tab) {
        return this.selectedTab === tab;
    }

    /**
     * Called by the modalWizardTab child component to add itself to the list of tabs.
     * @param {ModalWizardTabController} tab
     */
    addModalWizardTab(tab) {
        if (!this.selectedTab && !tab.hideTab) {
            this.selectTab(tab);
        }

        this.modalWizardTabs.push(tab);
    }
}

angular.module('aviApp').component('modalContainer', {
    controller: ModalContainerController,
    transclude: true,
    bindings: {
        modalTitle: '@',
        busy: '<',
        submitLabel: '<',
        showCancel: '<',
        confirmCheck: '&',
        onClose: '&',
        onSubmit: '&?',
        onCancel: '&',
        breadcrumbs: '<?',
        disableSubmit: '<?',
    },
    templateUrl: 'src/components/modals/modal-container/modal-container.html',
});
