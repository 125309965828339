/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name KeystoneIsEnabledCollDataSource
 * @description
 *
 *     Regular CollDataSource, only difference is the fact there is no need to load it when
 *     collection has no entries.
 *
 **/
angular.module('aviApp').factory('KeystoneIsEnabledCollDataSource', [
'CollDataSource', function(CollDataSource) {
    return class KeystoneIsEnabledCollDataSource extends CollDataSource {
        /** @override */
        isInactive() {
            return !this.owner_.getNumberOfItems();
        }
    };
}]);
