/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function fileServiceCollectionFactory(Collection, FileService) {
    /**
     * @alias module:services/FileServiceCollection
     * @extends Collection
     * @private
     */
    class FileServiceCollection extends Collection {}

    /** @override */
    FileServiceCollection.prototype.objectName_ = 'fileservice';

    /** @override */
    FileServiceCollection.prototype.itemClass_ = FileService;

    return FileServiceCollection;
}

fileServiceCollectionFactory.$inject = [
    'Collection',
    'FileService',
];

/**
 * @ngdoc services
 * @name FileServiceCollection
 * @module services/FileServiceCollection
 * @author Akul Aggarwal
 * @description
 *
 *     Collection for File Service items. This is an abstract class, to be extended
 *     as needed wherever /fileservice/ api is needed.
 *
 */
angular.module('aviApp').factory('FileServiceCollection', fileServiceCollectionFactory);
