/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/** @alias angularJsAppRun */
function angularJsAppRun(
    $rootScope,
    $location,
    $state,
    $stateParams,
    $window,
    $transitions,
    Schema,
    Timeframe,
    User,
    appStateHandler,
    myAccount,
    activateAviModalJQueryPlugin,
    FaultService,
    logErrorToController,
    schemaService,
) {
    activateAviModalJQueryPlugin(jQuery);
    schemaService.init();

    $window.onerror = logErrorToController;

    FaultService.setListeners();

    /**
     * @type {boolean} True when app works through the iFrame inside another app.
     */
    $rootScope.embeddedMode = !!$location.search().session_id;

    //Hiding entire window with overlay to avoid seeing broken layout
    $('<div class="sel-overlay avi-welcome" style="z-index:999999"/>').appendTo('body');

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.Schema = Schema;

    // So that we repaint when we change the timeframe
    // (at some point switch to just repainting the axes)
    // TODO replace with events
    $rootScope.$watch(
        () => myAccount.uiProperty.useUTCTime,
        () => $rootScope.$broadcast('repaint'),
    );

    $transitions.onStart({}, appStateHandler.handleTransitionStart);
    $transitions.onSuccess({}, appStateHandler.handleTransitionSuccess);
    $transitions.onError({}, appStateHandler.handleTransitionError);

    // FIXME remove
    _.each([
        'display-currentPoint',
        'display-averagePoint',
        'display-totalPoint',
        'display-peakPoint'], function(eventName) {
        const pointName = eventName.split('-')[1];

        $rootScope.$on(eventName, function() {
            $rootScope.defaultPoint = pointName;
        });
    });

    // add single window listener to
    $($window).on('resize',
        _.throttle(() => {
            $rootScope.$broadcast('$repaintViewport');
            $rootScope.$broadcast('repaint');
        }, 100));
}

angularJsAppRun.$inject = [
    '$rootScope',
    '$location',
    '$state',
    '$stateParams',
    '$window',
    '$transitions',
    'Schema',
    'Timeframe',
    'User',
    'appStateHandler',
    'myAccount',
    'activateAviModalJQueryPlugin',
    'FaultService',
    'logErrorToController',
    'schemaService',
];

/**
 * @ngdoc service
 * @name angularJsAppRun
 * @author Alex Malitsky
 */
angular.module('aviApp').run(angularJsAppRun);
