/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../less/components/client-insight-end-to-end.less';

/**
 * Client Insights Controller Notes:
 * TopListCollection -- a collection of TopListItems, a data structure with resource timings,
 * relative use, base data, etc.
 * The collection has methods to get the top x items, and other helpers
 * data.endToEnd is a TimingItem -- has a base, and a filtered, both of which have
 * endToEnds and drilldowns.
 */
angular.module('aviApp').controller('ClientInsightsController', [
'$scope', '$http', '$stateParams', '$q', '$timeout', 'TopListCollection', 'TimingItem', 'Timeframe',
function($scope, $http, $stateParams, $q, $timeout, TopListCollection, TimingItem, Timeframe) {
    // -------------------- Member Variables -------------------//
    $scope.data = {};
    $scope.ui = {
        busy: false,
        ready: false,
    };

    // Filters has top list items in it
    $scope.data.filters = [];
    $scope.data.topList = {};

    $scope.data.timing = {};

    $scope.data.endToEnd = new TimingItem(null);

    // ------------------- Useful Constants --------------------//
    // The dimensions we're querying for (devtype means device type)
    const dimensions = ['ipgroup', 'browser', 'os', 'devtype', 'url'];

    // Setting up urls
    const curTimeframe = Timeframe.selected();
    let stepAndLimit = `&step=${curTimeframe.step}&limit=${curTimeframe.limit}`;
    const vsUrl = `/api/analytics/metrics/virtualservice/${$stateParams.vsId}`;
    const baseUrl = `${vsUrl}?metric_id=collection.rum_visits&dimension_aggregation=percent&` +
        'dimension_limit=5&pad_missing_data=false&dimensions=';
    const endToEndUrl = `${vsUrl}?metric_id=collection.rum_navigation_timing&` +
        'dimension_aggregation=avg&pad_missing_data=false';

    $scope.onTimeframeChange = function() {
        const curTimeframe = Timeframe.selected();

        stepAndLimit = `&step=${curTimeframe.step}&limit=${curTimeframe.limit}`;
        start();
    };

    // ----------------------- Listeners ---------------------------------//

    $scope.$on('filter-by-item', updateFilters);
    $scope.$on('show-resource-timing', showResourceTiming);
    $scope.$on('$destroy', function() {
        $scope.VirtualService.async.stop(true);
        Timeframe.unbind('change', $scope.onTimeframeChange);
    });

    $scope.setInitialState = function() {
        Timeframe.on('change', $scope.onTimeframeChange);
        start();
        $scope.VirtualService.addLoad(['health', 'alert', 'faults']);
    };

    $scope.showBaseData = function() {
        _.each($scope.data.topList, function(list) {
            list.clearAll();
            list.showBaseData();
            list.display.oneActive = false;
        });
        $scope.data.endToEnd.clearFiltered();

        // Lets globe know that filters are clearing
        $scope.$broadcast('clearfilters');
    };

    function showResourceTiming(e, item) {
        $scope.data.currentItem = item;
        $scope.showResourceTiming = true;
    }

    $scope.clearFilters = function() {
        while ($scope.data.filters.length) {
            $scope.data.filters.pop();
        }

        $scope.showBaseData();
    };

    function start() {
        $scope.active = $scope.VirtualService.data.config.analytics_policy &&
            $scope.VirtualService.data.config.analytics_policy.client_insights === 'ACTIVE';
        $q.all([getTopListBaseData(), getEndToEnd()]).finally(function() {
            $scope.ui.ready = true;
        });
    }

    /**
     * updateFilters
     * Takes an event (it's from $scope.$on('filter-by-item'), updateFilters) and a TopListItem
     * If $scope.filters already has the item, removes it from $scope.filters
     * If there is an item with the same dimension, replace that item
     * Else just push the item into $scope.`filters
     * Then gets new filtered data
     */
    function updateFilters(e, item) {
        const { filters } = $scope.data;
        const index = _.indexOf(filters, item);

        if (index >= 0) {
            filters.splice(index, 1);
        } else {
            const found = _.some(filters, function(filterItem, i) {
                if (filterItem.parent.dim === item.parent.dim) {
                    filters.splice(i, 1, item);

                    return true;
                }
            });

            if (!found) {
                filters.push(item);
            }
        }

        if (filters.length) {
            getTopListFilteredData();
            getFilteredEndToEnd();
        } else {
            $scope.showBaseData();
        }

        setActives();
    }

    /**
     * On topLists display.oneActive is true if we are filtering by one of the items within
     * the list display.oneActive is used to set a class that greys out the other items.
     * On topListItems, display.active is used to set a class that highlights that entry.
     * This function is here because topListCollections (currently) have nothing to do with
     * filtering, which is a method on the topListDirective $scope
     */
    function setActives() {
        $timeout(function() {
            _.each($scope.data.topList, function(list) {
                list.each(function(item) {
                    item.display.active = false;
                });
                list.display.oneActive = false;
            });
            _.each($scope.data.filters, function(item) {
                item.display.active = true;
                item.parent.display.oneActive = true;
            });
        });
    }

    /**
     * getTopListData
     * Takes array of filters -- each filter object in filters has metric (e.g. 'devtype') and
     * a filter (e.g. 'computer').
     * Returns a promise
     * Either creates topLists or updates topLists on $scope.data.topList
     */
    function getTopListData(filters) {
        // Using slice here because I just want a copy of the array with the same objects within it
        // So that if somebody keeps clicking around, it doesn't mutate this array. Don't use
        filters = filters && filters.slice() || [];

        const filterLength = filters.length;
        const promises = [];

        _.each(dimensions, function(dimension) {
            let currentFilter = '';

            for (let i = 0; i < filterLength; i++) {
                const filter = filters[i];
                const { dim } = filter.parent;
                const { id } = filter.info;

                // We want to filter each toplist relative to all of the other dimensions
                // that we've selected
                // (If we filter relative to the same dimension, we'll just get 100% on
                // the item we click on)
                if (dim !== dimension) {
                    currentFilter += `&${dim}=${id}`;
                }
            }

            let url = baseUrl + dimension + stepAndLimit + currentFilter;

            if (dimension === 'ipgroup') {
                url += '&include_name&include_refs';
            }

            $scope.ui.busy = true;
            promises.push($http.get(url).then(function(rsp) {
                const { series } = rsp.data;
                let isBaseDataSet = false;

                if (!$scope.data.topList[dimension]) {
                    $scope.data.topList[dimension] = new TopListCollection(
                        series, dimension, $stateParams.vsId,
                    );

                    const topList = $scope.data.topList[dimension];

                    topList.on('end-to-end-set toggling', setMaxNavigationTiming);
                } else if (!filterLength) {
                    const topList = $scope.data.topList[dimension];

                    topList.updateListItems(series);
                    topList.setBaseData(series);
                    isBaseDataSet = true;
                }

                const topList = $scope.data.topList[dimension];

                if (filterLength) {
                    topList.setFilteredData(series);
                    topList.showFilteredData();

                    if (typeof topList.getTiming === 'function') {
                        topList.getTiming(filters);
                    }
                } else if (!isBaseDataSet) {
                    topList.setBaseData(series);
                }
            }));
        });

        return $q.all(promises).finally(function() {
            $scope.ui.busy = false;
        });
    }

    // note: maybe move this into toplist so that toplists are able to get their max time?
    function setMaxNavigationTiming() {
        // Grabbing the largest total navigation timing from all non-url top lists
        $scope.data.maxNavigationTiming = _.reduce($scope.data.topList, function(memo, list) {
            // Not including the url data because we don't show the same stuff for them
            if (list.dim === 'url') {
                return memo;
            }

            const val = list.getMaxNavigationTiming();

            return val > memo ? val : memo;
        }, 0);
    }

    function getTopListFilteredData() {
        getTopListData($scope.data.filters);
        _.each($scope.data.topList, function(list) {
            list.getEndToEndData($scope.data.filters);
        });
    }

    function getTopListBaseData() {
        getTopListData($scope.data.filters);
    }

    // ----------------------- End to end requests (for top section) -----------------------//
    function getEndToEnd(filters) {
        filters = filters || [];

        const filterLength = filters.length;
        const url = endToEndUrl + stepAndLimit + _.reduce(filters, function(memo, filter) {
            return `${memo}&${filter.parent.dim}=${filter.info.id}`;
        }, '');

        if (filterLength) {
            $scope.data.endToEnd.clearFiltered();
        }

        $scope.ui.busy = true;
        $http.get(url).then(function(rsp) {
            if (!rsp || !rsp.data || !rsp.data.series || !rsp.data.series.length) {
                if (filterLength) {
                    $scope.data.endToEnd.noFilteredData();
                    $scope.$broadcast('new-filtered-data');
                }

                return;
            }

            const timing = rsp.data.series[0];

            if (filterLength) {
                $scope.data.endToEnd.setFilteredEndToEnd(timing);
                $scope.$broadcast('new-filtered-data');
            } else {
                $scope.data.endToEnd.setBaseEndToEnd(timing);
            }
        }).finally(function() {
            $scope.ui.busy = false;
        });
    }

    function getFilteredEndToEnd() {
        if ($scope.data.filters.length) {
            getEndToEnd($scope.data.filters);
        }
    }

    /**
     * init
     */
    if (!$scope.VirtualService.data || !$scope.VirtualService.data.config) {
        $scope.VirtualService.load(['config']);
        $scope.VirtualService.on('itemLoadSuccess', function() {
            $scope.setInitialState();
        }, true); // one time event
    } else {
        $scope.setInitialState();
    }
}]);
