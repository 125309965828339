/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../../../less/pages/profile/certificate-list.less';

angular.module('aviApp').controller('CertificateListController', [
'CertificateCollection', '$templateCache', 'CRUDGridConfig', 'Schema', 'AviModal',
function(CertificateCollection, $templateCache, CRUDGridConfig, Schema, AviModal) {
    /**
     * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
     */
    const certCollectionEnums = Object.keys(Schema.enums.SSLCertificateType.values)
        .filter(function(type) {
            return type !== 'SSL_CERTIFICATE_TYPE_CA';
        }).join(',');

    const certCollection = new CertificateCollection({
        params: {
            type: certCollectionEnums,
        },
    });

    const authCollection = new CertificateCollection({
        params: {
            type: 'SSL_CERTIFICATE_TYPE_CA',
        },
    });

    authCollection.isCreatable = function() {
        return false;
    };

    this.collection = new CertificateCollection({
        bind: {
            collItemSave() {
                certCollection.load();
                authCollection.load();
            },
        },
    });

    // Mofify default grid configuration
    const certGridConfig = new CRUDGridConfig();
    const authGridConfig = new CRUDGridConfig();

    certGridConfig.collection = certCollection;
    authGridConfig.collection = authCollection;

    certGridConfig.fields = getCertFields();
    authGridConfig.fields = getCertFields();

    certGridConfig.singleactions = getSingleActions();
    authGridConfig.singleactions = getSingleActions();

    certGridConfig.layout = {
        lengthAsTotal: true,
        createActionsPosition: 'right',
    };

    authGridConfig.layout = getLayout();

    certGridConfig.createActions = [{
        label: 'Root/Intermediate CA Certificate',
        action() {
            authGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_CA',
            });

            authGridConfig.collection.create('ssl-certificate-create-root');
        },
    }, {
        label: 'Application Certificate',
        action() {
            certGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            });

            certGridConfig.collection.create('ssl-certificate-create-application-system');
        },
    }, {
        label: 'Controller Certificate',
        action() {
            certGridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_CERTIFICATE_TYPE_SYSTEM',
            });

            certGridConfig.collection.create('ssl-certificate-create-application-system');
        },
    }];

    this.certGridConfig = certGridConfig;
    this.authGridConfig = authGridConfig;

    /**
     * Returns grid fields for both certGridConfig and authGridConfig as both are identical.
     * @return {Object[]} gridConfig fields.
     */
    function getCertFields() {
        return [{
            name: 'name',
            title: 'Name',
            template: '<ssl-certificate-grid-name-column row="row" />',
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'status',
            title: 'Status',
            template: '<ssl-certificate-grid-status-column row="row" />',
            visibility: 'm',
        }, {
            name: 'common',
            title: 'Common Name',
            template: `
                <span class="sel-common-name">
                    {{ row.data.config.certificate.subject.common_name }}
                </span>`,
            visibility: 'm',
        }, {
            name: 'issuer',
            title: 'Issuer Name',
            template: `
                <span class="sel-issuer-name">
                    {{ row.data.config.certificate.issuer.common_name }}
                </span>`,
            visibility: 'm',
        }, {
            name: 'algorithm',
            title: 'Algorithm',
            template: require('./certificate-list-algorithm.partial.html'),
            visibility: 'm',
        }, {
            name: 'selfsigned',
            title: 'Self Signed',
            template: `
                <span class="sel-self-signed">
                    {{row.data.config.certificate.self_signed ? 'Yes' : 'No'}}
                </span>`,
            visibility: 'm',
        }, {
            name: 'valid',
            title: 'Valid Until',
            template: `
                <span class="sel-valid">
                    {{row.data.config.status!="SSL_CERTIFICATE_PENDING" ?
                       row.data.config.certificate.not_after : "Awaiting Certificate"}}
                </span>`,
            visibility: 'm',
        }, {
            name: 'san_domain_names',
            title: 'SAN Domain Names',
            template: '<span>{{ row.data.config.certificate.subject_alt_names.join(", ") }}</span>',
            visibility: 'optional',
        }];
    }

    /**
     * Returns singleactions for the gridConfig.
     * @return {Object[]} gridConfig singleactions.
     */
    function getSingleActions() {
        return [{
            title: 'Export',
            class: 'icon-download sel-download',
            hidden(row) {
                return row.data.config.status === 'SSL_CERTIFICATE_PENDING';
            },
            do(row) {
                AviModal.open('ssl-certificate-export', {
                    uuid: row.data.config.uuid,
                });
            },
        }, {
            title: 'Edit',
            class: 'icon-pencil-4',
            hidden(row) {
                return !row.isEditable();
            },
            do: row => row.edit(),
        }];
    }

    /**
     * Returns layout for the gridConfig.
     * @return {Object<string, boolean>} gridConfig layout.
     */
    function getLayout() {
        return {
            lengthAsTotal: true,
            includeCreateButton: false,
        };
    }
}]);
