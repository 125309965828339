/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateImportedInformation
 * @description  Displays the imported certificate information.
 * @param {object} config - Certificate config object.
 */
function Controller(Schema) {
    this.Schema = Schema;
}

Controller.$inject = [
        'Schema',
];

angular.module('aviApp').component('sslCertificateImportedInformation', {
    bindings: {
        certificate: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/templates/security/ssl-tls-certificates/' +
            'ssl-certificate-imported-information/ssl-certificate-imported-information.html',
});
