/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name VRFContext
 */
angular.module('aviApp').factory('VRFContext', [
'Item', function(Item) {
    class VRFContext extends Item {
        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { bgp_profile: bgpProfile } = config;

            if (bgpProfile && angular.isUndefined(bgpProfile['local_as'])) {
                delete config['bgp_profile'];
            }

            return config;
        }

        /**
         * @return {Boolean} Returns true if VRF Context is editable, false otherwise (for
         * global and management VRFs).
         * @override
         */
        isEditable() {
            return !this.isProtected() && super.isEditable();
        }
    }

    angular.extend(VRFContext.prototype, {
        objectName: 'vrfcontext',
        windowElement: 'infra-vrf-context-create',
    });

    return VRFContext;
}]);
