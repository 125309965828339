/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('VirtualServiceCollection', [
'Collection', 'VirtualService', '$q',
function(Collection, VirtualService, $q) {
    const allDataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'VSInventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health',
                'runtime',
                'alert',
                'faults',
            ],
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'l4_client.avg_bandwidth',
                'l4_client.max_num_active_se',
                'l4_client.max_open_conns',
                'l4_client.avg_total_rtt',
                'l4_server.avg_total_rtt',
                'l4_client.avg_complete_conns',
                'l4_client.avg_rx_pkts',
                'l4_client.avg_tx_pkts',
                'l4_client.avg_policy_drops',
                'l4_client.avg_dos_attacks',
                'l4_client.avg_errored_connections',
                'l7_client.avg_complete_responses',
                'l7_client.avg_error_responses',
                'l7_client.pct_response_errors',
            ],
            dependsOn: 'config',
        },
    };

    const listConfigDataSource = {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    };

    class VirtualServiceCollection extends Collection {
        constructor(args = {}) {
            //when no inventory needed lets use regular list API
            if (args.objectName === 'virtualservice') {
                args.defaultDataSources = 'list';
                args.dataFields = args.dataFields || ['config'];
                args.allDataSources = angular.copy(allDataSources);
                delete args.allDataSources['inventory'];
                args.allDataSources['list'] = listConfigDataSource;
            }

            super(args);
        }

        /**
         * Virtual Services cannot be dropped by any order. We need to make sure that:
         * 1. Child VSes are dropped first.
         * 2. VSes with auto_allocate_ip = true are dropped last.
         * @param {VirtualService[]} items - List of VSes.
         * @param {boolean} force - True if deleting VS along with children.
         * @return {ng.$q.promise}
         */
        dropByPriority(items, force) {
            const
                childVirtualServices = [],
                autoAllocateIpVirtualServices = [],
                otherVirtualServices = [];

            items.forEach(item => {
                const config = item.getConfig();

                if (config['type'] === 'VS_TYPE_VH_CHILD') {
                    childVirtualServices.push(item.id);
                } else if (config['auto_allocate_ip']) {
                    autoAllocateIpVirtualServices.push(item.id);
                } else {
                    otherVirtualServices.push(item.id);
                }
            });

            let dropParams;

            if (force) {
                dropParams = {
                    withChildren: true,
                };
            }

            const promise = childVirtualServices.length ?
                this.dropItems(childVirtualServices, false, false, dropParams) : $q.when(true);

            return promise
                .then(() => {
                    return otherVirtualServices.length ?
                        this.dropItems(otherVirtualServices, false, false, dropParams) :
                        $q.when(true);
                }).then(() => {
                    return autoAllocateIpVirtualServices.length ?
                        this.dropItems(autoAllocateIpVirtualServices, false, false, dropParams) :
                        $q.when(true);
                });
        }
    }

    const serverDefaultsOverride = {
        services: [VirtualService.getDefaultServiceConfig({ port: 80 })],
        connections_rate_limit: undefined,
        requests_rate_limit: undefined,
        se_group_ref: undefined,
        saml_sp_config: undefined,
        analytics_policy: {
            client_log_filters: [],
            full_client_logs: {
                enabled: true,
            },
            metrics_realtime_update: {
                enabled: true,
            },
        },
    };

    Object.assign(VirtualServiceCollection.prototype, {
        objectName_: 'virtualservice-inventory',
        itemClass_: VirtualService,
        windowElement_: 'app-vs-create',
        defaultDataSources_: 'inventory',
        isStatic_: false,
        serverDefaultsOverride_: serverDefaultsOverride,
        allDataSources_: allDataSources,
        defaultDataFields_: ['config', 'runtime', 'health'],
        sortBy_: 'name',
    });

    return VirtualServiceCollection;
}]);
