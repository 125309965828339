/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('operStatus', function() {
    return {
        scope: false,
        link(scope, elm, attr) {
            elm.wrap('<div class="input-append"></div>').after(`<span class="oper_status">${
                attr.operStatus}</span>`);
            elm.parent().find('.oper_status').addClass(attr.operStatus.toLowerCase());
        },
    };
});
