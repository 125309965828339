/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const wafPsmRuleConfigItemFactory = MessageItem => {
    /**
     * @alias module:config-item/WafPSMRuleConfigItem
     * @private
     */
    class WafPSMRuleConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'WafPSMRule',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * Returns the rule_id.
         * @returns {string}
         */
        get ruleId() {
            return this.config.rule_id;
        }

        /**
         * Returns the enable state.
         * @returns {boolean}
         */
        get enable() {
            return this.config.enable;
        }

        /**
         * Sets the enable state.
         * @param {boolean} enabled - New state to set.
         */
        set enable(enabled) {
            this.config.enable = enabled;
        }

        /**
         * Returns the mode.
         * @returns {string}
         */
        get mode() {
            return this.config.mode;
        }

        /**
         * Sets the mode.
         * @param {string} wafMode - Mode enum to set.
         */
        set mode(wafMode) {
            this.config.mode = wafMode;
        }

        /**
         * Returns the number of configured match elements.
         * @returns {number}
         */
        get matchElementsCount() {
            return this.config.match_elements.count;
        }

        /**
         * Adds a WafPSMMatchElement instance to the list of Waf PSM match_elements.
         */
        addMatchElement() {
            const { match_elements: matchElements } = this.config;
            const maxIndex = matchElements.getMaxIndex();
            const newIndex = _.isNaN(+maxIndex) ? 0 : maxIndex + 1;
            const newMatchElement = this.createChildByField_('match_elements', null, true);

            newMatchElement.setIndex(newIndex);
            matchElements.add(newMatchElement);
        }
    }

    return WafPSMRuleConfigItem;
};

wafPsmRuleConfigItemFactory.$inject = [
    'MessageItem',
];

/**
 * @ngdoc factory
 * @name  WafPSMRuleConfigItem
 * @description  WafPSMRule ConfigItem class.
 * @module config-item/WafPSMRuleConfigItem
 * @author alextsg
 */
angular.module('waf.vantage.avi').factory('WafPSMRuleConfigItem', wafPsmRuleConfigItemFactory);
