/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
const componentName = 'waf-policy-child-mode-setter';

/** @alias wafPolicyChildModeSetter **/
class WafPolicyChildModeSetterController {
    constructor($element, schemaService, WafRuleGroupConfigItem) {
        this.$element = $element;
        this.schemaService = schemaService;
        this.WafRuleGroupConfigItem = WafRuleGroupConfigItem;

        this.detectionModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            'WAF_MODE_DETECTION_ONLY',
        );

        this.enforcementModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            'WAF_MODE_ENFORCEMENT',
        );

        this.useParentModeValue = {
            label: '',
            value: undefined,
        };
    }

    /** @override */
    $onInit() {
        this.$element.addClass(componentName);

        // as of now used only by rule
        this.childType = this.editable instanceof this.WafRuleGroupConfigItem ? 'group' : 'rule';

        this.useParentModeValue.label = `Use policy mode (${this.getParentPolicyModeLabel()})`;
    }

    /**
     * Returns label of the passed parentMode enum.
     * @return {string}
     */
    getParentPolicyModeLabel() {
        return this.schemaService.getEnumValue(
            'WafMode',
            this.parentMode,
        ).label;
    }
}

WafPolicyChildModeSetterController.$inject = [
    '$element',
    'schemaService',
    'WafRuleGroupConfigItem',
];

/**
 * @ngdoc component
 * @name wafPolicyChildModeSetter
 * @param {wafRule} editable
 * @param {string} parentMode - WafMode enum value.
 * @param {boolean?} isDisabled
 * @author Alex Malitsky
 * @desc
 *
 *     Renders three radio buttons to choose WAF rule mode with a header.
 *     Disables radio inputs when true is passed as isDisabled attribute value.
 *     Shows warning message when mode had been set but is not applied due to the
 *     "allow override" WAF policy flag value.
 *
 *     Can be easily extended to work with wafRuleGroup.
 */
angular.module('aviApp').component('wafPolicyChildModeSetter', {
    bindings: {
        editable: '<',
        parentMode: '<',
        isDisabled: '<?',
    },
    controller: WafPolicyChildModeSetterController,
    templateUrl: `src/components/modals/waf-policy-modal/${componentName}/${componentName}.html`,
});
