/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name OnePoolServerInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     DataSource to get all servers within one pool. Faster than
 *     {@link PoolServerInventoryCollDataSource} API.
 */
angular.module('aviApp').factory('OnePoolServerInventoryCollDataSource', [
'InventoryCollDataSource',
function(InventoryCollDataSource) {
    return class OnePoolServerInventoryCollDataSource extends InventoryCollDataSource {
        constructor(args = {}) {
            if (!('defaultParams' in args)) {
                args.defaultParams = {};
            }

            args.defaultParams.all_se = true;

            super(args);

            this.hasSearch = false;
            this.hasSorting = false;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_(this.params_);

            params['objectName_'] = ['pool-inventory', this.owner_.poolId, 'server'];

            return params;
        }
    };
}]);
