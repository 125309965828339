/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 *
 * @typedef {Object} MessageItemData - Plain object that does not contain any MessageItem instances.
 * @property {Object} config - Config data object.
 *
 * @typedef {Object} MessageItemTreeConfig - Config data that contains instances of MessageItems.
 */

const messageItemFactory = (MessageBase, RepeatedMessageItem) => {
    /**
     * @alias module:services/MessageItem
     * @private
     */
    class MessageItem extends MessageBase {
        /**
         * List of fields that should always be present in the config. If that field's value is
         * undefined, it will be set to the default value in this.modifyConfigDataAfterLoad_.
         * @return {string[]}
         */
        get requiredFields_() {
            return [];
        }

        /**
         * @override
         */
        modifyConfigDataAfterLoad_() {
            this.requiredFields_.forEach(field => {
                if (_.isUndefined(this.config[field])) {
                    this.setNewChildByField_(field);
                }
            });
        }

        /**
         * If this returns false, this.flattenConfig will return undefined.
         * @returns {boolean}
         * @protected
         */
        canFlatten_() { //eslint-disable-line class-methods-use-this
            return true;
        }

        /**
         * Updates the current config with new config data, and calls lifecycle hooks used to modify
         * data after loading.
         * @param {Object} newConfig - New config data.
         * @param {boolean} [skipDataTransformation=] - True to skip modifying the data on update.
         */
        updateConfig(newConfig, skipDataTransformation = false) {
            if (skipDataTransformation) {
                this.setConfigData_(newConfig);
            } else {
                this.setConfigData_(this.dataAfterLoad_(newConfig));
                this.modifyConfigDataAfterLoad_();
            }
        }

        /**
         * Update the current config with new config data.
         * @param {Object} newConfig - New config data.
         * @protected
         */
        setConfigData_(newConfig = {}) {
            const updatedConfigItems = _.reduce(
                this.messageMap_,
                this.getSetConfigDataReducer_(newConfig),
                {},
            );

            this.data.config = {
                ...newConfig,
                ...updatedConfigItems,
            };
        }

        /**
         * Given a field name of a config, create a MessageItem corresponding to that field.
         * @param {string} fieldName - name of the property in the config.
         * @param {Object=} childConfig - Config data object to be set in the child MessageItem.
         * @param {boolean} [skipRepeated=false] - True if RepeatedMessageItem should not be
         *     created, and the ConfigItemClass should be created directly.
         * @param {Object=} optionalArgs - Optional arguments to pass to the child constructor.
         * @returns {RepeatedMessageItem|MessageItem}
         * @protected
         */
        createChildByField_(fieldName, childConfig, skipRepeated = false, optionalArgs = {}) {
            const messageMapProps = this.messageMap_[fieldName];

            if (!messageMapProps) {
                throw new Error(`'${fieldName}' is not a message field.`);
            }

            const { ConfigItemClass, isRepeated, objectType } = messageMapProps;
            const args = {
                objectType,
                fieldName,
                config: childConfig,
                parent: this,
                isClone: this.isClone_,
                ...optionalArgs,
            };

            return isRepeated && !skipRepeated ?
                new RepeatedMessageItem(args) :
                new ConfigItemClass(args);
        }

        /**
         * Creates a new RepeatedMessageItem or MessageItem instance and sets it as a property on
         * the config.
         * @param {string} fieldName - Property of a MessageItem to set on the config.
         * @protected
         */
        setNewChildByField_(fieldName, ...args) {
            this.config[fieldName] = this.createChildByField_(fieldName, ...args);
        }

        /**
         * Calls a callback on each MessageItem within the config.
         * @param {Function} callback - Callback to be called on each MessageItem.
         * @protected
         */
        eachChildConfigItem_(callback) {
            _.each(this.messageMap_, (messageMapProps, field) => {
                const configItem = this.config[field];

                if (!_.isUndefined(configItem)) {
                    callback(configItem);
                }
            });
        }

        /**
         * Calls a method on this, then calls the same method on each child MessageItem.
         * @param {string} methodName - Name of the method to be called on this and children.
         * @protected
         */
        recursiveConfigItemCall_(methodName) {
            this[methodName]();
            this.eachChildConfigItem_(childConfigItem => {
                childConfigItem.recursiveConfigItemCall_(methodName);
            });
        }

        /**
         * Calls this.dataToSave to get a copy of data.config, then flattens every nested
         * MessageItem into config data and removes empty repeated values.
         * @returns {Object} - Flat config data object.
         */
        getDataToSave() {
            this.recursiveConfigItemCall_('modifyConfigDataBeforeSave_');

            return this.dataToSave(this.flattenConfig_());
        }

        /**
         * Returns the index of the MessageItem, which typically exists for repeated MessageItems.
         * Can be overwritten with a more applicable unique identifier.
         * @param {MessageItemTreeConfig} [config=this.config]
         * @returns {number|string|undefined}
         */
        getIndex(config = this.config) {
            return config.index;
        }

        /**
         * Sets the index property on a config.
         * @param {number} [index=] - Index to set.
         */
        setIndex(index = 0) {
            this.config.index = index;
        }

        /**
         * Returns the MessageItem name.
         * @returns {string|undefined}
         */
        getName(config = this.config) {
            return config && (config.name || config.url && config.url.name()) || '';
        }

        /**
         * Returns plain object config data from this instance.
         * @returns {Object}
         * @param {boolean} [bypassCheck=] - True to bypass the canFlatten check, used for cloning.
         * @protected
         */
        flattenConfig_(bypassCheck = false) {
            if (!bypassCheck && !this.canFlatten_()) {
                return undefined;
            }

            const { config } = this;

            const flattenedChildConfigItems = _.reduce(this.messageMap_, (acc, base, field) => {
                const configItem = config[field];

                if (!_.isUndefined(configItem)) {
                    acc[field] = configItem.flattenConfig_(bypassCheck);
                }

                return acc;
            }, {});

            return {
                ...config,
                ...flattenedChildConfigItems,
            };
        }

        /**
         * Reducer used when updating or creating new MessageItems.
         * @param {Object} newConfig - New config object to update the current config with.
         * @returns {Function} Reducer function to set MessageItem instances.
         * @protected
         */
        getSetConfigDataReducer_(newConfig) {
            return (configItems, messageMapProps, field) => {
                const { isRepeated } = messageMapProps;
                const newValue = newConfig[field];

                // We check isRepeated here since we want to instantiate RepeatedMessageItems
                // even if their configs are not present.
                if (_.isUndefined(newValue) && !isRepeated) {
                    return configItems;
                }

                if (!_.isUndefined(this.config[field])) {
                    const configItem = this.config[field];

                    configItem.updateConfig(newValue);
                    configItems[field] = configItem;

                    return configItems;
                }

                configItems[field] = this.createChildByField_(field, newValue);

                return configItems;
            };
        }
    }

    return MessageItem;
};

messageItemFactory.$inject = [
    'MessageBase',
    'RepeatedMessageItem',
];

/**
 * @ngdoc factory
 * @name MessageItem
 * @description
 *     ConfigItem class. Intended to mirror protobuf objects (ex. Vip, DnsInfo) used in
 *     configuration. The idea is to add some separation in managing data when a top-level object,
 *     like VirtualService, contains many sub-objects like Vip and DnsInfo, such that methods
 *     dealing with those sub-objects can be defined on their ConfigItem classes instead of having
 *     everything on the top-level object class.
 *
 *     ConfigItems have methods similar to Item methods in order to keep the data flow consistent.
 * @module services/MessageItem
 * @author alextsg
 */
angular.module('core.vantage.avi').factory('MessageItem', messageItemFactory);
