/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBSite
 * @author Alex Malitsky
 * @description
 *
 *     GSLBSite is the nested structure of GSLB config, hence there is no direct API. These
 *     items are created by collection which is supposed to have a loaded GSLB Item
 */
angular.module('aviApp').factory('GSLBSite', ['$q', 'Item', function($q, Item) {
    class GSLBSite extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config['cluster_uuid'] || '';
        }

        /**
         * Returns true if member is `active`, false otherwise.
         * @returns {boolean|undefined}
         * @public
         */
        isActiveMember() {
            const config = this.data && this.data.config;

            if (config) {
                return config['member_type'] === 'GSLB_ACTIVE_MEMBER';
            }
        }

        /**
         * Returns true if member is a non-Avi site, false otherwise.
         * @return {boolean}
         * @public
         */
        isNonAviSite() {
            return !!this.getConfig()._isNonAviSite;
        }

        /**
         * Returns boolean flag whether this GslbSite is the GSLB leader.
         * @returns {boolean}
         */
        isLeader() {
            return !!this.getConfig().isLeader;
        }

        /** @override */
        isEditable() {
            const gslb = this.collection && this.collection.gslb;

            return gslb ? gslb.isEditable() : false;
        }

        /** @override */
        isDroppable() {
            const gslb = this.collection && this.collection.gslb;

            return gslb ? gslb.isEditable() && !this.isLeader() : false;
        }

        /**
         * Returns GslbSite enabled/disabled state.
         * @returns {boolean}
         * @public
         */
        isEnabled() {
            return this.getConfig()['enabled'];
        }

        /** @override */
        drop() {
            const { collection } = this;

            if (collection) {
                return collection.dropSites(this.id);
            } else {
                return $q.reject('Can\'t drop when no GSLB item is present');
            }
        }

        /** @override */
        edit() {
            const
                gslb = this.collection && this.collection.gslb;

            if (gslb) {
                const methodName = this.isNonAviSite() ? 'editNonAviSite' : 'editSite';

                return gslb[methodName](this.id);
            } else {
                return $q.reject('Can\'t edit when no GSLB item is present');
            }
        }
    }

    angular.extend(GSLBSite.prototype, {
        objectName: 'gslbsite',
    });

    return GSLBSite;
}]);
