/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBService
 * @author Alex Malitsky
 * @description
 *
 *     Item as of protobuf GslbService message. Needs a reference to GSLB it belongs to.
 */

/**
 * @typedef {Object} GslbServiceConfig
 * @property {string} uuid
 * @property {string} name
 * @property {strings[]|Object[]} domain_names - UI represents
 * them in objects with two string properties to be joined on save. Backend keeps them as strings.
 * @property {GslbPool[]} groups
 * @property {number|undefined} num_dns_ip
 * @property {number|undefined} ttl
 **/

/**
 * @typedef {Object} GslbPoolConfig
 * @property {string} name
 * @property {number} priority - From 1 to 100.
 * @property {string} algorithm - GslbAlgorithm protobuf enum.
 * @property {number|undefined} consistent_hash_mask - For consistent hash algorithm only.
 * @property {GslbPoolMemberConfig[]} members
 */

/**
 * @typedef {Object} GslbPoolMemberConfig
 * @property {string|undefined} cluster_uuid - If set through cluster and VS.
 * @property {string|undefined} vs_uuid - If set through cluster and VS.
 * @property {IpAddr} ip - Provided by user or resolved by DNS service.
 * @property {string|undefined} fqdn - FQDN
 * @property {boolean|undefined} isSetByIp - Plainly UI property defining layout type for IP.
 * setting.
 * @property {GslbPool#priority} priority_ - Plainly UI property used for basic create.
 * active-standby mode.
 * @property {IpAddr.addr[]|undefined} allVSIPs - UI property to show dropdown when VS has more
 * than one IP.
 */

angular.module('GSLB.vantage.avi').factory('GSLBService', [
'$q', 'UpdatableItem', 'GSLBVSCollection', 'systemInfoService', 'fqdnSplit', 'fqdnJoin',
'gslbLocationAfterLoad',
function($q, UpdatableItem, GSLBVSCollection, systemInfo, fqdnSplit, fqdnJoin,
gslbLocationAfterLoad) {
    /**
     * @constructor
     * @extends Item
     */
    class GSLBService extends UpdatableItem {
        constructor(args = {}) {
            super(args);

            if ('gslb' in args) {
                this.gslb_ = args.gslb;
            }
        }

        /** @override **/
        isEditable() {
            if (systemInfo.localSiteIsGSLBLeader() && super.isEditable()) {
                const gslb = this.getGSLB();

                if (gslb) {
                    return gslb.hasConfig();
                }
            }

            return false;
        }

        /** @override **/
        isProtected() {
            return !systemInfo.localSiteIsGSLBLeader() || super.isProtected();
        }

        /** @override */
        loadRequest(fields) {
            return $q.all([
                this.loadConfig(fields),
                this.loadMetrics(fields),
            ]);
        }

        /**
         * Get method for GSLB instance this GSLBService belongs to.
         * @returns {GSLB|undefined}
         * //TODO mb it is better to pass gslb ref into GslbService item on create/edit/append
         */
        getGSLB() {
            if ('gslb_' in this) {
                return this.gslb_;
            } else if (this.collection) {
                return this.collection.gslb;
            } else if (this.opener && 'gslb_' in this.opener) {
                return this.opener.gslb_;
            }
        }

        /**
         * Checks whether Item is in enabled (default) state.
         * @returns {boolean}
         * @public
         */
        isEnabled() {
            const { enabled } = this.getConfig();

            return !!enabled;
        }

        /**
         * Makes a PATCH request to set enabled state to the passed value.
         * @param {boolean} enabled - True or false.
         * @returns {ng.$q.promise}
         * @public
         */
        setEnabledState(enabled) {
            return this.patch({ replace: { enabled } });
        }
    }

    GSLBService.prototype.objectName = 'gslbservice';
    GSLBService.prototype.windowElement = 'app-gslb-service-edit';

    /** @override */
    GSLBService.prototype.beforeEdit = function() {
        const config = this.getConfig();

        ['domain_names', 'health_monitor_refs', 'groups']
            .forEach(fieldName => {
                if (!(fieldName in config)) {
                    config[fieldName] = [];
                }
            });

        const { groups } = config;

        if (groups) {
            groups.forEach(({ members }) => {
                if (members) {
                    members.forEach(({ location }) => gslbLocationAfterLoad(location));
                }
            });
        }

        if (!config['domain_names'].length) {
            const gslb = this.getGSLB();

            config['domain_names'].push(
                this.domainNameBeforeEdit(gslb ? gslb.getDefaultDNSDomainName() : ''),
            );
        } else {
            config['domain_names'] = config['domain_names']
                .map(this.domainNameBeforeEdit.bind(this));
        }
    };

    /** @override */
    GSLBService.prototype.dataToSave = function() {
        const config = angular.copy(this.data.config);

        ['health_monitor_refs', 'groups'].forEach(fieldName => {
            config[fieldName] = _.compact(config[fieldName]);//removes falsy values

            if (!config[fieldName].length) {
                config[fieldName] = undefined;
            }
        });

        config['domain_names'] = _.reject(config['domain_names'],
            domainName => !domainName.appDomainName);

        if (!config['domain_names'].length) {
            config['domain_names'] = undefined;
        } else {
            config['domain_names'] = config['domain_names']
                .map(domainName => GSLBService.domainNameBeforeSave_(domainName));
        }

        if (config['groups']) {
            config['groups'].forEach(group => {
                group['members'].forEach(member => {
                    if (member['vs_uuid']) {
                        member['vs_uuid'] = member['vs_uuid'].slug();
                    }

                    ['isSetByIp', 'priority_', 'allVSIPs'].forEach(fieldName => {
                        member[fieldName] = undefined;
                    });
                });
            });
        }

        return config;
    };

    /**
     * Returns default GslbPoolConfig and sequential array index within GslbServiceConfig#groups
     * to be saved to.
     * @param {GslbPoolConfig=} newGslbPoolConfig - When passed default config values will be
     *     overwritten by these.
     * @returns {{config: GslbPoolConfig, id: number}}
     * @public
     */
    GSLBService.prototype.createPool = function(newGslbPoolConfig) {
        const
            config = this.getConfig(),
            defGslbPoolConfig = this.getDefaultPoolConfig_();

        if (config) {
            return {
                id: config['groups'].length,
                config: angular.extend(
                    defGslbPoolConfig,
                    {
                        members: [this.getDefaultPoolMemberConfig()],
                        priority: undefined,
                    },
                    newGslbPoolConfig,
                ),
            };
        }
    };

    /**
     * Returns default configuration of the nested GslbPool.
     * @returns {GslbPoolConfig}
     * @private
     */
    GSLBService.prototype.getDefaultPoolConfig_ = function() {
        const { groups } = this.getDefaultConfig_();

        return groups && groups[0] || {};
    };

    /**
     * Returns copied GslbPoolConfig of a passed index (or found by config itself) and
     * index value. Kinda Item#beforeEdit method for GslbPool.
     * @param {GslbPoolConfig|number} poolConfig
     * @returns {{config: GslbPoolConfig, id: number}} - Id is an index within
     * GslbServiceConfig#groups of editable GslbPoolConfig.
     * @public
     */
    GSLBService.prototype.editPool = function(poolConfig) {
        const index = angular.isNumber(poolConfig) && poolConfig >= 0 &&
            poolConfig < this['groups'].length ? poolConfig : this.getPoolIndex_(poolConfig);

        if (this.data && !_.isUndefined(index)) {
            poolConfig = angular.copy(this.data.config['groups'][index]);

            poolConfig['members'].forEach(member => {
                member.isSetByIp = !('vs_uuid' in member);

                if (!('ip' in member)) {
                    member.ip = {
                        addr: '',
                        type: 'V4',
                    };
                }
            });

            return {
                id: index,
                config: poolConfig,
            };
        }
    };

    /**
     * Puts a GslbPool into a passed position of GslbServiceConfig#groups array. Kinda
     * Item#dataToSave method for GslbPool.
     * @param {GslbPoolConfig} poolConfig
     * @param {number} index
     * @public
     */
    GSLBService.prototype.appendPool = function(poolConfig, index) {
        if (this.data) {
            const { groups } = this.data.config;

            if (angular.isObject(poolConfig) && angular.isNumber(index) &&
                index >= 0 && index <= groups.length) {
                groups[index] = poolConfig;
            }
        }
    };

    /**
     * Returns a position of a passed GslbPoolConfig in GslbServiceConfig#groups array.
     * @param {GslbPoolConfig} poolConfig
     * @returns {number|undefined} - Undefined when not found.
     * @protected
     */
    GSLBService.prototype.getPoolIndex_ = function(poolConfig) {
        const index = this.data.config['groups'].indexOf(poolConfig);

        return index !== -1 ? index : undefined;
    };

    /**
     * Removes a passed (by config itself or array index) GslbPoolConfig from GslbServiceConfig.
     * @param {number|GslbPoolConfig} poolConfig
     * @public
     */
    GSLBService.prototype.dropPool = function(poolConfig) {
        if (this.data) {
            const { groups } = this.data.config;
            let index;

            if (angular.isNumber(poolConfig) && poolConfig >= 0 && poolConfig < groups.length) {
                index = poolConfig;
            } else {
                index = groups.indexOf(poolConfig);
            }

            if (index !== -1) {
                groups.splice(index, 1);
            }
        }
    };

    /**
     * Checks whether a passed GslbPoolConfig confirms to the existent GslbServiceConfig
     * and it's requirements - basically we verify that name and priority properties are unique
     * among all GslbPools within the GslbServiceConfig.
     * @param {GslbPoolConfig} poolConfig
     * @param {number=} indexToExclude - Ignores GslbPoolConfig of a passed index which we might
     *     be editing right now.
     * @returns {boolean|undefined}
     * @public
     */
    GSLBService.prototype.checkEditablePool = function(poolConfig, indexToExclude) {
        let isValid;

        if (this.data) {
            let name;

            isValid = false;

            if (name = poolConfig['name']) {
                const checkIndex = angular.isNumber(indexToExclude) &&
                    indexToExclude >= 0 && indexToExclude < this.data.config['groups'].length;

                //looking for the pool with same name and different index
                isValid = poolConfig['members'].length &&
                    !_.any(this.data.config['groups'], (poolConfig, index) => {
                        return poolConfig['name'] === name &&
                            (!checkIndex || indexToExclude !== index);
                    });
            }
        }

        return isValid;
    };

    /**
     * Checks one faked active-standby GslbPoolConfig which will be translated into many pools
     * with a single member each. Used by basic create and `active-standby` only.
     * @param {GslbPoolConfig} poolConfig - Config which must have members with not overlapping
     *     {GslbPoolMemberConfig#priority_} properties. GslbServiceConfig shouldn't have any other
     *     pools in config since this method is used by basic creation modal only.
     * @returns {boolean|undefined}
     * @public
     */
    GSLBService.prototype.checkEditableActiveStandbyPool = function(poolConfig) {
        let isValid;

        if (this.data) {
            isValid = !this.data.config['groups'].length && poolConfig['members'].length &&
                _.uniq(_.pluck(poolConfig['members'], 'priority_')).length ===
                poolConfig['members'].length;
        }

        return isValid;
    };

    /**
     * For basic create `active-standby` only from one GslbPoolConfig with many members we make
     * corresponding number of pools with only one member each. Made so to be able to reuse
     * {@link gslbPoolMemberForm}.
     * @param {GslbPoolConfig} poolConfig
     * @public
     */
    GSLBService.prototype.appendActiveStandbyPool = function(poolConfig) {
        poolConfig['members'].forEach((member, index) => {
            const newPool = this.createPool({
                name: `${poolConfig['name']}-${index + 1}`,
                members: [member],
                priority: member.priority_,
            });

            this.appendPool(newPool.config, newPool.id);
        });
    };

    /**
     * Returns the default GslbPoolMemberConfig configuration.
     * @returns {GslbPoolMemberConfig}
     * @public
     */
    GSLBService.prototype.getDefaultPoolMemberConfig = function() {
        const
            { members } = this.getDefaultPoolConfig_(),
            memberDefaultConfig = members && members[0];

        return angular.extend(memberDefaultConfig, {
            ip: { addr: '', type: 'V4' },
            isSetByIp: false,
        });
    };

    /**
     * Since object model keeps {GslbPoolMember#vs_uuid} not as ref but bare uuid we need to go over
     * all GslbPools, pick GslbSites and vsIds used and load their names and ips.
     * @returns {angular.$q.promise} - When resolved config will have names appended to all used
     * {GslbPoolMember#vs_uuid} and {GslbPoolMember#allVSIPs} should be populated.
     * @public
     */
    GSLBService.prototype.getPoolMemberVsData = function() {
        let promise;

        if (this.data) {
            // clusterId: {names: {vsId1: name, vsId2: name}, ips: {vsId1: [ipAddr]},
            // collection: vsCollection}
            const hash = {};
            const config = this.getConfig();

            this.busy = true;

            config['groups'].forEach(group => {
                group['members'].forEach(member => {
                    const clusterId = member['cluster_uuid'],
                        vsId = member['vs_uuid'];

                    if (clusterId && vsId) {
                        if (!(clusterId in hash)) {
                            hash[clusterId] = { names: {}, ips: {} };
                        }

                        hash[clusterId].names[vsId] = '';//name placeholder
                    }
                });
            });

            _.each(hash, (data, gslbSiteId) => {
                if (!_.isEmpty(data.names)) {
                    data.collection = new GSLBVSCollection({
                        gslbSiteId,
                        limit: 1000,
                        params: {
                            'uuid.in': _.keys(data.names).join(),
                            fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                            join: 'vsvip_ref',
                        },
                    });
                }
            });

            promise = $q.all(
                _.map(hash, data => data.collection && data.collection.load()),
            ).then(() => {
                //put names and ips in place
                _.each(hash, (data, clusterId) => {
                    _.each(data.names, (emptyName, vsId) => {
                        const vs = data.collection.getItemById(vsId);

                        if (vs) {
                            data.names[vsId] = vs.getName();

                            //Handle vs potentially being a childVS
                            if (vs.isVHChild()) {
                                const headerParam = { headers_:
                                    { 'X-Avi-Internal-GSLB': clusterId },
                                };

                                vs.addParams(headerParam);
                                vs.getVHParentIPs('allV4')
                                    .then(parentIPs => data.ips[vsId] = parentIPs)
                                    .catch(console.error);
                            } else {
                                data.ips[vsId] = vs.getIPAddresses('allV4');
                            }
                        }
                    });
                });

                //update vs uuids and allVSIPs from config
                config['groups'].forEach(group => {
                    group['members'].forEach(member => {
                        const clusterId = member['cluster_uuid'],
                            vsId = member['vs_uuid'];
                        let clusterData;

                        if (clusterId && vsId && (clusterData = hash[clusterId])) {
                            if (vsId in clusterData.names) {
                                member['vs_uuid'] += `#${clusterData.names[vsId]}`;
                            }

                            if (vsId in clusterData.ips) {
                                member.allVSIPs = clusterData.ips[vsId];
                            }
                        }
                    });
                });
            }).finally(() => {
                this.busy = false;
                _.each(hash, data => data.collection && data.collection.destroy());
            });
        } else {
            promise = $q.reject('Config is not ready');
        }

        return promise;
    };

    /**
     * Returns an array of domain names used by this GSLBService.
     * @returns {GSLBService.domain_names}
     * @public
     */
    GSLBService.prototype.getDomainNames = function() {
        const { domain_names: domainNames } = this.getConfig();

        return domainNames.concat();
    };

    /**
     * Parses full domain name into an object expected by edit modal. Full domain names consists
     * of subdomain as well as top most level domain name supported by GSLB.
     * @param {string=} fullDomainName - If not passed default empty object will be returned.
     * @returns {{appDomainName: string, subdomain: string}}
     * @public
     */
    GSLBService.prototype.domainNameBeforeEdit = function(fullDomainName) {
        let appDomainName = '',
            subdomain = '';

        if (fullDomainName) {
            [appDomainName, subdomain] = this.splitDomainNameField_(fullDomainName);
        }

        return {
            appDomainName,
            subdomain,
        };
    };

    /**
     * Since we present domain in forms as two inputs we need a way to split them on load.
     * @param {string=} fullDomainName
     * @returns {string[]} - Array with two strings - app domain name and subdomain.
     * @private
     */
    GSLBService.prototype.splitDomainNameField_ = function(fullDomainName) {
        const gslb = this.getGSLB();

        return fqdnSplit(fullDomainName, gslb && gslb.getDNSDomainNames());
    };

    /**
     * Concatenates subdomain and domain name into one string.
     * @param {string} appDomainName
     * @param {string} subdomain
     * @returns {string}
     * @static
     * @private
     */
    GSLBService.domainNameBeforeSave_ = function({ appDomainName, subdomain }) {
        return fqdnJoin(appDomainName, subdomain);
    };

    return GSLBService;
}]);
