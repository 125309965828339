/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

function poolCollectionFactory(
    Collection,
    Pool,
) {
    /**
     * @class PoolCollection
     * @extends module:avi/dataModel.Collection
     * @desc Collection of {@link Pool pools}.
     * @author Alex Malitsky
     **/
    class PoolCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    includeName_: true,
                    join: 'application_persistence_profile_ref',
                    ...params,
                },
            };

            super(extendedArgs);
        }
    }

    const serverDefaultsOverride = {
        certificate: undefined,
        servers: [],
        networks: [],
        autoscale_policy: undefined,
        x509_cert: [],
        max_conn_rate_per_server: undefined,
    };

    //TODO we need "inventory" pools as often as regular ones hence need an easy way to use
    // different sets of DataSources
    const allDataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'InventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health',
                'runtime',
                'alert',
            ],
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'l4_server.avg_bandwidth',
                'l4_server.max_open_conns',
                'l4_server.avg_total_rtt',
                'l7_server.avg_complete_responses',
                'l7_server.avg_error_responses',
                'l7_server.pct_response_errors',
            ],
            dependsOn: 'config',
        },
    };

    Object.assign(PoolCollection.prototype, {
        objectName_: 'pool-inventory',
        itemClass_: Pool,
        windowElement_: 'app-pool-create',
        defaultDataSources_: 'inventory',
        isStatic_: false,
        serverDefaultsOverride_: serverDefaultsOverride,
        allDataSources_: allDataSources,
        searchFields_: [
            'name',
            'addr',
        ],
    });

    return PoolCollection;
}

poolCollectionFactory.$inject = [
    'Collection',
    'Pool',
];

angular.module('aviApp')
    .factory('PoolCollection', poolCollectionFactory);
