/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name wafExcludeList
 * @description
 *     Displays and configures entries on a WAF exclude list.
 * @param {ConfigItem} configItem - ConfigItem containing handleAddExcludeListEntry and
 *     handleRemoveExcludeListEntry methods.
 * @param {boolean} editMode - True when exclude list is being edited.
 */
class WafExcludeListController {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }

    /**
     * Handler for clicking "Add exclude list entry" button. Adds a new exclude list entry on
     * the item.
     */
    handleAddExcludeListEntry() {
        this.item.addExcludeListEntry();
    }

    /**
     * Handler for clicking the remove entry button. Removes an entry based on the index.
     * @param {index} index
     */
    handleRemoveExcludeListEntry(index) {
        this.item.removeExcludeListEntry(index);
    }
}

WafExcludeListController.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('wafExcludeList', {
    controller: WafExcludeListController,
    bindings: {
        item: '<configItem',
        editMode: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-exclude-list/' +
            'waf-exclude-list.html',
});
