/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').service('AlertDataTransformer', [
    'DataTransformer', 'itemAlertDataTransform',
    function(DataTransformer, itemAlertDataTransform) {
        /**
         * Base data transformer class
         * @constructor
         */
        const AlertDataTransformer = function() {
            AlertDataTransformer.superconstructor.apply(this, arguments);
        };

        avi.inherit(AlertDataTransformer, DataTransformer);
        AlertDataTransformer.prototype.name = 'alert';

        /**
         * Model is executing this function to ask the field to participate in a call group.
         * @param data - model data hash, used to figure out the earliest start time for the call
         *     and also may do other preparations.
         * @param groupCall - the object that the fields put their parameters in to participate
         *     in the request.
         */
        AlertDataTransformer.prototype.beforeCall = function(data, groupCall) {
            if (groupCall.inventory) {
                groupCall.inventory.include.alert = true;
            }

            return null;
        };

        /**
         * Model is executing this function when the call already happened and the purpose of
         * the call is to share the response data between the fields each field is taking
         * whatever data it needs to generate the right value.
         * @param data - model data hash, here used to update fields value
         * @param groupCall - the group call object, at this point it contains server
         *     response that the field want to access to generate value.
         */
        AlertDataTransformer.prototype.afterCall = function(data, groupCall) {
            // Just place the result into the data
            if (groupCall.inventory && groupCall.inventory.response) {
                data.alert = groupCall.inventory.response.alert;

                if (data.alert) {
                    itemAlertDataTransform(data.alert);
                }
            }
        };

        return AlertDataTransformer;
    }]);
