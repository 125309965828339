/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBVSCollection
 * @description
 *
 *     Syntax sugar to set GSLB specific headers through constructor params.
 *     Loads VSes of local as well as remote controllers.
 */
angular.module('GSLB.vantage.avi').factory('GSLBVSCollection', [
'VirtualServiceCollection', function(VirtualServiceCollection) {
    return class GSLBVSCollection extends VirtualServiceCollection {
        constructor(args = {}) {
            args.isStatic = true;
            args.objectName = 'virtualservice';

            super(args);

            const {
                gslbSiteId,
                gslbTenant,
            } = args;

            const headerParams = this.getParams('headers_') || {};

            if (gslbSiteId) {
                headerParams['X-Avi-Internal-GSLB'] = gslbSiteId;
            }

            if (gslbTenant) {
                headerParams['X-Avi-Tenant'] = gslbTenant;
            }

            this.setParams({ headers_: headerParams });
        }
    };
}]);
