/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const userCollectionFactory = (Collection, User) => {
    /**
     * @class
     * @name UserCollection
     * @extends module:avi/dataModel.Collection
     * @desc
     *     List of Users.
     *
     * @see {@link User}
     * @author Aravindh Nagarajan
     */
    class UserCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    include_activity: true,
                    ...params,
                },
            };

            super(extendedArgs);
        }
    }

    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
        'keystone-auth': {
            source: 'KeystoneIsEnabledCollDataSource',
            transformer: 'RevisedDataTransformer',
            transport: 'KeystoneIsEnabledDataTransport',
            fields: ['keystone'],
            dependsOn: 'config',
        },
    };

    Object.assign(UserCollection.prototype, {
        objectName_: 'user',
        itemClass_: User,
        windowElement_: 'user-modal',
        isStatic_: false,
        defaultDataSources_: ['list', 'keystone-auth'],
        allDataSources_: dataSources,
        serverDefaultsOverride_: {
            require_password_confirmation: false,
            is_active: true,
        },
    });

    return UserCollection;
};

userCollectionFactory.$inject = [
    'Collection',
    'User',
];

/**
 * @ngdoc service
 * @alias  UserCollection
 */
angular.module('aviApp').factory('UserCollection', userCollectionFactory);
