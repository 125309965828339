/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  schedulerCreate
 * @param {Scheduler} editable
 * @param {function} closeModal
 * @description
 *     Component used to create and edit a Scheduler item.
 */
class Controller {
    constructor(Regex, Schema, CloudConnectorUserCollection) {
        this.Regex = Regex;
        this.Schema = Schema;
        this.sshUserCollection = new CloudConnectorUserCollection();
    }
}

Controller.$inject = [
        'Regex',
        'Schema',
        'CloudConnectorUserCollection',
];

angular.module('aviApp').component('schedulerCreate', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/administration/system/scheduler-create.html',
});
