/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { Component, Input, Inject } from '@angular/core';
import './waf-policy-psm-rules-config.less';

/**
 * @ngdoc component
 * @name WafPolicyPsmRulesConfigComponent
 * @module waf/WafPolicyPsmRulesConfigComponent
 * @desc Component for configuring WAF PSM rules.
 * @param location - WAF Policy PSM location
 * @param preventEdit - True to prevent editing.
 * @param breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rules-config',
    templateUrl: './waf-policy-psm-rules-config.component.html',
})
export class WafPolicyPsmRulesConfigComponent {
    @Input() location: WafPSMLocationConfigItem;
    @Input() preventEdit: boolean;
    @Input() breadcrumbs: string[] = [];

    constructor(@Inject('AviModal') private AviModal: AviModalService) {}

    /**
     * Creates a new rule and opens the modal to edit it.
     */
    addRule(): void {
        this.editRule(this.location.createNewRule());
    }

    /**
     * Edits a WafPSMRule config item.
     */
    editRule(rule: WafPSMRuleConfigItem): void {
        const breadcrumb = `Location: ${this.location.getName()}`;

        this.AviModal.open('waf-policy-psm-rule-modal', {
            rule: rule.clone(),
            preventEdit: this.preventEdit,
            breadcrumbs: this.breadcrumbs.concat(breadcrumb),
            onSubmit: ['rule', (rule: WafPSMRuleConfigItem): void => {
                this.location.addRule(rule);
            }],
        });
    }
}
