/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBPoolMemberCollection
 * @description
 *
 *     Collection of GSLBPoolMembers presented by GSLBService inventory API. Updated list is not
 *     merged into existent but is rendered from scratch and all Items we had before that get
 *     removed.
 */
angular.module('aviApp').factory('GSLBPoolMemberCollection', [
'Collection', 'GSLBPoolMember',
function(Collection, GSLBPoolMember) {
    class GSLBPoolMemberCollection extends Collection {
        constructor(args) {
            super(args);

            if (args.gslbServiceId) {
                this.gslbServiceId = args.gslbServiceId;
            } else {
                console.warn(
                    'gslbServiceId is needed to instantiate GSLBPoolMemberCollection collection',
                );
            }
        }
    }

    angular.extend(GSLBPoolMemberCollection.prototype, {
        objectName_: 'gslbServiceMember',
        itemClass_: GSLBPoolMember,
        allDataSources_: {
            list: {
                source: 'GSLBServicePoolMembersInventoryCollDataSource',
                transformer: 'GSLBServicePoolMembersInventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config', 'runtime'],
            },
        },
        isStatic_: false,
    });

    return GSLBPoolMemberCollection;
}]);
