/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @type {Function}
 * @name parseURL
 * @param {string} url - Valid URL string, may or may not contain a protocol.
 * @returns {Object}
 * @description
 *
 *      Parse a URL string to a URL object;
 *      this object contains the necessary URI components as its properties:
 *      protocol, host, path, query, hash.
 *      URI components can be accessed by refering to these properties.
 */
const defaultProtocol = 'https';

/**
 * Add protocol field to URL string.
 * Use the protocol in URL string if existed;
 * if no protocol field is existed in the URL string,
 * use 'https' as its default protocol;
 * @param {string} url - Valid URL string, may or may not contain a protocol.
 * @returns {string}
 * @inner
 */
const normalizeURL = url => {
    // if there is a protocol field in the URL string
    if (url.indexOf('://') !== -1) {
        return url;
    }

    return `${defaultProtocol}://${url}`;
};

const parseURL = url => {
    const
        aTag = document.createElement('a'),
        normalizedURL = normalizeURL(url);

    aTag.href = normalizedURL;

    const { protocol: proto, hostname, pathname, search, hash } = aTag;

    return {
        protocol: proto.split(':')[0],
        host: hostname,
        path: pathname,
        query: search,
        hash,
    };
};

angular.module('aviApp').constant('parseURL', parseURL);
