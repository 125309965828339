/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const ipAddrMatchConfigItemFactory = (MessageItem, schemaService, getSubnetString) => {
    /**
     * @alias module:config-item/IpAddrMatchConfigItem
     * @private
     */
    class IpAddrMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'IpAddrMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchOperationLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchOperation');
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                match_criteria: 'IS_IN',
                group_refs: [],
            };
        }

        /**
         * Returns the default values for this.config._flatProps.
         * @returns {Object}
         */
        get defaultFlatProps_() {
            return {
                ranges: [],
                prefixes: [],
                addrs: [],
            };
        }

        /**
         * Returns the label for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.matchOperationLabelsHash_[matchCriteria];
        }

        /**
         * Returns an array of all addresses in this.config.
         * @returns {string[]}
         */
        get addresses() {
            const { addrs, ranges, prefixes } = this.config;
            const repeatedConfigItems = [addrs, ranges, prefixes];

            return repeatedConfigItems.reduce((acc, base) => {
                const labels = base && base.config.map(configItem => configItem.toString()) || [];

                return acc.concat(labels);
            }, []);
        }

        /**
         * Returns an array of all addresses in this.config._flatProps.
         * @returns {string[]}
         */
        get flatPropsAddresses() {
            const { addrs = [], ranges = [], prefixes = [] } = this.config._flatProps;
            const addrStrings = addrs.map(addr => addr.addr);
            const rangeStrings = ranges.map(range => `${range.begin.addr} - ${range.end.addr}`);
            const prefixStrings = prefixes.map(prefix => getSubnetString(prefix));

            return addrStrings.concat(rangeStrings).concat(prefixStrings);
        }

        /**
         * Returns an array of names of the group_refs.
         * @returns {string[]}
         */
        get groupNames() {
            const { group_refs: groupRefs = [] } = this.config;

            return groupRefs.map(ref => ref.name());
        }

        /**
         * Returns a string representation of the _flatProps config data.
         * @returns {string}
         */
        flatDataToString() {
            const values = this.flatPropsAddresses.concat(this.groupNames).join(', ');

            return `${this.matchCriteriaLabel} ${values}`;
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            const values = this.addresses.concat(this.groupNames).join(', ');

            return `${this.matchCriteriaLabel} ${values}`;
        }

        /**
         * @override
         */
        modifyConfigDataAfterLoad_() {
            this.config._flatProps = this.config._flatProps || this.defaultFlatProps_;

            // Needed to use with ipOrGroupList directive, as it expects flat data.
            // Rewrite ipOrGroupList to work with a ConfigItem.
            const repeatedConfigItems = _.pick(this.config, 'ranges', 'prefixes', 'addrs');

            Object.keys(repeatedConfigItems).forEach(field => {
                const repeatedConfigItem = repeatedConfigItems[field];

                this.config._flatProps[field] = repeatedConfigItem.getDataToSave() || [];
            });
        }

        /**
         * @override
         */
        modifyConfigDataBeforeSave_() {
            const { _flatProps } = this.config;

            Object.keys(_flatProps).forEach(field => {
                const repeatedConfigItem = this.config[field];

                repeatedConfigItem.removeAll();
                _flatProps[field].forEach(config => repeatedConfigItem.add(config));
            });

            delete this.config._flatProps;
        }
    }

    return IpAddrMatchConfigItem;
};

ipAddrMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
    'getSubnetString',
];

/**
 * @ngdoc factory
 * @name  IpAddrMatchConfigItem
 * @description  IpAddrMatch ConfigItem class.
 * @module config-item/IpAddrMatchConfigItem
 * @author alextsg
 */
angular.module('aviApp').factory('IpAddrMatchConfigItem', ipAddrMatchConfigItemFactory);
