/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../waf-log-entry.less';
import '../waf-logs-list.less';

/**
 * @alias module:waf/nsmLogsList
 * @private
 */
class NsmLogsListComponent {
    $onInit() {
        this.ruleGroupsHash = this.getRuleGroupsHash_();
    }

    /**
     * Creates a hash of rule group names to the rule group's rules.
     * @protected
     * @returns {Object}
     */
    getRuleGroupsHash_() {
        const { logs = [] } = this;

        return logs.reduce(NsmLogsListComponent.ruleGroupReducer_, {});
    }

    /**
     * Reducer to populate a hash with a ruleGroup's rules.
     * @param {Object} hash - Object to populate rules with.
     * @param {WafRule} rule
     * @protected
     * @returns {Object}
     */
    static ruleGroupReducer_(hash, rule) {
        const { rule_group: ruleGroup } = rule;

        hash[ruleGroup] = hash[ruleGroup] || [];
        hash[ruleGroup].push(rule);

        return hash;
    }
}

/**
 * @ngdoc component
 * @name  nsmLogsList
 * @description Component for a list of NSM Logs.
 * @param {Object[]} logs - List of NSM Logs.
 * @param {WafPolicy} [wafPolicy=] - Optional because the Virtual Service may no longer have the
 *     WafPolicy.
 * @param {string} clientIp - Client IP of the log.
 * @param {string} uriPath - URI Path of the log.
 * @module waf/nsmLogsList
 * @author alextsg
 */
angular.module('logs.vantage.avi').component('nsmLogsList', {
    bindings: {
        logs: '<',
        wafPolicy: '<?',
        clientIp: '@',
        uriPath: '@',
    },
    controller: NsmLogsListComponent,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'nsm-logs-list/nsm-logs-list.component.html',
});
