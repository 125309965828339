/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('StaticRouteCollection', ['$q', 'Collection', 'StaticRoute',
    function($q, Collection, StaticRoute) {
        function StaticRouteCollection(args) {
            StaticRouteCollection.superconstructor.call(this, args);
            this.vrf = args && args.vrf || 'global';
            this.bind('collItemSave', function() {
                this.load();
            }.bind(this));
        }

        avi.inherit(StaticRouteCollection, Collection);

        StaticRouteCollection.prototype.objectName_ = 'vrfcontext';
        StaticRouteCollection.prototype.windowElement_ = 'infra-staticroute-create';
        StaticRouteCollection.prototype.itemClass_ = StaticRoute;

        StaticRouteCollection.prototype.defaultDataSources_ = 'list';
        StaticRouteCollection.prototype.allDataSources_ = {
            list: {
                source: 'ListCollDataSource',
                transformer: 'StaticRouteListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        StaticRouteCollection.prototype.getNewIndex = function() {
            let res = 1;

            if (this.getNumberOfItems()) {
            // Get Route holding maximum route_id
                const row = _.max(this.items, function(row) {
                    return +row.data.config.route_id;
                });

                if (row) {
                    res = parseInt(row.data.config.route_id, 10) + 1;
                }
            }

            return `${res}`;
        };

        StaticRouteCollection.prototype.save = function(route) {
            const data = _.pluck(this.items, 'data');
            const entireObject = angular.copy(this.entireObject);
            const item = _.find(data, function(item) {
                return item.config.route_id == route.route_id;
            });

            if (item) { // update the entry
                angular.copy(route, item.config);
            } else { // push new entry
                data.push({ config: route });
            }

            entireObject.static_routes = _.pluck(data, 'config');

            return this.request('put', entireObject.url, entireObject).then((function() {
                this.entireObject.static_routes = data;

                return route;
            }).bind(this));
        };

        StaticRouteCollection.prototype.drop = function(routeId) {
            if (routeId) {
                this.entireObject.static_routes =
                _.reject(this.entireObject.static_routes, function(route) {
                    return route.route_id === routeId;
                });
            }

            return this.request('put', this.entireObject.url, this.entireObject)
                .then((function() {
                    return this.load();
                }).bind(this));
        };

        return StaticRouteCollection;
    }]);

angular.module('aviApp').factory('StaticRoute', ['Item', function(Item) {
    function StaticRoute(oArgs) {
        StaticRoute.superconstructor.call(this, oArgs);
    }

    avi.inherit(StaticRoute, Item);

    StaticRoute.prototype.objectName = 'vrfcontext';
    StaticRoute.prototype.windowElement = 'infra-staticroute-create';
    StaticRoute.prototype.loadOnEdit = false;

    StaticRoute.prototype.getIdFromData_ = function(data) {
        return data && data.config && data.config.route_id || '';
    };

    /**
     * Called on ng-change of VRF Context collection dropdown. Sets the VRF Context collection so we
     * know where to put the Static Route.
     * @param  {Boolean} onClear - If called by onclear, set collection to 'global'.
     */
    StaticRoute.prototype.loadVRFContextCollection = function(onClear) {
        const self = this;

        // Default collection is with VRFContext === 'global'. When dropdown value is changed or
        // cleared, we load the new collection.
        this.collection.vrf = onClear ? 'global' : this.data.config.vrfContext.name();

        this.busy = true;
        this.collection.load().finally(function() {
            self.busy = false;
        });
    };

    StaticRoute.prototype.dataToSave = function() {
        const data = angular.copy(this.data.config);

        if (!data.route_id) {
            data.route_id = this.collection.getNewIndex();
            this.data.config.route_id = data.route_id; // update the item also
        }

        return data;
    };

    StaticRoute.prototype.saveRequest = function() {
        if (!this.collection) {
            return;
        }

        return this.collection.save(this.dataToSave());
    };

    StaticRoute.prototype.drop = function() {
        if (!this.collection) {
            return;
        }

        return this.collection.drop(this.data.config.route_id);
    };

    return StaticRoute;
}]);
