/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServiceCollection
 * @author Alex Malitsky
 */
angular.module('GSLB.vantage.avi').factory('GSLBServiceCollection', [
'Auth', 'GSLBService', 'Collection', 'systemInfoService',
function(Auth, GSLBService, Collection, systemInfo) {
    class GSLBServiceCollection extends Collection {
        constructor(args) {
            super(args);

            //expect loaded GSLB item here
            if (angular.isObject(args.gslb)) {
                this.gslb = args.gslb;
            }
        }

        /** @override */
        isCreatable() {
            return systemInfo.localSiteIsGSLBLeader() &&
                Auth.isAllowed('gslb') &&
                super.isCreatable();
        }
    }

    angular.extend(GSLBServiceCollection.prototype, {
        objectName_: 'gslbservice-inventory',
        itemClass_: GSLBService,
        allDataSources_: {
            list: {
                source: 'InventoryCollDataSource',
                transformer: 'InventoryDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config', 'runtime', 'health'],
            },
        },
        serverDefaultsOverride_: {
            groups: [],
            health_monitor_refs: [],
            domain_names: [],
        },
        isStatic_: false,
    });

    return GSLBServiceCollection;
}]);
