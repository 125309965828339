/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name KeystoneIsEnabledDataTransport
 * @description
 *
 *    This kinda weird data transport is used to make an API call through {@link Auth} service to
 *    get some data related to objects which use this transport. Right way to do it would be
 *    performing an actual API call here and making corresponding response transformations in
 *    CollDataSource or DataTransformer.
 *
 */

angular.module('aviApp').factory('KeystoneIsEnabledDataTransport', ['IdentityDataTransport', 'Auth',
function(IdentityDataTransport, Auth) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends IdentityDataTransport
     */
    function KeystoneIsEnabledDataTransport(args) {
        KeystoneIsEnabledDataTransport.superconstructor.call(this, args);
    }

    avi.inherit(KeystoneIsEnabledDataTransport, IdentityDataTransport);

    /** @override */
    KeystoneIsEnabledDataTransport.prototype.load = function() {
        return Auth.getKeystoneAuth();
    };

    return KeystoneIsEnabledDataTransport;
}]);
