/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name aviFormItem
 * @restrict A
 * @param {*} ngLabel
 * @deprecated
 * This directive has been Deprecated.We have created new avi form item component.
 * @see {@link module:avi/component-kit.aviFormItem}
 */
angular.module('aviApp').directive('aviFormItem', ['$compile', '$timeout', '$parse',
function($compile, $timeout, $parse) {
    function link(scope, element, attrs, ctrl) {
        // wrap element in a new parent <div> container so we can apply the right styles.
        const
            className = attrs.className || '',
            wrapper = `<div class="control-group ${className}"><div class="controls"></div></div>`;

        element.wrap(wrapper);

        const scopik = scope.$new();

        scopik.$attrs = attrs;

        //otherwise plain text attribute value is passed which evaluates to true
        if (!_.isUndefined(attrs['ngRequired']) && attrs['ngRequired']) {
            scopik.$attrs['ngRequired'] = $parse(attrs['ngRequired']);
        }

        const labelTemplate = `<label for="${element[0].id}" ` +
            `avi-form-label="${attrs.aviFormItem}">` +
            `<span ng-class="{'label-required': $attrs.required || $attrs.ngRequired()}">${
                attrs.ngLabel || ''}</span></label>`;

        const compiledLabelTemplate = $compile(labelTemplate)(scopik);

        // Create a <label> and insert before the <input> field.
        element.parent().before(compiledLabelTemplate);

        if (attrs.ngLabel) {
            attrs.$set('name', attrs.name || attrs.ngLabel);
        }

        // Check for changes
        const findAttr = function(obj, fieldName) {
            const a = fieldName.split('.');

            if (obj && a && a[0]) {
                return findAttr(obj[a.splice(0, 1)], a.join('.'));
            }

            return obj;
        };

        const checkForChanges = function() {
            const editable = scope.editable || scope.$parent.editable ||
                scope.$parent.$parent.editable;
            let propName = attrs.showChanged;

            if (!propName && attrs.ngModel) {
                propName = attrs.ngModel.replace('editable.data.config.', '');
            }

            if (propName && editable && editable.data &&
                findAttr(editable.data.config, propName) !== findAttr(editable.backup, propName)) {
                element.addClass('changed');
            } else {
                element.removeClass('changed');
            }
        };

        const render = function() {
            element.parent().parent()
                .find('.required')
                .removeClass('valid')
                .addClass(ctrl.$valid ? 'valid' : '');
        };

        /**
         * Watchers and binds.
         */
        element.on('focus blur keyup change', function() {
            $timeout(render);
        });

        element.on('focus blur keyup click change', checkForChanges);

        scope.$watch(attrs.ngModel, checkForChanges);

        /**
         * On initialization.
         */
        $timeout(render);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
