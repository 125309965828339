/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').controller('VirtualServiceMultipleNetworksResolutionController', [
'$scope', '$controller',
function($scope, $controller) {
    //$scope.NetworkResolution comes from NetworkResolutionController.
    $controller('NetworkResolutionController', { $scope });
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.init = function() {
        $scope.error = null;
        $scope.data = {
            loading: true,
        };
        $scope.busy = true;

        const slug = $scope.editable.data.config.url.slug();

        $scope.NetworkResolution.getRuntime(slug).then(function() {
            $scope.data.vs_runtime = $scope.NetworkResolution.vs_runtime;

            const firstNetwork = $scope.data.vs_runtime.vs_placement_resolution_info.networks[0];

            $scope.selectNetwork(firstNetwork);
        }).finally(function() {
            $scope.data.loading = false;
            $scope.busy = false;
            $scope.error = $scope.NetworkResolution.error;
        });
    };

    /*---------- When a network has been selected ----------*/
    /**
     * Called when user selects network from network dropdown. Loads the network and populates list
     * of subnets and networkruntime.
     */
    $scope.selectNetwork = function(nw) {
        $scope.data.loading = true;
        $scope.busy = true;
        // Choose network
        $scope.data.discovered_network = nw;
        $scope.data.network_ref = nw.network_ref;

        // Get instance of network
        const networkSlug = $scope.data.network_ref.slug();

        // Load it and get network subnet to be able to edit it's dhcp and pool settings
        $scope.NetworkResolution.loadNetwork(networkSlug).then(function() {
            $scope.network = $scope.NetworkResolution.network;

            return $scope.NetworkResolution.getNetworkRuntime($scope.network.data.config.uuid);
        }).then(function() {
            $scope.data.networkruntime = $scope.NetworkResolution.networkruntime;
            getSubnets($scope.data.discovered_network);
            $scope.setSelectedSubnet();
        }).finally(function() {
            $scope.error = $scope.NetworkResolution.error;
            $scope.data.loading = false;
            $scope.busy = false;
        });
    };

    /**
     * Gets a list of all subnets from the network to be displayed in the dropdown subnet field.
     */
    function getSubnets(network) {
        $scope.NetworkResolution.getSubnetsMultipleNetworks(network);
        $scope.data.subnetsList = $scope.NetworkResolution.subnetsList;
    }
    /*------------------------------------------------------*/

    /**
     * Sets selected_subnet. Called when network has changed and  when user toggles between adding
     * manual subnet or choosing existing one.
     */
    $scope.setSelectedSubnet = function() {
        const { subnetsList } = $scope.data;

        $scope.NetworkResolution.setSelectedSubnet(false, subnetsList);
        $scope.data.selected_subnet = $scope.NetworkResolution.selected_subnet;
        $scope.selectSubnet();
    };

    /**
     * Called when user hits Save button. Saves VS along with selected network.
     */
    $scope.submit = function() {
        $scope.busy = true;
        $scope.saveVS().then(function() {
            return $scope.saveNetwork();
        }).then(function() {
            return $scope.editable.disableAndEnable();
        }).then(function() {
            return $scope.editable.checkForIssues();
        })
            .then(function() {
                $scope.editable.dismiss(true);
            })
            .catch(function(rsp) {
                $scope.error = rsp.data.error;
            })
            .finally(function() {
                $scope.busy = false;
            });
    };
}]);
