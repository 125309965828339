/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').directive('aviTooltip', ['popoverFactory', function(PopoverFactory) {
    return {
        restrict: 'A',
        link(scope, elm, attrs) {
            const PopoverClass = function(config) {
                PopoverClass.superconstructor.call(this, config);
            };

            avi.inherit(PopoverClass, PopoverFactory);

            const popover = new PopoverClass({
                className: 'aviTooltip',
                html: attrs['aviTooltip'],
                position: 'top',
                carat: true,
                repositioning: true,
                removeAfterHide: true,
                hide: {
                    mouseOut: true,
                    parentMouseOut: true,
                },
            });

            elm.on('mouseover', function() {
                popover.show(elm);
            });

            scope.$on('$destroy', function() {
                popover.remove();
            });

            attrs.$observe('aviTooltip', newValue => {
                popover.config.html = newValue;
            });
        },
    };
}]);
