/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name httpRespCode
 * @restrict A
 * @description Parses and formats comma-separated string of HTTP Response Codes.
 */
angular.module('aviApp').directive('httpRespCode', ['Schema', 'Regex', function(Schema, Regex) {
    const enumHash = buildEnumHash();
    const existingValues = {};

    let min,
        max;

    /**
     * Builds hash of RespCodeBlocks
     * @return {Object} - Hash of code block values to enum values.
     */
    function buildEnumHash() {
        const codeBlockHash = Schema.enums.HttpReselectRespCodeBlock.values;

        return _.reduce(codeBlockHash, function(hash, val, key) {
            hash[val.options.text.value] = key;

            return hash;
        }, {});
    }

    function link(scope, elm, attr, ngModel) {
        min = attr.min ? +attr.min : 101;
        max = attr.max ? +attr.max : 599;

        ngModel.$parsers.push(parseRespCodes);
        ngModel.$formatters.push(formatRespCodes);
    }

    /**
     * Parses string into an object of response code objects.
     * @param  {string} val - Comma-separated response codes, code ranges, or code blocks.
     * @return {Object} Object containing ranges, codes, and resp_code_block properties.
     */
    function parseRespCodes(val) {
        if (!val) {
            return '';
        }

        const codes = val.replace(/\s+/g, '').split(',');
        const outputHash = {};

        const check = _.any(codes, function(code) {
            if (code === '') {
                return;
            }

            const parsedValue = respCodeParser(code);

            if (parsedValue === null) {
                return true;
            } else {
                populateOutputHash(parsedValue, outputHash);
            }
        });

        return check ? undefined : outputHash;
    }

    /**
     * Populates the output hash.
     * @param  {Object} code - Response code object.
     * @param  {Object} hash - Output hash.
     */
    function populateOutputHash(code, hash) {
        hash[code.type] = hash[code.type] || [];
        hash[code.type].push(code.value);
    }

    /**
     * Parses each comma-separated part of the input string and returns value based on whether it's
     * a single code, code-range, or code block.
     * @param  {string} code - Response code string.
     * @return {Object|null} - Returns object if valid response code string, null otherwise.
     */
    function respCodeParser(code) {
        // If matches, code is either a single code or a range.
        if (Regex.listOfStatusCodeOrRanges.test(code)) {
            // Range
            if (/[-]/.test(code)) {
                const
                    range = code.split('-'),
                    begin = +range[0],
                    end = +range[1];

                if (begin >= min && end <= max &&
                    !(begin in existingValues) && !(end in existingValues)) {
                    existingValues[begin] = true;
                    existingValues[end] = true;

                    return {
                        type: 'ranges',
                        value: {
                            begin: +range[0],
                            end: +range[1],
                        },
                    };
                } else {
                    return null;
                }
            // Single code
            } else if (+code >= min && +code <= max && !(+code in existingValues)) {
                existingValues[+code] = true;

                return {
                    type: 'codes',
                    value: +code,
                };
            } else {
                return null;
            }
        // Code block
        } else if (code.toUpperCase() in enumHash && !(code.toUpperCase() in existingValues)) {
            existingValues[code.toUpperCase()] = true;

            return {
                type: 'resp_code_block',
                value: enumHash[code.toUpperCase()],
            };
        } else {
            return null;
        }
    }

    /**
     * Formats response code object into string of comma-separated status codes.
     * @param  {Object} val - Object containing ranges, codes, and resp_code_block properties.
     * @return {string} Comma-separated response codes, code ranges, or code blocks.
     */
    function formatRespCodes(val) {
        if (_.isUndefined(val) || _.size(val) === 0) {
            return '';
        }

        const output = [];

        if (Array.isArray(val.codes)) {
            val.codes.forEach(function(code) {
                output.push(code);
            });
        }

        if (Array.isArray(val.ranges)) {
            val.ranges.forEach(function(range) {
                output.push(`${range.begin}-${range.end}`);
            });
        }

        if (Array.isArray(val.resp_code_block)) {
            val.resp_code_block.forEach(function(block) {
                output.push(block.enumeration('HTTP_RSP_').toUpperCase());
            });
        }

        return output.join(', ');
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
