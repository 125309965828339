/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name InventoryMapDataTransformer
 * @description
 *
 *     We get inventory data for the whole tree vs > ses, pools > servers, networks. Need to
 *     split config/runtime/hs/alert properties of each Item from nested objects and add couple of
 *     additional properties such as vs.serversNum and vs.poolsNum keeping quantities of nested
 *     Items.
 *
 */
angular.module('aviApp').factory('InventoryMapDataTransformer', [
'InventoryDataTransformer', 'itemAlertDataTransform', 'FaultService',
function(InventoryDataTransformer, itemAlertDataTransform, FaultService) {
    function InventoryMapDataTransformer(args) {
        InventoryMapDataTransformer.superconstructor.call(this, args);
    }

    avi.inherit(InventoryMapDataTransformer, InventoryDataTransformer);

    /**
     * Modifies returned server config to be consistent with Server class.
     * @param {Server#data} serverData
     * @param {Server#id} serverId
     * @param {Pool#id} poolId
     * @returns {Server#data}
     * @protected
     */
    InventoryMapDataTransformer.prototype.transformServerData_ =
        function(serverData, serverId, poolId) {
            serverData.config.uuid = serverId;
            serverData.config.poolId = poolId;

            return serverData;
        };

    /**
     * Provides consistent network configuration for inventory map.
     * @param {Server#id[]} serverIdsList
     * @param {string} netName - Network name.
     * @param {{string: Server#data}} poolServersHash - Keys are Server#id.
     * @returns {Network#data}
     * @protected
     */
    InventoryMapDataTransformer.prototype.transformNetworkData_ =
        function(serverIdsList, netName, poolServersHash) {
            return {
                config: { name: netName },
                servers: serverIdsList.map(function(serverId) {
                    return poolServersHash[serverId];
                }),
            };
        };

    /**
     * Modifies pool' data object provided by backend inventory map API. Has a few additional
     * properties, specific for inventory map, such as lists of servers and networks.
     * @param {Object} poolData
     * @param {VirtualService#id} vsId - VS id this Pool belongs to.
     * @param {Pool#id=} parentPoolId - For backup and a\b pool we have a child/parent relation.
     * @param {string=} type -
     * @returns {Pool#data}
     * @protected
     */
    InventoryMapDataTransformer.prototype.transformPoolData_ =
        function(poolData, vsId, parentPoolId, type) {
            poolData.poolsNum = 0;
            poolData.serversNum = 0;
            poolData.virtualservices = [vsId];

            itemAlertDataTransform(poolData.alert);

            if (!_.isUndefined(parentPoolId)) {
                poolData.parentPoolId = parentPoolId;
                poolData.type = type;
            }

            //if no networks we show servers after pool's list
            if ('servers' in poolData) {
                _.each(poolData.servers, function(serverData, serverId, hash) {
                    poolData.serversNum++;
                    hash[serverId] =
                        this.transformServerData_(serverData, serverId, poolData.config.uuid);
                }, this);
            }

            if ('networks' in poolData) {
                poolData.networks = _.map(poolData.networks, function(serverIdsList, netName) {
                    return this.transformNetworkData_(serverIdsList, netName, poolData.servers);
                }, this);
            }

            return poolData;
        };

    /** @override */
    InventoryMapDataTransformer.prototype.processResponse = function(resp, request) {
        let seHash = {};

        resp = this.responseListOffsetControl_(resp, request);

        if (Array.isArray(resp.data.serviceengines)) {
            seHash = resp.data.serviceengines.reduce(function(hash, se) {
                itemAlertDataTransform(se.alert);
                hash[se.config.uuid] = se;

                return hash;
            }, {});
        }

        if (Array.isArray(resp.data.results)) {
            resp.data.results = resp.data.results.map(function(vs) {
                const pools = [];

                vs.serversNum = 0;
                itemAlertDataTransform(vs.alert);

                vs.serviceengines = vs.serviceengines.map(function(seId) {
                    return seHash[seId];
                });

                vs.pools.forEach(function(poolData) {
                    poolData = this.transformPoolData_(poolData, vs.config.uuid);

                    pools.push(poolData);

                    vs.serversNum += poolData.serversNum;

                    //`child` pools should follow their parents
                    ['ab_pools', 'backup_pools'].forEach(function(fieldName) {
                        if (fieldName in poolData && !_.isEmpty(poolData[fieldName])) {
                            _.each(poolData[fieldName], function(childPoolData) {
                                childPoolData = this.transformPoolData_(
                                    childPoolData, vs.config.uuid, poolData.config.uuid,
                                    fieldName === 'ab_pools' ? 'ab-child' : 'backup',
                                );

                                poolData.poolsNum++;
                                poolData.serversNum += childPoolData.serversNum;

                                pools.push(childPoolData);
                            }, this);

                            if (fieldName === 'ab_pools') {
                                poolData.type = 'ab-parent';
                            }
                        }

                        poolData[fieldName] = undefined;
                    }, this);
                }, this);

                vs.faults = FaultService.parseFaults(vs.faults);
                vs.pools = pools;
                vs.poolsNum = pools.length;

                return vs;
            }, this);
        }

        return resp;
    };

    return InventoryMapDataTransformer;
}]);
