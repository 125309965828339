/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('PoolGroupCollection', ['Collection', 'PoolGroup',
function(Collection, PoolGroup) {
    class PoolGroupCollection extends Collection {
        /**
         * Returns a hash of Pool ids from the items array of a PoolGroup collection or from the
         * inventoryMap API.
         * @param  {PoolGroup[]|PoolGroup.data[]} items - An array of PoolGroup items or PoolGroup
         *     data properties.
         * @return {Object}
         */
        static getPoolIdsHash(items) {
            return items.reduce((acc, item) => {
                const members = 'members' in item ? item.members : item.data.config.members;

                if (members && members.length) {
                    members.forEach(member => {
                        acc[member.pool_ref.slug()] = true;
                    });
                }

                return acc;
            }, {});
        }
    }

    angular.extend(PoolGroupCollection.prototype, {
        objectName_: 'poolgroup-inventory',
        itemClass_: PoolGroup,
        windowElement_: 'pool-group-create',
    });

    return PoolGroupCollection;
}]);
