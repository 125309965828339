/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ndoc service
 * @name IdentityDataTransport
 * @author Alex Malitsky
 * @description
 *
 *     This is a mock of DataTransport - it returns a request object as a backend response.
 *     Needed to follow the pattern when we don't have a backend API for some kind of data.
 *
 *     For now used by Pool Servers - to get the list of them from the Pool `config` data.
 */
angular.module('aviApp').factory('IdentityDataTransport', [
'$q', 'Base', 'aviInherit',
function($q, Base, aviInherit) {
    /**
     * @param {Object=} args
     * @constructor
     * @extends Base
     */
    function IdentityDataTransport(args) {
        IdentityDataTransport.superconstructor.call(this, args);
    }

    aviInherit(IdentityDataTransport, Base);

    /**
     * Gets a request object and returns it back wrapped in a promise doing some basic
     * transformations (when applicable). The only exposed method.
     * @param {*=} params
     * @returns {ng.$q.promise}
     * @abstract
     */
    IdentityDataTransport.prototype.load = function(params) {
        return $q.when(
            this.processResponse_(this.getRequestObject_(params)),
        );
    };

    /**
     * Transforms request parameters object into specific for particular DataTransport format.
     * @param {*=} params
     * @returns {*}
     * @private
     * @abstract
     */
    IdentityDataTransport.prototype.getRequestObject_ = function(params) {
        return params;
    };

    /**
     * Makes very basic and general transformations over the backend response before passing it
     * further.
     * @param {*} resp
     * @returns {*}
     * @private
     * @abstract
     */
    IdentityDataTransport.prototype.processResponse_ = angular.identity;

    /**
     * Destroys the transport by cancelling all pending (if any) requests.
     * @public
     * @abstract
     */
    IdentityDataTransport.prototype.destroy = angular.noop;

    return IdentityDataTransport;
}]);
