/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name gatewayMonitorList
 * @description Component for the Gateway Monitor List page.
 */
class GatewayMonitorListController {
    constructor($scope, CRUDGridConfig, GatewayMonitorCollection, infraCloudState) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._GatewayMonitorCollection_ = GatewayMonitorCollection;
        this._infraCloudState = infraCloudState;

        this.handleVrfCollectionChange = this.handleVrfCollectionChange.bind(this);
        infraCloudState.on('vrfContextCollectionChange', this.handleVrfCollectionChange);
    }

    $onInit() {
        this.gridConfigs = [];
        this.vrfContextCollection = this._infraCloudState.getVrfContextCollection();
        this.loadVRFCollection_();
    }

    /**
     * Sets the gridConfigs to be displayed on the page, as there will be one grid for every VRF
     * context.
     */
    setGridConfigs() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.vrfContextCollection.items.forEach(vrf => {
            const name = vrf.getName();

            if (name !== 'management') {
                const collection = new this._GatewayMonitorCollection_({
                    params: {
                        'cloud_ref.uuid': slug,
                    },
                    defaults: {
                        cloud_ref: cloudRef,
                    },
                    vrf: name,
                    bind: {
                        collItemSaveSuccess: () => this.loadGatewayMonitorCollections(),
                    },
                });

                this.gridConfigs.push({
                    vrf: name,
                    gridConfig: this.buildGridConfig(collection),
                });
            }
        });
    }

    /**
     * Builds gridConfig, since multiple are needed when custom VRFs exist.
     * @param  {GatewayMonitorCollection} collection.
     * @return {Object} - gridConfig.
     */
    buildGridConfig(collection) {
        const config = {
            collection,
            fields: [{
                name: 'data.config.gateway_ip.addr',
                title: 'IP Address',
            }],
            layout: {
                includeCreateButton: true,
            },
        };

        return this._CRUDGridConfig(config);
    }

    /**
     * Destroys each collection in the list of grid configs before resetting this.gridConfigs.
     */
    resetGridConfigs() {
        this.gridConfigs.forEach(config => config.gridConfig.collection.destroy());
        this.gridConfigs.length = 0;
    }

    /**
     * Loads each collection in the gridConfigs.
     */
    loadGatewayMonitorCollections() {
        this.gridConfigs.forEach(config => config.gridConfig.collection.load());
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Resets and loads everything based on the
     * new VRF Context collection.
     */
    handleVrfCollectionChange() {
        this.resetGridConfigs();
        this.vrfContextCollection = this._infraCloudState.getVrfContextCollection();
        this.loadVRFCollection_();
        this.loadGatewayMonitorCollections();
    }

    /**
     * Since collection is carried over all tabs of Routing section we want to make sure it
     * loads what we need it to load for the component. Also kicks off page rendering.
     * @protected
     */
    loadVRFCollection_() {
        this.vrfContextCollection.updateViewportSize(200, true, true);
        this.vrfContextCollection.load()
            .then(() => this.setGridConfigs());
    }

    $onDestroy() {
        const {
            _infraCloudState: infraCloudState,
        } = this;

        this.resetGridConfigs();

        infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }
}

GatewayMonitorListController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'GatewayMonitorCollection',
    'infraCloudState',
];

angular.module('aviApp').component('gatewayMonitorList', {
    controller: GatewayMonitorListController,
    templateUrl: 'src/components/pages/infrastructure/gateway-monitor-list/' +
            'gateway-monitor-list.html',
});
