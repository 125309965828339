/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ndgoc service
 * @name EndToEndTimeMetric
 * @description
 *
 *     Has a few series for VS, Pool and Server EndToEndTiming charts and infoBlocks. List of the
 *     series depends on VS type and item type. Other then that is an ordinary CollMetric with
 *     totalSeries.
 */
angular.module('aviApp').service('EndToEndTimeMetric', [
'CollMetric',
function(CollMetric) {
    const
        clientRTTSeries = 'l4_client.avg_total_rtt',
        serverRTTSeries = 'l4_server.avg_total_rtt',
        appRespTimeSeries = 'l7_server.avg_application_response_time',
        dataTransTimeSeries = 'l7_client.avg_client_data_transfer_time';

    const allTimingTiles = [{
        id: 'clientRttIcon',
        sName: clientRTTSeries,
        icon: 'client',
        iconTitle: 'Client',
    }, {
        id: 'clientRtt',
        sName: clientRTTSeries,
        title: 'Client RTT',
    }, {
        id: 'serverRttIcon',
        sName: serverRTTSeries,
        icon: 'lb',
        iconTitle: 'LB',
    }, {
        id: 'serverRtt',
        sName: serverRTTSeries,
        title: 'Server RTT',
    }, {
        id: 'appRespTimeIcon',
        sName: appRespTimeSeries,
        icon: 'server',
        iconTitle: 'Server',
        highlighted: true,
    }, {
        id: 'appRespTime',
        sName: appRespTimeSeries,
        title: 'App Response',
        highlighted: true,
    }, {
        id: 'appIcon',
        sName: appRespTimeSeries,
        icon: 'app',
        iconTitle: 'App',
        highlighted: true,
    }, {
        id: 'dataTransfer',
        sName: dataTransTimeSeries,
        title: 'Data Transfer',
    }];

    /**
     * @class
     * @extends CollMetric
     **/
    return class EndToEndTimeMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;

            super(args);

            const
                sList = [],
                { item } = this;

            let vsAppType;

            switch (item.getItemType()) {
                case 'virtualservice':
                    vsAppType = item.appType();

                    //  e2e is not applicable for DNS VS with UDP service only
                    sList.push(
                        clientRTTSeries,
                        serverRTTSeries,
                    );

                    if (vsAppType !== 'l4' && vsAppType !== 'ssl' && vsAppType !== 'dns') {
                        sList.push(
                            appRespTimeSeries,
                            dataTransTimeSeries,
                        );
                    }

                    break;

                case 'pool':
                case 'server':
                    sList.push(serverRTTSeries);

                    vsAppType = args.vsAppType;

                    if (vsAppType !== 'l4' && vsAppType !== 'ssl' && vsAppType !== 'dns') {
                        sList.push(appRespTimeSeries);
                    }

                    break;
            }

            this.addSeries(sList);
        }

        /** @override */
        getTimingTilesConfig() {
            const
                { item } = this,
                tiles = angular.copy(allTimingTiles),
                config = {
                    header: 'End to End Timing',
                    tiles: [],
                };

            tiles.forEach(tileConfig => {
                const { sName } = tileConfig;

                if (this.hasSeries(sName)) {
                    if (!('icon' in tileConfig)) {
                        tileConfig.series = this.getSeries(sName);
                    }

                    delete tileConfig.sName;
                    config.tiles.push(tileConfig);
                }
            });

            // non http
            if (!this.hasSeries(appRespTimeSeries)) {
                config.tiles.push({
                    id: 'totalIcon',
                    icon: 'server',
                    iconTitle: 'Server',
                });
            }

            if (item.getItemType() === 'virtualservice') {
                config.tiles.push({
                    id: 'total',
                    title: 'Total Time',
                    series: this.getMainSeries(),
                });
            }

            return config;
        }
    };
}]);
