/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
import './pool-list.less';

angular.module('aviApp').controller('PoolListController', [
'$scope', 'CRUDGridConfig', 'PoolCollection', '$templateCache', 'Auth',
'getGridMetricFieldConfig',
function($scope, CRUDGridConfig, PoolCollection, $templateCache, Auth,
getGridMetricFieldConfig) {
    const gridMetricFields = [
        {
            require: 'l7_server.avg_complete_responses',
            title: 'RPS',
            fullTitle: 'Requests per second',
            visibility: 'd',
        }, {
            require: 'l4_server.max_open_conns',
            visibility: 'd',
        }, {
            require: 'l4_server.avg_bandwidth',
            visibility: 'd',
        },
        'l4_server.avg_total_rtt',
        'l7_server.avg_error_responses',
        'l7_server.pct_response_errors',
    ];

    const gridConfig = {
        collection: new PoolCollection(),
    };

    gridConfig.layout = {
        includeTimeframeSelector: true,
        includeMetricsValueSelector: true,
    };

    const gridFields = [{
        name: 'name',
        title: 'Name',
        template: require('./pool-grid-name-column.partial.html'),
        sortBy: 'name',
        visibility: 'm',
    }, {
        // Style based on health name, don't remove
        require: 'health,runtime',
        name: 'health',
        title: 'Health',
        template: '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
        sortBy: 'health_score',
        visibility: 'm',
    }, {
        name: 'virtualservice',
        title: 'Virtual Service',
        template: require('./pool-grid-virtualservice-column.partial.html'),
        visibility: 'd',
    }, {
        name: 'servers',
        title: 'Servers (Up/Total)',
        template: require('./pool-grid-servers-column.partial.html'),
        visibility: 'd',
    }, {
        name: 'poolgroups',
        title: 'Pool Groups',
        template: '{{ row.getPoolGroupNames().join(", ") }}',
    }, {
        name: 'cloud',
        title: 'Cloud',
        template: '{{ row.getCloudRef() | name }}',
        visibility: 'd',
    }, {
        require: 'config',
        name: 'vrf_context',
        title: 'VRF Context',
        template: '{{ row.getVRFContextRef().name() || "None" }}',
        visibility: 'optional',
    }, {
        name: 'num_vs',
        title: '# Virtual Services',
        template: '{{ row.getVSRefs().length }}',
        visibility: 'optional',
    }];

    gridFields.push(
        ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)),
    );

    // TODO: Alerts column will not show if PERMISSION_ALERT changes from NO_ACCESS or
    // WRITE_ACCESS to READ_ACCESS unless page is reloaded.
    if (Auth.isPrivilegeAllowed('PERMISSION_ALERT')) {
        gridFields.push({
            require: 'alert',
            name: 'alerts',
            title: 'Alerts',
            template: '<item-alert-bell item="row"></item-alert-bell>',
        });
    }

    gridConfig.fields = gridFields;

    $scope.gridConfig = CRUDGridConfig(gridConfig);

    $scope.gridConfig.multipleactions.push({
        title: 'Enable',
        disabled:
            pools => _.all(pools, pool => pool.isEnabled() || !pool.isEditable()),
        do: pools => {
            pools.forEach(pool => pool.setEnabledState(true));

            return true;
        },
    }, {
        title: 'Disable',
        disabled:
            pools => _.all(pools, pool => !pool.isEnabled() || !pool.isEditable()),
        do: pools => {
            pools.forEach(pool => pool.setEnabledState(false));

            return true;
        },
    });

    $scope.$on('$destroy', () => {
        gridConfig.collection.destroy();
    });
}]);
