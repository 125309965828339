/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('StaticRouteListDataTransformer', [
'ListDataTransformer',
function(ListDataTransformer) {
    return class StaticRouteListDataTransformer extends ListDataTransformer {
        /**
         * Adds "name" param with VRFContext name.
         * @override
         */
        getRequestConfig(requestParams) {
            const params = super.getRequestConfig(requestParams);

            params.name = this.owner_.vrf;

            return params;
        }

        /**
         * Taking list of static routes out of the first entry returned by API.
         * @override
         */
        processResponse(resp, request) {
            const results = [];

            resp = this.responseListOffsetControl_(resp, request);

            const { data } = resp;

            if ('results' in data && Array.isArray(data.results)) {
                const [vrfContext] = data.results;

                this.owner_.entireObject = vrfContext;

                const {
                    tenant_ref: tenantRef,
                    static_routes: staticRoutes,
                } = vrfContext;

                if (Array.isArray(staticRoutes)) {
                    results.push(
                        ...staticRoutes.map(row => {
                            const rowCopy = angular.copy(row);

                            rowCopy.tenant_ref = tenantRef;

                            return { config: rowCopy };
                        }),
                    );
                }
            }

            data.results = results;
            data.count = results.length;

            return resp;
        }
    };
}]);
