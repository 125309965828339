/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name eventsListSidebar
 * @author Alex Malitsky
 * @description
 *
 *     Sidebar is supposed to render the list of available Summary popovers and open them once
 *     link is clicked. All Summary popovers are actually components with naming pattern
 *     events-list-***-summary where *** is `default` when not set.
 *
 */
/**
 * List of all available popovers with their settings.
 * @typedef {Object} SummaryPopoverConfig
 * @property {string} id - Unique filed name to be used as a groupby value.
 * @property {string} name - Header for the first grid's column.
 * @property {string} header - Main popover header.
 * @property {string|undefined} component - Name of component to be used as a popover
 * template. When not set `default` is used.
 */
const popovers = [{
    id: 'obj_type',
    name: 'Object Type',
    header: 'Top Object Types',
}, {
    id: 'obj_name',
    name: 'Object Name',
    header: 'Top Object Names',
}, {
    id: 'event_id',
    name: 'Event Id',
    header: 'Top Event Ids',
}, {
    id: 'module',
    name: 'Module',
    header: 'Top Modules',
}];

/**
 * Hash of Summary popover config objects.
 * @type {{string: SummaryPopoverConfig}}
 * @private
 */
const popoverHash = popovers.reduce(function(hash, popoverConfig) {
    hash[popoverConfig.id] = popoverConfig;

    return hash;
}, {});

function EventListSidebarController(
        $scope,
        $compile,
        myAccount,
        PopoverFactory,
) {
    this.myAccount = myAccount;

    const popover = new PopoverFactory({
        position: 'left',
        className: 'event-list-sidebar-popover',
        repositioning: true,
        carat: true,
        width: 600,
        height: 100,
        margin: 10,
        hide: {
            outClick: true,
            onEscape: true,
            onWinResize: true,
        },
        removeAfterHide: true,
    });

    /**
     * @type {SummaryPopoverConfig[]}
     * @public
     */
    this.popovers = popovers;

    /**
     * Keeps active popover config, null when not present.
     * @type {SummaryPopoverConfig|null}
     * @public
     */
    this.activePopover = null;

    /**
     * Event handler for the Summary link click.
     * @param {SummaryPopoverConfig#id} popoverId
     * @param {ng.$event} $event
     * @public
     */
    this.openPopover = function(popoverId, $event) {
        this.activePopover = popoverHash[popoverId];

        const componentTag = $(`<events-list-${
            this.activePopover.component || 'default'
        }-summary/>`).attr({
            'summary-config': '$ctrl.activePopover',
            'on-close': '$ctrl.closePopover()',
            'on-reposition': '$ctrl.repositionPopover()',
        });

        popover.config.html = $compile(componentTag)($scope);
        popover.show($event.target);
    };

    /**
     * Used by Summary component to close popover.
     * @public
     */
    this.closePopover = function() {
        popover.hide();
        this.activePopover = null;
    };

    /**
     * Used by Summary component to reposition popover in case of size change.
     * @public
     */
    this.repositionPopover = function() {
        popover.reposition();
    };

    this.$onDestroy = function() {
        popover.remove();
    };
}

EventListSidebarController.$inject = [
    '$scope',
    '$compile',
    'myAccount',
    'popoverFactory',
];

angular.module('aviApp').component('eventsListSidebar', {
    templateUrl: 'src/components/applications/events-list-sidebar/events-list-sidebar.html',
    controller: EventListSidebarController,
    bindings: {
        isEnabled: '<',
    },
});
