/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './match-container.less';

/**
 * @ngdoc component
 * @name  matchContainer
 * @module match/matchContainer
 * @description Container component used as a wrapper around MatchTarget Config Item configuration.
 * @param {string} header - Name to be used as the title.
 * @param {Function} onClose - Called when the close button is clicked.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('policies.vantage.avi').component('matchContainer', {
    bindings: {
        header: '@',
        onClose: '&',
        preventEdit: '<',
    },
    transclude: true,
    templateUrl: 'src/components/forms/match-config/match-container/match-container.component.html',
});
