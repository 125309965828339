/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('CertificateCollection', ['Collection', 'Certificate',
function(Collection, Certificate) {
    function CertificateCollection(oArgs) {
        CertificateCollection.superconstructor.call(this, oArgs);
        this.filters = oArgs && oArgs.filters || angular.copy(this.filters);
    }

    avi.inherit(CertificateCollection, Collection);

    CertificateCollection.prototype.objectName_ = 'sslkeyandcertificate';
    CertificateCollection.prototype.itemClass_ = Certificate;
    CertificateCollection.prototype.windowElement_ = 'ssl-certificate-create-application-system';

    CertificateCollection.prototype.serverDefaultsOverride_ = {
        certificate: {
            self_signed: true,
        },
    };

    CertificateCollection.prototype.defaultDataSources_ = 'list';
    CertificateCollection.prototype.allDataSources_ = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'ListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    /** @override */
    CertificateCollection.prototype.create = function(windowElement, params) {
        const certTypeCAEnum = 'SSL_CERTIFICATE_TYPE_CA';
        const newItem = this.createNewItem();

        if (newItem.data.config['type'] === certTypeCAEnum) {
            windowElement = 'ssl-certificate-create-root';
        }

        return newItem.edit(windowElement, params);
    };

    return CertificateCollection;
}]);
