/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name Regex
 * @description
 *
 *     Dictionary of various regular expressions used by app. Initially generated from small
 *     chunks by few convenience methods.
 *
 *     For regular expression in a string form we need to escape the special "\" character.
 *
 *     WG suffix for the regexp means that it has some presumably meaningful character groups.
 *     Such groups have performance penalties so use wisely. Those are not supposed to be used
 *     for the templates.
 */
angular.module('aviApp').factory('Regex', ['Schema', function(Schema) {
    /**
     * Makes a list regexp from regexp for a single string/match.
     * @param {string} regExpStr
     * @param {string=} separator
     * @param {boolean=} spacesAllowed
     * @returns {string}
     * @inner
     */
    const listOfRegExpStr = function(regExpStr, separator = ',', spacesAllowed = true) {
        if (spacesAllowed) {
            separator = `\\s*${separator}\\s*`;
        }

        const res = `(?:${regExpStr}|(?:${regExpStr}${separator})+${regExpStr})`;

        return spacesAllowed ? `\\s*${res}\\s*` : res;
    };

    /**
     * Provides a regular expression in a string form for values range. Basically joins two
     * passed regExpStrs with a separator.
     * @param {string} regExpStr
     * @param {string=} separator
     * @param {boolean=} spacesAllowed
     * @returns {string}
     * @inner
     **/
    const rangeOfRegExpStr = function(regExpStr, separator = '\\-', spacesAllowed = true) {
        if (spacesAllowed) {
            separator = `\\s*${separator}\\s*`;
        }

        return regExpStr + separator + regExpStr;
    };

    const
        alpha = 'a-zA-Z',
        alphaNum = `${alpha}\\d`,
        alphaNumWDash = `${alphaNum}\\-`,
        alphaNumWDashUnderscore = `${alphaNumWDash}\\_`;

    const
        port = '(?:[1-9]|' +
            '[1-9]\\d{1,3}|' +
            '[1-5]\\d{4}|' +
            '6[0-4]\\d{3}|' +
            '65[0-4]\\d{2}|' +
            '655[0-2]\\d|' +
            '6553[0-5])';

    const
        ipAddrOctet = '(?:25[0-5]|2[0-4]\\d|[01]?\\d\\d?)',
        ipAddr = _.times(4, () => ipAddrOctet).join('\\.'),
        ipAddrWG = _.times(4, () => `(${ipAddrOctet})`).join('\\.'),
        ipAddrWithPort = `${ipAddr}:${port}`,
        ipAddrWithPortWG = `(${ipAddr}):(${port})`,
        ipAddrRange = rangeOfRegExpStr(ipAddr),
        ipAddrRangeWG = rangeOfRegExpStr(`(${ipAddr})`),
        ipAddrOrRange = `(?:${ipAddr}|${ipAddrRange})`,
        listOfIpAddrOrRanges = listOfRegExpStr(ipAddrOrRange),
        ipAddrOrIpAddrWithPortOrRange = `(?:${ipAddr}|${ipAddrWithPort}|${ipAddrRange})`,
        listOfIpAddrOrIpAddrWithPortOrRange = listOfRegExpStr(ipAddrOrIpAddrWithPortOrRange);

    const
        prefixLength = '(?:\\d|[1-2]\\d|3[0-2])',
        subnet = `${ipAddr}\\/${prefixLength}`,
        subnetWG = `(${ipAddr})\\/(${prefixLength})`;

    const
        ipAddrOrRangeOrSubnet = `(?:${ipAddr}|${ipAddrRange}|${subnet})`;

    const
        hostNamePart = `(?:[${alphaNum}]|[${alphaNum}][${alphaNumWDash}]*[${alphaNum}])`,
        hostName = `(?:${hostNamePart}\\.)*${hostNamePart}`,
        ipAddrOrRangeOrHostname = `(?:${ipAddrOrRange}|${hostName})`,
        listOfIpAddrOrRangesOrSingleHostname = `(?:${listOfIpAddrOrRanges}|${hostName})`,
        listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostname =
            `(?:${listOfIpAddrOrIpAddrWithPortOrRange}|${hostName})`,
        fqdn = `(?:${hostNamePart}\\.){2,}(?:${hostNamePart}){2,}`,
        ipAddrOrFqdn = `(?:${ipAddr}|${fqdn})`;

    const
        httpStatusCode = '[1-5]\\d{2}',
        httpStatusCodeRange = rangeOfRegExpStr(httpStatusCode),
        httpStatusCodeOrRange = `(${httpStatusCode}|${httpStatusCodeRange})`;

    const
        email = `[\\w.!#$%&'*+/=?^\`{|}~-]+@[${alphaNum}]` +
            `(?:[${alphaNumWDash}]{0,61}[${alphaNum}])?` +
            `(?:\\.[${alphaNum}](?:[${alphaNumWDash}]{0,61}[${alphaNum}])?)*`;

    const ipv6P1 = '(?:[0-9A-Fa-f]{1,4})';
    const ipv6P2 = '(?:25[0-5]|2[0-4]d|1dd|[1-9]?d)';
    const ipv6P3 = `(?:(?:${ipv6P2})(?:.(?:${ipv6P2})){3}))`;
    const ipv6 =
        `s*(?:(?:(?:${ipv6P1}:){7}(?:${ipv6P1}|:))|(?:(?:${ipv6P1}:){6}(?::${ipv6P1}|` +
        `(?:${ipv6P3}|:))|(?:(?:${ipv6P1}:){5}(?:(?:(?::${ipv6P1}){1,2})|` +
        `:(?:${ipv6P3}|:))|(?:(?:${ipv6P1}:){4}(?:(?:(?::${ipv6P1}){1,3})|` +
        `(?:(?::${ipv6P1})?:(?:${ipv6P3})|:))|(?:(?:${ipv6P1}:){3}(?:(?:(?::${ipv6P1}){1,4})|` +
        `(?:(?::${ipv6P1}){0,2}:(?:${ipv6P3})|:))|(?:(?:${ipv6P1}:){2}(?:(?:(?::${ipv6P1}){1,5})|` +
        `(?:(?::${ipv6P1}){0,3}:(?:${ipv6P3})|:))|(?:(?:${ipv6P1}:){1}(?:(?:(?::${ipv6P1}){1,6})|` +
        `(?:(?::${ipv6P1}){0,4}:(?:${ipv6P3})|:))|(?::(?:(?:(?::${ipv6P1}){1,7})|` +
        `(?:(?::${ipv6P1}){0,5}:(?:${ipv6P3})|:)))` +
        '(?:%.+)?s*';
    const ipv6Prefix = `(${ipv6})(\\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))`;
    const ipv6Range = rangeOfRegExpStr(`(${ipv6})`);
    const ipv6Port = `(\\[(${ipv6})\\]:(${port}))`;
    const anyIP = `(${ipAddr}|${ipv6})`;
    const anySubnet = `(${subnet}|${ipv6Prefix})`;
    const anyIPRange = `(${ipAddrRange}|${ipv6Range})`;
    const anyIPRangeOrSubnetList =
        `(${ipAddr}|${anyIPRange}|${subnet}|${ipv6}|${ipv6Prefix})`;
    const anyIPPort = `(${ipAddrWithPort}|${ipv6Port})`;
    const anyIPIPv4Range = `(${anyIP}|${ipAddrRange})`;
    const anyIPIPv4RangeHostname = `(${anyIP}|${ipAddrRange}|${hostName})`;
    const anyIPIPPortIPv4Range = `(${anyIP}|${anyIPPort}|${ipAddrRange})`;
    const anyIPIPSubnetIPv4Range = `(${anyIP}|${anySubnet}|${ipAddrRange})`;
    const anyIPIPPortIPv4RangeHostname = `(${anyIPIPPortIPv4Range}|${hostName})`;
    const anyIPIPPortHostname = `(?:${anyIP}|${anyIPPort}|${hostName})`;

    const licenseSerialKeyPart = `(?:[${alphaNum}]){5}`;
    const licenseSerialKey = _.times(5, () => licenseSerialKeyPart).join('\\-');

    /*
    - Typed out input \r in browser is actually treated as \\r in browser.
    - Typed out input \n in browser is actually treated as \\n in browser.
    - Typed out input \r\n in browser is actually treated as \\r\\n in browser.
    - Typed out new line in browser is treated as \n in browser.
     */
    const carriageReturnLineFeedWEscape = /\\r\\n|\\r|\r(?!\n)|\\n|(?!\r])\n/;

    const patterns = {
        anyIP: new RegExp(`^${anyIP}$`),
        anyIPIPPortHostname: new RegExp(`^${anyIPIPPortHostname}$`),
        anyIPIPPortIPv4RangeHostnameList:
            new RegExp(`^${listOfRegExpStr(anyIPIPPortIPv4RangeHostname)}$`),
        anyIPIPPortIPv4RangeList: new RegExp(`^${listOfRegExpStr(anyIPIPPortIPv4Range)}$`),
        anyIPIPSubnetIPv4RangeList: new RegExp(`^${listOfRegExpStr(anyIPIPSubnetIPv4Range)}$`),
        anyIPIPv4Range: new RegExp(`^${anyIPIPv4Range}$`),
        anyIPIPv4RangeHostnameList: new RegExp(`^${listOfRegExpStr(anyIPIPv4RangeHostname)}$`),
        anyIPIPv4RangeList: new RegExp(`^${listOfRegExpStr(anyIPIPv4Range)}$`),
        anyIPList: new RegExp(`^${listOfRegExpStr(anyIP)}$`),
        anyIPPort: new RegExp(`^${anyIPPort}$`),
        anyIPRange: new RegExp(`^${anyIPRange}$`),
        anyIPRangeOrSubnetList: new RegExp(`^${listOfRegExpStr(anyIPRangeOrSubnetList)}$`),
        anyIPSubnetList: new RegExp(`^${listOfRegExpStr(anySubnet)}$`),
        anySubnet: new RegExp(`^${anySubnet}$`),
        carriageReturn: /\r/,
        carriageReturnLineFeedWEscape,
        dnsName: new RegExp(
            `^(?:[${alphaNum}]{1,2}|` +
                `[${alphaNum}][${alphaNumWDashUnderscore}.]{1,61}[${alphaNum}])$`,
        ),
        email: new RegExp(`^${email}$`),
        emailList: new RegExp(`^${listOfRegExpStr(email)}$`),
        hostname: new RegExp(`^${hostName}$`),
        fqdn: new RegExp(`^${fqdn}$`),
        ipAddrOrFqdn: new RegExp(`^${ipAddrOrFqdn}$`),
        integer: /^-?\d+$/,
        unsignedInteger: /^\d+$/,
        ip: new RegExp(`^${ipAddr}$`),
        ipAddrOrRange: new RegExp(`^${ipAddrOrRange}$`),
        ipAddrOrRangeOrHostname: new RegExp(`^${ipAddrOrRangeOrHostname}$`),
        ipAddrRange: new RegExp(`^${ipAddrRange}$`),
        ipAddrRangeWG: new RegExp(`^${ipAddrRangeWG}$`),
        ipAddrWithPort: new RegExp(`^${ipAddrWithPort}$`),
        ipAddrWithPortWG: new RegExp(`^${ipAddrWithPortWG}$`),
        ipRangePrefix: new RegExp(`^\\s*${ipAddrOrRangeOrSubnet}\\s*$`),
        ipv6: new RegExp(`^${ipv6}$`),
        ipv6Port: new RegExp(`^${ipv6Port}$`),
        ipv6Prefix: new RegExp(`^${ipv6Prefix}$`),
        ipv6PrefixList: new RegExp(`^${listOfRegExpStr(ipv6Prefix)}$`),
        ipv6Range: new RegExp(`^${ipv6Range}$`),
        ipWG: new RegExp(`^${ipAddrWG}$`),
        lineFeed: /\n/,
        listOfHostnames: new RegExp(`^${listOfRegExpStr(hostName)}$`),
        listOfIpAddrOrIpAddrWithPortOrRange: new RegExp(`^${listOfIpAddrOrIpAddrWithPortOrRange}$`),
        listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostname:
            new RegExp(`^${listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostname}$`),
        listOfIpAddrOrRangesOrSingleHostname:
            new RegExp(`^${listOfIpAddrOrRangesOrSingleHostname}$`),
        listOfIps: new RegExp(`^${listOfRegExpStr(ipAddr)}$`),
        listOfIpsPrefixes: new RegExp(`^${listOfRegExpStr(subnet)}$`),
        listOfIpsRanges: new RegExp(`^${listOfIpAddrOrRanges}$`),
        listOfIpsRangesPrefixes: new RegExp(`^${listOfRegExpStr(ipAddrOrRangeOrSubnet)}$`),
        listOfStatusCodeOrRanges: new RegExp(`^${listOfRegExpStr(httpStatusCodeOrRange)}$`),
        mac: /^(?:[\dA-Fa-f]{2}[:-]){5}(?:[\dA-Fa-f]{2})$/,
        mask: new RegExp(`^${subnet}$`),
        objName: /^[^<>]*$/,
        poolURL: /^(?:-\.)?(?:[^\s/?.#-]+\.?)+(?:\/[^\s]*)?$/, //mathiasbynens.be/demo/url-regex
        port: new RegExp(`^${port}$`),
        prefixLength: new RegExp(`^${prefixLength}$`),
        ratio: /^(?:20|1\d|[1-9])$/,
        schemaObjectFieldSpecialValuesHash: //supposed to be used to create global regex
            /{?\s?([^\s']+?)\s?:\s?'(.+?)'\s?[,}]?\s?/,
        statusCodeList: new RegExp(`^${listOfRegExpStr(httpStatusCode)}$`),
        statusCodeRangeList: new RegExp(`^${listOfRegExpStr(httpStatusCodeRange)}$`),
        strictObjName: /^[-\w\s][-\w\s.:|@]*$/,
        subnetWG: new RegExp(`^${subnetWG}$`),
        licenseSerialKey: new RegExp(`^${licenseSerialKey}$`),
    };

    //old ambiguous names (to the right) are deprecated
    //FIXME remove old names from code and remove em completely
    patterns.listOfIpOrRanges = patterns.listOfIpsRanges;
    patterns.subnet = patterns.mask;

    const URITokenTypes = [
        'HOST', 'PATH', 'H', 'P',
        'SG_MAP', 'StringGroup_Map',
        'SG_RE', 'StringGroup_Regex',
    ].join('|');

    // Checks the list of tokens like HOST[0:2],
    patterns.hostTokens = new RegExp(
        `^(?:[^\\[\\]&/]*?|(?:(?:${URITokenTypes})\\[\\d*:?\\d*]))` +
        `(?:\\.(?:[^\\[\\]&/]*?|(?:(?:${URITokenTypes})\\[\\d*:?\\d*])))*?$`,
        'i',
    );

    patterns.pathTokens = new RegExp(
        `^(?:[^\\[\\]&]*?|(?:(?:${URITokenTypes})\\[\\d*:?\\d*]))` +
        `(?:\\/([^\\[\\]&]*?|(?:(?:${URITokenTypes})\\[\\d*:?\\d*])))*?$`,
        'i',
    );

    let wafExclusionMatchElement;

    //don't want to break app on sudden Schema update of particular field
    try {
        wafExclusionMatchElement =
            Schema.pb.WafExcludeListEntry.fields.match_element.options.pattern.value;

        //dropping Perl modifiers
        wafExclusionMatchElement = /^(?:\(\?\w+\))?(.*)$/.exec(wafExclusionMatchElement)[1];
    } catch (e) {
        console.error(e);
        wafExclusionMatchElement = '^.*$';
    }

    patterns.wafExclusionMatchElement = new RegExp(wafExclusionMatchElement, 'i');

    // Checks O/5-14400 for TCP FastPath/TCP Proxy session_idle_timeout
    patterns.tcpProfileSessionIdleTimeout = new RegExp(
        '^(0|[5-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8]' +
        '[0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|' +
        '1[0-3][0-9]{3}|14[0-3][0-9]{2}|14400)$',
    );

    return patterns;
}]);
