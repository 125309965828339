/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  virtualserviceSecuritySslPatches
 * @param {function} closeModal - Closes modal.
 * @description  Displays OpenSSL patches changelog information.
 */
class Controller {
    constructor(VirtualServiceSecurityService) {
        this.securityService_ = VirtualServiceSecurityService;
    }

    $onInit() {
        this.busy = true;

        this.securityService_.getSSLPatches()
            .then(response => this.patches = response)
            .finally(() => this.busy = false);
    }

    $onDestroy() {
        this.securityService_.cancelGetSSLPatches();
    }
}

Controller.$inject = [
        'VirtualServiceSecurityService',
];

angular.module('aviApp').component('virtualserviceSecuritySslPatches', {
    bindings: {
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/applications/virtualservice/' +
            'virtualservice-security-ssl-patches/virtualservice-security-ssl-patches.html',
});
