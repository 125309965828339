/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('logs.vantage.avi').directive('logInputPills', ['logSearchMediator',
function(logSearchMediator) {
    function logInputPillsLink(scope, elm) {
        const
            input = elm.find('input.main'),
            { ta } = logSearchMediator;

        if (!Array.isArray(scope.ngModel)) {
            scope.ngModel = [];
        }

        scope.newItem = '';
        scope.max = Math.max;
        scope.status = [];//editModes for search pills
        scope.status.length = scope.ngModel.length;

        //Focus the input when container clicked
        elm.find('> div.dropdown-container > span.multiple.choice')
            .on('mousedown', function(e) {
                if (e.target === this || $(e.target).is('ul')) {
                    setTimeout(() => input.trigger('focus'));
                }
            });

        input
            .on('keydown', function(e) {
                if (e.which === 13) { /* enter */
                    if (ta.suggClicked) {
                        return;
                    }

                    if (scope.newItem) {
                        scope.add();
                    } else {
                        scope.$apply('ngChange()');
                    }
                // backspace
                } else if (e.which === 8 && !scope.newItem && scope.ngModel.length) {
                    scope.$apply('newItem = ngModel.pop();');
                    e.preventDefault();
                } else if (e.which === 9) {
                    if (ta.tabClicked) {
                        return;
                    }

                    setTimeout(() => input.trigger('focus'));
                } else if (e.which === 27) { /* esc */
                    if (!ta.escClicked) {
                        if (scope.newItem) {
                            scope.add();
                        } else {
                            scope.$apply('ngChange()');
                        }
                    }
                }
            })
            .on('focusout', function() {
                if (ta.suggClicked) {
                    return;
                }

                if (scope.newItem) {
                    scope.add();
                }
            });

        scope.add = function() {
            if (scope.newItem) {
                scope.$apply('ngModel.push(newItem); newItem = \'\'; ngChange();');
                setTimeout(checkMultiline);
            }
        };

        scope.remove = function(index, event, type) {
            //console.log('pill remove ' + type);
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }

            scope.ngModel.splice(index, 1);

            if (type && +index !== 0) { //by backslash from empty search-pill
                scope.status[index - 1] = true;
                scope.$apply();
                elm.find(`search-pill[index="${index - 1}] input`).trigger('focus');
            } else {
                input.trigger('focus');
                scope.ngChange();
            }

            setTimeout(checkMultiline);
        };

        scope.placeholder = function() {
            return scope.ngModel.length ? '' : 'Search';
        };

        scope.pillUpdated = function() { /* pill edit finished */
            input.trigger('focus');
            scope.ngChange();
            scope.$apply();
        };

        const checkMultiline = function() { /* to modify buttons layout when we have second row */
            const wrap = $('div.log-search');

            if (elm.innerHeight() > 40) {
                wrap.addClass('multiline');
            } else {
                wrap.removeClass('multiline');
            }
        };
    }

    return {
        scope: {
            ngModel: '=',
            newItem: '=',
            isHTTPVS: '<isHttpVs',
            requestUri: '&',
            ngChange: '&',
            ngDisabled: '=',
            ngRequired: '=',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/log-input-pills.html',
        link: logInputPillsLink,
    };
}]);
