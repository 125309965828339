/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name Alert
 */
angular.module('aviApp').factory('Alert',
    ['Item', function(Item) {
        class Alert extends Item {
        /**
         * Returns the Item name this alert was generated about.
         * @returns {string}
         * @public
         */
            getObjName() {
                const { obj_name: objName, obj_ref: objRef } = this.getConfig();

                return objName || objRef.name() || '';
            }

            /**
             * Human readable alert level enum value, such as "high", "medium" and "low".
             * @type {string}
             */
            get level() {
                const { level } = this.getConfig();

                return level.slice(6).toLowerCase();// 'ALERT_'.length
            }
        }

        Alert.prototype.objectName = 'alert';

        return Alert;
    }]);
