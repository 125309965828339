/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const componentName = 'c-grid-table-header-cell-sort-icon';

class GridTableHeaderCellSortIconController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$element.addClass(componentName);
    }

    /**
     * Returns class name for the appropriate icon (up or down).
     * @returns {string}
     */
    getClassName() {
        const { state } = this;

        return state ? `icon-sort-${state === 1 ? 'desc' : 'asc'}` : '';
    }
}

GridTableHeaderCellSortIconController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCellSortIcon
 * @param {number} state - 0 for no sorting, 1 for descending and 2 for ascending.
 * @desc
 *
 *     Renders appropriate icon representing column sorting.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi').component('gridTableHeaderCellSortIcon', {
    bindings: {
        state: '<',
    },
    controller: GridTableHeaderCellSortIconController,
    template: '<i class="icon icon-sort" ng-class="$ctrl.getClassName()"/>',
});
