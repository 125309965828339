/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('MetricDataListDataTransformer', [
'ListDataTransformer',
ListDataTransformer => {
    return class MetricDataListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(rsp) {
            const
                { data } = rsp,
                results = [];

            if (data && 'series' in data && !_.isEmpty(data.series)) {
                const entityUuidHash = data.series.reduce((acc, series) => {
                    const
                        { name, entity_uuid: entityUuid, entity_ref: entityRef } = series.header,
                        itemId = entityUuid || entityRef;

                    acc[itemId] = acc[itemId] || {};
                    acc[itemId][name] = series;

                    return acc;
                }, {});

                results.push(
                    ..._.map(entityUuidHash, (metricIdHash, id) => {
                        return {
                            id,
                            name: id,
                            metrics: metricIdHash,
                        };
                    }),
                );
            }

            data.results = results;
            data.count = results.length;

            return rsp;
        }
    };
}]);
