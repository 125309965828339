/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name GSLBServicePoolMembersInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *    Flat list of GslbService GslbPool members. Build from scratch every time since there are
 *    no unique uuids.
 */
angular.module('aviApp').factory('GSLBServicePoolMembersInventoryCollDataSource', [
'LimitedListCollDataSource',
function(LimitedListCollDataSource) {
    return class GSLBServicePoolMembersInventoryCollDataSource extends LimitedListCollDataSource {
        /** @override */
        getRequestParams_() {
            const request = super.getRequestParams_(this.params_);

            request['objectName_'] = [
                'gslbservice-inventory',
                this.owner_.gslbServiceId,
            ];

            return request;
        }
    };
}]);
