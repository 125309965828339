/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  cloudTypeSelector
 * @param {function} onCloudTypeChange - Called when user selects a cloud.
 * @param {Cloud.vtype} vtype - Cloud type.
 * @description  Shows menu of Cloud types for selection during Cloud creation process.
 */
class CloudTypeSelectorController {
    constructor(Schema, Cloud) {
        this.Schema = Schema;
        this.Cloud_ = Cloud;
    }

    /**
     * Returns the name of the cloud defined by enums.
     * @param  {string} cloudType - type of Cloud.
     * @return {string}
     */
    getCloudTypeName(vtype) {
        return this.Cloud_.getCloudTypeName(vtype);
    }

    /**
     * Called when a cloud type is selected.
     * @param  {string} type - Type of cloud selected.
     */
    handleCloudTypeChange(type) {
        this.onCloudTypeChange({
            cloudType: type,
        });
    }
}

CloudTypeSelectorController.$inject = [
        'Schema',
        'Cloud',
];

angular.module('aviApp').component('cloudTypeSelector', {
    bindings: {
        onCloudTypeChange: '&',
        vtype: '<',
    },
    controller: CloudTypeSelectorController,
    templateUrl: 'src/components/infrastructure/clouds/cloud-type-selector/' +
            'cloud-type-selector.html',
});
