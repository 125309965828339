/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * Used when a function needs to be called on a change in ng-model and the ng-model value needs to
 * be validated.
 * @ngdoc directive
 * @name  changeAndValidate
 * @restrict A
 * @example <input type="text" change-and-validate="onChange()"/>
 */
angular.module('aviApp').directive('changeAndValidate', function() {
    return {
        scope: {
            changeAndValidate: '&',
        },
        require: 'ngModel',
        restrict: 'A',
        link(scope, elm, attr, ngModelCtrl) {
            ngModelCtrl.$validators.changeAndValidate = function(ngModelValue) {
                if (!ngModelValue) {
                    return true;
                }

                return scope.changeAndValidate({ newValue: ngModelValue });
            };
        },
    };
});
