/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
import './pool-fail-action-setter.less';

const componentName = 'pool-fail-action-setter';

/** @alias poolFailActionSetter **/
class PoolFailActionSetterController {
    constructor($element, $scope, schemaService, Regex) {
        this.$elem = $element;
        this.$scope = $scope;
        this.schemaService = schemaService;
        this.Regex = Regex;
    }

    $onInit() {
        this.$elem.addClass(componentName);

        const { schemaService } = this;

        this.types = schemaService.getEnumValues('FailActionEnum');

        if (this.noHttpTypes) {
            this.types = this.types.filter(({ value }) => value.indexOf('HTTP') === -1);
        }

        this.localRespStatusCodes =
                schemaService.getEnumValues('FailHttpStatusCode');

        this.httpProtocols =
                schemaService.getEnumValues('HTTPProtocol');

        this.httpRedirectStatusCodes =
                schemaService.getEnumValues('HTTPRedirectStatusCode');

        // for initial setup and external changes tracking (if any)
        // need local 'type' variable here so that onTypeChange event handler to do the job
        this.$scope.$watch(
            () => this.failAction.type,
            type => this.type = type,
        );
    }
}

PoolFailActionSetterController.$inject = [
        '$element',
        '$scope',
        'schemaService',
        'Regex',
];

/**
 * @ngdoc component
 * @name poolFailActionSetter
 * @author Alex Malitsky
 * @param {PoolFailActionConfig} failAction
 * @param {boolean=} noHttpTypes - Pass true to hide HTTP specific fail actions.
 * @param {Function} onTypeChange - To be called on new fail action type selection/removal.
 * @param {string} objectType - 'Pool' or 'PoolGroup'
 * @desc
 *     Form component to set up FailAction protobuf message for
 *     {@link Pool} and {@link PoolGroup}.
 */
angular.module('aviApp').component('poolFailActionSetter', {
    bindings: {
        failAction: '<',
        noHttpTypes: '<?',
        onTypeChange: '&',
        objectType: '@',
    },
    controller: PoolFailActionSetterController,
    templateUrl: `src/components/applications/pool/${componentName}/${componentName}.html`,
});
