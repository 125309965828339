/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

//TODO remove @am
angular.module('aviApp').directive('doesItFit', ['$timeout', function($timeout) {
    return {
        scope: {},
        restrict: 'A',
        link(scope, elm) {
            const tries = elm.find('> [fit-try]');

            const findBestFit = function() {
                const width = elm.width();
                let found;

                tries.each(function() {
                    const $tryElm = $(this);

                    if (found) {
                        if ($tryElm.is(':visible')) {
                            $tryElm.hide();
                        }
                    } else {
                        $tryElm.css({ display: 'inline-block' });

                        if ($tryElm.width() <= width) {
                            found = true;
                        } else {
                            $tryElm.hide();
                        }
                    }
                });
            };

            /* unacceptable performance @am
            scope.$watch(function(){
                    return elm.width();
                }, findBestFit);*/

            // Needs to happen after elm has width
            $timeout(findBestFit);

            scope.$on('repaint', findBestFit);
        },
    };
}]);
