/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './item-alert-popover.less';

/**
 * @ngdoc component
 * @name itemAlertPopover
 * @param {Pool|VirtualService|ServiceEngine} item
 * @description
 *
 *     Item alert popover with header showing total number of alerts per severity level, main
 *     section with a list of relevant alerts and button to go to alerts list page.
 */
const componentName = 'item-alert-popover';

const alertsPageLabels = [
        'View All Alerts',
        'Go to Alerts Page',
];

let $state;

class ItemAlertPopoverController {
    constructor($element, _$state_, itemAlertDataTransform) {
        $state = _$state_;

        this.alertLevels = itemAlertDataTransform.alertLevels;
        this.$elem_ = $element;
    }

    $onInit() {
        this.alertsCollection = this.item.alertsPrioritized;
        this.$elem_.addClass(componentName);
    }

    /**
     * Returns a text label for "go to alerts page" button.
     * @type {string}
     * @public
     */
    get alertsPageLinkLabel() {
        const alertsData = this.item.getAlertsData();

        if (alertsData && alertsData.highestAlertLevel) {
            return alertsPageLabels[0];
        }

        return alertsPageLabels[1];
    }

    /**
     * Return true to show "go to alerts page" button.
     * @type {boolean}
     * @public
     **/
    get hasAlertsPageLink() {
        return !$state.includes('**.alerts');
    }

    /**
     * Click event handler to take use to alert list page.
     * @public
     */
    goToAlertsListPage() {
        this.item.goToItemPage('alerts');
    }
}

ItemAlertPopoverController.$inject = [
    '$element',
    '$state',
    'itemAlertDataTransform',
];

angular.module('aviApp').component('itemAlertPopover', {
    controller: ItemAlertPopoverController,
    bindings: {
        item: '<',
    },
    templateUrl: 'src/components/common/item-alert-popover/item-alert-popover.html',
});
