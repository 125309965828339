/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-modal.less';

const wafEnforcedModeEnumValue = 'WAF_MODE_ENFORCEMENT';

/** @alias wafPolicyModal **/
class WafPolicyModalController {
    constructor(
        Schema,
        Regex,
        WafProfileCollection,
        schemaService,
        WafCrsCollection,
        AviConfirmService,
        $q,
        AviModal,
    ) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.schemaService = schemaService;
        this.AviConfirmService_ = AviConfirmService;
        this.$q_ = $q;
        this.AviModal_ = AviModal;

        this.wafModeEnumValues = schemaService.getEnumValues('WafMode');
        this.wafProfileCollection = new WafProfileCollection();
        this.wafCrsCollection = new WafCrsCollection({
            params: {
                fields: 'name',
            },
        });

        /**
         * @type {string|undefined}
         */
        this.wafCrsRef = undefined;

        /**
         * @type {boolean}
         */
        this.saveUncommitted = true;
    }

    /**
     * Sets this.wafCrsRef, which is related to updating the CRS Version. This field is tied to
     * the ngModel of a <collection-dropdown />, and is copied from config.waf_crs_ref. The
     * reason this is done is because this property can't be changed and saved as we normally
     * do with other editable objects - we need to make a separate request to update this field.
     * Therefore, we use this field as a proxy for updating config.waf_crs_ref.
     */
    $onInit() {
        const config = this.editable.getConfig();

        this.wafCrsRef = config.waf_crs_ref;
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param {string} groupsProperty - 'pre_crs_groups', 'crs_groups', or 'pre_crs_groups'.
     * @param {number} newIndex - Index of the new position.
     * @param {Object} group - Object that was moved. dndList directive passes in the object
     *     data and not the instance.
     */
    handleDragAndDropGroup(groupsProperty, newIndex, group) {
        const { editable } = this;
        const groupIndex = group.data.config.index;
        const oldIndex = editable.getArrayIndexFromGroupIndex(groupsProperty, groupIndex);

        if (oldIndex < newIndex) {
            newIndex--;
        }

        this.editable.moveGroup(groupsProperty, oldIndex, newIndex);
    }

    /**
     * Sets "allow delegation" flag to true on enforcement mode selection.
     * @param {string} mode - WafMode enum value.
     */
    onModeChange(mode) {
        if (mode === wafEnforcedModeEnumValue) {
            const { editable: wafPolicy } = this;

            wafPolicy.getConfig()['allow_mode_delegation'] = true;
        }
    }

    /**
     * Returns true if policy is in Enforced mode.
     * @return {boolean}
     */
    get modeDelegationIsDisabled() {
        return this.editable.getMode() === wafEnforcedModeEnumValue;
    }

    /**
     * Called when the user has selected from the CRS Version <collection-dropdown />, but
     * before updating the ngModel. Opens up a confirmation popup and allows the user to cancel
     * the change.
     * @param {WafCrs} selected - The selected WafCrs object.
     * @return {ng.$q.promise}
     */
    handleCrsVersionSelect(selected) {
        const [wafCrs] = selected;
        const wafCrsUuid = wafCrs.id;
        const bindings = {
            wafCrs,
            loadPreview: () => this.editable.updateCrsRules(wafCrsUuid, false),
            modified: this.editable.modified(),
        };

        return this.AviConfirmService_.prompt('waf-policy-confirm-change-crs-version', bindings)
            .then(({ saveUncommitted }) => this.saveUncommitted = saveUncommitted);
    }

    /**
     * Called after the ngModel of the CRS Version <collection-dropdown /> has changed. If
     * this.saveUncommitted is set to true, we save editable first before updating the CRS rules
     * of editable, otherwise we only update the CRS rules.
     * @param {WafCrs} wafCrs - The selected WafCrs object
     * @return {ng.$q.promise}
     */
    handleCrsVersionChange(wafCrs) {
        const { id: wafCrsUuid } = wafCrs;
        const promise = this.saveUncommitted ? this.editable.save() : this.$q_.when(true);

        return promise
            .then(() => this.editable.updateCrsRules(wafCrsUuid, true))
            .then(() => this.editable.dismiss(true));
    }

    /**
     * Creates a new whitelist rule and opens the modal to edit it.
     */
    addWhitelistRule() {
        const newRule = this.editable.createNewWhiteListRule();

        this.editWhitelistRule(newRule);
    }

    /**
     * Opens the whitelist rule modal to edit an existing rule.
     * @param {WafPolicyWhitelistRule} rule
     */
    editWhitelistRule(rule) {
        this.AviModal_.open('waf-policy-whitelist-rule-modal', {
            rule: rule.clone(),
            onSubmit: ['rule', rule => this.editable.addWhitelistRule(rule)],
        });
    }

    /**
     * Returns the state of the enable app learning switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getAppLearningState() {
        return this.editable.appLearning ? 1 : 0;
    }

    /**
     * Click handler for the enable app learning switch. Flips the current value.
     */
    handleAppLearningClick() {
        this.editable.appLearning = !this.editable.appLearning;
        this.editable.setPsmLearningGroup();
    }

    /** @override */
    $onDestroy() {
        this.wafProfileCollection.destroy();
        this.wafCrsCollection.destroy();
    }
}

WafPolicyModalController.$inject = [
    'Schema',
    'Regex',
    'WafProfileCollection',
    'schemaService',
    'WafCrsCollection',
    'AviConfirmService',
    '$q',
    'AviModal',
];

/**
 * @ngdoc component
 * @name wafPolicyModal
 * @param {WafPolicy} editable
 * @desc
 *     Modal component for Waf Policy
 */
angular.module('aviApp').component('wafPolicyModal', {
    controller: WafPolicyModalController,
    bindings: {
        editable: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-policy-modal.html',
});
