/*
 **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

function techSupportCaptureDataSourceFactory(DataSource) {
    /**
     * @alias module:services/TechSupportCaptureDataSource
     * @extends DataSource
     * @private
     */
    class TechSupportCaptureDataSource extends DataSource {
        constructor(args) {
            args = Object.assign(
                {
                    updateInterval: 15,
                    defaultFields: [
                        {
                            id: 'config',
                            preserved: true,
                            subscribers: ['__default_field'],
                        },
                    ],
                },
                args,
            );

            super(args);
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params.objectName_ = this.owner_.objectName_;

            return params;
        }

        /** @override */
        processResponse_({ data }) {
            this.owner_.setTechSupportCaptureStatus_(data);
            this.owner_.triggerUpdateEvent();
        }
    }

    return TechSupportCaptureDataSource;
}

techSupportCaptureDataSourceFactory.$inject = ['DataSource'];

/**
 * @ngdoc service
 * @name TechSupportCaptureDataSource
 * @module services/TechSupportCaptureDataSource
 * @author Akul Aggarwal
 * @description
 *
 *      Returns last tech support generation data for @TechSupportCapture.
 *
 */
angular.module('aviApp').factory('TechSupportCaptureDataSource',
    techSupportCaptureDataSourceFactory);
