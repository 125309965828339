/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/ms-map-graph-chart.less';

/**
 * @ngdoc directive
 * @name msMapGraphChart
 * @restrict E
 * @param {MicroServiceGraphFactory} graph - An instance of a graph factory.
 * @param {string=} metricValues - If we have to show metric values on the graph edges. True or
 *     false.
 * @param {string=} layout - Bi-directional if we want to show one group of the nodes on the
 *     left side and another on the right side. With root node sitting at the chart's center.
 * @param {string=} noRootClick - If we want to disable single click event on the root element.
 * @param {string=} highlightEnabled - If we want to support a highlighting of connected
 *     nodes for this chart. Any provided string will be considered as 'enabled' flag.
 * @param {string=} minScale - Minimal scale value available for the chart. Equals to one when not
 *     provided.
 * @param {string=} showLegend - Whether we need to show chart's legend.
 * @description Draws the graph as a chart taking all available space provided by the parent
 * DOM element.
 * @example <div ms-map-graph-chart graph="MicroServiceGraph" no-root-click="true"></div>
 */

//TODO A.href from root node should go into analytics (with exclusion for the dashboard).

angular.module('aviApp').directive('msMapGraphChart', ['$filter', '$state',
'MsMapGraphChartHighlightFactory', 'MsMapGraphChartZoomFactory',
function($filter, $state, MsMapGraphChartHighlightFactory, MsMapGraphChartZoomFactory) {
    function link(scope, elm) {
        /**
         * Returns a unique key of the node (vertex) of the graph.
         * @param {MSGraphNode} node
         * @returns {string}
         * @inner
         */
        function nodeKey(node) {
            return node.uuid;
        }

        /**
         * Returns a unique key of the link (edge).
         * @param {MSGraphEdge} link - Link object, gotten from the backend and updated by the
         *     {@link d3.layout.force.links | D3 force layout}.
         * @returns {string}
         * @inner
         */
        function linkKey(link) {
            return link.id;
        }

        /**
         * Returns a hs value string. Takes in account operational status.
         * @param  {MSGraphNode} node
         * @returns {string}
         * @inner
         */
        function nodeHsText(node) {
            let res = `${node.healthscore}`;
            const state = $filter('unitStateLabel')(node.runtime && node.runtime.oper_status.state);

            if (state &&
                state !== 'Up' && state !== 'Disabled' &&
                state !== 'Inactive' && state !== 'Unused') {
                res = '!';
            }

            return res;
        }

        /**
         * Returns a healthscore class name from the node object as well as the type of node.
         * @param {MSGraphNode} node - Node object, gotten from the backend.
         * @returns {string}
         * @inner
         */
        function nodeCircleClassName(node) {
            let res = 'main ';

            switch (node.obj_type) {
                case 'virtualservice':
                    res += $filter('healthScoreClass')(node.healthscore,
                        node.runtime && node.runtime.oper_status.state);
                    break;

                case 'IP':
                    if (node.uuid !== '0.0.0.0') {
                        res += 'ip';
                    } else {
                        res += 'user';
                    }

                    break;
            }

            return res;
        }

        /**
         * Returns a class name for the Node's svg:group element.
         * @param {MSGraphNode} node
         * @returns {string}
         * @inner
         */
        function nodeGroupClassName(node) {
            let res = 'node';

            switch (node.obj_type) {
                case 'virtualservice':
                    if (node.secured) {
                        res += ' secured';
                    }

                    if (node.isolated) {
                        switch (node.security_action) {
                            case whiteListSign:
                                res += ' white-list';
                                break;

                            case blackListSign:
                                res += ' black-list';
                                break;
                        }
                    }

                    break;

                case 'IP':
                    if (node.uuid === '0.0.0.0') {
                        res += ' user-node';
                    }

                    break;
            }

            return res;
        }

        /**
         * Returns an edge (link) class name.
         * @param {MSGraphEdge} link
         * @returns {string}
         * @inner
         */
        function linkClassName(link) {
            let res = 'link ';

            switch (link.security_action) {
                case whiteListSign:
                    res += 'white-list ';
                    break;

                case blackListSign:
                    res += 'black-list ';
                    break;

                default:
                    res += 'no-list ';
                    break;
            }

            switch (link.policy_drops) {
                case 0:
                    res += 'no-drops ';
                    break;

                case 100:
                    res += 'full-drops ';
                    break;

                default:
                    res += 'some-drops ';
                    break;
            }

            return res;
        }

        /**
         * Depending on policy drops and presence in white/black lists we figure out edge
         * color. Used by markers only - color of edges themselves is set by style rules.
         * @param {MSGraphEdge} link
         * @returns {string}
         * @inner
         */
        function getLinkColor(link) {
            let res = 'grey';

            if (link.security_action === blackListSign ||
                !link.security_action && link.policy_drops > 0) {
                res = 'red';
            }

            return res;
        }

        /**
         * Calculates a marker-start attribute for the link's path element. Used only of
         * self-referencing nodes.
         * @param {MSGraphEdge} link
         * @returns {null|string} - Null when not applicable or string with a reference for
         * the predefined marker.
         * @inner
         */
        function linkMarkerStart(link) { //only for self referencing link
            let res = null;

            if (link.target.index === link.source.index) {
                res = 'url(#arrow-self-ref-start';

                if (getLinkColor(link) === 'red') {
                    res += '-red';
                }

                res += ')';
            }

            return res;
        }

        /**
         * Calculates a marker-end attribute for the link's path element.
         * @param {MSGraphEdge} link
         * @returns {string} - String with a reference for the predefined marker.
         * @inner
         */
        function linkMarkerEnd(link) {
            let res = 'url(#arrow';

            if (link.target.index === link.source.index) {
                res += '-self-ref-end';
            }

            if (getLinkColor(link) === 'red') {
                res += '-red';
            }

            return `${res})`;
        }

        /**
         * Returns text (metric value) for the link between Nodes.
         * @param {MSGraphEdge} link
         * @returns {string}
         * @inner
         */
        function linkMetricValueText(link) {
            return !_.isEmpty(link.metrics) &&
                Math.toFixed3(link.metrics[_.keys(link.metrics)[0]].value) || '';
        }

        /**
         * Puts the text and textPath elements into the link group. This value is provided
         * by D3 and has a reference to svg element we are working on.
         * @param {MSGraphEdge} link
         * @inner
         */
        function newLinkMetricValue(link) {
            d3.select(this) // jshint ignore:line
                .append('text')
                .attr({
                    class: 'metric-value',
                    dy: metricValueMargin,
                })
                .append('textPath')
                .text(linkMetricValueText)
                .attr({
                    'xlink:href': function(d) {
                        return `#${linkKey(d)}`;
                    },
                    startOffset: '50%',
                });
        }

        /**
         * Updates the text (metric value) of the link's group textPath element. This value is
         * provided by D3 and has a reference to svg element we are working on.
         * @param {MSGraphEdge} link
         * @inner
         */
        function updateLinkMetricValue(link) {
            d3.select(this) // jshint ignore:line
                .select('text.metric-value > textPath')
                .text(linkMetricValueText);
        }

        /**
         * Returns a Node name from the node object. Adds ellypsis for long names.
         * @param {MSGraphNode} node
         * @returns {string}
         * @inner
         */
        function nodeName(node) {
            let name = '';

            if (!(node.obj_type === 'IP' && node.uuid === '0.0.0.0')) {
                if (typeof node.name === 'string' && node.name.length) {
                    if (node.name.length > 15) {
                        name = `${node.name.substr(0, 14)}\u2026`;
                    } else {
                        name = node.name;
                    }
                }
            }

            return name;
        }

        /**
         * Returns X position attribute value for the Node's name.
         * @param {MSGraphNode} node
         * @returns {number}
         * @inner
         */
        function nodeNameXPosition(node) {
            return getFullNodeRadius(node) + nameBackgroundPadding * 2;
        }

        /**
         * Returns the full radius (with all the borders) for the particular node.
         * @param {MSGraphNode} node
         * @returns {number}
         * @inner
         */
        function getFullNodeRadius(node) {
            let res = node.uiRadius;

            if (node.obj_type === 'virtualservice') {
                res += node.secured ? securedBorderWidth : vsBorderWidth;
                res += node.isolated && !!node.security_action ? listBorderWidth : 0;
            }

            return res;
        }

        /**
         * Returns Node's radius depending on servers quantity it has. Can vary from 1
         * default radius up to 2 default radiuses. Not radius but square relation is
         * preserved so that square of Node with 5 servers should be two times
         * smaller (radius will be Math.sqrt(2) times less) then square of the node with 10
         * servers (keeping in mind that min is equal to zero). For user node it is always 1
         * and for "IP" nodes - 0.6. Sets uiRadius property of the Node for further
         * processing. Used by repaint and populateLegend.
         * @param {MSGraphNode} node
         * @param {number=} minNodeServers - We need to know the min number of servers we
         *     have for VS to calculate the Node's radius. If not set we use the value of
         *     node as min and max value at the same time.
         * @param {number=} maxNodeServers - We need to know the max number of servers we
         *     have for VS to calculate the Node's radius.
         * @returns {number} From 0.6 to 2 * default Node's radius in pixels.
         * @inner
         */
        function getNodeRadius(node, minNodeServers, maxNodeServers) {
            const
                max = maxNodeServers || node.num_servers,
                min = minNodeServers || node.num_servers;

            let r = 1;

            if (node.obj_type === 'virtualservice' && !_.isUndefined(node.num_servers) &&
                !node.isolated) {
                if (max < min || node.num_servers > max || node.num_servers < min) {
                    throw new Error(`Max (${max}) & min (${min}) of Nodes's ` +
                        `servers mismatch. Current node servers quantity: ${
                            node.num_servers}`);
                }

                if (max - min > 0 && node.num_servers - min > 0) {
                    r = 2 * Math.sqrt((node.num_servers - min) / (max - min));
                    r = Math.max(1, r);
                }
            } else if (node.obj_type === 'IP' && node.uuid !== '0.0.0.0') {
                r = 0.6;
            }

            node.uiRadius = Math.toFixed3(r * defaultNodeRadius);

            return node.uiRadius;
        }

        /**
         * Puts or removes an extra circle underneath main Node's circle. Used as an extra
         * border around Node's circle. Currently used to show secured/normal or
         * white/black/no rule VS.
         * @param {MSGraphNode} node
         * @inner
         */
        function nodeBorderElement(node) {
            function updateBorder(borderElem, className, radius) {
                let target = 'circle.main';//border will be appended behind the target

                if (className === 'white-list' || className === 'black-list') {
                    if (!d3elem.select('circle.border.secured').empty()) {
                        target = 'circle.border.secured';
                    } else if (!d3elem.select('circle.border.vs').empty()) {
                        target = 'circle.border.vs';
                    }
                }

                if (borderElem.empty()) {
                    d3elem
                        .insert('circle', target)
                        .attr({
                            class: `border ${className}`,
                            r: Math.toFixed3(radius),
                        });
                } else {
                    borderElem.attr('r', Math.toFixed3(radius));
                }
            }

            const elem = this;

            let d3elem,
                listBorderRadius = node.uiRadius + listBorderWidth + 1,
                secBorder,
                wlBorder,
                blBorder,
                vsBorder;

            if (node.obj_type === 'virtualservice') {
                listBorderRadius += node.secured ? securedBorderWidth : vsBorderWidth;
                d3elem = d3.select(elem);

                vsBorder = d3elem.select('circle.border.vs');
                secBorder = d3elem.select('circle.border.secured');
                wlBorder = d3elem.select('circle.border.white-list');
                blBorder = d3elem.select('circle.border.black-list');

                if (node.secured) {
                    vsBorder.remove();
                    updateBorder(secBorder, 'secured', node.uiRadius + securedBorderWidth);
                } else {
                    secBorder.remove();
                    updateBorder(vsBorder, 'vs', node.uiRadius + vsBorderWidth);
                }

                if (node.isolated) {
                    switch (node.security_action) {
                        case whiteListSign:
                            blBorder.remove();
                            updateBorder(wlBorder, 'white-list', listBorderRadius);
                            break;

                        case blackListSign:
                            wlBorder.remove();
                            updateBorder(blBorder, 'black-list', listBorderRadius);
                            break;

                        default:
                            wlBorder.remove();
                            blBorder.remove();
                            break;
                    }
                } else {
                    wlBorder.remove();
                    blBorder.remove();
                }
            }
        }

        /**
         * Sets an attributes on the rectangle background of the Node's name.
         * This value is provided by D3 and has a reference to svg element we are working on.
         * @inner
         */
        function setNodeNameBackgroundRectAttributes() {
            const
                elem = this,
                nameNode = d3.select(elem.parentNode).select('text.name'),
                nameTextSize = nameNode[0][0].getBBox();

            d3.select(elem)
                .attr({
                    class: 'name-background',
                    x: nameNode.attr('x') - nameBackgroundPadding,
                    y: nameNode.attr('y') - textHeight,
                    rx: nameBackgroundPadding, //round borders
                    ry: nameBackgroundPadding,
                    width: nameTextSize.width + nameBackgroundPadding * 2,
                    height: nameTextSize.height,
                });
        }

        /**
         * Fulfils appMap legend with examples of Nodes and Links.
         * @param {d3.selection} legend - SVG placeholder inside the chart.
         */
        function populateLegend(legend) {
            const
                lineHeight = defaultNodeRadius * 1.75,
                y = [0, 137], //y coordinate for nodes and links groups
                legendNodes = [
                {
                    uuid: 'first',
                    name: 'Unsecured',
                    healthscore: 99,
                    obj_type: 'virtualservice',
                    num_servers: 1,
                    secured: false,
                }, {
                    uuid: 'second',
                    name: 'Secured',
                    healthscore: 99,
                    obj_type: 'virtualservice',
                    num_servers: 1,
                    secured: true,
                }, {
                    uuid: 'allow-list',
                    name: 'Allowed no traffic',
                    healthscore: 99,
                    obj_type: 'virtualservice',
                    num_servers: 1,
                    security_action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
                    isolated: true,
                }, {
                    uuid: 'deny-list',
                    name: 'Blocked no traffic',
                    healthscore: 99,
                    obj_type: 'virtualservice',
                    num_servers: 1,
                    security_action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_DENY',
                    secured: true,
                    isolated: true,
                }],
                legendLinks = [
                {
                    id: 'grey',
                    name: 'Allowed no drops',
                    policy_drops: 0,
                    target: { index: 0 },
                    source: { index: 1 },
                }, {
                    id: 'dashed-grey',
                    name: 'Allowed with drops',
                    policy_drops: 50,
                    security_action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
                    target: { index: 0 },
                    source: { index: 1 },
                }, {
                    id: 'solid-red',
                    name: 'Blocked',
                    policy_drops: 50,
                    target: { index: 0 },
                    source: { index: 1 },
                }];

            const nodes = legend.append('g')
                .attr({
                    class: 'nodes',
                    transform: `translate(0, ${y[0]})`,
                })
                .selectAll('g.node')
                .data(legendNodes, nodeKey)
                .enter()
                .append('g')
                .attr('class', nodeGroupClassName);

            nodes.append('circle')
                .attr({
                    r(node) {
                        return node.uiRadius = Math.toFixed3(getNodeRadius(node) * 0.4);
                    },
                    class: nodeCircleClassName,
                });

            nodes.attr('transform', function(node, i) {
                const r = getFullNodeRadius(node);

                return `translate(25, ${
                    r + lineHeight * i + Math.max(0, (lineHeight - 2 * r) / 2)})`;
            });

            nodes.each(function(node) {
                if (node.obj_type === 'virtualservice') {
                    nodeBorderElement.call(this, node);
                }
            });

            nodes.append('text')
                .attr({
                    class: 'name',
                    x: 28,
                    y: Math.toFixed3(textHeight * 0.4),
                })
                .text(function(node) {
                    return node.name;
                });

            let links = legend.append('g')
                .attr({
                    class: 'links',
                    transform: `translate(5, ${y[1]})`,
                });

            links = links.selectAll('g.link')
                .data(legendLinks, linkKey)
                .enter()
                .append('g')
                .attr('class', linkClassName);

            links.attr('transform', function(link, index) {
                return `translate(0, ${lineHeight * index})`;
            })
                .append('path')
                .attr({
                    id: linkKey,
                    'marker-end': linkMarkerEnd,
                    d() {
                        const x0 = 8;
                        const y0 = lineHeight * 0.85;

                        const x1 = x0 + 24;
                        const y1 = y0;

                        return ['M', x0, y0, 'L', x1, y1].join(' ');
                    },
                });

            links
                .append('text')
                .attr({
                    class: 'name',
                    x: 48,
                    y: Math.toFixed3(lineHeight),
                })
                .text(function(node) {
                    return node.name;
                });

            const separators = legend
                .append('g')
                .attr('class', 'separators');

            [140, 249].forEach(function(y) {
                separators.append('line')
                    .attr({
                        x1: 0,
                        x2: legendWidth,
                        y1: y,
                        y2: y,
                    });
            });
        }

        /**
         * Since we have different radius for different nodes we can't make links just between
         * their centers as arrow markers will become hidden that way (under the Node's body).
         * So we need to recalculate an appropriate position on the Node's circumference to
         * point our arrow marker from and to.
         * @param {MSGraphNode} source
         * @param {MSGraphNode} target
         * @param {boolean=} isCurvy - When we have two links between same Nodes we need to
         *     make such links curvy.
         * @returns {string} - SVG path d attribute value.
         */
        function getLinkPath(source, target, isCurvy) {
            /**
             * Calculates coordinate of the tangent point to be passed by Bezier Curve
             * connecting two nodes.
             * @param {number} x0 - Coordinate of start point.
             * @param {number} y0
             * @param {number} x1 - Coordinate of end point.
             * @param {number} y1
             * @returns {number[]} - X & Y coordinates of the point.
             * @inner
             */
            function getTangentPoint(x0, y0, x1, y1) {
                const dx = x0 - x1;
                const dy = y0 - y1;

                const distance = Math.sqrt((dx ** 2) + (dy ** 2));

                const sinAlpha = dy / distance;
                const cosAlpha = dx / distance;

                const cX = (x0 + x1) / 2;
                const cY = (y0 + y1) / 2;

                const cdX = sinAlpha * Math.sqrt(distance);
                const cdY = cosAlpha * Math.sqrt(distance);

                return [cX - cdX, cY + cdY];
            }

            /**
             * Calculates coordinates for two nicely selected control points of Bezier Curves.
             * @param {number} x0 - Coordinate of start point.
             * @param {number} y0
             * @param {number} x1 - Coordinate of the middle point.
             * @param {number} y1
             * @param {number} x2 - Coordinate of the end point.
             * @param {number} y2
             * @param {number} t - Coefficient defining the smoothness of the curve.
             * @returns {number[]}
             * @inner
             * @see {@link http://scaledinnovation.com/analytics/splines/aboutSplines.html}
             */
            function getControlPoints(x0, y0, x1, y1, x2, y2, t) {
                const
                    d01 = Math.sqrt(((x1 - x0) ** 2) + ((y1 - y0) ** 2)),
                    d12 = Math.sqrt(((x2 - x1) ** 2) + ((y2 - y1) ** 2)),
                    fa = t * d01 / (d01 + d12),
                    fb = t * d12 / (d01 + d12),
                    p1x = x1 - fa * (x2 - x0),
                    p1y = y1 - fa * (y2 - y0),
                    p2x = x1 + fb * (x2 - x0),
                    p2y = y1 + fb * (y2 - y0);

                return [p1x, p1y, p2x, p2y];
            }

            const margin = 8; //distance between arrow's end and the target Node

            let dx,
                dy,
                path,
                sinAlpha,
                curveTouchPoint,
                curveControlPoints;

            //distance between source and target (per axis)
            dy = Math.abs(source.y - target.y);
            dx = Math.abs(source.x - target.x);

            if (dx > 0 || dy > 0) {
                sinAlpha = dy / Math.sqrt((dx ** 2) + (dy ** 2));
            } else {
                throw new Error(`Can't draw a link between nodes "${source.uuid}" and "${
                    target.uuid}" which have the same position.`);
            }

            //target Node
            //distance between center of the Node and mounting point on circumference (per axis)
            let radius = target.uiRadius + (isCurvy ? 0 : margin);

            if (target.secured) {
                radius += securedBorderWidth;
            }

            dy = sinAlpha * radius;
            dx = Math.sqrt((radius ** 2) - (dy ** 2));

            //figure out the direction of differences (per axis)
            if (source.y < target.y) {
                dy *= -1;
            }

            if (source.x < target.x) {
                dx *= -1;
            }

            const targetX = target.x + dx;
            const targetY = target.y + dy;

            //source Node
            radius = source.uiRadius;

            if (source.secured) {
                radius += securedBorderWidth;
            }

            dy = sinAlpha * radius;
            dx = Math.sqrt((radius ** 2) - (dy ** 2));

            if (source.y > target.y) {
                dy *= -1;
            }

            if (source.x > target.x) {
                dx *= -1;
            }

            const sourceX = source.x + dx;
            const sourceY = source.y + dy;

            if (isCurvy) {
                curveTouchPoint = getTangentPoint(sourceX, sourceY, targetX, targetY);

                curveControlPoints = getControlPoints(sourceX, sourceY, curveTouchPoint[0],
                    curveTouchPoint[1], targetX, targetY, 0.5);

                path = ['M', sourceX, sourceY, 'C',
                    curveControlPoints[0], `${curveControlPoints[1]},`,
                    curveControlPoints[2], `${curveControlPoints[3]},`, targetX, targetY];
            } else {
                path = ['M', sourceX, sourceY, 'L', targetX, targetY];
            }

            return path.join(' ');
        }

        /**
         * Calculates the d attribute (ellipse basically) for the node's self-link path.
         * @param {MSGraphNode} node
         * @returns {string} SVG path d attribute value.
         */
        function getSelfLinkPath(node) {
            const r = getFullNodeRadius(node) - 1;

            const dx = Math.toFixed3(Math.cos(Math.PI / 4) * r);//for PI/4 dx & dy are equal
            const dy = dx;

            const x0 = Math.toFixed3(node.x + dx);
            const y0 = Math.toFixed3(node.y - dy);

            return ['M', x0, y0, 'A', 24, 16, -45, 1, 1, x0 + 1, y0 + 1].join(' ');
        }

        /**
         * To make metric values readable we sometimes need to rotate them for 180 degrees on
         * force.end event.
         * @param {MSGraphEdge} link
         * @returns {string|null} SVG Transform attribute value to rotate the value or null
         * to remove the attribute when rotation is not needed.
         * @inner
         */
        function updateLinkMetricValuesPosition(link) {
            const elem = this;

            let res = null;

            //can't use box size to figure out link direction
            const dx = link.source.x - link.target.x;
            const dy = link.source.y - link.target.y;

            const cosAlpha = dx / Math.sqrt((dx ** 2) + (dy, 2));

            if (cosAlpha > 0) {
                const box = elem.getBBox();//expensive and memory addicted

                const centerX = box.x + box.width / 2;
                const centerY = box.y + box.height / 2;

                res = ['rotate(180, ', centerX, ', ', centerY, ')'].join('');
            }

            return res;
        }

        /**
         * Has a JS Timeout id after the first Node's click.
         * Is set back to undefined on the second click or when timeout callback has been executed.
         * @type {number|undefined}
         * @inner
         */
        let waitingForSecondClick;

        //need this to avoid blinking on calling start().alpha(0) when we update values
        //wo reheating - 'start' and 'end' events in that case are simultaneous
        let startEventTimeout;

        /**
         * Returns an onClick handler for the Node elements such as name, circle and HS text.
         * Handles single (activates hyperlink) and double ("unfixes" the node) clicks. Skips
         * the drag events.
         * This value is provided by D3 and has a reference to svg element we are working on.
         * @param node {MSGraphNode}
         */
        function nodeClickHandler(node) {
            if (!d3.event.defaultPrevented) { //not a drag event
                if (_.isUndefined(waitingForSecondClick)) {
                    waitingForSecondClick = setTimeout(function() {
                        const linkDisabled = node.obj_type !== 'virtualservice' ||
                            !!scope.noRootClick && scope.data.getVsId() === node.uuid;

                        if (!linkDisabled) {
                            $state.go(
                                'authenticated.application.virtualservice-detail.app-map',
                                { vsId: node.uuid },
                            );
                        }

                        waitingForSecondClick = undefined;
                    }, 399);
                } else { //doubleclick
                    clearTimeout(waitingForSecondClick);
                    waitingForSecondClick = undefined;

                    //this provided by D3. As so, jshint, please no complains :)
                    d3.select(this).classed('fixed', node.fixed = false);// jshint ignore:line
                    force.resume();
                }
            }
        }

        /**
         * Highlights the group of immediate neighbours of hovered node.
         * @param {MSGraphNode} hoveredNode
         * @inner
         */
        function nodeMouseOverHandler(hoveredNode) {
            force.stop();
            highlight.draw(hoveredNode.uuid);
        }

        /**
         * Removes hovered group highlighting from the chart.
         * @inner
         */
        function nodeMouseOutHandler() {
            highlight.clear();
        }

        /**
         * Returns a cursor attribute value for the Node elements. Such as name, circle and HS.
         * @param node {MSGraphNode}
         */
        function nodeHoverCursor(node) {
            return node.obj_type !== 'virtualservice' || !!scope.noRootClick &&
                scope.data.getVsId() === node.uuid ? 'default' : 'pointer';
        }

        let
            svg, //DOM node
            legendCanvas,
            legend, //svg:group
            zw, //not zoomable area with zoomBehaviour attached, svg:group.zoom-wrap
            group, //zoomable area, main viewport, svg:group
            force, // d3.force
            drag, // d3.force.drag
            width = elm.width(), // viewport width
            height = elm.height(),
            zoom, //instance of zoom service
            highlight; // instance of highlighting service, undefined when !highlightEnabled

        const
            defaultNodeRadius = 18, // node's circle radius, should match less style variable
            textHeight = 15,
            nameBackgroundPadding = 3,
            securedBorderWidth = 4, //+1 node border/2, +2 secure color, +1 secure border/2
            vsBorderWidth = 3, //default VS node border, can't be used along with secured
            listBorderWidth = 2, //+1 node border/2, +1 list border/2
            metricValueMargin = -4,
            legendHeight = 250, //svg only height
            legendWidth = 210, //svg only width
            whiteListSign = 'NETWORK_SECURITY_POLICY_ACTION_TYPE_ALLOW',
            blackListSign = 'NETWORK_SECURITY_POLICY_ACTION_TYPE_DENY',
            svgPadding = defaultNodeRadius * 2;

        /**
         * Function, called on the resize event to update some private variables of the common
         * scope regarding the cart size.
         * @inner
         */
        function onResize() {
            /**
             * Updates position properties of all "fixed" Nodes proportionally.
             * @param {number} width
             * @param {number} height
             * @param {number} prevWidth
             * @param {number} prevHeight
             * @inner
             */
            function moveFixedNodes(width, height, prevWidth, prevHeight) {
                const cX = width / prevWidth;
                const cY = height / prevHeight;

                if (!_.isNaN(cX) && _.isFinite(cX) && cX > 0 &&
                    !_.isNaN(cY) && _.isFinite(cY) && cY > 0) {
                    _.each(cachedNodes, function(node) {
                        if (node.fixed) {
                            node.x *= cX;
                            node.px = node.x;//previous value, d3 force thing
                            node.y *= cY;
                            node.py = node.y;
                        }
                    });
                }
            }

            setTimeout(function() {
                moveFixedNodes(elm.width(), elm.height(), width, height);

                width = elm.width();
                height = elm.height();

                const size = {
                    width,
                    height,
                };

                svg.attr(size);

                zw.select('rect.background').attr(size);

                zoom.updateSize(width, height);

                force
                    .size([width, height])
                    .gravity(getGravityValue(width, height))
                    .charge(getForceChargeValue(cachedNodes.length))
                    .linkDistance(distanceBetweenNodes())
                    .start();
            }, 49);
        }

        /**
         * Calculates d3.charge value which depends on the Nodes quantity only.
         * @param {number} n - Number of Nodes to be shown on the chart.
         * @returns {number} Between -60000 and -500.
         * @inner
         */
        function getForceChargeValue(n) {
            n = n || 1;

            let coeff = -1 * 150000 / n;

            if (biDirectional) {
                coeff *= 1.5;
            }

            return Math.min(-500, Math.max(-60000, coeff));
        }

        /**
         * Returns d3.gravity value which depends of the viewport size and minimal scale value.
         * @param {number} width
         * @param {number} height
         * @returns {number}
         * @inner
         */
        function getGravityValue(width, height) {
            const r = minScale * Math.min(width, height) / 2 || 1;

            const coeff = 4 / (2 ** (r / 120));

            return Math.max(0.05, Math.min(1.5, coeff));
        }

        /**
         * Returns the preferred distance between graph nodes.
         * @returns {number}
         * @inner
         */
        function distanceBetweenNodes() {
            return defaultNodeRadius * 2;
        }

        /**
         * Middle layer between Nodes gotten from the backend and {@link d3.layout.force.nodes |
         * D3 force layout}
         * @type {MSGraphNode[]}
         * @inner
         */
        let cachedNodes = [];

        /**
         * Hash of the cachedNodes. To keep chart properties of the Node through updates.
         * @type {Object.<string, MSGraphNode>}
         * @inner
         */
        let nodesHash = {};

        /**
         * Middle layer between Links gotten from the backend and {@link d3.layout.force.links |
         * D3 force layout}.
         * Source and Target properties are replaced by references to the Nodes.
         * @type {MSGraphEdge[]}
         * @inner
         */
        let cachedLinks = [];

        /**
         * If we want to have one Nodes group on the right side of the root node and another on
         * the left side. Used for client/server/both type of layout.
         * @type {number}
         */
        const biDirectional = scope.layout === 'bi-directional' ? 1 : 0;

        /**
         * Whether we use highlighting functionality for this chart.
         * @type {boolean}
         * @inner
         */
        const highlightEnabled = !!scope.highlightEnabled;

        /**
         * Whether we show metric values on the edges of the nodes.
         * @type {boolean}
         */
        const showMetricValues = !!scope.metricValues;

        /**
         * Minimal scale value for chart. Set to one when not defined by directive attribute.
         * @type {number}
         */
        const minScale = !_.isUndefined(scope.minScale) ? +scope.minScale : 1;

        /**
         * Whether we should show a chart's legend.
         * @type {boolean}
         * @inner
         */
        const showLegend = _.isUndefined(scope.showLegend) || !!scope.showLegend;

        //since undefined shows legend need to update scope variable used in a template
        scope.showLegend = showLegend;

        /**
         * We show special message when had hit the API limit of 10 levels for a graph depth.
         * We get this number from the root VS eccentricity property.
         * @type {boolean}
         */
        scope.hitDepthLimit = false;

        /**
         * Toggles legend element class - to show or hide it. Legend's button ng-click.
         * @public
         */
        scope.toggleLegend = function() {
            legendCanvas.toggleClass('collapsed');
        };

        /**
         * Updates the CachedNodes and CachedLinks with new values from
         * MicroServiceGraphFactory. Preserves the current position and state for existing
         * nodes. On initial run sets x & y coordinates for the nodes on the chart depending
         * on its layout settings.
         * @inner
         */
        function updateData(updateStatus) {
            /**
             * Flips the id string of the link from source:target to target:source.
             * @param {string} linkId
             * @returns {string}
             * @inner
             */
            function flipLinkId(linkId) {
                return linkId.split(':').reverse().join(':');
            }

            const isolatedNodesHash = {};
            const isInitial = !cachedNodes.length;

            let newNodesHash = {},
                linksHash,
                /**
                 * If we have a user node on the chart.
                 * @type {boolean|number}
                 * */
                haveUserNode;

            if (updateStatus > 0) {
                if (updateStatus < 3) {
                    cachedNodes = angular.copy(scope.data.nodes);
                    cachedLinks = angular.copy(scope.data.links);

                    haveUserNode = !!_.findWhere(cachedNodes, {
                        obj_type: 'IP',
                        uuid: '0.0.0.0',
                    });

                    haveUserNode = haveUserNode ? 1 : 0;

                    //need to preserve some nodes properties
                    _.each(cachedNodes, function(node) {
                        newNodesHash[node.uuid] = node;

                        if (node.uuid in nodesHash) {
                            node.x = nodesHash[node.uuid].x;
                            node.px = nodesHash[node.uuid].px;
                            node.y = nodesHash[node.uuid].y;
                            node.py = nodesHash[node.uuid].py;
                            node.fixed = nodesHash[node.uuid].fixed;
                            node.uiRadius = nodesHash[node.uuid].uiRadius;
                        }

                        if (node.isolated) {
                            isolatedNodesHash[node.uuid] = true;
                        }
                    });

                    linksHash = _.reduce(cachedLinks, function(hash, link) {
                        hash[link.id] = true;

                        return hash;
                    }, {});

                    _.each(cachedLinks, function(link) {
                        const flippedId = flipLinkId(link.id);

                        link.bidirectional = flippedId in linksHash && link.id !== flippedId;
                    });

                    nodesHash = newNodesHash;
                    newNodesHash = null;
                } else {
                    if (updateStatus === 3) {
                        _.each(scope.data.nodes, function(updNode) {
                            angular.extend(nodesHash[updNode.uuid], updNode);
                        });
                    }

                    linksHash = _.reduce(cachedLinks, function(hash, link) {
                        hash[link.id] = link;

                        return hash;
                    }, {});

                    _.each(scope.data.links, function(updLink) {
                        angular.extend(linksHash[updLink.id],
                            _.pick(updLink, ['metrics', 'policy_drops']));
                    });
                }

                // draw from scratch
                if (updateStatus === 1 && isInitial && cachedNodes.length) {
                    //to have some room between border and chart on initial drawing
                    const r = Math.min(height - svgPadding * 2 -
                                haveUserNode * defaultNodeRadius * 2,
                        width - svgPadding * 2) / 2,
                        x0 = Math.toFixed3(width / 2);

                    let y0;

                    //client/server/both view with root node at the center
                    if (biDirectional) {
                        const nodeGroups = {};

                        y0 = 0;

                        _.each(cachedNodes, function(node) {
                            const dAngle = Math.PI / 20;
                            let i,
                                groupAngle,
                                nodeAngle;

                            if (!_.isUndefined(node.groupId)) {
                                if (!(node.groupId in nodeGroups)) {
                                    nodeGroups[node.groupId] = {
                                        currentId: 0,
                                    };
                                } else {
                                    nodeGroups[node.groupId].currentId++;
                                }

                                i = nodeGroups[node.groupId].currentId;

                                switch (node.groupId) {
                                    case 'server'://left
                                        groupAngle = Math.PI / 2;
                                        nodeAngle = groupAngle + (i % 2 ? 1 : -1) *
                                            (Math.floor(i / 2) + 1) * dAngle;
                                        break;

                                    case 'client'://right
                                        groupAngle = 3 * Math.PI / 2;
                                        nodeAngle = groupAngle + (i % 2 ? 1 : -1) *
                                            (Math.floor(i / 2) + 1) * dAngle;
                                        break;

                                    case 'both'://top and bottom
                                        groupAngle = i % 2 ? Math.PI : 0;
                                        nodeAngle = groupAngle +
                                            (Math.floor(i / 2) % 2 ? -1 : 1) *
                                            Math.floor(i / 2);
                                        break;
                                }

                                node.x = Math.toFixed3(x0 + r * Math.sin(nodeAngle));
                                node.y = Math.toFixed3(y0 + r * (1 - Math.cos(nodeAngle)));
                            } else if (!node.isolated) { //root node only
                                node.x = x0;
                                node.y = Math.toFixed3(height / 2);
                                node.fixed = true;
                            }
                        });
                    } else { //initial positions for all nodes on a circumference
                        y0 = Math.toFixed3(svgPadding + haveUserNode * defaultNodeRadius * 3);

                        _.each(cachedNodes, function(node, i) {
                            const angle = 2 * Math.PI * i /
                                (cachedNodes.length - haveUserNode - _.size(isolatedNodesHash));

                            if (node.obj_type === 'IP' && node.uuid === '0.0.0.0') {
                                node.x = x0;
                                node.y = y0 - defaultNodeRadius * 2;
                                node.fixed = true;
                            } else {
                                node.x = Math.toFixed3(x0 + r * Math.sin(angle));
                                node.y = Math.toFixed3(y0 + r * (1 - Math.cos(angle)));
                            }
                        });
                    }
                }
            }
        }

        /**
         * Main function initializing the layout and drawing the chart. Called by the watcher of
         * the nodes and links arrays.
         * @param {number} updateStatus
         * @inner
         */
        function repaint(updateStatus) {
            function getNodeRadius_(node) {
                return getNodeRadius(node, minNodeServers, maxNodeServers);
            }

            const isInitial = !cachedNodes.length;

            let nodes,
                links,
                newNode,
                newLink,
                i = 0,
                maxNodeServers,
                minNodeServers;

            if (updateStatus > 0) {
                updateData(updateStatus);

                scope.hitDepthLimit = !!cachedNodes.length &&
                    nodesHash[scope.data.getVsId()].eccentricity > 10;

                _.each(cachedNodes, function(node) {
                    const serversQ = node.num_servers;

                    if (node.obj_type === 'virtualservice' && !_.isUndefined(serversQ)) {
                        if (_.isUndefined(maxNodeServers)) {
                            maxNodeServers = serversQ;
                        } else {
                            maxNodeServers = Math.max(maxNodeServers, serversQ);
                        }

                        if (_.isUndefined(minNodeServers)) {
                            minNodeServers = serversQ;
                        } else {
                            minNodeServers = Math.min(minNodeServers, serversQ);
                        }
                    }
                });

                //if none of them had servers
                if (_.isUndefined(minNodeServers)) {
                    maxNodeServers = 0;
                    minNodeServers = 0;
                }

                force
                    .stop()
                    .nodes(cachedNodes)
                    .links(cachedLinks)
                    .start()//update settings wo reheating
                    .alpha(0);

                nodes = group.selectAll('g.node')
                    .data(force.nodes(), nodeKey);

                links = group.selectAll('g.link')
                    .data(force.links(), linkKey);

                //when list got updated we need to remove some and add some nodes/links
                if (updateStatus < 3) {
                    force
                        .charge(getForceChargeValue(cachedNodes.length))
                        .gravity(getGravityValue(width, height))
                        .linkDistance(distanceBetweenNodes());

                    links
                        .exit()
                        .remove();

                    newLink = links
                        .enter()
                        .insert('g', 'g.node')
                        .attr('class', linkClassName);

                    newLink
                        .append('path')
                        .attr({
                            id: linkKey,
                            'marker-start': linkMarkerStart,
                            'marker-end': linkMarkerEnd,
                        });

                    if (showMetricValues) {
                        newLink.each(newLinkMetricValue);
                    }

                    nodes
                        .exit()
                        .remove();

                    newNode = nodes
                        .enter()
                        .append('g')
                        .attr('class', nodeGroupClassName)
                        .call(drag)
                        .on('click', nodeClickHandler);

                    if (highlightEnabled) {
                        newNode.on({
                            mouseover: nodeMouseOverHandler,
                            mouseout: nodeMouseOutHandler,
                        });
                    }

                    newNode.append('circle')
                        .attr({
                            r: getNodeRadius_,
                            class: nodeCircleClassName,
                            cursor: nodeHoverCursor,
                        });

                    newNode.each(function(node) {
                        if (node.obj_type === 'IP' && node.uuid === '0.0.0.0') {
                            d3.select(this)
                                .append('svg:foreignObject')
                                .attr({
                                    x: -defaultNodeRadius,
                                    y: -defaultNodeRadius,
                                    height: Math.toFixed3(defaultNodeRadius * 2),
                                    width: Math.toFixed3(defaultNodeRadius * 2),
                                })
                                .append('xhtml:div')
                                .classed('container', true)
                                .html('<i class="icon icon-user"/>');
                        } else if (node.obj_type === 'virtualservice') {
                            d3.select(this)
                                .append('text')
                                .attr({
                                    class: 'hs',
                                    'text-anchor': 'middle',
                                    y: Math.toFixed3(textHeight * 0.4),
                                    cursor: nodeHoverCursor,
                                })
                                .text(nodeHsText);

                            nodeBorderElement.call(this, node);
                        }
                    });

                    newNode.append('text')
                        .attr({
                            class: 'name',
                            x: nodeNameXPosition,
                            y: Math.toFixed3(textHeight * 0.4),
                            cursor: nodeHoverCursor,
                        })
                        .text(nodeName);

                    newNode
                        .insert('rect', 'text.name')
                        .each(setNodeNameBackgroundRectAttributes);

                    if (highlightEnabled) {
                        highlight.updateGraph(cachedLinks);
                    }

                    force.start();

                    if (isInitial) {
                        zoom.setDefaultScale();

                        //to avoid initial animation we force calculations
                        while (force.alpha() > 0.5e-2 && i < 150) {
                            force.tick();
                            i++;
                        }

                        force.stop();

                        //need to flip metric values manually since force start and end
                        // events happen simultaneously on initial drwaing
                        group.selectAll('g.link text.metric-value')
                            .attr('transform', updateLinkMetricValuesPosition);
                    }
                }

                //otherwise just update the existing ones
                links.attr('class', linkClassName)
                    .select('path')
                    .attr({
                        'marker-start': linkMarkerStart,
                        'marker-end': linkMarkerEnd,
                    });

                if (showMetricValues) {
                    links.each(updateLinkMetricValue);
                }

                nodes
                    .selectAll('circle.main')
                    .attr({
                        r: getNodeRadius_,
                        class: nodeCircleClassName,
                    });

                nodes
                    .attr('class', nodeGroupClassName)
                    .each(nodeBorderElement)
                    .selectAll('text.hs')
                    .text(nodeHsText);

                nodes
                    .selectAll('text.name')
                    .attr('x', nodeNameXPosition)
                    .text(nodeName);

                nodes.selectAll('rect.name-background')
                    .each(setNodeNameBackgroundRectAttributes);

                if (highlightEnabled) { //need to update classes
                    highlight.draw();
                }

                //can't call force.tick on updateStatus > 2 as chart starts shivering on rt
            }
        }

        svg = d3.select(elm[0])
            .append('svg')
            .attr({
                width,
                height,
            });

        if (showLegend) {
            legendCanvas = elm.find('>div.main-legend');

            legend = d3.select(legendCanvas[0])
                .insert('svg', 'div.text-legend')
                .attr({
                    width: legendWidth,
                    height: legendHeight,
                })
                .append('g')
                .attr('class', 'legend');

            populateLegend(legend);
        }

        zw = svg.insert('g', 'g.legend')
            .attr('class', 'zoom-wrapper');

        zw.append('rect')
            .attr({
                class: 'background',
                width,
                height,
            });

        group = zw
            .append('g')
            .attr('class', 'wrapper');

        svg.append('defs')
            .selectAll('marker')
            .data(['arrow', 'arrow-red', 'arrow-self-ref-start', 'arrow-self-ref-end',
                'arrow-self-ref-start-red', 'arrow-self-ref-end-red'])
            .enter()
            .append('marker')
            .attr({
                id: angular.identity,
                class: angular.identity,
                viewBox: '0 -7 20 20',
                markerWidth: 9,
                markerHeight: 9,
                refX(l) {
                    let res;

                    if (l.indexOf('arrow-self-ref-') === -1) { //normal
                        res = 18;
                    } else if (l.indexOf('arrow-self-ref-start') === -1) {
                        res = -42; //self-ref-end
                    } else {
                        res = 60; //self-ref-start
                    }

                    return res;
                },
                refY(l) {
                    let res;

                    if (l.indexOf('arrow-self-ref-') === -1) { //normal
                        res = 0;
                    } else if (l.indexOf('arrow-self-ref-start') === -1) {
                        res = -41; //self-ref-end
                    } else {
                        res = -28; //self-ref-start
                    }

                    return res;
                },
                orient(l) {
                    let res;

                    if (l.indexOf('arrow-self-ref-') === -1) { //normal
                        res = 'auto';
                    } else if (l.indexOf('arrow-self-ref-start') === -1) {
                        res = -52; //self-ref-end
                    } else {
                        res = 128; //self-ref-start
                    }

                    return res;
                },
            })
            .append('path')
            .attr('d', 'M0,-7L20,0L0,7');

        force = d3.layout.force()
            .gravity(getGravityValue(width, height))
            .charge(getForceChargeValue(cachedNodes.length))
            .friction(0.5)
            .linkStrength(function(link) {
                return link.bidirectional ? 0.75 : 0.5;
            })
            .size([width, height]);

        drag = force.drag()
            .on('dragstart', function(d) {
                force.stop();

                if (highlightEnabled) {
                    highlight.disable();
                }

                d3.select(this).classed('fixed', d.fixed = true);
                d3.event.sourceEvent.stopPropagation();
            });

        if (highlightEnabled) {
            drag.on('dragend', function() {
                highlight.enable();
            });
        }

        /**
         * Bidirectional layout uses group dependant gravity settings meaning center of gravity
         * position and its multiplier.
         * @param {number} width - Chart width, assume that root node is placed in center.
         * @param {number} height - Chart height, assume that root node is placed in center.
         * @returns {{groupName: {center: number[] | function, kMult: number}}} - Returns a hash of
         * settings for all possible node groups besides `user` which is not supposed to be
         * rendered by bidirectional layout. Center keeps gravity center coordinates or a function
         * to be called with a node as only argument to calculate gravity center position based
         * on node's position.
         * @inner
         */
        function getBidirectionalGravityParams(width, height) {
            const gravityCenterBorderProximity = 1 / 8;

            return ['server', 'client', 'both', 'root'].reduce(function(hash, nodeGroup) {
                const params = {
                    center: [width / 2, height / 2],
                    kMult: 1,
                };

                switch (nodeGroup) {
                    case 'client'://left
                        params.center[0] = width * gravityCenterBorderProximity;
                        break;

                    case 'server'://right
                        params.center[0] = width * (1 - gravityCenterBorderProximity);
                        break;

                    case 'both'://centered top or bottom
                        params.center = function(node) {
                            return [
                                width / 2,
                                height * (node.y >= height / 2 ? 1 - gravityCenterBorderProximity :
                                    gravityCenterBorderProximity)];
                        };

                        params.kMult = 2;
                        break;
                }

                hash[nodeGroup] = params;

                return hash;
            }, {});
        }

        force.on('tick', function(event) { //thousands of calls!
            const
                aspectRatio = width / height, //how far are we from square
                regularK = event.alpha * force.gravity(),
                nodes = group.selectAll('g.node');

            let
                xK = regularK,
                yK = regularK,
                weakenKAxis,
                gravityParamsHash;

            //at first we want just to update nodes data properties (like x & y), wo applying them
            if (regularK) {
                //if viewport is not close to square we want to weaken gravity of prevailing axis
                if (Math.abs(aspectRatio - 1) > 0.25) {
                    //which axis do we want to expand by reducing k
                    weakenKAxis = aspectRatio > 1 ? 'x' : 'y';

                    if (weakenKAxis === 'x') {
                        xK *= 1 / aspectRatio;
                    } else {
                        yK *= aspectRatio;
                    }
                }

                if (!biDirectional) { //regular layout
                    if (weakenKAxis) {
                        //play back the default transformation made by d3 and apply a `weakened` one
                        //default transformation is: node.x += (x - o.x) * regularK; or same for y
                        nodes.each(function(node) {
                            if (weakenKAxis === 'x') {
                                node.x = (node.x - regularK * width / 2) / (1 - regularK);
                                node.x += (width / 2 - node.x) * xK;
                            } else {
                                node.y = (node.y - regularK * height / 2) / (1 - regularK);
                                node.y += (height / 2 - node.y) * yK;
                            }
                        });
                    }
                } else { //biDirectional view
                    gravityParamsHash = getBidirectionalGravityParams(width, height);

                    nodes.each(function(node) {
                        const gravityParams = gravityParamsHash[node.groupId || 'root'];

                        const gravityCenter = typeof gravityParams.center === 'function' ?
                            gravityParams.center.call(undefined, node) : gravityParams.center;

                        //revert default transformations
                        node.x = (node.x - regularK * width / 2) / (1 - regularK);
                        node.y = (node.y - regularK * height / 2) / (1 - regularK);

                        //apply modified ones
                        node.x += (gravityCenter[0] - node.x) * xK * gravityParams.kMult;
                        node.y += (gravityCenter[1] - node.y) * yK * gravityParams.kMult;
                    });
                }
            }

            // here we need to apply modified coordinates to the canvas by moving nodes around and
            // control SVG boundaries at the same time
            nodes.attr('transform', function(node) {
                const margin = getFullNodeRadius(node) + 2;

                node.x = Math.max(margin + 1, Math.min(width - margin - 1, node.x));
                node.y = Math.max(margin + 1, Math.min(height - margin - 2, node.y));

                return `translate(${node.x},${node.y})`;
            });

            group.selectAll('g.link').select('path')
                .attr('d', function(link) {
                    let res = null;

                    if (link.source.index === link.target.index) {
                        res = getSelfLinkPath(link.source);
                    } else if (link.source.x !== link.target.x ||
                        link.source.y !== link.target.y) {
                        res = getLinkPath(link.source, link.target, link.bidirectional);
                    }

                    return res;
                });

            //Let's consider graph stabilized slightly earlier (default value is 0.005).
            if (force.alpha() < 0.015) {
                force.stop();
            }
        });

        //to flip metric values which can be upside-down since they just follow link paths
        if (showMetricValues) {
            force.on('start', function() {
                //need to hide metric values and remove transformation
                if (!_.isUndefined(startEventTimeout)) {
                    clearTimeout(startEventTimeout);
                }

                startEventTimeout = setTimeout(function() {
                    group.classed('animation-in-progress', true);

                    group.selectAll('g.link text.metric-value')
                        .attr('transform', null);

                    startEventTimeout = undefined;
                }, 9);
            });

            force.on('end', function() {
                if (!_.isUndefined(startEventTimeout)) {
                    clearTimeout(startEventTimeout);
                    startEventTimeout = undefined;
                } else {
                    group.classed('animation-in-progress', false);

                    group.selectAll('g.link text.metric-value')
                        .attr('transform', updateLinkMetricValuesPosition);
                }
            });
        }

        zoom = new MsMapGraphChartZoomFactory({
            minScale,
            maxScale: minScale * 4,
            width,
            height,
            canvas: zw,
            buttons: elm.find('> div.zoom-buttons > button'),
        });

        scope.zoomButtonClick = zoom.buttonClick.bind(zoom);

        zw.call(zoom.behaviour)
            .on('dblclick.zoom', null);

        if (highlightEnabled) {
            highlight = new MsMapGraphChartHighlightFactory({ canvas: group });
        }

        scope.$on('$repaintViewport', onResize);
        scope.$on('repaint', onResize);

        scope.data.on('dataUpdate', repaint);

        repaint(1);

        scope.$on('$destroy', function() {
            const timeouts = [waitingForSecondClick, startEventTimeout];

            scope.data.unbind('dataUpdate', repaint);

            timeouts.forEach(function(timeout) {
                if (!_.isUndefined(timeout)) {
                    clearTimeout(timeout);
                }
            });
        });
    }

    return {
        restrict: 'E',
        scope: {
            data: '=graph',
            metricValues: '@',
            noRootClick: '@',
            layout: '@',
            highlightEnabled: '@',
            minScale: '@',
            showLegend: '@',
        },
        link,
        templateUrl: 'src/views/application/ms-map-graph-chart.html',
    };
}]);
