/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc service
 * @name cloudService
 * @description
 *     Handles interactions between cloud related controllers and directives.
 */
angular.module('avi/cloud').service('cloudService', CloudService);

CloudService.$inject = [
    'messageService',
    'Schema',
    '$compile',
    '$templateCache',
    '$rootScope',
    'AviModal',
    '$http',
    '$q',
    'AviAlertService',
];

/**
 * Cloud service constructor.
 * @param {MessageService} messageService
 * @param {Object} Schema
 * @param {angular.$compile} $compile
 * @param {ng.$templateCache} $templateCache
 * @param {angular.$scope} $rootScope
 * @param {AviModal} AviModal
 * @param {angular.$http} $http
 * @param {angular.$q} $q
 * @param {Object} AviAlertService
 * @constructor
 */
function CloudService(
        messageService,
        Schema,
        $compile,
        $templateCache,
        $rootScope,
        AviModal,
        $http,
        $q,
        AviAlertService,
) {
    /**
     * @type {Object}
     */
    this.Schema = Schema;

    /**
     * @type {MessageService}
     */
    this.messageService = messageService;

    /**
     * @type {string}
     */
    this.cloudStatusText = '';

    /**
     * @type {angular.$compile}
     */
    this.$compile = $compile;

    /**
     * @type {angular.$templateCache}
     */
    this.$templateCache = $templateCache;

    /**
     * @type {angular.$rootScope}
     */
    this.$rootScope = $rootScope;

    /**
     * @type {AviModal}
     */
    this.AviModal = AviModal;

    /**
     * @type {angular.$http}
     */
    this.$http = $http;

    /**
     * @type {angular.$q}
     */
    this.$q = $q;

    /**
     * @type {Object}
     */
    this.AviAlertService = AviAlertService;

    /**
     * @type {?angular.Promise}
     */
    this.httpTimeout = null;

    /**
     * @type {?angular.element}
     */
    this.compiledTemplate = null;

    /**
     * @type {string}
     */
    this.messageId = 'cloudStatusPopup';

    /**
     * @type {Cloud[]}
     */
    this.cloudItems = [];

    /**
     * @type {Array}
     */
    this.dismissedCloudIDs = [];

    /**
     * @type {string}
     */
    this.currentStatusID = '';

    /**
     * Processed cloud status object from diag API.
     * @type {Object}
     */
    this.cloudStatusDetails = {};

    /**
     * Flag for when /api/vimgrvcenterruntime/vcenter/diag API is active.
     * @type {boolean}
     */
    this.loadingDiag = false;
}

/**
 * Checks the provided data if cloud status message should be displayed.
 * @param {Cloud[]} items - Cloud Items array.
 */
CloudService.prototype.displayCloudDataStatus = function(items) {
    this.cloudItems = items;

    const schemaValues = this.Schema.enums.CloudState.values;
    let nothingToDisplay = true;

    _.each(items, function(item) {
        const { status } = item.data;

        if (status) {
            const { state } = status;

            if (state in schemaValues) {
                status.text = schemaValues[state].options.e_description.value;

                if (state == 'CLOUD_STATE_PLACEMENT_READY' && status.reason) {
                    nothingToDisplay = !this.displayCloudStatus(item.data);
                }
            }
        }
    }, this);

    if (nothingToDisplay) {
        this.removeMessage();
    }
};

/**
 * Displays cloud data for specified cloud data object.
 * @param {Object} item - Cloud data object.
 * @returns {boolean} True if specified item can be displayed..
 */
CloudService.prototype.displayCloudStatus = function(item) {
    const cloudUUID = item.config.uuid;

    if (this.dismissedCloudIDs.indexOf(cloudUUID) > -1) {
        return false;
    }

    if (this.currentStatusID != cloudUUID) {
        const templateElement = this.compileTemplate_();

        if (!document.body.contains(templateElement[0])) {
            const templateParent = document.querySelector('.messages') || document.body;

            templateParent.appendChild(templateElement[0]);
        }

        this.currentStatusID = cloudUUID;
        this.cloudStatusText = this.getStatusMessage(item);
        this.messageService.show(this.messageId);
    }

    return true;
};

/**
 * Returns CSS selector for cloud status icon.
 * @param {string} state - Cloud state enum
 * @returns {string} CSS class name for status icon
 * @static
 */
CloudService.getStatusIconClass = function(state) {
    const STATUS = 'status-';

    switch (state) {
        case 'CLOUD_STATE_IN_PROGRESS':
        case 'CLOUD_STATE_DELETING':
            return `${STATUS}in-progress`;

        case 'CLOUD_STATE_UNKNOWN':
            return `${STATUS}unknown`;

        case 'CLOUD_STATE_PLACEMENT_READY':
            return `${STATUS}ready`;

        case 'CLOUD_STATE_FAILED':
            return `${STATUS}failed`;
    }

    return '';
};

/**
 * Returns cloud status message.
 * @param {Object} item - Cloud data object.
 * @returns {string}
 */
CloudService.prototype.getStatusMessage = function(item) {
    return `${item.config.name}: ${item.status.reason || ''}`;
};

/**
 * Hides cloud status popup message and displays next message if available.
 */
CloudService.prototype.dismissMessage = function() {
    this.messageService.hide(this.messageId);

    if (this.currentStatusID) {
        this.dismissedCloudIDs.push(this.currentStatusID);
        this.currentStatusID = '';
    }

    this.displayCloudDataStatus(this.cloudItems);
};

/**
 * Removes popup message from DOM, but does not dismisses it, meaning same message
 * can be recreated again on next {@link displayCloudStatus} call.
 */
CloudService.prototype.removeMessage = function() {
    this.messageService.remove(this.messageId);
    this.compiledTemplate = null;
    this.currentStatusID = '';
};

/**
 * Opens modal window with additional cloud status details for {@link currentStatusID}.
 * @param {string=} cloudId - Optional cloud id to display status for.
 */
CloudService.prototype.openCloudStatusDetails = function(cloudId) {
    cloudId = cloudId || this.currentStatusID;

    if (cloudId) {
        if (this.httpTimeout) {
            this.httpTimeout.resolve();
        }

        this.AviModal.open('infra-cloud-status-details');
        this.cloudStatusDetails = {};
        this.httpTimeout = this.$q.defer();
        this.loadingDiag = true;
        this.$http.post('/api/vimgrvcenterruntime/vcenter/diag', {
            cloud_uuid: cloudId,
        }, { timeout: this.httpTimeout.promise }).then(({ data }) => {
            const { resource } = data;

            if (!resource) {
                return;
            }

            const { vcenter_inventory_diag_rsp: vCenter } = resource;
            const { networks } = vCenter;
            const { fields: schemaFields } = this.Schema.pb.VcenterHostDiag;
            const cloudStatusDetails = {
                hostErrorCount: vCenter.errored_hosts || 0,
            };

            if (networks) {
                cloudStatusDetails.overlappingSubnets = networks.overlapping_subnets;
                cloudStatusDetails.managementNetwork = networks.mgmt_nw_diag;
            }

            if (vCenter.hosts) {
                cloudStatusDetails.hosts = vCenter.hosts;
                cloudStatusDetails.hosts.forEach(function(host) {
                    if (!_.has(host, 'powerstate_up')) {
                        host.powerstate_up = schemaFields.powerstate_up.default;
                    }

                    if (!_.has(host, 'maintenance_mode')) {
                        host.maintenance_mode = schemaFields.maintenance_mode.default;
                    }

                    if (!_.has(host, 'connection_state')) {
                        host.connection_state = schemaFields.connection_state.default;
                    }

                    if (!_.has(host, 'cntlr_accessible')) {
                        host.cntlr_accessible = schemaFields.cntlr_accessible.default;
                    }

                    if (!_.has(host, 'quarantined')) {
                        host.quarantined = schemaFields.quarantined.default;
                    }
                });
            }

            let hasNetworkErrors = false;

            if (networks && cloudStatusDetails.managementNetwork &&
                    cloudStatusDetails.managementNetwork.is_static) {
                const mgmNtwk = cloudStatusDetails.managementNetwork;

                hasNetworkErrors = mgmNtwk.static_ip_pool_exhausted ||
                        mgmNtwk.static_no_ip_pool || mgmNtwk.static_subnet_not_present;
            }

            cloudStatusDetails.hasNetworkErrors = hasNetworkErrors;

            const hasOverlappingSubnets = cloudStatusDetails.overlappingSubnets &&
                    cloudStatusDetails.overlappingSubnets.length > 0;

            if (vCenter.errored_hosts > 0 || hasOverlappingSubnets || hasNetworkErrors) {
                this.cloudStatusDetails = cloudStatusDetails;
            } else {
                this.AviAlertService.throw('No details to display');
            }
        }).catch(({ data }) => {
            if (data) {
                this.AviAlertService.throw(data.error);
            }
        }).finally(() => this.loadingDiag = false);
    }
};

/**
 * Hides cloud status details modal windows.
 */
CloudService.prototype.closeCloudStatusDetails = function() {
    this.AviModal.destroy('infra-cloud-status-details');

    if (this.httpTimeout) {
        this.httpTimeout.resolve();
    }
};

/**
 * Compiles cloud message popup template.
 * @returns {angular.element}
 * @private
 */
CloudService.prototype.compileTemplate_ = function() {
    if (this.compiledTemplate) {
        return this.compiledTemplate;
    }

    this.compiledTemplate = this.$compile(
        require('./cloud-status-popup.partial.html'),
    )(this.$rootScope);

    return this.compiledTemplate;
};

export { CloudService };
