/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

angular.module('aviApp').factory('AlertActionCollection', ['Collection', 'AlertAction',
    function(Collection, AlertAction) {
        const AlertActionCollection = function(oArgs) {
            AlertActionCollection.superconstructor.call(this, oArgs);
        };

        avi.inherit(AlertActionCollection, Collection);

        AlertActionCollection.prototype.objectName_ = 'actiongroupconfig';
        AlertActionCollection.prototype.itemClass_ = AlertAction;
        AlertActionCollection.prototype.windowElement_ = 'adm-alertaction-create';
        AlertActionCollection.prototype.defaultDataSources_ = 'list';

        AlertActionCollection.prototype.allDataSources_ = {
            list: {
                source: 'ListCollDataSource',
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        return AlertActionCollection;
    }]);

angular.module('aviApp').factory('AlertAction', ['Item', function(Item) {
    const AlertAction = function(oArgs) {
        AlertAction.superconstructor.call(this, oArgs);
    };

    avi.inherit(AlertAction, Item);

    AlertAction.prototype.objectName = 'actiongroupconfig';
    AlertAction.prototype.windowElement = 'adm-alertaction-create';

    return AlertAction;
}]);
