/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc directive
 * @name hostWithTokensValidator
 * @restrict A
 * @author Igor Kucheinik
 * @description
 *
 *     Validates tokenized string like HOST[1].PATH[2:3].
 *
 */
angular.module('aviApp').directive('hostWithTokensValidator', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, elm, attr, ctrl) {
            const regExp = '^(HOST|PATH|H|P|SG_MAP|StringGroup_Map|SG_RE|StringGroup_Regex)' +
                    '((\\[(\\d+):(\\d*)])|(\\[(\\d+)])|(\\[])?)$';

            function validToken(item) {
                return new RegExp(regExp, 'i').test(item);
            }

            function foundSpecialChars(item) {
                return /[!|@|#|$|%|^|&|*|(|)|[|\]]/g.test(item);
            }

            $(elm).on('keyup', function() {
                const val = $(elm).val().split('.');

                ctrl.$setValidity('tokens', !_.any(val, function(item) {
                    return !validToken(item) && foundSpecialChars(item);
                }));
            });
        },
    };
});
