/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

const mixin = {
    /**
     * True when all rows are selected.
     * @type {boolean}
     */
    allRowsSelected: false,

    /**
     * True when no rows are selected.
     * @type {boolean}
     */
    noRowsSelected: true,

    /**
     * Hash of selected rows, all rows are expected to be present. Default value only,
     * has to be overwritten in constructor otherwise will be polluted and shared between
     * instances.
     * @type {{string: boolean}}
     * @readonly
     */
    selectedRowsHash: {},

    /**
     * Returns true for currently selected rows.
     * @param {string} rowId
     * @returns {boolean}
     */
    isRowSelected(rowId) {
        return this.selectedRowsHash[rowId];
    },

    /**
     * Adds selected flag for new rows and removes entries for rows not present.
     * Updates boolean flags of selectedRowsHash as well.
     * @param {boolean=} fromList
     * @protected
     */
    updateSelectedHash_(fromList = true) {
        const {
            filteredRows,
            selectedRowsHash,
            config,
        } = this;

        const
            filteredRowsHash = {},
            checkboxCheck = angular.isFunction(config.checkboxDisable);

        if (fromList) {
            filteredRows.forEach(row => {
                const rowId = this.rowId(row);

                if (!(rowId in filteredRowsHash)) {
                    filteredRowsHash[rowId] = row;

                    if (!(rowId in selectedRowsHash)) {
                        selectedRowsHash[rowId] = this.allRowsSelected &&
                                !(checkboxCheck || config.checkboxDisable(row));
                    }
                } else {
                    console.error(
                        'Regular grid keeps rows with duplicated ids. ' +
                            'rowId for the row: %s, Rows: %O, rowId property: %O, config: %O',
                        rowId,
                        filteredRows,
                        config.rowId,
                        config,
                    );
                }
            });

            const selectedRowIdsToRemove = [];

            _.each(selectedRowsHash, (boolVal, rowId) => {
                if (!(rowId in filteredRowsHash)) {
                    selectedRowIdsToRemove.push(rowId);
                }
            });

            //removing hash entries not present in the list anymore
            selectedRowIdsToRemove.forEach(rowId => delete selectedRowsHash[rowId]);

            this.allRowsSelected = this.allRowsSelectedCheck_();
            this.noRowsSelected = this.noRowsSelectedCheck_();
        } else if (this.allRowsSelected) {
            //TODO add checkbox disabled flag
            _.each(selectedRowsHash, (value, key) => {
                selectedRowsHash[key] = true;
            });
        } else if (this.noRowsSelected) {
            _.each(selectedRowsHash, (value, key) => {
                selectedRowsHash[key] = false;
            });
        }
    },

    /**
     * Returns true when all filteredRows are selected.
     * @returns {boolean}
     * @protected
     */
    allRowsSelectedCheck_() {
        const { selectedRowsHash } = this;

        //TODO add checkbox disabled check, those can be not selected
        return !_.isEmpty(selectedRowsHash) && _.every(selectedRowsHash, angular.identity);
    },

    /**
     * Returns true when no rows is selected.
     * @returns {boolean}
     * @protected
     */
    noRowsSelectedCheck_() {
        const { selectedRowsHash } = this;

        return _.isEmpty(selectedRowsHash) || !_.some(selectedRowsHash, angular.identity);
    },

    /**
     * Event handler for row checkbox click.
     */
    onRowCheckboxClick() {
        this.updateSelectedHash_();
    },

    /**
     * Event handler for grid header checkbox click.
     * @param {boolean} allRowsSelected
     */
    onAllSelectedChange(allRowsSelected) {
        this.allRowsSelected = allRowsSelected;
        this.noRowsSelected = !allRowsSelected;
        this.updateSelectedHash_(false);
    },

    /**
     * Clears rows selection. Used by grid table header checkbox or after multiple action
     * execution.
     */
    clearSelection() {
        this.onAllSelectedChange(false);
    },

    /**
     * Returns selected rows
     * @returns {Object[]}
     */
    getSelectedRows() {
        const { filteredRows, selectedRowsHash } = this;

        return _.filter(filteredRows, row => selectedRowsHash[this.rowId(row)]);
    },
};

function gridCtrlRowSelectionMixin(classMixin) {
    return GridCtrl => classMixin(GridCtrl, angular.copy(mixin));
}

gridCtrlRowSelectionMixin.$inject = ['classMixin'];

/**
 * @ngdoc service
 * @name gridCtrlRowSelectionMixin
 * @mixin
 * @desc
 *
 *     Row selection mixin for Grid controller.
 *
 * @author Alex Malitsky
 */
angular.module('grid.ui.vantage.avi')
    .factory('gridCtrlRowSelectionMixin', gridCtrlRowSelectionMixin);
