/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class VirtualserviceVipPlacementController {
    constructor(
        Schema,
        SubnetListNetwork,
        getSubnetString,
    ) {
        this.Schema = Schema;
        this.SubnetListNetwork_ = SubnetListNetwork;
        this.getSubnetString_ = getSubnetString;
    }

    $onInit() {
        this.subnetworks = [];
        this.subnetworks6 = [];

        const
            vipConfig = this.vip.getConfig(),
            { network_ref: netRef } = vipConfig;

        //initial load for edit
        if (netRef) {
            const { cloudId } = this;

            this.busy = true;

            const network = new this.SubnetListNetwork_({
                id: netRef.slug(),
                cloudId,
            });

            network.load()
                .then(() => this.populateSubnetLists_(network.getSubnets()))
                .finally(() => {
                    this.busy = false;
                    network.destroy();
                });
        }
    }

    /**
     * Clears subnet properties.
     * @protected
     */
    clearSubnetLists_() {
        this.subnetworks.length = 0;
        this.subnetworks6.length = 0;
    }

    /**
     * Called when the placement network has been changed.
     * @param {PlacementNetwork} network - Placement Network item when selected
     *     Item was removed.
     */
    handlePlacementNetworkChange(network) {
        this.vip.clearPlacementNetworkSubnet();
        this.populateSubnetLists_(network ? network.getSubnets() : []);
    }

    /**
     * Adds placement network during creation
     */
    addPlacementNetwork() {
        const config = this.vip.getConfig();
        const placementNetworks = [];

        placementNetworks.push({
            subnet: undefined,
            subnet6: undefined,
        });

        config.placement_networks = placementNetworks;
    }

    /**
     * Populates lists of IPV4 and IPV6 subnets for the selected network.
     * @param {Object[]} subnets
     * @protected
     */
    populateSubnetLists_(subnets = []) {
        this.clearSubnetLists_();

        subnets.forEach(({ prefix }) => {
            const
                { type: ipType } = prefix['ip_addr'],
                list = ipType === 'V4' ? this.subnetworks : this.subnetworks6,
                label = this.getSubnetString_(prefix);

            list.push({ value: label });
        });
    }
}

VirtualserviceVipPlacementController.$inject = [
    'Schema',
    'SubnetListNetwork',
    'getSubnetString',
];

/**
 * @ngdoc component
 * @name  virtualserviceVipPlacement
 * @author alextsg, chitra
 * @param {SubnetListNetworkCollection} networks
 * @param {VipConfig} vip
 * @param {Cloud#id} cloudId
 * @description
 *
 *     Handles inputs for vCenter VIP network placement in Virtual Service create/edit.
 *     User chooses a network from the bound SubnetListNetworkCollection, which sets fields
 *     on the SubnetworkCollection that allows the user to either select a subnet or
 *     manually enter one in.
 */
angular.module('aviApp').component('virtualserviceVipPlacement', {
    bindings: {
        networks: '<',
        cloudId: '<',
        vip: '<',
    },
    controller: VirtualserviceVipPlacementController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-placement/virtualservice-vip-placement.html',
});
