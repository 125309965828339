/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import '../../less/components/log-callout.less';

/**
 * @ngdoc directive
 * @name logCallout
 * @restrict A
 * @param {string} name - Text label for the button.
 * @param {string} logCallout - Callout internal id to figure out the template.
 * @author Alex Malitsky
 */
angular.module('logs.vantage.avi').directive('logCallout', ['$compile',
function($compile) {
    //defines html template from attribute value, if not set `default` will be used
    const templates = {
        'server-ip-addr': 'grid',
        'e2e-timing': 'e2e-timing',
        'waf-phase-latency': 'waf-phase-latency',
        'waf-rule-groups': 'waf-rule-groups',
        policy: 'groups',
        significance: 'groups',
        ssl: 'groups',
    };

    function logCalloutLink(scope, elm, attr) {
        const carHeight = 20;

        let callout = false,
            carrat = false;

        scope.opened = false;
        scope.calloutType = attr.logCallout;

        const bodyClick = function(event) { //click outside of popover closes it
            if (event.target !== elm[0]) {
                scope.$apply('removeCallout();');
            }
        };

        const destroy = function() {
            if (callout) {
                scope.removeCallout();
                callout.remove();
                callout = false;
                carrat.remove();
                carrat = false;
            }
        };

        scope.removeCallout = function() {
            if (scope.opened === false) { return; }

            callout.hide();
            carrat.hide();
            scope.opened = false;
            $('body').off('mouseup', bodyClick);
        };

        scope.createCallout = function(event) {
            if (!event || event.target !== elm[0]) { return; }

            if (scope.opened) { //if popover is opened and clicked on <li> for same popover again
                scope.removeCallout();

                return;
            }

            const
                link = {
                    left: elm.offset().left,
                    top: elm.offset().top - $(window).scrollTop(),
                    width: elm.outerWidth(),
                    height: elm.outerHeight(),
                },
                carPos = {
                    left: 'auto',
                    right: 'auto',
                },
                pos = {
                    left: 'auto',
                    right: 'auto',
                },
                margin = 15, //margin from bottom of window & link in sidebar
                vertMargin = 80, //desired margin between popover top and link
                sbWidth = 250; //expected sidebar width to define side of popover

            let height = 132; //popover default outerHeight

            if (link.left < sbWidth) { //sidebar on the left side
                pos.left = link.left + link.width + margin;
            } else {
                pos.right = $(window).width() - link.left + margin;
            }

            if (!callout) { //first callout opening
                //usual mouseup doesn't work (with ng-if?)
                callout = $('<div/>')
                    .addClass('log-callout')
                    .attr('ng-show', 'opened')
                    .attr('ng-animate', '{show:\'callout-show\', hide:\'callout-hide\'}')
                    .attr('ng-mouseup', 'stopPropagation($event)');

                //need one more wrapper because of github.com/angular/angular.js/issues/4505
                $('<div/>')
                    .attr('ng-include', `'src/views/components/log-callout-${
                        templates[attr.logCallout] || 'default'}.html'`)
                    .appendTo(callout);

                callout = $compile(callout)(scope, function(elem) {
                    elem.appendTo('body');
                });

                carrat = $('<div/>')
                    .addClass('logCalloutCarrat')
                    .appendTo('body');
            } else {
                height = callout.outerHeight();
            }

            pos.top = Math.max(margin, Math.min(link.top - vertMargin, $(window).height() -
                height - margin));
            carPos.top = link.top + link.height / 2 - carHeight / 2;

            if (pos.left !== 'auto') {
                callout.addClass('left');
                carrat.addClass('left');
                carPos.left = link.left + link.width - 5;
                carPos.right = 'auto';
            } else {
                callout.removeClass('left');
                carrat.removeClass('left');
                carPos.right = $(window).width() - link.left + margin;
                carPos.left = 'auto';
            }

            scope.opened = true;
            callout.css(pos).show();
            carrat.css(carPos);
            scope.checkCarrat();
            $('body').on('mouseup', bodyClick);
            //$(window).on('scroll', bodyClick);
        };

        scope.checkCarrat = function(popover) {
            const carTop = parseFloat(carrat.css('top'));

            popover = popover || {
                top: callout.offset().top - $(window).scrollTop(),
                height: callout.outerHeight(),
            };

            if (scope.opened && carTop >= popover.top + 3 &&
                carTop + carHeight <= popover.top + popover.height - 3) {
                carrat.show();
            } else {
                carrat.hide();
            }
        };

        scope.stopPropagation = function(e) {
            e.stopPropagation();
        };//for clicks on popover

        scope.$on('userLoggedOut', destroy);
        scope.$on('$destroy', destroy);
        scope.$on('$repaintViewport', function() {
            if (callout) { scope.$apply('removeCallout();'); }
        });
    }

    return {
        restrict: 'A',
        scope: true,
        template: (elem, attr) => attr.name,
        link: logCalloutLink,
    };
}]);
