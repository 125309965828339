/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const SUM_FLAGGED_METRIC = 'waf_psmgroup.sum_flagged';
const SUM_EVALUATED_METRIC = 'waf_psmgroup.sum_evaluated';

class WafPolicyPsmGroupListPageComponent {
    constructor(WafPolicyPsmGroupCollection, CRUDGridConfig) {
        this.WafPolicyPsmGroupCollection_ = WafPolicyPsmGroupCollection;
        this.CrudGridConfig_ = CRUDGridConfig;
    }

    $onInit() {
        const getGridMetricFieldTemplate = metric => {
            return `{{ row.getMetric('${metric}').getMainSeries().getValue() || 'N/A' }}`;
        };

        const fields = [
            {
                require: 'config',
                name: 'data.config.name',
                title: 'Name',
                sortBy: 'name',
            },
            {
                require: SUM_FLAGGED_METRIC,
                name: SUM_FLAGGED_METRIC,
                title: 'Flagged',
                template: getGridMetricFieldTemplate(SUM_FLAGGED_METRIC),
            },
            {
                require: SUM_EVALUATED_METRIC,
                name: SUM_EVALUATED_METRIC,
                title: 'Evaluated',
                template: getGridMetricFieldTemplate(SUM_EVALUATED_METRIC),
            },
        ];

        const config = {
            collection: new this.WafPolicyPsmGroupCollection_(),
            fields,
            permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            singleactions: [
                {
                    title: 'Edit',
                    class: 'icon-pencil-4',
                    hidden: row => !row.isAllowed() || row.isProtected(),
                    do: row => row.edit(),
                },
            ],
            layout: {
                includeTimeframeSelector: true,
                hideEditColumns: true,
            },
        };

        this.gridConfig = new this.CrudGridConfig_(config);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

WafPolicyPsmGroupListPageComponent.$inject = [
    'WafPolicyPsmGroupCollection',
    'CRUDGridConfig',
];

/**
 * @ngdoc component
 * @name  wafPolicyPsmGroupListPage
 * @description Component for a WAF Policy PSM Group list page.
 * @module waf/wafPolicyPsmGroupListPage
 * @author alextsg
 */
angular.module('waf.vantage.avi').component('wafPolicyPsmGroupListPage', {
    controller: WafPolicyPsmGroupListPageComponent,
    template:
        `<div class="panel templatesList">
            <collection-grid config="$ctrl.gridConfig" />
        </div>`,
});
