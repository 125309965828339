/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/**
 * Makes prop key used in @LicenseHashMap, as well as to lookup vals in @LicenseHashMap.
 * @alias module:services/licenseSummaryService.getLicenseHashKey
 * @param {string} tier - license tier; i.e. 'ENTERPRISE_16'
 * @param {string} licenseName - names of licenses;
 *     i.e. 'cores' or 'SE_BANDWIDTH_10000M'
 * @returns {string}
 * @public
 */
function getLicenseHashKey(tier, licenseName) {
    return `${tier}-${licenseName}`;
}

/**
 * Constructs list of licenses organized by license type, with both tiers inside.
 * @alias module:services/licenseSummaryService.getLicenseListByType
 * @param {LicenseHashMap} hashMap - to be used for lookup
 * @param {LicenseTierType[]} tierTypes
 * @param {LicenseType[]} limitKeys
 * @returns {Object[]} - has props: key_, description, label;
 *     and has props {used, total} for each tier
 * @public
 */
function getLicenseListByType(hashMap, tierTypes, limitKeys) {
    return limitKeys.map(limitKey => {
        const tempTierKey = getLicenseHashKey(tierTypes[0], limitKey);
        const result = {
            key_: limitKey,
            label: hashMap[tempTierKey].label,
            description: hashMap[tempTierKey].description,
            tiers: {},
        };

        /**
         * Assigns used and total license vals to each tier in each license type.
         * @param {string} tierKey
         * @inner
         */
        function extendWithUsageAndLimit(tierKey) {
            const hashKey = getLicenseHashKey(tierKey, limitKey);

            result.tiers[tierKey] = {
                used: hashMap[hashKey].used,
                total: hashMap[hashKey].total,
            };
        }

        tierTypes.forEach(extendWithUsageAndLimit);

        return result;
    });
}

/**
 * @alias module:services/licenseSummaryService
 * @return {{getLicenseListByType: *, getLicenseHashKey: *}}
 */
function licenseSummaryService() {
    return {
        getLicenseHashKey,
        getLicenseListByType,
    };
}

/**
 * @ngdoc service
 * @name licenseSummaryService
 * @module services/licenseSummaryService
 * @author Akul Aggarwal
 * @description
 *
 *      Service to construct @LicenseHashMap keys and organize license summary data.
 *
 */
angular.module('avi/licensing').factory('licenseSummaryService', licenseSummaryService);
