/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name timingTile
 * @param {timingTileConfig} config
 * @description
 *
 *     Timing tile for timingTiles component. Can have either icon or value, not both (for
 *     sizing and grouping purposes).
 *
 */
const componentName = 'timing-tile';

const iconsImgHash = {
    client: require('../../../../img/timing/client.svg'),
    server: require('../../../../img/timing/server2.svg'),
    lb: require('../../../../img/timing/loadbalancer.png'),
    app: require('../../../../img/timing/app.svg'),
};

let graphSync,
    myAccount;

class timingTileController {
    constructor($element, _graphSync_, _myAccount_) {
        myAccount = _myAccount_;
        graphSync = _graphSync_;

        this.$elem_ = $element;
    }

    get iconImgPath() {
        return iconsImgHash[this.config.icon];
    }

    $onInit() {
        const { $elem_: $elem, config } = this;

        $elem.addClass(
            `${componentName} sel-${componentName}__${config.id.camelCaseToDash()}`,
        );

        if (config.highlighted) {
            $elem.addClass(`${componentName}--highlighted`);
        }

        if ('icon' in config) {
            $elem.addClass(`${componentName}--icon`);
        }
    }

    /**
     * Returns actual value to be passed to aviValue component.
     * @type {number}
     */
    // TODO switch to events
    get value() {
        const { series, value } = this.config;

        if (!angular.isUndefined(value)) {
            return value;
        }

        if (series) {
            let seriesValue;

            if (graphSync.mouseIsOnGraph()) {
                seriesValue = series.getValue('exact', graphSync.mouseIsOnGraph());
            } else {
                seriesValue = series.getValue(myAccount.getValuesDisplayType());
            }

            return angular.isUndefined(seriesValue) ? NaN : seriesValue;
        }

        return NaN;
    }

    isUndefined(val) {
        return angular.isUndefined(val);
    }
}

timingTileController.$inject = ['$element', 'GraphSync', 'myAccount'];

angular.module('aviApp').component('timingTile', {
    bindings: {
        config: '<',
    },
    controller: timingTileController,
    templateUrl: 'src/components/applications/timing-tiles/timing-tile/timing-tile.html',
});
