/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc constant
 * @name ipAddrToInt
 * @desc
 *
 *     Returns an integer representation of IP address string. Used for IP comparison and ordering.
 *
 */
angular.module('aviApp').constant('ipAddrToInt', function(ipAddr) {
    let res;

    try {
        const parts = ipAddr.split('.');

        if (parts.length === 4) {
            res = parts.reduce((base, part, index) => {
                part = +part;

                if (_.isNaN(part) || !_.isFinite(part) || part < 0 || part > 255) {
                    throw new Error('format');
                }

                return base + part * (256 ** (3 - index));
            }, 0);
        } else {
            throw new Error('format');
        }
    } catch (e) {
        res = NaN;
        console.error(`IP address '${ipAddr}' has the wrong format`);
    }

    return res;
});
