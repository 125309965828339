/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2017] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/
class CustomIpamDnsPageController {
    constructor(CRUDGridConfig, CustomIpamDnsCollection) {
        this.CRUDGridConfig = CRUDGridConfig;
        this.CustomIpamDnsCollection = CustomIpamDnsCollection;
    }

    $onInit() {
        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.CustomIpamDnsCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            sortBy: 'name',
            title: 'Name',
        }, {
            name: 'data.config.script_uri',
            title: 'Script',
        }];
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

CustomIpamDnsPageController.$inject = [
    'CRUDGridConfig',
    'CustomIpamDnsCollection',
];

/**
 * @ngdoc component
 * @name customIpamDnsPage
 * @description
 *      Component for Custom IPAM/DNS Profile page.
 */
angular.module('aviApp').component('customIpamDnsPage', {
    controller: CustomIpamDnsPageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid 
                    config="$ctrl.gridConfig">
                </collection-grid>
            </div>
        </div>`,
});
