/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './waf-policy-psm-group-modal.less';

/**
 * @alias module:waf/wafPolicyPsmGroupModal
 * @private
 */
class WafPolicyPsmGroupModalComponent {
    constructor(dropDownUtils) {
        this.wafActions = dropDownUtils.getEnumDropdownOptions('WafAction');

        // These values are unsupported by the backend, not yet annotated in the protobuf. Update
        // this using Schema after this has been updated.
        this.hitHiddenActions = ['WAF_ACTION_BLOCK'];
        this.missHiddenActions = ['WAF_ACTION_ALLOW_PARAMETER'];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.editable.getConfig().enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.editable.getConfig().enable = !this.editable.getConfig().enable;
    }

    /**
     * Handler for clicking the Submit button. If this.onSubmit exists, the modal was opened from
     * the WAF Policy modal, so we should not make a save request, but pass this.editable to
     * onSubmit to be saved as group_refs_data.
     */
    handleSubmit() {
        if (this.onSubmit) {
            this.onSubmit({ group: this.editable });
            this.closeModal();
        } else {
            this.editable.submit();
        }
    }
}

WafPolicyPsmGroupModalComponent.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name  wafPolicyPsmGroupModal
 * @description Component for a WAF Policy PSM Group modal.
 * @param {WafPolicyPsmGroup} editable
 * @param {Function} [onSubmit=] - Called over editable.submit() if passed.
 * @param {Function} closeModal - Called to close this modal.
 * @param {string[]} [breadcrumbs=] - Array of strings to be displayed as breadcrumbs.
 * @module waf/wafPolicyPsmGroupModal
 * @author alextsg
 */
angular.module('aviApp').component('wafPolicyPsmGroupModal', {
    bindings: {
        editable: '<',
        onSubmit: '&?',
        closeModal: '&',
        breadcrumbs: '<?',
    },
    controller: WafPolicyPsmGroupModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-group-modal/' +
        'waf-policy-psm-group-modal.component.html',
});
