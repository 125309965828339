/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2020] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

const UPLOAD_STATE_IDLE = 'idle';
const UPLOAD_STATE_IN_PROGRESS = 'in_progress';
const UPLOAD_STATE_PROCESSING = 'processing';
const UPLOAD_STATE_COMPLETE = 'complete';
const UPLOAD_STATE_ERROR = 'error';
const UPLOAD_STATE_ABORTED = 'aborted';

/**
 * Configs used to make file upload calls. Can contain fields with name of destination and uri.
 * @typedef {Object}
 * @name UploadServiceConfig
 * @memberOf module:aviApp
 * @property {string} destination - Where file should be sent. API path. Required config field to
 *    send a file.
 * @property {string=} uri - FormData property used by controller to determine where the file should
 *     be referenced.
 */

/**
 * Object holding status for upload process.
 * @typedef {Object}
 * @name UploadStatus
 * @memberOf module:aviApp
 * @property {number} percent - Percent number of data uploaded.
 * @property {number} loaded - Size of data uploaded, in byte.
 * @property {number} total - Total size of data being uploaded, in byte.
 */

const uploadFactory = (
    $q,
    $http,
    $interval,
    Auth,
) => {
    class Upload {
        constructor(args = {}) {
            /**
             * Object holding status for upload process.
             * @type {UploadStatus}
             * @protected
             */
            this.uploadStatus_ = {
                percent: 0,
                loaded: 0,
                total: 0,
            };

            this.error = '';
            this.state = UPLOAD_STATE_IDLE;

            /**
             * Where file should be sent. API path. Required to send a file.
             * @type {string}
             * @protected
             */
            this.defaultDestination_ = args.destination || '';

            /**
             * FormData property used by controller to decide where the file should be referenced.
             * @type {string}
             * @protected
             */
            this.defaultUri_ = args.uri || '';

            /**
             * When resolved, it triggers timeout and cancellation of the  pending upload request.
             * @type {ng.$q.promise|null}
             * @protected
             */
            this.pendingUpload_ = null;
        }

        /**
         * Returns a destination API string. Destination URL may already contain params, so we need
         * to figure out if we need to start with a '?' or '&' for the rest of the params.
         * @param {string} destination - Destination URL.
         * @param {string[]} params - Array of params.
         * @return {string}
         */
        static destinationBuilder = function(destination, params) {
            const paramsString = params.join('&');
            let sign = '&';

            if (destination.indexOf('?') < 0) {
                sign = '?';
            }

            return destination + sign + paramsString;
        };

        /**
         * Set current upload state.
         * @param {string} state
         * @protected
         */
        setState_(state) {
            this.state = state;
        }

        /**
         * Reset upload status object with initial values.
         * @protected
         */
        resetUploadStatus_() {
            this.uploadStatus_.loaded = 0;
            this.uploadStatus_.total = 0;
            this.uploadStatus_.percent = 0;
        }

        /**
         * Update upload status by progress event.
         * @param {ProgressEvent} event
         * @protected
         */
        updateUploadStatus_ = event => {
            if (event.lengthComputable) {
                this.uploadStatus_.loaded = event.loaded;
                this.uploadStatus_.total = event.total;
                this.uploadStatus_.percent = Math.ceil(
                    this.uploadStatus_.loaded / this.uploadStatus_.total * 100,
                );
            }
        }

        /**
         * Send file and track the progress.
         * @param {File} file - File to be uploaded.
         * @param {string} filename - Name of file at destination.
         * @param {string=} destination - Where file should be sent.
         * @param  {string=} uri - FormData property used by controller to determine where the file
         *                         should be referenced.
         * @return {ng.$q.promise}
         */
        send(file, filename, destination, uri) {
            if (!file) {
                return;
            }

            destination = destination || this.defaultDestination_;

            if (!destination) {
                throw new Error('Destination must be defined.');
            }

            uri = uri || this.defaultUri_;

            this.resetUploadStatus_();

            this.uploadStatus_.total = file.size;

            this.error = '';

            this.setState_(UPLOAD_STATE_IN_PROGRESS);

            let resetSessionInterval;

            if (Auth.autoLogoutDelay_ > 0) {
                resetSessionInterval = $interval(() => Auth.resetSessionTimer(), 10000);
            }

            return this.upload_(file, filename, destination, uri)
                .then(() => this.setState_(UPLOAD_STATE_COMPLETE))
                .catch(rsp => {
                    if (!this.isState(UPLOAD_STATE_ABORTED)) {
                        this.setState_(UPLOAD_STATE_ERROR);
                        this.error = rsp.data && rsp.data.error || 'Upload failed';
                    }

                    return $q.reject(this.error);
                })
                .finally(() => $interval.cancel(resetSessionInterval));
        }

        /**
         * Send file with XMLHttpRequest (wrapped in $http used in Base.request) and FormData.
         * @param {File} file - File to be uploaded.
         * @param {string} filename - Name of file at destination.
         * @param {string=} destination - Where file should be sent.
         * @param {string=} uri - FormData property used by controller to determine where the file
         *                         should be referenced.
         * @param {string[]?} params - Parameter list for making http request.
         * @return {ng.$q.promise}
         */
        upload_(file, fileName, destination, uri, params = []) {
            const data = new FormData();

            data.append('file', file, fileName);

            if (uri) {
                data.append('uri', uri);
            }

            const api = Upload.destinationBuilder(destination, params);

            if (this.pendingUpload_) {
                this.cancelUpload();
                throw new Error('Already an upload pending, being cancelled now');
            }

            this.pendingUpload_ = $q.defer();

            const uploadRequestConfig = {
                method: 'POST',
                url: api,
                data,
                timeout: this.pendingUpload_,
                // For FormData, manually setting Content-Type will cause the header missing
                // boundary param which in turn raises 400 Bad Request error.
                // See details: https://github.com/github/fetch/issues/505#issuecomment-293064470.
                headers: { 'Content-Type': undefined },
                uploadEventHandlers: {
                    progress: this.updateUploadStatus_,
                },
            };

            return $http(uploadRequestConfig)
                .finally(() => {
                    this.pendingUpload_ = null;
                    this.resetUploadStatus_();
                });
        }

        /**
         * Returns the inProgress state of the upload.
         * @return {Boolean} True if upload is in progress, false otherwise.
         */
        isInProgress() {
            return this.isState(UPLOAD_STATE_IN_PROGRESS) || this.isState(UPLOAD_STATE_PROCESSING);
        }

        /**
         * Get upload percentage.
         * @return {number}
         */
        getUploadPercentage() {
            if (!this.pendingUpload_) {
                return NaN;
            }

            return this.uploadStatus_.percent;
        }

        /**
         * Cancel uplod POST request and reset states.
         */
        cancelUpload() {
            // cancel only when there is request pending
            if (this.pendingUpload_) {
                this.pendingUpload_.resolve();
                this.pendingUpload_ = null;
                this.setState_(UPLOAD_STATE_ABORTED);
            } else {
                throw new Error('Can\'t cancel, no upload is pending!');
            }
        }

        /**
         * Decide current upload state.
         * @param {string} state
         * @return {boolean}
         */
        isState(state) {
            return this.state === state;
        }
    }

    return Upload;
};

uploadFactory.$inject = [
    '$q',
    '$http',
    '$interval',
    'Auth',
];

/**
 * @ngdoc factory
 * @name Upload
 * @memberOf module:aviApp
 * @description
 *     Service for uploading files to the controller. Responsible for making a send request, setting
 * the state of the upload:
 *
 *     UPLOAD_STATE_IDLE: 'idle' - Nothing is in process.
 *     UPLOAD_STATE_IN_PROGRESS:  'in_progress' - If a file upload is currently in progress.
 *     UPLOAD_STATE_PROCESSING: 'processing' - Further proceure is in action(e.g. collate) after all
 *         file chunks are uploaded.
 *     UPLOAD_STATE_COMPLETE: 'complete' - The whole upload process is completed and successful.
 *     UPLOAD_STATE_ERROR: 'error' - File upload failed with errors.
 *     UPLOAD_STATE_ABORTED : 'aborted' - File upload is aborted in its mid way.
 *
 * and mainatining the upload status:
 *
 *     uploadStatus: {
 *         percent: Ratio of loaded/total.
 *         loaded:  File size that has been loaded/sent. Does not seem to be that accurate as even
 *                  when loaded === total, the upload request may not be returned, possibly due to
 *                  the controller/system moving the file to the uri destination.
 *         total:   Total size of file.
 *     }
 * @author alextsg, Zhiqian Liu
 */
angular.module('aviApp').factory('Upload', uploadFactory);

/**
 * States representing different stages of the upload process.
 * Used together with this.isState(state) and this.setState_(state).
 */
export const uploadStatesHash = {
    UPLOAD_STATE_IDLE,
    UPLOAD_STATE_IN_PROGRESS,
    UPLOAD_STATE_PROCESSING,
    UPLOAD_STATE_COMPLETE,
    UPLOAD_STATE_ERROR,
    UPLOAD_STATE_ABORTED,
};
