/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './timing-tiles.less';

/**
 * @ngdoc component
 * @name timingTiles
 * @param {Metric} metric
 * @description
 *
 *     List of tiles with metric values or icons to illustrate them.
 */

/**
 * @typedef {Object} timingTilesConfig
 * @property {string} header
 * @property {timingTileConfig[]} tiles
 */

/**
 * @typedef {Object} timingTileConfig
 * @property {string} id - Unique within list of tiles.
 * @property {string|undefined} icon - Icon id, options defined in {@link timingTile}.
 * @property {string|undefined} iconTitle - Text label to be placed under the icon.
 * @property {string|undefined} title - Text label for metrics value.
 * @property {boolean|undefined} highlighted - When true tile will have different background color.
 * @property {Series|undefined} series
 * @property {number|undefined} value
 */
const componentName = 'timing-tiles';

const iconTileWidth = 3.2;// em, minimal

const valueTileWidth = iconTileWidth * 1.75;

const maxScale = 1.6; // max coefficient (x times) of the icon tile width

class timingTilesController {
    constructor($element) {
        this.$elem_ = $element;
    }

    $onInit() {
        this.$elem_.addClass(componentName);
    }

    $onChanges({ metric: metricChange }) {
        const { currentValue: metric } = metricChange;

        if (metric) {
            const { header, tiles } = metric.getTimingTilesConfig();

            this.header = header;
            this.tiles = tiles;
        } else {
            this.header = undefined;
            this.tiles = undefined;
        }
    }

    /**
     * Returns wrapper width based on number of tiles and their types. We don't want to be
     * too wide and too narrow. That's what wrapper is for.
     * @type {Object} - object with HTML style attribute properties.
     */
    // can't use flex for equal margins cause we need ability to highlight neighbours with
    // same background color
    get wrapperStyle() {
        if (this.tiles) {
            const width = this.tiles.reduce(
                (base, tileConfig) =>
                    base += ('icon' in tileConfig) ? iconTileWidth : valueTileWidth,
                0,
            );

            return {
                'min-width': `${width}em`,
                'max-width': `${Math.ceil(width * maxScale)}em`,
            };
        }

        return null;
    }
}

timingTilesController.$inject = ['$element'];

angular.module('aviApp').component('timingTiles', {
    bindings: {
        metric: '<',
    },
    controller: timingTilesController,
    template:
        `<h3 class="${componentName}__header">{{::$ctrl.header}}</h3>` +
        `<div class="${componentName}__list-wrapper" ng-style="::$ctrl.wrapperStyle">` +
            '<timing-tile ng-repeat="tile in ::$ctrl.tiles track by tile.id" config="tile"/>' +
        '</div>',
});
