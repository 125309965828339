/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class DnsNtpModalController {
    constructor(dropDownUtils, Schema, Regex, schemaService) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.schemaService_ = schemaService;
        this.dropDownUtils_ = dropDownUtils;
    }

    $onInit() {
        /**
         * Options for NTPAuthAlgorithm dropdown.
         * @type {Object[]}
         */
        this.ntpAuthAlgorithmOptions =
            this.dropDownUtils_.getEnumDropdownOptions('NTPAuthAlgorithm');

        const { ntp_authentication_keys: ntpAuthenticationKeys } = this.editable.getNTPConfig();

        /**
         * Options for NTPAuthenticationKeyNumber dropdown.
         * @type {Object[]}
         */
        this.ntpAuthenticationKeyNumberOptions = ntpAuthenticationKeys
            .map(({ key_number: keyNumber }) =>
                this.dropDownUtils_.createOption(keyNumber, keyNumber));
    }

    /**
     * Adds empty object to ntp_authentication_keys array.
     */
    addAuthenticationKey() {
        const { ntp_authentication_keys: authKeys } = this.editable.getNTPConfig();

        authKeys.push({});
    }

    /**
     * Splices authentication key object from ntp_authentication_keys array.
     * @param  {number} index - Index to remove.
     */
    removeAuthenticationKey(index) {
        const { ntp_authentication_keys: authKeys } = this.editable.getNTPConfig();

        authKeys.splice(index, 1);
    }

    /**
     * Adds empty object to ntp_servers array.
     */
    addServer() {
        const { ntp_servers: ntpServers } = this.editable.getNTPConfig();

        ntpServers.push({});
    }

    /**
     * Splices server object from ntp_servers array.
     * @param  {number} index - Index to remove.
     */
    removeServer(index) {
        const { ntp_servers: ntpServers } = this.editable.getNTPConfig();

        ntpServers.splice(index, 1);
    }
}

DnsNtpModalController.$inject = [
        'dropDownUtils',
        'Schema',
        'Regex',
        'schemaService',
];

/**
 * @ngdoc component
 * @name  dnsNtpModal
 * @description
 *     Modal component for updating System Settings (DNS/NTP Configuration settings).
 * @param {Object} editable - SystemConfig object.
 * @param {Function} closeModal - Callback to be called while closing modal.
 */
angular.module('aviApp').component('dnsNtpModal', {
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: DnsNtpModalController,
    templateUrl: 'src/components/system-configuration/modals/dns-ntp-modal/dns-ntp-modal.html',
});
