/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class WafRulesCollapsibleListButtonController {
    constructor() {
        /** @type {boolean} */
        this.expanded = false;

        /** @type {CollapsibleListController} */
        this.parent = null;

        /** @type {Function|null} */
        this.onToggle = null;
    }

    /**
     * Expands or collapses toggle button based on current state.
     */
    toggle() {
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.parent.expand();
        } else {
            this.parent.collapse();
        }

        if (angular.isFunction(this.onToggle)) {
            this.onToggle.call(this);
        }
    }
}

/**
 * @ngdoc component
 * @requires collapsibleList
 * @param {Function=} onToggle - Callback when button is clicked.
 * @description
 *      Toggle button used for collapsibleList directive.
 */
angular.module('aviApp').component('wafRulesCollapsibleListButton', {
    controller: WafRulesCollapsibleListButtonController,
    bindings: {
        onToggle: '&?',
    },
    require: {
        parent: '^wafRulesCollapsibleList',
    },
    templateUrl: 'src/components/common/collapsible-list/waf-rule-groups-collapsible-list/' +
            'waf-rule-groups-collapsible-list-button.html',
});
