/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc factory
 * @name  ErrorPageBodyCollection
 * @description  Collection of {WafPolicy} items.
 */
const ErrorPageBodyCollection = (Collection, ErrorPageBody) => {
    class ErrorPageBodyCollection extends Collection {

    }

    angular.extend(ErrorPageBodyCollection.prototype, {
        objectName_: 'errorpagebody',
        itemClass_: ErrorPageBody,
        windowElement_: 'error-page-body-modal',
    });

    return ErrorPageBodyCollection;
};

ErrorPageBodyCollection.$inject = [
        'Collection',
        'ErrorPageBody',
];

angular.module('aviApp').factory('ErrorPageBodyCollection', ErrorPageBodyCollection);
