/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  poolGroupUnitCards
 * @param {Pool[]=} pools - List of Pool items.
 * @param {PoolGroup} poolgroup - Pool Group item.
 * @description
 *     Displays the list of Pools in the Pool Group associated with the Virtual Service. Pools are
 *     ordered and grouped by priority and color-coded. Each pool is a unit card and is clickable
 *     to access the Pool detail page. This is used in the Application Dashboard along with the
 *     Virtual Service Analytics Sidebar page, the difference being that Networks and Servers will
 *     also be shown in the Application Dashboard.
 */
class Controller {
    constructor(PoolGroup) {
        this.PoolGroup_ = PoolGroup;
    }

    $onInit() {
        if (!angular.isUndefined(this.poolCollection)) {
            this.pools = this.poolCollection.items;

            this.poolCollection.bind('collectionLoadSuccess', () => {
                this.pools = this.poolCollection.items;
                this.setSortedPools();
            });
        }

        this.setSortedPools();
    }

    $onChanges(changes) {
        if (_.sample(changes).isFirstChange()) {
            return;
        }

        if (changes.poolgroup) {
            this.setSortedPools(changes.poolgroup.currentValue.members);
        }
    }

    /**
     * Returns the members to be sorted. Members can be returned in the case of the Application
     * Dashboard since they contain pool information, but in the case of the Virtual Service
     * Analytics sidebar they don't, so pool data needs to first be merged with member data.
     * @param  {PoolGroup.members} poolgroupMembers
     * @return {PoolGroup.members}
     */
    getMembers(poolgroupMembers) {
        if (angular.isUndefined(this.pools)) {
            return poolgroupMembers;
        } else {
            return this.PoolGroup_.matchPoolsToMembers(this.poolgroup.members, this.pools);
        }
    }

    /**
     * Sets sortedPools by getting a list of members and sorting them.
     * @param  {PoolGroup.members=} poolgroupMembers
     */
    setSortedPools(poolgroupMembers = this.poolgroup.members) {
        const members = this.getMembers(poolgroupMembers);

        this.sortedPools = this.PoolGroup_.membersToSortedPools(members);
    }

    /**
     * Returns true if object is empty.
     * @param  {Object}  obj
     * @return {boolean}
     */
    isObjectEmpty(obj) {
        return _.isEmpty(obj);
    }
}

Controller.$inject = [
        'PoolGroup',
];

angular.module('aviApp').component('poolGroupUnitCards', {
    bindings: {
        poolCollection: '<',
        poolgroup: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/applications/pool-group/pool-group-unit-cards/' +
            'pool-group-unit-cards.html',
});
