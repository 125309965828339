/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  sslCertificateCreateApplicationSystem
 * @param {Certificate} editable - Certificate item.
 * @param {function} closeModal - closes this modal.
 * @description
 *     This is a modal component for creating an Application or a Controller(System) certificate.
 *     These two kinds of certificates are very similar and are therefore coupled together, as
 *     opposed to a Root certificate that has its own modal.
 *     Certificates of type 'Self-signed' and 'Import' can only be created. 'CSR', or Certificate
 *     Signing Requests, can be edited, but only to attach a signed certificate - all other input
 *     fields are disabled on 'Edit'.
 *     User workflows:
 *         Self-signed: Create and save certificate -> Done.
 *         CSR: Create and save certificate -> Edit to add signed certificate -> Done.
 *         Import: Upload/copy paste certificate -> Validate -> Save certificate -> Done.
 */
class CertificateController {
    constructor(Schema, Regex, HSMGroupCollection, CertificateManagementCollection) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.hsmGroupCollection = new HSMGroupCollection();
        this.certificateManagementCollection = new CertificateManagementCollection();
    }

    $onInit() {
        this.showHSMEncryptedPrivateKey = false;
        this.validated = false;

        const config = this.editable.getConfig();

        if (angular.isUndefined(config.url)) {
            this.type = 'self-signed';
        } else {
            this.type = this.editable.isCertificateSigningRequest() && 'ca-signed' || 'import';
        }
    }

    /**
     * Called when user selects between "Self-signed", "CSR", and "Import" types.
     * @param  {string} type - 'self-signed', 'ca-signed', or 'import'.
     */
    changeType(type) {
        this.type = type;
        this.editable.changeType(type);
    }

    /**
     * Clears certificate information when Paste/Upload radio buttons are selected.
     */
    clearCertificate() {
        this.editable.data.config.certificate = undefined;
        this.validated = false;
    }

    /**
     * Clears certificate key information when Paste/Upload radio buttons are selected.
     */
    clearKey() {
        this.editable.data.config.key = undefined;
        this.validated = false;
    }

    /**
     * Called by HSMGroup CollectionDropdown to show/hide the Encrypted Private Key field.
     * @param  {HSMGroup} selected - Selected HSM Group from dropdown.
     */
    handleHSMGroupChange(selected) {
        this.showHSMEncryptedPrivateKey = selected &&
                selected.data.config.hsm.type === 'HSM_TYPE_THALES_NETHSM';

        if (!this.showHSMEncryptedPrivateKey) {
            delete this.editable.data.config.enckey_base64;
            delete this.editable.data.config.enckey_name;
        }
    }

    /**
     * Called by sslCertificateSigningRequest to populate certificate properties.
     * @param  {boolean} base64 - True if certificate is base64.
     * @param  {string} certificate - Certificate string.
     */
    handleCertificateSigningRequestChange(base64, certificate) {
        const config = this.editable.getConfig();

        config.certificate_base64 = base64;
        config.certificate.certificate = certificate;
    }

    /**
     * Sends certificate validate request and sets the Certificate config.
     * @returns {promise}
     */
    validateCert() {
        return this.editable.validateCertificate()
            .then(() => this.validated = true);
    }
}

CertificateController.$inject = [
    'Schema',
    'Regex',
    'HSMGroupCollection',
    'CertificateManagementCollection',
];

angular.module('aviApp').component('sslCertificateCreateApplicationSystem', {
    bindings: {
        editable: '=',
        closeModal: '&',
    },
    controller: CertificateController,
    templateUrl: 'src/components/templates/security/ssl-tls-certificates/modals/' +
        'ssl-certificate-create-application-system/' +
        'ssl-certificate-create-application-system.html',
});
