/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { diff as deepDiff } from 'deep-diff';

/**
 * My Account service constructor.
 * @param {angular.$http} $http
 * @param {angular.$q} $q
 * @param {angular.$rootScope} $rootScope
 * @param {Timeframe} Timeframe
 * @param {Base} Base
 * @constructor
 */
function myAccount($http, $q, $rootScope, Timeframe, Base) {
    class MyAccount extends Base {
        constructor() {
            super();

            /**
             * Account data object.
             * @type {Object}
             */
            this.accountData = {};

            /**
             * User preferences data object.
             * @type {Object}
             */
            this.uiProperty = {};

            /**
             * Original user preferences data object as returned by server.
             * @type {Object}
             */
            this.uiPropertyClone = {};

            /**
             * Original copy of account data object as returned by server.
             * @type {Object}
             */
            this.accountDataClone = {};

            /**
             * @type {angular.$http}
             */
            this.$http = $http;

            /**
             * @type {angular.$q}
             */
            this.$q = $q;

            /** @type {Timeframe} */
            this.Timeframe = Timeframe;

            /**
             * Used in request when saving account data.
             * @type {?angular.$q.Deferred}
             */
            this.accountSaveDeferred = null;

            /**
             * Used in request when saving UI properties data.
             * @type {?angular.$q.Deferred}
             */
            this.uiPropertySaveDeferred = null;

            /**
             * Promise used when loading user account and preferences.
             * @type {?angular.$q.promise}
             */
            this.loadPromise = null;

            /**
             * Promise used when loading user account and preferences.
             * @type {?angular.$q.promise}
             */
            this.savePromise = null;

            /**
             * Indicates initial loading is complete. Has to be false by default.
             * @private {boolean}
             */
            this.isLoaded_ = false;

            /**
             * Prevent saveUIProperty HTTP request from being invoked too often.
             * @type {Function}
             */
            this.saveUIProperty = _.debounce(this.saveUIProperty.bind(this), 0);

            /**
             * @type {?Object}
             */
            this.controllerProperties = null;

            this.dataHandler_ = this.dataHandler_.bind(this);
        }
    }

    /**
     * Loads user preferences and account data.
     * @returns {angular.$q.promise}
     * @private
     */
    MyAccount.prototype.load_ = function() {
        const { $http } = this;

        const requests = [
                $http.get('/api/useraccount'),
                $http.get('/api/userpreferences'),
        ];

        this.loadPromise = this.$q.all(requests)
            .then(this.dataHandler_)
            .finally(() => this.loadPromise = null);

        return this.loadPromise;
    };

    /**
     * Loads user preferences and account data.
     * @returns {angular.$q.promise}
     */
    MyAccount.prototype.load = function() {
        if (this.loadPromise) {
            return this.loadPromise;
        } else if (this.savePromise) {
            return this.savePromise.then(() => this.load_());
        } else {
            return this.load_();
        }
    };

    MyAccount.prototype.getControllerProperties = function() {
        return this.$http.get('/api/controllerproperties').then(function(response) {
            this.controllerProperties = response.data;
        }.bind(this));
    };

    MyAccount.prototype.saveControllerProperties = function() {
        this.$http.put('/api/controllerproperties', this.controllerProperties);
    };

    /**
     * Returns boolean value indicating initial load is complete. Doesn't prevent from loading
     * multiple times, but in most cases application does not need to reload user preferences
     * multiple times.
     * @returns {boolean}
     */
    MyAccount.prototype.isLoaded = function() {
        return this.isLoaded_;
    };

    /**
     * Checks if original data matches modified data.
     * @returns {boolean} - True if user preferences or account data doesn't match original
     *     copies of those objects.
     */
    MyAccount.prototype.isModified = function() {
        return !!deepDiff(this.uiProperty, this.uiPropertyClone) ||
                !!deepDiff(this.accountData, this.accountDataClone);
    };

    /**
     * Reverts changes to stored cloned object.
     */
    MyAccount.prototype.revertChanges = function() {
        this.uiProperty = this.uiPropertyClone;
        this.accountData = this.accountDataClone;
    };

    /**
     * Handles user account and user preferences data. Checks required ui_profile fields and
     * fulfills them when not present. Also sets Timeframe#defaultValue_.
     * @param {Object} userAccount - Server data for user account and user preferences data.
     * @param {Object} prefs
     * @protected
     */
    MyAccount.prototype.dataHandler_ = function([{ data: userAccount }, { data: prefs }]) {
        this.isLoaded_ = true;
        this.loadPromise = null;
        this.accountData = userAccount;

        const uiProperty = prefs || {};

        uiProperty.ui_property = uiProperty.ui_property ?
            JSON.parse(uiProperty.ui_property) : {};

        this.uiProperty = uiProperty.ui_property;

        this.uiPropertyTransformAfterLoad_();

        this.Timeframe.setDefaultValue(this.uiProperty['defaultTimeframe']);
        this.Timeframe.set(this.uiProperty['defaultTimeframe']);

        this.accountDataClone = angular.copy(this.accountData);
        this.uiPropertyClone = angular.copy(this.uiProperty);
    };

    /**
     * Checks all required ui_profile properties which are used throughout the app and fulfills
     * them with default values when not present. Calls MyAccount#saveUIProperty when it got
     * updated.
     * @protected
     */
    MyAccount.prototype.uiPropertyTransformAfterLoad_ = function() {
        let hasChangedUIProperty = false;

        if (!this.hasUIProperty('defaultTimeframe')) {
            hasChangedUIProperty = true;
            this.uiProperty['defaultTimeframe'] = '6h';
        }

        if (!this.hasUIProperty('valuesToDisplay')) {
            hasChangedUIProperty = true;
            this.uiProperty['valuesToDisplay'] = 'avg';
        }

        if (!('sideRailOpen' in this.uiProperty)) {
            hasChangedUIProperty = true;
            this.uiProperty['sideRailOpen'] = true;
        }

        if (!this.hasUIProperty('logs') || !('savedSearch' in this.uiProperty['logs']) ||
                !('sidebarActiveTab' in this.uiProperty['logs'])) {
            hasChangedUIProperty = true;

            const appLogsProp = this.uiProperty['logs'] || {};

            if (!('savedSearch' in appLogsProp)) {
                appLogsProp['savedSearch'] = [];
            }

            if (!('sidebarActiveTab' in appLogsProp)) {
                appLogsProp['sidebarActiveTab'] = '1';
            }

            this.uiProperty['logs'] = appLogsProp;
        }

        //migration for pre 16.3 versions
        if (!angular.isUndefined(this.uiProperty['showMetricsLogs'])) {
            hasChangedUIProperty = true;

            if (!('hideLogSummaries' in this.uiProperty['logs'])) {
                this.uiProperty['logs']['hideLogSummaries'] =
                        !this.uiProperty['showMetricsLogs'];
            }

            delete this.uiProperty['showMetricsLogs'];
        }

        if (!this.hasUIProperty('appDashboard') ||
                !('viewType' in this.uiProperty['appDashboard'])) {
            hasChangedUIProperty = true;

            const appDashProp = this.uiProperty['appDashboard'] || {};

            appDashProp['viewType'] = 'list';
            this.uiProperty['appDashboard'] = appDashProp;
        }

        if (!this.hasUIProperty('grid')) {
            this.uiProperty.grid = {};
        } else if (!this.uiProperty.grid.pageSize) {
            this.uiProperty.grid.pageSize = 30;
        }

        if (hasChangedUIProperty) {
            this.saveUIProperty();
        }
    };

    /**
     * Checks if specified UI property exists within {@link this.uiProperty} object and returns
     * value of that property.
     * @param {string} propertyName - Name of the property to find.
     * @returns {?*} Value assigned to specified property name.
     */
    MyAccount.prototype.hasUIProperty = function(propertyName) {
        return propertyName in this.uiProperty && this.uiProperty[propertyName];
    };

    /**
     * Checks asynchronously if specified ui property exists. Doesn't do nested properties
     * checks.
     * @param {string} propertyName - Property name of ui_property.
     * @returns {ng.$q.promise<*|undefined>} - Promise resolves specified property name value.
     */
    MyAccount.prototype.checkUIProperty = function(propertyName) {
        const deferred = this.$q.defer();
        const self = this;

        if (this.isLoaded_) {
            deferred.resolve(this.hasUIProperty(propertyName));
        } else {
            this.load().then(function() {
                deferred.resolve(self.hasUIProperty(propertyName));
            });
        }

        return deferred.promise;
    };

    /**
     * Checks multiple ui property names as arguments.
     * @param {...string} values - Repeated string arguments.
     * @returns {ng.$q.promise<Array<*>>} - Promise that resolves to an array of values from
     *     specified arguments.
     */
    MyAccount.prototype.checkUIProperties = function(values) {
        const self = this;

        return this.$q.all(_.map(arguments, function(value) {
            return self.checkUIProperty(value);
        }));
    };

    /**
     * Saves user preferences to server.
     * @returns {angular.$q.promise}
     */
    MyAccount.prototype.saveUIProperty = function() {
        if (this.uiPropertySaveDeferred) {
            this.uiPropertySaveDeferred.resolve();
        }

        const payload = {
            ui_property: this.uiProperty,
        };

        if (Object.keys(payload.ui_property).length) {
            payload.ui_property = JSON.stringify(payload.ui_property);
        } else {
            payload.ui_property = '';
        }

        this.uiPropertySaveDeferred = this.$q.defer();
        this.savePromise = this.$http.put('/api/userpreferences', payload, {
            timeout: this.uiPropertySaveDeferred.promise,
        }).then(() => this.savePromise = null);

        return this.savePromise;
    };

    /**
     * Saves account data to server.
     * @returns {angular.promise}
     */
    MyAccount.prototype.saveAccount = function() {
        if (this.accountSaveDeferred) {
            this.accountSaveDeferred.resolve();
        }

        this.accountSaveDeferred = this.$q.defer();

        return this.$http.put('/api/useraccount', this.accountData, {
            timeout: this.accountSaveDeferred.promise,
        });
    };

    /**
     * Checks if account password and confirm password match.
     * @returns {boolean} - True if password and confirm password match or password is empty.
     */
    MyAccount.prototype.passwordsMatch = function() {
        if (this.accountData && this.accountData.password) {
            return this.accountData.password === this.accountData.confirm_password;
        }

        return true;
    };

    /**
     * Changes uiProperty.valuesToDisplay by metricValuesSelector and saves setting.
     * @param {string} value
     */
    MyAccount.prototype.setValuesToDisplay = function(value) {
        this.uiProperty.valuesToDisplay = value;
        this.trigger('MyAccount valuesToDisplayChange', value);
        this.saveUIProperty();
    };

    /**
     * Changes uiProperty.valuesToDisplay by metricValuesSelector and saves setting.
     * @param {string} value
     */
    MyAccount.prototype.saveDefaultTimeframe = function(value) {
        this.uiProperty.defaultTimeframe = value;
        this.saveUIProperty();
    };

    /**
     * Returns "valuesToDisplay" value (avg/total/peak).
     * @returns {string}
     * @public
     */
    MyAccount.prototype.getValuesDisplayType = function() {
        return this.uiProperty.valuesToDisplay;
    };

    /**
     * Toggles sidebar state.
     * @param {boolean=} desiredState - When true is passed will open sidebar, when false is
     *     passed will close it.
     * @public
     */
    MyAccount.prototype.toggleSidebar = function(desiredState) {
        const
            { uiProperty } = this,
            { sideRailOpen: prevValue } = uiProperty,
            newValue = angular.isUndefined(desiredState) ? !prevValue : !!desiredState;

        if (prevValue !== newValue) {
            uiProperty.sideRailOpen = newValue;
            this.saveUIProperty();

            setTimeout(() => {
                $rootScope.$broadcast('repaint');
                $rootScope.$broadcast('$repaintViewport');
            }, 49);
        }
    };

    return new MyAccount();
}

myAccount.$inject = [
    '$http',
    '$q',
    '$rootScope',
    'Timeframe',
    'Base',
];

/**
 * @ngdoc service
 * @name myAccount
 */
angular.module('aviApp').service('myAccount', myAccount);
