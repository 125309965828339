/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import { WAF_ACTION } from '../../waf-logs.service';
import './psm-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * @alias module:waf/psmLog
 * @private
 */
class PsmLogComponent {
    constructor(wafLogsService) {
        this.wafLogsService_ = wafLogsService;
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @returns {string} - List of text to display from the action enums.
     */
    getActionLabels() {
        return this.wafLogsService_.getActionLabels(WAF_ACTION, this.log.actions);
    }
}

PsmLogComponent.$inject = [
    'wafLogsService',
];

/**
 * @ngdoc component
 * @name  psmLog
 * @description Component for PSM Logs.
 * @param {Object} log - PSM Log object.
 * @module waf/psmLog
 * @author alextsg
 */
angular.module('logs.vantage.avi').component('psmLog', {
    bindings: {
        log: '<',
    },
    controller: PsmLogComponent,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'psm-logs-list/psm-log/psm-log.component.html',
});
