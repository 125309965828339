/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

class DsrProfileSettingsController {
    constructor(schemaService, dropDownUtils) {
        this.dropDownUtils_ = dropDownUtils;
        this.schemaService_ = schemaService;
    }

    $onInit() {
        const dsrTypeEnums = this.schemaService_.getEnumValues('DsrType');
        const dsrEncapTypeEnums = this.schemaService_.getEnumValues('EncapType');
        const { dsr_profile: dsrProfile } = this.profileConfig;

        this.dsrTypes = dsrTypeEnums.map(this.createDropdownOption_);
        this.dsrEncapTypes = dsrEncapTypeEnums.map(this.createDropdownOption_);
        this.profileConfig.useDSR = !!dsrProfile;
    }

    /**
     * Callback method to create dropdown options.
     * @param {string} value - value of the dropdown.
     * @param {string} label - label for the dropddown.
     * @param {string} description - description of dropdown.
     * @protected
     * @return {DropDownOption}
     */
    createDropdownOption_ = ({ value, label, description }) => {
        return this.dropDownUtils_.createOption(
            value,
            label,
            description,
        );
    }

    /**
     * Update dropdown data if profile type is changed.
     * @param {Object} profileType selected profile type.
     */
    $onChanges({ profileType }) {
        if (!profileType.isFirstChange() &&
            profileType.currentValue !== profileType.previousValue) {
            this.profileConfig.useDSR = false;
            this.onDsrToggle();
        }
    }
}

DsrProfileSettingsController.$inject = [
    'schemaService',
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name dsrProfileSettings
 * @param {Object} profileConfig - Network profile data.
 * @param {NetProfileType} profileType -  Network profile type.
 * @param {boolean} editMode - EditMode for the already created newtwork profile.
 * @param {Function} onDsrToggle -  Handle check and uncheck of use DSR checkbox.
 * @author Ashish Verma
 * @description
 *      Form component for DSR settings of Network Profile.
 *      Only for TCP and UDP network profile types
 */
angular.module('aviApp').component('drsProfileSettings', {
    bindings: {
        profileConfig: '<',
        profileType: '<',
        editMode: '<',
        onDsrToggle: '&',
    },
    controller: DsrProfileSettingsController,
    templateUrl: 'src/components/templates/profiles/network/dsr-profile-settings.html',
});
