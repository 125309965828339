/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './virtualservice-vip-optional-auto-allocate.less';

/**
 * @ngdoc component
 * @name  virtualserviceVipOptionalAutoAllocate
 * @description
 *     Allows the user to select a network and subnet for a Virtual Service. Used if the cloud is
 *     OpenStack, or if the cloud is not AWS and has a DNS provider profile configured.
 * @param {VirtualService} vs
 * @param {Cloud} cloud
 * @param {string} createMode - 'advanced' or 'basic'. Used to differentiate between 'advanced' and
 *     'basic' Virtual Service creation. Vip sharing is only allowed in 'advanced' creation.
 */
class Controller {
    constructor(Schema, Regex) {
        this.Schema = Schema;
        this.Regex = Regex;
    }

    $onInit() {
        this.busy = false;
        this.vip = this.vsVip.getVip(0);
        this.dnsInfo = this.vsVip.getDnsInfo(0);
        this.enableFloatingIp = this.vip.floatingIpIsEnabled();

        /**
         * Set Auto-allocate IP to true by default. We need to check for the presence of an
         * address or a network_ref in addition to editable#id, in cases where the user changes
         * wizard pages and comes back, as $onInit will be called again.
         */
        if (!this.vsVip.id && !this.vip.isSet()) {
            this.vip.setAutoAllocateIp(true);
        }
    }

    /**
     * Called when the checkbox to enable Floating IP is clicked. Needs to be applied to all
     * floating_ip fields in the vip list.
     */
    handleEnableFloatingIpChange() {
        this.vsVip.setAutoAllocateFloatingIpForAllVips(this.enableFloatingIp);
        this.vip.setAutoAllocateFloatingIp(this.enableFloatingIp);
        this.handleAutoAllocateFloatingIpChange();
    }

    /**
     * Resets floating_ip property of config.
     */
    handleAutoAllocateFloatingIpChange() {
        this.vip.setFloatingIpAddr(undefined);
    }

    /**
     * Resets properties on Auto-allocate IP change.
     */
    handleAutoAllocateIpChange() {
        this.vip.clearVipNetwork();
        this.vip.clearVipSubnet(true);
        this.vip.setIpAddr(undefined);
    }

    /**
     * Wrapper for getting cloud type.
     * @return {string}
     */
    getCloudType() {
        return this.cloud.getVtype();
    }
}

Controller.$inject = [
    'Schema',
    'Regex',
];

angular.module('aviApp').component('virtualserviceVipOptionalAutoAllocate', {
    bindings: {
        vsVip: '<',
        cloud: '<',
        createMode: '<',
    },
    controller: Controller,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-vip-address/' +
        'virtualservice-vip-optional-auto-allocate/' +
        'virtualservice-vip-optional-auto-allocate.html',
});
