/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

/**
 * @ngdoc component
 * @name  subheader
 * @param {Item?} - Item might be passed to figure out which tabs to show or hide.
 * @description
 *
 *     Subheader component for the subheader navbar below the main application navbar.
 */
//TODO rename
class SubheaderController {
    constructor($state, $transitions, $stateParams, systemInfoService, infraCloudState) {
        this.$state = $state;
        this.$transitions_ = $transitions;
        this.$stateParams = $stateParams;
        this.systemInfoService_ = systemInfoService;
        this._infraCloudState = infraCloudState;
    }

    $onInit() {
        this.setProperties_();
        this.showTimeframe = this.getTimeframeVisibility_();
        this.showMetricSelector = this.getMetricSelectorVisibilty_();

        this.destroyTransitionListener = this.$transitions_.onSuccess({}, () => {
            if (this.parent_.name !== this.getParent_().name) {
                this.setProperties_();
            }

            this.showTimeframe = this.getTimeframeVisibility_();
            this.showMetricSelector = this.getMetricSelectorVisibilty_();
        });
    }

    /**
     * Returns true if the header should show the Metric selector dropdown (display value type).
     * @return {boolean}
     * @protected
     */
    getMetricSelectorVisibilty_() {
        return this.$state.current.data &&
                this.$state.current.data.tfGroup !== 'logs' || false;
    }

    /**
     * Sets properties used by the template along with the parent state name. States get reset
     * only if the parent name changes.
     */
    setProperties_() {
        this.parent_ = this.getParent_();
        this.states = this.getStates_();
    }

    /**
     * Returns the parent state.
     * @return {Object}
     */
    getParent_() {
        return this.$state.get(this.$state.current.data.parentState);
    }

    /**
     * Returns true if the timeframe should be shown in the subheader.
     * @return {Boolean}
     */
    getTimeframeVisibility_() {
        return !!(this.$state.current.data && this.$state.current.data.showTimeframe ||
                this.parent_ && this.parent_.data && this.parent_.data.showTimeframe);
    }

    /**
     * Sets the array of states to be shown in the template.
     */
    getStates_() {
        return this.parent_.children.filter(child => !angular.isUndefined(child.title));
    }

    /**
     * Returns true if tab should be hidden due to special circumstances (ex. only show if GSLB
     * config exists), unrelated to permissions.
     * @param  {Object} state - Name of the state defined in state tree.
     * @return {Boolean}
     */
    //TODO use some custom state property to put checks in along with
    // resolvable Item shared by all the nested states
    isHidden(state) {
        const { name: stateName } = state;

        switch (stateName) {
            case 'authenticated.profile.profiles.gslb-healthmonitor-list':
                return !this.haveGSLBConfig();

            case 'authenticated.infrastructure.gslb.upload-geo-files':
            case 'authenticated.infrastructure.gslb.geo-profile':
                return !this.systemInfoService_.localSiteIsGSLBLeader();

            case 'authenticated.application.virtualservice-detail.clientinsight':
                return !this.item || !this.item.isHTTP();

            case 'authenticated.application.virtualservice-detail.app-map':
                return !this.item || !this.item.isAppMapAvail();

            case 'authenticated.application.virtualservice-detail.dns-records':
                return !this.item || !this.item.isDNS();

            case 'authenticated.application.virtualservice-detail.WAF':
                return !this.item.hasWAFPolicy();

            case 'authenticated.infrastructure.infrastructure-detail.routing.vrf-context-list':
                return !this._infraCloudState.showVrfContextTab();

            case 'authenticated.infrastructure.infrastructure-detail.routing.' +
                    'gateway-monitor-list':
                return !this._infraCloudState.showGatewayMonitorTab();

            default:
                return false;
        }
    }

    /**
     * Returns true if the GSLB Health Monitors tab should be shown in the subheader.
     * @return {boolean}
     */
    haveGSLBConfig() {
        return this.systemInfoService_.haveGSLBConfig();
    }

    $onDestroy() {
        this.destroyTransitionListener();
    }
}

SubheaderController.$inject = [
    '$state',
    '$transitions',
    '$stateParams',
    'systemInfoService',
    'infraCloudState',
];

angular.module('aviApp').component('subheader', {
    controller: SubheaderController,
    templateUrl: 'src/components/common/subheader/subheader.html',
    bindings: {
        item: '<?',
    },
});
