/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2019] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
*/

import './case-modal.component.less';

import {
    typeHash,
    severityHash,
    environmentHash,
} from '../../../../js/constants/case.constant';

const {
    SEVERITY_1,
    SEVERITY_2,
    SEVERITY_3,
    SEVERITY_4,
    SEVERITY_5,
} = severityHash;

const {
    CONFIGURATION_HELP,
    QUESTION,
    FEATURE_REQUEST,
} = typeHash;

/**
 * @class
 * @alias module:components/modals/CaseModalController
 * @desc Modal component for creating/editing a Case
 * @author Ashish Verma
 */
class CaseModalController {
    /**
     * Severity list for case.
     * @type {DropDownOption[]}
     */
    static severitiesList = Object.values(severityHash).map(severity => {
        const value = severity;
        let label = '';

        switch (value) {
            case SEVERITY_1:
                label = `${value} (Critical)`;
                break;

            case SEVERITY_2:
                label = `${value} (High)`;
                break;

            case SEVERITY_3:
                label = `${value} (Medium)`;
                break;

            case SEVERITY_4:
                label = `${value} (Low)`;
                break;

            case SEVERITY_5:
                label = `${value} (Default)`;
                break;
        }

        return { value, label };
    });

    /**
     * List of case type.
     * @type {string[]}
     */
    static typesList = Object.values(typeHash);

    /**
     * List of case environments.
     * @type {string[]}
     */
    static environments = Object.values(environmentHash);

    constructor(dropDownUtils) {
        /**
         * @type {DropDownOption[]}
         */
        this.severities = CaseModalController.severitiesList
            .map(({ value, label }) => dropDownUtils.createOption(
                value,
                label,
            ));

        /**
         * @type {DropDownOption[]}
         */
        this.types = CaseModalController.typesList.map(value => dropDownUtils.createOption(value));

        /**
         * @type {DropDownOption[]}
         */
        this.environments = CaseModalController.environments
            .map(value => dropDownUtils.createOption(value));

        /**
         * Flag to show and hide severity dropdown.
         * when selected type is Configuration Help, Question or Feature Request
         * we will hide severity dropdown.
         * @type {boolean}
         */
        this.displaySeverity = true;
    }

    /**
     * @override
     */
    $onInit() {
        this.updateSeverity_();
    }

    /**
     * Handle type change.
     */
    handleTypeChange() {
        this.updateSeverity_();
    }

    /**
     * Update the severity dropdown value.
     * @param {Object} Case Case Item instance.
     */
    updateSeverity_() {
        const config = this.editable.getConfig();
        const { type } = config;

        if (
            type === CONFIGURATION_HELP ||
            type === QUESTION ||
            type === FEATURE_REQUEST
        ) {
            this.displaySeverity = false;
            config.severity = SEVERITY_4;
        } else {
            this.displaySeverity = true;
        }
    }
}

CaseModalController.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @alias caseModal
 * @param {Case} editable
 * @author Ashish Verma
 */
angular.module('avi/cportal').component('caseModal', {
    bindings: {
        editable: '<',
    },
    controller: CaseModalController,
    templateUrl: 'src/components/modals/portal/case/case-modal.component.html',
});
