/***************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */

/** @alias samlSettings */
class SamlSettingsController {
    constructor(
        Schema,
        schemaService,
        Regex,
    ) {
        this.Schema = Schema;
        this.Regex = Regex;
        this.schemaService = schemaService;
    }

    /** @override */
    $onInit() {
        this.types = this.schemaService.getEnumValues('AuthSamlEntityType');
    }

    /**
     * Check SAML entity type is of a particular one.
     * @param {string} type
     * @return {boolean}
     */
    isSamlEntityType(type) {
        return this.saml.sp.saml_entity_type === type;
    }
}

SamlSettingsController.$inject = [
    'Schema',
    'schemaService',
    'Regex',
];

/**
 * @ngdoc component
 * @name samlSettings
 * @param {Object} saml - SAML config object (message SamlSettings)
 * @param {Function} onTypeChange - SAML type change event handler. "Type" variable
 *     should be passed with AuthSamlEntityType enum to be set.
 * @param {boolean} editMode - Disables SAML entity type change when true is passed.
 * @desc
 *     Configuration form for SAML Settings object (message SamlSettings).
 */
angular.module('aviApp').component('samlSettings', {
    bindings: {
        saml: '<',
        onTypeChange: '&',
        editMode: '<',
    },
    controller: SamlSettingsController,
    templateUrl: 'src/components/common/saml-settings/saml-settings.component.html',
});
